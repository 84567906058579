import { Camera, useCesium } from 'resium';
import PointGeometry from '../../../../entities/geometry/PointGeometry';
import { useSelector } from '../../../../store';
import { selectSelectedPresentationSlide } from '../../../../store/slices/presentation';
import { setSlideDescriptionCoordinates } from '../../viewpoint-description/ViewpointDescription';

export default function AppCamera() {
    const { scene } = useCesium();
    const selectedPresentationSlide = useSelector(state => selectSelectedPresentationSlide(state));

    return (
        <Camera
            percentageChanged={0.001}
            onChange={() => {
                if (selectedPresentationSlide) {
                    const position2d = PointGeometry.getCoordinatesAsWindowCoordinates(
                        selectedPresentationSlide.content.geometry.coordinates,
                        scene!
                    );

                    if (position2d) {
                        setSlideDescriptionCoordinates(position2d);
                    }
                }
            }}
        />
    );
}
