import * as Cesium from 'cesium';
import _ from 'lodash';
import { forwardRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Cesium3DTileset, CesiumComponentRef } from 'resium';
import { SourceType } from '../../../generated/cloud-frontend-api';
import { useResourceVisibility } from '../../../hooks/useResourceVisibility';
import { AppDispatch, useSelector } from '../../../store';
import { setTilesetBoundingSphere, setTilesetLoading, setTilesetTransform } from '../../../store/slices/projectView';
import { LimitBoxParams } from '../geometry-layers/limit-box/LimitBox';
import { AppResource } from './ResourceProvider';
import { getQualityParameters } from './tileset3d-visualization/tileset3dQualityParameters';

type Props = {
    resource: AppResource;
    opacity: number;
};

const customShader = new Cesium.CustomShader({ lightingModel: Cesium.LightingModel.UNLIT });

const Tileset3DVisualization = forwardRef<CesiumComponentRef<Cesium.Cesium3DTileset>, Props>(
    ({ resource, opacity }, ref) => {
        const dispatch: AppDispatch = useDispatch();
        const isCesiumLoaded = useSelector(state => state.projectView.isCesiumLoaded);
        const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
        const quality3DMode = useSelector(state => state.projectView.quality3DMode);
        const structures = useSelector(state => state.structure.structures);
        const datasets = useSelector(state => state.datasets.datasets);

        const clippingPlaneCollection = useMemo(() => {
            const dataset = datasets.find(d => d.visualData?.dataUid === resource.tilesetId);
            const structureInfo = structures.find(s => s.uid === dataset?.datasetUid);
            const limitBox = structureInfo?.properties?.limitBoxParams;
            if (limitBox) {
                const limitBoxParams = JSON.parse(limitBox) as LimitBoxParams;
                return new Cesium.ClippingPlaneCollection({
                    planes: _.map(limitBoxParams, ({ distance, normal }) => new Cesium.ClippingPlane(normal, distance)),
                    edgeWidth: 0,
                    unionClippingRegions: true
                });
            }
        }, [datasets, structures, resource.tilesetId]);

        const { actualVisible, visibleInCompareTree1, visibleInCompareTree2 } = useResourceVisibility(resource);

        const style = useMemo(
            () => new Cesium.Cesium3DTileStyle({ color: `rgba(255, 255, 255, ${opacity})` }),
            [opacity]
        );

        const isPointCloud =
            resource.sourceType === SourceType.POINT_CLOUD || resource.sourceType === SourceType.TIE_POINTS;

        const splitDirection = useMemo(() => {
            if (isCompareToolEnabled) {
                if (visibleInCompareTree1 && visibleInCompareTree2) return Cesium.SplitDirection.NONE;
                if (visibleInCompareTree1) return Cesium.SplitDirection.LEFT;
                return Cesium.SplitDirection.RIGHT;
            }

            return Cesium.SplitDirection.NONE;
        }, [isCompareToolEnabled, visibleInCompareTree1, visibleInCompareTree2]);

        const pointCloudShading = useMemo(
            () =>
                new Cesium.PointCloudShading({
                    attenuation: true,
                    geometricErrorScale: 1,
                    maximumAttenuation: isPointCloud ? (opacity * 7 >= 1 ? opacity * 7 : 1) : 5,
                    normalShading: false
                }),
            [opacity, isPointCloud]
        );

        const qualityParameters = useMemo(
            () => getQualityParameters(resource.maximumScreenSpaceError, quality3DMode),
            [resource.maximumScreenSpaceError, quality3DMode]
        );

        return (
            <Cesium3DTileset
                ref={ref}
                customShader={customShader}
                splitDirection={splitDirection}
                maximumScreenSpaceError={qualityParameters.maximumScreenSpaceError}
                skipLevelOfDetail={qualityParameters.skipLevelOfDetail}
                foveatedConeSize={qualityParameters.foveatedConeSize}
                dynamicScreenSpaceError={qualityParameters.dynamicScreenSpaceError}
                show={actualVisible}
                clippingPlanes={clippingPlaneCollection}
                url={resource}
                // @ts-expect-error cesium typings bugged
                pointCloudShading={pointCloudShading}
                progressiveResolutionHeightFraction={0.3}
                luminanceAtZenith={1}
                onReady={tileset => {
                    if (!tileset) {
                        return;
                    }
                    dispatch(setTilesetBoundingSphere({ tilesetId: resource.tilesetId, bs: tileset.boundingSphere }));
                    dispatch(
                        setTilesetTransform({
                            tilesetId: resource.tilesetId,
                            transform: tileset.root.computedTransform
                        })
                    );
                }}
                onLoadProgress={(numberOfPendingRequests, numberOfTilesProcessing) => {
                    if (!isCesiumLoaded) return;

                    dispatch(
                        setTilesetLoading({
                            tilesetId: resource.tilesetId,
                            isLoading: !!(numberOfPendingRequests || numberOfTilesProcessing)
                        })
                    );
                }}
                style={!isPointCloud ? style : undefined}
            />
        );
    }
);

export default Tileset3DVisualization;
