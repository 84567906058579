import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import produce from 'immer';
import _, { isUndefined } from 'lodash';
import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import appAxios from '../../api/appAxios';
import { projectPreviewApi, structureApi } from '../../api/initApis';
import { StructureInfo } from '../../generated/project-structure-api/model';
import { upsertConditionally } from '../../lib/upsert';
import { Routes, TerrainViewModes, Units } from '../../sharedConstants';
import { createSetterReducer } from '../helpers';
import { PointOfView, ProjectStructureObjectTypes } from '../helpers/interfaces';
import { AppDispatch, ApplicationState } from '../index';
import { CoordinateSystem } from './coordinateSystems';
import { LimitBoxParams } from '../../components/ProjectView/geometry-layers/limit-box/LimitBox';

type StructureInfoProperties = {
    expanded?: string;
    visible?: string;
    order?: string;
    opacity?: string; // = point size for Point clouds
    limitBoxEnabled?: string;
    limitBoxParams?: string;
    styleBlockExpanded?: string;
    generalBlockExpanded?: string;
    reportsBlockExpanded?: string;
    parentProjectBlockExpanded?: string;
    relationsBlockExpanded?: string;
    // Root properties
    defaultPointOfView?: string;
    viewMode?: string;
    baseMap?: string;
    coordinateSystem?: string;
    units?: string;
    // Group properties
    name?: string;
    nestingLevel?: string;
};

export interface ExtendedStructureInfo extends StructureInfo {
    properties: StructureInfoProperties;
}

type StructureState = {
    structures: ExtendedStructureInfo[];
    defaultPOVSaveRequested: boolean;
};

const initialState: StructureState = {
    structures: [],
    defaultPOVSaveRequested: false
};

const name = 'structure';

interface DeleteStructureArgs {
    projectId: string;
    structureId: string;
}
export const deleteStructure = createAsyncThunk(
    `${name}/deleteStructure`,
    async ({ projectId, structureId }: DeleteStructureArgs) => {
        const { data } = await structureApi.deleteStructure(projectId, structureId);
        return data;
    }
);

interface GetStructuresArgs {
    projectId: string;
    access?: string;
    embed?: string;
}
export function requestStructureInfo({ projectId, access, embed }: GetStructuresArgs) {
    let url = `/api/projects/${projectId}/structures`;
    if (access) url = url.concat(`?access=${access}`);
    if (embed) url = url.concat(`?embed=${embed}`);

    return axios
        .request({ url, method: 'GET', headers: { Accept: 'application/stream+json' } })
        .then(({ data }: AxiosResponse<ExtendedStructureInfo | string>) => {
            let resStructures: ExtendedStructureInfo[] = [];
            if (typeof data === 'string')
                resStructures = _.compact(data.split('\n')).map(part => JSON.parse(part)) as ExtendedStructureInfo[];
            else resStructures = [data];
            return resStructures;
        })
        .catch(err => {
            return [] as ExtendedStructureInfo[];
        });
}
export const getStructures = createAsyncThunk(`${name}/getStructures`, requestStructureInfo);

export interface PutStructureArgs {
    type: ProjectStructureObjectTypes;
    projectId: string;
    structureInfo: ExtendedStructureInfo;
}

export const putStructure = createAsyncThunk(
    `${name}/putStructure`,
    async ({ structureInfo, projectId }: PutStructureArgs) => {
        const isInOwnedProjectView = Boolean(
            matchPath(window.location.pathname, {
                path: [Routes.PROJECT_VIEW, Routes.SITE_VIEW],
                exact: true
            })
        );
        if (!isInOwnedProjectView) return;

        const { data } = await structureApi.putStructure(projectId, structureInfo);
        return data;
    }
);

interface UpdateStructureInfoArgs {
    type: ProjectStructureObjectTypes;
    projectId: string;
    propName: keyof ExtendedStructureInfo['properties'];
    propValue: string;
    structureUid: string;
}
export const updateStructureInfo = createAsyncThunk<
    void,
    UpdateStructureInfoArgs,
    { state: ApplicationState; dispatch: AppDispatch }
>(`${name}/update`, async ({ propName, type, propValue, projectId, structureUid }, { getState, dispatch }) => {
    const existingStructure = getState().structure.structures.find(s => s.uid === structureUid);
    dispatch(
        putStructure({
            structureInfo: {
                parentUid: existingStructure?.parentUid || undefined,
                uid: structureUid,
                properties: {
                    ...(existingStructure?.properties || { visible: true.toString() }),
                    [propName]: propValue
                }
            },
            type,
            projectId
        })
    );
});

interface PutStructuresArgs {
    projectId: string;
    structureInfo: ExtendedStructureInfo[];
}
export const putStructures = createAsyncThunk(
    `${name}/putStructures`,
    async ({ structureInfo, projectId }: PutStructuresArgs) => {
        const { data } = await structureApi.putStructures(projectId, structureInfo);
        return data as unknown as ExtendedStructureInfo[];
    }
);

type SaveRootStructureProperty =
    | { propertyName: 'defaultPointOfView'; propertyValue: PointOfView<number> }
    | { propertyName: 'viewMode'; propertyValue: TerrainViewModes }
    | { propertyName: 'baseMap'; propertyValue: string }
    | { propertyName: 'coordinateSystem'; propertyValue: CoordinateSystem }
    | { propertyName: 'units'; propertyValue: Units };
export const saveRootStructureProperty = createAsyncThunk<
    void,
    SaveRootStructureProperty,
    { state: ApplicationState; dispatch: AppDispatch }
>(`${name}/saveRootProperty`, async ({ propertyName, propertyValue }, { dispatch, getState }) => {
    const projectId = getState().project.projectInfo.id!;
    const existingStructureInfo = getState().structure.structures.find(s => s.uid === projectId);
    const serializedValue = JSON.stringify(propertyValue);
    const properties = { ...(existingStructureInfo?.properties || {}), [propertyName]: serializedValue };
    await dispatch(
        putStructure({
            structureInfo: { uid: projectId, properties },
            projectId,
            type: ProjectStructureObjectTypes.ROOT
        })
    );
});

interface CreateProjectPreviewArgs {
    projectId: string;
    image: Blob;
}
export const createProjectPreview = createAsyncThunk(
    `${name}/createProjectPreview`,
    async ({ projectId, image }: CreateProjectPreviewArgs) => {
        const { data } = await projectPreviewApi.createPreview(projectId, { sizeInBytes: image.size });
        if (data.url) {
            await appAxios.request({ url: data.url, method: 'PUT', data: image });
        }
    }
);

const setterReducer = createSetterReducer<StructureState>();
const structureSlice = createSlice({
    name,
    initialState,
    reducers: {
        setDefaultPOVSaveRequested: setterReducer('defaultPOVSaveRequested'),
        setStructureProperty(
            state,
            { payload }: PayloadAction<{ id: string; propName: keyof StructureInfoProperties; propValue: string }>
        ) {
            const structureInfo = state.structures.find(s => s.uid === payload.id);
            if (structureInfo?.properties) structureInfo.properties[payload.propName] = payload.propValue;
        },
        setStructureParent(state, { payload }: PayloadAction<{ id: string; parentId: string }>) {
            const structureInfo = state.structures.find(s => s.uid === payload.id);
            if (structureInfo) structureInfo.parentUid = payload.parentId;
        },
        addStructureInfo(state, { payload }: PayloadAction<ExtendedStructureInfo>) {
            state.structures.push(payload);
        },
        deleteStructureInfo(state, { payload }: PayloadAction<{ id: string }>) {
            const index = state.structures.findIndex(s => s.uid === payload.id);
            if (index !== -1) state.structures.splice(index, 1);
        }
    },
    extraReducers: builder =>
        builder
            .addCase(deleteStructure.fulfilled, (state, action) => {
                const { structureId } = action.meta.arg;
                const index = state.structures.findIndex(s => s.uid === structureId);
                if (index !== -1) state.structures.splice(index, 1);
            })
            .addCase(getStructures.fulfilled, (state, { payload, meta }) => {
                state.structures = payload;
                for (let s of state.structures) {
                    if (!s.parentUid) s.parentUid = meta.arg.projectId;
                }
            })
            .addCase(putStructure.pending, (state, action) => {
                const { structureInfo } = action.meta.arg;
                // Handle first ever chunk visibility change
                if (action.meta.arg.type === ProjectStructureObjectTypes.CHUNK) {
                    const structureInfoExists = state.structures.find(s => s.uid === structureInfo.uid);
                    upsertConditionally(
                        state.structures,
                        produce(structureInfo, draft => {
                            if (!structureInfoExists && !structureInfo.properties.expanded)
                                draft.properties.expanded = 'true';
                        }),
                        si => si.uid === structureInfo.uid
                    );
                } else upsertConditionally(state.structures, structureInfo, si => si.uid === structureInfo.uid);
            })
            .addCase(putStructures.fulfilled, (state, action) => {
                state.structures = action.meta.arg.structureInfo;
            })
});

export const {
    setDefaultPOVSaveRequested,
    setStructureProperty,
    addStructureInfo,
    deleteStructureInfo,
    setStructureParent
} = structureSlice.actions;

export default structureSlice.reducer;

const selectRootStructureInfo = createSelector(
    (state: ApplicationState) => state.structure,
    (state: ApplicationState) => state.project.projectInfo,
    ({ structures }, { id }) => structures.find(s => s.uid === id)
);

export const selectDefaultPointOfView = createSelector(selectRootStructureInfo, rootStructureInfo => {
    const stringPov = rootStructureInfo?.properties?.defaultPointOfView;
    if (stringPov) return JSON.parse(stringPov) as PointOfView<number>;
});

const selectViewMode = createSelector(selectRootStructureInfo, rootStructureInfo => {
    const stringViewMode = rootStructureInfo?.properties?.viewMode;
    if (stringViewMode) return JSON.parse(stringViewMode) as TerrainViewModes;
    else return TerrainViewModes.EARTH;
});

export function isObjectVisible(structures: StructureInfo[], structureUid: string): boolean {
    const structureInfo = structures.find(s => s.uid === structureUid);
    if (structureInfo?.properties?.visible) return structureInfo.properties.visible === 'true';
    return true;
}
export const selectObjectVisibility = createSelector(
    (state: ApplicationState) => state.structure.structures,
    (state: ApplicationState, structureUid: string) => structureUid,
    (structures, structureUid) => isObjectVisible(structures, structureUid)
);

export function isObjectExpanded(structures: StructureInfo[], structureUid: string): boolean {
    const structureInfo = structures.find(s => s.uid === structureUid);
    if (structureInfo?.properties?.expanded) return structureInfo.properties.expanded === 'true';
    return true;
}
export const selectObjectExpansion = createSelector(
    (state: ApplicationState) => state.structure.structures,
    (state: ApplicationState, structureUid: string) => structureUid,
    (structures, structureUid) => isObjectExpanded(structures, structureUid)
);

export function getGroups(structures: ExtendedStructureInfo[]) {
    return structures.filter(s => s.properties?.nestingLevel === '1').reverse();
}
export const selectGroups = createSelector(
    (state: ApplicationState) => state.structure.structures,
    structures => getGroups(structures)
);

export const makeSelectStructureInfo = () =>
    createSelector(
        (state: ApplicationState) => state.structure.structures,
        (state: ApplicationState, structureUid: string) => structureUid,
        (structures, structureUid) => structures.find(s => s.uid === structureUid)
    );

export function getNextStructureOrder(structures: ExtendedStructureInfo[]): number {
    if (!structures.length) return -1; // Math.min on empty array returns Infinity!
    return Math.min(...structures.map(s => parsed(s.properties?.order))) - 1;

    function parsed(order: string | undefined): number {
        if (!order || isNaN(parseInt(order))) return 0;
        return parseInt(order);
    }
}

const selectStructuresWithEnabledLimitBox = createSelector(
    (state: ApplicationState) => state.structure.structures,
    structures => structures.filter(s => s.properties?.limitBoxEnabled === String(true))
);

export const selectStructureWithEnabledLimitBox = createSelector(
    selectStructuresWithEnabledLimitBox,
    structures => structures?.[0]
);

export const selectLimitBoxParamsOfEnabledLimitBox = createSelector(selectStructureWithEnabledLimitBox, structure => {
    const limitBoxParams = structure?.properties?.limitBoxParams;
    if (limitBoxParams) return JSON.parse(limitBoxParams) as LimitBoxParams;
});

export const expandedPropertiesBlockNames = [
    'styleBlockExpanded',
    'generalBlockExpanded',
    'reportsBlockExpanded',
    'parentProjectBlockExpanded',
    'relationsBlockExpanded'
] as const;

function isPropertiesBlockExpanded(
    structures: ExtendedStructureInfo[],
    structureUid: string,
    propName: keyof Pick<ExtendedStructureInfo['properties'], (typeof expandedPropertiesBlockNames)[number]>
): boolean {
    const structureInfo = structures.find(s => s.uid === structureUid);
    if (structureInfo) {
        const expanded = structureInfo.properties[propName];
        if (isUndefined(expanded)) return true;
        return expanded === String(true);
    }
    return true; // default is true
}

// TODO: доработать в ACM-4248
export const selectReportsPropertiesBlockExpansion = createSelector(
    (state: ApplicationState) => state.structure.structures,
    (state: ApplicationState, structureUid: string) => structureUid,
    (structures, structureUid) => isPropertiesBlockExpanded(structures, structureUid, 'reportsBlockExpanded')
);

export const selectPropertiesBlockExpansion = createSelector(
    (state: ApplicationState) => state.structure.structures,
    (state: ApplicationState, structureUid: string) => structureUid,
    (state: ApplicationState, structureUid: string, propName: (typeof expandedPropertiesBlockNames)[number]) =>
        propName,
    (structures, structureUid, propName) => isPropertiesBlockExpanded(structures, structureUid, propName)
);

export function isStructureInfoGroup(structureInfo: StructureInfo): boolean {
    return structureInfo.properties?.nestingLevel === '1';
}

export function sortItemsByStructureInfo<T extends { id: string }>(items: T[], structures: StructureInfo[]): T[] {
    const itemsWithOrder = items.filter(item => structures.find(s => s.uid === item.id)?.properties?.order);
    const itemsWithoutOrder = _.difference(items, itemsWithOrder);

    return [
        ..._.sortBy(itemsWithOrder, item => {
            const structureInfo = structures.find(s => s.uid === item.id);
            if (structureInfo?.properties?.order) return parseInt(structureInfo.properties.order);
        }),
        ...itemsWithoutOrder
    ];
}
