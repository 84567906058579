import { unwrapResult } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import produce from 'immer';
import _, { uniqBy } from 'lodash';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { ComponentProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ProjectInfo, ProjectStructure, ProjectType } from '../../../../generated/cloud-frontend-api';
import { ProjectPartType } from '../../../../generated/dataset-api';
import getArtifactDisplayName from '../../../../lib/getArtifactDisplayName';
import getFilename from '../../../../lib/getFilename';
import { WGS84_EPSG_CODE } from '../../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../../store';
import { ExtendedChunk, ProjectStructureObjectTypes } from '../../../../store/helpers/interfaces';
import { createLoadingSelector } from '../../../../store/selectors/createLoadingSelector';
import {
    addProjectCoordinateSystem,
    selectPersonalAndDefaultCoordinateSystems
} from '../../../../store/slices/coordinateSystems';
import { ExtendedDatasetInfo, getDataset, linkDataset } from '../../../../store/slices/datasetfilesUpload';
import { getGroupedDatasets, isLinkedDataset, setDatasetProperty } from '../../../../store/slices/datasets';
import { selectDatasets } from '../../../../store/slices/datasetsUpload';
import { getLayer } from '../../../../store/slices/geometryLayers';
import { getRawProjectStructure, setLayerProperty } from '../../../../store/slices/project';
import { sortTreeNodes } from '../../../../store/slices/projectStructure';
import { getAllProjects, getAllProjectsByParent } from '../../../../store/slices/projectsPage';
import { ExtendedStructureInfo } from '../../../../store/slices/structure';
import Checkbox from '../../checkbox/Checkbox';
import ItemIcon from '../../item-icon/ItemIcon';
import Modal, { ModalProps } from '../../modal/Modal';
import ModalActions from '../../modal/ModalActions';
import ModalBody from '../../modal/ModalBody';
import ModalHead from '../../modal/ModalHead';
import TippyTooltip from '../../tippy-tooltip/TippyTooltip';

type Props = ModalProps;

const selectProjectsLoading = createLoadingSelector([getAllProjects.typePrefix, getAllProjectsByParent.typePrefix]);
const selectProjectStructureLoading = createLoadingSelector([getRawProjectStructure.typePrefix]);

export default function LinkModal({ isOpen, setIsOpen }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['modals']);
    const { projectInfo, selectedObject } = useSelector(state => state.project);
    const datasets = useSelector(state => selectDatasets(state));
    const coordinateSystems = useSelector(state => selectPersonalAndDefaultCoordinateSystems(state));
    const isProjectsInfoLoading = useSelector(state => selectProjectsLoading(state));
    const isProjectStructureLoading = useSelector(state => selectProjectStructureLoading(state));
    const [nestingLevel, setNestingLevel] = useState(0);
    const [selectedProjectId, setSelectedProjectId] = useState('');
    const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
    const [selectedParentId, setSelectedParentId] = useState('');
    const [modalProjects, setModalProjects] = useState<
        (FileTreeNode & { type?: ProjectType; lastModified?: string })[]
    >([]);
    const [requestedStructureInfo, setRequestedStructureInfo] = useState<ExtendedStructureInfo[]>([]);
    const [requestedStructure, setRequestedStructure] = useState<ProjectStructure>();

    function getFiles(
        projectId: string,
        structure: ProjectStructure | undefined,
        structureInfo: ExtendedStructureInfo[]
    ): FileTreeNode[] {
        return sortTreeNodes(
            buildProjectFilesTree(
                structure?.datasets || [],
                structureInfo,
                (structure?.chunks || []) as ExtendedChunk[],
                modalProjects,
                projectId,
                nestingLevel,
                t
            ),
            structureInfo
        );
    }

    const fileTreeItemsProject = getFiles(selectedProjectId, requestedStructure, requestedStructureInfo);
    const fileTreeItems = modalProjects.concat(fileTreeItemsProject);

    const showSkeleton = isProjectsInfoLoading || (isProjectStructureLoading && selectedParentId !== projectInfo.id);
    const skeletonItems = 8; // max = 8

    const selectedParent = modalProjects.find(p => p.id === selectedParentId);
    const selectedParentType = selectedParent?.type;
    const selectedParentLastModified = selectedParent?.lastModified;

    const showBackLink = nestingLevel > 0;

    function isMovePossible() {
        return selectedItemIds.length;
    }

    async function moveDatasets() {
        const parentProjectName = modalProjects.find(p => p.id === selectedProjectId)?.name || '';

        await Promise.all(
            selectedItemIds.map(async id => {
                await dispatch(
                    linkDataset({
                        datasetId: id,
                        linkedProjectId: projectInfo.id!,
                        projectId: selectedProjectId,
                        parentId: getParentId()
                    })
                );

                const dataset = requestedStructure?.datasets?.find(d => d.datasetUid === id);
                if (dataset?.projectPartType === ProjectPartType.VECTOR_LAYERS)
                    await dispatch(getLayer({ layerId: id, projectId: projectInfo.id! }));
                else await dispatch(getDataset({ datasetUid: id, projectId: projectInfo.id! }));

                const value = { uid: selectedProjectId, name: parentProjectName };
                if (dataset?.projectPartType === ProjectPartType.VECTOR_LAYERS) {
                    dispatch(setLayerProperty({ id, propName: 'parentProject', propValue: value }));
                    dispatch(
                        setLayerProperty({
                            id: dataset?.datasetUid!,
                            propName: 'coordinateSystemUid',
                            propValue: coordinateSystems.find(crs => crs.epsgCode === WGS84_EPSG_CODE)?.uid
                        })
                    );
                } else {
                    dispatch(addProjectCoordinateSystem(dataset?.coordinateSystemUid!));
                    dispatch(setDatasetProperty({ id, propName: 'parentProject', value }));
                }
            })
        );

        function getParentId(): string {
            if (selectedObject?.type === ProjectStructureObjectTypes.GROUP) return selectedObject.artifactId!; // View page
            return projectInfo.id!;
        }
    }

    const alreadyLinkedToThisProject = (n: FileTreeNode & { type?: ProjectType; lastModified?: string }) =>
        !n?.type && Boolean(datasets.find(d => d.datasetUid === n.id));

    useEffect(() => {
        if (selectedProjectId) return;
        if (selectedParentType === ProjectType.METASHAPE || selectedParentType === ProjectType.NON_METASHAPE) return;

        if (nestingLevel === 0) {
            dispatch(getAllProjects({}))
                .then(unwrapResult)
                .then(({ projects: allProjects }) => {
                    setModalProjects(prevProjects =>
                        uniqBy([...prevProjects, ...buildProjectsTree(allProjects || [], 0, '')], p => p.id)
                    );
                });
        } else {
            dispatch(getAllProjectsByParent({ parentUid: selectedParentId }))
                .then(unwrapResult)
                .then(allProjects => {
                    setModalProjects(prevProjects =>
                        uniqBy(
                            [...prevProjects, ...buildProjectsTree(allProjects, nestingLevel, selectedParentId)],
                            p => p.id
                        )
                    );
                });
        }
    }, [dispatch, nestingLevel, selectedParentType, selectedParentId, selectedProjectId]);

    useEffect(() => {
        if (!selectedParentType) return;
        if (selectedParentType === ProjectType.SITE || selectedParentType === ProjectType.FOLDER) return;

        dispatch(getRawProjectStructure({ id: selectedParentId, lastModified: selectedParentLastModified }))
            .then(unwrapResult)
            .then(({ structure, structureInfo }) => {
                setRequestedStructureInfo(structureInfo);
                setRequestedStructure(structure);
            });
    }, [dispatch, selectedParentId, selectedParentType, selectedParentLastModified]);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalClass='modal-files'>
            <ModalHead setIsOpen={setIsOpen}>
                <span>{t('modals:linkModal.title')}</span>
            </ModalHead>
            <ModalBody>
                <div className='data-info'>
                    <div className='data-title'>
                        {t('modals:linkModal.projectTitle')} <b>{projectInfo.name}</b>
                    </div>
                    <div className='data-hint'>{t('modals:linkModal.description')}</div>
                </div>
                <div className='navigation'>
                    {showBackLink && (
                        <div
                            className='back-link'
                            onClick={e => {
                                if (selectedParentId === selectedProjectId) setSelectedProjectId('');

                                setSelectedParentId(
                                    prevParentId => fileTreeItems.find(i => i.id === prevParentId)?.parentId || ''
                                );
                                setSelectedItemIds([]);
                                setNestingLevel(nestingLevel => nestingLevel - 1);
                            }}
                        >
                            <span className='back-link-icon' />
                        </div>
                    )}
                    <span className='navigation-title'>
                        {(() => {
                            if (nestingLevel === 0) return t('modals:linkModal.navigationDefaultTitle');
                            if (nestingLevel === 1 && selectedParentId === projectInfo.id!) return projectInfo.name;
                            return fileTreeItems.find(i => i.id === selectedParentId)?.name || '';
                        })()}
                    </span>
                </div>
                {showSkeleton ? (
                    <div className='files-wrapper modal-files-list'>
                        {[...Array(skeletonItems)].map((e, i) => (
                            <div className='file-item skel-item' key={i}>
                                <div className='skel-icon' />
                                <div className='skel-title' />
                                <div className='skel-checkbox' />
                            </div>
                        ))}
                    </div>
                ) : (
                    <OverlayScrollbarsComponent>
                        <div className='files-wrapper modal-files-list'>
                            {fileTreeItems
                                .filter(
                                    item =>
                                        item.parentId === selectedParentId &&
                                        !alreadyLinkedToThisProject(item) &&
                                        item.id !== '#datasets'
                                )
                                .map(({ id, active, name, parentId, expandable, iconType }) => (
                                    <div
                                        key={id}
                                        className={classNames('file-item', {
                                            disabled: !active,
                                            selected: selectedItemIds.includes(id),
                                            expandable: expandable && active
                                        })}
                                        onClick={e => {
                                            e.stopPropagation();
                                            if (expandable && active) {
                                                setSelectedParentId(id);
                                                setSelectedItemIds([]);
                                                setNestingLevel(l => l + 1);
                                                const projectType = modalProjects.find(p => p.id === id)?.type;
                                                if (
                                                    projectType === ProjectType.METASHAPE ||
                                                    projectType === ProjectType.NON_METASHAPE
                                                )
                                                    setSelectedProjectId(id);
                                            }
                                        }}
                                    >
                                        <ItemIcon type={iconType} />
                                        <TippyTooltip tooltipText={name}>
                                            <span className='item-title'>{name}</span>
                                        </TippyTooltip>
                                        {!expandable && active && (
                                            <div className='item-checkbox'>
                                                <Checkbox
                                                    checked={selectedItemIds.includes(id)}
                                                    onChange={e => {
                                                        setSelectedItemIds(
                                                            produce((ids: string[]) => {
                                                                if (ids.includes(id)) ids.splice(ids.indexOf(id), 1);
                                                                else ids.push(id);
                                                            })
                                                        );
                                                    }}
                                                />
                                            </div>
                                        )}
                                        {expandable && (
                                            <div className='item-move'>
                                                <div className='item-move-icon' />
                                            </div>
                                        )}
                                    </div>
                                ))}
                        </div>
                    </OverlayScrollbarsComponent>
                )}
                <ModalActions>
                    <button
                        type='button'
                        className='btn'
                        disabled={!isMovePossible()}
                        onClick={e => {
                            moveDatasets();
                            setIsOpen(false);
                        }}
                    >
                        {t('modals:linkModal.linkAction')}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}

export interface FileTreeNode {
    id: string;
    parentId?: string;
    name: string;
    nestingLevel: number;
    active?: boolean;
    expandable?: boolean;
    iconType: ComponentProps<typeof ItemIcon>['type'];
}
export const fileTreeIconTypes: Record<ProjectType, ComponentProps<typeof ItemIcon>['type']> = {
    [ProjectType.METASHAPE]: 'project',
    [ProjectType.NON_METASHAPE]: 'project',
    [ProjectType.FOLDER]: 'folder',
    [ProjectType.SITE]: 'site'
};

function buildProjectsTree(
    projects: ProjectInfo[],
    nestingLevel: number,
    parentId: string
): (FileTreeNode & { type: ProjectType; lastModified: string })[] {
    return projects.map(p => ({
        id: p.id!,
        name: p.name!,
        nestingLevel,
        parentId,
        type: p.type!,
        active: true,
        expandable: true,
        lastModified: p.dateModified!,
        iconType: fileTreeIconTypes[p.type!]
    }));
}

function buildProjectFilesTree(
    datasets: ExtendedDatasetInfo[],
    structures: ExtendedStructureInfo[],
    chunks: ExtendedChunk[],
    projects: (FileTreeNode & { type?: ProjectType })[],
    projectId: string,
    nestingLevel: number,
    t: TFunction<'modals', void>
): FileTreeNode[] {
    const shownDatasets = datasets.filter(d => !isLinkedDataset(d, projectId));
    const groups = structures.filter(s => s.properties?.nestingLevel === '1');
    const notArtifacts = shownDatasets.filter(d => d.projectPartType !== ProjectPartType.TILESETS);
    const groupedDatasets = getGroupedDatasets(notArtifacts, structures);
    const ungroupedDatasets = _.difference(notArtifacts, groupedDatasets);
    const project = projects.find(p => p.id === projectId);

    const nodes: FileTreeNode[] = _.compact([
        project?.id
            ? {
                  id: `${project.id}#datasets`,
                  name: t('linkModal.datasets'),
                  active: true,
                  expandable: true,
                  nestingLevel,
                  parentId: projectId,
                  iconType: 'group' as const
              }
            : null,
        project?.type === ProjectType.METASHAPE
            ? {
                  id: `${projectId}#chunks`,
                  name: t('linkModal.processingResults'),
                  active: true,
                  expandable: true,
                  nestingLevel,
                  parentId: projectId,
                  iconType: 'chunk' as const
              }
            : null,
        ...chunks.map(chunk => ({
            id: chunk.assetUid!,
            name: chunk.chunkName!,
            parentId: `${projectId}#chunks`,
            nestingLevel: nestingLevel + 1,
            iconType: 'chunk' as const,
            active: true,
            expandable: true
        })),
        ...chunks.flatMap(c =>
            c.artifacts
                .map(a => shownDatasets.findLast(d => d.datasetUid === a.datasetUid))
                .filter(dataset => dataset !== undefined)
                .map(dataset => {
                    return {
                        id: dataset?.datasetUid!,
                        name: getArtifactDisplayName(dataset!),
                        active: true,
                        parentId: c.assetUid,
                        nestingLevel: nestingLevel + 2,
                        expandable: false,
                        iconType: dataset?.sourceData?.type!
                    };
                })
        ),
        ...groups.map(g => ({
            id: g.uid!,
            name: g.properties?.name || '',
            active: true,
            parentId: `${projectId}#datasets`,
            nestingLevel: nestingLevel + 1,
            expandable: true,
            iconType: 'group' as const
        })),
        ...ungroupedDatasets.map(d => {
            const isActive = d.properties?.isInspection !== 'true' && d.properties?.isPresentation !== 'true';
            return {
                id: d.datasetUid!,
                name: getFilename(d.name!),
                active: isActive,
                parentId: `${projectId}#datasets`,
                nestingLevel: nestingLevel + 1,
                iconType:
                    d.projectPartType === ProjectPartType.VECTOR_LAYERS ? mapLayerIconToType(d) : d.sourceData?.type!
            };
        }),
        ...groupedDatasets.map(d => {
            const isActive = d.properties?.isInspection !== 'true' && d.properties?.isPresentation !== 'true';
            return {
                id: d.datasetUid!,
                name: getFilename(d.name!),
                active: isActive,
                parentId: structures.find(s => s.uid === d.datasetUid)?.parentUid || '',
                nestingLevel: nestingLevel + 2,
                iconType:
                    d.projectPartType === ProjectPartType.VECTOR_LAYERS ? mapLayerIconToType(d) : d.sourceData?.type!
            };
        })
    ]);
    return nodes;
}

function mapLayerIconToType(layer: ExtendedDatasetInfo): 'inspection' | 'layer' | 'tour' {
    if (layer.properties?.isInspection) return 'inspection';
    if (layer.properties?.isPresentation) return 'tour';
    return 'layer';
}
