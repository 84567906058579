import classNames from 'classnames';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';

type Props = {
    disabled?: boolean;
    active: boolean;
    onClick(e: SyntheticEvent): void;
};

export default function VisibilityToggleControl({ disabled, onClick, active }: Props) {
    const { t } = useTranslation('projectView');

    if (disabled)
        return (
            <span className='control disabled'>
                <i className='visibility-disabled' data-testid={'disabledVisibilityIcon'} />
            </span>
        );

    return (
        <TippyTooltip
            tooltipText={
                active
                    ? t('structureItem.controls.tooltipVisibility_hide')
                    : t('structureItem.controls.tooltipVisibility_show')
            }
        >
            <span
                className={classNames('control')}
                onClick={e => {
                    e.stopPropagation();
                    onClick(e);
                }}
            >
                <i className={classNames('icon', active ? 'icon-visible' : 'icon-invisible')} data-testid={'eyeIcon'} />
            </span>
        </TippyTooltip>
    );
}
