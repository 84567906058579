import React, { useContext, useEffect, useRef } from 'react';
import { DatasetInfo } from '../../../generated/dataset-api/model/dataset-info';
import { AppDispatch, useSelector } from '../../../store';
import { useDispatch } from 'react-redux';
import { getDataset } from '../../../store/slices/datasetfilesUpload';
import { unwrapResult } from '@reduxjs/toolkit';
import { Status } from '../../../generated/dataset-api';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { useParams } from 'react-router-dom';
import { EmbeddedProjectViewRouteParams } from '../../../pages/EmbeddedProjectView';

type Props = {
    dataset: DatasetInfo;
    hasQueriedForProject: boolean;
};

export default function DatasetProgressPoller({ dataset, hasQueriedForProject }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { embed } = useParams<EmbeddedProjectViewRouteParams>();
    const { projectInfo } = useSelector(state => state.project);
    const { owned } = useContext(ProjectViewAccessContext);
    const { access } = useSelector(state => state.sharing);
    const interval = useRef(0);

    useEffect(() => {
        if (hasQueriedForProject && projectInfo.id && dataset.datasetUid && !interval.current) {
            interval.current = window.setInterval(async () => {
                const datasetInfo = unwrapResult(
                    await dispatch(
                        getDataset({
                            projectId: projectInfo.id!,
                            datasetUid: dataset.datasetUid!,
                            access: !owned ? access?.accessKey : undefined,
                            embed
                        })
                    )
                );

                if (datasetInfo.visualData?.status !== Status.IN_PROGRESS) clearInterval(interval.current);
            }, 10000);
        }
    }, [embed, hasQueriedForProject, dispatch, projectInfo.id, dataset.datasetUid, access?.accessKey, owned]);

    useEffect(() => {
        return () => {
            clearInterval(interval.current);
        };
    }, []);

    return null;
}
