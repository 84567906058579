import rewind from '@mapbox/geojson-rewind';
import {
    EntityState,
    PayloadAction,
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice
} from '@reduxjs/toolkit';
import _, { isUndefined } from 'lodash';
import { AppDispatch, ApplicationState } from '..';
import appAxios from '../../api/appAxios';
import { geometryApi } from '../../api/initApis';
import getGeometryMeasures from '../../lib/getGeometryMeasures';
import { EMPTY_ARRAY, GeometryTypes } from '../../sharedConstants';
import {
    CurrentlyEditingShape,
    GeoJson,
    PropertyBlocksProperties,
    TemporaryGeometry,
    isIssuePointGeometry,
    isPointGeometry,
    isPolygonGeometry,
    isPolylineGeometry
} from '../helpers/interfaces';
import { volumeDeleted } from '../sharedActions';
import { unlinkDataset } from './datasetfilesUpload';
import {
    commitGeometry,
    deleteLayer,
    getLayer,
    getLayerSize,
    selectInspections,
    updateAllGeometriesInLayer
} from './geometryLayers';

export const geometriesAdapter = createEntityAdapter<TemporaryGeometry>({});

const initialState: EntityState<TemporaryGeometry, string> = geometriesAdapter.getInitialState();

const name = 'geometries';

interface DeleteGeometry {
    projectUid: string;
    layerUid: string;
    id: string;
}
export const deleteGeometry = createAsyncThunk<void, DeleteGeometry, { dispatch: AppDispatch }>(
    `${name}/deleteGeometry`,
    async ({ id, layerUid, projectUid }, { dispatch }) => {
        const url = `/api/projects/${projectUid}/layers/${layerUid}/geometries/${id}`;
        const { data } = await appAxios.request({ url, method: 'delete' });
        dispatch(getLayerSize({ layerId: layerUid, projectId: projectUid }));
        return data;
    }
);

interface MoveGeometryArgs {
    projectUid: string;
    layerUid: string;
    geometryUid: string;
    afterUid: string | undefined;
}
export const moveGeometry = createAsyncThunk(
    `${name}/move`,
    async ({ afterUid, geometryUid, layerUid, projectUid }: MoveGeometryArgs) => {
        const { data } = await geometryApi.moveGeometry(projectUid, layerUid, geometryUid, afterUid);
        return data;
    }
);

const slice = createSlice({
    name,
    initialState,
    reducers: {
        setGeometries: geometriesAdapter.setAll,
        addGeometries(state, { payload }: PayloadAction<{ geometries: TemporaryGeometry[]; datasetId: string }>) {
            geometriesAdapter.addMany(state, payload.geometries);
        },
        addGeometry(
            state,
            { payload }: PayloadAction<{ geoJson: GeoJson; id: string; index?: number; datasetId: string }>
        ) {
            geometriesAdapter.addOne(state, {
                id: payload.id,
                content: payload.geoJson,
                renderAsEntity: true
            });
        },
        removeGeometry(state, { payload }: PayloadAction<{ id: string; datasetId: string }>) {
            geometriesAdapter.removeOne(state, payload.id);
        },
        removeGeometries: geometriesAdapter.removeMany,
        updateGeometryContent(state, { payload }: PayloadAction<{ id: string; geoJson: GeoJson }>) {
            geometriesAdapter.updateOne(state, { id: payload.id, changes: { content: payload.geoJson } });
        },
        updateGeometryProperty(
            state,
            {
                payload
            }: PayloadAction<{
                id: string;
                propName: keyof TemporaryGeometry;
                propValue: TemporaryGeometry[keyof TemporaryGeometry];
            }>
        ) {
            const geometry = state.entities[payload.id];
            if (geometry) _.set(geometry, payload.propName, payload.propValue);
        },
        addCoordinatesToGeometry(state, { payload }: PayloadAction<{ id: string; coordinates: number[] }>) {
            const geometry = state.entities[payload.id];
            if (geometry) {
                if (isPolylineGeometry(geometry)) geometry.content.geometry.coordinates.push(payload.coordinates);
                if (isPolygonGeometry(geometry)) {
                    const coords = geometry.content.geometry.coordinates;
                    // Insert at the position before last point (which duplicates the 1 coordinate)
                    geometry.content.geometry.coordinates[0].splice(coords.length - 2, 0, payload.coordinates);
                    // Outer ring must follow right hand rule
                    rewind(geometry.content.geometry.coordinates[0]);
                }
            }
        },
        removeCoordinateFromGeometry(state, { payload }: PayloadAction<{ id: string; index: number }>) {
            const geometry = state.entities[payload.id];
            if (geometry) {
                if (isPolylineGeometry(geometry)) {
                    geometry.content.geometry.coordinates.splice(payload.index, 1);
                    geometry.content.properties = {
                        ...geometry.content.properties,
                        ...getGeometryMeasures(geometry.content)
                    };
                }
                if (isPolygonGeometry(geometry)) {
                    geometry.content.geometry.coordinates[0].splice(payload.index, 1);
                    if (payload.index === 0) {
                        // Last position duplicates first, so if first is removed, last one needs to become second position
                        geometry.content.geometry.coordinates[0][geometry.content.geometry.coordinates[0].length - 1] =
                            geometry.content.geometry.coordinates[0][1];
                        // Outer ring must follow right hand rule
                        rewind(geometry.content.geometry.coordinates[0]);
                        geometry.content.properties = {
                            ...geometry.content.properties,
                            ...getGeometryMeasures(geometry.content)
                        };
                    }
                }
            }
        },
        replaceCoordinatesInGeometry(
            state,
            { payload }: PayloadAction<{ currentlyEditingShape: CurrentlyEditingShape; coordinates: number[] }>
        ) {
            const { id, isMidpoint, index } = payload.currentlyEditingShape;
            const geometry = state.entities[id];
            if (geometry) {
                if (isPointGeometry(geometry)) geometry.content.geometry.coordinates = payload.coordinates;
                const correctIndex = isMidpoint ? index! + 1 : index;
                if (isPolylineGeometry(geometry))
                    geometry.content.geometry.coordinates[correctIndex!] = payload.coordinates;

                if (isPolygonGeometry(geometry)) {
                    geometry.content.geometry.coordinates[0][correctIndex!] = payload.coordinates;
                    // If the first position gets updated, update the last position, since they must always be identical
                    if (correctIndex === 0) {
                        geometry.content.geometry.coordinates[0][geometry.content.geometry.coordinates[0].length - 1] =
                            payload.coordinates;
                    }

                    // Remove volume calculations, since they become invalid on coordinates change
                    geometry.content.properties.ac_volume_above_cubic_meters = undefined;
                    geometry.content.properties.ac_volume_below_cubic_meters = undefined;
                    geometry.content.properties.ac_volume_total_cubic_meters = undefined;
                }
            }
        },
        createCoordinateInGeometry(
            state,
            { payload }: PayloadAction<{ currentlyEditingShape: CurrentlyEditingShape; coordinates: number[] }>
        ) {
            const { id, isMidpoint, index } = payload.currentlyEditingShape;
            const geometry = state.entities[id];
            if (geometry && isMidpoint) {
                if (isPolylineGeometry(geometry))
                    geometry.content.geometry.coordinates.splice(index! + 1, 0, payload.coordinates);
                if (isPolygonGeometry(geometry))
                    geometry.content.geometry.coordinates[0].splice(index! + 1, 0, payload.coordinates);
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getLayer.fulfilled, (state, { payload }) => {
                geometriesAdapter.addMany(state, payload.geometries);
            })
            .addCase(deleteGeometry.fulfilled, (state, { meta }) => {
                geometriesAdapter.removeOne(state, meta.arg.id);
            })
            .addCase(deleteLayer.fulfilled, (state, { meta }) => {
                const { geometryIds } = meta.arg;
                geometriesAdapter.removeMany(state, geometryIds);
            })
            .addCase(commitGeometry.fulfilled, (state, { payload, meta }) => {
                const temporaryId = meta.arg.temporaryGeometryUid;
                const id = payload.geometryId;
                geometriesAdapter.updateOne(state, { id: temporaryId, changes: { id } });
            })
            .addCase(updateAllGeometriesInLayer.fulfilled, (state, { meta }) => {
                const { properties, ids } = meta.arg;
                for (let id of ids) {
                    const geometry = state.entities[id];
                    if (geometry) {
                        if (isPolylineGeometry(geometry)) {
                            geometry.content.properties = {
                                ...geometry.content.properties,
                                ...properties,
                                ac_color: properties.ac_stroke_color
                            };
                        } else {
                            geometry.content.properties = { ...geometry.content.properties, ...properties };
                        }
                    }
                }
            })
            .addCase(volumeDeleted, (state, { payload }) => {
                const polygon = state.entities[payload.id];
                if (polygon) {
                    const properties = polygon.content.properties;
                    delete properties.ac_volume_base_plane;
                    delete properties.ac_volume_cell_size_meters;
                    delete properties.ac_volume_base_level_meters;
                    delete properties.ac_volume_above_cubic_meters;
                    delete properties.ac_volume_below_cubic_meters;
                    delete properties.ac_volume_total_cubic_meters;
                }
            })
            .addCase(unlinkDataset.fulfilled, (state, { payload }) => {
                geometriesAdapter.removeMany(state, payload.geometryIds);
            });
    }
});

export default slice.reducer;

export const {
    setGeometries,
    addGeometries,
    addCoordinatesToGeometry,
    addGeometry,
    removeGeometries,
    removeGeometry,
    removeCoordinateFromGeometry,
    replaceCoordinatesInGeometry,
    updateGeometryContent,
    updateGeometryProperty,
    createCoordinateInGeometry
} = slice.actions;

export const {
    selectAll: selectGeometriesArray,
    selectEntities: selectGeometries,
    selectById: selectGeometryById
} = geometriesAdapter.getSelectors((state: ApplicationState) => state.geometries);

export const makeSelectLayerGeometries = () =>
    createSelector(
        selectGeometries,
        (state: ApplicationState) => state.project.structure.temporaryLayers,
        (state: ApplicationState, datasetId: string) => datasetId,
        (geometries, layers, datasetId) =>
            layers.find(l => l.id === datasetId)?.geometries.map(id => geometries[id]!) || EMPTY_ARRAY
    );

export const selectIssues = createSelector(
    selectGeometries,
    (state: ApplicationState) => selectInspections(state),
    (geometries, inspections) =>
        inspections
            .flatMap(i => i.geometries)
            .map(id => geometries[id]!)
            .filter(g => isIssuePointGeometry(g)) as TemporaryGeometry<GeometryTypes.POINT>[]
);

export const makeSelectIssuesByPhotoUid = () =>
    createSelector(
        selectIssues,
        (state: ApplicationState, photoUid: string) => photoUid,
        (issues, photoUid) =>
            issues.filter(
                i => i.content.properties.ac_photoUid === photoUid
            ) as TemporaryGeometry<GeometryTypes.POINT>[]
    );

export const makeSelectIssuesByImageUid = () =>
    createSelector(
        selectIssues,
        (state: ApplicationState, imageUid: string) => imageUid,
        (issues, imageUid) =>
            issues.filter(
                i => i.content.properties.ac_image_uid === imageUid
            ) as TemporaryGeometry<GeometryTypes.POINT>[]
    );

export function isGeometryPropertiesBlockExpanded<T extends GeometryTypes>(
    geojson: GeoJson<T>,
    propName: keyof PropertyBlocksProperties<T>
): boolean {
    const expanded = geojson?.properties[propName] as boolean | undefined;
    if (isUndefined(expanded)) return true;
    return expanded;
}
