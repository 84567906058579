import { memo, useContext, useMemo, useState } from 'react';
import { GeometryTypes, isGeoJsonFeatureSupported } from '../../../sharedConstants';
import { useSelector } from '../../../store';
import { TemporaryLayer } from '../../../store/helpers/interfaces';
import { isObjectVisible, selectGroups, selectObjectVisibility } from '../../../store/slices/structure';
import Geometry from './Geometry';
import GeometryPrimitives from './primitives/GeometryPrimitives';
import { makeSelectLayerGeometries } from '../../../store/slices/geometries';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { adaptVectorLayerToDataset, selectUpload } from '../../../store/slices/datasetsUpload';
import { isLinkedDataset } from '../../../store/slices/datasets';

type Props = {
    geometryLayer: TemporaryLayer;
};

function GeometryLayer({ geometryLayer }: Props) {
    const { owned } = useContext(ProjectViewAccessContext);
    const project = useSelector(state => state.project.projectInfo);
    const groups = useSelector(state => selectGroups(state));
    const structures = useSelector(state => state.structure.structures);
    const upload = useSelector(state => selectUpload(state, geometryLayer.id));
    const selectLayerGeometries = useMemo(makeSelectLayerGeometries, []);
    const layerGeometries = useSelector(state => selectLayerGeometries(state, geometryLayer.id));
    const layerVisible = useSelector(state => selectObjectVisibility(state, geometryLayer.id));
    const currentlyDrawingShapeId = useSelector(state => state.projectView.currentlyDrawingShapeId);
    const [primitiveHasRendered, setPrimitiveHasRendered] = useState<Record<GeometryTypes, boolean>>({
        [GeometryTypes.POINT]: true, // No need to prevent points' rendering
        [GeometryTypes.POLYLINE]: false,
        [GeometryTypes.POLYGON]: false
    });

    const structureInfo = structures.find(s => s.uid === geometryLayer.id);
    const group = groups.find(g => g.uid === structureInfo?.parentUid);
    const belongsToGroup = Boolean(group);
    const groupVisible = belongsToGroup ? isObjectVisible(structures, group?.uid!) : true;

    const dataset = adaptVectorLayerToDataset(geometryLayer, upload);
    const isLinkedFromAnotherProject = isLinkedDataset(dataset, project.id!);

    return (
        <>
            <GeometryPrimitives
                layerVisible={layerVisible && groupVisible}
                setPrimitiveHasRendered={setPrimitiveHasRendered}
                hasRendered={primitiveHasRendered}
                geometries={layerGeometries.filter(g => isGeoJsonFeatureSupported(g.content) && !g.renderAsEntity)}
            />
            {layerGeometries
                .filter(g => isGeoJsonFeatureSupported(g.content) && g.renderAsEntity)
                .map(g => (
                    <Geometry
                        readonly={
                            (!owned && !geometryLayer.isTemporary) ||
                            currentlyDrawingShapeId === g.id ||
                            isLinkedFromAnotherProject
                        }
                        geometry={g}
                        key={g.id}
                        layerVisible={layerVisible && groupVisible}
                    />
                ))}
        </>
    );
}

export default memo(GeometryLayer);
