import { ImageryLayerCollection } from 'resium';
import { useSelector } from '../../../../store';
import { selectIonBaseLayers, selectUrlBaseLayers } from '../../../../store/slices/init';
import BaseIonImageryLayer from './BaseIonImageryLayer';
import BaseUrlImageryLayer from './BaseUrlImageryLayer';

function BaseImagery() {
    const ionBaseLayers = useSelector(selectIonBaseLayers);
    const urlBaseLayers = useSelector(selectUrlBaseLayers);

    return (
        // @ts-expect-error wrong resium type of ImageryLayerCollection['children']
        <ImageryLayerCollection>
            {ionBaseLayers.map(layer => (
                <BaseIonImageryLayer key={layer.id} baseLayer={layer} />
            ))}
            {urlBaseLayers.map(layer => (
                <BaseUrlImageryLayer key={layer.id} baseLayer={layer} />
            ))}
        </ImageryLayerCollection>
    );
}

export default BaseImagery;
