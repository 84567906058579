import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { ProjectInfo } from '../../../../generated/cloud-frontend-api/model';
import { Routes } from '../../../../sharedConstants';
import { useSelector } from '../../../../store';
import { ReactComponent as SvgMove } from '../../../../svg/dropdown_actions/move_to.svg';
import MoveProjectModal from '../../../Projects/move-project-modal/MoveProjectModal';
import TippyDropdownItemWithIcon from '../../tippy-dropdown/TippyDropdownItemWithIcon';

type Props = {
    projects: ProjectInfo | ProjectInfo[];
};

export default function MoveProjectAction({ projects }: Props) {
    const { t } = useTranslation(['projects']);
    const projectsPageParent = useSelector(state => state.projectsPage.parent);
    const projectParent = useSelector(state => state.project.parent);
    const siteParent = useSelector(state => state.site.siteInfo.parent);
    const isProjectsPage = !!useRouteMatch({ path: [Routes.INDEX, Routes.SITE, Routes.FOLDER], exact: true });
    const isSiteViewPath = !!useRouteMatch({ path: Routes.SITE_VIEW, exact: true });
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getCurrentParent = () => {
        if (isProjectsPage) return projectsPageParent;
        if (isSiteViewPath) return siteParent;
        return projectParent;
    };

    return (
        <>
            <TippyDropdownItemWithIcon
                icon={<SvgMove />}
                text={t('projects:projectActions.move')}
                onClick={() => {
                    setIsModalOpen(true);
                }}
            />

            {isModalOpen && (
                <MoveProjectModal
                    setIsOpen={setIsModalOpen}
                    isOpen={isModalOpen}
                    projects={projects}
                    currentParent={getCurrentParent()}
                />
            )}
        </>
    );
}
