import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../store';
import { selectIsAnyToolEnabled } from '../../store/slices/projectView';
import FullscreenButton from '../../components/Elements/fullscreen-button/FullscreenButton';
import CompareCloseControl from '../../components/ProjectView/breadcrumbs/CompareCloseControl';
import InspectCloseControl from '../../components/ProjectView/breadcrumbs/elements/InspectCloseControl';
import PresentationCloseControl from '../../components/ProjectView/breadcrumbs/elements/PresentationCloseControl';

type Props = {
    showName: boolean;
    projectName: string | undefined;
};

export default function EmbeddedProjectHead({ showName, projectName }: Props) {
    const { t } = useTranslation(['projectView']);
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const isCamerasInspectionEnabled = useSelector(state => state.projectView.isCamerasInspectionEnabled);
    const isPresentationSetupEnabled = useSelector(state => state.projectView.isPresentationSetupEnabled);
    const isAnyToolEnabled = useSelector(state => selectIsAnyToolEnabled(state));

    return (
        <div className={classNames('embed-head', { 'embed-tool': isAnyToolEnabled })}>
            {!isAnyToolEnabled && (
                <>
                    {showName ? <div className='project-name'>{projectName}</div> : <div />}
                    <FullscreenButton />
                </>
            )}
            {isCompareToolEnabled && (
                <>
                    <div className='tool-title'>
                        <div className='tool-icon'>
                            <i className='icon icon-compare' />
                        </div>
                        <span>{t('projectView:compareMode.breadcrumbsTitle')}</span>
                    </div>
                    <div className='tool-controls'>
                        <FullscreenButton />
                        <CompareCloseControl />
                    </div>
                </>
            )}
            {isCamerasInspectionEnabled && (
                <>
                    <div className='tool-title'>
                        <div className='tool-icon'>
                            <i className='icon icon-inspector' />
                        </div>
                        <span>{t('projectView:inspectionTool.breadcrumbsTitle')}</span>
                    </div>
                    <div className='tool-controls'>
                        <FullscreenButton />
                        <InspectCloseControl />
                    </div>
                </>
            )}
            {isPresentationSetupEnabled && (
                <>
                    <div className='tool-title'>
                        <div className='tool-icon'>
                            <i className='icon icon-presentation' />
                        </div>
                        <span>{t('projectView:presentationTool.breadcrumbsTitle')}</span>
                    </div>
                    <div className='tool-controls'>
                        <FullscreenButton />
                        <PresentationCloseControl />
                    </div>
                </>
            )}
        </div>
    );
}
