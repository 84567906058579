import { Issue } from '../../../entities/Issue';

type Props = {
    label: string;
    field: 'severity' | 'type' | 'description';
    severity?: keyof ReturnType<typeof Issue.Severities>;
    type?: keyof typeof Issue.Types;
    description?: string;
};

export default function IssueReadOnlyProperty({ label, field, severity, type, description }: Props) {
    return (
        <div className='property'>
            <div className='prop-wrapper read-only'>
                <div className='prop-label-wrapper'>
                    <textarea className='prop-input prop-label' readOnly rows={1} tabIndex={-1} value={label} />
                </div>
                <div className='prop-value-wrapper'>
                    {field === 'severity' && (
                        <div className='prop-input prop-severity'>
                            <div
                                className='prop-severity-bullet'
                                style={{ backgroundColor: Issue.SeverityColors[severity!] }}
                            />
                            <span>{Issue.Severities()[severity!]}</span>
                        </div>
                    )}

                    {field === 'type' && <div className='prop-input prop-text'>{Issue.TypesDisplayNames()[type!]}</div>}

                    {field === 'description' && <div className='prop-input prop-text'>{description!}</div>}
                </div>
            </div>
        </div>
    );
}
