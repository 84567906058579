import { useSelector } from '../../../store';
import { TemporaryLayer } from '../../../store/helpers/interfaces';
import { selectGeometries } from '../../../store/slices/geometries';
import GeometryName from './GeometryName';

type Props = {
    layer: TemporaryLayer;
};

export default function GeometryNames({ layer }: Props) {
    const geometries = useSelector(selectGeometries);

    return (
        <>
            {layer.geometries.map(id => (
                <GeometryName key={id} geometry={geometries[id]!} layer={layer} />
            ))}
        </>
    );
}
