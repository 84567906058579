import { useCesium } from 'resium';
import makePolylinesAlwaysVisible from '../../../lib/makePolylinesAlwaysVisible';
import { useSelector } from '../../../store';
import { selectIssues } from '../../../store/slices/geometries';
import { selectInspections, selectVectorLayers } from '../../../store/slices/geometryLayers';
import GeometryNames from '../geometry-names/GeometryNames';
import GeometryLayer from './GeometryLayer';
import IssueGeometry from './geometries/IssueGeometry';
import Presentation from './presentation/Presentation';
import RulerPolylineGeometry from './ruler/RulerPolylineGeometry';

makePolylinesAlwaysVisible();

export default function GeometryLayers() {
    const { viewer } = useCesium();
    const vectorLayers = useSelector(selectVectorLayers);
    const inspectionLayers = useSelector(selectInspections);
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const isRulerToolEnabled = useSelector(state => state.projectView.isRulerToolEnabled);
    const issues = useSelector(selectIssues);

    return (
        <>
            {!isCompareToolEnabled && (
                <>
                    {vectorLayers.map(l => (
                        <GeometryLayer key={l.id} geometryLayer={l} />
                    ))}
                    <Presentation />
                    {vectorLayers
                        .filter(l => l.showGeometriesNames)
                        .map(l => (
                            <GeometryNames key={l.id} layer={l} />
                        ))}
                    {inspectionLayers
                        .flatMap(i => i.geometries)
                        .map(id => {
                            const issue = issues.find(i => i.id === id);
                            return issue && <IssueGeometry key={issue.id} issue={issue} />;
                        })}
                </>
            )}

            {isRulerToolEnabled && <RulerPolylineGeometry />}
        </>
    );
}
