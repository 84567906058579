import { BlobReader, ZipWriter, BlobWriter } from '@zip.js/zip.js';
import i18n from '../../../i18n/config';
import { Issue } from '../../../entities/Issue';
import { Units } from '../../../sharedConstants';
import { getUnitsFullName } from '../../../lib/getUnitsFullName';
import { convertLengthUnit } from '../../../lib/convertUnit';

export default function InspectionCSVGenerator(
    projectName: string,
    layerName: string,
    issues: any,
    allCameras: any,
    units: Units
) {
    const unitsName = getUnitsFullName(units);

    const headers = [
        `"${i18n.t('generatedReports:inspectionReport_csv.severity')}","${i18n.t(
            'generatedReports:inspectionReport_csv.type'
        )}","${i18n.t('generatedReports:inspectionReport_csv.name')}","${i18n.t(
            'generatedReports:inspectionReport_csv.description'
        )}","${i18n.t('generatedReports:inspectionReport_csv.photo')}","${i18n.t(
            'generatedReports:inspectionReport_csv.latitude'
        )}","${i18n.t('generatedReports:inspectionReport_csv.longitude')}","${i18n.t(
            'generatedReports:inspectionReport_csv.altitude'
        )} (${unitsName})",`
    ];

    issues.sort((a: any, b: any) => b.content.properties.ac_severity - a.content.properties.ac_severity);

    let inspectionCSV: Array<any> = [];

    issues.forEach((issue: any) => {
        const camera = allCameras.find((c: any) => c.uid === issue.content.properties.ac_photoUid);

        inspectionCSV.push([
            Issue.Severities()[issue.content.properties.ac_severity as keyof ReturnType<typeof Issue.Severities>],
            Issue.TypesDisplayNames()[issue.content.properties.ac_type as keyof typeof Issue.TypesDisplayNames],
            replaceComma(issue.content.properties.ac_name),
            formatDescription(issue.content.properties.ac_description),
            camera?.fileName || '-',
            issue.content.geometry.coordinates[1].toFixed(6),
            issue.content.geometry.coordinates[0].toFixed(6),
            convertLengthUnit(issue.content.geometry.coordinates[2], Units.METRE, units).toFixed(3)
        ]);
    });

    function replaceComma(name: string) {
        return name.replaceAll(',', '.');
    }

    function formatDescription(descr: string | undefined) {
        let splitted = replaceComma(descr || '').split(/\n/);

        let newString = splitted.reduce((sum, substring) => {
            const trimmedSubstring = substring.trim();
            if (trimmedSubstring === '') return sum;
            return sum === '' ? trimmedSubstring : sum + ' ' + trimmedSubstring;
        }, '');

        return newString;
    }

    const inspectionData = [...headers, ...inspectionCSV].join('\n');
    const inspectionBlob = new Blob([inspectionData], { type: 'text/csv' });
    const inspectionFileName = i18n.t('generatedReports:inspectionReport_csv.filenames.file', {
        projectName,
        layerName
    });

    const zipFileName = i18n.t('generatedReports:inspectionReport_csv.filenames.zip', { projectName, layerName });

    getZipFileBlob().then(zipBlob => {
        const a = document.createElement('a');
        a.download = zipFileName;
        a.href = window.URL.createObjectURL(zipBlob);
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true
        });
        a.dispatchEvent(clickEvt);
        a.remove();
    });

    async function getZipFileBlob() {
        const zipWriter = new ZipWriter(new BlobWriter('application/zip'));
        await Promise.all([
            inspectionCSV.length > 0 && zipWriter.add(inspectionFileName, new BlobReader(inspectionBlob))
        ]);
        return zipWriter.close();
    }
}
