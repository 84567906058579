import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Dataset } from '../../../entities/Dataset';
import { ProjectPartType } from '../../../generated/cloud-frontend-api';
import { AppDispatch, useSelector } from '../../../store';
import { ProjectStructureObjectTypes, TemporaryLayer } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors/index';
import { ExtendedDatasetInfo, cancelDatasetUpload } from '../../../store/slices/datasetfilesUpload';
import { removeDataset } from '../../../store/slices/datasets';
import { adaptVectorLayerToDataset, selectUpload, uploadAborted } from '../../../store/slices/datasetsUpload';
import AbortDatasetUploadModal from '../../Elements/modals/abort-dataset-upload-modal/AbortDatasetUploadModal';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';

export default function DatasetFileUploadAbort() {
    const { t } = useTranslation(['projectView']);
    const dispatch: AppDispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const projectInfo = useSelector(state => state.project.projectInfo);

    const selectedObject = useSelector(state => state.project.selectedObject);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));
    const upload = useSelector(state => selectUpload(state, selectedObject?.artifactId!));

    if (!selectedObjectInfo) return null;

    const dataset =
        selectedObject.type === ProjectStructureObjectTypes.DATASET ||
        selectedObject.type === ProjectStructureObjectTypes.ARTIFACT
            ? (selectedObjectInfo as ExtendedDatasetInfo)
            : adaptVectorLayerToDataset(selectedObjectInfo as TemporaryLayer, upload!);

    const { isValidating, isPendingManualAction, datasetUid, projectPartType } = new Dataset(dataset);

    const hasNotBeenUploaded = isValidating || isPendingManualAction;

    async function abortDatasetUpload() {
        if (hasNotBeenUploaded) dispatch(removeDataset({ datasetUid: datasetUid! }));
        else await dispatch(cancelDatasetUpload({ datasetUid: datasetUid!, projectId: projectInfo?.id! }));
    }

    return (
        <>
            <TippyTooltip tooltipText={t('projectView:inspectionSidebar.controls.tooltipAbort')}>
                <div
                    className='control'
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                >
                    <i className='icon icon-delete' />
                </div>
            </TippyTooltip>

            {isModalOpen && (
                <AbortDatasetUploadModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    datasetName={dataset.name}
                    onAbort={() => {
                        if (projectPartType === ProjectPartType.VECTOR_LAYERS) dispatch(uploadAborted(datasetUid));
                        else abortDatasetUpload();
                    }}
                />
            )}
        </>
    );
}
