import { unwrapResult } from '@reduxjs/toolkit';
import * as Cesium from 'cesium';
import { colord } from 'colord';
import produce from 'immer';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useCesium } from 'resium';
import { v4 as uuidv4 } from 'uuid';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { assertNever } from '../../../lib/assertNever';
import { getRandomColorHex } from '../../../lib/getRandomColor';
import pickEntities, { getPointFromPickedObjects } from '../../../lib/pickEntities';
import { GeometryTypes, TerrainViewModes, WGS84_EPSG_CODE } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { GeoJson, ProjectStructureObjectTypes, TemporaryLayer } from '../../../store/helpers/interfaces';
import { selectPersonalAndDefaultCoordinateSystems } from '../../../store/slices/coordinateSystems';
import { isLinkedDataset } from '../../../store/slices/datasets';
import { adaptVectorLayerToDataset } from '../../../store/slices/datasetsUpload';
import {
    addGeometry as addGeometryAction,
    selectGeometries,
    updateGeometryContent
} from '../../../store/slices/geometries';
import { beginLayer, selectInspections, selectTours, selectVectorLayers } from '../../../store/slices/geometryLayers';
import { addTemporaryLayer } from '../../../store/slices/project';
import {
    addStructureInfo,
    getNextStructureOrder,
    isObjectVisible,
    putStructure,
    selectGroups,
    updateStructureInfo
} from '../../../store/slices/structure';

interface GetLayerUidArgs {
    isInspection?: boolean;
    isPresentation?: boolean;
}

interface ReturnValue {
    getLayerUid(args?: GetLayerUidArgs): Promise<TemporaryLayer>;
    getNewLayerProperties(args?: GetLayerUidArgs): Partial<Record<keyof TemporaryLayer, string>>;
    getNewLayerUid(properties?: Record<string, string>): Promise<TemporaryLayer>;
    enableLayerVisibilityIfInvisible(layerUid: string): void;
    enableDrawingGeometryVisibilityIfInvisible(layerUid: string): void;
    getGeometryColors(
        layer: TemporaryLayer,
        type: GeometryTypes
    ): {
        ac_color: string;
        ac_stroke_color?: string;
    };
    addGeometry(geoJson: GeoJson, layerUid: string, index?: number): string;
    pickCartographicDegrees(position: Cesium.Cartesian2): number[] | undefined;
    pickCartesian(position: Cesium.Cartesian2): Cesium.Cartesian3 | undefined;
    cartesianToCartographicDegrees(position: Cesium.Cartesian3): number[] | undefined;
    hasPickedSavingPointWhileDrawing(position: Cesium.Cartesian2): boolean;
}

export default function useDrawing(): ReturnValue {
    const { scene, camera, globe } = useCesium();
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const currentlyDrawingShapeId = useSelector(state => state.projectView.currentlyDrawingShapeId);
    const coordinateSystems = useSelector(state => selectPersonalAndDefaultCoordinateSystems(state));
    const selectedObject = useSelector(state => state.project.selectedObject);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const temporaryLayers = useSelector(state => state.project.structure.temporaryLayers);
    const vectorLayers = useSelector(selectVectorLayers);
    const inspections = useSelector(selectInspections);
    const tours = useSelector(selectTours);
    const geometries = useSelector(selectGeometries);
    const uploads = useSelector(state => state.datasetsUpload.uploads);
    const structures = useSelector(state => state.structure.structures);
    const groups = useSelector(state => selectGroups(state));
    const isAnyLayerSelected = selectedObject?.type === ProjectStructureObjectTypes.LAYER;
    const isAnyGeometrySelected = selectedObject?.type === ProjectStructureObjectTypes.GEOMETRY;
    const { owned } = useContext(ProjectViewAccessContext);
    const notUploadedYet = !!uploads[selectedObject?.artifactId!];

    async function getLayerUid(
        { isInspection, isPresentation }: GetLayerUidArgs = { isInspection: false, isPresentation: false }
    ): Promise<TemporaryLayer> {
        return (
            getLayerUidFromSelectedLayer() ||
            (await getNewLayerUid(getNewLayerProperties({ isInspection, isPresentation })))
        );

        function getLayerUidFromSelectedLayer(): TemporaryLayer | undefined {
            if (isAnyGeometrySelected) {
                const layerOfSelectedGeometry = temporaryLayers.find(l =>
                    l.geometries.includes(selectedObject?.artifactId!)
                );
                return layerOfSelectedGeometry ? checkIfCanDraw(layerOfSelectedGeometry) : undefined;
            }

            if (isAnyLayerSelected) {
                const selectedLayer = temporaryLayers.find(l => l.id === selectedObject.artifactId);
                return selectedLayer ? checkIfCanDraw(selectedLayer) : undefined;
            }
        }

        function checkIfCanDraw(layer: TemporaryLayer): TemporaryLayer | undefined {
            if (layer?.isTemporary) return layer;

            if ((!isPresentation && layer?.isPresentation) || (isPresentation && !layer?.isPresentation))
                return undefined;

            if ((!isInspection && layer?.isInspection) || (isInspection && !layer?.isInspection)) return undefined;

            if (isLinkedDataset(adaptVectorLayerToDataset(layer!, uploads[layer?.id!]), projectInfo.id!))
                return undefined;

            return !owned || notUploadedYet ? undefined : layer;
        }
    }

    function getNewLayerProperties(
        { isInspection, isPresentation }: GetLayerUidArgs = { isInspection: false, isPresentation: false }
    ): Partial<Record<keyof TemporaryLayer, string>> {
        const newColor = getRandomColorHex();
        const properties: Partial<Record<keyof TemporaryLayer, string>> = {
            color: newColor,
            strokeColor: newColor
        };

        if (isInspection) {
            return {
                ...properties,
                isInspection: String(true),
                name: t('defaultNames.inspection', { index: inspections.length + 1 })
            };
        }
        if (isPresentation) {
            return {
                ...properties,
                isPresentation: String(true),
                name: t('defaultNames.tour', { index: tours.length + 1 })
            };
        }

        return { ...properties, name: t('defaultNames.vectorLayer', { index: vectorLayers.length + 1 }) };
    }

    async function getNewLayerUid(
        properties: Partial<Record<keyof TemporaryLayer, string>> = {}
    ): Promise<TemporaryLayer> {
        if (owned) {
            const { layer, parentUid } = unwrapResult(
                await dispatch(beginLayer({ projectUid: projectInfo.id!, properties, isGeojson: true }))
            );
            const group = groups.find(g => g.uid === parentUid);
            if (group) {
                dispatch(
                    putStructure({
                        projectId: projectInfo.id!,
                        type: ProjectStructureObjectTypes.GROUP,
                        structureInfo: produce(group, draft => {
                            draft.properties.expanded = String(true);
                            draft.properties.visible = String(true);
                        })
                    })
                );
            }
            return layer;
        } else {
            const index = vectorLayers.filter(l => l.isTemporary).length + 1;
            const layer = dispatch(
                addTemporaryLayer(t('defaultNames.temporaryVectorLayer', { index }), {
                    selectOnCreate: true,
                    crsId: coordinateSystems.find(crs => crs.epsgCode === WGS84_EPSG_CODE)?.uid!
                })
            ).payload;
            const order = getNextStructureOrder(structures);
            dispatch(
                addStructureInfo({
                    uid: layer.id,
                    properties: { order: order.toString(), expanded: 'true', visible: 'true' }
                })
            );
            return layer;
        }
    }

    function getGeometryColors(
        layer: TemporaryLayer,
        type: GeometryTypes
    ): { ac_color: string; ac_stroke_color?: string } {
        const color = layer.color;
        const strokeColor = layer.strokeColor;
        if (type === GeometryTypes.POINT) return { ac_color: color };
        if (type === GeometryTypes.POLYLINE) return { ac_color: strokeColor };
        if (type === GeometryTypes.POLYGON)
            return { ac_color: colord(color).alpha(0.4).toHex(), ac_stroke_color: strokeColor };

        assertNever(type);
    }

    function addGeometry(geoJson: GeoJson, layerUid: string, index?: number): string {
        let id = uuidv4();
        dispatch(addGeometryAction({ datasetId: layerUid, geoJson, id, index }));
        return id;
    }

    function enableLayerVisibilityIfInvisible(layerUid: string): void {
        const layer = temporaryLayers.find(l => l.id === layerUid);
        const visible = isObjectVisible(structures, layer?.id!);
        if (layer && !visible) {
            dispatch(
                updateStructureInfo({
                    projectId: projectInfo.id!,
                    structureUid: layer.id,
                    type: ProjectStructureObjectTypes.LAYER,
                    propValue: true.toString(),
                    propName: 'visible'
                })
            );
        }
        const group = groups.find(g => g.uid === structures.find(s => s.uid === layerUid)?.parentUid);
        if (group) {
            dispatch(
                putStructure({
                    projectId: projectInfo.id!,
                    type: ProjectStructureObjectTypes.GROUP,
                    structureInfo: produce(group, draft => {
                        draft.properties.expanded = true.toString();
                        draft.properties.visible = true.toString();
                    })
                })
            );
        }
    }

    function enableDrawingGeometryVisibilityIfInvisible(layerUid: string): void {
        const layer = temporaryLayers.find(l => l.id === layerUid);
        const geometry = geometries[currentlyDrawingShapeId || ''];
        if (layer && geometry && !geometry.content.properties.ac_visibility) {
            dispatch(
                updateGeometryContent({
                    id: geometry.id,
                    geoJson: produce(geometry.content, draft => {
                        draft.properties.ac_visibility = true;
                    })
                })
            );
        }
    }

    function pickCartesian(position: Cesium.Cartesian2): Cesium.Cartesian3 | undefined {
        return scene?.pickPosition(position);
    }

    /**
     * @return {number[]} as [longitude, latitude, height]
     */
    function pickCartographicDegrees(position: Cesium.Cartesian2): number[] | undefined {
        let pickedPosition = pickCartesian(position);
        if (pickedPosition) {
            const cartographic = Cesium.Cartographic.fromCartesian(pickedPosition);
            return [
                Cesium.Math.toDegrees(cartographic.longitude),
                Cesium.Math.toDegrees(cartographic.latitude),
                cartographic.height
            ];
        }
    }

    /**
     * @return {number[]} as [longitude, latitude, height]
     */
    function cartesianToCartographicDegrees(position: Cesium.Cartesian3): number[] | undefined {
        const cartographic = Cesium.Cartographic.fromCartesian(position);
        return [
            Cesium.Math.toDegrees(cartographic.longitude),
            Cesium.Math.toDegrees(cartographic.latitude),
            cartographic.height
        ];
    }

    //Performance optimization idea - we can compare canvas coordinates with some threshold instead of picking
    function hasPickedSavingPointWhileDrawing(position: Cesium.Cartesian2): boolean {
        if (currentlyDrawingShapeId) {
            const entities = pickEntities(scene!, position);
            const pointEntity = getPointFromPickedObjects(entities);
            if (pointEntity instanceof Cesium.Entity && pointEntity?.id.match(/SavingPoint/)) return true;
        }
        return false;
    }

    return {
        getNewLayerProperties,
        getLayerUid,
        getGeometryColors,
        addGeometry,
        getNewLayerUid,
        enableLayerVisibilityIfInvisible,
        enableDrawingGeometryVisibilityIfInvisible,
        pickCartesian,
        pickCartographicDegrees,
        cartesianToCartographicDegrees,
        hasPickedSavingPointWhileDrawing
    };
}
