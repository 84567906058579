import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import { ReactComponent as SvgDelete } from '../../../svg/general/delete.svg';
import { useTranslation } from 'react-i18next';

type Props = {
    onCancel(): void;
};

export default function CancelUploadControl({ onCancel }: Props) {
    const { t } = useTranslation('projectView');

    return (
        <TippyTooltip tooltipText={t('structureItem.controls.tooltipCancelUpload')}>
            <span
                className='control control-extended'
                onClick={e => {
                    e.stopPropagation();
                    onCancel();
                }}
            >
                <SvgDelete data-testid='cancelIcon' />
            </span>
        </TippyTooltip>
    );
}
