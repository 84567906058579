import { useSelector } from '../../../store';
import StructureTree from '../structure-tree/StructureTree';
import CompareStructureTreeContext from '../../../contexts/CompareStructureTreeContext';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import { useTranslation } from 'react-i18next';

export default function CompareToolSidebar() {
    const { t } = useTranslation('projectView');
    const projectInfo = useSelector(state => state.project.projectInfo);

    return (
        <>
            <section className='section-title compare-title'>
                <div className='tline-number tline-l'>{t('compareMode.L')}</div>
                <TippyTooltip tooltipText={projectInfo.name || ''}>
                    <div className='tline-title'>{projectInfo.name}</div>
                </TippyTooltip>
            </section>
            <section className='section-artefacts'>
                <CompareStructureTreeContext.Provider value={{ treeId: 'tree1' }}>
                    <StructureTree />
                </CompareStructureTreeContext.Provider>
            </section>
            <div className='compare-delimiter' />
            <section className='section-title compare-title'>
                <div className='tline-number tline-r'>{t('compareMode.R')}</div>
                <TippyTooltip tooltipText={projectInfo.name || ''}>
                    <div className='tline-title'>{projectInfo.name}</div>
                </TippyTooltip>
            </section>
            <section className='section-artefacts'>
                <CompareStructureTreeContext.Provider value={{ treeId: 'tree2' }}>
                    <StructureTree />
                </CompareStructureTreeContext.Provider>
            </section>
        </>
    );
}
