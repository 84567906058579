import * as Cesium from 'cesium';
import { Cartesian3 } from 'cesium';
import { Tuple } from '../../../../sharedConstants';

export default abstract class GeometryMeasures {
    protected readonly _coordinatesSystemEllipsoid: Cesium.Ellipsoid = Cesium.Ellipsoid.WGS84;

    protected fromCartographicToCartesian3(cartographic: number[]): Cesium.Cartesian3 {
        return Cartesian3.fromDegrees(
            cartographic[0],
            cartographic[1],
            cartographic[2],
            this._coordinatesSystemEllipsoid
        );
    }

    protected length2D(positions: Cesium.Cartesian3[]): number {
        const ellipsoidTangentPlane = Cesium.EllipsoidTangentPlane.fromPoints(
            positions,
            this._coordinatesSystemEllipsoid
        );
        const positions2D = ellipsoidTangentPlane.projectPointsToNearestOnPlane(positions);
        let length2D = 0;
        for (let i = 0; i < positions2D.length - 1; i++) {
            const segmentLength2D = Cesium.Cartesian2.distance(positions2D[i], positions2D[i + 1]);
            length2D += segmentLength2D;
        }
        return length2D;
    }

    protected length3D(positions: Cesium.Cartesian3[]): number {
        let length = 0;
        for (let i = 0; i < positions.length - 1; i++) {
            const segmentLength = Cesium.Cartesian3.distance(positions[i], positions[i + 1]);
            length += segmentLength;
        }
        return length;
    }

    /**
     * Calculate bounding box for set of 3D points, assuming that points are laying on 2D plane.
     * @return [minX, minY, maxX, maxY] A bounding box.
     */
    public static bbox(positions: Cesium.Cartesian3[]): Tuple<number, 4> {
        const bbox: Tuple<number, 4> = [Infinity, Infinity, -Infinity, -Infinity];
        positions.forEach(vertex => {
            if (bbox[0] > vertex.x) {
                bbox[0] = vertex.x;
            }
            if (bbox[1] > vertex.y) {
                bbox[1] = vertex.y;
            }
            if (bbox[2] < vertex.x) {
                bbox[2] = vertex.x;
            }
            if (bbox[3] < vertex.y) {
                bbox[3] = vertex.y;
            }
        });
        return bbox;
    }

    public static applyTransformationMatrix(
        positions: Cesium.Cartesian3[],
        matrix: Cesium.Matrix4
    ): Cesium.Cartesian3[] {
        return positions.map(position => Cesium.Matrix4.multiplyByPoint(matrix, position, new Cesium.Cartesian3()));
    }

    /**
     * @return A point laying on the given line offsetted by given value from the line start point
     */
    protected getPointOnLine(
        startPoint: Cesium.Cartesian3,
        endPoint: Cesium.Cartesian3,
        offset = 0
    ): Cesium.Cartesian3 {
        const vector = Cesium.Cartesian3.subtract(endPoint, startPoint, new Cesium.Cartesian3());
        const vectorNormalized = Cesium.Cartesian3.normalize(vector, new Cesium.Cartesian3());
        const vectorOffsetted = Cesium.Cartesian3.multiplyByScalar(vectorNormalized, offset, new Cesium.Cartesian3());
        return Cesium.Cartesian3.add(startPoint, vectorOffsetted, new Cesium.Cartesian3());
    }

    protected abstract valuesToObject(): Record<string, number>;
}

export function computeCenterOfPoints(cartesians: Cartesian3[]): Cartesian3 {
    let centerX = 0;
    let centerY = 0;
    let centerZ = 0;

    for (let i = 0; i < cartesians.length; i++) {
        const cartesian = cartesians[i];

        centerX += cartesian.x;
        centerY += cartesian.y;
        centerZ += cartesian.z;
    }

    centerX /= cartesians.length;
    centerY /= cartesians.length;
    centerZ /= cartesians.length;

    return new Cesium.Cartesian3(centerX, centerY, centerZ);
}
