import classNames from 'classnames';
import { useContext } from 'react';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { Select } from 'antd';
import { AppDispatch, useSelector } from '../../../store';
import { useDispatch } from 'react-redux';
import { saveRootStructureProperty } from '../../../store/slices/structure';
import { Units } from '../../../sharedConstants';
import { setUnits } from '../../../store/slices/coordinateSystems';
import { useTranslation } from 'react-i18next';

import styles from './MapSettings.module.scss';

type Props = {
    containerRef: HTMLElement;
};

export default function UnitsSwitch({ containerRef }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const activeUnits = useSelector(store => store.coordinateSystems.units);
    const { isInEmbedView } = useContext(ProjectViewAccessContext);
    const { t } = useTranslation(['projectView']);

    return (
        <div>
            <div className={styles.settingsItemTitle}>{t('projectView:mapControls.measureUnit.title')}</div>
            <Select
                value={activeUnits}
                getPopupContainer={() => containerRef as HTMLElement}
                onChange={newValue => {
                    if (newValue !== activeUnits) {
                        dispatch(setUnits(newValue));
                        dispatch(saveRootStructureProperty({ propertyName: 'units', propertyValue: newValue }));
                    }
                }}
                className={classNames('ant-select-small', { 'theme-dark': isInEmbedView })}
                popupClassName={classNames('ant-dropdown-small', { 'theme-dark': isInEmbedView })}
                data-testid={'unitsSelect'}
                options={[
                    {
                        value: Units.METRE,
                        label: t('projectView:mapControls.measureUnit.meter')
                    },
                    {
                        value: Units.FOOT,
                        label: t('projectView:mapControls.measureUnit.foot')
                    },
                    {
                        value: Units.US_SURVEY_FOOT,
                        label: t('projectView:mapControls.measureUnit.usFoot')
                    }
                ]}
            />
        </div>
    );
}
