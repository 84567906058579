import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ProjectsPlaceholder from '../components/Projects/ProjectsPlaceholder';
import ProjectsGrid from '../components/projects-grid/ProjectsGrid';
import { AppDispatch, useSelector } from '../store';
import { createLoadingSelector } from '../store/selectors/createLoadingSelector';
import { getAccount } from '../store/slices/accountResources';
import { firstProgressTick } from '../store/slices/progress';
import { resetProjects, selectProjects, setIsLoading } from '../store/slices/projectsPage';

const loadingSelector = createLoadingSelector([firstProgressTick.typePrefix, getAccount.typePrefix]);

export default function Projects() {
    const dispatch: AppDispatch = useDispatch();
    const isOnline = useSelector(state => state.globalFlags.isOnline);
    const page = useSelector(state => state.projectsPage);
    const projects = useSelector(selectProjects);
    const isLoading = useSelector(state => loadingSelector(state) || state.projectsPage.isLoading);

    const notFoundBySearch = !projects.length && !isLoading && !!page.searchText.length;
    const isPlaceholderVisible = !isOnline || notFoundBySearch;

    useEffect(() => {
        dispatch(setIsLoading(true));

        return () => {
            dispatch(resetProjects());
        };
    }, [dispatch]);

    return (
        <>
            {!isOnline && <ProjectsPlaceholder type={'offline'} />}

            {notFoundBySearch && isOnline && <ProjectsPlaceholder type={'noprojectsfound'} />}

            {!isPlaceholderVisible && <ProjectsGrid />}
        </>
    );
}
