import classNames from 'classnames';
import { memo, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FreeMode, Mousewheel, Navigation, Virtual } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/mousewheel';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AppDispatch, useSelector } from '../../../store';
import { ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { indexOfSelectedCameraSelector, selectedCameraSelector } from '../../../store/selectors';
import { setSelectedObject } from '../../../store/sharedActions';
import { ExtendedCamera } from '../../../store/slices/cameras';
import { setRulerToolEnabled, setSelectedCamera } from '../../../store/slices/projectView';
import CameraImage from '../../Elements/camera-image/CameraImage';
import FlyToTilesetsContext from '../../../contexts/FlyToTilesetsContext';

export const VIEWER_THUMB_SIZE = 117; // px
const INSPECTOR_THUMB_SIZE = 170; // px

type Props = {
    type: 'viewer' | 'inspector';
    containerWidth: number | undefined;
    cameras: (ExtendedCamera & { issueId?: string })[];
    triggerFlyToOnSelect?: boolean;
};

export function NavigationCarousel({ type, containerWidth, cameras, triggerFlyToOnSelect }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const [swiper, setSwiper] = useState<any>();
    const { setClickedTilesetIds } = useContext(FlyToTilesetsContext);
    const galleryMode = useSelector(state => state.projectView.galleryVisibility.mode);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const allCameras = useSelector(state => state.cameras);
    const selectedCamera = useSelector(state => selectedCameraSelector(state));
    const isCamerasInspectionEnabled = useSelector(state => state.projectView.isCamerasInspectionEnabled);
    const indexOfSelectedCamera = useSelector(state => indexOfSelectedCameraSelector(state));

    const thumbSize = type === 'inspector' ? INSPECTOR_THUMB_SIZE : VIEWER_THUMB_SIZE;
    const carouselThumbnailParam = {
        width: thumbSize,
        height: thumbSize,
        mode: 'FILL'
    };
    const numberOfFullSlides = Math.floor((containerWidth || 0) / thumbSize);
    const hasInsufficientSlides = cameras.length <= numberOfFullSlides;

    useEffect(() => {
        if (swiper && indexOfSelectedCamera !== -1) {
            swiper.slideTo(indexOfSelectedCamera);
        }
    }, [swiper, indexOfSelectedCamera]);

    return (
        <Swiper
            className='swiper-nav'
            style={{
                display: cameras.length ? 'block' : 'none' // No conditional rendering for swiper to avoid unwanted behavior
            }}
            onSwiper={sw => {
                setSwiper(sw);
            }}
            spaceBetween={2}
            initialSlide={indexOfSelectedCamera}
            slidesPerView={numberOfFullSlides}
            modules={[Virtual, Navigation, FreeMode, Mousewheel]}
            virtual={{ enabled: true, addSlidesAfter: 3, addSlidesBefore: 3 }}
            slideToClickedSlide={false}
            normalizeSlideIndex={false}
            threshold={5} // 5px threshold on swiping to avoid annoying swipe while fast-selecting items
            navigation={!hasInsufficientSlides}
            centerInsufficientSlides={hasInsufficientSlides}
            mousewheel={{ sensitivity: 2.5 }}
            freeMode
            speed={500}
        >
            {cameras.map((camera, index) => {
                const active =
                    galleryMode === 'cameras'
                        ? camera.uid === selectedCamera?.uid
                        : selectedObject?.artifactId === camera.issueId;

                return (
                    <SwiperSlide
                        key={camera.uid ?? index}
                        data-testid={'swiperSlide'}
                        virtualIndex={index}
                        className={classNames('thumb', { active })}
                        onClick={e => {
                            const artifactUid = Object.keys(allCameras).find(key =>
                                allCameras[key].find(c => c.uid === camera.uid)
                            )!;
                            dispatch(
                                setSelectedCamera({
                                    artifactUid,
                                    uid: camera.uid,
                                    selectInWorkspace: !isCamerasInspectionEnabled && galleryMode === 'cameras'
                                })
                            );
                            dispatch(setRulerToolEnabled(false));

                            if (triggerFlyToOnSelect) {
                                setClickedTilesetIds({
                                    ids: camera.uid || '',
                                    objectType: ProjectStructureObjectTypes.IMAGE
                                });
                            }

                            if (galleryMode === 'issues') {
                                dispatch(
                                    setSelectedObject({
                                        type: ProjectStructureObjectTypes.GEOMETRY,
                                        artifactId: camera.issueId
                                    })
                                );
                            }
                        }}
                    >
                        <CameraImage
                            camera={camera}
                            cameraType='swiper'
                            thumbnail={carouselThumbnailParam}
                            showIssueId={camera.issueId}
                        />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
}

export default memo(NavigationCarousel);
