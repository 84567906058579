import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { FixedSizeTree } from 'react-vtree';
import { AppDispatch, useSelector } from '../../../store';
import { ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { layerWithSelectedGeometrySelector } from '../../../store/selectors';
import { setSelectedObject } from '../../../store/sharedActions';
import { makeSelectCameraArtifactId } from '../../../store/slices/cameras';
import { selectObjectExpansion, updateStructureInfo } from '../../../store/slices/structure';

export default function useAutoScrollOnSelect(treeRef: React.MutableRefObject<FixedSizeTree>) {
    const dispatch: AppDispatch = useDispatch();
    const projectInfo = useSelector(state => state.project.projectInfo);
    const selectCameraArtifactId = useMemo(makeSelectCameraArtifactId, []);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const selectedCameraArtifactId = useSelector(state =>
        selectCameraArtifactId(state, selectedObject?.artifactId || '')
    );
    const parentLayer = useSelector(state => layerWithSelectedGeometrySelector(state));
    const isParentLayerExpanded = useSelector(state => selectObjectExpansion(state, parentLayer?.id!));
    const isSelectedCameraParentExpanded = useSelector(state =>
        selectObjectExpansion(state, selectedCameraArtifactId!)
    );
    const isSidebarExpanded = useSelector(state => state.projectView.isSidebarExpanded);

    useEffect(() => {
        if (!isSidebarExpanded || !selectedObject?.needToScroll || !treeRef.current) return;

        if (selectedObject.type === ProjectStructureObjectTypes.DATASET) {
            treeRef.current.scrollToItem(selectedObject.artifactId!, 'smart');
        }

        if (selectedObject.type === ProjectStructureObjectTypes.GEOMETRY) {
            if (parentLayer && !isParentLayerExpanded) {
                expandParent(parentLayer.id, ProjectStructureObjectTypes.LAYER);
                return;
            }
            treeRef.current.scrollToItem(selectedObject.artifactId!, 'smart');
            turnOffNeedToScroll(ProjectStructureObjectTypes.GEOMETRY);
        }

        if (selectedObject.type === ProjectStructureObjectTypes.IMAGE) {
            if (selectedCameraArtifactId && !isSelectedCameraParentExpanded) {
                expandParent(selectedCameraArtifactId, ProjectStructureObjectTypes.CAMERAS);
                return;
            }
            treeRef.current.scrollToItem(selectedObject.artifactId!, 'smart');
            turnOffNeedToScroll(ProjectStructureObjectTypes.IMAGE);
        }

        function turnOffNeedToScroll(type: ProjectStructureObjectTypes) {
            dispatch(setSelectedObject({ artifactId: selectedObject.artifactId, type, needToScroll: false }));
        }

        function expandParent(id: string, type: ProjectStructureObjectTypes): void {
            dispatch(
                updateStructureInfo({
                    projectId: projectInfo.id!,
                    structureUid: id,
                    type,
                    propValue: true.toString(),
                    propName: 'expanded'
                })
            );
        }
    }, [
        selectedObject,
        isSelectedCameraParentExpanded,
        parentLayer,
        isParentLayerExpanded,
        isSidebarExpanded,
        dispatch,
        projectInfo.id,
        treeRef,
        selectedCameraArtifactId
    ]);
}
