import classNames from 'classnames';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useGetElementDimensions from '../../../hooks/useGetElementDimensions';
import { EMPTY_ARRAY } from '../../../sharedConstants';
import { useSelector } from '../../../store';
import { createLoadingSelector } from '../../../store/selectors/createLoadingSelector';
import { filterCamerasByPoint, selectCamerasFilteredByPoint } from '../../../store/slices/cameras';
import NavigationCarousel from '../navigation-carousel/NavigationCarousel';

const selectIsFiltering = createLoadingSelector([filterCamerasByPoint.typePrefix]);

export const MIN_INSPECTION_WIDTH = 240; // (px)
const CAROUSEL_PADDING = 8; // = 2 * (1px border + 3px padding) - отступы в контейнере карусели, см. в файле стилей

export default function InspectOverlay() {
    const { t } = useTranslation('projectView');
    const camerasFilteredByPoint = useSelector(selectCamerasFilteredByPoint);
    const filteringPoint = useSelector(state => state.projectView.cameraFilteringPoint);
    const isFiltering = useSelector(selectIsFiltering);

    const inspectOverlayRef = useRef<HTMLDivElement>(null);
    const inspectOverlayDimensions = useGetElementDimensions(inspectOverlayRef);

    const showElements = (inspectOverlayDimensions.width || 0) >= MIN_INSPECTION_WIDTH;
    const carouselContainerWidth = (inspectOverlayDimensions.width || 0) - CAROUSEL_PADDING;

    return (
        <div ref={inspectOverlayRef} className='inspect-overlay'>
            <div
                className={classNames('inspect-carousel', {
                    'show-hint': !camerasFilteredByPoint.length || !showElements
                })}
            >
                {!showElements && (
                    <div className='carousel-hint'>
                        <i className='icon icon-inspector icon-small' />
                    </div>
                )}
                {showElements && isFiltering && (
                    <div className='carousel-hint'>
                        <i className='icon icon-inspector' />
                        <div className='loader' />
                        <div className='hint-text'>{t('inspectionSidebar.imagesInspector.loader_inspection')}</div>
                    </div>
                )}
                {showElements && !isFiltering && (
                    <>
                        <NavigationCarousel
                            triggerFlyToOnSelect
                            type='inspector'
                            containerWidth={carouselContainerWidth}
                            cameras={camerasFilteredByPoint || EMPTY_ARRAY}
                        />
                        {!camerasFilteredByPoint.length && (
                            <div className='carousel-hint'>
                                <i className='icon icon-inspector' />
                                <div className='hint-text'>
                                    {filteringPoint
                                        ? t('inspectionSidebar.imagesInspector.tip_noInspectionResults')
                                        : t('inspectionSidebar.imagesInspector.tip_noClickedPoint')}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
