import { ReactNode, SyntheticEvent } from 'react';
import Modal from '../../modal/Modal';
import ModalHead from '../../modal/ModalHead';
import ModalBody from '../../modal/ModalBody';
import ModalActions from '../../modal/ModalActions';

type Props = {
    isOpen: boolean;
    setIsOpen: () => void;
    onAction: (event: SyntheticEvent) => void;
    onCancel: (event: SyntheticEvent) => void;
    headerText: string;
    areYouSureText: ReactNode | string;
    actionButtonText: string;
    actionButtonStyle?: keyof typeof modalButtonsStyle;
    cancelButtonText: string;
    cancelButtonStyle?: keyof typeof modalButtonsStyle;
};

const modalButtonsStyle = {
    cancel: 'btn-cancel',
    cancelBlue: 'btn-ghost-blue',
    actionAlert: 'btn-alert',
    actionConfirm: 'btn'
};

const ProjectActionModal = (props: Props) => {
    return (
        <Modal isOpen={props.isOpen} setIsOpen={e => props.setIsOpen()}>
            <ModalHead setIsOpen={e => props.setIsOpen()}>{props.headerText}</ModalHead>
            <ModalBody>
                <div>{props.areYouSureText}</div>
                <ModalActions>
                    <button
                        className={modalButtonsStyle[props.cancelButtonStyle ? props.cancelButtonStyle : 'cancel']}
                        type='button'
                        onClick={event => {
                            event.stopPropagation();
                            event.nativeEvent.stopImmediatePropagation();
                            props.onCancel(event);
                        }}
                    >
                        {props.cancelButtonText}
                    </button>

                    <button
                        className={modalButtonsStyle[props.actionButtonStyle ? props.actionButtonStyle : 'actionAlert']}
                        type='button'
                        onClick={event => {
                            event.stopPropagation();
                            event.nativeEvent.stopImmediatePropagation();
                            props.onAction(event);
                            props.onCancel(event);
                        }}
                    >
                        {props.actionButtonText}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
};

export default ProjectActionModal;
