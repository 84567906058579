import { createPortal } from 'react-dom';

type Props = {
    message: string;
    container?: Element | null;
    visible: boolean;
    close(): void;
};

export default function BottomConfirmMessage({ message, visible, close, container = document.body }: Props) {
    if (!visible || !container) return null;

    return createPortal(
        <div className='modal-message'>
            <div className='modal-message-text confirm-view'>
                <span>{message}</span>
                <div className='modal-message-close' data-testid={'closeIcon'} onClick={close}>
                    <i className='ico-x-12' />
                </div>
            </div>
        </div>,
        container
    );
}
