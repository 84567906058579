import { Chunk, SourceType } from '../generated/cloud-frontend-api';
import { ExtendedChunk } from '../store/helpers/interfaces';
import { ExtendedDatasetInfo } from '../store/slices/datasetfilesUpload';

export default function (datasets: ExtendedDatasetInfo[], chunk?: Chunk, label?: string) {
    return chunk?.artifacts?.find(a => {
        const dataset = datasets.find(d => d.datasetUid === a.datasetUid);
        return dataset?.sourceData?.type === SourceType.DEM_3_D && dataset?.name === label;
    });
}
