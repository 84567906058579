import * as Cesium from 'cesium';

export default function wgs84ToCartesian3(
    coordinates: number[] | number[][],
    scene: Cesium.Scene
): Cesium.Cartesian3 | Cesium.Cartesian3[] {
    if (isTwoDimensionalArray(coordinates)) return coordinates.map(c => convert(c));
    else return convert(coordinates);

    function convert(wgs84: number[]): Cesium.Cartesian3 {
        let cartesian;
        try {
            cartesian = Cesium.Cartesian3.fromDegrees(wgs84[0], wgs84[1], wgs84[2]);
        } catch (error) {
            const windowPosition = Cesium.SceneTransforms.wgs84ToWindowCoordinates(
                scene,
                Cesium.Cartesian3.fromDegrees(wgs84[0], wgs84[1])
            );
            const cartesianFromWindow = scene.pickPosition(windowPosition!);
            const height = Cesium.Cartographic.fromCartesian(cartesianFromWindow).height;
            cartesian = Cesium.Cartesian3.fromDegrees(wgs84[0], wgs84[1], height);
        }

        return cartesian;
    }
}

function isTwoDimensionalArray<T>(arr: T[] | T[][]): arr is T[][] {
    return (arr as []).every((a: T | T[]) => Array.isArray(a));
}
