import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import exportAsJson, { preProcessGeoJsonProperties } from '../../../lib/exportAsJson';
import { AppDispatch, useSelector } from '../../../store';
import { ProjectStructureObjectTypes, TemporaryGeometry, TemporaryLayer } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { ExtendedDatasetInfo, downloadDataset } from '../../../store/slices/datasetfilesUpload';
import { isLinkedDataset } from '../../../store/slices/datasets';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';

type Props = {
    geometries: any;
};

export default function ExportControl({ geometries }: Props) {
    const { t } = useTranslation('projectView');
    const dispatch: AppDispatch = useDispatch();
    const projectInfo = useSelector(state => state.project.projectInfo);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));

    return (
        <TippyTooltip tooltipText={t('inspectionSidebar.controls.tooltipDownload')}>
            <div
                className='control'
                data-testid={'exportControl'}
                onClick={e => {
                    let geoJson;
                    if (selectedObject.type === ProjectStructureObjectTypes.GEOMETRY) {
                        geoJson = (selectedObjectInfo as TemporaryGeometry).content;
                        exportAsJson(preProcessGeoJsonProperties(geoJson), geoJson?.properties?.ac_name || '');
                    }

                    if (selectedObject.type === ProjectStructureObjectTypes.LAYER) {
                        geoJson = {
                            type: 'FeatureCollection',
                            features: (selectedObjectInfo as TemporaryLayer).geometries.map(id =>
                                preProcessGeoJsonProperties(geometries[id]!.content)
                            )
                        } as const;
                        exportAsJson(geoJson, (selectedObjectInfo as TemporaryLayer).name);
                    }

                    if (selectedObject.type === ProjectStructureObjectTypes.DATASET) {
                        const dataset = selectedObjectInfo as ExtendedDatasetInfo;

                        dispatch(
                            downloadDataset({
                                projectId: isLinkedDataset(dataset, projectInfo.id!)
                                    ? dataset.parentProject?.uid!
                                    : projectInfo.id!,
                                datasetUid: dataset.datasetUid || ''
                            })
                        );
                    }
                }}
            >
                <i className='icon icon-export' />
            </div>
        </TippyTooltip>
    );
}
