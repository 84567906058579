import { useTranslation } from 'react-i18next';
import { StorageProductGradeEnum } from '../../../../generated/billing-api';
import { PlanType } from '../../../../generated/account-frontend-api';

type Props = {
    isCommercial: boolean;
    onlyHours?: boolean;
    isOverdue?: boolean;
    storageProductGrade?: StorageProductGradeEnum;
    scheduledPlanType?: PlanType;
    totalPrice: number;
};

export default function TotalSumText({
    isCommercial,
    onlyHours,
    isOverdue,
    storageProductGrade,
    scheduledPlanType,
    totalPrice
}: Props) {
    const { t } = useTranslation(['subscribe']);

    let text = '';

    const total = scheduledPlanType === PlanType.FREE && !onlyHours ? '0.00' : totalPrice.toFixed(2);

    if (onlyHours) {
        text = t('subscribe:summary.total.total');
    } else if (isOverdue) {
        text = t('subscribe:summary.total.outstandingPayment');
    } else if (
        isCommercial &&
        (storageProductGrade === StorageProductGradeEnum.CURRENT ||
            storageProductGrade === StorageProductGradeEnum.DOWNGRADE ||
            storageProductGrade === StorageProductGradeEnum.SCHEDULED)
    ) {
        text = t('subscribe:summary.total.nextPayment');
    } else {
        text = t('subscribe:summary.total.total');
    }

    return `${text}: $${total}`;
}
