import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { array, number, object, string } from 'yup';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import i18n from '../../../i18n/config';
import trimNumberToNDecimalPoints from '../../../lib/trimNumberToNDecimalPoints';
import { floatPositiveRegex, GeometryTypes, Units } from '../../../sharedConstants';
import { useSelector } from '../../../store';
import { TemporaryGeometry } from '../../../store/helpers/interfaces';
import { AppDispatch } from '../../../store/index';
import { selectReadyDemFiles, selectedObjectInfoSelector } from '../../../store/selectors';
import {
    calculationAborted,
    calculationAdded,
    makeSelectElevationProfileById
} from '../../../store/slices/elevationProfiles';
import { ReactComponent as SvgClose } from '../../../svg/general/x-icon.svg';
import { ReactComponent as SvgSave } from '../../../svg/general/save.svg';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import { convertLengthUnit } from '../../../lib/convertUnit';
import { getUnitsShortName } from '../../../lib/getUnitsShortName';
import useGetElementDimensions from '../../../hooks/useGetElementDimensions';
import { Input, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

type Props = {
    setToolVisibility: (visibilityValue: boolean) => void;
};

interface InitialValues {
    step: string;
    surfaces: string[];
}

export const globalTerrainOption = () => ({
    id: 'globalTerrain',
    name: i18n.t('projectView:elevationProfile.tool.surfaces.option_globalTerrain')
});

export const stepPlaceholderText = '0.000';

const MAX_SURFACES = 5;

export default function ElevationProfileTool({ setToolVisibility }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView', 'common']);
    const toolRef = useRef<HTMLDivElement>(null);
    const { isInEmbedView } = useContext(ProjectViewAccessContext);
    const polyline = useSelector(state =>
        selectedObjectInfoSelector(state)
    ) as TemporaryGeometry<GeometryTypes.POLYLINE>;
    const selectElevationProfileById = useMemo(makeSelectElevationProfileById, []);
    const elevationProfile = useSelector(state => selectElevationProfileById(state, polyline.id));
    const units = useSelector(state => state.coordinateSystems.units);
    const dems = useSelector(state => selectReadyDemFiles(state));
    const unitsShortName = getUnitsShortName(units);

    const dropdownWidth = useGetElementDimensions(toolRef).width;

    const polylineLength = polyline.content.properties.ac_horizontal_length_meters;

    const validationSchema = useMemo(
        () =>
            object({
                step: number()
                    .typeError(t('common:invalidValue'))
                    .required('')
                    .moreThan(0, t('projectView:elevationProfile.tool.step.error_gtnZero')),
                surfaces: array().of(string()).required().min(1).max(5)
            }),
        [t]
    );

    const errorMessages = useMemo(
        () => ({
            stepTooLarge: t('projectView:elevationProfile.tool.step.error_tooLarge')
        }),
        [t]
    );

    const initialValues: InitialValues = {
        step: elevationProfile
            ? String(Number(convertLengthUnit(elevationProfile?.step, Units.METRE, units).toFixed(3)))
            : '',
        surfaces: elevationProfile?.surfaceIds
            ? [...dems, globalTerrainOption()].filter(d => elevationProfile.surfaceIds.includes(d.id)).map(d => d.id)
            : []
    };

    const abortPrevCalculation = () => {
        if (elevationProfile?.status === 'new' || elevationProfile?.status === 'pending') {
            dispatch(calculationAborted(polyline.id));
        }
    };

    return (
        <div
            className={classNames('inspector-tool', { 'theme-dark': isInEmbedView })}
            data-testid={'elevationProfileTool'}
            ref={toolRef}
        >
            <div className='tool-header'>
                <div className='tool-title'>
                    <span>{t('projectView:elevationProfile.tool.title')}</span>
                </div>
                <div
                    className='tool-close'
                    onClick={() => {
                        setToolVisibility(false);
                    }}
                >
                    <SvgClose />
                </div>
            </div>
            <div className='tool-body'>
                <Formik
                    initialValues={initialValues}
                    validateOnMount
                    validationSchema={validationSchema}
                    onSubmit={values => {
                        abortPrevCalculation();
                        dispatch(
                            calculationAdded({
                                id: polyline.id,
                                surfaceIds: values.surfaces,
                                status: 'new',
                                step: convertLengthUnit(parseFloat(values.step), units, Units.METRE),
                                result: { altitudes: [], distances: [], points: [] }
                            })
                        );
                        setToolVisibility(false);
                    }}
                >
                    {({ values, errors, setFieldValue }) => (
                        <Form>
                            <div className='tool-fields tool-fields-surface'>
                                <div className='field-title'>
                                    {t('projectView:elevationProfile.tool.surfaces.label')}
                                </div>
                                <div className='field-input'>
                                    <Field
                                        as={Select}
                                        value={values.surfaces}
                                        onChange={(newValues: string[]) => {
                                            setFieldValue('surfaces', newValues);
                                        }}
                                        mode='multiple'
                                        placeholder={t('projectView:elevationProfile.tool.surfaces.placeholder')}
                                        getPopupContainer={() => toolRef.current!}
                                        showSearch
                                        filterOption={(inputValue: string, option: DefaultOptionType | undefined) => {
                                            return Boolean(
                                                option?.children &&
                                                    option.children
                                                        .toString()
                                                        .toLowerCase()
                                                        .indexOf(inputValue.toLowerCase()) >= 0
                                            );
                                        }}
                                        notFoundContent={false}
                                        placement='bottomRight'
                                        popupMatchSelectWidth={dropdownWidth ? dropdownWidth : true}
                                        className={classNames({ 'theme-dark': isInEmbedView })}
                                        popupClassName={classNames({ 'theme-dark': isInEmbedView })}
                                    >
                                        <Select.Option
                                            value={globalTerrainOption().id}
                                            disabled={
                                                values.surfaces.length === MAX_SURFACES &&
                                                !values.surfaces.includes(globalTerrainOption().id)
                                            }
                                        >
                                            {globalTerrainOption().name}
                                        </Select.Option>
                                        {dems.map(d => (
                                            <Select.Option
                                                key={d.id}
                                                value={d.id}
                                                disabled={
                                                    values.surfaces.length === MAX_SURFACES &&
                                                    !values.surfaces.includes(d.id)
                                                }
                                            >
                                                {d.name}
                                            </Select.Option>
                                        ))}
                                    </Field>
                                </div>
                                <div className='field-title'>
                                    {t('projectView:elevationProfile.tool.step.label', { units: unitsShortName })}
                                </div>
                                <TippyTooltip
                                    tooltipText={errors.step || ''}
                                    visible={Boolean(errors.step)}
                                    touch={true}
                                >
                                    <div className='field-input'>
                                        <Field
                                            as={Input}
                                            name={'step'}
                                            autoComplete={'off'}
                                            className={classNames('input-small', {
                                                'input-theme-dark': isInEmbedView,
                                                'input-error': Boolean(errors.step)
                                            })}
                                            placeholder={stepPlaceholderText}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const value = e.currentTarget.value;
                                                if (!floatPositiveRegex.test(value)) {
                                                    setFieldValue('step', values.step);
                                                    return;
                                                }
                                                const trimmed = trimNumberToNDecimalPoints(value, 3);
                                                setFieldValue('step', trimmed);
                                            }}
                                            validate={(value: string) => {
                                                if (
                                                    polylineLength &&
                                                    !isNaN(parseFloat(value)) &&
                                                    convertLengthUnit(parseFloat(value), units, Units.METRE) >
                                                        polylineLength
                                                )
                                                    return errorMessages.stepTooLarge;
                                            }}
                                        />
                                    </div>
                                </TippyTooltip>
                            </div>
                            <div className='tool-actions'>
                                <TippyTooltip tooltipText={t('projectView:elevationProfile.controls.tooltipCalculate')}>
                                    <button className='tool-action' type='submit' disabled={!_.isEmpty(errors)}>
                                        <SvgSave />
                                    </button>
                                </TippyTooltip>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
