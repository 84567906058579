import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ProjectsPlaceholder from '../../components/Projects/ProjectsPlaceholder';
import ProjectsGrid from '../../components/projects-grid/ProjectsGrid';
import { AppDispatch, useSelector } from '../../store';
import { createLoadingSelector } from '../../store/selectors/createLoadingSelector';
import { firstProgressTick } from '../../store/slices/progress';
import { resetProjects, selectProjects, setIsLoading } from '../../store/slices/projectsPage';
import { useHistory, generatePath, useParams } from 'react-router-dom';
import { getFolder } from '../../store/slices/folder';
import { Routes } from '../../sharedConstants';
import { ProjectType } from '../../generated/cloud-frontend-api';

const loadingSelector = createLoadingSelector([firstProgressTick.typePrefix]);

export interface FolderRouteParams {
    folderId?: string;
}

export default function Folder() {
    const dispatch: AppDispatch = useDispatch();
    const history = useHistory();
    const { folderId } = useParams<FolderRouteParams>();
    const isOnline = useSelector(state => state.globalFlags.isOnline);
    const page = useSelector(state => state.projectsPage);
    const isLoading = useSelector(state => loadingSelector(state) || state.projectsPage.isLoading);
    const projects = useSelector(selectProjects);
    const notFoundBySearch = !projects.length && !isLoading && !!page.searchText.length;
    const isPlaceholderVisible = !isOnline || notFoundBySearch;

    useEffect(() => {
        dispatch(setIsLoading(true));

        if (folderId) {
            fetchFolder(folderId);
        }
        async function fetchFolder(id: string) {
            const result = await dispatch(getFolder({ id })).unwrap();
            if (result.projectInfo?.type !== ProjectType.FOLDER) {
                history.replace(generatePath(Routes.NOT_FOUND));
            }
        }

        return () => {
            dispatch(resetProjects());
        };
    }, [dispatch, folderId, history]);

    return (
        <>
            {!isOnline && <ProjectsPlaceholder type={'offline'} />}

            {notFoundBySearch && isOnline && <ProjectsPlaceholder type={'noprojectsfound'} />}

            {!isPlaceholderVisible && <ProjectsGrid key={folderId} />}
        </>
    );
}
