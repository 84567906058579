import * as Cesium from 'cesium';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useCesium } from 'resium';
import { Viewpoint } from '../../../../entities/Viewpoint';
import { EMPTY_ARRAY } from '../../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../../store';
import { PointOfView, ProjectStructureObjectTypes, SelectedObject } from '../../../../store/helpers/interfaces';
import { selectGeometries } from '../../../../store/slices/geometries';
import { makeSelectLayerByGeometryId } from '../../../../store/slices/geometryLayers';
import { setTourPlayerState } from '../../../../store/slices/projectView';
import { isEqual } from 'lodash';
import { selectSelectedPresentationSlide } from '../../../../store/slices/presentation';
import { setSelectedObject } from '../../../../store/sharedActions';

export const FLY_DURATION_SECONDS = 5;

export function TourPlaying() {
    const dispatch: AppDispatch = useDispatch();
    const flyTimeout = useRef(0);
    const hasStartedThePlaying = useRef(false);
    const { camera } = useCesium();
    const geometries = useSelector(state => selectGeometries(state));
    const selectedViewpoint = useSelector(state => selectSelectedPresentationSlide(state));
    const selectedViewpointId = selectedViewpoint?.id ?? '';
    const currentlyPlayingViewpoint = geometries[selectedViewpointId];
    const selectLayerByGeometryId = useMemo(makeSelectLayerByGeometryId, []);
    const selectedTour = useSelector(state => selectLayerByGeometryId(state, currentlyPlayingViewpoint?.id!));
    const selectedTourViewpoints = selectedTour?.geometries ?? EMPTY_ARRAY;

    useEffect(() => {
        if (!hasStartedThePlaying.current) {
            hasStartedThePlaying.current = true;
            flyToViewpoint(selectedViewpointId);
        }

        function flyToViewpoint(viewpointId: string) {
            const viewpoint = geometries[viewpointId];

            const pointOfView = (viewpoint?.content.properties.ac_point_of_view ??
                viewpoint?.content.properties.pointOfView) as PointOfView<number> | undefined;
            const durationSeconds =
                (viewpoint?.content.properties.ac_duration as number | undefined) ?? Viewpoint.DEFAULT_DURATION_SECONDS;
            const cartesianPointOfView = pointOfView
                ? {
                      destination: new Cesium.Cartesian3(...pointOfView.destination),
                      orientation: {
                          direction: new Cesium.Cartesian3(...pointOfView.orientation.direction),
                          up: new Cesium.Cartesian3(...pointOfView.orientation.up)
                      }
                  }
                : null;

            if (cartesianPointOfView && durationSeconds) {
                const beforeFlight = Date.now();
                camera?.flyTo({
                    destination: cartesianPointOfView.destination,
                    orientation: cartesianPointOfView.orientation,
                    duration: FLY_DURATION_SECONDS,
                    cancel() {
                        dispatch(setTourPlayerState('pause'));
                    },
                    complete() {
                        // Actual flight time may be shorter, so we need to account for it
                        const afterFlight = Date.now();
                        let nextFlightTimeout = durationSeconds * 1000;
                        const actualFlightDurationSeconds = (afterFlight - beforeFlight) * 1000;
                        if (actualFlightDurationSeconds < FLY_DURATION_SECONDS)
                            nextFlightTimeout += (FLY_DURATION_SECONDS - actualFlightDurationSeconds) * 1000;

                        const indexOfViewpoint = selectedTourViewpoints.indexOf(viewpointId);
                        const indexOfNextViewpoint = (indexOfViewpoint + 1) % selectedTourViewpoints.length;
                        const idOfNextViewpoint = selectedTourViewpoints[indexOfNextViewpoint];

                        flyTimeout.current = window.setTimeout(async () => {
                            await dispatch(setSelectedObject({} as SelectedObject));
                            dispatch(
                                setSelectedObject({
                                    type: ProjectStructureObjectTypes.GEOMETRY,
                                    artifactId: idOfNextViewpoint,
                                    needToScroll: true
                                })
                            );
                            flyToViewpoint(idOfNextViewpoint);
                        }, nextFlightTimeout);
                    }
                });
            }
        }
    }, [camera, dispatch, geometries, selectedTourViewpoints, selectedViewpointId]);

    useEffect(() => {
        document.addEventListener('keydown', stopPlayingOnCertainKeys);
        document.addEventListener('mousedown', stopPlaying);
        document.addEventListener('contextmenu', stopPlaying);
        document.addEventListener('wheel', stopPlaying);

        return () => {
            document.removeEventListener('keydown', stopPlayingOnCertainKeys);
            document.removeEventListener('mousedown', stopPlaying);
            document.removeEventListener('contextmenu', stopPlaying);
            document.removeEventListener('wheel', stopPlaying);
        };

        function stopPlayingOnCertainKeys(e: KeyboardEvent) {
            const keyCodes = ['KeyW', 'KeyS', 'KeyA', 'KeyD', 'KeyQ', 'KeyE', 'Delete'];
            if (keyCodes.includes(e.code)) {
                stopPlaying();
            }
        }

        function stopPlaying() {
            dispatch(setTourPlayerState('pause'));
        }
    }, [dispatch]);

    useEffect(() => {
        return () => {
            camera?.cancelFlight();
        };
    }, [camera]);

    useEffect(() => {
        return () => {
            window.clearTimeout(flyTimeout.current);
        };
    }, []);

    return null;
}
