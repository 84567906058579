import React from 'react';

type Props = {
    checked: boolean;
    disabled?: boolean;
    onChange(): void;
};

export default function SwitchControl({ checked, disabled, onChange }: Props) {
    return (
        <label className='switch-wrapper'>
            <input
                type='checkbox'
                data-testid={'switch'}
                checked={checked}
                disabled={disabled}
                onChange={e => {
                    onChange();
                }}
            />
            <span className='switch-styler' />
        </label>
    );
}
