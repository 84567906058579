import TippyTooltipTimed from '../../Elements/tippy-tooltip/TippyTooltipTimed';
import { useTranslation } from 'react-i18next';

export default function DisabledItemControl() {
    const { t } = useTranslation('projectView');

    return (
        <TippyTooltipTimed tooltipText={t('compareMode.visualizationNotAvailable')}>
            <span
                className='control control-inactive'
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <i className='icon icon-invisible' />
            </span>
        </TippyTooltipTimed>
    );
}
