import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../store';
import { createLoadingSelector } from '../../store/selectors/createLoadingSelector';
import { getInvoices, setInvoicesPageNumber } from '../../store/slices/invoices';

import Pagination from '../../components/Elements/pagination/Pagination';
import InvoicesSkeleton from './components/InvoicesSkeleton';
import InvoicesTable from './components/InvoicesTable';

import styles from './Invoices.module.scss';

const loadingSelector = createLoadingSelector([getInvoices.typePrefix]);

export default function InvoicesPage() {
    const { t } = useTranslation(['cabinet']);
    const dispatch: AppDispatch = useDispatch();

    const invoicesPage = useSelector(state => state.invoices.page);
    const pageNumber = useSelector(state => state.invoices.pageNumber);
    const sort = useSelector(state => state.invoices.sort);
    const isLoading = useSelector(state => loadingSelector(state));

    useEffect(() => {
        let isInvoicesPage = true;
        dispatch(getInvoices({ page: pageNumber, sort: sort }))
            .then(unwrapResult)
            .then(page => {
                if (isInvoicesPage) {
                    const wrapper = document.getElementById('wrapper');
                    if (page.total_elements === 0) wrapper?.classList.add('full-height');
                    else wrapper?.classList.remove('full-height');
                }
            });
        return () => {
            isInvoicesPage = false;
        };
    }, [pageNumber, sort, dispatch]);

    return (
        <div className={styles.contentWrapper}>
            <div className={styles.contentSection}>
                <h2 className={styles.sectionTitle}>{t('cabinet:invoices.invoicesTitle')}</h2>
                {isLoading ? (
                    <InvoicesSkeleton />
                ) : invoicesPage.total_elements || 0 >= 1 ? (
                    <>
                        <InvoicesTable />
                        <Pagination
                            page={pageNumber}
                            pageCount={invoicesPage.total_pages || 0}
                            onPageChange={({ selected }) => {
                                dispatch(setInvoicesPageNumber(selected));
                            }}
                        />
                    </>
                ) : (
                    <div>
                        <Trans ns={'cabinet'} i18nKey={'invoices.emptyPlaceholder'} shouldUnescape />
                    </div>
                )}
            </div>
        </div>
    );
}
