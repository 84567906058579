import { Artifact, SourceType } from '../generated/cloud-frontend-api/model';
import _ from 'lodash';
import { DatasetInfo } from '../generated/dataset-api';

function sortArtifacts(artifacts: Artifact[], datasets: DatasetInfo[]) {
    let newArtifacts = [...artifacts];
    newArtifacts.forEach(a => {
        const dataset = datasets.find(d => d.datasetUid === a.datasetUid);
        (a as any).order = mapOrderToArtifactType(dataset?.sourceData?.type!);
    });

    newArtifacts = _.orderBy(artifacts as any, 'order');
    newArtifacts.forEach(a => {
        delete (a as any).order;
    });

    return newArtifacts;
}

function mapOrderToArtifactType(type: SourceType) {
    switch (type) {
        case SourceType.TIE_POINTS:
            return 1;
        case SourceType.POINT_CLOUD:
            return 2;
        case SourceType.MODEL_3_D:
            return 3;
        case SourceType.TILED_MODEL:
            return 4;
        case SourceType.DEM:
        case SourceType.DEM_3_D:
            return 5;
        case SourceType.RASTER_MAP:
            return 6;
        default:
            return 7;
    }
}

export default sortArtifacts;
