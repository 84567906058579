import classNames from 'classnames';
import { Select } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { QualityOf3DModes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { projectViewActions } from '../../../store/slices/projectView';

import styles from './MapSettings.module.scss';

type Props = {
    containerRef: HTMLElement;
};

export default function QualitySwitch({ containerRef }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const quality3DMode = useSelector(state => state.projectView.quality3DMode);
    const { isInEmbedView } = useContext(ProjectViewAccessContext);

    function getQualityMode(value: string) {
        switch (value) {
            case '0':
                return QualityOf3DModes.LOW;
            case '1':
                return QualityOf3DModes.MEDIUM;
            case '2':
                return QualityOf3DModes.HIGH;
            default:
                return QualityOf3DModes.LOW;
        }
    }

    return (
        <div>
            <div className={styles.settingsItemTitle}>{t('projectView:mapControls.3dquality.title')}</div>
            <Select
                defaultValue={quality3DMode}
                getPopupContainer={() => containerRef}
                onChange={newValue => {
                    dispatch(projectViewActions.setQuality3DMode(getQualityMode(String(newValue))));
                }}
                onClick={e => {
                    e.stopPropagation();
                }}
                className={classNames('ant-select-small', { 'theme-dark': isInEmbedView })}
                popupClassName={classNames('ant-dropdown-small', { 'theme-dark': isInEmbedView })}
                options={[
                    {
                        value: 0,
                        label: t('projectView:mapControls.3dquality.option_low')
                    },
                    {
                        value: 1,
                        label: t('projectView:mapControls.3dquality.option_medium')
                    },
                    {
                        value: 2,
                        label: t('projectView:mapControls.3dquality.option_high')
                    }
                ]}
            />
        </div>
    );
}
