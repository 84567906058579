import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectInfo } from '../../../../generated/cloud-frontend-api/model';
import TippyDropdownItemWithIcon from '../../tippy-dropdown/TippyDropdownItemWithIcon';
import ShareModal from '../../share-modal/ShareModal';

type Props = {
    project: ProjectInfo;
};

export default function ShareProjectAction({ project }: Props) {
    const { t } = useTranslation(['projects']);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <TippyDropdownItemWithIcon
                icon={<i className='icon icon-share' />}
                text={t('projects:projectActions.share')}
                onClick={event => {
                    setIsOpen(true);
                }}
                data-testid='shareAction'
            />

            <ShareModal isOpen={isOpen} setIsOpen={setIsOpen} project={project} />
        </>
    );
}
