import Tippy from '@tippyjs/react/headless';
import classNames from 'classnames';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { v4 } from 'uuid';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import useGetPublishState from '../../../hooks/useGetPublishState';
import { Routes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { setDatasetsUploadModalOpen } from '../../../store/slices/datasetsUpload';
import { beginLayer, selectInspections, selectTours } from '../../../store/slices/geometryLayers';
import { getNextStructureOrder, putStructure, selectGroups } from '../../../store/slices/structure';
import { ReactComponent as SvgGroup } from '../../../svg/dropdown_actions/group.svg';
import { ReactComponent as SvgLayer } from '../../../svg/dropdown_actions/layer.svg';
import { ReactComponent as SvgLink } from '../../../svg/dropdown_actions/link.svg';
import PublishPaymentOverdueModal from '../../Elements/modals/PublishPaymentOverdueModal';
import PublishStorageIsFullModal from '../../Elements/modals/PublishStorageIsFullModal';
import TippyDropdownItemWithIcon from '../../Elements/tippy-dropdown/TippyDropdownItemWithIcon';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import useDrawing from '../../ProjectView/geometry-drawing/useDrawing';
import LinkModal from '../../Elements/modals/link-modal/LinkModal';
import useIsOwnedEmptySite from '../../../hooks/useIsOwnedEmptySite';
import { selectIsAnyToolEnabled } from '../../../store/slices/projectView';

export default function WorkspaceControls() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const location = useLocation();
    const { isInEmbedView, owned } = useContext(ProjectViewAccessContext);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const inspections = useSelector(selectInspections);
    const tours = useSelector(selectTours);
    const structures = useSelector(state => state.structure.structures);
    const groups = useSelector(state => selectGroups(state));
    const projectInfo = useSelector(state => state.project.projectInfo);
    const isModalOpen = useSelector(state => state.datasetsUpload.isModalOpen);
    const isCamerasInspectionEnabled = useSelector(state => state.projectView.isCamerasInspectionEnabled);
    const isPresentationSetupEnabled = useSelector(state => state.projectView.isPresentationSetupEnabled);
    const isAnyToolEnabled = useSelector(state => selectIsAnyToolEnabled(state));
    const [isLinkModalOpen, setLinkModalOpen] = useState(false);
    const { getNewLayerUid, getNewLayerProperties } = useDrawing();
    const isOwnedEmptySite = useIsOwnedEmptySite();

    const hasFullMenu =
        owned && Boolean(matchPath(location.pathname, { path: [Routes.PROJECT_VIEW, Routes.SITE_VIEW], exact: true }));
    const publishState = useGetPublishState();

    const [showPaymentOverdueModal, setShowPaymentOverdueModal] = useState(false);
    const [showStorageIsFullModal, setShowStorageIsFullModal] = useState(false);

    if (isOwnedEmptySite) return null;

    return (
        <>
            <div className='project-controls'>
                {!isAnyToolEnabled && (
                    <TippyTooltip tooltipText={t('structureSidebar.controls.tooltipUpload')}>
                        <div
                            className={classNames('control', { active: isModalOpen })}
                            onClick={e => {
                                switch (publishState) {
                                    case 'overdue':
                                        setShowPaymentOverdueModal(true);
                                        break;
                                    case 'storageFull':
                                        setShowStorageIsFullModal(true);
                                        break;
                                    default:
                                        dispatch(setDatasetsUploadModalOpen(true));
                                }
                            }}
                            data-testid={'importControl'}
                        >
                            <i className='icon icon-import' />
                        </div>
                    </TippyTooltip>
                )}

                {hasFullMenu ? (
                    <TippyTooltip tooltipText={t('structureSidebar.controls.tooltipAdd')}>
                        <Tippy
                            render={(attrs, content, instance) => (
                                <div
                                    className={classNames('tippy-dropdown', { 'theme-dark': isInEmbedView })}
                                    onClick={e => {
                                        instance?.hide();
                                    }}
                                    tabIndex={-1}
                                    {...attrs}
                                >
                                    {isCamerasInspectionEnabled && (
                                        <TippyDropdownItemWithIcon
                                            icon={<i className='icon icon-inspector' />}
                                            text={t('workspaceActions.createInspection')}
                                            onClick={e => {
                                                getNewLayerUid(getNewLayerProperties({ isInspection: true }));
                                            }}
                                        />
                                    )}

                                    {isPresentationSetupEnabled && (
                                        <TippyDropdownItemWithIcon
                                            icon={<i className='icon icon-presentation' />}
                                            text={t('workspaceActions.createTour')}
                                            onClick={e => {
                                                getNewLayerUid(getNewLayerProperties({ isPresentation: true }));
                                            }}
                                        />
                                    )}

                                    {!isAnyToolEnabled && (
                                        <TippyDropdownItemWithIcon
                                            icon={<SvgLayer />}
                                            text={t('workspaceActions.createLayer')}
                                            onClick={e => {
                                                getNewLayerUid(getNewLayerProperties());
                                            }}
                                        />
                                    )}
                                    <TippyDropdownItemWithIcon
                                        icon={<SvgGroup />}
                                        text={t('workspaceActions.createGroup')}
                                        onClick={e => {
                                            const uid = v4();
                                            const index = groups.length + 1;
                                            dispatch(
                                                putStructure({
                                                    projectId: projectInfo.id!,
                                                    type: ProjectStructureObjectTypes.GROUP,
                                                    structureInfo: {
                                                        uid: uid,
                                                        parentUid: projectInfo.id!,
                                                        properties: {
                                                            name: t('defaultNames.group', { index }),
                                                            nestingLevel: '1',
                                                            order: String(getNextStructureOrder(structures)),
                                                            visible: true.toString(),
                                                            expanded: true.toString()
                                                        }
                                                    }
                                                })
                                            );
                                        }}
                                    />
                                    {!isAnyToolEnabled && (
                                        <TippyDropdownItemWithIcon
                                            icon={<SvgLink />}
                                            text={t('workspaceActions.linkDataset')}
                                            onClick={e => {
                                                setLinkModalOpen(open => !open);
                                            }}
                                        />
                                    )}
                                    {isLinkModalOpen && (
                                        <LinkModal isOpen={isLinkModalOpen} setIsOpen={setLinkModalOpen} />
                                    )}
                                </div>
                            )}
                            delay={[100, 100]}
                            offset={[0, 8]}
                            interactive={true}
                            placement={'bottom'}
                            trigger={'click'}
                            hideOnClick
                            onShow={() => {
                                setIsDropdownOpen(true);
                            }}
                            onHide={() => {
                                setIsDropdownOpen(false);
                            }}
                        >
                            <div
                                className={classNames('control', { active: isDropdownOpen })}
                                data-testid={'addControl'}
                            >
                                <i className='icon icon-add' />
                            </div>
                        </Tippy>
                    </TippyTooltip>
                ) : (
                    <>
                        {!isAnyToolEnabled && (
                            <TippyTooltip tooltipText={t('structureSidebar.controls.tooltipAddLayer')}>
                                <div className='control' data-testid={'addLayerControl'}>
                                    <i
                                        className='icon icon-add'
                                        onClick={e => {
                                            getNewLayerUid(getNewLayerProperties());
                                        }}
                                    />
                                </div>
                            </TippyTooltip>
                        )}
                    </>
                )}
            </div>
            {showPaymentOverdueModal && (
                <PublishPaymentOverdueModal setIsOpen={setShowPaymentOverdueModal} isOpen={showPaymentOverdueModal} />
            )}
            {showStorageIsFullModal && (
                <PublishStorageIsFullModal setIsOpen={setShowStorageIsFullModal} isOpen={showStorageIsFullModal} />
            )}
        </>
    );
}
