import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSelector } from '../../../store';
import { InvoicesSort, setInvoicesPageNumber, setInvoicesSort } from '../../../store/slices/invoices';
import { useDispatch } from 'react-redux';
import InvoicesTableRow from './InvoicesTableRow';

import styles from '../Invoices.module.scss';

export default function InvoicesTable() {
    const dispatch = useDispatch();
    const { t } = useTranslation(['cabinet']);
    const invoicesPage = useSelector(state => state.invoices.page);
    const sort = useSelector(state => state.invoices.sort);

    return (
        <table className={styles.invoicesTable}>
            <thead>
                <tr>
                    <th className={styles.thSorting}>
                        <div
                            data-testid={'sortControl'}
                            className={styles.sortingParameter}
                            onClick={e => {
                                const nextSort: InvoicesSort = sort.includes('+payment_date')
                                    ? ['-payment_date']
                                    : ['+payment_date'];
                                dispatch(setInvoicesSort(nextSort));
                                dispatch(setInvoicesPageNumber(0));
                            }}
                        >
                            <span>{t('cabinet:invoices.table.date')}</span>
                            <div
                                className={classNames(styles.sortArrow, sort.includes('+payment_date') && styles.up)}
                            />
                        </div>
                    </th>
                    <th>{t('cabinet:invoices.table.resource')}</th>
                    <th>{t('cabinet:invoices.table.value')}</th>
                    <th className={styles.thStatus} />
                    <th className={styles.thActions}></th>
                </tr>
            </thead>
            <tbody>
                {invoicesPage.invoices!.map((invoice, index) => (
                    <InvoicesTableRow invoice={invoice} key={index} />
                ))}
            </tbody>
        </table>
    );
}
