import { Select, SelectProps } from 'antd';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Viewpoint } from '../../../entities/Viewpoint';
import { EMPTY_ARRAY } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { selectGeometries } from '../../../store/slices/geometries';
import { makeSelectLayerByGeometryId, selectTours } from '../../../store/slices/geometryLayers';
import { setTourPlayerState } from '../../../store/slices/projectView';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import { FLY_DURATION_SECONDS } from '../ArtifactViewer/camera/TourPlaying';

import { ReactComponent as SvgNext } from '../../../svg/presentation/next.svg';
import { ReactComponent as SvgPause } from '../../../svg/presentation/pause.svg';
import { ReactComponent as SvgPlay } from '../../../svg/presentation/play.svg';
import { ReactComponent as SvgPrev } from '../../../svg/presentation/prev.svg';

import {
    isGeometry,
    isLayer,
    isViewpointGeometry,
    ProjectStructureObjectTypes
} from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { setSelectedObject } from '../../../store/sharedActions';
import styles from './TourPlayer.module.scss';
import { selectSelectedPresentationSlide } from '../../../store/slices/presentation';

type Props = {
    isEmbedView?: boolean;
};

const DROPDOWN_ITEM_HEIGHT = 32; // высота одногой записи в дропдауне. берется из стилей
const DROPDOWN_ITEMS = 5; // необходимое количество видимых записей в дропдауне

export default function TourPlayer({ isEmbedView }: Props) {
    const { t } = useTranslation(['projectView']);
    const dispatch: AppDispatch = useDispatch();
    const dropdownContainerRef = useRef<HTMLDivElement>(null);
    const [showFullProgressBar, setShowFullProgressBar] = useState(false);
    const tourPlayerState = useSelector(state => state.projectView.tourPlayerState);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));
    const selectedViewpoint = useSelector(state => selectSelectedPresentationSlide(state));
    const selectLayerByGeometryId = useMemo(makeSelectLayerByGeometryId, []);
    const selectedViewpointId = selectedViewpoint?.id ?? '';
    const layerOfSelectedViewpoint = useSelector(state => selectLayerByGeometryId(state, selectedViewpointId));

    const tours = useSelector(state => selectTours(state));
    const geometries = useSelector(state => selectGeometries(state));

    const selectedTourId =
        layerOfSelectedViewpoint?.id ||
        (isLayer(selectedObjectInfo) && selectedObjectInfo.isPresentation ? selectedObjectInfo.id : '');
    const [playSecondsPassed, setPlaySecondsPassed] = useState(0);

    const toursOptions: SelectProps['options'] = useMemo(
        () => tours.map(t => ({ value: t.id, label: t.name })),
        [tours]
    );

    const dropdownListHeight = DROPDOWN_ITEM_HEIGHT * DROPDOWN_ITEMS;

    const getTourViewpoints = useCallback(
        (tourId: string) => {
            const tour = tours.find(t => t.id === tourId);
            return tour?.geometries.map(id => geometries[id]) ?? EMPTY_ARRAY;
        },
        [geometries, tours]
    );

    const selectedTourViewpoints = useMemo(
        () => getTourViewpoints(selectedTourId),
        [getTourViewpoints, selectedTourId]
    );

    const indexOfSelectedViewpoint = selectedViewpoint ? selectedTourViewpoints.indexOf(selectedViewpoint) : 0;
    const controlsDisabled = toursOptions.length === 0 || selectedTourViewpoints.length === 0;
    const durationOfSelectedViewpoint =
        (selectedViewpoint?.content.properties.ac_duration as number | undefined) ?? Viewpoint.DEFAULT_DURATION_SECONDS;
    const fullViewpointDuration = durationOfSelectedViewpoint + FLY_DURATION_SECONDS;

    const isTourPlaying = tourPlayerState === 'playing';

    const playProgress = showFullProgressBar ? 100 : Math.floor((playSecondsPassed / fullViewpointDuration) * 100);

    // source: https://blog.openreplay.com/use-requestanimationframe-in-react-for-smoothest-animations/
    const requestIdRef = useRef(0);
    const runAgain = useRef(performance.now() + 1000);
    const progressTimer = useRef(performance.now() + 1000);

    const nextAnimationFrame = useCallback((timestamp: any) => {
        if (runAgain.current <= timestamp) {
            if (progressTimer.current <= timestamp) {
                setPlaySecondsPassed(s => s + 1);

                progressTimer.current = timestamp + 1000;
            }

            runAgain.current = timestamp + 1000;
        }

        requestIdRef.current = requestAnimationFrame(nextAnimationFrame);
    }, []);

    useEffect(() => {
        if (isTourPlaying) {
            requestIdRef.current = requestAnimationFrame(nextAnimationFrame);

            return () => {
                cancelAnimationFrame(requestIdRef.current);
                setPlaySecondsPassed(0);
            };
        }
    }, [selectedViewpointId, isTourPlaying, nextAnimationFrame]);

    useEffect(() => {
        if (isTourPlaying && playSecondsPassed === fullViewpointDuration - 1) {
            setShowFullProgressBar(true);
        } else {
            if (showFullProgressBar) {
                setShowFullProgressBar(false);
            }
        }
    }, [isTourPlaying, playSecondsPassed, fullViewpointDuration, showFullProgressBar, setShowFullProgressBar]);

    return (
        <div className={classNames(styles.player, isEmbedView && styles.embed)} ref={dropdownContainerRef}>
            {(selectedTourId || selectedViewpointId) && (
                <>
                    <div className={styles.tourSelector}>
                        <Select<string>
                            value={selectedTourId}
                            onChange={newValue => {
                                dispatch(
                                    setSelectedObject({
                                        type: ProjectStructureObjectTypes.LAYER,
                                        artifactId: newValue,
                                        needToScroll: true
                                    })
                                );
                            }}
                            getPopupContainer={() => dropdownContainerRef.current!}
                            placement='topLeft'
                            variant='borderless'
                            className={classNames('ant-select-tour', isEmbedView && 'theme-dark')}
                            popupClassName={isEmbedView ? 'theme-dark' : ''}
                            listHeight={dropdownListHeight}
                            options={toursOptions}
                        />
                    </div>

                    <div className={styles.slide}>
                        <span className={styles.slidesCounter}>
                            {selectedViewpoint ? (
                                <>
                                    {indexOfSelectedViewpoint + 1} / {selectedTourViewpoints.length}
                                </>
                            ) : (
                                <>0 / {selectedTourViewpoints.length}</>
                            )}
                        </span>
                        <span>{selectedViewpoint?.content.properties.ac_name}</span>
                    </div>

                    <div className={styles.progressBar}>
                        <div
                            className={classNames(styles.progressValue, isTourPlaying && styles.progressAnimation)}
                            style={{ width: `${playProgress}%` }}
                        />
                    </div>
                </>
            )}

            <div>
                <div className={styles.buttonsContainer}>
                    <TippyTooltip tooltipText={t('projectView:tourPlayer.tooltipPrev')}>
                        <div
                            className={classNames(styles.button, controlsDisabled && styles.disabled)}
                            onClick={e => {
                                const prevIndex = indexOfSelectedViewpoint - 1;
                                dispatch(
                                    setSelectedObject({
                                        type: ProjectStructureObjectTypes.GEOMETRY,
                                        artifactId: selectedTourViewpoints.at(prevIndex)?.id!,
                                        needToScroll: true
                                    })
                                );
                            }}
                        >
                            <SvgPrev />
                        </div>
                    </TippyTooltip>
                    {!isTourPlaying && (
                        <TippyTooltip tooltipText={t('projectView:tourPlayer.tooltipPlay')}>
                            <div
                                className={classNames(styles.button, controlsDisabled && styles.disabled)}
                                onClick={async e => {
                                    if (!selectedViewpoint) {
                                        await dispatch(
                                            setSelectedObject({
                                                type: ProjectStructureObjectTypes.GEOMETRY,
                                                artifactId: selectedTourViewpoints[0].id
                                            })
                                        );
                                    }
                                    dispatch(setTourPlayerState('playing'));
                                }}
                            >
                                <SvgPlay />
                            </div>
                        </TippyTooltip>
                    )}
                    {isTourPlaying && (
                        <TippyTooltip tooltipText={t('projectView:tourPlayer.tooltipPause')}>
                            <div
                                className={classNames(styles.button, controlsDisabled && styles.disabled)}
                                onClick={e => {
                                    dispatch(setTourPlayerState('pause'));
                                }}
                            >
                                <SvgPause />
                            </div>
                        </TippyTooltip>
                    )}
                    <TippyTooltip tooltipText={t('projectView:tourPlayer.tooltipNext')}>
                        <div
                            className={classNames(styles.button, controlsDisabled && styles.disabled)}
                            onClick={e => {
                                const nextIndex = (indexOfSelectedViewpoint + 1) % selectedTourViewpoints.length;
                                dispatch(
                                    setSelectedObject({
                                        type: ProjectStructureObjectTypes.GEOMETRY,
                                        artifactId: selectedTourViewpoints.at(nextIndex)?.id!,
                                        needToScroll: true
                                    })
                                );
                            }}
                        >
                            <SvgNext />
                        </div>
                    </TippyTooltip>
                </div>
            </div>
        </div>
    );
}
