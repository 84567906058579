import { PropsWithChildren, ReactNode } from 'react';
import { PlanType } from '../../../generated/account-frontend-api';
import { useSelector } from '../../../store';

type Props = {
    children: ReactNode;
    type: PlanType;
};

function PlanFilter({ type, children }: Props) {
    const plan = useSelector(state => state.accountResources.account.plan.current);

    if (plan && plan.type === type) {
        return <>{children}</>;
    } else {
        return null;
    }
}

export function CommercialPlan({ children }: Required<PropsWithChildren<object>>) {
    return <PlanFilter type={PlanType.COMMERCIAL}>{children}</PlanFilter>;
}

export function NonCommercialPlan({ children }: Required<PropsWithChildren<object>>) {
    return <PlanFilter type={PlanType.FREE}>{children}</PlanFilter>;
}
