import produce from 'immer';
import { useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../../contexts/ProjectViewAccessContext';
import getFilename from '../../lib/getFilename';
import { AppDispatch, useSelector } from '../../store';
import {
    ProjectStructureObjectTypes,
    TemporaryGeometry,
    TemporaryLayer,
    getLayerProperties
} from '../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../store/selectors';
import { ExtendedDatasetInfo, renameDataset } from '../../store/slices/datasetfilesUpload';
import { selectGeometries, updateGeometryContent } from '../../store/slices/geometries';
import { updateGeometry, updateLayer } from '../../store/slices/geometryLayers';
import { setLayerProperty } from '../../store/slices/project';
import { ExtendedStructureInfo, setStructureProperty, updateStructureInfo } from '../../store/slices/structure';
import useCanRenameStructureItem from '../../hooks/useCanRenameStructureItem';
import useGetNodeName from '../../hooks/useGetNodeName';

export default function SelectedObjectNameInput() {
    const dispatch: AppDispatch = useDispatch();
    const selectedObject = useSelector(state => state.project.selectedObject);
    const geometries = useSelector(selectGeometries);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const temporaryLayers = useSelector(state => state.project.structure.temporaryLayers);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));
    const { owned } = useContext(ProjectViewAccessContext);
    const initialName = useRef('');
    const canRename = useCanRenameStructureItem(selectedObject.artifactId || '', selectedObject?.type || '');

    const name = useGetNodeName({
        selectedObjectType: selectedObject.type!,
        selectedObjectInfo: selectedObjectInfo
    });

    function updateGeometryName(value: string) {
        const geometry = selectedObjectInfo as TemporaryGeometry;
        dispatch(
            updateGeometryContent({
                id: geometry.id,
                geoJson: produce(geometry.content, draft => {
                    draft.properties.ac_name = value;
                })
            })
        );
    }

    function submitGeometryName(value: string): void {
        const newValue = value.trim() || initialName.current;

        if (newValue !== value) {
            updateGeometryName(newValue);
        }

        if (owned) {
            const layer = temporaryLayers.find(l => l.geometries.includes(selectedObject.artifactId || ''));
            const prevGeoJson = layer?.geometries
                .map(id => geometries[id]!)
                .find(g => g.id === selectedObject?.artifactId)?.content;
            dispatch(
                updateGeometry({
                    id: selectedObject?.artifactId!,
                    projectUid: projectInfo.id!,
                    layerUid: layer?.id!,
                    geoJson: produce(prevGeoJson!, draft => {
                        draft.properties.ac_name = newValue;
                    })
                })
            );
        }
    }

    function updateLayerName(layer: TemporaryLayer, value: string) {
        dispatch(
            setLayerProperty({
                id: layer.id,
                propName: 'name',
                propValue: value
            })
        );
    }

    function submitLayerName(layer: TemporaryLayer, value: string) {
        const newValue = value.trim() || initialName.current;

        if (newValue !== value) {
            updateLayerName(layer, newValue);
        }

        dispatch(
            updateLayer({
                projectUid: projectInfo?.id!,
                layerUid: layer.id,
                properties: { ...getLayerProperties(layer), name: newValue }
            })
        );
    }

    function updateDatasetName(dataset: ExtendedDatasetInfo, value: string) {
        dispatch(
            renameDataset({
                projectId: projectInfo.id!,
                datasetUid: dataset.datasetUid!,
                name: value
            })
        );
    }

    if (selectedObject.type === ProjectStructureObjectTypes.GEOMETRY) {
        const geometry = selectedObjectInfo as TemporaryGeometry;

        return (
            <input
                className='element-info-title'
                type='text'
                value={name}
                readOnly={!canRename}
                onFocus={e => {
                    initialName.current = geometry.content.properties.ac_name;
                    e.target.select();
                }}
                onChange={e => {
                    updateGeometryName(e.target.value);
                }}
                onKeyUp={e => {
                    if (e.key === 'Enter') {
                        e.currentTarget.blur();
                    }
                }}
                onBlur={e => {
                    submitGeometryName(e.target.value);
                }}
            />
        );
    }

    if (selectedObject.type === ProjectStructureObjectTypes.LAYER) {
        const layer = selectedObjectInfo as TemporaryLayer;

        return (
            <input
                className='element-info-title'
                type='text'
                value={name}
                readOnly={!canRename}
                onFocus={() => {
                    initialName.current = layer.name;
                }}
                onChange={e => {
                    updateLayerName(layer, e.target.value);
                }}
                onKeyUp={e => {
                    if (e.key === 'Enter') {
                        e.currentTarget.blur();
                    }
                }}
                onBlur={e => {
                    if (canRename) submitLayerName(layer, e.target.value);
                }}
            />
        );
    }

    if (selectedObject.type === ProjectStructureObjectTypes.DATASET) {
        const datasetInfo = selectedObjectInfo as ExtendedDatasetInfo;
        const isDisabled = !!datasetInfo.sourceData?.status;

        return (
            <input
                className='element-info-title'
                type='text'
                value={name}
                readOnly={!canRename || isDisabled}
                onFocus={e => {
                    initialName.current = name;
                }}
                onChange={e => {
                    updateDatasetName(datasetInfo, e.target.value);
                }}
                onKeyUp={e => {
                    if (e.key === 'Enter') {
                        e.currentTarget.blur();
                    }
                }}
                onBlur={e => {
                    let value = e.target.value.trim() || initialName.current;
                    updateDatasetName(datasetInfo, value);
                }}
            />
        );
    }

    if (selectedObject.type === ProjectStructureObjectTypes.GROUP) {
        const group = selectedObjectInfo as ExtendedStructureInfo;

        return (
            <input
                className='element-info-title'
                type='text'
                value={name}
                readOnly={!canRename}
                onFocus={e => {
                    initialName.current = name;
                }}
                onChange={e => {
                    dispatch(setStructureProperty({ propName: 'name', propValue: e.target.value, id: group.uid! }));
                }}
                onKeyUp={e => {
                    if (e.key === 'Enter') {
                        e.currentTarget.blur();
                    }
                }}
                onBlur={e => {
                    let value = e.target.value.trim() || initialName.current;
                    dispatch(
                        updateStructureInfo({
                            type: ProjectStructureObjectTypes.GROUP,
                            projectId: projectInfo.id!,
                            propName: 'name',
                            propValue: value,
                            structureUid: group.uid!
                        })
                    );
                }}
            />
        );
    }

    return null;
}
