import classNames from 'classnames';
import { Img } from 'react-image';
import defaultProjectPreview from '../../../assets/images/projects_list/default_preview.svg';
import defaultSitePreview from '../../../assets/images/projects_list/site.svg';
import defaultFolderPreview from '../../../assets/images/projects_list/folder.svg';
import { ProjectType } from '../../../generated/cloud-frontend-api';

type Props = {
    previewSrc?: string;
    type: ProjectType;
};

function ProjectPreviewImage({ type, previewSrc }: Props) {
    const preview = previewSrc || defaultPreview();
    const previewClassName = previewSrc ? 'project-image' : 'project-icon';

    function defaultPreview() {
        const previews: Record<ProjectType, string> = {
            SITE: defaultSitePreview,
            METASHAPE: defaultProjectPreview,
            NON_METASHAPE: defaultProjectPreview,
            FOLDER: defaultFolderPreview
        };
        return previews[type];
    }

    return (
        <Img
            className={previewClassName}
            src={[preview, defaultProjectPreview]}
            loader={<img className={classNames(previewClassName, 'loading')} alt={''} src={preview} />}
            decode={false}
            alt={''}
        />
    );
}

export default ProjectPreviewImage;
