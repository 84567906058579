import React, { useEffect, useContext, useState } from 'react';
import { useCesium } from 'resium';
import { useSelector, AppDispatch } from '../../../store/index';
import { selectNewCalculations, calculateVolume, VolumeCalculationInfo } from '../../../store/slices/volume';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';

export default function VolumeCalculations() {
    const calculations = useSelector(state => state.volume.calculations);

    return (
        <>
            {calculations
                .filter(c => c.status !== 'fulfilled')
                .map((c, index) => (
                    <VolumeCalculation calculation={c} key={index} />
                ))}
        </>
    );
}

function VolumeCalculation({ calculation }: { calculation: VolumeCalculationInfo }) {
    const { viewer, scene } = useCesium();
    const dispatch: AppDispatch = useDispatch();
    const { owned } = useContext(ProjectViewAccessContext);
    const [promise, setPromise] = useState<(Promise<any> & { abort(reason?: string): void }) | null>(null);

    useEffect(() => {
        if (calculation.status === 'new') {
            const promise = dispatch(
                calculateVolume({
                    scene: scene!,
                    globalTerrainProvider: viewer?.terrainProvider!,
                    owned,
                    id: calculation.id
                })
            );
            setPromise(promise);
        }
    }, [calculation, dispatch, scene, viewer, owned]);

    useEffect(() => {
        if (calculation.status === 'aborted') promise?.abort();
    }, [promise, calculation]);

    return null;
}
