import {
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    EntityState,
    IdSelector,
    PayloadAction,
    unwrapResult
} from '@reduxjs/toolkit';
import produce from 'immer';
import _ from 'lodash';
import { projectsApi } from '../../api/initApis';
import { CompareStructureTreeContextValue } from '../../contexts/CompareStructureTreeContext';
import { ProjectInfo, ProjectPartType, SourceType } from '../../generated/cloud-frontend-api';
import { StructureInfo } from '../../generated/project-structure-api/model/structure-info';
import { mapDatasetToLayer } from '../../lib/mapDatasetToLayer';
import { EMPTY_ARRAY } from '../../sharedConstants';
import { ExtendedChunk, TemporaryLayer } from '../helpers/interfaces';
import { AppDispatch, ApplicationState } from '../index';
import { ExtendedDatasetInfo } from './datasetfilesUpload';
import { selectDatasets } from './datasetsUpload';
import { getStructureTree } from './projectStructure';
import { setCompareToolEnabled } from './projectView';
import { ExtendedStructureInfo, requestStructureInfo } from './structure';
import sortChunks from '../../lib/sortChunks';
import sortArtifacts from '../../lib/sortArtifacts';

const name = 'compareTool';

interface ComparedProjectState {
    projectInfo: ProjectInfo;
    layers: TemporaryLayer[];
    chunks: ExtendedChunk[];
    datasets: ExtendedDatasetInfo[];
}

interface CompareToolState {
    tree1: EntityState<Required<StructureInfo>, string>;
    tree2: EntityState<Required<StructureInfo>, string>;
    comparedProject: ComparedProjectState | null;
    mode: 'compareProject' | 'compareSiteProjects';
}

export const nonComparableSourceTypes: SourceType[] = [SourceType.DEM, SourceType.DEM_3_D, SourceType.GEOJSON];

export const getComparedProject = createAsyncThunk<
    { project: ComparedProjectState; structures: ExtendedStructureInfo[] } | null,
    string,
    { state: ApplicationState }
>(`${name}/getComparedProject`, async (comparedProjectId: string) => {
    const { data: project } = await projectsApi.getProjectById(comparedProjectId);
    if (!project.structure || !project.projectInfo) return null;

    const layers = project.structure.datasets!.filter(d => d.projectPartType === ProjectPartType.VECTOR_LAYERS);
    const structures = await requestStructureInfo({ projectId: comparedProjectId });

    const sortedChunks = sortChunks((project.structure.chunks || []) as ExtendedChunk[]);
    for (const chunk of sortedChunks)
        chunk.artifacts = sortArtifacts(chunk.artifacts, project.structure.datasets || []);

    return {
        project: {
            projectInfo: project.projectInfo,
            chunks: sortedChunks,
            layers: layers.map(l => mapDatasetToLayer(l)),
            datasets: project.structure.datasets!.filter(d => d.projectPartType !== ProjectPartType.VECTOR_LAYERS)
        },
        structures
    };
});

function disableExpansionAndVisibilityIfNonComparable(
    structureInfo: StructureInfo,
    datasets: ExtendedDatasetInfo[],
    camerasIds: string[]
) {
    // Hide objects that can not be compared in compare tool
    return produce(structureInfo, draft => {
        const dataset = datasets.find(d => d.datasetUid === draft.uid);
        if (nonComparableSourceTypes.includes(dataset?.sourceData?.type!) || camerasIds.includes(structureInfo.uid!)) {
            draft.properties!.visible = String(false);
            draft.properties!.expanded = String(false);
        }
    });
}

interface InitCompareToolArgs {
    currentProjectStructures: StructureInfo[];
    comparedProjectId: string;
    currentProjectId: string;
    mode: CompareToolState['mode'];
}
interface InitCompareToolReturn {
    tree1: StructureInfo[];
    tree2: StructureInfo[];
    comparedProject: ComparedProjectState | null;
}
export const initCompareTool = createAsyncThunk<
    InitCompareToolReturn,
    InitCompareToolArgs,
    { state: ApplicationState; dispatch: AppDispatch }
>(
    `${name}/init`,
    async ({ comparedProjectId, currentProjectStructures, currentProjectId, mode }, { getState, dispatch }) => {
        const datasets = [
            ...selectDatasets(getState()),
            ...getState().datasets.datasets.filter(d => d.projectPartType === ProjectPartType.TILESETS)
        ];
        const currentProject = getState().project;
        const camerasIds = _.compact(currentProject.structure.chunks.map(c => c.cameras?.artifactUid));

        const currentProjectStructuresCopy = handleOldToursStructureInfo(currentProjectStructures, datasets);

        const tree1 = currentProjectStructuresCopy.map(s =>
            disableExpansionAndVisibilityIfNonComparable(s, datasets, camerasIds)
        );

        if (mode === 'compareProject') return { tree1, tree2: tree1, comparedProject: null };

        const comparedProject = {
            projectInfo: currentProject.projectInfo,
            chunks: currentProject.structure.chunks,
            layers: currentProject.structure.temporaryLayers.map(l => ({ ...l, geometries: [] })),
            datasets
        };

        if (comparedProjectId === currentProjectId) return { tree1, tree2: tree1, comparedProject };
        const data = unwrapResult(await dispatch(getComparedProject(comparedProjectId)));
        if (!data) return { tree1, tree2: tree1, comparedProject: null };
        return {
            tree1,
            tree2: handleOldToursStructureInfo(data.structures, data.project.datasets),
            comparedProject: data.project
        };

        function handleOldToursStructureInfo(
            structures: StructureInfo[],
            datasets: ExtendedDatasetInfo[]
        ): StructureInfo[] {
            // Old tours may be missing associated structure info, if they do, we need to provide it
            const structuresCopy = [...structures];
            const tours = datasets.filter(d => d.properties?.isPresentation === String(true));
            for (const tour of tours) {
                const structureInfo = structuresCopy.find(s => s.uid === tour.datasetUid);
                if (!structureInfo) {
                    structuresCopy.push({
                        uid: tour.datasetUid,
                        parentUid: currentProjectId,
                        properties: {}
                    });
                }
            }
            return structuresCopy;
        }
    }
);

export const updateCompareToolTree1Structures = createAsyncThunk<
    StructureInfo[],
    StructureInfo[],
    { state: ApplicationState }
>(`${name}/updateTree1Structures`, (newStructures, { getState }) => {
    const datasets = [
        ...selectDatasets(getState()),
        ...getState().datasets.datasets.filter(d => d.projectPartType === ProjectPartType.TILESETS)
    ];
    const currentProject = getState().project;
    const camerasIds = _.compact(currentProject.structure.chunks.map(c => c.cameras?.artifactUid));
    return newStructures.map(s => disableExpansionAndVisibilityIfNonComparable(s, datasets, camerasIds));
});

const selectId: IdSelector<StructureInfo, string> = model => model.uid!;
class CompareToolSlice {
    public readonly tree1 = createEntityAdapter<Required<StructureInfo>, string>({ selectId });
    public readonly tree2 = createEntityAdapter<Required<StructureInfo>, string>({ selectId });
    readonly #slice;
    public readonly selectors;

    constructor() {
        const initialState: CompareToolState = {
            ...{ tree1: this.tree1.getInitialState() },
            ...{ tree2: this.tree2.getInitialState() },
            comparedProject: null,
            mode: 'compareProject'
        };
        this.#slice = createSlice({
            name,
            initialState,
            reducers: {
                updateCompareToolStructureInfo: (
                    state,
                    { payload }: PayloadAction<{ structureInfo: Required<StructureInfo>; treeId: 'tree1' | 'tree2' }>
                ) => {
                    const structureInfo = state[payload.treeId].ids.find(id => id === payload.structureInfo.uid);
                    if (structureInfo)
                        this[payload.treeId].updateOne(state[payload.treeId], {
                            id: payload.structureInfo.uid,
                            changes: payload.structureInfo
                        });
                    else this[payload.treeId].addOne(state[payload.treeId], payload.structureInfo);
                }
            },
            extraReducers: builder => {
                builder
                    .addCase(setCompareToolEnabled, (state, { payload }) => {
                        if (!payload) {
                            this.tree1.removeAll(state.tree1);
                            this.tree2.removeAll(state.tree2);
                            state.comparedProject = null;
                        }
                    })
                    .addCase(initCompareTool.pending, (state, { meta }) => {
                        state.mode = meta.arg.mode;
                    })
                    .addCase(initCompareTool.fulfilled, (state, { payload }) => {
                        this.tree1.setAll(state.tree1, payload.tree1 as Readonly<Required<StructureInfo>[]>);
                        this.tree2.setAll(state.tree2, payload.tree2 as Readonly<Required<StructureInfo>[]>);
                        state.comparedProject = payload.comparedProject;
                    })
                    .addCase(getComparedProject.pending, (state, { meta }) => {
                        if (state.comparedProject) state.comparedProject.projectInfo.id = meta.arg;
                    })
                    .addCase(getComparedProject.fulfilled, (state, { payload }) => {
                        if (payload?.project) state.comparedProject = payload.project;
                        if (payload?.structures)
                            this.tree2.setAll(state.tree2, payload.structures as Readonly<Required<StructureInfo>[]>);
                    })
                    .addCase(updateCompareToolTree1Structures.fulfilled, (state, { payload }) => {
                        this.tree1.setAll(state.tree1, payload as Readonly<Required<StructureInfo>[]>);
                    });
            }
        });
        this.selectors = {
            tree1: this.tree1.getSelectors<ApplicationState>(state => state.compareTool.tree1),
            tree2: this.tree2.getSelectors<ApplicationState>(state => state.compareTool.tree2)
        };
    }

    get slice() {
        return this.#slice;
    }
}

export const compareToolState = new CompareToolSlice();

export default compareToolState.slice.reducer;

export const { updateCompareToolStructureInfo } = compareToolState.slice.actions;

export const selectCompareToolComparedProject = createSelector(
    (state: ApplicationState) => state.compareTool.comparedProject,
    project => project
);

export const selectComparedProjectFilesTree = createSelector(
    selectCompareToolComparedProject,
    (state: ApplicationState) => state.projectView.isCompareToolEnabled,
    (state: ApplicationState, treeId?: CompareStructureTreeContextValue['treeId']) =>
        treeId ? compareToolState.selectors[treeId].selectAll(state) : null,
    (project, isCompareToolEnabled, compareToolStructures) => {
        if (!project) return EMPTY_ARRAY;
        return getStructureTree(
            project.layers,
            project.chunks,
            project.datasets,
            [],
            {},
            project.projectInfo,
            isCompareToolEnabled,
            {},
            {},
            compareToolStructures
        );
    }
);
