import i18n from '../i18n/config';
import { Units } from '../sharedConstants';

export function getUnitsFullName(units: Units) {
    return {
        [Units.METRE]: i18n.t('common:measureUnits_full.meter'),
        [Units.FOOT]: i18n.t('common:measureUnits_full.foot'),
        [Units.US_SURVEY_FOOT]: i18n.t('common:measureUnits_full.usFoot')
    }[units];
}

export function getUnitsSquareFullName(units: Units) {
    return {
        [Units.METRE]: i18n.t('common:measureUnits_full.squareMeter'),
        [Units.FOOT]: i18n.t('common:measureUnits_full.squareFoot'),
        [Units.US_SURVEY_FOOT]: i18n.t('common:measureUnits_full.squareUsFoot')
    }[units];
}

export function getUnitsCubicFullName(units: Units) {
    return {
        [Units.METRE]: i18n.t('common:measureUnits_full.cubicMeter'),
        [Units.FOOT]: i18n.t('common:measureUnits_full.cubicFoot'),
        [Units.US_SURVEY_FOOT]: i18n.t('common:measureUnits_full.cubicUsFoot')
    }[units];
}
