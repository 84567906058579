import { compact } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useTranslation } from 'react-i18next';
import { RemoveScroll } from 'react-remove-scroll';
import { matchPath, NavLink } from 'react-router-dom';
import { useWindowSize } from 'rooks';
import { DESKTOP_BREAKPOINT, IS_TOUCH_DEVICE, Routes } from '../../../sharedConstants';
import { useSelector } from '../../../store';
import { ReactComponent as SvgBilling } from '../../../svg/navigation/billing.svg';
import { ReactComponent as SvgInvoices } from '../../../svg/navigation/invoices.svg';
import { ReactComponent as SvgProjects } from '../../../svg/navigation/projects.svg';
import { ReactComponent as SvgProjectsShared } from '../../../svg/navigation/shared.svg';
import SidebarResources from '../sidebar-resources/SidebarResources';
import './hideScrollbar.css';

export default function NavSidebar() {
    const { t } = useTranslation(['navigation']);
    const { innerWidth } = useWindowSize();
    const isBillingActive = useSelector(state => state.init.isBillingActive);
    const [isVerticalScrollingBlocked, setIsVerticalScrollingBlocked] = useState(false);

    const menuItems: JSX.Element[] = useMemo(() => {
        return compact([
            <div className='nav-item' key='/'>
                <NavLink
                    exact
                    to={Routes.INDEX}
                    className='nav-link'
                    activeClassName='active'
                    isActive={(match, location) => {
                        return !!matchPath(location.pathname, { path: [Routes.INDEX, Routes.FOLDER], exact: true });
                    }}
                    onClick={e => {
                        e.nativeEvent.stopImmediatePropagation();
                    }}
                >
                    <span className='nav-icon'>
                        <SvgProjects />
                    </span>
                    <span data-testid={'my-projects-menu-item'}>
                        <span>{t('navigation:drive')}</span>
                    </span>
                </NavLink>
            </div>,
            <div className='nav-item' key='/shared/projects'>
                <NavLink
                    exact
                    to={Routes.SHARED}
                    className='nav-link'
                    activeClassName='active'
                    onClick={e => {
                        e.nativeEvent.stopImmediatePropagation();
                    }}
                >
                    <span className='nav-icon'>
                        <SvgProjectsShared />
                    </span>
                    <span data-testid={'shared-projects-menu-item'}>
                        <span>{t('navigation:sharedWithMe')}</span>
                    </span>
                </NavLink>
            </div>,

            isBillingActive ? (
                <div className='nav-item' key='billing'>
                    <NavLink
                        exact
                        to={Routes.BILLING}
                        className='nav-link'
                        activeClassName='active'
                        onClick={e => {
                            e.nativeEvent.stopImmediatePropagation();
                        }}
                    >
                        <span className='nav-icon'>
                            <SvgBilling />
                        </span>
                        <span data-testid={'billing-menu-item'}>
                            <span>{t('navigation:billing')}</span>
                        </span>
                    </NavLink>
                </div>
            ) : null,

            isBillingActive ? (
                <div className='nav-item' key='invoices'>
                    <NavLink
                        exact
                        to={Routes.INVOICES}
                        className='nav-link'
                        activeClassName='active'
                        onClick={e => {
                            e.nativeEvent.stopImmediatePropagation();
                        }}
                    >
                        <span className='nav-icon'>
                            <SvgInvoices />
                        </span>
                        <span data-testid={'invoices-menu-item'}>
                            <span>{t('navigation:invoices')}</span>
                        </span>
                    </NavLink>
                </div>
            ) : null
        ]);
    }, [t, isBillingActive]);

    useEffect(() => {
        if (!IS_TOUCH_DEVICE && innerWidth && innerWidth < DESKTOP_BREAKPOINT) {
            const mobileMenuDiv = document.querySelector('.mobilePrimaryNav');
            mobileMenuDiv?.addEventListener('mouseenter', setIsVerticalScrollingBlocked.bind(null, true));
            mobileMenuDiv?.addEventListener('mouseleave', setIsVerticalScrollingBlocked.bind(null, false));

            return () => {
                mobileMenuDiv?.removeEventListener('mouseenter', setIsVerticalScrollingBlocked.bind(null, true));
                mobileMenuDiv?.removeEventListener('mouseleave', setIsVerticalScrollingBlocked.bind(null, false));
                setIsVerticalScrollingBlocked(false);
            };
        }
    }, [innerWidth]);

    return innerWidth! < DESKTOP_BREAKPOINT ? (
        <div className='scrolling-nav-wrapper'>
            <RemoveScroll removeScrollBar={false} enabled={isVerticalScrollingBlocked}>
                <ScrollMenu wrapperClassName={'mobilePrimaryNav'}>{menuItems.map(item => item)}</ScrollMenu>
            </RemoveScroll>
            <SidebarResources isMobileView={true} />
        </div>
    ) : (
        <div id='pagesSidebar'>
            <div id='primaryNav'>{menuItems.map(item => item)}</div>
            <SidebarResources isMobileView={false} />
        </div>
    );
}
