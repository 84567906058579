import Konva from 'konva';
import React, { memo, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Layer, Stage } from 'react-konva';
import { useDispatch } from 'react-redux';
import { useImageOrientationAndRotation } from '../../../hooks/useImageOrientationAndRotation';
import { useImageSizeScaledToContainerSize } from '../../../hooks/useImageSizeScaledToContainerSize';
import { WidthHeight } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import {
    makeSelectIssuesByImageUid,
    makeSelectIssuesByPhotoUid,
    selectGeometryById
} from '../../../store/slices/geometries';
import { IssuesRects } from './IssuesRects';
import { useDragBoundsCheck, useZoom } from './useKonvaUtils';
import { useURLImage } from './useURLImage';

type Props = {
    onLoad(): void;
    isLoading: boolean;
    setHasFailed: React.Dispatch<React.SetStateAction<boolean>>;
    containerSize: WidthHeight;
    imageUrl: string;
    singleIssueIdToDisplay?: string; // Pass to display only one issue on an undrawable canvas
};

function NavigableImageWithCanvas({
    isLoading,
    onLoad,
    containerSize,
    setHasFailed,
    singleIssueIdToDisplay,
    imageUrl
}: Props) {
    const { t } = useTranslation('projectView');
    const dispatch: AppDispatch = useDispatch();
    const layerRef = useRef<Konva.Layer>(null!);
    const singleIssueRect = useRef<Konva.Rect>(null!);
    const selectIssuesByPhotoUid = useMemo(makeSelectIssuesByPhotoUid, []);
    const selectIssuesByImageUid = useMemo(makeSelectIssuesByImageUid, []);
    const selectedIssue = useSelector(state => selectGeometryById(state, singleIssueIdToDisplay!));

    const cameraIssuesByPhotoUid = useSelector(state =>
        selectIssuesByPhotoUid(state, (selectedIssue?.content.properties.ac_photoUid ?? '') as string)
    );
    const cameraIssuesByImageUid = useSelector(state =>
        selectIssuesByImageUid(state, (selectedIssue?.content.properties.ac_image_uid ?? '') as string)
    );

    const cameraIssues = cameraIssuesByImageUid.length > 0 ? cameraIssuesByImageUid : cameraIssuesByPhotoUid;
    const [hasAppliedInitialPanZoom, setHasAppliedInitialPanZoom] = useState(false);
    const [isImageReady, setImageReady] = useState(false);

    const { image, orientation } = useURLImage({
        url: imageUrl,
        onError: () => {
            setHasFailed(true);
        },
        onLoad: () => {
            onLoad();
            setImageReady(true);
        },
        parseOrientation: true
    });

    const { scale, zoomFn, setScale, zoomFnTouchEnd, zoomFnTouchMove } = useZoom();
    const dragBoundsCheck = useDragBoundsCheck();

    const { height, left, top, width } = useImageSizeScaledToContainerSize(containerSize, {
        width: image?.width ?? 0,
        height: image?.height ?? 0
    });

    const { offset, rotation } = useImageOrientationAndRotation(orientation, width || 0, height || 0);

    useLayoutEffect(() => {
        if (isLoading || !singleIssueIdToDisplay || hasAppliedInitialPanZoom || !isImageReady) return;

        const issue = cameraIssues.find(i => i.id === singleIssueIdToDisplay)!;
        if (issue && singleIssueRect.current) {
            const clientRect = singleIssueRect.current.getClientRect();
            const stage = singleIssueRect.current.getStage()!;
            const scale = Math.min(stage.width() / clientRect.width, stage.height() / clientRect.height);
            const prevX = layerRef.current?.x() || 0;
            const prevY = layerRef.current?.y() || 0;
            const centerX = containerSize.width / 2 / scale - clientRect.width / 2;
            const centerY = containerSize.height / 2 / scale - clientRect.height / 2;
            layerRef.current?.x(prevX - clientRect.x + centerX);
            layerRef.current?.y(prevY - clientRect.y + centerY);
            setScale({ x: scale, y: scale });
            setHasAppliedInitialPanZoom(true);
        }
    }, [
        isLoading,
        cameraIssues,
        singleIssueIdToDisplay,
        setScale,
        containerSize,
        hasAppliedInitialPanZoom,
        isImageReady
    ]);

    if (isLoading || !image) return null;

    return (
        <Stage
            width={containerSize.width}
            height={containerSize.height}
            scale={scale}
            onWheel={e => {
                zoomFn(e);
            }}
        >
            <Layer
                ref={layerRef}
                x={left || 0}
                y={top || 0}
                draggable
                onTouchMove={e => {
                    zoomFnTouchMove(e);
                }}
                onTouchEnd={e => {
                    zoomFnTouchEnd();
                }}
                onDragEnd={e => {
                    dragBoundsCheck(e);
                }}
            >
                <Image image={image} height={height || 0} width={width || 0} rotation={rotation} position={offset} />
                {isImageReady && (
                    <>
                        {cameraIssues
                            .filter(c => (singleIssueIdToDisplay ? c.id === singleIssueIdToDisplay : true))
                            .map(issue => {
                                return (
                                    <IssuesRects
                                        key={issue.id}
                                        sourceSize={{ width: image.width, height: image.height }}
                                        issue={issue}
                                        imageHeight={height || 0}
                                        imageWidth={width || 0}
                                        singleIssueIdToDisplay={singleIssueIdToDisplay}
                                        scale={scale}
                                        ref={singleIssueRect}
                                    />
                                );
                            })}
                    </>
                )}
            </Layer>
        </Stage>
    );
}

export default memo(NavigableImageWithCanvas);
