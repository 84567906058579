/* tslint:disable */
/* eslint-disable */
/**
 * uploaded-dataset-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateDatasetRequest } from '../model';
// @ts-ignore
import { DatasetUid } from '../model';
// @ts-ignore
import { PartNumber } from '../model';
// @ts-ignore
import { PresignedUrl } from '../model';
/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel upload
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUpload: async (projectUid: string, datasetUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('cancelUpload', 'projectUid', projectUid)
            // verify required parameter 'datasetUid' is not null or undefined
            assertParamExists('cancelUpload', 'datasetUid', datasetUid)
            const localVarPath = `/projects/{projectUid}/datasets/{datasetUid}/upload/cancel`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"datasetUid"}}`, encodeURIComponent(String(datasetUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete upload
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeUpload: async (projectUid: string, datasetUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('completeUpload', 'projectUid', projectUid)
            // verify required parameter 'datasetUid' is not null or undefined
            assertParamExists('completeUpload', 'datasetUid', datasetUid)
            const localVarPath = `/projects/{projectUid}/datasets/{datasetUid}/upload/complete`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"datasetUid"}}`, encodeURIComponent(String(datasetUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new dataset with defined parameters
         * @param {string} projectUid UUID of project
         * @param {CreateDatasetRequest} createDatasetRequest Initial info to create dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataset: async (projectUid: string, createDatasetRequest: CreateDatasetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('createDataset', 'projectUid', projectUid)
            // verify required parameter 'createDatasetRequest' is not null or undefined
            assertParamExists('createDataset', 'createDatasetRequest', createDatasetRequest)
            const localVarPath = `/projects/{projectUid}/datasets`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDatasetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create presigned url to download dataset
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDownloadUrl: async (projectUid: string, datasetUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('createDownloadUrl', 'projectUid', projectUid)
            // verify required parameter 'datasetUid' is not null or undefined
            assertParamExists('createDownloadUrl', 'datasetUid', datasetUid)
            const localVarPath = `/projects/{projectUid}/datasets/{datasetUid}/download`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"datasetUid"}}`, encodeURIComponent(String(datasetUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create presigned url to upload dataset
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {PartNumber} partNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadUrl: async (projectUid: string, datasetUid: string, partNumber: PartNumber, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('createUploadUrl', 'projectUid', projectUid)
            // verify required parameter 'datasetUid' is not null or undefined
            assertParamExists('createUploadUrl', 'datasetUid', datasetUid)
            // verify required parameter 'partNumber' is not null or undefined
            assertParamExists('createUploadUrl', 'partNumber', partNumber)
            const localVarPath = `/projects/{projectUid}/datasets/{datasetUid}/upload/create`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"datasetUid"}}`, encodeURIComponent(String(datasetUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partNumber, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete dataset
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataset: async (projectUid: string, datasetUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('deleteDataset', 'projectUid', projectUid)
            // verify required parameter 'datasetUid' is not null or undefined
            assertParamExists('deleteDataset', 'datasetUid', datasetUid)
            const localVarPath = `/projects/{projectUid}/datasets/{datasetUid}`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"datasetUid"}}`, encodeURIComponent(String(datasetUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel upload
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelUpload(projectUid: string, datasetUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelUpload(projectUid, datasetUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Complete upload
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeUpload(projectUid: string, datasetUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeUpload(projectUid, datasetUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new dataset with defined parameters
         * @param {string} projectUid UUID of project
         * @param {CreateDatasetRequest} createDatasetRequest Initial info to create dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDataset(projectUid: string, createDatasetRequest: CreateDatasetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetUid>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDataset(projectUid, createDatasetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create presigned url to download dataset
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDownloadUrl(projectUid: string, datasetUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresignedUrl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDownloadUrl(projectUid, datasetUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create presigned url to upload dataset
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {PartNumber} partNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUploadUrl(projectUid: string, datasetUid: string, partNumber: PartNumber, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresignedUrl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUploadUrl(projectUid, datasetUid, partNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete dataset
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDataset(projectUid: string, datasetUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDataset(projectUid, datasetUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel upload
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUpload(projectUid: string, datasetUid: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelUpload(projectUid, datasetUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Complete upload
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeUpload(projectUid: string, datasetUid: string, options?: any): AxiosPromise<void> {
            return localVarFp.completeUpload(projectUid, datasetUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new dataset with defined parameters
         * @param {string} projectUid UUID of project
         * @param {CreateDatasetRequest} createDatasetRequest Initial info to create dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDataset(projectUid: string, createDatasetRequest: CreateDatasetRequest, options?: any): AxiosPromise<DatasetUid> {
            return localVarFp.createDataset(projectUid, createDatasetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create presigned url to download dataset
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDownloadUrl(projectUid: string, datasetUid: string, options?: any): AxiosPromise<PresignedUrl> {
            return localVarFp.createDownloadUrl(projectUid, datasetUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create presigned url to upload dataset
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {PartNumber} partNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadUrl(projectUid: string, datasetUid: string, partNumber: PartNumber, options?: any): AxiosPromise<PresignedUrl> {
            return localVarFp.createUploadUrl(projectUid, datasetUid, partNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete dataset
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDataset(projectUid: string, datasetUid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDataset(projectUid, datasetUid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
    /**
     * 
     * @summary Cancel upload
     * @param {string} projectUid UUID of project
     * @param {string} datasetUid uid of dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public cancelUpload(projectUid: string, datasetUid: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).cancelUpload(projectUid, datasetUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Complete upload
     * @param {string} projectUid UUID of project
     * @param {string} datasetUid uid of dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public completeUpload(projectUid: string, datasetUid: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).completeUpload(projectUid, datasetUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new dataset with defined parameters
     * @param {string} projectUid UUID of project
     * @param {CreateDatasetRequest} createDatasetRequest Initial info to create dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createDataset(projectUid: string, createDatasetRequest: CreateDatasetRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createDataset(projectUid, createDatasetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create presigned url to download dataset
     * @param {string} projectUid UUID of project
     * @param {string} datasetUid uid of dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createDownloadUrl(projectUid: string, datasetUid: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createDownloadUrl(projectUid, datasetUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create presigned url to upload dataset
     * @param {string} projectUid UUID of project
     * @param {string} datasetUid uid of dataset
     * @param {PartNumber} partNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createUploadUrl(projectUid: string, datasetUid: string, partNumber: PartNumber, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createUploadUrl(projectUid, datasetUid, partNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete dataset
     * @param {string} projectUid UUID of project
     * @param {string} datasetUid uid of dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public deleteDataset(projectUid: string, datasetUid: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).deleteDataset(projectUid, datasetUid, options).then((request) => request(this.axios, this.basePath));
    }
}
