import { isEmpty } from 'lodash';
import { useContext } from 'react';
import ProjectViewAccessContext from '../contexts/ProjectViewAccessContext';
import { useSelector } from '../store';
import { ProjectStructureObjectTypes } from '../store/helpers/interfaces';
import { isLinkedDataset } from '../store/slices/datasets';
import { selectUpload } from '../store/slices/datasetsUpload';

const editableNameObjectTypes: ProjectStructureObjectTypes[] = [
    ProjectStructureObjectTypes.DATASET,
    ProjectStructureObjectTypes.GEOMETRY,
    ProjectStructureObjectTypes.GROUP,
    ProjectStructureObjectTypes.LAYER
];

export default function useCanRenameStructureItem(id: string, type: ProjectStructureObjectTypes) {
    const { owned } = useContext(ProjectViewAccessContext);
    const temporaryLayers = useSelector(state => state.project.structure.temporaryLayers);
    const datasets = useSelector(state => state.datasets.datasets);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const layerUpload = useSelector(state => selectUpload(state, id));

    const layer = temporaryLayers.find(l => l.id === id);
    const dataset = datasets.find(d => d.datasetUid === id);
    const isTemporaryLayer = layer?.isTemporary;
    const isLayerLinkedFromAnotherProject =
        (type === ProjectStructureObjectTypes.LAYER && isLinkedDataset(layer!, projectInfo.id!)) ||
        (type === ProjectStructureObjectTypes.GEOMETRY &&
            isLinkedDataset(temporaryLayers.find(l => l.geometries.find(g => g === id))!, projectInfo.id!));
    const isGeometryTemporary = temporaryLayers.find(tl => Boolean(tl.geometries.find(g => g === id)))?.isTemporary;
    const isDatasetLinkedFromAnotherProject =
        type === ProjectStructureObjectTypes.DATASET && isLinkedDataset(dataset!, projectInfo.id!);

    const layerCantBeRenamed =
        type === ProjectStructureObjectTypes.LAYER &&
        (layerUpload?.status === 'uploading' ||
            layerUpload?.status === 'validating' ||
            layerUpload?.status === 'rejected');
    const datasetCantBeRenamed = type === ProjectStructureObjectTypes.DATASET && dataset && isEmpty(dataset.visualData);
    const cantBeRenamed = layerCantBeRenamed || datasetCantBeRenamed;

    const viewOnly =
        (!owned && !isTemporaryLayer && !isGeometryTemporary) ||
        isLayerLinkedFromAnotherProject ||
        isDatasetLinkedFromAnotherProject;

    const isEditable = editableNameObjectTypes.includes(type);

    return isEditable && !viewOnly && !cantBeRenamed;
}
