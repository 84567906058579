import { SyntheticEvent, ReactNode } from 'react';
import classNames from 'classnames';
import { ReactComponent as SvgArrowDown } from '../../../../svg/general/arrow_down.svg';

type Props = {
    isBlockExpanded: boolean;
    title: string;
    onClick(e: SyntheticEvent): void;
    className?: string;
    children?: ReactNode;
};

export default function PropertiesBlockHead({ isBlockExpanded, title, onClick, className, children }: Props) {
    return (
        <div className={classNames('properties-title', className)}>
            <div className='title-wrapper' onClick={onClick}>
                <div className='expand-arrow'>
                    <SvgArrowDown className={classNames('arrow-icon', { rolledUp: !isBlockExpanded })} />
                </div>
                <span className='title'>{title}</span>
            </div>
            {children}
        </div>
    );
}
