import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../../../store';
import {
    selectLimitBoxParamsOfEnabledLimitBox,
    selectStructureWithEnabledLimitBox,
    setStructureProperty
} from '../../../../store/slices/structure';
import { LimitBoxParams, getDefaultLimitBoxParams } from './LimitBox';
import { useCallback, useRef } from 'react';
import * as Cesium from 'cesium';

const MIN_DISTANCE_BETWEEN_OPPOSITE_PLANES = 1; // In 3D coordinates

export default function useLimitBoxRecalculation() {
    const dispatch: AppDispatch = useDispatch();
    const limitBoxParams = useSelector(state => selectLimitBoxParamsOfEnabledLimitBox(state));
    const structureInfoWithEnabledLimitBox = useSelector(state => selectStructureWithEnabledLimitBox(state));
    const movingFaceId = useSelector(state => state.projectView.limitBoxMovingFaceId);
    const datasets = useSelector(state => state.datasets.datasets);
    const boundingSpheres = useSelector(state => state.projectView.tilesetsBoundingSpheres);
    const tilesetTransforms = useSelector(state => state.projectView.tilesetsTransforms);

    const tilesetId = datasets.find(d => d.datasetUid === structureInfoWithEnabledLimitBox?.uid)?.visualData?.dataUid;
    const boundingSphere = boundingSpheres[tilesetId || ''];

    const transform = tilesetTransforms[tilesetId || ''];

    return useCallback(
        (startPosition: Cesium.Cartesian2, endPosition: Cesium.Cartesian2, scene: Cesium.Scene) => {
            if (!(structureInfoWithEnabledLimitBox && movingFaceId)) return;

            const modelTransform = transform;

            const boxParams = limitBoxParams || getDefaultLimitBoxParams(boundingSphere);
            const faceNormal = boxParams[movingFaceId].normal;

            //Get the plane normal vector
            const faceNormalTransformed = Cesium.Matrix4.multiplyByPointAsVector(
                modelTransform,
                faceNormal,
                new Cesium.Cartesian3()
            );

            //Construct rays from camera origin to the mouse positions
            const startRay = scene.camera.getPickRay(startPosition);
            const endRay = scene.camera.getPickRay(endPosition);

            //Build a reference plane from the point on the ray and normal pointing to camera
            const referencePlaneNormal = Cesium.Cartesian3.multiplyByScalar(
                scene.camera.direction,
                -1.0,
                new Cesium.Cartesian3()
            );

            const referencePlanePoint = Cesium.Ray.getPoint(
                startRay!,
                Cesium.Cartesian3.distance(scene.camera.position, boundingSphere.center)
            );

            const referencePlane = Cesium.Plane.fromPointNormal(referencePlanePoint, referencePlaneNormal);

            //Find intersections between rays and plane
            const startIntersection = Cesium.IntersectionTests.rayPlane(
                startRay!,
                referencePlane,
                new Cesium.Cartesian3()
            );

            const endIntersection = Cesium.IntersectionTests.rayPlane(endRay!, referencePlane, new Cesium.Cartesian3());

            //Calculate the movement direction vector
            const movementDirection = Cesium.Cartesian3.subtract(
                startIntersection,
                endIntersection,
                new Cesium.Cartesian3()
            );

            //Normalize the movement direction vector
            const movementDirectionNormalized = Cesium.Cartesian3.normalize(movementDirection, new Cesium.Cartesian3());

            //Calculate the movement direction
            const movementDirectionDotProduct = Cesium.Cartesian3.dot(
                faceNormalTransformed,
                movementDirectionNormalized
            );

            const movementDistance = Cesium.Cartesian3.distance(startIntersection, endIntersection);

            const directionMultiplier = movementDirectionDotProduct > 0 ? 1 : -1;

            let nextDistance = boxParams[movingFaceId].distance + movementDistance * directionMultiplier;

            const oppositeFaceId = getOppositeFace(movingFaceId);

            const isMovingFaceToOppositeDirection = movementDirectionDotProduct < 0;

            //Calculate the distance between moving and opposit face. Multiple opposite by -1 to fit on coordinate system.
            const nextDistanceToOppositFace = nextDistance - boxParams[oppositeFaceId].distance * -1;

            const isNextFaceCollidingOpposite = nextDistanceToOppositFace <= MIN_DISTANCE_BETWEEN_OPPOSITE_PLANES;

            //Handle faces overlaping and out of bounds cases
            if (isNextFaceCollidingOpposite) return;

            if (nextDistance >= boundingSphere.radius) return;

            boxParams[movingFaceId].distance = nextDistance;

            dispatch(
                setStructureProperty({
                    id: structureInfoWithEnabledLimitBox.uid!,
                    propName: 'limitBoxParams',
                    propValue: JSON.stringify(boxParams)
                })
            );
        },
        [boundingSphere, dispatch, limitBoxParams, movingFaceId, structureInfoWithEnabledLimitBox, transform]
    );
}

function getOppositeFace(faceId: keyof LimitBoxParams): keyof LimitBoxParams {
    const oppositeFaces: Record<keyof LimitBoxParams, keyof LimitBoxParams> = {
        x: 'negativeX',
        y: 'negativeY',
        z: 'negativeZ',
        negativeX: 'x',
        negativeY: 'y',
        negativeZ: 'z'
    };
    return oppositeFaces[faceId];
}
