import React, { useEffect, useMemo, useState } from 'react';
import { Clock, useCesium } from 'resium';
import { useSelector } from '../../../store';
import * as Cesium from 'cesium';

const HANDLED_KEYS = [
    'KeyW',
    'KeyS',
    'KeyA',
    'KeyD',
    'KeyQ',
    'KeyE',
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ShiftLeft'
] as const;
type EventKey = (typeof HANDLED_KEYS)[number];

const ROTATION_RATE = 0.0174533; // 1 degree

enum EVENTS {
    Keydown = 'keydown',
    Keyup = 'keyup',
    Click = 'click',
    Blur = 'blur'
}

export default function KeyboardNavigation() {
    const { scene, camera } = useCesium();
    const isCesiumLoaded = useSelector(state => state.projectView.isCesiumLoaded);
    const [pressedKeys, setPressedKeys] = useState<Set<EventKey>>(new Set());
    const [acceleration, setAcceleration] = useState(1);
    const [baseSpeed, setBaseSpeed] = useState(1);

    const canvas = useMemo(() => scene?.canvas, [scene]);
    const screenCenter = useMemo(
        () => (canvas ? new Cesium.Cartesian2(canvas.clientWidth / 2, canvas.clientHeight / 2) : null),
        [canvas]
    );
    const moveRate = useMemo(() => baseSpeed * acceleration, [acceleration, baseSpeed]);
    const rotationRate = useMemo(() => ROTATION_RATE * acceleration, [acceleration]);

    useEffect(() => {
        if (canvas && isCesiumLoaded) {
            canvas.setAttribute('tabindex', '-1');
            canvas.focus();
            canvas.addEventListener(EVENTS.Keydown, handleKeyDown);
            canvas.addEventListener(EVENTS.Keyup, handleKeyUp);
            canvas.addEventListener(EVENTS.Click, setFocused);
            canvas.addEventListener(EVENTS.Blur, handleBlur);
        }

        return () => {
            canvas?.removeEventListener(EVENTS.Keydown, handleKeyDown);
            canvas?.removeEventListener(EVENTS.Keyup, handleKeyUp);
            canvas?.removeEventListener(EVENTS.Click, setFocused);
            canvas?.removeEventListener(EVENTS.Blur, handleBlur);
        };

        function handleKeyDown(e: KeyboardEvent) {
            if (HANDLED_KEYS.includes(e.code as EventKey)) {
                const eventKey = e.code as EventKey;
                const keyJustPressed = !pressedKeys.has(eventKey);
                setPressedKeys(pressedKeys => pressedKeys.add(eventKey));
                if (keyJustPressed) updateSpeed(eventKey);
            }
        }

        function handleKeyUp(e: KeyboardEvent) {
            if (HANDLED_KEYS.includes(e.code as EventKey)) {
                const eventKey = e.code as EventKey;
                const keyJustReleased = pressedKeys.has(eventKey);
                setPressedKeys(pressedKeys => {
                    pressedKeys.delete(eventKey);
                    return pressedKeys;
                });
                if (keyJustReleased) updateSpeed(eventKey);
            }
        }

        function updateSpeed(eventKey: EventKey) {
            if (eventKey === 'ShiftLeft') {
                updateAcceleration();
            } else if (isKeyToMove(eventKey)) {
                updateBaseSpeed();
            }
        }

        function isKeyToMove(eventKey: EventKey): boolean {
            return (
                eventKey === 'KeyW' ||
                eventKey === 'KeyS' ||
                eventKey === 'KeyQ' ||
                eventKey === 'KeyE' ||
                eventKey === 'KeyA' ||
                eventKey === 'KeyD'
            );
        }

        function updateAcceleration() {
            setAcceleration(pressedKeys.has('ShiftLeft') ? 2 : 1);
        }

        function updateBaseSpeed() {
            let updatedBaseSpeed = 1;
            if (screenCenter && camera && scene) {
                let target: Cesium.Cartesian3 | undefined = scene.pickPosition(screenCenter);
                if (!target) {
                    const ray = camera.getPickRay(screenCenter);
                    if (ray) target = scene.globe.pick(ray, scene);
                }
                if (target) updatedBaseSpeed = Cesium.Cartesian3.distance(camera.position, target) / 100.0;
            }
            setBaseSpeed(updatedBaseSpeed);
        }

        function setFocused() {
            canvas?.focus();
        }

        // Prevent any movement if canvas loses focus
        function handleBlur() {
            setPressedKeys(pressedKeys => {
                pressedKeys.clear();
                return pressedKeys;
            });
        }
    }, [canvas, isCesiumLoaded, screenCenter, camera, scene, pressedKeys]);

    return (
        <Clock
            onTick={clock => {
                if (screenCenter && camera && scene && canvas && pressedKeys.size > 0) {
                    const up = scene.globe.ellipsoid.geodeticSurfaceNormal(camera.position);
                    const forward = camera.direction;
                    const right = Cesium.Cartesian3.cross(
                        Cesium.Cartesian3.cross(up, camera.right, new Cesium.Cartesian3()),
                        up,
                        new Cesium.Cartesian3()
                    );

                    pressedKeys.forEach(key => {
                        if (key === 'KeyW') {
                            camera.move(forward, moveRate);
                        }
                        if (key === 'KeyS') {
                            camera.move(forward, -moveRate);
                        }
                        if (key === 'KeyQ') {
                            camera.move(up, moveRate);
                        }
                        if (key === 'KeyE') {
                            camera.move(up, -moveRate);
                        }
                        if (key === 'KeyD') {
                            camera.move(right, moveRate);
                        }
                        if (key === 'KeyA') {
                            camera.move(right, -moveRate);
                        }
                        if (key === 'ArrowRight') {
                            camera.look(up, rotationRate);
                        }
                        if (key === 'ArrowLeft') {
                            camera.look(up, -rotationRate);
                        }
                        if (key === 'ArrowDown') {
                            camera.look(right, rotationRate);
                        }
                        if (key === 'ArrowUp') {
                            camera.look(right, -rotationRate);
                        }
                    });
                }
            }}
        />
    );
}
