import { Units } from '../../../sharedConstants';
import { ElevationProfileResult } from '../../../store/helpers/elevation-profile/ElevationProfile';

import generateElevationProfileCSVData from './generateElevationProfileCSVData';

export default function exportAsCsv(
    data: ElevationProfileResult,
    projectName: string,
    layerName: string,
    polylineName: string,
    units: Units
) {
    const profile = generateElevationProfileCSVData(data, units);

    const link = document.createElement('a');
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(profile));
    link.setAttribute('download', `Elevation_profile_${projectName}_${layerName}_${polylineName}.csv`);
    link.click();
}
