import { ChangeEvent } from 'react';

type Props = {
    onChange?(e: ChangeEvent<HTMLInputElement>): void;
    checked?: boolean;
    disabled?: boolean;
};

export default function Checkbox({ checked, onChange, disabled }: Props) {
    return (
        <label className='checkbox-wrapper'>
            <input
                type='checkbox'
                onChange={e => {
                    onChange?.(e);
                }}
                checked={checked}
                disabled={disabled}
            />
            <span className='checkbox-styler' />
        </label>
    );
}
