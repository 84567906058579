import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Vector2d } from 'konva/lib/types';
import { MutableRefObject, useCallback, useRef, useState } from 'react';
import { IS_TOUCH_DEVICE } from '../../../sharedConstants';

const MIN_SCALE = 1;

if (IS_TOUCH_DEVICE) {
    // More precise capture of touch events
    Konva.hitOnDragEnabled = true;
}

export function useZoom() {
    const [scale, setScale] = useState({ x: 1, y: 1 });
    const lastCenter: MutableRefObject<Vector2d | null> = useRef(null);
    const lastDist = useRef(0);

    const zoomFn = useCallback((e: KonvaEventObject<WheelEvent>) => {
        e.evt.preventDefault();
        const scaleBy = 1.1;
        const stage = e.target.getStage()!;
        const oldScale = stage.scaleX();

        const pointer = stage.getPointerPosition()!;

        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale
        };

        // how to scale? Zoom in? Or zoom out?
        let direction = e.evt.deltaY < 0 ? 1 : -1;

        // when we zoom on trackpad, e.evt.ctrlKey is true
        // in that case lets revert direction
        if (e.evt.ctrlKey) {
            direction = -direction;
        }

        let newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

        if (newScale < MIN_SCALE) newScale = MIN_SCALE;
        setScale({ x: newScale, y: newScale });

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale
        };
        stage.position(newPos);
    }, []);

    const zoomFnTouchMove = useCallback((e: KonvaEventObject<TouchEvent>) => {
        const touch1 = e.evt.touches[0];
        const touch2 = e.evt.touches[1];

        if (touch1 && touch2) {
            e.evt.preventDefault();
            const stage = e.target.getStage()!;
            const layer = e.target.getLayer()!;
            // if the stage was under Konva's drag&drop
            // we need to stop it, and implement our own pan logic with two pointers
            if (layer.isDragging()) layer.stopDrag();

            const p1 = { x: touch1.clientX, y: touch1.clientY };
            const p2 = { x: touch2.clientX, y: touch2.clientY };

            if (!lastCenter.current) {
                lastCenter.current = getCenter(p1, p2);
                return;
            }
            const newCenter = getCenter(p1, p2);

            const dist = getDistance(p1, p2);

            if (!lastDist.current) lastDist.current = dist;

            // local coordinates of center point
            const pointTo = {
                x: (newCenter.x - stage.x()) / stage.scaleX(),
                y: (newCenter.y - stage.y()) / stage.scaleX()
            };

            const scale = stage.scaleX() * (dist / lastDist.current);
            setScale({ x: scale, y: scale });

            // calculate new position of the stage
            const dx = newCenter.x - lastCenter.current.x;
            const dy = newCenter.y - lastCenter.current.y;

            const newPos = {
                x: newCenter.x - pointTo.x * scale + dx,
                y: newCenter.y - pointTo.y * scale + dy
            };

            stage.position(newPos);

            lastDist.current = dist;
            lastCenter.current = newCenter;
        }

        function getDistance(p1: Vector2d, p2: Vector2d) {
            return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
        }

        function getCenter(p1: Vector2d, p2: Vector2d) {
            return {
                x: (p1.x + p2.x) / 2,
                y: (p1.y + p2.y) / 2
            };
        }
    }, []);

    const zoomFnTouchEnd = useCallback(() => {
        lastDist.current = 0;
        lastCenter.current = null;
    }, []);

    const resetScale = useCallback(() => {
        setScale({ x: 1, y: 1 });
    }, []);

    return { scale, zoomFn, resetScale, setScale, zoomFnTouchEnd, zoomFnTouchMove };
}

export function useDragBoundsCheck(): (e: KonvaEventObject<DragEvent>) => void {
    return e => {
        const layerBox = e.target.getClientRect();
        const absolutePosition = e.target.getAbsolutePosition();
        const offsetX = layerBox.x - absolutePosition.x;
        const offsetY = layerBox.y - absolutePosition.y;
        const newAbsPos = { ...absolutePosition };
        const stage = e.target.getStage()!;
        if (stage.scale()?.x === 1) {
            if (layerBox.x < 0) {
                newAbsPos.x = -offsetX;
            }
            if (layerBox.y < 0) {
                newAbsPos.y = -offsetY;
            }
            if (layerBox.x + layerBox.width > stage.width()) {
                newAbsPos.x = stage.width() - layerBox.width - offsetX;
            }
            if (layerBox.y + layerBox.height > stage.height()) {
                newAbsPos.y = stage.height() - layerBox.height - offsetY;
            }
            e.target.setAbsolutePosition(newAbsPos);
        }
    };
}
