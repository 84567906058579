import classNames from 'classnames';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { useCameraImageUrl } from '../../../hooks/useCameraImageUrl';
import getFilenameExtension from '../../../lib/getFilenameExtension';
import isImageFormatViewable from '../../../lib/isImageFormatViewable';
import { FULL_IMAGE_SIZE_LIMIT, inspectorImageListThumbnailParam as thumbnail } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { ExtendedCamera, selectCamerasFilteredByPoint } from '../../../store/slices/cameras';
import { setSelectedCamera } from '../../../store/slices/projectView';
import { ReactComponent as SvgLoader } from '../../../svg/general/loader.svg';
import { ReactComponent as SvgClose } from '../../../svg/general/x-icon.svg';
import { ReactComponent as SvgIssue } from '../../../svg/tool_inspection/issue.svg';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import CameraPhotoInfo from '../camera-photo-info/CameraPhotoInfo';
import InspectedCanvasImage from './InspectedCanvasImage';

type Props = {
    camera: ExtendedCamera;
    wrapperWidth: number | undefined;
    wrapperHeight: number | undefined;
};

export default function InspectedImage({ camera, wrapperWidth, wrapperHeight }: Props) {
    const { t } = useTranslation('projectView');
    const dispatch: AppDispatch = useDispatch();
    const { owned } = useContext(ProjectViewAccessContext);
    const camerasFilteredByPoint = useSelector(selectCamerasFilteredByPoint);
    const [isAddIssueActive, setIsAddIssueActive] = useState(false);

    const isViewable =
        isImageFormatViewable(getFilenameExtension(camera.fileName || '')) &&
        camera.sizeInBytes <= FULL_IMAGE_SIZE_LIMIT;
    const [isLoading, setIsLoading] = useState(isViewable);
    const [hasFailed, setHasFailed] = useState(false);

    const { url, preloadThumbUrl } = useCameraImageUrl(camera, thumbnail);

    return (
        <div className='image-container' style={{ width: wrapperWidth, height: wrapperHeight }}>
            {isLoading && (
                <>
                    <div className='background-image' style={{ backgroundImage: `url(${preloadThumbUrl})` }} />
                    <div className='image-loading'>
                        <div className='image-loader'>
                            <div className='loader'>
                                <SvgLoader className='loader-svg' />
                            </div>
                            <div className='loader-text'>{t('imageViewer.loader')}</div>
                        </div>
                    </div>
                </>
            )}
            {isViewable && !hasFailed ? (
                <InspectedCanvasImage
                    camera={camera}
                    imageUrl={url}
                    isLoading={isLoading}
                    setHasFailed={setHasFailed}
                    onLoad={() => {
                        setIsLoading(false);
                    }}
                    setIsAddIssueActive={setIsAddIssueActive}
                    containerSize={{
                        width: wrapperWidth!,
                        height: wrapperHeight!
                    }}
                    isAddIssueActive={isAddIssueActive}
                    showIssues
                />
            ) : (
                <div className='image-stub'>
                    <i className='icon icon-no_preview' />
                    <div className='stub-text'>{t('imageViewer.stub')}</div>
                </div>
            )}

            <div className='image-controls'>
                {owned && (
                    <TippyTooltip tooltipText={t('imageViewer.tooltipAddIssue')}>
                        <div
                            className={classNames('image-control', {
                                active: isAddIssueActive,
                                disabled: hasFailed || isLoading || !isViewable
                            })}
                            onClick={() => {
                                if (!hasFailed) {
                                    const nextIsActive = !isAddIssueActive;
                                    setIsAddIssueActive(nextIsActive);
                                }
                            }}
                        >
                            <SvgIssue data-testid={'addIssueControl'} />
                        </div>
                    </TippyTooltip>
                )}
                <TippyTooltip tooltipText={t('imageViewer.tooltipClose')}>
                    <div
                        className='image-control'
                        onClick={e => {
                            dispatch(setSelectedCamera(undefined));
                        }}
                    >
                        <SvgClose data-testid={'closeControl'} />
                    </div>
                </TippyTooltip>
            </div>
            <CameraPhotoInfo camera={camera} cameras={camerasFilteredByPoint} />
        </div>
    );
}
