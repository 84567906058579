import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiError, CoordinateSystemType, Units, WGS84_EPSG_CODE } from '../../sharedConstants';
import { coordinateSystemApi } from '../../api/initApis';
import axios, { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { AppDispatch, ApplicationState } from '../index';
import { CoordinateSystemInfo } from '../../generated/cloud-frontend-api';
import { getCrsProperty, getRelatedAxis } from '../../lib/coordinatesHelper';
import { saveRootStructureProperty } from './structure';
import isProjectBelongsUser from '../../lib/isProjectBelongsUser';

interface CoordinateSystemState {
    defaultCrs: CoordinateSystem[];
    personalCrs: CoordinateSystem[];
    projectCrs: CoordinateSystem[];
    currentCrs: DeterminedCoordinateSystem;
    units: Units;
}

export interface CoordinateSystem {
    uid: string;
    name: string;
    epsgCode: number;
}

let WGS_84: DeterminedCoordinateSystem = {
    uid: '',
    name: 'WGS84',
    epsgCode: WGS84_EPSG_CODE,
    type: CoordinateSystemType.GEOGRAPHIC,
    defaultUnit: Units.METRE,
    wkt: '',
    axis: getRelatedAxis(CoordinateSystemType.GEOGRAPHIC),
    invalidateHeight: false
};

export interface DeterminedCoordinateSystem extends CoordinateSystem {
    type: CoordinateSystemType;
    defaultUnit: Units;
    wkt: string;
    axis: string[];
    invalidateHeight: boolean;
}

const initialState: CoordinateSystemState = {
    defaultCrs: [],
    personalCrs: [],
    projectCrs: [],
    currentCrs: WGS_84,
    units: Units.METRE
};

const name = 'coordinateSystems';

export const getDefaultCoordinateSystems = createAsyncThunk(`${name}/getDefaultCoordinateSystems`, async () => {
    const { data } = await coordinateSystemApi.getDefaultCoordinateSystems({
        headers: { Accept: 'application/stream+json' }
    });
    let coordinateSystems: CoordinateSystem[] = data as CoordinateSystem[];
    return coordinateSystems;
});

export const getPersonalCoordinateSystems = createAsyncThunk(`${name}/getPersonalCoordinateSystems`, async () => {
    const { data } = (await coordinateSystemApi.getPersonalCoordinateSystems({
        headers: { Accept: 'application/stream+json' }
    })) as unknown as AxiosResponse<string | CoordinateSystemInfo>;

    if (typeof data === 'string') {
        let coordinateSystems: CoordinateSystem[] = [];
        coordinateSystems = _.compact(data.split('\n')).map(part => JSON.parse(part)) as CoordinateSystem[];
        return coordinateSystems;
    }
    return data;
});

export const createCoordinateSystem = createAsyncThunk(
    `${name}/createCoordinateSystem`,
    ({ content }: { content: string }, { rejectWithValue }) => {
        return coordinateSystemApi
            .createCoordinateSystem(content, { headers: { Accept: 'application/stream+json' } })
            .then(({ data }) => data)
            .catch((error: AxiosError<ApiError>) => rejectWithValue(error?.response?.data?.message || ''));
    }
);

export const updateCurrentCoordinateSystem = createAsyncThunk<
    DeterminedCoordinateSystem,
    { crsInfo: CoordinateSystem; isInEmbedView: boolean },
    { dispatch: AppDispatch; state: ApplicationState; rejectValue: DeterminedCoordinateSystem }
>(
    `${name}/updateCurrentCoordinateSystem`,
    async ({ crsInfo, isInEmbedView }, { dispatch, getState, rejectWithValue }) => {
        const previousCrs = getState().coordinateSystems.currentCrs;
        const projectInfo = getState().project.projectInfo;
        const isOwner = isProjectBelongsUser(getState().sharing.accessInfo, projectInfo);
        try {
            dispatch(setCoordinateSystem(crsInfo));
            const projectUid = projectInfo.id!;
            const determinedCrs = isInEmbedView
                ? await getDeterminedCoordinateSystem(projectUid, crsInfo.uid, '', getState().sharing.embedCode)
                : isOwner
                ? await getDeterminedCoordinateSystem(projectUid, crsInfo.uid)
                : await getDeterminedCoordinateSystem(projectUid, crsInfo.uid, getState().sharing.access.accessKey, '');
            if (determinedCrs.defaultUnit && determinedCrs.defaultUnit !== getState().coordinateSystems.units) {
                dispatch(setUnits(determinedCrs.defaultUnit));
                dispatch(
                    saveRootStructureProperty({
                        propertyName: 'units',
                        propertyValue: determinedCrs.defaultUnit
                    })
                );
            }
            return determinedCrs;
        } catch (error) {
            return rejectWithValue(previousCrs);
        }
    }
);

export const initCurrentCoordinateSystem = createAsyncThunk<
    DeterminedCoordinateSystem,
    { crsInfo: CoordinateSystem; projectId: string; access?: string; embed?: string }
>(`${name}/initCurrentCoordinateSystem`, async ({ crsInfo, projectId, access, embed }) => {
    return getDeterminedCoordinateSystem(projectId, crsInfo.uid, access, embed);
});

export const deleteProjectCoordinateSystem = createAsyncThunk<
    string,
    string,
    { dispatch: AppDispatch; state: ApplicationState; rejectValue: null }
>(`${name}/deleteProjectCoordinateSystem`, async (crsUid, { dispatch, getState, rejectWithValue }) => {
    if (WGS_84.uid === crsUid) {
        return rejectWithValue(null);
    }
    const currentCrs = getState().coordinateSystems.currentCrs;
    if (currentCrs.uid === crsUid) {
        dispatch(resetCurrentCoordinateSystem());
        dispatch(
            saveRootStructureProperty({
                propertyName: 'coordinateSystem',
                propertyValue: { uid: WGS_84.uid, epsgCode: WGS84_EPSG_CODE, name: WGS_84.name }
            })
        );
    }
    return crsUid;
});

export async function getDeterminedCoordinateSystem(
    projectUid: string,
    crsUid: string,
    access?: string,
    embed?: string
) {
    let url = `/api/projects/${projectUid}/coordinate-systems/${crsUid}`;
    if (access) url = url.concat(`?access=${access}`);
    if (embed) url = url.concat(`?embed=${embed}`);

    return axios
        .request({ url, method: 'GET', headers: { Accept: 'application/json' } })
        .then((res: AxiosResponse<DeterminedCoordinateSystem>) => {
            const determinedCrs = {
                ...res.data
            } as DeterminedCoordinateSystem;
            if (determinedCrs.wkt) {
                const { type, unit, wkt, invalidateHeight } = getCrsProperty(determinedCrs.wkt)!;
                determinedCrs.type = type;
                determinedCrs.axis = getRelatedAxis(type);
                determinedCrs.defaultUnit = unit;
                determinedCrs.wkt = wkt;
                determinedCrs.invalidateHeight = invalidateHeight;
            }
            return determinedCrs;
        });
}

const coordinateSystemsSlice = createSlice({
    name,
    initialState,
    reducers: {
        setProjectCoordinateSystems(state, { payload }: PayloadAction<CoordinateSystemInfo[] | undefined>) {
            if (payload) {
                const projectCrs: CoordinateSystem[] = [];
                projectCrs.push(...(payload as CoordinateSystem[]));
                if (!projectCrs.find(cs => cs.epsgCode === WGS84_EPSG_CODE)) {
                    projectCrs.push(WGS_84);
                }
                state.projectCrs = projectCrs;
            } else {
                state.projectCrs = [WGS_84];
            }
        },
        setCoordinateSystem(state, { payload }: PayloadAction<CoordinateSystem>) {
            state.currentCrs = { ...payload } as DeterminedCoordinateSystem;
        },
        resetCurrentCoordinateSystem(state) {
            state.currentCrs = WGS_84;
        },
        setUnits(state, { payload }: PayloadAction<any>) {
            state.units = payload;
        },
        resetUnits(state) {
            state.units = Units.METRE;
        },
        resetProjectViewCrsState(state) {
            state.units = Units.METRE;
            state.projectCrs = [];
            state.currentCrs = WGS_84;
        },
        addProjectCoordinateSystem(state, { payload }: PayloadAction<string>) {
            if (!state.projectCrs.find(cs => cs.uid === payload)) {
                const newProjectCrs = state.personalCrs.concat(state.defaultCrs).find(cs => cs.uid === payload);
                if (newProjectCrs) state.projectCrs.unshift(newProjectCrs);
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getDefaultCoordinateSystems.fulfilled, (state, { payload }) => {
                const defaultCoordinateSystems = payload as CoordinateSystem[];
                state.defaultCrs = defaultCoordinateSystems;
                const wgs84 = defaultCoordinateSystems.find(s => s.epsgCode === WGS84_EPSG_CODE);
                if (wgs84?.uid) {
                    WGS_84 = { ...WGS_84, uid: wgs84.uid };
                    if (state.currentCrs.epsgCode === WGS84_EPSG_CODE && !state.currentCrs.uid) {
                        state.currentCrs.uid = wgs84.uid;
                    }
                    const projectWgs84 = state.projectCrs.find(cs => cs.epsgCode === WGS84_EPSG_CODE);
                    if (projectWgs84 && !projectWgs84.uid) {
                        projectWgs84.uid = wgs84.uid;
                    }
                }
            })
            .addCase(getPersonalCoordinateSystems.fulfilled, (state, { payload }) => {
                state.personalCrs = payload as CoordinateSystem[];
            })
            .addCase(createCoordinateSystem.fulfilled, (state, { payload }) => {
                if (!state.personalCrs.find(cs => cs.uid === payload?.uid))
                    state.personalCrs.unshift(payload as Required<CoordinateSystem>);
            })
            .addCase(updateCurrentCoordinateSystem.fulfilled, (state, { payload }) => {
                const { uid } = payload;
                if (state.currentCrs.uid !== uid) {
                    return;
                }
                state.currentCrs = payload;
            })
            .addCase(updateCurrentCoordinateSystem.rejected, (state, { payload }) => {
                state.currentCrs = payload!;
            })
            .addCase(initCurrentCoordinateSystem.fulfilled, (state, { payload }) => {
                state.currentCrs = payload;
            })
            .addCase(initCurrentCoordinateSystem.rejected, state => {
                state.units = Units.METRE;
                state.currentCrs = WGS_84;
            })
            .addCase(deleteProjectCoordinateSystem.fulfilled, (state, { payload }) => {
                const index = state.projectCrs.findIndex(crs => crs.uid === payload);
                if (index !== -1) state.projectCrs.splice(index, 1);
            });
    }
});

export default coordinateSystemsSlice.reducer;

export const {
    setProjectCoordinateSystems,
    setCoordinateSystem,
    setUnits,
    addProjectCoordinateSystem,
    resetProjectViewCrsState,
    resetCurrentCoordinateSystem
} = coordinateSystemsSlice.actions;

export const makeSelectCrsFromProjectCrsById = () =>
    createSelector(
        (state: ApplicationState) => state.coordinateSystems.projectCrs,
        (state: ApplicationState, crsId: string) => crsId,
        (projectCrs, crsId) => projectCrs.find(c => c.uid === crsId)
    );

export const selectPersonalAndDefaultCoordinateSystems = createSelector(
    (state: ApplicationState) => state.coordinateSystems,
    ({ personalCrs, defaultCrs }) => personalCrs.concat(defaultCrs).filter(crs => !!crs.name)
);
