import { isEmpty } from 'lodash';
import { ReadonlyDeep } from 'type-fest';
import { ProjectPartType, RelatedProjectInfo, SourceType, Status, VisualData } from '../generated/cloud-frontend-api';
import getArtifactDisplayName from '../lib/getArtifactDisplayName';
import getFilename from '../lib/getFilename';
import { ExtendedDatasetInfo } from '../store/slices/datasetfilesUpload';

type DatasetInfo = ReadonlyDeep<
    Required<Omit<ExtendedDatasetInfo, 'visualData' | 'parentProject' | 'coordinateSystemUid'>> & {
        visualData?: VisualData;
        parentProject: RelatedProjectInfo | null;
        coordinateSystemUid: string | undefined;
    }
>;

export class Dataset implements DatasetInfo {
    constructor(private readonly dataset: ExtendedDatasetInfo) {}

    get uploadPercent() {
        return this.dataset.uploadPercent ?? 0;
    }
    get sourceData() {
        return this.dataset.sourceData!;
    }
    get assetUid() {
        return this.dataset.assetUid!;
    }
    get datasetUid() {
        return this.dataset.datasetUid!;
    }
    get sizeInBytes() {
        return this.dataset.sizeInBytes ?? 0;
    }
    get projectPartType() {
        return this.dataset.projectPartType!;
    }
    get properties() {
        return this.dataset.properties || {};
    }
    get parentProject() {
        return this.dataset.parentProject || null;
    }
    get linkedProjects() {
        return this.dataset.linkedProjects || [];
    }
    get coordinateSystemUid() {
        return this.dataset.coordinateSystemUid;
    }
    get visualData() {
        return this.dataset.visualData;
    }
    get name(): string {
        if (this.isArtifact) return getArtifactDisplayName(this.dataset);
        return getFilename(this.dataset.name || '');
    }

    get hasVisualData(): boolean {
        return !isEmpty(this.visualData);
    }

    private get isSourceDataReady(): boolean {
        return !this.sourceData?.status;
    }

    private isSourceDataInStatus(status: NonNullable<ExtendedDatasetInfo['sourceData']>['status']) {
        return this.sourceData?.status === status;
    }

    private isVisualDataInStatus(status: Status) {
        return this.visualData?.status === status;
    }

    get isPendingManualAction(): boolean {
        return !this.hasVisualData && this.isSourceDataInStatus('pending');
    }

    get isValidating(): boolean {
        return !this.hasVisualData && this.isSourceDataInStatus('validating');
    }

    get hasFailedValidation(): boolean {
        return !this.hasVisualData && this.isSourceDataInStatus('validationError');
    }

    get hasFailedUpload(): boolean {
        return !this.hasVisualData && this.isSourceDataInStatus('uploadError');
    }

    get isUploading(): boolean {
        return this.isSourceDataReady && !this.hasVisualData;
    }

    get isPublishing(): boolean {
        return this.isSourceDataReady && this.hasVisualData && this.isVisualDataInStatus(Status.IN_PROGRESS);
    }

    get hasFailedPublishing(): boolean {
        return this.isSourceDataReady && this.hasVisualData && this.isVisualDataInStatus(Status.FAILED);
    }

    get hasAbsentVisualData(): boolean {
        return this.isSourceDataReady && this.hasVisualData && this.isVisualDataInStatus(Status.ABSENT);
    }

    get isCompleted(): boolean {
        return this.isSourceDataReady && this.hasVisualData && this.isVisualDataInStatus(Status.COMPLETED);
    }

    get isInErrorState(): boolean {
        return this.hasFailedPublishing || this.hasFailedValidation || this.hasFailedUpload;
    }

    get isInProgress(): boolean {
        return this.isValidating || this.isUploading || this.isPublishing;
    }

    get isArtifact(): boolean {
        return this.projectPartType === ProjectPartType.TILESETS;
    }

    get canBeDeleted(): boolean {
        return this.hasVisualData || this.isInErrorState;
    }

    get isUploadAbortable(): boolean {
        return this.isPendingManualAction || this.isValidating || this.isUploading;
    }

    static defaultOpacity(sourceType: SourceType): number {
        if (sourceType === SourceType.DEM || sourceType === SourceType.DEM_3_D) return 0.5;
        return 1;
    }
}
