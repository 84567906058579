import classNames from 'classnames';
import { unwrapResult } from '@reduxjs/toolkit';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useKey, useOnWindowResize, usePreviousImmediate, useWillUnmount } from 'rooks';
import BottomConfirmMessage from '../components/Elements/bottom-message/BottomConfirmMessage';
import ArtifactViewer from '../components/ProjectView/ArtifactViewer';
import ViewerSplit from '../components/ProjectView/ArtifactViewer/ViewerSplit';
import MapSettings from '../components/ProjectView/MapSettings/MapSettings';
import BottomBar from '../components/ProjectView/bottom-bar/BottomBar';
import InspectOverlay from '../components/ProjectView/cameras-inspection/InspectOverlay';
import InspectedImageContainer from '../components/ProjectView/cameras-inspection/InspectedImageContainer';
import DarkenedOverlay from '../components/ProjectView/darkened-overlay/DarkenedOverlay';
import ElevationProfileExpandedTool from '../components/ProjectView/elevation-profile/ElevationProfileExpandedTool';
import ImageViewer from '../components/ProjectView/image-viewer/ImageViewer';
import MapControls from '../components/ProjectView/map-controls/MapControls';
import ProjectViewNotification from '../components/ProjectView/notification/ProjectViewNotification';
import ViewpointDescription from '../components/ProjectView/viewpoint-description/ViewpointDescription';
import TourPlayer from '../components/ProjectView/tour-player/TourPlayer';
import UndoOperation from '../components/ProjectView/undo-operation/UndoOperation';
import ViewLoader from '../components/ProjectView/view-loader/ViewLoader';
import ViewerError from '../components/ProjectView/viewer-error/ViewerError';
import InspectionSidebar, { TWO_PANELS_MIN_WIDTH } from '../components/inspection-sidebar/InspectionSidebar';
import ProjectStructureSidebar from '../components/project-structure-sidebar/ProjectStructureSidebar';
import ProjectViewAccessContext from '../contexts/ProjectViewAccessContext';
import { ProjectStatus, ProjectType } from '../generated/cloud-frontend-api/model';
import useOnBeforeUnload from '../hooks/useOnBeforeUnload';
import useSidebarsToggleButtonsReposition from '../hooks/useSidebarsToggleButtonsReposition';
import isLastPipelineProcessing from '../lib/isLastPipelineProcessing';
import isProjectBelongsUser from '../lib/isProjectBelongsUser';
import { WEBGL_SUPPORTED } from '../sharedConstants';
import { AppDispatch, useSelector } from '../store';
import { SelectedObject } from '../store/helpers/interfaces';
import { selectedCameraSelector } from '../store/selectors';
import { setSelectedObject } from '../store/sharedActions';
import { selectPendingDatasets } from '../store/slices/datasets';
import { resetPresentationState } from '../store/slices/presentation';
import { progressTick } from '../store/slices/progress';
import {
    setManualConfigurationForUploadRequiredMessageVisible,
    setUpcomingStructureChangesMessageVisible,
    setUploadsCompleteMessageVisible
} from '../store/slices/projectStructure';
import {
    resetProjectView,
    resetProjectViewMode,
    setInspectorVisibility,
    setViewerError
} from '../store/slices/projectView';

type Props = {
    isLoading: boolean;
    isAnyQueryPending: boolean;
    hasQueriedForProject: boolean;
};

export default function ProjectView({ isLoading, isAnyQueryPending, hasQueriedForProject }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projects', 'projectView']);
    const hasCesiumError = useSelector(state => state.projectView.hasCesiumError);
    const isCesiumLoaded = useSelector(state => state.projectView.isCesiumLoaded);
    const isGalleryVisible = useSelector(state => state.projectView.galleryVisibility.isVisible);
    const isInspectorExpanded = useSelector(state => state.projectView.isInspectorExpanded);
    const isElevationProfileExpanded = useSelector(state => state.projectView.isElevationProfileExpanded);
    const isPresentationSetupEnabled = useSelector(state => state.projectView.isPresentationSetupEnabled);
    const isTerrainProviderLoaded = useSelector(state => state.projectView.isTerrainProviderLoaded);
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const isSidebarExpanded = useSelector(state => state.projectView.isSidebarExpanded);
    const uploadsCompleteMessageVisible = useSelector(state => state.projectFiles.uploadsCompleteMessageVisible);
    const manualConfigurationForUploadRequiredMessageVisible = useSelector(
        state => state.projectFiles.manualConfigurationForUploadRequiredMessageVisible
    );
    const pendingDatasets = useSelector(state => selectPendingDatasets(state));
    const isCamerasInspectionEnabled = useSelector(state => state.projectView.isCamerasInspectionEnabled);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const structure = useSelector(state => state.project.structure);
    const upcomingStructureChangesMessageVisible = useSelector(
        state => state.projectFiles.upcomingStructureChangesMessageVisible
    );
    const accessInfo = useSelector(state => state.sharing.accessInfo);
    const selectedCamera = useSelector(state => selectedCameraSelector(state));
    const pipelineUid = projectInfo?.pipeline?.pipelineUid!;
    const pollerTimer = useRef(0);
    const { owned } = useContext(ProjectViewAccessContext);
    const loading = WEBGL_SUPPORTED && (isAnyQueryPending || !isCesiumLoaded);
    const location = useLocation<{ projectName?: string }>();
    const isBlockedByTerrainProvider = !loading && !isTerrainProviderLoaded;
    const isOwnedProject = isProjectBelongsUser(accessInfo, projectInfo);
    const hasActivePipeline =
        projectInfo?.pipeline &&
        (projectInfo.status === ProjectStatus.INPROGRESS || projectInfo.status === ProjectStatus.ABORTING);
    const isUpdatingStructureActive = hasActivePipeline && !isLastPipelineProcessing(projectInfo!);
    const isProcessingActive = hasActivePipeline && isLastPipelineProcessing(projectInfo!);
    const previousUpdatingProjectStructureActive = usePreviousImmediate(isUpdatingStructureActive);
    const previousProcessingActive = usePreviousImmediate(isProcessingActive);
    const name = location.state?.projectName || projectInfo?.name!;

    const overlayAreaRef = useRef<HTMLDivElement>(null);

    const showTourPlayer = isCesiumLoaded && isPresentationSetupEnabled;
    const showOverlayToolsArea = showTourPlayer || isElevationProfileExpanded;
    const hasViewerError = hasCesiumError || !WEBGL_SUPPORTED;

    const wrapper = useRef<HTMLDivElement>(null!);
    const [wrapperWidth, setWrapperWidth] = useState<number>(null!);
    const [wrapperHeight, setWrapperHeight] = useState<number>(null!);

    useEffect(() => {
        setWrapperWidth(wrapper.current?.offsetWidth);
        setWrapperHeight(wrapper.current?.offsetHeight);
    }, []);

    useOnWindowResize(() => {
        const newWidth = wrapper.current?.offsetWidth;
        setWrapperWidth(newWidth);
        setWrapperHeight(wrapper.current?.offsetHeight);
        if (newWidth <= TWO_PANELS_MIN_WIDTH && isSidebarExpanded) {
            dispatch(setInspectorVisibility(false));
        }
    });

    useOnBeforeUnload();

    // reset UI on page load
    useWillUnmount(async () => {
        // AUTOBATCH
        dispatch(resetProjectView({}));
        dispatch(resetProjectViewMode());
        dispatch(resetPresentationState());
        dispatch(setUploadsCompleteMessageVisible(false));
        dispatch(setSelectedObject({} as SelectedObject));
    });

    // handle focus
    useKey(['Tab'], (e: any) => {
        !isInspectorExpanded && e.preventDefault();
    });

    // handle sidebars toggle buttons y-position
    const [elevationProfileTopCoord, setElevationProfileTopCoord] = useState<number>();
    const sidebarsToggleTransition = useSidebarsToggleButtonsReposition(
        wrapper.current,
        wrapperHeight,
        elevationProfileTopCoord
    );

    useEffect(() => {
        if (
            isUpdatingStructureActive !== previousUpdatingProjectStructureActive ||
            isProcessingActive !== previousProcessingActive
        ) {
            isUpdatingStructureActive || isProcessingActive
                ? dispatch(setUpcomingStructureChangesMessageVisible(true))
                : dispatch(setUpcomingStructureChangesMessageVisible(false));
        }
    }, [
        dispatch,
        isUpdatingStructureActive,
        previousUpdatingProjectStructureActive,
        isProcessingActive,
        previousProcessingActive
    ]);

    useEffect(() => {
        if (isOwnedProject && hasActivePipeline && pipelineUid && projectInfo?.id && !pollerTimer.current)
            startPollingProgress(projectInfo.id, pipelineUid);

        return () => {
            window.clearInterval(pollerTimer.current);
        };

        async function startPollingProgress(projectId: string, processId: string) {
            dispatch(progressTick({ projectId, processId }));
            pollerTimer.current = window.setInterval(() => {
                dispatch(progressTick({ projectId, processId }))
                    .then(unwrapResult)
                    .then(({ status }) => {
                        if (status === ProjectStatus.COMPLETED || status === ProjectStatus.FAILED) {
                            window.clearInterval(pollerTimer.current);
                        }
                    });
            }, 7000);
        }
    }, [hasActivePipeline, isOwnedProject, projectInfo?.id, pipelineUid, dispatch]);

    return (
        <>
            <div id='mapWrapper' className='map-wrapper' ref={wrapper}>
                {!isAnyQueryPending &&
                    ((projectInfo?.type === ProjectType.METASHAPE && Boolean(structure?.chunks?.length)) ||
                        (projectInfo?.type === ProjectType.METASHAPE && projectInfo.parts?.publication?.size! === 0) ||
                        projectInfo?.type !== ProjectType.METASHAPE) && (
                        <div
                            className='map-placeholder'
                            style={{ visibility: !isCesiumLoaded || isAnyQueryPending ? 'hidden' : 'visible' }}
                        >
                            {WEBGL_SUPPORTED && <ArtifactViewer key={projectInfo.id} />}
                            {isCompareToolEnabled && <ViewerSplit />}
                        </div>
                    )}

                <ViewpointDescription />

                <div className='map-tools'></div>

                {isLoading && (
                    <div className='map-loading'>
                        <ViewLoader type='loading' />
                    </div>
                )}

                {isBlockedByTerrainProvider && !hasViewerError && (
                    <div className='map-loading'>
                        <ViewLoader type='blocked' />
                    </div>
                )}

                {!isLoading && (
                    <>
                        {hasCesiumError && <ViewerError type='badrender' />}
                        {!WEBGL_SUPPORTED && <ViewerError type='nowebgl' />}
                    </>
                )}

                <div id='mapOverlay'>
                    <ProjectStructureSidebar
                        projectName={name}
                        wrapperWidth={wrapperWidth}
                        sidebarsToggleTransition={sidebarsToggleTransition}
                    />

                    <div className='overlay-area' ref={overlayAreaRef}>
                        {isCamerasInspectionEnabled && <InspectOverlay />}
                        <MapControls loading={loading} />
                        <MapSettings wrapperWidth={wrapperWidth} wrapperHeight={wrapperHeight} />
                        <DarkenedOverlay />
                        {showOverlayToolsArea && (
                            <div
                                className={classNames('overlay-tools-area', {
                                    'with-profile': isElevationProfileExpanded
                                })}
                            >
                                {showTourPlayer && <TourPlayer />}
                                {isElevationProfileExpanded && (
                                    <ElevationProfileExpandedTool
                                        getToolTopCoord={setElevationProfileTopCoord}
                                        wrapperHeight={wrapperHeight}
                                    />
                                )}
                            </div>
                        )}
                        {isCamerasInspectionEnabled && selectedCamera && (
                            <InspectedImageContainer camera={selectedCamera} />
                        )}
                    </div>

                    {!isCompareToolEnabled && (
                        <InspectionSidebar
                            wrapperWidth={wrapperWidth}
                            sidebarsToggleTransition={sidebarsToggleTransition}
                        />
                    )}
                </div>

                <BottomBar />

                <UndoOperation />
            </div>

            {!loading && (
                <ProjectViewNotification
                    visible={upcomingStructureChangesMessageVisible}
                    close={() => {
                        dispatch(setUpcomingStructureChangesMessageVisible(false));
                    }}
                    message={
                        isProcessingActive
                            ? t('projectView:notifications.processingProjectMessage')
                            : t('projectView:notifications.updatingProjectStructureMessage')
                    }
                />
            )}

            <BottomConfirmMessage
                visible={uploadsCompleteMessageVisible}
                close={() => {
                    dispatch(setUploadsCompleteMessageVisible(false));
                }}
                message={t('projectView:notifications.datasetsUploadedMessage')}
                container={document.getElementById('wrapper')}
            />
            <BottomConfirmMessage
                visible={manualConfigurationForUploadRequiredMessageVisible && !!pendingDatasets.length}
                close={() => {
                    dispatch(setManualConfigurationForUploadRequiredMessageVisible(false));
                }}
                message={t('projectView:notifications.manualConfigurationForUploadRequiredOnViewPage')}
                container={document.getElementById('wrapper')}
            />
            {isGalleryVisible && <ImageViewer />}
        </>
    );
}
