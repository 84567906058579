import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useDrawingCancel from '../../../hooks/useDrawingCancel';
import { AppDispatch, useSelector } from '../../../store';
import {
    disableAllControls,
    setCamerasInspectionEnabled,
    setElevationProfileExpanded,
    setSelectedCamera
} from '../../../store/slices/projectView';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';

export default function InspectionControl() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const isCamerasInspectionEnabled = useSelector(state => state.projectView.isCamerasInspectionEnabled);
    const isElevationProfileExpanded = useSelector(state => state.projectView.isElevationProfileExpanded);

    const cancelDrawing = useDrawingCancel();

    return (
        <TippyTooltip tooltipText={t('mapControls.tooltipInspection')}>
            <div
                data-testid={'control'}
                className={classNames('control-btn', { active: isCamerasInspectionEnabled })}
                onClick={e => {
                    const isEnablingInspection = isCamerasInspectionEnabled === false;
                    dispatch(disableAllControls());

                    // Because expanded elevation profile takes the same space as inspection image carousel
                    if (isElevationProfileExpanded) dispatch(setElevationProfileExpanded(false));

                    if (isEnablingInspection) {
                        cancelDrawing();
                        dispatch(setSelectedCamera(undefined));
                    }

                    dispatch(setCamerasInspectionEnabled(!isCamerasInspectionEnabled));
                }}
            >
                <i className='icon icon-inspector' />
            </div>
        </TippyTooltip>
    );
}
