import ReactPaginate from 'react-paginate';

type Props = {
    onPageChange: (selectedItem: { selected: number }) => void;
    pageCount: number;
    page: number;
};

export default function Pagination({ onPageChange, pageCount, page }: Props) {
    return pageCount > 1 ? (
        <ReactPaginate
            data-testid={'pager'}
            forcePage={page}
            pageCount={pageCount}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
            previousLabel={''}
            nextLabel={''}
            breakLabel={'...'}
            breakClassName={'pager-item ellipsis'}
            disableInitialCallback
            containerClassName={'pager'}
            pageClassName={'pager-item'}
            activeClassName={'active'}
            previousClassName={'pager-item link-prev'}
            nextClassName={'pager-item link-next'}
            disabledClassName={'disabled'}
            onPageChange={onPageChange}
        />
    ) : null;
}
