import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../../store';
import { disableAllControls, setPresentationSetupEnabled } from '../../../store/slices/projectView';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';

export default function PresentationControl() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const isPresentationSetupEnabled = useSelector(state => state.projectView.isPresentationSetupEnabled);

    return (
        <TippyTooltip tooltipText={t('mapControls.tooltipPresentation')}>
            <div
                data-testid={'control'}
                className={classNames('control-btn presentation-btn', { active: isPresentationSetupEnabled })}
                onClick={async e => {
                    dispatch(disableAllControls());
                    dispatch(setPresentationSetupEnabled(!isPresentationSetupEnabled));
                }}
            >
                <i className='icon icon-presentation' />
            </div>
        </TippyTooltip>
    );
}
