import { useTranslation } from 'react-i18next';

type Props = {
    topText: string;
    percentText?: string;
    percent?: number;
    infinite?: boolean;
};
export function InspectorStructureItemProgress({ topText, percentText, infinite, percent }: Props) {
    const { t } = useTranslation('projectView');

    return (
        <div className='inspector-upload'>
            <div className='upload-progress'>
                <div className='progress-bar-wrapper'>
                    <div className='progress-top'>
                        <b>{topText}</b>
                        {percentText && <b>{percentText}</b>}
                    </div>
                    <div className='progress-bar'>
                        {!!percent && !infinite && (
                            <span
                                data-testid={'progress'}
                                className='progress-value'
                                style={{ width: `${percent}%` }}
                            />
                        )}
                        {infinite && <span data-testid={'progressInfinite'} className='progress-value-infinite' />}
                    </div>
                </div>
            </div>
        </div>
    );
}
