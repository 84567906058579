/* tslint:disable */
/* eslint-disable */
/**
 * dataset-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DatasetInfo } from '../model';
// @ts-ignore
import { PatchDatasetRequest } from '../model';
/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the datasets info of the project
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {string} linkedProjectUid UUID of linked project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLink: async (projectUid: string, datasetUid: string, linkedProjectUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('addLink', 'projectUid', projectUid)
            // verify required parameter 'datasetUid' is not null or undefined
            assertParamExists('addLink', 'datasetUid', datasetUid)
            // verify required parameter 'linkedProjectUid' is not null or undefined
            assertParamExists('addLink', 'linkedProjectUid', linkedProjectUid)
            const localVarPath = `/projects/{projectUid}/datasets/{datasetUid}/links/{linkedProjectUid}`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"datasetUid"}}`, encodeURIComponent(String(datasetUid)))
                .replace(`{${"linkedProjectUid"}}`, encodeURIComponent(String(linkedProjectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the datasets info of the project
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {string} linkedProjectUid UUID of linked project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLink: async (projectUid: string, datasetUid: string, linkedProjectUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('deleteLink', 'projectUid', projectUid)
            // verify required parameter 'datasetUid' is not null or undefined
            assertParamExists('deleteLink', 'datasetUid', datasetUid)
            // verify required parameter 'linkedProjectUid' is not null or undefined
            assertParamExists('deleteLink', 'linkedProjectUid', linkedProjectUid)
            const localVarPath = `/projects/{projectUid}/datasets/{datasetUid}/links/{linkedProjectUid}`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"datasetUid"}}`, encodeURIComponent(String(datasetUid)))
                .replace(`{${"linkedProjectUid"}}`, encodeURIComponent(String(linkedProjectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the dataset
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataset: async (projectUid: string, datasetUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getDataset', 'projectUid', projectUid)
            // verify required parameter 'datasetUid' is not null or undefined
            assertParamExists('getDataset', 'datasetUid', datasetUid)
            const localVarPath = `/projects/{projectUid}/datasets/{datasetUid}`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"datasetUid"}}`, encodeURIComponent(String(datasetUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the datasets info of the project
         * @param {string} projectUid UUID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetsInfo: async (projectUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getDatasetsInfo', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}/datasets`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update dataset
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {PatchDatasetRequest} patchDatasetRequest Dataset info that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataset: async (projectUid: string, datasetUid: string, patchDatasetRequest: PatchDatasetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('updateDataset', 'projectUid', projectUid)
            // verify required parameter 'datasetUid' is not null or undefined
            assertParamExists('updateDataset', 'datasetUid', datasetUid)
            // verify required parameter 'patchDatasetRequest' is not null or undefined
            assertParamExists('updateDataset', 'patchDatasetRequest', patchDatasetRequest)
            const localVarPath = `/projects/{projectUid}/datasets/{datasetUid}`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"datasetUid"}}`, encodeURIComponent(String(datasetUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchDatasetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns the datasets info of the project
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {string} linkedProjectUid UUID of linked project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addLink(projectUid: string, datasetUid: string, linkedProjectUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addLink(projectUid, datasetUid, linkedProjectUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the datasets info of the project
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {string} linkedProjectUid UUID of linked project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLink(projectUid: string, datasetUid: string, linkedProjectUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLink(projectUid, datasetUid, linkedProjectUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the dataset
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataset(projectUid: string, datasetUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatasetInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataset(projectUid, datasetUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the datasets info of the project
         * @param {string} projectUid UUID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDatasetsInfo(projectUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatasetInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDatasetsInfo(projectUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update dataset
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {PatchDatasetRequest} patchDatasetRequest Dataset info that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDataset(projectUid: string, datasetUid: string, patchDatasetRequest: PatchDatasetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDataset(projectUid, datasetUid, patchDatasetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns the datasets info of the project
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {string} linkedProjectUid UUID of linked project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addLink(projectUid: string, datasetUid: string, linkedProjectUid: string, options?: any): AxiosPromise<void> {
            return localVarFp.addLink(projectUid, datasetUid, linkedProjectUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the datasets info of the project
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {string} linkedProjectUid UUID of linked project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLink(projectUid: string, datasetUid: string, linkedProjectUid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLink(projectUid, datasetUid, linkedProjectUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the dataset
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataset(projectUid: string, datasetUid: string, options?: any): AxiosPromise<DatasetInfo> {
            return localVarFp.getDataset(projectUid, datasetUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the datasets info of the project
         * @param {string} projectUid UUID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDatasetsInfo(projectUid: string, options?: any): AxiosPromise<Array<DatasetInfo>> {
            return localVarFp.getDatasetsInfo(projectUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update dataset
         * @param {string} projectUid UUID of project
         * @param {string} datasetUid uid of dataset
         * @param {PatchDatasetRequest} patchDatasetRequest Dataset info that needs to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDataset(projectUid: string, datasetUid: string, patchDatasetRequest: PatchDatasetRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateDataset(projectUid, datasetUid, patchDatasetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
    /**
     * 
     * @summary Returns the datasets info of the project
     * @param {string} projectUid UUID of project
     * @param {string} datasetUid uid of dataset
     * @param {string} linkedProjectUid UUID of linked project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public addLink(projectUid: string, datasetUid: string, linkedProjectUid: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).addLink(projectUid, datasetUid, linkedProjectUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the datasets info of the project
     * @param {string} projectUid UUID of project
     * @param {string} datasetUid uid of dataset
     * @param {string} linkedProjectUid UUID of linked project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public deleteLink(projectUid: string, datasetUid: string, linkedProjectUid: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).deleteLink(projectUid, datasetUid, linkedProjectUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the dataset
     * @param {string} projectUid UUID of project
     * @param {string} datasetUid uid of dataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public getDataset(projectUid: string, datasetUid: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).getDataset(projectUid, datasetUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the datasets info of the project
     * @param {string} projectUid UUID of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public getDatasetsInfo(projectUid: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).getDatasetsInfo(projectUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update dataset
     * @param {string} projectUid UUID of project
     * @param {string} datasetUid uid of dataset
     * @param {PatchDatasetRequest} patchDatasetRequest Dataset info that needs to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public updateDataset(projectUid: string, datasetUid: string, patchDatasetRequest: PatchDatasetRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).updateDataset(projectUid, datasetUid, patchDatasetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
