import { memo, useContext, useEffect, useMemo, useRef } from 'react';
import {
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Filler,
    LinearScale,
    LineElement,
    PointElement,
    Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import _ from 'lodash';
import getGraphOptions, { graphTheme } from './GraphOptions';
import { AppDispatch, useSelector } from '../../../store';
import { selectedObjectInfoSelector } from '../../../store/selectors/index';
import { TemporaryGeometry } from '../../../store/helpers/interfaces';
import { GeometryTypes, Units } from '../../../sharedConstants';
import { makeSelectElevationProfileById, setHoveredPointIndex } from '../../../store/slices/elevationProfiles';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { useTranslation } from 'react-i18next';
import { convertLengthUnit } from '../../../lib/convertUnit';
import { getUnitsShortName } from '../../../lib/getUnitsShortName';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler, zoomPlugin);

type Props = {
    resetZoom: boolean;
    passResetZoom: (value: boolean) => void;
};

function ElevationProfileExpandedGraph({ resetZoom, passResetZoom }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const polyline = useSelector(state =>
        selectedObjectInfoSelector(state)
    ) as TemporaryGeometry<GeometryTypes.POLYLINE>;
    const selectElevationProfileById = useMemo(makeSelectElevationProfileById, []);
    const elevationProfile = useSelector(state => selectElevationProfileById(state, polyline.id));
    const units = useSelector(state => state.coordinateSystems.units);
    const chartRef = useRef<ChartJS<'line'>>(null!);

    const { isInEmbedView } = useContext(ProjectViewAccessContext);
    const backgroundColor = isInEmbedView ? graphTheme.background.embed : graphTheme.background.default;

    useEffect(() => {
        if (resetZoom) {
            if (chartRef.current) {
                chartRef.current.resetZoom();
                passResetZoom(false);
            }
        }
    }, [resetZoom, passResetZoom, chartRef]);

    useEffect(() => {
        chartRef.current.resetZoom();
        passResetZoom(false);
    }, [polyline.id, passResetZoom]);

    useEffect(() => {
        if (elevationProfile?.status === 'new') {
            chartRef.current?.resetZoom();
        }
    }, [elevationProfile?.status]);

    const localOptions: ChartOptions<'line'> = useMemo(() => {
        const unitsNaming = getUnitsShortName(units);
        return _.merge(getGraphOptions({ distanceUnits: unitsNaming, elevationUnits: unitsNaming }), {
            scales: {
                x: {
                    ticks: {
                        maxTicksLimit: 6
                    }
                },
                y: {
                    ticks: {
                        maxTicksLimit: 6
                    },
                    callback: function (value: any) {
                        // round tick values while zooming
                        if (Math.floor(value) === value) {
                            return value;
                        } else {
                            return value.toFixed(2);
                        }
                    }
                }
            },
            plugins: {
                zoom: {
                    pan: {
                        enabled: true,
                        mode: 'xy' as const
                    },
                    zoom: {
                        wheel: {
                            enabled: true
                        },
                        pinch: {
                            enabled: true
                        }
                    },
                    limits: {
                        x: {
                            min: 'original' as const,
                            max: 'original' as const
                        },
                        y: {
                            min: 'original' as const,
                            max: 'original' as const
                        }
                    }
                }
            }
        });
    }, [units]);

    const options: ChartOptions<'line'> = useMemo(
        () => ({
            ...localOptions,
            onHover(event, elements) {
                if (elements.length) {
                    const element = elements[0];
                    dispatch(setHoveredPointIndex(element.index));
                }
            }
        }),
        [dispatch, localOptions]
    );

    if (!elevationProfile) return null;

    const data: ChartData<'line'> = {
        labels: elevationProfile.result.distances.map(r => convertLengthUnit(r, Units.METRE, units).toFixed(2)),
        datasets: elevationProfile.result.altitudes.map(({ altitudes, color, name }) => ({
            data: altitudes.map(r => convertLengthUnit(r, Units.METRE, units)),
            label: name,
            borderColor: color,
            backgroundColor: color
        }))
    };

    return (
        <Line
            options={options}
            data={data}
            ref={chartRef}
            onMouseOut={e => {
                dispatch(setHoveredPointIndex(null));
            }}
        />
    );
}

export default memo(ElevationProfileExpandedGraph);
