import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Dataset as DatasetPresenter } from '../../../entities/Dataset';
import { SourceType } from '../../../generated/cloud-frontend-api';
import { AppDispatch, useSelector } from '../../../store';
import { ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { ExtendedDatasetInfo } from '../../../store/slices/datasetfilesUpload';
import {
    expandedPropertiesBlockNames,
    makeSelectStructureInfo,
    selectObjectVisibility,
    selectPropertiesBlockExpansion,
    selectStructureWithEnabledLimitBox,
    setStructureProperty,
    updateStructureInfo
} from '../../../store/slices/structure';
import RangeControl from '../../Elements/range-control/RangeControl';
import SwitchControl from '../../Elements/switch-control/SwitchControl';
import PropertiesBlockHead from '../../ProjectView/geometry-properties/elements/PropertiesBlockHead';

const expandedPropertyName: (typeof expandedPropertiesBlockNames)[number] = 'styleBlockExpanded';

export default function DatasetStyleProperties() {
    const dispatch: AppDispatch = useDispatch();
    const isChangingValueTimer = useRef(0);
    const { t } = useTranslation(['projectView']);
    const project = useSelector(state => state.project.projectInfo);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const datasetInfo = useSelector(state => selectedObjectInfoSelector(state)) as ExtendedDatasetInfo;
    const selectStructureInfo = useMemo(makeSelectStructureInfo, []);
    const structureInfo = useSelector(state => selectStructureInfo(state, datasetInfo.datasetUid!));
    const isStylePropertiesBlockExpanded = useSelector(state =>
        selectPropertiesBlockExpansion(state, datasetInfo.datasetUid!, expandedPropertyName)
    );
    const visible = useSelector(state => selectObjectVisibility(state, datasetInfo.datasetUid!));
    const structureInfoWithEnabledLimitBox = useSelector(state => selectStructureWithEnabledLimitBox(state));
    const opacityNumber = structureInfo?.properties.opacity ? Number(structureInfo?.properties.opacity) : undefined;
    const opacity = opacityNumber ?? DatasetPresenter.defaultOpacity(datasetInfo.sourceData?.type!);
    const limitBoxEnabled = structureInfo?.properties.limitBoxEnabled === String(true);

    const sourceType = datasetInfo?.sourceData?.type;

    useEffect(() => {
        return () => {
            clearTimeout(isChangingValueTimer.current);
        };
    }, []);

    return (
        <div className='inspector-properties'>
            <PropertiesBlockHead
                isBlockExpanded={isStylePropertiesBlockExpanded}
                title={t('projectView:inspectionSidebar.geometryProperties.titleStyle')}
                onClick={() => {
                    dispatch(
                        updateStructureInfo({
                            projectId: project.id!,
                            structureUid: datasetInfo.datasetUid!,
                            type: ProjectStructureObjectTypes.DATASET,
                            propName: expandedPropertyName,
                            propValue: isStylePropertiesBlockExpanded ? String(false) : String(true)
                        })
                    );
                }}
            />
            {isStylePropertiesBlockExpanded && (
                <>
                    <RangeControl
                        key={selectedObject.artifactId}
                        text={
                            sourceType === SourceType.POINT_CLOUD || sourceType === SourceType.TIE_POINTS
                                ? t('inspectionSidebar.pointSize')
                                : t('inspectionSidebar.opacity')
                        }
                        startValue={opacity * 100}
                        onChange={newValue => {
                            clearTimeout(isChangingValueTimer.current);
                            const newOpacity = String(Number(newValue) / 100);
                            dispatch(
                                setStructureProperty({
                                    id: selectedObject?.artifactId!,
                                    propName: 'opacity',
                                    propValue: newOpacity
                                })
                            );

                            isChangingValueTimer.current = window.setTimeout(() => {
                                dispatch(
                                    updateStructureInfo({
                                        projectId: project.id!,
                                        structureUid: selectedObject?.artifactId!,
                                        type: ProjectStructureObjectTypes.DATASET,
                                        propName: 'opacity',
                                        propValue: newOpacity
                                    })
                                );
                            }, 400);
                        }}
                    />
                    {(
                        [
                            SourceType.TILED_MODEL,
                            SourceType.TIE_POINTS,
                            SourceType.POINT_CLOUD,
                            SourceType.BLOCK_MODEL
                        ] as SourceType[]
                    ).includes(sourceType!) && (
                        <div className='inspector-switch-block'>
                            <div className='switch-control-wrapper'>
                                <span className='switch-title'>{t('inspectionSidebar.limitBox')}</span>
                                <SwitchControl
                                    disabled={!visible}
                                    checked={limitBoxEnabled}
                                    onChange={() => {
                                        // If another limit box is enabled, turn it off
                                        if (structureInfoWithEnabledLimitBox) {
                                            dispatch(
                                                updateStructureInfo({
                                                    projectId: project.id!,
                                                    structureUid: structureInfoWithEnabledLimitBox.uid!,
                                                    type: ProjectStructureObjectTypes.DATASET,
                                                    propName: 'limitBoxEnabled',
                                                    propValue: String(false)
                                                })
                                            );
                                        }

                                        dispatch(
                                            updateStructureInfo({
                                                projectId: project.id!,
                                                structureUid: selectedObject?.artifactId!,
                                                type: ProjectStructureObjectTypes.DATASET,
                                                propName: 'limitBoxEnabled',
                                                propValue: limitBoxEnabled ? String(false) : String(true)
                                            })
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
