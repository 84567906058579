import * as Cesium from 'cesium';
import { forwardRef, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CesiumComponentRef, ImageryLayer, useCesium } from 'resium';
import { useResourceVisibility } from '../../../hooks/useResourceVisibility';
import { AppDispatch, useSelector } from '../../../store';
import { setTilesetBoundingSphere } from '../../../store/slices/projectView';
import { AppResource } from './ResourceProvider';

type Props = {
    resource: AppResource;
    opacity: number;
    index: number;
};

const TileMapServiceVisualization = forwardRef<CesiumComponentRef<Cesium.ImageryLayer>, Props>(
    ({ resource, opacity, index }, ref) => {
        const { imageryLayerCollection } = useCesium();
        const dispatch: AppDispatch = useDispatch();
        const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);

        const [isReady, setReady] = useState(false);

        const { actualVisible, visibleInCompareTree1, visibleInCompareTree2 } = useResourceVisibility(resource);

        const splitDirection = useMemo(() => {
            if (isCompareToolEnabled) {
                if (visibleInCompareTree1 && visibleInCompareTree2) return Cesium.SplitDirection.NONE;
                if (visibleInCompareTree1) return Cesium.SplitDirection.LEFT;
                return Cesium.SplitDirection.RIGHT;
            }

            return Cesium.SplitDirection.NONE;
        }, [isCompareToolEnabled, visibleInCompareTree1, visibleInCompareTree2]);

        const imageryProvider = useMemo(() => {
            const provider = Cesium.TileMapServiceImageryProvider.fromUrl(resource, { minimumLevel: 1 });
            provider.then(provider => {
                const orientedBoundingBox = Cesium.OrientedBoundingBox.fromRectangle(provider.rectangle);
                const boundingSphere = Cesium.BoundingSphere.fromOrientedBoundingBox(orientedBoundingBox);
                dispatch(setTilesetBoundingSphere({ tilesetId: resource.tilesetId, bs: boundingSphere }));
                setReady(true);
            });
            return provider;
        }, [resource, dispatch]);

        return (
            <ImageryLayer
                splitDirection={splitDirection}
                imageryProvider={imageryProvider}
                show={actualVisible}
                alpha={opacity}
                ref={ref}
                index={
                    imageryLayerCollection?.length && isReady ? imageryLayerCollection.length - 1 + index : undefined
                }
            />
        );
    }
);

export default TileMapServiceVisualization;
