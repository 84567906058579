import classNames from 'classnames';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, useParams, useRouteMatch } from 'react-router-dom';
import useGetElementDimensions from '../../hooks/useGetElementDimensions';
import { FolderRouteParams } from '../../pages/folder/Folder';
import { DESKTOP_BREAKPOINT, Routes } from '../../sharedConstants';
import Notifications from '../Elements/notifications/Notifications';
import { FolderBreadcrumbs } from './breadcrumbs/FolderBreadcrumbs';
import ProjectsPageControls from './projects-page-controls/ProjectsPageControls';

const HEADER_HEIGHT = 60; // = $header_height (высота #header)

export default function ProjectsPageHead() {
    const { t } = useTranslation(['projects', 'sharedProjects']);

    const [documentWidth, setDocumentWidth] = useState(document.body.clientWidth);
    const [isHeadFixed, setIsHeadFixed] = useState(false);
    const { folderId } = useParams<FolderRouteParams>();

    const projectsPageHeadRef = useRef<HTMLDivElement>(null);
    const contentSpacerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const isDrivePage = !!useRouteMatch({ path: Routes.INDEX, exact: true });
    const isSharedProjectsPage = !!useRouteMatch({ path: Routes.SHARED, exact: true });

    const contentHeight = useGetElementDimensions(contentRef, true).height || 0;

    const isDesktopLayout = useMemo(() => documentWidth >= DESKTOP_BREAKPOINT, [documentWidth]);

    const isSitePage = !!useRouteMatch({ path: [Routes.SITE, Routes.SHARED_SITE], exact: true });

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

        function handleResize() {
            setDocumentWidth(document.body.clientWidth);
        }
    }, []);

    const pageTitle = useMemo(() => {
        if (isDrivePage) {
            return t('projects:pageHead');
        } else if (isSharedProjectsPage) {
            return t('sharedProjects:pageHead.title');
        } else {
            return false;
        }
    }, [isDrivePage, isSharedProjectsPage, t]);

    function setIntersectionState(entries: IntersectionObserverEntry[]) {
        setIsHeadFixed(!entries[0].isIntersecting);
    }

    useEffect(() => {
        const contentSpacer = contentSpacerRef.current;

        const topIntersectionMargin = -(isSitePage ? 0 : HEADER_HEIGHT);

        const observer = new IntersectionObserver(setIntersectionState, {
            rootMargin: `${isDesktopLayout ? topIntersectionMargin : 0}px 0px 0px 0px`,
            threshold: 1.0
        });

        if (contentSpacer) observer.observe(contentSpacer);

        return () => {
            if (contentSpacer) observer.unobserve(contentSpacer);
        };
    }, [contentSpacerRef, isDesktopLayout, isSitePage]);

    return (
        <div className={classNames(isSitePage ? 'site-page-head' : 'projects-page-head')} ref={projectsPageHeadRef}>
            <Route exact path={[Routes.INDEX, Routes.FOLDER]}>
                <Notifications />
            </Route>
            <div
                className={classNames('head-content-spacer', isHeadFixed && 'isFixed')}
                ref={contentSpacerRef}
                style={{ height: isHeadFixed ? `${contentHeight}px` : '0' }}
            />
            <div
                className={classNames('head-content-wrapper', !isSitePage && 'project-view', isHeadFixed && 'isFixed')}
                ref={contentRef}
            >
                {pageTitle && (
                    <div className='page-title-wrapper'>
                        <div className='page-title-container'>
                            <h1 className='page-title' role='pageTitle'>
                                {pageTitle}
                            </h1>
                        </div>
                    </div>
                )}
                <Route exact path={Routes.FOLDER}>
                    <FolderBreadcrumbs key={folderId} />
                </Route>
                <ProjectsPageControls />
            </div>
        </div>
    );
}
