import { useEffect, useState } from 'react';
import { ReactComponent as SvgCamera } from '../../../svg/presentation/camera.svg';
import BottomMessage, { BOTTOM_MESSAGE_TIMEOUT } from '../../Elements/bottom-message/BottomMessage';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { setDefaultPOVSaveRequested } from '../../../store/slices/structure';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from '../../../sharedConstants';
import { useTranslation } from 'react-i18next';

import styles from './MapSettings.module.scss';

let timeout = 0;

export default function SavePointOfView() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const [isPointSet, setIsPointSet] = useState(false);

    const showSaveViewControl = useRouteMatch({ path: [Routes.PROJECT_VIEW, Routes.SITE_VIEW], exact: true });

    const handleBottomMessage = () => {
        if (isPointSet) {
            clearTimeout(timeout);
            setIsPointSet(false);
        }

        setIsPointSet(true);
        timeout = window.setTimeout(() => {
            setIsPointSet(false);
        }, BOTTOM_MESSAGE_TIMEOUT);
    };

    useEffect(() => {
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    if (!showSaveViewControl) return null;

    return (
        <>
            <div className={styles.settingsItem}>
                <div className={styles.saveSceneViewWrapper}>
                    <div
                        className={styles.saveSceneView}
                        onClick={e => {
                            dispatch(setDefaultPOVSaveRequested(true));
                            handleBottomMessage();
                        }}
                    >
                        <SvgCamera className={styles.saveViewIcon} />
                        <span>{t('projectView:savePov')}</span>
                    </div>
                </div>
            </div>

            <BottomMessage
                message={t('projectView:povSavedMessage')}
                visible={isPointSet}
                container={document.getElementById('mapWrapper')}
            />
        </>
    );
}
