import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { AppDispatch, ApplicationState } from '..';
import { siteApi } from '../../api/initApis';
import { Parent, ProjectInfo, Site } from '../../generated/cloud-frontend-api';
import { createProject, moveProject, renameProject, updateSurveyDate } from './projectsPage';
import GetProjectById from '../GetProjectById';
import { compareDesc } from 'date-fns';
import { isAxiosError } from 'axios';
import { ExtendedProject } from './project';

interface SiteState {
    siteInfo: Required<Omit<Site, 'parent'>> & { parent: Parent | null };
    projectInfo: ProjectInfo;
}

const siteInitialState: SiteState = {
    siteInfo: { uid: '', name: '', parent: null, projects: [] },
    projectInfo: {}
};

const name = 'site';

interface GetSiteArgs {
    projectId: string;
    access?: string;
}
export const getSite = createAsyncThunk<Site, GetSiteArgs, { rejectValue: { statusCode: number } }>(
    `${name}/get`,
    async ({ projectId, access }, { rejectWithValue }) => {
        return siteApi
            .getSite(projectId, { params: { access } })
            .then(({ data }) => data)
            .catch(err => {
                if (isAxiosError(err)) {
                    if (err.response?.status === 404 || err.response?.status === 410)
                        return rejectWithValue({ statusCode: err.response.status });
                    throw err;
                }
                throw err;
            });
    }
);

export const getSiteProjectInfo = createAsyncThunk<
    ExtendedProject,
    GetSiteArgs,
    { dispatch: AppDispatch; state: ApplicationState }
>(`${name}/getProjectInfo`, async ({ projectId, access }: GetSiteArgs, { dispatch, getState }) => {
    const { project } = await new GetProjectById(projectId, dispatch, getState, access).result();
    return project;
});

const slice = createSlice({
    name,
    initialState: siteInitialState,
    reducers: {
        resetSiteState: () => siteInitialState
    },
    extraReducers: builder => {
        builder
            .addCase(getSite.fulfilled, (state, { payload }) => {
                state.siteInfo = payload as SiteState['siteInfo'];
            })
            .addCase(createProject.fulfilled, (state, { payload }) => {
                state.siteInfo.projects.unshift({
                    name: payload.name,
                    surveyDate: payload.surveyDate,
                    uid: payload.uid
                });
            })
            .addCase(getSiteProjectInfo.fulfilled, (state, { payload }) => {
                state.projectInfo = payload.projectInfo;
            })
            .addCase(renameProject.pending, (state, { meta }) => {
                if (state.siteInfo.uid === meta.arg.projectId) {
                    state.siteInfo.name = meta.arg.name;
                    state.projectInfo.name = meta.arg.name;
                }
            })
            .addCase(moveProject.fulfilled, (state, { meta }) => {
                if (meta.arg.projectId === state.siteInfo.uid) {
                    // Handle move of site itself
                    if (meta.arg.parent === 'unset') state.siteInfo.parent = null;
                    else state.siteInfo.parent = meta.arg.parent;
                } else {
                    // Handle move of site's project
                    if (meta.arg.parent === 'unset' || state.siteInfo.uid !== meta.arg.parent.uid) {
                        const index = state.siteInfo.projects.findIndex(p => p.uid === meta.arg.projectId);
                        if (index !== -1) {
                            state.siteInfo.projects.splice(index, 1);
                        }
                    }
                }
            })
            .addCase(updateSurveyDate.fulfilled, (state, { meta }) => {
                const project = state.siteInfo.projects.find(p => p.uid === meta.arg.projectId);
                if (project) {
                    project.surveyDate = meta.arg.surveyDate;
                    state.siteInfo.projects.sort((a, b) =>
                        compareDesc(new Date(a.surveyDate!), new Date(b.surveyDate!))
                    );
                }
            });
    }
});

export const { resetSiteState } = slice.actions;

export default slice.reducer;

export const selectFirstSiteProject = createSelector(
    (state: ApplicationState) => state.site.siteInfo.projects,
    projects => (projects?.[0]?.uid ? projects?.[0] : undefined)
);

export const makeSelectSiteProjectById = () =>
    createSelector(
        (state: ApplicationState) => state.site.siteInfo.projects,
        (state: ApplicationState, projectId: string) => projectId,
        (projects, projectId) => projects.find(p => p.uid === projectId)
    );
