import { useTranslation, Trans } from 'react-i18next';
import PreviousPageLink from '../../components/Elements/previous-page-link/PreviousPageLink';
import Checkbox from '../../components/Elements/checkbox/Checkbox';
import CheckboxWrapper from '../../components/Elements/checkbox-wrapper/CheckboxWrapper';
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { EventType, SubscriptionType } from '../../generated/subscription-api';
import { AppDispatch, useSelector } from '../../store';
import { subscriptionsAsObject } from '../../store/selectors';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { updateSubscriptions } from '../../store/slices/emailSubscriptions';

import styles from './Preferences.module.scss';

export default function Preferences() {
    const { t } = useTranslation('cabinet');
    const dispatch: AppDispatch = useDispatch();
    const subscriptions = useSelector(state => subscriptionsAsObject(state));

    return (
        <div className={styles.contentWrapper}>
            <PreviousPageLink />

            <div className={styles.contentSection}>
                <div className={styles.sectionTitle}>{t('emailNotifications.notificationsTitle')}</div>

                <Formik
                    initialValues={subscriptions}
                    onSubmit={async (values, { resetForm }) => {
                        const newSubscriptions = Object.entries(_.pick(values, Object.values(EventType))).map(
                            ([key, value]) => ({
                                event: key as EventType,
                                type: SubscriptionType.EMAIL,
                                enabled: value
                            })
                        );
                        const result = await dispatch(
                            updateSubscriptions({
                                subscriptions: newSubscriptions
                            })
                        );
                        if (updateSubscriptions.fulfilled.match(result)) {
                            resetForm({ values });
                        }
                    }}
                >
                    {({ dirty }) => {
                        return (
                            <Form className={styles.notificationsForm}>
                                <div
                                    className={classNames(
                                        'form-checkbox',
                                        styles.checkboxGroup,
                                        styles.checkboxGroupDisabled
                                    )}
                                >
                                    <Checkbox checked disabled />
                                    <span>
                                        <Trans
                                            ns={'cabinet'}
                                            i18nKey={'emailNotifications.transactions'}
                                            components={{ b: <b /> }}
                                        />
                                    </span>
                                </div>

                                <div
                                    className={classNames(
                                        'form-checkbox',
                                        styles.checkboxGroup,
                                        styles.checkboxGroupDisabled
                                    )}
                                >
                                    <Checkbox checked disabled />
                                    <span>
                                        <Trans
                                            ns={'cabinet'}
                                            i18nKey={'emailNotifications.invitations'}
                                            components={{ b: <b /> }}
                                        />
                                    </span>
                                </div>

                                <div className={classNames('form-checkbox', styles.checkboxGroup)}>
                                    <CheckboxWrapper>
                                        <Field type='checkbox' name={EventType.PROCESSING_FINISHED} />
                                    </CheckboxWrapper>
                                    <span>{t('emailNotifications.projectStatus')}</span>
                                </div>

                                <div className={classNames('form-checkbox', styles.checkboxGroup)}>
                                    <CheckboxWrapper>
                                        <Field type='checkbox' name={EventType.LIMIT_EXCEEDED} />
                                    </CheckboxWrapper>
                                    <span>{t('emailNotifications.outOfResources')}</span>
                                </div>

                                <div className={classNames('form-checkbox', styles.checkboxGroup)}>
                                    <CheckboxWrapper>
                                        <Field type='checkbox' name={EventType.NEWS} />
                                    </CheckboxWrapper>
                                    <span>{t('emailNotifications.news')}</span>
                                </div>

                                <div className={styles.formActions}>
                                    <button className='btn' type='submit' disabled={!dirty}>
                                        {t('emailNotifications.saveChanges')}
                                    </button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>

            <div className={styles.contentSection}>
                <div className={styles.sectionTitle}>{t('infrastructure.infrastructureTitle')}</div>
                <div>{t('infrastructure.description')}</div>
            </div>
        </div>
    );
}
