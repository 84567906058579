/**
 * @private
 */
import { AttributeCompression, getJsonFromTypedArray } from './CesiumHelper';
import {
    BoundingSphere,
    Cartesian3,
    defined,
    IndexDatatype,
    Intersections2D,
    Math as CesiumMath,
    OrientedBoundingBox,
    QuantizedMeshTerrainData,
    GeographicTilingScheme,
    Ellipsoid
} from 'cesium';

var QuantizedMeshUtil = {};
var MAX_SHORT = 32767;
/**
 * When using the Quantized-Mesh format, a tile may be returned that includes additional extensions, such as PerVertexNormals, watermask, etc.
 * This enumeration defines the unique identifiers for each type of extension data that has been appended to the standard mesh data.
 *
 * Copy from Cesium sources
 * @namespace QuantizedMeshExtensionIds
 * @see CesiumMultiTerrainProvider
 * @private
 */
var QuantizedMeshExtensionIds = {
    /**
     * Oct-Encoded Per-Vertex Normals are included as an extension to the tile mesh
     *
     * @type {Number}
     * @constant
     * @default 1
     */
    OCT_VERTEX_NORMALS: 1,
    /**
     * A watermask is included as an extension to the tile mesh
     *
     * @type {Number}
     * @constant
     * @default 2
     */
    WATER_MASK: 2,
    /**
     * A json object contain metadata about the tile
     *
     * @type {Number}
     * @constant
     * @default 4
     */
    METADATA: 4
};

/**
 * Modified version of source createQuantizedMeshTerrainDatam from Cesium. It creates quantized mesh from zipped data
 * with additional parameters min max height
 * @param provider
 * @param buffer
 * @param level
 * @param x
 * @param y
 * @param layer
 * @returns {module:cesium.QuantizedMeshTerrainData}
 */
QuantizedMeshUtil.createQuantizedMeshTerrainData = function (provider, buffer, level, x, y, layer) {
    var littleEndianExtensionSize = layer.littleEndianExtensionSize;
    var pos = 0;
    var cartesian3Elements = 3;
    var boundingSphereElements = cartesian3Elements + 1;
    var cartesian3Length = Float64Array.BYTES_PER_ELEMENT * cartesian3Elements;
    var boundingSphereLength = Float64Array.BYTES_PER_ELEMENT * boundingSphereElements;
    var encodedVertexElements = 3;
    var encodedVertexLength = Uint16Array.BYTES_PER_ELEMENT * encodedVertexElements;
    var triangleElements = 3;
    var bytesPerIndex = Uint16Array.BYTES_PER_ELEMENT;
    var triangleLength = bytesPerIndex * triangleElements;

    var view = new DataView(buffer);
    var center = new Cartesian3(
        view.getFloat64(pos, true),
        view.getFloat64(pos + 8, true),
        view.getFloat64(pos + 16, true)
    );
    pos += cartesian3Length;

    var minimumHeight = view.getFloat32(pos, true);
    pos += Float32Array.BYTES_PER_ELEMENT;
    var maximumHeight = view.getFloat32(pos, true);
    pos += Float32Array.BYTES_PER_ELEMENT;

    var boundingSphere = new BoundingSphere(
        new Cartesian3(view.getFloat64(pos, true), view.getFloat64(pos + 8, true), view.getFloat64(pos + 16, true)),
        view.getFloat64(pos + cartesian3Length, true)
    );
    pos += boundingSphereLength;

    var horizonOcclusionPoint = new Cartesian3(
        view.getFloat64(pos, true),
        view.getFloat64(pos + 8, true),
        view.getFloat64(pos + 16, true)
    );
    pos += cartesian3Length;

    var vertexCount = view.getUint32(pos, true);
    pos += Uint32Array.BYTES_PER_ELEMENT;
    var encodedVertexBuffer = new Uint16Array(buffer, pos, vertexCount * 3);
    pos += vertexCount * encodedVertexLength;

    if (vertexCount > 64 * 1024) {
        // More than 64k vertices, so indices are 32-bit.
        bytesPerIndex = Uint32Array.BYTES_PER_ELEMENT;
        triangleLength = bytesPerIndex * triangleElements;
    }

    // Decode the vertex buffer.
    var uBuffer = encodedVertexBuffer.subarray(0, vertexCount);
    var vBuffer = encodedVertexBuffer.subarray(vertexCount, 2 * vertexCount);
    var heightBuffer = encodedVertexBuffer.subarray(vertexCount * 2, 3 * vertexCount);

    AttributeCompression.zigZagDeltaDecode(uBuffer, vBuffer, heightBuffer);

    // skip over any additional padding that was added for 2/4 byte alignment
    if (pos % bytesPerIndex !== 0) {
        pos += bytesPerIndex - (pos % bytesPerIndex);
    }

    var triangleCount = view.getUint32(pos, true);
    pos += Uint32Array.BYTES_PER_ELEMENT;
    var indices = IndexDatatype.createTypedArrayFromArrayBuffer(
        vertexCount,
        buffer,
        pos,
        triangleCount * triangleElements
    );
    pos += triangleCount * triangleLength;

    // High water mark decoding based on decompressIndices_ in webgl-loader's loader.js.
    // https://code.google.com/p/webgl-loader/source/browse/trunk/samples/loader.js?r=99#55
    // Copyright 2012 Google Inc., Apache 2.0 license.
    var highest = 0;
    var length = indices.length;
    for (var i = 0; i < length; ++i) {
        var code = indices[i];
        indices[i] = highest - code;
        if (code === 0) {
            ++highest;
        }
    }

    var westVertexCount = view.getUint32(pos, true);
    pos += Uint32Array.BYTES_PER_ELEMENT;
    var westIndices = IndexDatatype.createTypedArrayFromArrayBuffer(vertexCount, buffer, pos, westVertexCount);
    pos += westVertexCount * bytesPerIndex;

    var southVertexCount = view.getUint32(pos, true);
    pos += Uint32Array.BYTES_PER_ELEMENT;
    var southIndices = IndexDatatype.createTypedArrayFromArrayBuffer(vertexCount, buffer, pos, southVertexCount);
    pos += southVertexCount * bytesPerIndex;

    var eastVertexCount = view.getUint32(pos, true);
    pos += Uint32Array.BYTES_PER_ELEMENT;
    var eastIndices = IndexDatatype.createTypedArrayFromArrayBuffer(vertexCount, buffer, pos, eastVertexCount);
    pos += eastVertexCount * bytesPerIndex;

    var northVertexCount = view.getUint32(pos, true);
    pos += Uint32Array.BYTES_PER_ELEMENT;
    var northIndices = IndexDatatype.createTypedArrayFromArrayBuffer(vertexCount, buffer, pos, northVertexCount);
    pos += northVertexCount * bytesPerIndex;

    var encodedNormalBuffer;
    var waterMaskBuffer;
    while (pos < view.byteLength) {
        var extensionId = view.getUint8(pos, true);
        pos += Uint8Array.BYTES_PER_ELEMENT;
        var extensionLength = view.getUint32(pos, littleEndianExtensionSize);
        pos += Uint32Array.BYTES_PER_ELEMENT;

        if (extensionId === QuantizedMeshExtensionIds.OCT_VERTEX_NORMALS && provider._requestVertexNormals) {
            encodedNormalBuffer = new Uint8Array(buffer, pos, vertexCount * 2);
        } else if (extensionId === QuantizedMeshExtensionIds.WATER_MASK && provider._requestWaterMask) {
            waterMaskBuffer = new Uint8Array(buffer, pos, extensionLength);
        } else if (extensionId === QuantizedMeshExtensionIds.METADATA && provider._requestMetadata) {
            var stringLength = view.getUint32(pos, true);
            if (stringLength > 0) {
                var metadata = getJsonFromTypedArray(
                    new Uint8Array(buffer),
                    pos + Uint32Array.BYTES_PER_ELEMENT,
                    stringLength
                );
                var availableTiles = metadata.available;
                if (defined(availableTiles)) {
                    for (var offset = 0; offset < availableTiles.length; ++offset) {
                        var availableLevel = level + offset + 1;
                        var rangesAtLevel = availableTiles[offset];
                        var yTiles = provider._tilingScheme.getNumberOfYTilesAtLevel(availableLevel);

                        for (var rangeIndex = 0; rangeIndex < rangesAtLevel.length; ++rangeIndex) {
                            var range = rangesAtLevel[rangeIndex];
                            var yStart = yTiles - range.endY - 1;
                            var yEnd = yTiles - range.startY - 1;
                            provider.availability.addAvailableTileRange(
                                availableLevel,
                                range.startX,
                                yStart,
                                range.endX,
                                yEnd
                            );
                            layer.availability.addAvailableTileRange(
                                availableLevel,
                                range.startX,
                                yStart,
                                range.endX,
                                yEnd
                            );
                        }
                    }
                }
            }
            layer.availabilityTilesLoaded.addAvailableTileRange(level, x, y, x, y);
        }
        pos += extensionLength;
    }

    var skirtHeight = provider.getLevelMaximumGeometricError(level) * 5.0;

    // The skirt is not included in the OBB computation. If this ever
    // causes any rendering artifacts (cracks), they are expected to be
    // minor and in the corners of the screen. It's possible that this
    // might need to be changed - just change to `minimumHeight - skirtHeight`
    // A similar change might also be needed in `upsampleQuantizedTerrainMesh.js`.

    var rectangle = provider._tilingScheme.tileXYToRectangle(x, y, level);
    var orientedBoundingBox = OrientedBoundingBox.fromRectangle(
        rectangle,
        minimumHeight,
        maximumHeight,
        provider._tilingScheme.ellipsoid
    );

    return new QuantizedMeshTerrainData({
        center: center,
        minimumHeight: minimumHeight,
        maximumHeight: maximumHeight,
        boundingSphere: boundingSphere,
        orientedBoundingBox: orientedBoundingBox,
        horizonOcclusionPoint: horizonOcclusionPoint,
        quantizedVertices: encodedVertexBuffer,
        encodedNormals: encodedNormalBuffer,
        indices: indices,
        westIndices: westIndices,
        southIndices: southIndices,
        eastIndices: eastIndices,
        northIndices: northIndices,
        westSkirtHeight: skirtHeight,
        southSkirtHeight: skirtHeight,
        eastSkirtHeight: skirtHeight,
        northSkirtHeight: skirtHeight,
        childTileMask: provider.availability.computeChildMaskForTile(level, x, y),
        waterMask: waterMaskBuffer,
        credits: provider._tileCredits
    });
};

/**
 *  Method for patching mesh with additional mesh layer. First terrain in list is taken as a basis,
 *  and other terrains is used to fill nodata vertexes.
 * @param terrains
 * @param tilingScheme
 * @returns {undefined|*}
 */
QuantizedMeshUtil.patchMesh = function (terrains, tilingScheme) {
    var t1 = new Date().getTime();
    if (!defined(terrains) || !defined(tilingScheme) || terrains.length < 2) {
        return undefined;
    }
    const mesh = terrains[0].terrain;
    const x = terrains[0].x;
    const y = terrains[0].y;
    const level = terrains[0].level;
    const minElevation = terrains[0].minElevation;
    const additionalTerrain =
        terrains.length > 2
            ? {
                  terrain: QuantizedMeshUtil.patchMesh(terrains.slice(1), tilingScheme),
                  x: terrains[1].x,
                  y: terrains[1].y,
                  level: terrains[1].level,
                  minElevation: terrains[1].minElevation
              }
            : terrains[1];

    if (defined(minElevation) && minElevation < mesh._minimumHeight) {
        // Mesh does not need to be patched
        return mesh;
    }
    if (defined(minElevation) && mesh._maximumHeight <= minElevation && level === additionalTerrain.level) {
        return additionalTerrain.terrain;
    }
    var hackCounter;
    var vertexCount = mesh._quantizedVertices.length / 3;
    const additionalMesh = additionalTerrain.terrain;

    //START
    var uBuffer = mesh._quantizedVertices.subarray(0, vertexCount);
    var vBuffer = mesh._quantizedVertices.subarray(vertexCount, 2 * vertexCount);
    var heightBuffer = mesh._quantizedVertices.subarray(vertexCount * 2, 3 * vertexCount);
    const relativeMinElevation =
        defined(minElevation) && mesh._maximumHeight - mesh._minimumHeight > 0
            ? ((minElevation - mesh._minimumHeight) / (mesh._maximumHeight - mesh._minimumHeight)) * MAX_SHORT
            : 0;

    const rectangle = tilingScheme.tileXYToRectangle(x, y, level);
    const additionalRectangle = tilingScheme.tileXYToRectangle(
        additionalTerrain.x,
        additionalTerrain.y,
        additionalTerrain.level
    );

    var updatedPositions = [];
    var indexesH = [];
    var oldNotZeroMin = MAX_SHORT;
    for (hackCounter = 0; hackCounter < vertexCount; hackCounter++) {
        if (heightBuffer[hackCounter] === 0 || heightBuffer[hackCounter] < relativeMinElevation) {
            const newH =
                level === additionalTerrain.level
                    ? interpolateHeight(additionalMesh, uBuffer[hackCounter], vBuffer[hackCounter])
                    : interpolateHeightFromAnotherLevel(
                          additionalMesh,
                          uBuffer[hackCounter],
                          vBuffer[hackCounter],
                          rectangle,
                          additionalRectangle
                      );
            updatedPositions.push(newH);
            indexesH.push(hackCounter);
        } else if (heightBuffer[hackCounter] < oldNotZeroMin) {
            oldNotZeroMin = heightBuffer[hackCounter];
        }
    }

    //find new min max
    var min =
        oldNotZeroMin === MAX_SHORT
            ? MAX_SHORT
            : (oldNotZeroMin / MAX_SHORT) * (mesh._maximumHeight - mesh._minimumHeight) + mesh._minimumHeight;
    var max = mesh._maximumHeight;
    for (hackCounter = 0; hackCounter < updatedPositions.length; hackCounter++) {
        if (updatedPositions[hackCounter] > max) {
            max = updatedPositions[hackCounter];
        }
        if (updatedPositions[hackCounter] < min) {
            min = updatedPositions[hackCounter];
        }
    }

    //if min max changed
    if (min !== mesh._minimumHeight || max !== mesh._maximumHeight) {
        var newKoeff = (mesh._maximumHeight - mesh._minimumHeight) / (max - min);
        var newAdditional = (MAX_SHORT * (mesh._minimumHeight - min)) / (max - min);
        for (hackCounter = 0; hackCounter < heightBuffer.length; hackCounter++) {
            heightBuffer[hackCounter] = heightBuffer[hackCounter] * newKoeff + newAdditional;
        }
        mesh._orientedBoundingBox = calculateBoundingBox(tilingScheme, x, y, level, min, max);
        mesh._minimumHeight = min;
        mesh._maximumHeight = max;
        mesh._boundingSphere = BoundingSphere.fromOrientedBoundingBox(mesh._orientedBoundingBox);
    }

    var k = MAX_SHORT / (max - min);
    for (hackCounter = 0; hackCounter < indexesH.length; hackCounter++) {
        var ind = indexesH[hackCounter];
        var newHeight = (updatedPositions[hackCounter] - min) * k;
        if (newHeight < 0) {
            newHeight = 0;
        } else if (newHeight > MAX_SHORT) {
            newHeight = MAX_SHORT;
        }
        heightBuffer[ind] = newHeight;
    }
    t1 = new Date().getTime() - t1;
    if (t1 > 1) {
        if (import.meta.env.NODE_ENV === 'development')
            console.debug('Spent time in millis = ' + t1 + ' level ' + level);
    }
    return mesh;
};

/**
 * @param {Number} [x] An X coordinate of tile in a reference tiling scheme.
 * @param {Number} [y] An Y coordinate of tile in a reference tiling scheme.
 * @param {Number} [level] An Levelev of tile in a reference tiling scheme.
 * @param {QuantizedMeshTerrainData} [tileData] A QuantizedMeshTerrainData request result.
 * @returns {TerrainMeshData} An object with vertices and indices of tiles in ECEF coordinate system/
 */
QuantizedMeshUtil.getTerrainTileMesh = function (x, y, level, tileData) {
    const vertices = [];
    const indices = tileData._indices;

    const tilingScheme = new GeographicTilingScheme();
    const rectangle = tilingScheme.tileXYToRectangle(x, y, level);

    const MAX_SHORT = 32767;

    const minHeight = tileData._minimumHeight;
    const maxHeight = tileData._maximumHeight;

    const quantizedVertices = tileData._quantizedVertices;
    const vertexCount = quantizedVertices.length / 3;
    const uBuffer = quantizedVertices.subarray(0, vertexCount);
    const vBuffer = quantizedVertices.subarray(vertexCount, 2 * vertexCount);
    const heightBuffer = quantizedVertices.subarray(vertexCount * 2, 3 * vertexCount);

    for (let i = 0; i < vertexCount; i++) {
        const rawU = uBuffer[i];
        const rawV = vBuffer[i];
        const rawH = heightBuffer[i];

        const u = rawU / MAX_SHORT;
        const v = rawV / MAX_SHORT;
        const h = rawH / MAX_SHORT;

        const longitude = CesiumMath.toDegrees(CesiumMath.lerp(rectangle.west, rectangle.east, u));
        const latitude = CesiumMath.toDegrees(CesiumMath.lerp(rectangle.south, rectangle.north, v));
        const height = CesiumMath.lerp(minHeight, maxHeight, h);

        const cartesian = Cartesian3.fromDegrees(longitude, latitude, height, Ellipsoid.WGS84, new Cartesian3());
        vertices.push(cartesian);
    }

    const mesh = {
        vertices: vertices,
        indices: indices
    };
    return mesh;
};

function calculateBoundingBox(tilingScheme, x, y, level, min, max) {
    var rectangle = tilingScheme.tileXYToRectangle(x, y, level);
    return OrientedBoundingBox.fromRectangle(rectangle, min, max, tilingScheme.ellipsoid);
}

function interpolateHeightFromAnotherLevel(terrainData, rawU, rawV, rectangle, additionalRectangle) {
    const u = rawU / MAX_SHORT;
    const v = rawV / MAX_SHORT;
    const longitude = CesiumMath.lerp(rectangle.west, rectangle.east, u);
    const latitude = CesiumMath.lerp(rectangle.south, rectangle.north, v);
    return terrainData.interpolateHeight(additionalRectangle, longitude, latitude);
}

function interpolateHeight(terrainData, u, v) {
    var uBuffer = terrainData._uValues;
    var vBuffer = terrainData._vValues;
    var heightBuffer = terrainData._heightValues;
    var barycentricCoordinateScratch = new Cartesian3();

    var indices = terrainData._indices;
    for (var i = 0, len = indices.length; i < len; i += 3) {
        var i0 = indices[i];
        var i1 = indices[i + 1];
        var i2 = indices[i + 2];

        var u0 = uBuffer[i0];
        var u1 = uBuffer[i1];
        var u2 = uBuffer[i2];

        var v0 = vBuffer[i0];
        var v1 = vBuffer[i1];
        var v2 = vBuffer[i2];

        if (pointInBoundingBox(u, v, u0, v0, u1, v1, u2, v2)) {
            var barycentric = Intersections2D.computeBarycentricCoordinates(
                u,
                v,
                u0,
                v0,
                u1,
                v1,
                u2,
                v2,
                barycentricCoordinateScratch
            );
            if (barycentric.x >= -1e-15 && barycentric.y >= -1e-15 && barycentric.z >= -1e-15) {
                var quantizedHeight =
                    barycentric.x * heightBuffer[i0] +
                    barycentric.y * heightBuffer[i1] +
                    barycentric.z * heightBuffer[i2];
                return CesiumMath.lerp(
                    terrainData._minimumHeight,
                    terrainData._maximumHeight,
                    quantizedHeight / MAX_SHORT
                );
            }
        }
    }
    // Position does not lie in any triangle in this mesh.
    return undefined;
}

function pointInBoundingBox(u, v, u0, v0, u1, v1, u2, v2) {
    var minU = Math.min(u0, u1, u2);
    var maxU = Math.max(u0, u1, u2);
    var minV = Math.min(v0, v1, v2);
    var maxV = Math.max(v0, v1, v2);
    return u >= minU && u <= maxU && v >= minV && v <= maxV;
}

export default QuantizedMeshUtil;
