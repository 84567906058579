import { useRef } from 'react';
import { ExtendedCamera } from '../../../store/slices/cameras';
import useGetElementDimensions from '../../../hooks/useGetElementDimensions';
import InspectedImage from './InspectedImage';
import { MIN_INSPECTION_WIDTH } from './InspectOverlay';

type Props = {
    camera: ExtendedCamera;
};

export default function InspectedImageContainer({ camera }: Props) {
    const imageContainerRef = useRef<HTMLDivElement>(null);
    const imageWrapperRef = useRef<HTMLDivElement>(null);

    const imageContainerDimensions = useGetElementDimensions(imageContainerRef);
    const imageWrapperDimensions = useGetElementDimensions(imageWrapperRef);

    const showImage = (imageContainerDimensions.width ?? 0) >= MIN_INSPECTION_WIDTH;

    return (
        <div className='inspect-image' ref={imageContainerRef}>
            <div className='image-wrapper' ref={imageWrapperRef}>
                {showImage ? (
                    <InspectedImage
                        key={camera.photoUid}
                        camera={camera}
                        wrapperWidth={imageWrapperDimensions.width}
                        wrapperHeight={imageWrapperDimensions.height}
                    />
                ) : null}
            </div>
        </div>
    );
}
