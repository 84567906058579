import convertSize from '../../../lib/convertSize';
import getFilenameExtension from '../../../lib/getFilenameExtension';
import { ExtendedCamera } from '../../../store/slices/cameras';
import i18n from '../../../i18n/config';

type Props = {
    camera: ExtendedCamera;
    cameras: ExtendedCamera[]; // NOT always all cameras!
};

export default function CameraPhotoInfo({ camera, cameras }: Props) {
    const cameraIndex = cameras.findIndex(c => c.uid === camera.uid);

    return (
        <div className='image-overlay'>
            <div className='image-title'>
                <span className='image-counter'>
                    <span data-testid='imagesCounter'>
                        {cameraIndex + 1} / {cameras.length}
                    </span>
                </span>
                <b>{camera.label}</b>
            </div>
            <div className='image-info'>
                <span>{getFilenameExtension(camera.fileName || '')}</span>
                <span className='divider' />
                <span>{convertSize(camera.sizeInBytes)}</span>
                <span className='divider' />
                <span>
                    {camera.width}x{camera.height} {i18n.t('common:px')}
                </span>
            </div>
        </div>
    );
}
