import isWebglSupported from './lib/isWebglSupported';
import { decodeDelimitedArray, encodeDelimitedArray } from 'use-query-params';
import { isMobile } from 'react-device-detect';
import * as Cesium from 'cesium';
import { GeoJson, TemporaryGeometry } from './store/helpers/interfaces';
import crosshairIcon from './assets/images/crosshair.svg';
import i18n, {
    australiaStatesToMapping,
    canadaStatesToLangMapping,
    SupportedLocales,
    usaStatesToLangMapping
} from './i18n/config';
import _ from 'lodash';

export enum ProjectsSortModes {
    MODIFICATION_DATE = 'modificationDate',
    SIZE = 'size',
    NAME = 'name',
    SURVEY_DATE = 'surveyDate'
}

export enum ProjectsSortOrders {
    ASCENDING = 'asc',
    DESCENDING = 'desc'
}

const UUID_REGEX = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

const UUID_REGEX_STRING: string = UUID_REGEX.toString().slice(1, UUID_REGEX.toString().length).slice(0, -1);

const CLOUD_URL = window.location.origin;

export const LOGIN_URL = `${CLOUD_URL}/oauth2/login`;

export enum TerrainViewModes {
    EARTH,
    MODEL
}

export enum QualityOf3DModes {
    LOW,
    MEDIUM,
    HIGH
}

const TABLE_MOBILE_BREAKPOINT = 576;

const TABLE_MINIFIED_BREAKPOINT = 932;

// Map panels, control buttons, darkened overlay visibility & toggle behaviour
export const MAP_PANEL_DEFAULT_WIDTH = 360; // базовая (максимальная) ширина панелей в инспекторе проекта

const MAP_PANEL_MIN_WIDTH = 280; // минимальная ширина панелей в инспекторе проекта

export const MAP_PANEL_CONTROL_WIDTH = 35; // ширина управляющих кнопок панелей в инспекторе проекта = 28px + отступ на тень до следующей кнопки/до границы окна. В эмбед-окнах = пространство для оверлея

const TWO_PANELS_MIN_WIDTH = (MAP_PANEL_MIN_WIDTH + MAP_PANEL_CONTROL_WIDTH) * 2; // мин шририна при которой могут быть раскрыты обе панели

export const DARK_OVERLAY_BREAKPOINT = TWO_PANELS_MIN_WIDTH;

const MAP_SETTINGS_BREAKPOINT = MAP_PANEL_MIN_WIDTH + MAP_PANEL_CONTROL_WIDTH;

export const DESKTOP_BREAKPOINT = 1200; // смена мобильного вида на десктопный. значение должно быть равно scss переменной $desktop_layout_breakpoint

export enum CoordinateSystemType {
    GEOGRAPHIC = 'geographic',
    GEOCENTRIC = 'geocentric',
    PROJECTED = 'projected'
}

export enum Units {
    METRE = 'Metre',
    FOOT = 'Foot',
    US_SURVEY_FOOT = 'US Survey Foot'
}

export const Routes = {
    INDEX: '/',
    SHARED: '/shared/projects',
    PROJECT_VIEW: `/projects/:id(${UUID_REGEX_STRING})`,
    SITE: `/sites/:id(${UUID_REGEX_STRING})`,
    FOLDER: `/:folderId(${UUID_REGEX_STRING})`,
    SHARED_SITE: `/shared/sites/:hash(${UUID_REGEX_STRING})`,
    SITE_VIEW: `/sites/:id(${UUID_REGEX_STRING})/view`,
    SHARED_SITE_VIEW: `/shared/sites/:hash(${UUID_REGEX_STRING})/view`,
    SHARED_PROJECT_VIEW: `/shared/projects/:hash(${UUID_REGEX_STRING})`,
    EMBEDDED_PROJECT_VIEW: `/embedded/projects/:id(${UUID_REGEX_STRING})/:embed(${UUID_REGEX_STRING})`,
    BILLING: '/billing',
    INVOICES: '/invoices',
    PREFERENCES: '/preferences',
    PLAN_MANAGEMENT: '/plan-management',
    UPGRADE_PLAN: '/upgrade-plan',
    STORAGE: '/storage',
    TIME: '/time',
    UNSUBSCRIBE: '/unsubscribe',
    USER_AGREEMENT: '/agreement',
    NOT_FOUND: '/404'
};

export const ownerProjectViewRoutes = [Routes.PROJECT_VIEW, Routes.SITE, Routes.SITE_VIEW];

export const unprotectedRoutes = [
    Routes.SHARED_PROJECT_VIEW,
    Routes.SHARED_SITE,
    Routes.SHARED_SITE_VIEW,
    Routes.EMBEDDED_PROJECT_VIEW,
    Routes.UNSUBSCRIBE,
    Routes.USER_AGREEMENT,
    Routes.NOT_FOUND
];

export enum EmbedViewMode {
    PRESENTATION = 'Presentation',
    FULL = 'Full'
}

export const WEBGL_SUPPORTED = isWebglSupported();

export const CSVParam = {
    encode: (array: string[] | undefined) => encodeDelimitedArray(array, ','),
    decode: (arrayStr: string | (string | null)[] | null | undefined) => decodeDelimitedArray(arrayStr, ',')
};

// Enum values are GeoJson geometry.type
export enum GeometryTypes {
    POINT = 'Point',
    POLYLINE = 'LineString',
    POLYGON = 'Polygon'
}
export function isGeoJsonFeatureSupported(geoJson: GeoJson): boolean {
    return Object.values(GeometryTypes).includes(geoJson.geometry.type);
}

export const nadirHeadingPitchRange = new Cesium.HeadingPitchRange(0, -1.5708);

export const IS_TOUCH_DEVICE = window.matchMedia('(pointer: coarse)').matches || isMobile;

const calculatedReservedPropertiesNames = [
    'ac_elevation_meters',
    'ac_elevation_feet',
    'ac_length_meters',
    'ac_length_feet',
    'ac_horizontal_length_meters',
    'ac_horizontal_length_feet',
    'ac_terrain_length_meters',
    'ac_terrain_length_feet',
    'ac_elevation_difference_meters',
    'ac_elevation_difference_feet',
    'ac_slope_percents',
    'ac_slope_degrees',
    'ac_area2d_square_meters',
    'ac_area2d_square_feet',
    'ac_area_square_meters',
    'ac_area_square_feet',
    'ac_perimeter2d_meters',
    'ac_perimeter2d_feet',
    'ac_perimeter3d_meters',
    'ac_perimeter3d_feet'
];

export const volumePropertyNames = [
    'ac_volume_base_plane',
    'ac_volume_surface',
    'ac_volume_cell_size_meters',
    'ac_volume_cell_size_feet',
    'ac_volume_cell_size_feet_us',
    'ac_volume_base_level_meters',
    'ac_volume_base_level_feet',
    'ac_volume_base_level_feet_us',
    'ac_volume_above_cubic_meters',
    'ac_volume_above_cubic_feet',
    'ac_volume_above_cubic_feet_us',
    'ac_volume_below_cubic_meters',
    'ac_volume_below_cubic_feet',
    'ac_volume_below_cubic_feet_us',
    'ac_volume_total_cubic_meters',
    'ac_volume_total_cubic_feet',
    'ac_volume_total_cubic_feet_us'
];

export const reservedPropertiesNames = [
    'ac_name',
    'ac_description',
    'ac_color',
    'ac_visibility',
    'ac_style',
    'ac_stroke_color',
    'ac_severity',
    'ac_type',
    'ac_description',
    'ac_issue_bbox',
    'ac_photoUid',
    'ac_point_of_view',
    'ac_image_uid',
    'ac_image_name',
    'ac_style_block_expanded',
    'ac_general_block_expanded',
    'ac_additional_block_expanded',
    'ac_elevation_profile_block_expanded',
    'ac_volume_block_expanded',
    'ac_preview_uid',
    'ac_duration',
    ...volumePropertyNames,
    ...calculatedReservedPropertiesNames
];

export const propertiesTooltipMessages = () => ({
    reserved: i18n.t('projectView:geometryProperties.error_reserved'),
    exists: i18n.t('projectView:geometryProperties.error_exists'),
    empty: i18n.t('projectView:geometryProperties.error_empty')
});

export type Tuple<T, N extends number> = N extends N ? (number extends N ? T[] : _TupleOf<T, N, []>) : never;
type _TupleOf<T, N extends number, R extends unknown[]> = R['length'] extends N ? R : _TupleOf<T, N, [T, ...R]>;

export const DRAWING_CURSOR = `url('${crosshairIcon}') 18 18, auto`;

export const primitiveOptimizationProps = {
    vertexCacheOptimize: true,
    releaseGeometryInstances: false,
    interleave: true,
    compressVertices: true
};

export const inspectorMainImageThumbnailParam = {
    width: 352,
    height: 264,
    mode: 'FIT'
};

export const inspectorImageListThumbnailParam = {
    width: 116,
    height: 116,
    mode: 'FILL'
};

export const floatRegex = /^[+-]?[0-9]*[.]?[0-9]*$/;

export const floatPositiveRegex = /^[0-9]*[.]?[0-9]*$/;

export const WGS84_EPSG_CODE = 4326;

export interface ApiError {
    message: string;
    timestamp: string;
    status: number;
}

// for use with selectors' reference equality
export const EMPTY_ARRAY = [];

export const GeometryNamesFontSizes = {
    extraSmall: '11px sans-serif',
    small: '14px sans-serif',
    medium: '17px sans-serif',
    large: '20px sans-serif',
    extraLarge: '25px sans-serif'
} as const;
const GeometryNamesFontSizesPxOnly = _.reduce(
    GeometryNamesFontSizes,
    (prev, cur, key) => ({ ...prev, [key]: cur.match(/\d*px/)?.[0] || '' }),
    {} as Record<GeometryNamesFontIds, string>
);
export type GeometryNamesFontIds = keyof typeof GeometryNamesFontSizes;
export type SupportedGeometryNamesFontSizes = (typeof GeometryNamesFontSizes)[GeometryNamesFontIds];

export const FULL_IMAGE_SIZE_LIMIT = 104857600; // 100 MB in bytes

export type WidthHeight = { width: number; height: number };

export const MAX_GEOMETRY_DESCRIPTION_LENGTH = 1025;

export const DATE_FORMAT = 'dd.MM.yyyy';

export const INVOICES_PAGE_LIMIT = 14;

export const statesMapping: Record<string, Array<any>> = {
    US: usaStatesToLangMapping[i18n.resolvedLanguage as SupportedLocales],
    AU: australiaStatesToMapping[i18n.resolvedLanguage as SupportedLocales],
    CA: canadaStatesToLangMapping[i18n.resolvedLanguage as SupportedLocales]
};

export const definedStatesCountries = _.keys(statesMapping);

export const SUMMARY_BREAKPOINT = 768; // subscription summary mobile->desktop breakpoint (px)

export const LS_ACCOUNT_IN_PROGRESS = 'ACCOUNT_IN_PROGRESS';

export const distinctColorsList = ['#4892E9', '#55DD59', '#9957DB', '#F0A042', '#ECF042'];

export const INSPECTOR_PANEL_ANIMATION_DURATION = 300;
