import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { cancelSubscriptionTransition } from '../store/slices/billing';
import { setAccountUpdateInProgress } from '../store/slices/globalFlags';
import { setScheduledPlan } from '../store/slices/accountResources';

export default function useCancelDowngrade() {
    const dispatch: AppDispatch = useDispatch();
    const history = useHistory();

    return async () => {
        await dispatch(cancelSubscriptionTransition());
        dispatch(setAccountUpdateInProgress(true));
        dispatch(setScheduledPlan(null));

        if (window.location.pathname !== '/') history.push('/');
    };
}
