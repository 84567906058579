import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { deleteEmailAccess } from '../../../store/slices/sharing';
import { AccessInfo as AccessInfoV2 } from '../../../generated/access-api-v2/model';

type Props = {
    email: string;
    projectId: string;
    currentAccesses: AccessInfoV2[] | undefined;
};

export default function ShareModalEmailListItem({ email, projectId, currentAccesses }: Props) {
    const dispatch: AppDispatch = useDispatch();

    return (
        <div className='user'>
            <div className='user-icon'>
                <i className='icon icon-user' />
            </div>
            <span className='user-email'>{email}</span>
            <div
                className='remove-user-area'
                data-testid={'deleteEmailAccess'}
                onClick={e => {
                    dispatch(deleteEmailAccess({ email, projectId, currentAccesses }));
                }}
            >
                <i className='icon icon-delete' />
            </div>
        </div>
    );
}
