import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../../store';
import { publishProject } from '../../../store/slices/projectActions';
import { Trans, useTranslation } from 'react-i18next';
import ProjectActionModal from '../../Elements/project-actions/modals/ProjectActionModal';
import { ModalProps } from '../../Elements/modal/Modal';
import { selectProjects, setSelectedProjects } from '../../../store/slices/projectsPage';

type Props = ModalProps & { selectedProjects: string[] };

export default function PublishSeveralProjectsModal({ isOpen, setIsOpen, selectedProjects }: Props) {
    const { t } = useTranslation('projects');
    const dispatch: AppDispatch = useDispatch();
    const projects = useSelector(state => selectProjects(state));
    const numberOfSelectedProjects = selectedProjects.length;

    return (
        <ProjectActionModal
            isOpen={isOpen}
            setIsOpen={() => setIsOpen(false)}
            headerText={t('projectActions.publish')}
            areYouSureText={
                <Trans
                    ns={'modals'}
                    i18nKey={
                        numberOfSelectedProjects > 1 ? 'publishSeveralProjectsModal.body' : 'publishProjectModal.body'
                    }
                    components={{ p: <p />, s: <strong className='overflow' /> }}
                    values={{
                        projectsCount: numberOfSelectedProjects,
                        projectName: projects.find(p => p.id === selectedProjects[0])?.name || ''
                    }}
                />
            }
            actionButtonText={t('projectActions.publish')}
            actionButtonStyle={'actionConfirm'}
            cancelButtonText={t('projectActions.cancel')}
            cancelButtonStyle={'cancelBlue'}
            onAction={async () => {
                const promises = selectedProjects.map(p => dispatch(publishProject({ id: p, onProjectsPage: true })));
                await Promise.all(promises);
                dispatch(setSelectedProjects([]));
            }}
            onCancel={() => {
                setIsOpen(false);
            }}
        />
    );
}
