import { useState } from 'react';
import { Field, useFormikContext } from 'formik';
import RadiobuttonWrapper from '../elements/radiobutton-wrapper/RadiobuttonWrapper';
import classNames from 'classnames';
import { SubscriptionFormValues } from '../SubscriptionForm';
import { useSelector } from '../../../store';
import { useTranslation } from 'react-i18next';
import EstimateBudgetModal from '../../Elements/modals/estimate-budget/EstimateBudgetModal';

import styles from '../SubscriptionForm.module.scss';

type Props = {
    onlyHours: boolean;
    isHoursListVisible: boolean;
    makeHoursListVisible: () => void;
};

export default function ProcessingHoursSubscription({ onlyHours, isHoursListVisible, makeHoursListVisible }: Props) {
    const { t } = useTranslation(['subscribe', 'glossary']);
    const { values } = useFormikContext<SubscriptionFormValues>();
    const hoursProducts = useSelector(state => state.billing.products.processingTimeProducts);
    const products = onlyHours ? hoursProducts.filter(hp => hp.name !== '0 hours') : hoursProducts;

    const [isBudgetModalOpen, setIsBudgetModalOpen] = useState(false);

    return (
        <>
            <div className={styles.contentSection}>
                <h2 className={classNames(styles.sectionTitle, styles.processingHoursTitle)}>
                    <span>{t('glossary:processing')}</span>
                    {isHoursListVisible ? (
                        <span
                            className={classNames('link-like', styles.processingHoursAction)}
                            onClick={() => setIsBudgetModalOpen(true)}
                        >
                            {t('subscribe:processingHours.estimateBudget')}
                        </span>
                    ) : (
                        <span
                            className={classNames('link-like', styles.processingHoursAction)}
                            onClick={() => makeHoursListVisible()}
                        >
                            {t('subscribe:processingHours.add')}
                        </span>
                    )}
                </h2>

                <div className={styles.fieldInfo}>
                    {isHoursListVisible ? (
                        <p>{t('subscribe:processingHours.commercialPlanHint')}</p>
                    ) : (
                        <p>{t('subscribe:processingHours.noPurchasedHoursHint')}</p>
                    )}
                    <p>{t('subscribe:processingHours.metashapeProHint')}</p>
                </div>

                {isHoursListVisible && (
                    <ul className={styles.formList}>
                        {products.map(({ price, name }) => (
                            <li className={styles.formListItem} key={name}>
                                <label
                                    className={classNames(styles.radioItem, values.hours === name && styles.checked)}
                                >
                                    <div className={styles.radioButtonWrapper}>
                                        <RadiobuttonWrapper>
                                            <Field type='radio' name='hours' value={name} />
                                        </RadiobuttonWrapper>
                                    </div>
                                    <span className={styles.radioText}>
                                        <b>{name}</b> ${price}
                                    </span>
                                </label>
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            {isBudgetModalOpen && (
                <EstimateBudgetModal isOpen={isBudgetModalOpen} setIsOpen={setIsBudgetModalOpen} products={products} />
            )}
        </>
    );
}
