/* tslint:disable */
/* eslint-disable */
/**
 * account-frontend-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Account } from '../model';
// @ts-ignore
import { PersonalInfo } from '../model';
/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns aggregated information for account page
         * @summary Information for account page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns aggregated information for personal info page
         * @summary Information for personal info&preferences page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/personal-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update personal info
         * @param {PersonalInfo} personalInfo Personal info for update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonalInfo: async (personalInfo: PersonalInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personalInfo' is not null or undefined
            assertParamExists('updatePersonalInfo', 'personalInfo', personalInfo)
            const localVarPath = `/personal-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personalInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns aggregated information for account page
         * @summary Information for account page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns aggregated information for personal info page
         * @summary Information for personal info&preferences page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonalInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update personal info
         * @param {PersonalInfo} personalInfo Personal info for update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePersonalInfo(personalInfo: PersonalInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePersonalInfo(personalInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiApiFp(configuration)
    return {
        /**
         * Returns aggregated information for account page
         * @summary Information for account page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(options?: any): AxiosPromise<Account> {
            return localVarFp.getAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns aggregated information for personal info page
         * @summary Information for personal info&preferences page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalInfo(options?: any): AxiosPromise<PersonalInfo> {
            return localVarFp.getPersonalInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update personal info
         * @param {PersonalInfo} personalInfo Personal info for update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePersonalInfo(personalInfo: PersonalInfo, options?: any): AxiosPromise<void> {
            return localVarFp.updatePersonalInfo(personalInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
    /**
     * Returns aggregated information for account page
     * @summary Information for account page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public getAccount(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).getAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns aggregated information for personal info page
     * @summary Information for personal info&preferences page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public getPersonalInfo(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).getPersonalInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update personal info
     * @param {PersonalInfo} personalInfo Personal info for update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public updatePersonalInfo(personalInfo: PersonalInfo, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).updatePersonalInfo(personalInfo, options).then((request) => request(this.axios, this.basePath));
    }
}
