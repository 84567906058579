import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectInfo } from '../../../../generated/cloud-frontend-api/model';
import TippyDropdownItemWithIcon from '../../tippy-dropdown/TippyDropdownItemWithIcon';
import ProcessingInfoModal from '../../modals/processing-info-modal/ProcessingInfoModal';
import { ReactComponent as SvgProcessingInfo } from '../../../../svg/dropdown_actions/processing_info.svg';

type Props = {
    project: ProjectInfo;
};

export default function ShowProcessingInfoAction({ project }: Props) {
    const { t } = useTranslation(['projects']);
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <TippyDropdownItemWithIcon
                icon={<SvgProcessingInfo />}
                text={t('projects:projectActions.processingInfo')}
                onClick={() => {
                    setIsModalOpen(true);
                }}
            />

            {isModalOpen && (
                <ProcessingInfoModal setIsOpen={setIsModalOpen} isOpen={isModalOpen} projectInfo={project} />
            )}
        </>
    );
}
