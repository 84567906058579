import * as Cesium from 'cesium';
import { Cartesian3, TerrainProvider } from 'cesium';
import GeometryMeasures, { computeCenterOfPoints } from './GeometryMeasures';
import { inv, matrix, multiply } from 'mathjs';
import { VolumeBaseLevel, VolumeBasePlane } from '../../../../store/helpers/interfaces';
import { calculateVolume } from './VolumeUtils';

export interface CalculateVolumeResult {
    above: number;
    below: number;
    total: number;
    calculatedBaseLevel: VolumeBaseLevel;
}

export default class PolygonMeasures extends GeometryMeasures {
    private readonly _outerRing: Cesium.Cartesian3[];
    private readonly _innerRings: Cesium.Cartesian3[][];

    constructor(coordinates: number[][][]) {
        super();
        this._outerRing = coordinates[0].map(c => this.fromCartographicToCartesian3(c));
        // All other coordinates are inner rings (polygon holes)
        if (coordinates.length > 1) {
            const innerRingCoordinates = coordinates.slice(1, coordinates.length);
            this._innerRings = innerRingCoordinates.map(c => c.map(c => this.fromCartographicToCartesian3(c)));
        } else this._innerRings = [];
    }

    public get vertices(): Cesium.Cartesian3[] {
        return [...this._outerRing, ...this._innerRings.flat()];
    }

    public area2D(): number {
        let polygonArea2D = 0;
        const ellipsoidTangentPlane = Cesium.EllipsoidTangentPlane.fromPoints(
            this._outerRing,
            this._coordinatesSystemEllipsoid
        );
        const outerRing2D = ellipsoidTangentPlane.projectPointsToNearestOnPlane(this._outerRing);
        polygonArea2D += computeArea2D(outerRing2D);

        for (const innerRing of this._innerRings) {
            const innerRingPositions2D = ellipsoidTangentPlane.projectPointsToNearestOnPlane(innerRing);
            const innerRingArea2D = computeArea2D(innerRingPositions2D);
            polygonArea2D -= innerRingArea2D;
        }

        return polygonArea2D;
    }

    public perimeter2D(): number {
        return this.length2D(this._outerRing);
    }

    public perimeter3D(): number {
        return this.length3D(this._outerRing);
    }

    public area(): number {
        try {
            let polygonBestFitArea = 0;
            const bestFittingPlane = computeBestFittingPlane(this._outerRing);

            const outerRingPositions2D = projectPointsToNearestOnPlane(this._outerRing, bestFittingPlane);
            polygonBestFitArea += computeArea2D(outerRingPositions2D);
            if (this._innerRings.length) {
                for (let i = 0; i < this._innerRings.length; i++) {
                    let innerRingPositions2D = projectPointsToNearestOnPlane(this._innerRings[i], bestFittingPlane);
                    let innerRingBestFitArea = computeArea2D(innerRingPositions2D);
                    polygonBestFitArea -= innerRingBestFitArea;
                }
            }
            return polygonBestFitArea;
        } catch (e) {
            return 0;
        }
    }

    public async volume(
        basePlane: VolumeBasePlane,
        baseLevel: VolumeBaseLevel,
        terrainProvider: TerrainProvider
    ): Promise<CalculateVolumeResult> {
        return calculateVolume(terrainProvider, this._outerRing, this._innerRings, basePlane, baseLevel);
    }

    public center(): Cesium.Cartesian3 {
        return computeCenterOfPoints(this._outerRing);
    }

    public valuesToObject() {
        return {
            ac_area2d_square_meters: parseFloat(this.area2D().toFixed(3)),
            ac_area_square_meters: parseFloat(this.area().toFixed(3)),
            ac_perimeter2d_meters: parseFloat(this.perimeter2D().toFixed(3)),
            ac_perimeter3d_meters: parseFloat(this.perimeter3D().toFixed(3))
        };
    }
}

// Вспомогательная функция вычисления площади по формуле Гауса: https://en.wikipedia.org/wiki/Shoelace_formula
function computeArea2D(positions: Cesium.Cartesian2[]) {
    const length = positions.length;
    let area = 0.0;

    for (let i0 = length - 1, i1 = 0; i1 < length; i0 = i1++) {
        const v0 = positions[i0];
        const v1 = positions[i1];
        area += v0.x * v1.y - v1.x * v0.y;
    }

    return Math.abs(area * 0.5);
}

//Algorithm which is used in metashape
export function computeBestFittingPlane(positions: Cesium.Cartesian3[]): Cesium.Plane {
    const mean = computeCenterOfPoints(positions);

    // compute least squares plane
    let plane_a = 0;
    let plane_b = 0;
    let plane_c = 0;

    const m = matrix([
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0]
    ]);
    const b = matrix([0, 0, 0]);

    for (let i = 0; i < positions.length - 1; i++) {
        let pt = positions[i];
        pt = Cartesian3.subtract(pt, mean, new Cartesian3());

        m.set([0, 0], m.get([0, 0]) + pt.x * pt.x);
        m.set([0, 1], m.get([0, 1]) + pt.y * pt.x);
        m.set([0, 2], m.get([0, 2]) + pt.x);

        m.set([1, 0], m.get([1, 0]) + pt.x * pt.y);
        m.set([1, 1], m.get([1, 1]) + pt.y * pt.y);
        m.set([1, 2], m.get([1, 2]) + pt.y);

        m.set([2, 0], m.get([2, 0]) + pt.x);
        m.set([2, 1], m.get([2, 1]) + pt.y);
        m.set([2, 2], m.get([2, 2]) + 1.0);

        b.set([0], b.get([0]) + pt.z * pt.x);
        b.set([1], b.get([1]) + pt.z * pt.y);
        b.set([2], b.get([2]) + pt.z);
    }

    if (m.get([2, 2]) != 0) {
        const m_inv = inv(m);
        const x = multiply(m_inv, b);
        plane_a = x.get([0]);
        plane_b = x.get([1]);
        plane_c = x.get([2]);
    }
    plane_c = plane_c + mean.z - plane_a * mean.x - plane_b * mean.y;

    const result = new Cesium.Cartesian3(-plane_a, -plane_b, 1);
    const normal = Cesium.Cartesian3.normalize(result, new Cesium.Cartesian3());
    const d = (plane_c * normal.x) / plane_a;
    return new Cesium.Plane(normal, d);
}

// Вспомогательная функция проецирования коллекции точек
function projectPointsToNearestOnPlane(positions: Cartesian3[], plane: Cesium.Plane): Cesium.Cartesian2[] {
    const projectedPoints = [];
    for (let i = 0; i < positions.length; i++) {
        const projectedPoint = projectPointToNearestOnPlane(positions[i], plane);
        projectedPoints.push(projectedPoint);
    }
    return projectedPoints;
}

function projectPointToNearestOnPlane(position: Cesium.Cartesian3, plane: Cesium.Plane): Cesium.Cartesian2 {
    //Reference 1: https://stackoverflow.com/questions/41275311/a-good-way-to-find-a-vector-perpendicular-to-another-vector
    //Reference 2: https://stackoverflow.com/questions/52185727/transforming-a-3d-plane-onto-a-2d-coordinate-system

    const arbitraryVectors = [
        new Cesium.Cartesian3(1, 0, 0),
        new Cesium.Cartesian3(0, 1, 0),
        new Cesium.Cartesian3(0, 0, 1)
    ];

    const dotProducts = [];
    for (let i = 0; i < arbitraryVectors.length; i++) {
        dotProducts.push(Cesium.Cartesian3.dot(plane.normal, arbitraryVectors[i]));
    }
    const minDot = Math.min(...dotProducts);
    const xAxis = arbitraryVectors[dotProducts.indexOf(minDot)];

    Cesium.Cartesian3.cross(plane.normal, xAxis, xAxis);
    const yAxis = Cesium.Cartesian3.cross(plane.normal, xAxis, new Cesium.Cartesian3());

    Cesium.Cartesian3.normalize(xAxis, xAxis);
    Cesium.Cartesian3.normalize(yAxis, yAxis);

    const x = Cesium.Cartesian3.dot(position, xAxis);
    const y = Cesium.Cartesian3.dot(position, yAxis);

    return new Cesium.Cartesian2(x, y);
}

export function isPointInPolygon(point: Cesium.Cartesian3, positions: Cesium.Cartesian3[]): boolean {
    /**
     * Reference https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html
     */
    const x = point.x;
    const y = point.y;
    const start = 0;
    const end = positions.length;
    let inside = false;

    const len = end - start;
    for (let i = 0, j = len - 1; i < len; j = i++) {
        const xi = positions[i + start].x;
        const yi = positions[i + start].y;
        const xj = positions[j + start].x;
        const yj = positions[j + start].y;
        const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
    }
    return inside;
}
