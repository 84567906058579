import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, useRouteMatch } from 'react-router-dom';
import { ProjectType } from '../../../generated/cloud-frontend-api';
import useIsLoggedIn from '../../../hooks/useIsLoggedIn';
import { LOGIN_URL, Routes, ownerProjectViewRoutes } from '../../../sharedConstants';
import { useSelector } from '../../../store';
import { createLoadingSelector } from '../../../store/selectors/createLoadingSelector';
import { firstProgressTick } from '../../../store/slices/progress';
import { getProjectById } from '../../../store/slices/project';
import { getAccess, getAccessInfo } from '../../../store/slices/sharing';
import { getSite } from '../../../store/slices/site';
import { ReactComponent as SvgCalendar } from '../../../svg/dropdown_actions/calendar.svg';
import AddProjectModal from '../../Elements/add-project-modal/AddProjectModal';
import BreadcrumbsDropdownMenu from './BreadcrumbsDropdownMenu';
import BreadcrumbsBackLink from './back-link/BreadcrumbsBackLink';
import SiteSurveyDateControl from './elements/SiteSurveyDateControl';
import ViewNavigationSwitch from './elements/ViewNavigationSwitch';

export default function NormalBreadcrumbs() {
    const { t } = useTranslation('projectView');
    const loggedIn = useIsLoggedIn();
    const isSitePath = !!useRouteMatch({ exact: true, path: [Routes.SITE, Routes.SITE_VIEW, Routes.SHARED_SITE_VIEW] });
    const projectInfoName = useSelector(state => state.project.projectInfo.name);
    const siteName = useSelector(state => state.site.siteInfo.name);
    const siteProjects = useSelector(state => state.site.siteInfo.projects);
    const [isAddProjectModalOpen, setAddProjectModalOpen] = useState(false);
    const projectName = isSitePath ? siteName : projectInfoName;

    const loadingSelector = isSitePath
        ? createLoadingSelector([getSite.typePrefix])
        : createLoadingSelector([
              getProjectById.typePrefix,
              getAccessInfo.typePrefix,
              getAccess.typePrefix,
              firstProgressTick.typePrefix
          ]);
    const isLoading = useSelector(state => loadingSelector(state));

    return (
        <>
            {!isLoading && projectName ? (
                <>
                    <BreadcrumbsBackLink />
                    <div className='infobar-controls'>
                        <Route exact path={ownerProjectViewRoutes}>
                            <Route exact path={Routes.SITE_VIEW}>
                                {siteProjects.length > 0 ? (
                                    <SiteSurveyDateControl Icon={<SvgCalendar />} />
                                ) : (
                                    <span
                                        className='create-project'
                                        onClick={e => {
                                            setAddProjectModalOpen(true);
                                        }}
                                    >
                                        {t('head.createProject')}
                                    </span>
                                )}
                                {isAddProjectModalOpen && (
                                    <AddProjectModal
                                        projectType={ProjectType.NON_METASHAPE}
                                        isOpen={isAddProjectModalOpen}
                                        setIsOpen={setAddProjectModalOpen}
                                    />
                                )}
                            </Route>
                            <ViewNavigationSwitch />
                            {loggedIn && <BreadcrumbsDropdownMenu />}
                        </Route>
                        {!loggedIn && (
                            <span
                                className='infobar-sign-in'
                                onClick={e => {
                                    e.preventDefault();
                                    window.location.href = LOGIN_URL;
                                }}
                            >
                                {t('breadcrumbs.signIn')}
                            </span>
                        )}
                    </div>
                </>
            ) : (
                <>
                    {!loggedIn && (
                        <>
                            <div className='infobar-info' />
                            <div className='infobar-controls'>
                                <span
                                    className='infobar-sign-in'
                                    onClick={e => {
                                        window.location.href = LOGIN_URL;
                                    }}
                                >
                                    {t('breadcrumbs.signIn')}
                                </span>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
}
