import { useEffect, useState } from 'react';
import { AppDispatch, useSelector } from '../store';
import { useDispatch } from 'react-redux';
import { getDocumentUrl } from '../store/slices/documents';
import { unwrapResult } from '@reduxjs/toolkit';
import isProjectBelongsUser from '../lib/isProjectBelongsUser';

export default function useDocumentUrl(
    documentUid: string | undefined,
    runWhen = true
): {
    url: string;
    isLoading: boolean;
    hasFailed: boolean;
    hasTriedGettingUrl: boolean;
} {
    const dispatch: AppDispatch = useDispatch();
    const projectInfo = useSelector(state => state.project.projectInfo);
    const embedCode = useSelector(state => state.sharing.embedCode);
    const accessInfo = useSelector(state => state.sharing.accessInfo);
    const accessKey = useSelector(state => state.sharing.access.accessKey);
    const isOwnedProject = isProjectBelongsUser(accessInfo, projectInfo);
    const [url, setUrl] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [hasFailed, setHasFailed] = useState(false);
    const [hasTried, setHasTried] = useState(false);

    useEffect(() => {
        if (!runWhen) return;

        if (!documentUid) {
            setHasTried(true);
            setHasFailed(true);
            return;
        }

        setLoading(true);
        let access;
        if (embedCode) access = embedCode;
        if (!embedCode && !isOwnedProject) access = accessKey;
        dispatch(getDocumentUrl({ projectUid: projectInfo.id!, documentUid, access }))
            .then(unwrapResult)
            .then(previewUrl => {
                setUrl(previewUrl!);
            })
            .catch(() => {
                setHasFailed(true);
            })
            .finally(() => {
                setLoading(false);
                setHasTried(true);
            });
    }, [documentUid, dispatch, runWhen, embedCode, isOwnedProject, accessKey, projectInfo.id]);

    return { url, isLoading, hasFailed, hasTriedGettingUrl: hasTried };
}
