import { useCallback, useEffect, useRef } from 'react';
import { AppDispatch, useSelector } from '../../../store';
import { useDispatch } from 'react-redux';
import { setUndoableOperation } from '../../../store/slices/projectView';
import UndoableOperation from './UndoableOperation';
import { useWillUnmount } from 'rooks';
import { useTranslation } from 'react-i18next';

export default function UndoOperation() {
    const { t } = useTranslation(['common']);
    const timeout = useRef(0);
    const prevUndoableOperation = useRef<UndoableOperation | undefined>(undefined);
    const dispatch: AppDispatch = useDispatch();
    const undoableOperation = useSelector(state => state.projectView.undoableOperation);

    const commitOperation = useCallback(() => {
        undoableOperation?.commit();
        dispatch(setUndoableOperation(undefined));
    }, [dispatch, undoableOperation]);

    useEffect(() => {
        if (undoableOperation) {
            if (prevUndoableOperation.current?.id !== undoableOperation.id) {
                prevUndoableOperation.current?.commit();
                clearTimeout(timeout.current);
            }
            timeout.current = window.setTimeout(commitOperation, 15000);
            prevUndoableOperation.current = undoableOperation;
        }
    }, [undoableOperation, dispatch, commitOperation]);

    useWillUnmount(() => {
        clearTimeout(timeout.current);
        prevUndoableOperation.current?.commit();
        dispatch(setUndoableOperation(undefined));
    });

    return undoableOperation ? (
        <div className='undo-primitive-popup'>
            <div className='undo-text' data-testid={'undoText'}>
                {undoableOperation.toString()}
            </div>
            <button
                type='button'
                className='btn btn-undo'
                onClick={e => {
                    undoableOperation.undo();
                    dispatch(setUndoableOperation(undefined));
                    clearTimeout(timeout.current);
                    prevUndoableOperation.current = undefined;
                }}
            >
                {t('common:undo')}
            </button>
            <div
                className='close-area'
                data-testid={'closeArea'}
                onClick={e => {
                    commitOperation();
                    clearTimeout(timeout.current);
                }}
            >
                <div className='close' />
            </div>
        </div>
    ) : null;
}
