import React, { useEffect, useMemo } from 'react';
import { GeoJson } from '../../../store/helpers/interfaces';
import PolygonMeasures from './measures/PolygonMeasures';
import { useSelector } from '../../../store';
import produce from 'immer';
import GeneralProperty from '../../inspection-sidebar/GeneralProperty';
import { GeometryTypes, Units } from '../../../sharedConstants';
import formatNumber from '../../../lib/formatNumber';
import { useTranslation } from 'react-i18next';
import { getUnitsShortName, getUnitsSquareShortName } from '../../../lib/getUnitsShortName';
import { convertAreaUnit, convertLengthUnit } from '../../../lib/convertUnit';

type Props = {
    geoJson: GeoJson<GeometryTypes.POLYGON>;
    setTextForCopying(text: string): void;
};

const precision = 3;

export default function PolygonProperties({ setTextForCopying, geoJson }: Props) {
    const { t } = useTranslation('projectView');
    const coordinates = geoJson.geometry.coordinates;

    const selectedObject = useSelector(state => state.project.selectedObject);
    const { floatingPointCoordinates, currentlyDrawingShapeId } = useSelector(state => state.projectView);
    const units = useSelector(state => state.coordinateSystems.units);
    const unitsNaming = getUnitsShortName(units);
    const unitsSquareNaming = getUnitsSquareShortName(units);

    const shouldAddFloatingPoint = currentlyDrawingShapeId === selectedObject?.artifactId;
    const coordinatesWithFloatingPoint = useMemo(() => {
        if (!shouldAddFloatingPoint || !floatingPointCoordinates) return coordinates;
        return produce(coordinates, draft => {
            // Insert at N-1 index, because Nth point is always a duplicate of 1 point
            draft[0].splice(coordinates.length - 2, 0, floatingPointCoordinates);
        });
    }, [coordinates, floatingPointCoordinates, shouldAddFloatingPoint]);

    const polygonMeasures = useMemo(
        () => new PolygonMeasures(coordinatesWithFloatingPoint),
        [coordinatesWithFloatingPoint]
    );

    const perimeter2D = useMemo(
        () => convertLengthUnit(Number(polygonMeasures.perimeter2D().toFixed(precision)), Units.METRE, units),
        [polygonMeasures, units]
    );
    const perimeter3D = useMemo(
        () => convertLengthUnit(Number(polygonMeasures.perimeter3D().toFixed(precision)), Units.METRE, units),
        [polygonMeasures, units]
    );
    const area2D = useMemo(
        () => convertAreaUnit(Number(polygonMeasures.area2D().toFixed(precision)), Units.METRE, units),
        [polygonMeasures, units]
    );
    const area = useMemo(
        () => convertAreaUnit(Number(polygonMeasures.area().toFixed(precision)), Units.METRE, units),
        [polygonMeasures, units]
    );

    useEffect(() => {
        setTextForCopying(
            `Perimeter 2D\t${perimeter2D.toFixed(precision)} ${unitsNaming}\nPerimeter 3D\t${perimeter3D.toFixed(
                precision
            )} ${unitsNaming}\nArea 2D\t${area2D.toFixed(precision)} ${unitsSquareNaming}\nArea\t${area.toFixed(
                precision
            )} ${unitsSquareNaming}`
        );
    }, [setTextForCopying, perimeter3D, perimeter2D, area2D, area, unitsNaming, unitsSquareNaming]);

    return (
        <>
            <GeneralProperty
                label={t('geometryProperties.perimeter2d')}
                value={`${formatNumber(perimeter2D, precision)} ${unitsNaming}`}
            />
            <GeneralProperty
                label={t('geometryProperties.perimeter3d')}
                value={`${formatNumber(perimeter3D, precision)} ${unitsNaming}`}
            />
            <GeneralProperty
                label={t('geometryProperties.area2d')}
                value={`${formatNumber(area2D, precision)} ${unitsSquareNaming}`}
            />
            <GeneralProperty
                label={t('geometryProperties.area')}
                value={`${formatNumber(area, precision)} ${unitsSquareNaming}`}
                tooltip={t('geometryProperties.tooltipArea')}
            />
        </>
    );
}
