import { SourceType } from '../../../generated/cloud-frontend-api';
import { DatasetFileValidationResult, DatasetFileValidator } from './DatasetFileValidator';
import * as Comlink from 'comlink';

export class ZipFileValidator implements DatasetFileValidator {
    constructor(private readonly file: File) {}

    validate(): Promise<DatasetFileValidationResult> {
        return this.isValidTileset()
            .then(({ sourceType, manualSourceTypeInputRequired }) => {
                if (sourceType || (!sourceType && manualSourceTypeInputRequired)) {
                    return { file: this.file, sourceType, isValid: true };
                }

                return { file: this.file, sourceType: undefined, isValid: false };
            })
            .catch(err => ({ file: this.file, sourceType: undefined, isValid: false }));
    }

    private async isValidTileset(): Promise<{
        sourceType: SourceType | undefined;
        manualSourceTypeInputRequired?: boolean;
    }> {
        return await new Promise(resolve => {
            const proxy = Comlink.wrap<{
                zipFileValidate: (file: File) => {
                    sourceType: SourceType | undefined;
                    manualSourceTypeInputRequired?: boolean;
                };
            }>(
                new Worker(new URL('../../../lib/workers/zip-file-validate.worker.ts', import.meta.url), {
                    type: 'module'
                })
            );

            return proxy
                .zipFileValidate(this.file)
                .then(result => resolve(result))
                .finally(() => {
                    proxy[Comlink.releaseProxy]();
                });
        });
    }
}
