import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import useDrawingCancel from '../../../hooks/useDrawingCancel';
import { AppDispatch, useSelector } from '../../../store';
import { ProjectStructureObjectTypes, SelectedObject } from '../../../store/helpers/interfaces';
import { setSelectedObject } from '../../../store/sharedActions';
import { initCompareTool } from '../../../store/slices/compareTool';
import {
    disableAllControls,
    setCameraFilteringPoint,
    setCompareToolEnabled,
    setSidebarVisibility
} from '../../../store/slices/projectView';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import { useTranslation } from 'react-i18next';
import { selectStructureWithEnabledLimitBox, updateStructureInfo } from '../../../store/slices/structure';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from '../../../sharedConstants';

export default function CompareControl() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const project = useSelector(state => state.project.projectInfo);
    const siteProjects = useSelector(state => state.site.siteInfo.projects);
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const isSidebarExpanded = useSelector(state => state.projectView.isSidebarExpanded);
    const structures = useSelector(state => state.structure.structures);
    const isSiteViewPath = !!useRouteMatch({ path: [Routes.SITE_VIEW], exact: true });
    const structureInfoWithEnabledLimitBox = useSelector(state => selectStructureWithEnabledLimitBox(state));

    const cancelDrawing = useDrawingCancel();

    return (
        <TippyTooltip tooltipText={t('mapControls.tooltipCompare')}>
            <div
                data-testid={'control'}
                className={classNames('control-btn compare-btn', { active: isCompareToolEnabled })}
                onClick={() => {
                    const nextEnabledState = !isCompareToolEnabled;
                    dispatch(setCompareToolEnabled(nextEnabledState));
                    dispatch(disableAllControls());
                    cancelDrawing();
                    dispatch(setSelectedObject({} as SelectedObject));
                    if (nextEnabledState) {
                        let comparedProjectId = project.id!;
                        if (isSiteViewPath) {
                            const indexOfCurrentProject = siteProjects.findIndex(p => p.uid === project.id);
                            if (indexOfCurrentProject !== -1 && indexOfCurrentProject !== siteProjects.length - 1) {
                                comparedProjectId = siteProjects[indexOfCurrentProject + 1].uid!;
                            }
                        }

                        dispatch(
                            initCompareTool({
                                currentProjectStructures: structures,
                                currentProjectId: project.id!,
                                comparedProjectId,
                                mode: isSiteViewPath ? 'compareSiteProjects' : 'compareProject'
                            })
                        );
                        if (!isSidebarExpanded) dispatch(setSidebarVisibility(true));

                        if (structureInfoWithEnabledLimitBox) {
                            dispatch(
                                updateStructureInfo({
                                    projectId: project.id!,
                                    structureUid: structureInfoWithEnabledLimitBox.uid!,
                                    type: ProjectStructureObjectTypes.DATASET,
                                    propName: 'limitBoxEnabled',
                                    propValue: String(false)
                                })
                            );
                        }
                    }
                }}
            >
                <i className='icon icon-compare' />
            </div>
        </TippyTooltip>
    );
}
