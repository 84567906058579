import classNames from 'classnames';
import { ReactComponent as SvgGroup } from '../../../svg/files/group.svg';
import { ReactComponent as SvgProject } from '../../../svg/files/project.svg';
import { ReactComponent as SvgSite } from '../../../svg/projects_list/site.svg';
import { ReactComponent as SvgFolder } from '../../../svg/projects_list/folder.svg';
import { SourceType } from '../../../generated/cloud-frontend-api';
import { assertNever } from '../../../lib/assertNever';

type Props = {
    type: 'group' | 'project' | 'site' | 'folder' | 'chunk' | 'layer' | 'inspection' | 'tour' | SourceType | 'CAMERAS';
};

const iconsSvg = {
    group: <SvgGroup />,
    project: <SvgProject />,
    site: <SvgSite />,
    folder: <SvgFolder />
};

function getIconClass(type: Props['type']) {
    switch (type) {
        case SourceType.POINT_CLOUD:
            return 'icon-dense_cloud';
        case SourceType.TIE_POINTS:
            return 'icon-tie_points';
        case SourceType.TILED_MODEL:
        case SourceType.BLOCK_MODEL:
            return 'icon-tiled_model';
        case SourceType.RASTER_MAP:
            return 'icon-orthomosaic';
        case SourceType.DEM:
        case SourceType.DEM_3_D:
            return 'icon-elevation';
        case SourceType.MODEL_3_D:
            return 'icon-3d_model';
        case 'CAMERAS':
            return 'icon-camera';
        case 'chunk':
            return 'icon-chunk';
        case 'layer':
            return 'icon-layer';
        case 'inspection':
            return 'icon-inspector';
        case 'tour':
            return 'icon-presentation';
        default:
            return '';
    }
}

export default function ItemIcon({ type }: Props) {
    function getIconElement() {
        if (type === 'group' || type === 'project' || type === 'site' || type === 'folder') {
            return iconsSvg[type];
        } else {
            return <i className={classNames('icon', getIconClass(type))} />;
        }
    }

    return <span className='item-icon'>{getIconElement()}</span>;
}
