import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { billingApi } from '../../api/initApis';
import { InvoicesPage } from '../../generated/billing-api/model';
import { INVOICES_PAGE_LIMIT } from '../../sharedConstants';
import { createSetterReducer } from '../helpers';

export type InvoicesSort = Array<'+payment_date' | '-payment_date'>;

interface InvoicesState {
    page: InvoicesPage;
    sort: InvoicesSort;
    pageNumber: number;
}

const initialState: InvoicesState = {
    page: { invoices: [] },
    pageNumber: 0,
    sort: ['-payment_date']
};

const name = 'invoices';

interface GetInvoicesArgs {
    limit?: number;
    page?: number;
    sort?: InvoicesSort;
}

const setterReducer = createSetterReducer<InvoicesState>();
export const getInvoices = createAsyncThunk(
    `${name}/getInvoices`,
    ({ limit = INVOICES_PAGE_LIMIT, page, sort }: GetInvoicesArgs) =>
        billingApi.getInvoicesInfo(limit, page, sort).then(({ data }) => data)
);

const invoicesSlice = createSlice({
    name,
    initialState,
    reducers: {
        setInvoicesPageNumber: setterReducer('pageNumber'),
        setInvoicesSort: setterReducer('sort')
    },
    extraReducers: builder =>
        builder.addCase(getInvoices.fulfilled, (state, { payload }) => {
            state.page = payload;
        })
});

export const { setInvoicesPageNumber, setInvoicesSort } = invoicesSlice.actions;

export default invoicesSlice.reducer;
