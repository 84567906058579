import React, { CSSProperties, ReactNode, useContext } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DRAG_SPACING_PX, ProjectionContext } from '../structure-tree/dndUtilities';

type Props = {
    id: string;
    children({ isDragging }: { isDragging: boolean }): ReactNode;
    style?: CSSProperties;
    disabled?: boolean;
};

export default function Sortable({ style, id, children, disabled }: Props) {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id, disabled });
    const { depth } = useContext(ProjectionContext);

    const finalStyle = {
        ...style,
        transform: CSS.Transform.toString(transform),
        transition,
        paddingLeft: depth && isDragging ? `${depth * (DRAG_SPACING_PX - 20)}px` : 0
    };

    return (
        <div ref={setNodeRef} style={finalStyle} {...attributes} {...listeners} data-testid={'sortable'}>
            {children({ isDragging })}
        </div>
    );
}
