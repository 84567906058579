import React, { useEffect, useMemo } from 'react';
import { GeoJson } from '../../../store/helpers/interfaces';
import PolylineMeasures from './measures/PolylineMeasures';
import { useSelector } from '../../../store';
import GeneralProperty from '../../inspection-sidebar/GeneralProperty';
import formatNumber from '../../../lib/formatNumber';
import { useTranslation } from 'react-i18next';
import { Units } from '../../../sharedConstants';
import { getUnitsShortName } from '../../../lib/getUnitsShortName';
import { convertLengthUnit } from '../../../lib/convertUnit';

type Props = {
    geoJson: GeoJson;
    setTextForCopying(text: string): void;
};

const precision = 3;

export default function PolylineProperties({ geoJson, setTextForCopying }: Props) {
    const { t } = useTranslation('projectView');
    const coordinates = geoJson.geometry.coordinates as number[][];

    const { floatingPointCoordinates, currentlyDrawingShapeId } = useSelector(state => state.projectView);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const units = useSelector(state => state.coordinateSystems.units);
    const unitsNaming = getUnitsShortName(units);

    const shouldAddFloatingPoint = currentlyDrawingShapeId === selectedObject?.artifactId;
    const coordinatesWithFloatingPoint = useMemo(() => {
        if (!shouldAddFloatingPoint || !floatingPointCoordinates) return coordinates;
        return coordinates.concat([floatingPointCoordinates]);
    }, [floatingPointCoordinates, coordinates, shouldAddFloatingPoint]);

    const polylineMeasures = useMemo(
        () => new PolylineMeasures(coordinatesWithFloatingPoint),
        [coordinatesWithFloatingPoint]
    );

    const length = useMemo(
        () => convertLengthUnit(Number(polylineMeasures.length().toFixed(precision)), Units.METRE, units),
        [polylineMeasures, units]
    );
    const horizontalLength = useMemo(
        () => convertLengthUnit(Number(polylineMeasures.horizontalLength().toFixed(precision)), Units.METRE, units),
        [polylineMeasures, units]
    );
    const elevationDifference = useMemo(
        () => convertLengthUnit(Number(polylineMeasures.elevationDifference().toFixed(precision)), Units.METRE, units),
        [polylineMeasures, units]
    );
    const slope = useMemo(() => polylineMeasures.slope(), [polylineMeasures]);

    useEffect(() => {
        setTextForCopying(
            `Length\t${length.toFixed(precision)} ${unitsNaming}\nHorizontal length\t${horizontalLength.toFixed(
                precision
            )} ${unitsNaming}\nElevation difference\t${elevationDifference.toFixed(
                precision
            )} ${unitsNaming}\nSlope\t${slope.toFixed(precision)} %`
        );
    }, [setTextForCopying, length, horizontalLength, elevationDifference, slope, units, unitsNaming]);

    return (
        <>
            <GeneralProperty
                label={t('geometryProperties.length')}
                value={`${formatNumber(length, precision)} ${unitsNaming}`}
            />
            <GeneralProperty
                label={t('geometryProperties.horizontalLength')}
                value={`${formatNumber(horizontalLength, precision)} ${unitsNaming}`}
            />
            <GeneralProperty
                label={t('geometryProperties.elevationDifference')}
                value={`${formatNumber(elevationDifference, precision)} ${unitsNaming}`}
            />
            <GeneralProperty
                label={t('geometryProperties.slope')}
                value={`${(!isNaN(slope) ? slope : 0).toFixed(precision)} %`}
            />
        </>
    );
}
