import * as Cesium from 'cesium';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Primitive, useCesium } from 'resium';
import { GeometryTypes, primitiveOptimizationProps } from '../../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../../store';
import { ProjectStructureObjectTypes, TemporaryGeometry } from '../../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../../store/selectors';
import { updateGeometryProperty } from '../../../../store/slices/geometries';
import { defaultBlueCss, polylineWidth, polylinesAppearance } from '../styling';
import { GeometryPrimitiveProps } from './GeometryPrimitives';
import { adjustAlpha } from '../../../../lib/adjustAlpha';

type Props = GeometryPrimitiveProps<GeometryTypes.POLYGON>;

const appearance = new Cesium.PerInstanceColorAppearance({ translucent: true, closed: true, flat: true });

function PolygonPrimitive({
    geometries,
    onMouseEnter,
    onMouseLeave,
    hasPrimitiveRendered,
    setPrimitiveHasRendered,
    layerVisible
}: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { scene } = useCesium();
    const polygonsFillPrimitive = useRef<Cesium.Primitive>(null!);
    const polygonsOutlinePrimitive = useRef<Cesium.Primitive>(null!);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));
    const [polygonsFills, setPolygonsFills] = useState<Cesium.GeometryInstance[]>([]);
    const [polygonsOutlines, setPolygonsOutlines] = useState<Cesium.GeometryInstance[]>([]);

    function hideOtherPart(target: { id: string; primitive: Cesium.Primitive }, part: Cesium.Primitive, show = false) {
        const GIIndex = (target.primitive.geometryInstances as Cesium.GeometryInstance[])
            .map(gi => gi.id)
            .indexOf(target.id);

        if (part.ready) {
            const attributes = part.getGeometryInstanceAttributes(
                (part.geometryInstances as Cesium.GeometryInstance[])[GIIndex]?.id || ''
            );
            attributes.show = Cesium.ShowGeometryInstanceAttribute.toValue(show);
        }
    }

    useEffect(() => {
        if (hasPrimitiveRendered || !geometries.length) return;

        // Ensure that geometry instances are initialized only once
        setPolygonsFills(
            geometries.map(g => {
                return new Cesium.GeometryInstance({
                    geometry: new Cesium.PolygonGeometry({
                        polygonHierarchy: new Cesium.PolygonHierarchy(
                            Cesium.Cartesian3.fromDegreesArrayHeights(g.content.geometry.coordinates[0].flat()),
                            g.content.geometry.coordinates
                                .slice(1, g.content.geometry.coordinates.length)
                                .map(
                                    hole =>
                                        new Cesium.PolygonHierarchy(
                                            Cesium.Cartesian3.fromDegreesArrayHeights(hole.flat())
                                        )
                                )
                        ),
                        perPositionHeight: true
                    }),
                    id: g.id,
                    attributes: {
                        color: Cesium.ColorGeometryInstanceAttribute.fromColor(
                            Cesium.Color.fromCssColorString(g.content.properties.ac_color || defaultBlueCss)
                        ),
                        show: new Cesium.ShowGeometryInstanceAttribute(
                            layerVisible && g.content.properties.ac_visibility
                        )
                    }
                });
            })
        );

        setPolygonsOutlines(
            geometries.map(g => {
                return new Cesium.GeometryInstance({
                    geometry: new Cesium.PolylineGeometry({
                        width: polylineWidth,
                        arcType: Cesium.ArcType.NONE,
                        positions: Cesium.Cartesian3.fromDegreesArrayHeights(g.content.geometry.coordinates[0].flat()),
                        vertexFormat: Cesium.PolylineColorAppearance.VERTEX_FORMAT
                    }),
                    id: g.id,
                    attributes: {
                        color: Cesium.ColorGeometryInstanceAttribute.fromColor(
                            adjustAlpha(
                                Cesium.Color.fromCssColorString(
                                    g.content.properties.ac_stroke_color ||
                                        g.content.properties.ac_color ||
                                        defaultBlueCss
                                )
                            )
                        ),
                        show: new Cesium.ShowGeometryInstanceAttribute(
                            layerVisible && g.content.properties.ac_visibility
                        )
                    }
                });
            })
        );

        setPrimitiveHasRendered(renderingState => ({ ...renderingState, [GeometryTypes.POLYGON]: true }));
    }, [hasPrimitiveRendered, layerVisible, geometries, setPrimitiveHasRendered]);

    useEffect(() => {
        if (selectedObject?.type === ProjectStructureObjectTypes.GEOMETRY) {
            const geometry = selectedObjectInfo as TemporaryGeometry;
            if (geometry && geometries.map(g => g.id).includes(geometry.id) && !geometry.renderAsEntity) {
                dispatch(
                    updateGeometryProperty({
                        id: geometry.id,
                        propName: 'renderAsEntity',
                        propValue: layerVisible && geometry.content.properties.ac_visibility
                    })
                );
                hideOtherPart(
                    { id: geometry.id, primitive: polygonsFillPrimitive.current },
                    polygonsOutlinePrimitive.current
                );
                hideOtherPart(
                    { id: geometry.id, primitive: polygonsOutlinePrimitive.current },
                    polygonsFillPrimitive.current
                );
            }
        }
    }, [selectedObjectInfo, selectedObject, dispatch, geometries, layerVisible]);

    useEffect(() => {
        for (const geometry of geometries) {
            if (geometry.renderAsEntity) continue;

            if (layerVisible && geometry.content.properties.ac_visibility) {
                hideOtherPart(
                    { id: geometry.id, primitive: polygonsFillPrimitive.current },
                    polygonsOutlinePrimitive.current,
                    true
                );
                hideOtherPart(
                    { id: geometry.id, primitive: polygonsOutlinePrimitive.current },
                    polygonsFillPrimitive.current,
                    true
                );
            } else {
                hideOtherPart(
                    { id: geometry.id, primitive: polygonsOutlinePrimitive.current },
                    polygonsFillPrimitive.current
                );
                hideOtherPart(
                    { id: geometry.id, primitive: polygonsFillPrimitive.current },
                    polygonsOutlinePrimitive.current
                );
            }
        }
    }, [layerVisible, geometries]);

    useEffect(() => {
        if (polygonsFillPrimitive.current?.ready && polygonsOutlinePrimitive.current?.ready) {
            for (const g of geometries) {
                let attributes = polygonsFillPrimitive.current.getGeometryInstanceAttributes(g.id);
                if (attributes)
                    attributes.color = Cesium.ColorGeometryInstanceAttribute.toValue(
                        Cesium.Color.fromCssColorString(g.content.properties.ac_color)
                    );

                attributes = polygonsOutlinePrimitive.current.getGeometryInstanceAttributes(g.id);
                if (attributes)
                    attributes.color = Cesium.ColorGeometryInstanceAttribute.toValue(
                        adjustAlpha(
                            Cesium.Color.fromCssColorString(
                                g.content.properties.ac_stroke_color || g.content.properties.ac_color
                            )
                        )
                    );
            }
            scene?.requestRender();
        }
    }, [geometries, scene]);

    return geometries.length ? (
        <>
            <Primitive
                ref={el => {
                    polygonsFillPrimitive.current = el?.cesiumElement!;
                }}
                onMouseEnter={(movement, target) => {
                    onMouseEnter(movement, target);
                }}
                onMouseLeave={(movement, target) => {
                    onMouseLeave(movement, target);
                }}
                onClick={(movement, target) => {
                    hideOtherPart(target, polygonsOutlinePrimitive.current);
                }}
                geometryInstances={polygonsFills}
                appearance={appearance}
                {...primitiveOptimizationProps}
            />
            <Primitive
                ref={el => {
                    polygonsOutlinePrimitive.current = el?.cesiumElement!;
                }}
                onMouseEnter={(movement, target) => {
                    onMouseEnter(movement, target, 'ac_stroke_color');
                }}
                onMouseLeave={(movement, target) => {
                    onMouseLeave(movement, target, 'ac_stroke_color');
                }}
                onClick={(movement, target) => {
                    hideOtherPart(target, polygonsFillPrimitive.current);
                }}
                geometryInstances={polygonsOutlines}
                appearance={polylinesAppearance}
                {...primitiveOptimizationProps}
            />
        </>
    ) : null;
}

export default PolygonPrimitive;
