import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import axiosRetry, { isRetryableError } from 'axios-retry';
import * as Cesium from 'cesium';
import produce from 'immer';
import { v4 } from 'uuid';
import { datasetApi, uploadedDatasetApi } from '../../api/initApis';
import { Dataset as DatasetPresenter } from '../../entities/Dataset';
import { Dataset, ProjectPartType } from '../../generated/cloud-frontend-api';
import { DatasetInfo, SourceType, Status } from '../../generated/dataset-api';
import getFilename from '../../lib/getFilename';
import getFilenameExtension from '../../lib/getFilenameExtension';
import { onInterceptorRejected } from '../../lib/onInterceptorRejected';
import { DatasetFileUploader } from '../helpers/DatasetFileUploader';
import { DatasetFileValidationResult } from '../helpers/dataset-files-validators/DatasetFileValidator';
import {
    DatasetFilesValidator,
    isValidFormat,
    recognizeSourceType
} from '../helpers/dataset-files-validators/DatasetFilesValidator';
import GeoJsonUpload from '../helpers/geojson-upload/GeoJsonUpload';
import { ProjectStructureObjectTypes, SelectedObject } from '../helpers/interfaces';
import { AppDispatch, ApplicationState } from '../index';
import { addRecentlyFinishedUploadId, setSelectedObject } from '../sharedActions';
import { addProjectCoordinateSystem, deleteProjectCoordinateSystem } from './coordinateSystems';
import { datasetInfoUpdated, removeDataset, uploadPercentChanged } from './datasets';
import { uploadPending } from './datasetsUpload';
import {
    setManualConfigurationForUploadRequiredMessageVisible,
    setUploadsCompleteMessageVisible
} from './projectStructure';
import { addStructureInfo, getNextStructureOrder, putStructure } from './structure';

export interface ExtendedDatasetInfo extends Dataset {
    uploadPercent?: number;
    sourceData?: Dataset['sourceData'] & {
        status?: 'validating' | 'pending' | 'validationError' | 'uploadError';
        lastError?: string;
        file?: File; // Only on frontend side
    };
}

const name = 'datasetFilesUpload';

interface UploadDatasetFileArgs {
    projectId: string;
    file: File;
    coordinateSystemUid: string;
    type: SourceType;
    structureParentId?: string;
    temporaryId?: string;
    shouldScrollInTree?: boolean;
}
export const uploadDatasetFile = createAsyncThunk<
    DatasetInfo,
    UploadDatasetFileArgs,
    { dispatch: AppDispatch; state: ApplicationState; rejectValue: { id: string; errorMessage?: string } }
>(
    `${name}/do`,
    async (
        { projectId, coordinateSystemUid, file, type, structureParentId, temporaryId, shouldScrollInTree = true },
        { rejectWithValue, dispatch, getState }
    ) => {
        const fileName = getFilename(file.name);
        const datasetUid = await dispatch(
            createDataset({ file, projectId, type, coordinateSystemUid, temporaryId })
        ).unwrap();
        if (temporaryId) {
            dispatch(removeDataset({ datasetUid: temporaryId }));
            if (getState().project.selectedObject?.artifactId === temporaryId)
                await dispatch(
                    setSelectedObject({
                        artifactId: datasetUid,
                        type: ProjectStructureObjectTypes.DATASET,
                        needToScroll: shouldScrollInTree
                    })
                );
        }
        dispatch(addProjectCoordinateSystem(coordinateSystemUid));
        if (structureParentId && !getState().structure.structures.find(s => s.uid === structureParentId)) {
            return rejectWithValue({ id: datasetUid });
        }

        const actualType = type === SourceType.DEM ? SourceType.DEM_3_D : type;
        dispatch(
            datasetInfoUpdated({
                datasetUid,
                coordinateSystemUid,
                name: fileName,
                sourceData: { sizeInBytes: file.size, type: actualType },
                projectPartType: ProjectPartType.DATASETS
            })
        );

        setStructureProperties(datasetUid, actualType);
        scrollIntoView(temporaryId || datasetUid);

        try {
            const datasetFileUploader = new DatasetFileUploader(file, { dispatch, getState, rejectWithValue });
            await datasetFileUploader.upload(datasetUid, uploaded => {
                if (!isStillInTheSameProject()) return rejectWithValue({ id: datasetUid });
                const percent = Math.floor((uploaded / file.size) * 100);
                dispatch(uploadPercentChanged({ id: datasetUid, percent }));
            });
        } catch (error) {
            if (isAxiosError(error)) {
                onInterceptorRejected(error);
                return rejectWithValue({ id: datasetUid, errorMessage: error.response?.data?.message || '' });
            }
            return rejectWithValue({ id: datasetUid, errorMessage: (error as any)?.message });
        }
        if (!isStillInTheSameProject()) return rejectWithValue({ id: datasetUid });

        const { data } = await datasetApi.getDataset(projectId, datasetUid);
        const otherOngoingUploads = getState()
            .datasets.datasets.filter(d => d.datasetUid !== data.datasetUid)
            .filter(d => !d.visualData);
        if (!otherOngoingUploads.length) dispatch(setUploadsCompleteMessageVisible(true));
        return produce(data, draft => {
            if (draft.sourceData?.type) draft.sourceData.type = actualType;
        });

        function setStructureProperties(datasetUid: string, sourceType: SourceType) {
            const parentUid = getParentId();
            const structureInfos = getState().structure.structures;
            const parentStructureInfo = structureInfos.find(s => s.uid === parentUid);
            if (parentStructureInfo?.properties?.nestingLevel === '1') {
                // Expand group and make it visible
                dispatch(
                    putStructure({
                        projectId,
                        type: ProjectStructureObjectTypes.GROUP,
                        structureInfo: produce(parentStructureInfo, draft => {
                            draft.properties.visible = true.toString();
                            draft.properties.expanded = true.toString();
                        })
                    })
                );
            }

            const existingStructureInfo = structureInfos.find(s => s.uid === temporaryId);

            dispatch(
                putStructure({
                    projectId: projectId,
                    type: ProjectStructureObjectTypes.DATASET,
                    structureInfo: {
                        uid: datasetUid,
                        parentUid: existingStructureInfo?.parentUid || parentUid,
                        properties: {
                            order:
                                existingStructureInfo?.properties?.order ||
                                getNextStructureOrder(structureInfos).toString(),
                            visible: 'true',
                            opacity: String(DatasetPresenter.defaultOpacity(sourceType))
                        }
                    }
                })
            );
        }

        function getParentId() {
            const selectedObject = getState().project.selectedObject;
            let parentUid = structureParentId || projectId;
            if (selectedObject?.type === ProjectStructureObjectTypes.GROUP) parentUid = selectedObject.artifactId!;
            return parentUid;
        }

        function scrollIntoView(datasetUid: string) {
            const previousSelectedObject = getState().project.selectedObject;
            dispatch(
                setSelectedObject({
                    type: ProjectStructureObjectTypes.DATASET,
                    artifactId: datasetUid,
                    needToScroll: shouldScrollInTree
                })
            ).finally(() => {
                dispatch(setSelectedObject(previousSelectedObject));
            });
        }

        function isStillInTheSameProject() {
            return projectId === getState().project.projectInfo?.id;
        }
    },
    {
        condition({ temporaryId, structureParentId }, { getState }) {
            // Skip uploading dataset if its validated version was deleted BEFORE the upload has started
            if (!getState().datasets.datasets.find(d => d.datasetUid === temporaryId)) return false;

            // Skip uploading dataset if its structure parentId was deleted BEFORE the upload has started
            if (structureParentId && !getState().structure.structures.find(s => s.uid === structureParentId))
                return false;
        }
    }
);

interface CreateDatasetArgs {
    projectId: string;
    file: File;
    coordinateSystemUid: string;
    type: SourceType;
    temporaryId?: string;
}
export const createDataset = createAsyncThunk<string, CreateDatasetArgs, { rejectValue: { id: string } }>(
    `${name}/createDataset`,
    async ({ file, projectId, type, coordinateSystemUid, temporaryId }, { rejectWithValue }) => {
        const {
            data: { datasetUid }
        } = await uploadedDatasetApi.createDataset(
            projectId,
            { coordinateSystemUid, fileName: file.name, sizeInBytes: file.size, type: type as any },
            {
                'axios-retry': {
                    retries: 3,
                    retryCondition: isRetryableError,
                    retryDelay: (retryCount, error) => axiosRetry.exponentialDelay(retryCount, error, 1000)
                }
            }
        );
        if (!datasetUid) return rejectWithValue({ id: '' });
        return datasetUid;
    }
);

interface UploadDatasetsArgs {
    projectId: string;
    files: File[];
    structureParentId?: string;
    owned: boolean;
    scene: Cesium.Scene;
    terrainProvider: Cesium.TerrainProvider;
}
export const uploadDatasets = createAsyncThunk<
    void,
    UploadDatasetsArgs,
    { dispatch: AppDispatch; state: ApplicationState }
>(
    `${name}/uploadDatasets`,
    async ({ files, projectId, structureParentId, owned, scene, terrainProvider }, { dispatch, getState }) => {
        const recognizedFormatFiles = files.filter(f => isValidFormat(f) && f.size > 0);
        const unrecognizedFormatFiles = files.filter(f => !isValidFormat(f) || f.size === 0);
        addUnrecognizedFiles();
        const tempIds = addRecognizedFiles();

        const validator = new DatasetFilesValidator(recognizedFormatFiles.map((f, i) => ({ file: f, id: tempIds[i] })));
        const validationResults = await validator.validate();
        const isValid = (dvr: DatasetFileValidationResult) =>
            // To show validation error, if uploading a zip non-geojson dataset in shared/embed view
            dvr.isValid && (owned || dvr.sourceType === SourceType.GEOJSON);

        const validResults = validationResults.filter(isValid);
        const invalidResults = validationResults.filter(dvr => !isValid(dvr));

        for (const { file, sourceType, lastError, id } of invalidResults) {
            upsertTemporaryDataset(
                file,
                {
                    type: owned || sourceType === SourceType.GEOJSON ? sourceType : undefined,
                    status: 'validationError',
                    lastError
                },
                id
            );
        }

        const hasCanceled = (id: string) => !getState().datasets.datasets.find(d => d.datasetUid === id);
        for (const { file, sourceType, id, data } of validResults.filter(
            r => !hasCanceled(r.id) || r.sourceType === SourceType.GEOJSON
        )) {
            upsertTemporaryDataset(file, { type: sourceType }, id);
            if (sourceType === SourceType.GEOJSON) {
                uploadGeoJson(file, id);
            } else {
                const coordinateSystemUid = findCRS(data?.epsgCode);
                if (sourceType === undefined || !coordinateSystemUid) {
                    upsertTemporaryDataset(
                        file,
                        { type: sourceType, status: 'pending', file },
                        id,
                        coordinateSystemUid
                    );
                    dispatch(setManualConfigurationForUploadRequiredMessageVisible(true));
                } else {
                    uploadNonGeoJson(file, id, sourceType, coordinateSystemUid);
                }
            }
        }

        function addRecognizedFiles(): string[] {
            const tempIds: string[] = [];
            for (const f of recognizedFormatFiles) {
                const sourceType = recognizeSourceType(f);
                const id = upsertTemporaryDataset(f, { type: sourceType, status: 'validating' });
                dispatch(
                    addStructureInfo({
                        uid: id,
                        parentUid: structureParentId,
                        properties: {
                            order: String(getNextStructureOrder(getState().structure.structures) - 1),
                            visible: 'true'
                        }
                    })
                );
                tempIds.push(id);
            }
            return tempIds;
        }

        function addUnrecognizedFiles() {
            for (const f of unrecognizedFormatFiles) {
                const id = upsertTemporaryDataset(f, { status: 'validationError' });
                dispatch(
                    addStructureInfo({
                        uid: id,
                        parentUid: structureParentId,
                        properties: {
                            order: String(getNextStructureOrder(getState().structure.structures) - 1),
                            visible: 'true'
                        }
                    })
                );
            }
        }

        function findCRS(epsgCode: number | undefined) {
            if (epsgCode) {
                const personalAndDefaultCoordinateSystems = getState().coordinateSystems.personalCrs.concat(
                    getState().coordinateSystems.defaultCrs
                );
                return personalAndDefaultCoordinateSystems.find(cs => cs.epsgCode === epsgCode)?.uid;
            }
        }

        function upsertTemporaryDataset(
            file: File,
            sourceData: ExtendedDatasetInfo['sourceData'],
            id = v4(),
            coordinateSystemUid?: string
        ) {
            if (sourceData?.type !== SourceType.GEOJSON) {
                dispatch(
                    datasetInfoUpdated({
                        datasetUid: id,
                        coordinateSystemUid,
                        name: getFilename(file.name),
                        projectPartType: ProjectPartType.DATASETS,
                        properties: {},
                        sourceData: sourceData
                    })
                );
            }
            return id;
        }

        async function uploadGeoJson(file: File, id: string) {
            if (getFilenameExtension(file.name).toLowerCase() === 'geojson') {
                const { layerUid } = await dispatch(
                    GeoJsonUpload.createLayer({ isTemporary: !owned, file, isGeojson: true })
                ).unwrap();
                dispatch(GeoJsonUpload.uploadGeoJsonLayer({ file, terrainProvider, temporarily: !owned, layerUid }));
            } else {
                const { layerUid } = await dispatch(
                    GeoJsonUpload.createLayer({ file, isTemporary: !owned, isGeojson: false })
                ).unwrap();
                dispatch(removeDataset({ datasetUid: id }));
                dispatch(uploadPending({ id: layerUid, file, terrainProviderRef: terrainProvider }));
                dispatch(setManualConfigurationForUploadRequiredMessageVisible(true));
            }
        }

        function uploadNonGeoJson(file: File, id: string, sourceType: SourceType, coordinateSystemUid: string) {
            dispatch(
                uploadDatasetFile({
                    file,
                    projectId,
                    structureParentId,
                    type: sourceType!,
                    coordinateSystemUid,
                    temporaryId: id
                })
            );
        }
    }
);

interface CommonDatasetArgs {
    projectId: string;
    datasetUid: string;
}
export const getDataset = createAsyncThunk<DatasetInfo, CommonDatasetArgs & { access?: string; embed?: string }>(
    `${name}/get`,
    async ({ projectId, datasetUid, access, embed }, { dispatch }) => {
        const { data } = await datasetApi.getDataset(projectId, datasetUid, {
            params: { access, embed }
        });
        if (data.visualData?.status === Status.COMPLETED) {
            dispatch(addRecentlyFinishedUploadId({ id: datasetUid }));
        }
        return data;
    }
);

export const deleteDataset = createAsyncThunk<
    void,
    CommonDatasetArgs,
    { dispatch: AppDispatch; state: ApplicationState }
>(`${name}/delete`, async ({ datasetUid, projectId }, { dispatch, getState }) => {
    if (getState().project.selectedObject?.artifactId === datasetUid)
        await dispatch(setSelectedObject({} as SelectedObject));
    const datasets = getState().datasets.datasets;
    removeDatasetCrs(datasets, datasetUid, dispatch);

    await uploadedDatasetApi.deleteDataset(projectId, datasetUid);
});

export const cancelDatasetUpload = createAsyncThunk<
    void,
    CommonDatasetArgs,
    { dispatch: AppDispatch; state: ApplicationState }
>(`${name}/cancel`, async ({ datasetUid, projectId }, { dispatch, getState }) => {
    if (getState().project.selectedObject?.artifactId === datasetUid)
        await dispatch(setSelectedObject({} as SelectedObject));

    try {
        await uploadedDatasetApi.cancelUpload(projectId, datasetUid);
    } catch (err: any) {
        // 404 means that dataset is already being processed, just delete it in this case
        if (err?.response?.status === 404) await uploadedDatasetApi.deleteDataset(projectId, datasetUid);
    }
});

interface RenameDatasetArgs extends CommonDatasetArgs {
    name: string;
}
export const renameDataset = createAsyncThunk<void, RenameDatasetArgs, { state: ApplicationState }>(
    `${name}/renameDataset`,
    async ({ datasetUid, projectId, name }) => {
        await datasetApi.updateDataset(projectId, datasetUid, { name });
    }
);

export const downloadDataset = createAsyncThunk<void, CommonDatasetArgs>(
    `${name}/downloadDataset`,
    async ({ datasetUid, projectId }) => {
        const { data } = await uploadedDatasetApi.createDownloadUrl(projectId, datasetUid);
        if (data.url) {
            const link = document.createElement('a');
            link.setAttribute('href', data.url);
            link.setAttribute('rel', 'noopener noreferrer');
            link.click();
        }
    }
);

interface LinkDatasetArgs {
    projectId: string;
    datasetId: string;
    linkedProjectId: string;
    parentId: string;
}
export const linkDataset = createAsyncThunk<void, LinkDatasetArgs, { dispatch: AppDispatch; state: ApplicationState }>(
    `${name}/link`,
    async ({ datasetId, linkedProjectId, projectId, parentId }, { dispatch, getState }) => {
        const { data } = await datasetApi.addLink(projectId, datasetId, linkedProjectId);
        const isLinkingFromParentProject = projectId === getState().project.projectInfo.id;
        if (isLinkingFromParentProject) return data;

        const structures = getState().structure.structures;
        await dispatch(
            putStructure({
                projectId: linkedProjectId,
                type: ProjectStructureObjectTypes.DATASET,
                structureInfo: {
                    uid: datasetId,
                    parentUid: parentId,
                    properties: {
                        visible: String(true),
                        expanded: String(false),
                        order: String(getNextStructureOrder(structures))
                    }
                }
            })
        );
        return data;
    }
);

type UnlinkDatasetArgs = Pick<LinkDatasetArgs, 'datasetId' | 'linkedProjectId' | 'projectId'>;
export const unlinkDataset = createAsyncThunk<
    { geometryIds: string[] },
    UnlinkDatasetArgs,
    { dispatch: AppDispatch; state: ApplicationState }
>(`${name}/unlink`, async ({ projectId, datasetId, linkedProjectId }, { dispatch, getState }) => {
    const { data } = await datasetApi.deleteLink(projectId, datasetId, linkedProjectId);
    const layer = getState().project.structure.temporaryLayers.find(l => l.id === datasetId);
    const datasets = getState().datasets.datasets;
    removeDatasetCrs(datasets, datasetId, dispatch);
    return { geometryIds: layer?.geometries || [] };
});

export const unlinkDatasetFromParentProject = createAsyncThunk(
    `${name}/unlinkDataset`,
    async ({ projectId, datasetId, linkedProjectId }: UnlinkDatasetArgs) => {
        const { data } = await datasetApi.deleteLink(projectId, datasetId, linkedProjectId);
        return data;
    }
);

function removeDatasetCrs(datasets: ExtendedDatasetInfo[], datasetUid: string, dispatch: any) {
    const crsUid = datasets.find(dataset => dataset.datasetUid === datasetUid)?.coordinateSystemUid;
    if (crsUid && datasets.filter(dataset => dataset.coordinateSystemUid === crsUid).length === 1) {
        dispatch(deleteProjectCoordinateSystem(crsUid));
    }
}
