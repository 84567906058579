import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import CompareCloseControl from './CompareCloseControl';
import { createLoadingSelector } from '../../../store/selectors/createLoadingSelector';
import { initCompareTool } from '../../../store/slices/compareTool';
import { useSelector } from '../../../store';
import CompareSurveyDatePicker from './elements/CompareSurveyDatePicker';
import { Routes } from '../../../sharedConstants';

const selectCompareToolLoading = createLoadingSelector([initCompareTool.typePrefix]);

export default function CompareToolBreadcrumbs() {
    const { t } = useTranslation(['projectView']);
    const isCompareToolLoading = useSelector(state => selectCompareToolLoading(state));

    return (
        <>
            <div className='tool-title'>
                <div className='tool-icon'>
                    <i className='icon icon-compare' />
                </div>
                <span className='tool-text'>{t('projectView:compareMode.breadcrumbsTitle')}</span>
            </div>

            <Route exact path={[Routes.SITE_VIEW]}>
                <div className='infobar-compare-controls'>
                    {!isCompareToolLoading && (
                        <>
                            <CompareSurveyDatePicker selectDateType='left' />
                            <CompareSurveyDatePicker selectDateType='right' />
                        </>
                    )}
                </div>
            </Route>

            <CompareCloseControl />
        </>
    );
}
