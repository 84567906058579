import { Helmet, HelmetData } from 'react-helmet-async';
import { useSelector } from '../store';

const helmetData = new HelmetData({});

export function Head() {
    const faviconUrl = useSelector(state => state.init.faviconUrl);
    const appleTouchIconUrl = useSelector(state => state.init.appleTouchIconUrl);
    const safariPinnedTabUrl = useSelector(state => state.init.safariPinnedTabUrl);
    const manifestUrl = useSelector(state => state.init.manifestUrl);
    const title = useSelector(state => state.init.title);
    const metaDescription = useSelector(state => state.init.metaDescription);
    const metaApplicationName = useSelector(state => state.init.metaApplicationName);
    const metaAppleMobileWebAppTitle = useSelector(state => state.init.metaAppleMobileWebAppTitle);

    return (
        <Helmet helmetData={helmetData}>
            {title && <title>{title}</title>}
            {faviconUrl && <link rel='shortcut icon' href={faviconUrl} />}
            {appleTouchIconUrl && <link rel='apple-touch-icon' sizes='180x180' href={appleTouchIconUrl} />}
            {safariPinnedTabUrl && <link rel='mask-icon' href={safariPinnedTabUrl} />}
            {manifestUrl && <link rel='manifest' href={manifestUrl} />}
            {metaDescription && <meta name='description' content={metaDescription} />}
            {metaApplicationName && <meta name='application-name' content={metaApplicationName} />}
            {metaAppleMobileWebAppTitle && (
                <meta name='apple-mobile-web-app-title' content={metaAppleMobileWebAppTitle} />
            )}
        </Helmet>
    );
}
