import { Globe } from 'resium';
import { AppDispatch, useSelector } from '../../../store';
import { TerrainViewModes } from '../../../sharedConstants';
import { useDispatch } from 'react-redux';
import { setIsViewerLoaded, setTerrainProviderLoaded } from '../../../store/slices/projectView';
import { useEffect, useMemo, useState } from 'react';
import * as Cesium from 'cesium';
import {
    selectIonTerrainLayersResources,
    selectUrlTerrainLayersResources,
    selectTerrainLayersCredits
} from '../../../store/slices/init';
import CesiumMultiTerrainProvider from '../terrain/CesiumMultiTerrainProvider';

type Props = {
    needsToRecreateTerrainProvider: boolean;
    setNeedsToRecreateTerrainProvider(needs: boolean): void;
    setNeedsToRecreateTerrainMaterial(needs: boolean): void;
    terrainLayers: string[];
};

async function createMultiTerrainProvider(
    terrainLayers: (Promise<Cesium.IonResource> | Cesium.Resource | string)[],
    credit: Cesium.Credit | string
) {
    return (await CesiumMultiTerrainProvider.fromUrl(terrainLayers, {
        enablePatching: true,
        requestVertexNormals: true,
        credit
    })) as unknown as Cesium.TerrainProvider;
}

export default function AppGlobe({
    needsToRecreateTerrainProvider,
    setNeedsToRecreateTerrainMaterial,
    terrainLayers,
    setNeedsToRecreateTerrainProvider
}: Props) {
    const dispatch: AppDispatch = useDispatch();
    const terrainViewMode = useSelector(state => state.projectView.terrainViewMode);
    const isCesiumLoaded = useSelector(state => state.projectView.isCesiumLoaded);

    const [multiTerrainProvider, setMultiTerrainProvider] = useState<Cesium.TerrainProvider>(); //new EllipsoidTerrainProvider());
    const [initialCreateCalled, setInitialCreateCalled] = useState(false);
    const initialIonTerrainLayers = useSelector(state => selectIonTerrainLayersResources(state));
    const initialUrlTerrainLayers = useSelector(state => selectUrlTerrainLayersResources(state));
    const terrainLayersCredits = useSelector(state => selectTerrainLayersCredits(state));

    const initialTerrainLayers = useMemo(
        () => [...initialIonTerrainLayers, ...initialUrlTerrainLayers],
        [initialIonTerrainLayers, initialUrlTerrainLayers]
    );

    useEffect(() => {
        if (!initialCreateCalled) {
            createMultiTerrainProvider(initialTerrainLayers, terrainLayersCredits).then(provider => {
                setMultiTerrainProvider(provider);
                dispatch(setTerrainProviderLoaded(true));
            });
            setInitialCreateCalled(true);
        }
    }, [
        dispatch,
        terrainLayersCredits,
        setNeedsToRecreateTerrainMaterial,
        initialTerrainLayers,
        initialCreateCalled,
        setInitialCreateCalled
    ]);

    useEffect(() => {
        if (needsToRecreateTerrainProvider) {
            dispatch(setTerrainProviderLoaded(false));
            setNeedsToRecreateTerrainProvider(false);
            createMultiTerrainProvider([...initialTerrainLayers, ...terrainLayers], terrainLayersCredits).then(
                terrainProvider => {
                    setMultiTerrainProvider(terrainProvider);
                    dispatch(setTerrainProviderLoaded(true));
                }
            );
        }
    }, [
        initialTerrainLayers,
        needsToRecreateTerrainProvider,
        setNeedsToRecreateTerrainProvider,
        setNeedsToRecreateTerrainMaterial,
        terrainLayers,
        dispatch,
        terrainLayersCredits,
        setMultiTerrainProvider
    ]);

    return (
        <Globe
            terrainProvider={multiTerrainProvider}
            onTerrainProviderChange={tp => {
                setNeedsToRecreateTerrainMaterial(true);
            }}
            show={terrainViewMode === TerrainViewModes.EARTH}
            onTileLoadProgress={loadQueueLength => {
                if (loadQueueLength === 0 && !isCesiumLoaded) dispatch(setIsViewerLoaded(true));
            }}
            depthTestAgainstTerrain={false}
        />
    );
}
