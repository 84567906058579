import * as Cesium from 'cesium';
import { forwardRef, ReactNode } from 'react';
import { CesiumComponentRef, Viewer } from 'resium';
import useGdal from '../../../hooks/useGdal';

type Props = {
    children: ReactNode;
};

const AppViewer = forwardRef<CesiumComponentRef<Cesium.Viewer>, Props>(({ children }, ref) => {
    useGdal();

    return (
        <Viewer
            ref={ref}
            baseLayer={false}
            full
            scene3DOnly
            requestRenderMode
            fullscreenButton={false}
            animation={false}
            geocoder={false}
            homeButton={false}
            timeline={false}
            navigationHelpButton={false}
            navigationInstructionsInitiallyVisible={false}
            sceneModePicker={false}
            baseLayerPicker={false}
            maximumRenderTimeChange={Infinity}
            showRenderLoopErrors={false}
            skyAtmosphere={false}
            selectionIndicator={false}
            infoBox={false}
            useBrowserRecommendedResolution={false}
        >
            {children}
        </Viewer>
    );
});

export default AppViewer;
