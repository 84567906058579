import produce from 'immer';
import { memo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CompareStructureTreeContext from '../../../contexts/CompareStructureTreeContext';
import FlyToTilesetsContext from '../../../contexts/FlyToTilesetsContext';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { Dataset } from '../../../entities/Dataset';
import { CamerasArtifact } from '../../../generated/cloud-frontend-api';
import { Status } from '../../../generated/dataset-api';
import useProjectStructureObjectsActions from '../../../hooks/useProjectStructureObjectsActions';
import { useVisibility } from '../../../hooks/useStructureInfo';
import { GeometryTypes, TerrainViewModes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import {
    ExtendedChunk,
    ProjectStructureObjectTypes,
    SelectedObject,
    TemporaryGeometry,
    TemporaryLayer
} from '../../../store/helpers/interfaces';
import {
    isGeometryVisible,
    selectDatasetsByCompareTreeId,
    selectLayersByCompareTreeId,
    selectProjectInfoByCompareTreeId,
    selectStructureItemInfoByCompareTreeId,
    selectStructuresByCompareTreeId
} from '../../../store/selectors';
import { setSelectedObject } from '../../../store/sharedActions';
import { ExtendedCamera } from '../../../store/slices/cameras';
import { updateCompareToolStructureInfo } from '../../../store/slices/compareTool';
import { ExtendedDatasetInfo, unlinkDataset } from '../../../store/slices/datasetfilesUpload';
import { isLinkedDataset, isVisualDataAbsent } from '../../../store/slices/datasets';
import { adaptVectorLayerToDataset, selectUpload } from '../../../store/slices/datasetsUpload';
import { selectGeometries, updateGeometryContent } from '../../../store/slices/geometries';
import { deleteLayer as deleteLayerAction, updateGeometry } from '../../../store/slices/geometryLayers';
import {
    ExtendedStructureInfo,
    putStructure,
    selectStructureWithEnabledLimitBox
} from '../../../store/slices/structure';
import DeleteDatasetModal from '../../Elements/modals/delete-dataset-modal/DeleteDatasetModal';
import { isCameraAligned } from '../../ProjectView/frustums/Frustums';
import DeleteGroupModal from '../../Elements/modals/delete-group-modal/DeleteGroupModal';
import DatasetFileUploadControls from '../dataset-upload/DatasetFileUploadControls';
import DeleteDatasetControl from '../dataset-upload/DeleteDatasetControl';
import { TreeNode } from '../structure-tree/StructureTree';
import DeleteControl from '../workspace-controls/DeleteControl';
import DisabledItemControl from '../workspace-controls/DisabledItemControl';
import ViewOnlyControl from '../workspace-controls/ViewOnlyControl';
import VisibilityToggleControl from '../workspace-controls/VisibilityToggleControl';
import ZoomControl from '../workspace-controls/ZoomControl';
import { sourceTypesDisabledInModelView } from './StructureItem';

type Props = {
    className?: string;
    node: TreeNode;
    isParentVisible(node: TreeNode): boolean;
    isParentSelected?: boolean;
    disabledInCompareTool?: boolean;
};

function StructureItemControls({
    className = 'artefact-controls',
    node,
    isParentVisible,
    isParentSelected,
    disabledInCompareTool
}: Props) {
    const { t } = useTranslation('projectView');
    const dispatch: AppDispatch = useDispatch();
    const { owned } = useContext(ProjectViewAccessContext);
    const compareContext = useContext(CompareStructureTreeContext);
    const { setClickedTilesetIds } = useContext(FlyToTilesetsContext);
    const geometries = useSelector(selectGeometries);
    const allCameras = useSelector(state => state.cameras);
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const projectInfo = useSelector(state => selectProjectInfoByCompareTreeId(state, compareContext?.treeId));
    const temporaryLayers = useSelector(state => selectLayersByCompareTreeId(state, compareContext?.treeId));
    const structures = useSelector(state => selectStructuresByCompareTreeId(state, compareContext?.treeId));
    const datasets = useSelector(state => selectDatasetsByCompareTreeId(state, compareContext?.treeId));
    const vectorLayerUpload = useSelector(state => selectUpload(state, node.id));
    const currentlyDrawingShapeId = useSelector(state => state.projectView.currentlyDrawingShapeId);
    const recentlyFinished = useSelector(state => state.datasetsUpload.recentlyFinished);
    const terrainViewMode = useSelector(state => state.projectView.terrainViewMode);
    const structureItemInfo = useSelector(state =>
        selectStructureItemInfoByCompareTreeId(state, compareContext?.treeId || '', { id: node.id, type: node.type })
    );
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const { deleteLayer, deleteGeometry } = useProjectStructureObjectsActions();
    const parentVisible = node.parentId ? isParentVisible(node) : true;
    const visible = useVisibility({ structureId: node.id, treeId: compareContext?.treeId });
    const structureInfo = structures.find(s => s.uid === node.id);
    const isRecentlyUploaded = recentlyFinished.includes(node.id);
    const structureInfoWithEnabledLimitBox = useSelector(state => selectStructureWithEnabledLimitBox(state));

    function setSelected() {
        if (!isCompareToolEnabled)
            dispatch(setSelectedObject({ type: node.type, artifactId: node.id, treeId: compareContext?.treeId }));
    }

    function updateVisibility() {
        const newStructureInfo = {
            uid: node.id,
            parentUid: structureInfo?.parentUid || node.parentId!,
            properties: produce(structureInfo?.properties || {}, draft => {
                draft.visible = String(!visible);
                if (draft.expanded !== 'false') draft.expanded = String(true);
                if (structureInfoWithEnabledLimitBox && draft.visible === 'false')
                    draft.limitBoxEnabled = String(false);
            })
        };
        if (isCompareToolEnabled && compareContext)
            dispatch(
                updateCompareToolStructureInfo({ treeId: compareContext?.treeId, structureInfo: newStructureInfo })
            );
        else dispatch(putStructure({ type: node.type, projectId: projectInfo?.id!, structureInfo: newStructureInfo }));
    }

    if (node.type === ProjectStructureObjectTypes.LAYER) {
        const layer = structureItemInfo as TemporaryLayer;
        const datasetInfo = adaptVectorLayerToDataset(layer, vectorLayerUpload);
        const { isInProgress, isInErrorState, isPendingManualAction } = new Dataset(datasetInfo);
        const cantDelete =
            layer.geometries.includes(currentlyDrawingShapeId || '') &&
            layer.geometries.map(id => geometries[id]!).find(g => g.id === currentlyDrawingShapeId)?.content.geometry
                .type !== GeometryTypes.POINT;
        const canModifyLayer = owned || layer.isTemporary;
        const isLinkedFromAnotherProject = isLinkedDataset(layer, projectInfo?.id!);
        const isLinkedToOtherProjects = owned && Boolean(layer.linkedProjects?.length);

        return (
            <span className={className}>
                {(() => {
                    if (disabledInCompareTool) {
                        return (
                            <>
                                <ViewOnlyControl isParentSelected={isParentSelected} />
                                <DisabledItemControl />
                            </>
                        );
                    }

                    if (isInProgress || isPendingManualAction) {
                        return <DatasetFileUploadControls datasetInfo={datasetInfo} />;
                    }

                    return (
                        <>
                            {canModifyLayer && !cantDelete && !isCompareToolEnabled && (
                                <>
                                    <DeleteControl
                                        onClick={e => {
                                            if (isLinkedFromAnotherProject || isLinkedToOtherProjects)
                                                setDeleteModalOpen(true);
                                            else deleteLayer(layer);
                                        }}
                                        showUnlinkTooltip={isLinkedFromAnotherProject}
                                    />
                                    {isDeleteModalOpen && (
                                        <DeleteDatasetModal
                                            datasetInfo={datasetInfo}
                                            isOpen={isDeleteModalOpen}
                                            setIsOpen={setDeleteModalOpen}
                                            onDelete={async () => {
                                                await dispatch(setSelectedObject({} as SelectedObject));
                                                if (isLinkedFromAnotherProject) {
                                                    dispatch(
                                                        unlinkDataset({
                                                            datasetId: datasetInfo.datasetUid!,
                                                            projectId: datasetInfo.parentProject?.uid!,
                                                            linkedProjectId: projectInfo?.id!
                                                        })
                                                    );
                                                    return;
                                                }
                                                dispatch(
                                                    deleteLayerAction({
                                                        geometryIds:
                                                            temporaryLayers.find(l => l.id === datasetInfo.datasetUid)
                                                                ?.geometries || [],
                                                        projectUid: projectInfo?.id!,
                                                        layerUid: datasetInfo.datasetUid!
                                                    })
                                                );
                                            }}
                                        />
                                    )}
                                </>
                            )}
                            {visible && isParentVisible(node) && (
                                <>
                                    {isRecentlyUploaded && (
                                        <span className='new-upload'>{t('structureSidebar.newUpload')}</span>
                                    )}
                                    {node.children.length > 0 && (
                                        <ZoomControl
                                            onClick={e => {
                                                setSelected();
                                                setClickedTilesetIds({ ids: layer.id, objectType: node.type });
                                            }}
                                        />
                                    )}
                                </>
                            )}
                            {!canModifyLayer && <ViewOnlyControl isParentSelected={isParentSelected} />}
                            {isInErrorState ? (
                                <DisabledItemControl />
                            ) : (
                                <VisibilityToggleControl
                                    active={visible}
                                    disabled={!parentVisible}
                                    onClick={e => {
                                        updateVisibility();
                                    }}
                                />
                            )}
                        </>
                    );
                })()}
            </span>
        );
    }

    if (node.type === ProjectStructureObjectTypes.GROUP) {
        const group = structureItemInfo as ExtendedStructureInfo;
        const canZoom =
            node.children.length > 0 &&
            node.children.some(
                c =>
                    (c.type === ProjectStructureObjectTypes.DATASET && c.status !== Status.FAILED) ||
                    (c.type === ProjectStructureObjectTypes.LAYER && c.status !== 'failed' && c.children?.length)
            );

        return (
            <span className={className}>
                {owned && !isCompareToolEnabled && (
                    <>
                        <DeleteControl
                            onClick={e => {
                                setDeleteModalOpen(true);
                            }}
                        />
                        <DeleteGroupModal isOpen={isDeleteModalOpen} setIsOpen={setDeleteModalOpen} groupInfo={group} />
                    </>
                )}

                {visible && canZoom && (
                    <ZoomControl
                        onClick={e => {
                            setSelected();
                            setClickedTilesetIds({ ids: node.id, objectType: node.type });
                        }}
                    />
                )}

                {(!owned || isCompareToolEnabled) && <ViewOnlyControl />}

                <VisibilityToggleControl
                    active={visible}
                    onClick={e => {
                        updateVisibility();
                    }}
                />
            </span>
        );
    }

    if (node.type === ProjectStructureObjectTypes.CHUNK) {
        const chunk = structureItemInfo as ExtendedChunk;

        const canZoom =
            (chunk.artifacts.length > 0 &&
                chunk.artifacts
                    .map(a => datasets.find(d => d.datasetUid === a.datasetUid))
                    .some(d => d?.visualData?.status === Status.COMPLETED)) ||
            (!!chunk.cameras?.count && chunk.cameras.count > 0);

        return (
            <span className={className}>
                {visible && canZoom && (
                    <ZoomControl
                        onClick={e => {
                            setSelected();
                            const hasOnlyCameras = !chunk.artifacts.length && !!chunk.cameras?.artifactUid;
                            if (hasOnlyCameras) {
                                setClickedTilesetIds({
                                    ids: chunk.cameras?.artifactUid!,
                                    objectType: ProjectStructureObjectTypes.CAMERAS
                                });
                            } else {
                                setClickedTilesetIds({
                                    ids: chunk.artifacts.map(
                                        a => datasets.find(d => d.datasetUid === a.datasetUid)?.visualData?.dataUid!
                                    ),
                                    objectType: ProjectStructureObjectTypes.CHUNK
                                });
                            }
                        }}
                    />
                )}
                <ViewOnlyControl />
                <VisibilityToggleControl
                    active={visible}
                    onClick={e => {
                        updateVisibility();
                    }}
                />
            </span>
        );
    }

    if (node.type === ProjectStructureObjectTypes.DATASET || node.type === ProjectStructureObjectTypes.ARTIFACT) {
        const dataset = structureItemInfo as ExtendedDatasetInfo;
        const { isUploading, isPublishing, isInErrorState, isValidating, isPendingManualAction, hasFailedValidation } =
            new Dataset(dataset);
        const isArtifact = node.type === ProjectStructureObjectTypes.ARTIFACT;

        const visibilityDisabled =
            (terrainViewMode === TerrainViewModes.MODEL &&
                sourceTypesDisabledInModelView.includes(dataset.sourceData?.type!)) ||
            isVisualDataAbsent(dataset);

        return (
            <span className={className}>
                {(() => {
                    if (isCompareToolEnabled) {
                        return disabledInCompareTool || visibilityDisabled ? (
                            <>
                                <ViewOnlyControl isParentSelected={isParentSelected} />
                                <DisabledItemControl />
                            </>
                        ) : (
                            <>
                                {visible && isParentVisible(node) && (
                                    <ZoomControl
                                        onClick={e => {
                                            e.stopPropagation();
                                            setSelected();
                                            setClickedTilesetIds({
                                                objectType: node.type,
                                                ids: dataset.visualData?.dataUid || null
                                            });
                                        }}
                                    />
                                )}
                                <ViewOnlyControl isParentSelected={isParentSelected} />
                                <VisibilityToggleControl
                                    active={visible}
                                    disabled={!parentVisible}
                                    onClick={e => {
                                        updateVisibility();
                                    }}
                                />
                            </>
                        );
                    }

                    if (isUploading || isPublishing || isValidating || isPendingManualAction)
                        return <DatasetFileUploadControls datasetInfo={dataset} />;

                    return (
                        <>
                            {(owned || (!owned && hasFailedValidation)) && !isArtifact && !visibilityDisabled && (
                                <DeleteDatasetControl datasetInfo={dataset} useExtendedClassName={true} />
                            )}
                            {isRecentlyUploaded && (
                                <span className='new-upload'>{t('structureSidebar.newUpload')}</span>
                            )}
                            {visible && isParentVisible(node) && (
                                <>
                                    {!visibilityDisabled && !isInErrorState && (
                                        <ZoomControl
                                            onClick={e => {
                                                e.stopPropagation();
                                                setSelected();
                                                setClickedTilesetIds({
                                                    objectType: node.type,
                                                    ids: dataset.visualData?.dataUid || null
                                                });
                                            }}
                                        />
                                    )}
                                </>
                            )}
                            {(!owned || isArtifact) && !isInErrorState && (
                                <ViewOnlyControl isParentSelected={isParentSelected} />
                            )}
                            {visibilityDisabled || isInErrorState ? (
                                <DisabledItemControl />
                            ) : (
                                <VisibilityToggleControl
                                    active={visible}
                                    disabled={!parentVisible}
                                    onClick={e => {
                                        updateVisibility();
                                    }}
                                />
                            )}
                        </>
                    );
                })()}
            </span>
        );
    }

    if (node.type === ProjectStructureObjectTypes.GEOMETRY) {
        const geometry = structureItemInfo as TemporaryGeometry;
        const geometryVisible = isGeometryVisible(geometry);
        const layer = temporaryLayers.find(l => l.geometries.includes(geometry.id));
        const canModify = owned || layer?.isTemporary;
        const canDelete =
            currentlyDrawingShapeId !== geometry.id || geometry.content.geometry.type === GeometryTypes.POINT;
        const hasLinkedParent = layer && isLinkedDataset(layer, projectInfo?.id!);
        const showDeleteControl = canModify && canDelete && !hasLinkedParent;

        return (
            <span className={className}>
                {parentVisible && geometryVisible && (
                    <>
                        {showDeleteControl && (
                            <DeleteControl
                                onClick={e => {
                                    deleteGeometry(geometry);
                                }}
                            />
                        )}
                        <ZoomControl
                            onClick={e => {
                                setSelected();
                                setClickedTilesetIds({ ids: geometry.id, objectType: node.type });
                            }}
                        />
                        {!canModify && <ViewOnlyControl isParentSelected={isParentSelected} />}
                    </>
                )}
                {!parentVisible && !canModify && <ViewOnlyControl isParentSelected={isParentSelected} />}
                <VisibilityToggleControl
                    active={geometryVisible}
                    disabled={!parentVisible}
                    onClick={e => {
                        const newGeoJson = produce(geometry.content, draft => {
                            draft.properties.ac_visibility = !geometryVisible;
                        });
                        dispatch(updateGeometryContent({ id: geometry.id, geoJson: newGeoJson }));
                        if (owned && !hasLinkedParent)
                            dispatch(
                                updateGeometry({
                                    id: geometry.id,
                                    projectUid: projectInfo?.id!,
                                    layerUid: layer?.id!,
                                    geoJson: newGeoJson
                                })
                            );
                    }}
                />
            </span>
        );
    }

    if (node.type === ProjectStructureObjectTypes.CAMERAS) {
        const cameras = structureItemInfo as CamerasArtifact;
        const camerasItems = cameras?.artifactUid ? allCameras[cameras.artifactUid] || [] : [];

        return (
            <span className={className}>
                {parentVisible && visible && !isCompareToolEnabled && camerasItems.length > 0 && (
                    <ZoomControl
                        onClick={e => {
                            setSelected();
                            setClickedTilesetIds({ ids: cameras.artifactUid!, objectType: node.type });
                        }}
                    />
                )}
                <ViewOnlyControl isParentSelected={isParentSelected} />
                {isCompareToolEnabled ? (
                    <DisabledItemControl />
                ) : (
                    <VisibilityToggleControl
                        active={visible}
                        disabled={!parentVisible}
                        onClick={e => {
                            updateVisibility();
                        }}
                    />
                )}
            </span>
        );
    }

    if (node.type === ProjectStructureObjectTypes.IMAGE) {
        const camera = structureItemInfo as ExtendedCamera;
        const isAligned = isCameraAligned(camera);

        return (
            <span className={className}>
                {parentVisible && visible && isAligned && (
                    <ZoomControl
                        onClick={e => {
                            setSelected();
                            setClickedTilesetIds({ ids: camera.uid, objectType: node.type });
                        }}
                    />
                )}
                <ViewOnlyControl isParentSelected={isParentSelected} />
                {parentVisible ? (
                    <>
                        {isAligned ? (
                            <VisibilityToggleControl
                                active={visible}
                                disabled={!parentVisible}
                                onClick={e => {
                                    updateVisibility();
                                }}
                            />
                        ) : (
                            <DisabledItemControl />
                        )}
                    </>
                ) : (
                    <VisibilityToggleControl
                        active={visible}
                        disabled={true}
                        onClick={e => {
                            updateVisibility();
                        }}
                    />
                )}
            </span>
        );
    }

    return null;
}

export default StructureItemControls;
