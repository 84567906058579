import classNames from 'classnames';
import { Field, FieldProps, Form, Formik } from 'formik';
import produce from 'immer';
import React, { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { propertiesTooltipMessages, reservedPropertiesNames } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { TemporaryGeometry } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { updateGeometry } from '../../../store/slices/geometryLayers';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { updateGeometryContent } from '../../../store/slices/geometries';
import { parseProperty } from './AdditionalProperties';

type Props = {
    setCustomProperties?: React.Dispatch<React.SetStateAction<string[]>>;
};

export default function AddProperty({ setCustomProperties }: Props) {
    const { t } = useTranslation('projectView');
    const dispatch: AppDispatch = useDispatch();
    const projectInfo = useSelector(state => state.project.projectInfo);
    const temporaryLayers = useSelector(state => state.project.structure.temporaryLayers);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state)) as TemporaryGeometry;
    const { owned } = useContext(ProjectViewAccessContext);
    const layer = temporaryLayers.find(l => l.geometries.includes(selectedObjectInfo.id));
    const propertyInput = useRef<HTMLTextAreaElement>(null!);

    const existingPropertiesNames = [...Object.keys(selectedObjectInfo.content.properties)];

    const [formHasFocus, setFormHasFocus] = useState(false);

    return (
        <Formik
            initialValues={{ property: '', value: '' }}
            onSubmit={(values, { resetForm }) => {
                const geoJson = produce(selectedObjectInfo.content, draft => {
                    draft.properties = {
                        ...draft.properties,
                        [values.property]: parseProperty(values.value)
                    };
                });
                dispatch(updateGeometryContent({ id: selectedObjectInfo.id, geoJson }));

                setCustomProperties &&
                    setCustomProperties(
                        produce((draft: string[]) => {
                            draft.unshift(values.property);
                        })
                    );

                if (owned)
                    dispatch(
                        updateGeometry({
                            projectUid: projectInfo.id!,
                            layerUid: layer?.id!,
                            id: selectedObjectInfo.id,
                            geoJson
                        })
                    );

                resetForm();
                propertyInput.current.focus();
            }}
        >
            {({ values, errors }) => (
                <TippyTooltip
                    tooltipText={errors.property || ''}
                    touch={true}
                    disabled={!errors.property}
                    appendTo='parent'
                    visible
                >
                    <Form
                        className='property add-property-form'
                        onFocus={() => setFormHasFocus(true)}
                        onBlur={() => setFormHasFocus(false)}
                    >
                        <div
                            className={classNames(
                                'prop-wrapper add-prop',
                                { active: formHasFocus },
                                { alert: !!errors.property }
                            )}
                        >
                            <div className='prop-label-wrapper'>
                                <Field
                                    name='property'
                                    validate={(value: string) => {
                                        if (reservedPropertiesNames.includes(value)) {
                                            return propertiesTooltipMessages().reserved;
                                        } else if (existingPropertiesNames.includes(value)) {
                                            return propertiesTooltipMessages().exists;
                                        }
                                    }}
                                >
                                    {({ field }: FieldProps) => (
                                        <Input.TextArea
                                            {...field}
                                            ref={propertyInput}
                                            autoSize
                                            autoComplete='off'
                                            className='prop-input prop-label'
                                            placeholder={t(
                                                'inspectionSidebar.geometryProperties.addProperty.propertyPlaceholder'
                                            )}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className='prop-value-wrapper'>
                                <Field
                                    as={Input.TextArea}
                                    className='prop-input prop-value'
                                    placeholder={t('inspectionSidebar.geometryProperties.addProperty.valuePlaceholder')}
                                    name='value'
                                    autoSize
                                    autoComplete='off'
                                />
                            </div>
                        </div>
                        <TippyTooltip
                            tooltipText={t('inspectionSidebar.geometryProperties.addProperty.tooltipAdd')}
                            trigger='mouseenter'
                        >
                            <button
                                className='prop-action save-prop'
                                type='submit'
                                disabled={values.property === '' || !!errors.property}
                            >
                                <i
                                    className={classNames('icon icon-add', {
                                        disabled: values.property === '' || !!errors.property
                                    })}
                                />
                            </button>
                        </TippyTooltip>
                    </Form>
                </TippyTooltip>
            )}
        </Formik>
    );
}
