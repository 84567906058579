import React, { ReactNode } from 'react';
import { ProjectStatus } from '../generated/cloud-frontend-api/model';
import i18n from '../i18n/config';
import { Trans } from 'react-i18next';

const getAreYouSureText = (
    action: 'unpublish' | 'publish' | 'remove' | 'abort',
    projectName: string,
    projectStatus?: ProjectStatus,
    projectPublishedSize?: string,
    projectSize?: string
) => {
    let returnedFragment: ReactNode;
    switch (action) {
        case 'remove':
            returnedFragment = (
                <Trans
                    ns={'modals'}
                    i18nKey={'removeProjectModal.body'}
                    components={{ p: <p />, s: <strong className='overflow'></strong> }}
                    values={{ projectName }}
                />
            );
            break;
        case 'unpublish':
            returnedFragment = (
                <Trans
                    ns={'modals'}
                    i18nKey={'unpublishProjectModal.body'}
                    components={{ p: <p />, s: <strong className='overflow'></strong> }}
                    values={{ projectName, publicationSize: projectPublishedSize }}
                />
            );
            break;
        case 'publish':
            returnedFragment = (
                <Trans
                    ns={'modals'}
                    i18nKey={'publishProjectModal.body'}
                    components={{ p: <p />, s: <strong className='overflow' /> }}
                    values={{ projectName }}
                />
            );
            break;
        case 'abort':
            returnedFragment = (
                <Trans
                    ns={'modals'}
                    i18nKey={'abortProcessingModal.body'}
                    components={{ p: <p />, s: <strong className='overflow'></strong> }}
                    values={{ projectName }}
                />
            );
            break;
    }

    const inProgressDeleteFragment: ReactNode = (
        <p>
            <Trans
                ns='modals'
                i18nKey='deleteProjectModal.bodyInProgress'
                values={{ projectName }}
                components={{ s: <strong className='overflow' /> }}
            />
        </p>
    );

    return projectStatus === ProjectStatus.INPROGRESS && action === 'remove'
        ? inProgressDeleteFragment
        : returnedFragment;
};

export default getAreYouSureText;
