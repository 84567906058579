import { useTranslation } from 'react-i18next';
import Modal, { ModalProps } from '../../modal/Modal';
import ModalActions from '../../modal/ModalActions';
import ModalBody from '../../modal/ModalBody';
import ModalHead from '../../modal/ModalHead';

type Props = ModalProps;

export function NestingLevelErrorModal({ isOpen, setIsOpen }: Props) {
    const { t } = useTranslation(['modals']);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <ModalHead setIsOpen={setIsOpen}>{t('modals:nestingLevelErrorModal.head')}</ModalHead>
            <ModalBody>
                {t('modals:nestingLevelErrorModal.body')}
                <ModalActions>
                    <button
                        className='btn'
                        type='button'
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        {t('modals:nestingLevelErrorModal.actionOk')}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}
