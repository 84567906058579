import classNames from 'classnames';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { PlanType } from '../../../generated/account-frontend-api';
import { PaymentType } from '../../../generated/billing-api';
import useCancelDowngrade from '../../../hooks/useCancelDowngrade';
import { SupportedLocales } from '../../../i18n/config';
import { useSelector } from '../../../store';
import {
    selectScheduledPlanStartDateDisplayValue,
    selectScheduledPlanStorageDisplayValue
} from '../../../store/slices/accountResources';
import Notification from '../notification/Notification';
import styles from '../notification/Notification.module.scss';
import { CommercialPlan } from '../plan-filters/PlanFilters';

export default function Notifications() {
    const { t, i18n } = useTranslation(['cabinet', 'subscribe']);

    const isAccountUpdateInProgress = useSelector(state => state.globalFlags.isAccountUpdateInProgress);
    const currentPlan = useSelector(state => state.accountResources.account.plan.current);
    const scheduledPlan = useSelector(state => state.accountResources.account.plan.scheduled);
    const scheduledPlanDate = useSelector(state => selectScheduledPlanStartDateDisplayValue(state));
    const scheduledStorage = useSelector(state => selectScheduledPlanStorageDisplayValue(state));
    const hasAccountPendingOrders = useSelector(state => state.accountResources.account.hasPendingOrders);
    const paymentType = useSelector(state => state.accountResources.account.paymentType);
    const fastSpringLink = useSelector(state => state.user.fastspringLink);
    const globalNotification = useSelector(state => state.init.globalNotification);

    const cancelDowngrade = useCancelDowngrade();

    return (
        <>
            {globalNotification?.isVisible && i18n.resolvedLanguage && (
                <Notification icon='warning' iconColor={globalNotification.type === 'alert' ? 'red' : undefined}>
                    <div
                        className={styles.msgText}
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                `<span>${globalNotification.text[i18n.resolvedLanguage as SupportedLocales]}</span>`
                            )
                        }}
                    />
                </Notification>
            )}
            {isAccountUpdateInProgress && (
                <Notification icon={'in_progress'}>
                    <div className={styles.msgText}>{t('cabinet:notifications.accountUpdateInProgress')}</div>
                </Notification>
            )}

            {hasAccountPendingOrders && (
                <Notification icon={'in_progress'}>
                    <div className={styles.msgText}>{t('cabinet:notifications.accountHasPendingOrders')}</div>
                </Notification>
            )}

            <CommercialPlan>
                {currentPlan && currentPlan.overdue ? (
                    <Notification iconColor='red'>
                        <div className={styles.msgText}>
                            {t('cabinet:notifications.overdue', { scheduledPlanDate })}
                        </div>
                        <a
                            href={fastSpringLink}
                            className={classNames(styles.msgBtn, 'btn-ghost-alert')}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {paymentType === PaymentType.AUTO
                                ? t('subscribe:actions.updatePaymentMethod')
                                : t('subscribe:actions.payNow')}
                        </a>
                    </Notification>
                ) : (
                    <>
                        {scheduledPlan?.type === PlanType.FREE &&
                            scheduledPlan?.cancelable &&
                            !isAccountUpdateInProgress && (
                                <Notification>
                                    <div className={styles.msgText}>
                                        {t('cabinet:notifications.cancelableDowngrade', { scheduledPlanDate })}
                                    </div>
                                    <div
                                        className={classNames(styles.msgBtn, 'btn-ghost-blue')}
                                        onClick={() => cancelDowngrade()}
                                    >
                                        {t('cabinet:plans.cancelPlanDowngrade')}
                                    </div>
                                </Notification>
                            )}
                        {scheduledPlan?.type === PlanType.COMMERCIAL &&
                            scheduledPlan?.cancelable &&
                            !isAccountUpdateInProgress && (
                                <Notification>
                                    <div className={styles.msgText}>
                                        {t('cabinet:notifications.cancelableDowngradeCommercial', {
                                            scheduledStorage,
                                            scheduledPlanDate
                                        })}
                                    </div>
                                    <div
                                        className={classNames(styles.msgBtn, 'btn-ghost-blue')}
                                        onClick={() => cancelDowngrade()}
                                    >
                                        {t('cabinet:plans.cancelStorageDowngrade')}
                                    </div>
                                </Notification>
                            )}
                        {scheduledPlan?.type === PlanType.COMMERCIAL && !scheduledPlan?.cancelable && (
                            <Notification>
                                <div className={styles.msgText}>
                                    {t('cabinet:notifications.nonCancelableDowngradeCommercial', {
                                        scheduledStorage,
                                        scheduledPlanDate
                                    })}
                                </div>
                            </Notification>
                        )}
                    </>
                )}
            </CommercialPlan>
        </>
    );
}
