import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { accountApi } from '../../api/initApis';
import { Account, Plan, ProcessingTimeInfo, ScheduledPlan, Storage } from '../../generated/account-frontend-api/model';
import { PaymentType } from '../../generated/billing-api';
import secondsToHoursAndMinutes from '../../lib/secondsToHoursAndMinutes';
import { format, parseISO } from 'date-fns';
import { ApplicationState } from '..';
import { DATE_FORMAT } from '../../sharedConstants';
import convertSize from '../../lib/convertSize';

interface AccountResourcesState {
    account: Required<Account>;
    blockedForRetryCall: boolean;
}

const initialState: AccountResourcesState = {
    account: {
        fastspringLink: '',
        hasActiveProcessings: false,
        paymentType: PaymentType.AUTO,
        plan: {},
        hasPendingOrders: false,
        resources: {}
    },
    blockedForRetryCall: false
};

const name = 'accountResources';

export const getAccount = createAsyncThunk(`${name}/getAccount`, async () => {
    const { data } = await accountApi.getAccount();
    return data;
});

export const getAccountHomeDataNoSkeleton = createAsyncThunk(`${name}/getHomeDataNoSkeleton`, () =>
    accountApi.getAccount().then(({ data }) => data)
);

const accountResourcesSlice = createSlice({
    name,
    initialState,
    reducers: {
        setBlockedForRetryCall(state, action: PayloadAction<boolean>) {
            state.blockedForRetryCall = action.payload;
        },
        setStorage(state, { payload }: PayloadAction<Storage>) {
            state.account.resources.storage = payload;
        },
        setProcessingTime(state, { payload }: PayloadAction<ProcessingTimeInfo>) {
            state.account.resources.processingTime = payload;
        },
        setPlan(state, { payload }: PayloadAction<Plan>) {
            state.account.plan = payload;
        },
        setScheduledPlan(state, { payload }: PayloadAction<ScheduledPlan | null>) {
            state.account.plan.scheduled = payload || undefined;
        },
        setHasPendingOrders(state, { payload }: PayloadAction<boolean>) {
            state.account.hasPendingOrders = payload;
        }
    },
    extraReducers: builder =>
        builder.addCase(getAccount.fulfilled, (state, { payload }) => {
            state.account = payload as Required<Account>;
        })
});

export const { setStorage, setProcessingTime, setPlan, setScheduledPlan, setHasPendingOrders } =
    accountResourcesSlice.actions;

export default accountResourcesSlice.reducer;

export const selectFreeTimeHoursMinutes = createSelector(
    (state: ApplicationState) => state.accountResources.account.resources.processingTime?.freeTime,
    freeTime => {
        if (freeTime) {
            if (freeTime <= 0) return { hours: 0, minutes: 0 };
            return secondsToHoursAndMinutes(freeTime);
        }
        return { hours: 0, minutes: 0 };
    }
);

export const selectCurrentPlanRenewDateDisplayValue = createSelector(
    (state: ApplicationState) => state.accountResources.account.plan.current?.renewDate,
    renewDate => (renewDate ? format(parseISO(renewDate), DATE_FORMAT) : '')
);

export const selectScheduledPlanStartDateDisplayValue = createSelector(
    (state: ApplicationState) => state.accountResources.account.plan.scheduled?.startDate,
    startDate => (startDate ? format(parseISO(startDate), DATE_FORMAT) : '')
);

export const selectStorageLimitAtFreePlanDisplayValue = createSelector(
    (state: ApplicationState) => state.accountResources.account.plan.storageLimitAtFreePlan,
    storageLimitAtFreePlan => convertSize(storageLimitAtFreePlan, false, 0)
);

export const selectScheduledPlanStorageDisplayValue = createSelector(
    (state: ApplicationState) => state.accountResources.account.plan.scheduled?.storageLimit,
    storageLimit => convertSize(storageLimit ?? 0)
);
