// 'hideTextOverflow' property diplays value in one line with 'overflow: ellipsis' styling

import classNames from 'classnames';
import { ReactComponent as SvgLoader20 } from '../../../../svg/volume/loader_20.svg';

type Props = {
    label: string;
    value: string;
    loading?: boolean;
    hideTextOverflow?: boolean;
};

export default function VolumeProperty({ label, value, loading, hideTextOverflow }: Props) {
    return (
        <div className='property'>
            <div className='prop-wrapper read-only'>
                <div className='prop-label-wrapper'>
                    <div className='prop-text prop-label'>{label}</div>
                </div>
                <div className='prop-value-wrapper'>
                    {!loading && value ? (
                        <div className={classNames('prop-text', { 'prop-overflow': hideTextOverflow })}>{value}</div>
                    ) : (
                        <div className='prop-value-loader' data-testid={'loader'}>
                            <SvgLoader20 className='spinner-svg' />
                        </div>
                    )}
                </div>
                <div className='prop-action' />
            </div>
        </div>
    );
}
