import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { VisualData } from '../../generated/cloud-frontend-api';
import { ProjectPartType, SourceType, Status } from '../../generated/dataset-api';
import { createSetterReducer } from '../helpers';
import GeoJsonUpload from '../helpers/geojson-upload/GeoJsonUpload';
import { ExtendedChunk, TemporaryLayer } from '../helpers/interfaces';
import { VectorLayerUpload } from '../helpers/vector-layer-upload/VectorLayerUpload';
import { ApplicationState } from '../index';
import { addRecentlyFinishedUploadId, deleteRecentlyFinishedUploadId } from '../sharedActions';
import { ExtendedDatasetInfo } from './datasetfilesUpload';
import { isOwnOrLinkedDataset } from './datasets';
import { ExtendedStructureInfo } from './structure';

export interface VectorLayerUploadInfo {
    status: 'validating' | 'uploading' | 'rejected' | 'aborted' | 'failed' | 'pending';
    geometriesCount: number;
    uploadedGeometriesCount: number;
    error?: any;
    file?: File;
    terrainProviderRef?: any;
}
interface DatasetsUploadState {
    uploads: Record<string, VectorLayerUploadInfo>;
    isModalOpen: boolean;
    recentlyFinished: string[];
}

const initialState: DatasetsUploadState = {
    isModalOpen: false,
    uploads: {},
    recentlyFinished: []
};

const name = 'datasetsUpload';

const setterReducer = createSetterReducer<DatasetsUploadState>();

const slice = createSlice({
    name,
    initialState,
    reducers: {
        setDatasetsUploadModalOpen: setterReducer('isModalOpen'),
        validationStarted(state, { payload }: PayloadAction<{ id: string }>) {
            state.uploads[payload.id] = {
                status: 'validating',
                geometriesCount: 0,
                uploadedGeometriesCount: 0
            };
        },
        uploadStarted(
            state,
            { payload }: PayloadAction<{ id: string; geometriesCount: number; coordinateSystemUid: string }>
        ) {
            state.uploads[payload.id] = {
                status: 'uploading',
                geometriesCount: payload.geometriesCount,
                uploadedGeometriesCount: 0
            };
        },
        uploadAborted(state, { payload }: PayloadAction<string>) {
            state.uploads[payload].status = 'aborted';
        },
        uploadRemoved(state, { payload }: PayloadAction<string>) {
            delete state.uploads[payload];
        },
        uploadPending(state, { payload }: PayloadAction<{ id: string; file: File; terrainProviderRef: any }>) {
            state.uploads[payload.id] = {
                status: 'pending',
                geometriesCount: 0,
                uploadedGeometriesCount: 0,
                file: payload.file,
                terrainProviderRef: payload.terrainProviderRef
            };
        },
        setRecentlyFinishedUploadIds(state, { payload }: PayloadAction<string[]>) {
            state.recentlyFinished = payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(GeoJsonUpload.uploadGeoJsonLayer.fulfilled, (state, { payload }) => {
                delete state.uploads[payload.id];
            })
            .addCase(GeoJsonUpload.uploadGeoJsonLayer.rejected, (state, { payload }) => {
                if (payload) {
                    const isAborted = state.uploads[payload?.id!]?.status === 'aborted';
                    if (isAborted) return state;

                    state.uploads[payload.id].error = payload.error;
                    if (payload.errorCode === 413) {
                        state.uploads[payload.id].status = 'failed';
                    } else {
                        state.uploads[payload.id].status = 'rejected';
                    }
                }
            })
            .addCase(VectorLayerUpload.uploadVectorLayer.rejected, (state, { payload }) => {
                if (payload) {
                    const isAborted = state.uploads[payload?.id!]?.status === 'aborted';
                    if (isAborted) return state;

                    state.uploads[payload.id].error = payload.error;
                    state.uploads[payload.id].status = 'rejected';
                }
            })
            .addCase(GeoJsonUpload.uploadBatch.fulfilled, (state, { meta }) => {
                state.uploads[meta.arg.layerUid].uploadedGeometriesCount += meta.arg.batch.length;
            })
            .addCase(addRecentlyFinishedUploadId, (state, { payload }) => {
                if (!state.recentlyFinished.includes(payload.id)) state.recentlyFinished.push(payload.id);
            })
            .addCase(deleteRecentlyFinishedUploadId, (state, { payload }) => {
                const index = state.recentlyFinished.indexOf(payload.id);
                if (index !== -1) state.recentlyFinished.splice(index, 1);
            });
    }
});

export const {
    setDatasetsUploadModalOpen,
    validationStarted,
    uploadStarted,
    uploadAborted,
    setRecentlyFinishedUploadIds,
    uploadRemoved,
    uploadPending
} = slice.actions;

export const selectPendingUploads = createSelector(
    (state: ApplicationState) => state.datasetsUpload.uploads,
    uploads => _.filter(uploads, upload => upload.status === 'uploading')
);

export const selectUpload = createSelector(
    (state: ApplicationState) => state.datasetsUpload.uploads,
    (state: ApplicationState, id: string) => id,
    (uploads, id) => (id in uploads ? uploads[id] : undefined)
);

export const selectPercentUploaded = createSelector(selectUpload, upload => {
    if (!upload) return 0;
    if (upload.geometriesCount === 0) return 100;
    return Number(Math.floor((upload.uploadedGeometriesCount / upload.geometriesCount) * 100).toFixed(0));
});

export function getSortedDatasets(
    layers: TemporaryLayer[],
    layerUploads: Record<string, VectorLayerUploadInfo>,
    datasets: ExtendedDatasetInfo[],
    structures: ExtendedStructureInfo[],
    projectId: string,
    chunks: ExtendedChunk[]
) {
    const vectorLayerDatasets: ExtendedDatasetInfo[] = layers.map(layer => {
        const layerUploadInfo = layerUploads[layer.id];
        return adaptVectorLayerToDataset(layer, layerUploadInfo);
    });
    const allDatasets = [...datasets.filter(isOwnOrLinkedDataset(projectId, chunks)), ...vectorLayerDatasets];

    const datasetsWithoutOrder = allDatasets.filter(
        d => !structures.find(s => s.uid === d.datasetUid)?.properties?.order
    );
    const datasetsWithOrder = allDatasets.filter(d => structures.find(s => s.uid === d.datasetUid)?.properties?.order);

    return [
        ..._.sortBy(datasetsWithOrder, d =>
            parseInt(structures.find(s => s.uid === d.datasetUid)?.properties?.order || '0')
        ),
        ...datasetsWithoutOrder
    ];
}
export const selectDatasets = createSelector(
    (state: ApplicationState) => state.project.projectInfo,
    (state: ApplicationState) => state.project.structure,
    (state: ApplicationState) => state.datasetsUpload.uploads,
    (state: ApplicationState) => state.datasets.datasets,
    (state: ApplicationState) => state.structure,
    (projectInfo, structure, vectorLayerUploads, datasets, { structures }) => {
        return getSortedDatasets(
            structure.temporaryLayers,
            vectorLayerUploads,
            datasets,
            structures,
            projectInfo.id!,
            structure.chunks
        );
    }
);
export function adaptVectorLayerToDataset(
    layer: TemporaryLayer,
    layerUploadInfo: VectorLayerUploadInfo | undefined
): ExtendedDatasetInfo {
    return {
        datasetUid: layer.id,
        name: layer.name,
        coordinateSystemUid: layer.coordinateSystemUid,
        parentProject: layer.parentProject,
        linkedProjects: layer.linkedProjects || [],
        projectPartType: ProjectPartType.VECTOR_LAYERS,
        properties: {
            isInspection: layer.isInspection ? 'true' : 'false',
            isPresentation: layer.isPresentation ? 'true' : 'false'
        },
        sourceData: mapSourceData(layerUploadInfo),
        visualData: mapVisualData(layerUploadInfo),
        uploadPercent: layerUploadInfo
            ? Math.floor((layerUploadInfo.uploadedGeometriesCount / layerUploadInfo.geometriesCount) * 100)
            : undefined
    };

    function mapVisualData(layerUploadInfo?: VectorLayerUploadInfo): VisualData | undefined {
        if (!layerUploadInfo) return { status: Status.COMPLETED };
        return undefined;
    }

    function mapSourceData(layerUploadInfo?: VectorLayerUploadInfo): ExtendedDatasetInfo['sourceData'] {
        if (!layerUploadInfo) return { type: SourceType.GEOJSON, sizeInBytes: layer.sizeInBytes ?? 0 };

        const { status, error } = layerUploadInfo;
        if (status === 'validating') return { type: SourceType.GEOJSON, status: 'validating' };
        if (status === 'rejected') return { type: SourceType.GEOJSON, status: 'validationError', lastError: error };
        if (status === 'failed') return { type: SourceType.GEOJSON, status: 'uploadError', lastError: error };
        if (status === 'pending') return { type: SourceType.GEOJSON, status: 'pending' };

        return { type: SourceType.GEOJSON, sizeInBytes: layer.sizeInBytes ?? 0 };
    }
}

export default slice.reducer;
