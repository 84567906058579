import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from '../../../../sharedConstants';
import { AppDispatch } from '../../../../store';
import {
    disableAllControls,
    setCameraFilteringPoint,
    setCamerasInspectionEnabled,
    setFilteredCamerasPointProjection
} from '../../../../store/slices/projectView';
import TippyTooltip from '../../../Elements/tippy-tooltip/TippyTooltip';
import { useTranslation } from 'react-i18next';

export default function InspectCloseControl() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const isEmbedRoute = !!useRouteMatch({ path: Routes.EMBEDDED_PROJECT_VIEW, exact: true });

    return (
        <div className='tool-close'>
            <TippyTooltip tooltipText={t('inspectionTool.exitButton')}>
                <div
                    className='close-control'
                    onClick={() => {
                        dispatch(disableAllControls());
                        dispatch(setCamerasInspectionEnabled(false));
                        dispatch(setCameraFilteringPoint(null));
                        dispatch(setFilteredCamerasPointProjection([]));
                    }}
                >
                    <div className={classNames('ico-x', { dark: !isEmbedRoute })} data-testid={'iconClose'} />
                </div>
            </TippyTooltip>
        </div>
    );
}
