import { useEffect, useRef, useState, useCallback, useMemo, memo } from 'react';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import ElevationProfileExpandedGraph from './ElevationProfileExpandedGraph';
import ElevationProfileGraphOverlay from './ElevationProfileGraphOverlay';
import { useDispatch } from 'react-redux';
import { setElevationProfileExpanded } from '../../../store/slices/projectView';
import { ReactComponent as SvgZoomOut } from '../../../svg/tool_profile/zoom_out.svg';
import { ReactComponent as SvgClose } from '../../../svg/general/x-icon.svg';
import { AppDispatch, useSelector } from '../../../store';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { isPolylineGeometry, ProjectStructureObjectTypes, TemporaryGeometry } from '../../../store/helpers/interfaces';
import exportAsCsv from './export';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { TerrainViewModes } from '../../../sharedConstants';
import { makeSelectElevationProfileById } from '../../../store/slices/elevationProfiles';
import { SurfaceAltitudes } from '../../../store/helpers/elevation-profile/ElevationProfile';
import ElevationProfileLegend from './ElevationProfileLegend';
import { makeSelectLayerByGeometryId } from '../../../store/slices/geometryLayers';

type Props = {
    getToolTopCoord: (e: number) => void;
    wrapperHeight: number;
};

function ElevationProfileExpandedTool({ getToolTopCoord, wrapperHeight }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const selectedObject = useSelector(state => state.project.selectedObject);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));
    const isElevationProfileExpanded = useSelector(state => state.projectView.isElevationProfileExpanded);
    const selectElevationProfileById = useMemo(makeSelectElevationProfileById, []);
    const elevationProfile = useSelector(state => selectElevationProfileById(state, selectedObject?.artifactId!));
    const projectInfo = useSelector(state => state.project.projectInfo);
    const units = useSelector(state => state.coordinateSystems.units);
    const terrainViewMode = useSelector(state => state.projectView.terrainViewMode);
    const selectLayerByGeometryId = useMemo(makeSelectLayerByGeometryId, []);
    const layer = useSelector(state => selectLayerByGeometryId(state, selectedObject?.artifactId!));
    const isReady = elevationProfile?.status === 'fulfilled';
    const isPending = elevationProfile?.status === 'pending';
    const hasFailed = elevationProfile?.status === 'rejected';
    const hasResults = Boolean(elevationProfile?.result.altitudes.length);
    const isRestartable = terrainViewMode === TerrainViewModes.MODEL || elevationProfile?.status === 'aborted';
    const surfaceAltitudes: SurfaceAltitudes[] = elevationProfile?.result.altitudes || [];

    // handle sidebars toggle buttons y-position
    const profileRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        getToolTopCoord(profileRef.current?.getBoundingClientRect().top || 0);
    }, [wrapperHeight, profileRef, getToolTopCoord]);

    // reset zoom
    const [resetZoom, setResetZoom] = useState(false);
    const passResetZoom = useCallback((value: boolean) => setResetZoom(value), []);

    const isPolylineSelected =
        selectedObject?.type === ProjectStructureObjectTypes.GEOMETRY &&
        isPolylineGeometry(selectedObjectInfo as TemporaryGeometry);

    const isLegendHidden = elevationProfile?.status !== 'fulfilled';

    return elevationProfile && isPolylineSelected && isElevationProfileExpanded ? (
        <div className='map-profile' ref={profileRef}>
            <div className='profile-title'>
                <span className='title'>{t('elevationProfile.expandedTool.title')}</span>
                <TippyTooltip tooltipText={t('elevationProfile.controls.tooltipResetZoom')} disabled={!isReady}>
                    <div
                        className={classNames('profile-control', { disabled: !isReady })}
                        onClick={() => setResetZoom(true)}
                    >
                        <SvgZoomOut data-testid={'zoomControl'} />
                    </div>
                </TippyTooltip>
                <TippyTooltip tooltipText={t('elevationProfile.controls.tooltipExport')} disabled={!isReady}>
                    <div
                        className={classNames('profile-control', { disabled: !isReady })}
                        onClick={e => {
                            if (!isReady) return;

                            exportAsCsv(
                                elevationProfile.result,
                                projectInfo.name!,
                                layer?.name!,
                                (selectedObjectInfo as TemporaryGeometry).content.properties.ac_name,
                                units
                            );
                        }}
                    >
                        <i className='icon icon-export' data-testid={'exportControl'} />
                    </div>
                </TippyTooltip>
                <TippyTooltip tooltipText={t('elevationProfile.controls.tooltipClose')}>
                    <div
                        className='profile-control'
                        onClick={e => {
                            e.preventDefault();
                            dispatch(setElevationProfileExpanded(false));
                        }}
                    >
                        <SvgClose data-testid={'closeControl'} />
                    </div>
                </TippyTooltip>
            </div>
            <div className='map-profile-wrapper'>
                <ElevationProfileLegend surfaceAltitudes={surfaceAltitudes} isLegendHidden={isLegendHidden} />
                <div className='map-profile-placeholder'>
                    {elevationProfile?.status === 'fulfilled' && (
                        <ElevationProfileExpandedGraph resetZoom={resetZoom} passResetZoom={passResetZoom} />
                    )}
                </div>
                {isPending && <ElevationProfileGraphOverlay elevationProfile={elevationProfile} type='inProgress' />}
                {hasFailed && <ElevationProfileGraphOverlay elevationProfile={elevationProfile} type='error' />}
                {isRestartable && (
                    <ElevationProfileGraphOverlay elevationProfile={elevationProfile} type={'interrupted'} />
                )}
            </div>
        </div>
    ) : null;
}

export default memo(ElevationProfileExpandedTool);
