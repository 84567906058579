import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import Modal, { ModalProps } from '../../modal/Modal';
import ModalHead from '../../modal/ModalHead';
import ModalBody from '../../modal/ModalBody';
import ModalActions from '../../modal/ModalActions';
import Checkbox from '../../checkbox/Checkbox';
import jsonData from '../../../../entities/budgetEstimation.json';
import formatTimeWithNegative from '../../../../lib/formatTimeWithNegative';
import { ProcessingTimeProduct } from '../../../../generated/billing-api';

const { Option } = Select;

const jsonResolutionKey = 'resolutionInMegapixel';
const jsonNumberOfImagesKey = 'number';

type Props = ModalProps & {
    products: ProcessingTimeProduct[];
};

export default function EstimateBudgetModal({ isOpen, setIsOpen, products }: Props) {
    const { t } = useTranslation(['modals', 'common']);

    const data = jsonData;

    const resolutions = [
        ...new Set(
            data.map((item: any) => {
                return item.images[jsonResolutionKey];
            })
        )
    ];

    const numberOfImages = [
        ...new Set(
            data.map((item: any) => {
                return item.images[jsonNumberOfImagesKey];
            })
        )
    ];

    const outputOptions = new Set(Object.keys(data[0].processingResults));

    const [selectedResolution, setSelectedResolution] = useState(resolutions[0]);
    const [selectedImages, setSelectedImages] = useState(numberOfImages[0]);
    const [selectedOptions, setSelectedOptions] = useState(new Set(['tiePoints', 'depthMaps']));

    const productsList = getProductsList();

    const computeSeconds = useMemo(() => {
        const resultsSection = data.find(
            item =>
                item.images[jsonResolutionKey] === selectedResolution &&
                item.images[jsonNumberOfImagesKey] === selectedImages
        )?.processingResults;

        const computeSeconds = Object.keys(resultsSection!).reduce((accum, item) => {
            return (
                accum +
                (selectedOptions.has(item)
                    ? resultsSection?.[item as keyof typeof resultsSection].processingTimeInSeconds!
                    : 0)
            );
        }, 0);

        return computeSeconds;
    }, [data, selectedResolution, selectedImages, selectedOptions]);

    const total = useMemo(() => {
        const totalValues = formatTimeWithNegative(computeSeconds);
        const totalString = `${totalValues.hours} ${t('common:timeUnits_short.hour')} ${totalValues.minutes} ${t(
            'common:timeUnits_short.minute'
        )}`;

        return totalString;
    }, [computeSeconds, t]);

    const price = useMemo(() => {
        const suitableProduct = productsList.find(product => product.seconds >= computeSeconds);

        const usedTime = computeSeconds / suitableProduct!.seconds;
        const price = Math.round(suitableProduct?.price! * usedTime * 100) / 100;

        return `$${price}`;
    }, [productsList, computeSeconds]);

    function getOptionString(option: string) {
        switch (option) {
            case 'tiePoints':
                return t('modals:estimateBudgetModal.processingResults.tiePoints');
            case 'depthMaps':
                return t('modals:estimateBudgetModal.processingResults.depthMaps');
            case 'pointCloud':
                return t('modals:estimateBudgetModal.processingResults.pointCloud');
            case 'dem':
                return t('modals:estimateBudgetModal.processingResults.dem');
            case 'orthomosaic':
                return t('modals:estimateBudgetModal.processingResults.orthomosaic');
            case 'texturedMesh':
                return t('modals:estimateBudgetModal.processingResults.texturedMesh');
            case 'tiledModel':
                return t('modals:estimateBudgetModal.processingResults.tiledModel');
            default:
                return '';
        }
    }

    function getProductsList() {
        const productsList = products.map(product => {
            return {
                seconds: parseInt(product.name!, 10) * 3600,
                price: product.price
            };
        });
        return productsList;
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalClass='modal-budget'>
            <ModalHead setIsOpen={setIsOpen}>{t('modals:estimateBudgetModal.head')}</ModalHead>
            <ModalBody>
                <div className='modal-paragraph'>{t('modals:estimateBudgetModal.body')}</div>
                <form>
                    <div className='fieldGroup'>
                        <div className='field-label'>{t('modals:estimateBudgetModal.labels.resolution')}</div>
                        <Select
                            defaultValue={selectedResolution}
                            onChange={value => {
                                setSelectedResolution(value);
                            }}
                        >
                            {resolutions.map(item => {
                                return (
                                    <Option value={item} key={item}>
                                        {t('modals:estimateBudgetModal.resolutionString', { resolution: item })}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>

                    <div className='fieldGroup'>
                        <div className='field-label'>{t('modals:estimateBudgetModal.labels.images')}</div>
                        <Select
                            defaultValue={selectedImages}
                            onChange={value => {
                                setSelectedImages(value);
                            }}
                        >
                            {numberOfImages.map(item => {
                                return (
                                    <Option value={item} key={item}>
                                        {t('modals:estimateBudgetModal.numberOfImagesString', { number: item })}
                                    </Option>
                                );
                            })}
                        </Select>
                    </div>

                    <div className='fieldGroup'>
                        <div className='field-label'>{t('modals:estimateBudgetModal.labels.outputs')}</div>
                        <div>
                            {Array.from(outputOptions).map(item => {
                                return (
                                    <div className='budget-option form-checkbox' key={item}>
                                        <Checkbox
                                            checked={selectedOptions.has(item)}
                                            disabled={item === 'tiePoints' || item === 'depthMaps'}
                                            onChange={() => {
                                                setSelectedOptions(prev => {
                                                    const newSetState = new Set(prev);
                                                    prev.has(item) ? newSetState.delete(item) : newSetState.add(item);
                                                    return newSetState;
                                                });
                                            }}
                                        />
                                        <span>{getOptionString(item)}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div className='budget-results'>
                        <span>
                            <b>{t('modals:estimateBudgetModal.computeTime')}</b>
                            <span>{` ${total}`}</span>
                        </span>
                        <b>{price}</b>
                    </div>
                </form>

                <ModalActions>
                    <button
                        className='btn'
                        type='button'
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        {t('modals:estimateBudgetModal.action')}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}
