import * as Cesium from 'cesium';
import { defined, PointPrimitive } from 'cesium';

export default function (scene: Cesium.Scene, windowPosition: Cesium.Cartesian2) {
    const pickedObjects = scene.drillPick(windowPosition);
    return pickedObjects;
}

function isPoint(pickedObject: any): boolean {
    const isEntityPoint = pickedObject?.id?.point || pickedObject?.id?.billboard;
    const isPrimitivePoint = pickedObject?.primitive instanceof PointPrimitive;
    return isEntityPoint || isPrimitivePoint;
}

export function getPointFromPickedObjects(pickedObjects: any[]) {
    const pickedPointOrBillboard = pickedObjects.filter(isPoint);
    if (defined(pickedPointOrBillboard) && pickedPointOrBillboard?.length) {
        const id = Cesium.defaultValue(pickedPointOrBillboard[0].id, pickedPointOrBillboard[0].primitive.id);
        if (id instanceof Cesium.Entity) return id;
        else return pickedPointOrBillboard[0]?.primitive as PointPrimitive;
    }
    return undefined;
}
