import { Group, Rect } from 'react-konva';
import { Issue } from '../../../entities/Issue';
import { GeometryTypes, Tuple, WidthHeight } from '../../../sharedConstants';
import { ProjectStructureObjectTypes, TemporaryGeometry } from '../../../store/helpers/interfaces';
import { Vector2d } from 'konva/lib/types';
import { ExtendedCamera } from '../../../store/slices/cameras';
import { forwardRef, useState } from 'react';
import { AppDispatch, useSelector } from '../../../store';
import { selectInspections } from '../../../store/slices/geometryLayers';
import { isObjectVisible, selectGroups } from '../../../store/slices/structure';
import { useDispatch } from 'react-redux';
import { setSelectedObject } from '../../../store/sharedActions';
import Konva from 'konva';

type Props = {
    issue: TemporaryGeometry<GeometryTypes.POINT>;
    sourceSize: WidthHeight;
    imageWidth: number;
    imageHeight: number;
    singleIssueIdToDisplay?: string;
    scale: Vector2d;
    selectionDisabled?: boolean;
    usePreviewBbox?: boolean;
};

const STROKE_WIDTH = 2;
const OUTER_STROKE_WIDTH = 1;

export const IssuesRects = forwardRef<Konva.Rect, Props>(
    (
        {
            issue,
            sourceSize,
            imageHeight,
            selectionDisabled,
            imageWidth,
            singleIssueIdToDisplay,
            scale,
            usePreviewBbox
        },
        ref
    ) => {
        const dispatch: AppDispatch = useDispatch();
        const [hoveredIssueId, setHoveredIssueId] = useState('');
        const layers = useSelector(selectInspections);
        const selectedObject = useSelector(state => state.project.selectedObject);
        const structures = useSelector(state => state.structure.structures);
        const groups = useSelector(selectGroups);

        const bbox = issue.content.properties.ac_issue_bbox as Tuple<number, 4>;
        const bboxInPreview = issue.content.properties.ac_issue_bbox_in_preview as Tuple<number, 4>;
        const bboxToUse = usePreviewBbox ? bboxInPreview : bbox;

        const { initPoint, endPoint } = normalizePoints(
            { x: bboxToUse[0], y: bboxToUse[1] },
            { x: bboxToUse[2], y: bboxToUse[3] }
        );
        const bboxNormalized = [initPoint.x, initPoint.y, endPoint.x, endPoint.y];
        const bboxScaled = [
            (bboxNormalized[0] * imageWidth) / sourceSize.width,
            (bboxNormalized[1] * imageHeight) / sourceSize.height,
            (bboxNormalized[2] * imageWidth) / sourceSize.width,
            (bboxNormalized[3] * imageHeight) / sourceSize.height
        ];
        const rectWidth = bboxScaled[2] - bboxScaled[0];
        const rectHeight = bboxScaled[3] - bboxScaled[1];
        const color = new Issue(issue).color;
        const isActive = hoveredIssueId === issue.id || selectedObject?.artifactId === issue.id;
        const layer = layers.find(l => l.geometries.includes(issue.id));
        const layerVisible = isObjectVisible(structures, layer?.id!);
        const structureInfo = structures.find(s => s.uid === layer?.id);
        const group = groups.find(g => g.uid === structureInfo?.parentUid);
        const belongsToGroup = Boolean(group);
        const groupVisible = belongsToGroup ? isObjectVisible(structures, group?.uid!) : true;
        const show =
            (issue.content.properties.ac_visibility && layerVisible && groupVisible) || !!singleIssueIdToDisplay;
        const rectStrokeWidth = isActive ? STROKE_WIDTH + 2 : STROKE_WIDTH;

        const outerRectOffset = Math.sqrt(rectStrokeWidth + OUTER_STROKE_WIDTH) / scale.x;
        const strokeRectOuter = {
            x: bboxScaled[0] - outerRectOffset,
            y: bboxScaled[1] - outerRectOffset
        };
        const strokeRectInnerXY = {
            x: bboxScaled[0] + outerRectOffset,
            y: bboxScaled[1] + outerRectOffset
        };

        return (
            bboxScaled.every(value => !!value) && (
                <Group
                    key={issue.id}
                    visible={show}
                    onPointerEnter={e => {
                        if (selectionDisabled) return;
                        setHoveredIssueId(issue.id);
                    }}
                    onPointerLeave={e => {
                        if (selectionDisabled) return;
                        setHoveredIssueId('');
                    }}
                    onPointerClick={e => {
                        if (selectionDisabled) return;
                        dispatch(
                            setSelectedObject({
                                artifactId: issue.id,
                                type: ProjectStructureObjectTypes.GEOMETRY,
                                needToScroll: true
                            })
                        );
                    }}
                >
                    <Rect
                        ref={ref}
                        fillEnabled={false}
                        x={bboxScaled[0]}
                        y={bboxScaled[1]}
                        width={rectWidth}
                        height={rectHeight}
                        stroke={color}
                        strokeWidth={rectStrokeWidth}
                        strokeScaleEnabled={false}
                    />
                    <Rect
                        fillEnabled={false}
                        x={strokeRectOuter.x}
                        y={strokeRectOuter.y}
                        width={rectWidth + 2 * outerRectOffset}
                        height={rectHeight + 2 * outerRectOffset}
                        stroke={'#fff'}
                        strokeWidth={OUTER_STROKE_WIDTH}
                        strokeScaleEnabled={false}
                    />

                    <Rect
                        fillEnabled={false}
                        x={strokeRectInnerXY.x}
                        y={strokeRectInnerXY.y}
                        width={rectWidth - 2 * outerRectOffset}
                        height={rectHeight - 2 * outerRectOffset}
                        stroke={'#fff'}
                        strokeWidth={OUTER_STROKE_WIDTH}
                        strokeScaleEnabled={false}
                    />
                </Group>
            )
        );
    }
);

/**
 * "Normalizes" rect points to: initialPoint = top-left, endPoint = bottom-right
 */
function normalizePoints(initPoint: Vector2d, endPoint: Vector2d): { initPoint: Vector2d; endPoint: Vector2d } {
    // initPoint is bottom-right, just reverse
    if (endPoint.x < initPoint.x && endPoint.y < initPoint.y) {
        return { initPoint: { ...endPoint }, endPoint: { ...initPoint } };
    }

    // initPoint is top-right
    if (endPoint.x < initPoint.x && endPoint.y > initPoint.y) {
        return {
            initPoint: { x: endPoint.x, y: initPoint.y },
            endPoint: { x: initPoint.x, y: endPoint.y }
        };
    }

    // initPoint is bottom-left
    if (endPoint.x > initPoint.x && endPoint.y < initPoint.y) {
        return {
            initPoint: { x: initPoint.x, y: endPoint.y },
            endPoint: { x: endPoint.x, y: initPoint.y }
        };
    }

    // otherwise normalized
    return { initPoint, endPoint };
}
