import React from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import classNames from 'classnames';

type Props = {
    rows?: number;
    as: 'input' | 'textarea';
    name: string;
    type?: 'text' | 'password' | 'email';
    placeholder?: string;
    trim?: boolean;
};

function FieldWithErrorMessage(props: Props) {
    const { errors, touched, setFieldValue, setFieldTouched } = useFormikContext<Record<string, any>>();

    const handleBlur = (e: React.SyntheticEvent<EventTarget>) => {
        const target = e.target as HTMLInputElement;
        setFieldValue(target.name, target.value.trim());
        setFieldTouched(target.name, true);
    };

    return (
        <>
            <Field
                as={props.as}
                rows={props.rows}
                type={props.type}
                placeholder={props.placeholder}
                name={props.name}
                className={classNames({ error: errors[props.name] && touched[props.name] })}
                onBlur={props.trim && handleBlur}
            />
            <div className='field-error' data-testid={'fieldError'}>
                <ErrorMessage name={props.name} component={'span'} />
            </div>
        </>
    );
}

FieldWithErrorMessage.defaultProps = {
    type: 'text',
    as: 'input'
};

export default FieldWithErrorMessage;
