import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import * as Cesium from 'cesium';
import { WritableDraft } from 'immer/dist/internal';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import { ApplicationState } from '..';
import { LimitBoxParams } from '../../components/ProjectView/geometry-layers/limit-box/LimitBox';
import UndoableOperation from '../../components/ProjectView/undo-operation/UndoableOperation';
import {
    DARK_OVERLAY_BREAKPOINT,
    GeometryTypes,
    INSPECTOR_PANEL_ANIMATION_DURATION,
    MAP_PANEL_CONTROL_WIDTH,
    MAP_PANEL_DEFAULT_WIDTH,
    QualityOf3DModes,
    TerrainViewModes
} from '../../sharedConstants';
import { createSetterReducer } from '../helpers';
import { CurrentlyEditingShape } from '../helpers/interfaces';
import { setSelectedObject } from '../sharedActions';
import { PointProjectionsOnCameras } from './cameras';

interface Indicators {
    isSidebarExpanded: boolean;
    isInspectorExpanded: boolean;
    isInspectorAnimated: boolean;
    areMapSettingsOpen: boolean;
    galleryVisibility: { isVisible: boolean; mode: 'cameras' | 'issues' };
    isCesiumLoaded: boolean;
    isTerrainProviderLoaded: boolean;
    hasCesiumError: boolean;
    isFullscreen: boolean;
    camerasLoading: Record<string, boolean>;
    isCamerasInspectionEnabled: boolean;
    isPresentationSetupEnabled: boolean;
    isElevationProfileExpanded: boolean;
    isRulerToolEnabled: boolean;
    isCompareToolEnabled: boolean;
}

interface GeometryDrawing {
    selectedGeometryType: GeometryTypes | undefined;
    currentlyDrawingShapeId: string | undefined;
    floatingPointCoordinates: number[] | undefined;
    currentlyEditingShapeId: CurrentlyEditingShape | undefined;
    currentlyHoveringShapeId: string | undefined;
    undoableOperation: UndoableOperation | undefined;
    selectedGeometryNodeId: string | undefined;
    hoveredGeometryNodeId: string | undefined;
    limitBoxMovingFaceId: keyof LimitBoxParams | null;
}

interface ProjectViewState extends Indicators, GeometryDrawing {
    terrainViewMode: TerrainViewModes;
    baseImageryProvider: string;
    quality3DMode: QualityOf3DModes;
    tilesetLoading: Record<string, boolean>;
    selectedCamera: { artifactUid: string; uid: string } | undefined;
    cameraFilteringPoint: number[] | null;
    filteredCamerasPointProjection: PointProjectionsOnCameras;
    tilesetsBoundingSpheres: Record<string, Cesium.BoundingSphere>;
    tilesetsTransforms: Record<string, Cesium.Matrix4>;
    idOfGeometryWithSavingPreview: string | undefined;
    tourPlayerState: 'playing' | 'pause' | undefined;
}

const initialState: ProjectViewState = {
    terrainViewMode: TerrainViewModes.EARTH,
    baseImageryProvider: '',
    quality3DMode: isMobile ? QualityOf3DModes.MEDIUM : QualityOf3DModes.HIGH,
    tilesetLoading: {},
    areMapSettingsOpen: false,
    isInspectorExpanded: false,
    isInspectorAnimated: false,
    galleryVisibility: { isVisible: false, mode: 'cameras' },
    isCesiumLoaded: false,
    isTerrainProviderLoaded: false,
    hasCesiumError: false,
    isSidebarExpanded: window.innerWidth >= (MAP_PANEL_DEFAULT_WIDTH + MAP_PANEL_CONTROL_WIDTH) * 2,
    isPresentationSetupEnabled: false,
    isFullscreen: false,
    isCamerasInspectionEnabled: false,
    limitBoxMovingFaceId: null,
    selectedCamera: undefined,
    camerasLoading: {},
    tilesetsBoundingSpheres: {},
    tilesetsTransforms: {},
    selectedGeometryType: undefined,
    selectedGeometryNodeId: undefined,
    hoveredGeometryNodeId: undefined,
    currentlyDrawingShapeId: undefined,
    floatingPointCoordinates: undefined,
    currentlyEditingShapeId: undefined,
    currentlyHoveringShapeId: undefined,
    undoableOperation: undefined,
    cameraFilteringPoint: null,
    filteredCamerasPointProjection: [],
    isElevationProfileExpanded: false,
    isRulerToolEnabled: false,
    isCompareToolEnabled: false,
    idOfGeometryWithSavingPreview: undefined,
    tourPlayerState: undefined
};

const name = 'projectView';

export const setAnimatedInspectorVisibility = createAsyncThunk(
    `${name}/setAnimatedInspectorVisibility`,
    (newState: boolean, { dispatch }) => {
        dispatch(setInspectorAnimation(true));
        setTimeout(() => {
            dispatch(setInspectorAnimation(false));
        }, INSPECTOR_PANEL_ANIMATION_DURATION);
        dispatch(setInspectorVisibility(newState));
    }
);

const setterReducer = createSetterReducer<ProjectViewState>();
const projectViewSlice = createSlice({
    name,
    initialState,
    reducers: {
        setTerrainViewMode: setterReducer('terrainViewMode'),
        setBaseImageryProvider: setterReducer('baseImageryProvider'),
        setQuality3DMode: setterReducer('quality3DMode'),
        setTilesetLoading(state, { payload }: PayloadAction<{ tilesetId: string; isLoading: boolean }>) {
            state.tilesetLoading[payload.tilesetId] = payload.isLoading;
        },
        setMapSettingsVisibility: setterReducer('areMapSettingsOpen'),
        setInspectorVisibility: setterReducer('isInspectorExpanded'),
        setInspectorAnimation: setterReducer('isInspectorAnimated'),
        setGalleryVisibility(
            state,
            { payload }: PayloadAction<{ isVisible: boolean; mode?: ProjectViewState['galleryVisibility']['mode'] }>
        ) {
            state.galleryVisibility = { isVisible: payload.isVisible, mode: payload.mode || 'cameras' };
            if (!payload.isVisible && state.selectedCamera) state.selectedCamera = undefined;
        },
        setIsViewerLoaded: setterReducer('isCesiumLoaded'),
        setTerrainProviderLoaded: setterReducer('isTerrainProviderLoaded'),
        setViewerError: setterReducer('hasCesiumError'),
        setSidebarVisibility: setterReducer('isSidebarExpanded'),
        setFullscreen: setterReducer('isFullscreen'),
        setLimitBoxMovingFaceId: setterReducer('limitBoxMovingFaceId'),
        setIdOfGeometryWithSavingPreview: setterReducer('idOfGeometryWithSavingPreview'),
        setTourPlayerState: setterReducer('tourPlayerState'),
        resetProjectView: (state, { payload }: PayloadAction<{ doNotResetCompareTool?: boolean }>) => {
            return {
                ...initialState,
                baseImageryProvider: state.baseImageryProvider,
                terrainViewMode: state.terrainViewMode,
                isCompareToolEnabled: payload.doNotResetCompareTool
                    ? state.isCompareToolEnabled
                    : initialState.isCompareToolEnabled
            };
        },
        setSelectedCamera(
            state,
            {
                payload
            }: PayloadAction<(ProjectViewState['selectedCamera'] & { selectInWorkspace?: boolean }) | undefined>
        ) {
            state.selectedCamera = payload?.artifactUid
                ? {
                      artifactUid: payload.artifactUid,
                      uid: payload.uid
                  }
                : undefined;
        },
        setCamerasLoading(state, { payload }: PayloadAction<{ artifactId: string; hasLoaded: boolean }>) {
            state.camerasLoading[payload.artifactId] = payload.hasLoaded;
        },
        setSelectedGeometryType(state, { payload }: PayloadAction<GeometryTypes | undefined>) {
            state.selectedGeometryType = payload;
            if (payload) state.isCamerasInspectionEnabled = false;
        },
        setCurrentlyDrawingShapeId: setterReducer('currentlyDrawingShapeId'),
        setFloatingPointCoordinates: setterReducer('floatingPointCoordinates'),
        setCurrentlyEditingShapeId: setterReducer('currentlyEditingShapeId'),
        setCurrentlyHoveringShapeId: setterReducer('currentlyHoveringShapeId'),
        setRulerToolEnabled: setterReducer('isRulerToolEnabled'),
        setUndoableOperation: setterReducer('undoableOperation'),
        setHoveredGeometryNodeId: setterReducer('hoveredGeometryNodeId'),
        setSelectedGeometryNodeId: setterReducer('selectedGeometryNodeId'),
        setCamerasInspectionEnabled(state, { payload }: PayloadAction<boolean>) {
            state.isCamerasInspectionEnabled = payload;
            // Reset camera inspection parameters on disabling inspection
            if (!payload) {
                state.cameraFilteringPoint = null;
                state.filteredCamerasPointProjection = [];
            } else state.selectedGeometryType = undefined;
        },
        setCameraFilteringPoint: setterReducer('cameraFilteringPoint'),
        setFilteredCamerasPointProjection: setterReducer('filteredCamerasPointProjection'),
        setPresentationSetupEnabled: setterReducer('isPresentationSetupEnabled'),
        disableAllControls(state) {
            disableProjectControls(state);
        },
        setElevationProfileExpanded: setterReducer('isElevationProfileExpanded'),
        resetProjectViewMode(state) {
            const initialControlsState = _.pick(initialState, [
                'isTerrainProviderLoaded',
                'areMapSettingsOpen',
                'isPresentationSetupEnabled'
            ]);
            return { ...state, ...initialControlsState };
        },
        setCompareToolEnabled(state, { payload }: PayloadAction<boolean>) {
            if (payload) {
                disableProjectControls(state);
                state.isInspectorExpanded = false;
            }
            state.isCompareToolEnabled = payload;
        },
        setTilesetBoundingSphere(state, { payload }: PayloadAction<{ tilesetId: string; bs: Cesium.BoundingSphere }>) {
            state.tilesetsBoundingSpheres[payload.tilesetId] = payload.bs;
        },
        setTilesetTransform(state, { payload }: PayloadAction<{ tilesetId: string; transform: Cesium.Matrix4 }>) {
            state.tilesetsTransforms[payload.tilesetId] = payload.transform;
        }
    },
    extraReducers: builder => {
        builder.addCase(setSelectedObject.fulfilled, (state, { meta }) => {
            if (meta.arg?.type) {
                state.isRulerToolEnabled = false;
                if (meta.arg.artifactId !== state.currentlyDrawingShapeId) {
                    state.currentlyDrawingShapeId = undefined;
                }
            }
        });
    }
});

function disableProjectControls(state: WritableDraft<ProjectViewState>) {
    state.selectedGeometryType = undefined;
    state.isRulerToolEnabled = false;
    state.currentlyDrawingShapeId = undefined;
}

export const {
    setTilesetLoading,
    setRulerToolEnabled,
    setBaseImageryProvider,
    setQuality3DMode,
    setTilesetBoundingSphere,
    setTerrainViewMode,
    setGalleryVisibility,
    setInspectorVisibility,
    setInspectorAnimation,
    setIsViewerLoaded,
    setTerrainProviderLoaded,
    setMapSettingsVisibility,
    setSidebarVisibility,
    setViewerError,
    setFullscreen,
    resetProjectView,
    setTourPlayerState,
    setSelectedCamera,
    setIdOfGeometryWithSavingPreview,
    setCamerasLoading,
    setCamerasInspectionEnabled,
    setTilesetTransform,
    setSelectedGeometryType,
    setCurrentlyDrawingShapeId,
    setFilteredCamerasPointProjection,
    setFloatingPointCoordinates,
    setCurrentlyEditingShapeId,
    setCurrentlyHoveringShapeId,
    setUndoableOperation,
    setCameraFilteringPoint,
    disableAllControls,
    setPresentationSetupEnabled,
    setElevationProfileExpanded,
    resetProjectViewMode,
    setCompareToolEnabled,
    setHoveredGeometryNodeId,
    setSelectedGeometryNodeId,
    setLimitBoxMovingFaceId
} = projectViewSlice.actions;

export const projectViewActions = projectViewSlice.actions;

export default projectViewSlice.reducer;

export const selectIsAnyToolEnabled = createSelector(
    (state: ApplicationState) => state.projectView.isCamerasInspectionEnabled,
    (state: ApplicationState) => state.projectView.isCompareToolEnabled,
    (state: ApplicationState) => state.projectView.isPresentationSetupEnabled,
    (isCamerasInspectionEnabled, isCompareToolEnabled, isPresentationSetupEnabled) =>
        isCamerasInspectionEnabled || isCompareToolEnabled || isPresentationSetupEnabled
);

export const selectIsOverlayDarkened = createSelector(
    (state: ApplicationState) => state.projectView.isSidebarExpanded,
    (state: ApplicationState) => state.projectView.isInspectorExpanded,
    (isSidebarExpanded, isInspectorExpanded) =>
        (isSidebarExpanded || isInspectorExpanded) && window.innerWidth < DARK_OVERLAY_BREAKPOINT
);
