import { Trans, useTranslation } from 'react-i18next';
import { ProjectType, SharedProjectInfo } from '../../../../generated/cloud-frontend-api';
import convertSize from '../../../../lib/convertSize';
import Modal, { ModalProps } from '../../modal/Modal';
import ModalActions from '../../modal/ModalActions';
import ModalBody from '../../modal/ModalBody';
import ModalHead from '../../modal/ModalHead';

type Props = ModalProps & { project: SharedProjectInfo; onRemove(): void };

export default function RemoveSharedProjectModal({ isOpen, onRemove, project, setIsOpen }: Props) {
    const { t } = useTranslation('modals');

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <ModalHead setIsOpen={setIsOpen}>{t('removeProjectModal.head')}</ModalHead>
            <ModalBody>
                <div>
                    <Trans
                        ns='modals'
                        i18nKey={
                            project.type === ProjectType.SITE
                                ? 'removeProjectModal.body_site'
                                : 'removeProjectModal.body'
                        }
                        components={{ p: <p />, s: <strong className='overflow' /> }}
                        values={{ projectName: project.name, projectSize: convertSize(project.size || 0) }}
                    />
                </div>
                <ModalActions>
                    <button
                        type='button'
                        className='btn-cancel'
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        {t('removeProjectModal.action1')}
                    </button>
                    <button
                        className='btn-alert'
                        type='button'
                        onClick={() => {
                            onRemove();
                            setIsOpen(false);
                        }}
                    >
                        {t('removeProjectModal.action2')}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}
