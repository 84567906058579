import { useEffect, useRef } from 'react';
import { WidthHeight } from '../sharedConstants';
import scaleImage from '../lib/scaleImage';

export function useImageSizeScaledToContainerSize(containerSize: WidthHeight, sourceSize: WidthHeight) {
    const width = useRef<number | null>(null);
    const height = useRef<number | null>(null);
    const top = useRef<number | null>(null);
    const left = useRef<number | null>(null);

    useEffect(() => {
        if (containerSize.width && containerSize.height && sourceSize.width && sourceSize.height) {
            if (width.current) return;

            const scaledSize = scaleImage(
                sourceSize.width,
                sourceSize.height,
                containerSize.width,
                containerSize.height
            );

            width.current = scaledSize.width;
            height.current = scaledSize.height;
            top.current = scaledSize.top;
            left.current = scaledSize.left;
        }
    }, [containerSize, sourceSize.width, sourceSize.height]);

    return { left: left.current, top: top.current, width: width.current, height: height.current };
}
