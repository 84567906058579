/* tslint:disable */
/* eslint-disable */
/**
 * cloud-frontend-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SharedProjectsPage } from '../model';
/**
 * SharedApi - axios parameter creator
 * @export
 */
export const SharedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the list of the shared with user projects
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {Array<'name'>} [sort] Order of projects: \&quot;+\&quot; - ascending, \&quot;-\&quot; - descending
         * @param {string} [pattern] String to filter projects by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedProjects: async (limit?: number, page?: number, sort?: Array<'name'>, pattern?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shared/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pattern !== undefined) {
                localVarQueryParameter['pattern'] = pattern;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SharedApi - functional programming interface
 * @export
 */
export const SharedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SharedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns the list of the shared with user projects
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {Array<'name'>} [sort] Order of projects: \&quot;+\&quot; - ascending, \&quot;-\&quot; - descending
         * @param {string} [pattern] String to filter projects by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSharedProjects(limit?: number, page?: number, sort?: Array<'name'>, pattern?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SharedProjectsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSharedProjects(limit, page, sort, pattern, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SharedApi - factory interface
 * @export
 */
export const SharedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SharedApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns the list of the shared with user projects
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {Array<'name'>} [sort] Order of projects: \&quot;+\&quot; - ascending, \&quot;-\&quot; - descending
         * @param {string} [pattern] String to filter projects by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedProjects(limit?: number, page?: number, sort?: Array<'name'>, pattern?: string, options?: any): AxiosPromise<SharedProjectsPage> {
            return localVarFp.getSharedProjects(limit, page, sort, pattern, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SharedApi - object-oriented interface
 * @export
 * @class SharedApi
 * @extends {BaseAPI}
 */
export class SharedApi extends BaseAPI {
    /**
     * 
     * @summary Returns the list of the shared with user projects
     * @param {number} [limit] Maximum number of the items to be returned
     * @param {number} [page] Page number to be returned
     * @param {Array<'name'>} [sort] Order of projects: \&quot;+\&quot; - ascending, \&quot;-\&quot; - descending
     * @param {string} [pattern] String to filter projects by name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SharedApi
     */
    public getSharedProjects(limit?: number, page?: number, sort?: Array<'name'>, pattern?: string, options?: AxiosRequestConfig) {
        return SharedApiFp(this.configuration).getSharedProjects(limit, page, sort, pattern, options).then((request) => request(this.axios, this.basePath));
    }
}
