/* 
    (!) Для правильной обработки переносов, строки с их наличием необходимо передавать в формате шаблонной строки: {`строка1\nстрока2`}
*/
import React, { ReactElement } from 'react';
import Tippy, { TippyProps } from '@tippyjs/react/headless';

interface Props extends TippyProps {
    tooltipText: string;
    children: ReactElement;
}

const TippyTooltip: React.FC<Props> = ({ tooltipText, children, ...props }): JSX.Element => {
    return (
        <Tippy
            render={attrs => (
                <div className='tippy-tooltip' tabIndex={-1} {...attrs}>
                    <span>{tooltipText}</span>
                </div>
            )}
            delay={[700, 100]}
            offset={[0, 8]}
            touch={false}
            {...props}
        >
            {children}
        </Tippy>
    );
};

export default TippyTooltip;
