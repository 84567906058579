import { useTranslation } from 'react-i18next';
import InspectCloseControl from './elements/InspectCloseControl';

export default function InspectToolBreadcrumbs() {
    const { t } = useTranslation(['projectView']);

    return (
        <>
            <div className='tool-title'>
                <div className='tool-icon'>
                    <i className='icon icon-inspector' />
                </div>
                <span className='tool-text'>{t('projectView:inspectionSidebar.imagesInspector.title_inspection')}</span>
            </div>
            <InspectCloseControl />
        </>
    );
}
