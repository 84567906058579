import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import isProjectBelongsUser from '../../../lib/isProjectBelongsUser';
import { Routes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { TemporaryLayer, ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { selectCameras } from '../../../store/slices/cameras';
import { selectGeometries } from '../../../store/slices/geometries';
import { selectReportsPropertiesBlockExpansion, updateStructureInfo } from '../../../store/slices/structure';
import InspectionReportsDropdown from '../inspections-reports-dropdown/InspectionsReportsDropdown';
import PropertiesBlockHead from '../../ProjectView/geometry-properties/elements/PropertiesBlockHead';

export default function InspectionReport() {
    const { t } = useTranslation(['common', 'projectView']);
    const dispatch: AppDispatch = useDispatch();
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state)) as TemporaryLayer;
    const projectInfo = useSelector(state => state.project.projectInfo);
    const geometries = useSelector(selectGeometries);
    const isShared = !!useRouteMatch({ path: Routes.SHARED_PROJECT_VIEW, exact: true });
    const accessKey = useSelector(state => state.sharing.access.accessKey);
    const units = useSelector(state => state.coordinateSystems.units);
    const coordinateSystem = useSelector(store => store.coordinateSystems.currentCrs);
    const embedCode = useSelector(state => state.sharing.embedCode);
    const allCameras = useSelector(state => selectCameras(state));
    const accessInfo = useSelector(state => state.sharing.accessInfo);
    const reportsBlockExpanded = useSelector(state =>
        selectReportsPropertiesBlockExpansion(state, (selectedObjectInfo as TemporaryLayer)?.id)
    );

    const isOwnedProject = isProjectBelongsUser(accessInfo, projectInfo);

    function generatePDF() {
        const projectName = projectInfo.name!;
        const companyName = ''; // TODO: правка по бэку, пробросить название компании
        const layerName = (selectedObjectInfo as TemporaryLayer).name;
        const geometryIds = (selectedObjectInfo as TemporaryLayer).geometries;
        const issues = geometryIds.map(id => geometries[id]!);
        let previewUrl = projectInfo.preview ? `${projectInfo.preview}` : undefined;
        if (previewUrl && isShared) previewUrl = previewUrl.concat(`&access=${accessKey}`);
        if (previewUrl && embedCode) previewUrl = previewUrl.concat(`&embed=${embedCode}`);

        import('./InspectionPDFGenerator').then(({ default: inspectionPDFGenerator }) => {
            inspectionPDFGenerator(
                projectName,
                companyName,
                layerName,
                issues,
                'window',
                projectInfo.id,
                allCameras,
                isOwnedProject,
                units,
                coordinateSystem,
                previewUrl,
                embedCode ? embedCode : undefined,
                accessKey ? accessKey : undefined
            );
        });
    }

    function updateReportsBlockExpansion(expanded: boolean) {
        dispatch(
            updateStructureInfo({
                projectId: projectInfo.id!,
                structureUid: (selectedObjectInfo as TemporaryLayer).id,
                type: ProjectStructureObjectTypes.LAYER,
                propName: 'reportsBlockExpanded',
                propValue: expanded ? String(true) : String(false)
            })
        );
    }

    return (
        <div className='inspector-properties'>
            <PropertiesBlockHead
                isBlockExpanded={reportsBlockExpanded}
                title={t('projectView:inspectionSidebar.reports.title')}
                onClick={() => {
                    updateReportsBlockExpansion(!reportsBlockExpanded);
                }}
            />
            {reportsBlockExpanded && (
                <div className='inspector-report'>
                    <div className='report-title-wrapper'>
                        <span className='report-title' onClick={generatePDF}>
                            {t('common:inspectionReport')}
                        </span>
                    </div>
                    <div className='report-info'>
                        <span>{t('projectView:inspectionSidebar.reports.onDemand')}</span>
                        <span className='report-divider' />
                        <span data-testid='reportFormats'>.PDF, .CSV</span>
                    </div>
                    <InspectionReportsDropdown layer={selectedObjectInfo as TemporaryLayer} placement='bottom' />
                </div>
            )}
        </div>
    );
}
