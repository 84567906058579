import { useLayoutEffect, useRef, useState } from 'react';
import exifr from 'exifr';

type Props = {
    url: string;
    onLoad?(): void;
    onError?(): void;
    parseOrientation?: boolean;
};

export function useURLImage({ url, onError, onLoad, parseOrientation }: Props) {
    const imageRef = useRef<HTMLImageElement | undefined>();
    const orientation = useRef(0);
    const onLoadMemoized = useRef(onLoad || (() => {}));
    const onErrorMemoized = useRef(onError || (() => {}));

    const [_, setStateToken] = useState(0);

    const oldUrl = useRef(url);
    if (oldUrl.current !== url) {
        imageRef.current = undefined;
        oldUrl.current = url;
    }

    useLayoutEffect(() => {
        if (!url) return;
        const img = document.createElement('img');

        async function onload() {
            imageRef.current = img;
            if (parseOrientation) orientation.current = (await exifr.orientation(img)) || 1;
            setStateToken(Math.random());
            onLoadMemoized.current();
        }

        function onerror() {
            imageRef.current = undefined;
            setStateToken(Math.random());
            onErrorMemoized.current();
        }

        img.addEventListener('load', onload);
        img.addEventListener('error', onerror);
        img.crossOrigin = 'anonymous';
        img.src = url;

        return () => {
            img.removeEventListener('load', onload);
            img.removeEventListener('error', onerror);
        };
    }, [url, parseOrientation]);

    return { image: imageRef.current, orientation: orientation.current };
}
