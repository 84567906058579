import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
    stubType: 'viewer' | 'swiper';
};

export default function PreviewImageStub({ stubType }: Props) {
    const { t } = useTranslation(['projectView']);

    return (
        <div className='image-stub' data-testid='stubImage'>
            <i
                className={classNames('icon icon-no_preview', {
                    'icon-viewer': stubType === 'viewer',
                    'icon-swiper': stubType === 'swiper'
                })}
            />
            <div className='stub-text'>{t('projectView:inspectionSidebar.imagesInspector.stub')}</div>
        </div>
    );
}
