import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ProjectInfo } from '../../../../generated/cloud-frontend-api';
import { AppDispatch } from '../../../../store';
import { ReactComponent as SvgAbort } from '../../../../svg/dropdown_actions/abort.svg';
import AbortProjectProcessingModal from '../../modals/abort-project-processing-modal/AbortProjectProcessingModal';
import TippyDropdownItemWithIcon from '../../tippy-dropdown/TippyDropdownItemWithIcon';

type Props = {
    project: ProjectInfo;
    disabled?: boolean;
};

export default function AbortProcessingAction({ project, disabled }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projects', 'modals']);
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <TippyDropdownItemWithIcon
                disabled={disabled}
                icon={<SvgAbort />}
                text={t('projects:projectActions.abort')}
                onClick={() => {
                    setIsModalOpen(true);
                }}
            />

            {isModalOpen && (
                <AbortProjectProcessingModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} projectInfo={project} />
            )}
        </>
    );
}
