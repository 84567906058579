import { useTranslation } from 'react-i18next';
import { ReactComponent as SvgCamera } from '../../../svg/presentation/camera.svg';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import { AppDispatch, useSelector } from '../../../store';
import { useDispatch } from 'react-redux';
import { setPovSaveRequested } from '../../../store/slices/presentation';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { TemporaryGeometry } from '../../../store/helpers/interfaces';

export function SaveViewpointPovControl() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const selectedSlide = useSelector(state => selectedObjectInfoSelector(state)) as TemporaryGeometry;

    return (
        <TippyTooltip tooltipText={t('inspectionSidebar.controls.tooltipSavePov')}>
            <div
                className='control'
                data-testid={'saveSlidePovControl'}
                onClick={e => {
                    dispatch(setPovSaveRequested(selectedSlide.id));
                }}
            >
                <SvgCamera />
            </div>
        </TippyTooltip>
    );
}
