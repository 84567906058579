import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Cesium from 'cesium';
import produce from 'immer';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { globalTerrainOption } from '../../components/ProjectView/elevation-profile/ElevationProfileTool';
import PolygonMeasures, {
    CalculateVolumeResult
} from '../../components/ProjectView/geometry-properties/measures/PolygonMeasures';
import isProjectBelongsUser from '../../lib/isProjectBelongsUser';
import { GeometryTypes } from '../../sharedConstants';
import { isPolygonGeometry, TemporaryGeometry, VolumeBaseLevel, VolumeBasePlane } from '../helpers/interfaces';
import { AppDispatch, ApplicationState } from '../index';
import { calculationAdded, volumeDeleted } from '../sharedActions/index';
import { updateGeometryContent } from './geometries';
import { updateGeometry } from './geometryLayers';

interface VolumeCalculationArgs {
    basePlane: VolumeBasePlane;
    baseLevel: VolumeBaseLevel;
    surfaceId: string;
    polygon: TemporaryGeometry<GeometryTypes.POLYGON>;
}

export interface VolumeCalculationInfo {
    id: string;
    status: 'new' | 'pending' | 'fulfilled' | 'aborted' | 'rejected';
    args: VolumeCalculationArgs;
}

interface VolumeState {
    calculations: VolumeCalculationInfo[];
}

const initialState: VolumeState = {
    calculations: []
};

const name = 'volume';

interface CalculateVolumeArgs {
    id: string;
    scene: Cesium.Scene;
    globalTerrainProvider: Cesium.TerrainProvider;
    owned: boolean;
}
export const calculateVolume = createAsyncThunk<
    CalculateVolumeResult | null,
    CalculateVolumeArgs,
    { dispatch: AppDispatch; state: ApplicationState; rejectValue: 'aborted' | 'error' }
>(
    `${name}/calculate`,
    async ({ id, scene, globalTerrainProvider, owned }, { dispatch, getState, rejectWithValue, signal }) => {
        const {
            args: { baseLevel, basePlane, polygon, surfaceId }
        } = getState().volume.calculations.find(c => c.id === id)!;
        const projectUid = getState().project.projectInfo.id!;
        const projectInfo = getState().project.projectInfo;
        const { access, accessInfo, embedCode } = getState().sharing;
        const isOwnedProject = isProjectBelongsUser(accessInfo, projectInfo);
        const layer = getState().project.structure.temporaryLayers.find(l => l.geometries.includes(polygon.id))!;

        dispatch(
            updateGeometryContent({
                geoJson: produce(polygon.content, draft => {
                    draft.properties.ac_volume_surface = surfaceId;
                    draft.properties.ac_volume_above_cubic_meters = undefined;
                    draft.properties.ac_volume_below_cubic_meters = undefined;
                    draft.properties.ac_volume_total_cubic_meters = undefined;
                    draft.properties.ac_volume_base_level_meters =
                        basePlane === 'bestFit' || basePlane === 'customLevel' ? baseLevel : undefined;
                    draft.properties.ac_volume_base_plane = basePlane;
                }),
                id: polygon.id
            })
        );
        try {
            const polygonMeasures = new PolygonMeasures(polygon.content.geometry.coordinates);

            let terrainProvider: Cesium.TerrainProvider;
            if (surfaceId === globalTerrainOption().id) {
                terrainProvider = globalTerrainProvider;
            } else {
                const surface = getState().datasets.datasets.find(d => d.datasetUid === surfaceId);
                let url = `/api/projects/${projectUid}/datasets/${surface?.datasetUid}/tilesets/${surface?.visualData?.dataUid}`;
                if (!isOwnedProject) url = url.concat(`?access=${access.accessKey}`);
                if (embedCode) url = url.concat(`?embed=${embedCode}`);
                terrainProvider = await Cesium.CesiumTerrainProvider.fromUrl(url, { requestVertexNormals: true });
            }
            const volume = await polygonMeasures.volume(basePlane, baseLevel, terrainProvider);

            if (signal.aborted) throw new Error('aborted');

            const actualArgs = getState().volume.calculations.find(c => c.id === polygon.id)!.args;
            const hasStartedAnotherCalculation = checkIfAnotherCalculationIsRunning(
                { baseLevel, basePlane, surfaceId },
                actualArgs
            );

            if (hasStartedAnotherCalculation) return null;

            const polygonContent = getState().geometries.entities[polygon.id]?.content!;
            const updatedPolygonContent = produce(polygonContent, draft => {
                draft.properties.ac_volume_above_cubic_meters = parseFloat(volume.above.toFixed(3));
                draft.properties.ac_volume_below_cubic_meters = parseFloat(volume.below.toFixed(3));
                draft.properties.ac_volume_total_cubic_meters = parseFloat(volume.total.toFixed(3));
                draft.properties.ac_volume_base_level_meters = volume.calculatedBaseLevel;
                draft.properties.ac_volume_base_plane = basePlane;
            });
            dispatch(updateGeometryContent({ geoJson: updatedPolygonContent, id: polygon.id }));
            if (owned)
                dispatch(
                    updateGeometry({ geoJson: updatedPolygonContent, id: polygon.id, projectUid, layerUid: layer.id })
                );

            return volume;
        } catch (e) {
            console.error(e);
            if (e === 'aborted') return rejectWithValue('aborted');
            return rejectWithValue('error');
        }
    },
    {
        condition({ id }, { getState }) {
            const calculation = getState().volume.calculations.find(c => c.id === id);
            if (!calculation || calculation?.status === 'aborted') return false;
        }
    }
);

export const invalidateCalculation = createAsyncThunk<
    VolumeCalculationInfo | null,
    { id: string; owned: boolean },
    { state: ApplicationState; dispatch: AppDispatch }
>(
    `${name}/invalidate`,
    ({ id, owned }, { getState, dispatch }) => {
        const geometry = getState().geometries.entities[id];

        if (geometry && isPolygonGeometry(geometry)) {
            const { ac_volume_base_plane, ac_volume_base_level_meters, ac_volume_surface } =
                geometry.content.properties;
            const calculation: VolumeCalculationInfo = {
                args: {
                    surfaceId: ac_volume_surface!,
                    polygon: geometry,
                    baseLevel:
                        ac_volume_base_plane === 'bestFit' || ac_volume_base_plane === 'customLevel'
                            ? (ac_volume_base_level_meters as number)
                            : undefined!,
                    basePlane: ac_volume_base_plane as VolumeBasePlane
                },
                id: geometry.id,
                status: 'aborted' as const
            };
            const updatedPolygonContent = produce(geometry.content, draft => {
                draft.properties.ac_volume_base_level_meters = calculation.args.baseLevel;
                draft.properties.ac_volume_base_plane = calculation.args.basePlane;
                draft.properties.ac_volume_above_cubic_meters = undefined;
                draft.properties.ac_volume_below_cubic_meters = undefined;
                draft.properties.ac_volume_total_cubic_meters = undefined;
            });
            const projectUid = getState().project.projectInfo.id!;
            const layerUid =
                getState().project.structure.temporaryLayers.find(l => l.geometries.includes(geometry.id))?.id || '';

            dispatch(updateGeometryContent({ geoJson: updatedPolygonContent, id: geometry.id }));
            if (owned)
                dispatch(updateGeometry({ geoJson: updatedPolygonContent, id: geometry.id, projectUid, layerUid }));
            return calculation;
        }

        return null;
    },
    {
        condition({ id }, { getState }) {
            const calculation = getState().volume.calculations.find(c => c.id === id);
            if (calculation && calculation.status === 'aborted') return false;
        }
    }
);

const slice = createSlice({
    name,
    initialState,
    reducers: {
        calculationAborted(state, { payload }: PayloadAction<string>) {
            const index = state.calculations.findIndex(c => c.id === payload);
            if (index !== -1) state.calculations[index].status = 'aborted';
        }
    },
    extraReducers: builder => {
        builder
            .addCase(calculateVolume.pending, (state, { meta }) => {
                const calculation = state.calculations.find(c => c.id === meta.arg.id);
                if (calculation) calculation.status = 'pending';
            })
            .addCase(calculateVolume.fulfilled, (state, { meta, payload }) => {
                const calculation = state.calculations.find(c => c.id === meta.arg.id);
                if (!_.isNull(payload) && calculation) calculation.status = 'fulfilled';
            })
            .addCase(calculateVolume.rejected, (state, { meta, error }) => {
                const calculation = state.calculations.find(c => c.id === meta.arg.id);
                if (calculation) {
                    calculation.status = error?.name === 'AbortError' ? 'aborted' : 'rejected';
                }
            })
            .addCase(invalidateCalculation.fulfilled, (state, { payload }) => {
                if (payload) {
                    const index = state.calculations.findIndex(c => c.id === payload.id);
                    if (index !== -1) state.calculations[index] = payload;
                    else state.calculations.push(payload);
                }
            })
            .addCase(calculationAdded, (state, { payload }) => {
                // Handle restarting the same calculation
                const index = state.calculations.findIndex(c => c.id === payload.id);
                if (index !== -1) state.calculations[index] = payload;
                else state.calculations.push(payload);
            })
            .addCase(volumeDeleted, (state, { payload }) => {
                const index = state.calculations.findIndex(c => c.id === payload.id);
                if (index !== -1) state.calculations.splice(index, 1);
            });
    }
});

export default slice.reducer;

export const { calculationAborted } = slice.actions;

export const selectNewCalculations = createSelector(
    (state: ApplicationState) => state.volume.calculations,
    calculations => calculations.filter(c => c.status === 'new')
);

function checkIfAnotherCalculationIsRunning(
    args: Omit<VolumeCalculationArgs, 'polygon'>,
    actualArgs: Omit<VolumeCalculationArgs, 'polygon'>
) {
    return (
        args.basePlane !== actualArgs.basePlane ||
        args.baseLevel !== actualArgs.baseLevel ||
        args.surfaceId !== actualArgs.surfaceId
    );
}
