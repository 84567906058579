import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Routes } from '../../../../sharedConstants';
import { useTranslation } from 'react-i18next';
import { SiteRouteParams } from '../../../../pages/site/Site';
import { ReactComponent as SvgView } from '../../../../svg/navigation/navbar_map.svg';
import { ReactComponent as SvgProjects } from '../../../../svg/navigation/navbar_projects.svg';

export default function ViewNavigationSwitch() {
    const { t } = useTranslation(['projectView']);
    const history = useHistory();
    const { id: siteId, hash: accessKey } = useParams<SiteRouteParams>();

    const isSiteRoute = !!useRouteMatch({ path: [Routes.SITE], exact: true });
    const isSiteViewRoute = !!useRouteMatch({ path: [Routes.SITE_VIEW], exact: true });
    const isSharedSiteRoute = !!useRouteMatch({ path: [Routes.SHARED_SITE], exact: true });
    const isSharedSiteViewRoute = !!useRouteMatch({ path: [Routes.SHARED_SITE_VIEW], exact: true });

    if (!isSiteRoute && !isSiteViewRoute && !isSharedSiteRoute && !isSharedSiteViewRoute) return null;

    return (
        <div
            className='infobar-control'
            data-testid='navControl'
            onClick={e => {
                let path = '';
                if (isSiteRoute) path = generatePath(Routes.SITE_VIEW, { id: siteId });
                if (isSiteViewRoute) path = generatePath(Routes.SITE, { id: siteId });
                if (isSharedSiteRoute) path = generatePath(Routes.SHARED_SITE_VIEW, { hash: accessKey });
                if (isSharedSiteViewRoute) path = generatePath(Routes.SHARED_SITE, { hash: accessKey });

                history.push(path);
            }}
        >
            <div className='control-icon'>
                {(isSiteViewRoute || isSharedSiteViewRoute) && <SvgProjects />}
                {(isSiteRoute || isSharedSiteRoute) && <SvgView />}
            </div>
            <span className='control-text' data-testid='navText'>
                {(isSiteViewRoute || isSharedSiteViewRoute) && t('projectView:head.projects')}
                {(isSiteRoute || isSharedSiteRoute) && t('projectView:head.view')}
            </span>
        </div>
    );
}
