import * as Cesium from 'cesium';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CesiumMovementEvent, ScreenSpaceEvent } from 'resium';
import useDrawingCancel from '../../../hooks/useDrawingCancel';
import { GeometryTypes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { GeoJson, ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { createLoadingSelector } from '../../../store/selectors/createLoadingSelector';
import { setSelectedObject } from '../../../store/sharedActions';
import { addCoordinatesToGeometry } from '../../../store/slices/geometries';
import { beginLayer } from '../../../store/slices/geometryLayers';
import { setCurrentlyDrawingShapeId } from '../../../store/slices/projectView';
import useDrawing from './useDrawing';

const selectIsLayerCreationPending = createLoadingSelector([beginLayer.typePrefix]);

type Props = {
    lastPickedCartographicDegrees: number[];
};

export default function DrawPolyline({ lastPickedCartographicDegrees }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const currentlyDrawingShapeId = useSelector(state => state.projectView.currentlyDrawingShapeId);
    const temporaryLayers = useSelector(state => state.project.structure.temporaryLayers);
    const isLayerCreationPending = useSelector(state => selectIsLayerCreationPending(state));

    const cancelDrawing = useDrawingCancel();

    const {
        enableDrawingGeometryVisibilityIfInvisible,
        addGeometry,
        getGeometryColors,
        enableLayerVisibilityIfInvisible,
        getLayerUid,
        hasPickedSavingPointWhileDrawing
    } = useDrawing();

    async function draw(coordinates: number[]) {
        if (!currentlyDrawingShapeId) {
            if (isLayerCreationPending) return;

            const layer = await getLayerUid();
            enableLayerVisibilityIfInvisible(layer.id);

            const geoJson: GeoJson = {
                type: 'Feature',
                geometry: { type: GeometryTypes.POLYLINE, coordinates: [coordinates] },
                properties: {
                    ac_name: t('defaultNames.polyline'),
                    ac_visibility: true,
                    ...getGeometryColors(layer, GeometryTypes.POLYLINE)
                }
            };
            const id = addGeometry(geoJson, layer.id);

            dispatch(setCurrentlyDrawingShapeId(id));
            await dispatch(
                setSelectedObject({ artifactId: id, type: ProjectStructureObjectTypes.GEOMETRY, needToScroll: true })
            );
        } else {
            // ADD COORDINATES TO EXISTING ONE
            const layer = temporaryLayers.find(l => l.geometries.includes(currentlyDrawingShapeId));
            enableLayerVisibilityIfInvisible(layer!.id);
            enableDrawingGeometryVisibilityIfInvisible(layer!.id);
            dispatch(addCoordinatesToGeometry({ id: currentlyDrawingShapeId, coordinates }));
        }
    }

    return (
        <ScreenSpaceEvent
            type={Cesium.ScreenSpaceEventType.LEFT_CLICK}
            action={(e: CesiumMovementEvent) => {
                // Have to call scene.pickPosition only before drillPick. Otherwise pickPosition won't work

                const coordinates = lastPickedCartographicDegrees;
                if (hasPickedSavingPointWhileDrawing(e.position!)) {
                    cancelDrawing(true);
                    return;
                }
                if (coordinates) draw(coordinates);
            }}
        />
    );
}
