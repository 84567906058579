import { memo } from 'react';
import TippyTooltip from '../../../Elements/tippy-tooltip/TippyTooltip';
import { ReactComponent as SvgLink } from '../../../../svg/project_view/link.svg';
import { useTranslation } from 'react-i18next';
import { isURL } from 'validator';

type Props = {
    onDelete(): void;
    deletable?: boolean;
    formHasFocus: boolean;
    value: string;
};

function AdditionalPropertyControl({ deletable, formHasFocus, onDelete, value }: Props) {
    const { t } = useTranslation('projectView');
    const isUrl = isURL(value, { require_protocol: true });

    return (
        <>
            {formHasFocus ? (
                <>
                    {deletable ? (
                        <TippyTooltip tooltipText={t('inspectionSidebar.geometryProperties.tooltipDelete')}>
                            <div
                                data-testid={'deleteProperty'}
                                className='prop-action'
                                onMouseDown={e => {
                                    onDelete();
                                }}
                            >
                                <i className='icon icon-minus' data-testid={'deletePropertyIcon'} />
                            </div>
                        </TippyTooltip>
                    ) : (
                        <div className='prop-action empty' data-testid={'emptyControl'} />
                    )}
                </>
            ) : (
                <>
                    {isUrl ? (
                        <TippyTooltip tooltipText={t('inspectionSidebar.geometryProperties.tooltipOpen')}>
                            <div
                                data-testid={'linkControl'}
                                className={'prop-action'}
                                onClick={e => {
                                    window.open(value, '_blank');
                                }}
                            >
                                <SvgLink />
                            </div>
                        </TippyTooltip>
                    ) : (
                        <div className='prop-action empty' data-testid={'emptyControl'} />
                    )}
                </>
            )}
        </>
    );
}

export default memo(AdditionalPropertyControl);
