import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CamerasArtifact } from '../../../generated/cloud-frontend-api';
import { AppDispatch, useSelector } from '../../../store';
import { ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import {
    expandedPropertiesBlockNames,
    selectPropertiesBlockExpansion,
    updateStructureInfo
} from '../../../store/slices/structure';
import { isCameraAligned, isCameraMasterOrNotMultispectral } from '../../ProjectView/frustums/Frustums';
import CopyPropertiesControl from '../../ProjectView/geometry-properties/elements/CopyPropertiesControl';
import PropertiesBlockHead from '../../ProjectView/geometry-properties/elements/PropertiesBlockHead';
import GeneralProperty from '../GeneralProperty';

const expandedPropertyName: (typeof expandedPropertiesBlockNames)[number] = 'generalBlockExpanded';

export default function ImagesGeneralProperties() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const camerasInfo = useSelector(state => selectedObjectInfoSelector(state)) as CamerasArtifact;
    const allCameras = useSelector(state => state.cameras);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const isBlockExpanded = useSelector(state =>
        selectPropertiesBlockExpansion(state, camerasInfo.artifactUid!, expandedPropertyName)
    );
    const cameras = camerasInfo?.artifactUid ? allCameras[camerasInfo.artifactUid] || [] : [];
    const displayableCameras = cameras.filter(c => isCameraAligned(c) && isCameraMasterOrNotMultispectral(c));
    const value = `${displayableCameras.length}/${cameras.length} ${t('inspectionSidebar.cameraProperties.aligned')}`;
    const textForCopying = `${t('inspectionSidebar.imagesInspector.title_cameras')}\t${value}`;

    return (
        <div className='inspector-properties'>
            <PropertiesBlockHead
                isBlockExpanded={isBlockExpanded}
                title={t('projectView:inspectionSidebar.titleGeneral')}
                onClick={e => {
                    dispatch(
                        updateStructureInfo({
                            projectId: projectInfo.id!,
                            structureUid: camerasInfo.artifactUid!,
                            type: ProjectStructureObjectTypes.CAMERAS,
                            propName: expandedPropertyName,
                            propValue: isBlockExpanded ? String(false) : String(true)
                        })
                    );
                }}
            >
                <CopyPropertiesControl clipboardText={textForCopying} />
            </PropertiesBlockHead>
            {isBlockExpanded && (
                <div className='properties-list'>
                    <GeneralProperty label={t('inspectionSidebar.imagesInspector.title_cameras')} value={value} />
                </div>
            )}
        </div>
    );
}
