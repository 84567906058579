import * as Cesium from 'cesium';
import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ScreenSpaceCameraController, ScreenSpaceEvent, ScreenSpaceEventHandler, useCesium } from 'resium';
import { useCompareToolSplitPositionUpdate } from '../../../hooks/useCompareToolSplitPositionUpdate';
import useDrawingCancel from '../../../hooks/useDrawingCancel';
import { AppDispatch, useSelector } from '../../../store';
import { setMapSettingsVisibility } from '../../../store/slices/projectView';
import CamerasInspection from '../cameras-inspection/CamerasInspection';
import GeometryDrawing from '../geometry-drawing/GeometryDrawing';

function AppViewerEvents() {
    const dispatch: AppDispatch = useDispatch();
    const { viewer } = useCesium();
    const currentlyEditingShapeId = useSelector(state => state.projectView.currentlyEditingShapeId);
    const limitBoxMovingFaceId = useSelector(state => state.projectView.limitBoxMovingFaceId);

    const cancelDrawing = useDrawingCancel();

    useCompareToolSplitPositionUpdate();

    useEffect(() => {
        viewer?.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
    }, [viewer]);

    return (
        <>
            <ScreenSpaceCameraController
                enableCollisionDetection={false}
                maximumZoomDistance={20000000}
                inertiaSpin={0}
                inertiaTranslate={0}
                inertiaZoom={0}
                zoomEventTypes={[
                    Cesium.CameraEventType.MIDDLE_DRAG,
                    Cesium.CameraEventType.WHEEL,
                    Cesium.CameraEventType.PINCH
                ]}
                tiltEventTypes={[
                    Cesium.CameraEventType.RIGHT_DRAG,
                    Cesium.CameraEventType.PINCH,
                    { eventType: Cesium.CameraEventType.LEFT_DRAG, modifier: Cesium.KeyboardEventModifier.CTRL }
                ]}
                enableInputs={!currentlyEditingShapeId && !limitBoxMovingFaceId}
            />

            <ScreenSpaceEventHandler>
                <ScreenSpaceEvent
                    type={Cesium.ScreenSpaceEventType.RIGHT_CLICK}
                    action={e => {
                        dispatch(setMapSettingsVisibility(false));
                        cancelDrawing();
                    }}
                />

                <GeometryDrawing />
                <CamerasInspection />

                <ScreenSpaceEvent
                    type={Cesium.ScreenSpaceEventType.PINCH_START}
                    action={e => {
                        dispatch(setMapSettingsVisibility(false));
                    }}
                />
                <ScreenSpaceEvent
                    type={Cesium.ScreenSpaceEventType.MIDDLE_DOWN}
                    action={e => {
                        dispatch(setMapSettingsVisibility(false));
                    }}
                />
            </ScreenSpaceEventHandler>
        </>
    );
}

export default memo(AppViewerEvents);
