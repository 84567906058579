import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ProjectInfo } from '../../../../generated/cloud-frontend-api';
import convertSize from '../../../../lib/convertSize';
import getAreYouSureText from '../../../../lib/getAreYouSureText';
import { AppDispatch } from '../../../../store';
import { cancelProjectProcessing } from '../../../../store/slices/projectActions';
import Modal, { ModalProps } from '../../modal/Modal';
import ModalActions from '../../modal/ModalActions';
import ModalBody from '../../modal/ModalBody';
import ModalHead from '../../modal/ModalHead';

type Props = ModalProps & {
    projectInfo: ProjectInfo;
};

export default function CancelProcessingModal({ isOpen, setIsOpen, projectInfo }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('modals');
    const publicationSize = convertSize(projectInfo?.parts?.publication?.size);
    const areYouSureText = getAreYouSureText('abort', projectInfo.name!, projectInfo.status!, publicationSize);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <ModalHead setIsOpen={setIsOpen}>{t('abortProcessingModal.head')}</ModalHead>
            <ModalBody>
                <div>{areYouSureText}</div>
                <ModalActions>
                    <button
                        className='btn-cancel'
                        type='button'
                        onClick={event => {
                            setIsOpen(false);
                        }}
                    >
                        {t('abortProcessingModal.cancel')}
                    </button>

                    <button
                        className='btn-alert'
                        type='button'
                        onClick={event => {
                            dispatch(cancelProjectProcessing(projectInfo.pipeline?.pipelineUid || ''));
                            setIsOpen(false);
                        }}
                    >
                        {t('abortProcessingModal.abort')}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}
