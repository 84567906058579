import { useEffect, useRef } from 'react';

export function useImageOrientationAndRotation(orientation: number, width: number, height: number) {
    const rotation = useRef(0);
    const offset = useRef({ x: 0, y: 0 });

    useEffect(() => {
        if (orientation !== 0 && width && height) {
            rotation.current = getRotationDegreesForExifOrientation(orientation);
            offset.current = getOffsetXYForExifOrientation(orientation, width, height);
        }
    }, [orientation, width, height]);

    return { rotation: rotation.current, offset: offset.current };
}

/** Exif Orientation values:
    1 = Horizontal (normal) 
    2 = Mirror horizontal
    3 = Rotate 180
    4 = Mirror vertical
    5 = Mirror horizontal and rotate 270 CW
    6 = Rotate 90 CW
    7 = Mirror horizontal and rotate 90 CW
    8 = Rotate 270 CW */
function getRotationDegreesForExifOrientation(orientation: number): number {
    switch (orientation) {
        case 3:
        case 4:
            return 180;

        case 5:
        case 6:
            return -90;

        case 7:
        case 8:
            return 90;

        case 1:
        case 2:
        default:
            return 0;
    }
}

function getOffsetXYForExifOrientation(orientation: number, w: number, h: number): { x: number; y: number } {
    switch (orientation) {
        case 3:
        case 4:
            return { x: w, y: h };

        case 5:
        case 6:
            return { x: 0, y: h };

        case 7:
        case 8:
            return { x: w, y: 0 };
        case 1:
        case 2:
        default:
            return { x: 0, y: 0 };
    }
}
