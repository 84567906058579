import { SourceType } from '../generated/cloud-frontend-api/model';
import getArtifactDisplayType from './getArtifactDisplayType';
import { ExtendedDatasetInfo } from '../store/slices/datasetfilesUpload';
import i18n from '../i18n/config';

export default function (dataset: ExtendedDatasetInfo): string {
    if (dataset?.name) {
        return dataset?.name;
    } else {
        switch (dataset?.sourceData?.type!) {
            case SourceType.TIE_POINTS:
                return i18n.t('glossary:defaultSourceNames.tiePoints');
            case SourceType.RASTER_MAP:
                return i18n.t('glossary:defaultSourceNames.rasterMap');
            default:
                return getArtifactDisplayType(dataset?.sourceData?.type!);
        }
    }
}
