import { useContext } from 'react';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { CircularProgressbar } from 'react-circular-progressbar';

type Props = {
    value: number;
};

const VIEW_TRAIL_STROKE = '#E7EAEF';
const EMBED_TRAIL_STROKE = '#4E4E4E';

export default function UploadProgressBar({ value }: Props) {
    const { isInEmbedView } = useContext(ProjectViewAccessContext);

    return (
        <CircularProgressbar
            value={value}
            styles={{
                root: { width: '100%', height: '100%' },
                path: {
                    stroke: '#66DC6A',
                    transitionDuration: '0.5'
                },
                trail: {
                    stroke: isInEmbedView ? EMBED_TRAIL_STROKE : VIEW_TRAIL_STROKE
                }
            }}
            strokeWidth={20}
        />
    );
}
