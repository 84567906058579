/* tslint:disable */
/* eslint-disable */
/**
 * vector-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreateGeometryUploadRequest } from '../model';
// @ts-ignore
import { CreateGeometryUploadResponse } from '../model';
// @ts-ignore
import { Geometry } from '../model';
// @ts-ignore
import { GeometryInfo } from '../model';
// @ts-ignore
import { GeometryProperties } from '../model';
// @ts-ignore
import { NewGeometry } from '../model';
/**
 * GeometryApi - axios parameter creator
 * @export
 */
export const GeometryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Geometry document upload completion
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {string} documentUid UUID of document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeGeometryDocumentUpload: async (projectUid: string, layerUid: string, geometryUid: string, documentUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('completeGeometryDocumentUpload', 'projectUid', projectUid)
            // verify required parameter 'layerUid' is not null or undefined
            assertParamExists('completeGeometryDocumentUpload', 'layerUid', layerUid)
            // verify required parameter 'geometryUid' is not null or undefined
            assertParamExists('completeGeometryDocumentUpload', 'geometryUid', geometryUid)
            // verify required parameter 'documentUid' is not null or undefined
            assertParamExists('completeGeometryDocumentUpload', 'documentUid', documentUid)
            const localVarPath = `/projects/{projectUid}/layers/{layerUid}/geometries/{geometryUid}/documents/{documentUid}/upload/complete`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"layerUid"}}`, encodeURIComponent(String(layerUid)))
                .replace(`{${"geometryUid"}}`, encodeURIComponent(String(geometryUid)))
                .replace(`{${"documentUid"}}`, encodeURIComponent(String(documentUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a geometries in layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {Array<NewGeometry>} newGeometry New geometries which need to be added
         * @param {string} [after] Uid of geometry after which insert new one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeometries: async (projectUid: string, layerUid: string, newGeometry: Array<NewGeometry>, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('createGeometries', 'projectUid', projectUid)
            // verify required parameter 'layerUid' is not null or undefined
            assertParamExists('createGeometries', 'layerUid', layerUid)
            // verify required parameter 'newGeometry' is not null or undefined
            assertParamExists('createGeometries', 'newGeometry', newGeometry)
            const localVarPath = `/projects/{projectUid}/layers/{layerUid}/geometries`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"layerUid"}}`, encodeURIComponent(String(layerUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/stream+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newGeometry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a geometry in layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {NewGeometry} newGeometry New geometry that needs to be added
         * @param {string} [after] Uid of geometry after which insert new one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeometry: async (projectUid: string, layerUid: string, newGeometry: NewGeometry, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('createGeometry', 'projectUid', projectUid)
            // verify required parameter 'layerUid' is not null or undefined
            assertParamExists('createGeometry', 'layerUid', layerUid)
            // verify required parameter 'newGeometry' is not null or undefined
            assertParamExists('createGeometry', 'newGeometry', newGeometry)
            const localVarPath = `/projects/{projectUid}/layers/{layerUid}/geometry`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"layerUid"}}`, encodeURIComponent(String(layerUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newGeometry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create geometry document upload
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {CreateGeometryUploadRequest} [createGeometryUploadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeometryDocumentUpload: async (projectUid: string, layerUid: string, geometryUid: string, createGeometryUploadRequest?: CreateGeometryUploadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('createGeometryDocumentUpload', 'projectUid', projectUid)
            // verify required parameter 'layerUid' is not null or undefined
            assertParamExists('createGeometryDocumentUpload', 'layerUid', layerUid)
            // verify required parameter 'geometryUid' is not null or undefined
            assertParamExists('createGeometryDocumentUpload', 'geometryUid', geometryUid)
            const localVarPath = `/projects/{projectUid}/layers/{layerUid}/geometries/{geometryUid}/documents/upload/create`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"layerUid"}}`, encodeURIComponent(String(layerUid)))
                .replace(`{${"geometryUid"}}`, encodeURIComponent(String(geometryUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGeometryUploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a geometry object from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGeometry: async (projectUid: string, layerUid: string, geometryUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('deleteGeometry', 'projectUid', projectUid)
            // verify required parameter 'layerUid' is not null or undefined
            assertParamExists('deleteGeometry', 'layerUid', layerUid)
            // verify required parameter 'geometryUid' is not null or undefined
            assertParamExists('deleteGeometry', 'geometryUid', geometryUid)
            const localVarPath = `/projects/{projectUid}/layers/{layerUid}/geometries/{geometryUid}`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"layerUid"}}`, encodeURIComponent(String(layerUid)))
                .replace(`{${"geometryUid"}}`, encodeURIComponent(String(geometryUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a document from a geometry
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {string} documentUid UUID of document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGeometryDocument: async (projectUid: string, layerUid: string, geometryUid: string, documentUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('deleteGeometryDocument', 'projectUid', projectUid)
            // verify required parameter 'layerUid' is not null or undefined
            assertParamExists('deleteGeometryDocument', 'layerUid', layerUid)
            // verify required parameter 'geometryUid' is not null or undefined
            assertParamExists('deleteGeometryDocument', 'geometryUid', geometryUid)
            // verify required parameter 'documentUid' is not null or undefined
            assertParamExists('deleteGeometryDocument', 'documentUid', documentUid)
            const localVarPath = `/projects/{projectUid}/layers/{layerUid}/geometries/{geometryUid}/documents/{documentUid}`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"layerUid"}}`, encodeURIComponent(String(layerUid)))
                .replace(`{${"geometryUid"}}`, encodeURIComponent(String(geometryUid)))
                .replace(`{${"documentUid"}}`, encodeURIComponent(String(documentUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a list of geometries from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeometries: async (projectUid: string, layerUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getGeometries', 'projectUid', projectUid)
            // verify required parameter 'layerUid' is not null or undefined
            assertParamExists('getGeometries', 'layerUid', layerUid)
            const localVarPath = `/projects/{projectUid}/layers/{layerUid}/geometries`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"layerUid"}}`, encodeURIComponent(String(layerUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a geometry object from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeometry: async (projectUid: string, layerUid: string, geometryUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getGeometry', 'projectUid', projectUid)
            // verify required parameter 'layerUid' is not null or undefined
            assertParamExists('getGeometry', 'layerUid', layerUid)
            // verify required parameter 'geometryUid' is not null or undefined
            assertParamExists('getGeometry', 'geometryUid', geometryUid)
            const localVarPath = `/projects/{projectUid}/layers/{layerUid}/geometries/{geometryUid}`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"layerUid"}}`, encodeURIComponent(String(layerUid)))
                .replace(`{${"geometryUid"}}`, encodeURIComponent(String(geometryUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a geometry properties from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeometryInfo: async (projectUid: string, layerUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getGeometryInfo', 'projectUid', projectUid)
            // verify required parameter 'layerUid' is not null or undefined
            assertParamExists('getGeometryInfo', 'layerUid', layerUid)
            const localVarPath = `/projects/{projectUid}/layers/{layerUid}/geometries/info`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"layerUid"}}`, encodeURIComponent(String(layerUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move geometry inside layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {string} [after] Uid of geometry after which insert new one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveGeometry: async (projectUid: string, layerUid: string, geometryUid: string, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('moveGeometry', 'projectUid', projectUid)
            // verify required parameter 'layerUid' is not null or undefined
            assertParamExists('moveGeometry', 'layerUid', layerUid)
            // verify required parameter 'geometryUid' is not null or undefined
            assertParamExists('moveGeometry', 'geometryUid', geometryUid)
            const localVarPath = `/projects/{projectUid}/layers/{layerUid}/geometries/{geometryUid}/move`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"layerUid"}}`, encodeURIComponent(String(layerUid)))
                .replace(`{${"geometryUid"}}`, encodeURIComponent(String(geometryUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a geometry object from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {NewGeometry} newGeometry New version of geometry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGeometry: async (projectUid: string, layerUid: string, geometryUid: string, newGeometry: NewGeometry, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('putGeometry', 'projectUid', projectUid)
            // verify required parameter 'layerUid' is not null or undefined
            assertParamExists('putGeometry', 'layerUid', layerUid)
            // verify required parameter 'geometryUid' is not null or undefined
            assertParamExists('putGeometry', 'geometryUid', geometryUid)
            // verify required parameter 'newGeometry' is not null or undefined
            assertParamExists('putGeometry', 'newGeometry', newGeometry)
            const localVarPath = `/projects/{projectUid}/layers/{layerUid}/geometries/{geometryUid}`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"layerUid"}}`, encodeURIComponent(String(layerUid)))
                .replace(`{${"geometryUid"}}`, encodeURIComponent(String(geometryUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newGeometry, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Restore a geometry object from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreGeometry: async (projectUid: string, layerUid: string, geometryUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('restoreGeometry', 'projectUid', projectUid)
            // verify required parameter 'layerUid' is not null or undefined
            assertParamExists('restoreGeometry', 'layerUid', layerUid)
            // verify required parameter 'geometryUid' is not null or undefined
            assertParamExists('restoreGeometry', 'geometryUid', geometryUid)
            const localVarPath = `/projects/{projectUid}/layers/{layerUid}/geometries/{geometryUid}/restore`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"layerUid"}}`, encodeURIComponent(String(layerUid)))
                .replace(`{${"geometryUid"}}`, encodeURIComponent(String(geometryUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Write properties for all geometries in the layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {GeometryProperties} geometryProperties Properties of geometries to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeGeometriesProperties: async (projectUid: string, layerUid: string, geometryProperties: GeometryProperties, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('writeGeometriesProperties', 'projectUid', projectUid)
            // verify required parameter 'layerUid' is not null or undefined
            assertParamExists('writeGeometriesProperties', 'layerUid', layerUid)
            // verify required parameter 'geometryProperties' is not null or undefined
            assertParamExists('writeGeometriesProperties', 'geometryProperties', geometryProperties)
            const localVarPath = `/projects/{projectUid}/layers/{layerUid}/geometries`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"layerUid"}}`, encodeURIComponent(String(layerUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(geometryProperties, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeometryApi - functional programming interface
 * @export
 */
export const GeometryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeometryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Geometry document upload completion
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {string} documentUid UUID of document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async completeGeometryDocumentUpload(projectUid: string, layerUid: string, geometryUid: string, documentUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.completeGeometryDocumentUpload(projectUid, layerUid, geometryUid, documentUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a geometries in layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {Array<NewGeometry>} newGeometry New geometries which need to be added
         * @param {string} [after] Uid of geometry after which insert new one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGeometries(projectUid: string, layerUid: string, newGeometry: Array<NewGeometry>, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGeometries(projectUid, layerUid, newGeometry, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a geometry in layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {NewGeometry} newGeometry New geometry that needs to be added
         * @param {string} [after] Uid of geometry after which insert new one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGeometry(projectUid: string, layerUid: string, newGeometry: NewGeometry, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGeometry(projectUid, layerUid, newGeometry, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create geometry document upload
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {CreateGeometryUploadRequest} [createGeometryUploadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGeometryDocumentUpload(projectUid: string, layerUid: string, geometryUid: string, createGeometryUploadRequest?: CreateGeometryUploadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateGeometryUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGeometryDocumentUpload(projectUid, layerUid, geometryUid, createGeometryUploadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a geometry object from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGeometry(projectUid: string, layerUid: string, geometryUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGeometry(projectUid, layerUid, geometryUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a document from a geometry
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {string} documentUid UUID of document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGeometryDocument(projectUid: string, layerUid: string, geometryUid: string, documentUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGeometryDocument(projectUid, layerUid, geometryUid, documentUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a list of geometries from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeometries(projectUid: string, layerUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Geometry>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeometries(projectUid, layerUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a geometry object from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeometry(projectUid: string, layerUid: string, geometryUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Geometry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeometry(projectUid, layerUid, geometryUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a geometry properties from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeometryInfo(projectUid: string, layerUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeometryInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeometryInfo(projectUid, layerUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Move geometry inside layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {string} [after] Uid of geometry after which insert new one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveGeometry(projectUid: string, layerUid: string, geometryUid: string, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveGeometry(projectUid, layerUid, geometryUid, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a geometry object from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {NewGeometry} newGeometry New version of geometry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putGeometry(projectUid: string, layerUid: string, geometryUid: string, newGeometry: NewGeometry, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putGeometry(projectUid, layerUid, geometryUid, newGeometry, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Restore a geometry object from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreGeometry(projectUid: string, layerUid: string, geometryUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreGeometry(projectUid, layerUid, geometryUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Write properties for all geometries in the layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {GeometryProperties} geometryProperties Properties of geometries to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async writeGeometriesProperties(projectUid: string, layerUid: string, geometryProperties: GeometryProperties, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.writeGeometriesProperties(projectUid, layerUid, geometryProperties, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeometryApi - factory interface
 * @export
 */
export const GeometryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeometryApiFp(configuration)
    return {
        /**
         * 
         * @summary Geometry document upload completion
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {string} documentUid UUID of document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeGeometryDocumentUpload(projectUid: string, layerUid: string, geometryUid: string, documentUid: string, options?: any): AxiosPromise<void> {
            return localVarFp.completeGeometryDocumentUpload(projectUid, layerUid, geometryUid, documentUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a geometries in layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {Array<NewGeometry>} newGeometry New geometries which need to be added
         * @param {string} [after] Uid of geometry after which insert new one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeometries(projectUid: string, layerUid: string, newGeometry: Array<NewGeometry>, after?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.createGeometries(projectUid, layerUid, newGeometry, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a geometry in layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {NewGeometry} newGeometry New geometry that needs to be added
         * @param {string} [after] Uid of geometry after which insert new one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeometry(projectUid: string, layerUid: string, newGeometry: NewGeometry, after?: string, options?: any): AxiosPromise<string> {
            return localVarFp.createGeometry(projectUid, layerUid, newGeometry, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create geometry document upload
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {CreateGeometryUploadRequest} [createGeometryUploadRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGeometryDocumentUpload(projectUid: string, layerUid: string, geometryUid: string, createGeometryUploadRequest?: CreateGeometryUploadRequest, options?: any): AxiosPromise<CreateGeometryUploadResponse> {
            return localVarFp.createGeometryDocumentUpload(projectUid, layerUid, geometryUid, createGeometryUploadRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a geometry object from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGeometry(projectUid: string, layerUid: string, geometryUid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGeometry(projectUid, layerUid, geometryUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a document from a geometry
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {string} documentUid UUID of document
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGeometryDocument(projectUid: string, layerUid: string, geometryUid: string, documentUid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGeometryDocument(projectUid, layerUid, geometryUid, documentUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a list of geometries from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeometries(projectUid: string, layerUid: string, options?: any): AxiosPromise<Array<Geometry>> {
            return localVarFp.getGeometries(projectUid, layerUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a geometry object from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeometry(projectUid: string, layerUid: string, geometryUid: string, options?: any): AxiosPromise<Geometry> {
            return localVarFp.getGeometry(projectUid, layerUid, geometryUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a geometry properties from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeometryInfo(projectUid: string, layerUid: string, options?: any): AxiosPromise<Array<GeometryInfo>> {
            return localVarFp.getGeometryInfo(projectUid, layerUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move geometry inside layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {string} [after] Uid of geometry after which insert new one
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveGeometry(projectUid: string, layerUid: string, geometryUid: string, after?: string, options?: any): AxiosPromise<void> {
            return localVarFp.moveGeometry(projectUid, layerUid, geometryUid, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a geometry object from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {NewGeometry} newGeometry New version of geometry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGeometry(projectUid: string, layerUid: string, geometryUid: string, newGeometry: NewGeometry, options?: any): AxiosPromise<void> {
            return localVarFp.putGeometry(projectUid, layerUid, geometryUid, newGeometry, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Restore a geometry object from a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {string} geometryUid Uid of geometry
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreGeometry(projectUid: string, layerUid: string, geometryUid: string, options?: any): AxiosPromise<void> {
            return localVarFp.restoreGeometry(projectUid, layerUid, geometryUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Write properties for all geometries in the layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {GeometryProperties} geometryProperties Properties of geometries to write
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeGeometriesProperties(projectUid: string, layerUid: string, geometryProperties: GeometryProperties, options?: any): AxiosPromise<void> {
            return localVarFp.writeGeometriesProperties(projectUid, layerUid, geometryProperties, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeometryApi - object-oriented interface
 * @export
 * @class GeometryApi
 * @extends {BaseAPI}
 */
export class GeometryApi extends BaseAPI {
    /**
     * 
     * @summary Geometry document upload completion
     * @param {string} projectUid UUID of project
     * @param {string} layerUid Uid of layer
     * @param {string} geometryUid Uid of geometry
     * @param {string} documentUid UUID of document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryApi
     */
    public completeGeometryDocumentUpload(projectUid: string, layerUid: string, geometryUid: string, documentUid: string, options?: AxiosRequestConfig) {
        return GeometryApiFp(this.configuration).completeGeometryDocumentUpload(projectUid, layerUid, geometryUid, documentUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a geometries in layer
     * @param {string} projectUid UUID of project
     * @param {string} layerUid Uid of layer
     * @param {Array<NewGeometry>} newGeometry New geometries which need to be added
     * @param {string} [after] Uid of geometry after which insert new one
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryApi
     */
    public createGeometries(projectUid: string, layerUid: string, newGeometry: Array<NewGeometry>, after?: string, options?: AxiosRequestConfig) {
        return GeometryApiFp(this.configuration).createGeometries(projectUid, layerUid, newGeometry, after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a geometry in layer
     * @param {string} projectUid UUID of project
     * @param {string} layerUid Uid of layer
     * @param {NewGeometry} newGeometry New geometry that needs to be added
     * @param {string} [after] Uid of geometry after which insert new one
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryApi
     */
    public createGeometry(projectUid: string, layerUid: string, newGeometry: NewGeometry, after?: string, options?: AxiosRequestConfig) {
        return GeometryApiFp(this.configuration).createGeometry(projectUid, layerUid, newGeometry, after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create geometry document upload
     * @param {string} projectUid UUID of project
     * @param {string} layerUid Uid of layer
     * @param {string} geometryUid Uid of geometry
     * @param {CreateGeometryUploadRequest} [createGeometryUploadRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryApi
     */
    public createGeometryDocumentUpload(projectUid: string, layerUid: string, geometryUid: string, createGeometryUploadRequest?: CreateGeometryUploadRequest, options?: AxiosRequestConfig) {
        return GeometryApiFp(this.configuration).createGeometryDocumentUpload(projectUid, layerUid, geometryUid, createGeometryUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a geometry object from a layer
     * @param {string} projectUid UUID of project
     * @param {string} layerUid Uid of layer
     * @param {string} geometryUid Uid of geometry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryApi
     */
    public deleteGeometry(projectUid: string, layerUid: string, geometryUid: string, options?: AxiosRequestConfig) {
        return GeometryApiFp(this.configuration).deleteGeometry(projectUid, layerUid, geometryUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a document from a geometry
     * @param {string} projectUid UUID of project
     * @param {string} layerUid Uid of layer
     * @param {string} geometryUid Uid of geometry
     * @param {string} documentUid UUID of document
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryApi
     */
    public deleteGeometryDocument(projectUid: string, layerUid: string, geometryUid: string, documentUid: string, options?: AxiosRequestConfig) {
        return GeometryApiFp(this.configuration).deleteGeometryDocument(projectUid, layerUid, geometryUid, documentUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a list of geometries from a layer
     * @param {string} projectUid UUID of project
     * @param {string} layerUid Uid of layer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryApi
     */
    public getGeometries(projectUid: string, layerUid: string, options?: AxiosRequestConfig) {
        return GeometryApiFp(this.configuration).getGeometries(projectUid, layerUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a geometry object from a layer
     * @param {string} projectUid UUID of project
     * @param {string} layerUid Uid of layer
     * @param {string} geometryUid Uid of geometry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryApi
     */
    public getGeometry(projectUid: string, layerUid: string, geometryUid: string, options?: AxiosRequestConfig) {
        return GeometryApiFp(this.configuration).getGeometry(projectUid, layerUid, geometryUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a geometry properties from a layer
     * @param {string} projectUid UUID of project
     * @param {string} layerUid Uid of layer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryApi
     */
    public getGeometryInfo(projectUid: string, layerUid: string, options?: AxiosRequestConfig) {
        return GeometryApiFp(this.configuration).getGeometryInfo(projectUid, layerUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Move geometry inside layer
     * @param {string} projectUid UUID of project
     * @param {string} layerUid Uid of layer
     * @param {string} geometryUid Uid of geometry
     * @param {string} [after] Uid of geometry after which insert new one
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryApi
     */
    public moveGeometry(projectUid: string, layerUid: string, geometryUid: string, after?: string, options?: AxiosRequestConfig) {
        return GeometryApiFp(this.configuration).moveGeometry(projectUid, layerUid, geometryUid, after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a geometry object from a layer
     * @param {string} projectUid UUID of project
     * @param {string} layerUid Uid of layer
     * @param {string} geometryUid Uid of geometry
     * @param {NewGeometry} newGeometry New version of geometry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryApi
     */
    public putGeometry(projectUid: string, layerUid: string, geometryUid: string, newGeometry: NewGeometry, options?: AxiosRequestConfig) {
        return GeometryApiFp(this.configuration).putGeometry(projectUid, layerUid, geometryUid, newGeometry, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Restore a geometry object from a layer
     * @param {string} projectUid UUID of project
     * @param {string} layerUid Uid of layer
     * @param {string} geometryUid Uid of geometry
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryApi
     */
    public restoreGeometry(projectUid: string, layerUid: string, geometryUid: string, options?: AxiosRequestConfig) {
        return GeometryApiFp(this.configuration).restoreGeometry(projectUid, layerUid, geometryUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Write properties for all geometries in the layer
     * @param {string} projectUid UUID of project
     * @param {string} layerUid Uid of layer
     * @param {GeometryProperties} geometryProperties Properties of geometries to write
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeometryApi
     */
    public writeGeometriesProperties(projectUid: string, layerUid: string, geometryProperties: GeometryProperties, options?: AxiosRequestConfig) {
        return GeometryApiFp(this.configuration).writeGeometriesProperties(projectUid, layerUid, geometryProperties, options).then((request) => request(this.axios, this.basePath));
    }
}
