import { useSelector } from '../../../store';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import StructureItemIcon from '../../project-structure-sidebar/structure-item/StructureItemIcon';
import InspectorElementControls from '../inspector-element-controls/InspectorElementControls';
import useCanRenameStructureItem from '../../../hooks/useCanRenameStructureItem';
import useGetNodeName from '../../../hooks/useGetNodeName';
import SelectedObjectNameInput from '../SelectedObjectNameInput';

type Props = {
    viewOnly: boolean;
};

export default function InspectorTitle({ viewOnly }: Props) {
    const selectedObject = useSelector(state => state.project.selectedObject);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));

    const canRename = useCanRenameStructureItem(selectedObject.artifactId || '', selectedObject?.type || '');

    const name = useGetNodeName({
        selectedObjectType: selectedObject.type,
        selectedObjectInfo
    });

    return (
        <section className='inspector-title'>
            <StructureItemIcon
                className='title-icon'
                structureItemType={selectedObject?.type!}
                structureItemId={selectedObject?.artifactId!}
            />

            <div className='inspector-element-info'>
                {canRename ? <SelectedObjectNameInput /> : <span className='title-text'>{name}</span>}
            </div>

            <InspectorElementControls viewOnly={viewOnly} />
        </section>
    );
}
