import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { AppDispatch } from '../../../store';
import {
    setProcessingTime,
    setStorage,
    setPlan,
    setHasPendingOrders,
    getAccountHomeDataNoSkeleton
} from '../../../store/slices/accountResources';
import { LS_ACCOUNT_IN_PROGRESS } from '../../../sharedConstants';
import { setAccountUpdateInProgress } from '../../../store/slices/globalFlags';

export default function Socket() {
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        let webSocket = connect();
        let closingClientSide = false;

        return () => {
            closingClientSide = true;
            webSocket.close();
        };

        function connect() {
            let url = `wss://${window.location.host}/ws/account/events`;
            if (import.meta.env.DEV) url = `ws://${window.location.host}/ws/account/events`; // To avoid wss errors in local dev

            const socket = new WebSocket(url);

            const pingIntervalId = setInterval(() => socket.send('ping'), 30000);

            socket.onopen = e => {
                if (import.meta.env.DEV) console.log('socket is open');
            };

            socket.onmessage = event => {
                const obj = JSON.parse(event.data);
                if (import.meta.env.DEV) console.log(obj);

                if (obj?.eventType === 'STORAGE_UPDATED') {
                    dispatch(setStorage(obj.storage));
                    checkAccountProcessingStatus();
                }
                if (obj?.eventType === 'PROCESSING_TIME_UPDATED') {
                    dispatch(setProcessingTime(obj.processingTime));
                    checkAccountProcessingStatus();
                }
                if (obj?.eventType === 'PLAN_UPDATED') {
                    dispatch(setPlan(obj.plan));
                    dispatch(setAccountUpdateInProgress(false));
                }
                if (obj?.eventType === 'ORDER_PENDING') {
                    const hasPendingOrders = (obj.numberOfRemainingPendingOrders as number) > 0;
                    dispatch(setHasPendingOrders(hasPendingOrders));

                    if (obj.newOrderStatus === 'PENDING') {
                        dispatch(setAccountUpdateInProgress(false));
                    }
                }
            };

            socket.onclose = event => {
                if (import.meta.env.DEV) console.log('socket is closed');
                clearInterval(pingIntervalId);
                if (!event.wasClean && !closingClientSide) {
                    if (import.meta.env.DEV) console.log('socket is reconnected');
                    webSocket = connect();
                }
            };

            socket.onerror = err => {
                if (import.meta.env.NODE_ENV === 'development') console.log('socket encountered error: ', err);
                socket.close();
            };

            return socket;
        }

        function checkAccountProcessingStatus(): void {
            dispatch(getAccountHomeDataNoSkeleton())
                .then(unwrapResult)
                .then(({ resources, plan }) => {
                    const previousSnapshot = localStorage.getItem(LS_ACCOUNT_IN_PROGRESS);
                    if (previousSnapshot) {
                        const currentSnapshot = JSON.stringify({ resources, plan });
                        dispatch(setAccountUpdateInProgress(currentSnapshot === previousSnapshot));
                    }
                });
        }
    }, [dispatch]);

    return null;
}
