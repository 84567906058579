import { ReactComponent as SvgSpinnerMedium } from '../../../svg/general/spinner_medium.svg';
import { ReactComponent as SvgClose } from '../../../svg/general/x-icon.svg';
import { ReactComponent as SvgDenied } from '../../../svg/tool_profile/denied.svg';
import { AppDispatch } from '../../../store/index';
import { useDispatch } from 'react-redux';
import { calculationAborted, ElevationProfileCalculationInfo } from '../../../store/slices/elevationProfiles';
import { useTranslation } from 'react-i18next';

type Props = {
    type: 'inProgress' | 'error' | 'interrupted';
    elevationProfile: ElevationProfileCalculationInfo;
};

export default function ElevationProfileGraphOverlay({ type, elevationProfile }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');

    return (
        <div className='profile-overlay'>
            {type === 'inProgress' && (
                <>
                    <div className='profile-spinner-wrapper'>
                        <div className='profile-spinner'>
                            <SvgSpinnerMedium className='profile-spinner-svg' />
                        </div>
                        <div
                            className='profile-icon profile-icon-spinner hasHover'
                            onClick={() => {
                                dispatch(calculationAborted(elevationProfile.id));
                            }}
                        >
                            <SvgClose />
                        </div>
                    </div>
                    <span className='profile-text text-progress'>{t('elevationProfile.overlay_inProgress')}</span>
                </>
            )}
            {type === 'error' && (
                <>
                    <div className='profile-icon'>
                        <SvgDenied />
                    </div>
                    <span className='profile-text text-error'>
                        {t('elevationProfile.overlay_unableToSampleHeights')}
                    </span>
                </>
            )}
            {type === 'interrupted' && (
                <>
                    <div className='profile-icon'>
                        <SvgDenied />
                    </div>
                    <span className='profile-text text-error'>{t('elevationProfile.overlay_interrupted')}</span>
                </>
            )}
        </div>
    );
}
