import Cesium from 'cesium';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useContext, useMemo } from 'react';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { useSelector } from '../../../store';
import { selectSelectedPresentationSlide } from '../../../store/slices/presentation';
import classNames from 'classnames';

import styles from './ViewpointDescription.module.scss';
import { makeSelectLayerByGeometryId } from '../../../store/slices/geometryLayers';
import { selectGroups, selectObjectVisibility, isObjectVisible } from '../../../store/slices/structure';

export default function ViewpointDescription() {
    const selectedSlide = useSelector(state => selectSelectedPresentationSlide(state));
    const { isInEmbedView } = useContext(ProjectViewAccessContext);
    const title = selectedSlide?.content.properties.ac_name;
    const description =
        selectedSlide?.content.properties.ac_description ??
        (selectedSlide?.content.properties.description as string | undefined);
    const isPresentationSetupEnabled = useSelector(state => state.projectView.isPresentationSetupEnabled);
    const { owned } = useContext(ProjectViewAccessContext);
    const structures = useSelector(state => state.structure.structures);

    const selectLayerByGeometryId = useMemo(makeSelectLayerByGeometryId, []);
    const layer = useSelector(state => selectLayerByGeometryId(state, selectedSlide?.id ?? ''));
    const groups = useSelector(state => selectGroups(state));
    const layerVisible = useSelector(state => selectObjectVisibility(state, layer?.id!));

    const structureInfo = structures.find(s => s.uid === layer?.id);
    const group = groups.find(g => g.uid === structureInfo?.parentUid);
    const belongsToGroup = Boolean(group);
    const groupVisible = belongsToGroup ? isObjectVisible(structures, group?.uid!) : true;

    const visible = groupVisible && layerVisible && selectedSlide?.content.properties.ac_visibility;

    const isHidden = (!title && !description) || (owned && !isPresentationSetupEnabled);

    if (isHidden || !selectedSlide || !visible) return null;

    return (
        <div
            className={classNames(styles.slideDescription, isInEmbedView && styles.themeEmbed)}
            id='slideDescription'
            style={{ top: `${0}px`, left: `${0}px` }}
        >
            <OverlayScrollbarsComponent
                className={classNames(styles.descriptionWrapper, 'os-custom-scrollbar')}
                key={selectedSlide.id}
            >
                <div className={styles.descriptionTitle}>{title}</div>
                {description && <div className={styles.descriptionContent}>{description}</div>}
            </OverlayScrollbarsComponent>
        </div>
    );
}

export function setSlideDescriptionCoordinates(coords?: Cesium.Cartesian2) {
    const slideTool = document.getElementById('slideDescription');
    if (slideTool) {
        if (!coords?.x || !coords?.y) {
            slideTool.style.display = 'none';
            return;
        }

        slideTool.style.display = 'block';
        const top = coords.y + 20;
        const left = coords.x - 97;
        slideTool.style.top = `${top}px`;
        slideTool.style.left = `${left}px`;
    }
}
