import { GeoTIFFImage, fromBlob } from 'geotiff';
import { DatasetFileValidationResult, DatasetFileValidator } from './DatasetFileValidator';
import { SourceType } from '../../../generated/cloud-frontend-api';

export class GeotiffFileReader implements DatasetFileValidator {
    constructor(private readonly file: File, private readonly image: GeoTIFFImage) {}

    static async build(file: File) {
        const tiff = await fromBlob(file);
        const image = await tiff.getImage();
        return new GeotiffFileReader(file, image);
    }

    async validate(): Promise<DatasetFileValidationResult> {
        try {
            const numBands = this.image.getSamplesPerPixel();
            const sourceType = numBands === 1 ? SourceType.DEM : SourceType.RASTER_MAP;
            return { sourceType, isValid: true, file: this.file };
        } catch (e) {
            return { isValid: false, file: this.file, sourceType: undefined };
        }
    }

    extractCrsCode(): number {
        try {
            switch (this.image.geoKeys.GTModelTypeGeoKey) {
                /*
            Ref: https://docs.opengeospatial.org/is/19-008r4/19-008r4.html
            The GTModelTypeGeoKey value SHALL be:
            * GTModelTypeGeoKey === 0 to indicate that the Model CRS in undefined or unknown;
            * GTModelTypeGeoKey === 1 to indicate that the Model CRS is a 2D projected coordinate reference system, indicated by the value of the ProjectedCRSGeoKey;
                > In GeoTIFF 1.0 this key was called ProjectedCSTypeGeoKey.
            * GTModelTypeGeoKey === 2 to indicate that the Model CRS is a geographic 2D coordinate reference system, indicated by the value of the GeodeticCRSGeoKey;
                > In GeoTIFF 1.0 this key was called GeographicTypeGeoKey.
            * GTModelTypeGeoKey === 3 to indicate that the Model CRS is a geocentric Cartesian 3D coordinate reference system, indicated by the value of the GeodeticCRSGeoKey; or
            * GTModelTypeGeoKey === 32767 to indicate that the Model CRS type is user-defined.
        */

                case 1:
                    return this.image.geoKeys.ProjectedCSTypeGeoKey || this.image.geoKeys.ProjectedCRSGeoKey;
                case 2:
                    return this.image.geoKeys.GeographicTypeGeoKey || this.image.geoKeys.GeodeticCRSGeoKey;
                case 3:
                    return this.image.geoKeys.GeodeticCRSGeoKey;
                case 0:
                default:
                    return 0;
            }
        } catch (e) {
            return 0;
        }
    }
}
