// This property is writable. For read-only property use GeneralProperty component
import { createAsyncThunk } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import produce from 'immer';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../../../../contexts/ProjectViewAccessContext';
import { propertiesTooltipMessages, reservedPropertiesNames } from '../../../../sharedConstants';
import { AppDispatch, ApplicationState, useSelector } from '../../../../store';
import { GeoJson, TemporaryGeometry } from '../../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../../store/selectors';
import { updateGeometryContent } from '../../../../store/slices/geometries';
import { updateGeometry } from '../../../../store/slices/geometryLayers';
import { setUndoableOperation } from '../../../../store/slices/projectView';
import AutoSave from '../../../Elements/auto-save-formik/AutoSaveFormik';
import TippyTooltip from '../../../Elements/tippy-tooltip/TippyTooltip';
import UndoableOperation, { UndoableOperationTypes } from '../../undo-operation/UndoableOperation';
import { parseProperty } from '../AdditionalProperties';
import AdditionalPropertyControl from './AdditionalPropertyControl';
import { Input } from 'antd';

type Props = {
    label: string;
    value: string;
    readOnly?: boolean;
    deletable?: boolean;
    setCustomProperties?: React.Dispatch<React.SetStateAction<string[]>>;
    customProperties?: string[];
};

const name = 'additionalProperty';

const updateTempProperty = createAsyncThunk<
    void,
    {
        layerId: string;
        geometryId: string;
        label: string;
        deleteProperty: boolean;
        value?: string;
        owned: boolean;
        setCustomProperties?: React.Dispatch<React.SetStateAction<string[]>>;
        customProperties?: string[];
    },
    { state: ApplicationState; dispatch: AppDispatch }
>(
    `${name}/updateTempProperty`,
    async (
        { layerId, label, deleteProperty, geometryId, value, owned, setCustomProperties, customProperties },
        { getState, dispatch }
    ) => {
        const projectInfo = getState().project.projectInfo;

        const geometry = getState().geometries.entities[geometryId];
        let newGeoJson;
        if (deleteProperty) {
            newGeoJson = produce(geometry!.content, draft => {
                delete draft.properties[label];
            });
        } else {
            const selectedObject = getState().project.selectedObject.artifactId;
            if (selectedObject === geometry!.id) {
                const index = customProperties!.indexOf(label);
                if (setCustomProperties) {
                    setCustomProperties(
                        produce((draft: string[]) => {
                            draft.splice(index, 0, label);
                        })
                    );
                }
            }
            newGeoJson = produce(geometry!.content, draft => {
                draft.properties[label] = value || '';
            });
        }

        dispatch(updateGeometryContent({ id: geometry?.id!, geoJson: newGeoJson }));
        if (owned) {
            await dispatch(
                updateGeometry({
                    projectUid: projectInfo.id!,
                    layerUid: layerId,
                    id: geometryId,
                    geoJson: newGeoJson
                })
            );
        }
    }
);

export default function AdditionalProperty({
    label,
    readOnly,
    value,
    deletable,
    setCustomProperties,
    customProperties
}: Props) {
    const dispatch: AppDispatch = useDispatch();
    const projectInfo = useSelector(state => state.project.projectInfo);
    const temporaryLayers = useSelector(state => state.project.structure.temporaryLayers);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state)) as TemporaryGeometry;
    const { owned } = useContext(ProjectViewAccessContext);
    const layer = temporaryLayers.find(l => l.geometries.includes(selectedObjectInfo.id));

    const [formHasFocus, setFormHasFocus] = useState(false);

    function updatePropertyTemporarily(newGeoJson: GeoJson, newPropertyName?: string) {
        if (setCustomProperties)
            setCustomProperties(
                produce((draft: string[]) => {
                    draft[draft.indexOf(label)] = newPropertyName || '';
                })
            );

        dispatch(updateGeometryContent({ id: selectedObjectInfo.id, geoJson: newGeoJson }));
    }

    function updateProperty(newGeoJson: GeoJson, value?: string) {
        updatePropertyTemporarily(newGeoJson, value);

        if (owned)
            dispatch(
                updateGeometry({
                    projectUid: projectInfo.id!,
                    layerUid: layer?.id!,
                    id: selectedObjectInfo.id,
                    geoJson: newGeoJson
                })
            );
    }

    return (
        <Formik
            enableReinitialize
            initialValues={{ label, value }}
            onSubmit={values => {
                updateProperty(
                    produce(selectedObjectInfo.content, draft => {
                        if (!customProperties?.includes(values.label)) {
                            draft.properties[values.label] = values.value;
                            delete draft.properties[label];
                        } else {
                            draft.properties[label] = parseProperty(values.value);
                        }
                    }),
                    values.label
                );
            }}
        >
            {({ errors, setFieldValue, values }) => (
                <TippyTooltip tooltipText={errors.label || ''} disabled={!errors.label} visible appendTo='parent'>
                    <Form
                        className='property'
                        onFocus={() => {
                            if (!readOnly) setFormHasFocus(true);
                        }}
                        onBlur={() => {
                            if (!readOnly) setFormHasFocus(false);
                        }}
                    >
                        <AutoSave debounceMs={300} />
                        <div
                            className={classNames('prop-wrapper', {
                                active: formHasFocus && !readOnly,
                                alert: errors.label,
                                'read-only': readOnly
                            })}
                        >
                            <div className='prop-label-wrapper'>
                                <Field
                                    as={Input.TextArea}
                                    className='prop-input prop-label'
                                    name='label'
                                    autoSize
                                    autoComplete='off'
                                    readOnly={readOnly}
                                    validate={(newValue: string) => {
                                        if (!newValue?.length) return propertiesTooltipMessages().empty;
                                        if (reservedPropertiesNames.includes(newValue)) {
                                            return propertiesTooltipMessages().reserved;
                                        } else if (
                                            Object.keys(selectedObjectInfo.content.properties).includes(newValue) &&
                                            newValue !== label
                                        ) {
                                            return propertiesTooltipMessages().exists;
                                        }
                                    }}
                                />
                            </div>
                            <div className='prop-value-wrapper'>
                                <Field
                                    as={Input.TextArea}
                                    className='prop-input prop-value'
                                    name='value'
                                    autoSize
                                    autoComplete='off'
                                    readOnly={readOnly}
                                />
                            </div>
                        </div>
                        <AdditionalPropertyControl
                            deletable={deletable}
                            formHasFocus={formHasFocus}
                            value={value}
                            onDelete={() => {
                                const newGeoJson = produce(selectedObjectInfo.content, draft => {
                                    delete draft.properties[label];
                                });

                                const uo = new UndoableOperation(
                                    UndoableOperationTypes.DELETE_ADDITIONAL_PROPERTY,
                                    () => {
                                        dispatch(
                                            updateTempProperty({
                                                layerId: layer!.id,
                                                geometryId: selectedObjectInfo!.id,
                                                deleteProperty: false,
                                                label: label,
                                                value: value,
                                                customProperties,
                                                setCustomProperties,
                                                owned
                                            })
                                        );
                                    },
                                    () => {
                                        dispatch(
                                            updateTempProperty({
                                                layerId: layer!.id,
                                                geometryId: selectedObjectInfo!.id,
                                                deleteProperty: true,
                                                label: label,
                                                owned
                                            })
                                        );
                                    }
                                );
                                dispatch(setUndoableOperation(uo));
                                setCustomProperties &&
                                    setCustomProperties(
                                        produce((draft: string[]) => {
                                            draft.splice(draft.indexOf(label), 1);
                                        })
                                    );
                                updatePropertyTemporarily(newGeoJson);
                            }}
                        />
                    </Form>
                </TippyTooltip>
            )}
        </Formik>
    );
}
