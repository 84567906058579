import { ProjectInfo, ProjectType, SharedProjectInfo } from '../generated/cloud-frontend-api';
import isProjectInfoSharedProjectInfo from './isProjectInfoSharedProjectInfo';
import { generatePath } from 'react-router-dom';
import { Routes } from '../sharedConstants';

export default function getProjectLinkPath(project: ProjectInfo | SharedProjectInfo): string {
    if (project.type === ProjectType.SITE) {
        if (isProjectInfoSharedProjectInfo(project))
            return generatePath(Routes.SHARED_SITE, { hash: project.accessKey });
        else return generatePath(Routes.SITE_VIEW, { id: project.id });
    }

    if (project.type === ProjectType.FOLDER) {
        return generatePath(Routes.FOLDER, { folderId: project.id });
    }

    if (isProjectInfoSharedProjectInfo(project))
        return generatePath(Routes.SHARED_PROJECT_VIEW, { hash: project.accessKey });

    return generatePath(Routes.PROJECT_VIEW, { id: project.id! });
}
