import { ReactNode, SyntheticEvent } from 'react';
import classNames from 'classnames';

type Props = {
    children: ReactNode;
    alert?: boolean;
    disabled?: boolean;
    active?: boolean;
    onClick?: (event: SyntheticEvent) => void;
};

export default function TippyDropdownItem({ children, disabled, alert, active, onClick, ...props }: Props) {
    return (
        <div
            className={classNames('tippy-dropdown-item', { alert, disabled, active })}
            onClick={event => {
                if (disabled) {
                    event.preventDefault();
                    event.stopPropagation();
                } else {
                    onClick && onClick(event);
                }
            }}
            {...props}
        >
            {children}
        </div>
    );
}
