import { useOutsideClickRef } from 'rooks';
import { useTranslation } from 'react-i18next';

type Props = {
    email: string | undefined;
    handleClose: () => void;
    handleCopiedTooltip: () => void;
};

export default function ProjectOwnerEmail({ email, handleClose, handleCopiedTooltip }: Props) {
    const { t } = useTranslation('sharedProjects');
    const handleCopyMessage = () => {
        navigator.clipboard.writeText(email!);
        handleClose();
        if (handleCopiedTooltip) handleCopiedTooltip();
    };

    const [popup] = useOutsideClickRef(handleClose);

    return (
        <div className='email-popup' ref={popup}>
            <span className='email'>{email}</span>
            <button className='btn btn-copy' type='button' onClick={handleCopyMessage}>
                {t('projectCard.ownerCopy')}
            </button>
            <div className='close-area' onClick={handleClose}>
                <div className='close' />
            </div>
        </div>
    );
}
