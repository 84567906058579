import { AxiosError } from 'axios';
import { matchPath } from 'react-router-dom';
import { Routes, LOGIN_URL, unprotectedRoutes } from '../sharedConstants';
import store from '../store';
import { globalFlagsActions } from '../store/slices/globalFlags';
import { setSelectedProjects } from '../store/slices/projectsPage';
import isOnline from './isOnline';

export function onInterceptorRejected(error: AxiosError) {
    const isUnprotectedRoute = !!matchPath(window.location.pathname, { path: unprotectedRoutes, exact: true });

    if (!isUnprotectedRoute && error?.response?.status === 401) window.location.href = LOGIN_URL;

    const online = isOnline();
    if (!online) store.dispatch(globalFlagsActions.setOnlineStatus(false));

    const isServiceUnavailable = Math.floor((error?.response?.status || 0) / 100) === 5; // check for 5xx error codes

    if (isServiceUnavailable && online) {
        const requestType = error?.response?.config.method;
        if (requestType === 'get') {
            store.dispatch(globalFlagsActions.setServiceAvailability(false));
        } else {
            if ([502, 503, 504].includes(error.response!.status)) {
                store.dispatch(globalFlagsActions.clearServerErrorNotification());
                store.dispatch(globalFlagsActions.setServerErrorNotification('The service is temporary unavailable'));
            } else {
                store.dispatch(globalFlagsActions.clearServerErrorNotification());
                store.dispatch(globalFlagsActions.setServerErrorNotification('Unexpected server error'));
            }
        }
    }

    if (!online || isServiceUnavailable) {
        store.dispatch(setSelectedProjects([]));

        const openDropdowns = document.querySelectorAll('.dropdown-menu.active');
        openDropdowns?.forEach(el => {
            el.classList.remove('active');
        });
    }
}
