import { useSelector } from '../store';
import { selectIsCreateViewpointControlEnabled } from '../store/slices/presentation';

export default function useAreSceneMouseEventsBlocked() {
    const isCreateViewpointControlEnabled = useSelector(state => selectIsCreateViewpointControlEnabled(state));
    const isCamerasInspectionEnabled = useSelector(state => state.projectView.isCamerasInspectionEnabled);
    const isRulerToolEnabled = useSelector(state => state.projectView.isRulerToolEnabled);
    const selectedGeometryType = useSelector(state => state.projectView.selectedGeometryType);

    const areMouseEventsBlocked =
        isCamerasInspectionEnabled || isCreateViewpointControlEnabled || !!selectedGeometryType || isRulerToolEnabled;

    return areMouseEventsBlocked;
}
