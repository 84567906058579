/* tslint:disable */
/* eslint-disable */
/**
 * account-frontend-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const PlanType = {
    COMMERCIAL: 'COMMERCIAL',
    FREE: 'FREE'
} as const;

export type PlanType = typeof PlanType[keyof typeof PlanType];



