import { Trans, useTranslation } from 'react-i18next';
import { ProjectInfo, ProjectType } from '../../../generated/cloud-frontend-api';
import convertSize from '../../../lib/convertSize';
import Modal, { ModalProps } from '../modal/Modal';
import ModalActions from '../modal/ModalActions';
import ModalBody from '../modal/ModalBody';
import ModalHead from '../modal/ModalHead';

type Props = ModalProps & { project: ProjectInfo; onDelete(): void };

const projectTypesDisplayValues: Record<ProjectType, string> = {
    [ProjectType.METASHAPE]: 'project',
    [ProjectType.NON_METASHAPE]: 'project',
    [ProjectType.FOLDER]: 'folder',
    [ProjectType.SITE]: 'site'
};

export default function DeleteProjectModal({ isOpen, onDelete, project, setIsOpen }: Props) {
    const { t } = useTranslation(['modals']);

    const getI18nKey = (projectType: keyof typeof ProjectType) => {
        switch (projectType) {
            case ProjectType.FOLDER:
                return 'deleteProjectModal.bodyEmptyFolder';
            case ProjectType.SITE:
                return 'deleteProjectModal.bodyEmptySite';
            default:
                return 'deleteProjectModal.bodyEmptyProject';
        }
    };

    const getModalText = () => {
        if (project.size) {
            return (
                <Trans
                    ns='modals'
                    i18nKey='deleteProjectModal.body'
                    components={{ p: <p />, s: <strong className='overflow' /> }}
                    values={{
                        projectName: project.name,
                        projectSize: convertSize(project.size),
                        projectType: projectTypesDisplayValues[project.type!]
                    }}
                />
            );
        }
        return (
            <Trans
                ns='modals'
                i18nKey={getI18nKey(project.type as keyof typeof ProjectType)}
                components={{ p: <p />, s: <strong className='overflow' /> }}
                values={{ projectName: project.name }}
            />
        );
    };

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <ModalHead setIsOpen={setIsOpen}>{t('modals:deleteProjectModal.head')}</ModalHead>
            <ModalBody>
                <div>{getModalText()}</div>
                <ModalActions>
                    <button
                        type='button'
                        className='btn-cancel'
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        {t('modals:deleteProjectModal.cancel')}
                    </button>
                    <button
                        className='btn-alert'
                        type='button'
                        onClick={() => {
                            onDelete();
                            setIsOpen(false);
                        }}
                    >
                        {t('modals:deleteProjectModal.delete')}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}
