import { useTranslation } from 'react-i18next';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import { AppDispatch, useSelector } from '../../../store';
import { useDispatch } from 'react-redux';
import { setCreateViewpointControlEnabled } from '../../../store/slices/presentation';
import classNames from 'classnames';
import { useEffect } from 'react';

export default function CreateViewpointControl() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const isCreateViewpointControlEnabled = useSelector(state => state.presentation.isCreateViewpointControlEnabled);

    useEffect(() => {
        return () => {
            dispatch(setCreateViewpointControlEnabled(false));
        };
    }, [dispatch]);

    return (
        <TippyTooltip tooltipText={t('projectView:mapControls.tooltipCreateView')}>
            <div
                data-testid='viewpoint-control'
                className={classNames('control-btn', { active: isCreateViewpointControlEnabled })}
                onClick={e => {
                    dispatch(setCreateViewpointControlEnabled(!isCreateViewpointControlEnabled));
                }}
            >
                <i className='icon icon-slide' />
            </div>
        </TippyTooltip>
    );
}
