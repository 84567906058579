import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SharedProjectInfo } from '../../../../generated/cloud-frontend-api';
import { AppDispatch } from '../../../../store';
import RemoveSharedProjectModal from '../../modals/remove-shared-project-modal/RemoveSharedProjectModal';
import { deleteProjectFromSharedWithMe } from '../../../../store/slices/sharing';
import TippyDropdownItem from '../../tippy-dropdown/TippyDropdownItem';
import { isSharedSiteRoute } from '../../../../lib/isSharedProjectsPageRoute';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../sharedConstants';

type Props = {
    project: SharedProjectInfo;
};

export default function RemoveSharedProjectAction({ project }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation(['projects']);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isSharedSitePath = isSharedSiteRoute();

    return (
        <>
            <TippyDropdownItem
                alert
                onClick={() => {
                    setIsModalOpen(true);
                }}
            >
                {t('projects:projectActions.remove')}
            </TippyDropdownItem>

            <RemoveSharedProjectModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                project={project}
                onRemove={() => {
                    dispatch(deleteProjectFromSharedWithMe(project.id!));
                    if (isSharedSitePath) history.push(Routes.SHARED);
                }}
            />
        </>
    );
}
