import { useMemo, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import { Dataset } from '../../../entities/Dataset';
import { ProjectPartType } from '../../../generated/cloud-frontend-api';
import { Routes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store/index';
import { ExtendedDatasetInfo, cancelDatasetUpload } from '../../../store/slices/datasetfilesUpload';
import { isLinkedDataset, removeDataset } from '../../../store/slices/datasets';
import AbortDatasetUploadModal from '../../Elements/modals/abort-dataset-upload-modal/AbortDatasetUploadModal';
import CancelUploadControl from '../workspace-controls/CancelUploadControl';
import DeleteDatasetControl from './DeleteDatasetControl';
import UploadProgressBar from './UploadProgressBar';
import { ReactComponent as SvgSpinner } from '../../../svg/general/spinner_small.svg';
import { uploadAborted } from '../../../store/slices/datasetsUpload';
import classNames from 'classnames';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { ReactComponent as SvgPauseLight } from '../../../svg/general/pause_light.svg';
import { ReactComponent as SvgPauseDark } from '../../../svg/general/pause_dark.svg';

type Props = {
    datasetInfo: ExtendedDatasetInfo;
};

export default function DatasetFileUploadControls({ datasetInfo }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const {
        isUploading,
        isPublishing,
        isValidating,
        isPendingManualAction,
        uploadPercent,
        visualData,
        datasetUid,
        isUploadAbortable,
        projectPartType
    } = useMemo(() => new Dataset(datasetInfo), [datasetInfo]);
    const [isAbortModalOpen, setAbortModalOpen] = useState(false);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);

    const hasNotBeenUploaded = isValidating || isPendingManualAction;
    const percent = (isUploading ? uploadPercent : visualData?.progress ?? 0).toFixed(0);
    const isLinkedFromAnotherProject = isLinkedDataset(datasetInfo, projectInfo.id!);
    const isArtifactTypeNode = projectPartType === ProjectPartType.TILESETS && !isLinkedFromAnotherProject;

    const { isInEmbedView } = useContext(ProjectViewAccessContext);

    function abortDatasetUpload() {
        if (hasNotBeenUploaded) dispatch(removeDataset({ datasetUid: datasetUid! }));
        else dispatch(cancelDatasetUpload({ datasetUid: datasetUid!, projectId: projectInfo?.id! }));
    }

    return (
        <>
            <Route exact path={[Routes.PROJECT_VIEW, Routes.SITE_VIEW]}>
                {isPublishing && !isCompareToolEnabled && !isArtifactTypeNode && (
                    <DeleteDatasetControl datasetInfo={datasetInfo} useExtendedClassName />
                )}
            </Route>

            {isUploadAbortable && (
                <>
                    <CancelUploadControl
                        onCancel={() => {
                            setAbortModalOpen(true);
                        }}
                    />
                    <AbortDatasetUploadModal
                        isOpen={isAbortModalOpen}
                        setIsOpen={setAbortModalOpen}
                        datasetName={datasetInfo.name}
                        onAbort={() => {
                            if (projectPartType === ProjectPartType.VECTOR_LAYERS) dispatch(uploadAborted(datasetUid));
                            else abortDatasetUpload();
                        }}
                    />
                </>
            )}

            <span className={classNames('control artefact-upload', { disabled: isPendingManualAction })}>
                {(isUploading || isPublishing) && (
                    <span className='progress-circle'>
                        <UploadProgressBar value={Number(percent)} />
                    </span>
                )}
                {isValidating && (
                    <div className='upload-spinner'>
                        <SvgSpinner className='upload-spinner-svg' />
                    </div>
                )}
                {isPendingManualAction && (
                    <span className='progress-circle'>{isInEmbedView ? <SvgPauseDark /> : <SvgPauseLight />}</span>
                )}
            </span>
        </>
    );
}
