import Tippy from '@tippyjs/react/headless';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Img } from 'react-image';
import { useDispatch } from 'react-redux';
import { Link, Route, useParams, useRouteMatch } from 'react-router-dom';
import metashapeLogo from '../../assets/images/projects_list/metashape.svg';
import notPublishedImg from '../../assets/images/projects_list/not-published.svg';
import publishedImg from '../../assets/images/projects_list/published.svg';
import { ProjectInfo, ProjectStatus, SharedProjectInfo } from '../../generated/cloud-frontend-api/model';
import { ProjectType } from '../../generated/project-api-v2';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';
import { formatDistanceToNowStrict } from '../../i18n/dateTimeWrappers';
import { hasSharedOrEmbedAccess } from '../../lib/accessHelpers';
import convertSize from '../../lib/convertSize';
import getProjectLinkPath from '../../lib/getProjectLinkPath';
import isLastPipelineProcessing from '../../lib/isLastPipelineProcessing';
import isProjectInfoSharedProjectInfo from '../../lib/isProjectInfoSharedProjectInfo';
import isSharedProjectsPageRoute from '../../lib/isSharedProjectsPageRoute';
import { FolderRouteParams } from '../../pages/folder/Folder';
import { Routes } from '../../sharedConstants';
import { useSelector } from '../../store';
import { setSelectedProjects } from '../../store/slices/projectsPage';
import Checkbox from '../Elements/checkbox/Checkbox';
import ProgressBar from '../Elements/progress-bar/ProgressBar';
import ProjectActions from '../Elements/project-actions/ProjectActions';
import TippyTooltip from '../Elements/tippy-tooltip/TippyTooltip';
import ProjectPreviewImage from '../Projects/project-preview-image/ProjectPreviewImage';
import ProjectOwnerEmail from './ProjectOwnerEmail';

type Props = {
    project: ProjectInfo | SharedProjectInfo;
    isLoading?: boolean;
};

const TOOLTIP_TIMEOUT = 2000;

const dateFormat = 'dd/MM/yyyy';

const ProjectCard: React.FC<Props> = ({ project, isLoading }) => {
    const { t, i18n } = useTranslation(['projects', 'sharedProjects']);
    const dispatch = useDispatch();
    const timeout = useRef(0);
    const selected = useSelector(state => state.projectsPage.selected);

    const isLoggedIn = useIsLoggedIn();

    const [isTitleHighlighted, setIsTitleHighlighted] = useState(false);
    const [showEmailPopup, setShowEmailPopup] = useState(false);
    const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);

    const ownerFullName =
        isProjectInfoSharedProjectInfo(project) && `${project.owner?.firstName ?? ''} ${project.owner?.lastName ?? ''}`;

    const dateCreated =
        !isProjectInfoSharedProjectInfo(project) && project.dateCreated
            ? format(parseISO(project.dateCreated), dateFormat)
            : '';

    const dateModified =
        !isProjectInfoSharedProjectInfo(project) && project.dateModified
            ? t('projects:projectCard.dateModified', {
                  formattedInWords: formatDistanceToNowStrict(
                      parseISO(project.dateModified),
                      { roundingMethod: 'round' },
                      i18n.resolvedLanguage!
                  )
              })
            : '';
    const dateSurveyed =
        !isProjectInfoSharedProjectInfo(project) && project.surveyDate
            ? format(parseISO(project.surveyDate!), dateFormat)
            : '';
    const size = t('projects:projectCard.size', { formattedSize: convertSize(project.size || 0) });
    const showSharedIcon = !isProjectInfoSharedProjectInfo(project) && hasSharedOrEmbedAccess(project.accesses);
    const previewSrc = project.preview
        ? `${project.preview}${
              isSharedProjectsPageRoute() && isProjectInfoSharedProjectInfo(project)
                  ? `&access=${project.accessKey}`
                  : ''
          }`
        : undefined;
    const isSelected = selected.includes(project.id!);
    const linkPath = isLoading ? '' : getProjectLinkPath(project);

    const isMetashapeProject = project.type === ProjectType.METASHAPE;
    const isProcessing = !isProjectInfoSharedProjectInfo(project) && isLastPipelineProcessing(project);

    const hasInProgressPipeline =
        !isProjectInfoSharedProjectInfo(project) &&
        project.status &&
        ([ProjectStatus.INPROGRESS, ProjectStatus.ABORTING] as ProjectStatus[]).includes(project.status);

    const showOverlay = useRouteMatch({ path: Routes.INDEX, strict: true }) && hasInProgressPipeline && isProcessing;

    function selectProject(e: SyntheticEvent) {
        e.preventDefault(); // prevent 2nd click on checkbox

        if (isLoading) return;
        dispatch(setSelectedProjects([project.id!]));
    }

    function handleCopiedTooltip() {
        if (showCopiedTooltip) {
            clearTimeout(timeout.current);
            setShowCopiedTooltip(false);
        }
        setShowCopiedTooltip(true);
        timeout.current = window.setTimeout(() => {
            setShowCopiedTooltip(false);
        }, TOOLTIP_TIMEOUT);
    }

    useEffect(() => {
        return () => clearTimeout(timeout.current);
    }, []);

    return (
        <div
            data-testid={'projectTile'}
            className={classNames('project-tile', { skeleton: isLoading, selected: isSelected })}
        >
            <div className='image-wrapper'>
                {!isLoading && (
                    <>
                        <ProjectPreviewImage previewSrc={previewSrc} type={project.type!} />
                        <Link
                            to={linkPath}
                            className='project-link'
                            onMouseOver={() => setIsTitleHighlighted(true)}
                            onMouseLeave={() => setIsTitleHighlighted(false)}
                        />

                        {isMetashapeProject && (
                            <div className={classNames('icons-overlay', { inactive: showOverlay })}>
                                <TippyTooltip tooltipText='Metashape'>
                                    <div className='overlay-icon' data-testid={'iconMetashape'}>
                                        <Img src={metashapeLogo} />
                                    </div>
                                </TippyTooltip>
                                {project.published ? (
                                    <TippyTooltip tooltipText={t('projects:projectCard.published')}>
                                        <div className='overlay-icon' data-testid={'iconPublished'}>
                                            <Img src={publishedImg} />
                                        </div>
                                    </TippyTooltip>
                                ) : (
                                    <TippyTooltip tooltipText={t('projects:projectCard.notPublished')}>
                                        <div className='overlay-icon' data-testid={'iconNotPublished'}>
                                            <Img src={notPublishedImg} />
                                        </div>
                                    </TippyTooltip>
                                )}
                            </div>
                        )}

                        <Route exact path={[Routes.INDEX, Routes.SITE, Routes.FOLDER]}>
                            {hasInProgressPipeline && isProcessing && (
                                <div className='project-tile-overlay'>
                                    <div className='overlay-bottom'>
                                        <ProgressBar project={project} />
                                    </div>
                                </div>
                            )}

                            <div className='overlay-checkbox' onClick={selectProject}>
                                <Checkbox checked={isSelected} />
                            </div>
                        </Route>
                    </>
                )}
            </div>
            <div className='project-info'>
                {isLoading ? (
                    <>
                        <div className='skeleton-title' />
                        <div className='skeleton-date' />
                        <div className='skeleton-size' />
                    </>
                ) : (
                    <>
                        <div className='title-wrapper'>
                            <Tippy
                                render={attrs => (
                                    <div className='tippy-tooltip tippy-project-title' tabIndex={-1} {...attrs}>
                                        {project.name}
                                    </div>
                                )}
                                delay={[700, 100]}
                                offset={[0, 8]}
                                touch={false}
                                placement={'top-start'}
                            >
                                <Link
                                    to={linkPath}
                                    className={classNames('project-title', { isHighlighted: isTitleHighlighted })}
                                >
                                    {showSharedIcon && <i className='icon icon-shared' />}
                                    <span>{project.name}</span>
                                </Link>
                            </Tippy>
                            {isLoggedIn && <ProjectActions project={project} parent={null} />}
                        </div>
                        <Route exact path={[Routes.INDEX, Routes.SITE, Routes.FOLDER]}>
                            <div className='project-date'>
                                <div>
                                    {project.type === ProjectType.SITE || project.type === ProjectType.FOLDER
                                        ? `${t('projects:projectCard.created')} ${dateCreated}`
                                        : `${t('projects:projectCard.surveyed')} ${dateSurveyed}`}
                                </div>
                                <div>{dateModified}</div>
                            </div>
                        </Route>
                        <div className='project-size'>{size}</div>
                        <Route exact path={Routes.SHARED}>
                            <TippyTooltip
                                tooltipText={t('sharedProjects:projectCard.ownerTooltip')}
                                visible={showCopiedTooltip}
                                placement={'top-start'}
                                offset={[0, 2]}
                            >
                                <div className='project-owner' data-testid={'projectOwner'}>
                                    <span>{t('sharedProjects:projectCard.owner')}</span>
                                    <span
                                        className='owner-link'
                                        onClick={e => {
                                            e.stopPropagation();
                                            setShowCopiedTooltip(false);
                                            setShowEmailPopup(true);
                                        }}
                                    >
                                        {ownerFullName}
                                    </span>
                                </div>
                            </TippyTooltip>
                            {isProjectInfoSharedProjectInfo(project) && showEmailPopup && (
                                <ProjectOwnerEmail
                                    email={project.owner?.email}
                                    handleClose={() => {
                                        setShowEmailPopup(false);
                                    }}
                                    handleCopiedTooltip={handleCopiedTooltip}
                                />
                            )}
                        </Route>
                    </>
                )}
            </div>
        </div>
    );
};

export default ProjectCard;
