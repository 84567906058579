import classNames from 'classnames';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../../store';
import { TemporaryLayer } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { ExtendedDatasetInfo, uploadDatasetFile } from '../../../store/slices/datasetfilesUpload';
import { isDatasetInfo } from '../../../store/slices/datasets';
import SelectCrs from '../../Elements/select-crs/SelectCrs';
import { InspectorStructureItemProgress } from '../../inspection-sidebar/inspector-structure-item-progress/InspectorStructureItemProgress';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { VectorLayerUpload } from '../../../store/helpers/vector-layer-upload/VectorLayerUpload';
import SelectSourceType from '../../Elements/select-source-type/SelectSourceType';
import { SourceType } from '../../../generated/cloud-frontend-api';
import { ReactComponent as SvgSave } from '../../../svg/general/save.svg';

export default function SelectCrsAndTypeProperty() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const { owned } = useContext(ProjectViewAccessContext);
    const { isInEmbedView } = useContext(ProjectViewAccessContext);
    const hasClicked = useRef(false);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const vectorLayerUploads = useSelector(state => state.datasetsUpload.uploads);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state)) as
        | ExtendedDatasetInfo
        | TemporaryLayer;
    const [selectedCoordinateSystemId, setSelectedCoordinateSystemId] = useState<string | undefined>(undefined);
    const [prjErrorMessage, setPrjErrorMessage] = useState('');
    const [selectedSourceType, setSelectedSourceType] = useState<SourceType>(SourceType.TILED_MODEL);

    const topText = (() => {
        if (isDatasetInfo(selectedObjectInfo)) {
            if (!selectedObjectInfo.coordinateSystemUid)
                return t('projectView:inspectionSidebar.datasetProperties.selectTitle');
            return t('projectView:inspectionSidebar.datasetProperties.selectTitleType');
        }
        return t('projectView:inspectionSidebar.datasetProperties.selectTitle');
    })();

    useEffect(() => {
        setSelectedCoordinateSystemId(undefined);
        setPrjErrorMessage('');
    }, [selectedObject?.artifactId]);

    const hasMissingSourceType = isDatasetInfo(selectedObjectInfo) && !selectedObjectInfo.sourceData?.type;

    const hasNotSelectedCrs =
        !selectedObjectInfo.coordinateSystemUid &&
        (!selectedCoordinateSystemId || selectedCoordinateSystemId === 'local' || !!prjErrorMessage);

    const hasNotSelectedSourceType = hasMissingSourceType && !selectedSourceType;

    return (
        <div className='inspector-properties inspector-properties_upload'>
            <InspectorStructureItemProgress topText={topText} percent={0} />
            {!selectedObjectInfo.coordinateSystemUid && (
                <div className='properties-list'>
                    <div className={classNames('property', { 'no-margin': prjErrorMessage })}>
                        <div className='prop-wrapper read-only'>
                            <div className='prop-label-wrapper'>
                                <textarea
                                    className='prop-input prop-label'
                                    readOnly
                                    rows={1}
                                    tabIndex={-1}
                                    value={t('projectView:inspectionSidebar.datasetProperties.crs')}
                                />
                            </div>
                            <SelectCrs
                                className='prop-value-wrapper'
                                selectedCrsId={selectedCoordinateSystemId}
                                setSelectedCrsId={setSelectedCoordinateSystemId}
                                error={!!prjErrorMessage}
                                setCrsErrorMessage={setPrjErrorMessage}
                            />
                        </div>
                    </div>
                    {prjErrorMessage && (
                        <div className='property'>
                            <div className='prop-wrapper read-only'>
                                <div className='prop-label-wrapper' />
                                <div className='prop-value-wrapper'>
                                    <span className='prop-error'>{prjErrorMessage}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {hasMissingSourceType && (
                <div className='properties-list'>
                    <div className={classNames('property')}>
                        <div className='prop-wrapper read-only'>
                            <div className='prop-label-wrapper'>
                                <textarea
                                    className='prop-input prop-label'
                                    readOnly
                                    rows={1}
                                    tabIndex={-1}
                                    value={t('projectView:inspectionSidebar.datasetProperties.type')}
                                />
                            </div>
                            <SelectSourceType
                                className='prop-value-wrapper'
                                selectedSourceType={selectedSourceType}
                                setSelectedSourceType={setSelectedSourceType}
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className='select-crs-upload'>
                <button
                    className='btn-icon'
                    type='button'
                    disabled={hasNotSelectedCrs || hasNotSelectedSourceType}
                    onClick={e => {
                        if (!hasClicked.current) {
                            hasClicked.current = true;

                            if (isDatasetInfo(selectedObjectInfo)) {
                                dispatch(
                                    uploadDatasetFile({
                                        file: selectedObjectInfo.sourceData?.file!,
                                        projectId: projectInfo.id!,
                                        type: selectedObjectInfo.sourceData?.type || selectedSourceType,
                                        coordinateSystemUid:
                                            selectedObjectInfo.coordinateSystemUid || selectedCoordinateSystemId!,
                                        temporaryId: selectedObjectInfo.datasetUid,
                                        shouldScrollInTree: false
                                    })
                                );
                            } else {
                                const upload = vectorLayerUploads[selectedObjectInfo.id];
                                dispatch(
                                    VectorLayerUpload.uploadVectorLayer({
                                        id: selectedObjectInfo.id,
                                        file: upload?.file!,
                                        isTemporary: !owned,
                                        terrainProvider: upload?.terrainProviderRef!,
                                        crsId: selectedCoordinateSystemId!
                                    })
                                );
                            }
                        }
                    }}
                >
                    <SvgSave />
                </button>
            </div>
        </div>
    );
}
