import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import { ProjectInfo } from '../../../generated/cloud-frontend-api';
import { AppDispatch, useSelector } from '../../../store';
import { addEmailAccess } from '../../../store/slices/sharing';

type Props = {
    isDisabled: boolean;
    project: ProjectInfo;
};

const validationSchema = object().shape({
    email: string().min(1).email()
});

export default function InviteUser({ isDisabled, project }: Props) {
    const { t } = useTranslation('common');
    const dispatch: AppDispatch = useDispatch();

    const emails = project.accesses
        ?.filter(accessItem => accessItem.accessType === 'EMAIL' && accessItem.email !== undefined)
        .map(accessItem => accessItem.email || '');

    return (
        <Formik
            validate={({ email }) => {
                if (emails?.map(e => e.toLowerCase()).includes(email.toLowerCase())) return { email: 'error' };
            }}
            initialValues={{ email: '' }}
            onSubmit={async ({ email }, { resetForm }) => {
                const result = await dispatch(
                    addEmailAccess({ projectId: project.id!, email, currentAccesses: project.accesses })
                );
                if (addEmailAccess.fulfilled.match(result)) resetForm({ values: { email: '' } });
            }}
            validationSchema={validationSchema}
        >
            {({ dirty, errors, setFieldValue }) => (
                <Form className='form-wrapper'>
                    <Field
                        name={'email'}
                        type='text'
                        placeholder='you@example.com'
                        disabled={isDisabled}
                        onChange={(e: React.ChangeEvent<any>) => {
                            setFieldValue('email', e.target.value.replace(/\s+/g, ''));
                        }}
                    />
                    <button
                        type='submit'
                        data-testid={'submitButton'}
                        className='btn form-btn'
                        onClick={e => {
                            e.nativeEvent.stopImmediatePropagation();
                        }}
                        disabled={isDisabled || !dirty || !!errors.email}
                    >
                        {t('invite')}
                    </button>
                </Form>
            )}
        </Formik>
    );
}
