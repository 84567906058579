import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { Dataset } from '../../../entities/Dataset';
import { SourceType } from '../../../generated/cloud-frontend-api';
import useProjectStructureObjectsActions from '../../../hooks/useProjectStructureObjectsActions';
import { GeometryTypes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import {
    ProjectStructureObjectTypes,
    SelectedObject,
    TemporaryGeometry,
    TemporaryLayer,
    isGeometry,
    isLayer,
    isViewpointGeometry
} from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { setSelectedObject } from '../../../store/sharedActions';
import { ExtendedDatasetInfo, deleteDataset, unlinkDataset } from '../../../store/slices/datasetfilesUpload';
import { isLinkedDataset, removeDataset } from '../../../store/slices/datasets';
import { adaptVectorLayerToDataset } from '../../../store/slices/datasetsUpload';
import { selectGeometries } from '../../../store/slices/geometries';
import { deleteLayer as deleteLayerAction } from '../../../store/slices/geometryLayers';
import { ExtendedStructureInfo } from '../../../store/slices/structure';
import DeleteDatasetModal from '../../Elements/modals/delete-dataset-modal/DeleteDatasetModal';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import DeleteGroupModal from '../../Elements/modals/delete-group-modal/DeleteGroupModal';
import DeleteDatasetControl from '../../project-structure-sidebar/dataset-upload/DeleteDatasetControl';
import ExportControl from '../../project-structure-sidebar/workspace-controls/ExportControl';
import { SaveViewpointPovControl } from '../../project-structure-sidebar/workspace-controls/SaveViewpointPovControl';
import DatasetFileUploadAbort from './DatasetFileUploadAbort';

type Props = {
    viewOnly: boolean;
};

export default function InspectorElementControls({ viewOnly }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const { owned } = useContext(ProjectViewAccessContext);
    const uploads = useSelector(state => state.datasetsUpload.uploads);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const temporaryLayers = useSelector(state => state.project.structure.temporaryLayers);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));
    const currentlyDrawingShapeId = useSelector(state => state.projectView.currentlyDrawingShapeId);
    const geometries = useSelector(selectGeometries);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isDeleteGroupModalOpen, setDeleteGroupModalOpen] = useState(false);
    const isLayerOrGeometry = [ProjectStructureObjectTypes.GEOMETRY, ProjectStructureObjectTypes.LAYER].includes(
        selectedObject?.type
    );
    const isDataset = selectedObject?.type === ProjectStructureObjectTypes.DATASET;
    const isLinkedToOtherProjects =
        [
            ProjectStructureObjectTypes.DATASET,
            ProjectStructureObjectTypes.ARTIFACT,
            ProjectStructureObjectTypes.LAYER
        ].includes(selectedObject?.type!) &&
        (selectedObjectInfo as ExtendedDatasetInfo)?.parentProject?.uid === projectInfo.id &&
        Boolean((selectedObjectInfo as ExtendedDatasetInfo)?.linkedProjects?.length);
    const isLinkedFromAnotherProject =
        (selectedObject?.type === ProjectStructureObjectTypes.DATASET ||
            selectedObject?.type === ProjectStructureObjectTypes.LAYER) &&
        isLinkedDataset(selectedObjectInfo as ExtendedDatasetInfo, projectInfo.id!);
    const { deleteGeometry, deleteLayer } = useProjectStructureObjectsActions();
    const upload = uploads[selectedObject.artifactId!];

    const datasetPresenter = useMemo(() => {
        if (selectedObject?.type === ProjectStructureObjectTypes.DATASET)
            return new Dataset(selectedObjectInfo as ExtendedDatasetInfo);
        return null;
    }, [selectedObjectInfo, selectedObject]);

    const cantDeleteLayer =
        selectedObject.type === ProjectStructureObjectTypes.LAYER &&
        (selectedObjectInfo as TemporaryLayer).geometries.includes(currentlyDrawingShapeId || '') &&
        (selectedObjectInfo as TemporaryLayer).geometries
            .map(id => geometries[id]!)
            .find(g => g.id === currentlyDrawingShapeId)?.content.geometry.type !== GeometryTypes.POINT;

    const canDeleteGeometry =
        selectedObject.type === ProjectStructureObjectTypes.GEOMETRY &&
        (currentlyDrawingShapeId !== selectedObject?.artifactId ||
            (selectedObjectInfo as TemporaryGeometry).content.geometry.type === GeometryTypes.POINT);

    const canDeleteDataset = isDataset && datasetPresenter?.canBeDeleted;
    const canDownloadDataset =
        isDataset &&
        ((selectedObjectInfo as ExtendedDatasetInfo)?.sourceData?.sizeInBytes || 0) > 0 &&
        !!(selectedObjectInfo as ExtendedDatasetInfo).visualData?.status;
    const isGroup = selectedObject?.type === ProjectStructureObjectTypes.GROUP;
    const canDeleteGroup = !viewOnly;

    const canDelete =
        (selectedObject?.type === ProjectStructureObjectTypes.GEOMETRY && canDeleteGeometry) ||
        (selectedObject?.type === ProjectStructureObjectTypes.LAYER && !cantDeleteLayer) ||
        (selectedObject?.type === ProjectStructureObjectTypes.DATASET && canDeleteDataset) ||
        (isGroup && canDeleteGroup);

    const canExportLayer = isLayer(selectedObjectInfo) && (owned || (!owned && selectedObjectInfo.isTemporary));
    const canExport = canExportLayer || canDownloadDataset;

    const isSelectedObjectBeingUploaded = !!upload;
    const relatedUpload = selectedObjectInfo && uploads[(selectedObjectInfo as TemporaryLayer).id];
    const isUploadFailed = relatedUpload?.status === 'failed' || relatedUpload?.status === 'rejected';

    const showUploadDelete = isSelectedObjectBeingUploaded && isUploadFailed;

    const showUploadAbort =
        (selectedObject.type === ProjectStructureObjectTypes.LAYER &&
            isSelectedObjectBeingUploaded &&
            !isUploadFailed) ||
        (selectedObject.type === ProjectStructureObjectTypes.DATASET && datasetPresenter?.isUploadAbortable);

    const canExportLinked =
        owned &&
        [ProjectStructureObjectTypes.LAYER, ProjectStructureObjectTypes.DATASET].includes(selectedObject?.type) &&
        isLinkedDataset(selectedObjectInfo as ExtendedDatasetInfo, projectInfo.id!) &&
        ((selectedObject?.type === ProjectStructureObjectTypes.DATASET && canDeleteDataset && canDownloadDataset) ||
            (selectedObject?.type === ProjectStructureObjectTypes.LAYER && !cantDeleteLayer));

    const isViewpoint = isGeometry(selectedObjectInfo) && isViewpointGeometry(selectedObjectInfo);

    return (
        <div className='element-controls'>
            {owned && isViewpoint && <SaveViewpointPovControl />}
            {canExportLinked && <ExportControl geometries={geometries} />}
            {(isLayerOrGeometry || isDataset || isGroup) && !viewOnly && !isSelectedObjectBeingUploaded && (
                <>
                    {canExport && <ExportControl geometries={geometries} />}
                    {canDelete && (
                        <>
                            <TippyTooltip tooltipText={t('inspectionSidebar.controls.tooltipDelete')}>
                                <div
                                    className='control'
                                    data-testid={'deleteControl'}
                                    onClick={async e => {
                                        if (selectedObject.type === ProjectStructureObjectTypes.LAYER) {
                                            if (isLinkedFromAnotherProject || isLinkedToOtherProjects)
                                                setDeleteModalOpen(true);
                                            else deleteLayer(selectedObjectInfo as TemporaryLayer);
                                        }

                                        if (selectedObject.type === ProjectStructureObjectTypes.GEOMETRY)
                                            deleteGeometry(selectedObjectInfo as TemporaryGeometry);

                                        if (selectedObject.type === ProjectStructureObjectTypes.DATASET)
                                            setDeleteModalOpen(true);

                                        if (selectedObject.type === ProjectStructureObjectTypes.GROUP)
                                            setDeleteGroupModalOpen(true);
                                    }}
                                >
                                    <i className='icon icon-delete' />
                                </div>
                            </TippyTooltip>
                            <DeleteDatasetModal
                                isOpen={isDeleteModalOpen}
                                setIsOpen={setDeleteModalOpen}
                                datasetInfo={selectedObjectInfo as ExtendedDatasetInfo}
                                onDelete={async () => {
                                    const dataset =
                                        selectedObjectInfo && 'datasetUid' in selectedObjectInfo
                                            ? selectedObjectInfo
                                            : adaptVectorLayerToDataset(selectedObjectInfo as TemporaryLayer, upload);
                                    await dispatch(setSelectedObject({} as SelectedObject));
                                    if (isLinkedFromAnotherProject) {
                                        dispatch(
                                            unlinkDataset({
                                                datasetId: dataset.datasetUid!,
                                                projectId: dataset.parentProject?.uid!,
                                                linkedProjectId: projectInfo.id!
                                            })
                                        );
                                        return;
                                    }
                                    if (dataset.sourceData?.type === SourceType.GEOJSON) {
                                        dispatch(
                                            deleteLayerAction({
                                                geometryIds:
                                                    temporaryLayers.find(l => l.id === dataset.datasetUid)
                                                        ?.geometries || [],
                                                projectUid: projectInfo.id!,
                                                layerUid: dataset.datasetUid!
                                            })
                                        );
                                    } else {
                                        if (datasetPresenter?.hasVisualData) {
                                            dispatch(
                                                deleteDataset({
                                                    datasetUid: dataset.datasetUid!,
                                                    projectId: projectInfo.id!
                                                })
                                            );
                                        } else {
                                            dispatch(removeDataset({ datasetUid: dataset.datasetUid! }));
                                        }
                                    }
                                }}
                            />
                            <DeleteGroupModal
                                isOpen={isDeleteGroupModalOpen}
                                setIsOpen={setDeleteGroupModalOpen}
                                groupInfo={selectedObjectInfo as ExtendedStructureInfo}
                            />
                        </>
                    )}
                </>
            )}

            {selectedObject?.type === ProjectStructureObjectTypes.DATASET && isLinkedFromAnotherProject && owned && (
                <DeleteDatasetControl datasetInfo={selectedObjectInfo as ExtendedDatasetInfo} />
            )}

            {selectedObject?.type === ProjectStructureObjectTypes.LAYER && isLinkedFromAnotherProject && owned && (
                <DeleteDatasetControl
                    datasetInfo={adaptVectorLayerToDataset(selectedObjectInfo as TemporaryLayer, upload)}
                />
            )}

            {showUploadDelete && (
                <TippyTooltip tooltipText={t('inspectionSidebar.controls.tooltipDelete')}>
                    <div
                        className='control'
                        onClick={async () => {
                            deleteLayer(selectedObjectInfo as TemporaryLayer);
                        }}
                    >
                        <i className='icon icon-delete' />
                    </div>
                </TippyTooltip>
            )}

            {showUploadAbort && <DatasetFileUploadAbort />}
        </div>
    );
}
