import wgs84ToCartesian3 from '../../lib/wgs84ToCartesian3';
import Geometry from './Geometry';
import * as Cesium from 'cesium';

export default class PointGeometry extends Geometry {
    constructor() {
        super();
    }

    static getCoordinatesAsWindowCoordinates(coordinates: number[], scene: Cesium.Scene): Cesium.Cartesian2 {
        const objectPosition = wgs84ToCartesian3(coordinates, scene) as Cesium.Cartesian3;
        return Cesium.SceneTransforms.wgs84ToWindowCoordinates(scene!, objectPosition)!;
    }
}
