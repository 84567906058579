import React from 'react';
import { Entity, LabelGraphics } from 'resium';
import * as Cesium from 'cesium';

type Props = {
    rulerEntityId: string;
    value: string;
    positioning?: 'up' | 'center';
    position: Cesium.Cartesian3 | null;
};

const upPositioningPixelOffset = new Cesium.Cartesian2(0, -30);
const outlineWidth = 3;
const outlineColor = Cesium.Color.fromCssColorString('#1e1e1e');
const font = 'bold 20px Lato'; // CSS font property

export default function RulerMeasurementDisplay({ rulerEntityId, value, positioning = 'up', position }: Props) {
    if (!position) return null;

    return (
        <Entity position={position} id={`${rulerEntityId}#measurement`}>
            <LabelGraphics
                text={value}
                style={Cesium.LabelStyle.FILL_AND_OUTLINE}
                outlineColor={outlineColor}
                outlineWidth={outlineWidth}
                font={font}
                pixelOffset={positioning === 'up' ? upPositioningPixelOffset : undefined}
                disableDepthTestDistance={Number.POSITIVE_INFINITY}
            />
        </Entity>
    );
}
