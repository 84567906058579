import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { PlanType } from '../../../generated/account-frontend-api';
import { PaymentType } from '../../../generated/billing-api';
import formatISODate from '../../../lib/formatISODate';

import styles from '../PlanManagement.module.scss';

type Props = {
    itemType: PlanType;
    scheduledPlanType?: PlanType;
    isCommercial: boolean;
    isOverdue?: boolean;
    renewDate?: string;
    paymentType?: PaymentType;
};

export default function PlanItemState({
    itemType,
    scheduledPlanType,
    isCommercial,
    isOverdue,
    renewDate,
    paymentType
}: Props) {
    const { t } = useTranslation('cabinet');
    let result = null;
    let warning = false;

    const formattedRenewDate = formatISODate(renewDate);

    if (itemType === PlanType.FREE) {
        if (!isCommercial) {
            result = t('plans.state.active');
        } else {
            if (isOverdue) {
                result = t('plans.state.scheduled', { formattedRenewDate });
            } else if (scheduledPlanType === PlanType.FREE) {
                result = t('plans.state.scheduled', { formattedRenewDate });
            }
        }
    }

    if (itemType === PlanType.COMMERCIAL && isCommercial) {
        if (isOverdue) {
            result = t('plans.state.deactivationOn', { formattedRenewDate });
            warning = true;
        } else {
            if (scheduledPlanType === PlanType.FREE) {
                result = t('plans.state.deactivationOn', { formattedRenewDate });
                warning = true;
            } else {
                if (paymentType === PaymentType.AUTO) {
                    result = t('plans.state.nextPaymentBy', { formattedRenewDate });
                } else {
                    result = t('plans.state.nextPaymentOn', { formattedRenewDate });
                }
            }
        }
    }

    return result === null ? null : (
        <div className={classNames(styles.planStateLabel, warning && styles.warning)}>{result}</div>
    );
}
