import { matchPath, Route, Switch, useLocation } from 'react-router-dom';
import ProjectView from '../containers/ProjectView';
import { PlanType } from '../generated/account-frontend-api';
import Billing from '../pages/billing/Billing';
import EmbeddedProjectView from '../pages/EmbeddedProjectView';
import Invoices from '../pages/invoices/Invoices';
import PlanManagement from '../pages/plan-management/PlanManagement';
import Preferences from '../pages/preferences/Preferences';
import Projects from '../pages/Projects';
import SharedProjects from '../pages/SharedProjects';
import Site from '../pages/site/Site';
import Storage from '../pages/storage/Storage';
import Time from '../pages/time/Time';
import Unsubscribe from '../pages/unsubscribe/Unsubscribe';
import UpgradePlan from '../pages/upgrade-plan/UpgradePlan';
import UserAgreement from '../pages/user-agreement/UserAgreement';
import { Routes, unprotectedRoutes } from '../sharedConstants';
import { useSelector } from '../store';
import Layout from './Layout';
import PlaceholderPage from './PlaceholderPage';
import useHistoryListener from './useHistoryListener';
import useScrollResetOnRouteChange from './useScrollResetOnRouteChange';
import Folder from '../pages/folder/Folder';

export function App() {
    const location = useLocation();
    const firstName = useSelector(state => state.user.firstName);
    const serverErrorNotification = useSelector(state => state.globalFlags.serverErrorNotification);
    const isBillingActive = useSelector(state => state.init.isBillingActive);
    const currentPlan = useSelector(state => state.accountResources.account.plan.current);

    const isCommercial = (currentPlan && currentPlan.type === PlanType.COMMERCIAL) || false;
    const isFree = (currentPlan && currentPlan.type === PlanType.FREE) || false;

    useScrollResetOnRouteChange();
    useHistoryListener();

    const isUnprotectedRoute = !!matchPath(window.location.pathname, { path: unprotectedRoutes, exact: true });

    return !!firstName || isUnprotectedRoute ? (
        <Layout serverErrorNotification={serverErrorNotification} isCommercial={isCommercial} isFree={isFree}>
            <Switch>
                <Route exact path={Routes.PROJECT_VIEW}>
                    <ProjectView />
                </Route>
                <Route
                    exact
                    path={Routes.SHARED_PROJECT_VIEW}
                    render={props => {
                        if (location.search && location.search !== '?preview') {
                            location.search = '';
                        }
                        return <ProjectView />;
                    }}
                />

                <Route exact path={Routes.EMBEDDED_PROJECT_VIEW}>
                    <EmbeddedProjectView />
                </Route>
                <Route exact path={Routes.INDEX} component={Projects} />
                <Route exact path={Routes.SHARED} component={SharedProjects} />
                <Route exact path={Routes.FOLDER}>
                    <Folder />
                </Route>
                <Route exact path={[Routes.SITE_VIEW]}>
                    <ProjectView />
                </Route>
                <Route exact path={[Routes.SITE]}>
                    <Site />
                </Route>

                {/* It's not possible to group these <Route> under one condition, because react-router doesn't support Fragments */}
                {isBillingActive && (
                    <Route exact path={Routes.BILLING}>
                        <Billing />
                    </Route>
                )}
                {isBillingActive && (
                    <Route exact path={Routes.INVOICES}>
                        <Invoices />
                    </Route>
                )}
                {isBillingActive && (
                    <Route exact path={Routes.PLAN_MANAGEMENT}>
                        <PlanManagement />
                    </Route>
                )}
                {isBillingActive && isCommercial && (
                    <Route exact path={Routes.STORAGE}>
                        <Storage />
                    </Route>
                )}
                {isBillingActive && isCommercial && (
                    <Route exact path={Routes.TIME}>
                        <Time />
                    </Route>
                )}
                {isBillingActive && (
                    <Route exact path={Routes.UPGRADE_PLAN}>
                        <UpgradePlan />
                    </Route>
                )}

                <Route exact path={Routes.UNSUBSCRIBE}>
                    <Unsubscribe />
                </Route>
                <Route exact path={Routes.PREFERENCES}>
                    <Preferences />
                </Route>
                <Route exact path={Routes.USER_AGREEMENT}>
                    <UserAgreement />
                </Route>
                <Route path={'*'}>
                    <PlaceholderPage type={'404'} />
                </Route>
            </Switch>
        </Layout>
    ) : null;
}
