import { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import ProjectViewAccessContext from '../contexts/ProjectViewAccessContext';
import { Routes } from '../sharedConstants';
import { AppDispatch, useSelector } from '../store';
import { selectUploadingDatasets } from '../store/slices/datasets';
import { selectPendingUploads } from '../store/slices/datasetsUpload';
import { setUndoableOperation } from '../store/slices/projectView';

export default function useOnBeforeUnload(): void {
    const dispatch: AppDispatch = useDispatch();
    const undoableOperation = useSelector(state => state.projectView.undoableOperation);
    const temporaryLayers = useSelector(state => state.project.structure.temporaryLayers);
    const uploadingDatasets = useSelector(selectUploadingDatasets);
    const pendingUploads = useSelector(state => selectPendingUploads(state));
    const { owned } = useContext(ProjectViewAccessContext);
    const isNotOwnerPage = useRouteMatch({
        path: [Routes.EMBEDDED_PROJECT_VIEW, Routes.SHARED_PROJECT_VIEW],
        exact: true
    });

    const commitOperation = useCallback(() => {
        undoableOperation?.commit();
        dispatch(setUndoableOperation(undefined));
    }, [dispatch, undoableOperation]);

    useEffect(() => {
        window.onbeforeunload = () => {
            commitOperation();
            if (isNotOwnerPage) {
                if (!owned && temporaryLayers.filter(l => l.isTemporary).length) return true;

                if (pendingUploads.length || uploadingDatasets.length) return true;
            }
        };

        return () => {
            window.onbeforeunload = () => {};
        };
    }, [
        commitOperation,
        owned,
        temporaryLayers,
        pendingUploads.length,
        uploadingDatasets.length,
        isNotOwnerPage,
        dispatch
    ]);
}
