import { useTranslation } from 'react-i18next';
import PresentationCloseControl from './elements/PresentationCloseControl';

export default function PresentationToolBreadcrumbs() {
    const { t } = useTranslation(['projectView']);

    return (
        <>
            <div className='tool-title'>
                <div className='tool-icon'>
                    <i className='icon icon-presentation' />
                </div>
                <span className='tool-text'>{t('projectView:presentationTool.breadcrumbsTitle')}</span>
            </div>
            <PresentationCloseControl />
        </>
    );
}
