import { useTranslation } from 'react-i18next';
import { PlanType } from '../../../generated/account-frontend-api';
import { ReactComponent as SvgCheck } from '../../../svg/cabinet/check.svg';
import { ReactComponent as SvgMinus } from '../../../svg/cabinet/minus.svg';

import styles from '../PlanManagement.module.scss';

type Props = {
    planType: PlanType;
};

export default function PlanItemDetails({ planType }: Props) {
    const { t } = useTranslation('cabinet');
    const freePlanDetailsPlus = [
        t('plans.freePlan.detailsPlus.plus1'),
        t('plans.freePlan.detailsPlus.plus2'),
        t('plans.freePlan.detailsPlus.plus3'),
        t('plans.freePlan.detailsPlus.plus4'),
        t('plans.freePlan.detailsPlus.plus5'),
        t('plans.freePlan.detailsPlus.plus6'),
        t('plans.freePlan.detailsPlus.plus7'),
        t('plans.freePlan.detailsPlus.plus8')
    ];
    const freePlanDetailsMinus = [
        t('plans.freePlan.detailsMinus.minus1'),
        t('plans.freePlan.detailsMinus.minus2'),
        t('plans.freePlan.detailsMinus.minus3')
    ];

    const commericalPlanDetailsPlus = [
        t('plans.commercialPlan.detailsPlus.plus1'),
        t('plans.commercialPlan.detailsPlus.plus2'),
        t('plans.commercialPlan.detailsPlus.plus3')
    ];

    const commericalPlanDetailsMinus = [t('plans.commercialPlan.detailsMinus.minus1')];

    const detailsPlus = planType === PlanType.FREE ? freePlanDetailsPlus : commericalPlanDetailsPlus;
    const detailsMinus = planType === PlanType.FREE ? freePlanDetailsMinus : commericalPlanDetailsMinus;

    return (
        <>
            {detailsPlus.map((item: string, index: number) => {
                return (
                    <div className={styles.detailsItem} key={index}>
                        <div className={styles.detailsIcon}>
                            <SvgCheck />
                        </div>
                        <span>{item}</span>
                    </div>
                );
            })}
            {detailsMinus.map((item: string, index: number) => {
                return (
                    <div className={styles.detailsItem} key={index}>
                        <div className={styles.detailsIcon}>
                            <SvgMinus />
                        </div>
                        <span>{item}</span>
                    </div>
                );
            })}
        </>
    );
}
