import classNames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { memo, useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeNodeData, FixedSizeTree, TreeWalker, TreeWalkerValue } from 'react-vtree';
import CompareStructureTreeContext from '../../../contexts/CompareStructureTreeContext';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { SourceType, Status } from '../../../generated/cloud-frontend-api';
import { AppDispatch, useSelector } from '../../../store';
import { ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { ExtendedDatasetInfo } from '../../../store/slices/datasetfilesUpload';
import { VectorLayerUploadInfo } from '../../../store/slices/datasetsUpload';
import { selectStructureTreeByTreeId } from '../../../store/slices/projectStructure';
import StructureTreeNode from './StructureTreeNode';
import TreeDragDrop from './TreeDragDrop';
import useAutoScrollOnSelect from './useAutoScrollOnSelect';

export type TreeNode = Readonly<{
    children: TreeNode[];
    id: string;
    name: string;
    nestingLevel: number;
    type: ProjectStructureObjectTypes;
    parentId: string | null;
    status?: Status | VectorLayerUploadInfo['status'] | NonNullable<ExtendedDatasetInfo['sourceData']>['status'];
    isOpenByDefault: boolean;
    sourceType?: SourceType;
}>;

export type TreeData = FixedSizeNodeData &
    Readonly<{
        isLeaf: boolean;
        name: string;
        nestingLevel: number;
        node: TreeNode;
    }>;

type NodeMeta = Readonly<{
    nestingLevel: number;
    node: TreeNode;
}>;

const getNodeData = (node: TreeNode, nestingLevel: number): TreeWalkerValue<TreeData, NodeMeta> => ({
    data: {
        id: node.id,
        isLeaf: node.children.length === 0,
        isOpenByDefault: node.isOpenByDefault,
        name: node.name,
        nestingLevel,
        node
    },
    nestingLevel,
    node
});

function StructureTree() {
    const dispatch: AppDispatch = useDispatch();
    const compareContext = useContext(CompareStructureTreeContext);
    const refTree = useRef<FixedSizeTree>(null!);
    const scrollbar = useRef<OverlayScrollbarsComponent>(null);
    const { isInEmbedView } = useContext(ProjectViewAccessContext);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const sortedNodes = useSelector(state => selectStructureTreeByTreeId(state, compareContext?.treeId));

    useAutoScrollOnSelect(refTree);

    const tree: TreeNode = {
        id: projectInfo.id!,
        name: 'Root',
        isOpenByDefault: true,
        parentId: null,
        nestingLevel: -1,
        type: ProjectStructureObjectTypes.ROOT,
        children: sortedNodes
    };

    function* treeWalker(): ReturnType<TreeWalker<TreeData, NodeMeta>> {
        for (let i = 0; i < tree.children.length; i++) {
            yield getNodeData(tree.children[i], 0);
        }

        while (true) {
            const parentMeta = yield;

            for (let i = 0; i < parentMeta.node.children.length; i++) {
                yield getNodeData(parentMeta.node.children[i], parentMeta.nestingLevel + 1);
            }
        }
    }

    if (!sortedNodes.length) return null;

    return (
        <AutoSizer disableWidth>
            {({ height }) => (
                <OverlayScrollbarsComponent
                    ref={scrollbar}
                    options={{
                        callbacks: {
                            onScroll(e) {
                                if (e?.target && refTree.current) {
                                    const { scrollTop } = e.target as HTMLDivElement;
                                    refTree.current.scrollTo(scrollTop);
                                }
                            }
                        }
                    }}
                    className={classNames('os-host-flexbox', { 'os-theme-light': isInEmbedView })}
                >
                    <TreeDragDrop>
                        <FixedSizeTree
                            style={{ overflow: 'visible' }}
                            ref={refTree}
                            treeWalker={treeWalker}
                            itemSize={42}
                            height={height}
                            width='100%'
                            overscanCount={5}
                            onScroll={({ scrollOffset }) => {
                                scrollbar.current?.osInstance()?.scroll({ x: 0, y: scrollOffset });
                            }}
                        >
                            {StructureTreeNode as any}
                        </FixedSizeTree>
                    </TreeDragDrop>
                </OverlayScrollbarsComponent>
            )}
        </AutoSizer>
    );
}

export default StructureTree;
