import produce from 'immer';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Scene, useCesium } from 'resium';
import appAxios from '../../../api/appAxios';
import { geometryApi } from '../../../api/initApis';
import { inspectorMainImageThumbnailParam } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { selectGeometries, updateGeometryContent } from '../../../store/slices/geometries';
import { commitGeometry, selectTours, updateGeometryById } from '../../../store/slices/geometryLayers';
import { setPovSaveRequested } from '../../../store/slices/presentation';
import { setIdOfGeometryWithSavingPreview, setViewerError } from '../../../store/slices/projectView';
import {
    createProjectPreview,
    saveRootStructureProperty,
    setDefaultPOVSaveRequested
} from '../../../store/slices/structure';
import { getCameraPointOfView } from '../geometry-drawing/DrawViewpoint';

const MAX_PREVIEW_WIDTH = 720;
const MAX_PREVIEW_HEIGHT = 720;

export default function AppScene() {
    const dispatch: AppDispatch = useDispatch();
    const { viewer, camera } = useCesium();
    const povSaveRequestSlideId = useSelector(state => state.presentation.povSaveRequestSlideId);
    const defaultPOVSaveRequested = useSelector(state => state.structure.defaultPOVSaveRequested);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const geometries = useSelector(selectGeometries);
    const tours = useSelector(state => selectTours(state));

    useEffect(() => {
        if (defaultPOVSaveRequested) {
            dispatch(
                saveRootStructureProperty({
                    propertyName: 'defaultPointOfView',
                    propertyValue: getCameraPointOfView(camera!)
                })
            );
        }
    }, [defaultPOVSaveRequested, camera, dispatch]);

    useEffect(() => {
        if (povSaveRequestSlideId) {
            viewer?.scene.requestRender();
        }
    }, [povSaveRequestSlideId, viewer?.scene]);

    return (
        <Scene
            useDepthPicking={true}
            pickTranslucentDepth={false}
            onRenderError={() => {
                dispatch(setViewerError(true));
            }}
            onPostRender={async () => {
                // Because of requestRenderMode canvas screenshots are only available onPostRender
                if (povSaveRequestSlideId) {
                    dispatch(setPovSaveRequested(''));
                    dispatch(setIdOfGeometryWithSavingPreview(povSaveRequestSlideId));

                    // The canvas screenshot must be captured as early as possible in this callback, otherwise it will capture emptiness
                    const resizedCanvas = await resizeImage(
                        viewer!.canvas.toDataURL('image/jpeg'),
                        inspectorMainImageThumbnailParam.width,
                        inspectorMainImageThumbnailParam.height
                    );
                    const preview = (await canvasToBlobJpeg(resizedCanvas)) as Blob;

                    const geometry = geometries[povSaveRequestSlideId];
                    if (geometry) {
                        const layer = tours.find(l => l.geometries.includes(povSaveRequestSlideId));

                        try {
                            const { data } = await geometryApi.createGeometryDocumentUpload(
                                projectInfo.id!,
                                layer?.id!,
                                povSaveRequestSlideId,
                                {
                                    name: geometry.content.properties.ac_name ?? '',
                                    contentType: 'application/octet-stream',
                                    sizeInBytes: preview.size
                                }
                            );
                            await appAxios.put(data.url!, preview);
                            await geometryApi.completeGeometryDocumentUpload(
                                projectInfo.id!,
                                layer?.id!,
                                povSaveRequestSlideId,
                                data.documentUid!
                            );
                            dispatch(
                                updateGeometryContent({
                                    id: povSaveRequestSlideId,
                                    geoJson: produce(geometry.content, draft => {
                                        draft.properties.ac_preview_uid = data.documentUid!;
                                        draft.properties.ac_point_of_view = getCameraPointOfView(camera!);
                                    })
                                })
                            );
                            await dispatch(updateGeometryById(povSaveRequestSlideId));
                        } finally {
                            dispatch(setIdOfGeometryWithSavingPreview(undefined));
                        }
                    }
                }

                if (defaultPOVSaveRequested) {
                    dispatch(setDefaultPOVSaveRequested(false));
                    const currentViewCanvas = await resizeImage(
                        viewer!.canvas.toDataURL('image/jpeg', 0.8),
                        MAX_PREVIEW_WIDTH,
                        MAX_PREVIEW_HEIGHT
                    );
                    const blob: Blob = await new Promise((resolve, reject) =>
                        currentViewCanvas.toBlob(blob => (blob ? resolve(blob) : reject(blob)), 'image/jpeg')
                    );
                    dispatch(createProjectPreview({ projectId: projectInfo.id!, image: blob }));
                }
            }}
        />
    );
}

function resizeImage(base64Str: string, maxWidth: number, maxHeight: number): Promise<HTMLCanvasElement> {
    return new Promise(resolve => {
        let img = new Image();
        img.src = base64Str;
        img.onload = () => {
            let canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext('2d')!;
            ctx.drawImage(img, 0, 0, width, height);
            resolve(canvas);
        };
    });
}

function canvasToBlobJpeg(canvas: HTMLCanvasElement): Promise<Blob | null> {
    return new Promise(resolve => {
        canvas.toBlob(blob => {
            resolve(blob);
        }, 'image/jpeg');
    });
}
