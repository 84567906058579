import { createAsyncThunk } from '@reduxjs/toolkit';
import { documentApi } from '../../api/initApis';

const name = 'documents';

interface GetDocumentArgs {
    projectUid: string;
    documentUid: string;
    access?: string;
}
export const getDocumentUrl = createAsyncThunk(
    `${name}/getUrl`,
    async ({ documentUid, projectUid, access }: GetDocumentArgs) => {
        const { data } = await documentApi.getPresignedUrl(projectUid, documentUid, access);
        return data.url;
    }
);
