import * as Cesium from 'cesium';
import { useEffect, useMemo } from 'react';
import {
    formatCoordinatesWithUnits,
    getRelatedAxisFullName,
    transformCoordinates
} from '../../../lib/coordinatesHelper';
import formatNumber from '../../../lib/formatNumber';
import { getUnitsShortName } from '../../../lib/getUnitsShortName';
import { useSelector } from '../../../store';
import { GeoJson } from '../../../store/helpers/interfaces';
import GeneralProperty from '../../inspection-sidebar/GeneralProperty';

type Props = {
    geoJson: GeoJson;
    setTextForCopying(text: string): void;
};

export default function PointProperties({ geoJson, setTextForCopying }: Props) {
    const coordinates = geoJson.geometry.coordinates as number[];
    const coordinateSystem = useSelector(store => store.coordinateSystems.currentCrs);
    const units = useSelector(store => store.coordinateSystems.units);

    const transformedCoords = useMemo(
        () =>
            transformCoordinates(
                Cesium.Cartesian3.fromDegrees(coordinates[0], coordinates[1], coordinates[2]),
                coordinateSystem,
                units
            )!,
        [coordinates, coordinateSystem, units]
    );
    const formattedCoords = useMemo(() => {
        if (transformedCoords) {
            return formatCoordinatesWithUnits(transformedCoords, coordinateSystem, units);
        }
        return [`-`, `-`, `-`];
    }, [transformedCoords, coordinateSystem, units]);

    const unitsNaming = getUnitsShortName(units);
    const elevation = useMemo(
        () =>
            transformedCoords && !coordinateSystem.invalidateHeight
                ? `${formatNumber(transformedCoords!.z, 3)} ${unitsNaming}`
                : `-`,
        [transformedCoords, unitsNaming, coordinateSystem]
    );
    const axisFullName = useMemo(
        () => (coordinateSystem.type ? getRelatedAxisFullName(coordinateSystem.type) : [`-`, `-`, `-`]),
        [coordinateSystem.type]
    );

    useEffect(() => {
        setTextForCopying(
            `${axisFullName[0]}\t${formattedCoords[0]}\n${axisFullName[1]}\t${formattedCoords[1]}\n${
                axisFullName[2]
            }\t${coordinateSystem.invalidateHeight ? '-' : formattedCoords[2]}`
        );
    }, [setTextForCopying, axisFullName, formattedCoords, coordinateSystem.invalidateHeight]);

    return (
        <>
            <GeneralProperty label={axisFullName[0]} value={formattedCoords[0]} />
            <GeneralProperty label={axisFullName[1]} value={formattedCoords[1]} />
            <GeneralProperty label={axisFullName[2]} value={`${elevation}`} />
        </>
    );
}
