import * as Cesium from 'cesium';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Primitive } from 'resium';
import { GeometryTypes, primitiveOptimizationProps } from '../../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../../store';
import { ProjectStructureObjectTypes, TemporaryGeometry } from '../../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../../store/selectors';
import { updateGeometryProperty } from '../../../../store/slices/geometries';
import { defaultBlueCss, polylineWidth, polylinesAppearance } from '../styling';
import { GeometryPrimitiveProps } from './GeometryPrimitives';
import { adjustAlpha } from '../../../../lib/adjustAlpha';

type Props = GeometryPrimitiveProps<GeometryTypes.POLYLINE>;

export default function PolylinePrimitive({
    geometries,
    hasPrimitiveRendered,
    onMouseLeave,
    onMouseEnter,
    setPrimitiveHasRendered,
    layerVisible
}: Props) {
    const dispatch: AppDispatch = useDispatch();
    const polylinesPrimitive = useRef<Cesium.Primitive>(null!);
    const [polylines, setPolylines] = useState<Cesium.GeometryInstance[]>([]);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));

    function changeVisibility(id: string, show = false) {
        if (polylinesPrimitive.current.ready) {
            const attributes = polylinesPrimitive.current.getGeometryInstanceAttributes(id);
            attributes.show = Cesium.ShowGeometryInstanceAttribute.toValue(show);
        }
    }

    function changeColor(id: string, color: Cesium.Color) {
        if (polylinesPrimitive.current.ready) {
            const attributes = polylinesPrimitive.current.getGeometryInstanceAttributes(id);
            attributes.color = Cesium.ColorGeometryInstanceAttribute.toValue(adjustAlpha(color));
        }
    }

    useEffect(() => {
        if (hasPrimitiveRendered || !geometries.length) return;

        setPolylines(
            geometries.map(g => {
                return new Cesium.GeometryInstance({
                    geometry: new Cesium.PolylineGeometry({
                        width: polylineWidth,
                        arcType: Cesium.ArcType.NONE,
                        positions: Cesium.Cartesian3.fromDegreesArrayHeights(g.content.geometry.coordinates.flat()),
                        vertexFormat: Cesium.PolylineColorAppearance.VERTEX_FORMAT
                    }),
                    id: g.id,
                    attributes: {
                        color: Cesium.ColorGeometryInstanceAttribute.fromColor(
                            adjustAlpha(
                                Cesium.Color.fromCssColorString(g.content.properties.ac_color || defaultBlueCss)
                            )
                        ),
                        show: new Cesium.ShowGeometryInstanceAttribute(
                            layerVisible && g.content.properties.ac_visibility
                        )
                    }
                });
            })
        );
        setPrimitiveHasRendered(renderingState => ({ ...renderingState, [GeometryTypes.POLYLINE]: true }));
    }, [layerVisible, hasPrimitiveRendered, geometries, setPrimitiveHasRendered]);

    useEffect(() => {
        if (selectedObject?.type === ProjectStructureObjectTypes.GEOMETRY && selectedObjectInfo) {
            const geometry = selectedObjectInfo as TemporaryGeometry;
            if (geometries.map(g => g.id).includes(geometry.id) && !geometry.renderAsEntity) {
                dispatch(
                    updateGeometryProperty({
                        id: geometry.id,
                        propName: 'renderAsEntity',
                        propValue: layerVisible && geometry.content.properties.ac_visibility
                    })
                );
                changeVisibility(geometry.id);
            }
        }
    }, [layerVisible, selectedObjectInfo, selectedObject, dispatch, geometries]);

    useEffect(() => {
        for (const geometry of geometries) {
            if (geometry.renderAsEntity) continue;

            if (layerVisible && geometry.content.properties.ac_visibility) {
                changeVisibility(geometry.id, true);
            } else {
                changeVisibility(geometry.id);
            }
            changeColor(
                geometry.id,
                adjustAlpha(Cesium.Color.fromCssColorString(geometry.content.properties.ac_color || defaultBlueCss))
            );
        }
    }, [geometries, layerVisible]);

    return geometries.length ? (
        <Primitive
            ref={el => {
                polylinesPrimitive.current = el?.cesiumElement!;
            }}
            geometryInstances={polylines}
            appearance={polylinesAppearance}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            {...primitiveOptimizationProps}
        />
    ) : null;
}
