import classNames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { Dataset } from '../../../entities/Dataset';
import { Status } from '../../../generated/dataset-api';
import { useSelector } from '../../../store';
import {
    ExtendedChunk,
    isGeometry,
    isIssuePointGeometry,
    isLayer,
    isViewpointGeometry,
    ProjectStructureObjectTypes,
    TemporaryLayer
} from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { ExtendedCamera, selectCameras } from '../../../store/slices/cameras';
import { ExtendedDatasetInfo } from '../../../store/slices/datasetfilesUpload';
import { isLinkedDataset } from '../../../store/slices/datasets';
import { adaptVectorLayerToDataset } from '../../../store/slices/datasetsUpload';
import { isVectorLayer } from '../../../store/slices/geometryLayers';
import DatasetGeneralProperties from '../../ProjectView/dataset-upload-info/DatasetGeneralProperties';
import GeometryProperties from '../../ProjectView/geometry-properties/GeometryProperties';
import ParentProjectProperty from '../../ProjectView/parent-project-property/ParentProjectProperty';
import Relations from '../../ProjectView/relations/Relations';
import InspectionReport from '../inspection-report/InspectionReport';
import InspectorTitle from '../inspector-title/InspectorTitle';
import MeasurementsReport from '../measurements-report/MeasurementsReport';
import PreviewImage from '../preview-image/PreviewImage';
import PreviewIssueImage from '../preview-image/PreviewIssueImage';
import { ViewpointPreviewImage } from '../preview-image/ViewpointPreviewImage';
import ProcessingReport from '../processing-report/ProcessingReport';
import CameraGeneralProperties from './CameraGeneralProperties';
import DatasetStyleProperties from './DatasetStyleProperties';
import ImagesGeneralProperties from './ImagesGeneralProperties';
import IssueGeneralProperties from './IssueGeneralProperties';
import LayerProperties from './LayerProperties';
import SlideGeneralProperties from './SlideGeneralProperties,';

export default function ElementInspector() {
    const { t } = useTranslation(['projectView', 'glossary']);
    const { owned } = useContext(ProjectViewAccessContext);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const temporaryLayers = useSelector(state => state.project.structure.temporaryLayers);
    const cameras = useSelector(selectCameras);
    const datasets = useSelector(state => state.datasets.datasets);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));
    const uploads = useSelector(state => state.datasetsUpload.uploads);

    const processingReport =
        selectedObject?.type === ProjectStructureObjectTypes.CHUNK
            ? (selectedObjectInfo as ExtendedChunk)?.processingReport
            : undefined;

    const datasetPresenter = useMemo(() => {
        if (selectedObject?.type === ProjectStructureObjectTypes.DATASET)
            return new Dataset(selectedObjectInfo as ExtendedDatasetInfo);
        return null;
    }, [selectedObjectInfo, selectedObject]);

    const hasMeasurementsReport =
        isLayer(selectedObjectInfo) && selectedObjectInfo.geometries.length > 0 && isVectorLayer(selectedObjectInfo);

    const hasInspectionReport =
        isLayer(selectedObjectInfo) && selectedObjectInfo.geometries.length > 0 && selectedObjectInfo.isInspection;

    const isLayerTemporary = isLayer(selectedObjectInfo) && selectedObjectInfo?.isTemporary;

    const isGeometryTemporary =
        isGeometry(selectedObjectInfo) &&
        temporaryLayers.find(tl => tl.geometries.includes(selectedObjectInfo.id))?.isTemporary;

    const isLayerLinkedFromAnotherProject =
        isLayer(selectedObjectInfo) && isLinkedDataset(selectedObjectInfo, projectInfo.id!);

    const isGeometryLinkedFromAnotherObject =
        isGeometry(selectedObjectInfo) &&
        isLinkedDataset(temporaryLayers.find(l => l.geometries.includes(selectedObjectInfo.id))!, projectInfo.id!);

    const isDatasetLinkedFromAnotherProject =
        selectedObject?.type === ProjectStructureObjectTypes.DATASET &&
        isLinkedDataset(selectedObjectInfo as ExtendedDatasetInfo, projectInfo.id!);

    const viewOnly =
        (!owned && !datasetPresenter?.hasFailedValidation && !isLayerTemporary && !isGeometryTemporary) ||
        isLayerLinkedFromAnotherProject ||
        isGeometryLinkedFromAnotherObject ||
        isDatasetLinkedFromAnotherProject;

    const datasetFileUpload = datasets.find(d => d.datasetUid === selectedObject.artifactId);

    const isVisualDataReady = datasetFileUpload?.visualData?.status === Status.COMPLETED;

    const { isInEmbedView } = useContext(ProjectViewAccessContext);

    const scrollableContanerRef = useRef<OverlayScrollbarsComponent>(null);

    const showParentProject = owned && (isLayerLinkedFromAnotherProject || isDatasetLinkedFromAnotherProject);

    const isLinkedToOtherProjects =
        [
            ProjectStructureObjectTypes.DATASET,
            ProjectStructureObjectTypes.ARTIFACT,
            ProjectStructureObjectTypes.LAYER
        ].includes(selectedObject?.type!) &&
        (selectedObjectInfo as ExtendedDatasetInfo)?.parentProject?.uid === projectInfo.id &&
        Boolean((selectedObjectInfo as ExtendedDatasetInfo)?.linkedProjects?.length);

    const upload = uploads[selectedObject.artifactId!];

    if (!selectedObjectInfo)
        return (
            <section className='inspector-tooltips'>
                <div className='inspector-tip'>
                    <div>{t('projectView:inspectionSidebar.tip_nothingSelected')}</div>
                </div>
            </section>
        );

    return (
        <>
            <InspectorTitle viewOnly={viewOnly} />
            <OverlayScrollbarsComponent
                className={classNames('inspector-section', { 'os-theme-light': isInEmbedView })}
                ref={scrollableContanerRef}
            >
                <div className='inspector-section-content'>
                    {selectedObject.type === ProjectStructureObjectTypes.IMAGE && (
                        <PreviewImage key={selectedObject?.artifactId} camera={selectedObjectInfo as ExtendedCamera} />
                    )}

                    {isGeometry(selectedObjectInfo) && isIssuePointGeometry(selectedObjectInfo) && (
                        <PreviewIssueImage
                            key={selectedObject?.artifactId}
                            camera={cameras.find(
                                c => c.photoUid === selectedObjectInfo.content.properties.ac_image_uid
                            )}
                        />
                    )}

                    {isGeometry(selectedObjectInfo) && isViewpointGeometry(selectedObjectInfo) && (
                        <ViewpointPreviewImage key={selectedObject?.artifactId} />
                    )}

                    {[ProjectStructureObjectTypes.ARTIFACT, ProjectStructureObjectTypes.DATASET].includes(
                        selectedObject.type
                    ) && (
                        <>
                            {isVisualDataReady && <DatasetStyleProperties />}
                            <DatasetGeneralProperties />
                        </>
                    )}

                    {selectedObject.type === ProjectStructureObjectTypes.CAMERAS && <ImagesGeneralProperties />}
                    {selectedObject.type === ProjectStructureObjectTypes.IMAGE && <CameraGeneralProperties />}
                    {isGeometry(selectedObjectInfo) &&
                        !isIssuePointGeometry(selectedObjectInfo) &&
                        !isViewpointGeometry(selectedObjectInfo) && (
                            <GeometryProperties viewOnly={viewOnly} isLinked={isGeometryLinkedFromAnotherObject} />
                        )}
                    {isGeometry(selectedObjectInfo) && isIssuePointGeometry(selectedObjectInfo) && (
                        <IssueGeneralProperties
                            viewOnly={viewOnly}
                            containerRef={scrollableContanerRef.current?.osTarget()!}
                        />
                    )}
                    {isGeometry(selectedObjectInfo) && isViewpointGeometry(selectedObjectInfo) && (
                        <SlideGeneralProperties
                            viewOnly={viewOnly}
                            containerRef={scrollableContanerRef.current?.osTarget()!}
                        />
                    )}
                    {isLayer(selectedObjectInfo) && <LayerProperties viewOnly={viewOnly} />}
                    {showParentProject && (
                        <ParentProjectProperty
                            dataset={
                                selectedObjectInfo && 'datasetUid' in selectedObjectInfo
                                    ? selectedObjectInfo
                                    : adaptVectorLayerToDataset(selectedObjectInfo as TemporaryLayer, upload)
                            }
                        />
                    )}
                    {owned && isLinkedToOtherProjects && (
                        <Relations
                            dataset={
                                selectedObjectInfo && 'datasetUid' in selectedObjectInfo
                                    ? selectedObjectInfo
                                    : adaptVectorLayerToDataset(selectedObjectInfo as TemporaryLayer, upload)
                            }
                        />
                    )}
                    {selectedObject.type === ProjectStructureObjectTypes.CHUNK && processingReport && (
                        <ProcessingReport report={processingReport} />
                    )}
                    {hasMeasurementsReport && <MeasurementsReport />}
                    {hasInspectionReport && <InspectionReport />}
                </div>
            </OverlayScrollbarsComponent>
        </>
    );
}
