import * as Cesium from 'cesium';
import { useMemo } from 'react';
import { ImageryLayer } from 'resium';
import { useSelector } from '../../../../store';
import { BaseLayerInfo } from '../../../../store/slices/init';

type Props = {
    baseLayer: BaseLayerInfo;
};

export default function BaseUrlImageryLayer({ baseLayer }: Props) {
    const baseImageryProvider = useSelector(state => state.projectView.baseImageryProvider);

    const resource = useMemo(() => new Cesium.Resource(baseLayer.resource), [baseLayer]);

    const credit = useMemo(() => new Cesium.Credit(baseLayer.creditHtml), [baseLayer.creditHtml]);

    const provider = useMemo(
        () =>
            new Cesium.UrlTemplateImageryProvider({
                url: resource,
                credit,
                minimumLevel: baseLayer.minimumLevel,
                maximumLevel: baseLayer.maximumLevel,
                tileWidth: baseLayer.tileWidth,
                tileHeight: baseLayer.tileHeight
            }),
        [resource, credit, baseLayer.minimumLevel, baseLayer.maximumLevel, baseLayer.tileWidth, baseLayer.tileHeight]
    );

    return <ImageryLayer imageryProvider={provider} show={baseLayer.id === baseImageryProvider} index={0} />;
}
