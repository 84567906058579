/* tslint:disable */
/* eslint-disable */
/**
 * pipeline-api
 * Pipeline API that allows to get pipelines
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Pipeline } from '../model';
// @ts-ignore
import { PipelineType } from '../model';
// @ts-ignore
import { View } from '../model';
/**
 * PipelineApi - axios parameter creator
 * @export
 */
export const PipelineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get last launched pipeline
         * @param {string} projectUid UUID of project
         * @param {Array<PipelineType>} [types] Pipeline types
         * @param {View} [view] View of pipeline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastLaunchedPipeline: async (projectUid: string, types?: Array<PipelineType>, view?: View, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getLastLaunchedPipeline', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}/pipelines/lastLaunched`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (view !== undefined) {
                localVarQueryParameter['view'] = view;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get pipeline
         * @param {string} projectUid UUID of project
         * @param {string} pipelineUid Uid of pipeline
         * @param {View} [view] View of pipeline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPipeline: async (projectUid: string, pipelineUid: string, view?: View, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getPipeline', 'projectUid', projectUid)
            // verify required parameter 'pipelineUid' is not null or undefined
            assertParamExists('getPipeline', 'pipelineUid', pipelineUid)
            const localVarPath = `/projects/{projectUid}/pipelines/{pipelineUid}`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"pipelineUid"}}`, encodeURIComponent(String(pipelineUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)

            if (view !== undefined) {
                localVarQueryParameter['view'] = view;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PipelineApi - functional programming interface
 * @export
 */
export const PipelineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PipelineApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get last launched pipeline
         * @param {string} projectUid UUID of project
         * @param {Array<PipelineType>} [types] Pipeline types
         * @param {View} [view] View of pipeline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastLaunchedPipeline(projectUid: string, types?: Array<PipelineType>, view?: View, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pipeline>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastLaunchedPipeline(projectUid, types, view, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get pipeline
         * @param {string} projectUid UUID of project
         * @param {string} pipelineUid Uid of pipeline
         * @param {View} [view] View of pipeline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPipeline(projectUid: string, pipelineUid: string, view?: View, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pipeline>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPipeline(projectUid, pipelineUid, view, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PipelineApi - factory interface
 * @export
 */
export const PipelineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PipelineApiFp(configuration)
    return {
        /**
         * 
         * @summary Get last launched pipeline
         * @param {string} projectUid UUID of project
         * @param {Array<PipelineType>} [types] Pipeline types
         * @param {View} [view] View of pipeline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastLaunchedPipeline(projectUid: string, types?: Array<PipelineType>, view?: View, options?: any): AxiosPromise<Pipeline> {
            return localVarFp.getLastLaunchedPipeline(projectUid, types, view, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get pipeline
         * @param {string} projectUid UUID of project
         * @param {string} pipelineUid Uid of pipeline
         * @param {View} [view] View of pipeline
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPipeline(projectUid: string, pipelineUid: string, view?: View, options?: any): AxiosPromise<Pipeline> {
            return localVarFp.getPipeline(projectUid, pipelineUid, view, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PipelineApi - object-oriented interface
 * @export
 * @class PipelineApi
 * @extends {BaseAPI}
 */
export class PipelineApi extends BaseAPI {
    /**
     * 
     * @summary Get last launched pipeline
     * @param {string} projectUid UUID of project
     * @param {Array<PipelineType>} [types] Pipeline types
     * @param {View} [view] View of pipeline
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public getLastLaunchedPipeline(projectUid: string, types?: Array<PipelineType>, view?: View, options?: AxiosRequestConfig) {
        return PipelineApiFp(this.configuration).getLastLaunchedPipeline(projectUid, types, view, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get pipeline
     * @param {string} projectUid UUID of project
     * @param {string} pipelineUid Uid of pipeline
     * @param {View} [view] View of pipeline
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PipelineApi
     */
    public getPipeline(projectUid: string, pipelineUid: string, view?: View, options?: AxiosRequestConfig) {
        return PipelineApiFp(this.configuration).getPipeline(projectUid, pipelineUid, view, options).then((request) => request(this.axios, this.basePath));
    }
}
