import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import * as yup from 'yup';
import { ProjectType } from '../../../generated/cloud-frontend-api';
import useSiteViewQueryParams from '../../../hooks/useSiteViewQueryParams';
import { SiteRouteParams } from '../../../pages/site/Site';
import { Routes } from '../../../sharedConstants';
import { AppDispatch } from '../../../store';
import { createProject, resetProjects } from '../../../store/slices/projectsPage';
import Modal, { ModalProps } from '../modal/Modal';
import ModalActions from '../modal/ModalActions';
import ModalBody from '../modal/ModalBody';
import ModalHead from '../modal/ModalHead';
import { FolderRouteParams } from '../../../pages/folder/Folder';
import { unwrapResult } from '@reduxjs/toolkit';
import { useState } from 'react';
import { NestingLevelErrorModal } from '../modals/nesting-level-error-modal/NestingLevelErrorModal';

type Props = ModalProps & {
    projectType: typeof ProjectType.NON_METASHAPE | typeof ProjectType.SITE | typeof ProjectType.FOLDER;
};

const validationSchema = yup.object().shape({
    name: yup.string().required().trim().min(1).max(255, 'tooLong')
});

export default function AddProjectModal({ isOpen, setIsOpen, projectType }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('modals');
    const { id: siteId } = useParams<SiteRouteParams>();
    const { folderId } = useParams<FolderRouteParams>();
    const { setQueryProjectId } = useSiteViewQueryParams();
    const [hasCreationFailed, setHasCreationFailed] = useState(false);
    const isSiteViewPath = !!useRouteMatch({ path: Routes.SITE_VIEW, exact: true });

    if (hasCreationFailed) {
        return <NestingLevelErrorModal isOpen={isOpen} setIsOpen={setIsOpen} />;
    }

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onClick={e => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                setIsOpen(false); // close modal on overlay click
            }}
            modalClass='modal-add-project'
        >
            <ModalHead setIsOpen={setIsOpen}>
                {projectType === ProjectType.NON_METASHAPE && t('addProjectModal.head_createProject')}
                {projectType === ProjectType.SITE && t('addProjectModal.head_createSite')}
                {projectType === ProjectType.FOLDER && t('addProjectModal.head_createFolder')}
            </ModalHead>
            <ModalBody>
                <Formik
                    initialValues={{ name: '' }}
                    onSubmit={async values => {
                        const name = values.name.trim();
                        const parentUid = folderId ?? siteId;
                        const result = await dispatch(
                            createProject({ name, type: projectType, parentUid, surveyDate: new Date().toISOString() })
                        );

                        if (createProject.rejected.match(result)) {
                            setHasCreationFailed(true);
                            return;
                        }

                        const project = unwrapResult(result);
                        if (isSiteViewPath) {
                            setQueryProjectId(project.uid);
                        } else {
                            dispatch(resetProjects());
                        }
                        setIsOpen(false);
                    }}
                    validationSchema={validationSchema}
                    validateOnMount={true}
                >
                    {({ isValid, errors }) => (
                        <Form>
                            <Field
                                type='text'
                                name='name'
                                placeholder={t('addProjectModal.name.placeholder')}
                                autoComplete='off'
                            />

                            <div className='field-error'>
                                {errors.name === 'tooLong' && <span>{t('addProjectModal.name.errors.tooLong')}</span>}
                            </div>

                            <ModalActions>
                                <button
                                    type='button'
                                    className='modal-action btn-ghost-blue'
                                    onClick={() => setIsOpen(false)}
                                >
                                    {t('addProjectModal.cancel')}
                                </button>
                                <button type='submit' className='modal-action btn' disabled={!isValid}>
                                    {t('addProjectModal.create')}
                                </button>
                            </ModalActions>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
}
