import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Routes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { TemporaryLayer, ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { selectGeometries } from '../../../store/slices/geometries';
import { selectReportsPropertiesBlockExpansion, updateStructureInfo } from '../../../store/slices/structure';
import useGetDemsList from '../../../hooks/useGetDemsList';
import measurementsPDFStarter from './measurementsPDFStarter';
import MeasurementReportsDropdown from '../measurements-reports-dropdown/MeasurementsReportsDropdown';
import PropertiesBlockHead from '../../ProjectView/geometry-properties/elements/PropertiesBlockHead';

export default function MeasurementsReport() {
    const { t } = useTranslation(['common', 'projectView']);
    const dispatch: AppDispatch = useDispatch();
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));
    const projectInfo = useSelector(state => state.project.projectInfo);
    const geometries = useSelector(selectGeometries);
    const units = useSelector(state => state.coordinateSystems.units);
    const isShared = !!useRouteMatch({ path: Routes.SHARED_PROJECT_VIEW, exact: true });
    const accessKey = useSelector(state => state.sharing.access.accessKey);
    const embedCode = useSelector(state => state.sharing.embedCode);
    const demsList = useGetDemsList();
    const elevationProfiles = useSelector(state => state.elevationProfiles.calculations);
    const reportsBlockExpanded = useSelector(state =>
        selectReportsPropertiesBlockExpansion(state, (selectedObjectInfo as TemporaryLayer)?.id)
    );

    function callGeneratePDF() {
        let previewUrl = projectInfo.preview ? `${projectInfo.preview}` : undefined;
        if (previewUrl && isShared) previewUrl = previewUrl.concat(`&access=${accessKey}`);
        if (previewUrl && embedCode) previewUrl = previewUrl.concat(`&embed=${embedCode}`);

        measurementsPDFStarter({
            projectInfo,
            layer: selectedObjectInfo as TemporaryLayer,
            previewUrl,
            geometries,
            elevationProfiles,
            units,
            demsList
        });
    }

    function updateReportsBlockExpansion(expanded: boolean) {
        dispatch(
            updateStructureInfo({
                projectId: projectInfo.id!,
                structureUid: (selectedObjectInfo as TemporaryLayer).id,
                type: ProjectStructureObjectTypes.LAYER,
                propName: 'reportsBlockExpanded',
                propValue: expanded ? String(true) : String(false)
            })
        );
    }

    return (
        <div className='inspector-properties'>
            <PropertiesBlockHead
                isBlockExpanded={reportsBlockExpanded}
                title={t('projectView:inspectionSidebar.reports.title')}
                onClick={() => {
                    updateReportsBlockExpansion(!reportsBlockExpanded);
                }}
            />
            {reportsBlockExpanded && (
                <div className='inspector-report'>
                    <div className='report-title-wrapper'>
                        <span className='report-title' onClick={callGeneratePDF}>
                            {t('common:measurementReport')}
                        </span>
                    </div>
                    <div className='report-info'>
                        <span>{t('projectView:inspectionSidebar.reports.onDemand')}</span>
                        <span className='report-divider' />
                        <span>.PDF, .CSV</span>
                    </div>
                    <MeasurementReportsDropdown layer={selectedObjectInfo as TemporaryLayer} placement='bottom' />
                </div>
            )}
        </div>
    );
}
