import i18n from '../i18n/config';

// 'keepZeros' adds trailing zeroes to integer numbers
export default (size = 0, keepZeros = false, precision = 2) => {
    let projectSize: number = size;
    let step = 0;
    let convertedSize: string;

    while (projectSize >= 1024) {
        projectSize = projectSize / 1024;
        step++;
    }

    if (projectSize !== Math.floor(projectSize) || keepZeros) {
        convertedSize = keepZeros
            ? parseFloat(projectSize.toString()).toFixed(precision)
            : parseFloat(projectSize.toFixed(precision)).toString();
    } else {
        convertedSize = projectSize.toString();
    }

    switch (step) {
        case 0:
            return convertedSize + ` ${i18n.t('common:bytes')}`;
        case 1:
            return convertedSize + ` ${i18n.t('common:kb')}`;
        case 2:
            return convertedSize + ` ${i18n.t('common:mb')}`;
        case 3:
            return convertedSize + ` ${i18n.t('common:gb')}`;
        default:
            return convertedSize + ` ${i18n.t('common:tb')}`;
    }
};
