import React from 'react';
import AppHeader from './AppHeader/AppHeader';
import ProjectsPlaceholder from './Projects/ProjectsPlaceholder';

type Props = {
    type: '404' | 'serviceUnavailable' | 'serviceUnavailableEmbed';
};

export default function PlaceholderPage(props: Props) {
    return (
        <div id='wrapper'>
            {props.type !== 'serviceUnavailableEmbed' && <AppHeader />}
            <div className='content full-height full-width' id={'content'}>
                <div className='content-inner'>
                    <ProjectsPlaceholder type={props.type} />
                </div>
            </div>
        </div>
    );
}
