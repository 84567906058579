import { useState } from 'react';
import _ from 'lodash';
import { Img } from 'react-image';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { InvoiceInfo, InvoiceInfoItemsEnum } from '../../../generated/billing-api';
import formatISODate from '../../../lib/formatISODate';
import Tippy from '@tippyjs/react/headless';
import TippyTooltip from '../../../components/Elements/tippy-tooltip/TippyTooltip';

import statusExpiredSvg from '@/assets/images/invoices/expired.svg';
import statusPaidSvg from '@/assets/images/invoices/paid.svg';
import statusPendingSvg from '@/assets/images/invoices/pending.svg';

import styles from '../Invoices.module.scss';

const VALUE_AFFIX = '$'; // value units

type Props = {
    invoice: InvoiceInfo;
};

export default function InvoicesTableRow({ invoice }: Props) {
    const { t } = useTranslation(['glossary', 'cabinet']);
    const [actionsOpen, setActionsOpen] = useState(false);

    const displayValues: Record<InvoiceInfoItemsEnum, string> = {
        [InvoiceInfoItemsEnum.STORAGE]: t('glossary:storage'),
        [InvoiceInfoItemsEnum.PROCESSING_TIME]: t('glossary:processingHoursSpaced')
    };

    const resourceString =
        invoice.items?.length === 1
            ? _.capitalize(displayValues[invoice.items[0]])
            : _.capitalize(_.values(displayValues).join(' & '));

    const valueString = `${VALUE_AFFIX}${invoice.total}`;

    const getStatusString = () => {
        switch (invoice.status) {
            case 'COMPLETED':
                return t('cabinet:invoices.status.paid');
            case 'PENDING':
                return t('cabinet:invoices.status.pending');
            case 'EXPIRED':
                return t('cabinet:invoices.status.expired');
            default:
                return '';
        }
    };

    const getStatusIcon = () => {
        switch (invoice.status) {
            case 'COMPLETED':
                return statusPaidSvg;
            case 'PENDING':
                return statusPendingSvg;
            case 'EXPIRED':
                return statusExpiredSvg;
            default:
                return '';
        }
    };

    return (
        <tr data-testid={'invoicesTableRow'}>
            <td>{formatISODate(invoice.paymentDate)}</td>
            <td>{resourceString}</td>
            <td>
                <b>{valueString}</b>
            </td>
            <td>
                <TippyTooltip
                    tooltipText={getStatusString()}
                    delay={[100, 100]}
                    trigger={'click'}
                    offset={[0, 4]}
                    placement={'bottom'}
                >
                    <div className={styles.statusIcon} data-testid={'statusIcon'}>
                        <Img src={getStatusIcon()} />
                    </div>
                </TippyTooltip>
            </td>
            <td>
                <div className={styles.actionsWrapper}>
                    <Tippy
                        render={(attrs, content, instance) => (
                            <div
                                className='tippy-dropdown'
                                tabIndex={-1}
                                {...attrs}
                                onClick={() => {
                                    instance?.hide();
                                }}
                            >
                                <div
                                    className='tippy-dropdown-item'
                                    onClick={() => {
                                        window.open(invoice.invoiceUrl!, '_blank', 'noopener noreferrer');
                                    }}
                                >
                                    {t('cabinet:invoices.table.actions.openInNewTab')}
                                </div>
                            </div>
                        )}
                        trigger={'click'}
                        placement={'left'}
                        offset={[0, 4]}
                        interactive={true}
                        hideOnClick
                        onShow={() => {
                            setActionsOpen(true);
                        }}
                        onHide={() => {
                            setActionsOpen(false);
                        }}
                    >
                        <div className={classNames(styles.actionsButton, actionsOpen && styles.active)}>
                            <i className='icon icon-menu' />
                        </div>
                    </Tippy>
                </div>
            </td>
        </tr>
    );
}
