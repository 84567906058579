import * as Cesium from 'cesium';
import { colord } from 'colord';
import { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Billboard, useCesium } from 'resium';
import ProjectViewAccessContext from '../../../../contexts/ProjectViewAccessContext';
import useAreSceneMouseEventsBlocked from '../../../../hooks/useAreSceneMouseEventsBlocked';
import { adjustAlpha } from '../../../../lib/adjustAlpha';
import wgs84ToCartesian3 from '../../../../lib/wgs84ToCartesian3';
import { GeometryTypes } from '../../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../../store';
import { ProjectStructureObjectTypes, TemporaryGeometry } from '../../../../store/helpers/interfaces';
import { setSelectedObject } from '../../../../store/sharedActions';
import { makeSelectLayerByGeometryId } from '../../../../store/slices/geometryLayers';
import { selectHoveredPresentationSlide } from '../../../../store/slices/presentation';
import { setCurrentlyEditingShapeId, setCurrentlyHoveringShapeId } from '../../../../store/slices/projectView';
import { isObjectVisible, selectGroups, selectObjectVisibility } from '../../../../store/slices/structure';
import { defaultBlueCss, getCorrespondingHoverColor } from '../styling';

type Props = {
    geometry: TemporaryGeometry<GeometryTypes.POINT>;
};

export default function Viewpoint({ geometry }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { scene } = useCesium();
    const { owned } = useContext(ProjectViewAccessContext);
    const hoveredSlide = useSelector(state => selectHoveredPresentationSlide(state));
    const selectedSlideId = useSelector(state => state.project.selectedObject.artifactId);
    const isPresentationSetupEnabled = useSelector(state => state.projectView.isPresentationSetupEnabled);
    const structures = useSelector(state => state.structure.structures);
    const selectLayerByGeometryId = useMemo(makeSelectLayerByGeometryId, []);
    const layer = useSelector(state => selectLayerByGeometryId(state, geometry.id));
    const groups = useSelector(state => selectGroups(state));
    const layerVisible = useSelector(state => selectObjectVisibility(state, layer?.id!));

    const structureInfo = structures.find(s => s.uid === layer?.id);
    const group = groups.find(g => g.uid === structureInfo?.parentUid);
    const belongsToGroup = Boolean(group);
    const groupVisible = belongsToGroup ? isObjectVisible(structures, group?.uid!) : true;

    const visible = geometry.content.properties.ac_visibility;
    const index = layer!.geometries.indexOf(geometry.id) + 1;
    const selected = selectedSlideId === geometry.id;
    const hovered = hoveredSlide?.id === geometry.id;
    const layerColor = layer?.color ?? defaultBlueCss;

    const mouseEventsBlocked = useAreSceneMouseEventsBlocked();

    const color = useMemo(() => {
        let cssColor = geometry.content.properties.ac_color || layerColor;
        if (selected || hovered) cssColor = getCorrespondingHoverColor(layerColor);
        return adjustAlpha(colord(cssColor)).toHex();
    }, [geometry.content.properties.ac_color, hovered, layerColor, selected]);

    const image = useMemo(() => {
        const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><circle cx="12" cy="12" r="12" fill="${color}"/><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="Lato" lengthAdjust="spacing" fill="white">${index!.toString()}</text></svg>`;
        const image = new Image();
        image.src = 'data:image/svg+xml;base64,' + window.btoa(window.unescape(encodeURIComponent(svg)));
        return image;
    }, [color, index]);

    const objectPosition = wgs84ToCartesian3(geometry.content.geometry.coordinates, scene!) as Cesium.Cartesian3;

    return (
        <Billboard
            id={geometry.id}
            disableDepthTestDistance={Infinity}
            image={image.src}
            position={objectPosition}
            show={groupVisible && layerVisible && visible}
            heightReference={Cesium.HeightReference.NONE}
            onMouseEnter={(movement, target) => {
                if (mouseEventsBlocked) return;

                dispatch(setCurrentlyHoveringShapeId(geometry.id));
            }}
            onMouseLeave={(movement, target) => {
                if (mouseEventsBlocked) return;

                if (hovered) dispatch(setCurrentlyHoveringShapeId(undefined));
            }}
            onClick={async (movement, target) => {
                if (mouseEventsBlocked) return;

                await dispatch(
                    setSelectedObject({
                        artifactId: target.id || '',
                        type: ProjectStructureObjectTypes.GEOMETRY,
                        needToScroll: true
                    })
                );
            }}
            onMouseDown={(movement, target) => {
                if (mouseEventsBlocked) return;

                if (selected && owned && isPresentationSetupEnabled) {
                    dispatch(
                        setCurrentlyEditingShapeId({
                            id: geometry.id,
                            type: GeometryTypes.POINT,
                            isMidpoint: false,
                            index: 0
                        })
                    );
                }
            }}
        />
    );
}
