import { Select } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'rooks';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { GeometryNamesFontIds, GeometryNamesFontSizes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import {
    ProjectStructureObjectTypes,
    TemporaryLayer,
    getLayerProperties,
    isPointGeometry,
    isPolylineGeometry
} from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { selectUpload } from '../../../store/slices/datasetsUpload';
import { selectGeometries } from '../../../store/slices/geometries';
import { updateColorForAllGeometriesInLayer, updateLayer } from '../../../store/slices/geometryLayers';
import { setLayerProperty } from '../../../store/slices/project';
import {
    expandedPropertiesBlockNames,
    selectPropertiesBlockExpansion,
    updateStructureInfo
} from '../../../store/slices/structure';
import SwitchControl from '../../Elements/switch-control/SwitchControl';
import DatasetGeneralProperties from '../../ProjectView/dataset-upload-info/DatasetGeneralProperties';
import ColorPickerProperty from '../../ProjectView/geometry-properties/ColorPickerProperty';
import CopyPropertiesControl from '../../ProjectView/geometry-properties/elements/CopyPropertiesControl';
import PropertiesBlockHead from '../../ProjectView/geometry-properties/elements/PropertiesBlockHead';

const { Option } = Select;

type Props = {
    viewOnly: boolean;
};

const expandedPropertyName: (typeof expandedPropertiesBlockNames)[number] = 'styleBlockExpanded';

export default function LayerProperties({ viewOnly }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const geometries = useSelector(selectGeometries);
    const layer = useSelector(selectedObjectInfoSelector) as TemporaryLayer;
    const isStylePropertiesBlockExpanded = useSelector(state =>
        selectPropertiesBlockExpansion(state, layer.id, expandedPropertyName)
    );
    const upload = useSelector(state => selectUpload(state, layer.id));
    const { owned, isInEmbedView } = useContext(ProjectViewAccessContext);
    const [selectHasHover, setSelectHasHover] = useState(false);
    const isStyleVisible = !upload?.status && !layer.isInspection;

    const fontOptions: Record<GeometryNamesFontIds, string> = useMemo(
        () => ({
            extraSmall: t('projectView:layerProperties.namesFontSizes.extraSmall'),
            small: t('projectView:layerProperties.namesFontSizes.small'),
            medium: t('projectView:layerProperties.namesFontSizes.medium'),
            large: t('projectView:layerProperties.namesFontSizes.large'),
            extraLarge: t('projectView:layerProperties.namesFontSizes.extraLarge')
        }),
        [t]
    );

    const fontSizeValue: GeometryNamesFontIds =
        (_.findKey(GeometryNamesFontSizes, value => value === layer.geometriesNamesFont) as
            | GeometryNamesFontIds
            | undefined) || 'medium';

    const textForCopying = (() => {
        const fill = `${t('projectView:geometryProperties.fill')}\t${layer.color.toUpperCase()}`;
        if (layer.isPresentation) return fill;

        return `${fill}\n${t('projectView:geometryProperties.stroke')}\t${layer.strokeColor.toUpperCase()}\n${t(
            'projectView:layerProperties.names'
        )}\t${layer.geometriesNamesFont}`;
    })();

    const saveLayerProperties = useDebounce((layerInfo: TemporaryLayer) => {
        dispatch(
            updateLayer({
                projectUid: projectInfo.id!,
                layerUid: layerInfo.id!,
                properties: getLayerProperties(layerInfo)
            })
        );
    }, 350);

    function updateLayerColor(newColor: string, propertyName: 'color' | 'strokeColor' = 'color') {
        dispatch(setLayerProperty({ id: layer.id, propName: propertyName, propValue: newColor }));

        if (owned) {
            const layerInfo = { ...layer, [propertyName]: newColor };
            saveLayerProperties(layerInfo);
        }
    }

    function updateFontSize(newSize: GeometryNamesFontIds) {
        const newValue = GeometryNamesFontSizes[newSize];
        dispatch(setLayerProperty({ id: layer.id, propName: 'geometriesNamesFont', propValue: newValue }));

        if (owned) {
            const layerInfo = { ...layer, geometriesNamesFont: newValue };
            saveLayerProperties(layerInfo);
        }
    }

    function applyColorToAllGeometriesInLayer(color: string) {
        dispatch(
            updateColorForAllGeometriesInLayer({
                layerUid: layer.id,
                ids: layer.geometries.filter(id => {
                    const geometry = geometries[id];
                    // Polylines ignore layer.color
                    return geometry && !isPolylineGeometry(geometry);
                }),
                properties: { ac_color: color },
                owned
            })
        );
    }

    function applyStrokeColorToAllGeometriesInLayer(color: string) {
        dispatch(
            updateColorForAllGeometriesInLayer({
                layerUid: layer.id,
                ids: layer.geometries.filter(id => {
                    const geometry = geometries[id];
                    // Points have no strokeColor
                    return geometry && !isPointGeometry(geometry);
                }),
                properties: { ac_stroke_color: color },
                owned
            })
        );
    }

    function updateStyleBlockExpansion(expanded: boolean) {
        dispatch(
            updateStructureInfo({
                projectId: projectInfo.id!,
                structureUid: layer.id,
                type: ProjectStructureObjectTypes.LAYER,
                propName: expandedPropertyName,
                propValue: expanded ? String(true) : String(false)
            })
        );
    }

    return (
        <>
            {isStyleVisible && (
                <div className='inspector-properties'>
                    <PropertiesBlockHead
                        isBlockExpanded={isStylePropertiesBlockExpanded}
                        title={t('projectView:inspectionSidebar.geometryProperties.titleStyle')}
                        onClick={e => {
                            updateStyleBlockExpansion(!isStylePropertiesBlockExpanded);
                        }}
                    >
                        <CopyPropertiesControl clipboardText={textForCopying} />
                    </PropertiesBlockHead>
                    {isStylePropertiesBlockExpanded && (
                        <div className='properties-list'>
                            <ColorPickerProperty
                                viewOnly={viewOnly}
                                color={layer.color}
                                label={t('projectView:geometryProperties.fill')}
                                onChange={newColor => {
                                    updateLayerColor(newColor, 'color');
                                }}
                                withApplyToAll
                                onApplyToAll={applyColorToAllGeometriesInLayer}
                            />
                            {!layer.isPresentation && (
                                <ColorPickerProperty
                                    viewOnly={viewOnly}
                                    color={layer.strokeColor}
                                    label={t('projectView:geometryProperties.stroke')}
                                    onChange={newColor => {
                                        updateLayerColor(newColor, 'strokeColor');
                                    }}
                                    withApplyToAll
                                    onApplyToAll={applyStrokeColorToAllGeometriesInLayer}
                                />
                            )}
                            {!layer.isPresentation && (
                                <div className='property'>
                                    <div className='prop-wrapper prop-wrapper-names read-only'>
                                        <div className='prop-label-wrapper'>
                                            <div className='prop-label prop-text'>
                                                {t('projectView:layerProperties.names')}
                                            </div>
                                        </div>
                                        <div className='prop-value-wrapper'>
                                            <Select<GeometryNamesFontIds>
                                                onMouseEnter={e => {
                                                    setSelectHasHover(true);
                                                }}
                                                onMouseLeave={e => {
                                                    setSelectHasHover(false);
                                                }}
                                                variant={selectHasHover ? 'outlined' : 'borderless'}
                                                suffixIcon={selectHasHover ? undefined : null}
                                                className={classNames('prop-select-names', {
                                                    'theme-dark': isInEmbedView
                                                })}
                                                popupClassName={isInEmbedView ? 'theme-dark' : ''}
                                                disabled={viewOnly}
                                                value={fontSizeValue}
                                                onChange={updateFontSize}
                                            >
                                                {_.map(fontOptions, (value, key) => (
                                                    <Option key={key}>{value}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='switch-control-wrapper'>
                                            <SwitchControl
                                                checked={Boolean(layer.showGeometriesNames)}
                                                onChange={() => {
                                                    const newValue = !layer.showGeometriesNames;
                                                    dispatch(
                                                        setLayerProperty({
                                                            id: layer.id,
                                                            propName: 'showGeometriesNames',
                                                            propValue: newValue
                                                        })
                                                    );

                                                    if (!viewOnly) {
                                                        const layerInfo = { ...layer, showGeometriesNames: newValue };
                                                        saveLayerProperties(layerInfo);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
            <DatasetGeneralProperties />
        </>
    );
}
