import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { matchPath, useRouteMatch } from 'react-router-dom';
import { ProjectInfo } from '../../../../generated/cloud-frontend-api';
import getAreYouSureText from '../../../../lib/getAreYouSureText';
import { Routes } from '../../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../../store';
import { SelectedObject } from '../../../../store/helpers/interfaces';
import { setSelectedObject } from '../../../../store/sharedActions';
import { publishProject } from '../../../../store/slices/projectActions';
import { ModalProps } from '../../modal/Modal';
import ProjectActionModal from './ProjectActionModal';
import { setUndoableOperation } from '../../../../store/slices/projectView';

type Props = ModalProps & { project: ProjectInfo };

export default function PublishProjectModal({ isOpen, setIsOpen, project }: Props) {
    const { t } = useTranslation('projects');
    const dispatch: AppDispatch = useDispatch();
    const undoableOperation = useSelector(state => state.projectView.undoableOperation);

    const areYouSureText = getAreYouSureText('publish', project.name!);
    const isOnSomeProjectsPage = !!useRouteMatch({ path: [Routes.INDEX, Routes.SITE, Routes.FOLDER], exact: true });

    return (
        <ProjectActionModal
            isOpen={isOpen}
            setIsOpen={() => setIsOpen(false)}
            headerText={t('projectActions.publish')}
            areYouSureText={areYouSureText}
            actionButtonText={t('projectActions.publish')}
            actionButtonStyle={'actionConfirm'}
            cancelButtonText={t('projectActions.cancel')}
            cancelButtonStyle={'cancelBlue'}
            onAction={async () => {
                if (undoableOperation) {
                    undoableOperation?.commit();
                    dispatch(setUndoableOperation(undefined));
                }
                await dispatch(publishProject({ id: project.id!, onProjectsPage: isOnSomeProjectsPage }));
                dispatch(setSelectedObject({} as SelectedObject));
            }}
            onCancel={() => {
                setIsOpen(false);
            }}
        />
    );
}
