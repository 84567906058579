window.global ||= window; // Workaround for missing "global" if some library depends on it
import 'antd/dist/reset.css';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import 'overlayscrollbars/css/OverlayScrollbars.min.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, matchPath } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import appAxios from './api/appAxios';
import { App } from './components/App';
import { Head } from './components/Head';
import './i18n/config';
import { onInterceptorRejected } from './lib/onInterceptorRejected';
import './scss/main.scss';
import { Routes } from './sharedConstants';
import store from './store';
import { getAccount } from './store/slices/accountResources';
import { getInitialConfig } from './store/slices/init';
import { getPersonalInfo } from './store/slices/user';

initApp();

async function initApp() {
    setAxiosDefaultHeaders();
    setAxiosInterceptors(appAxios);

    const isEmbedProjectRoute = !!matchPath(window.location.pathname, {
        path: Routes.EMBEDDED_PROJECT_VIEW,
        exact: true
    });
    const isUnsubscribeRoute = !!matchPath(window.location.pathname, {
        path: Routes.UNSUBSCRIBE,
        exact: true
    });

    if (isEmbedProjectRoute || isUnsubscribeRoute) {
        await store.dispatch(getInitialConfig());
    } else {
        store.dispatch(getPersonalInfo()), store.dispatch(getAccount());
        await store.dispatch(getInitialConfig());
    }
    render(App);
}

function render(AppComponent: typeof App) {
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(
        <StrictMode>
            <Provider store={store}>
                <Router>
                    <QueryParamProvider ReactRouterRoute={Route}>
                        <Head />
                        <AppComponent />
                    </QueryParamProvider>
                </Router>
            </Provider>
        </StrictMode>,
        document.getElementById('root')
    );
}

function setAxiosDefaultHeaders() {
    const axiosDefaultHeaders = axios.defaults.headers;
    axiosDefaultHeaders.common['X-Requested-With'] = 'XMLHttpRequest';
    axiosDefaultHeaders.common['Accept'] = 'application/json';
    axiosDefaultHeaders.common['Content-Type'] = 'application/json';
    axiosDefaultHeaders.common['X-Agisoft-Client-Name'] = `${import.meta.env.VITE_CLIENT_NAME}`;
    axiosDefaultHeaders.common['X-Agisoft-Client-Version'] = `${import.meta.env.VITE_CLIENT_VERSION}`;
}

function setAxiosInterceptors(axios: AxiosInstance) {
    axios.interceptors.response.use(
        (response: AxiosResponse) => response,
        (error: AxiosError) => {
            onInterceptorRejected(error);
            return Promise.reject(error);
        },
        { runWhen: config => !config['axios-retry'] } // Retries override default 5xx handling
    );
}
