import * as Cesium from 'cesium';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CesiumMovementEvent, useCesium } from 'resium';
import { useEffectOnceWhen, useOnWindowResize } from 'rooks';
import { useSelector } from '../store';

const leftOffset = 60; // отступ от разделителя до левого края экрана
const rightOffset = 36; // отступ от разделителя до правого края экрана

export function useCompareToolSplitPositionUpdate() {
    const { scene } = useCesium();
    const split = useRef<HTMLElement | null>(null);
    const handler = useRef<Cesium.ScreenSpaceEventHandler | null>(null);
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const [moveActive, setMoveActive] = useState(false);

    const move = useCallback(
        (movement: CesiumMovementEvent) => {
            if (!moveActive || !split.current || !scene) return;

            const parentWidth = split.current.parentElement!.offsetWidth;
            const relativeOffset = movement.endPosition?.x!;

            let newPosition = split.current!.offsetLeft + relativeOffset;
            if (newPosition > parentWidth - rightOffset) newPosition = parentWidth - rightOffset;
            if (newPosition < leftOffset) newPosition = leftOffset;

            let splitPosition = newPosition / parentWidth;

            split.current!.style.left = `${100.0 * splitPosition}%`;
            scene.splitPosition = splitPosition;
            scene.requestRender();
        },
        [moveActive, scene]
    );

    useEffect(() => {
        if (!isCompareToolEnabled) return;
        const splitElement = document.getElementById('mapSplitRuler');
        if (!splitElement) return;
        split.current = splitElement;
        handler.current = new Cesium.ScreenSpaceEventHandler(split.current as any);

        handler.current.setInputAction(() => {
            setMoveActive(true);
        }, Cesium.ScreenSpaceEventType.LEFT_DOWN);
        handler.current.setInputAction(() => {
            setMoveActive(true);
        }, Cesium.ScreenSpaceEventType.PINCH_START);

        handler.current.setInputAction(() => {
            setMoveActive(false);
        }, Cesium.ScreenSpaceEventType.LEFT_UP);
        handler.current.setInputAction(() => {
            setMoveActive(false);
        }, Cesium.ScreenSpaceEventType.PINCH_END);

        return () => {
            handler.current?.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN);
            handler.current?.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP);
            handler.current?.removeInputAction(Cesium.ScreenSpaceEventType.PINCH_START);
            handler.current?.removeInputAction(Cesium.ScreenSpaceEventType.PINCH_END);
        };
    }, [isCompareToolEnabled]);

    useEffect(() => {
        if (!isCompareToolEnabled) return;
        handler.current?.setInputAction(move, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
        handler.current?.setInputAction(move, Cesium.ScreenSpaceEventType.PINCH_MOVE);
        return () => {
            handler.current?.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
            handler.current?.removeInputAction(Cesium.ScreenSpaceEventType.PINCH_MOVE);
        };
    }, [move, isCompareToolEnabled]);

    // Initialize
    useEffect(() => {
        if (isCompareToolEnabled && scene && isNaN(scene.splitPosition)) {
            scene.splitPosition = 0.5;
            scene.requestRender();
        }
    }, [isCompareToolEnabled, scene]);

    // Reset
    useEffect(() => {
        if (!isCompareToolEnabled && scene) {
            split.current = null;
            scene.splitPosition = NaN;
            scene.requestRender();
        }
    }, [isCompareToolEnabled, scene]);
}
