import * as Cesium from 'cesium';
import { Cartesian3 } from 'cesium';
import { VolumeBaseLevel, VolumeBasePlane } from '../../../../store/helpers/interfaces';
import { mean } from 'mathjs';
import { CalculateVolumeResult, computeBestFittingPlane, isPointInPolygon } from './PolygonMeasures';
import { computeCenterOfPoints } from './GeometryMeasures';
import QuantizedMeshUtil from '../../terrain/QuantizedMeshUtil';
import _ from 'lodash';
import { Tuple } from '../../../../sharedConstants';

interface VolumeCalculationPrerequisites {
    transform: Cesium.Matrix4;
    inverseTransform: Cesium.Matrix4;
    calculatedBaseLevel: VolumeBaseLevel;
    plane?: Cesium.Plane | undefined;
}

interface TriangleIntersectionInfo {
    isTriangleIntersectPolygon: boolean;
    isTriangleFullyInsidePolygon: boolean;
}

interface Triangle {
    coords: Tuple<Cesium.Cartesian3, 3>;
}

function getListOfTilesInsideRectangleAtLevel(
    rectangle: Cesium.Rectangle,
    tilingScheme: Cesium.TilingScheme,
    level: number
) {
    /**
     * @param {Rectangle} [rectangle] A rectangle bounding the polygon
     * @param {TilingScheme} [tilingScheme] A tiling scheme of tilemap
     * @returns {[[x,y,level][x,y,level]]} A list of tiles inside the given rectangle on given level.
     */
    const northWest = new Cesium.Cartographic(rectangle.west, rectangle.north);
    const northEast = new Cesium.Cartographic(rectangle.east, rectangle.north);
    const southEast = new Cesium.Cartographic(rectangle.east, rectangle.south);
    const southWest = new Cesium.Cartographic(rectangle.west, rectangle.south);

    const northWestTile = tilingScheme.positionToTileXY(northWest, level, new Cesium.Cartesian2());
    const northEastTile = tilingScheme.positionToTileXY(northEast, level, new Cesium.Cartesian2());
    const southEastTile = tilingScheme.positionToTileXY(southEast, level, new Cesium.Cartesian2());
    const southWestTile = tilingScheme.positionToTileXY(southWest, level, new Cesium.Cartesian2());

    const minX = Math.min(northWestTile.x, southWestTile.x);
    const maxX = Math.max(northEastTile.x, southEastTile.x);

    const minY = Math.min(northWestTile.y, northEastTile.y);
    const maxY = Math.max(southWestTile.y, southEastTile.y);

    const tilesList = [];
    for (let x = minX; x <= maxX; x++) {
        for (let y = minY; y <= maxY; y++) {
            tilesList.push({
                x: x,
                y: y,
                level: level
            });
        }
    }
    return tilesList;
}

function calculateVolumeOfTruncatedPrism(v1: Cesium.Cartesian3, v2: Cesium.Cartesian3, v3: Cesium.Cartesian3) {
    /**
     * https://en.wikipedia.org/wiki/Triangular_prism#Truncated_triangular_prism
     * https://math.stackexchange.com/questions/2371139/volume-of-truncated-prism
     */
    const baseArea = 0.5 * Math.abs((v1.x - v3.x) * (v2.y - v1.y) - (v1.x - v2.x) * (v3.y - v1.y));
    const h1 = Math.abs(v1.z);
    const h2 = Math.abs(v2.z);
    const h3 = Math.abs(v3.z);

    return baseArea * ((h1 + h2 + h3) / 3);
}

function calculateVolumeOfBifacialPrism(triangleECEF: Triangle['coords'], plane: Cesium.Plane): number {
    const projectedTriangleECEF = triangleECEF.map(vertexECEF => verticalProjectOnPlane(vertexECEF, plane));
    let minDistance = Number.MAX_VALUE;
    let minIndex = 0;
    for (let i = 0; i < triangleECEF.length; i++) {
        const dist = Cartesian3.distance(triangleECEF[i], projectedTriangleECEF[i]);
        if (dist < minDistance) {
            minDistance = dist;
            minIndex = i;
        }
    }
    const splitPrismPoint = projectedTriangleECEF[minIndex];
    const enuToEcef = Cesium.Transforms.eastNorthUpToFixedFrame(splitPrismPoint);
    const ecefToEnu = Cesium.Matrix4.inverse(enuToEcef, new Cesium.Matrix4());

    const v1 = calculateVolumeOfTruncatedPrism(
        ...(triangleECEF.map(vertexECEF =>
            Cesium.Matrix4.multiplyByPoint(ecefToEnu, vertexECEF, new Cesium.Cartesian3())
        ) as Triangle['coords'])
    );
    const v2 = calculateVolumeOfTruncatedPrism(
        ...(projectedTriangleECEF.map(vertexECEF =>
            Cesium.Matrix4.multiplyByPoint(ecefToEnu, vertexECEF, new Cesium.Cartesian3())
        ) as Triangle['coords'])
    );
    return v1 + v2;
}

function verticalProjectOnPlane(pointECEF: Cartesian3, plane: Cesium.Plane): Cartesian3 {
    const cartographic = Cesium.Cartographic.fromCartesian(pointECEF);
    const upPointECEF = Cesium.Cartesian3.fromRadians(
        cartographic.longitude,
        cartographic.latitude,
        cartographic.height + 1
    );
    const up = Cartesian3.subtract(pointECEF, upPointECEF, new Cartesian3());
    const down = Cartesian3.subtract(upPointECEF, pointECEF, new Cartesian3());
    const upDirection = Cesium.Cartesian3.normalize(up, new Cartesian3());
    const downDirection = Cesium.Cartesian3.normalize(down, new Cartesian3());
    const upRay = new Cesium.Ray(pointECEF, upDirection);
    const downRay = new Cesium.Ray(pointECEF, downDirection);
    let verticalProjection = Cesium.IntersectionTests.rayPlane(upRay, plane, new Cartesian3());
    if (!verticalProjection) {
        verticalProjection = Cesium.IntersectionTests.rayPlane(downRay, plane, new Cartesian3());
    }
    return verticalProjection;
}

function getVolumeCalculationPrerequisites(
    vertices: Cesium.Cartesian3[],
    basePlane: VolumeBasePlane,
    baseLevel: VolumeBaseLevel
): VolumeCalculationPrerequisites {
    const centerOfPoints = computeCenterOfPoints(vertices);
    const centerOfPointsCartographic = Cesium.Cartographic.fromCartesian(centerOfPoints, Cesium.Ellipsoid.WGS84);

    if (basePlane == 'bestFit') {
        const plane = computeBestFittingPlane(vertices);
        const transform = planeFrameToFixedFrame(centerOfPoints, plane.normal);
        return {
            transform: transform,
            inverseTransform: Cesium.Matrix4.inverse(transform, new Cesium.Matrix4()),
            calculatedBaseLevel: 'Varies',
            plane: plane
        };
    } else {
        const polygonVerticesHeights = vertices.map(vertex => Cesium.Cartographic.fromCartesian(vertex).height);
        let ellipsoidTangentPlane = new Cesium.EllipsoidTangentPlane(centerOfPoints, Cesium.Ellipsoid.WGS84).plane;
        let plane = new Cesium.Plane(ellipsoidTangentPlane.normal, 0.0);

        let referenceHeight = 0;
        if (basePlane === 'minLevel') referenceHeight = Math.min(...polygonVerticesHeights);
        if (basePlane === 'maxLevel') referenceHeight = Math.max(...polygonVerticesHeights);
        if (basePlane === 'meanLevel') referenceHeight = mean(...polygonVerticesHeights);
        if (basePlane === 'customLevel' && typeof baseLevel === 'number') referenceHeight = baseLevel;

        const origin = Cesium.Cartographic.toCartesian(
            new Cesium.Cartographic(
                centerOfPointsCartographic.longitude,
                centerOfPointsCartographic.latitude,
                referenceHeight
            ),
            Cesium.Ellipsoid.WGS84
        );
        const transform = planeFrameToFixedFrame(origin, plane.normal);
        return {
            transform: transform,
            inverseTransform: Cesium.Matrix4.inverse(transform, new Cesium.Matrix4()),
            calculatedBaseLevel: parseFloat(referenceHeight.toFixed(3))
        };
    }
}

function planeFrameToFixedFrame(origin: Cartesian3, normal: Cartesian3) {
    /**
     * @param {Cartesian3} position The center point of the local reference frame.
     * @param {Cartesian3} normal The ellipsoid whose fixed frame is used in the transformation.
     * @returns {Matrix4} The modified result parameter or a new Matrix4 instance if none was provided.
     */

    const axises = getAxesFromNormal(normal);

    const firstAxis = axises.x;
    const secondAxis = axises.y;
    const thirdAxis = axises.z;

    const result = new Cesium.Matrix4(
        firstAxis.x,
        secondAxis.x,
        thirdAxis.x,
        origin.x,
        firstAxis.y,
        secondAxis.y,
        thirdAxis.y,
        origin.y,
        firstAxis.z,
        secondAxis.z,
        thirdAxis.z,
        origin.z,
        0,
        0,
        0,
        1
    );

    return result;
}

function getAxesFromNormal(normal: Cartesian3) {
    /**
     * Takes plane normal and returns 3 orthogonal axis.
     * @returns {
        'x': xAxis,
        'y': yAxis,
        'z': zAxis
      }; An object containing 3 orgthogonal vectors
     */
    // Z axis is considered as a vector
    const zAxis = normal;

    const arbitraryVectors = [
        new Cesium.Cartesian3(1, 0, 0),
        new Cesium.Cartesian3(0, 1, 0),
        new Cesium.Cartesian3(0, 0, 1)
    ];

    /*
      In order to get an X axis with good numerical stability it is required to get an arbitrary vector that forms
      smallest dot product (in magnitude) with the Z axis, and than for the corss product of selected arbitrary vector
      and Z axis. The result should be normalized.
    */

    let dotProducts = [];

    for (let i = 0; i < arbitraryVectors.length; i++) {
        dotProducts.push(Cesium.Cartesian3.dot(normal, arbitraryVectors[i]));
    }

    let minDot = Math.min(...dotProducts);

    let xAxis = Cesium.Cartesian3.cross(zAxis, arbitraryVectors[dotProducts.indexOf(minDot)], new Cesium.Cartesian3());

    Cesium.Cartesian3.normalize(xAxis, xAxis);

    /*
     In order to get an Y axis it is required to form the cross product of the Z axis and X axis.
     The result should be normalized.
    */
    let yAxis = Cesium.Cartesian3.cross(zAxis, xAxis, new Cesium.Cartesian3());
    Cesium.Cartesian3.normalize(yAxis, yAxis);

    return {
        x: xAxis,
        y: yAxis,
        z: zAxis
    };
}

function computeBestAvailableLevelOverRectangle(polygon: Cesium.Rectangle, terrainProvider: Cesium.TerrainProvider) {
    return terrainProvider.availability.computeBestAvailableLevelOverRectangle(polygon);
}

function calculateVolumeForTilesBatch(
    terrainProvider: Cesium.TerrainProvider,
    terrainTiles: any[],
    isVariesLevel: boolean,
    volumeCalculationPrerequisites: VolumeCalculationPrerequisites,
    parallelPlanePrerequisites: VolumeCalculationPrerequisites,
    polygonENU: Cartesian3[],
    holesENU: Cartesian3[][],
    polygonBoundingPlanes: Cesium.Plane[]
): Promise<CalculateVolumeResult> {
    const promises = [];
    const planeFrameToFixedFrame = volumeCalculationPrerequisites.transform;
    const fixedFrameToPlaneFrame = volumeCalculationPrerequisites.inverseTransform;

    for (let i = 0; i < terrainTiles.length; i++) {
        const tile = terrainTiles[i];
        promises.push(
            terrainProvider.requestTileGeometry(tile.x, tile.y, tile.level)!.then(data => {
                const volume = {
                    above: 0,
                    below: 0,
                    total: 0,
                    calculatedBaseLevel: volumeCalculationPrerequisites.calculatedBaseLevel
                } as CalculateVolumeResult;
                const quantizedMeshTile = QuantizedMeshUtil.getTerrainTileMesh(tile.x, tile.y, tile.level, data);

                const verticesENU = quantizedMeshTile.vertices.map((vertexECEF: any) => {
                    return Cesium.Matrix4.multiplyByPoint(
                        parallelPlanePrerequisites.inverseTransform,
                        vertexECEF,
                        new Cesium.Cartesian3()
                    );
                });

                const indices = quantizedMeshTile.indices;

                for (let i = 0; i < indices.length; i += 3) {
                    const t1 = verticesENU[indices[i]];
                    const t2 = verticesENU[indices[i + 1]];
                    const t3 = verticesENU[indices[i + 2]];
                    let trianglesInside = getTrianglesInsidePolygon(
                        [t1, t2, t3],
                        polygonENU,
                        holesENU,
                        polygonBoundingPlanes
                    );

                    if (isVariesLevel) {
                        trianglesInside = trianglesInside.map(triangle => {
                            return {
                                coords: triangle.coords.map(coord => {
                                    const ecefCoord = Cesium.Matrix4.multiplyByPoint(
                                        parallelPlanePrerequisites.transform,
                                        coord,
                                        new Cesium.Cartesian3()
                                    );
                                    return Cesium.Matrix4.multiplyByPoint(
                                        fixedFrameToPlaneFrame,
                                        ecefCoord,
                                        new Cesium.Cartesian3()
                                    );
                                })
                            } as Triangle;
                        });
                    }
                    for (let k = 0; k < trianglesInside.length; k++) {
                        const triangle = trianglesInside[k];
                        const intersection = Cesium.IntersectionTests.trianglePlaneIntersection(
                            ...triangle.coords,
                            new Cesium.Plane(new Cesium.Cartesian3(0.0, 0.0, 1.0), 0.0)
                        );

                        if (!intersection) {
                            const prismVolume = isVariesLevel
                                ? calculateVolumeOfBifacialPrism(
                                      triangle.coords.map(coord =>
                                          Cesium.Matrix4.multiplyByPoint(
                                              planeFrameToFixedFrame,
                                              coord,
                                              new Cesium.Cartesian3()
                                          )
                                      ) as Triangle['coords'],
                                      volumeCalculationPrerequisites.plane!
                                  )
                                : calculateVolumeOfTruncatedPrism(...triangle.coords);
                            processPrismVolume(...triangle.coords, prismVolume, volume);
                        } else {
                            for (let j = 0; j < intersection.indices.length; j += 3) {
                                const vi1 = intersection.positions[intersection.indices[j]];
                                const vi2 = intersection.positions[intersection.indices[j + 1]];
                                const vi3 = intersection.positions[intersection.indices[j + 2]];
                                const prismVolume = isVariesLevel
                                    ? calculateVolumeOfBifacialPrism(
                                          [vi1, vi2, vi3].map(coord =>
                                              Cesium.Matrix4.multiplyByPoint(
                                                  planeFrameToFixedFrame,
                                                  coord,
                                                  new Cesium.Cartesian3()
                                              )
                                          ) as Triangle['coords'],
                                          volumeCalculationPrerequisites.plane!
                                      )
                                    : calculateVolumeOfTruncatedPrism(vi1, vi2, vi3);
                                processPrismVolume(vi1, vi2, vi3, prismVolume, volume);
                            }
                        }
                    }
                }
                return volume;
            })
        );
    }
    return resolvePromisesSeq(promises).then(volumes => aggregateVolume(volumes));
}

async function resolvePromisesSeq(tasks: Promise<CalculateVolumeResult>[]) {
    const results = [];
    for (let task of tasks) {
        results.push(await task);
    }
    return results as CalculateVolumeResult[];
}

function aggregateVolume(volumes: CalculateVolumeResult[]): CalculateVolumeResult {
    if (volumes.length == 0) {
        return {
            above: 0,
            below: 0,
            total: 0,
            calculatedBaseLevel: 'Varies'
        } as CalculateVolumeResult;
    }
    let above = 0;
    let below = 0;
    for (let volume of volumes) {
        above += volume.above;
        below += volume.below;
    }
    return {
        above: above,
        below: below,
        total: above - below,
        calculatedBaseLevel: volumes[0].calculatedBaseLevel
    } as CalculateVolumeResult;
}

export function calculateVolume(
    terrainProvider: Cesium.TerrainProvider,
    polygon: Cartesian3[],
    holes: Cartesian3[][],
    basePlane: VolumeBasePlane,
    level: VolumeBaseLevel = 0
): Promise<CalculateVolumeResult> {
    const polygonBoundingRectangle = Cesium.Rectangle.fromCartesianArray(polygon, Cesium.Ellipsoid.WGS84);
    const bestLevelInsideRectangle = computeBestAvailableLevelOverRectangle(polygonBoundingRectangle, terrainProvider);

    const terrainTilesOnBestLevelInsideRectangle = getListOfTilesInsideRectangleAtLevel(
        polygonBoundingRectangle,
        terrainProvider.tilingScheme,
        bestLevelInsideRectangle
    );

    const volumeCalculationPrerequisites = getVolumeCalculationPrerequisites(polygon, basePlane, level);
    const isVariesLevel = volumeCalculationPrerequisites.calculatedBaseLevel === 'Varies';
    const parallelPlanePrerequisites = isVariesLevel
        ? getVolumeCalculationPrerequisites(polygon, 'minLevel', 0)
        : volumeCalculationPrerequisites;

    const polygonENU = polygon.map(vertex => {
        return Cesium.Matrix4.multiplyByPoint(
            parallelPlanePrerequisites.inverseTransform,
            vertex,
            new Cesium.Cartesian3()
        );
    });
    const holesENU = holes.map(hole =>
        hole.map(vertex => {
            return Cesium.Matrix4.multiplyByPoint(
                parallelPlanePrerequisites.inverseTransform,
                vertex,
                new Cesium.Cartesian3()
            );
        })
    );
    const polygonBoundingPlanes = getBoundingPlanesForPolygonENU(polygonENU);

    const promises = [];
    let numberOfBatches = 3;
    const tilesSubListLength = Math.max(Math.floor(terrainTilesOnBestLevelInsideRectangle.length / numberOfBatches), 1);
    const batches = _.chunk(terrainTilesOnBestLevelInsideRectangle, tilesSubListLength);
    for (let batch of batches) {
        promises.push(
            calculateVolumeForTilesBatch(
                terrainProvider,
                batch,
                isVariesLevel,
                volumeCalculationPrerequisites,
                parallelPlanePrerequisites,
                polygonENU,
                holesENU,
                polygonBoundingPlanes
            )
        );
    }
    return Promise.all(promises).then(volumes => {
        return aggregateVolume(volumes);
    });
}

function getTrianglesInsidePolygon(
    triangle: Triangle['coords'],
    polygon: Cartesian3[],
    holes: Cartesian3[][],
    boundingPlanes: Cesium.Plane[]
): Triangle[] {
    const triangles = [{ coords: triangle }];
    let intersectionInfo = getTriangleIntersectionInfo(triangle, polygon);
    if (intersectionInfo.isTriangleFullyInsidePolygon) {
        for (const hole of holes) {
            const holeIntersectionInfo = getTriangleIntersectionInfo(triangle, hole);
            if (holeIntersectionInfo.isTriangleFullyInsidePolygon) {
                return [];
            }
        }
        return triangles;
    }
    if (!intersectionInfo.isTriangleIntersectPolygon) {
        return [];
    } // both if have not covered cases

    boundingPlanes.forEach(plane => {
        const trianglesIndexToDelete = [];
        const trianglesToAdd: Triangle[] = [];
        for (let i = 0; i < triangles.length; i++) {
            const currentTriangle = triangles[i];
            const intersection = Cesium.IntersectionTests.trianglePlaneIntersection(...currentTriangle.coords, plane);
            if (intersection) {
                trianglesIndexToDelete.push(i);
                for (let j = 0; j < intersection.indices.length; j += 3) {
                    const vi1 = intersection.positions[intersection.indices[j]];
                    const vi2 = intersection.positions[intersection.indices[j + 1]];
                    const vi3 = intersection.positions[intersection.indices[j + 2]];
                    intersectionInfo = getTriangleIntersectionInfo([vi1, vi2, vi3], polygon);
                    if (intersectionInfo.isTriangleIntersectPolygon) {
                        trianglesToAdd.push({ coords: [vi1, vi2, vi3] });
                    }
                }
            }
        }
        trianglesIndexToDelete.reverse().forEach(index => {
            triangles.splice(index, 1);
        });
        triangles.push(...trianglesToAdd);
    });
    return triangles.filter(t => {
        const center = computeCenterOfPoints(t.coords);
        return isPointInPolygon(center, polygon);
    });
}

function getBoundingPlanesForPolygonENU(polygonENU: Cartesian3[]): Cesium.Plane[] {
    const planes = [];
    for (let j = 0; j < polygonENU.length - 1; j++) {
        const k = j + 1;
        if (polygonENU[j].equals(polygonENU[k])) {
            continue;
        }
        const cENU = Cartesian3.fromElements(polygonENU[j].x, polygonENU[j].y, polygonENU[j].z + 1.0);
        planes.push(getPlaneFromPoints(polygonENU[j], polygonENU[k], cENU));
    }
    return planes;
}

function getPlaneFromPoints(aENU: Cartesian3, bENU: Cartesian3, cENU: Cartesian3): Cesium.Plane {
    const vector1 = Cartesian3.subtract(aENU, bENU, new Cartesian3());
    const vector2 = Cartesian3.subtract(cENU, bENU, new Cartesian3());
    let normal = Cartesian3.cross(vector1, vector2, new Cartesian3());
    normal = Cesium.Cartesian3.normalize(normal.clone(), new Cesium.Cartesian3());
    return Cesium.Plane.fromPointNormal(aENU, normal);
}

function getTriangleIntersectionInfo(triangle: Cartesian3[], polygon: Cartesian3[]): TriangleIntersectionInfo {
    const firstPointInside = isPointInPolygon(triangle[0], polygon);
    const secondPointInside = isPointInPolygon(triangle[1], polygon);
    const thirdPointInside = isPointInPolygon(triangle[2], polygon);

    return {
        isTriangleIntersectPolygon: firstPointInside || secondPointInside || thirdPointInside,
        isTriangleFullyInsidePolygon: firstPointInside && secondPointInside && thirdPointInside //TODO допущение
    };
}

function processPrismVolume(
    v1: Cartesian3,
    v2: Cartesian3,
    v3: Cartesian3,
    prismVolume: number,
    volume: CalculateVolumeResult
): number {
    if (v1.z + v2.z + v3.z > 0) {
        volume.above += prismVolume;
        return 1;
    } else if (v1.z + v2.z + v3.z < 0) {
        volume.below += prismVolume;
        return -1;
    } else {
        console.log('Error: prism vertices intersect plane');
        return 0;
    }
}
