import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import countriesEn from 'iso3166-2-db/countryList/en.json';
import countriesRu from 'iso3166-2-db/countryList/ru.json';
import usaRegionsEn from 'iso3166-2-db/regions/US/en.json';
import usaRegionsRu from 'iso3166-2-db/regions/US/ru.json';
import canadaRegionsEn from 'iso3166-2-db/regions/CA/en.json';
import canadaRegionsRu from 'iso3166-2-db/regions/CA/ru.json';
import australiaRegionsEn from 'iso3166-2-db/regions/AU/en.json';
import australiaRegionsRu from 'iso3166-2-db/regions/AU/ru.json';

// use alphabetic order (?)
import glossaryEn from './en/glossary.json';
import glossaryRu from './ru/glossary.json';
import cabinetEn from './en/cabinet.json';
import cabinetRu from './ru/cabinet.json';
import commonEn from './en/common.json';
import commonRu from './ru/common.json';
import sharedProjectsEn from './en/sharedProjects.json';
import sharedProjectsRu from './ru/sharedProjects.json';
import projectsEn from './en/projects.json';
import projectsRu from './ru/projects.json';
import modalsEn from './en/modals.json';
import modalsRu from './ru/modals.json';
import navigationEn from './en/navigation.json';
import navigationRu from './ru/navigation.json';
import placeholdersEn from './en/placeholders.json';
import placeholdersRu from './ru/placeholders.json';
import projectViewEn from './en/projectView.json';
import projectViewRu from './ru/projectView.json';
import generatedReportsEn from './en/generatedReports.json';
import generatedReportsRu from './ru/generatedReports.json';
import subscribeEn from './en/subscribe.json';
import subscribeRu from './ru/subscribe.json';
import userAgreementEn from './en/userAgreement.json';
import userAgreementRu from './ru/userAgreement.json';

export const defaultNS = 'common';

export const resources = {
    en: {
        glossary: glossaryEn,
        cabinet: cabinetEn,
        common: commonEn,
        sharedProjects: sharedProjectsEn,
        projects: projectsEn,
        modals: modalsEn,
        navigation: navigationEn,
        placeholders: placeholdersEn,
        projectView: projectViewEn,
        generatedReports: generatedReportsEn,
        subscribe: subscribeEn,
        userAgreement: userAgreementEn
    },
    ru: {
        glossary: glossaryRu,
        cabinet: cabinetRu,
        common: commonRu,
        sharedProjects: sharedProjectsRu,
        projects: projectsRu,
        modals: modalsRu,
        navigation: navigationRu,
        placeholders: placeholdersRu,
        projectView: projectViewRu,
        generatedReports: generatedReportsRu,
        subscribe: subscribeRu,
        userAgreement: userAgreementRu
    }
} as const;

i18n
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        lng: 'en', // use this property for testing purposes only
        debug: false,
        detection: { order: ['navigator'] }, // Detect language from browser language
        fallbackLng: 'en',
        nonExplicitSupportedLngs: true, // this way "en" resolves to en-US, en-GB and whatever
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        defaultNS,
        resources,
        ns: Object.keys(resources)
    });

export type SupportedLocales = 'en' | 'ru';

export default i18n;

export const countriesToLangMapping: Record<SupportedLocales, typeof countriesEn> = {
    en: countriesEn,
    ru: countriesRu
};

export const usaStatesToLangMapping: Record<SupportedLocales, typeof usaRegionsEn> = {
    en: usaRegionsEn,
    ru: usaRegionsRu
};

export const canadaStatesToLangMapping: Record<SupportedLocales, typeof canadaRegionsEn> = {
    en: canadaRegionsEn,
    ru: canadaRegionsRu
};

export const australiaStatesToMapping: Record<SupportedLocales, typeof australiaRegionsEn> = {
    en: australiaRegionsEn,
    ru: australiaRegionsRu
};
