import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Access, AccessInfo, Type } from '../../generated/access-api/model';
import { accessApi } from '../../api/initApis';
import { accessV2Api } from '../../api/initApis';
import {
    AccessInfoAccessTypeEnum,
    AccessInfoProjectRoleEnum,
    AccessInfo as AccessInfoV2
} from '../../generated/access-api-v2/model';
import { getProjectById } from './project';
import { ProjectInfo } from '../../generated/cloud-frontend-api';

interface SharingState {
    accessInfo: AccessInfo;
    access: Access;
    embedCode: string;
}

const initialState: SharingState = {
    accessInfo: {},
    access: { emails: [] },
    embedCode: ''
};

const name = 'sharing';

export const getAccess = createAsyncThunk(`${name}/getAccess`, (projectId: string) => {
    return accessApi
        .getAccessByProject(projectId)
        .then(({ data }) => data)
        .catch(error => ({} as Access));
});

export const getAccessInfo = createAsyncThunk(`${name}/getAccessInfo`, (accessKey: string, { rejectWithValue }) => {
    return accessApi
        .getAccessInfo(accessKey)
        .then(({ data }) => data)
        .catch(error => ({} as AccessInfo));
});

interface AccessArgs {
    projectId: string;
    currentAccesses: AccessInfoV2[] | undefined;
}

export const enableLinkAccess = createAsyncThunk(
    `${name}/enableLinkAccess`,
    async ({ projectId, currentAccesses }: AccessArgs) => {
        const linkData = await accessV2Api.enableLinkAccess(projectId);
        return mergeAccesses(currentAccesses, linkData.data.accesses, AccessInfoAccessTypeEnum.LINK);
    }
);

export const disableLinkAccess = createAsyncThunk(
    `${name}/disableLinkAccess`,
    async ({ projectId, currentAccesses }: AccessArgs) => {
        const linkData = await accessV2Api.disableLinkAccess(projectId);
        return mergeAccesses(currentAccesses, linkData.data.accesses, AccessInfoAccessTypeEnum.LINK);
    }
);

export const enableEmbedAccess = createAsyncThunk(
    `${name}/enableEmbedAccess`,
    async ({ projectId, currentAccesses }: AccessArgs) => {
        const embedData = await accessV2Api.enableEmbedAccess(projectId);
        return mergeAccesses(currentAccesses, embedData.data.accesses, AccessInfoAccessTypeEnum.EMBED);
    }
);

export const disableEmbedAccess = createAsyncThunk(
    `${name}/disableEmbedAccess`,
    async ({ projectId, currentAccesses }: AccessArgs) => {
        const embedData = await accessV2Api.disableEmbedAccess(projectId);
        return mergeAccesses(currentAccesses, embedData.data.accesses, AccessInfoAccessTypeEnum.EMBED);
    }
);

export const deleteProjectFromSharedWithMe = createAsyncThunk(
    `${name}/deleteProjectFromSharedWithMe`,
    async (projectId: string) => {
        const { data } = await accessApi.deleteCallerEmailFromAccess(projectId);
        return data;
    }
);

interface EmailAccessArgs {
    projectId: string;
    email: string;
    currentAccesses: AccessInfoV2[] | undefined;
}

export const addEmailAccess = createAsyncThunk(
    `${name}/addEmailAccess`,
    async ({ projectId, email, currentAccesses }: EmailAccessArgs, { rejectWithValue }) => {
        const emailAccessData = await accessV2Api
            .addEmail(projectId, email)
            .then(({ data }) => {
                return mergeAccesses(currentAccesses, data.accesses, AccessInfoAccessTypeEnum.EMAIL);
            })
            .catch(error => rejectWithValue(error.response.data));

        return emailAccessData;
    }
);

export const deleteEmailAccess = createAsyncThunk(
    `${name}/deleteEmailAccess`,
    async ({ email, projectId, currentAccesses }: EmailAccessArgs, { rejectWithValue }) => {
        const emailAccessData = await accessV2Api
            .deleteEmail(projectId, email)
            .then(({ data }) => {
                return mergeAccesses(currentAccesses, data.accesses, AccessInfoAccessTypeEnum.EMAIL);
            })
            .catch(error => rejectWithValue(error.response.data));

        return emailAccessData;
    }
);

function mergeAccesses(currentAccesses?: AccessInfoV2[], accesses?: AccessInfoV2[], type?: AccessInfoAccessTypeEnum) {
    const filtered = currentAccesses?.filter(accessItem => accessItem.accessType !== type);
    let mergedArray: AccessInfoV2[] = [];
    filtered?.forEach((access: AccessInfoV2) => {
        mergedArray.push(access);
    });
    accesses?.forEach((access: AccessInfoV2) => {
        mergedArray.push(access);
    });
    return mergedArray;
}

const sharingSlice = createSlice({
    name,
    initialState,
    reducers: {
        resetAccessInfoAndToken(state) {
            state.accessInfo = {};
            state.access = {};
        },
        setAccessToken(state, action: PayloadAction<string>) {
            state.access.accessKey = action.payload;
        },
        setEmbedCode(state, { payload }: PayloadAction<string>) {
            state.embedCode = payload;
        },
        setAccessInfo(state, { payload }: PayloadAction<AccessInfo>) {
            state.accessInfo = payload;
        }
    },
    extraReducers: builder =>
        builder
            .addCase(getAccess.fulfilled, (state, { payload }) => {
                state.access = payload;
            })
            .addCase(enableLinkAccess.fulfilled, (state, { payload }) => {
                state.access.accessKey = payload?.find(
                    accessInfo => accessInfo.projectRole === 'VIEWER' && accessInfo.accessType === 'LINK'
                )?.accessKey;
            })
            .addCase(disableLinkAccess.fulfilled, (state, { payload }) => {
                state.access.accessKey = undefined;
            })
            .addCase(enableEmbedAccess.fulfilled, (state, { payload }) => {
                state.embedCode =
                    payload?.find(
                        accessItem =>
                            accessItem.accessType === AccessInfoAccessTypeEnum.EMBED &&
                            accessItem.projectRole !== AccessInfoProjectRoleEnum.NO_ACCESS
                    )?.accessKey || '';
            })
            .addCase(disableEmbedAccess.fulfilled, (state, { payload }) => {
                state.embedCode = '';
            })
            .addCase(getAccessInfo.fulfilled, (state, { payload }) => {
                state.accessInfo = payload;
            })
});

export const { resetAccessInfoAndToken, setAccessToken, setEmbedCode, setAccessInfo } = sharingSlice.actions;

export default sharingSlice.reducer;
