import { globalTerrainOption } from '../components/ProjectView/elevation-profile/ElevationProfileTool';
import { useSelector } from '../store';
import { selectReadyDemFiles } from '../store/selectors';

export default function useGetDemsList() {
    const dems = useSelector(state => selectReadyDemFiles(state));

    const demsList: Record<string, string> = {};

    demsList[globalTerrainOption().id] = globalTerrainOption().name; // add Global terrain name

    dems.forEach(item => {
        demsList[item.id] = item.name;
    });

    return demsList;
}
