import initGdalJs from 'gdal3.js';
import workerUrl from 'gdal3.js/dist/package/gdal3.js?url';
import dataUrl from 'gdal3.js/dist/package/gdal3WebAssembly.data?url';
import wasmUrl from 'gdal3.js/dist/package/gdal3WebAssembly.wasm?url';
import { useEffect, useRef } from 'react';

const paths = { wasm: wasmUrl, data: dataUrl, js: workerUrl };

export default function useGdal() {
    const gdalRef = useRef<Gdal>();

    useEffect(() => {
        initGdalJs({ paths }).then(gdal => (gdalRef.current = gdal));
    }, []);

    return gdalRef.current;
}
