import { Select } from 'antd';
import classNames from 'classnames';
import { ReactElement, memo, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { ProjectType } from '../../../../generated/cloud-frontend-api';
import useGetElementDimensions from '../../../../hooks/useGetElementDimensions';
import useSiteViewQueryParams from '../../../../hooks/useSiteViewQueryParams';
import { Routes } from '../../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../../store';
import { resetProjectView } from '../../../../store/slices/projectView';
import { selectFirstSiteProject } from '../../../../store/slices/site';
import AddProjectModal from '../../../Elements/add-project-modal/AddProjectModal';
import formatSurveyDate from '../helpers/formatSurveyDate';

const REDUCE_DROPDOWN_WIDTH_BY = 8; // = px, used in setting dropdown horizontal position
const dropdownAlign = { offset: [-REDUCE_DROPDOWN_WIDTH_BY, 5] }; // rc-select properties

const createProjectValue = 'createProject';

type Props = {
    Icon: ReactElement;
    createProjectOption?: boolean;
};

function SiteSurveyDateControl({ Icon, createProjectOption = true }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const { queryProjectId, setQueryProjectId } = useSiteViewQueryParams();
    const firstSiteProject = useSelector(state => selectFirstSiteProject(state));
    const siteProjects = useSelector(state => state.site.siteInfo.projects);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isIconHovered, setIsIconHovered] = useState(false);
    const isInEmbedView = !!useRouteMatch({ path: [Routes.EMBEDDED_PROJECT_VIEW], exact: true });

    const siteProjectsWithSurveyDatesFormatted = useMemo(
        () => siteProjects.map(p => ({ ...p, formattedSurveyDate: formatSurveyDate(p.surveyDate) })),
        [siteProjects]
    );

    const dropdownContainerRef = useRef<HTMLDivElement>(null);
    const dropdownContainerWidth = useGetElementDimensions(dropdownContainerRef).width || 0;

    const dropdownWidth = useMemo(() => {
        return dropdownContainerWidth ? dropdownContainerWidth - REDUCE_DROPDOWN_WIDTH_BY : 0;
    }, [dropdownContainerWidth]);

    const dropdownOptions = useMemo(() => {
        const options = [];
        if (createProjectOption) {
            options.push({
                label: t('projectView:head.createProject'),
                value: createProjectValue,
                className: 'ant-select-item_linkLike'
            });
        }
        siteProjectsWithSurveyDatesFormatted.map(p =>
            options.push({
                label: p.formattedSurveyDate,
                value: p.uid
            })
        );
        return options;
    }, [t, createProjectOption, siteProjectsWithSurveyDatesFormatted]);

    return (
        <div
            className={classNames('infobar-control', { active: isDropdownVisible })}
            ref={dropdownContainerRef}
            data-testid={'dateControl'}
        >
            <div
                className='control-icon'
                onMouseOver={() => {
                    setIsIconHovered(true);
                }}
                onMouseLeave={() => {
                    setIsIconHovered(false);
                }}
            >
                {Icon}
            </div>
            <Select
                variant='borderless'
                className={classNames(
                    'ant-select-small ant-survey-date',
                    { hovered: isIconHovered },
                    { 'theme-dark': isInEmbedView }
                )}
                popupClassName={classNames('ant-dropdown-project', { 'theme-dark': isInEmbedView })}
                value={queryProjectId ?? firstSiteProject?.uid}
                onSelect={newValue => {
                    if (newValue !== createProjectValue) {
                        if (queryProjectId !== newValue) {
                            dispatch(resetProjectView({ doNotResetCompareTool: true }));
                            setQueryProjectId(newValue);
                        }
                    } else setIsModalOpen(true);
                }}
                popupMatchSelectWidth={dropdownWidth}
                placement='bottomRight'
                dropdownAlign={dropdownAlign}
                onDropdownVisibleChange={(open: boolean) => {
                    setIsDropdownVisible(open);
                }}
                options={dropdownOptions}
            />
            <AddProjectModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} projectType={ProjectType.NON_METASHAPE} />
        </div>
    );
}

export default memo(SiteSurveyDateControl);
