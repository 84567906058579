import * as Cesium from 'cesium';
import { Colord } from 'colord';
import { isMobile } from 'react-device-detect';

export function adjustAlpha(color: Cesium.Color): Cesium.Color;
export function adjustAlpha(color: Colord): Colord;
/**
 *
 * @returns Color without alpha if user is on the mobile device
 */
export function adjustAlpha(color: Cesium.Color | Colord) {
    if (color instanceof Colord) {
        if (isMobile) return color.alpha(1);
        return color;
    }
    if (isMobile) return color.withAlpha(1);
    return color;
}
