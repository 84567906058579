import { useTranslation } from 'react-i18next';
import { StorageProductGradeEnum, PaymentType } from '../../../../generated/billing-api';
import { PlanType } from '../../../../generated/account-frontend-api';

type Props = {
    onlyHours?: boolean;
    isCommercial: boolean;
    scheduledPlanType?: string;
    paymentType: PaymentType;
    storageProductGrade?: StorageProductGradeEnum;
    isOverdue?: boolean;
};

export default function ActionButtonText({
    onlyHours,
    isCommercial,
    scheduledPlanType,
    paymentType,
    storageProductGrade,
    isOverdue
}: Props) {
    const { t } = useTranslation('subscribe');

    let text = '';

    if (!isCommercial) {
        // переход с FREE плана
        text = t('actions.proceed');
    } else if (onlyHours) {
        // страница с покупкой часов, без storage
        text = t('actions.proceed');
    } else if (isOverdue) {
        // случай с Overdue
        paymentType === PaymentType.AUTO ? (text = t('actions.updatePaymentMethod')) : (text = t('actions.payNow'));
    } else if (storageProductGrade === StorageProductGradeEnum.DOWNGRADE) {
        // выбран более дешевый план
        text = t('actions.scheduleDowngrade');
    } else if (storageProductGrade === StorageProductGradeEnum.UPGRADE) {
        // выбран более дорогой план
        text = t('actions.proceed');
    } else if (scheduledPlanType === PlanType.COMMERCIAL) {
        // отмена запланированного downgrade storage
        text = t('actions.stayOnActiveSubscription');
    } else if (scheduledPlanType === PlanType.FREE) {
        // отмена перехода на FREE план. (на странице смены подписки кнопки нет)
        text = t('actions.stayOnCommercialPlan');
    } else if (paymentType === PaymentType.AUTO) {
        // общий случай, когда тип оплаты AUTO
        text = t('actions.updatePaymentMethod');
    } else if (paymentType === PaymentType.MANUAL) {
        // общий случай, когда тип оплаты MANUAL
        text = t('actions.payNow');
    } else {
        // fallback-значение
        text = t('actions.proceed');
    }

    return text;
}
