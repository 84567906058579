import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import { Routes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { createLoadingSelector } from '../../../store/selectors/createLoadingSelector';
import { firstProgressTick } from '../../../store/slices/progress';
import {
    resetProjects,
    selectProjects,
    setCurrentProjectsPage,
    setIsLoading,
    setSearchText
} from '../../../store/slices/projectsPage';
import Search from '../../Elements/search/Search';
import ProjectsSortControl from '../projects-sort-control/ProjectsSortControl';
import SelectedProjectsActions from '../selected-projects-actions/SelectedProjectsActions';

export default function ProjectsPageControls() {
    const dispatch: AppDispatch = useDispatch();
    const isOnline = useSelector(state => state.globalFlags.isOnline);
    const page = useSelector(state => state.projectsPage);
    const projects = useSelector(state => selectProjects(state));
    const isLoading = useSelector(state => createLoadingSelector([firstProgressTick.typePrefix])(state));
    const selectedProjects = page.selected;

    const noProjects = !projects.length && !isLoading && !page.searchText;

    const hasSelectedProjects = Boolean(selectedProjects.length);

    async function search(newValue: string) {
        dispatch(setCurrentProjectsPage(0));
        dispatch(setSearchText(newValue));
        dispatch(resetProjects());
        dispatch(setIsLoading(true));
    }

    return (
        <section className='projects-page-controls'>
            <Search
                value={page.searchText}
                onChange={search}
                reset={() => {
                    search('');
                }}
                disabled={!isOnline || noProjects}
            />
            {hasSelectedProjects && !isLoading && <SelectedProjectsActions />}
            {!hasSelectedProjects && (
                <div className='projects-controls'>
                    <Route exact path={[Routes.INDEX, Routes.SITE, Routes.FOLDER]}>
                        <ProjectsSortControl disabled={!projects.length || isLoading || !isOnline} />
                    </Route>
                </div>
            )}
        </section>
    );
}
