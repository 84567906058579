import * as Cesium from 'cesium';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PointPrimitive, PointPrimitiveCollection, useCesium } from 'resium';
import { GeometryTypes } from '../../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../../store';
import { ProjectStructureObjectTypes, TemporaryGeometry } from '../../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../../store/selectors';
import { updateGeometryProperty } from '../../../../store/slices/geometries';
import { POINT_STYLES, defaultBlueCss, getCorrespondingHoverColor } from '../styling';
import { adjustAlpha } from '../../../../lib/adjustAlpha';
import useAreSceneMouseEventsBlocked from '../../../../hooks/useAreSceneMouseEventsBlocked';

type Props = {
    geometries: TemporaryGeometry<GeometryTypes.POINT>[];
    layerVisible: boolean;
};

export default function PointPrimitives({ geometries, layerVisible }: Props) {
    const { scene } = useCesium();
    const pointPrimitiveCollection = useRef<Cesium.PointPrimitiveCollection>(null!);
    const dispatch: AppDispatch = useDispatch();
    const [hoveredId, setHoveredId] = useState('');
    const selectedObject = useSelector(state => state.project.selectedObject);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));
    const areMouseEventsBlocked = useAreSceneMouseEventsBlocked();

    const color = useCallback(
        (geometry: TemporaryGeometry) => {
            return Cesium.Color.fromCssColorString(
                hoveredId === geometry.id
                    ? getCorrespondingHoverColor(geometry.content.properties.ac_color || defaultBlueCss)
                    : geometry.content.properties.ac_color || defaultBlueCss
            );
        },
        [hoveredId]
    );

    useEffect(() => {
        scene?.requestRender();
    }, [hoveredId, scene]);

    useEffect(() => {
        if (selectedObject?.type === ProjectStructureObjectTypes.GEOMETRY) {
            const geometry = selectedObjectInfo as TemporaryGeometry | undefined;
            if (geometry && geometries.map(g => g.id).includes(geometry.id) && !geometry.renderAsEntity) {
                dispatch(
                    updateGeometryProperty({
                        id: geometry.id,
                        propName: 'renderAsEntity',
                        propValue: layerVisible && geometry.content.properties.ac_visibility
                    })
                );
                const point = pointPrimitiveCollection.current.get(
                    geometries.indexOf(geometry as TemporaryGeometry<GeometryTypes.POINT>)
                );
                pointPrimitiveCollection.current.remove(point);
            }
        }
    }, [selectedObjectInfo, selectedObject, layerVisible, dispatch, geometries]);

    return (
        <PointPrimitiveCollection
            ref={el => {
                pointPrimitiveCollection.current = el?.cesiumElement!;
            }}
        >
            {geometries.map((g, i) => (
                <PointPrimitive
                    show={g.content.properties.ac_visibility && layerVisible}
                    key={g.id}
                    id={`${GeometryTypes.POINT}#${g.id}#0`}
                    position={Cesium.Cartesian3.fromDegreesArrayHeights(g.content.geometry.coordinates)[0]}
                    pixelSize={POINT_STYLES.size}
                    color={adjustAlpha(color(g))}
                    disableDepthTestDistance={Infinity}
                    onMouseEnter={(movement, target) => {
                        if (areMouseEventsBlocked) return;
                        setHoveredId(g.id);
                    }}
                    onMouseLeave={(movement, target) => {
                        if (areMouseEventsBlocked) return;
                        setHoveredId('');
                    }}
                />
            ))}
        </PointPrimitiveCollection>
    );
}
