import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppDispatch, useSelector } from '../../../../store';
import { cancelSubscription } from '../../../../store/slices/billing';
import { selectCurrentPlanRenewDateDisplayValue } from '../../../../store/slices/accountResources';
import Modal, { ModalProps } from '../../modal/Modal';
import ModalHead from '../../modal/ModalHead';
import ModalBody from '../../modal/ModalBody';
import ModalActions from '../../modal/ModalActions';

type Props = ModalProps;

export default function DowngradePlanModal({ isOpen, setIsOpen }: Props) {
    const { t } = useTranslation('modals');
    const dispatch: AppDispatch = useDispatch();
    const downgradeDate = useSelector(state => selectCurrentPlanRenewDateDisplayValue(state));
    const isOverdue = useSelector(state => state.accountResources.account.plan.current?.overdue);
    const history = useHistory();

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <ModalHead setIsOpen={setIsOpen}>{t('downgradePlanModal.head')}</ModalHead>
            <ModalBody>
                {isOverdue ? (
                    <>
                        <p>{t('downgradePlanModal.bodyOverdue1')}</p>
                        <p>{t('downgradePlanModal.bodyOverdue2')}</p>
                    </>
                ) : (
                    <>
                        <p>{t('downgradePlanModal.body1', { downgradeDate })}</p>
                        <p>{t('downgradePlanModal.body2')}</p>
                    </>
                )}
                <ModalActions>
                    <button
                        className='btn-ghost-alert'
                        type='button'
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        {t('downgradePlanModal.actionCancel')}
                    </button>
                    <button
                        className='btn-alert'
                        type='button'
                        onClick={() => {
                            dispatch(cancelSubscription()).then(() => {
                                history.push('/');
                            });
                        }}
                    >
                        {t('downgradePlanModal.actionDowngrade')}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}
