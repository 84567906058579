import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';
import { ProjectsSortModes, ProjectsSortOrders, Routes } from '../sharedConstants';
import { AppDispatch, useSelector } from '../store';
import { getDefaultSortParameters, setSearchText, setSortMode, setSortOrder } from '../store/slices/projectsPage';

export default function useHistoryListener() {
    const dispatch: AppDispatch = useDispatch();
    const history = useHistory();
    const sortMode = useSelector(state => state.projectsPage.sortMode);
    const sortOrder = useSelector(state => state.projectsPage.sortOrder);
    const prevSortMode = useRef<ProjectsSortModes>(sortMode);
    const prevSortOrder = useRef<ProjectsSortOrders>(sortOrder);

    useEffect(() => {
        const unlisten = history.listen((location: Location) => {
            if (matchPath(location.pathname, { path: [Routes.SITE, Routes.SHARED_SITE], exact: true })) {
                prevSortMode.current = sortMode;
                prevSortOrder.current = sortOrder;
                dispatch(setSortMode(ProjectsSortModes.SURVEY_DATE));
                dispatch(setSortOrder(ProjectsSortOrders.DESCENDING));
            } else {
                if (prevSortMode.current === ProjectsSortModes.SURVEY_DATE)
                    prevSortMode.current = getDefaultSortParameters().sortMode;

                dispatch(setSortMode(prevSortMode.current));
                dispatch(setSortOrder(prevSortOrder.current));
            }
        });

        return () => {
            unlisten();
        };
    }, [history, dispatch, sortMode, sortOrder]);

    useEffect(() => {
        const unlisten = history.listen((location: Location) => {
            dispatch(setSearchText(''));
        });

        return () => {
            unlisten();
        };
    }, [history, dispatch]);
}
