import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../contexts/ProjectViewAccessContext';
import getGeometryMeasures from '../lib/getGeometryMeasures';
import { GeometryTypes } from '../sharedConstants';
import { AppDispatch, useSelector } from '../store';
import { GeoJson, ProjectStructureObjectTypes, TemporaryLayer } from '../store/helpers/interfaces';
import { setSelectedObject } from '../store/sharedActions';
import { removeGeometry, updateGeometryContent } from '../store/slices/geometries';
import { commitGeometry } from '../store/slices/geometryLayers';
import { setCurrentlyDrawingShapeId, setFloatingPointCoordinates } from '../store/slices/projectView';
import { resetRulerGeometry } from '../store/slices/rulerTool';

export const polylineMinVerticesCount = 2;
export const polygonMinVerticesCount = 4; // 4 for polygon, since geojson spec requires first vertex to be duplicated in the end

export default function (): (saveGeometry?: boolean) => void {
    const dispatch: AppDispatch = useDispatch();
    const temporaryLayers = useSelector(state => state.project.structure.temporaryLayers);
    const geometries = useSelector(state => state.geometries.entities);
    const selectedGeometryType = useSelector(state => state.projectView.selectedGeometryType);
    const floatingPointCoordinates = useSelector(state => state.projectView.floatingPointCoordinates);
    const currentlyDrawingShapeId = useSelector(state => state.projectView.currentlyDrawingShapeId);
    const isRulerToolEnabled = useSelector(state => state.projectView.isRulerToolEnabled);
    const { owned } = useContext(ProjectViewAccessContext);

    return async saveGeometry => {
        if (isRulerToolEnabled) {
            dispatch(resetRulerGeometry());
            return;
        }

        if (!selectedGeometryType) return;

        if ([GeometryTypes.POLYGON, GeometryTypes.POLYLINE].includes(selectedGeometryType) && currentlyDrawingShapeId) {
            const layer = temporaryLayers.find(l => l.geometries.includes(currentlyDrawingShapeId));
            const geoJson = geometries[currentlyDrawingShapeId]?.content!;

            if (isGeometryInvalid(geoJson)) cleanupGeometry(layer!);
            else {
                if (saveGeometry) {
                    const measures = getGeometryMeasures(geoJson);
                    dispatch(
                        updateGeometryContent({
                            id: currentlyDrawingShapeId,
                            geoJson: { ...geoJson, properties: { ...geoJson.properties, ...measures } }
                        })
                    );

                    if (owned) dispatch(commitGeometry({ temporaryGeometryUid: currentlyDrawingShapeId }));
                } else cleanupGeometry(layer!);
            }
        }

        if (floatingPointCoordinates) dispatch(setFloatingPointCoordinates(undefined));
        dispatch(setCurrentlyDrawingShapeId(undefined));
    };

    async function cleanupGeometry(layer: TemporaryLayer) {
        await dispatch(setSelectedObject({ type: ProjectStructureObjectTypes.LAYER, artifactId: layer?.id! }));
        dispatch(removeGeometry({ id: currentlyDrawingShapeId!, datasetId: layer.id }));
    }

    function isGeometryInvalid(geoJson: GeoJson): boolean {
        const isPolylineInvalid =
            geoJson?.geometry.type === GeometryTypes.POLYLINE &&
            (geoJson?.geometry?.coordinates?.length || 0) < polylineMinVerticesCount;

        const isPolygonInvalid =
            geoJson?.geometry.type === GeometryTypes.POLYGON &&
            (geoJson?.geometry.coordinates as number[][][])[0].length < polygonMinVerticesCount;

        return isPolygonInvalid || isPolylineInvalid;
    }
}
