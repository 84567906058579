import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectInfo } from '../../../../generated/cloud-frontend-api';
import { ReactComponent as SvgCalendar } from '../../../../svg/dropdown_actions/calendar.svg';
import ChangeSurveyDateModal from '../../modals/change-survey-date-modal/ChangeSurveyDateModal';
import TippyDropdownItemWithIcon from '../../tippy-dropdown/TippyDropdownItemWithIcon';

type Props = {
    project: ProjectInfo;
};

export default function DeleteProjectAction({ project }: Props) {
    const { t } = useTranslation(['projects']);
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <TippyDropdownItemWithIcon
                icon={<SvgCalendar />}
                text={t('projects:projectActions.changeSurveyDate')}
                onClick={() => {
                    setIsModalOpen(true);
                }}
            />
            <ChangeSurveyDateModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} project={project} />
        </>
    );
}
