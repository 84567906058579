import { useState, useEffect } from 'react';
import TippyTooltip from '../../../Elements/tippy-tooltip/TippyTooltip';
import { useTranslation } from 'react-i18next';

const TOOLTIP_TIMEOUT = 2000;
let timeout = 0;

type Props = {
    clipboardText: string;
};

export default function CopyPropertiesControl({ clipboardText }: Props) {
    const { t } = useTranslation('projectView');
    const [isInfoCopied, setIsInfoCopied] = useState(false);

    const handleCopyMessage = () => {
        if (isInfoCopied) {
            clearTimeout(timeout);
            setIsInfoCopied(false);
        }
        navigator.clipboard.writeText(clipboardText);
        setIsInfoCopied(true);
        timeout = window.setTimeout(() => {
            setIsInfoCopied(false);
        }, TOOLTIP_TIMEOUT);
    };

    useEffect(() => {
        return () => clearTimeout(timeout);
    }, []);

    return (
        <TippyTooltip tooltipText={t('inspectionSidebar.geometryProperties.tooltipCopy')}>
            <div className='properties-control' onClick={handleCopyMessage} data-testid='copyButton'>
                <TippyTooltip
                    tooltipText={t('inspectionSidebar.geometryProperties.tooltipCopied')}
                    visible={isInfoCopied}
                    placement={'left'}
                    offset={[0, 6]}
                    touch={true}
                    appendTo='parent'
                >
                    <i className='icon icon-copy' />
                </TippyTooltip>
            </div>
        </TippyTooltip>
    );
}
