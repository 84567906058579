import TippyTooltipTimed from '../../Elements/tippy-tooltip/TippyTooltipTimed';
import { useTranslation } from 'react-i18next';

type Props = {
    isParentSelected?: boolean;
};

export default function ViewOnlyControl({ isParentSelected }: Props) {
    const { t } = useTranslation('projectView');

    return isParentSelected ? (
        <span className='control control-inactive'>
            <i className='visibility-dot' />
        </span>
    ) : (
        <TippyTooltipTimed tooltipText={t('structureItem.controls.tooltipEditNotAllowed')}>
            <span className='control control-inactive control_view-only'>
                <i className='icon icon-block' data-testid={'viewOnlyIcon'} />
            </span>
        </TippyTooltipTimed>
    );
}
