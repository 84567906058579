import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { PlanType } from '../../../generated/account-frontend-api/model';
import convertSize from '../../../lib/convertSize';
import formatTimeWithNegative from '../../../lib/formatTimeWithNegative';
import { Routes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { storageUsedPercentSelector } from '../../../store/selectors';
import { selectFreeTimeHoursMinutes } from '../../../store/slices/accountResources';
import { ReactComponent as SvgProcessingHours } from '../../../svg/resources/processing_hours.svg';
import { ReactComponent as SvgStorage } from '../../../svg/resources/storage.svg';
import SidebarResourcesSkeleton from './SidebarResourcesSkeleton';
import SidebarResourceState from './SidebarResourceState';

type Props = {
    isMobileView: boolean;
};

export default function SidebarResources({ isMobileView }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projects', 'glossary']);
    const account = useSelector(state => state.accountResources.account);
    const percentUsed = useSelector(storageUsedPercentSelector);
    const isBillingActive = useSelector(state => state.init.isBillingActive);
    const storage = account.resources?.storage;
    const currentPlan = account.plan?.current;
    const scheduledPlanType = account.plan?.scheduled?.type;

    const isAccountUpdateInProgress = useSelector(state => state.globalFlags.isAccountUpdateInProgress);

    const [isResourceButtonActive, setIsResourceButtonActive] = useState<boolean>(!isMobileView);
    const showResources = !isMobileView || (isMobileView && isResourceButtonActive);

    const prePaidSeconds = account.resources?.processingTime?.prePaidTime || 0;
    const freeTime = useSelector(state => selectFreeTimeHoursMinutes(state));
    const prePaidTime = formatTimeWithNegative(prePaidSeconds);

    const usedStorage = convertSize(storage?.used || 0, false, 1);
    const totalStorage = convertSize(storage?.limit || 0, false, 1);

    const isInDebt = prePaidSeconds < 0;
    const isCommercial = currentPlan?.type === PlanType.COMMERCIAL;
    const paymentType = useSelector(state => state.accountResources.account.paymentType);

    return (
        <div className={classNames('sidebar-resources', { isMobile: isMobileView })}>
            {isEmpty(account.resources) ? (
                <SidebarResourcesSkeleton isMobileView={isMobileView} />
            ) : (
                <>
                    <div className='sidebar-plan'>
                        {isMobileView && (
                            <div
                                className={classNames('toggle-button', { active: isResourceButtonActive })}
                                onClick={() => {
                                    setIsResourceButtonActive(prev => !prev);
                                }}
                            >
                                <div className='link-arrow' />
                            </div>
                        )}
                        <div className='plan-title-wrapper'>
                            <div className='plan-title'>
                                {isCommercial ? t('projects:plan.commercialPlan') : t('projects:plan.freePlan')}
                            </div>
                            <SidebarResourceState
                                isCommercial={isCommercial}
                                isOverdue={isInDebt}
                                paymentType={paymentType}
                                scheduledPlanType={scheduledPlanType}
                                renewDate={currentPlan?.renewDate}
                            />
                        </div>
                        {isBillingActive && (
                            <>
                                {isAccountUpdateInProgress ? (
                                    <span className='change-plan change-plan-disabled'>
                                        {t('projects:plan.changePlan')}
                                    </span>
                                ) : (
                                    <Link to={Routes.PLAN_MANAGEMENT} className='change-plan'>
                                        {t('projects:plan.changePlan')}
                                    </Link>
                                )}
                            </>
                        )}
                    </div>
                    {showResources && (
                        <div className='resources'>
                            <div className='resource'>
                                <div className='resource-info'>
                                    <div className='resource-icon'>
                                        <SvgStorage />
                                    </div>
                                    <div className='resource-title'>{t('projects:resources.storage')}</div>
                                    {isCommercial && (
                                        <Link
                                            to={Routes.STORAGE}
                                            className={classNames('btn btn-sm resource-btn', {
                                                disabled: isAccountUpdateInProgress
                                            })}
                                        >
                                            {t('projects:resources.configure')}
                                        </Link>
                                    )}
                                </div>
                                <div className='progress-bar-wrapper'>
                                    <div className='progress-bar'>
                                        <span
                                            className={classNames('progress-value', {
                                                warning: Number(percentUsed) >= 50 && Number(percentUsed) < 99,
                                                alert: Number(percentUsed) >= 99
                                            })}
                                            style={{ width: `${percentUsed}%` }}
                                            data-testid='storageProgressBar'
                                        />
                                    </div>
                                    <div className='progress-bottom'>
                                        <span data-testid='storageText'>
                                            <b>{usedStorage}</b>
                                            <span>{` / ${totalStorage}`}</span>
                                        </span>
                                        <span data-testid='storagePercent'>
                                            {t('projects:resources.storageUsed', { percentUsed })}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='resource'>
                                <div className='resource-info'>
                                    <div className='resource-icon'>
                                        <SvgProcessingHours />
                                    </div>
                                    <div className='resource-title'>{t('projects:resources.processingHours')}</div>
                                    {isCommercial && (
                                        <Link
                                            to={Routes.TIME}
                                            className={classNames('btn btn-sm resource-btn', {
                                                disabled: isAccountUpdateInProgress
                                            })}
                                        >
                                            {t('projects:resources.buy')}
                                        </Link>
                                    )}
                                </div>

                                {!isCommercial && (
                                    <>
                                        <div className='resource-text'>
                                            <span>{t('projects:resources.processingTime_free')}</span>
                                            <span>&nbsp;</span>
                                            <span>
                                                {t('projects:resources.processingTime_time', { time: freeTime })}
                                            </span>
                                        </div>
                                        <div className='resource-text'>
                                            <span>{t('projects:resources.processingTime_prePaid')}</span>
                                            <span>&nbsp;</span>
                                            <span className={classNames({ 'resource-alert': isInDebt })}>
                                                {t('projects:resources.processingTime_time', { time: prePaidTime })}
                                            </span>
                                        </div>
                                    </>
                                )}
                                {isCommercial && (
                                    <div className='resource-text'>
                                        <span>{t('projects:resources.processingTime_prePaid')}</span>
                                        <span>&nbsp;</span>
                                        <span className={classNames({ 'resource-alert': isInDebt })}>
                                            {t('projects:resources.processingTime_time', { time: prePaidTime })}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
