import i18n from '../i18n/config';
import { GeometryTypes } from '../sharedConstants';
import { GeoJson, TemporaryGeometry } from '../store/helpers/interfaces';

export class Issue {
    static Types = {
        None: 'None',
        POI: 'POI',
        Crack: 'Crack',
        Dent: 'Dent',
        Leak: 'Leak',
        Mold: 'Mold',
        Rust: 'Rust',
        Erosion: 'Erosion',
        Sagging: 'Sagging',
        Deterioration: 'Deterioration',
        FireSafety: 'FireSafety',
        ElectricalHazards: 'ElectricalHazards',
        SafetyViolation: 'SafetyViolation',
        StructuralDamage: 'StructuralDamage'
    } as const;

    static TypesDisplayNames: () => Record<keyof typeof Issue.Types, string> = () =>
        ({
            None: i18n.t('glossary:issuesTypes.none'),
            POI: i18n.t('glossary:issuesTypes.poi'),
            Crack: i18n.t('glossary:issuesTypes.crack'),
            Dent: i18n.t('glossary:issuesTypes.dent'),
            Leak: i18n.t('glossary:issuesTypes.leak'),
            Mold: i18n.t('glossary:issuesTypes.mold'),
            Rust: i18n.t('glossary:issuesTypes.rust'),
            Erosion: i18n.t('glossary:issuesTypes.erosion'),
            Sagging: i18n.t('glossary:issuesTypes.sagging'),
            Deterioration: i18n.t('glossary:issuesTypes.deterioration'),
            FireSafety: i18n.t('glossary:issuesTypes.fireSafety'),
            ElectricalHazards: i18n.t('glossary:issuesTypes.electricalHazards'),
            SafetyViolation: i18n.t('glossary:issuesTypes.safetyViolation'),
            StructuralDamage: i18n.t('glossary:issuesTypes.structuralDamage')
        } as const);

    static Severities = () => ({
        0: i18n.t('glossary:issuesSeverity.undefined'),
        1: i18n.t('glossary:issuesSeverity.minimal'),
        2: i18n.t('glossary:issuesSeverity.low'),
        3: i18n.t('glossary:issuesSeverity.moderate'),
        4: i18n.t('glossary:issuesSeverity.high'),
        5: i18n.t('glossary:issuesSeverity.critical')
    });

    static SeverityColors: Record<keyof ReturnType<typeof Issue.Severities>, string> = {
        0: '#adadad',
        1: '#498ad6',
        2: '#41d746',
        3: '#d7d242',
        4: '#ec9f26',
        5: '#d74155'
    };

    constructor(private readonly _issue: TemporaryGeometry<GeometryTypes.POINT>) {}

    static geoJson(
        coordinates: number[],
        // TODO Refactor so that Partial is not needed
        properties: Partial<GeoJson<GeometryTypes.POINT>['properties']>
    ): GeoJson<GeometryTypes.POINT> {
        return {
            type: 'Feature',
            geometry: { type: GeometryTypes.POINT, coordinates },
            properties: {
                ...properties,
                ac_visibility: true,
                ac_severity: 0,
                ac_color: Issue.SeverityColors[0],
                ac_type: Issue.Types.None,
                ac_description: '',
                saved: false
            } as GeoJson<GeometryTypes.POINT>['properties']
        };
    }

    get color() {
        const severity = this._issue.content.properties.ac_severity!;
        return Issue.SeverityColors[severity];
    }
}
