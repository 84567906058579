import { useOnWindowScroll } from 'rooks';
import { ReactComponent as SvgArrowUp } from '../../../svg/general/arrow_up.svg';
import { useState } from 'react';
import classNames from 'classnames';

import styles from './BackToTopButton.module.scss';

export const breakPointPixels = 200;

export default function BackToTopButton() {
    const [visible, setVisible] = useState(false);
    useOnWindowScroll(() => {
        if (window.scrollY >= breakPointPixels) setVisible(true);
        else setVisible(false);
    });

    if (!visible) return null;

    return (
        <button
            type='button'
            className={classNames('btn', styles.backToTopBtn)}
            onClick={e => {
                window.scrollTo(0, 0);
            }}
        >
            <SvgArrowUp />
        </button>
    );
}
