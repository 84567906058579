import { isEmpty } from 'lodash';
import { useRouteMatch } from 'react-router-dom';
import {
    Parent,
    ProcessStep,
    ProjectInfo,
    ProjectStatus,
    ProjectType,
    SharedProjectInfo
} from '../../../generated/cloud-frontend-api/model';
import useGetPublishState from '../../../hooks/useGetPublishState';
import isLastPipelineProcessing from '../../../lib/isLastPipelineProcessing';
import { Routes } from '../../../sharedConstants';
import { useSelector } from '../../../store';
import { selectProgressById } from '../../../store/slices/progress';
import AbortProcessingAction from './actions/AbortProcessingAction';
import ChangeSurveyDateAction from './actions/ChangeSurveyDateAction';
import DeleteProjectAction from './actions/DeleteProjectAction';
import EmbedProjectAction from './actions/EmbedProjectAction';
import LogSharedProjectAction from './actions/LogSharedProjectAction';
import MoveProjectAction from './actions/MoveProjectAction';
import PublishProjectAction from './actions/PublishProjectAction';
import RemoveSharedProjectAction from './actions/RemoveSharedProjectAction';
import RenameProjectAction from './actions/RenameProjectAction';
import ShareProjectAction from './actions/ShareProjectAction';
import ShowProcessingInfoAction from './actions/ShowProcessingInfoAction';
import UnpublishProjectAction from './actions/UnpublishProjectAction';

type Props = {
    project: ProjectInfo;
    parent: Parent | null;
};

export default function ProjectActionsList({ project, parent }: Props) {
    const progress = useSelector(state => selectProgressById(state, project.pipeline?.pipelineUid!));
    const publishState = useGetPublishState();
    const processingLogs = project?.processingLogs;
    const isProcessing = isLastPipelineProcessing(project);

    const isSharedRoute = !!useRouteMatch({ path: [Routes.SHARED, Routes.SHARED_SITE], exact: true });

    const isProject = project.type === ProjectType.METASHAPE || project.type === ProjectType.NON_METASHAPE;
    const canAbort = progress?.step !== ProcessStep.SAVING_RESULTS;

    const showProcessingLogsAction = !isEmpty(processingLogs);

    const sharingAccessKey = useSelector(state => state.sharing.access.accessKey);
    const sharedProject: SharedProjectInfo = project;

    const accessKey = sharingAccessKey ? sharingAccessKey : sharedProject.accessKey;

    return (
        <>
            {isSharedRoute ? (
                <>
                    {showProcessingLogsAction && (
                        <>
                            <LogSharedProjectAction
                                projectUid={project.id}
                                processingLogs={processingLogs}
                                access={accessKey}
                            />
                            <hr className='dropdown-menu-line' />
                        </>
                    )}
                    <RemoveSharedProjectAction project={project} />
                </>
            ) : (
                <>
                    <RenameProjectAction project={project} />

                    {project.type === ProjectType.METASHAPE && (
                        <>
                            {project.published ? (
                                <UnpublishProjectAction project={project} />
                            ) : (
                                <PublishProjectAction publishState={publishState} project={project} />
                            )}
                        </>
                    )}

                    {isProject && <EmbedProjectAction project={project} />}

                    {project.status === ProjectStatus.INPROGRESS && isProcessing && canAbort && (
                        <AbortProcessingAction project={project} disabled={!canAbort} />
                    )}

                    {isProject && <ShareProjectAction project={project} />}

                    {project.type === ProjectType.METASHAPE && <ShowProcessingInfoAction project={project} />}

                    <MoveProjectAction projects={project} />
                    {isProject && <ChangeSurveyDateAction project={project} />}

                    <hr className='dropdown-menu-line' />
                    <DeleteProjectAction project={project} parent={parent} />
                </>
            )}
        </>
    );
}
