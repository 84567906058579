import Tippy from '@tippyjs/react/headless';
import classNames from 'classnames';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import useGetDemsList from '../../../hooks/useGetDemsList';
import { Routes } from '../../../sharedConstants';
import { TemporaryLayer } from '../../../store/helpers/interfaces';
import { useSelector } from '../../../store/index';
import { makeSelectLayerGeometries, selectGeometries } from '../../../store/slices/geometries';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import MeasurementsCSVGenerator from '../measurements-report/MeasurementsCSVGenerator';
import generatePDF from '../measurements-report/measurementsPDFStarter';

type Props = {
    layer: TemporaryLayer;
    placement?: string | string[];
};

export default function MeasurementReportsDropdown({ layer, placement = 'bottom' }: Props) {
    const { t } = useTranslation(['common']);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const selectLayerGeometries = useMemo(makeSelectLayerGeometries, []);
    const layerGeometries = useSelector(state => selectLayerGeometries(state, layer.id));
    const isShared = !!useRouteMatch({ path: Routes.SHARED_PROJECT_VIEW, exact: true });
    const accessKey = useSelector(state => state.sharing.access.accessKey);
    const embedCode = useSelector(state => state.sharing.embedCode);
    const units = useSelector(state => state.coordinateSystems.units);
    const demsList = useGetDemsList();

    const geometries = useSelector(selectGeometries);
    const elevationProfiles = useSelector(state => state.elevationProfiles.calculations);

    const { isInEmbedView } = useContext(ProjectViewAccessContext);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const projectName = projectInfo.name!;

    function callGeneratePDF(outputType: 'window' | 'file' = 'window') {
        let previewUrl = projectInfo.preview ? `${projectInfo.preview}` : undefined;
        if (previewUrl && isShared) previewUrl = previewUrl.concat(`&access=${accessKey}`);
        if (previewUrl && embedCode) previewUrl = previewUrl.concat(`&embed=${embedCode}`);

        generatePDF({
            projectInfo,
            layer,
            previewUrl,
            geometries,
            elevationProfiles,
            units,
            demsList,
            outputType
        });
    }

    function callGenerateCSV() {
        const layerGeometryIds = layer.geometries;
        const layerElevationProfiles = elevationProfiles.filter(profile => layerGeometryIds.includes(profile.id));
        const elevationProfilesData = layerElevationProfiles
            .map(profile => (profile.status === 'fulfilled' ? profile.result : null))
            .filter(profileData => profileData !== null);

        let elevationProfilesNames: string[] = [];

        layerElevationProfiles.forEach(profile => {
            const profileGeometry = layerGeometries.find(geometry => geometry.id === profile.id);
            const geometryName = profileGeometry!.content.properties.ac_name;
            elevationProfilesNames.push(geometryName);
        });

        MeasurementsCSVGenerator(
            projectName,
            layer.name,
            layerGeometries,
            units,
            demsList,
            elevationProfilesData,
            elevationProfilesNames
        );
    }

    return (
        <Tippy
            render={attrs => (
                <div
                    className={classNames('tippy-dropdown', { 'theme-dark': isInEmbedView })}
                    tabIndex={-1}
                    {...attrs}
                    onClick={() => {
                        setIsDropdownOpen(false);
                    }}
                >
                    <div
                        className='tippy-dropdown-item'
                        onClick={() => {
                            callGeneratePDF('file');
                        }}
                    >
                        PDF
                    </div>
                    <div
                        className='tippy-dropdown-item'
                        onClick={() => {
                            callGenerateCSV();
                        }}
                    >
                        CSV
                    </div>
                </div>
            )}
            delay={[100, 100]}
            interactive={true}
            offset={[0, 6]}
            placement={placement as any}
            visible={isDropdownOpen}
            onClickOutside={() => {
                setIsDropdownOpen(false);
            }}
        >
            <div className='download-btn-wrapper'>
                <TippyTooltip tooltipText={t('common:download')}>
                    <div
                        data-testid={'controlDownload'}
                        className='download-btn'
                        onClick={() => {
                            setIsDropdownOpen(prev => !prev);
                        }}
                    >
                        <i className='icon icon-export' />
                    </div>
                </TippyTooltip>
            </div>
        </Tippy>
    );
}
