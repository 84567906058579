import Tippy, { TippyProps } from '@tippyjs/react/headless';
import classNames from 'classnames';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import isProjectBelongsUser from '../../../lib/isProjectBelongsUser';
import { Routes } from '../../../sharedConstants';
import { TemporaryLayer } from '../../../store/helpers/interfaces';
import { useSelector } from '../../../store/index';
import { selectCameras } from '../../../store/slices/cameras';
import { makeSelectLayerGeometries } from '../../../store/slices/geometries';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import InspectionCSVGenerator from '../inspection-report/InspectionCSVGenerator';

type Props = {
    layer: TemporaryLayer;
    placement?: string | string[];
};

export default function InspectionsReportsDropdown({ layer, placement = 'bottom' }: Props) {
    const { t } = useTranslation(['common']);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const projectName = projectInfo.name!;
    const companyName = ''; // TODO: правка по бэку, пробросить название компании
    const selectLayerGeometries = useMemo(makeSelectLayerGeometries, []);
    const units = useSelector(state => state.coordinateSystems.units);
    const coordinateSystem = useSelector(store => store.coordinateSystems.currentCrs);
    const layerGeometries = useSelector(state => selectLayerGeometries(state, layer.id));
    const isShared = !!useRouteMatch({ path: Routes.SHARED_PROJECT_VIEW, exact: true });
    const accessKey = useSelector(state => state.sharing.access.accessKey);
    const embedCode = useSelector(state => state.sharing.embedCode);

    const { isInEmbedView } = useContext(ProjectViewAccessContext);

    const accessInfo = useSelector(state => state.sharing.accessInfo);
    const isOwnedProject = isProjectBelongsUser(accessInfo, projectInfo);

    const allCameras = useSelector(state => selectCameras(state));

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    function generatePDF(outputType: 'window' | 'file' = 'window') {
        let previewUrl = projectInfo.preview ? `${projectInfo.preview}` : undefined;
        if (previewUrl && isShared) previewUrl = previewUrl.concat(`&access=${accessKey}`);
        if (previewUrl && embedCode) previewUrl = previewUrl.concat(`&embed=${embedCode}`);

        import('../inspection-report/InspectionPDFGenerator').then(({ default: inspectionsPDFGenerator }) => {
            inspectionsPDFGenerator(
                projectName,
                companyName,
                layer.name,
                layerGeometries,
                outputType,
                projectInfo.id,
                allCameras,
                isOwnedProject,
                units,
                coordinateSystem,
                previewUrl,
                embedCode ? embedCode : undefined,
                accessKey ? accessKey : undefined
            );
        });
    }

    return (
        <Tippy
            render={attrs => (
                <div
                    className={classNames('tippy-dropdown', { 'theme-dark': isInEmbedView })}
                    tabIndex={-1}
                    {...attrs}
                    onClick={() => {
                        setIsDropdownOpen(false);
                    }}
                >
                    <div
                        className='tippy-dropdown-item'
                        onClick={() => {
                            generatePDF('file');
                        }}
                    >
                        PDF
                    </div>
                    <div
                        className='tippy-dropdown-item'
                        onClick={() => {
                            InspectionCSVGenerator(projectName, layer.name, layerGeometries, allCameras, units);
                        }}
                    >
                        CSV
                    </div>
                </div>
            )}
            delay={[100, 100]}
            interactive={true}
            offset={[0, 6]}
            placement={placement as TippyProps['placement']}
            visible={isDropdownOpen}
            onClickOutside={() => {
                setIsDropdownOpen(false);
            }}
        >
            <div className='download-btn-wrapper'>
                <TippyTooltip tooltipText={t('common:download')}>
                    <div
                        data-testid={'controlDownload'}
                        className='download-btn'
                        onClick={() => {
                            setIsDropdownOpen(prev => !prev);
                        }}
                    >
                        <i className='icon icon-export' />
                    </div>
                </TippyTooltip>
            </div>
        </Tippy>
    );
}
