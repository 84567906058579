import { HintIssue } from '@placemarkio/check-geojson';
import * as Cesium from 'cesium';
import { colord } from 'colord';
import * as Comlink from 'comlink';
import flattenGeoJson from 'geojson-flatten';
import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import { isBoolean, isUndefined } from 'lodash';
import { isGeoJsonFeatureSupported, volumePropertyNames } from '../sharedConstants';
import {
    GeoJson,
    GeoJsonFeatureCollection,
    isLineStringFeature,
    isPointFeature,
    isPolygonFeature,
    TemporaryLayer
} from '../store/helpers/interfaces';
import getGeometryMeasures from './getGeometryMeasures';

export interface GeoJsonFeaturesScavengeResult {
    result: GeoJsonFeatureCollection;
    rejected: { feature: any; reasons: HintIssue[] }[];
}

export default class GeoJsonUtils {
    public static getFeaturesFlattened(features: GeoJson[]): GeoJson[] {
        const multiFeaturesFlattenedToSupported = features
            .filter(g => !isGeoJsonFeatureSupported(g))
            .flatMap(flattenGeoJson);
        return [...features.filter(isGeoJsonFeatureSupported), ...multiFeaturesFlattenedToSupported];
    }

    public static async getValidatedFeatures(file: File) {
        const worker = new Worker(new URL('./workers/geojson-check.worker.ts', import.meta.url), {
            type: 'module'
        });
        const proxy = Comlink.wrap<{ getValidGeoJsonFeatures: (file: File) => GeoJsonFeaturesScavengeResult | string }>(
            worker
        );
        const data = await proxy.getValidGeoJsonFeatures(file);
        proxy[Comlink.releaseProxy]();
        return data;
    }

    public static hasNoElevation(geoJson: GeoJson): boolean {
        const isElevationMissingInPosition = (position: number[]) => !position?.[2];

        if (isPointFeature(geoJson)) return isElevationMissingInPosition(geoJson.geometry.coordinates);
        if (isLineStringFeature(geoJson)) return geoJson.geometry.coordinates.some(isElevationMissingInPosition);
        if (isPolygonFeature(geoJson))
            return geoJson.geometry.coordinates.some(ring => ring.some(isElevationMissingInPosition));

        return false;
    }

    public static async fillElevation(
        geometries: GeoJson[],
        terrainProvider: Cesium.TerrainProvider
    ): Promise<GeoJson[]> {
        const toCartographic = (position: number[]) => Cesium.Cartographic.fromDegrees(position[0], position[1]);
        const cartographics: Cesium.Cartographic[] = [];
        for (let geometry of geometries) {
            if (isPointFeature(geometry)) cartographics.push(toCartographic(geometry.geometry.coordinates));
            if (isLineStringFeature(geometry)) cartographics.push(...geometry.geometry.coordinates.map(toCartographic));
            if (isPolygonFeature(geometry))
                cartographics.push(...geometry.geometry.coordinates.flatMap(ring => ring.map(toCartographic)));
        }

        await Cesium.sampleTerrainMostDetailed(terrainProvider, cartographics);

        let index = 0;
        const geometriesWithElevation = geometries.map(g => {
            if (isPointFeature(g)) {
                g.geometry.coordinates[2] = cartographics[index].height || 0;
                index++;
            }

            if (isLineStringFeature(g)) {
                for (let i = 0; i < g.geometry.coordinates.length; i++) {
                    g.geometry.coordinates[i][2] = cartographics[index].height || 0;
                    index++;
                }
            }

            if (isPolygonFeature(g)) {
                for (const ring of g.geometry.coordinates) {
                    for (let position of ring) {
                        position[2] = cartographics[index].height || 0;
                        index++;
                    }
                }
            }

            return g;
        });

        return geometriesWithElevation;
    }

    public static fillRequiredPropertiesToDisplay(g: GeoJson, layer: TemporaryLayer): GeoJson {
        if (!isGeoJsonFeatureSupported(g)) return g;

        return produce(g, draft => {
            fillName(draft);
            fillColor(draft);

            if (isPolygonFeature(g)) {
                fillStrokeColor(draft);
            }

            fillVisibility(draft);
            // Remove volume properties, because volume takes much time to be calculated
            deleteVolumeProperties(draft);
            const measures = getGeometryMeasures(g);

            draft.properties = { ...draft.properties, ...measures };
        });

        function deleteVolumeProperties(draft: WritableDraft<GeoJson>) {
            for (const propName of volumePropertyNames)
                if (propName in draft.properties) delete draft.properties[propName];
        }

        function fillVisibility(draft: WritableDraft<GeoJson>) {
            if (
                isUndefined(draft.properties.ac_visibility) ||
                !isSurelyConvertableToBoolean(draft.properties.ac_visibility)
            ) {
                const checkedPropertyNames = ['visibility', 'VISIBILITY'].filter(propName =>
                    isSurelyConvertableToBoolean(draft.properties[propName])
                );
                for (const propName of checkedPropertyNames) {
                    let valueToWrite: boolean;
                    if (typeof draft.properties[propName] === 'string')
                        valueToWrite = draft.properties[propName] === 'true';
                    else if (typeof draft.properties[propName] === 'number')
                        valueToWrite = draft.properties[propName] === 1;
                    else valueToWrite = draft.properties[propName] as boolean;

                    draft.properties.ac_visibility = valueToWrite;
                }

                if (isUndefined(draft.properties.ac_visibility)) draft.properties.ac_visibility = true;
            }
        }

        function fillStrokeColor(draft: WritableDraft<GeoJson>) {
            if (!draft.properties.ac_stroke_color) {
                const checkedPropertyNames = ['strokeColor', 'STROKE_COLOR', 'stroke_color'];
                for (const propName of checkedPropertyNames) {
                    if (propName in draft.properties && typeof draft.properties[propName] === 'string') {
                        const colorCss = draft.properties[propName] as string;
                        const color = colord(colorCss);
                        if (color.isValid()) draft.properties.ac_stroke_color = color.toHex();
                    }
                }
                if (!draft.properties.ac_stroke_color) draft.properties.ac_stroke_color = layer.strokeColor;
            } else {
                const colorCss = draft.properties['ac_stroke_color'] as string;
                const color = colord(colorCss);
                if (color.isValid()) draft.properties.ac_stroke_color = color.toHex();
                else draft.properties.ac_stroke_color = layer.strokeColor;
            }
        }

        function fillColor(draft: WritableDraft<GeoJson>) {
            if (!draft.properties.ac_color) {
                const checkedPropertyNames = ['color', 'COLOR'];
                for (const propName of checkedPropertyNames) {
                    if (propName in draft.properties && typeof draft.properties[propName] === 'string') {
                        const colorCss = draft.properties[propName] as string;
                        const color = colord(colorCss);
                        if (color.isValid())
                            draft.properties.ac_color = color.alpha(isPolygonFeature(g) ? 0.4 : 1).toHex();
                    }
                }
                if (!draft.properties.ac_color)
                    draft.properties.ac_color = colord(layer.color)
                        .alpha(isPolygonFeature(g) ? 0.4 : 1)
                        .toHex();
            } else {
                const colorCss = draft.properties.ac_color as string;
                const color = colord(colorCss);
                if (color.isValid()) draft.properties.ac_color = color.alpha(isPolygonFeature(g) ? 0.4 : 1).toHex();
                else {
                    draft.properties.ac_color = colord(layer.color)
                        .alpha(isPolygonFeature(g) ? 0.4 : 1)
                        .toHex();
                }
            }
        }

        function fillName(draft: WritableDraft<GeoJson>) {
            if (!draft.properties.ac_name) {
                const checkedPropertyNames = ['name', 'NAME'];
                for (const propName of checkedPropertyNames) {
                    if (propName in draft.properties && typeof draft.properties[propName] === 'string') {
                        draft.properties.ac_name = draft.properties[propName] as string;
                    }
                }

                if (!draft.properties.ac_name) draft.properties.ac_name = draft.geometry.type;
            }
        }
    }
}

function isSurelyConvertableToBoolean(value: any): boolean {
    return isBoolean(value) || value === 'true' || value === 'false' || value === 1 || value === 0;
}
