import { useContext, useState } from 'react';
import classNames from 'classnames';
import Tippy from '@tippyjs/react/headless';
import { useTranslation } from 'react-i18next';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';

type Props = {
    url: string;
    filenameExtension: string;
};

export default function ProcessingReportDropdown({ url, filenameExtension }: Props) {
    const { t } = useTranslation(['common']);
    const { isInEmbedView } = useContext(ProjectViewAccessContext);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    return (
        <Tippy
            render={attrs => (
                <div
                    className={classNames('tippy-dropdown', { 'theme-dark': isInEmbedView })}
                    tabIndex={-1}
                    {...attrs}
                    onClick={() => {
                        setIsDropdownOpen(false);
                    }}
                >
                    <a className='tippy-dropdown-item' href={url} download target='_blank' rel='noopener noreferrer'>
                        {filenameExtension}
                    </a>
                </div>
            )}
            delay={[100, 100]}
            interactive={true}
            offset={[0, 6]}
            placement='bottom'
            visible={isDropdownOpen}
            onClickOutside={() => {
                setIsDropdownOpen(false);
            }}
        >
            <div className='download-btn-wrapper'>
                <TippyTooltip tooltipText={t('common:download')}>
                    <div
                        data-testid={'controlDownload'}
                        className='download-btn'
                        onClick={() => {
                            setIsDropdownOpen(prev => !prev);
                        }}
                    >
                        <i className='icon icon-export' />
                    </div>
                </TippyTooltip>
            </div>
        </Tippy>
    );
}
