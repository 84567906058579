import { ProcessStep } from '../generated/cloud-frontend-api';
import i18n from '../i18n/config';
import { ProcessingInfo } from '../services/processing';

export default function getProcessStepText(step: ProcessStep, processingInfo: ProcessingInfo) {
    switch (step) {
        case ProcessStep.PREPARING_DATA:
            return i18n.t('projects:progress.processSteps.preparingData');
        case ProcessStep.PROCESSING: {
            const processing = processingInfo?.items?.find(
                item => item.name === 'PROCESSING' && item.status === 'INPROGRESS'
            );
            return processing?.info || i18n.t('projects:progress.processSteps.processing');
        }
        case ProcessStep.SAVING_RESULTS:
            return i18n.t('projects:progress.processSteps.savingResults');
        case ProcessStep.PUBLISHING_RESULTS:
            return i18n.t('projects:progress.processSteps.publishingResults');
        default:
            return i18n.t('projects:progress.processSteps.processing');
    }
}
