import classNames from 'classnames';
import { useState } from 'react';
import { HexAlphaColorPicker, HexColorInput } from 'react-colorful';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useOutsideClickRef } from 'rooks';
import { AppDispatch, useSelector } from '../../../store';
import { isGeometry, isLayer } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { updateColorForAllGeometriesInLayer } from '../../../store/slices/geometryLayers';
import { ReactComponent as SvgSpinner18 } from '../../../svg/color_picker/spinner_18.svg';

import styles from './GeometryColorPicker.module.scss';
import './removeAlphaBackground.css';

type Props = {
    viewOnly: boolean;
    color: string;
    onChange(newColor: string): void;
    withApplyToAll?: boolean;
    onApplyToAll?(newColor: string): void;
};

export default function GeometryColorPicker({ viewOnly, color, onChange, withApplyToAll, onApplyToAll }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const [isOpen, setIsOpen] = useState(false);
    const temporaryLayers = useSelector(state => state.project.structure.temporaryLayers);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));
    const loadingForColorUpdate = useSelector(state => state.loading[updateColorForAllGeometriesInLayer.typePrefix]);

    const geometryLayer = isGeometry(selectedObjectInfo)
        ? temporaryLayers.find(l => l.geometries.includes(selectedObjectInfo.id))
        : undefined;

    const isUpdatingColorForAllGeometries =
        !!loadingForColorUpdate?.isLoading &&
        (geometryLayer?.id === loadingForColorUpdate?.meta?.layerUid ||
            (isLayer(selectedObjectInfo) && selectedObjectInfo.id === loadingForColorUpdate.meta?.layerUid));

    const [colorPickerDropdown] = useOutsideClickRef(() => {
        setIsOpen(false);
    });

    return (
        <div className={styles.pickerControl} ref={colorPickerDropdown}>
            <div
                data-testid={'colorPickerControl'}
                className={classNames(
                    styles.pickerSelect,
                    viewOnly && styles.readOnly,
                    isUpdatingColorForAllGeometries && styles.isLoading
                )}
                style={{ background: color }}
                onClick={e => {
                    !viewOnly && setIsOpen(prev => !prev);
                }}
            >
                {isUpdatingColorForAllGeometries && (
                    <div className={styles.pickerSpinner}>
                        <SvgSpinner18 />
                    </div>
                )}
            </div>
            {!viewOnly && isOpen && (
                <div className={styles.colorPicker} data-testid={'colorPicker'}>
                    <HexAlphaColorPicker color={color} onChange={onChange} />
                    <div className={styles.pickerInputWrapper}>
                        <b className={styles.pickerInputLabel}>HEX</b>
                        <HexColorInput
                            color={color.toUpperCase()}
                            onChange={onChange}
                            className={styles.pickerInput}
                            alpha={true}
                            type='text'
                        />
                    </div>
                    {withApplyToAll && onApplyToAll && (
                        <div className={styles.pickerApplyWrapper}>
                            <span
                                className={classNames(styles.pickerApply, 'link-like')}
                                onClick={e => {
                                    onApplyToAll(color);
                                    setIsOpen(false);
                                }}
                            >
                                {t('inspectionSidebar.colorPicker.applyToAll')}
                            </span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
