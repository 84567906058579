/* tslint:disable */
/* eslint-disable */
/**
 * billing-api
 * Billing API allows to get info about products and purchase them
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const PaymentType = {
    AUTO: 'AUTO',
    MANUAL: 'MANUAL'
} as const;

export type PaymentType = typeof PaymentType[keyof typeof PaymentType];



