import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import produce from 'immer';
import _ from 'lodash';
import { ValueOf } from 'type-fest';
import appAxios from '../../api/appAxios';
import { datasetApi, geometryApi } from '../../api/initApis';
import { Geometry } from '../../generated/vector-api/model';
import { WGS84_EPSG_CODE } from '../../sharedConstants';
import { GeoJson, ProjectStructureObjectTypes, TemporaryGeometry, TemporaryLayer } from '../helpers/interfaces';
import { AppDispatch, ApplicationState } from '../index';
import { addRecentlyFinishedUploadId } from '../sharedActions';
import { updateGeometryContent } from './geometries';
import { globalFlagsActions } from './globalFlags';
import {
    addStructureInfo,
    ExtendedStructureInfo,
    getNextStructureOrder,
    putStructure,
    PutStructureArgs,
    sortItemsByStructureInfo
} from './structure';

const name = 'geometryLayers';
interface BeginLayerArgs {
    projectUid: string;
    properties: Record<string, any>;
    startTransaction?: boolean;
    expanded?: boolean;
    sizeInBytes?: number;
    isGeojson?: boolean;
}
export const beginLayer = createAsyncThunk<
    { layer: TemporaryLayer; sizeInBytes: number; parentUid?: string; coordinateSystemUid?: string },
    BeginLayerArgs,
    { state: ApplicationState }
>(
    `${name}/beginLayer`,
    async (
        { projectUid, properties, startTransaction, sizeInBytes = 0, expanded, isGeojson },
        { getState, dispatch }
    ) => {
        const url = `/api/projects/${projectUid}/layer?startTransaction=${!!startTransaction}`;
        const { data } = await appAxios.request({ url, method: 'POST', data: { properties } });

        let parentUid = projectUid;
        const selectedObject = getState().project.selectedObject;
        if (selectedObject?.type === ProjectStructureObjectTypes.GROUP) parentUid = selectedObject.artifactId!;

        const structureInfos = getState().structure.structures;
        const putStructureInfo = createPutStructureInfo(projectUid, data, structureInfos, parentUid, expanded);
        if (startTransaction) {
            dispatch(addStructureInfo(putStructureInfo.structureInfo));
        } else {
            dispatch(putStructure(putStructureInfo));
        }

        const coordinateSystemUid = isGeojson
            ? getState().coordinateSystems.projectCrs.find(c => c.epsgCode === WGS84_EPSG_CODE)?.uid
            : undefined;

        const layer: TemporaryLayer = {
            isTemporary: false,
            id: data as string,
            sizeInBytes: sizeInBytes,
            assetUid: data as string,
            color: properties.color,
            strokeColor: properties.strokeColor,
            name: properties.name,
            isInspection: properties.isInspection,
            isPresentation: properties.isPresentation,
            coordinateSystemUid,
            geometries: [],
            parentProject: { uid: projectUid }
        };

        return { layer, sizeInBytes, parentUid, coordinateSystemUid };
    }
);

function createPutStructureInfo(
    projectUid: string,
    structureUid: string,
    structureInfos: ExtendedStructureInfo[],
    parentUid?: string,
    expanded = true
): PutStructureArgs {
    const order = getNextStructureOrder(structureInfos);

    return {
        type: ProjectStructureObjectTypes.LAYER,
        projectId: projectUid,
        structureInfo: {
            uid: structureUid,
            parentUid: parentUid || projectUid,
            properties: { visible: 'true', expanded: String(expanded), order: order.toString() }
        }
    };
}

interface CommitLayerArgs {
    projectUid: string;
    layerUid: string;
    parentUid?: string;
}
export const commitLayer = createAsyncThunk<void, CommitLayerArgs, { state: ApplicationState }>(
    `${name}/commitLayer`,
    async ({ layerUid, projectUid, parentUid }, { signal, getState, dispatch }) => {
        const url = `/api/projects/${projectUid}/layers/${layerUid}/commit`;

        const source = axios.CancelToken.source();
        signal.addEventListener('abort', () => {
            source.cancel();
        });

        await appAxios.request({ url, method: 'POST', cancelToken: source.token }).then(() => {
            const structureInfos = getState().structure.structures;
            const existedStructureInfo = structureInfos.find(info => info.uid === layerUid);
            const putStructureInfo = existedStructureInfo
                ? {
                      type: ProjectStructureObjectTypes.LAYER,
                      projectId: projectUid,
                      structureInfo: existedStructureInfo
                  }
                : createPutStructureInfo(projectUid, layerUid, structureInfos, parentUid);
            dispatch(putStructure(putStructureInfo));
        });
    },
    {
        condition({ layerUid }, { getState }) {
            const upload = getState().datasetsUpload.uploads[layerUid];
            if (upload?.status === 'aborted') return false;
        }
    }
);

interface UpdateLayerArgs {
    layerUid: string;
    projectUid: string;
    properties: Record<string, any>;
}
export const updateLayer = createAsyncThunk(
    `${name}/updateLayer`,
    async ({ projectUid, properties, layerUid }: UpdateLayerArgs) => {
        const url = `/api/projects/${projectUid}/layers/${layerUid}`;
        const { data } = await appAxios.request({ url, method: 'PUT', data: { properties } });
        return data;
    }
);

async function postGeometryToLayer(layerUid: string, projectUid: string, geoJson: GeoJson) {
    const { data } = await geometryApi.createGeometry(projectUid, layerUid, { content: geoJson });
    return data;
}

interface DeleteLayerArgs {
    projectUid: string;
    layerUid: string;
    geometryIds: string[];
}
export const deleteLayer = createAsyncThunk(
    `${name}/deleteLayer`,
    async ({ layerUid, projectUid, geometryIds }: DeleteLayerArgs) => {
        const url = `/api/projects/${projectUid}/layers/${layerUid}`;
        const { data } = await appAxios.request({ url, method: 'delete' });
        return data;
    }
);

interface UpdateGeometryArgs {
    projectUid: string;
    layerUid: string;
    id: string;
    geoJson: GeoJson;
}
export const updateGeometry = createAsyncThunk<
    void,
    UpdateGeometryArgs,
    { dispatch: AppDispatch; state: ApplicationState }
>(`${name}/updateGeometry`, async ({ id, layerUid, projectUid, geoJson }, { dispatch, getState }) => {
    try {
        const { data } = await geometryApi.putGeometry(projectUid, layerUid, id, { content: geoJson });
        dispatch(getLayerSize({ layerId: layerUid, projectId: projectUid }));
        return data;
    } catch (err) {
        if ((err as any).response.status === 413) {
            dispatch(
                globalFlagsActions.setServerErrorNotification('The maximum geometry size of 255 KB has been exceeded.')
            );
        }
    }
});

export const updateGeometryById = createAsyncThunk<void, string, { state: ApplicationState; dispatch: AppDispatch }>(
    `${name}/updateGeometryById`,
    async (id: string, { getState, dispatch }) => {
        const projectUid = getState().project.projectInfo.id;
        const geometries = getState().geometries;
        const layers = getState().project.structure.temporaryLayers;
        const geometry = geometries.entities[id];
        const layer = getState().project.structure.temporaryLayers.find(l => l.geometries.includes(id));
        if (layer?.parentProject?.uid !== projectUid) return;

        const layerUid = layers.find(l => l.geometries.includes(id))?.id;
        const url = `/api/projects/${projectUid}/layers/${layerUid}/geometries/${id}`;
        const { data } = await axios.request({ url, method: 'put', data: { content: geometry?.content! } });
        dispatch(getLayerSize({ layerId: layerUid!, projectId: projectUid! }));
        return data;
    }
);

export const updateGeometryPropertyById = createAsyncThunk<
    void,
    { id: string; owned?: boolean; propName: keyof GeoJson['properties']; propValue: ValueOf<GeoJson['properties']> },
    { dispatch: AppDispatch; state: ApplicationState }
>(`${name}/updateGeometryContentById`, ({ id, owned, propName, propValue }, { dispatch, getState }) => {
    const geoJson = getState().geometries.entities[id]?.content;
    if (geoJson) {
        dispatch(
            updateGeometryContent({
                id,
                geoJson: produce(geoJson, draft => {
                    draft.properties[propName] = propValue;
                })
            })
        );
        if (owned) dispatch(updateGeometryById(id));
    }
});

export async function getLayerGeometries(
    projectUid: string,
    layerUid: string,
    access?: string,
    embed?: string
): Promise<TemporaryGeometry[]> {
    let url = `/api/projects/${projectUid}/layers/${layerUid}/geometries`;
    if (access) url = url.concat(`?access=${access}`);
    if (embed) url = url.concat(`?embed=${embed}`);

    return appAxios
        .request({ url, method: 'GET', headers: { Accept: 'application/stream+json' } })
        .then((res: AxiosResponse<Geometry | string>) => {
            let resGeometries: Geometry[] = [];
            if (typeof res.data === 'string')
                resGeometries = _.compact(res.data.split('\n')).map(part => JSON.parse(part)) as Geometry[];

            const geometries: TemporaryGeometry[] =
                typeof res.data === 'string'
                    ? resGeometries.map(g => ({
                          id: g.uid!,
                          content: JSON.parse(`${g.content!}`) as GeoJson
                      }))
                    : [{ id: res.data.uid!, content: JSON.parse(`${res.data.content}`) as GeoJson }];

            return geometries;
        })
        .catch(err => []);
}

interface Args {
    temporaryGeometryUid: string;
}
interface Return {
    geometryId: string;
}
export const commitGeometry = createAsyncThunk<Return, Args, { state: ApplicationState; dispatch: AppDispatch }>(
    `${name}/commitGeometry`,
    async ({ temporaryGeometryUid }, { getState, dispatch }) => {
        const project = getState().project;
        const temporaryLayer = project.structure.temporaryLayers.find(l => l.geometries.includes(temporaryGeometryUid));
        const temporaryGeometryGeoJson = getState().geometries.entities[temporaryGeometryUid]?.content!;

        const geometryId = await postGeometryToLayer(
            temporaryLayer?.id!,
            project.projectInfo.id!,
            temporaryGeometryGeoJson
        );

        await dispatch(getLayerSize({ layerId: temporaryLayer?.id!, projectId: project.projectInfo.id! }));

        return { geometryId };
    }
);

interface GetLayerArgs {
    layerId: string;
    projectId: string;
}
export const getLayerSize = createAsyncThunk(`${name}/getSize`, async ({ layerId, projectId }: GetLayerArgs) => {
    const { data } = await datasetApi.getDataset(projectId, layerId);
    return data.visualData?.sizeInBytes ?? 0;
});

interface UpdateAllGeometriesInLayerArgs {
    layerUid?: string;
    ids: string[];
    properties: Record<string, any>;
    owned: boolean;
}

export const updateAllGeometriesInLayer = createAsyncThunk<
    void,
    UpdateAllGeometriesInLayerArgs,
    { state: ApplicationState }
>(`${name}/updateAllGeometriesInLayer`, async ({ layerUid, properties, owned }, { getState }) => {
    const projectUid = getState().project.projectInfo.id;
    const url = `/api/projects/${projectUid}/layers/${layerUid}/geometries`;
    if (owned)
        await appAxios.request({
            url,
            method: 'PATCH',
            data: { properties }
        });
    return;
});

export const updateColorForAllGeometriesInLayer = createAsyncThunk<
    void,
    UpdateAllGeometriesInLayerArgs,
    { dispatch: AppDispatch }
>(`${name}/updateColorForAllGeometriesInLayer`, async ({ layerUid, properties, owned, ids }, { dispatch }) => {
    await dispatch(updateAllGeometriesInLayer({ layerUid, properties, owned, ids }));
});

export const getLayer = createAsyncThunk<
    { layer: TemporaryLayer; geometries: TemporaryGeometry[] },
    GetLayerArgs,
    { dispatch: AppDispatch }
>(`${name}/getLayer`, async ({ layerId, projectId }, { dispatch }) => {
    const { data } = await datasetApi.getDataset(projectId, layerId);
    const geometries = await getLayerGeometries(projectId, layerId);
    dispatch(addRecentlyFinishedUploadId({ id: layerId }));
    const layer: TemporaryLayer = {
        id: data.datasetUid!,
        strokeColor: data.properties?.strokeColor || data.properties?.color!,
        assetUid: data.datasetUid!,
        geometries: geometries.map(g => g.id),
        isTemporary: false,
        parentProject: { uid: data.parentProjectUid! },
        color: data.properties?.color!,
        name: data.name!,
        sizeInBytes: data.sourceData?.sizeInBytes || 0
    };
    return { layer, geometries };
});

export function isVectorLayer(layer: TemporaryLayer): boolean {
    return !layer.isPresentation && !layer.isInspection;
}

export const selectLayers = createSelector(
    (state: ApplicationState) => state.project.structure.temporaryLayers,
    (state: ApplicationState) => state.structure.structures,
    (layers, structures) => sortItemsByStructureInfo(layers, structures)
);

export const selectVectorLayers = createSelector(
    (state: ApplicationState) => selectLayers(state),
    layers => layers.filter(isVectorLayer)
);

export const selectInspections = createSelector(
    (state: ApplicationState) => selectLayers(state),
    layers => layers.filter(l => l.isInspection)
);

export const selectTours = createSelector(
    (state: ApplicationState) => selectLayers(state),
    layers => layers.filter(l => l.isPresentation)
);

export const selectMeasurementsLayers = createSelector(selectVectorLayers, vectorLayers =>
    vectorLayers.filter(l => l.geometries.length)
);

export const makeSelectLayerByGeometryId = () =>
    createSelector(
        (state: ApplicationState) => state.project.structure.temporaryLayers,
        (state: ApplicationState, geometryId: string) => geometryId,
        (layers, geometryId) => layers.find(l => l.geometries.includes(geometryId))
    );
