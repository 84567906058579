import Modal, { ModalProps } from '../modal/Modal';
import ModalHead from '../modal/ModalHead';
import ModalBody from '../modal/ModalBody';
import ModalActions from '../modal/ModalActions';
import { Trans, useTranslation } from 'react-i18next';

type Props = ModalProps;

export default function PublishStorageIsFullModal({ setIsOpen, isOpen }: Props) {
    const { t } = useTranslation('modals');

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <ModalHead setIsOpen={setIsOpen}>{t('publishStorageIsFullModal.head')}</ModalHead>
            <ModalBody>
                <Trans ns={'modals'} i18nKey={'publishStorageIsFullModal.body'} components={{ p: <p /> }} />
                <ModalActions>
                    <button
                        type='button'
                        className='btn-alert'
                        onClick={e => {
                            setIsOpen(false);
                        }}
                    >
                        {t('publishStorageIsFullModal.action1')}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}
