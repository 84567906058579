import { Field, Form, Formik } from 'formik';
import { FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { ProjectInfo, ProjectType } from '../../../generated/cloud-frontend-api';
import i18n from '../../../i18n/config';
import { AppDispatch } from '../../../store';
import { renameProject } from '../../../store/slices/projectsPage';
import Modal, { ModalProps } from '../modal/Modal';
import ModalActions from '../modal/ModalActions';
import ModalBody from '../modal/ModalBody';
import ModalHead from '../modal/ModalHead';

type Props = ModalProps & { project: ProjectInfo };

const validationSchema = yup.object().shape({
    name: yup.string().required().trim().min(1).max(255, 'tooLong')
});

const headings: Record<ProjectType, string> = {
    [ProjectType.METASHAPE]: i18n.t('modals:renameProjectModal.head_project'),
    [ProjectType.NON_METASHAPE]: i18n.t('modals:renameProjectModal.head_project'),
    [ProjectType.SITE]: i18n.t('modals:renameProjectModal.head_site'),
    [ProjectType.FOLDER]: i18n.t('modals:renameProjectModal.head_folder')
};

export default function RenameProjectModal({ isOpen, setIsOpen, project }: Props) {
    const { t } = useTranslation('modals');
    const dispatch: AppDispatch = useDispatch();

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <ModalHead setIsOpen={setIsOpen}>{headings[project.type!]}</ModalHead>
            <ModalBody>
                <Formik
                    initialValues={{ name: project.name! }}
                    onSubmit={values => {
                        let name = values.name.trim();
                        setIsOpen(false);
                        dispatch(renameProject({ projectId: project.id!, name: name }));
                    }}
                    validationSchema={validationSchema}
                    validateOnMount={true}
                >
                    {({ isValid, errors }) => (
                        <Form>
                            {project.type === ProjectType.METASHAPE && (
                                <div className='modal-paragraph'>
                                    {t('renameProjectModal.metashapeProjectNotification')}
                                </div>
                            )}

                            <Field
                                type='text'
                                name='name'
                                placeholder={t('renameProjectModal.name.placeholder')}
                                autoComplete='off'
                                autoFocus
                                onFocus={(e: FocusEvent<HTMLInputElement, Element>) => {
                                    e.target.select();
                                }}
                            />

                            <div className='field-error'>
                                {errors.name === 'tooLong' && (
                                    <span>{t('renameProjectModal.name.errors.tooLong')}</span>
                                )}
                            </div>

                            <ModalActions>
                                <button
                                    type='button'
                                    className='modal-action btn-ghost-blue'
                                    onClick={() => {
                                        setIsOpen(false);
                                    }}
                                >
                                    {t('renameProjectModal.cancel')}
                                </button>
                                <button type='submit' className='modal-action btn' disabled={!isValid}>
                                    {t('renameProjectModal.save')}
                                </button>
                            </ModalActions>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
}
