import { useTranslation } from 'react-i18next';
import { useSelector } from '../../store';
import ProfileForm from './components/ProfileForm';

import styles from './Billing.module.scss';

export default function BillingPage() {
    const { t } = useTranslation('cabinet');

    const fastSpringLink = useSelector(state => state.user.fastspringLink);

    return (
        <div className={styles.contentWrapper}>
            <div className={styles.contentSection}>
                <h2 className={styles.sectionTitle}>{t('billing.billingAddressFormTitle')}</h2>
                <ProfileForm />
            </div>

            <div className={styles.contentSection}>
                <h2 className={styles.sectionTitle}>{t('payments.paymentsTitle')}</h2>
                <div className={styles.paymentsContent}>{t('payments.paymentsText1')}</div>
                <div className={styles.paymentsContent}>{t('payments.paymentsText2')}</div>
                <div className={styles.paymentsActionsWrapper}>
                    <a className='btn' href={fastSpringLink} target='_blank' rel='noopener noreferrer'>
                        {t('payments.managePayments')}
                    </a>
                </div>
            </div>
        </div>
    );
}
