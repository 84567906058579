import Tippy from '@tippyjs/react/headless';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { Parent, ProjectInfo } from '../../../generated/cloud-frontend-api/model';
import ProjectActionsList from './ProjectActionsList';

type Props = {
    project: ProjectInfo;
    parent: Parent | null;
};

const ProjectActions = ({ project, parent }: Props) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const dropdownWrapperRef = useRef<HTMLDivElement>(null);

    function closeOnScroll() {
        setIsDropdownOpen(false);
    }

    return (
        <div className='project-actions-wrapper' ref={dropdownWrapperRef}>
            <Tippy
                render={attrs => (
                    <div
                        className='tippy-dropdown'
                        tabIndex={-1}
                        onClick={e => {
                            setIsDropdownOpen(false);
                        }}
                        {...attrs}
                    >
                        <ProjectActionsList project={project} parent={parent} />
                    </div>
                )}
                zIndex={20}
                delay={[100, 100]}
                interactive={true}
                offset={[0, 4]}
                placement={'left'}
                visible={isDropdownOpen}
                appendTo={() => document.body}
                touch={true}
                onClickOutside={() => {
                    setIsDropdownOpen(false);
                }}
                onShow={() => {
                    window.addEventListener('scroll', closeOnScroll, { once: true });
                }}
            >
                <div
                    className={classNames('project-actions', { active: isDropdownOpen })}
                    onClick={() => {
                        setIsDropdownOpen(prev => !prev);
                    }}
                >
                    <i className='icon icon-menu' />
                </div>
            </Tippy>
        </div>
    );
};

export default ProjectActions;
