import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Routes, inspectorMainImageThumbnailParam } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { ExtendedCamera, makeSelectCameraArtifactId } from '../../../store/slices/cameras';
import { setGalleryVisibility, setSelectedCamera } from '../../../store/slices/projectView';
import CameraImage from '../../Elements/camera-image/CameraImage';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';

type Props = {
    camera: ExtendedCamera;
};

export default function PreviewImage({ camera }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const container = useRef<HTMLDivElement>(null);
    const selectArtifactUid = useMemo(makeSelectCameraArtifactId, []);
    const cameraArtifactId = useSelector(state => selectArtifactUid(state, camera?.uid || ''));
    const [isLoading, setIsLoading] = useState(true);
    const isEmbedProjectRoute = !!useRouteMatch({ path: Routes.EMBEDDED_PROJECT_VIEW, exact: true });

    return (
        <section className='inspector-image' ref={container}>
            <div
                className={classNames('image-wrapper', {
                    loading: isLoading && !isEmbedProjectRoute,
                    'loading-embed': isLoading && isEmbedProjectRoute
                })}
            >
                <CameraImage
                    camera={camera}
                    cameraType={'viewer'}
                    alt={camera.label}
                    className={'image-source'}
                    thumbnail={inspectorMainImageThumbnailParam}
                    onLoad={() => {
                        setIsLoading(false);
                    }}
                />

                <div className='image-controls'>
                    <TippyTooltip tooltipText={t('projectView:inspectionSidebar.imagesInspector.tooltipMaximize')}>
                        <div
                            className='image-control'
                            onClick={e => {
                                dispatch(setGalleryVisibility({ isVisible: true, mode: 'cameras' }));
                                dispatch(setSelectedCamera({ uid: camera.uid, artifactUid: cameraArtifactId! }));
                            }}
                        >
                            <i className='icon icon-fullscreen' />
                        </div>
                    </TippyTooltip>
                </div>
            </div>
        </section>
    );
}
