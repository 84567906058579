import * as Cesium from 'cesium';
import _ from 'lodash';
import { forwardRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { CesiumComponentRef, Entity, PolygonGraphics, PolylineGraphics, useCesium } from 'resium';
import { polygonMinVerticesCount } from '../../../../hooks/useDrawingCancel';
import wgs84ToCartesian3 from '../../../../lib/wgs84ToCartesian3';
import { GeometryTypes } from '../../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../../store';
import { ProjectStructureObjectTypes } from '../../../../store/helpers/interfaces';
import { setSelectedObject } from '../../../../store/sharedActions';
import { setCurrentlyHoveringShapeId } from '../../../../store/slices/projectView';
import { GeometryProps } from '../Geometry';
import { defaultBlue, polylineWidth } from '../styling';
import Point from './PointNodeGeometry';
import PolylineGeometry from './PolylineGeometry';

type Props = GeometryProps & {
    strokeColor?: Cesium.Color;
};

export default forwardRef<CesiumComponentRef<Cesium.Entity>, Props>(
    (
        {
            geometry,
            layerVisible,
            isHovering,
            isSelected,
            isEditing,
            isDrawing,
            color,
            mouseEventsBlocked,
            strokeColor,
            readonly
        },
        ref
    ) => {
        const dispatch: AppDispatch = useDispatch();
        const { scene } = useCesium();
        const floatingPointCoordinates = useSelector(state => state.projectView.floatingPointCoordinates);
        const currentlyDrawingShapeId = useSelector(state => state.projectView.currentlyDrawingShapeId);
        const shouldDrawFloatingPoint = currentlyDrawingShapeId === geometry.id;
        const allCoordinates = geometry.content.geometry.coordinates as number[][][];
        const coordinates = useMemo(() => allCoordinates[0].slice(0, allCoordinates[0].length - 1), [allCoordinates]);

        const coordinatesWithFloatingPoint = useMemo(() => {
            if (!shouldDrawFloatingPoint) return coordinates;
            return floatingPointCoordinates ? coordinates.concat([floatingPointCoordinates]) : coordinates;
        }, [floatingPointCoordinates, coordinates, shouldDrawFloatingPoint]);

        const coordinatesCartesian3 = useMemo(
            () => wgs84ToCartesian3(coordinatesWithFloatingPoint, scene!) as Cesium.Cartesian3[],
            [coordinatesWithFloatingPoint, scene]
        );

        const hierarchy = useMemo(
            () => new Cesium.CallbackProperty(() => new Cesium.PolygonHierarchy(coordinatesCartesian3), false),
            [coordinatesCartesian3]
        );

        const lastPolylinePositions = useMemo(
            () =>
                new Cesium.CallbackProperty(
                    () =>
                        wgs84ToCartesian3(
                            [coordinatesWithFloatingPoint[0], _.last(coordinatesWithFloatingPoint)!],
                            scene!
                        ) as Cesium.Cartesian3[],
                    false
                ),
            [coordinatesWithFloatingPoint, scene]
        );

        const visible = !!geometry.content.properties.ac_visibility;

        return (
            <>
                <PolylineGeometry
                    color={strokeColor || color}
                    geometry={geometry}
                    layerVisible={layerVisible}
                    isEditing={isEditing}
                    isHovering={isHovering}
                    isDrawing={isDrawing}
                    isSelected={isSelected}
                    isPartOfPolygon
                    mouseEventsBlocked={mouseEventsBlocked}
                    readonly={readonly}
                />
                <Entity
                    ref={ref}
                    id={`${geometry.id}#${GeometryTypes.POLYGON}`}
                    onMouseEnter={e => {
                        if (mouseEventsBlocked) return;

                        if (!isDrawing && !isEditing) dispatch(setCurrentlyHoveringShapeId(geometry.id));
                    }}
                    onMouseLeave={e => {
                        if (mouseEventsBlocked) return;

                        if (!isDrawing && !isEditing) dispatch(setCurrentlyHoveringShapeId(undefined));
                    }}
                    onClick={e => {
                        if (mouseEventsBlocked) return;

                        if (!isDrawing && !isEditing && isHovering)
                            dispatch(
                                setSelectedObject({
                                    type: ProjectStructureObjectTypes.GEOMETRY,
                                    artifactId: geometry.id,
                                    needToScroll: true
                                })
                            );
                    }}
                >
                    <PolygonGraphics
                        show={layerVisible && visible}
                        hierarchy={hierarchy}
                        material={color}
                        arcType={Cesium.ArcType.NONE}
                        heightReference={Cesium.HeightReference.NONE}
                        perPositionHeight={true}
                    />
                </Entity>

                <Entity>
                    <PolylineGraphics
                        positions={lastPolylinePositions}
                        clampToGround={false}
                        material={strokeColor || color}
                        width={polylineWidth}
                        arcType={Cesium.ArcType.NONE}
                        depthFailMaterial={defaultBlue.withAlpha(0.7)}
                        show={layerVisible && visible}
                    />
                </Entity>
                {(isSelected || isDrawing) && (
                    <>
                        {coordinates.map((c: number[], index) => (
                            <Point
                                id={`${GeometryTypes.POLYGON}#${geometry.id}#${index}`}
                                key={index}
                                position={wgs84ToCartesian3(c, scene!) as Cesium.Cartesian3}
                                visible={layerVisible && visible}
                                color={strokeColor?.withAlpha(1) || color.withAlpha(1)}
                                isSavingPoint={
                                    isDrawing && index === 0 && coordinates.length >= polygonMinVerticesCount - 1
                                }
                                readonly={readonly}
                            />
                        ))}
                    </>
                )}
            </>
        );
    }
);
