import * as Cesium from 'cesium';
import GeometryMeasures, { computeCenterOfPoints } from './GeometryMeasures';

export default class PolylineMeasures extends GeometryMeasures {
    private readonly _coordinates: Cesium.Cartesian3[];

    constructor(coordinates: number[][]) {
        super();
        this._coordinates = coordinates.map(c => this.fromCartographicToCartesian3(c));
    }

    public get vertices() {
        return this._coordinates;
    }

    public length(): number {
        try {
            return this.length3D(this._coordinates);
        } catch (e) {
            return 0;
        }
    }

    public horizontalLength(): number {
        return this.length2D(this._coordinates);
    }

    public elevationDifference(): number {
        const start = this._coordinates[0];
        const end = this._coordinates[this._coordinates.length - 1];
        const startHeight = Cesium.Cartographic.fromCartesian(start, this._coordinatesSystemEllipsoid).height;
        const endHeight = Cesium.Cartographic.fromCartesian(end, this._coordinatesSystemEllipsoid).height;
        return Math.abs(endHeight - startHeight);
    }

    public slope(): number {
        const start = this._coordinates[0];
        const end = this._coordinates[this._coordinates.length - 1];
        return 100 * (this.elevationDifference() / this.length2D([start, end]));
    }

    public midpoint(): Cesium.Cartesian3 {
        const length = this.length();
        const distanceToMiddlePoint = length / 2;
        let temporaryLength = 0;

        for (let i = 0; i < this._coordinates.length - 1; i++) {
            let segmentLength3D = Cesium.Cartesian3.distance(this._coordinates[i], this._coordinates[i + 1]);
            temporaryLength += segmentLength3D;
            if (temporaryLength >= distanceToMiddlePoint) {
                return this.getPointOnLine(
                    this._coordinates[i + 1],
                    this._coordinates[i],
                    temporaryLength - distanceToMiddlePoint
                );
            }
        }

        return null!; // Just to shut the compiler. Condition will always pass at some point
    }

    public centroid(): Cesium.Cartesian3 {
        return computeCenterOfPoints(this._coordinates);
    }

    public valuesToObject() {
        return {
            ac_length_meters: parseFloat(this.length().toFixed(3)),
            ac_horizontal_length_meters: parseFloat(this.horizontalLength().toFixed(3)),
            ac_elevation_difference_meters: parseFloat(this.elevationDifference().toFixed(3)),
            ac_slope_percents: parseFloat(this.slope().toFixed(3))
        };
    }
}
