import { useDispatch } from 'react-redux';
import {
    setSidebarVisibility,
    setAnimatedInspectorVisibility,
    selectIsOverlayDarkened
} from '../../../store/slices/projectView';
import { AppDispatch, useSelector } from '../../../store';

export default function DarkenedOverlay() {
    const dispatch: AppDispatch = useDispatch();
    const isOverlayDarkened = useSelector(state => selectIsOverlayDarkened(state));

    return (
        <>
            {isOverlayDarkened && (
                <div
                    data-testid={'darkenedOverlay'}
                    className='overlay-coloring'
                    onClick={e => {
                        dispatch(setSidebarVisibility(false));
                        dispatch(setAnimatedInspectorVisibility(false));
                    }}
                />
            )}
        </>
    );
}
