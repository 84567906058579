import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectInfo } from '../../../../generated/cloud-frontend-api';
import RenameProjectModal from '../../modals/RenameProjectModal';
import TippyDropdownItemWithIcon from '../../tippy-dropdown/TippyDropdownItemWithIcon';
import { ReactComponent as SvgRename } from '../../../../svg/dropdown_actions/rename.svg';

type Props = {
    project: ProjectInfo;
};

export default function RenameProjectAction({ project }: Props) {
    const { t } = useTranslation(['projects']);
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <TippyDropdownItemWithIcon
                icon={<SvgRename />}
                text={t('projects:projectActions.rename')}
                onClick={() => {
                    setIsModalOpen(true);
                }}
            />

            {isModalOpen && <RenameProjectModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} project={project} />}
        </>
    );
}
