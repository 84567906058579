import React from 'react';
import classNames from 'classnames';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import { AppDispatch, useSelector } from '../../../store';
import { useDispatch } from 'react-redux';
import { setMapSettingsVisibility } from '../../../store/slices/projectView';
import { useTranslation } from 'react-i18next';

const MapSettingsControl: React.FC = () => {
    const { t } = useTranslation('projectView');
    const dispatch: AppDispatch = useDispatch();
    const areMapSettingsOpen = useSelector(state => state.projectView.areMapSettingsOpen);

    return (
        <TippyTooltip tooltipText={t('mapControls.tooltipSceneSettings')}>
            <div
                data-testid={'control'}
                className={classNames('control-btn notTriggersMapSettingsClose', { active: areMapSettingsOpen })}
                onClick={e => {
                    dispatch(setMapSettingsVisibility(!areMapSettingsOpen));
                }}
            >
                <i className='icon icon-settings notTriggersMapSettingsClose' />
            </div>
        </TippyTooltip>
    );
};

export default MapSettingsControl;
