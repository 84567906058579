import { matchPath } from 'react-router-dom';
import { Routes } from '../sharedConstants';

export default function isSharedProjectsPageRoute(): boolean {
    return !!matchPath(window.location.pathname, { path: Routes.SHARED, exact: true });
}

export function isSharedSiteRoute() {
    return !!matchPath(window.location.pathname, { path: Routes.SHARED_SITE, exact: true });
}
