import * as Cesium from 'cesium';
import { colord } from 'colord';

export const defaultBlueCss = '#419AE0';

export const defaultBlue = Cesium.Color.fromCssColorString(defaultBlueCss);

export const polylineWidth = 2;

export function getCorrespondingHoverColor(color: string): string {
    return colord(color).lighten(0.1).toHex();
}

export const polylinesAppearance = new Cesium.PolylineColorAppearance({ translucent: true });

export const POINT_STYLES = {
    size: 16,
    sizeActive: 20,
    savingPointSize: 22,
    savingPointColorAlpha: 0.8,
    midpointOutlineColorAlpha: 0.25,
    midpointColorAlpha: 0.7
};
