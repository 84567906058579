import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';
import { FolderRouteParams } from '../../../pages/folder/Folder';
import { Routes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { getFolder, resetFolderState } from '../../../store/slices/folder';
import TitleDropdownMenu from '../title-dropdown-menu/TitleDropdownMenu';

export function FolderBreadcrumbs() {
    const dispatch: AppDispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation('navigation');
    const { folderId } = useParams<FolderRouteParams>();
    const folder = useSelector(state => state.folder);

    useEffect(() => {
        if (!folder.projectInfo?.name && folderId) {
            fetchFolder(folderId);
        }

        async function fetchFolder(id: string) {
            const result = await dispatch(getFolder({ id }));
            if (getFolder.rejected.match(result)) {
                if (result.payload?.status === 404 || result.payload?.status === 410) {
                    history.replace(generatePath(Routes.NOT_FOUND));
                }
            }
        }
    }, [dispatch, folderId, folder.projectInfo?.name, history]);

    useEffect(() => {
        return () => {
            dispatch(resetFolderState());
        };
    }, [dispatch]);

    if (!folder.projectInfo?.name)
        return (
            <div className='page-title-wrapper'>
                <div className='page-title-container'>
                    <div className='title-skel-block' />
                    <div className='title-separator'>/</div>
                    <div className='title-skel-block' />
                </div>
            </div>
        );

    return (
        <div className='page-title-wrapper'>
            <div className='page-title-container'>
                <Link to={generatePath(Routes.INDEX)} className='title-link'>
                    {t('drive')}
                </Link>

                {folder?.parent?.parentUid && (
                    <>
                        <span className='title-separator' role={'separator'}>
                            /
                        </span>
                        <Link to={generatePath(Routes.FOLDER, { folderId: folder.parent.parentUid })}>...</Link>
                    </>
                )}

                {folder?.parent?.uid && (
                    <>
                        <span className='title-separator' role={'separator'}>
                            /
                        </span>
                        <Link to={generatePath(Routes.FOLDER, { folderId: folder.parent.uid })} className='title-link'>
                            {folder.parent.name}
                        </Link>
                    </>
                )}

                <span className='title-separator' role={'separator'}>
                    /
                </span>

                {/* <i className='title-icon-shared icon icon-shared' /> */}
                <h1 className='page-title' role='pageTitle'>
                    {folder.projectInfo?.name}
                </h1>

                <TitleDropdownMenu projectInfo={folder.projectInfo!} parent={folder.parent} />
            </div>
        </div>
    );
}
