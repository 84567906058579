import classNames from 'classnames';
import { ReactNode, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectActionModal from '../../Elements/project-actions/modals/ProjectActionModal';

import { ReactComponent as SvgAbort } from '../../../svg/dropdown_actions/abort.svg';

type Props = {
    onAction: (event: SyntheticEvent) => void;
    areYouSureText: ReactNode | string;
};

const CancelSeveralProjectsProcessingAction: React.FC<Props> = ({ onAction, areYouSureText }: Props) => {
    const { t } = useTranslation(['projects', 'modals']);
    const [isModalOpen, setIsModalOpen] = useState(false);

    function handleOnClick() {
        setIsModalOpen(prev => !prev);
    }

    return (
        <>
            <div
                className={classNames('tippy-dropdown-item tippy_menu-item')}
                onClick={e => {
                    handleOnClick();
                }}
            >
                <div className='control-icon'>
                    <SvgAbort />
                </div>
                <div className='control-text'>{t('projects:projectActions.abort')}</div>
            </div>

            <ProjectActionModal
                setIsOpen={handleOnClick}
                headerText={t('modals:abortProcessingModal.head')}
                isOpen={isModalOpen}
                onAction={onAction!}
                onCancel={handleOnClick}
                areYouSureText={areYouSureText}
                actionButtonText={t('modals:abortProcessingModal.abort')}
                cancelButtonText={t('projects:projectActions.cancel')}
            />
        </>
    );
};

export default CancelSeveralProjectsProcessingAction;
