import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import * as Cesium from 'cesium';
import _ from 'lodash';
import appAxios from '../../api/appAxios';
import { isCameraAligned, isCameraMasterOrNotMultispectral } from '../../components/ProjectView/frustums/Frustums';
import { Camera } from '../../generated/camera-api/model';
import { PointProjectionResult, projectPointOntoCamera } from '../../lib/inspectionHelpers';
import isProjectBelongsUser from '../../lib/isProjectBelongsUser';
import { AppDispatch, ApplicationState } from '../index';

export interface ExtendedCamera extends Required<Camera> {
    type: 'Frame' | 'Fisheye' | 'Spherical' | 'RPC' | 'Cylindrical';
}

type CamerasState = Record<string, Array<ExtendedCamera>>;

const initialState: CamerasState = {};

const name = 'cameras';

interface GetCamerasArgs {
    artifactId: string;
    projectId: string;
}
export const getCameras = createAsyncThunk<
    ExtendedCamera[],
    GetCamerasArgs,
    { state: ApplicationState; dispatch: AppDispatch }
>(`${name}/get`, ({ artifactId, projectId }, { getState, dispatch }) => {
    const { projectInfo } = getState().project;
    const { access, accessInfo, embedCode } = getState().sharing;
    const isOwnedProject = isProjectBelongsUser(accessInfo, projectInfo);
    let url = `/api/projects/${projectId}/cameras/artifacts/${artifactId}`;
    if (access.accessKey && !isOwnedProject) url = url.concat(`?access=${access.accessKey}`);
    if (embedCode) url = url.concat(`?embed=${embedCode}`);

    return appAxios
        .request({ url, method: 'GET', headers: { Accept: 'application/stream+json' } })
        .then((res: AxiosResponse<Camera | string>) => {
            const cameras: Camera[] =
                typeof res.data === 'string'
                    ? (_.compact(res.data.split('\n')).map(part => JSON.parse(part)) as Camera[])
                    : [JSON.parse(`${res.data}`) as Camera];

            return cameras as ExtendedCamera[];
        });
});

interface FilterCamerasByPointArgs {
    point: Cesium.Cartesian3;
    scene: Cesium.Scene;
}

export type PointProjectionsOnCameras = { uid: string; projection: NonNullable<PointProjectionResult> }[];
export const filterCamerasByPoint = createAsyncThunk<
    PointProjectionsOnCameras,
    FilterCamerasByPointArgs,
    { state: ApplicationState }
>(`${name}/filterByPoint`, ({ point, scene }, { getState }) => {
    const camerasFromAllChunks = _.flatMap(getState().cameras);

    return camerasFromAllChunks
        .filter(isCameraAligned)
        .map(camera => ({
            uid: camera.uid,
            projection: projectPointOntoCamera(point, camera)
        }))
        .filter(({ projection }) => !_.isNull(projection))
        .sort((a, b) =>
            compareSortByDistance(
                camerasFromAllChunks.find(c => c.uid === a.uid)!,
                camerasFromAllChunks.find(c => c.uid === b.uid)!
            )
        ) as PointProjectionsOnCameras;

    function compareSortByDistance(a: ExtendedCamera, b: ExtendedCamera): 0 | 1 | -1 {
        const distanceFromFilteringPoint = (position: number[]) => {
            return Cesium.Cartesian3.distance(Cesium.Cartesian3.unpack(position), point);
        };

        const distanceA = distanceFromFilteringPoint(a.position);
        const distanceB = distanceFromFilteringPoint(b.position);

        if (distanceA > distanceB) return 1;
        if (distanceA < distanceB) return -1;
        return 0;
    }
});

const camerasSlice = createSlice({
    name,
    initialState,
    reducers: {
        addCamerasInfos(
            state,
            { payload }: PayloadAction<{ cameraArtifactId: string; cameras: Array<ExtendedCamera> }>
        ) {
            state[payload.cameraArtifactId] = _.uniqBy(payload.cameras, c => c.key);
        },
        resetCameras(state) {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder.addCase(getCameras.fulfilled, (state, { payload, meta }) => {
            const artifactId = meta.arg.artifactId;
            state[artifactId] = payload;
        });
    }
});

export const { resetCameras } = camerasSlice.actions;

export default camerasSlice.reducer;

export const selectCamerasStructureInfo = createSelector(
    (state: ApplicationState) => state.cameras,
    (state: ApplicationState) => state.structure.structures,
    (cameras, structures) => structures.filter(s => _.flatMap(cameras).find(c => c.uid === s.uid))
);

export const makeSelectCameraArtifactId = () =>
    createSelector(
        (state: ApplicationState) => state.cameras,
        (state: ApplicationState, photoUid: string) => photoUid,
        (cameras, photoUid) =>
            Object.keys(cameras).find(artifactId => cameras[artifactId]?.find(c => c.uid === photoUid))
    );

export const selectCamerasFilteredByPoint = createSelector(
    (state: ApplicationState) => state.cameras,
    (state: ApplicationState) => state.projectView.filteredCamerasPointProjection,
    (allCameras, filteredPointProjections) => {
        const cameras = _.flatMap(allCameras).filter(isCameraMasterOrNotMultispectral);
        return _.compact(filteredPointProjections.map(p => cameras.find(c => c.uid === p.uid)));
    }
);

export const makeSelectPointProjectionOntoCamera = () =>
    createSelector(
        (state: ApplicationState) => state.projectView.filteredCamerasPointProjection,
        (state: ApplicationState, photoUid: string) => photoUid,
        (pointProjections, photoUid) => pointProjections.find(pp => pp.uid === photoUid)?.projection
    );

export const selectCameras = createSelector(
    (state: ApplicationState) => state.cameras,
    cameras => _.flatMap(cameras)
);

export function hasDistortionParameters(camera: ExtendedCamera) {
    return _(camera)
        .pick(['pPoint', 'rDistortion', 'tDistortion', 'aCoefficients'])
        .every(value => !_.isEmpty(value));
}
