/* tslint:disable */
/* eslint-disable */
/**
 * project-access-api-v2
 * project access API v2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccessInfoResponse } from '../model';
/**
 * ProjectAccessV2Api - axios parameter creator
 * @export
 */
export const ProjectAccessV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Put email into access by projectUid
         * @param {string} projectUid Uid of project
         * @param {string} email User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmail: async (projectUid: string, email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('addEmail', 'projectUid', projectUid)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('addEmail', 'email', email)
            const localVarPath = `/projects/{projectUid}/access/email`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete email from access by email
         * @param {string} projectUid Uid of project
         * @param {string} email User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmail: async (projectUid: string, email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('deleteEmail', 'projectUid', projectUid)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('deleteEmail', 'email', email)
            const localVarPath = `/projects/{projectUid}/access/email`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable embed access
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableEmbedAccess: async (projectUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('disableEmbedAccess', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}/embed-code/disable`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Disable link access
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableLinkAccess: async (projectUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('disableLinkAccess', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}/link/disable`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable embed access
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableEmbedAccess: async (projectUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('enableEmbedAccess', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}/embed-code/enable`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable link access
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableLinkAccess: async (projectUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('enableLinkAccess', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}/link/enable`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get accesses list
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccesses: async (projectUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getAccesses', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}/accesses`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectAccessV2Api - functional programming interface
 * @export
 */
export const ProjectAccessV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectAccessV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Put email into access by projectUid
         * @param {string} projectUid Uid of project
         * @param {string} email User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addEmail(projectUid: string, email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addEmail(projectUid, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete email from access by email
         * @param {string} projectUid Uid of project
         * @param {string} email User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmail(projectUid: string, email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmail(projectUid, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disable embed access
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableEmbedAccess(projectUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableEmbedAccess(projectUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Disable link access
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disableLinkAccess(projectUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disableLinkAccess(projectUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable embed access
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableEmbedAccess(projectUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableEmbedAccess(projectUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable link access
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableLinkAccess(projectUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableLinkAccess(projectUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get accesses list
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccesses(projectUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccesses(projectUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectAccessV2Api - factory interface
 * @export
 */
export const ProjectAccessV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectAccessV2ApiFp(configuration)
    return {
        /**
         * 
         * @summary Put email into access by projectUid
         * @param {string} projectUid Uid of project
         * @param {string} email User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEmail(projectUid: string, email: string, options?: any): AxiosPromise<AccessInfoResponse> {
            return localVarFp.addEmail(projectUid, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete email from access by email
         * @param {string} projectUid Uid of project
         * @param {string} email User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmail(projectUid: string, email: string, options?: any): AxiosPromise<AccessInfoResponse> {
            return localVarFp.deleteEmail(projectUid, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disable embed access
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableEmbedAccess(projectUid: string, options?: any): AxiosPromise<AccessInfoResponse> {
            return localVarFp.disableEmbedAccess(projectUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Disable link access
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disableLinkAccess(projectUid: string, options?: any): AxiosPromise<AccessInfoResponse> {
            return localVarFp.disableLinkAccess(projectUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable embed access
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableEmbedAccess(projectUid: string, options?: any): AxiosPromise<AccessInfoResponse> {
            return localVarFp.enableEmbedAccess(projectUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable link access
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableLinkAccess(projectUid: string, options?: any): AxiosPromise<AccessInfoResponse> {
            return localVarFp.enableLinkAccess(projectUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get accesses list
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccesses(projectUid: string, options?: any): AxiosPromise<AccessInfoResponse> {
            return localVarFp.getAccesses(projectUid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectAccessV2Api - object-oriented interface
 * @export
 * @class ProjectAccessV2Api
 * @extends {BaseAPI}
 */
export class ProjectAccessV2Api extends BaseAPI {
    /**
     * 
     * @summary Put email into access by projectUid
     * @param {string} projectUid Uid of project
     * @param {string} email User email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAccessV2Api
     */
    public addEmail(projectUid: string, email: string, options?: AxiosRequestConfig) {
        return ProjectAccessV2ApiFp(this.configuration).addEmail(projectUid, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete email from access by email
     * @param {string} projectUid Uid of project
     * @param {string} email User email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAccessV2Api
     */
    public deleteEmail(projectUid: string, email: string, options?: AxiosRequestConfig) {
        return ProjectAccessV2ApiFp(this.configuration).deleteEmail(projectUid, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disable embed access
     * @param {string} projectUid Uid of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAccessV2Api
     */
    public disableEmbedAccess(projectUid: string, options?: AxiosRequestConfig) {
        return ProjectAccessV2ApiFp(this.configuration).disableEmbedAccess(projectUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Disable link access
     * @param {string} projectUid Uid of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAccessV2Api
     */
    public disableLinkAccess(projectUid: string, options?: AxiosRequestConfig) {
        return ProjectAccessV2ApiFp(this.configuration).disableLinkAccess(projectUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable embed access
     * @param {string} projectUid Uid of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAccessV2Api
     */
    public enableEmbedAccess(projectUid: string, options?: AxiosRequestConfig) {
        return ProjectAccessV2ApiFp(this.configuration).enableEmbedAccess(projectUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable link access
     * @param {string} projectUid Uid of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAccessV2Api
     */
    public enableLinkAccess(projectUid: string, options?: AxiosRequestConfig) {
        return ProjectAccessV2ApiFp(this.configuration).enableLinkAccess(projectUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get accesses list
     * @param {string} projectUid Uid of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAccessV2Api
     */
    public getAccesses(projectUid: string, options?: AxiosRequestConfig) {
        return ProjectAccessV2ApiFp(this.configuration).getAccesses(projectUid, options).then((request) => request(this.axios, this.basePath));
    }
}
