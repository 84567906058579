import produce from 'immer';
import Konva from 'konva';
import { round } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useBus } from 'react-bus';
import { useTranslation } from 'react-i18next';
import { Image, Layer, Stage } from 'react-konva';
import { useDispatch } from 'react-redux';
import { useKey } from 'rooks';
import inspectionPoint from '../../../assets/images/inspection_point.svg';
import { Issue } from '../../../entities/Issue';
import { useImageOrientationAndRotation } from '../../../hooks/useImageOrientationAndRotation';
import { useImageSizeScaledToContainerSize } from '../../../hooks/useImageSizeScaledToContainerSize';
import { DRAWING_CURSOR, GeometryTypes, Tuple, WidthHeight } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { GeoJson, ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { setSelectedObject } from '../../../store/sharedActions';
import { ExtendedCamera, makeSelectPointProjectionOntoCamera } from '../../../store/slices/cameras';
import {
    makeSelectIssuesByImageUid,
    makeSelectIssuesByPhotoUid,
    removeGeometry,
    updateGeometryContent
} from '../../../store/slices/geometries';
import { selectInspections } from '../../../store/slices/geometryLayers';
import { setIdOfGeometryWithSavingPreview } from '../../../store/slices/projectView';
import useDrawing from '../geometry-drawing/useDrawing';
import { IssuesRects } from '../image-viewer/IssuesRects';
import { useDragBoundsCheck, useZoom } from '../image-viewer/useKonvaUtils';
import { useURLImage } from '../image-viewer/useURLImage';

type Props = {
    camera: ExtendedCamera;
    onLoad(): void;
    imageUrl: string;
    isLoading: boolean;
    setHasFailed: React.Dispatch<React.SetStateAction<boolean>>;
    setIsAddIssueActive: React.Dispatch<React.SetStateAction<boolean>>;
    containerSize: WidthHeight;
    isAddIssueActive?: boolean;
    showIssues?: boolean;
};

function InspectedCanvasImage({
    camera,
    isLoading,
    onLoad,
    containerSize,
    setHasFailed,
    isAddIssueActive,
    setIsAddIssueActive,
    showIssues,
    imageUrl
}: Props) {
    const { t } = useTranslation('projectView');
    const dispatch: AppDispatch = useDispatch();
    const currentIssueId = useRef('');
    const stage = useRef<Konva.Stage>(null!);
    const imageRef = useRef<Konva.Image>(null!);
    const layerRef = useRef<Konva.Layer>(null!);
    const singleIssueRect = useRef<Konva.Rect>(null!);
    const hasUppedThePointer = useRef(false);
    const bus = useBus();
    const layers = useSelector(selectInspections);
    const selectIssuesByPhotoUid = useMemo(makeSelectIssuesByPhotoUid, []);
    const selectIssuesByImageUid = useMemo(makeSelectIssuesByImageUid, []);
    const selectPointProjectionOntoCamera = useMemo(makeSelectPointProjectionOntoCamera, []);
    const pointProjectionOntoCamera = useSelector(state => selectPointProjectionOntoCamera(state, camera.uid!));
    const cameraIssuesByPhotoUid = useSelector(state => selectIssuesByPhotoUid(state, camera.uid!));
    const cameraIssuesByImageUid = useSelector(state => selectIssuesByImageUid(state, camera.photoUid!));
    const cameraIssues = cameraIssuesByImageUid.length > 0 ? cameraIssuesByImageUid : cameraIssuesByPhotoUid;
    const cameraFilteringPoint = useSelector(state => state.projectView.cameraFilteringPoint);
    const { getLayerUid, addGeometry, enableLayerVisibilityIfInvisible } = useDrawing();
    const [isImageReady, setImageReady] = useState(false);

    const currentIssue = cameraIssues.find(i => i.id === currentIssueId.current);
    const isSelectionDisabled = isAddIssueActive;
    const dragDisabled = isAddIssueActive;
    const zoomDisabled = isAddIssueActive;
    const drawDisabled = !isAddIssueActive;

    const { image, orientation } = useURLImage({
        url: imageUrl,
        onError: () => {
            setHasFailed(true);
        },
        onLoad: () => {
            onLoad();
            setImageReady(true);
        },
        parseOrientation: true
    });
    const { image: inspectionPointImage } = useURLImage({ url: inspectionPoint });

    useKey('Escape', () => {
        if (currentIssueId.current) currentIssueId.current = '';
        if (currentIssue) {
            const layer = layers.find(l => l.geometries.find(g => g === currentIssue.id));
            dispatch(setSelectedObject({ type: ProjectStructureObjectTypes.LAYER, artifactId: layer?.id! }));
            dispatch(removeGeometry({ id: currentIssue.id, datasetId: layer?.id! }));
        }
    });

    const { scale, zoomFn, zoomFnTouchEnd, zoomFnTouchMove } = useZoom();
    const dragBoundsCheck = useDragBoundsCheck();

    const shouldDisplayPointForCameraType = camera.type !== 'RPC';
    const shouldDisplayPoint = shouldDisplayPointForCameraType;

    const cleanupUnfinishedDrawing = useCallback(() => {
        if (currentIssue) {
            const layer = layers.find(l => l.geometries.find(g => g === currentIssue.id));
            dispatch(setSelectedObject({ type: ProjectStructureObjectTypes.LAYER, artifactId: layer?.id! }));
            dispatch(removeGeometry({ id: currentIssue.id, datasetId: layer?.id! }));
            currentIssueId.current = '';
        }
    }, [currentIssue, layers, dispatch]);

    const { height, left, top, width } = useImageSizeScaledToContainerSize(containerSize, {
        width: camera.width,
        height: camera.height
    });

    const inspectionPointXY = pointProjectionOntoCamera
        ? {
              x: Math.round((pointProjectionOntoCamera.u * (width || 0)) / camera.width) - 21 / scale.x,
              y: Math.round((pointProjectionOntoCamera.v * (height || 0)) / camera.height) - 21 / scale.y
          }
        : null;

    const { offset, rotation } = useImageOrientationAndRotation(orientation, width || 0, height || 0);

    useEffect(() => {
        document.addEventListener('pointerup', saveIssue);
        document.addEventListener('contextmenu', cleanupUnfinishedDrawing);

        return () => {
            document.removeEventListener('pointerup', saveIssue);
            document.removeEventListener('contextmenu', cleanupUnfinishedDrawing);
        };

        async function saveIssue(e: PointerEvent) {
            hasUppedThePointer.current = true;
            if (currentIssue) {
                const bbox = currentIssue.content.properties.ac_issue_bbox!;

                // No width or height
                if (!bbox[2] || !bbox[3]) {
                    cleanupUnfinishedDrawing();
                    return;
                }

                const canvasBlob = (await imageRef.current.toBlob({
                    mimeType: 'image/jpeg',
                    x: 0,
                    y: 0,
                    width: imageRef.current.getStage()!.width(),
                    height: imageRef.current.getStage()!.height()
                })) as Blob;
                bus.emit('Issue.Save', { payload: { id: currentIssue.id, canvasBlob } });
                setIsAddIssueActive?.(false);
                currentIssueId.current = '';
            }
        }
    }, [cleanupUnfinishedDrawing, currentIssue, setIsAddIssueActive, bus, dispatch]);

    if (isLoading) return null;

    return (
        <Stage
            ref={stage}
            style={{ cursor: isAddIssueActive ? DRAWING_CURSOR : 'default' }}
            width={containerSize.width}
            height={containerSize.height}
            scale={scale}
            onWheel={e => {
                if (zoomDisabled) return;
                zoomFn(e);
            }}
        >
            <Layer
                ref={layerRef}
                x={left || 0}
                y={top || 0}
                draggable={!dragDisabled}
                onTouchMove={e => {
                    if (zoomDisabled) return;
                    zoomFnTouchMove(e);
                }}
                onTouchEnd={e => {
                    if (zoomDisabled) return;
                    zoomFnTouchEnd();
                }}
                onPointerDown={async e => {
                    hasUppedThePointer.current = false;
                    if (drawDisabled) return;

                    const layer = await getLayerUid({ isInspection: true });
                    enableLayerVisibilityIfInvisible(layer.id);

                    if (hasUppedThePointer.current) return;

                    const x =
                        (layerRef.current.getRelativePointerPosition()!.x * camera.width) / imageRef.current.width();
                    const y =
                        (layerRef.current.getRelativePointerPosition()!.y * camera.height) / imageRef.current.height();

                    const geoJson: GeoJson<GeometryTypes.POINT> = Issue.geoJson(cameraFilteringPoint!, {
                        ac_name: t('defaultNames.issue'),
                        ac_photoUid: camera.uid,
                        ac_image_uid: camera.photoUid,
                        ac_issue_bbox: [round(x, 1), round(y, 1), 0, 0],
                        ac_issue_bbox_in_preview: [e.evt.offsetX, e.evt.offsetY, 0, 0]
                    });
                    const id = addGeometry(geoJson, layer.id);
                    currentIssueId.current = id;
                    dispatch(setIdOfGeometryWithSavingPreview(id));
                    dispatch(
                        setSelectedObject({
                            artifactId: id,
                            type: ProjectStructureObjectTypes.GEOMETRY,
                            needToScroll: true
                        })
                    );
                }}
                onPointerMove={e => {
                    if (drawDisabled) return;

                    if (currentIssue) {
                        const x =
                            (layerRef.current.getRelativePointerPosition()!.x * camera.width) /
                            imageRef.current.width();
                        const y =
                            (layerRef.current.getRelativePointerPosition()!.y * camera.height) /
                            imageRef.current.height();
                        dispatch(
                            updateGeometryContent({
                                id: currentIssue.id,
                                geoJson: produce(currentIssue.content, draft => {
                                    const bbox = draft.properties.ac_issue_bbox as Tuple<number, 4>;
                                    const previewBbox = draft.properties.ac_issue_bbox_in_preview as Tuple<number, 4>;
                                    draft.properties.ac_issue_bbox = [bbox[0], bbox[1], round(x, 1), round(y, 1)];
                                    draft.properties.ac_issue_bbox_in_preview = [
                                        previewBbox[0],
                                        previewBbox[1],
                                        e.evt.offsetX,
                                        e.evt.offsetY
                                    ];
                                })
                            })
                        );
                    }
                }}
                onDragEnd={e => {
                    dragBoundsCheck(e);
                }}
            >
                <Image
                    ref={imageRef}
                    image={image}
                    height={height || 0}
                    width={width || 0}
                    rotation={rotation}
                    position={offset}
                />
                {showIssues && isImageReady && (
                    <>
                        {cameraIssues.map(issue => {
                            return (
                                <IssuesRects
                                    key={issue.id}
                                    sourceSize={{ width: camera.width, height: camera.height }}
                                    issue={issue}
                                    imageHeight={height || 0}
                                    imageWidth={width || 0}
                                    scale={scale}
                                    selectionDisabled={isSelectionDisabled}
                                    ref={singleIssueRect}
                                />
                            );
                        })}
                        {shouldDisplayPoint && inspectionPointXY && (
                            <Image
                                image={inspectionPointImage}
                                x={inspectionPointXY.x}
                                y={inspectionPointXY.y}
                                scaleX={1 / scale.x}
                                scaleY={1 / scale.y}
                            />
                        )}
                    </>
                )}
            </Layer>
        </Stage>
    );
}

export default memo(InspectedCanvasImage);
