import * as Cesium from 'cesium';
import _ from 'lodash';
import { ComponentProps, useContext } from 'react';
import { CameraFlyToBoundingSphere } from 'resium';
import FlyToTilesetsContext from '../../../../contexts/FlyToTilesetsContext';
import { Status } from '../../../../generated/cloud-frontend-api/model/status';
import { getPhotoPov } from '../../../../lib/getPhotoPov';
import { isGeoJsonFeatureSupported, nadirHeadingPitchRange } from '../../../../sharedConstants';
import { useSelector } from '../../../../store';
import {
    PointOfView,
    ProjectStructureObjectTypes,
    TemporaryGeometry,
    TemporaryLayer,
    isIssuePointGeometry,
    isPointGeometry,
    isPolylineGeometry,
    isViewpointGeometry
} from '../../../../store/helpers/interfaces';
import { getGroupDatasets } from '../../../../store/slices/datasets';
import { selectGeometriesArray } from '../../../../store/slices/geometries';
import { selectSelectedPresentationSlide } from '../../../../store/slices/presentation';
import { isCameraAligned } from '../../frustums/Frustums';
import CameraFlyToObject from './CameraFlyToObject';
import CameraFlyToPointOfView from './CameraFlyToPointOfView';

const commonProps: Partial<ComponentProps<typeof CameraFlyToBoundingSphere>> = {
    once: true,
    duration: 1,
    offset: nadirHeadingPitchRange
};

type Props = {
    artifactRefs: Record<string, Cesium.Cesium3DTileset | Cesium.ImageryLayer | Cesium.Entity>;
    objectType: ProjectStructureObjectTypes;
    objectId: string | string[];
};

export default function HandleCameraFlyToEvent({ artifactRefs, objectId, objectType }: Props) {
    const { clickedTilesets, setClickedTilesetIds } = useContext(FlyToTilesetsContext);
    const datasets = useSelector(state => state.datasets.datasets);
    const boundingSpheres = useSelector(state => state.projectView.tilesetsBoundingSpheres);
    const selectedSlide = useSelector(state => selectSelectedPresentationSlide(state));
    const allCameras = useSelector(state => state.cameras);
    const structures = useSelector(state => state.structure.structures);
    const allGeometries = useSelector(selectGeometriesArray);
    const allLayers = useSelector(state => state.project.structure.temporaryLayers);

    function onComplete() {
        setClickedTilesetIds({ ids: null });
    }

    function isBoundingSphereEmpty(bs: Cesium.BoundingSphere) {
        return bs.center.equals(new Cesium.Cartesian3(0, 0, 0));
    }

    const flyToCamerasRequested = objectType === ProjectStructureObjectTypes.CAMERAS;
    const flyToChunkRequested = objectType === ProjectStructureObjectTypes.CHUNK;
    const flyToArtifactRequested = objectType === ProjectStructureObjectTypes.ARTIFACT;
    const flyToDatasetRequested = objectType === ProjectStructureObjectTypes.DATASET;
    const flyToGroupRequested = objectType === ProjectStructureObjectTypes.GROUP;
    const flyToPhotoOriginRequested = objectType === ProjectStructureObjectTypes.IMAGE;
    const flyToLayerObject = allLayers.find(l => l.id === objectId);
    const flyToGeometryObject = allGeometries.find(g => g.id === objectId);

    if (flyToGroupRequested) {
        const groupId = clickedTilesets.ids as string;
        const groupLayers = allLayers.filter(
            l => structures.find(s => s.uid === l.id)?.parentUid === groupId && l.geometries.length > 0
        );
        const groupDatasets = getGroupDatasets(datasets, structures, groupId).filter(
            d => d.visualData?.status === Status.COMPLETED
        );
        const boundingSphere = Cesium.BoundingSphere.fromBoundingSpheres([
            ..._.compact(
                groupDatasets.map(d => (d?.visualData?.dataUid ? boundingSpheres[d.visualData?.dataUid!] : null))
            ),
            ...groupLayers.map(l => getLayerBoundingSphere(l, allGeometries))
        ]);

        if (isBoundingSphereEmpty(boundingSphere)) {
            return null;
        }

        return <CameraFlyToBoundingSphere boundingSphere={boundingSphere} {...commonProps} onComplete={onComplete} />;
    }

    if (flyToGeometryObject) {
        const isIssue = isIssuePointGeometry(flyToGeometryObject);
        const isViewpoint = isViewpointGeometry(flyToGeometryObject);
        if (isIssue) {
            const ac_point_of_view = flyToGeometryObject.content.properties.ac_point_of_view as
                | PointOfView<number>
                | undefined;
            if (ac_point_of_view) {
                return <CameraFlyToPointOfView onComplete={onComplete} pov={ac_point_of_view} />;
            }

            const bs = getGeometryBoundingSphere(flyToGeometryObject);
            return <CameraFlyToBoundingSphere boundingSphere={bs} {...commonProps} onComplete={onComplete} />;
        }

        if (isViewpoint) {
            const pointOfView = (flyToGeometryObject.content.properties.ac_point_of_view ??
                flyToGeometryObject.content.properties.pointOfView) as PointOfView<number> | undefined;

            if (pointOfView)
                return (
                    <CameraFlyToPointOfView
                        pov={pointOfView}
                        onComplete={() => {
                            setClickedTilesetIds({ ids: null });
                        }}
                    />
                );
            else return null;
        }

        const boundingSphere = getGeometryBoundingSphere(flyToGeometryObject);
        if (isBoundingSphereEmpty(boundingSphere)) {
            return null;
        }

        return <CameraFlyToBoundingSphere boundingSphere={boundingSphere} {...commonProps} onComplete={onComplete} />;
    }

    if (flyToCamerasRequested && !Array.isArray(objectId)) {
        const boundingSphere = Cesium.BoundingSphere.fromBoundingSpheres(
            allCameras[objectId]
                ?.filter(isCameraAligned)
                .map(camera => new Cesium.BoundingSphere(Cesium.Cartesian3.unpack(camera.position), 1))
        );

        if (isBoundingSphereEmpty(boundingSphere)) return null;

        return <CameraFlyToBoundingSphere boundingSphere={boundingSphere} {...commonProps} onComplete={onComplete} />;
    }
    return (
        <>
            {/* Fly to on zoom clicks */}
            {objectId && (
                <>
                    {flyToPhotoOriginRequested && !Array.isArray(objectId) && (
                        <CameraFlyToPointOfView
                            onComplete={onComplete}
                            pov={getPhotoPov(_.flatMap(allCameras).find(c => c.uid === objectId)!)}
                        />
                    )}

                    {flyToChunkRequested && Array.isArray(objectId) && (
                        <CameraFlyToBoundingSphere
                            boundingSphere={Cesium.BoundingSphere.fromBoundingSpheres(
                                objectId
                                    .filter(tilesetId => !!boundingSpheres[tilesetId])
                                    .map(tilesetId => boundingSpheres[tilesetId])
                            )}
                            {...commonProps}
                            onComplete={onComplete}
                        />
                    )}

                    {(flyToArtifactRequested || flyToDatasetRequested) &&
                        !Array.isArray(objectId) &&
                        artifactRefs[objectId] instanceof Cesium.ImageryLayer && (
                            <CameraFlyToBoundingSphere
                                boundingSphere={boundingSpheres[objectId]}
                                once
                                duration={1}
                                onComplete={() => {
                                    setClickedTilesetIds({ ids: null });
                                }}
                                offset={nadirHeadingPitchRange}
                            />
                        )}

                    {(flyToArtifactRequested || flyToDatasetRequested) &&
                        !Array.isArray(objectId) &&
                        !(artifactRefs[objectId] instanceof Cesium.ImageryLayer) && (
                            <CameraFlyToObject object={artifactRefs[objectId]} />
                        )}

                    {flyToLayerObject && (
                        <CameraFlyToBoundingSphere
                            boundingSphere={getLayerBoundingSphere(flyToLayerObject, allGeometries)}
                            {...commonProps}
                            onComplete={onComplete}
                        />
                    )}
                </>
            )}
        </>
    );
}

function getGeometryBoundingSphere(geometry: TemporaryGeometry): Cesium.BoundingSphere {
    return Cesium.BoundingSphere.fromPoints(
        Cesium.Cartesian3.fromDegreesArrayHeights(
            isPointGeometry(geometry)
                ? geometry.content.geometry.coordinates
                : isPolylineGeometry(geometry)
                ? geometry.content.geometry.coordinates.flat()
                : (geometry.content.geometry.coordinates as number[][][])[0].flat()
        )
    );
}

function getLayerBoundingSphere(layer: TemporaryLayer, geometries: TemporaryGeometry[]): Cesium.BoundingSphere {
    return Cesium.BoundingSphere.fromBoundingSpheres(
        layer.geometries
            .map(id => geometries.find(g => g.id === id)!)
            .filter(g => isGeoJsonFeatureSupported(g.content))
            .map(g => getGeometryBoundingSphere(g))
    );
}
