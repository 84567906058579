import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PaymentResultState {
    processingTime?: { quantity: number; price: string };
    storage?: { quantity: number; price: string };
    hasUpgradedStorage?: boolean;
    hasDowngradedStorage?: boolean;
    isPaymentPending?: boolean;
    totalPrice?: string;
    selectedStorageProductName?: string;
}

const initialState: PaymentResultState = {};

const name = 'paymentResult';

const paymentResultSlice = createSlice({
    name,
    initialState,
    reducers: {
        setPaymentResult(state, { payload }: PayloadAction<PaymentResultState>) {
            return { ...state, ...payload };
        },
        setPaymentResultProperty(
            state,
            { payload }: PayloadAction<{ propName: keyof PaymentResultState; propValue: any }>
        ) {
            state[payload.propName] = payload.propValue;
        }
    }
});

export const { setPaymentResult, setPaymentResultProperty } = paymentResultSlice.actions;
