import React, { useCallback, useMemo, useRef } from 'react';
import { Entity, PolylineGraphics, useCesium } from 'resium';
import { defaultBlueCss, polylineWidth } from '../styling';
import Point from '../geometries/PointNodeGeometry';
import wgs84ToCartesian3 from '../../../../lib/wgs84ToCartesian3';
import * as Cesium from 'cesium';
import { useSelector } from '../../../../store';
import { v4 } from 'uuid';
import RulerMeasurementDisplay from './RulerMeasurementDisplay';
import PolylineMeasures from '../../geometry-properties/measures/PolylineMeasures';
import formatNumber from '../../../../lib/formatNumber';
import { GeoJson } from '../../../../store/helpers/interfaces';
import { GeometryTypes, Units } from '../../../../sharedConstants';
import _ from 'lodash';
import PolygonMeasures from '../../geometry-properties/measures/PolygonMeasures';
import produce from 'immer';
import { convertAreaUnit, convertLengthUnit } from '../../../../lib/convertUnit';
import { getUnitsSquareShortName, getUnitsShortName } from '../../../../lib/getUnitsShortName';

export default function RulerPolylineGeometry() {
    const { scene } = useCesium();
    const id = useRef(`${v4()}#ruler`);
    const { floatingPointCoordinates } = useSelector(state => state.projectView);
    const { geometry } = useSelector(state => state.rulerTool);
    const units = useSelector(state => state.coordinateSystems.units);
    const unitsNaming = getUnitsShortName(units);
    const unitsSquareNaming = getUnitsSquareShortName(units);

    const coordinates = useMemo(() => geometry?.geometry.coordinates || [], [geometry]);
    const coordinatesWithFloatingPoint = useMemo(() => {
        return floatingPointCoordinates ? coordinates.concat([floatingPointCoordinates]) : coordinates;
    }, [floatingPointCoordinates, coordinates]);

    const positions = useMemo(
        () =>
            new Cesium.CallbackProperty(
                () => wgs84ToCartesian3(coordinatesWithFloatingPoint, scene!) as Cesium.Cartesian3[],
                false
            ),
        [coordinatesWithFloatingPoint, scene]
    );
    const color = useMemo(
        () => Cesium.Color.fromCssColorString(geometry?.properties?.ac_color || defaultBlueCss),
        [geometry]
    );
    const material: Cesium.MaterialProperty = useMemo(
        () => new Cesium.PolylineDashMaterialProperty({ color, dashLength: 6 }),
        [color]
    );

    const polylineMeasures = useMemo(
        () => new PolylineMeasures(coordinatesWithFloatingPoint),
        [coordinatesWithFloatingPoint]
    );

    const polygonMeasures = useMemo(() => {
        if (!geometry) return null;
        const polygon = polygonizePolyline(
            produce(geometry, draft => {
                draft.geometry.coordinates = coordinatesWithFloatingPoint;
            }),
            scene!
        );
        if (polygon) return new PolygonMeasures(polygon.geometry.coordinates);
        return null;
    }, [geometry, scene, coordinatesWithFloatingPoint]);

    const getRulerMeasurementPosition = useCallback(
        (positioning: 'up' | 'center') => {
            return positioning === 'up' ? getUpPosition() : getCenterPosition();

            function getUpPosition() {
                if (!floatingPointCoordinates) return null;
                const [lon, lat, height] = floatingPointCoordinates;
                return Cesium.Cartesian3.fromDegrees(lon, lat, height);
            }

            function getCenterPosition() {
                if (!polygonMeasures) return null;
                return polygonMeasures.center();
            }
        },
        [polygonMeasures, floatingPointCoordinates]
    );

    if (!geometry || !coordinatesWithFloatingPoint.length) return null;

    return (
        <>
            <Entity id={id.current}>
                <PolylineGraphics
                    positions={positions}
                    clampToGround={false}
                    width={polylineWidth}
                    material={material}
                    arcType={Cesium.ArcType.NONE}
                    depthFailMaterial={color.withAlpha(0.7)}
                    show
                />
            </Entity>
            {coordinates.map((c, index) => (
                <Point
                    id={`${id.current}#Point#${index}`}
                    key={index}
                    position={wgs84ToCartesian3(c, scene!) as Cesium.Cartesian3}
                    visible
                    color={color}
                    readonly
                />
            ))}
            <RulerMeasurementDisplay
                rulerEntityId={`${id.current}#${GeometryTypes.POLYLINE}`}
                value={`${formatNumber(
                    convertLengthUnit(polylineMeasures.length(), Units.METRE, units),
                    2
                )} ${unitsNaming}`}
                position={getRulerMeasurementPosition('up')}
            />
            {polygonMeasures && (
                <RulerMeasurementDisplay
                    rulerEntityId={`${id.current}#${GeometryTypes.POLYGON}`}
                    value={`${formatNumber(
                        convertAreaUnit(polygonMeasures.area(), Units.METRE, units),
                        2
                    )} ${unitsSquareNaming}`}
                    positioning={'center'}
                    position={getRulerMeasurementPosition('center')}
                />
            )}
        </>
    );
}

function polygonizePolyline(
    polyline: GeoJson<GeometryTypes.POLYLINE>,
    scene: Cesium.Scene
): GeoJson<GeometryTypes.POLYGON> | null {
    if (polyline.geometry.coordinates.length < 3) return null;
    const first = polyline.geometry.coordinates[0];
    const last = _.last(polyline.geometry.coordinates)!;
    const firstLastDistance = Cesium.Cartesian2.distance(
        Cesium.SceneTransforms.wgs84ToWindowCoordinates(scene, wgs84ToCartesian3(first, scene) as Cesium.Cartesian3)!,
        Cesium.SceneTransforms.wgs84ToWindowCoordinates(scene, wgs84ToCartesian3(last, scene) as Cesium.Cartesian3)!
    );
    // pixels
    if (firstLastDistance <= 40) {
        return {
            type: 'Feature',
            properties: { ...polyline.properties },
            geometry: {
                type: GeometryTypes.POLYGON,
                coordinates: [[...polyline.geometry.coordinates, polyline.geometry.coordinates[0]]]
            }
        };
    }

    return null;
}
