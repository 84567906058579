/* tslint:disable */
/* eslint-disable */
/**
 * project-access-api-v2
 * project access API v2
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface AccessInfo
 */
export interface AccessInfo {
    /**
     * Access key
     * @type {string}
     * @memberof AccessInfo
     */
    'accessKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessInfo
     */
    'projectRole'?: AccessInfoProjectRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof AccessInfo
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessInfo
     */
    'accessType'?: AccessInfoAccessTypeEnum;
}

export const AccessInfoProjectRoleEnum = {
    VIEWER: 'VIEWER',
    NO_ACCESS: 'NO_ACCESS'
} as const;

export type AccessInfoProjectRoleEnum = typeof AccessInfoProjectRoleEnum[keyof typeof AccessInfoProjectRoleEnum];
export const AccessInfoAccessTypeEnum = {
    EMAIL: 'EMAIL',
    LINK: 'LINK',
    EMBED: 'EMBED'
} as const;

export type AccessInfoAccessTypeEnum = typeof AccessInfoAccessTypeEnum[keyof typeof AccessInfoAccessTypeEnum];


