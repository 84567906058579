import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { Select } from 'antd';
import { AppDispatch, useSelector } from '../../../store';
import { useDispatch } from 'react-redux';
import { saveRootStructureProperty } from '../../../store/slices/structure';
import { updateCurrentCoordinateSystem, CoordinateSystem } from '../../../store/slices/coordinateSystems';
import { useTranslation } from 'react-i18next';
import { selectPersonalAndDefaultCoordinateSystems } from '../../../store/slices/coordinateSystems';

import styles from './MapSettings.module.scss';

type Props = {
    containerRef: HTMLElement;
};

const DROPDOWN_ITEM_HEIGHT = 32; // высота одного пункта в дропдауне селекта
const DROPDOWN_LIST_HEIGHT = DROPDOWN_ITEM_HEIGHT * 5; // полная высота дропдауна селекта (5 пунктов)

export default function CoordinateSystemSwitch({ containerRef }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const activeSystem = useSelector(store => store.coordinateSystems.currentCrs);
    const coordinateSystems = useSelector(state => selectPersonalAndDefaultCoordinateSystems(state));
    const { isInEmbedView } = useContext(ProjectViewAccessContext);
    const { t } = useTranslation(['projectView']);

    const optionName = (cs: CoordinateSystem) => {
        if (cs.epsgCode) {
            return `${cs.name} (EPSG::${cs.epsgCode.toString()})`;
        }
        return cs.name;
    };

    const options = useMemo(() => {
        return coordinateSystems.map(cs => {
            return {
                value: cs.uid,
                label: optionName(cs),
                title: cs.name
            };
        });
    }, [coordinateSystems]);

    return (
        <div className={styles.settingsItem}>
            <div className={styles.settingsItemTitle}>{t('projectView:mapControls.coordinate-system.title')}</div>
            <Select
                value={activeSystem.uid}
                getPopupContainer={() => containerRef}
                onChange={async newValue => {
                    let csUid: string = newValue.toString();
                    let crsInfo = coordinateSystems.find(crs => crs.uid === csUid);
                    if (crsInfo && crsInfo.uid !== activeSystem.uid) {
                        await dispatch(updateCurrentCoordinateSystem({ crsInfo, isInEmbedView })).unwrap();
                        dispatch(
                            saveRootStructureProperty({
                                propertyName: 'coordinateSystem',
                                propertyValue: crsInfo
                            })
                        );
                    }
                }}
                showSearch
                filterOption={(inputValue, option) => {
                    return Boolean(
                        option?.label && option.label.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                    );
                }}
                className={classNames('ant-select-small', { 'theme-dark': isInEmbedView })}
                popupClassName={classNames('ant-dropdown-small', { 'theme-dark': isInEmbedView })}
                disabled={coordinateSystems.length === 1}
                notFoundContent={false}
                placement='topLeft'
                dropdownAlign={{ offset: [0, 0] }} // taken from 'rc-select' props
                listHeight={DROPDOWN_LIST_HEIGHT}
                virtual={true}
                options={options}
            />
        </div>
    );
}
