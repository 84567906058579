import * as Cesium from 'cesium';
import _ from 'lodash';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CesiumMovementEvent, PointPrimitive, PointPrimitiveCollection, Primitive, useCesium } from 'resium';
import { usePreviousImmediate } from 'rooks';
import useAreSceneMouseEventsBlocked from '../../../hooks/useAreSceneMouseEventsBlocked';
import { primitiveOptimizationProps } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { setSelectedObject } from '../../../store/sharedActions';
import { ExtendedCamera, selectCamerasStructureInfo } from '../../../store/slices/cameras';
import { setCamerasLoading, setSelectedCamera } from '../../../store/slices/projectView';
import { isObjectVisible } from '../../../store/slices/structure';
import {
    FrustumGeometryColors,
    FrustumParts,
    frustumActiveColors,
    frustumCameraOriginOutlineColor,
    frustumCameraOriginOutlineWidth,
    frustumCameraOriginScaleByDistance,
    frustumCameraOriginSize,
    frustumDefaultColors,
    frustumFrameAppearance,
    frustumHoverColors
} from './styling';

type Props = {
    artifactId: string;
    visible: boolean;
};

let hoveredPhotoUid = '';

function Frustums({ artifactId, visible }: Props) {
    const { scene } = useCesium();
    const dispatch: AppDispatch = useDispatch();
    const frameRef = useRef<Cesium.Primitive | null>(null);
    const cameraOriginRef = useRef<Cesium.Primitive | null>(null);
    const selectedCamera = useSelector(state => state.projectView.selectedCamera);
    const isCamerasInspectionEnabled = useSelector(state => state.projectView.isCamerasInspectionEnabled);
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const cameras = useSelector(state => state.cameras[artifactId] || []);
    const structures = useSelector(selectCamerasStructureInfo);
    const [frameGeometryInstances, setFrameGeometryInstances] = useState<Cesium.GeometryInstance[]>([]);

    const cameraPositions = useMemo(() => {
        return cameras
            .filter(isCameraAligned)
            .filter(isCameraMasterOrNotMultispectral)
            .map(camera => ({
                position: Cesium.Cartesian3.unpack(camera.position),
                id: camera.uid
            }));
    }, [cameras]);

    const previousSelectedCamera = usePreviousImmediate(selectedCamera);
    const mouseEventsBlocked = useAreSceneMouseEventsBlocked();

    const updateFrustumColors = useCallback((colors: FrustumGeometryColors, photoUid: string) => {
        const frustumGeometry = {
            [FrustumParts.CAMERA_ORIGIN]: cameraOriginRef.current,
            [FrustumParts.FRAME]: frameRef.current
        };

        _.forEach(frustumGeometry, (value, key) => {
            if (value?.ready) {
                const attributes = value.getGeometryInstanceAttributes(photoUid);
                if (attributes)
                    attributes.color = Cesium.ColorGeometryInstanceAttribute.toValue(colors[key as FrustumParts]);
            }
        });
    }, []);

    const onHover = useCallback(
        (e: CesiumMovementEvent) => {
            if (mouseEventsBlocked) return;

            const pickedObject = scene?.pick(e.endPosition!);
            if (
                Cesium.defined(pickedObject) &&
                (pickedObject.primitive instanceof Cesium.Primitive ||
                    pickedObject.primitive instanceof Cesium.PointPrimitive)
            ) {
                const photoUid = pickedObject.id.split('#')[0];
                if (photoUid !== selectedCamera?.uid) {
                    hoveredPhotoUid = photoUid;
                    updateFrustumColors(frustumHoverColors, hoveredPhotoUid);
                    scene?.requestRender();
                }
            }
        },
        [selectedCamera, scene, updateFrustumColors, mouseEventsBlocked]
    );

    const onHoverEnd = useCallback(
        (e: CesiumMovementEvent) => {
            if (mouseEventsBlocked) return;

            if (hoveredPhotoUid !== selectedCamera?.uid) {
                updateFrustumColors(frustumDefaultColors, hoveredPhotoUid);
                scene?.requestRender();
                hoveredPhotoUid = '';
            }
        },
        [scene, updateFrustumColors, selectedCamera, mouseEventsBlocked]
    );

    const onClick = useCallback(
        (e: CesiumMovementEvent) => {
            if (mouseEventsBlocked) return;

            const pickedObject = scene?.pick(e.position!);
            if (
                Cesium.defined(pickedObject) &&
                (pickedObject.primitive instanceof Cesium.Primitive ||
                    pickedObject.primitive instanceof Cesium.PointPrimitive)
            ) {
                const photoUid = pickedObject.id.split('#')[0];
                dispatch(
                    setSelectedCamera({
                        artifactUid: artifactId,
                        uid: photoUid,
                        selectInWorkspace: !isCamerasInspectionEnabled
                    })
                );
                dispatch(
                    setSelectedObject({
                        artifactId: photoUid,
                        type: ProjectStructureObjectTypes.IMAGE,
                        needToScroll: true
                    })
                );
            }
        },
        [scene, dispatch, mouseEventsBlocked, artifactId, isCamerasInspectionEnabled]
    );

    useEffect(() => {
        if (!cameras.length || frameGeometryInstances.length > 0) return; // already initialized
        const renderableCameras = cameras?.filter(c => isCameraAligned(c) && isCameraMasterOrNotMultispectral(c));
        const frameGIs = renderableCameras?.reduce<Cesium.GeometryInstance[]>(
            (acc, { position, quaternion, uid, aspect, fov }) => {
                const cameraPosition = Cesium.Cartesian3.unpack(position);
                const cameraOrientation = Cesium.Quaternion.unpack(quaternion);
                return acc.concat(
                    new Cesium.GeometryInstance({
                        geometry: new Cesium.FrustumOutlineGeometry({
                            frustum: new Cesium.PerspectiveFrustum({
                                fov: fov,
                                aspectRatio: aspect,
                                near: 0.5, // Небольшое пространство для размещения шара camera origin
                                far: 10
                            }),
                            vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
                            origin: cameraPosition,
                            orientation: cameraOrientation
                        } as any),
                        attributes: {
                            color: Cesium.ColorGeometryInstanceAttribute.fromColor(
                                frustumDefaultColors[FrustumParts.FRAME]
                            ),
                            show: new Cesium.ShowGeometryInstanceAttribute(isObjectVisible(structures, uid))
                        },
                        id: uid
                    })
                );
            },
            []
        );
        setFrameGeometryInstances(frameGIs);
    }, [artifactId, cameras, structures, frameGeometryInstances.length]);

    useEffect(() => {
        if (selectedCamera) {
            if (isCamerasInspectionEnabled) return;
            if (previousSelectedCamera) updateFrustumColors(frustumDefaultColors, previousSelectedCamera.uid);
            updateFrustumColors(frustumActiveColors, selectedCamera.uid);
        } else {
            // If selected camera was reset from outside, deselect previously selected
            if (previousSelectedCamera) updateFrustumColors(frustumDefaultColors, previousSelectedCamera.uid);
        }
        scene?.requestRender();
    }, [selectedCamera, scene, updateFrustumColors, previousSelectedCamera, isCamerasInspectionEnabled]);

    useEffect(() => {
        if (!frameRef.current?.ready) return;

        for (const s of structures) {
            const attributes = frameRef.current.getGeometryInstanceAttributes(s.uid);
            if (attributes) {
                attributes.show = Cesium.ShowGeometryInstanceAttribute.toValue(isObjectVisible(structures, s.uid!));
            }
        }
    }, [structures, artifactId]);

    if (!frameGeometryInstances.length || isCompareToolEnabled) return null;

    return (
        <>
            <PointPrimitiveCollection>
                {cameraPositions.map(({ position, id }) => (
                    <PointPrimitive
                        id={`${id}#frustumPoint`}
                        key={id}
                        position={position}
                        color={frustumDefaultColors.CAMERA_ORIGIN}
                        outlineColor={frustumCameraOriginOutlineColor}
                        outlineWidth={frustumCameraOriginOutlineWidth}
                        scaleByDistance={frustumCameraOriginScaleByDistance}
                        pixelSize={frustumCameraOriginSize}
                        show={visible && isObjectVisible(structures, id)}
                        onMouseEnter={onHover}
                        onMouseLeave={onHoverEnd}
                        onClick={onClick}
                    />
                ))}
            </PointPrimitiveCollection>
            <Primitive
                onReady={primitive => {
                    dispatch(setCamerasLoading({ artifactId, hasLoaded: true }));
                }}
                ref={el => {
                    frameRef.current = el?.cesiumElement!;
                }}
                show={visible}
                geometryInstances={frameGeometryInstances}
                appearance={frustumFrameAppearance}
                onMouseEnter={onHover}
                onMouseLeave={onHoverEnd}
                onClick={onClick}
                {...primitiveOptimizationProps}
            />
        </>
    );
}

export default memo(Frustums);

export function isCameraAligned(camera: ExtendedCamera): boolean {
    return _(camera)
        .pick(['position', 'transform', 'quaternion', 'rotation'])
        .every(value => !_.isEmpty(value));
}

export function isCameraMasterOrNotMultispectral(camera: ExtendedCamera): boolean {
    return camera.masterKey !== null ? camera.masterKey === camera.key : true;
}
