import { Select } from 'antd';
import classNames from 'classnames';
import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { AppDispatch, useSelector } from '../../../store';
import {
    createCoordinateSystem,
    selectPersonalAndDefaultCoordinateSystems
} from '../../../store/slices/coordinateSystems';

type Props = {
    className?: string;
    selectedCrsId: string | undefined;
    setSelectedCrsId(newValue: string): void;
    setCrsErrorMessage(message: string): void;
    error?: boolean;
};

export default function SelectCrs({ className, selectedCrsId, setSelectedCrsId, setCrsErrorMessage, error }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const { owned, isInEmbedView } = useContext(ProjectViewAccessContext);
    const prjFileInput = useRef<HTMLInputElement>(null!);
    const coordinateSystems = useSelector(state => selectPersonalAndDefaultCoordinateSystems(state));

    return (
        <div className={className}>
            <Select
                value={selectedCrsId}
                placeholder={t('projectView:inspectionSidebar.datasetProperties.select.placeholder')}
                onSelect={newValue => {
                    setSelectedCrsId(newValue);
                    if (newValue === 'local') {
                        prjFileInput.current.click();
                        return;
                    }

                    setCrsErrorMessage('');
                }}
                onClick={e => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                }}
                showSearch
                className={classNames({ 'theme-dark': isInEmbedView, 'ant-error': error })}
                popupClassName={classNames({ 'theme-dark': isInEmbedView })}
                filterOption={(inputValue, option) => {
                    return Boolean(
                        option?.children &&
                            option.children.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                    );
                }}
                notFoundContent={false}
            >
                {owned && (
                    <Select.Option value='local'>
                        {t('projectView:inspectionSidebar.datasetProperties.select.option_useLocalPrj')}
                    </Select.Option>
                )}
                {coordinateSystems.map(({ uid, name, epsgCode }) => (
                    <Select.Option key={uid} value={uid} title={`${name} ${epsgCode ? `(EPSG::${epsgCode})` : ''}`}>
                        {name} {epsgCode ? `(EPSG::${epsgCode})` : ''}
                    </Select.Option>
                ))}
            </Select>

            <input
                data-testid={'fileInput'}
                ref={prjFileInput}
                type={'file'}
                style={{ display: 'none', visibility: 'hidden' }}
                accept={'.prj'}
                onChange={async e => {
                    const files = e.target.files;
                    if (!files?.length) return;

                    const file = files[0];
                    if (file.size === 0) {
                        setCrsErrorMessage(t('projectView:inspectionSidebar.datasetProperties.error_emptyPrjFile'));
                        return;
                    }

                    const content = await file.text();
                    setCrsErrorMessage('');

                    const result = await dispatch(createCoordinateSystem({ content }));
                    if (createCoordinateSystem.rejected.match(result)) {
                        let errMsg = t('projectView:inspectionSidebar.datasetProperties.error_invalidFile');
                        if (result?.payload) errMsg = errMsg.concat(`: ${result?.payload}`);
                        setCrsErrorMessage(errMsg);
                    } else {
                        setSelectedCrsId(result?.payload?.uid!);
                    }
                }}
            />
        </div>
    );
}
