import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../../store';
import { selectStorageLimitAtFreePlanDisplayValue } from '../../../store/slices/accountResources';
import { selectScheduledPlanStartDateDisplayValue } from '../../../store/slices/accountResources';
import StorageProduct from './StorageProduct';

import styles from '../SubscriptionForm.module.scss';

export default function StorageSubscription() {
    const { t } = useTranslation(['subscribe', 'glossary']);
    const dispatch: AppDispatch = useDispatch();
    const storageProducts = useSelector(state => state.billing.products.storageProducts);
    const account = useSelector(state => state.accountResources.account);
    const currentPlan = account.plan.current;
    const downgradeDate = useSelector(state => selectScheduledPlanStartDateDisplayValue(state));
    const storageLimitAtFreePlanDisplayValue = useSelector(state => selectStorageLimitAtFreePlanDisplayValue(state));

    return (
        <div className={styles.contentSection}>
            <h2 className={styles.sectionTitle}>{t('glossary:storage')}</h2>

            <div className={styles.fieldInfo}>
                {currentPlan?.overdue ? (
                    <span>
                        {t('subscribe:storage.overdueNotification', {
                            downgradeDate,
                            storageLimitAtFreePlanDisplayValue
                        })}
                    </span>
                ) : (
                    <>
                        {currentPlan?.type === 'COMMERCIAL' && (
                            <>
                                <p>{t('subscribe:storage.commercialPlanHint1')}</p>
                                <p>{t('subscribe:storage.commercialPlanHint2')}</p>
                            </>
                        )}
                        {currentPlan?.type === 'FREE' && <span>{t('subscribe:storage.freePlanHint')}</span>}
                    </>
                )}
            </div>

            <ul className={styles.formList}>
                {storageProducts.map(({ name, grade }) => (
                    <StorageProduct grade={grade!} name={name!} key={name} />
                ))}
            </ul>
        </div>
    );
}
