import { formatDuration, hoursToMinutes, hoursToSeconds } from 'date-fns';
import i18n from '../i18n/config';
import secondsToHoursAndMinutes from './secondsToHoursAndMinutes';

export default function getShortTimeString(totalSeconds: number): string {
    const hr = i18n.t('common:timeUnits_short.hour');
    const min = i18n.t('common:timeUnits_short.minute');
    const sec = i18n.t('common:timeUnits_short.second');
    const { hours, minutes } = secondsToHoursAndMinutes(totalSeconds);
    const seconds = totalSeconds - hoursToSeconds(hours) - hoursToMinutes(minutes);
    if (totalSeconds >= 3600) return `${hours} ${hr} ${minutes} ${min}`;
    return `${minutes} ${min} ${seconds} ${sec}`;
}
