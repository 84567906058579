import * as Cesium from 'cesium';
import { memo, useCallback, useEffect } from 'react';
import { useCesium } from 'resium';
import { useSelector } from '../../../store';
import CesiumMultiTerrainProvider from '../terrain/CesiumMultiTerrainProvider';
import Material from '../terrain/Material';
import { AppResource } from './ResourceProvider';
import { Dataset } from '../../../entities/Dataset';

type Props = {
    dem3DResources: AppResource[];
    needsToRecreateTerrainMaterial: boolean;
    setNeedsToRecreateTerrainMaterial(needs: boolean): void;
    needsToUpdateTerrainMaterialOpacity: boolean;
    setNeedsToUpdateTerrainMaterialOpacity(needs: boolean): void;
};

function ElevationRampServiceVisualization({
    dem3DResources,
    needsToRecreateTerrainMaterial,
    setNeedsToRecreateTerrainMaterial,
    needsToUpdateTerrainMaterialOpacity,
    setNeedsToUpdateTerrainMaterialOpacity
}: Props) {
    const { globe } = useCesium();
    const datasets = useSelector(state => state.datasets.datasets);
    const chunks = useSelector(state => state.project.structure.chunks);
    const structures = useSelector(state => state.structure.structures);

    const terrainProvider =
        globe?.terrainProvider instanceof CesiumMultiTerrainProvider
            ? (globe?.terrainProvider as unknown as CesiumMultiTerrainProvider)
            : undefined;
    const layers = terrainProvider?.getLayers();

    const createElevationColoredMap = useCallback(
        (layer: any) => {
            const boundingRectangle = layer.boundingRectangle;
            const layerUrl = layer.resource.url;
            const dem3DResource = dem3DResources.find(resource => resource.url === layerUrl);
            const dataset = datasets.find(d => d.visualData?.dataUid === dem3DResource?.tilesetId);
            const structureInfo = structures.find(s => s.uid === dataset?.datasetUid);
            const opacity = structureInfo?.properties.opacity
                ? Number(structureInfo?.properties.opacity)
                : Dataset.defaultOpacity(dataset?.sourceData?.type!);

            const c = Cesium.Rectangle.center(boundingRectangle);
            const center = Cesium.Cartographic.toCartesian(c, globe?.ellipsoid);

            const enuToEcef = Cesium.Transforms.eastNorthUpToFixedFrame(center, globe?.ellipsoid);
            const ecefToEnu = Cesium.Matrix4.inverse(enuToEcef, new Cesium.Matrix4());

            const vertices = [
                Cesium.Cartographic.toCartesian(Cesium.Rectangle.southeast(boundingRectangle), globe?.ellipsoid),
                Cesium.Cartographic.toCartesian(Cesium.Rectangle.southwest(boundingRectangle), globe?.ellipsoid),
                Cesium.Cartographic.toCartesian(Cesium.Rectangle.northwest(boundingRectangle), globe?.ellipsoid),
                Cesium.Cartographic.toCartesian(Cesium.Rectangle.northeast(boundingRectangle), globe?.ellipsoid)
            ];
            const enuVertices = applyTransformationMatrix(vertices, ecefToEnu);
            return {
                boundsEnu: enuVertices,
                ecefToEnu: ecefToEnu,
                minHeight: layer.minElevation,
                maxHeight: layer.maxElevation,
                opacity: opacity
            };
        },
        [datasets, dem3DResources, globe, structures]
    );

    const getElevationColoredMaps = useCallback(() => {
        const maps: ElevationColoredMap[] = [];
        if (globe && layers) {
            layers
                .slice(0, -1)
                .filter(l => l.boundingRectangle)
                .forEach(l => maps.push(createElevationColoredMap(l)));
        }
        return maps;
    }, [layers, globe, createElevationColoredMap]);

    useEffect(() => {
        if (!terrainProvider || !globe || !layers) {
            return;
        }
        if (layers.length <= 1 && needsToRecreateTerrainMaterial) {
            if (globe.material && !globe.material.isDestroyed()) {
                const previousMaterial = globe.material;
                globe.material = undefined;
                previousMaterial.destroy();
            }
            setNeedsToRecreateTerrainMaterial(false);
            return;
        }
        if (!needsToRecreateTerrainMaterial) {
            return;
        }
        const elevationColoredMaps = getElevationColoredMaps();

        const enuHoods: Cesium.Cartesian3[] = [];
        elevationColoredMaps.forEach(map => Array.prototype.push.apply(enuHoods, map.boundsEnu));
        const ecefToEnus: Cesium.Cartesian4[] = [];
        elevationColoredMaps.forEach(map =>
            ecefToEnus.push(
                Cesium.Matrix4.getColumn(map.ecefToEnu, 0, new Cesium.Cartesian4()),
                Cesium.Matrix4.getColumn(map.ecefToEnu, 1, new Cesium.Cartesian4()),
                Cesium.Matrix4.getColumn(map.ecefToEnu, 2, new Cesium.Cartesian4()),
                Cesium.Matrix4.getColumn(map.ecefToEnu, 3, new Cesium.Cartesian4())
            )
        );

        const mins: number[] = [];
        elevationColoredMaps.forEach(map => mins.push(map.minHeight));
        const maxs: number[] = [];
        elevationColoredMaps.forEach(map => maxs.push(map.maxHeight));
        const opacities: number[] = [];
        elevationColoredMaps.forEach(map => opacities.push(map.opacity));

        const eartchm = new Material({
            fabric: {
                uniforms: {
                    hood: {
                        type: 'arrayvec3_' + 4 * elevationColoredMaps.length,
                        value: enuHoods
                    },
                    ecefToEnuArray: {
                        type: 'arrayvec4_' + 4 * elevationColoredMaps.length,
                        value: ecefToEnus
                    },
                    minimumHeights: {
                        type: 'arrayfloat_' + elevationColoredMaps.length,
                        value: mins
                    },
                    maximumHeights: {
                        type: 'arrayfloat_' + elevationColoredMaps.length,
                        value: maxs
                    },
                    opacities: {
                        type: 'arrayfloat_' + elevationColoredMaps.length,
                        value: opacities
                    },
                    image: getColorRamp()
                },
                source: createShaderSource(elevationColoredMaps.length)
            }
        });

        const previousMaterial = globe.material;
        globe.material = eartchm as unknown as Cesium.Material;
        if (previousMaterial && !previousMaterial.isDestroyed()) {
            previousMaterial.destroy();
        }
        setNeedsToRecreateTerrainMaterial(false);
    }, [
        terrainProvider,
        layers,
        globe,
        chunks,
        getElevationColoredMaps,
        setNeedsToRecreateTerrainMaterial,
        dem3DResources,
        needsToRecreateTerrainMaterial
    ]);

    useEffect(() => {
        if (needsToUpdateTerrainMaterialOpacity && globe?.material) {
            const elevationColoredMaps = getElevationColoredMaps();
            const opacities: number[] = [];
            elevationColoredMaps.forEach(map => opacities.push(map.opacity));

            globe.material.uniforms['opacities'] = {
                type: 'arrayfloat_' + elevationColoredMaps.length,
                value: opacities
            };
        }
        setNeedsToUpdateTerrainMaterialOpacity(false);
    }, [
        terrainProvider,
        globe,
        chunks,
        dem3DResources,
        getElevationColoredMaps,
        needsToUpdateTerrainMaterialOpacity,
        setNeedsToUpdateTerrainMaterialOpacity
    ]);

    function applyTransformationMatrix(positions: Cesium.Cartesian3[], matrix: Cesium.Matrix4): Cesium.Cartesian3[] {
        return positions.map(position => Cesium.Matrix4.multiplyByPoint(matrix, position, new Cesium.Cartesian3()));
    }

    function getColorRamp() {
        const ramp = document.createElement('canvas');
        ramp.width = 100;
        ramp.height = 1;
        const ctx = ramp.getContext('2d');

        const values = [0.0, 0.25, 0.5, 0.75, 1.0];

        if (ctx) {
            const grd = ctx.createLinearGradient(0, 0, 100, 0);
            grd.addColorStop(values[0], '#0000ff');
            grd.addColorStop(values[1], '#00ffff');
            grd.addColorStop(values[2], '#00ff00');
            grd.addColorStop(values[3], '#ffff00');
            grd.addColorStop(values[4], '#ff0000');

            ctx.fillStyle = grd;
            ctx.fillRect(0, 0, 100, 1);
        }
        return ramp;
    }

    function createShaderSource(length: number): string {
        return `czm_material czm_getMaterial(czm_materialInput materialInput)
            {
                czm_material material = czm_getDefaultMaterial(materialInput);
                float height = materialInput.height;
                vec4 worldCoordinate4 = czm_inverseView * vec4(-materialInput.positionToEyeEC, 1.0);
                worldCoordinate4[3] = 1.0;

                for (int j = 0; j < ${length}; j++) {
                    mat4 ecefToEnu = mat4(ecefToEnuArray[j*4], ecefToEnuArray[j*4 + 1], ecefToEnuArray[j*4 + 2], ecefToEnuArray[j*4 + 3]);
                    vec4 enuPosition = ecefToEnu * worldCoordinate4;
                    bool isPointInside = false;
                    for (int i = 0; i < 4; i++) {
                        //const int index = i + j*4; 
                        if ( i == 0) {
                             if (((hood[i + j*4].y > enuPosition.y) != (hood[(j+1)*4 -1].y > enuPosition.y)) &&
                                (enuPosition.x < (hood[(j+1)*4-1].x-hood[i + j*4].x) * (enuPosition.y-hood[i + j*4].y) / (hood[(j+1)*4-1].y-hood[i + j*4].y) + hood[i + j*4].x) ) {
                                isPointInside = !isPointInside;
                             }
                        } else {
                             if (((hood[i + j*4].y>enuPosition.y) != (hood[i + j*4-1].y>enuPosition.y)) &&
                                (enuPosition.x < (hood[i + j*4-1].x-hood[i + j*4].x) * (enuPosition.y-hood[i + j*4].y) / (hood[i + j*4-1].y-hood[i + j*4].y) + hood[i + j*4].x) ) {
                                isPointInside = !isPointInside;
                             }
                        }
                    }
                    if (isPointInside) {
                        float scaledHeight = clamp((materialInput.height - minimumHeights[j]) / (maximumHeights[j] - minimumHeights[j]), 0.0, 1.0);
                        vec4 rampColor = texture(image, vec2(scaledHeight, 0.5));
                        rampColor = czm_gammaCorrect(rampColor);
                        material.diffuse = rampColor.rgb;//vec3(0.5, 0.0, 0.0); //
                        material.alpha = opacities[j]; //rampColor.a;
                        return material;
                    }
                }  
                material.diffuse = vec3(0.0);
                material.alpha = 0.0;
                return material;
            }`;
    }

    type ElevationColoredMap = {
        boundsEnu: Cesium.Cartesian3[];
        ecefToEnu: Cesium.Matrix4;
        minHeight: number;
        maxHeight: number;
        opacity: number;
    };

    return null;
}

export default ElevationRampServiceVisualization;
