import { unwrapResult } from '@reduxjs/toolkit';
import produce from 'immer';
import { projectsApi } from '../api/initApis';
import { defaultBlueCss } from '../components/ProjectView/geometry-layers/styling';
import {
    Chunk,
    Dataset,
    Parent,
    Project,
    ProjectPartType,
    ProjectStructure,
    ProjectType
} from '../generated/cloud-frontend-api';
import { StructureInfo } from '../generated/project-structure-api';
import { mapDatasetToLayer } from '../lib/mapDatasetToLayer';
import sortArtifacts from '../lib/sortArtifacts';
import sortChunks from '../lib/sortChunks';
import {
    ExtendedChunk,
    ExtendedStructure,
    ProjectStructureObjectTypes,
    TemporaryGeometry,
    TemporaryLayer
} from './helpers/interfaces';
import { AppDispatch, ApplicationState } from './index';
import { getCameras } from './slices/cameras';
import { CoordinateSystem, initCurrentCoordinateSystem, setUnits } from './slices/coordinateSystems';
import { setDatasets } from './slices/datasets';
import { addGeometries } from './slices/geometries';
import { getLayerGeometries } from './slices/geometryLayers';
import { ExtendedProject, setLayers } from './slices/project';
import { setBaseImageryProvider, setTerrainViewMode } from './slices/projectView';
import { ExtendedStructureInfo, getStructures, putStructure, putStructures } from './slices/structure';

interface RawProjectWithFilledStructure extends Required<Omit<Project, 'parent'>> {
    structure: { chunks: Chunk[]; datasets: Dataset[]; activeChunk: number | undefined };
    parent: Parent | null;
}

export default class GetProjectById {
    private _owned = true;

    constructor(
        private readonly _projectId: string,
        private readonly _dispatch: AppDispatch,
        private readonly _getState: () => ApplicationState,
        private readonly _access?: string,
        private readonly _embed?: string
    ) {
        this._owned = !this._access && !this._embed;
    }

    async result(): Promise<{ project: ExtendedProject; structures: ExtendedStructureInfo[] }> {
        const rawProject = await this.getProject();
        if (rawProject.projectInfo?.type === ProjectType.SITE) {
            return {
                project: { ...rawProject, structure: { chunks: [], temporaryLayers: [] } },
                structures: []
            };
        }
        const layers = await this.addGeometryLayers(rawProject);
        this._dispatch(setLayers(layers));
        const project: ExtendedProject = {
            ...rawProject,
            structure: { ...castProjectStructureToExtended(rawProject.structure), temporaryLayers: layers }
        };
        const structures = await this.setStructureProperties(project);
        if (project.projectInfo?.type === ProjectType.METASHAPE && !project.projectInfo?.published) {
            return { project, structures };
        }

        project.structure.chunks = sortChunks(project.structure.chunks);
        for (const chunk of project.structure.chunks)
            chunk.artifacts = sortArtifacts(chunk.artifacts, rawProject.structure.datasets);

        this.getCameras(project);
        return { project, structures };
    }

    private async getProject(): Promise<RawProjectWithFilledStructure> {
        const { data } = await projectsApi.getProjectById(this._projectId, {
            params: { access: this._access, embed: this._embed }
        });
        if (data.projectInfo?.type === ProjectType.METASHAPE || data.projectInfo?.type === ProjectType.NON_METASHAPE) {
            this._dispatch(
                setDatasets(
                    data.structure?.datasets?.filter(d => d.projectPartType !== ProjectPartType.VECTOR_LAYERS) || []
                )
            );
        }

        return {
            structure: {
                chunks: data.structure?.chunks || [],
                datasets: data.structure?.datasets || [],
                activeChunk: data.structure?.activeChunk
            },
            parent: data.parent || null,
            projectInfo: data.projectInfo!
        };
    }

    private async setStructureProperties(project: ExtendedProject): Promise<ExtendedStructureInfo[]> {
        let structures = unwrapResult(
            await this._dispatch(
                getStructures({ projectId: this._projectId, access: this._access, embed: this._embed })
            )
        );

        let root = structures.find(s => s.uid === this._projectId);
        let hasCreatedRoot = false;
        if (!root && this._owned) {
            root = await this.addRootStructureElement();
            hasCreatedRoot = true;
        }
        if (root) this.setRootStructureProperties(root, this._projectId);

        let needsToUpdateStructure = hasCreatedRoot;

        // Check that all structures have correct parentUid. If not, create it to keep the object tree
        const newStructures = structures.map(structure => {
            let index = project.structure.chunks.findIndex(c => c.assetUid === structure.uid);
            if (index !== -1) {
                if (!structure.parentUid && root) {
                    structure = { ...structure, parentUid: root.uid };
                    needsToUpdateStructure = true;
                }
            }

            index = -1;
            project.structure.chunks.forEach(chunk => {
                index = chunk.artifacts.findIndex(a => a.datasetUid === structure.uid);
                if (index !== -1) {
                    if (!structure.parentUid && chunk.assetUid) {
                        structure = { ...structure, parentUid: chunk.assetUid };
                        needsToUpdateStructure = true;
                    }
                }
            });

            index = project.structure.temporaryLayers.findIndex(l => l.assetUid === structure.uid);
            if (index !== -1) {
                if (!structure.parentUid && root) {
                    structure = { ...structure, parentUid: root.uid };
                    needsToUpdateStructure = true;
                }
            }

            return structure;
        });

        // Set default cameras structure info
        const projectCameras = project.structure.chunks
            .filter(c => c.cameras?.artifactUid)
            .map(c => ({
                chunkId: c.assetUid,
                camerasId: c.cameras?.artifactUid
            }));
        for (const { camerasId, chunkId } of projectCameras) {
            if (!newStructures.find(s => s.uid === camerasId)) {
                const structureInfo = {
                    uid: camerasId!,
                    parentUid: chunkId,
                    properties: { visible: String(false), expanded: String(false) }
                };
                this._dispatch(
                    putStructure({
                        structureInfo,
                        projectId: project.projectInfo.id!,
                        type: ProjectStructureObjectTypes.CAMERAS
                    })
                );
                newStructures.push(structureInfo);
            }
        }

        if (needsToUpdateStructure && this._owned) {
            this._dispatch(
                putStructures({
                    structureInfo: hasCreatedRoot ? [...newStructures, root!] : newStructures,
                    projectId: this._projectId
                })
            );
        }

        return newStructures;
    }

    private async addRootStructureElement(): Promise<ExtendedStructureInfo> {
        const root: ExtendedStructureInfo = { uid: this._projectId, properties: {} };
        await this._dispatch(
            putStructure({ projectId: this._projectId, structureInfo: root, type: ProjectStructureObjectTypes.ROOT })
        );
        return root;
    }

    private setRootStructureProperties(root: StructureInfo, projectId: string) {
        if (root.properties?.viewMode) this._dispatch(setTerrainViewMode(JSON.parse(root.properties.viewMode)));
        if (root.properties?.baseMap) {
            // To avoid setting saved base map value if base map doesn't exist
            const baseMapId = JSON.parse(root.properties.baseMap);
            const baseMaps = this._getState().init.baseLayers;
            const baseMap = baseMaps.find(b => b.id === baseMapId);
            this._dispatch(setBaseImageryProvider(baseMap?.id || baseMaps[0].id));
        }
        if (root.properties?.coordinateSystem) {
            const access = this._access;
            const embed = this._embed;
            const crsInfo = JSON.parse(root.properties.coordinateSystem) as CoordinateSystem;
            this._dispatch(initCurrentCoordinateSystem({ crsInfo, projectId, access, embed }));
        }
        if (root.properties?.units) this._dispatch(setUnits(JSON.parse(root.properties.units)));
    }

    private async addGeometryLayers(project: RawProjectWithFilledStructure): Promise<TemporaryLayer[]> {
        const layers = project.structure.datasets.filter(d => d.projectPartType === ProjectPartType.VECTOR_LAYERS);
        const temporaryLayers: TemporaryLayer[] = [];
        for (const layer of layers) {
            const isPresentation = layer.properties?.isPresentation === 'true';
            let geometries = await getLayerGeometries(this._projectId, layer.datasetUid!, this._access, this._embed);
            if (isPresentation) geometries = geometries.map(g => fillViewpointColorIfMissing(g));
            this._dispatch(addGeometries({ geometries, datasetId: layer.datasetUid! }));
            const mappedLayer = mapDatasetToLayer(layer);
            mappedLayer.geometries = geometries.map(g => g.id);
            temporaryLayers.push(mappedLayer);
        }

        return temporaryLayers;
    }

    private getCameras(result: ExtendedProject): void {
        for (const chunk of result.structure.chunks) {
            if (chunk.cameras?.count) {
                this._dispatch(getCameras({ artifactId: chunk.cameras.artifactUid!, projectId: this._projectId }));
            }
        }
    }
}

function castProjectStructureToExtended(structure: ProjectStructure): ExtendedStructure {
    return {
        chunks: structure.chunks as ExtendedChunk[],
        activeChunk: structure.activeChunk,
        temporaryLayers: []
    };
}

function fillViewpointColorIfMissing(geometry: TemporaryGeometry): TemporaryGeometry {
    if (!geometry.content.properties.ac_color)
        return produce(geometry, draft => {
            draft.content.properties.ac_color = defaultBlueCss;
        });
    return geometry;
}
