import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../sharedConstants';
import Modal, { ModalProps } from '../../modal/Modal';
import ModalActions from '../../modal/ModalActions';
import ModalBody from '../../modal/ModalBody';
import ModalHead from '../../modal/ModalHead';

type Props = ModalProps;

export default function BlockSwitchToCommercialPlanModal({ isOpen, setIsOpen }: Props) {
    const { t } = useTranslation(['modals', 'glossary']);
    const history = useHistory();

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <ModalHead setIsOpen={setIsOpen}>{t('glossary:commercialPlan')}</ModalHead>
            <ModalBody>
                <p data-testid={'body'}>{t('modals:blockSwitchToCommercialPlan.body')}</p>
                <ModalActions>
                    <button
                        className='btn-ghost-blue'
                        type='button'
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        {t('modals:blockSwitchToCommercialPlan.cancel')}
                    </button>
                    <button
                        className='btn'
                        type='button'
                        onClick={() => {
                            history.push(Routes.INDEX);
                        }}
                    >
                        {t('modals:blockSwitchToCommercialPlan.manageProjects')}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}
