/* tslint:disable */
/* eslint-disable */
/**
 * cloud-frontend-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const ProcessStep = {
    PREPARING_DATA: 'PREPARING_DATA',
    PROCESSING: 'PROCESSING',
    PUBLISHING_RESULTS: 'PUBLISHING_RESULTS',
    SAVING_RESULTS: 'SAVING_RESULTS'
} as const;

export type ProcessStep = typeof ProcessStep[keyof typeof ProcessStep];



