import React, { useEffect, useMemo } from 'react';
import { useSelector } from '../../../store';
import { selectHoveredPoint } from '../../../store/slices/elevationProfiles';
import * as Cesium from 'cesium';
import { Entity, PointGraphics, useCesium } from 'resium';

const pointColor = Cesium.Color.fromCssColorString('#FF981F');
const pointSize = 16;

type Props = {
    polylineId: string;
};

export default function ElevationProfilePoint({ polylineId }: Props) {
    const { scene } = useCesium();
    const hoveredPoint = useSelector(state => selectHoveredPoint(state));

    const pointPosition = useMemo(() => {
        if (!hoveredPoint) return null;
        const cartographic = Cesium.Cartographic.fromCartesian(hoveredPoint);
        return Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, cartographic.height);
    }, [hoveredPoint]);

    useEffect(() => {
        scene?.requestRender();
    }, [hoveredPoint, scene]);

    return pointPosition ? (
        <Entity position={pointPosition} id={`${polylineId}#elevationProfilePoint`}>
            <PointGraphics
                pixelSize={pointSize}
                color={pointColor}
                disableDepthTestDistance={Infinity}
                heightReference={Cesium.HeightReference.NONE}
            />
        </Entity>
    ) : null;
}
