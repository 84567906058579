import React, { ForwardRefExoticComponent, RefAttributes, useMemo, useRef } from 'react';
import { ProjectStructureObjectTypes, TemporaryGeometry, isPolygonGeometry } from '../../../store/helpers/interfaces';
import { GeometryTypes } from '../../../sharedConstants';
import PointGeometry from './geometries/PointGeometry';
import PolylineGeometry from './geometries/PolylineGeometry';
import PolygonGeometry from './geometries/PolygonGeometry';
import { CesiumComponentRef } from 'resium';
import * as Cesium from 'cesium';
import { useSelector } from '../../../store';
import { defaultBlueCss, getCorrespondingHoverColor } from './styling';
import { adjustAlpha } from '../../../lib/adjustAlpha';
import useAreSceneMouseEventsBlocked from '../../../hooks/useAreSceneMouseEventsBlocked';

type Props = {
    geometry: TemporaryGeometry;
    layerVisible: boolean;
    readonly: boolean;
};

const mapping: {
    [key in GeometryTypes]: ForwardRefExoticComponent<GeometryProps & RefAttributes<CesiumComponentRef<Cesium.Entity>>>;
} = {
    [GeometryTypes.POINT]: PointGeometry,
    [GeometryTypes.POLYLINE]: PolylineGeometry,
    [GeometryTypes.POLYGON]: PolygonGeometry
};

export default function Geometry({ geometry, layerVisible, readonly }: Props) {
    const entity = useRef<CesiumComponentRef<Cesium.Entity>>(null);
    const currentlyDrawingShapeId = useSelector(state => state.projectView.currentlyDrawingShapeId);
    const currentlyEditingShapeId = useSelector(state => state.projectView.currentlyEditingShapeId);
    const currentlyHoveringShapeId = useSelector(state => state.projectView.currentlyHoveringShapeId);
    const selectedObject = useSelector(state => state.project.selectedObject);

    const isEditing = currentlyEditingShapeId?.id === geometry.id;
    const isDrawing = currentlyDrawingShapeId === geometry.id;
    const isHovering = currentlyHoveringShapeId === geometry.id;
    const isSelected =
        selectedObject?.type === ProjectStructureObjectTypes.GEOMETRY && selectedObject?.artifactId === geometry.id;

    const areMouseEventsBlocked = useAreSceneMouseEventsBlocked();

    const color = useMemo(() => {
        let cssColor = geometry.content.properties.ac_color;
        if (isHovering || isSelected) cssColor = getCorrespondingHoverColor(cssColor);
        const color = Cesium.Color.fromCssColorString(cssColor || defaultBlueCss);
        return isPolygonGeometry(geometry) ? color : adjustAlpha(color);
    }, [geometry, isHovering, isSelected]);

    const strokeColor = useMemo(() => {
        let cssColor = (geometry.content.properties.ac_stroke_color || geometry.content.properties.ac_color) as string;
        if (isHovering || isSelected) cssColor = getCorrespondingHoverColor(cssColor);
        return adjustAlpha(Cesium.Color.fromCssColorString(cssColor || defaultBlueCss));
    }, [geometry.content.properties.ac_stroke_color, geometry.content.properties.ac_color, isHovering, isSelected]);

    const Geometry = mapping[geometry.content.geometry.type];

    return (
        <Geometry
            geometry={geometry}
            layerVisible={layerVisible}
            ref={entity}
            isSelected={isSelected}
            isHovering={isHovering}
            isDrawing={isDrawing}
            isEditing={isEditing}
            color={color}
            strokeColor={strokeColor}
            mouseEventsBlocked={areMouseEventsBlocked}
            readonly={readonly}
        />
    );
}

export interface GeometryProps extends Props {
    isEditing: boolean;
    isDrawing: boolean;
    isHovering: boolean;
    isSelected: boolean;
    color: Cesium.Color;
    strokeColor?: Cesium.Color;
    mouseEventsBlocked: boolean;
    readonly: boolean;
}
