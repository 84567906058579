import * as Cesium from 'cesium';
import { useMemo } from 'react';
import { ImageryLayer } from 'resium';
import { useSelector } from '../../../../store';
import { BaseLayerInfo } from '../../../../store/slices/init';

type Props = {
    baseLayer: BaseLayerInfo;
};

export default function BaseIonImageryLayer({ baseLayer }: Props) {
    const baseImageryProvider = useSelector(state => state.projectView.baseImageryProvider);

    const provider = useMemo(() => {
        if (baseImageryProvider === baseLayer.id)
            return Cesium.IonImageryProvider.fromAssetId(Number(baseLayer.resource.assetId!), {
                accessToken: baseLayer.accessToken
            });
        return null;
    }, [baseLayer, baseImageryProvider]);

    if (!provider) return null;

    return <ImageryLayer imageryProvider={provider} index={0} />;
}
