/* tslint:disable */
/* eslint-disable */
/**
 * vector-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { NewLayer } from '../model';
/**
 * LayerApi - axios parameter creator
 * @export
 */
export const LayerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Save layer that is in the transaction state
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitLayer: async (projectUid: string, layerUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('commitLayer', 'projectUid', projectUid)
            // verify required parameter 'layerUid' is not null or undefined
            assertParamExists('commitLayer', 'layerUid', layerUid)
            const localVarPath = `/projects/{projectUid}/layers/{layerUid}/commit`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"layerUid"}}`, encodeURIComponent(String(layerUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a layer
         * @param {string} projectUid UUID of project
         * @param {NewLayer} newLayer New layer that needs to be added
         * @param {boolean} [startTransaction] Flag indicates that layer must be deleted after timeout unless it will be commited
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLayer: async (projectUid: string, newLayer: NewLayer, startTransaction?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('createLayer', 'projectUid', projectUid)
            // verify required parameter 'newLayer' is not null or undefined
            assertParamExists('createLayer', 'newLayer', newLayer)
            const localVarPath = `/projects/{projectUid}/layer`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)

            if (startTransaction !== undefined) {
                localVarQueryParameter['startTransaction'] = startTransaction;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newLayer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLayer: async (projectUid: string, layerUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('deleteLayer', 'projectUid', projectUid)
            // verify required parameter 'layerUid' is not null or undefined
            assertParamExists('deleteLayer', 'layerUid', layerUid)
            const localVarPath = `/projects/{projectUid}/layers/{layerUid}`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"layerUid"}}`, encodeURIComponent(String(layerUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LayerApi - functional programming interface
 * @export
 */
export const LayerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LayerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Save layer that is in the transaction state
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commitLayer(projectUid: string, layerUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commitLayer(projectUid, layerUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a layer
         * @param {string} projectUid UUID of project
         * @param {NewLayer} newLayer New layer that needs to be added
         * @param {boolean} [startTransaction] Flag indicates that layer must be deleted after timeout unless it will be commited
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLayer(projectUid: string, newLayer: NewLayer, startTransaction?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLayer(projectUid, newLayer, startTransaction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLayer(projectUid: string, layerUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLayer(projectUid, layerUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LayerApi - factory interface
 * @export
 */
export const LayerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LayerApiFp(configuration)
    return {
        /**
         * 
         * @summary Save layer that is in the transaction state
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitLayer(projectUid: string, layerUid: string, options?: any): AxiosPromise<void> {
            return localVarFp.commitLayer(projectUid, layerUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a layer
         * @param {string} projectUid UUID of project
         * @param {NewLayer} newLayer New layer that needs to be added
         * @param {boolean} [startTransaction] Flag indicates that layer must be deleted after timeout unless it will be commited
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLayer(projectUid: string, newLayer: NewLayer, startTransaction?: boolean, options?: any): AxiosPromise<string> {
            return localVarFp.createLayer(projectUid, newLayer, startTransaction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a layer
         * @param {string} projectUid UUID of project
         * @param {string} layerUid Uid of layer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLayer(projectUid: string, layerUid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLayer(projectUid, layerUid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LayerApi - object-oriented interface
 * @export
 * @class LayerApi
 * @extends {BaseAPI}
 */
export class LayerApi extends BaseAPI {
    /**
     * 
     * @summary Save layer that is in the transaction state
     * @param {string} projectUid UUID of project
     * @param {string} layerUid Uid of layer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public commitLayer(projectUid: string, layerUid: string, options?: AxiosRequestConfig) {
        return LayerApiFp(this.configuration).commitLayer(projectUid, layerUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a layer
     * @param {string} projectUid UUID of project
     * @param {NewLayer} newLayer New layer that needs to be added
     * @param {boolean} [startTransaction] Flag indicates that layer must be deleted after timeout unless it will be commited
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public createLayer(projectUid: string, newLayer: NewLayer, startTransaction?: boolean, options?: AxiosRequestConfig) {
        return LayerApiFp(this.configuration).createLayer(projectUid, newLayer, startTransaction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a layer
     * @param {string} projectUid UUID of project
     * @param {string} layerUid Uid of layer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayerApi
     */
    public deleteLayer(projectUid: string, layerUid: string, options?: AxiosRequestConfig) {
        return LayerApiFp(this.configuration).deleteLayer(projectUid, layerUid, options).then((request) => request(this.axios, this.basePath));
    }
}
