import Decimal from 'decimal.js';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { isCameraMasterOrNotMultispectral } from '../../components/ProjectView/frustums/Frustums';
import { ProcessingTimeProduct, ProductsToOrder, StorageProduct } from '../../generated/billing-api/model';
import { CamerasArtifact, Dataset, ProjectPartType, SourceType, Status } from '../../generated/cloud-frontend-api';
import { EventType } from '../../generated/subscription-api/model';
import getRelated3DElevation from '../../lib/getRelated3DElevation';
import { EMPTY_ARRAY } from '../../sharedConstants';
import { ExtendedChunk, ProjectStructureObjectTypes, TemporaryGeometry, TemporaryLayer } from '../helpers/interfaces';
import { ApplicationState } from '../index';
import { ExtendedCamera, selectCameras } from '../slices/cameras';
import { ExtendedDatasetInfo } from '../slices/datasetfilesUpload';
import { getSortedDatasets } from '../slices/datasetsUpload';
import { selectGeometries } from '../slices/geometries';
import { selectFlatTree } from '../slices/projectStructure';
import { selectGroups } from '../slices/structure';
import { CompareStructureTreeContextValue } from '../../contexts/CompareStructureTreeContext';
import { compareToolState } from '../slices/compareTool';

export const storageUsedPercentSelector = createSelector(
    (state: ApplicationState) => state.accountResources.account.resources?.storage,
    storage => {
        if (storage) {
            const { used, limit, free } = storage;
            return parseFloat((used && limit ? (used / limit) * 100 : 0).toFixed(1));
        }
        return 0;
    }
);

export const selectedCameraSelector = createSelector(
    (state: ApplicationState) => state.cameras,
    (state: ApplicationState) => state.projectView.selectedCamera,
    (cameras, selectedCamera) => {
        return _(cameras)
            .flatMap(c => c)
            .find(c => c.uid === selectedCamera?.uid);
    }
);

export const selectedCamerasSelector = createSelector(
    (state: ApplicationState) => state.projectView.selectedCamera?.artifactUid,
    (state: ApplicationState) => state.cameras,
    (cameraArtifactUid, cameras) => {
        if (cameraArtifactUid)
            return cameras[cameraArtifactUid]?.filter(isCameraMasterOrNotMultispectral) || EMPTY_ARRAY;
    }
);

export const indexOfSelectedCameraSelector = createSelector(
    selectedCamerasSelector,
    selectedCameraSelector,
    (state: ApplicationState) => state.projectView,
    (state: ApplicationState) => selectCameras(state),
    (selectedCameras, selectedCamera, { isCamerasInspectionEnabled, filteredCamerasPointProjection }, allCameras) => {
        if (!selectedCamera) return -1;

        if (isCamerasInspectionEnabled)
            return filteredCamerasPointProjection
                .map(p => allCameras.find(c => c.uid === p.uid))
                .indexOf(selectedCamera!);

        if (!selectedCameras) return -1;

        return selectedCameras.indexOf(selectedCamera);
    }
);

export const selectStructureItemInfo = createSelector(
    (state: ApplicationState) => state.project.structure,
    (state: ApplicationState) => state.datasets.datasets,
    (state: ApplicationState) => selectGroups(state),
    (state: ApplicationState) => selectGeometries(state),
    (state: ApplicationState) => state.cameras,
    (state: ApplicationState, arg: { id: string; type: ProjectStructureObjectTypes }) => arg,
    ({ chunks, temporaryLayers }, datasets, groups, geometries, cameras, { id, type }) => {
        switch (type) {
            case ProjectStructureObjectTypes.LAYER:
                return temporaryLayers.find(l => l.id === id);
            case ProjectStructureObjectTypes.GEOMETRY:
                return geometries[id];
            case ProjectStructureObjectTypes.ARTIFACT:
            case ProjectStructureObjectTypes.DATASET:
                return datasets.find(d => d.datasetUid === id);
            case ProjectStructureObjectTypes.GROUP:
                return groups.find(g => g.uid === id);
            case ProjectStructureObjectTypes.CAMERAS:
                return chunks.flatMap(c => c.cameras).find(c => c?.artifactUid === id);
            case ProjectStructureObjectTypes.IMAGE:
                return _.flatMap(cameras).find(c => c.uid === id);
            case ProjectStructureObjectTypes.CHUNK:
                return chunks.find(c => c.assetUid === id);
            default:
                return undefined;
        }
    }
);

export function isGeometryVisible(geometry: TemporaryGeometry) {
    return geometry.content.properties.ac_visibility;
}
export const selectGeometryVisibility = createSelector(selectStructureItemInfo, structureItemInfo =>
    isGeometryVisible(structureItemInfo as TemporaryGeometry)
);

export const selectedObjectInfoSelector = createSelector(
    (state: ApplicationState) => state.project,
    (state: ApplicationState) => state.datasets.datasets,
    (state: ApplicationState) => selectGroups(state),
    (state: ApplicationState) => selectGeometries(state),
    (state: ApplicationState) => state.cameras,
    (
        { selectedObject, structure },
        datasets,
        groups,
        geometries,
        cameras
    ): TemporaryLayer | ExtendedChunk | TemporaryGeometry | Dataset | CamerasArtifact | ExtendedCamera | undefined => {
        switch (selectedObject.type) {
            case ProjectStructureObjectTypes.LAYER:
                return structure.temporaryLayers.find(l => l.id === selectedObject.artifactId);
            case ProjectStructureObjectTypes.CHUNK:
                return structure.chunks.find(c => c.assetUid === selectedObject.artifactId)!;
            case ProjectStructureObjectTypes.GEOMETRY:
                return geometries[selectedObject.artifactId!];
            case ProjectStructureObjectTypes.ARTIFACT:
            case ProjectStructureObjectTypes.DATASET:
                return datasets.find(d => d.datasetUid === selectedObject?.artifactId);
            case ProjectStructureObjectTypes.GROUP:
                return groups.find(g => g.uid === selectedObject?.artifactId);
            case ProjectStructureObjectTypes.CAMERAS:
                return structure.chunks
                    .flatMap(c => c.cameras)
                    ?.find(c => c?.artifactUid === selectedObject.artifactId);
            case ProjectStructureObjectTypes.IMAGE:
                return _.flatMap(cameras).find(c => c.uid === selectedObject?.artifactId);
            default:
                return undefined;
        }
    }
);

function getChunkDatasets(
    chunks: ExtendedChunk[],
    datasets: ExtendedDatasetInfo[],
    chunkId: string
): ExtendedDatasetInfo[] {
    const chunk = chunks.find(c => c.assetUid === chunkId);
    if (chunk) return _.compact(chunk.artifacts.map(a => datasets.find(d => d.datasetUid === a.datasetUid)));
    return EMPTY_ARRAY;
}
export function getDisplayableChunkDatasets(
    chunks: ExtendedChunk[],
    datasets: ExtendedDatasetInfo[],
    chunkId: string
): ExtendedDatasetInfo[] {
    const chunk = chunks.find(c => c.assetUid === chunkId);
    return getChunkDatasets(chunks, datasets, chunkId).filter(
        d => d.sourceData?.type !== SourceType.DEM || getRelated3DElevation(datasets, chunk, d.name) === undefined
    );
}

export const layerWithSelectedGeometrySelector = createSelector(
    (state: ApplicationState) => state.project.selectedObject,
    (state: ApplicationState) => state.project.structure.temporaryLayers,
    (selectedObject, temporaryLayers) => {
        if (selectedObject.type === ProjectStructureObjectTypes.GEOMETRY)
            return temporaryLayers.find(l => l.geometries.includes(selectedObject.artifactId!));
    }
);

export const storageProductsDisplayNamesSelector = createSelector(
    (state: ApplicationState) => state.billing.products.storageProducts,
    storageProducts =>
        storageProducts.map(sp => ({
            name: sp.name,
            displayName: [`${sp.name?.split('/')?.[0]}`, `$${sp?.price}/month`]
        }))
);

export const processingTimeProductSelector = (processingTime: string) =>
    createSelector(
        (state: ApplicationState) => state.billing.products.processingTimeProducts,
        processingTimeProducts => processingTimeProducts.find(p => p.name === processingTime)
    );

export const storageProductSelector = (storage: string) =>
    createSelector(
        (state: ApplicationState) => state.billing.products.storageProducts,
        storageProducts => storageProducts.find(p => p.name === storage)
    );

export const summaryTotalPriceSelector = ({ storage, processingTime }: ProductsToOrder) =>
    createSelector(
        (state: ApplicationState) => state.billing.products,
        ({ processingTimeProducts, storageProducts }) =>
            _.concat(processingTimeProducts, storageProducts).reduce(
                (sum, cur: StorageProduct | ProcessingTimeProduct) =>
                    sum.plus([storage, processingTime].includes(cur.name) ? new Decimal(cur.price!) : new Decimal(0)),
                new Decimal(0)
            )
    );

export const subscriptionsAsObject = createSelector(
    (state: ApplicationState) => state.emailSubscriptions.list,
    subscriptions => {
        const object = {} as { [event in EventType]: boolean };
        for (const s of subscriptions) object[s.event!] = !!s.enabled;

        return object;
    }
);

export const selectReadyDemFiles = createSelector(
    (state: ApplicationState) => selectFlatTree(state),
    files => files.filter(f => f.sourceType === SourceType.DEM_3_D && f.status === Status.COMPLETED)
);
export const selectWithCompareTreeId = (
    state: ApplicationState,
    treeId?: CompareStructureTreeContextValue['treeId'] | ''
) => treeId;

export const selectProjectInfoByCompareTreeId = createSelector(
    (state: ApplicationState) => state.compareTool.mode,
    (state: ApplicationState) => state.project.projectInfo,
    (state: ApplicationState) => state.compareTool.comparedProject?.projectInfo,
    selectWithCompareTreeId,
    (compareToolMode, projectInfo, comparedProjectInfo, treeId) => {
        if (compareToolMode === 'compareSiteProjects') {
            if (!treeId || treeId === 'tree1') return projectInfo;
            return comparedProjectInfo;
        }
        return projectInfo;
    }
);

export const selectChunksByCompareTreeId = createSelector(
    (state: ApplicationState) => state.compareTool.mode,
    (state: ApplicationState) => state.project.structure.chunks,
    (state: ApplicationState) => state.compareTool.comparedProject?.chunks,
    selectWithCompareTreeId,
    (compareToolMode, projectChunks, comparedProjectChunks, treeId) => {
        if (compareToolMode === 'compareSiteProjects') {
            if (!treeId || treeId === 'tree1') return projectChunks;
            return comparedProjectChunks || EMPTY_ARRAY;
        }
        return projectChunks;
    }
);

export const selectLayersByCompareTreeId = createSelector(
    (state: ApplicationState) => state.compareTool.mode,
    (state: ApplicationState) => state.project.structure.temporaryLayers,
    (state: ApplicationState) => state.compareTool.comparedProject?.layers,
    selectWithCompareTreeId,
    (compareToolMode, projectLayers, comparedProjectLayers, treeId) => {
        if (compareToolMode === 'compareSiteProjects') {
            if (!treeId || treeId === 'tree1') return projectLayers;
            return comparedProjectLayers || EMPTY_ARRAY;
        }
        return projectLayers;
    }
);

export const selectStructuresByCompareTreeId = createSelector(
    (state: ApplicationState) => state.structure.structures,
    (state: ApplicationState) => compareToolState.selectors.tree1.selectAll(state),
    (state: ApplicationState) => compareToolState.selectors.tree2.selectAll(state),
    (state: ApplicationState) => state.projectView.isCompareToolEnabled,
    selectWithCompareTreeId,
    (projectStructures, compareTree1Structures, compareTree2Structures, isCompareToolEnabled, treeId) => {
        if (!isCompareToolEnabled || !treeId) return projectStructures;

        if (treeId === 'tree1') return compareTree1Structures;
        return compareTree2Structures;
    }
);

export const selectDatasetsByCompareTreeId = createSelector(
    (state: ApplicationState) => state.compareTool.mode,
    (state: ApplicationState) => state.datasets.datasets,
    (state: ApplicationState) => state.compareTool.comparedProject?.datasets,
    selectWithCompareTreeId,
    (compareToolMode, projectDatasets, comparedProjectDatasets, treeId) => {
        if (compareToolMode === 'compareSiteProjects') {
            if (!treeId || treeId === 'tree1') return projectDatasets;
            return comparedProjectDatasets || EMPTY_ARRAY;
        }
        return projectDatasets;
    }
);

interface SelectStructureItemInfoArg {
    id: string;
    type: ProjectStructureObjectTypes;
}

export const selectGroupsByCompareTreeId = createSelector(selectStructuresByCompareTreeId, structures =>
    structures.filter(s => s.properties?.nestingLevel === '1').reverse()
);

export const selectStructureItemInfoByCompareTreeId = createSelector(
    selectChunksByCompareTreeId,
    selectLayersByCompareTreeId,
    selectDatasetsByCompareTreeId,
    selectGroupsByCompareTreeId,
    (state: ApplicationState) => state.geometries.entities,
    (state: ApplicationState) => state.cameras,
    (
        state: ApplicationState,
        treeId: CompareStructureTreeContextValue['treeId'] | '',
        arg: SelectStructureItemInfoArg
    ) => arg,
    (chunks, temporaryLayers, datasets, groups, geometries, cameras, { id, type }) => {
        switch (type) {
            case ProjectStructureObjectTypes.LAYER:
                return temporaryLayers.find(l => l.id === id);
            case ProjectStructureObjectTypes.GEOMETRY:
                return geometries[id];
            case ProjectStructureObjectTypes.ARTIFACT:
            case ProjectStructureObjectTypes.DATASET:
                return datasets.find(d => d.datasetUid === id);
            case ProjectStructureObjectTypes.GROUP:
                return groups.find(g => g.uid === id);
            case ProjectStructureObjectTypes.CAMERAS:
                return chunks.flatMap(c => c.cameras).find(c => c?.artifactUid === id);
            case ProjectStructureObjectTypes.IMAGE:
                return _.flatMap(cameras).find(c => c.uid === id);
            case ProjectStructureObjectTypes.CHUNK:
                return chunks.find(c => c.assetUid === id);
            default:
                return undefined;
        }
    }
);

export const selectCurrentAndComparedDatasets = createSelector(
    (state: ApplicationState) => state.datasets.datasets,
    (state: ApplicationState) => state.compareTool.comparedProject?.datasets || EMPTY_ARRAY,
    (currentDatasets, comparedDatasets) => [...currentDatasets, ...comparedDatasets]
);

export const selectDatasetsAndLayersByCompareTreeId = createSelector(
    selectProjectInfoByCompareTreeId,
    selectLayersByCompareTreeId,
    selectChunksByCompareTreeId,
    (state: ApplicationState) => state.datasetsUpload.uploads,
    selectDatasetsByCompareTreeId,
    selectStructuresByCompareTreeId,
    (projectInfo, layers, chunks, vectorLayerUploads, datasets, structures) => {
        return getSortedDatasets(layers, vectorLayerUploads, datasets, structures, projectInfo?.id!, chunks);
    }
);

export const selectTilesetsByTreeId = createSelector(selectDatasetsByCompareTreeId, datasets =>
    datasets.filter(d => d.projectPartType === ProjectPartType.TILESETS)
);

export const selectDatasetsAndArtifactsAndLayersByTreeId = createSelector(
    selectDatasetsAndLayersByCompareTreeId,
    selectTilesetsByTreeId,
    (datasetsAndLayers, artifacts) => [...datasetsAndLayers, ...artifacts]
);
