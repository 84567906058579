import { useSelector } from '../../store';
import { Redirect } from 'react-router-dom';
import PreviousPageLink from '../../components/Elements/previous-page-link/PreviousPageLink';
import SubscriptionForm from '../../components/subscriptions/SubscriptionForm';

import styles from './UpgradePlan.module.scss';

export default function UpgradePlan() {
    const currentPlan = useSelector(state => state.accountResources.account.plan.current);
    if (currentPlan?.type === 'COMMERCIAL') {
        return <Redirect to={'/'} />;
    }

    return (
        <div className={styles.contentWrapper}>
            <PreviousPageLink />
            <SubscriptionForm showStorage={true} showHours={true} expandedHoursList={false} />
        </div>
    );
}
