import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import Slider from 'react-rangeslider';
import '../../../scss/vendors/_react_rangeslider.scss';

type Props = {
    text: string;
    min?: number;
    max?: number;
    step?: number;
    startValue: number;
    onChange: (newValue: string) => void;
};

const MATCH_STRING = /^([0-9]|([1-9][0-9])|100)%?$/; // три диапазона (0-9, 10-99, 100) c опциональным знаком % в конце
const EMPTY_STRING = /^$/;

export default function RangeControl({ text, onChange, max = 100, min = 0, startValue, step = 1 }: Props) {
    const textInputRef = useRef<HTMLInputElement>(null!);
    const [inputValue, setInputValue] = useState<number>(Math.round(startValue));
    const [prevInputValue, setPrevInputValue] = useState<string>('');
    const [hasNoChanges, setHasNoChanges] = useState(true);
    const [isHandleActive, setIsHandleActive] = useState(false);

    useEffect(() => {
        textInputRef.current.value = `${Math.round(startValue)}%`;
    }, [startValue]);

    return (
        <div className='slider-control'>
            <label className='slider-label'>{text}</label>
            <span className={classNames('slider-input rangeslider-container', { isActive: isHandleActive })}>
                <Slider
                    min={min}
                    max={max}
                    step={step}
                    handleLabel={''}
                    value={inputValue}
                    tooltip={false}
                    onChange={value => {
                        setInputValue(value);
                        textInputRef.current.value = `${value}%`;
                        onChange(String(value));
                    }}
                    onChangeStart={() => {
                        setIsHandleActive(true);
                    }}
                    onChangeComplete={() => {
                        setIsHandleActive(false);
                    }}
                />
            </span>

            <input
                ref={textInputRef}
                type='text'
                onClick={() => {
                    textInputRef.current.select();
                }}
                onFocus={e => {
                    setPrevInputValue((e.target as any).value);
                    setHasNoChanges(true);
                }}
                onChange={e => {
                    if (e.target.value.match(MATCH_STRING)) {
                        setPrevInputValue((e.target as any).value);
                        setHasNoChanges(false);
                    }
                    if (!e.target.value.match(MATCH_STRING) && !e.target.value.match(EMPTY_STRING)) {
                        e.target.value = prevInputValue;
                        if (hasNoChanges) {
                            textInputRef.current.select();
                        }
                    }
                }}
                onBlur={e => {
                    if (e.target.value === '') {
                        e.target.value = prevInputValue;
                    }
                    if (e.target.value.endsWith('%')) {
                        let percentValue = e.target.value.replaceAll('%', '');
                        setInputValue(Number(percentValue));
                        onChange(String(percentValue));
                    } else {
                        setInputValue(Number(e.target.value));
                        onChange(String(e.target.value));
                        e.target.value = e.target.value + '%';
                    }
                }}
                onKeyUp={e => {
                    if (e.key === 'Enter') {
                        textInputRef.current.blur();
                    }
                }}
            />
        </div>
    );
}
