import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, Route, generatePath, useRouteMatch } from 'react-router-dom';
import { ProjectType } from '../../../../generated/cloud-frontend-api';
import useDrawingCancel from '../../../../hooks/useDrawingCancel';
import useIsLoggedIn from '../../../../hooks/useIsLoggedIn';
import { hasSharedOrEmbedAccess } from '../../../../lib/accessHelpers';
import { Routes } from '../../../../sharedConstants';
import { useSelector } from '../../../../store';

import styles from './BreadcrumbsBackLink.module.scss';

const ownerRoutePaths = [Routes.PROJECT_VIEW, Routes.SITE, Routes.SITE_VIEW];
const sharedRoutePaths = [Routes.SHARED_PROJECT_VIEW, Routes.SHARED_SITE, Routes.SHARED_SITE_VIEW];
const breadcrumbsTreePaths = [Routes.SITE, Routes.SITE_VIEW, Routes.PROJECT_VIEW];

export default function BreadcrumbsBackLink() {
    const { t } = useTranslation(['navigation']);
    const cancelDrawing = useDrawingCancel();
    const loggedIn = useIsLoggedIn();
    const projectInfo = useSelector(state => state.project.projectInfo);
    const siteProjectInfo = useSelector(state => state.site.projectInfo);
    const projectParent = useSelector(state => state.project.parent);
    const siteParent = useSelector(state => state.site.siteInfo.parent);
    const siteName = useSelector(state => state.site.siteInfo.name);
    const isSiteViewRoute = !!useRouteMatch({ path: Routes.SITE_VIEW, exact: true });
    const isSiteRoute = !!useRouteMatch({ path: Routes.SITE, exact: true });
    const actualProjectInfo = isSiteViewRoute ? siteProjectInfo : projectInfo;
    const actualParent = isSiteViewRoute || isSiteRoute ? siteParent : projectParent;
    const projectName = isSiteViewRoute || isSiteRoute ? siteName : projectInfo.name;

    return (
        <div className={styles.breadcrumbs}>
            {/* root link */}
            <Route exact path={ownerRoutePaths}>
                <Link
                    to={Routes.INDEX}
                    className={styles.backLink}
                    onClick={e => {
                        cancelDrawing();
                    }}
                >
                    {t('navigation:drive')}
                </Link>
                <span className={styles.delimiter} role={'separator'}>
                    /
                </span>
            </Route>
            <Route exact path={sharedRoutePaths}>
                {loggedIn && (
                    <>
                        <Link className={styles.backLink} to={`${Routes.SHARED}`}>
                            {t('navigation:sharedWithMe')}
                        </Link>
                        <span className={styles.delimiter} role={'separator'}>
                            /
                        </span>
                    </>
                )}
            </Route>

            {/* breadcrumbs tree paths */}
            <Route exact path={breadcrumbsTreePaths}>
                {actualParent?.parentUid && (
                    <span className={styles.intermediatePath}>
                        <Link
                            to={generatePath(Routes.FOLDER, { folderId: actualParent.parentUid })}
                            onClick={e => {
                                cancelDrawing();
                            }}
                        >
                            ...
                        </Link>
                        <span className={styles.delimiter} role={'separator'}>
                            /
                        </span>
                    </span>
                )}
            </Route>

            <Route exact path={breadcrumbsTreePaths}>
                {actualParent && (
                    <>
                        <Link
                            to={
                                actualParent.type === ProjectType.SITE
                                    ? generatePath(Routes.SITE, { id: actualParent.uid })
                                    : generatePath(Routes.FOLDER, { folderId: actualParent.uid })
                            }
                            className={styles.crumbsLink}
                            onClick={e => {
                                cancelDrawing();
                            }}
                        >
                            {actualParent.name}
                        </Link>
                        <span className={styles.delimiter} role={'separator'}>
                            /
                        </span>
                    </>
                )}
            </Route>

            <Route exact path={ownerRoutePaths}>
                {hasSharedOrEmbedAccess(actualProjectInfo.accesses) && (
                    <i className={classNames('icon icon-shared', styles.iconShared)} />
                )}
            </Route>
            <span className={styles.projectTitle}>{projectName}</span>
        </div>
    );
}
