// Стилизованная обертка чекбокса, используется для поля Formik'а или нативного html-чекбокса
import classNames from 'classnames';

type Props = {
    className?: string;
    children: JSX.Element; // must be <input type='checkbox'/>
};

export default function CheckboxWrapper(props: Props) {
    return (
        <label className={classNames('checkbox-wrapper', props.className)}>
            {props.children}
            <span className='checkbox-styler' />
        </label>
    );
}
