import React, { ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
    children: ReactNode;
    className?: string;
};

export default function ModalBody({ children, className }: Props) {
    return <div className={classNames('modal-body', className)}>{children}</div>;
}
