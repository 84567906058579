import { SourceType } from '../generated/cloud-frontend-api/model';
import i18n from '../i18n/config';

export default function (type: SourceType): string {
    const displayTypes: Record<SourceType, string> = {
        [SourceType.POINT_CLOUD]: i18n.t('glossary:sourceTypes.pointCloud'),
        [SourceType.TIE_POINTS]: i18n.t('glossary:sourceTypes.tiePoints'),
        [SourceType.TILED_MODEL]: i18n.t('glossary:sourceTypes.tiledModel'),
        [SourceType.BLOCK_MODEL]: i18n.t('glossary:sourceTypes.blockModel'),
        [SourceType.DEM]: i18n.t('glossary:sourceTypes.dem'),
        [SourceType.DEM_3_D]: i18n.t('glossary:sourceTypes.dem'),
        [SourceType.RASTER_MAP]: i18n.t('glossary:sourceTypes.rasterMap'),
        [SourceType.MODEL_3_D]: i18n.t('glossary:sourceTypes.model3d'),
        [SourceType.GEOJSON]: '' // never used
    };

    return displayTypes[type] ?? i18n.t('glossary:sourceTypes.undefined');
}
