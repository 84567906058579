/* tslint:disable */
/* eslint-disable */
/**
 * project-access-api
 * Project access API allows create delete and get access info about project.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Access } from '../model';
// @ts-ignore
import { AccessInfo } from '../model';
// @ts-ignore
import { ProjectsPage } from '../model';
// @ts-ignore
import { Type } from '../model';
/**
 * ProjectAccessApi - axios parameter creator
 * @export
 */
export const ProjectAccessApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete email that belongs to the user from list of shared emails
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCallerEmailFromAccess: async (projectUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('deleteCallerEmailFromAccess', 'projectUid', projectUid)
            const localVarPath = `/projects/shared-with-me/{projectUid}`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete email from access by email
         * @param {string} projectUid Uid of project
         * @param {string} email User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmail: async (projectUid: string, email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('deleteEmail', 'projectUid', projectUid)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('deleteEmail', 'email', email)
            const localVarPath = `/projects/{projectUid}/access/email`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get access by project
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessByProject: async (projectUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getAccessByProject', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}/access`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get access info by access key
         * @param {string} accessKey Access key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessInfo: async (accessKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessKey' is not null or undefined
            assertParamExists('getAccessInfo', 'accessKey', accessKey)
            const localVarPath = `/accesses/{accessKey}`
                .replace(`{${"accessKey"}}`, encodeURIComponent(String(accessKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get embed code for project
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbedCodeByProject: async (projectUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getEmbedCodeByProject', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}/embed-code`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get accesses shared with caller
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {Array<'name'>} [sort] Order of projects: \&quot;+\&quot; - ascending, \&quot;-\&quot; - descending
         * @param {string} [pattern] String to filter projects by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedWithMeProjects: async (limit?: number, page?: number, sort?: Array<'name'>, pattern?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects/shared-with-me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pattern !== undefined) {
                localVarQueryParameter['pattern'] = pattern;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put email into access by projectUid
         * @param {string} projectUid Uid of project
         * @param {string} email User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEmail: async (projectUid: string, email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('putEmail', 'projectUid', projectUid)
            // verify required parameter 'email' is not null or undefined
            assertParamExists('putEmail', 'email', email)
            const localVarPath = `/projects/{projectUid}/access/email`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put new access for project
         * @param {string} projectUid Uid of project
         * @param {Array<Type>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProjectAccess: async (projectUid: string, type?: Array<Type>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('putProjectAccess', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}/access`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(type, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectAccessApi - functional programming interface
 * @export
 */
export const ProjectAccessApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectAccessApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete email that belongs to the user from list of shared emails
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCallerEmailFromAccess(projectUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCallerEmailFromAccess(projectUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete email from access by email
         * @param {string} projectUid Uid of project
         * @param {string} email User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmail(projectUid: string, email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Access>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmail(projectUid, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get access by project
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessByProject(projectUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Access>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessByProject(projectUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get access info by access key
         * @param {string} accessKey Access key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessInfo(accessKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessInfo(accessKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get embed code for project
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmbedCodeByProject(projectUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmbedCodeByProject(projectUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get accesses shared with caller
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {Array<'name'>} [sort] Order of projects: \&quot;+\&quot; - ascending, \&quot;-\&quot; - descending
         * @param {string} [pattern] String to filter projects by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSharedWithMeProjects(limit?: number, page?: number, sort?: Array<'name'>, pattern?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSharedWithMeProjects(limit, page, sort, pattern, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put email into access by projectUid
         * @param {string} projectUid Uid of project
         * @param {string} email User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEmail(projectUid: string, email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Access>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEmail(projectUid, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put new access for project
         * @param {string} projectUid Uid of project
         * @param {Array<Type>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putProjectAccess(projectUid: string, type?: Array<Type>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Access>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putProjectAccess(projectUid, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectAccessApi - factory interface
 * @export
 */
export const ProjectAccessApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectAccessApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete email that belongs to the user from list of shared emails
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCallerEmailFromAccess(projectUid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCallerEmailFromAccess(projectUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete email from access by email
         * @param {string} projectUid Uid of project
         * @param {string} email User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmail(projectUid: string, email: string, options?: any): AxiosPromise<Access> {
            return localVarFp.deleteEmail(projectUid, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get access by project
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessByProject(projectUid: string, options?: any): AxiosPromise<Access> {
            return localVarFp.getAccessByProject(projectUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get access info by access key
         * @param {string} accessKey Access key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessInfo(accessKey: string, options?: any): AxiosPromise<AccessInfo> {
            return localVarFp.getAccessInfo(accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get embed code for project
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmbedCodeByProject(projectUid: string, options?: any): AxiosPromise<string> {
            return localVarFp.getEmbedCodeByProject(projectUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get accesses shared with caller
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {Array<'name'>} [sort] Order of projects: \&quot;+\&quot; - ascending, \&quot;-\&quot; - descending
         * @param {string} [pattern] String to filter projects by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSharedWithMeProjects(limit?: number, page?: number, sort?: Array<'name'>, pattern?: string, options?: any): AxiosPromise<ProjectsPage> {
            return localVarFp.getSharedWithMeProjects(limit, page, sort, pattern, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put email into access by projectUid
         * @param {string} projectUid Uid of project
         * @param {string} email User email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEmail(projectUid: string, email: string, options?: any): AxiosPromise<Access> {
            return localVarFp.putEmail(projectUid, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put new access for project
         * @param {string} projectUid Uid of project
         * @param {Array<Type>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProjectAccess(projectUid: string, type?: Array<Type>, options?: any): AxiosPromise<Access> {
            return localVarFp.putProjectAccess(projectUid, type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectAccessApi - object-oriented interface
 * @export
 * @class ProjectAccessApi
 * @extends {BaseAPI}
 */
export class ProjectAccessApi extends BaseAPI {
    /**
     * 
     * @summary Delete email that belongs to the user from list of shared emails
     * @param {string} projectUid Uid of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAccessApi
     */
    public deleteCallerEmailFromAccess(projectUid: string, options?: AxiosRequestConfig) {
        return ProjectAccessApiFp(this.configuration).deleteCallerEmailFromAccess(projectUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete email from access by email
     * @param {string} projectUid Uid of project
     * @param {string} email User email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAccessApi
     */
    public deleteEmail(projectUid: string, email: string, options?: AxiosRequestConfig) {
        return ProjectAccessApiFp(this.configuration).deleteEmail(projectUid, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get access by project
     * @param {string} projectUid Uid of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAccessApi
     */
    public getAccessByProject(projectUid: string, options?: AxiosRequestConfig) {
        return ProjectAccessApiFp(this.configuration).getAccessByProject(projectUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get access info by access key
     * @param {string} accessKey Access key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAccessApi
     */
    public getAccessInfo(accessKey: string, options?: AxiosRequestConfig) {
        return ProjectAccessApiFp(this.configuration).getAccessInfo(accessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get embed code for project
     * @param {string} projectUid Uid of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAccessApi
     */
    public getEmbedCodeByProject(projectUid: string, options?: AxiosRequestConfig) {
        return ProjectAccessApiFp(this.configuration).getEmbedCodeByProject(projectUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get accesses shared with caller
     * @param {number} [limit] Maximum number of the items to be returned
     * @param {number} [page] Page number to be returned
     * @param {Array<'name'>} [sort] Order of projects: \&quot;+\&quot; - ascending, \&quot;-\&quot; - descending
     * @param {string} [pattern] String to filter projects by name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAccessApi
     */
    public getSharedWithMeProjects(limit?: number, page?: number, sort?: Array<'name'>, pattern?: string, options?: AxiosRequestConfig) {
        return ProjectAccessApiFp(this.configuration).getSharedWithMeProjects(limit, page, sort, pattern, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put email into access by projectUid
     * @param {string} projectUid Uid of project
     * @param {string} email User email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAccessApi
     */
    public putEmail(projectUid: string, email: string, options?: AxiosRequestConfig) {
        return ProjectAccessApiFp(this.configuration).putEmail(projectUid, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put new access for project
     * @param {string} projectUid Uid of project
     * @param {Array<Type>} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectAccessApi
     */
    public putProjectAccess(projectUid: string, type?: Array<Type>, options?: AxiosRequestConfig) {
        return ProjectAccessApiFp(this.configuration).putProjectAccess(projectUid, type, options).then((request) => request(this.axios, this.basePath));
    }
}
