import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

type Props = {
    debounceMs: number;
};

export default function AutoSave({ debounceMs }: Props) {
    const formik = useFormikContext();
    const [lastSaved, setLastSaved] = useState<string | null>(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSubmit = useCallback(
        debounce(() => formik.submitForm().then(() => setLastSaved(new Date().toISOString())), debounceMs),
        [debounceMs, formik.submitForm]
    );

    useEffect(() => {
        if (formik.dirty) debouncedSubmit();
    }, [debouncedSubmit, formik.values, formik.dirty]);

    return null;
}
