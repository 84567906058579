import Tippy from '@tippyjs/react/headless';
import classNames from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import { useOutsideClickRef } from 'rooks';
import { ProjectStatus } from '../../../generated/cloud-frontend-api/model';
import useGetPublishState from '../../../hooks/useGetPublishState';
import convertSize from '../../../lib/convertSize';
import isLastPipelineProcessing from '../../../lib/isLastPipelineProcessing';
import { Routes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { cancelProjectProcessing, deleteProject } from '../../../store/slices/projectActions';
import { selectProjects, selectSelectedProjects, setSelectedProjects } from '../../../store/slices/projectsPage';
import Checkbox from '../../Elements/checkbox/Checkbox';
import PublishPaymentOverdueModal from '../../Elements/modals/PublishPaymentOverdueModal';
import PublishStorageIsFullModal from '../../Elements/modals/PublishStorageIsFullModal';
import ProjectActionModal from '../../Elements/project-actions/modals/ProjectActionModal';
import CancelSeveralProjectsProcessingAction from './CancelSeveralProjectsProcessingAction';
import PublishSeveralProjectsModal from './PublishSeveralProjectsModal';
import UnpublishSeveralProjectsModal from './UnpublishSeveralProjectsModal';
import { ProjectType } from '../../../generated/project-api-v2';
import { ReactComponent as SvgDelete } from '../../../svg/dropdown_actions/delete.svg';
import { ReactComponent as SvgPublish } from '../../../svg/dropdown_actions/publish.svg';
import { ReactComponent as SvgUnpublish } from '../../../svg/dropdown_actions/unpublish.svg';
import MoveProjectAction from '../../Elements/project-actions/actions/MoveProjectAction';

export default function SelectedProjectsActions() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['common', 'projects', 'modals']);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isTopFixed, setIsTopFixed] = useState(false);
    const projectsActionsWrapper = useRef<HTMLDivElement>(null!);
    const publishState = useGetPublishState();

    const [showPaymentOverdueModal, setShowPaymentOverdueModal] = useState(false);
    const [showStorageIsFullModal, setShowStorageIsFullModal] = useState(false);
    const [showPublishSeveralModal, setShowPublishSeveralModal] = useState(false);
    const [showUnPublishSeveralModal, setShowUnPublishSeveralModal] = useState(false);

    const selectedProjects = useSelector(state => state.projectsPage.selected);
    const projects = useSelector(selectProjects);
    const selectedPageProjects = useSelector(selectSelectedProjects);

    const isCancelProcessingVisible = selectedPageProjects.every(
        p => p.status === ProjectStatus.INPROGRESS && isLastPipelineProcessing(p)
    );
    const isPublishVisible = selectedPageProjects.every(p => !p.published && p.type === ProjectType.METASHAPE);
    const isUnpublishVisible = selectedPageProjects.every(p => p.published);

    const [dropdownWrapperRef] = useOutsideClickRef(() => {
        setIsDropdownOpen(false);
    });

    const totalSizeNumber = selectedPageProjects.reduce((acc, project) => acc + (project.size || 0), 0);
    const totalSize = convertSize(totalSizeNumber);

    const totalPublishedSize = convertSize(
        selectedPageProjects.reduce((acc, project) => {
            return acc + (project.parts?.publication?.size || 0);
        }, 0)
    );

    async function cancelProcessingSelected(): Promise<void> {
        const promises = [];
        for (const id of selectedProjects) {
            const project = projects.find(p => p.id === id)!;
            promises.push(dispatch(cancelProjectProcessing(project.pipeline?.pipelineUid!)));
        }
        await Promise.all(promises);
        dispatch(setSelectedProjects([]));
    }

    async function deleteSelected() {
        const promises = [];
        for (const id of selectedProjects) {
            promises.push(dispatch(deleteProject(id)));
            dispatch(setSelectedProjects([id]));
        }
        await Promise.all(promises);
    }

    function getAreYouSureText(action: 'delete' | 'abort') {
        let warningFragment: ReactNode = <p>{t('projects:areYouSureTexts.default', { action })}</p>;

        if (
            action === 'delete' &&
            selectedPageProjects.length === 1 &&
            totalSizeNumber === 0 &&
            (selectedPageProjects[0].type === ProjectType.METASHAPE ||
                selectedPageProjects[0].type === ProjectType.NON_METASHAPE)
        ) {
            warningFragment = (
                <Trans ns={'projects'} i18nKey={'areYouSureTexts.deleteEmptyProject'} components={{ p: <p /> }} />
            );
            return warningFragment;
        }

        if (action === 'delete') {
            warningFragment = (
                <Trans
                    ns={'projects'}
                    i18nKey={totalSizeNumber > 0 ? 'areYouSureTexts.delete' : 'areYouSureTexts.deleteEmptyObjects'}
                    values={{ projectsCount: selectedProjects.length, size: totalSize }}
                    components={{ p: <p /> }}
                    count={selectedProjects.length}
                />
            );
        }
        if (action === 'delete' && selectedPageProjects.some(p => p.status === ProjectStatus.INPROGRESS)) {
            warningFragment = <p>{t('projects:areYouSureTexts.deleteInProgress', { action })}</p>;
        }
        if (action === 'abort') {
            warningFragment = <Trans ns={'projects'} i18nKey={'areYouSureTexts.abort'} components={{ p: <p /> }} />;
        }

        return warningFragment;
    }

    function setSelected(ids: Array<string>): void {
        dispatch(setSelectedProjects(ids));
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };

        function onScroll() {
            const actionsTopOffset = projectsActionsWrapper.current?.getBoundingClientRect().top;
            if (actionsTopOffset < 0 && !isTopFixed) setIsTopFixed(true);

            if (actionsTopOffset >= 0 && isTopFixed) setIsTopFixed(false);
        }
    }, [isTopFixed]);

    return (
        <>
            <div className='projects-actions-wrapper' ref={projectsActionsWrapper}>
                <div className={classNames('projects-actions', { fixed: isTopFixed })}>
                    <div
                        className='unselect'
                        onClick={() => {
                            setSelected([]);
                        }}
                    >
                        <Checkbox checked />
                        <span className='unselect-text'>
                            <span className='unselect-text-title'>
                                {t('projects:selectedProjectsActions.unselectAll')}{' '}
                            </span>
                            <span className='unselect-number'>{`(${selectedProjects.length})`}</span>
                        </span>
                    </div>
                    <Route exact path={[Routes.INDEX, Routes.SITE, Routes.FOLDER]}>
                        <div className='dropdown-wrapper' ref={dropdownWrapperRef}>
                            <Tippy
                                render={attrs => (
                                    <div className='tippy-dropdown' tabIndex={-1} {...attrs}>
                                        {isPublishVisible && (
                                            <div
                                                className='tippy-dropdown-item tippy_menu-item'
                                                onClick={() => {
                                                    switch (publishState) {
                                                        case 'overdue':
                                                            setShowPaymentOverdueModal(true);
                                                            break;
                                                        case 'storageFull':
                                                            setShowStorageIsFullModal(true);
                                                            break;
                                                        default:
                                                            setShowPublishSeveralModal(true);
                                                    }
                                                    return;
                                                }}
                                            >
                                                <div className='control-icon'>
                                                    <SvgPublish />
                                                </div>
                                                <div className='control-text'>
                                                    {t('projects:projectActions.publish')}
                                                </div>
                                            </div>
                                        )}

                                        {isUnpublishVisible && (
                                            <div
                                                className='tippy-dropdown-item tippy_menu-item'
                                                onClick={() => {
                                                    setShowUnPublishSeveralModal(true);
                                                }}
                                            >
                                                <div className='control-icon'>
                                                    <SvgUnpublish />
                                                </div>
                                                <div className='control-text'>
                                                    {t('projects:projectActions.unpublish')}
                                                </div>
                                            </div>
                                        )}

                                        {isCancelProcessingVisible && (
                                            <CancelSeveralProjectsProcessingAction
                                                onAction={cancelProcessingSelected}
                                                areYouSureText={getAreYouSureText('abort')}
                                            />
                                        )}

                                        <MoveProjectAction projects={selectedPageProjects} />

                                        <hr className='dropdown-menu-line' />

                                        <div
                                            className='tippy-dropdown-item tippy_menu-item alert'
                                            onClick={() => {
                                                setIsDeleteModalOpen(true);
                                            }}
                                        >
                                            <div className='control-icon'>
                                                <SvgDelete />
                                            </div>
                                            <div className='control-text'>{t('projects:projectActions.delete')}</div>
                                        </div>
                                    </div>
                                )}
                                delay={[100, 100]}
                                interactive={true}
                                offset={[0, 4]}
                                placement={'bottom'}
                                visible={isDropdownOpen}
                                appendTo={() => projectsActionsWrapper.current!}
                                touch={true}
                                onClickOutside={() => {
                                    setIsDropdownOpen(false);
                                }}
                            >
                                <div
                                    className={classNames('project-control', { active: isDropdownOpen })}
                                    onClick={() => {
                                        setIsDropdownOpen(prev => !prev);
                                    }}
                                >
                                    <i className='icon icon-menu' />
                                </div>
                            </Tippy>
                        </div>
                    </Route>
                </div>
            </div>
            {showPaymentOverdueModal && (
                <PublishPaymentOverdueModal setIsOpen={setShowPaymentOverdueModal} isOpen={showPaymentOverdueModal} />
            )}
            {showStorageIsFullModal && (
                <PublishStorageIsFullModal setIsOpen={setShowStorageIsFullModal} isOpen={showStorageIsFullModal} />
            )}
            {showPublishSeveralModal && (
                <PublishSeveralProjectsModal
                    setIsOpen={setShowPublishSeveralModal}
                    isOpen={showPublishSeveralModal}
                    selectedProjects={selectedProjects}
                />
            )}
            {showUnPublishSeveralModal && (
                <UnpublishSeveralProjectsModal
                    setIsOpen={setShowUnPublishSeveralModal}
                    isOpen={showUnPublishSeveralModal}
                    selectedProjects={selectedProjects}
                    totalPublishedSize={totalPublishedSize}
                />
            )}
            <Route exact path={[Routes.INDEX, Routes.SITE, Routes.FOLDER]}>
                <ProjectActionModal
                    onAction={deleteSelected}
                    isOpen={isDeleteModalOpen}
                    setIsOpen={() => {
                        setIsDeleteModalOpen(false);
                    }}
                    actionButtonText={t('modals:deleteProjectModal.delete')}
                    areYouSureText={getAreYouSureText('delete')}
                    headerText={t('modals:deleteProjectModal.head')}
                    onCancel={() => {
                        setIsDeleteModalOpen(false);
                    }}
                    cancelButtonText={t('modals:deleteProjectModal.cancel')}
                />
            </Route>
        </>
    );
}
