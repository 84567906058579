import { useLayoutEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock-upgrade';
import { MODAL_ID } from './Modal';

export function ModalScrollLock() {
    useLayoutEffect(() => {
        const modal = document.getElementById(MODAL_ID);
        if (modal) disableBodyScroll(modal, { reserveScrollBarGap: true });

        return () => {
            enableBodyScroll(modal!);
        };
    }, []);

    return null;
}
