import { useFormikContext } from 'formik';
import { StorageProductGradeEnum } from '../../../generated/billing-api';
import { PlanType } from '../../../generated/account-frontend-api';
import { useSelector } from '../../../store';
import { storageProductSelector } from '../../../store/selectors';
import useCancelDowngrade from '../../../hooks/useCancelDowngrade';
import { SubscriptionFormValues } from '../SubscriptionForm';
import ActionButtonText from '../elements/action-button-text/ActionButtonText';

import styles from '../SubscriptionForm.module.scss';

type Props = {
    showStorage?: boolean;
    showHours?: boolean;
};

export default function SubscriptionActions({ showStorage = false, showHours = false }: Props) {
    const currentPlan = useSelector(state => state.accountResources.account.plan.current);
    const scheduledPlanType = useSelector(state => state.accountResources.account.plan.scheduled?.type);
    const isCancellable = useSelector(state => state.accountResources.account.plan.scheduled?.cancelable);
    const paymentType = useSelector(state => state.accountResources.account.paymentType);

    const isOverdue = currentPlan?.overdue || false;

    const { values } = useFormikContext<SubscriptionFormValues>();
    const storageProductGrade = useSelector(state => storageProductSelector(values.storage)(state))?.grade;

    const cancelDowngrade = useCancelDowngrade();

    const onlyHours = showHours && !showStorage;
    const isCommercial = currentPlan?.type === PlanType.COMMERCIAL;

    const canOnlyCancelDowngrade =
        !onlyHours &&
        scheduledPlanType === PlanType.COMMERCIAL &&
        ([StorageProductGradeEnum.CURRENT, StorageProductGradeEnum.SCHEDULED] as StorageProductGradeEnum[]).includes(
            storageProductGrade!
        );

    const isActionDisabled =
        isCommercial &&
        !onlyHours &&
        !currentPlan?.overdue &&
        scheduledPlanType === PlanType.COMMERCIAL &&
        storageProductGrade !== StorageProductGradeEnum.CURRENT;

    if (scheduledPlanType === PlanType.FREE && !onlyHours && !isOverdue) return null;

    // Особый случай, когда пользователь запланировал снижение уровня подписки, но заплатил за ее продление, не дождавшись собственно, перехода
    if (isCommercial && !onlyHours && scheduledPlanType === PlanType.COMMERCIAL && !isCancellable) return null;

    return (
        <div className={styles.formActions}>
            <button
                onClick={e => {
                    if (canOnlyCancelDowngrade) {
                        e.preventDefault();
                        cancelDowngrade();
                    }
                }}
                className='btn'
                type='submit'
                disabled={isActionDisabled}
            >
                <ActionButtonText
                    onlyHours={onlyHours}
                    isCommercial={isCommercial}
                    scheduledPlanType={scheduledPlanType}
                    paymentType={paymentType}
                    storageProductGrade={storageProductGrade}
                    isOverdue={isOverdue}
                />
            </button>
        </div>
    );
}
