import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import { isEmpty } from 'lodash';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { ProcessStatus, ProjectInfo } from '../../../../generated/cloud-frontend-api';
import i18n from '../../../../i18n/config';
import getShortTimeString from '../../../../lib/getShortTimeString';
import isLastPipelineProcessing from '../../../../lib/isLastPipelineProcessing';
import {
    ProcessStepInfo,
    reduceProcessingStepsStatus,
    useGetProcessingInfoQuery
} from '../../../../services/processing';
import { EMPTY_ARRAY, Routes } from '../../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../../store';
import { selectProgressById } from '../../../../store/slices/progress';
import { ProcessingLogsDownloader } from '../../logs-downloader/ProcessingLogsDownloader';
import Modal, { ModalProps } from '../../modal/Modal';
import ModalActions from '../../modal/ModalActions';
import ModalBody from '../../modal/ModalBody';
import ModalHead from '../../modal/ModalHead';
import ProcessingListItem from './ProcessingListItem';
import ProcessingListSkeleton from './ProcessingListSkeleton';
import ProcessingListSubItem from './ProcessingListSubItem';

type Props = ModalProps & {
    projectInfo: ProjectInfo;
};

type Status = 'FAILED' | 'ABORTED' | 'PENDING' | 'INPROGRESS' | 'COMPLETED' | 'ABORTING';

export const statusDisplayValues: () => Record<Status, string> = () => ({
    FAILED: i18n.t('glossary:processingStatuses.failed'),
    ABORTED: i18n.t('glossary:processingStatuses.aborted'),
    PENDING: i18n.t('glossary:processingStatuses.scheduled'),
    INPROGRESS: i18n.t('glossary:processingStatuses.inProgress'),
    COMPLETED: i18n.t('glossary:processingStatuses.completed'),
    ABORTING: i18n.t('glossary:processingStatuses.aborting')
});

export default function ProcessingInfoModal({ isOpen, setIsOpen, projectInfo }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('modals');
    const processingLogs = projectInfo?.processingLogs;
    const processInfo = useSelector(state => selectProgressById(state, projectInfo.processing?.processUid || ''));
    const isShared = useRouteMatch({ path: Routes.SHARED, exact: true });
    const isProcessing = isLastPipelineProcessing(projectInfo);
    const dateModified = projectInfo.dateModified;

    const processDuration = processInfo?.duration || 0;

    const hasProcessingInfo = !!(projectInfo as ProjectInfo).processing?.processUid;

    const { data: processingInfo, isLoading } = useGetProcessingInfoQuery(
        {
            projectId: projectInfo.id!,
            processId: projectInfo.processing?.processUid!
        },
        { skip: !hasProcessingInfo }
    );

    const firstProcessingItem = useMemo(
        () => processingInfo?.items.find(i => i.name === 'PROCESSING'),
        [processingInfo]
    );

    const lastProcessingItem = useMemo(
        () => processingInfo?.items.findLast(i => i.name === 'PROCESSING'),
        [processingInfo]
    );

    const processingSteps = useMemo(() => processingInfo?.items.filter(i => i.name === 'PROCESSING'), [processingInfo]);
    const processingStepStatuses = useMemo(
        () => processingInfo?.items.filter(i => i.name === 'PROCESSING').map(step => step.status),
        [processingInfo]
    );

    const startDate = useMemo(
        () => format(parseISO(processingInfo?.startDate || dateModified!), 'dd/MM/yyyy'),
        [processingInfo?.startDate, dateModified]
    );

    const transformedProcessing = useMemo(() => {
        if (!processingInfo?.items || !firstProcessingItem || !lastProcessingItem)
            return { ...processingInfo, items: EMPTY_ARRAY };

        const preProcessingSteps = processingInfo.items.slice(0, processingInfo.items.indexOf(firstProcessingItem));
        const postProcessingSteps = processingInfo.items.slice(processingInfo.items.indexOf(lastProcessingItem) + 1);

        const currentProcessingStatus = processingStepStatuses?.includes(ProcessStatus.INPROGRESS)
            ? ProcessStatus.INPROGRESS
            : processingStepStatuses?.includes(ProcessStatus.FAILED)
            ? ProcessStatus.FAILED
            : processingStepStatuses?.includes(ProcessStatus.ABORTED) ||
              processingStepStatuses?.includes(ProcessStatus.ABORTING)
            ? ProcessStatus.ABORTED
            : processingStepStatuses?.includes(ProcessStatus.COMPLETED)
            ? ProcessStatus.COMPLETED
            : 'PENDING';

        const steps = [
            {
                info: t('processingInfoModal.preparingProcessing'),
                name: 'PREPARING',
                progress: 0,
                status: reduceProcessingStepsStatus(preProcessingSteps),
                lastError: null,
                processingTimeInSeconds: 0
            },
            {
                info: t('processingInfoModal.processingProject'),
                name: 'PROCESSING_PROJECT',
                progress: 0,
                status: currentProcessingStatus,
                lastError: null,
                processingTimeInSeconds: 0
            },
            {
                info: t('processingInfoModal.savingResults'),
                name: 'SAVING_RESULTS',
                progress: 0,
                status: reduceProcessingStepsStatus(postProcessingSteps),
                lastError: null,
                processingTimeInSeconds: 0
            }
        ];

        return {
            ...processingInfo,
            items: steps
        };
    }, [processingInfo, firstProcessingItem, processingStepStatuses, lastProcessingItem, t]);

    const processingTotalTimeSeconds = useMemo(
        () =>
            processingSteps
                ?.filter(step => step.status !== ProcessStatus.ABORTED && step.status !== ProcessStatus.FAILED)
                .reduce((timeInSeconds, stepRight) => timeInSeconds + stepRight.processingTimeInSeconds, 0) ?? 0,
        [processingSteps]
    );

    const showProcessingLogs = !isShared && !isEmpty(processingLogs);

    function download() {
        const documentDownloader = new ProcessingLogsDownloader();
        const downloadDocuments = new Map<string, string>();
        processingLogs?.forEach(log => {
            if (log.documentUid !== undefined && log.fileName !== undefined) {
                downloadDocuments.set(log.documentUid, log.fileName);
            }
        });

        if (projectInfo.id !== undefined) {
            documentDownloader.download(projectInfo.id, 'metashape-log', downloadDocuments);
        }
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <ModalHead setIsOpen={setIsOpen}>{t('processingInfoModal.head')}</ModalHead>
            <ModalBody className='processing-info-modal'>
                <div className='project-name'>
                    <span>{t('processingInfoModal.projectTitle')} </span>
                    <b>{projectInfo.name}</b>
                </div>
                <div className='processing-description'>{t('processingInfoModal.description')}</div>
                <div className='processing-wrapper'>
                    {!hasProcessingInfo ? (
                        <div className='processing-list-wrapper processing-list_notification'>
                            <div className='notification'>{t('processingInfoModal.noProcessingInfo')}</div>
                        </div>
                    ) : (
                        <>
                            {isLoading || !transformedProcessing ? (
                                <ProcessingListSkeleton />
                            ) : (
                                <OverlayScrollbarsComponent className='processing-list-wrapper'>
                                    <div className='processing-list'>
                                        {transformedProcessing.items.map((item, index) =>
                                            item.name == 'PROCESSING_PROJECT' ? (
                                                <>
                                                    <ProcessingListItem
                                                        key={item?.info}
                                                        processStepInfo={item as ProcessStepInfo}
                                                        className={classNames({
                                                            'first-item': index === 0,
                                                            'last-item':
                                                                index === transformedProcessing.items.length - 1
                                                        })}
                                                    />
                                                    {processingSteps?.map((item, index) => (
                                                        <ProcessingListSubItem
                                                            key={item?.info}
                                                            processStepInfo={item!}
                                                        />
                                                    ))}
                                                </>
                                            ) : (
                                                <ProcessingListItem
                                                    key={item?.info}
                                                    processStepInfo={item as ProcessStepInfo}
                                                    className={classNames({
                                                        'first-item': index === 0,
                                                        'last-item': index === transformedProcessing.items.length - 1
                                                    })}
                                                />
                                            )
                                        )}

                                        {transformedProcessing.last_error && (
                                            <div className='error-message'>
                                                <b>Error:</b> {transformedProcessing.last_error.message}
                                            </div>
                                        )}
                                    </div>
                                </OverlayScrollbarsComponent>
                            )}

                            <div className='processing-info'>
                                <div>
                                    <div className='info-title'>{t('processingInfoModal.startDate')}</div>
                                    <div>{isLoading ? '-' : startDate}</div>
                                </div>
                                <div>
                                    <div className='info-title'>{t('processingInfoModal.processingHours')}</div>
                                    <div>{isLoading ? '-' : getShortTimeString(processingTotalTimeSeconds)}</div>
                                </div>
                                {isProcessing && (
                                    <div className={isLoading ? 'info-opaque' : ''}>
                                        <div className='info-title'>{t('processingInfoModal.duration')}</div>
                                        <div>{isLoading ? '-' : getShortTimeString(processDuration)}</div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <ModalActions>
                    {showProcessingLogs && (
                        <button className='btn-ghost-blue' onClick={download}>
                            {t('processingInfoModal.actionDownload')}
                        </button>
                    )}
                    <button
                        className='btn'
                        type='button'
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        {t('processingInfoModal.action')}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}
