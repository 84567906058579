/* Хук устанавливает ширину сайдбара с учетом текущей ширины вьюпорта */
import { useEffect } from 'react';
import { MAP_PANEL_DEFAULT_WIDTH, MAP_PANEL_CONTROL_WIDTH } from '../sharedConstants';

export default function (sidebarWidthCssVariable: string, wrapperWidth: number): void {
    useEffect(() => {
        updateSidebarWidth();

        return () => {
            document.documentElement.style.setProperty(sidebarWidthCssVariable, `${MAP_PANEL_DEFAULT_WIDTH}px`);
        };

        function updateSidebarWidth() {
            let newWidth =
                wrapperWidth < MAP_PANEL_DEFAULT_WIDTH + MAP_PANEL_CONTROL_WIDTH
                    ? wrapperWidth - MAP_PANEL_CONTROL_WIDTH
                    : MAP_PANEL_DEFAULT_WIDTH;
            document.documentElement.style.setProperty(sidebarWidthCssVariable, `${newWidth}px`);
        }
    }, [sidebarWidthCssVariable, wrapperWidth]);
}
