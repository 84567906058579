import Tippy from '@tippyjs/react/headless';
import { useTranslation } from 'react-i18next';
import { NavLink, generatePath } from 'react-router-dom';
import logout from '../../api/auth/logout';
import { Routes } from '../../sharedConstants';
import { useSelector } from '../../store';
import { ReactComponent as SvgPreferences } from '../../svg/navigation/preferences.svg';
import { ReactComponent as SvgSupport } from '../../svg/navigation/support.svg';
import { ReactComponent as SvgAgreement } from '../../svg/navigation/user_agreement.svg';
import { ReactComponent as SvgUserMenu } from '../../svg/navigation/user_menu.svg';

const UserProfileButton = () => {
    const { t } = useTranslation(['common']);
    const firstName = useSelector(state => state.user.firstName);
    const lastName = useSelector(state => state.user.lastName);
    const email = useSelector(state => state.user.email);
    const accountLink = useSelector(state => state.init.accountLink);
    const supportLink = useSelector(state => state.init.supportLink);

    const fullName = firstName ? firstName + ' ' + (lastName || '') : '';

    return (
        <div className='header-user' data-testid={'headerUserProfileButton'}>
            <Tippy
                render={(attrs, content, instance) => (
                    <div className='user-panel' tabIndex={-1} {...attrs}>
                        <div className='nav-user'>
                            <div className='user-name'>{fullName}</div>
                            <div className='user-email'>{email}</div>
                            <button
                                className='btn-ghost-blue account-link'
                                onClick={() => {
                                    window.location.assign(accountLink);
                                }}
                            >
                                {t('common:header.myAccount')}
                            </button>
                        </div>
                        <div className='nav-menu'>
                            <NavLink
                                exact
                                to={generatePath(Routes.PREFERENCES)}
                                className='menu-item'
                                onClick={() => {
                                    instance?.hide();
                                }}
                            >
                                <div className='menu-item-icon'>
                                    <SvgPreferences />
                                </div>
                                <span>{t('common:userMenu.preferences')}</span>
                            </NavLink>
                            <a
                                className='menu-item'
                                href={supportLink}
                                target={'_blank'}
                                rel='noopener noreferrer'
                                onClick={() => {
                                    instance?.hide();
                                }}
                            >
                                <div className='menu-item-icon'>
                                    <SvgSupport />
                                </div>
                                <span>{t('common:userMenu.support')}</span>
                            </a>
                            <NavLink
                                exact
                                to={generatePath(Routes.USER_AGREEMENT)}
                                className='menu-item'
                                onClick={() => {
                                    instance?.hide();
                                }}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <div className='menu-item-icon'>
                                    <SvgAgreement />
                                </div>
                                <span>{t('common:userMenu.userAgreement')}</span>
                            </NavLink>
                        </div>
                        <div
                            className='nav-menu'
                            onClick={() => {
                                logout(accountLink);
                                instance?.hide();
                            }}
                        >
                            <div className='menu-item menu-item-alert' onClick={() => logout(accountLink)}>
                                <span>{t('common:header.logout')}</span>
                            </div>
                        </div>
                    </div>
                )}
                delay={[100, 100]}
                offset={[0, 6]}
                touch={true}
                placement={'bottom-end'}
                trigger={'click'}
                interactive={true}
                hideOnClick
            >
                <div className='user-menu-toggle' data-testid={'headerUserProfileToggle'}>
                    <SvgUserMenu />
                </div>
            </Tippy>
        </div>
    );
};

export default UserProfileButton;
