import { useEffect } from 'react';
import { useCesium } from 'resium';
// @ts-expect-error no typings
import viewerCesiumNavigationMixin from '@znemz/cesium-navigation';
import { useSelector } from '../../../store';
import { useMediaQuery } from 'react-responsive';

export default function DistanceLegend() {
    const { viewer } = useCesium();
    const isCesiumLoaded = useSelector(state => state.projectView.isCesiumLoaded);
    const isVisible = useMediaQuery({ minWidth: 1018 });

    useEffect(() => {
        if (isVisible)
            viewer?.extend(viewerCesiumNavigationMixin, {
                enableCompass: false,
                enableZoomControls: false,
                enableDistanceLegend: true,
                units: 'meters'
            });
    }, [viewer, isVisible]);

    // Move the distance legend to where we need it
    useEffect(() => {
        const distanceLegendDiv = document.querySelector('#distanceLegendDiv');
        const coordinatesDiv = document.querySelector('.coordinates');
        if (distanceLegendDiv && coordinatesDiv && isCesiumLoaded) {
            if (isVisible) {
                coordinatesDiv.appendChild(distanceLegendDiv);
            } else {
                coordinatesDiv.removeChild(distanceLegendDiv);
            }
        }
    }, [isCesiumLoaded, isVisible]);

    return null;
}
