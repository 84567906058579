import { unwrapResult } from '@reduxjs/toolkit';
import * as Cesium from 'cesium';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CesiumMovementEvent, ScreenSpaceEvent, useCesium } from 'resium';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { Viewpoint } from '../../../entities/Viewpoint';
import { GeometryTypes } from '../../../sharedConstants';
import { AppDispatch } from '../../../store';
import { GeoJson, PointOfView, ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { setSelectedObject } from '../../../store/sharedActions';
import { commitGeometry } from '../../../store/slices/geometryLayers';
import { setPovSaveRequested } from '../../../store/slices/presentation';
import { setIdOfGeometryWithSavingPreview } from '../../../store/slices/projectView';
import useDrawing from './useDrawing';

type Props = {
    lastPickedCartographicDegrees: number[];
};

export default function DrawViewpoint({ lastPickedCartographicDegrees }: Props) {
    const { camera } = useCesium();
    const { t } = useTranslation('projectView');
    const { owned } = useContext(ProjectViewAccessContext);
    const dispatch: AppDispatch = useDispatch();

    const {
        enableDrawingGeometryVisibilityIfInvisible,
        addGeometry,
        getGeometryColors,
        enableLayerVisibilityIfInvisible,
        getLayerUid
    } = useDrawing();

    async function draw(coordinates: number[]) {
        let id = '';

        const layer = await getLayerUid({ isPresentation: true });
        enableLayerVisibilityIfInvisible(layer.id);
        enableDrawingGeometryVisibilityIfInvisible(layer.id);

        const { ac_color } = getGeometryColors(layer, GeometryTypes.POINT);
        const geoJson: GeoJson = {
            type: 'Feature',
            geometry: { type: GeometryTypes.POINT, coordinates },
            properties: {
                ac_name: t('defaultNames.viewpoint', { index: layer.geometries.length + 1 }),
                ac_color,
                ac_visibility: true,
                ac_duration: Viewpoint.DEFAULT_DURATION_SECONDS,
                ac_point_of_view: getCameraPointOfView(camera!)
            }
        };

        id = addGeometry(geoJson, layer.id);
        dispatch(setIdOfGeometryWithSavingPreview(id));

        await dispatch(
            setSelectedObject({ artifactId: id, type: ProjectStructureObjectTypes.GEOMETRY, needToScroll: true })
        );
        if (owned) {
            const { geometryId } = unwrapResult(await dispatch(commitGeometry({ temporaryGeometryUid: id })));
            dispatch(setPovSaveRequested(geometryId));
        }
    }

    return (
        <ScreenSpaceEvent
            type={Cesium.ScreenSpaceEventType.LEFT_CLICK}
            action={(e: CesiumMovementEvent) => {
                const coordinates = lastPickedCartographicDegrees;
                if (coordinates) draw(coordinates);
            }}
        />
    );
}

export function getCameraPointOfView(camera: Cesium.Camera): PointOfView<number> {
    return {
        destination: [camera.position.x, camera.position.y, camera.position.z],
        orientation: {
            direction: [camera.direction.x, camera.direction.y, camera.direction.z],
            up: [camera.up.x, camera.up.y, camera.up.z]
        }
    };
}
