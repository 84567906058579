import appAxios from '../appAxios';

export default async function logout(accountLink: string): Promise<void> {
    const logoutUrl = accountLink + '/oauth/logout';
    const response = await appAxios.post(`${window.location.origin}/api/logout`);
    if (response.status === 200) {
        const redirectUri = window.location.href;
        window.location.assign(`${accountLink}?redirect_uri=${redirectUri}`);
        window.location.assign(`${logoutUrl}?redirect_uri=${redirectUri}`);
    }
}
