import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import getFilenameExtension from '../../../lib/getFilenameExtension';
import { AppDispatch, useSelector } from '../../../store';
import { ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { ExtendedCamera } from '../../../store/slices/cameras';
import {
    expandedPropertiesBlockNames,
    selectPropertiesBlockExpansion,
    updateStructureInfo
} from '../../../store/slices/structure';
import { isCameraAligned } from '../../ProjectView/frustums/Frustums';
import CopyPropertiesControl from '../../ProjectView/geometry-properties/elements/CopyPropertiesControl';
import PropertiesBlockHead from '../../ProjectView/geometry-properties/elements/PropertiesBlockHead';
import GeneralProperty from '../GeneralProperty';

const expandedPropertyName: (typeof expandedPropertiesBlockNames)[number] = 'generalBlockExpanded';

export default function CameraGeneralProperties() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView', 'common']);
    const camera = useSelector(state => selectedObjectInfoSelector(state) as ExtendedCamera);
    const isBlockExpanded = useSelector(state =>
        selectPropertiesBlockExpansion(state, camera.uid, expandedPropertyName)
    );

    const projectInfo = useSelector(state => state.project.projectInfo);

    const formatLabel = t('projectView:inspectionSidebar.cameraProperties.format');
    const sizeLabel = t('projectView:inspectionSidebar.cameraProperties.size');
    const alignedLabel = t('projectView:inspectionSidebar.cameraProperties.aligned');
    const format = getFilenameExtension(camera.fileName);
    const size = `${camera.width}x${camera.height} ${t('common:px')}`;
    const aligned = isCameraAligned(camera) ? 'True' : 'False';
    const textForCopying = `${formatLabel}\t${format}\n${sizeLabel}\t${size}\n${alignedLabel}\t${aligned}`;

    return (
        <div className='inspector-properties'>
            <PropertiesBlockHead
                isBlockExpanded={isBlockExpanded}
                title={t('projectView:inspectionSidebar.titleGeneral')}
                onClick={() => {
                    dispatch(
                        updateStructureInfo({
                            projectId: projectInfo.id!,
                            structureUid: camera.uid,
                            type: ProjectStructureObjectTypes.IMAGE,
                            propName: expandedPropertyName,
                            propValue: isBlockExpanded ? String(false) : String(true)
                        })
                    );
                }}
            >
                <CopyPropertiesControl clipboardText={textForCopying} />
            </PropertiesBlockHead>
            {isBlockExpanded && (
                <div className='properties-list'>
                    <GeneralProperty label={formatLabel} value={format} />
                    <GeneralProperty label={sizeLabel} value={size} />
                    <GeneralProperty label={alignedLabel} value={aligned} />
                </div>
            )}
        </div>
    );
}
