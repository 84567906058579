import React, { useContext, useEffect, useState } from 'react';
import { useCesium } from 'resium';
import { AppDispatch, useSelector } from '../../../store/index';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { calculateElevationProfile, ElevationProfileCalculationInfo } from '../../../store/slices/elevationProfiles';

export default function ElevationProfileCalculations() {
    const calculations = useSelector(state => state.elevationProfiles.calculations);

    return (
        <>
            {calculations
                .filter(c => c.status !== 'fulfilled')
                .map(c => (
                    <ElevationProfileCalculation calculation={c} key={c.id} />
                ))}
        </>
    );
}

function ElevationProfileCalculation({ calculation }: { calculation: ElevationProfileCalculationInfo }) {
    const { viewer, scene } = useCesium();
    const dispatch: AppDispatch = useDispatch();
    const { owned } = useContext(ProjectViewAccessContext);
    const [promise, setPromise] = useState<(Promise<any> & { abort(reason?: string): void }) | null>(null);

    useEffect(() => {
        if (calculation.status === 'new') {
            const promise = dispatch(
                calculateElevationProfile({
                    scene: scene!,
                    terrainProvider: viewer?.terrainProvider!,
                    owned,
                    id: calculation.id
                })
            );
            setPromise(promise);
        }
    }, [calculation, dispatch, scene, viewer, owned]);

    useEffect(() => {
        if (calculation.status === 'aborted') promise?.abort();
    }, [promise, calculation]);

    return null;
}
