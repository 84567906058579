import { unwrapResult } from '@reduxjs/toolkit';
import classNames from 'classnames';
import { uniqBy } from 'lodash';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Parent, ProjectInfo, ProjectType } from '../../../generated/cloud-frontend-api';
import { AppDispatch, useSelector } from '../../../store';
import { createLoadingSelector } from '../../../store/selectors/createLoadingSelector';
import {
    getAllProjects,
    getAllProjectsByParent,
    moveProject,
    moveProjects,
    setSelectedProjects
} from '../../../store/slices/projectsPage';
import Modal, { ModalProps } from '../../Elements/modal/Modal';
import ModalActions from '../../Elements/modal/ModalActions';
import ModalBody from '../../Elements/modal/ModalBody';
import ModalHead from '../../Elements/modal/ModalHead';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import ItemIcon from '../../Elements/item-icon/ItemIcon';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from '../../../sharedConstants';
import { getFolder } from '../../../store/slices/folder';
import { NestingLevelErrorModal } from '../../Elements/modals/nesting-level-error-modal/NestingLevelErrorModal';
import { FileTreeNode, fileTreeIconTypes } from '../../Elements/modals/link-modal/LinkModal';

type Props = ModalProps & {
    projects: ProjectInfo | ProjectInfo[];
    currentParent: Parent | null;
};

const selectIsLoading = createLoadingSelector([getAllProjects.typePrefix, getAllProjectsByParent.typePrefix]);

export default function MoveProjectModal({ isOpen, setIsOpen, projects, currentParent }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('modals');
    const isLoading = useSelector(state => selectIsLoading(state));
    const selectedProjects = useSelector(state => state.projectsPage.selected);
    const [nestingLevel, setNestingLevel] = useState(0);
    const [selectedParentId, setSelectedParentId] = useState('');
    const [hasMoveFailed, setHasMoveFailed] = useState(false);
    const [modalProjects, setModalProjects] = useState<(FileTreeNode & { type: ProjectType })[]>([]);
    const isFolderPath = !!useRouteMatch({ path: [Routes.FOLDER], exact: true });

    const showBackLink = nestingLevel > 0;
    const showSkeleton = isLoading;
    const skeletonItems = 8;

    function isMovePossible() {
        return (currentParent?.uid || '') !== selectedParentId;
    }

    useEffect(() => {
        if (nestingLevel === 0) {
            dispatch(getAllProjects({}))
                .then(unwrapResult)
                .then(({ projects: allProjects }) => {
                    setModalProjects(prevProjects =>
                        uniqBy(
                            [
                                ...prevProjects,
                                ...buildTree(allProjects || [], 0, Array.isArray(projects) ? projects : [projects])
                            ],
                            p => p.id
                        )
                    );
                });
        } else {
            dispatch(getAllProjectsByParent({ parentUid: selectedParentId }))
                .then(unwrapResult)
                .then(allProjects => {
                    setModalProjects(prevProjects =>
                        uniqBy(
                            [
                                ...prevProjects,
                                ...buildTree(
                                    allProjects,
                                    nestingLevel,
                                    Array.isArray(projects) ? projects : [projects],
                                    selectedParentId
                                )
                            ],
                            p => p.id
                        )
                    );
                });
        }
    }, [dispatch, nestingLevel, selectedParentId, projects]);

    if (hasMoveFailed) {
        return <NestingLevelErrorModal isOpen={isOpen} setIsOpen={setIsOpen} />;
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} modalClass='modal-files'>
            <ModalHead setIsOpen={setIsOpen}>
                <span>{t('moveProjectModal.title_move')}</span>
            </ModalHead>
            <ModalBody>
                <div className='data-info'>
                    <div className='data-title'>
                        {Array.isArray(projects) ? (
                            <>
                                {t('moveProjectModal.selectedItems')} <b>{projects.length}</b>
                            </>
                        ) : (
                            <>
                                {(projects.type === ProjectType.METASHAPE ||
                                    projects.type === ProjectType.NON_METASHAPE) &&
                                    t('moveProjectModal.title_project')}
                                {projects.type === ProjectType.SITE && t('moveProjectModal.title_site')}
                                {projects.type === ProjectType.FOLDER && t('moveProjectModal.title_folder')}{' '}
                                <b>{projects.name}</b>
                            </>
                        )}
                    </div>
                    <div className='data-hint'>
                        {Array.isArray(projects)
                            ? t('moveProjectModal.description_moveItems')
                            : t('moveProjectModal.description_moveItem')}
                    </div>
                </div>
                <div className='navigation'>
                    {showBackLink && (
                        <div
                            className='back-link'
                            onClick={e => {
                                setSelectedParentId(
                                    prevParentId => modalProjects.find(i => i.id === prevParentId)?.parentId || ''
                                );
                                setNestingLevel(nestingLevel => nestingLevel - 1);
                            }}
                        >
                            <span className='back-link-icon' />
                        </div>
                    )}
                    <span className='navigation-title'>
                        {(() => {
                            if (nestingLevel === 0) return t('moveProjectModal.navigationDefaultTitle');
                            return modalProjects.find(i => i.id === selectedParentId)?.name || '';
                        })()}
                    </span>
                </div>
                {showSkeleton ? (
                    <div className='files-wrapper modal-files-list'>
                        {[...Array(skeletonItems)].map((e, i) => (
                            <div className='file-item skel-item' key={i}>
                                <div className='skel-icon' />
                                <div className='skel-title' />
                                <div className='skel-checkbox' />
                            </div>
                        ))}
                    </div>
                ) : (
                    <OverlayScrollbarsComponent>
                        <div className='files-wrapper modal-files-list'>
                            {modalProjects
                                .filter(
                                    item =>
                                        item.nestingLevel === nestingLevel &&
                                        (item.parentId === selectedParentId || !selectedParentId)
                                )
                                .map(({ id, active, name, expandable, iconType }) => (
                                    <div
                                        key={id}
                                        className={classNames('file-item', {
                                            disabled: !active,
                                            // selected: selectedItemIds.includes(id),
                                            expandable: expandable && active
                                        })}
                                        onClick={e => {
                                            e.stopPropagation();
                                            if (expandable && active) {
                                                setSelectedParentId(id);
                                                setNestingLevel(l => l + 1);
                                            }
                                        }}
                                    >
                                        <ItemIcon type={iconType} />
                                        <TippyTooltip tooltipText={name}>
                                            <span className='item-title'>{name}</span>
                                        </TippyTooltip>
                                        {expandable && (
                                            <div className='item-move'>
                                                <div className='item-move-icon' />
                                            </div>
                                        )}
                                    </div>
                                ))}
                        </div>
                    </OverlayScrollbarsComponent>
                )}
                <ModalActions>
                    <button
                        type='button'
                        className='btn'
                        disabled={!isMovePossible()}
                        onClick={async e => {
                            const parent = modalProjects.find(p => p.id === selectedParentId);

                            if (Array.isArray(projects)) {
                                const result = await dispatch(
                                    moveProjects({
                                        projectIds: projects.map(p => p.id!),
                                        parent: parent
                                            ? { name: parent.name, type: parent.type, uid: parent.id }
                                            : 'unset'
                                    })
                                );
                                if (moveProjects.rejected.match(result)) {
                                    setHasMoveFailed(true);
                                    return;
                                }
                            } else {
                                const result = await dispatch(
                                    moveProject({
                                        projectId: projects.id!,
                                        parent: parent
                                            ? { name: parent.name, type: parent.type, uid: parent.id }
                                            : 'unset'
                                    })
                                );
                                if (moveProject.rejected.match(result)) {
                                    setHasMoveFailed(true);
                                    return;
                                }
                            }

                            if (selectedProjects.length) {
                                dispatch(setSelectedProjects([]));
                            }

                            // Update folder parent info, when moving folder from folder page
                            if (isFolderPath && !Array.isArray(projects) && projects.type === ProjectType.FOLDER) {
                                dispatch(getFolder({ id: projects.id! }));
                            }

                            setIsOpen(false);
                        }}
                    >
                        {t('moveProjectModal.moveHereAction')}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}

function buildTree(
    projects: ProjectInfo[],
    nestingLevel: number,
    movedProjects: ProjectInfo[],
    parentId?: string
): (FileTreeNode & { type: ProjectType })[] {
    return projects
        .filter(p => !movedProjects.find(mp => mp.id === p.id))
        .map(p => {
            const isActive =
                (p.type === ProjectType.SITE &&
                    movedProjects.every(
                        p => p.type === ProjectType.METASHAPE || p.type === ProjectType.NON_METASHAPE
                    )) ||
                p.type === ProjectType.FOLDER;

            return {
                id: p.id!,
                name: p.name!,
                nestingLevel,
                parentId,
                type: p.type!,
                active: isActive,
                expandable: isActive,
                iconType: fileTreeIconTypes[p.type!]
            };
        });
}
