import { useEffect } from 'react';
import { useSelector } from '../store';

export default function (): void {
    const fastspringAccessKey = useSelector(state => state.init.fastspringAccessKey);
    const fastspringStorefront = useSelector(state => state.init.fastspringStorefront);

    useEffect(() => {
        // Check if sbl library has already been loaded
        if (!(window as any).fastspring?.builder) {
            const script = document.createElement('script');

            script.id = 'fsc-api';
            script.src = 'https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.3/fastspring-builder.min.js';
            script.type = 'text/javascript';
            script.setAttribute('data-popup-closed', 'onFSPopupClosed');
            script.setAttribute('data-popup-webhook-received', 'onWebhookReceived');
            script.setAttribute('data-storefront', fastspringStorefront);
            script.setAttribute('data-access-key', fastspringAccessKey);

            script.async = true;
            document.body.appendChild(script);
        }
    }, [fastspringAccessKey, fastspringStorefront]);
}
