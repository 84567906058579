import { GeometryTypes } from '../../../../sharedConstants';
import { useSelector } from '../../../../store';
import { TemporaryGeometry, TemporaryLayer } from '../../../../store/helpers/interfaces';
import { selectGeometries } from '../../../../store/slices/geometries';
import Viewpoint from './Viewpoint';

type Props = {
    tour: TemporaryLayer;
};

export function Tour({ tour }: Props) {
    const geometries = useSelector(selectGeometries);

    return (
        <>
            {tour.geometries.map(id => (
                <Viewpoint key={id} geometry={geometries[id] as TemporaryGeometry<GeometryTypes.POINT>} />
            ))}
        </>
    );
}
