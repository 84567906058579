import { useTranslation } from 'react-i18next';
import { StorageProduct as StorageProductType, StorageProductGradeEnum } from '../../../generated/billing-api/model';
import classNames from 'classnames';
import RadiobuttonWrapper from '../elements/radiobutton-wrapper/RadiobuttonWrapper';
import { Field, useFormikContext } from 'formik';
import { SubscriptionFormValues } from '../SubscriptionForm';
import { useSelector } from '../../../store';
import { storageProductsDisplayNamesSelector } from '../../../store/selectors';
import { selectScheduledPlanStartDateDisplayValue } from '../../../store/slices/accountResources';
import { PlanType } from '../../../generated/account-frontend-api';
import formatISODate from '../../../lib/formatISODate';

import styles from '../SubscriptionForm.module.scss';

type Props = Required<Pick<StorageProductType, 'name' | 'grade'>>;

export default function StorageProduct({ name, grade }: Props) {
    const { values } = useFormikContext<SubscriptionFormValues>();
    const { t } = useTranslation(['subscribe']);
    const storageProductsDisplayNames = useSelector(state => storageProductsDisplayNamesSelector(state));
    const currentPlan = useSelector(state => state.accountResources.account.plan.current);
    const currentPlanRenewDate = currentPlan?.renewDate;
    const scheduledPlanDate = useSelector(state => selectScheduledPlanStartDateDisplayValue(state));
    const scheduledPlan = useSelector(state => state.accountResources.account.plan.scheduled);

    const disabled =
        (currentPlan?.overdue && grade !== StorageProductGradeEnum.CURRENT) ||
        (currentPlan?.type === PlanType.COMMERCIAL &&
            scheduledPlan?.type === PlanType.FREE &&
            grade !== StorageProductGradeEnum.CURRENT) ||
        (scheduledPlan?.type === PlanType.COMMERCIAL &&
            !(
                [StorageProductGradeEnum.CURRENT, StorageProductGradeEnum.SCHEDULED] as StorageProductGradeEnum[]
            ).includes(grade));

    return (
        <li className={styles.formListItem}>
            <label
                className={classNames(
                    styles.radioItem,
                    values.storage === name && styles.checked,
                    grade === StorageProductGradeEnum.CURRENT && styles.current,
                    disabled && styles.disabled
                )}
            >
                <div className={styles.radioButtonWrapper}>
                    <RadiobuttonWrapper>
                        <Field type='radio' name='storage' value={name} disabled={disabled} />
                    </RadiobuttonWrapper>
                </div>
                <div>
                    <span className={styles.radioText}>
                        <b>{storageProductsDisplayNames.find(dn => dn.name === name)?.displayName[0]}</b>{' '}
                        {storageProductsDisplayNames.find(dn => dn.name === name)?.displayName[1]}
                    </span>
                    {grade === StorageProductGradeEnum.CURRENT && (
                        <div className={styles.radioItemDescr}>
                            ({t('subscribe:storage.product.descriptionActive')})
                        </div>
                    )}
                    {grade === StorageProductGradeEnum.SCHEDULED && (
                        <div className={styles.radioItemDescr}>
                            {t('subscribe:storage.product.descriptionActivatedOn', {
                                date: scheduledPlanDate
                            })}
                        </div>
                    )}
                    {grade === StorageProductGradeEnum.UPGRADE && values.storage === name && (
                        <div className={styles.radioItemDescr}>
                            ({t('subscribe:storage.product.descriptionActivatedImmediately')})
                        </div>
                    )}
                    {grade === StorageProductGradeEnum.DOWNGRADE && values.storage === name && (
                        <div className={styles.radioItemDescr}>
                            (
                            {t('subscribe:storage.product.descriptionActivatedOn', {
                                date: formatISODate(currentPlanRenewDate)
                            })}
                            )
                        </div>
                    )}
                </div>
            </label>
        </li>
    );
}
