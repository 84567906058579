import Tippy from '@tippyjs/react/headless';
import classNames from 'classnames';
import { SyntheticEvent, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import i18n from '../../../i18n/config';
import { ProjectsSortModes, ProjectsSortOrders, Routes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { resetProjects, setSortMode, setSortOrder } from '../../../store/slices/projectsPage';
import { ReactComponent as SvgArrowDown } from '../../../svg/projects_list/arrow_down.svg';
import { ReactComponent as SvgArrowUp } from '../../../svg/projects_list/arrow_up.svg';
import { ReactComponent as SvgSortingDown } from '../../../svg/projects_list/sort_order_down.svg';
import { ReactComponent as SvgSortingUp } from '../../../svg/projects_list/sort_order_up.svg';

type Props = {
    disabled?: boolean;
};

const sortTexts: Record<ProjectsSortModes, string> = {
    [ProjectsSortModes.SURVEY_DATE]: i18n.t('projects:sortModes.surveyDate'),
    [ProjectsSortModes.MODIFICATION_DATE]: i18n.t('projects:sortModes.modificationDate'),
    [ProjectsSortModes.NAME]: i18n.t('projects:sortModes.projectName'),
    [ProjectsSortModes.SIZE]: i18n.t('projects:sortModes.projectSize')
};

const ProjectsSortControl = ({ disabled }: Props) => {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projects']);
    const sortMode = useSelector(state => state.projectsPage.sortMode);
    const sortOrder = useSelector(state => state.projectsPage.sortOrder);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const isSiteRoute = !!useRouteMatch({ path: Routes.SITE, exact: true });

    const sortingWrapperRef = useRef<HTMLDivElement>(null);

    function getSortText(value: ProjectsSortModes) {
        return sortTexts[value];
    }

    function isActiveClass(sort: ProjectsSortModes) {
        return sortMode === sort;
    }

    const sortOptions = useMemo(
        () =>
            (Object.keys(sortTexts) as ProjectsSortModes[]).filter(sortMode =>
                isSiteRoute ? true : sortMode !== ProjectsSortModes.SURVEY_DATE
            ),
        [isSiteRoute]
    );

    const setSort = (newSort: ProjectsSortModes) => (e: SyntheticEvent) => {
        let order = ProjectsSortOrders.DESCENDING;
        if (sortMode === newSort) {
            order =
                sortOrder === ProjectsSortOrders.DESCENDING
                    ? ProjectsSortOrders.ASCENDING
                    : ProjectsSortOrders.DESCENDING;
        }

        // AUTOBATCH
        dispatch(setSortOrder(order));
        dispatch(setSortMode(newSort));
        dispatch(resetProjects());
        setIsDropdownOpen(false);
    };

    return (
        <div className='sorting-wrapper' ref={sortingWrapperRef}>
            <Tippy
                render={attrs => (
                    <div className='tippy-dropdown' tabIndex={-1} {...attrs}>
                        <div className='dropdown-menu-item-static sort-menu-item sort-menu-title'>
                            <span>{t('projects:sortModes.sorting')}</span>
                        </div>

                        {sortOptions.map(sortOption => (
                            <div
                                key={sortOption}
                                className={classNames('dropdown-menu-item sort-menu-item', {
                                    active: isActiveClass(sortOption),
                                    link: isActiveClass(sortOption)
                                })}
                                data-testid={'sortMenuOption'}
                                onClick={setSort(sortOption)}
                            >
                                {isActiveClass(sortOption) && (
                                    <span className='item-sorting-order'>
                                        {sortOrder === ProjectsSortOrders.DESCENDING && <SvgArrowDown />}
                                        {sortOrder === ProjectsSortOrders.ASCENDING && <SvgArrowUp />}
                                    </span>
                                )}
                                <span>{getSortText(sortOption)}</span>
                            </div>
                        ))}
                    </div>
                )}
                delay={[100, 100]}
                interactive={true}
                offset={[0, 4]}
                placement={'bottom'}
                visible={isDropdownOpen}
                appendTo={() => sortingWrapperRef.current!}
                touch={true}
                disabled={disabled}
                onClickOutside={() => {
                    setIsDropdownOpen(false);
                }}
            >
                <div
                    className={classNames('project-control projects-list-sorting', {
                        disabled,
                        active: isDropdownOpen
                    })}
                    onClick={e => {
                        setIsDropdownOpen(prev => !prev);
                    }}
                >
                    <span className='sorting-order'>
                        {sortOrder === ProjectsSortOrders.DESCENDING && <SvgSortingDown />}
                        {sortOrder === ProjectsSortOrders.ASCENDING && <SvgSortingUp />}
                    </span>
                    <span className='sorting-type'>{getSortText(sortMode)}</span>
                </div>
            </Tippy>
        </div>
    );
};

export default ProjectsSortControl;
