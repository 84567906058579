import classNames from 'classnames';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useCameraImageUrl } from '../../../hooks/useCameraImageUrl';
import useDocumentUrl from '../../../hooks/useDocumentUrl';
import {
    GeometryTypes,
    Routes,
    WidthHeight,
    inspectorMainImageThumbnailParam as thumbnail
} from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { TemporaryGeometry } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { ExtendedCamera, makeSelectCameraArtifactId } from '../../../store/slices/cameras';
import { setGalleryVisibility, setSelectedCamera } from '../../../store/slices/projectView';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import CanvasIssueImage from './CanvasIssueImage';
import PreviewImageStub from './PreviewImageStub';

type Props = {
    camera: ExtendedCamera | undefined;
};

export default function PreviewIssueImage({ camera }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const container = useRef<HTMLDivElement>(null);
    const issue = useSelector(selectedObjectInfoSelector) as TemporaryGeometry<GeometryTypes.POINT>;
    const issuePreviewUid = issue.content.properties.ac_preview_uid as string | undefined;
    const selectedObject = useSelector(state => state.project.selectedObject);
    const idOfGeometryWithSavingPreview = useSelector(state => state.projectView.idOfGeometryWithSavingPreview);
    const selectArtifactUid = useMemo(makeSelectCameraArtifactId, []);
    const cameraArtifactId = useSelector(state => selectArtifactUid(state, camera?.uid || ''));

    const [isLoading, setIsLoading] = useState(true);
    const [containerSize, setContainerSize] = useState<WidthHeight>({ width: 0, height: 0 });
    const isEmbedProjectRoute = !!useRouteMatch({ path: Routes.EMBEDDED_PROJECT_VIEW, exact: true });

    const isIssuePreviewBeingSaved = idOfGeometryWithSavingPreview === issue.id;
    const {
        url: issuePreviewUrl,
        isLoading: isIssuePreviewUrlLoading,
        hasFailed: hasIssuePreviewUrlLoadingFailed,
        hasTriedGettingUrl
    } = useDocumentUrl(issuePreviewUid, !isIssuePreviewBeingSaved);

    const { thumbUrl } = useCameraImageUrl(camera, thumbnail);
    const [hasFailed, setHasFailed] = useState(!thumbUrl);
    const loading =
        (issuePreviewUid ? isLoading || isIssuePreviewUrlLoading : thumbUrl ? isLoading : false) ||
        isIssuePreviewBeingSaved;
    const failed = hasFailed && hasIssuePreviewUrlLoadingFailed;

    const imageUrl = !issuePreviewUrl ? thumbUrl! : issuePreviewUrl;

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            setContainerSize({ width: entries[0].contentRect.width, height: entries[0].contentRect.height });
        });

        if (container.current) resizeObserver.observe(container.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useLayoutEffect(() => {
        if (container.current) {
            setContainerSize({ width: container.current.clientWidth, height: container.current.clientHeight });
        }
    }, []);

    return (
        <section className='inspector-image' ref={container}>
            <div
                className={classNames('image-wrapper', {
                    loading: loading && !isEmbedProjectRoute,
                    'loading-embed': failed && isEmbedProjectRoute
                })}
                style={{ width: containerSize.width, height: containerSize.height }}
            >
                {hasTriedGettingUrl && !isIssuePreviewBeingSaved && (
                    <>
                        {failed ? (
                            <PreviewImageStub stubType='viewer' />
                        ) : (
                            <CanvasIssueImage
                                camera={!issuePreviewUrl ? camera : undefined}
                                imageUrl={imageUrl}
                                containerSize={containerSize}
                                isLoading={loading}
                                onLoad={() => {
                                    setIsLoading(false);
                                }}
                                setHasFailed={hasFailed => {
                                    setHasFailed(hasFailed);
                                    setIsLoading(false);
                                }}
                                issueId={selectedObject?.artifactId!}
                                usePreviewBbox={!!issuePreviewUrl}
                            />
                        )}
                    </>
                )}

                <div className='image-controls'>
                    <TippyTooltip tooltipText={t('projectView:inspectionSidebar.imagesInspector.tooltipMaximize')}>
                        <div
                            className='image-control'
                            onClick={e => {
                                dispatch(setGalleryVisibility({ isVisible: true, mode: 'issues' }));
                                if (camera) {
                                    dispatch(setSelectedCamera({ uid: camera.uid, artifactUid: cameraArtifactId! }));
                                }
                            }}
                        >
                            <i className='icon icon-fullscreen' />
                        </div>
                    </TippyTooltip>
                </div>
            </div>
        </section>
    );
}
