import { useState, useEffect } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { EventType } from '../../generated/subscription-api';

import { CSVParam } from '../../sharedConstants';

import { AppDispatch, useSelector } from '../../store';
import { createLoadingSelector } from '../../store/selectors/createLoadingSelector';
import { getSubscriptions } from '../../store/slices/emailSubscriptions';

import UnsubscribeSkeleton from './components/UnsubscribeSkeleton';
import UnsubscribeForm from './components/UnsubscribeForm';
import ProjectsPlaceholder from '../../components/Projects/ProjectsPlaceholder';

const loadingSelector = createLoadingSelector([getSubscriptions.typePrefix]);

export default function Unsubscribe() {
    const { t } = useTranslation(['cabinet']);
    const dispatch: AppDispatch = useDispatch();
    const [isAlreadyUnsubscribed, setIsAlreadyUnsubscribed] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const isLoading = useSelector(state => loadingSelector(state));
    const [query] = useQueryParams({
        account_uid: StringParam,
        hash: StringParam,
        type: CSVParam
    });

    useEffect(() => {
        if (query.account_uid && query.hash)
            dispatch(getSubscriptions({ accountId: query.account_uid!, hash: query.hash }))
                .then(unwrapResult)
                .then(subscriptionsList => {
                    if (!subscriptionsList?.subscriptions?.length) {
                        setIsAlreadyUnsubscribed(true);
                    }
                });
    }, [dispatch, query.account_uid, query.hash]);

    if ([query.account_uid, query.hash].some(value => !value)) {
        return <ProjectsPlaceholder type='404' />;
    }

    if (isAlreadyUnsubscribed) {
        return (
            <div className='cabinet-content content-notification'>
                <div className='notification-inner'>
                    <ProjectsPlaceholder type='alreadyUnsubscribed' />
                </div>
            </div>
        );
    }

    if (isSubmitted) {
        return (
            <div className='cabinet-content content-notification'>
                <div className='notification-inner'>
                    <ProjectsPlaceholder type='unsubscribed' />
                </div>
            </div>
        );
    }

    return (
        <div className='cabinet-content content-unsubscribe'>
            <div className='cabinet-content-inner content-has-footer'>
                <div className='unsubscribe-wrapper'>
                    <div className='unsubscribe-title'>{t('cabinet:unsubscribe.pageHead')}</div>
                    {isLoading ? (
                        <UnsubscribeSkeleton />
                    ) : (
                        <UnsubscribeForm
                            types={query.type as Array<EventType>}
                            accountId={query.account_uid!}
                            hash={query.hash!}
                            setIsSubmitted={setIsSubmitted}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
