import * as Cesium from 'cesium';
import _, { compact, uniqBy } from 'lodash';
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { createSelector } from 'reselect';
import { Moon, SkyBox, Sun } from 'resium';
import { usePreviousImmediate } from 'rooks';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { Dataset } from '../../../entities/Dataset';
import { SourceType } from '../../../generated/cloud-frontend-api';
import { useIsVisibleFns } from '../../../hooks/useResourceVisibility';
import getRelated3DElevation from '../../../lib/getRelated3DElevation';
import is3DElevation from '../../../lib/is3DElevation';
import isTMS from '../../../lib/isTMS';
import { EmbedViewMode, QualityOf3DModes, Routes, TerrainViewModes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { ExtendedChunk, ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { selectCurrentAndComparedDatasets } from '../../../store/selectors';
import { compareToolState } from '../../../store/slices/compareTool';
import { ExtendedDatasetInfo } from '../../../store/slices/datasetfilesUpload';
import { isOwnOrLinkedDataset } from '../../../store/slices/datasets';
import { selectGeometries } from '../../../store/slices/geometries';
import { selectChunksWithCameras } from '../../../store/slices/project';
import { selectComparedFlatTree, selectFlatTree } from '../../../store/slices/projectStructure';
import { setIsViewerLoaded } from '../../../store/slices/projectView';
import {
    ExtendedStructureInfo,
    isObjectVisible,
    selectStructureWithEnabledLimitBox
} from '../../../store/slices/structure';
import DistanceLegend from '../../Elements/distance-legend/DistanceLegend';
import UploadDatasetModal from '../../Elements/modals/upload-dataset-modal/UploadDatasetModal';
import Frustums from '../frustums/Frustums';
import GeometryLayers from '../geometry-layers/GeometryLayers';
import LimitBox from '../geometry-layers/limit-box/LimitBox';
import AppDataAttribution from './AppDataAttribution';
import AppGlobe from './AppGlobe';
import AppScene from './AppScene';
import AppViewer from './AppViewer';
import AppViewerEvents from './AppViewerEvents';
import { BusEvents } from './BusEvents';
import DemServiceVisualization from './DemServiceVisualization';
import ElevationProfileCalculations from './ElevationProfileCalculations';
import ElevationRampServiceVisualization from './ElevationRampServiceVisualization';
import KeyboardNavigation from './KeyboardNavigation';
import { AppResource, useResourceProvider } from './ResourceProvider';
import TileMapServiceVisualization from './TileMapServiceVisualization';
import Tileset3DVisualization from './Tileset3DVisualization';
import VolumeCalculations from './VolumeCalculations';
import BaseImagery from './base-imagery/BaseImagery';
import AppCamera from './camera/AppCamera';
import CameraEvents from './camera/CameraEvents';
import './removeCesiumLogoAndDataAttribution.css';

export type IsVisibleFn = (
    dataId: string,
    structures: ExtendedStructureInfo[],
    datasets: ExtendedDatasetInfo[],
    chunks: ExtendedChunk[]
) => boolean;

const selectCurrentFlatDatasetsAndArtifacts = createSelector(selectFlatTree, nodes =>
    nodes.filter(n => n.type === ProjectStructureObjectTypes.DATASET || n.type === ProjectStructureObjectTypes.ARTIFACT)
);
const selectComparedFlatDatasetsAndArtifacts = createSelector(selectComparedFlatTree, nodes =>
    nodes.filter(n => n.type === ProjectStructureObjectTypes.DATASET || n.type === ProjectStructureObjectTypes.ARTIFACT)
);
const selectFlatDatasetsAndArtifacts = createSelector(
    selectCurrentFlatDatasetsAndArtifacts,
    selectComparedFlatDatasetsAndArtifacts,
    (current, compared) => [...current, ...compared]
);

function ArtifactViewer() {
    const dispatch: AppDispatch = useDispatch();
    const viewer = useRef<Cesium.Viewer | null>();
    const previousDem3DStructures = useRef<ExtendedStructureInfo[]>([]);
    const artifactRefs = useRef<Record<string, Cesium.Cesium3DTileset | Cesium.ImageryLayer | Cesium.Entity>>({});
    const projectInfo = useSelector(state => state.project.projectInfo);
    const geometries = useSelector(state => selectGeometries(state));
    const chunks = useSelector(state => state.project.structure.chunks);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const terrainViewMode = useSelector(state => state.projectView.terrainViewMode);
    const quality3DMode = useSelector(state => state.projectView.quality3DMode);
    const currentlyHoveringShapeId = useSelector(state => state.projectView.currentlyHoveringShapeId);
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const previousIsCompareToolEnabled = usePreviousImmediate(isCompareToolEnabled);
    const floatingPointCoordinates = useSelector(state => state.projectView.floatingPointCoordinates);
    const compareToolTree1Structures = useSelector(compareToolState.selectors.tree1.selectAll);
    const compareToolTree2Structures = useSelector(compareToolState.selectors.tree2.selectAll);
    const { embedViewMode } = useContext(ProjectViewAccessContext);
    const datasets = useSelector(state => selectCurrentAndComparedDatasets(state));
    const structures = useSelector(state => state.structure.structures);
    const chunksWithCameras = useSelector(state => selectChunksWithCameras(state));
    const isUploadDatasetModalOpen = useSelector(state => state.datasetsUpload.isModalOpen);
    const sortedDatasets = useSelector(selectFlatDatasetsAndArtifacts);
    const boundingSpheres = useSelector(state => state.projectView.tilesetsBoundingSpheres);
    const tilesetTransforms = useSelector(state => state.projectView.tilesetsTransforms);
    const [needsToRecreateTerrainProvider, setNeedsToRecreateTerrainProvider] = useState(false);
    const [needsToRecreateTerrainMaterial, setNeedsToRecreateTerrainMaterial] = useState(false);
    const [needsToUpdateTerrainMaterialOpacity, setNeedsToUpdateTerrainMaterialOpacity] = useState(false);
    const [hasTerrainLayers, setHasTerrainLayers] = useState(false);
    const projectId = projectInfo.id!;
    const resources = useResourceProvider();

    const structureWithEnabledLimitBox = useSelector(state => selectStructureWithEnabledLimitBox(state));
    const tilesetIdOfDatasetWithEnabledLimitBox = datasets.find(d => d.datasetUid === structureWithEnabledLimitBox?.uid)
        ?.visualData?.dataUid;
    const boundingSphereOfDatasetWithEnabledLimitBox = boundingSpheres[tilesetIdOfDatasetWithEnabledLimitBox || ''];
    const transformOfTilesetWithEnabledLimitBox = tilesetTransforms[tilesetIdOfDatasetWithEnabledLimitBox || ''];

    const getRelatedToResource3DElevation = useCallback(
        (resource: AppResource) => {
            const artifactDEM = datasets.find(d => d.visualData?.dataUid === resource.tilesetId);
            const chunk = chunks.find(c => c.artifacts.findIndex(a => a.datasetUid === artifactDEM?.datasetUid) !== -1);
            return getRelated3DElevation(datasets, chunk, artifactDEM?.name);
        },
        [datasets, chunks]
    );

    const tiles3DResources = useMemo(
        () => resources.filter(r => !isTMS(r.sourceType) && !is3DElevation(r.sourceType)),
        [resources]
    );
    const TMSResources = useMemo(
        () =>
            resources
                .filter(r => isTMS(r.sourceType))
                .filter(tms => tms.sourceType !== SourceType.DEM || getRelatedToResource3DElevation(tms) === undefined),
        [resources, getRelatedToResource3DElevation]
    );

    const sortedTMSResources = useMemo(() => {
        return uniqBy(
            compact(
                sortedDatasets
                    .reverse()
                    .map(sd => datasets.find(d => d.datasetUid === sd.id))
                    .map(d => TMSResources.find(r => r.tilesetId === d?.visualData?.dataUid))
            ),
            resource => resource.tilesetId
        );
    }, [datasets, TMSResources, sortedDatasets]);

    const dem3DResources = useMemo(() => resources.filter(r => is3DElevation(r.sourceType)), [resources]);

    const dem3DStructures = useMemo(() => {
        return _.compact(
            dem3DResources.map(r => {
                const dataset = datasets
                    .filter(isOwnOrLinkedDataset(projectId, chunks))
                    .find(d => d.visualData?.dataUid === r.tilesetId);
                const structureInfo = structures.find(s => s.uid === dataset?.datasetUid);
                return structureInfo;
            })
        );
    }, [datasets, structures, dem3DResources, projectId, chunks]);

    const { isArtifactVisible, isDatasetVisible } = useIsVisibleFns();

    const getTerrainLayers = useCallback(() => {
        if (isCompareToolEnabled) return [];

        const elevationLayers = dem3DResources
            .filter(resource =>
                resource.fromSource === 'artifact'
                    ? isArtifactVisible(resource.tilesetId, structures, datasets, chunks)
                    : isDatasetVisible(resource.tilesetId, structures, datasets, chunks)
            )
            .sort((a, b) => {
                const sortedDatasetsDataIds = _.compact(
                    sortedDatasets.map(sd => datasets.find(d => d.datasetUid === sd.id)?.visualData?.dataUid)
                );
                const sortedDatasetAIndex = sortedDatasetsDataIds.indexOf(a.tilesetId);
                const sortedDatasetBIndex = sortedDatasetsDataIds.indexOf(b.tilesetId);
                if (sortedDatasetAIndex > sortedDatasetBIndex) return -1;
                if (sortedDatasetAIndex < sortedDatasetBIndex) return 1;
                return 0;
            })
            .map(resourceDem3D => {
                return { url: resourceDem3D.url };
            })
            .map(layer => layer.url);

        if (!hasTerrainLayers && elevationLayers.length) {
            setHasTerrainLayers(true);
            setNeedsToRecreateTerrainProvider(true);
        }
        return elevationLayers;
    }, [
        sortedDatasets,
        chunks,
        isCompareToolEnabled,
        datasets,
        structures,
        dem3DResources,
        isArtifactVisible,
        hasTerrainLayers,
        setHasTerrainLayers,
        setNeedsToRecreateTerrainProvider,
        isDatasetVisible
    ]);

    const getOpacityByDataId = (dataId: string) => {
        const dataset = datasets.find(d => d.visualData?.dataUid === dataId);
        const structureInfo = structures.find(s => s.uid === dataset?.datasetUid);
        return structureInfo?.properties.opacity
            ? Number(structureInfo?.properties.opacity)
            : Dataset.defaultOpacity(dataset?.sourceData?.type!);
    };

    useEffect(() => {
        // Need to remove 3D DEM layers on compare mode toggle
        const visible3DDems = dem3DResources.filter(resource =>
            resource.fromSource === 'artifact'
                ? isArtifactVisible(resource.tilesetId, structures, datasets, chunks)
                : isDatasetVisible(resource.tilesetId, structures, datasets, chunks)
        );
        if (isCompareToolEnabled !== previousIsCompareToolEnabled && visible3DDems.length > 0) {
            setNeedsToRecreateTerrainProvider(true);
        }
    }, [
        isCompareToolEnabled,
        hasTerrainLayers,
        chunks,
        previousIsCompareToolEnabled,
        dem3DResources,
        isArtifactVisible,
        isDatasetVisible,
        structures,
        datasets
    ]);

    useEffect(() => {
        viewer.current?.scene.requestRender();
    }, [
        datasets,
        resources,
        structures,
        viewer,
        isCompareToolEnabled,
        geometries,
        floatingPointCoordinates,
        selectedObject,
        currentlyHoveringShapeId,
        compareToolTree1Structures,
        compareToolTree2Structures
    ]);

    useEffect(() => {
        return () => {
            dispatch(setIsViewerLoaded(false));
        };
    }, [dispatch]);

    useEffect(() => {
        // Because right now there is no way to get the event "Everything is loaded"
        // TODO add this event, rework tilesets loading
        if (terrainViewMode === TerrainViewModes.MODEL) dispatch(setIsViewerLoaded(true));
    }, [dispatch, terrainViewMode]);

    useEffect(() => {
        viewer.current?.scene.requestRender();
        const scene = viewer.current?.scene;
        if (!scene) return;
        scene.postProcessStages.fxaa.enabled = quality3DMode !== QualityOf3DModes.LOW;
    }, [quality3DMode]);

    useEffect(() => {
        const dem3dOrderChanged = dem3DStructures.some(s => {
            const previousOrder = previousDem3DStructures.current.find(ps => ps.uid === s.uid)?.properties?.order;
            return s.properties?.order !== previousOrder && s.properties?.visible === 'true';
        });
        if (dem3dOrderChanged) {
            previousDem3DStructures.current = dem3DStructures;
            setNeedsToRecreateTerrainProvider(true);
        }
    }, [dem3DStructures]);

    return (
        <AppViewer
            ref={e => {
                viewer.current = e?.cesiumElement!;
            }}
        >
            <AppScene />
            <Sun show={false} />
            <SkyBox show={false} />
            <Moon show={false} />
            <AppGlobe
                needsToRecreateTerrainProvider={needsToRecreateTerrainProvider}
                setNeedsToRecreateTerrainProvider={setNeedsToRecreateTerrainProvider}
                setNeedsToRecreateTerrainMaterial={setNeedsToRecreateTerrainMaterial}
                terrainLayers={getTerrainLayers()}
            />
            <AppCamera />
            <BaseImagery />
            <CameraEvents artifactRefs={artifactRefs.current} />
            <AppViewerEvents />
            <KeyboardNavigation />
            <ElevationRampServiceVisualization
                dem3DResources={dem3DResources}
                needsToRecreateTerrainMaterial={needsToRecreateTerrainMaterial}
                needsToUpdateTerrainMaterialOpacity={needsToUpdateTerrainMaterialOpacity}
                setNeedsToRecreateTerrainMaterial={setNeedsToRecreateTerrainMaterial}
                setNeedsToUpdateTerrainMaterialOpacity={setNeedsToUpdateTerrainMaterialOpacity}
            />
            {tiles3DResources.map(resource => (
                <Tileset3DVisualization
                    ref={el => {
                        artifactRefs.current[resource.tilesetId] = el?.cesiumElement!;
                    }}
                    key={resource.tilesetId}
                    resource={resource}
                    opacity={getOpacityByDataId(resource.tilesetId)}
                />
            ))}
            {sortedTMSResources.map((resource, index) => (
                <TileMapServiceVisualization
                    ref={el => {
                        artifactRefs.current[resource.tilesetId] = el?.cesiumElement!;
                    }}
                    index={index}
                    resource={resource}
                    key={resource.tilesetId}
                    opacity={getOpacityByDataId(resource.tilesetId)}
                />
            ))}
            {dem3DResources.map(resourceDem3D => (
                <DemServiceVisualization
                    ref={el => {
                        artifactRefs.current[resourceDem3D.tilesetId] = el?.cesiumElement!;
                    }}
                    resource={resourceDem3D}
                    show={
                        resourceDem3D.fromSource === 'artifact'
                            ? isArtifactVisible(resourceDem3D.tilesetId, structures, datasets, chunks)
                            : isDatasetVisible(resourceDem3D.tilesetId, structures, datasets, chunks)
                    }
                    key={resourceDem3D.tilesetId}
                    setNeedsToRecreateTerrainProvider={setNeedsToRecreateTerrainProvider}
                    setNeedsToUpdateTerrainMaterialOpacity={setNeedsToUpdateTerrainMaterialOpacity}
                />
            ))}
            {chunksWithCameras.map(({ cameras, chunk, assetUid }) => (
                <Frustums
                    key={cameras!.artifactUid}
                    artifactId={cameras!.artifactUid!}
                    visible={
                        isObjectVisible(structures, assetUid!) && isObjectVisible(structures, cameras?.artifactUid!)
                    }
                />
            ))}
            <GeometryLayers />
            {structureWithEnabledLimitBox &&
                boundingSphereOfDatasetWithEnabledLimitBox &&
                transformOfTilesetWithEnabledLimitBox && (
                    <LimitBox
                        structureInfo={structureWithEnabledLimitBox}
                        boundingSphere={boundingSphereOfDatasetWithEnabledLimitBox}
                        transform={transformOfTilesetWithEnabledLimitBox}
                    />
                )}
            <Switch>
                <Route exact path={[Routes.SHARED_PROJECT_VIEW, Routes.PROJECT_VIEW]}>
                    <DistanceLegend />
                </Route>
                <Route exact path={[Routes.EMBEDDED_PROJECT_VIEW]}>
                    {embedViewMode === EmbedViewMode.FULL ? (
                        <DistanceLegend />
                    ) : (
                        <div className='embed-copyright'>
                            <AppDataAttribution />
                        </div>
                    )}
                </Route>
            </Switch>
            {isUploadDatasetModalOpen && <UploadDatasetModal />}
            <VolumeCalculations />
            <ElevationProfileCalculations />
            <BusEvents />
        </AppViewer>
    );
}

export default memo(ArtifactViewer);
