import React, { ReactNode } from 'react';
import { ModalProps } from './Modal';
import classnames from 'classnames';

type Props = Pick<ModalProps, 'setIsOpen'> & {
    children: ReactNode;
    hideCloseButton?: boolean;
    isEmbed?: boolean;
};

export default function ModalHead({ children, setIsOpen, hideCloseButton, isEmbed = false }: Props) {
    return (
        <div className='modal-head'>
            <span className='modal-title'>{children}</span>
            {!hideCloseButton && (
                <span
                    data-testid={'iconClose'}
                    className={classnames('modal-close ico-x', { dark: !isEmbed })}
                    onClick={e => {
                        setIsOpen(false);
                    }}
                />
            )}
        </div>
    );
}
