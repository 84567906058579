import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectInfo } from '../../../../generated/cloud-frontend-api/model';
import TippyDropdownItemWithIcon from '../../tippy-dropdown/TippyDropdownItemWithIcon';
import PublishPaymentOverdueModal from '../../modals/PublishPaymentOverdueModal';
import PublishStorageIsFullModal from '../../modals/PublishStorageIsFullModal';
import PublishProjectModal from '../modals/PublishProjectModal';
import { ReactComponent as SvgPublish } from '../../../../svg/dropdown_actions/publish.svg';

type Props = {
    publishState: string;
    project: ProjectInfo;
};

export default function PublishProjectAction({ publishState, project }: Props) {
    const { t } = useTranslation(['projects']);

    const [showPaymentOverdueModal, setShowPaymentOverdueModal] = useState(false);
    const [showStorageIsFullModal, setShowStorageIsFullModal] = useState(false);
    const [showPublishModal, setShowPublishModal] = useState(false);

    return (
        <>
            <TippyDropdownItemWithIcon
                icon={<SvgPublish />}
                text={t('projects:projectActions.publish')}
                onClick={() => {
                    switch (publishState) {
                        case 'overdue':
                            setShowPaymentOverdueModal(true);
                            break;
                        case 'storageFull':
                            setShowStorageIsFullModal(true);
                            break;
                        default:
                            setShowPublishModal(true);
                    }
                }}
            />

            {showPaymentOverdueModal && (
                <PublishPaymentOverdueModal setIsOpen={setShowPaymentOverdueModal} isOpen={showPaymentOverdueModal} />
            )}

            {showStorageIsFullModal && (
                <PublishStorageIsFullModal setIsOpen={setShowStorageIsFullModal} isOpen={showStorageIsFullModal} />
            )}

            {showPublishModal && (
                <PublishProjectModal setIsOpen={setShowPublishModal} isOpen={showPublishModal} project={project} />
            )}
        </>
    );
}
