import { useState } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { AppDispatch, useSelector } from '../../store';
import { createLoadingSelector } from '../../store/selectors/createLoadingSelector';
import { createBillingOrder, downgradeSubscription, getProductsInfo } from '../../store/slices/billing';
import { StorageProductGradeEnum } from '../../generated/billing-api';
import useSubscriptionQueryParams from '../../hooks/useSubscriptionQueryParams';
import { useHistory } from 'react-router-dom';
import { useDidMount } from 'rooks';
import useFastspringScript from '../../hooks/useFastspringScript';
import { setPaymentResultProperty } from '../../store/slices/paymentResult';
import SubscriptionSkeleton from './components/SubscriptionSkeleton';
import StorageSubscription from './components/StorageSubscription';
import ProcessingHoursSubscription from './components/ProcessingHoursSubscription';
import SubscriptionSummary from './components/SubscriptionSummary';
import SubscriptionActions from './components/SubscriptionActions';

import styles from './SubscriptionForm.module.scss';

type Props = {
    showStorage?: boolean;
    showHours?: boolean;
    expandedHoursList?: boolean;
};

const loadingSelector = createLoadingSelector([getProductsInfo.typePrefix]);

export interface SubscriptionFormValues {
    storage: string;
    hours: string;
}

export default function SubscriptionForm({ showStorage = false, showHours = false, expandedHoursList = false }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const history = useHistory();
    const { upgrade } = useSubscriptionQueryParams();
    const onlyHours = showHours && !showStorage;
    const onlyStorage = showStorage && !showHours;
    const currentSubscriptionName = useSelector(
        state =>
            state.billing.products.storageProducts.find(sp => sp.grade === StorageProductGradeEnum.CURRENT)?.name || ''
    );
    const currentPlan = useSelector(state => state.accountResources.account.plan.current);
    const fastSpringLink = useSelector(state => state.user.fastspringLink);
    const { storageProducts, processingTimeProducts } = useSelector(state => state.billing.products);
    const isLoading = useSelector(state => loadingSelector(state));

    const [isHoursListVisible, setIsHoursListVisible] = useState(expandedHoursList);

    const initialHoursValue = onlyHours
        ? processingTimeProducts.filter(ptp => ptp.name !== '0 hours')?.[0]?.name || ''
        : '0 hours';

    function handleSubmit(values: any) {
        const storageProduct = storageProducts.find(sp => sp.name === values.storage);

        if (storageProduct?.grade === StorageProductGradeEnum.DOWNGRADE) {
            dispatch(downgradeSubscription(storageProduct.name!)).then(() => {
                dispatch(
                    setPaymentResultProperty({
                        propName: 'selectedStorageProductName',
                        propValue: values.storage
                    })
                );
                history.push('/');
            });
            return;
        }

        if (currentPlan?.overdue && !onlyHours) {
            window.open(fastSpringLink, '_blank', 'noopener noreferrer');
            return;
        }

        if (!currentPlan?.overdue && onlyStorage && storageProduct?.grade === StorageProductGradeEnum.CURRENT) {
            window.open(fastSpringLink, '_blank', 'noopener noreferrer');
            return;
        }

        const processingTimeProduct = processingTimeProducts.find(ptp => ptp.name === values.hours);

        dispatch(
            createBillingOrder({
                products: {
                    storage: showStorage ? values.storage : undefined,
                    processingTime: showHours
                        ? processingTimeProduct?.name === '0 hours'
                            ? undefined
                            : values.hours
                        : undefined
                }
            })
        )
            .then(unwrapResult)
            .then(({ payload, key }) => {
                (window as any).fastspring.builder.secure(payload, key);
                (window as any).fastspring.builder.checkout();
            });
    }

    useDidMount(() => {
        dispatch(getProductsInfo());
    });

    useFastspringScript();

    function getNextProductAfterCurrent() {
        const indexOfCurrentProduct = storageProducts.findIndex(sp => sp.name === currentSubscriptionName);
        if (indexOfCurrentProduct >= 0 && indexOfCurrentProduct + 1 < storageProducts.length) {
            return storageProducts[indexOfCurrentProduct + 1].name;
        }
    }

    return (
        <Formik
            initialValues={{
                storage:
                    storageProducts.find(sp => sp.grade === StorageProductGradeEnum.SCHEDULED)?.name ||
                    (upgrade && getNextProductAfterCurrent()) ||
                    currentSubscriptionName ||
                    storageProducts[0]?.name! ||
                    '',
                hours: initialHoursValue
            }}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            <Form className={styles.subscriptionForm}>
                {isLoading ? (
                    <SubscriptionSkeleton showStorage={showStorage} showHours={showHours} />
                ) : (
                    <>
                        {showStorage && <StorageSubscription />}
                        {showHours && (
                            <ProcessingHoursSubscription
                                onlyHours={onlyHours}
                                isHoursListVisible={isHoursListVisible}
                                makeHoursListVisible={() => {
                                    setIsHoursListVisible(true);
                                }}
                            />
                        )}
                        <SubscriptionSummary showStorage={showStorage} showHours={showHours && isHoursListVisible} />
                        <SubscriptionActions showStorage={showStorage} showHours={showHours} />
                    </>
                )}
            </Form>
        </Formik>
    );
}
