import { useCallback } from 'react';
import { IsVisibleFn } from '../components/ProjectView/ArtifactViewer';
import { useSelector } from '../store';
import { ExtendedChunk } from '../store/helpers/interfaces';
import { ExtendedDatasetInfo } from '../store/slices/datasetfilesUpload';
import { ExtendedStructureInfo, getGroups, isObjectVisible } from '../store/slices/structure';
import { compareToolState } from '../store/slices/compareTool';
import { EMPTY_ARRAY } from '../sharedConstants';
import { AppResource } from '../components/ProjectView/ArtifactViewer/ResourceProvider';

export function useIsVisibleFns() {
    const isChunkVisible = useCallback(
        (dataset: ExtendedDatasetInfo, structures: ExtendedStructureInfo[], chunks: ExtendedChunk[]) => {
            const chunk = chunks.find(c => c.artifacts.map(a => a.datasetUid).includes(dataset.datasetUid));
            if (chunk) return isObjectVisible(structures, chunk.assetUid!);
            return false;
        },
        []
    );

    const isDatasetVisible: IsVisibleFn = useCallback((dataUid, structures, datasets) => {
        const dataset = datasets.find(d => d.visualData?.dataUid === dataUid);
        if (dataset) {
            const visible = isObjectVisible(structures, dataset.datasetUid!);
            const structureInfo = structures.find(s => s.uid === dataset.datasetUid);
            const groups = getGroups(structures);
            const group = groups.find(g => g.uid === structureInfo?.parentUid);
            const belongsToGroup = Boolean(group);
            const groupVisible = belongsToGroup ? isObjectVisible(structures, group?.uid!) : true;
            return groupVisible && visible;
        }
        return false;
    }, []);

    const isArtifactVisible: IsVisibleFn = useCallback(
        (dataUid, structures, datasets, chunks) => {
            const dataset = datasets.find(d => d.visualData?.dataUid === dataUid);
            if (dataset)
                return (
                    isChunkVisible(dataset, structures, chunks) &&
                    isDatasetVisible(dataUid, structures, datasets, chunks)
                );
            return false;
        },
        [isChunkVisible, isDatasetVisible]
    );

    return { isDatasetVisible, isArtifactVisible };
}

export function useResourceVisibility(resource: AppResource) {
    const chunks = useSelector(state => state.project.structure.chunks);
    const datasets = useSelector(state => state.datasets.datasets);
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const comparedProjectChunks = useSelector(state => state.compareTool.comparedProject?.chunks || EMPTY_ARRAY);
    const comparedProjectDatasets = useSelector(state => state.compareTool.comparedProject?.datasets || EMPTY_ARRAY);
    const structures = useSelector(state => state.structure.structures);
    const compareToolTree1Structures = useSelector(state => compareToolState.selectors.tree1.selectAll(state));
    const compareToolTree2Structures = useSelector(state => compareToolState.selectors.tree2.selectAll(state));
    const compareToolMode = useSelector(state => state.compareTool.mode);

    const { isArtifactVisible, isDatasetVisible } = useIsVisibleFns();

    const isVisible = resource.fromSource === 'artifact' ? isArtifactVisible : isDatasetVisible;

    const visible = isVisible(resource.tilesetId, structures, datasets, chunks);
    const visibleInCompareTree1 = isVisible(resource.tilesetId, compareToolTree1Structures, datasets, chunks);
    const visibleInCompareTree2 = (() => {
        if (isCompareToolEnabled && compareToolMode === 'compareSiteProjects') {
            return isVisible(
                resource.tilesetId,
                compareToolTree2Structures,
                comparedProjectDatasets,
                comparedProjectChunks
            );
        }
        return isVisible(resource.tilesetId, compareToolTree2Structures, datasets, chunks);
    })();

    const actualVisible = (() => {
        if (isCompareToolEnabled) {
            return visibleInCompareTree1 || visibleInCompareTree2;
        }
        return visible;
    })();

    return { actualVisible, visibleInCompareTree1, visibleInCompareTree2 };
}
