import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useKey } from 'rooks';
import { useCameraImageUrl } from '../../../hooks/useCameraImageUrl';
import useGetElementDimensions from '../../../hooks/useGetElementDimensions';
import getFilenameExtension from '../../../lib/getFilenameExtension';
import isImageFormatViewable from '../../../lib/isImageFormatViewable';
import {
    EMPTY_ARRAY,
    FULL_IMAGE_SIZE_LIMIT,
    GeometryTypes,
    inspectorImageListThumbnailParam as thumbnail
} from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import {
    ProjectStructureObjectTypes,
    TemporaryGeometry,
    isIssuePointGeometry
} from '../../../store/helpers/interfaces';
import { selectedCameraSelector, selectedCamerasSelector, selectedObjectInfoSelector } from '../../../store/selectors';
import { setSelectedObject } from '../../../store/sharedActions';
import { ExtendedCamera, selectCameras } from '../../../store/slices/cameras';
import { makeSelectLayerGeometries, selectGeometryById } from '../../../store/slices/geometries';
import { makeSelectLayerByGeometryId } from '../../../store/slices/geometryLayers';
import { setGalleryVisibility, setSelectedCamera } from '../../../store/slices/projectView';
import { ReactComponent as SvgLoader } from '../../../svg/general/loader.svg';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import CameraPhotoInfo from '../camera-photo-info/CameraPhotoInfo';
import IssuePhotoInfo from '../camera-photo-info/IssuePhotoInfo';
import NavigationCarousel from '../navigation-carousel/NavigationCarousel';
import NavigableImageWithCanvas from './NavigableImageWithCanvas';

const ImageViewer: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const galleryMode = useSelector(state => state.projectView.galleryVisibility.mode);
    const isCamerasInspectionEnabled = useSelector(state => state.projectView.isCamerasInspectionEnabled);
    const allCameras = useSelector(state => selectCameras(state));
    const camerasObject = useSelector(state => state.cameras);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const selectedObjectInfo = useSelector(selectedObjectInfoSelector);
    const selectedCamera = useSelector(state => selectedCameraSelector(state));
    const selectedIssue = useSelector(state =>
        selectGeometryById(state, selectedObject?.artifactId!)
    ) as TemporaryGeometry<GeometryTypes.POINT>;
    const selectInspectionLayer = useMemo(makeSelectLayerByGeometryId, []);
    const inspectionLayer = useSelector(state => selectInspectionLayer(state, selectedObject?.artifactId!));
    const selectLayerGeometries = useMemo(makeSelectLayerGeometries, []);
    const inspectionLayerIssues = useSelector(state => selectLayerGeometries(state, inspectionLayer?.id!));
    const selectedCameras = useSelector(state => selectedCamerasSelector(state));
    const isViewable =
        (!!selectedCamera &&
            isImageFormatViewable(getFilenameExtension(selectedCamera.fileName)) &&
            selectedCamera.sizeInBytes! <= FULL_IMAGE_SIZE_LIMIT) ||
        (!selectedCamera && galleryMode === 'issues');

    const [isLoading, setIsLoading] = useState(isViewable);
    const [hasFailed, setHasFailed] = useState(false);

    const isIssueSelected =
        selectedObject?.type === ProjectStructureObjectTypes.GEOMETRY &&
        isIssuePointGeometry(selectedObjectInfo as TemporaryGeometry);

    const carouselContainerRef = useRef<HTMLDivElement>(null);
    const carouselContainerDimensions = useGetElementDimensions(carouselContainerRef);

    const camerasToIterate: (ExtendedCamera & { issueId?: string })[] = useMemo(() => {
        if (galleryMode === 'cameras') {
            return selectedCameras || EMPTY_ARRAY;
        }
        if (galleryMode === 'issues') {
            return inspectionLayerIssues.map(issue => {
                let camera = allCameras.find(c => c.photoUid === issue.content.properties.ac_image_uid);
                return { ...camera!, issueId: issue.id };
            });
        }
        return EMPTY_ARRAY;
    }, [allCameras, galleryMode, inspectionLayerIssues, selectedCameras]);

    const indexOfSelectedCamera =
        galleryMode === 'cameras'
            ? camerasToIterate?.findIndex(c => c.uid === selectedCamera?.uid)
            : camerasToIterate?.findIndex(c => c.issueId === selectedObject?.artifactId);

    const { preloadThumbUrl, url } = useCameraImageUrl(
        selectedCamera,
        thumbnail,
        selectedIssue?.content.properties.ac_image_uid as string
    );

    const failed = hasFailed;
    const showLoader = isViewable && isLoading && !failed;

    function goToNextCamera(): void {
        const newIndex = indexOfSelectedCamera === camerasToIterate?.length! - 1 ? 0 : indexOfSelectedCamera! + 1;
        const newCamera = camerasToIterate?.[newIndex];

        dispatch(
            setSelectedCamera({
                artifactUid: Object.keys(camerasObject).find(key =>
                    camerasObject[key].find(c => c.uid === newCamera?.uid)
                )!,
                uid: newCamera?.uid!,
                selectInWorkspace: !isCamerasInspectionEnabled && galleryMode === 'cameras'
            })
        );
        if (galleryMode === 'issues') {
            dispatch(
                setSelectedObject({ artifactId: newCamera?.issueId! || '', type: ProjectStructureObjectTypes.GEOMETRY })
            );
        }
    }

    function goToPreviousCamera(): void {
        const newIndex = indexOfSelectedCamera === 0 ? camerasToIterate?.length! - 1 : indexOfSelectedCamera! - 1;
        const newCamera = camerasToIterate?.[newIndex];

        dispatch(
            setSelectedCamera({
                artifactUid: Object.keys(camerasObject).find(key =>
                    camerasObject[key].find(c => c.uid === newCamera?.uid)
                )!,
                uid: newCamera?.uid!,
                selectInWorkspace: !isCamerasInspectionEnabled && galleryMode === 'cameras'
            })
        );
        if (galleryMode === 'issues') {
            dispatch(
                setSelectedObject({ artifactId: newCamera?.issueId! || '', type: ProjectStructureObjectTypes.GEOMETRY })
            );
        }
    }

    useEffect(() => {
        setIsLoading(isViewable);
        setHasFailed(false);
    }, [selectedCamera?.uid, selectedObject?.artifactId, isViewable]);

    useKey('ArrowLeft', goToPreviousCamera);
    useKey('ArrowRight', goToNextCamera);

    return (
        <div className='image-viewer'>
            <div className='image-wrapper' id={'navigableImageContainer'}>
                {showLoader && (
                    <>
                        <div
                            className='background-image'
                            style={preloadThumbUrl ? { backgroundImage: `url(${preloadThumbUrl})` } : undefined}
                        />
                        <div className='image-loader'>
                            <div className='loader'>
                                <SvgLoader className='loader-svg' />
                            </div>
                            <div className='loader-text'>{t('projectView:imageViewer.loader')}</div>
                        </div>
                    </>
                )}
                {!failed && (
                    <NavigableImageWithCanvas
                        imageUrl={url}
                        key={galleryMode === 'issues' ? selectedObject?.artifactId : selectedCamera?.uid}
                        isLoading={isLoading}
                        onLoad={() => {
                            setIsLoading(false);
                        }}
                        setHasFailed={() => {
                            setIsLoading(false);
                            setHasFailed(true);
                        }}
                        containerSize={{
                            width: document.querySelector('#navigableImageContainer')?.clientWidth!,
                            height: document.querySelector('#navigableImageContainer')?.clientHeight!
                        }}
                        singleIssueIdToDisplay={isIssueSelected ? selectedObject?.artifactId : undefined}
                    />
                )}
                {failed && (
                    <div className='no-image-stub'>
                        <i className='icon icon-no_preview' />
                        <div className='stub-text'>{t('projectView:imageViewer.stub')}</div>
                    </div>
                )}

                <div className='image-controls'>
                    {isViewable && !failed && (
                        <TippyTooltip tooltipText={t('projectView:imageViewer.tooltipDownload')}>
                            <div
                                data-testid={'exportControl'}
                                className='image-control'
                                onClick={e => {
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.download = isIssueSelected
                                        ? ((selectedObjectInfo as TemporaryGeometry).content.properties
                                              .ac_image_name as string)
                                        : (selectedCamera as ExtendedCamera).fileName;
                                    link.click();
                                }}
                            >
                                <i className='icon icon-export' />
                            </div>
                        </TippyTooltip>
                    )}
                    <TippyTooltip tooltipText={t('projectView:imageViewer.tooltipMinimize')}>
                        <div
                            data-testid={'fullscreenControl'}
                            className='image-control'
                            onClick={e => {
                                dispatch(setGalleryVisibility({ isVisible: false }));
                            }}
                        >
                            <i className='icon icon-windowed' />
                        </div>
                    </TippyTooltip>
                </div>

                {galleryMode === 'cameras' && selectedCamera && (
                    <CameraPhotoInfo camera={selectedCamera} cameras={camerasToIterate} />
                )}

                {galleryMode === 'issues' && selectedIssue && <IssuePhotoInfo issue={selectedIssue} />}

                <div
                    className={classNames('image-prev')}
                    onClick={e => {
                        goToPreviousCamera();
                    }}
                />
                <div
                    className={classNames('image-next')}
                    onClick={e => {
                        goToNextCamera();
                    }}
                />
            </div>

            <div className='images-carousel' ref={carouselContainerRef}>
                <NavigationCarousel
                    type='viewer'
                    containerWidth={carouselContainerDimensions.width}
                    cameras={camerasToIterate || EMPTY_ARRAY}
                />
            </div>
        </div>
    );
};

export default ImageViewer;
