import * as Cesium from 'cesium';

export function getJsonFromTypedArray(uint8Array: any, byteOffset: any, byteLength: any) {
    return (Cesium as any).getJsonFromTypedArray(uint8Array, byteOffset, byteLength);
}

export function defaultValue(value: any, def: any) {
    return (Cesium as any).defaultValue(value, def);
}

export const AttributeCompression = (Cesium as any).AttributeCompression;
export const Sampler = (Cesium as any).Sampler;
export const Texture = (Cesium as any).Texture;
