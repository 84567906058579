import { Colord, colord, HslaColor } from 'colord';

type ColorScheme = Omit<HslaColor, 'h'>;
const defaultColorScheme: ColorScheme = {
    l: 57,
    s: 72,
    a: 1
};

function getRandomColor(colorScheme: ColorScheme = defaultColorScheme): Colord {
    const s = Math.min(100, Math.max(0, colorScheme.s));
    const l = Math.min(100, Math.max(0, colorScheme.l));
    const a = Math.min(1, Math.max(0, colorScheme.a));

    const h = Math.floor(Math.random() * 360);

    return colord({ h, s, l, a });
}

export function getRandomColorHex(colorScheme: ColorScheme = defaultColorScheme): string {
    return getRandomColor(colorScheme).toHex();
}
