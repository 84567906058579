import { useRef } from 'react';
import classNames from 'classnames';
import { PlanType } from '../../generated/account-frontend-api';
import useGetElementDimensions from '../../hooks/useGetElementDimensions';
import PreviousPageLink from '../../components/Elements/previous-page-link/PreviousPageLink';
import PlanItemSkeleton from './components/PlanItemSkeleton';
import PlanItem from './components/PlanItem';
import { createLoadingSelector } from '../../store/selectors/createLoadingSelector';
import { useSelector } from '../../store';
import { getAccount } from '../../store/slices/accountResources';

import styles from './PlanManagement.module.scss';

const LAYOUT_BREAKPOINT = 768; // = $plans_layout_breakpoint from '_variables.scss'

export default function PlanManagement() {
    const account = useSelector(state => state.accountResources.account);
    const isLoading = useSelector(state => createLoadingSelector([getAccount.typePrefix])(state));

    const documentRef = useRef(document.body);
    const documentWidth = useGetElementDimensions(documentRef).width;
    const isDesktopLayout = (documentWidth || 0) >= LAYOUT_BREAKPOINT;

    return (
        <div className={styles.contentWrapper}>
            <PreviousPageLink />

            <div className={classNames(styles.plansWrapper, isDesktopLayout && styles.isDesktop)}>
                {isLoading ? (
                    <>
                        <PlanItemSkeleton itemType={PlanType.FREE} />
                        <PlanItemSkeleton itemType={PlanType.COMMERCIAL} />
                    </>
                ) : (
                    <>
                        <PlanItem itemType={PlanType.FREE} isDesktopLayout={isDesktopLayout} account={account} />
                        <PlanItem itemType={PlanType.COMMERCIAL} isDesktopLayout={isDesktopLayout} account={account} />
                    </>
                )}
            </div>
        </div>
    );
}
