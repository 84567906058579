import classNames from 'classnames';
import { Select } from 'antd';
import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { TerrainViewModes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { selectBaseLayers } from '../../../store/slices/init';
import { setBaseImageryProvider } from '../../../store/slices/projectView';
import { saveRootStructureProperty } from '../../../store/slices/structure';

import styles from './MapSettings.module.scss';

export default function BaseMapControl() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const mapModeControlRef = useRef<HTMLDivElement>(null);
    const { isInEmbedView } = useContext(ProjectViewAccessContext);
    const terrainViewMode = useSelector(state => state.projectView.terrainViewMode);
    const baseImageryProvider = useSelector(state => state.projectView.baseImageryProvider);
    const baseLayers = useSelector(selectBaseLayers);

    function switchBaseMap(baseMap: string) {
        dispatch(setBaseImageryProvider(baseMap));
        dispatch(saveRootStructureProperty({ propertyName: 'baseMap', propertyValue: baseMap }));
    }

    return (
        <div ref={mapModeControlRef}>
            <div className={styles.settingsItemTitle}>{t('projectView:mapControls.baseMap')}</div>
            <Select
                data-testid={'baseMapControl'}
                value={baseImageryProvider}
                onChange={newValue => {
                    if (newValue !== baseImageryProvider) {
                        switchBaseMap(newValue);
                    }
                }}
                getPopupContainer={() => mapModeControlRef.current!}
                className={classNames('ant-select-small', { 'theme-dark': isInEmbedView })}
                popupClassName={classNames('ant-dropdown-small', { 'theme-dark': isInEmbedView })}
                disabled={terrainViewMode === TerrainViewModes.MODEL}
                options={baseLayers.map(layer => {
                    return {
                        key: layer.id,
                        value: layer.id,
                        label: layer.name
                    };
                })}
            />
        </div>
    );
}
