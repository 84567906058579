import { GetThunkAPI, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, ApplicationState } from '..';

export function createSetterReducer<S>() {
    return <K extends keyof S>(propName: K) =>
        (state: S, { payload }: PayloadAction<S[K]>) => {
            state[propName] = payload;
        };
}

/*
 * Gets thunk name from action type, generated by redux-toolkit, by removing thunk status from action type
 * */
export default function getThunkName(fullActionName: string): string {
    if (!fullActionName.match(/\/(pending|fulfilled|rejected)$/g)) {
        throw new Error('Invalid argument');
    }

    const thunkNameParts = fullActionName.split('/');
    const thunkStatus = thunkNameParts[thunkNameParts.length - 1];
    return fullActionName.replace(`/${thunkStatus}`, '');
}

export type NeededThunkApi = Pick<
    GetThunkAPI<{ state: ApplicationState; dispatch: AppDispatch; rejectValue: any }>,
    'getState' | 'dispatch' | 'rejectWithValue'
>;
