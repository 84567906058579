import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useDrawingCancel from '../../../hooks/useDrawingCancel';
import { AppDispatch, useSelector } from '../../../store';
import { disableAllControls, setRulerToolEnabled } from '../../../store/slices/projectView';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';

export default function RulerControl() {
    const { t } = useTranslation('projectView');
    const dispatch: AppDispatch = useDispatch();
    const isRulerToolEnabled = useSelector(state => state.projectView.isRulerToolEnabled);
    const cancelDrawing = useDrawingCancel();

    return (
        <TippyTooltip tooltipText={t('mapControls.tooltipRuler')}>
            <div
                data-testid={'control'}
                className={classNames('control-btn', { active: isRulerToolEnabled })}
                onClick={async e => {
                    cancelDrawing();
                    dispatch(disableAllControls());
                    dispatch(setRulerToolEnabled(!isRulerToolEnabled));
                }}
            >
                <i className='icon icon-ruler' />
            </div>
        </TippyTooltip>
    );
}
