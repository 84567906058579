import classNames from 'classnames';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { AccessInfoAccessTypeEnum, AccessInfoProjectRoleEnum } from '../../../generated/access-api-v2/model';
import { ProjectInfo } from '../../../generated/cloud-frontend-api/model';
import { ProjectType } from '../../../generated/project-api-v2';
import { AppDispatch } from '../../../store';
import { disableLinkAccess, enableLinkAccess } from '../../../store/slices/sharing';
import Modal, { ModalProps } from '../modal/Modal';
import ModalBody from '../modal/ModalBody';
import ModalHead from '../modal/ModalHead';
import TippyTooltip from '../tippy-tooltip/TippyTooltip';
import InviteUser from './InviteUser';
import ShareModalEmailListItem from './ShareModalEmailListItem';

const TOOLTIP_TIMEOUT = 3000;
let timeout = 0;

const BASE_LINK_PROJECT = `${window.location.origin}/shared/projects`;
const BASE_LINK_SITE = `${window.location.origin}/shared/sites`;
type AccessOptions = (AccessInfoAccessTypeEnum | undefined)[] | undefined;

type Props = ModalProps & { project: ProjectInfo };

function ShareModal({ isOpen, setIsOpen, project }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('modals');
    const [linkCopied, setLinkCopied] = useState(false);

    const currentAccessTypes = project.accesses
        ?.filter(accessItem => accessItem.projectRole !== AccessInfoProjectRoleEnum.NO_ACCESS)
        .map(accessItem => accessItem.accessType);
    const [accessOption, setAccessOption] = useState<AccessOptions>(currentAccessTypes || []);
    const accessKey = project.accesses?.find(
        accessItem => accessItem.projectRole !== AccessInfoProjectRoleEnum.NO_ACCESS && accessItem.accessType === 'LINK'
    )?.accessKey;
    const isAccessByLinkActive = accessOption?.includes(AccessInfoAccessTypeEnum.LINK) && accessKey !== undefined;

    const emails = project.accesses
        ?.filter(accessItem => accessItem.accessType === 'EMAIL' && accessItem.email !== undefined)
        .map(accessItem => accessItem.email || '');

    const handleCopyMessage = () => {
        if (linkCopied) {
            clearTimeout(timeout);
            setLinkCopied(false);
        }
        navigator.clipboard.writeText(linkValue);
        setLinkCopied(true);
        timeout = window.setTimeout(() => {
            setLinkCopied(false);
        }, TOOLTIP_TIMEOUT);
    };

    useEffect(() => {
        return () => clearTimeout(timeout);
    }, []);

    const hasNoSharedEmails = (emails?.length || 0) === 0;

    const [isUsersListVisible, setIsUsersListVisible] = useState(false);

    const linkValue = `${project.type === ProjectType.SITE ? BASE_LINK_SITE : BASE_LINK_PROJECT}/${accessKey}`;

    const switchShareByLink = (isAccessByLinkActive: boolean | undefined) => {
        if (isAccessByLinkActive) {
            setAccessOption(accessOption?.filter(item => item !== AccessInfoAccessTypeEnum.LINK));
            dispatch(
                disableLinkAccess({
                    projectId: project.id!,
                    currentAccesses: project.accesses
                })
            );
        } else {
            setAccessOption(accessOption?.concat(AccessInfoAccessTypeEnum.LINK));
            dispatch(
                enableLinkAccess({
                    projectId: project.id!,
                    currentAccesses: project.accesses
                })
            );
        }
    };

    const switchUsersListVisibility = (numberOfItems: number) => {
        if (numberOfItems > 0) {
            setIsUsersListVisible(prev => !prev);
        }
    };

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} useShareModalLayout={true}>
            <ModalHead setIsOpen={setIsOpen}>{t('shareModal.head')}</ModalHead>
            <ModalBody>
                <div className='share-title'>
                    {project.type === ProjectType.SITE ? t('shareModal.bodySite') : t('shareModal.bodyProject')}{' '}
                    <b>{project.name}</b>
                </div>

                <div className='share-by-link'>
                    <div className='title-wrapper'>
                        <span>{t('shareModal.byLink')}</span>
                        <label className='switch-wrapper'>
                            <input
                                type='checkbox'
                                checked={isAccessByLinkActive}
                                onChange={() => switchShareByLink(isAccessByLinkActive)}
                            />
                            <span className='switch-styler' />
                        </label>
                    </div>
                    <div className='tip'>{t('shareModal.tipByLink')}</div>
                    <form className='form-wrapper'>
                        <input
                            type='text'
                            value={isAccessByLinkActive ? linkValue : ''}
                            readOnly
                            placeholder={linkValue}
                            disabled={!isAccessByLinkActive}
                            data-testid='linkText'
                        />
                        <TippyTooltip
                            tooltipText={t('shareModal.tooltipLinkCopied')}
                            visible={linkCopied}
                            placement={'left'}
                            offset={[0, 6]}
                            touch={true}
                        >
                            <button
                                className='btn-ghost-blue form-btn'
                                type='button'
                                disabled={!isAccessByLinkActive}
                                onClick={handleCopyMessage}
                                data-testid='linkCopyBtn'
                            >
                                {t('shareModal.copy')}
                            </button>
                        </TippyTooltip>
                    </form>
                </div>

                <div className='share-invited'>
                    <div className='title-wrapper'>
                        <span>{t('shareModal.withInvitedPeople')}</span>
                    </div>
                    <div className='tip'>{t('shareModal.tipWithInvitedPeople')}</div>
                    <InviteUser isDisabled={false} project={project} />
                </div>

                {emails !== undefined && emails?.length > 0 && (
                    <>
                        <div className='users-list-head'>
                            <div
                                className='users-list-control'
                                onClick={() => {
                                    switchUsersListVisibility(emails?.length || 0);
                                }}
                            >
                                <span className={classNames('users-list-arrow ', { expanded: isUsersListVisible })} />
                                <span className='users-list-title'>
                                    {t('shareModal.invitedPeople', { invitedCount: emails?.length || 0 })}
                                </span>
                            </div>
                        </div>
                        <div className={classNames('users-list-wrapper', { visible: isUsersListVisible })}>
                            <SimpleBar
                                className={classNames('users-list', { disabled: hasNoSharedEmails })}
                                autoHide={false}
                            >
                                {emails?.map(e => (
                                    <ShareModalEmailListItem
                                        key={e}
                                        email={e}
                                        projectId={project.id!}
                                        currentAccesses={project.accesses}
                                    />
                                ))}
                            </SimpleBar>
                        </div>
                    </>
                )}
            </ModalBody>
        </Modal>
    );
}

export default memo(ShareModal);
