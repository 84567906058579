import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Dataset } from '../../../generated/cloud-frontend-api';
import { AppDispatch, useSelector } from '../../../store';
import RelationsItem from './RelationsItem';
import PropertiesBlockHead from '../geometry-properties/elements/PropertiesBlockHead';
import {
    selectPropertiesBlockExpansion,
    updateStructureInfo,
    expandedPropertiesBlockNames
} from '../../../store/slices/structure';
import { ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';

type Props = {
    dataset: Dataset;
};

const expandedPropertyName: (typeof expandedPropertiesBlockNames)[number] = 'relationsBlockExpanded';

export default function Relations({ dataset }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const isRelationsBlockExpanded = useSelector(state =>
        selectPropertiesBlockExpansion(state, dataset.datasetUid!, expandedPropertyName)
    );

    return (
        <div className='inspector-properties'>
            <PropertiesBlockHead
                isBlockExpanded={isRelationsBlockExpanded}
                title={t('projectView:inspectionSidebar.relations.title')}
                onClick={() => {
                    dispatch(
                        updateStructureInfo({
                            projectId: projectInfo.id!,
                            structureUid: dataset.datasetUid!,
                            type: ProjectStructureObjectTypes.DATASET,
                            propName: expandedPropertyName,
                            propValue: isRelationsBlockExpanded ? String(false) : String(true)
                        })
                    );
                }}
            />
            {isRelationsBlockExpanded && (
                <div className='properties-list'>
                    {dataset.linkedProjects!.map(({ uid, name }) => (
                        <RelationsItem
                            key={uid}
                            projectId={projectInfo.id!}
                            linkedProjectId={uid!}
                            linkedProjectName={name!}
                            datasetInfo={dataset}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}
