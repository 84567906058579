import { SourceType } from '../../../generated/cloud-frontend-api';
import getFilenameExtension from '../../../lib/getFilenameExtension';
import { WGS84_EPSG_CODE } from '../../../sharedConstants';
import { ZipFileValidator } from './ZipFileValidator';
import { DatasetFileValidationResult } from './DatasetFileValidator';
import { GeotiffFileReader } from './GeotiffFileReader';

const POINT_CLOUD_FILE_EXTENSIONS = ['obj', 'ply', 'las', 'laz', 'e57', 'pts', 'pts.gz', 'ptx', 'pcd'];
const GEOTIFF_FILE_EXTENSIONS = ['tif', 'tiff', 'geotiff'];
export const CESIUM3DTILES_EXTENSIONS = ['zip', '3tz'];
export const VECTOR_LAYERS_EXTENSIONS = ['dxf', 'dgn', 'geojson', 'gml', 'gpkg', 'shp', 'zip'];
const ALL_EXTENSIONS = [
    ...POINT_CLOUD_FILE_EXTENSIONS,
    ...GEOTIFF_FILE_EXTENSIONS,
    ...CESIUM3DTILES_EXTENSIONS,
    ...VECTOR_LAYERS_EXTENSIONS
];
const RECOGNIZED_EXTENSIONS = new Set(ALL_EXTENSIONS);

export class DatasetFilesValidator {
    constructor(private readonly files: { file: File; id: string }[]) {}

    async validate(): Promise<(DatasetFileValidationResult & { id: string })[]> {
        return await Promise.all(
            this.files.map(async ({ file, id }) => {
                if (this.isZip(file)) {
                    const validator = new ZipFileValidator(file);
                    return { ...(await validator.validate()), id, data: { epsgCode: WGS84_EPSG_CODE } };
                }

                if (GEOTIFF_FILE_EXTENSIONS.includes(getFilenameExtension(file.name).toLowerCase())) {
                    try {
                        const validator = await GeotiffFileReader.build(file);
                        return { ...(await validator.validate()), id, data: { epsgCode: validator.extractCrsCode() } };
                    } catch (err) {
                        return { isValid: false, sourceType: undefined, file, id };
                    }
                }

                const sourceType = recognizeSourceType(file);
                return Promise.resolve({ sourceType, isValid: true, file, id });
            })
        );
    }

    private isZip(file: File): boolean {
        return (
            [
                'application/zip',
                'application/zip-compressed',
                'application/x-zip-compressed',
                'application/x-zip',
                'multipart/x-zip'
            ].includes(file.type) || getFilenameExtension(file.name).toLowerCase() === '3tz'
        );
    }
}

export function recognizeSourceType(file: File): SourceType | undefined {
    if (hasAmbiguousExtension(file.name)) return undefined;

    if (POINT_CLOUD_FILE_EXTENSIONS.includes(getFilenameExtension(file.name).toLowerCase()))
        return SourceType.POINT_CLOUD;

    if (VECTOR_LAYERS_EXTENSIONS.includes(getFilenameExtension(file.name).toLowerCase())) return SourceType.GEOJSON;
}

export function isValidFormat(file: File): boolean {
    return RECOGNIZED_EXTENSIONS.has(getFilenameExtension(file.name).toLowerCase());
}

function hasAmbiguousExtension(fileName: string): boolean {
    return ALL_EXTENSIONS.filter(ext => ext === getFilenameExtension(fileName).toLowerCase()).length > 1;
}
