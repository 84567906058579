/* tslint:disable */
/* eslint-disable */
/**
 * billing-api
 * Billing API allows to get info about products and purchase them
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface InvoiceInfo
 */
export interface InvoiceInfo {
    /**
     * 
     * @type {string}
     * @memberof InvoiceInfo
     */
    'invoiceUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceInfo
     */
    'total'?: number;
    /**
     * Date of payment invoice
     * @type {string}
     * @memberof InvoiceInfo
     */
    'paymentDate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceInfo
     */
    'items'?: Array<InvoiceInfoItemsEnum>;
    /**
     * 
     * @type {string}
     * @memberof InvoiceInfo
     */
    'status'?: InvoiceInfoStatusEnum;
}

export const InvoiceInfoItemsEnum = {
    PROCESSING_TIME: 'PROCESSING_TIME',
    STORAGE: 'STORAGE'
} as const;

export type InvoiceInfoItemsEnum = typeof InvoiceInfoItemsEnum[keyof typeof InvoiceInfoItemsEnum];
export const InvoiceInfoStatusEnum = {
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED',
    EXPIRED: 'EXPIRED'
} as const;

export type InvoiceInfoStatusEnum = typeof InvoiceInfoStatusEnum[keyof typeof InvoiceInfoStatusEnum];


