import { Dataset } from '../generated/cloud-frontend-api';
import { SupportedGeometryNamesFontSizes } from '../sharedConstants';
import { TemporaryLayer } from '../store/helpers/interfaces';
import { getRandomColorHex } from './getRandomColor';

export function mapDatasetToLayer(layerDataset: Dataset): TemporaryLayer {
    const isPresentation = layerDataset.properties?.isPresentation === 'true';
    const isInspection = layerDataset.properties?.isInspection === 'true';
    const newColor = getRandomColorHex();

    return {
        id: layerDataset.datasetUid!,
        assetUid: layerDataset.datasetUid!,
        name: layerDataset.properties?.name || '',
        isTemporary: false,
        isPresentation,
        isInspection,
        strokeColor: layerDataset.properties?.strokeColor || layerDataset.properties?.color || newColor,
        geometriesNamesFont: layerDataset.properties?.geometriesNamesFont as SupportedGeometryNamesFontSizes,
        parentProject: layerDataset.parentProject,
        showGeometriesNames: layerDataset.properties?.showGeometriesNames === 'true',
        color: layerDataset.properties?.color || newColor,
        geometries: [],
        sizeInBytes: layerDataset.visualData?.sizeInBytes || 0,
        linkedProjects: layerDataset.linkedProjects,
        coordinateSystemUid: layerDataset.coordinateSystemUid
    };
}
