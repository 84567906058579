import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { isCameraMasterOrNotMultispectral } from '../../components/ProjectView/frustums/Frustums';
import { TreeNode } from '../../components/project-structure-sidebar/structure-tree/StructureTree';
import { CompareStructureTreeContextValue } from '../../contexts/CompareStructureTreeContext';
import { ProjectInfo, Status } from '../../generated/cloud-frontend-api';
import { ProjectPartType } from '../../generated/dataset-api';
import { StructureInfo } from '../../generated/project-structure-api';
import getArtifactDisplayName from '../../lib/getArtifactDisplayName';
import getFilename from '../../lib/getFilename';
import { GeometryTypes, isGeoJsonFeatureSupported } from '../../sharedConstants';
import { createSetterReducer } from '../helpers';
import { ExtendedChunk, ProjectStructureObjectTypes, TemporaryGeometry, TemporaryLayer } from '../helpers/interfaces';
import { ApplicationState } from '../index';
import { getDisplayableChunkDatasets } from '../selectors';
import { ExtendedCamera } from './cameras';
import { compareToolState, selectComparedProjectFilesTree } from './compareTool';
import { ExtendedDatasetInfo, uploadDatasetFile } from './datasetfilesUpload';
import { getDatasetStatus, getGroupDatasets, getGroupedDatasets } from './datasets';
import { VectorLayerUploadInfo, adaptVectorLayerToDataset, getSortedDatasets } from './datasetsUpload';
import { selectGeometries } from './geometries';
import { ExtendedStructureInfo, isObjectExpanded, isStructureInfoGroup, sortItemsByStructureInfo } from './structure';
import i18n from '../../i18n/config';

interface ProjectStructureState {
    uploadsCompleteMessageVisible: boolean;
    upcomingStructureChangesMessageVisible: boolean;
    manualConfigurationForUploadRequiredMessageVisible: boolean;
}

const initialState: ProjectStructureState = {
    uploadsCompleteMessageVisible: false,
    upcomingStructureChangesMessageVisible: false,
    manualConfigurationForUploadRequiredMessageVisible: false
};

const name = 'projectStructure';

const setterReducer = createSetterReducer<ProjectStructureState>();
const slice = createSlice({
    name,
    initialState,
    reducers: {
        setUploadsCompleteMessageVisible: setterReducer('uploadsCompleteMessageVisible'),
        setUpcomingStructureChangesMessageVisible: setterReducer('upcomingStructureChangesMessageVisible'),
        setManualConfigurationForUploadRequiredMessageVisible: setterReducer(
            'manualConfigurationForUploadRequiredMessageVisible'
        ),
        resetStructureState() {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder.addCase(uploadDatasetFile.pending, (state, { payload }) => {
            state.uploadsCompleteMessageVisible = false;
        });
    }
});

export default slice.reducer;

export const {
    resetStructureState,
    setUploadsCompleteMessageVisible,
    setUpcomingStructureChangesMessageVisible,
    setManualConfigurationForUploadRequiredMessageVisible
} = slice.actions;

const selectStructureTree = createSelector(
    (state: ApplicationState) => state.project.structure.temporaryLayers,
    (state: ApplicationState) => state.project.structure.chunks,
    (state: ApplicationState) => state.datasets.datasets,
    (state: ApplicationState) => state.structure.structures,
    (state: ApplicationState) => state.datasetsUpload.uploads,
    (state: ApplicationState) => state.project.projectInfo,
    (state: ApplicationState) => state.projectView.isCompareToolEnabled,
    (state: ApplicationState) => selectGeometries(state),
    (state: ApplicationState) => state.cameras,
    (state: ApplicationState, treeId?: CompareStructureTreeContextValue['treeId']) =>
        treeId ? compareToolState.selectors[treeId].selectAll(state) : null,
    (
        temporaryLayers,
        chunks,
        datasets,
        structures,
        vectorLayerUploads,
        projectInfo,
        isCompareToolEnabled,
        geometries,
        cameras,
        compareToolStructures
    ) => {
        return getStructureTree(
            temporaryLayers,
            chunks,
            datasets,
            structures,
            vectorLayerUploads,
            projectInfo,
            isCompareToolEnabled,
            geometries,
            cameras,
            compareToolStructures
        );
    }
);

export function getStructureTree(
    temporaryLayers: TemporaryLayer[],
    chunks: ExtendedChunk[],
    datasets: ExtendedDatasetInfo[],
    structures: ExtendedStructureInfo[],
    vectorLayerUploads: Record<string, VectorLayerUploadInfo>,
    projectInfo: ProjectInfo,
    isCompareToolEnabled: boolean,
    geometries: Record<string, TemporaryGeometry<GeometryTypes>>,
    cameras: Record<string, ExtendedCamera[]>,
    compareToolStructures: Required<StructureInfo>[] | null
) {
    const actualStructures = compareToolStructures || structures;
    const groups = actualStructures.filter(isStructureInfoGroup);
    const notArtifacts = getSortedDatasets(
        temporaryLayers,
        vectorLayerUploads,
        datasets,
        actualStructures,
        projectInfo.id!,
        chunks
    ).filter(d => (isCompareToolEnabled ? getDatasetStatus(d) === Status.COMPLETED : true));
    const artifacts = datasets.filter(d => d.projectPartType === ProjectPartType.TILESETS);
    const groupedDatasets = getGroupedDatasets(notArtifacts, actualStructures);
    const ungroupedDatasets = _.difference(notArtifacts, groupedDatasets);

    const groupNodes: TreeNode[] = groups.map(g => ({
        id: g.uid!,
        name: g.properties?.name || '',
        nestingLevel: 0,
        type: ProjectStructureObjectTypes.GROUP,
        parentId: null,
        isOpenByDefault: isObjectExpanded(actualStructures, g.uid!),
        children: sortTreeNodes(
            getGroupDatasets(notArtifacts, actualStructures, g.uid!).map(d => getDatasetNode(d, g.uid, 1)),
            actualStructures
        )
    }));

    const datasetNodes: TreeNode[] = ungroupedDatasets.map(d => getDatasetNode(d));

    const chunkNodes: TreeNode[] = chunks.map(chunk => ({
        id: chunk.assetUid!,
        name: chunk.chunkName!,
        type: ProjectStructureObjectTypes.CHUNK,
        parentId: null,
        nestingLevel: 0,
        isOpenByDefault: isObjectExpanded(actualStructures, chunk.assetUid!),
        chunk,
        children: _.compact([
            chunk.cameras?.artifactUid && chunk.cameras?.count! > 0
                ? {
                      id: chunk.cameras?.artifactUid,
                      name: i18n.t('glossary:sourceTypes.images'),
                      nestingLevel: 1,
                      parentId: chunk.assetUid!,
                      type: ProjectStructureObjectTypes.CAMERAS,
                      isOpenByDefault: isObjectExpanded(actualStructures, chunk.cameras?.artifactUid!),
                      chunk,
                      children:
                          cameras[chunk.cameras.artifactUid]
                              ?.filter(c => isCameraMasterOrNotMultispectral(c))
                              .map(camera => ({
                                  id: camera.uid,
                                  name: camera.label,
                                  nestingLevel: 2,
                                  isOpenByDefault: false,
                                  type: ProjectStructureObjectTypes.IMAGE,
                                  parentId: chunk.cameras?.artifactUid!,
                                  children: []
                              })) || []
                  }
                : null,
            ...getDisplayableChunkDatasets(chunks, artifacts, chunk.assetUid!).map(d => ({
                id: d.datasetUid!,
                parentId: chunk.assetUid!,
                nestingLevel: 1,
                name: getArtifactDisplayName(d),
                type: ProjectStructureObjectTypes.ARTIFACT,
                artifact: d,
                sourceType: d.sourceData?.type!,
                isOpenByDefault: false,
                chunk,
                status: getDatasetStatus(d),
                children: []
            }))
        ])
    }));

    return sortTreeNodes([...groupNodes, ...datasetNodes, ...chunkNodes], actualStructures);

    function getDatasetNode(d: ExtendedDatasetInfo, parentId: string | null = null, nestingLevel = 0) {
        const layer = temporaryLayers.find(l => l.id === d.datasetUid);
        let name = d.name ?? '';
        if (d.projectPartType === ProjectPartType.DATASETS && d.name) {
            name = getFilename(d.name);
        }
        if (d.projectPartType === ProjectPartType.TILESETS) {
            name = getArtifactDisplayName(d);
        }

        return {
            id: d.datasetUid!,
            parentId,
            nestingLevel,
            sourceType: d.sourceData?.type,
            status:
                d.projectPartType === ProjectPartType.DATASETS || d.projectPartType === ProjectPartType.TILESETS
                    ? getDatasetStatus(d)
                    : getDatasetStatus(adaptVectorLayerToDataset(layer!, vectorLayerUploads[layer?.id!])),
            children:
                d.projectPartType === ProjectPartType.DATASETS
                    ? []
                    : (layer?.geometries || [])
                          .map(id => geometries[id]!)
                          .filter(g => isGeoJsonFeatureSupported(g.content))
                          .map(g => ({
                              id: g.id,
                              nestingLevel: nestingLevel + 1,
                              name: g.content.properties.ac_name,
                              parentId: layer?.id!,
                              isOpenByDefault: false,
                              type: ProjectStructureObjectTypes.GEOMETRY,
                              children: []
                          })),
            isOpenByDefault: isObjectExpanded(actualStructures, layer?.id!),
            name,
            type:
                d.projectPartType === ProjectPartType.VECTOR_LAYERS
                    ? ProjectStructureObjectTypes.LAYER
                    : ProjectStructureObjectTypes.DATASET
        };
    }
}

export function sortTreeNodes<T extends { id: string }>(nodes: T[], structures: StructureInfo[]): T[] {
    return sortItemsByStructureInfo(nodes, structures);
}

export const selectFlatTree = createSelector(selectStructureTree, nodes => flattenTree(nodes));
export const selectComparedFlatTree = createSelector(
    (state: ApplicationState, treeId?: CompareStructureTreeContextValue['treeId']) =>
        selectComparedProjectFilesTree(state, treeId),
    nodes => flattenTree(nodes)
);

function flattenTree(nodes: TreeNode[]): TreeNode[] {
    return nodes.flatMap(node => [node, ...flattenTree(node.children)]);
}

export const selectStructureTreeByTreeId = createSelector(
    (state: ApplicationState) => state.compareTool.mode,
    selectStructureTree,
    (state: ApplicationState, treeId?: CompareStructureTreeContextValue['treeId']) =>
        selectComparedProjectFilesTree(state, treeId),
    (state: ApplicationState, treeId?: CompareStructureTreeContextValue['treeId']) => treeId,
    (compareToolMode, projectFiles, comparedProjectFiles, treeId) => {
        if (compareToolMode === 'compareSiteProjects') {
            if (!treeId || treeId === 'tree1') return projectFiles;
            return comparedProjectFiles;
        }
        return projectFiles;
    }
);
