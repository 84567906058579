import { useSelector } from '../store';
import { useEffect } from 'react';
import { useCesium } from 'resium';
import { DRAWING_CURSOR } from '../sharedConstants';
import { selectIsCreateViewpointControlEnabled } from '../store/slices/presentation';

// May pass special canvas, currently used only in tests
export default function useDrawingModeCursor(specialCanvas?: HTMLCanvasElement): void {
    const { viewer } = useCesium();
    const selectedGeometryType = useSelector(state => state.projectView.selectedGeometryType);
    const isCamerasInspectionEnabled = useSelector(state => state.projectView.isCamerasInspectionEnabled);
    const isCreateViewpointControlEnabled = useSelector(state => selectIsCreateViewpointControlEnabled(state));
    const currentlyDrawingShapeId = useSelector(state => state.projectView.currentlyDrawingShapeId);
    const isRulerToolEnabled = useSelector(state => state.projectView.isRulerToolEnabled);

    const canvas = specialCanvas || viewer?.canvas;

    useEffect(() => {
        if (canvas)
            if (
                selectedGeometryType ||
                currentlyDrawingShapeId ||
                isCamerasInspectionEnabled ||
                isCreateViewpointControlEnabled ||
                isRulerToolEnabled
            )
                canvas.style.cursor = DRAWING_CURSOR;
            else canvas.style.cursor = 'default';
    }, [
        selectedGeometryType,
        isCamerasInspectionEnabled,
        isCreateViewpointControlEnabled,
        currentlyDrawingShapeId,
        isRulerToolEnabled,
        canvas
    ]);
}
