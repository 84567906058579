import _ from 'lodash';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { Issue } from '../../../entities/Issue';
import { GeometryTypes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { TemporaryGeometry } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { isGeometryPropertiesBlockExpanded } from '../../../store/slices/geometries';
import { updateGeometryPropertyById } from '../../../store/slices/geometryLayers';
import CopyPropertiesControl from '../../ProjectView/geometry-properties/elements/CopyPropertiesControl';
import PropertiesBlockHead from '../../ProjectView/geometry-properties/elements/PropertiesBlockHead';
import SelectProperty from '../../ProjectView/geometry-properties/SelectProperty';
import TextareaProperty from '../../ProjectView/geometry-properties/TextareaProperty';
import IssueReadOnlyProperty from './IssueReadOnlyProperty';

type Props = {
    viewOnly: boolean;
    containerRef: HTMLDivElement;
};

export default function IssueGeneralProperties({ viewOnly, containerRef }: Props) {
    const { t } = useTranslation(['projectView']);
    const dispatch: AppDispatch = useDispatch();
    const { owned } = useContext(ProjectViewAccessContext);
    const issue = useSelector(state => selectedObjectInfoSelector(state)) as TemporaryGeometry<GeometryTypes.POINT>;
    const idOfGeometryWithSavingPreview = useSelector(state => state.projectView.idOfGeometryWithSavingPreview);
    const severity = issue.content.properties.ac_severity!;
    const type = issue.content.properties.ac_type!;
    const description = issue.content.properties.ac_description || '';
    const generalPropertiesBlockExpanded = isGeometryPropertiesBlockExpanded(
        issue.content,
        'ac_general_block_expanded'
    );

    const isIssuePreviewBeingSaved = idOfGeometryWithSavingPreview === issue.id;

    const textForCopying = `${t('projectView:issueProperties.severity')}\t${severity}\n${t(
        'projectView:issueProperties.type'
    )}\t${type}\n${t('projectView:issueProperties.description')}\t${description}`;

    return (
        <div className='inspector-properties'>
            <PropertiesBlockHead
                isBlockExpanded={generalPropertiesBlockExpanded}
                title={t('projectView:inspectionSidebar.geometryProperties.titleGeneral')}
                onClick={e => {
                    dispatch(
                        updateGeometryPropertyById({
                            id: issue.id,
                            owned,
                            propName: 'ac_general_block_expanded',
                            propValue: !generalPropertiesBlockExpanded
                        })
                    );
                }}
            >
                <CopyPropertiesControl clipboardText={textForCopying} />
            </PropertiesBlockHead>
            {generalPropertiesBlockExpanded && (
                <div className='properties-list'>
                    {viewOnly || isIssuePreviewBeingSaved ? (
                        <>
                            <IssueReadOnlyProperty
                                label={t('projectView:issueProperties.severity')}
                                field='severity'
                                severity={severity}
                            />
                            <IssueReadOnlyProperty
                                label={t('projectView:issueProperties.type')}
                                field='type'
                                type={type}
                            />
                            <IssueReadOnlyProperty
                                label={t('projectView:issueProperties.description')}
                                field='description'
                                description={description}
                            />
                        </>
                    ) : (
                        <>
                            <SelectProperty
                                disabled={viewOnly}
                                label={t('projectView:issueProperties.severity')}
                                value={String(severity)}
                                onChange={newValue => {
                                    dispatch(
                                        updateGeometryPropertyById({
                                            id: issue.id,
                                            owned,
                                            propName: 'ac_severity',
                                            propValue: Number(newValue)
                                        })
                                    );
                                }}
                                containerRef={containerRef}
                                options={_.map(Issue.Severities(), (value, key) => {
                                    return {
                                        value: key,
                                        label: (
                                            <div className='ant-bullet-wrapper'>
                                                <div
                                                    className='ant-bullet'
                                                    style={{
                                                        backgroundColor:
                                                            Issue.SeverityColors[
                                                                Number(key) as keyof ReturnType<typeof Issue.Severities>
                                                            ]
                                                    }}
                                                />
                                                <div>{value}</div>
                                            </div>
                                        )
                                    };
                                })}
                            />
                            <SelectProperty
                                disabled={viewOnly}
                                label={t('projectView:issueProperties.type')}
                                value={type}
                                onChange={newValue => {
                                    dispatch(
                                        updateGeometryPropertyById({
                                            id: issue.id,
                                            owned,
                                            propName: 'ac_type',
                                            propValue: newValue
                                        })
                                    );
                                }}
                                containerRef={containerRef}
                                options={_.map(Issue.TypesDisplayNames(), (value, key) => {
                                    return {
                                        value: key,
                                        label: value
                                    };
                                })}
                            />
                            <TextareaProperty
                                label={t('projectView:issueProperties.description')}
                                value={description}
                                readOnly={viewOnly}
                                onSubmit={values => {
                                    dispatch(
                                        updateGeometryPropertyById({
                                            id: issue.id,
                                            owned,
                                            propName: 'ac_description',
                                            propValue: values.value
                                        })
                                    );
                                }}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
