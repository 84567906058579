import * as Cesium from 'cesium';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { CesiumComponentRef, EllipseGraphics, Entity, useCesium } from 'resium';
import { AppDispatch, useSelector } from '../../../store';
import CesiumMultiTerrainProvider from '../terrain/CesiumMultiTerrainProvider';
import { AppResource } from './ResourceProvider';
import { Dataset } from '../../../entities/Dataset';
import { useDispatch } from 'react-redux';
import { setTilesetBoundingSphere } from '../../../store/slices/projectView';

type Props = {
    resource: AppResource;
    show: boolean;
    setNeedsToRecreateTerrainProvider(needs: boolean): void;
    setNeedsToUpdateTerrainMaterialOpacity(needs: boolean): void;
};

const DemServiceVisualization = forwardRef<CesiumComponentRef<Cesium.Entity>, Props>(
    ({ resource, show, setNeedsToRecreateTerrainProvider, setNeedsToUpdateTerrainMaterialOpacity }, ref) => {
        const dispatch: AppDispatch = useDispatch();
        const { globe } = useCesium();
        const datasets = useSelector(state => state.datasets.datasets);
        const structures = useSelector(state => state.structure.structures);
        const dataset = datasets.find(d => d.visualData?.dataUid === resource.tilesetId);
        const structureInfo = structures.find(s => s.uid === dataset?.datasetUid);
        const opacity = structureInfo?.properties.opacity
            ? Number(structureInfo?.properties.opacity)
            : Dataset.defaultOpacity(dataset?.sourceData?.type!);
        const [isLayerVisible, setLayerVisible] = useState(show);
        const [currentOpacity, setCurrentOpacity] = useState(opacity);

        const terrainProvider =
            globe?.terrainProvider instanceof CesiumMultiTerrainProvider
                ? (globe?.terrainProvider as unknown as CesiumMultiTerrainProvider)
                : undefined;
        const layer = terrainProvider?.getLayer(resource.url);

        const boundingSphere = useMemo(() => {
            const orientedBoundingBox = layer?.boundingBox;
            if (orientedBoundingBox) {
                return Cesium.BoundingSphere.fromOrientedBoundingBox(orientedBoundingBox);
            }
        }, [layer]);

        useEffect(() => {
            if (boundingSphere) {
                dispatch(setTilesetBoundingSphere({ tilesetId: resource.tilesetId, bs: boundingSphere }));
            }
        }, [boundingSphere, resource, dispatch]);

        useEffect(() => {
            if (isLayerVisible !== show) {
                setLayerVisible(show);
                setNeedsToRecreateTerrainProvider(true);
            }
        }, [show, setNeedsToRecreateTerrainProvider, isLayerVisible, setLayerVisible]);

        useEffect(() => {
            if (opacity !== currentOpacity) {
                setCurrentOpacity(opacity);
                setNeedsToUpdateTerrainMaterialOpacity(true);
            }
        }, [opacity, setNeedsToUpdateTerrainMaterialOpacity, currentOpacity, setCurrentOpacity]);

        return (
            <Entity show={false} ref={ref} position={boundingSphere?.center}>
                <EllipseGraphics
                    show={true}
                    semiMajorAxis={boundingSphere?.radius}
                    semiMinorAxis={boundingSphere?.radius}
                />
            </Entity>
        );
    }
);

export default DemServiceVisualization;
