import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useKey } from 'rooks';
import ProjectViewAccessContext from '../../contexts/ProjectViewAccessContext';
import useAdaptableWidthSidebar from '../../hooks/useAdaptableWidthSidebar';
import useProjectStructureObjectsActions from '../../hooks/useProjectStructureObjectsActions';
import useSiteViewQueryParams from '../../hooks/useSiteViewQueryParams';
import {
    GeometryTypes,
    MAP_PANEL_CONTROL_WIDTH,
    MAP_PANEL_DEFAULT_WIDTH,
    Routes,
    WEBGL_SUPPORTED
} from '../../sharedConstants';
import { AppDispatch, ApplicationState, useSelector } from '../../store';
import { ProjectStructureObjectTypes, TemporaryGeometry, TemporaryLayer } from '../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../store/selectors';
import { createLoadingSelector } from '../../store/selectors/createLoadingSelector';
import { getComparedProject } from '../../store/slices/compareTool';
import { selectGeometries } from '../../store/slices/geometries';
import { getProjectById } from '../../store/slices/project';
import { setAnimatedInspectorVisibility, setSidebarVisibility } from '../../store/slices/projectView';
import { getAccess, getAccessInfo } from '../../store/slices/sharing';
import { getSite, makeSelectSiteProjectById, selectFirstSiteProject } from '../../store/slices/site';
import { selectGroups } from '../../store/slices/structure';
import TippyTooltip from '../Elements/tippy-tooltip/TippyTooltip';
import formatSurveyDate from '../ProjectView/breadcrumbs/helpers/formatSurveyDate';
import SkeletonArtifact from './SkeletonArtifact';
import CompareSiteToolSidebar from './compare-tool/CompareSiteToolSidebar';
import CompareToolSidebar from './compare-tool/CompareToolSidebar';
import ProjectStructureHelper from './structure-helper/ProjectStructureHelper';
import StructureTree from './structure-tree/StructureTree';
import WorkspaceControls from './workspace-controls/WorkspaceControls';

type Props = {
    projectName: string;
    wrapperWidth: number;
    sidebarsToggleTransition: string | undefined;
};

const TWO_PANELS_MIN_WIDTH = (MAP_PANEL_DEFAULT_WIDTH + MAP_PANEL_CONTROL_WIDTH) * 2;

const loadingSelector = createLoadingSelector([
    getProjectById.typePrefix,
    getAccess.typePrefix,
    getAccessInfo.typePrefix,
    getSite.typePrefix
]);
const selectCompareToolLoading = createLoadingSelector([
    getProjectById.typePrefix,
    getAccess.typePrefix,
    getAccessInfo.typePrefix,
    getComparedProject.typePrefix
]);

export default function ProjectStructureSidebar({ projectName, wrapperWidth, sidebarsToggleTransition }: Props) {
    const { t } = useTranslation('projectView');
    const dispatch: AppDispatch = useDispatch();
    const loading = useSelector(state => loadingSelector(state));
    const selectedObject = useSelector(state => state.project.selectedObject);
    const chunks = useSelector(state => state.project.structure.chunks);
    const geometries = useSelector(state => selectGeometries(state));
    const temporaryLayers = useSelector(state => state.project.structure.temporaryLayers);
    const datasets = useSelector(state => state.datasets.datasets);
    const groups = useSelector(state => selectGroups(state));
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));
    const isCesiumLoaded = useSelector(state => state.projectView.isCesiumLoaded);
    const isSidebarExpanded = useSelector(state => state.projectView.isSidebarExpanded);
    const isInspectorExpanded = useSelector(state => state.projectView.isInspectorExpanded);
    const isPresentationSetupEnabled = useSelector(state => state.projectView.isPresentationSetupEnabled);
    const currentlyDrawingShapeId = useSelector(state => state.projectView.currentlyDrawingShapeId);
    const selectedGeometryNodeId = useSelector(state => state.projectView.selectedGeometryNodeId);
    const compareToolIsLoading = useSelector(state => selectCompareToolLoading(state));
    const { queryProjectId } = useSiteViewQueryParams();
    const selectSiteProjectById = useMemo(makeSelectSiteProjectById, []);
    const siteProject = useSelector(state => selectSiteProjectById(state, queryProjectId || ''));
    const firstSiteProject = useSelector(state => selectFirstSiteProject(state));
    const activeSiteProject = siteProject || firstSiteProject;
    const isCompareToolEnabled = useSelector((state: ApplicationState) => state.projectView.isCompareToolEnabled);
    const { owned, isInEmbedView } = useContext(ProjectViewAccessContext);

    useAdaptableWidthSidebar('--sidebar-width', wrapperWidth);

    const isLoading = !WEBGL_SUPPORTED ? loading : !isCesiumLoaded || (isCompareToolEnabled && compareToolIsLoading);

    const isTogglerVisible =
        wrapperWidth > MAP_PANEL_DEFAULT_WIDTH + MAP_PANEL_CONTROL_WIDTH * 2 ||
        (wrapperWidth <= MAP_PANEL_DEFAULT_WIDTH + MAP_PANEL_CONTROL_WIDTH * 2 && !isInspectorExpanded);

    const { deleteGeometry, deleteLayer } = useProjectStructureObjectsActions();

    const hasAnythingToRenderInTree = Boolean(
        chunks.length || temporaryLayers.length || datasets.length || groups.length
    );

    const cantDeleteLayer =
        selectedObject.type === ProjectStructureObjectTypes.LAYER &&
        (selectedObjectInfo as TemporaryLayer)?.geometries.includes(currentlyDrawingShapeId || '') &&
        (selectedObjectInfo as TemporaryLayer)?.geometries
            .map(id => geometries[id!])
            .find(g => g?.id === currentlyDrawingShapeId)?.content.geometry.type !== GeometryTypes.POINT;

    const canDeleteGeometry =
        selectedObject.type === ProjectStructureObjectTypes.GEOMETRY &&
        (currentlyDrawingShapeId !== selectedObject?.artifactId ||
            (selectedObjectInfo as TemporaryGeometry)?.content.geometry.type === GeometryTypes.POINT);

    useKey('Delete', () => {
        if (isCompareToolEnabled) return;
        if (document.activeElement?.tagName.toLowerCase() === 'textarea') return;
        if (
            document.activeElement?.tagName.toLowerCase() === 'input' &&
            (document.activeElement as HTMLInputElement).type === 'text'
        )
            return;

        const isLayerTemporary =
            selectedObject?.type === ProjectStructureObjectTypes.LAYER &&
            (selectedObjectInfo as TemporaryLayer)?.isTemporary;
        const isGeometryTemporary =
            selectedObject?.type === ProjectStructureObjectTypes.GEOMETRY &&
            temporaryLayers.find(tl => tl.geometries.includes((selectedObjectInfo as TemporaryGeometry)?.id!))
                ?.isTemporary;

        const viewOnly = !owned && !isLayerTemporary && !isGeometryTemporary;

        if (viewOnly) return;

        if (selectedObject?.type === ProjectStructureObjectTypes.LAYER && !cantDeleteLayer)
            deleteLayer(selectedObjectInfo as TemporaryLayer);
        else if (selectedObject?.type === ProjectStructureObjectTypes.GEOMETRY && canDeleteGeometry) {
            if (selectedGeometryNodeId) return; // Then delete geometry node instead of geometry itself
            deleteGeometry(selectedObjectInfo as TemporaryGeometry);
        }
    });

    const showHelper = !hasAnythingToRenderInTree;

    return (
        <div className={classNames('sidebar', { expanded: isSidebarExpanded })} id='sidebar'>
            {isTogglerVisible && (
                <TippyTooltip
                    tooltipText={
                        isSidebarExpanded
                            ? t('structureSidebar.toggleTooltip_hide')
                            : t('structureSidebar.toggleTooltip_show')
                    }
                >
                    <div
                        className={classNames('sidebar-toggle', { hasBorder: !isInEmbedView })}
                        onClick={e => {
                            if (wrapperWidth < TWO_PANELS_MIN_WIDTH && !isSidebarExpanded) {
                                dispatch(setAnimatedInspectorVisibility(false));
                            }
                            dispatch(setSidebarVisibility(!isSidebarExpanded));
                        }}
                        style={
                            sidebarsToggleTransition !== undefined
                                ? { transform: `translateY(${sidebarsToggleTransition})` }
                                : undefined
                        }
                    />
                </TippyTooltip>
            )}
            <div className={classNames('sidebar-content', { skeleton: isLoading })}>
                {isCompareToolEnabled && !compareToolIsLoading && isCesiumLoaded ? (
                    <Switch>
                        <Route exact path={[Routes.SITE_VIEW]}>
                            <CompareSiteToolSidebar />
                        </Route>
                        <CompareToolSidebar />
                    </Switch>
                ) : (
                    <>
                        <section className='sidebar-section section-title'>
                            {isLoading ? (
                                <div className={classNames('project-title', { embed: isInEmbedView })} />
                            ) : (
                                <>
                                    <Switch>
                                        <Route
                                            exact
                                            path={[
                                                Routes.PROJECT_VIEW,
                                                Routes.SHARED_PROJECT_VIEW,
                                                Routes.EMBEDDED_PROJECT_VIEW
                                            ]}
                                        >
                                            <div className='project-title'>{t('structureSidebar.head')}</div>
                                        </Route>
                                        <Route exact path={[Routes.SITE_VIEW, Routes.SHARED_SITE_VIEW]}>
                                            <div className='project-title-wrapper'>
                                                <div className='project-title with-subtitle'>
                                                    {activeSiteProject?.name || '-'}
                                                </div>
                                                <div className={'project-subtitle'}>
                                                    {formatSurveyDate(activeSiteProject?.surveyDate) || '-'}
                                                </div>
                                            </div>
                                        </Route>
                                    </Switch>
                                    <WorkspaceControls />
                                </>
                            )}
                        </section>
                        <section
                            className={classNames('sidebar-section section-artefacts', {
                                'sidebar-helper': showHelper && !isLoading
                            })}
                        >
                            {(() => {
                                if (isLoading)
                                    return Array(9)
                                        .fill(null)
                                        .map((sa, index) => <SkeletonArtifact key={index} isEmbed={isInEmbedView} />);

                                if (!hasAnythingToRenderInTree && !isPresentationSetupEnabled)
                                    return <ProjectStructureHelper />;

                                if (hasAnythingToRenderInTree) return <StructureTree />;

                                return null;
                            })()}
                        </section>
                    </>
                )}
            </div>
        </div>
    );
}
