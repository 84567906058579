import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ProcessStatus } from '../../../../generated/cloud-frontend-api';
import getShortTimeString from '../../../../lib/getShortTimeString';
import { ProcessStepInfo } from '../../../../services/processing';
import { ReactComponent as SvgAborted } from '../../../../svg/processing_info/aborted.svg';
import { statusDisplayValues } from './ProcessingInfoModal';

interface Props {
    processStepInfo: ProcessStepInfo;
    className?: string;
}

export default function ProcessingListSubItem({ processStepInfo, className }: Props) {
    const { t } = useTranslation('modals');
    const totalStepTimeSeconds = processStepInfo.processingTimeInSeconds;

    return (
        <div key={processStepInfo.info} className='processing-list_item sub-item' data-testid={'processingTask'}>
            <div className='item-icon'>
                {processStepInfo.status === ProcessStatus.ABORTED || processStepInfo.status === ProcessStatus.FAILED ? (
                    <div className='state-svg-wrapper'>
                        <SvgAborted />
                    </div>
                ) : (
                    <div
                        className={classNames('state-icon', {
                            'state-complete': processStepInfo.status === ProcessStatus.COMPLETED,
                            'state-in-progress': processStepInfo.status === ProcessStatus.INPROGRESS
                        })}
                    />
                )}
            </div>

            <div className='item-status'>
                <b>{processStepInfo.info}</b>
                <span> - </span>
                <span>{statusDisplayValues()[processStepInfo.status]}</span>
                <span> </span>
                {!!processStepInfo.processingTimeInSeconds && (
                    <span className='item-time'>
                        {getShortTimeString(processStepInfo.processingTimeInSeconds || 0)}
                    </span>
                )}
            </div>
        </div>
    );
}
