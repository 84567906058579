import { unwrapResult } from '@reduxjs/toolkit';
import { addSeconds } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { usePreviousImmediate } from 'rooks';
import { ProcessStep, ProjectInfo, ProjectStatus } from '../../../generated/cloud-frontend-api/model';
import { formatDistanceToNowStrictShort } from '../../../i18n/dateTimeWrappers';
import getProcessStepText from '../../../lib/getProcessStepText';
import isLastPipelineProcessing from '../../../lib/isLastPipelineProcessing';
import { useGetProcessingInfoQuery } from '../../../services/processing';
import { AppDispatch, useSelector } from '../../../store';
import { makeSelectProgressById, progressTick } from '../../../store/slices/progress';
import { changeProjectPropertyInPage, replaceProjectInPage } from '../../../store/slices/projectsPage';
import { ReactComponent as SvgExclamation } from '../../../svg/volume/exclamation.svg';
import ProcessingInfoModal from '../modals/processing-info-modal/ProcessingInfoModal';

type Props = {
    project: ProjectInfo;
};

export default function ProgressBar({ project }: Props) {
    const { t, i18n } = useTranslation('projects');
    const dispatch: AppDispatch = useDispatch();
    const { id: projectId, status: projectStatus } = project;
    const processId = project.pipeline?.pipelineUid!;
    const previousProcessId = usePreviousImmediate(processId);
    const selectProgress = useMemo(makeSelectProgressById, []);
    const poller = useSelector(state => selectProgress(state, processId));
    const isProcessing = isLastPipelineProcessing(project);

    const { data: processingInfo } = useGetProcessingInfoQuery(
        { projectId: project.id!, processId },
        { refetchOnMountOrArgChange: true, skip: !isProcessing }
    );
    useGetProcessingInfoQuery(
        { projectId: project.id!, processId },
        {
            skip: !isProcessing,
            pollingInterval:
                isProcessing &&
                processingInfo &&
                processingInfo?.status !== 'ABORTED' &&
                processingInfo?.status !== 'FAILED' &&
                processingInfo?.status !== 'COMPLETED'
                    ? 5000
                    : 0
        }
    );

    const percent = poller?.progress ? Number(poller.progress.toFixed(0)) : 0;

    const [showProcessingInfoModal, setShowProcessingInfoModal] = useState(false);
    const stepName = isProcessing
        ? projectStatus === ProjectStatus.ABORTING
            ? t('progress.processSteps.aborting')
            : getProcessStepText(poller?.step!, processingInfo!)
        : poller?.step === ProcessStep.PUBLISHING_RESULTS
        ? t('progress.processSteps.publishingResults')
        : t('progress.processSteps.updatingStructure');

    const pollerTimer = useRef(0);

    useEffect(() => {
        if (!pollerTimer.current) startPoller(projectId!, processId);

        if (pollerTimer.current && previousProcessId !== processId) {
            window.clearInterval(pollerTimer.current);
            startPoller(projectId!, processId);
        }

        async function startPoller(projectId: string, processId: string) {
            pollerTimer.current = window.setInterval(() => {
                const oldPercent = percent;
                dispatch(progressTick({ projectId, processId }))
                    .then(unwrapResult)
                    .then(({ status, progress }) => {
                        if (status !== ProjectStatus.INPROGRESS && status !== ProjectStatus.ABORTING) {
                            if (pollerTimer.current > 0) {
                                window.clearInterval(pollerTimer.current);
                            }
                            dispatch(replaceProjectInPage(projectId));
                        } else if (status === ProjectStatus.ABORTING) {
                            dispatch(
                                changeProjectPropertyInPage({
                                    id: projectId,
                                    propName: 'status',
                                    propValue: ProjectStatus.ABORTING
                                })
                            );
                        } else if (progress < oldPercent) {
                            dispatch(replaceProjectInPage(projectId));
                        }
                    });
            }, 5000);
        }
    }, [projectId, processId, dispatch, percent, previousProcessId]);

    useEffect(() => {
        return () => {
            window.clearInterval(pollerTimer.current);
        };
    }, []);

    function getDuration(percent: number) {
        let durationInSeconds = poller?.duration || 0;
        const isEstimation = percent && percent !== 0;
        if (poller && isEstimation) {
            durationInSeconds = ((100 - percent) * poller.duration) / percent;
        }

        const now = new Date();
        const elapsed = addSeconds(now, -durationInSeconds);

        let duration = t('progress.durationDefault');

        if (isEstimation) {
            duration =
                durationInSeconds < 60
                    ? t('progress.duration<minute')
                    : t('progress.duration>=minute', {
                          formattedToWords: formatDistanceToNowStrictShort(
                              elapsed,
                              { roundingMethod: 'ceil' },
                              i18n.resolvedLanguage!
                          )
                      });
        }

        return duration;
    }

    return (
        <>
            <div className='progress-bar-wrapper'>
                <div className='progress-top'>
                    {isProcessing ? (
                        <b
                            className='progress-processing'
                            onClick={() => {
                                setShowProcessingInfoModal(true);
                            }}
                        >
                            <div className='processing-icon'>
                                <SvgExclamation />
                            </div>
                            <span data-testid={'progressStageText'} className='processing-text'>
                                {stepName}...
                            </span>
                        </b>
                    ) : (
                        <b data-testid={'progressStageText'}>{stepName}...</b>
                    )}
                </div>
                <div className='progress-bar'>
                    <span className='progress-value' style={{ width: `${percent}%` }} />
                </div>
                <div className='progress-bottom'>
                    <span>{`${getDuration(percent)}...`}</span>
                    <span>{percent}%</span>
                </div>
            </div>

            {showProcessingInfoModal && (
                <ProcessingInfoModal
                    setIsOpen={setShowProcessingInfoModal}
                    isOpen={showProcessingInfoModal}
                    projectInfo={project}
                />
            )}
        </>
    );
}
