import { CoordinateSystemType, Units } from '../sharedConstants';

const FOOT_CONVERT = 0.3048;
const US_FOOT_CONVERT = 0.3048006096012192;

export function convertPointUnit(
    point: number[],
    crsType: CoordinateSystemType,
    sourceUnit: Units,
    targetUnit: Units
): number[] {
    if (sourceUnit === targetUnit) {
        return point;
    }
    const sourceMultiplier = getMultiplier(sourceUnit);
    const targetMultiplier = getMultiplier(targetUnit);
    if (crsType === CoordinateSystemType.GEOGRAPHIC) {
        const resultPoint = point;
        resultPoint[2] = (point[2] * sourceMultiplier) / targetMultiplier;
        return resultPoint;
    }
    return point.map(coord => (coord * sourceMultiplier) / targetMultiplier);
}

export function convertLengthUnit(length: number, sourceUnit: Units, targetUnit: Units): number {
    if (sourceUnit === targetUnit) {
        return length;
    }
    const sourceMultiplier = getMultiplier(sourceUnit);
    const targetMultiplier = getMultiplier(targetUnit);
    return (length * sourceMultiplier) / targetMultiplier;
}

export function convertAreaUnit(area: number, sourceUnit: Units, targetUnit: Units): number {
    if (sourceUnit === targetUnit) {
        return area;
    }
    const sourceMultiplier = getMultiplier(sourceUnit);
    const targetMultiplier = getMultiplier(targetUnit);
    return (area * sourceMultiplier ** 2) / targetMultiplier ** 2;
}

export function convertVolumeUnit(volume: number, sourceUnit: Units, targetUnit: Units): number {
    if (sourceUnit === targetUnit) {
        return volume;
    }
    const sourceMultiplier = getMultiplier(sourceUnit);
    const targetMultiplier = getMultiplier(targetUnit);
    return (volume * sourceMultiplier ** 3) / targetMultiplier ** 3;
}

function getMultiplier(unit: Units): number {
    switch (unit) {
        case Units.METRE: {
            return 1;
        }
        case Units.FOOT: {
            return FOOT_CONVERT;
        }
        case Units.US_SURVEY_FOOT: {
            return US_FOOT_CONVERT;
        }
    }
}
