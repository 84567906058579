import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectInfo } from '../../../../generated/cloud-frontend-api/model';
import { AppDispatch } from '../../../../store/index';
import { useDispatch } from 'react-redux';
import TippyDropdownItemWithIcon from '../../tippy-dropdown/TippyDropdownItemWithIcon';
import EmbedModal from '../../modals/EmbedModal';

type Props = {
    project: ProjectInfo;
};

export default function EmbedProjectAction({ project }: Props) {
    const { t } = useTranslation(['projects']);
    const dispatch: AppDispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <TippyDropdownItemWithIcon
                icon={<i className='icon icon-code' />}
                text={t('projects:projectActions.embed')}
                onClick={() => {
                    setIsOpen(true);
                }}
                data-testid='embedCodeAction'
            />

            <EmbedModal isOpen={isOpen} setIsOpen={setIsOpen} project={project} />
        </>
    );
}
