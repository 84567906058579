import React, { forwardRef } from 'react';
import wgs84ToCartesian3 from '../../../../lib/wgs84ToCartesian3';
import * as Cesium from 'cesium';
import { CesiumComponentRef, Entity, PointGraphics, useCesium } from 'resium';
import { GeometryProps } from '../Geometry';
import { GeometryTypes } from '../../../../sharedConstants';
import { setCurrentlyHoveringShapeId } from '../../../../store/slices/projectView';
import { useDispatch } from 'react-redux';
import { ProjectStructureObjectTypes } from '../../../../store/helpers/interfaces';
import { setSelectedObject } from '../../../../store/sharedActions';
import { POINT_STYLES } from '../styling';
import { AppDispatch } from '../../../../store';

type Props = GeometryProps;

export default forwardRef<CesiumComponentRef<Cesium.Entity>, Props>(
    ({ geometry, layerVisible, isHovering, isSelected, isDrawing, mouseEventsBlocked, isEditing, color }, ref) => {
        const dispatch: AppDispatch = useDispatch();
        const { scene } = useCesium();

        return (
            <Entity
                id={`${GeometryTypes.POINT}#${geometry.id}#0`}
                ref={ref}
                position={
                    wgs84ToCartesian3(geometry.content.geometry.coordinates as number[], scene!) as Cesium.Cartesian3
                }
                show={layerVisible && !!geometry.content.properties.ac_visibility}
                onMouseEnter={e => {
                    if (mouseEventsBlocked) return;

                    if (!isDrawing && !isEditing) dispatch(setCurrentlyHoveringShapeId(geometry.id));
                }}
                onMouseLeave={e => {
                    if (mouseEventsBlocked) return;

                    if (!isDrawing && !isEditing) dispatch(setCurrentlyHoveringShapeId(undefined));
                }}
                onClick={e => {
                    if (mouseEventsBlocked) return;

                    if (!isDrawing && !isEditing && isHovering)
                        dispatch(
                            setSelectedObject({
                                type: ProjectStructureObjectTypes.GEOMETRY,
                                artifactId: geometry.id,
                                needToScroll: true
                            })
                        );
                }}
            >
                <PointGraphics
                    pixelSize={isSelected ? POINT_STYLES.sizeActive : POINT_STYLES.size}
                    color={color}
                    disableDepthTestDistance={Infinity}
                    outlineColor={color.withAlpha(POINT_STYLES.midpointOutlineColorAlpha)}
                    heightReference={Cesium.HeightReference.NONE}
                />
            </Entity>
        );
    }
);
