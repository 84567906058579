// This property is read-only. For writable property use AdditionalProperty component
import { useState, useEffect, useRef } from 'react';
import TippyTooltip from '../Elements/tippy-tooltip/TippyTooltip';
import { ReactComponent as SvgQuestion } from '../../svg/general/question.svg';
import { IS_TOUCH_DEVICE } from '../../sharedConstants';
import { useOutsideClick } from 'rooks';
import { Input } from 'antd';

type Props = {
    label: string;
    value: string;
    tooltip?: string;
};

let timeout = 0;

export default function GeneralProperty({ label, value, tooltip }: Props) {
    const propLabelRef = useRef<HTMLTextAreaElement>(null!);
    const propValueRef = useRef<HTMLTextAreaElement>(null!);

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const tooltipTriggerRef = useRef<HTMLDivElement>(null);

    const handleViewOnlyTooltip = () => {
        if (!tooltipVisible) {
            setTooltipVisible(true);
            timeout = window.setTimeout(() => {
                setTooltipVisible(false);
            }, 3000);
        } else closeOnlyTooltip();
    };

    const closeOnlyTooltip = () => {
        setTooltipVisible(false);
        clearTimeout(timeout);
    };

    useOutsideClick(tooltipTriggerRef, () => {
        closeOnlyTooltip();
    });

    useEffect(() => {
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <div className='property'>
            <div className='prop-wrapper read-only'>
                <div className='prop-label-wrapper'>
                    <Input.TextArea
                        autoSize
                        className='prop-input prop-label'
                        readOnly
                        rows={1}
                        tabIndex={-1}
                        ref={propLabelRef}
                        value={label}
                    />
                </div>
                <div className='prop-value-wrapper'>
                    <Input.TextArea
                        autoSize
                        className='prop-input prop-value'
                        readOnly
                        rows={1}
                        tabIndex={-1}
                        ref={propValueRef}
                        value={value}
                    />
                </div>
            </div>
            {tooltip ? (
                <TippyTooltip tooltipText={tooltip} touch={true} visible={tooltipVisible}>
                    <div
                        className='prop-action'
                        ref={tooltipTriggerRef}
                        onClick={e => {
                            handleViewOnlyTooltip();
                        }}
                        onMouseEnter={e => {
                            if (!IS_TOUCH_DEVICE) {
                                setTooltipVisible(true);
                            }
                        }}
                        onMouseLeave={e => {
                            if (!IS_TOUCH_DEVICE) {
                                setTooltipVisible(false);
                            }
                        }}
                    >
                        <SvgQuestion tabIndex={-1} />
                    </div>
                </TippyTooltip>
            ) : (
                <div className='prop-action' />
            )}
        </div>
    );
}
