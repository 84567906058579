import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import ProjectViewAccessContext from '../contexts/ProjectViewAccessContext';
import { useSelector } from '../store';
import { Routes } from '../sharedConstants';
import _ from 'lodash';

export default function useGetPublishState() {
    const { owned } = useContext(ProjectViewAccessContext);
    const match = useRouteMatch({ exact: true, path: [Routes.SHARED_PROJECT_VIEW, Routes.EMBEDDED_PROJECT_VIEW] });
    const account = useSelector(store => store.accountResources.account);
    const overdue = account.plan?.current?.overdue;
    const freeStorage = account.resources?.storage?.free || 0;

    const isInSharedProjectView = !owned && Boolean(match);

    if (isInSharedProjectView) return ''; // User must be allowed to do whatever in shared project, even if payment overdue

    if (overdue) {
        return 'overdue';
    } else if (freeStorage <= 0) {
        return 'storageFull';
    } else return '';
}
