import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useDispatch } from 'react-redux';
import { Route, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { SiteRouteParams } from '../../pages/site/Site';
import { EMPTY_ARRAY, Routes } from '../../sharedConstants';
import { AppDispatch, useSelector } from '../../store';
import { createLoadingSelector } from '../../store/selectors/createLoadingSelector';
import { loadNextProjectsPage } from '../../store/sharedActions';
import { firstProgressTick } from '../../store/slices/progress';
import { selectProjects, setCurrentProjectsPage, setIsLoading } from '../../store/slices/projectsPage';
import { getSite } from '../../store/slices/site';
import AddProjectTile from '../Elements/add-project/AddProjectTile';
import BackToTopButton from '../Projects/back-to-top-button/BackToTopButton';
import ProjectCard from './ProjectCard';
import ProjectCardSkeleton from './ProjectCardSkeleton';
import { getAccess, getAccessInfo } from '../../store/slices/sharing';
import { FolderRouteParams } from '../../pages/folder/Folder';

const MINIMAL_CARD_WIDTH = 288;
const MINIMAL_CARD_HEIGHT = 248;

export default function ProjectsGrid() {
    const dispatch: AppDispatch = useDispatch();
    const history = useHistory();
    const cardsContainer = useRef<HTMLDivElement | null>(null);
    const abortController = useRef(new AbortController());
    const isSharedProjectsRoute = !!useRouteMatch({ exact: true, path: Routes.SHARED });
    const isSharedSiteRoute = !!useRouteMatch({ exact: true, path: Routes.SHARED_SITE });
    const isIndexRoute = !!useRouteMatch({ exact: true, path: Routes.INDEX });
    const isSiteRoute = !!useRouteMatch({ exact: true, path: Routes.SITE });
    const isFolderRoute = !!useRouteMatch({ exact: true, path: Routes.FOLDER });
    const { id: siteId, hash } = useParams<SiteRouteParams>();
    const { folderId } = useParams<FolderRouteParams>();
    const loadingSelector = createLoadingSelector(
        (() => {
            if (isSharedProjectsRoute) return EMPTY_ARRAY;
            if (isSiteRoute) return [getSite.typePrefix, firstProgressTick.typePrefix];
            if (isIndexRoute || isFolderRoute) return [firstProgressTick.typePrefix];
            if (isSharedSiteRoute) return [getAccess.typePrefix, getAccessInfo.typePrefix, getSite.typePrefix];
            return EMPTY_ARRAY;
        })()
    );
    const isLoading = useSelector(state => loadingSelector(state));
    const page = useSelector(state => state.projectsPage);
    const projects = useSelector(state => selectProjects(state));
    const site = useSelector(state => state.site.siteInfo);

    const [containerWidth, setContainerWidth] = useState<number>(NaN);
    const [gridGap, setGridGap] = useState<number>();
    const [cardsInRow, setCardsInRow] = useState<number>();
    const [fixedCardHeight, setFixedCardHeight] = useState(false);
    const [pageLimit, setPageLimit] = useState(0);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [requestError, setRequestError] = useState(false);
    const [hasDoneFirstLoad, setHasDoneFirstLoad] = useState(false);

    const parentProjectUid = isFolderRoute ? folderId : site.uid || siteId;
    const isReadyToRequestByParentProject =
        !(isSharedSiteRoute || isSiteRoute || isFolderRoute) ||
        ((isSharedSiteRoute || isSiteRoute || isFolderRoute) && !!parentProjectUid);

    const [sentryRef] = useInfiniteScroll({
        loading: isLoadingMore,
        // If the first load has not been done yet, skeletons are taking too much height to trigger loading
        // Thus we sort of activate it "manually" by increasing root margin only for the first loading
        rootMargin: hasDoneFirstLoad ? '0px 0px 200px 0px' : '0px 0px 800px 0px',
        disabled: requestError || !isReadyToRequestByParentProject,
        hasNextPage:
            page.isLoading || page.currentPage < page.totalPages || page.searchText !== page.previousSearchText,
        onLoadMore: async () => {
            if (!pageLimit) return;
            setIsLoadingMore(true);
            const result = await dispatch(
                loadNextProjectsPage({
                    parentProjectUid,
                    page: page.currentPage,
                    limit: pageLimit,
                    sortMode: page.sortMode,
                    sortOrder: page.sortOrder,
                    pattern: page.searchText,
                    access: hash,
                    signal: abortController.current.signal
                })
            );
            if (loadNextProjectsPage.rejected.match(result)) {
                setRequestError(true);
                return;
            }
            setIsLoadingMore(false);
            if (!hasDoneFirstLoad) setHasDoneFirstLoad(true);
            dispatch(setCurrentProjectsPage(page.currentPage + 1));
        }
    });

    useEffect(() => {
        if (!pageLimit) {
            const newPageLimit = getProjectsPageLimit();
            setPageLimit(newPageLimit);
        }

        function getProjectsPageLimit() {
            if (!cardsInRow) return 0;

            let estimatedHeightLimit = Math.ceil((window.innerHeight + gridGap!) / (MINIMAL_CARD_HEIGHT + gridGap!));
            let estimatedPageLimit = estimatedHeightLimit * cardsInRow;
            return estimatedPageLimit;
        }
    }, [pageLimit, cardsInRow, gridGap, page, dispatch]);

    useEffect(() => {
        updateContainerWidth();
        window.addEventListener('resize', updateContainerWidth);

        return () => {
            window.removeEventListener('resize', updateContainerWidth);
        };

        function updateContainerWidth() {
            setContainerWidth(cardsContainer.current!.clientWidth);
        }
    }, []);

    useEffect(() => {
        setGridGap(parseInt(window.getComputedStyle(cardsContainer.current!).getPropertyValue('column-gap'), 10));
    }, [cardsContainer]);

    useLayoutEffect(() => {
        if (!Number.isNaN(containerWidth)) {
            const newCardsInRow = Math.floor((containerWidth! + gridGap!) / (MINIMAL_CARD_WIDTH + gridGap!));
            setCardsInRow(newCardsInRow);
            if (newCardsInRow !== undefined) {
                document.documentElement.style.setProperty('--project-grid-rows', newCardsInRow.toString());
                setFixedCardHeight(newCardsInRow > 1);
            }
        }
    }, [containerWidth, gridGap, cardsInRow]);

    useEffect(() => {
        const unlisten = history.listen((location: Location) => {
            dispatch(setIsLoading(false));
            abortController.current.abort();
        });
        return () => {
            unlisten();
        };
    }, [history, dispatch]);

    const showAddProject = !isLoading && !page.searchText && !page.isLoading;

    return (
        <div>
            <section className='project-tiles' ref={cardsContainer}>
                <Route exact path={[Routes.INDEX, Routes.SITE, Routes.FOLDER]}>
                    {showAddProject && <AddProjectTile fixedCardHeight={fixedCardHeight} />}
                </Route>
                {projects.map(project => (
                    <ProjectCard key={project.id} project={project} isLoading={isLoading} />
                ))}
                {(isLoadingMore || isLoading || !hasDoneFirstLoad) && (
                    <>
                        {Array(pageLimit)
                            .fill(null)
                            .map((e, i) => (
                                <ProjectCardSkeleton key={i} />
                            ))}
                    </>
                )}
            </section>
            {page.totalPages > 1 && page.totalPages === page.currentPage && <div className='back-to-top-spacer' />}
            <div ref={sentryRef} />

            <BackToTopButton />
        </div>
    );
}
