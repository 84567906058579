/* tslint:disable */
/* eslint-disable */
/**
 * billing-api
 * Billing API allows to get info about products and purchase them
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface StorageProduct
 */
export interface StorageProduct {
    /**
     * 
     * @type {string}
     * @memberof StorageProduct
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof StorageProduct
     */
    'description'?: string;
    /**
     * Price in dollars
     * @type {number}
     * @memberof StorageProduct
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof StorageProduct
     */
    'interval'?: StorageProductIntervalEnum;
    /**
     * 
     * @type {string}
     * @memberof StorageProduct
     */
    'grade'?: StorageProductGradeEnum;
    /**
     * Storage product size in bytes
     * @type {number}
     * @memberof StorageProduct
     */
    'storageInBytes'?: number;
}

export const StorageProductIntervalEnum = {
    MONTH: 'MONTH',
    YEAR: 'YEAR'
} as const;

export type StorageProductIntervalEnum = typeof StorageProductIntervalEnum[keyof typeof StorageProductIntervalEnum];
export const StorageProductGradeEnum = {
    CURRENT: 'CURRENT',
    DOWNGRADE: 'DOWNGRADE',
    UPGRADE: 'UPGRADE',
    SCHEDULED: 'SCHEDULED'
} as const;

export type StorageProductGradeEnum = typeof StorageProductGradeEnum[keyof typeof StorageProductGradeEnum];


