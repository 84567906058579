import React from 'react';
import GeneralProperties from './GeneralProperties';
import AdditionalProperties from './AdditionalProperties';
import { useSelector } from '../../../store';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { TemporaryGeometry, isPolygonGeometry, isPolylineGeometry } from '../../../store/helpers/interfaces';
import { reservedPropertiesNames } from '../../../sharedConstants';
import StyleProperties from './StyleProperties';
import ElevationProfile from '../elevation-profile/ElevationProfile';
import Volume from './volume/Volume';

type Props = {
    viewOnly: boolean;
    isLinked: boolean;
};

export default function GeometryProperties({ viewOnly, isLinked }: Props) {
    const geometry = useSelector(state => selectedObjectInfoSelector(state)) as TemporaryGeometry;

    const allProperties = geometry.content.properties;
    const customPropertiesKeys = Object.keys(allProperties).filter(k => !reservedPropertiesNames.includes(k));

    return (
        <>
            <StyleProperties viewOnly={viewOnly} />
            {isPolylineGeometry(geometry) && <ElevationProfile isLinked={isLinked} />}
            {isPolygonGeometry(geometry) && <Volume isLinked={isLinked} />}
            <GeneralProperties />
            {(!viewOnly || !!customPropertiesKeys.length) && (
                <div className='inspector-properties'>
                    <AdditionalProperties viewOnly={viewOnly} />
                </div>
            )}
        </>
    );
}
