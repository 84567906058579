import classNames from 'classnames';
import { useContext } from 'react';
import { HexColorInput } from 'react-colorful';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import GeometryColorPicker from '../../Elements/geometry-color-picker/GeometryColorPicker';

type Props = {
    color: string;
    label: string;
    onChange(newColor: string): void;
    viewOnly: boolean;
    withApplyToAll?: boolean;
    onApplyToAll?(newColor: string): void;
};

export default function ColorPickerProperty({ color, label, onChange, viewOnly, onApplyToAll, withApplyToAll }: Props) {
    const { isInEmbedView } = useContext(ProjectViewAccessContext);

    return (
        <div className='property property-color'>
            <div className='prop-wrapper read-only'>
                <div className='prop-label-wrapper'>
                    <div className='prop-label prop-text'>{label}</div>
                </div>
                <div className='prop-value-wrapper'>
                    {viewOnly ? (
                        <div className='prop-value prop-text'>{color.toUpperCase()}</div>
                    ) : (
                        <HexColorInput
                            color={color.toUpperCase()}
                            onChange={onChange}
                            className={classNames('prop-value-color', { 'theme-dark': isInEmbedView })}
                            alpha={true}
                            type='text'
                            prefixed={true}
                            tabIndex={-1}
                        />
                    )}
                </div>
            </div>
            <GeometryColorPicker
                viewOnly={viewOnly}
                color={color}
                onChange={onChange}
                onApplyToAll={onApplyToAll}
                withApplyToAll={withApplyToAll}
            />
        </div>
    );
}
