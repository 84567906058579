export default function ProjectCardSkeleton() {
    return (
        <div className='project-tile skeleton' data-testid={'projectCardSkeleton'}>
            <div className='image-wrapper' />
            <div className='project-info'>
                <div className='skeleton-title' />
                <div className='skeleton-line' />
                <div className='skeleton-line' />
                <div className='skeleton-line' />
            </div>
        </div>
    );
}
