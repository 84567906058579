import classNames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { memo, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOutsideClick } from 'rooks';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import useOutsideRightClick from '../../../hooks/useOutsideRightClick';
import { AppDispatch, useSelector } from '../../../store';
import { setMapSettingsVisibility } from '../../../store/slices/projectView';
import BaseMapControl from './BaseMapControl';
import CoordinateSystemSwitch from './CoordinateSystemSwitch';
import QualitySwitch from './QualitySwitch';
import SavePointOfView from './SavePointOfView';
import UnitsSwitch from './UnitsSwitch';
import ViewMode from './ViewModeSwitch';

import styles from './MapSettings.module.scss';

type Props = {
    wrapperWidth: number;
    wrapperHeight: number;
};

function MapSettings(props: Props) {
    const dispatch: AppDispatch = useDispatch();
    const mapModesRef = useRef<HTMLDivElement>(null);
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const areMapSettingsOpen = useSelector(state => state.projectView.areMapSettingsOpen);
    const { isInEmbedView } = useContext(ProjectViewAccessContext);

    function close(e: Event) {
        if (areMapSettingsOpen && !(e.target as Element).classList.contains('notTriggersMapSettingsClose'))
            dispatch(setMapSettingsVisibility(false));
    }

    useOutsideClick(mapModesRef, close);
    useOutsideRightClick(mapModesRef, close);

    const [settingsMaxHeight, setSettingsMaxHeight] = useState<number | null>(null);

    useEffect(() => {
        if (mapModesRef.current !== null) {
            // сумма всех вертикальных отступов, вычитаемая из доступной высоты.
            let deductedHeight =
                parseInt(getComputedStyle(mapModesRef.current!).top, 10) +
                parseInt(getComputedStyle(mapModesRef.current!).paddingTop, 10) +
                parseInt(getComputedStyle(mapModesRef.current!).paddingBottom, 10) +
                24 + // mapBottomBar (coordinates bar) height
                8; // bottom indent
            setSettingsMaxHeight(props.wrapperHeight - deductedHeight);
        }
    }, [props.wrapperHeight]);

    return (
        <div
            className={classNames(styles.sceneSettings, areMapSettingsOpen && styles.isActive)}
            ref={mapModesRef}
            id='mapModes' // for test purposes only. could rewrite to data-test-id
        >
            <OverlayScrollbarsComponent className={classNames({ 'os-theme-light': isInEmbedView })}>
                <div className={styles.settingsWrapper} style={{ maxHeight: `${settingsMaxHeight}px` }}>
                    <div className={styles.settingsContainer}>
                        <div className={styles.settingsGrid}>
                            <ViewMode containerRef={mapModesRef.current!} />
                            <BaseMapControl />
                            <QualitySwitch containerRef={mapModesRef.current!} />
                            <UnitsSwitch containerRef={mapModesRef.current!} />
                        </div>
                        <div className={styles.settingsItem}>
                            <CoordinateSystemSwitch containerRef={mapModesRef.current!} />
                        </div>
                        {!isCompareToolEnabled && <SavePointOfView />}
                    </div>
                </div>
            </OverlayScrollbarsComponent>
        </div>
    );
}

export default memo(MapSettings);
