import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { GeometryTypes } from '../../sharedConstants';
import { createSetterReducer } from '../helpers';
import { isViewpointGeometry, TemporaryGeometry } from '../helpers/interfaces';
import { ApplicationState } from '../index';

interface PresentationState {
    povSaveRequestSlideId: string;
    isCreateViewpointControlEnabled: boolean;
}

const initialState: PresentationState = {
    povSaveRequestSlideId: '',
    isCreateViewpointControlEnabled: false
};

const name = 'presentation';

const setterReducer = createSetterReducer<PresentationState>();
const slice = createSlice({
    name,
    initialState,
    reducers: {
        setPovSaveRequested: setterReducer('povSaveRequestSlideId'),
        setCreateViewpointControlEnabled: setterReducer('isCreateViewpointControlEnabled'),
        resetPresentationState: () => initialState
    }
});

export default slice.reducer;

export const { setPovSaveRequested, resetPresentationState, setCreateViewpointControlEnabled } = slice.actions;

export const selectSelectedPresentationSlide = createSelector(
    (state: ApplicationState) => state.project.selectedObject,
    (state: ApplicationState) => state.geometries.entities,
    ({ artifactId }, geometries) => {
        if (artifactId) {
            const geometry = geometries[artifactId];
            return (geometry && isViewpointGeometry(geometry) ? geometry : undefined) as
                | TemporaryGeometry<GeometryTypes.POINT>
                | undefined;
        }
    }
);

export const selectHoveredPresentationSlide = createSelector(
    (state: ApplicationState) => state.projectView.currentlyHoveringShapeId,
    (state: ApplicationState) => state.geometries.entities,
    (currentlyHoveringShapeId, geometries) => {
        if (currentlyHoveringShapeId) {
            const geometry = geometries[currentlyHoveringShapeId];
            return (geometry && isViewpointGeometry(geometry) ? geometry : undefined) as
                | TemporaryGeometry<GeometryTypes.POINT>
                | undefined;
        }
    }
);

export const selectIsCreateViewpointControlEnabled = createSelector(
    (state: ApplicationState) => state.projectView.isPresentationSetupEnabled,
    (state: ApplicationState) => state.presentation.isCreateViewpointControlEnabled,
    (isPresentationSetupEnabled, isCreateViewpointControlEnabled) =>
        isPresentationSetupEnabled && isCreateViewpointControlEnabled
);
