import { AxiosProgressEvent } from 'axios';
import axiosRetry, { isRetryableError } from 'axios-retry';
import { NeededThunkApi } from '.';
import { uploadedDatasetApi } from '../../api/initApis';
import appAxios from '../../api/appAxios';

interface FileUploader<T = any> {
    upload(...args: any): Promise<T>;
}

const DEFAULT_PART_SIZE = 16777216; // 16 MB
const MAX_PARTS_COUNT = 10000;

export class DatasetFileUploader implements FileUploader<void> {
    private readonly partSize: number;
    constructor(private readonly file: File, private readonly thunkApi: NeededThunkApi) {
        const estimatedPartsCount = Math.ceil(file.size / DEFAULT_PART_SIZE);
        this.partSize =
            estimatedPartsCount < MAX_PARTS_COUNT ? DEFAULT_PART_SIZE : Math.ceil(file.size / MAX_PARTS_COUNT);
    }

    async upload(datasetUid: string, onProgress: (uploaded: number) => void): Promise<void> {
        const projectId = this.thunkApi.getState().project.projectInfo.id!;

        let totalUploaded = 0;
        let previouslyUploaded = 0;
        const onUploadProgress = ({ loaded }: AxiosProgressEvent) => {
            totalUploaded += loaded - previouslyUploaded;
            onProgress(totalUploaded);
            previouslyUploaded = loaded;
        };

        const filePartsLength = Math.ceil(this.file.size / this.partSize);
        for (let i = 0; i < filePartsLength; i++) {
            const filePart = this.file.slice(i * this.partSize, (i + 1) * this.partSize);

            const {
                data: { url }
            } = await uploadedDatasetApi.createUploadUrl(
                projectId,
                datasetUid,
                { part: i + 1 },
                {
                    'axios-retry': {
                        retries: 5,
                        retryCondition: isRetryableError,
                        retryDelay: (retryCount, error) => axiosRetry.exponentialDelay(retryCount, error, 2000)
                    }
                }
            );
            if (!url) throw new Error('No create url');

            await appAxios.request({
                url,
                method: 'PUT',
                data: filePart,
                onUploadProgress,
                'axios-retry': {
                    retries: 5,
                    retryCondition: isRetryableError,
                    retryDelay: (retryCount, error) => axiosRetry.exponentialDelay(retryCount, error, 2000)
                }
            });
            previouslyUploaded = 0;
        }
        await uploadedDatasetApi.completeUpload(projectId, datasetUid, {
            'axios-retry': {
                retries: 10,
                retryCondition: isRetryableError,
                retryDelay: (retryCount, error) => axiosRetry.exponentialDelay(retryCount, error, 2000)
            }
        });
    }
}
