import React, { useContext, useEffect, useMemo } from 'react';
import OfflineReloadButton from '../Elements/offline-reload-button/OfflineReloadButton';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as Svg404 } from '../../svg/system/404.svg';
import { ReactComponent as SvgDenied } from '../../svg/system/denied.svg';
import { ReactComponent as SvgEmpty } from '../../svg/system/empty.svg';
import { ReactComponent as SvgNoConnection } from '../../svg/system/no_connection.svg';
import { ReactComponent as SvgNotFound } from '../../svg/system/not_found.svg';
import { ReactComponent as SvgUnavailable } from '../../svg/system/unavailable.svg';
import { ReactComponent as SvgEmail } from '../../svg/system/email.svg';
import ProjectViewAccessContext from '../../contexts/ProjectViewAccessContext';
import { useSelector } from '../../store';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

type PlaceholderType =
    | 'empty'
    | 'shared-empty'
    | 'offline'
    | 'noprojectsfound'
    | 'deleted'
    | 'noaccess'
    | '404'
    | 'serviceUnavailable'
    | 'serviceUnavailableEmbed'
    | 'alreadyUnsubscribed'
    | 'unsubscribed';

type Props = {
    type: PlaceholderType;
};

const BackToProjectsButton = ({ text }: { text?: string }) => {
    const { t } = useTranslation(['placeholders']);

    return (
        <Link to={'/'}>
            <button className='empty-button btn' type={'button'}>
                {text || t('placeholders:backToProjects')}
            </button>
        </Link>
    );
};

const ProjectsPlaceholder: React.FC<Props> = ({ type }) => {
    const { t } = useTranslation(['placeholders']);
    const { isInEmbedView } = useContext(ProjectViewAccessContext);
    const { projectInfo } = useSelector(state => state.project);
    const firstName = useSelector(state => state.user.firstName);
    const supportLink = useSelector(state => state.init.supportLink);
    const location = useLocation<{ projectName?: string }>();
    const projectName = location.state?.projectName || projectInfo?.name!;
    const [header, icon, text] = useMemo(() => getProperties(type, projectName, t), [type, projectName, t]);
    const loggedIn = Boolean(firstName);

    useEffect(() => {
        const content = document.getElementById('content');
        content?.classList.add('full-height');
        return () => {
            content?.classList.remove('full-height');
        };
    }, []);

    return (
        <section className='projects-empty' data-testid={'placeholderSection'}>
            <div className='empty-icon'>{icon}</div>
            <div className='empty-title text-wrap'>{header}</div>
            <div className='empty-text'>{text}</div>
            {['offline'].includes(type) && <OfflineReloadButton />}
            {['owned-unpublished', 'deleted'].includes(type) && !isInEmbedView && loggedIn && <BackToProjectsButton />}
            {['alreadyUnsubscribed', 'unsubscribed'].includes(type) && !isInEmbedView && loggedIn && (
                <BackToProjectsButton text={t('placeholders:returnToCloud')} />
            )}
            {type === '404' && loggedIn && <BackToProjectsButton />}
            {type === '404' && !loggedIn}
            {type === 'noaccess' && loggedIn && <BackToProjectsButton />}
            {type === 'empty' && (
                <a href={supportLink} target={'_blank'} rel={'noopener noreferrer'}>
                    <button className='empty-button btn' type={'button'}>
                        {t('placeholders:takeTheTutorial')}
                    </button>
                </a>
            )}
            {type === 'serviceUnavailable' && (
                <a href={supportLink} target='_blank' rel='noreferrer noopener'>
                    <button className='empty-button btn' type={'button'}>
                        {t('placeholders:requestSupport')}
                    </button>
                </a>
            )}
        </section>
    );
};

export default ProjectsPlaceholder;

function getProperties(
    type: PlaceholderType,
    projectName: string,
    t: TFunction<'placeholders'>
): [string, JSX.Element, string] {
    switch (type) {
        case 'empty':
            return [t('empty.head'), <SvgEmpty key={type} />, t('empty.body')];
        case 'shared-empty':
            return [t('sharedEmpty.head'), <SvgEmpty key={type} />, t('sharedEmpty.body')];
        case 'noprojectsfound':
            return [t('noProjectsFound.head'), <SvgNotFound key={type} />, t('noProjectsFound.body')];
        case 'offline':
            return [t('offline.head'), <SvgNoConnection key={type} />, t('offline.body')];
        case 'deleted':
            return [t('projectDeleted.head'), <SvgDenied key={type} />, t('projectDeleted.body')];
        case 'noaccess':
            return [t('accessDenied.head'), <SvgDenied key={type} />, t('accessDenied.body')];
        case '404':
            return [t('404.head'), <Svg404 key={type} />, t('404.body')];
        case 'serviceUnavailable':
            return [t('serviceUnavailable.head'), <SvgUnavailable key={type} />, t('serviceUnavailable.body')];
        case 'serviceUnavailableEmbed':
            return [
                t('serviceUnavailableEmbed.head'),
                <SvgUnavailable key={type} />,
                t('serviceUnavailableEmbed.body')
            ];
        case 'alreadyUnsubscribed':
            return [t('alreadyUnsubscribed.head'), <SvgEmail key={type} />, t('alreadyUnsubscribed.body')];
        case 'unsubscribed':
            return [t('unsubscribed.head'), <SvgEmail key={type} />, t('unsubscribed.body')];
        default:
            return ['', <>{''}</>, ''];
    }
}
