import {
    CategoryScale,
    ChartData,
    Chart as ChartJS,
    ChartOptions,
    Filler,
    LineElement,
    LinearScale,
    PointElement,
    Tooltip
} from 'chart.js';
import _ from 'lodash';
import { memo, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { convertLengthUnit } from '../../../lib/convertUnit';
import { getUnitsShortName } from '../../../lib/getUnitsShortName';
import { GeometryTypes, Units } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { TemporaryGeometry } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { makeSelectElevationProfileById, setHoveredPointIndex } from '../../../store/slices/elevationProfiles';
import getGraphOptions from './GraphOptions';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler);

function ElevationProfileGraph() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const polyline = useSelector(state =>
        selectedObjectInfoSelector(state)
    ) as TemporaryGeometry<GeometryTypes.POLYLINE>;
    const selectElevationProfileById = useMemo(makeSelectElevationProfileById, []);
    const elevationProfile = useSelector(state => selectElevationProfileById(state, polyline.id));
    const units = useSelector(state => state.coordinateSystems.units);

    const localOptions: ChartOptions<'line'> = useMemo(() => {
        const unitsNaming = getUnitsShortName(units);
        return _.merge(getGraphOptions({ distanceUnits: unitsNaming, elevationUnits: unitsNaming }), {
            x: {
                ticks: {
                    maxTicksLimit: 5
                }
            },
            y: {
                ticks: {
                    maxTicksLimit: 4
                }
            }
        });
    }, [units]);

    const options: ChartOptions<'line'> = useMemo(
        () => ({
            ...localOptions,
            onHover(event, elements) {
                if (elements.length) {
                    const element = elements[0];
                    dispatch(setHoveredPointIndex(element.index));
                }
            }
        }),
        [dispatch, localOptions]
    );

    if (!elevationProfile) return null;

    const data: ChartData<'line'> = {
        labels: elevationProfile.result.distances.map(r => convertLengthUnit(r, Units.METRE, units).toFixed(2)),
        datasets: elevationProfile.result.altitudes.map(({ altitudes, color, name }) => ({
            data: altitudes.map(r => convertLengthUnit(r, Units.METRE, units)),
            label: `${name}:`,
            borderColor: color,
            backgroundColor: color
        }))
    };

    return (
        <Line
            options={options}
            data={data}
            onMouseOut={e => {
                dispatch(setHoveredPointIndex(null));
            }}
        />
    );
}

export default memo(ElevationProfileGraph);
