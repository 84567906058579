import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';

type Props = {
    onClick(e: SyntheticEvent): void;
};

export default function ZoomControl({ onClick }: Props) {
    const { t } = useTranslation('projectView');

    return (
        <TippyTooltip tooltipText={t('structureItem.controls.tooltipZoom')}>
            <span className='control control-extended' onClick={onClick}>
                <i className='icon icon-center' data-testid={'zoomIcon'} />
            </span>
        </TippyTooltip>
    );
}
