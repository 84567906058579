import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Field, Form, Formik } from 'formik';

import { EventType } from '../../../generated/subscription-api/model';

import { AppDispatch, useSelector } from '../../../store';
import { subscriptionsAsObject } from '../../../store/selectors';
import { unsubscribe } from '../../../store/slices/emailSubscriptions';

import CheckboxWrapper from '../../../components/Elements/checkbox-wrapper/CheckboxWrapper';

type Props = {
    types: Array<EventType>;
    accountId: string;
    hash: string;
    setIsSubmitted: (value: boolean) => void;
};

export default function UnsubscribeForm({ types, accountId, hash, setIsSubmitted }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['cabinet']);

    const subscriptions = useSelector(state => subscriptionsAsObject(state));
    const subscriptionsList = useSelector(state => state.emailSubscriptions.list);

    const eventTypeTexts: Record<EventType, string> = useMemo(
        () => ({
            [EventType.NEWS]: t('cabinet:unsubscribe.eventType.news'),
            [EventType.LIMIT_EXCEEDED]: t('cabinet:unsubscribe.eventType.outOfResources'),
            [EventType.PROCESSING_FINISHED]: t('cabinet:unsubscribe.eventType.projectStatus')
        }),
        [t]
    );

    return (
        <Formik
            enableReinitialize
            initialValues={subscriptions}
            onSubmit={async values => {
                const typesToUnsubscribe = Object.keys(_.pickBy(values, value => !!value)) as Array<EventType>;
                await dispatch(unsubscribe({ accountId, hash, types: typesToUnsubscribe })).then(() => {
                    setIsSubmitted(true);
                });
            }}
        >
            {({ values }) => (
                <Form className='form-unsubscribe'>
                    {subscriptionsList.map(s => (
                        <div className='field-group form-checkbox' key={s.event}>
                            <CheckboxWrapper>
                                <Field name={s.event} type='checkbox' />
                            </CheckboxWrapper>
                            <span>{eventTypeTexts[s.event!]}</span>
                        </div>
                    ))}
                    <div className='form-actions'>
                        <button className='btn' type='submit' disabled={_.every(values, v => !v)}>
                            {t('cabinet:unsubscribe.unsubscribe')}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
