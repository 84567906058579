import { arrayMove } from '@dnd-kit/sortable';
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { v4 as uuidv4 } from 'uuid';
import { projectsApi } from '../../api/initApis';
import { ChunkPresenter } from '../../entities/Chunk';
import {
    AccessInfo,
    Parent,
    PipelineStatus,
    Project,
    ProjectStatus,
    ProjectStructure
} from '../../generated/cloud-frontend-api/model';
import { getRandomColorHex } from '../../lib/getRandomColor';
import isLastPipelineProcessing from '../../lib/isLastPipelineProcessing';
import GetProjectById from '../GetProjectById';
import { ExtendedStructure, ProjectStructureObjectTypes, SelectedObject, TemporaryLayer } from '../helpers/interfaces';
import { AppDispatch, ApplicationState } from '../index';
import { setSelectedObject } from '../sharedActions';
import { updateCompareToolTree1Structures } from './compareTool';
import { unlinkDataset } from './datasetfilesUpload';
import { uploadAborted, uploadStarted } from './datasetsUpload';
import { addGeometries, addGeometry, deleteGeometry, removeGeometry } from './geometries';
import { beginLayer, commitGeometry, deleteLayer, getLayer, getLayerSize, updateLayer } from './geometryLayers';
import { progressTick } from './progress';
import { cancelProjectProcessing, publishProject, unpublishProject } from './projectActions';
import { moveProject, renameProject } from './projectsPage';
import { setSelectedCamera } from './projectView';
import {
    addEmailAccess,
    deleteEmailAccess,
    disableEmbedAccess,
    disableLinkAccess,
    enableEmbedAccess,
    enableLinkAccess
} from './sharing';
import { requestStructureInfo } from './structure';

export interface ExtendedProject extends Required<Omit<Project, 'parent'>> {
    structure: ExtendedStructure;
    parent: Parent | null;
}

interface ProjectState extends ExtendedProject {
    selectedObject: SelectedObject;
}

const initialState: ProjectState = {
    projectInfo: {},
    parent: null,
    structure: { chunks: [], temporaryLayers: [] },
    selectedObject: {} as SelectedObject
};

const name = 'project';

interface GetProjectByIdArgs {
    id: string;
    access?: string;
    embed?: string;
}
export const getProjectById = createAsyncThunk<
    ExtendedProject,
    GetProjectByIdArgs,
    { dispatch: AppDispatch; state: ApplicationState }
>(`${name}/get`, async ({ id, access, embed }, { dispatch, getState }) => {
    const { project, structures } = await new GetProjectById(id, dispatch, getState, access, embed).result();

    if (getState().projectView.isCompareToolEnabled) {
        dispatch(updateCompareToolTree1Structures(structures));
    }

    return project;
});

interface GetRawProjectStructure extends GetProjectByIdArgs {
    lastModified?: string;
}
export const getRawProjectStructure = (() => {
    const cache = new Map<string, ProjectStructure>();

    return createAsyncThunk(`${name}/getRaw`, async ({ id, access, embed, lastModified }: GetRawProjectStructure) => {
        const cacheKey = `${id}#${lastModified}`;
        let structure: ProjectStructure;
        if (lastModified && cache.has(cacheKey)) structure = cache.get(cacheKey)!;
        else {
            structure = (await projectsApi.getProjectById(id, { params: { access, embed } })).data.structure!;
            structure = { chunks: structure.chunks, datasets: structure.datasets };
            if (lastModified) cache.set(cacheKey, structure);
        }

        const structureInfo = await requestStructureInfo({ projectId: id, access, embed });
        return { structure, structureInfo };
    });
})();

const projectSlice = createSlice({
    name,
    initialState,
    reducers: {
        resetProjectById() {
            return initialState;
        },
        addTemporaryLayer: {
            reducer(state, { payload }: PayloadAction<TemporaryLayer & { index?: number }>) {
                if (payload.index) state.structure.temporaryLayers.splice(payload.index, 0, payload);
                else state.structure.temporaryLayers.unshift(payload);
                if (payload.selectOnCreate) {
                    state.selectedObject = { type: ProjectStructureObjectTypes.LAYER, artifactId: payload.id };
                }
            },
            prepare(
                payload: TemporaryLayer | string,
                { crsId, index, selectOnCreate }: { selectOnCreate?: boolean; index?: number; crsId?: string }
            ) {
                const uid = uuidv4();
                const newColor = getRandomColorHex();
                const goodPayload: TemporaryLayer = {
                    geometries: [],
                    id: uid,
                    assetUid: uid,
                    color: newColor,
                    strokeColor: newColor,
                    coordinateSystemUid: crsId,
                    name: '',
                    selectOnCreate: selectOnCreate,
                    isTemporary: true
                };
                if (!payload) return { payload: goodPayload };
                else {
                    if (typeof payload === 'string') return { payload: { ...goodPayload, name: payload, index } };
                    else return { payload: { ...payload, index } };
                }
            }
        },
        deleteTemporaryLayer(state, { payload }: PayloadAction<string>) {
            const index = state.structure.temporaryLayers.findIndex(l => l.id === payload);
            if (index !== -1) state.structure.temporaryLayers.splice(index, 1);
        },
        setLayerProperty(
            state,
            {
                payload
            }: PayloadAction<{
                id: string;
                propName: keyof TemporaryLayer;
                propValue: TemporaryLayer[keyof TemporaryLayer];
            }>
        ) {
            const tlayer = state.structure.temporaryLayers.find(l => l.id === payload.id);
            if (tlayer) (tlayer[payload.propName] as any) = payload.propValue;
        },
        moveGeometryInLayer(state, { payload }: PayloadAction<{ layerUid: string; from: number; to: number }>) {
            const layer = state.structure.temporaryLayers.find(l => l.id === payload.layerUid);
            if (layer) {
                layer.geometries = arrayMove(layer.geometries, payload.from, payload.to);
            }
        },
        setLayers(state, { payload }: PayloadAction<TemporaryLayer[]>) {
            state.structure.temporaryLayers = payload;
        }
    },
    extraReducers: builder => {
        function updateAccessState(
            state: WritableDraft<ProjectState>,
            projectId: string,
            accesses: AccessInfo[] | undefined
        ) {
            if (state.projectInfo.id === projectId) {
                state.projectInfo.accesses = accesses;
            }
        }

        builder
            .addCase(getProjectById.fulfilled, (state, { payload }) => {
                state.projectInfo = payload.projectInfo!;
                state.structure.chunks = payload.structure.chunks;

                state.parent = payload.parent;
            })
            .addCase(enableLinkAccess.fulfilled, (state, action) => {
                const { projectId } = action.meta.arg;
                updateAccessState(state, projectId, action.payload);
            })
            .addCase(disableLinkAccess.fulfilled, (state, action) => {
                const { projectId } = action.meta.arg;
                updateAccessState(state, projectId, action.payload);
            })
            .addCase(enableEmbedAccess.fulfilled, (state, action) => {
                const { projectId } = action.meta.arg;
                updateAccessState(state, projectId, action.payload);
            })
            .addCase(disableEmbedAccess.fulfilled, (state, action) => {
                const { projectId } = action.meta.arg;
                updateAccessState(state, projectId, action.payload);
            })
            .addCase(addEmailAccess.fulfilled, (state, action) => {
                const { projectId } = action.meta.arg;
                updateAccessState(state, projectId, action.payload);
            })
            .addCase(deleteEmailAccess.fulfilled, (state, action) => {
                const { projectId } = action.meta.arg;
                updateAccessState(state, projectId, action.payload);
            })
            .addCase(beginLayer.fulfilled, (state, { payload, meta }) => {
                state.structure.temporaryLayers.unshift(payload.layer);
                if (!meta.arg.startTransaction) {
                    state.selectedObject = { type: ProjectStructureObjectTypes.LAYER, artifactId: payload.layer.id };
                }
            })
            .addCase(uploadStarted, (state, { payload }) => {
                const layer = state.structure.temporaryLayers.find(l => l.id === payload.id);
                if (layer) {
                    layer.coordinateSystemUid = payload.coordinateSystemUid;
                }
            })
            .addCase(updateLayer.fulfilled, (state, { meta }) => {
                const { layerUid, properties } = meta.arg;
                const index = state.structure.temporaryLayers.findIndex(l => l.id === layerUid);
                if (index !== -1) {
                    if (properties.name) state.structure.temporaryLayers[index].name = properties.name;
                }
            })
            .addCase(deleteGeometry.fulfilled, (state, { meta }) => {
                const { id, layerUid } = meta.arg;
                const layerIndex = state.structure.temporaryLayers.findIndex(l => l.id === layerUid);
                if (layerIndex !== -1) {
                    const index = state.structure.temporaryLayers[layerIndex].geometries.findIndex(g => g === id);
                    if (index !== -1) state.structure.temporaryLayers[layerIndex].geometries.splice(index, 1);
                }
            })
            .addCase(deleteLayer.fulfilled, (state, { meta }) => {
                const { layerUid } = meta.arg;
                let index = state.structure.temporaryLayers.findIndex(l => l.id === layerUid);
                if (index !== -1) state.structure.temporaryLayers.splice(index, 1);
            })
            .addCase(commitGeometry.fulfilled, (state, { payload, meta }) => {
                const { temporaryGeometryUid } = meta.arg;
                const { geometryId } = payload;

                const layerIndex = state.structure.temporaryLayers.findIndex(l =>
                    l.geometries.includes(temporaryGeometryUid)
                );

                if (layerIndex !== -1) {
                    const index = state.structure.temporaryLayers[layerIndex].geometries.findIndex(
                        g => g === temporaryGeometryUid
                    );
                    if (index !== -1) {
                        if (state.selectedObject.artifactId === temporaryGeometryUid)
                            state.selectedObject = {
                                type: ProjectStructureObjectTypes.GEOMETRY,
                                artifactId: geometryId
                            };
                        state.structure.temporaryLayers[layerIndex].geometries[index] = geometryId;
                    }
                }
            })
            .addCase(setSelectedObject.fulfilled, (state, { meta }) => {
                state.selectedObject = meta.arg;
            })
            .addCase(uploadAborted, (state, { payload }) => {
                const index = state.structure.temporaryLayers.findIndex(l => l.id === payload);
                if (index !== -1) {
                    state.structure.temporaryLayers.splice(index, 1);
                }
                state.selectedObject = {} as SelectedObject;
            })
            .addCase(publishProject.fulfilled, (state, { meta, payload }) => {
                const id = meta.arg.id;
                const isProcessingInProgress =
                    isLastPipelineProcessing(state.projectInfo) &&
                    (state.projectInfo.status === ProjectStatus.INPROGRESS ||
                        state.projectInfo.status === ProjectStatus.ABORTING);
                if (payload && id === state.projectInfo.id && !isProcessingInProgress) {
                    state.projectInfo.published = true;
                    state.projectInfo.status = ProjectStatus.INPROGRESS;
                    state.projectInfo.pipeline = {
                        pipelineUid: payload,
                        status: PipelineStatus.INPROGRESS
                    };
                }
            })
            .addCase(unpublishProject.fulfilled, (state, { meta }) => {
                const id = meta.arg.id;
                if (id === state.projectInfo.id) {
                    state.projectInfo.published = false;
                }
            })
            .addCase(progressTick.fulfilled, (state, { payload, meta }) => {
                const id = meta.arg.processId;
                if (payload && id === state.projectInfo.pipeline?.pipelineUid) {
                    state.projectInfo.status = payload.status;
                }
            })
            .addCase(cancelProjectProcessing.fulfilled, (state, { payload, meta }) => {
                const id = meta.arg;
                if (payload && id === state.projectInfo.pipeline?.pipelineUid) {
                    state.projectInfo.status = ProjectStatus.ABORTING;
                }
            })
            .addCase(getLayer.fulfilled, (state, { payload }) => {
                if (!state.structure.temporaryLayers.find(l => l.id === payload.layer.id)) {
                    state.structure.temporaryLayers.push(payload.layer);
                }
            })
            .addCase(unlinkDataset.pending, (state, { meta }) => {
                if (state.selectedObject?.artifactId === meta.arg.datasetId)
                    state.selectedObject = {} as SelectedObject;
            })
            .addCase(unlinkDataset.fulfilled, (state, { meta }) => {
                const { datasetId } = meta.arg;
                const index = state.structure.temporaryLayers.findIndex(l => l.id === datasetId);
                if (index !== -1) state.structure.temporaryLayers.splice(index, 1);
            })
            .addCase(addGeometry, (state, { payload }) => {
                let temporaryLayer = state.structure.temporaryLayers.find(l => l.id === payload.datasetId);
                if (!temporaryLayer) {
                    const uid = uuidv4();
                    const newColor = getRandomColorHex();
                    const layer: TemporaryLayer = {
                        geometries: [],
                        strokeColor: newColor,
                        color: newColor,
                        id: uid,
                        assetUid: uid,
                        name: `Temporary Layer ${
                            state.structure.temporaryLayers.filter(l => l.isTemporary).length + 1
                        }`,
                        isTemporary: true
                    };
                    state.structure.temporaryLayers.unshift(layer);
                    temporaryLayer = state.structure.temporaryLayers[0];
                }
                if (payload.index !== undefined) temporaryLayer.geometries.splice(payload.index, 0, payload.id);
                else temporaryLayer.geometries.unshift(payload.id);
            })
            .addCase(addGeometries, (state, { payload }) => {
                let temporaryLayer = state.structure.temporaryLayers.find(l => l.id === payload.datasetId);
                if (temporaryLayer) temporaryLayer.geometries.push(...payload.geometries.map(g => g.id));
            })
            .addCase(removeGeometry, (state, { payload }) => {
                const { id, datasetId } = payload;
                const layerIndex = state.structure.temporaryLayers.findIndex(l => l.id === datasetId);
                if (layerIndex !== -1) {
                    const index = state.structure.temporaryLayers[layerIndex].geometries.findIndex(g => g === id);
                    if (index !== -1) state.structure.temporaryLayers[layerIndex].geometries.splice(index, 1);
                }
            })
            .addCase(setSelectedCamera, (state, { payload }) => {
                if (payload?.artifactUid && payload.selectInWorkspace)
                    state.selectedObject = {
                        type: ProjectStructureObjectTypes.IMAGE,
                        artifactId: `${payload.uid}`,
                        needToScroll: true
                    };
            })
            .addCase(renameProject.pending, (state, { meta }) => {
                if (state.projectInfo.id === meta.arg.projectId) {
                    state.projectInfo.name = meta.arg.name;
                }
            })
            .addCase(moveProject.fulfilled, (state, { meta }) => {
                if (meta.arg.projectId === state.projectInfo.id) {
                    if (meta.arg.parent === 'unset') state.parent = null;
                    else state.parent = meta.arg.parent;
                }
            })
            .addCase(getLayerSize.fulfilled, (state, { meta, payload }) => {
                const layer = state.structure.temporaryLayers.find(l => l.id === meta.arg.layerId);
                if (layer) {
                    layer.sizeInBytes = payload;
                }
            });
    }
});

export const {
    resetProjectById,
    setLayers,
    addTemporaryLayer,
    deleteTemporaryLayer,
    setLayerProperty,
    moveGeometryInLayer
} = projectSlice.actions;

export default projectSlice.reducer;

export const selectChunks = createSelector(
    (state: ApplicationState) => state.project.structure.chunks,
    chunks => chunks.map(c => new ChunkPresenter(c))
);

export const selectChunksWithCameras = createSelector(
    (state: ApplicationState) => state.project.structure.chunks,
    chunks => chunks.filter(c => c.cameras?.count)
);
