import { useRouteMatch } from 'react-router-dom';
import { Routes } from '../sharedConstants';
import { useSelector } from '../store';

export default function useIsOwnedEmptySite() {
    const siteProjects = useSelector(state => state.site.siteInfo.projects);
    const isSiteViewRoute = !!useRouteMatch({ exact: true, path: Routes.SITE_VIEW });

    const isEmptySite = siteProjects.length === 0;

    return isSiteViewRoute && isEmptySite;
}
