import styles from './RadiobuttonWrapper.module.scss';

type Props = {
    children: JSX.Element; // must be <input type='radio'/>
};

export default function RadiobuttonWrapper(props: Props) {
    return (
        <label className={styles.radioWrapper}>
            {props.children}
            <span className={styles.radioStyler} />
        </label>
    );
}
