import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from '../../../sharedConstants';
import { AppDispatch } from '../../../store';
import { SelectedObject } from '../../../store/helpers/interfaces';
import { setSelectedObject } from '../../../store/sharedActions';
import { disableAllControls, setCompareToolEnabled } from '../../../store/slices/projectView';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import { useTranslation } from 'react-i18next';

export default function CompareCloseControl() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const isEmbedRoute = !!useRouteMatch({ path: Routes.EMBEDDED_PROJECT_VIEW, exact: true });

    return (
        <div className='tool-close'>
            <TippyTooltip tooltipText={t('compareMode.exitButton')}>
                <div
                    className='close-control'
                    onClick={() => {
                        dispatch(disableAllControls());
                        dispatch(setCompareToolEnabled(false));
                        dispatch(setSelectedObject({} as SelectedObject));
                    }}
                >
                    <div className={classNames('ico-x', { dark: !isEmbedRoute })} />
                </div>
            </TippyTooltip>
        </div>
    );
}
