import { createAsyncThunk } from '@reduxjs/toolkit';
import { processApi, projectsApi } from '../../api/initApis';
import { changeProjectPropertyInPage, replaceProjectInPage, selectProjects } from './projectsPage';
import { ProjectStatus } from '../../generated/cloud-frontend-api/model';
import { AppDispatch, ApplicationState } from '../index';
import { getProjectById } from './project';

const name = 'projectActions';

interface PublishProjectArgs {
    id: string;
    onProjectsPage: boolean;
}
export const publishProject = createAsyncThunk<string, PublishProjectArgs, { dispatch: AppDispatch }>(
    `${name}/publish`,
    async ({ id, onProjectsPage }, { dispatch }) => {
        const { data } = await projectsApi.publishProject(id);
        if (data) {
            if (onProjectsPage) {
                dispatch(changeProjectPropertyInPage({ id, propName: 'published', propValue: true }));
                await dispatch(replaceProjectInPage(id));
            } else {
                dispatch(getProjectById({ id }));
            }
        }
        return data;
    }
);

export const unpublishProject = createAsyncThunk<number, PublishProjectArgs, { dispatch: AppDispatch }>(
    `${name}/unpublish`,
    async ({ id, onProjectsPage }, { dispatch }) => {
        const { status } = await projectsApi.unpublishProject(id);
        if (status === 200) {
            onProjectsPage
                ? await replaceProjectOnUnpublishWithRetry(id, dispatch)
                : await dispatch(getProjectById({ id }));
        }
        return status;
    }
);

async function replaceProjectOnUnpublishWithRetry(projectId: string, dispatch: any) {
    let data = await dispatch(replaceProjectInPage(projectId));
    let attempts = 3;
    while (
        data.payload.projectInfo?.published === false &&
        data.payload.projectInfo?.parts.publication.size > 0 &&
        attempts > 0
    ) {
        data = await dispatch(replaceProjectInPage(projectId));
        attempts--;
    }
}

export const deleteProject = createAsyncThunk(`${name}/delete`, async (id: string) => {
    const { data } = await projectsApi.deleteProjectById(id);
    return data;
});

export const cancelProjectProcessing = createAsyncThunk<number, string, { state: ApplicationState }>(
    `${name}/cancelProcessing`,
    async (id, { getState, dispatch }) => {
        const projectId =
            selectProjects(getState()).find(p => p.pipeline?.pipelineUid === id)?.id ||
            getState().project.projectInfo.id!;
        const { status } = await processApi.cancelProcess(projectId, id);
        if (status === 200) {
            dispatch(
                changeProjectPropertyInPage({ id: projectId, propName: 'status', propValue: ProjectStatus.ABORTING })
            );
        }
        return status;
    }
);
