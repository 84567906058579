/* tslint:disable */
/* eslint-disable */
/**
 * document-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PresignedUrl } from '../model';
/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns download url
         * @param {string} projectUid UUID of project
         * @param {string} documentUid UUID of document
         * @param {string} [access] Access field for shared projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresignedUrl: async (projectUid: string, documentUid: string, access?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getPresignedUrl', 'projectUid', projectUid)
            // verify required parameter 'documentUid' is not null or undefined
            assertParamExists('getPresignedUrl', 'documentUid', documentUid)
            const localVarPath = `/projects/{projectUid}/documents/{documentUid}/download`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"documentUid"}}`, encodeURIComponent(String(documentUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)

            if (access !== undefined) {
                localVarQueryParameter['access'] = access;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns download url
         * @param {string} projectUid UUID of project
         * @param {string} documentUid UUID of document
         * @param {string} [access] Access field for shared projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPresignedUrl(projectUid: string, documentUid: string, access?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresignedUrl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPresignedUrl(projectUid, documentUid, access, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns download url
         * @param {string} projectUid UUID of project
         * @param {string} documentUid UUID of document
         * @param {string} [access] Access field for shared projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPresignedUrl(projectUid: string, documentUid: string, access?: string, options?: any): AxiosPromise<PresignedUrl> {
            return localVarFp.getPresignedUrl(projectUid, documentUid, access, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
    /**
     * 
     * @summary Returns download url
     * @param {string} projectUid UUID of project
     * @param {string} documentUid UUID of document
     * @param {string} [access] Access field for shared projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public getPresignedUrl(projectUid: string, documentUid: string, access?: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).getPresignedUrl(projectUid, documentUid, access, options).then((request) => request(this.axios, this.basePath));
    }
}
