import { ReadonlyDeep, RequiredDeep } from 'type-fest';
import { Artifact, CamerasArtifact, Chunk, Document, SourceType } from '../generated/cloud-frontend-api';
import { ExtendedDatasetInfo } from '../store/slices/datasetfilesUpload';
import _ from 'lodash';
import getRelated3DElevation from '../lib/getRelated3DElevation';

type ChunkInfo = ReadonlyDeep<Chunk>;

export class ChunkPresenter implements ChunkInfo {
    constructor(private readonly _chunk: Chunk) {}

    get artifacts(): Artifact[] {
        return this._chunk.artifacts ?? [];
    }
    get assetUid(): string {
        return this._chunk.assetUid ?? '';
    }
    get chunk(): number {
        return this._chunk.chunk ?? 0;
    }
    get chunkKey(): number {
        return this._chunk.chunkKey ?? 0;
    }
    get chunkName(): string {
        return this._chunk.chunkName ?? '';
    }
    get processingReport(): RequiredDeep<Document> | undefined {
        return mapDocumentToRequiredDocument(this._chunk.processingReport);
    }
    get cameras(): RequiredDeep<CamerasArtifact> {
        return {
            artifactUid: this._chunk.cameras?.artifactUid ?? '',
            assetUid: this._chunk.cameras?.assetUid ?? '',
            count: this._chunk?.cameras?.count ?? 0
        };
    }

    public getDisplayableDatasets(datasets: ExtendedDatasetInfo[]): ExtendedDatasetInfo[] {
        return _(this.artifacts)
            .map(a => datasets.find(d => d.datasetUid === a.datasetUid))
            .compact()
            .filter(
                d =>
                    d.sourceData?.type !== SourceType.DEM ||
                    getRelated3DElevation(datasets, this._chunk, d.name) === undefined
            )
            .value();
    }
}

function mapDocumentToRequiredDocument(document: Document | undefined): RequiredDeep<Document> | undefined {
    if (document)
        return {
            fileName: document.fileName ?? '',
            link: document.link ?? '',
            sizeInBytes: document.sizeInBytes ?? 0
        };
}
