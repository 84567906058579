/* format incoming number by set locale and fraction precision */
import { Units } from '../sharedConstants';
import i18n from '../i18n/config';

export function getUnitsShortName(unit: Units): string {
    switch (unit) {
        case Units.METRE: {
            return `${i18n.t('common:measureUnits_short.meter')}`;
        }
        case Units.FOOT: {
            return `${i18n.t('common:measureUnits_short.foot')}`;
        }
        case Units.US_SURVEY_FOOT: {
            return `${i18n.t('common:measureUnits_short.usFoot')}`;
        }
    }
}

export function getUnitsSquareShortName(unit: Units): string {
    switch (unit) {
        case Units.METRE: {
            return `${i18n.t('common:measureUnits_short.squareMeter')}`;
        }
        case Units.FOOT: {
            return `${i18n.t('common:measureUnits_short.squareFoot')}`;
        }
        case Units.US_SURVEY_FOOT: {
            return `${i18n.t('common:measureUnits_short.squareUsFoot')}`;
        }
    }
}

export function getUnitsCubicShortName(unit: Units): string {
    switch (unit) {
        case Units.METRE: {
            return `${i18n.t('common:measureUnits_short.cubicMeter')}`;
        }
        case Units.FOOT: {
            return `${i18n.t('common:measureUnits_short.cubicFoot')}`;
        }
        case Units.US_SURVEY_FOOT: {
            return `${i18n.t('common:measureUnits_short.cubicUsFoot')}`;
        }
    }
}
