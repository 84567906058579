import _ from 'lodash';
import { useContext, useEffect } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { useSelector } from '../../../store';
import ProjectsPlaceholder from '../../Projects/ProjectsPlaceholder';
import { Routes } from '../../../sharedConstants';

type Props = {
    visible?: boolean;
    hasQueriedForProject?: boolean;
    isAnyQueryPending?: boolean;
    setPlaceholderVisible(visible: boolean): void;
};

export default function ProjectPlaceholders({
    visible,
    hasQueriedForProject,
    isAnyQueryPending,
    setPlaceholderVisible
}: Props) {
    const location = useLocation();
    const { owned } = useContext(ProjectViewAccessContext);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const isOnline = useSelector(state => state.globalFlags.isOnline);
    const accessInfo = useSelector(state => state.sharing.accessInfo);
    const isSiteViewRoute = !!useRouteMatch({ path: Routes.SITE_VIEW, exact: true });
    const isSharedURL = location.pathname.includes('shared/projects');

    const isDeletedOrNonExistent = _.isEmpty(projectInfo) && !isSharedURL && !isSiteViewRoute;
    const isAccessDenied = !owned && accessInfo?.projectId === undefined && isSharedURL;

    useEffect(() => {
        const isPlaceholderVisible =
            hasQueriedForProject && !isAnyQueryPending && (!isOnline || isDeletedOrNonExistent || isAccessDenied);
        setPlaceholderVisible(Boolean(isPlaceholderVisible));
    }, [
        setPlaceholderVisible,
        hasQueriedForProject,
        isOnline,
        isDeletedOrNonExistent,
        isAccessDenied,
        isAnyQueryPending
    ]);

    if (!visible) return null;

    return (
        <div className='full-height content full-width' id={'content'}>
            <div className='content-inner'>
                {isOnline ? (
                    <Switch>
                        <Route path={'*'}>
                            {isDeletedOrNonExistent && <ProjectsPlaceholder type={'deleted'} />}
                            {isAccessDenied && <ProjectsPlaceholder type={'noaccess'} />}
                        </Route>
                    </Switch>
                ) : (
                    <ProjectsPlaceholder type={'offline'} />
                )}
            </div>
        </div>
    );
}
