import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AppDispatch, useSelector } from '../../../store';
import { Dataset } from '../../../generated/cloud-frontend-api';
import { Routes } from '../../../sharedConstants';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import { ReactComponent as SvgOpenNew } from '../../../svg/inspector/open_new.svg';
import PropertiesBlockHead from '../geometry-properties/elements/PropertiesBlockHead';
import {
    selectPropertiesBlockExpansion,
    updateStructureInfo,
    expandedPropertiesBlockNames
} from '../../../store/slices/structure';
import { ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';

type Props = {
    dataset: Dataset;
};

const expandedPropertyName: (typeof expandedPropertiesBlockNames)[number] = 'parentProjectBlockExpanded';

export default function ParentProjectProperty({ dataset }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const isParentProjectBlockExpanded = useSelector(state =>
        selectPropertiesBlockExpansion(state, dataset.datasetUid!, expandedPropertyName)
    );
    const linkPath = generatePath(Routes.PROJECT_VIEW, { id: dataset.parentProject?.uid! });

    return (
        <div className='inspector-properties'>
            <PropertiesBlockHead
                isBlockExpanded={isParentProjectBlockExpanded}
                title={t('projectView:inspectionSidebar.parentProject.title')}
                onClick={() => {
                    dispatch(
                        updateStructureInfo({
                            projectId: projectInfo.id!,
                            structureUid: dataset.datasetUid!,
                            type: ProjectStructureObjectTypes.DATASET,
                            propName: expandedPropertyName,
                            propValue: isParentProjectBlockExpanded ? String(false) : String(true)
                        })
                    );
                }}
            />
            {isParentProjectBlockExpanded && (
                <div className='properties-list'>
                    <div className='property'>
                        <div className='prop-name-wrapper'>
                            <b className='prop-project-name'>{dataset.parentProject?.name}</b>
                        </div>
                        <TippyTooltip tooltipText={t('projectView:inspectionSidebar.parentProject.tooltipOpen')}>
                            <a className='prop-action' rel='noopener noreferrer' target='_blank' href={linkPath}>
                                <SvgOpenNew />
                            </a>
                        </TippyTooltip>
                    </div>
                </div>
            )}
        </div>
    );
}
