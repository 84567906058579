import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SvgBackArrow } from '../../../svg/general/arrow_prev_page.svg';

import styles from './PreviousPageLink.module.scss';

export default function PreviousPageLink() {
    const history = useHistory();
    const { t } = useTranslation('cabinet');

    return (
        <div className={styles.backLinkSection}>
            <div
                className={classNames(styles.backLink, 'link-like')}
                onClick={() => {
                    history.goBack();
                }}
            >
                <div className={styles.backArrow}>
                    <SvgBackArrow />
                </div>
                <span>{t('breadcrumbs.backToPreviousPage')}</span>
            </div>
        </div>
    );
}
