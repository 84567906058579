import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Parent, ProjectInfo } from '../../../../generated/cloud-frontend-api';
import { Routes } from '../../../../sharedConstants';
import { AppDispatch } from '../../../../store';
import { deleteProject } from '../../../../store/slices/projectActions';
import { ReactComponent as SvgDelete } from '../../../../svg/dropdown_actions/delete.svg';
import DeleteProjectModal from '../../modals/DeleteProjectModal';
import TippyDropdownItemWithIcon from '../../tippy-dropdown/TippyDropdownItemWithIcon';

type Props = {
    project: ProjectInfo;
    parent: Parent | null;
};

export default function DeleteProjectAction({ project, parent }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation(['projects']);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const isCurrentFolderPage =
        !!project?.id && location.pathname === generatePath(Routes.FOLDER, { folderId: project.id });
    const isSomeCurrentSitePage =
        !!project?.id &&
        (location.pathname === generatePath(Routes.SITE, { id: project.id }) ||
            location.pathname === generatePath(Routes.SITE_VIEW, { id: project.id }));
    const isCurrentProjectViewPage =
        !!project?.id && location.pathname === generatePath(Routes.PROJECT_VIEW, { id: project.id });
    const isCurrentItemPage = isCurrentFolderPage || isSomeCurrentSitePage || isCurrentProjectViewPage;

    const history = useHistory();

    return (
        <>
            <TippyDropdownItemWithIcon
                icon={<SvgDelete />}
                text={t('projects:projectActions.delete')}
                alert
                onClick={() => {
                    setIsModalOpen(true);
                }}
            />

            <DeleteProjectModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                project={project}
                onDelete={() => {
                    dispatch(deleteProject(project.id!)).then(() => {
                        // If deleting project/folder/site from its own route, redirect to closest parent
                        if (isCurrentItemPage) {
                            if (parent?.uid) {
                                history.push(generatePath(Routes.FOLDER, { folderId: parent.uid }));
                            } else {
                                history.push(generatePath(Routes.INDEX));
                            }
                        }
                    });
                }}
            />
        </>
    );
}
