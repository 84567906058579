import * as Cesium from 'cesium';
import _ from 'lodash';

export default class HeightsSampler {
    constructor(
        private readonly _terrainProvider: Cesium.TerrainProvider,
        private readonly _scene: Cesium.Scene,
        private readonly _signal?: AbortSignal
    ) {}

    async doSampling(positions: Cesium.Cartesian3[]): Promise<Cesium.Cartographic[]> {
        const cartographics = positions.map(p => Cesium.Cartographic.fromCartesian(p));
        for (const c of _.chunk(cartographics, 50)) {
            if (this._signal?.aborted) throw new Error('aborted');
            await Cesium.sampleTerrainMostDetailed(this._terrainProvider, c);
        }
        return cartographics;
    }
}
