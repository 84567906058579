import { useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { BillboardCollection, useCesium } from 'resium';
import { useOnWindowResize } from 'rooks';
import ProjectViewAccessContext from '../../../../contexts/ProjectViewAccessContext';
import PointGeometry from '../../../../entities/geometry/PointGeometry';
import { AppDispatch, useSelector } from '../../../../store';
import { makeSelectLayerByGeometryId, selectTours } from '../../../../store/slices/geometryLayers';
import { selectHoveredPresentationSlide, selectSelectedPresentationSlide } from '../../../../store/slices/presentation';
import { setSlideDescriptionCoordinates } from '../../viewpoint-description/ViewpointDescription';
import { Tour } from './Tour';
import { selectGroups, selectObjectVisibility, isObjectVisible } from '../../../../store/slices/structure';

export default function Presentation() {
    const dispatch: AppDispatch = useDispatch();
    const { scene } = useCesium();
    const tours = useSelector(state => selectTours(state));
    const selectedSlide = useSelector(state => selectSelectedPresentationSlide(state));
    const hoveredSlide = useSelector(state => selectHoveredPresentationSlide(state));
    const isPresentationSetupEnabled = useSelector(state => state.projectView.isPresentationSetupEnabled);
    const { owned } = useContext(ProjectViewAccessContext);

    const structures = useSelector(state => state.structure.structures);

    const selectLayerByGeometryId = useMemo(makeSelectLayerByGeometryId, []);
    const layer = useSelector(state => selectLayerByGeometryId(state, selectedSlide?.id ?? ''));
    const groups = useSelector(state => selectGroups(state));
    const layerVisible = useSelector(state => selectObjectVisibility(state, layer?.id!));

    const structureInfo = structures.find(s => s.uid === layer?.id);
    const group = groups.find(g => g.uid === structureInfo?.parentUid);
    const belongsToGroup = Boolean(group);
    const groupVisible = belongsToGroup ? isObjectVisible(structures, group?.uid!) : true;

    const visible = groupVisible && layerVisible && selectedSlide?.content.properties.ac_visibility;

    useOnWindowResize(() => {
        if (selectedSlide) {
            const position2d = PointGeometry.getCoordinatesAsWindowCoordinates(
                selectedSlide.content.geometry.coordinates,
                scene!
            );
            setSlideDescriptionCoordinates(position2d);
        }
    }, !!selectedSlide);

    useEffect(() => {
        if (selectedSlide) {
            const position2d = PointGeometry.getCoordinatesAsWindowCoordinates(
                selectedSlide.content.geometry.coordinates,
                scene!
            );
            if (position2d) {
                setSlideDescriptionCoordinates(position2d);
            }
        }
    }, [selectedSlide, owned, scene, isPresentationSetupEnabled, visible]);

    useEffect(() => {
        scene?.requestRender();
    }, [hoveredSlide?.id, scene]);

    return (
        <BillboardCollection>
            {tours.map(t => (
                <Tour key={t.id} tour={t} />
            ))}
        </BillboardCollection>
    );
}
