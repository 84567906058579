import * as Cesium from 'cesium';
import React, { memo, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Entity, PolygonGraphics } from 'resium';
import { Tuple } from '../../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../../store';
import { setLimitBoxMovingFaceId } from '../../../../store/slices/projectView';
import { computeCenterOfPoints } from '../../geometry-properties/measures/GeometryMeasures';
import { LimitBoxParams } from './LimitBox';
import useAreSceneMouseEventsBlocked from '../../../../hooks/useAreSceneMouseEventsBlocked';

type Props = {
    coordinates: Tuple<Cesium.Cartesian3, 4>;
    faceId: keyof LimitBoxParams;
    setHoveredPlaneId: React.Dispatch<React.SetStateAction<keyof LimitBoxParams | null>>;
    hasHover: boolean;
};

function LimitBoxFace({ coordinates, faceId, hasHover, setHoveredPlaneId }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const movingFaceId = useSelector(state => state.projectView.limitBoxMovingFaceId);

    const color = useMemo(() => {
        if (movingFaceId === faceId) return Cesium.Color.fromAlpha(Cesium.Color.WHITE, 0.5);
        if (hasHover) return Cesium.Color.fromAlpha(Cesium.Color.WHITE, 0.3);
        return Cesium.Color.fromAlpha(Cesium.Color.WHITE, 0.2);
    }, [movingFaceId, faceId, hasHover]);

    const hierarchy = useMemo(
        () => new Cesium.CallbackProperty(() => new Cesium.PolygonHierarchy(coordinates), false),
        [coordinates]
    );

    const areMouseEventsBlocked = useAreSceneMouseEventsBlocked();

    return (
        <Entity
            onMouseDown={e => {
                if (areMouseEventsBlocked) return;
                dispatch(setLimitBoxMovingFaceId(faceId));
            }}
            onMouseEnter={e => {
                if (areMouseEventsBlocked) return;
                setHoveredPlaneId(faceId);
            }}
            onMouseLeave={e => {
                if (areMouseEventsBlocked) return;
                setHoveredPlaneId(null);
            }}
        >
            <PolygonGraphics
                hierarchy={hierarchy}
                material={color}
                arcType={Cesium.ArcType.NONE}
                heightReference={Cesium.HeightReference.NONE}
                perPositionHeight={true}
                outline={true}
                outlineColor={Cesium.Color.fromAlpha(Cesium.Color.WHITE, 1.0)}
            />
        </Entity>
    );
}

export default memo(LimitBoxFace);
