import {
    closestCenter,
    defaultDropAnimation,
    DndContext,
    DragCancelEvent,
    DragEndEvent,
    DragMoveEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    DropAnimation,
    UniqueIdentifier,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import produce from 'immer';
import { memo, ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { AppDispatch, useSelector } from '../../../store';
import { ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { setSelectedObject } from '../../../store/sharedActions';
import { moveGeometry } from '../../../store/slices/geometries';
import { moveGeometryInLayer } from '../../../store/slices/project';
import { selectFlatTree } from '../../../store/slices/projectStructure';
import {
    addStructureInfo,
    isObjectExpanded,
    putStructures,
    setStructureParent,
    setStructureProperty,
    updateStructureInfo
} from '../../../store/slices/structure';
import { PointerSensor } from '../structure-item/dndSensorsOverride';
import StructureItem from '../structure-item/StructureItem';
import { getProjection, ProjectionContext, ProjectionContextValue } from './dndUtilities';
import { TreeNode } from './StructureTree';

type Props = {
    children: ReactNode;
};

const dropAnimationConfig: DropAnimation = {
    keyframes({ transform }) {
        return [
            { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
            {
                opacity: 0,
                transform: CSS.Transform.toString({
                    ...transform.final,
                    x: transform.final.x + 5,
                    y: transform.final.y + 5
                })
            }
        ];
    },
    easing: 'ease-out',
    sideEffects({ active }) {
        active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
            duration: defaultDropAnimation.duration,
            easing: defaultDropAnimation.easing
        });
    }
};

export const draggableObjectTypes: ProjectStructureObjectTypes[] = [
    ProjectStructureObjectTypes.DATASET,
    ProjectStructureObjectTypes.GROUP,
    ProjectStructureObjectTypes.LAYER,
    ProjectStructureObjectTypes.GEOMETRY
];

function TreeDragDrop({ children }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { owned } = useContext(ProjectViewAccessContext);
    const flatTree = useSelector(selectFlatTree);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const structures = useSelector(state => state.structure.structures);
    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
    const [offsetLeft, setOffsetLeft] = useState(0);
    const wasExpandedBeforeDragging = useRef(false);
    const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 5 } }));

    const activeItem = activeId ? flatTree.find(n => n.id === activeId) : null;

    const projected = useMemo(
        () => (activeId && overId ? getProjection(flatTree, activeId, overId, offsetLeft, 64) : null),
        [activeId, flatTree, offsetLeft, overId]
    );

    const isDropValid: () => boolean = useCallback(() => {
        const overItem = flatTree.find(n => n.id === overId);
        if (!activeItem || !overItem || !projected) return false;

        if (
            activeItem.type !== ProjectStructureObjectTypes.GEOMETRY &&
            overItem.type === ProjectStructureObjectTypes.GEOMETRY &&
            projected.depth! > 1
        )
            return false;

        if (
            activeItem.type !== ProjectStructureObjectTypes.GEOMETRY &&
            (overItem.type === ProjectStructureObjectTypes.DATASET ||
                overItem.type === ProjectStructureObjectTypes.LAYER) &&
            projected.depth! > 1
        )
            return false;

        if (activeItem.type === ProjectStructureObjectTypes.GEOMETRY) {
            if (overItem.type !== ProjectStructureObjectTypes.GEOMETRY) return false;

            if (overItem.parentId !== activeItem.parentId) return false; // May only move geometry inside same layer

            if (projected.depth! !== activeItem.nestingLevel) return false;
        }

        return true;
    }, [activeItem, overId, projected, flatTree]);

    const memoizedContextValue: ProjectionContextValue = useMemo(
        () => ({
            depth: projected ? projected.depth : null,
            parentId: projected ? projected.parentId : null,
            isValid: isDropValid()
        }),
        [projected, isDropValid]
    );

    function resetState() {
        setActiveId(null);
        setOverId(null);
        setOffsetLeft(0);
        document.body.style.setProperty('cursor', '');
    }

    function restoreCollapsedItemState(id: string): boolean {
        const wasExpandedBeforeDraggingCopy = wasExpandedBeforeDragging.current;
        if (wasExpandedBeforeDragging.current) {
            dispatch(
                setStructureProperty({ id, propName: 'expanded', propValue: String(wasExpandedBeforeDragging.current) })
            );
            wasExpandedBeforeDragging.current = false;
        }
        return wasExpandedBeforeDraggingCopy;
    }

    async function onDragEnd({ active, over }: DragEndEvent) {
        resetState();
        if (!over || !isDropValid()) return;

        const destinationNode = flatTree.find(n => n.id === over.id);
        const sourceNode = flatTree.find(n => n.id === active.id);
        const projected = active?.id && over.id ? getProjection(flatTree, active?.id, over.id, offsetLeft, 50) : null;
        const structuresIterable = [...structures];

        if (!sourceNode || !destinationNode || !projected) return;

        if (sourceNode.type === ProjectStructureObjectTypes.GEOMETRY) {
            handleGeometry(sourceNode, destinationNode, flatTree);
        } else {
            handleNonGeometry(sourceNode, destinationNode, projected);
        }

        function handleNonGeometry(
            sourceNode: TreeNode,
            destinationNode: TreeNode,
            projected: Omit<ProjectionContextValue, 'isValid'>
        ) {
            const sourceStructureInfo = structures.find(s => s.uid === sourceNode.id);
            const newParentUid = projected.parentId || projectInfo.id;

            if (!sourceStructureInfo) {
                const structureInfo = {
                    uid: sourceNode.id,
                    parentUid: newParentUid!,
                    properties: { visible: true.toString() }
                };
                dispatch(addStructureInfo(structureInfo));
                structuresIterable.push(structureInfo);
            } else {
                dispatch(setStructureParent({ id: sourceNode.id, parentId: newParentUid! }));
            }

            const getOrder = (node: TreeNode) =>
                parseInt(structuresIterable.find(s => s.uid === node.id)?.properties?.order || '0');

            const reorderedFlatTree = arrayMove(
                flatTree,
                flatTree.indexOf(sourceNode),
                flatTree.indexOf(destinationNode)
            );
            const orders = reorderedFlatTree
                .filter(
                    n =>
                        n.type !== ProjectStructureObjectTypes.CHUNK &&
                        n.type !== ProjectStructureObjectTypes.CAMERAS &&
                        n.type !== ProjectStructureObjectTypes.ARTIFACT &&
                        n.type !== ProjectStructureObjectTypes.GEOMETRY
                )
                .map(n => ({
                    name: n.name,
                    id: n.id,
                    type: n.type,
                    order: getOrder(n)
                }));

            orders.forEach((o, i) => {
                o.order = i - orders.length;
                dispatch(setStructureProperty({ id: o.id, propValue: String(o.order), propName: 'order' }));
            });

            if (owned) {
                const newStructures = structuresIterable.map(s =>
                    produce(s, draft => {
                        if (s.uid === sourceNode.id) {
                            draft.parentUid = newParentUid!;
                            draft.properties.expanded = String(restoreCollapsedItemState(active.id as string));
                        }
                        const order = orders.find(o => o.id === s.uid);
                        if (order) {
                            draft.properties.order = String(order.order);
                        }
                    })
                );
                dispatch(putStructures({ projectId: projectInfo.id!, structureInfo: newStructures }));
            }
        }

        function handleGeometry(sourceNode: TreeNode, destinationNode: TreeNode, flatTree: TreeNode[]) {
            const layerNode = flatTree.find(f => f.id === sourceNode.parentId)!;
            const sourceIndex = layerNode.children.findIndex(c => c.id === sourceNode.id);
            const destinationIndex = layerNode.children.findIndex(c => c.id === destinationNode.id);

            if (sourceIndex !== -1 && destinationIndex !== -1) {
                const isMovingUpwards = sourceIndex >= destinationIndex;
                const afterIndex = isMovingUpwards && destinationIndex !== 0 ? destinationIndex - 1 : destinationIndex;
                const afterUid = layerNode.children[afterIndex]!.id;

                dispatch(
                    moveGeometryInLayer({ layerUid: sourceNode.parentId!, from: sourceIndex, to: destinationIndex })
                );

                if (owned) {
                    dispatch(
                        moveGeometry({
                            projectUid: projectInfo.id!,
                            layerUid: sourceNode?.parentId!,
                            geometryUid: sourceNode.id,
                            afterUid: destinationIndex === 0 ? undefined : afterUid
                        })
                    );
                }
            }
        }
    }

    function onDragStart({ active }: DragStartEvent) {
        const node = flatTree.find(n => n.id === (active.id as string));
        dispatch(setSelectedObject({ artifactId: active.id as string, type: node?.type! }));
        document.body.style.setProperty('cursor', 'grabbing');
        setActiveId(active.id);
        setOverId(active.id);

        const hasChildren = node?.children.length;

        // Close the group or layer while dragging
        if (hasChildren) {
            const expanded = isObjectExpanded(structures, active.id as string);
            dispatch(setStructureProperty({ id: active.id as string, propName: 'expanded', propValue: String(false) }));
            wasExpandedBeforeDragging.current = expanded;
        }
    }

    function onDragCancel({ active }: DragCancelEvent) {
        resetState();
        restoreCollapsedItemState(active.id as string);
    }

    function onDragOver({ over, active }: DragOverEvent) {
        setOverId(over?.id ?? null);
        // Expand the group if it is collapsed
        const overNode = flatTree.find(t => t.id === over?.id);
        const activeNode = flatTree.find(t => t.id === active?.id);
        const isGroup = overNode?.type === ProjectStructureObjectTypes.GROUP;
        if (
            activeNode?.type !== ProjectStructureObjectTypes.GROUP &&
            isGroup &&
            !isObjectExpanded(structures, overNode.id)
        ) {
            dispatch(
                updateStructureInfo({
                    type: ProjectStructureObjectTypes.GROUP,
                    projectId: projectInfo.id!,
                    propValue: String(true),
                    propName: 'expanded',
                    structureUid: overNode.id
                })
            );
        }
    }

    function onDragMove({ delta }: DragMoveEvent) {
        setOffsetLeft(delta.x);
    }

    return (
        <DndContext
            collisionDetection={closestCenter}
            sensors={sensors}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            onDragCancel={onDragCancel}
            onDragOver={onDragOver}
            onDragMove={onDragMove}
        >
            <SortableContext items={flatTree} strategy={verticalListSortingStrategy}>
                <ProjectionContext.Provider value={memoizedContextValue}>{children}</ProjectionContext.Provider>
                {createPortal(
                    <DragOverlay dropAnimation={dropAnimationConfig}>
                        {activeId && activeItem ? (
                            <StructureItem
                                setSortingEnabled={() => {}}
                                dragClone
                                data={activeItem}
                                isParentSelected={() => false}
                                isParentVisible={() => true}
                            />
                        ) : null}
                    </DragOverlay>,
                    document.body
                )}
            </SortableContext>
        </DndContext>
    );
}

export default TreeDragDrop;
