/* 
    Компонент-обертка Tippy Tooltip, поддерживающий как ховер, так и клик-события с таймаутом по времени показа.
    (!) Для правильной обработки переносов, строки с их наличием необходимо передавать в формате шаблонной строки: {`строка1\nстрока2`}
*/
import React, { useState, useEffect, ReactElement, cloneElement, SyntheticEvent } from 'react';
import Tippy, { TippyProps } from '@tippyjs/react/headless';
import { IS_TOUCH_DEVICE } from '../../../sharedConstants';
import classNames from 'classnames';

const TIMEOUT_VALUE = 3000;

let timeout: number;

interface Props extends TippyProps {
    tooltipText: string;
    children: ReactElement;
    disableHover?: boolean;
    allowFullWidth?: boolean;
}

const TippyTooltipTimed: React.FC<Props> = ({
    tooltipText,
    children,
    disableHover = false,
    allowFullWidth = false,
    ...props
}): JSX.Element => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const clonedElement = cloneElement(children, {
        onClick: (e: SyntheticEvent) => {
            handleTooltipVisibility();
            children.props.onClick && children.props.onClick(e);
        },
        onMouseEnter: (e: SyntheticEvent) => {
            if (!IS_TOUCH_DEVICE && !disableHover) {
                setIsTooltipVisible(true);
            }
            children.props.onMouseEnter && children.props.onMouseEnter(e);
        },
        onMouseLeave: (e: SyntheticEvent) => {
            if (!IS_TOUCH_DEVICE && !disableHover) {
                setIsTooltipVisible(false);
            }
            children.props.onMouseLeave && children.props.onMouseLeave(e);
        }
    });

    function handleTooltipVisibility() {
        if (!isTooltipVisible) {
            setIsTooltipVisible(true);
            timeout = window.setTimeout(() => {
                setIsTooltipVisible(false);
            }, TIMEOUT_VALUE);
        } else {
            closeTooltip();
        }
    }

    function closeTooltip() {
        setIsTooltipVisible(false);
        clearTimeout(timeout);
    }

    useEffect(() => {
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <Tippy
            render={attrs => (
                <div className={classNames('tippy-tooltip', { 'full-width': allowFullWidth })} tabIndex={-1} {...attrs}>
                    <span>{tooltipText}</span>
                </div>
            )}
            delay={[0, 0]}
            offset={[0, 8]}
            touch={true}
            visible={isTooltipVisible}
            onClickOutside={closeTooltip}
            {...props}
        >
            {clonedElement}
        </Tippy>
    );
};

export default TippyTooltipTimed;
