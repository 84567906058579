import { CoordinateSystemType, Units } from '../sharedConstants';
import { DeterminedCoordinateSystem } from '../store/slices/coordinateSystems';
import { getUnitsShortName } from './getUnitsShortName';
import proj4 from 'proj4';
import * as Cesium from 'cesium';
import i18n from '../i18n/config';
import { convertPointUnit } from './convertUnit';
// @ts-expect-error no typings
import wktParser from 'wkt-parser';

const EPSG_WGS84_CARTOGRAPHIC = 4326;
const EPSG_WGS84_GEOCENTRIC = 4328;

export function getRelatedAxis(crsType: CoordinateSystemType | undefined): string[] {
    switch (crsType) {
        case CoordinateSystemType.GEOCENTRIC: {
            return [
                `${i18n.t('projectView:bottomBar.x')}`,
                `${i18n.t('projectView:bottomBar.y')}`,
                `${i18n.t('projectView:bottomBar.z')}`
            ];
        }
        case CoordinateSystemType.PROJECTED: {
            return [
                `${i18n.t('projectView:bottomBar.e')}`,
                `${i18n.t('projectView:bottomBar.n')}`,
                `${i18n.t('projectView:bottomBar.alt')}`
            ];
        }
        case CoordinateSystemType.GEOGRAPHIC:
        case undefined: {
            return [
                `${i18n.t('projectView:bottomBar.lat')}`,
                `${i18n.t('projectView:bottomBar.lon')}`,
                `${i18n.t('projectView:bottomBar.alt')}`
            ];
        }
    }
}

export function getRelatedAxisFullName(crsType: CoordinateSystemType | undefined): string[] {
    switch (crsType) {
        case CoordinateSystemType.GEOCENTRIC: {
            return [
                `${i18n.t('projectView:geometryProperties.coordinateAxis.x')}`,
                `${i18n.t('projectView:geometryProperties.coordinateAxis.y')}`,
                `${i18n.t('projectView:geometryProperties.coordinateAxis.z')}`
            ];
        }
        case CoordinateSystemType.PROJECTED: {
            return [
                `${i18n.t('projectView:geometryProperties.coordinateAxis.easting')}`,
                `${i18n.t('projectView:geometryProperties.coordinateAxis.northing')}`,
                `${i18n.t('projectView:geometryProperties.coordinateAxis.altitude')}`
            ];
        }
        case CoordinateSystemType.GEOGRAPHIC:
        case undefined: {
            return [
                `${i18n.t('projectView:geometryProperties.coordinateAxis.latitude')}`,
                `${i18n.t('projectView:geometryProperties.coordinateAxis.longitude')}`,
                `${i18n.t('projectView:geometryProperties.coordinateAxis.altitude')}`
            ];
        }
    }
}

function formatCoordinates(coord: Cesium.Cartesian3, coordinateSystem: DeterminedCoordinateSystem): string[] {
    const numberOfDigits = getNumberOfDigitsForCoord(coordinateSystem.type!);
    const x = isGeographicCrsType(coordinateSystem.type) ? coord.y : coord.x;
    const y = isGeographicCrsType(coordinateSystem.type) ? coord.x : coord.y;
    return [x.toFixed(numberOfDigits), y.toFixed(numberOfDigits), coord.z.toFixed(3)];
}

export function formatCoordinatesWithUnits(
    coord: Cesium.Cartesian3,
    coordinateSystem: DeterminedCoordinateSystem,
    units: Units
): string[] {
    const formattedCoords = formatCoordinates(coord, coordinateSystem);
    const unitsNaming = getUnitsShortName(units);
    return [
        formatCoordWithUnit(formattedCoords[0], unitsNaming, coordinateSystem.type),
        formatCoordWithUnit(formattedCoords[1], unitsNaming, coordinateSystem.type),
        `${formattedCoords[2]} ${unitsNaming}`
    ];
}

export function formatCoordinatesWithAxis(
    coord: Cesium.Cartesian3,
    coordinateSystem: DeterminedCoordinateSystem,
    units: Units
): string[] {
    const formattedCoordsWithUnits = formatCoordinatesWithUnits(coord, coordinateSystem, units);
    return [
        `${coordinateSystem.axis![0]}: ${formattedCoordsWithUnits[0]}`,
        `${coordinateSystem.axis![1]}: ${formattedCoordsWithUnits[1]}`,
        `${coordinateSystem.axis![2]}: ${formattedCoordsWithUnits[2]}`
    ];
}
function formatCoordWithUnit(value: string, unitsNaming: string, csType?: CoordinateSystemType) {
    return isGeographicCrsType(csType) ? `${value}` : `${value} ${unitsNaming}`;
}

function isGeographicCrsType(csType?: CoordinateSystemType): boolean {
    return csType === CoordinateSystemType.GEOGRAPHIC;
}

function getNumberOfDigitsForCoord(csType: CoordinateSystemType): number {
    switch (csType) {
        case CoordinateSystemType.GEOCENTRIC:
        case CoordinateSystemType.PROJECTED: {
            return 3;
        }
        case CoordinateSystemType.GEOGRAPHIC: {
            return 6;
        }
    }
}

export function getCrsProperty(crsWKT: string):
    | {
          type: CoordinateSystemType;
          unit: Units;
          wkt: string;
          invalidateHeight: boolean;
      }
    | undefined {
    try {
        let crsJSON = wktParser(crsWKT);
        let wkt = crsWKT;
        let invalidateVerticalCoord = false;
        if (crsJSON.type === 'COMPD_CS') {
            const horizontalCrsPrefix = getHorizontalPartTypeOfCompoundCrs(crsJSON);
            const horizontalWkt = extractSubWkt(crsWKT, horizontalCrsPrefix!);
            if (!horizontalCrsPrefix || !horizontalWkt) {
                console.warn('Unsupported compound crs');
                return undefined;
            }
            wkt = horizontalWkt;
            crsJSON = wktParser(horizontalWkt);
            invalidateVerticalCoord = true;
        }
        let crsType: CoordinateSystemType | undefined = undefined;
        switch (crsJSON.type) {
            case 'PROJCS': {
                crsType = CoordinateSystemType.PROJECTED;
                break;
            }
            case 'GEOCCS': {
                crsType = CoordinateSystemType.GEOCENTRIC;
                break;
            }
            case 'GEOGCS': {
                crsType = CoordinateSystemType.GEOGRAPHIC;
                break;
            }
            default: {
                console.warn('Undefined type of selected CRS');
                return undefined;
            }
        }
        let crsUnit = Units.METRE;
        if (crsJSON.UNIT?.AUTHORITY?.EPSG)
            switch (crsJSON.UNIT.AUTHORITY.EPSG) {
                case '9001': {
                    crsUnit = Units.METRE;
                    break;
                }
                case '9002': {
                    crsUnit = Units.FOOT;
                    break;
                }
                case '9003': {
                    crsUnit = Units.US_SURVEY_FOOT;
                    break;
                }
                default: {
                    console.warn('Undefined type of unit in selected CRS');
                    crsUnit = Units.METRE;
                }
            }
        return { type: crsType, unit: crsUnit, wkt: wkt, invalidateHeight: invalidateVerticalCoord };
    } catch (e) {
        console.warn('Failed to parse crs wkt');
        return undefined;
    }
}

const buildGeocentricProjStringFromWKT = (crsJSON: any) => {
    let datum;

    if (crsJSON.type === 'PROJCS') {
        datum = crsJSON.GEOGCS.DATUM;
    } else {
        datum = crsJSON.DATUM;
    }

    const a = datum.SPHEROID.a;

    const rf = datum.SPHEROID.rf;

    const b = (1.0 - 1.0 / rf) * a;

    let towgs84 = datum.TOWGS84 ? datum.TOWGS84.toString() : '0,0,0,0,0,0,0';

    const geoccsProjString = `+proj=geocent +a=${a} +b=${b}  +towgs84=${towgs84}`;

    return geoccsProjString;
};

const WGS84_GEOCENTRIC_WKT =
    'GEOCCS["WGS 84 (geocentric)",DATUM["World Geodetic System 1984 ensemble",SPHEROID["WGS 84",6378137,298.257223563,AUTHORITY["EPSG","7030"]],TOWGS84[0,0,0,0,0,0,0],AUTHORITY["EPSG","6326"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AUTHORITY["EPSG","4328"]]';

export function transformCoordinates(
    coordinates: Cesium.Cartesian3 | undefined,
    coordinateSystem: DeterminedCoordinateSystem,
    units: Units
): Cesium.Cartesian3 | undefined {
    if (!coordinates) {
        return undefined;
    }
    try {
        const pointInTargetCrs = transformPointFromCartesian(coordinates, coordinateSystem);
        if (pointInTargetCrs && coordinateSystem.type && coordinateSystem.defaultUnit) {
            const pointInTargetUnit = convertPointUnit(
                [pointInTargetCrs.x, pointInTargetCrs.y, pointInTargetCrs.z],
                coordinateSystem.type,
                coordinateSystem.defaultUnit,
                units
            );
            return Cesium.Cartesian3.fromArray(pointInTargetUnit);
        }
    } catch (error: any) {
        return undefined;
    }
    return undefined;
}

function transformPointFromCartesian(
    cartesian3: Cesium.Cartesian3,
    crs: DeterminedCoordinateSystem
): Cesium.Cartesian3 | undefined {
    if (crs.epsgCode === EPSG_WGS84_GEOCENTRIC) {
        return cartesian3;
    } else if (crs.epsgCode === EPSG_WGS84_CARTOGRAPHIC) {
        return wgs84CartesianToCartographic(cartesian3);
    }
    if (crs.wkt)
        return Cesium.Cartesian3.fromArray(transformPoint([cartesian3.x, cartesian3.y, cartesian3.z], crs.wkt));
    return undefined;
}

function wgs84CartesianToCartographic(cartesian: Cesium.Cartesian3): Cesium.Cartesian3 | undefined {
    const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
    return Cesium.Cartesian3.fromArray([
        Cesium.Math.toDegrees(cartographic.longitude),
        Cesium.Math.toDegrees(cartographic.latitude),
        cartographic.height
    ]);
}

export function transformPointFromCartesianUsingWkt(cartesian3: number[], targetWKT: string) {
    return transformPoint(cartesian3, targetWKT, WGS84_GEOCENTRIC_WKT);
}

function transformWgs84PointToTargetGeocentric(
    point: number[],
    targetJson: any,
    targetGeocentricProjString: string
): number[] {
    const wgs84Datum = [0, 0, 0, 0, 0, 0, 0];
    const targetDatum = targetJson.DATUM || targetJson.GEOGCS.DATUM;
    const sameDatums = !targetDatum.TOWGS84 || wgs84Datum.toString() === targetDatum.TOWGS84.toString();

    if (sameDatums) {
        return point;
    }
    const sourceGeocentricProjString = getGeoccsProjStringWgs84();
    //Transform point from source geocentric CRS to target geocentric CRS

    return proj4(sourceGeocentricProjString, targetGeocentricProjString).forward([point[0], point[1], point[2]]);
}

function transformPoint(point: number[], targetWKT: string, sourceWKT?: string) {
    const targetJson = wktParser(targetWKT);

    if (!isCrsTypeKnown(targetJson.type)) {
        throw new Error('Unsupported transformation: unknown target crs type');
    }
    const targetGeocentricProjString = buildGeocentricProjStringFromWKT(targetJson);
    const sourceJson = sourceWKT ? wktParser(sourceWKT) : undefined;

    const pointInTargetGeocentricCRS = sourceWKT
        ? transformGeocentricToGeocentric(
              point,
              sourceJson,
              targetJson,
              buildGeocentricProjStringFromWKT(sourceJson),
              targetGeocentricProjString
          )
        : transformWgs84PointToTargetGeocentric(point, targetJson, targetGeocentricProjString);

    if (isGeocentric(targetJson.type)) {
        return pointInTargetGeocentricCRS;
    }

    if (isGeographic(targetJson.type)) {
        return transformGeocentricToGeographic(
            pointInTargetGeocentricCRS,
            targetGeocentricProjString,
            targetWKT,
            targetJson
        );
    }

    const targetGeographicProjString = buildGeographicWktFromProjectedWkt(targetJson);
    const targetGEOGCSJSON = wktParser(targetGeographicProjString);
    let pointInTargetGeographicCRS = transformGeocentricToGeographic(
        pointInTargetGeocentricCRS,
        targetGeocentricProjString,
        targetGeographicProjString,
        targetGEOGCSJSON
    );

    //Transform point from target geographic CRS to target projected CRS
    let pointInTargetProjectedCRS = proj4(targetGeographicProjString, targetWKT).forward(
        pointInTargetGeographicCRS.slice(0, 3)
    );

    if (targetJson.UNIT.convert !== 0) {
        pointInTargetProjectedCRS = [
            pointInTargetProjectedCRS[0],
            pointInTargetProjectedCRS[1],
            // As we get point altitude by transforming source geoccs to target geogcs, and altitudes in geogcs are always in meters,
            // we have to convert meters to feet in case of target projcs units are feet or us survey feet
            pointInTargetProjectedCRS[2] * (1 / targetJson.UNIT.convert)
        ];
    }

    return pointInTargetProjectedCRS;
}

function getHorizontalPartTypeOfCompoundCrs(crsJSON: any): string | undefined {
    if (!crsJSON.VERT_CS) return undefined;
    if (crsJSON.GEOGCS) return 'GEOGCS';
    if (crsJSON.GEOCCS) return 'GEOCCS';
    if (crsJSON.PROJCS) return 'PROJCS';
}

function extractSubWkt(wkt: string, prefix: string): string | undefined {
    const startIndex = wkt.indexOf(prefix);
    if (startIndex === -1) {
        return undefined;
    }
    const result = wkt.substring(startIndex);
    const openSquare = '[';
    const closeSquare = ']';
    let index = prefix.length;
    while (index < result.length && result.charAt(index) !== openSquare) index++;
    let counter = 1;
    index++;
    while (index < result.length && counter > 0) {
        if (result.charAt(index) === openSquare) counter += 1;
        if (result.charAt(index) === closeSquare) counter -= 1;
        index++;
    }
    if (counter === 0) {
        return result.substring(0, index);
    }
    return undefined;
}

function isGeocentric(type: string) {
    return type === 'GEOCCS';
}

function isGeographic(type: string) {
    return type === 'GEOGCS';
}

function isProjected(type: string) {
    return type === 'PROJCS';
}

function isCrsTypeKnown(type: string) {
    return isGeocentric(type) || isGeographic(type) || isProjected(type);
}

function transformGeocentricToGeocentric(
    point: number[],
    sourceJson: any,
    targetJson: any,
    sourceGeocentricProjString: string,
    targetGeocentricProjString: string
): number[] {
    if (!isGeocentric(sourceJson.type)) {
        throw new Error('Unsupported transformation: source crs is not geocentric');
    }
    const sourceDatum = sourceJson.DATUM || sourceJson.GEOGCS.DATUM;
    const targetDatum = targetJson.DATUM || targetJson.GEOGCS.DATUM;
    if (!sourceDatum.TOWGS84) sourceDatum.TOWGS84 = [0, 0, 0, 0, 0, 0, 0];
    if (!targetDatum.TOWGS84) targetDatum.TOWGS84 = [0, 0, 0, 0, 0, 0, 0];
    const sameDatums = sourceDatum.TOWGS84.toString() === targetDatum.TOWGS84.toString();

    if (sameDatums) {
        return point;
    }

    //Transform point from source geocentric CRS to target geocentric CRS
    return proj4(sourceGeocentricProjString, targetGeocentricProjString).forward([point[0], point[1], point[2]]);
}

function transformGeocentricToGeographic(
    pointInTargetGeocentricCRS: number[],
    targetGeocentricProjString: string,
    targetGeographicWkt: string,
    targetJSON: any
): number[] {
    let pointInTargetGeographicCRS = proj4(targetGeocentricProjString, targetGeographicWkt).forward(
        pointInTargetGeocentricCRS.slice(0, 3)
    );
    let targetPrimeMeridian = targetJSON.PRIMEM;
    if (targetPrimeMeridian.convert !== 0) {
        pointInTargetGeographicCRS = applyTargetMeridian(pointInTargetGeographicCRS, targetPrimeMeridian.convert);
    }
    return pointInTargetGeographicCRS;
}

function applyTargetMeridian(point: number[], meridianConvert: number) {
    return [point[0] - meridianConvert, point[1], point[2]];
}

function getGeoccsProjStringWgs84(): string {
    const a = Cesium.Ellipsoid.WGS84.radii.x;
    const b = Cesium.Ellipsoid.WGS84.radii.z;

    return `+proj=geocent +a=${a} +b=${b}  +towgs84=0,0,0,0,0,0,0`;
}

function buildGeographicWktFromProjectedWkt(crsJSON: any) {
    return `
        GEOGCS["${crsJSON.GEOGCS.name}",
            DATUM["${crsJSON.GEOGCS.DATUM.name}",
                SPHEROID["${crsJSON.GEOGCS.DATUM.SPHEROID.name}",${crsJSON.GEOGCS.DATUM.SPHEROID.a},${
        crsJSON.GEOGCS.DATUM.SPHEROID.rf
    },
                    AUTHORITY["EPSG","${crsJSON.GEOGCS.DATUM.SPHEROID.AUTHORITY || ''}"]],
                TOWGS84[${crsJSON.GEOGCS.DATUM.TOWGS84 || '0,0,0,0,0,0,0'}],
                AUTHORITY["EPSG","${crsJSON.GEOGCS.DATUM.AUTHORITY || ''}"]],
            PRIMEM["${crsJSON.GEOGCS.PRIMEM.name}",${crsJSON.GEOGCS.PRIMEM.convert},
                AUTHORITY["EPSG","${crsJSON.GEOGCS.PRIMEM.AUTHORITY?.EPSG || ''}"]],
            UNIT["${crsJSON.GEOGCS.UNIT.name}",${crsJSON.GEOGCS.UNIT.convert},
                AUTHORITY["EPSG","${crsJSON.GEOGCS.UNIT.AUTHORITY?.EPSG || ''}"]],
            AUTHORITY["EPSG","${crsJSON.GEOGCS.AUTHORITY?.EPSG || ''}"]]

    `;
}
