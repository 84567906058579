import classNames from 'classnames';
import _ from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useOutsideClickRef } from 'rooks';
import ProjectViewAccessContext from '../../../../contexts/ProjectViewAccessContext';
import useDemSurfaceName from '../../../../hooks/useDemSurfaceName';
import { convertLengthUnit, convertVolumeUnit } from '../../../../lib/convertUnit';
import { getUnitsCubicShortName, getUnitsShortName } from '../../../../lib/getUnitsShortName';
import { GeometryTypes, TerrainViewModes, Units, WGS84_EPSG_CODE } from '../../../../sharedConstants';
import { TemporaryGeometry } from '../../../../store/helpers/interfaces';
import { AppDispatch, useSelector } from '../../../../store/index';
import { selectedObjectInfoSelector } from '../../../../store/selectors/index';
import { isGeometryPropertiesBlockExpanded } from '../../../../store/slices/geometries';
import { makeSelectLayerByGeometryId, updateGeometryPropertyById } from '../../../../store/slices/geometryLayers';
import { ReactComponent as SvgEdit } from '../../../../svg/volume/edit.svg';
import TippyTooltip from '../../../Elements/tippy-tooltip/TippyTooltip';
import CopyPropertiesControl from '../elements/CopyPropertiesControl';
import VolumePropertiesList from './VolumePropertiesList';
import VolumeTool from './VolumeTool';
import PropertiesBlockHead from '../elements/PropertiesBlockHead';

type Props = {
    isLinked: boolean;
};

const precision = 3;

export default function Volume({ isLinked }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const [showTool, setShowTool] = useState(false);
    const { owned } = useContext(ProjectViewAccessContext);
    const polygon = useSelector(state => selectedObjectInfoSelector(state)) as TemporaryGeometry<GeometryTypes.POLYGON>;
    const currentlyDrawingShapeId = useSelector(state => state.projectView.currentlyDrawingShapeId);
    const selectLayerByGeometryId = useMemo(makeSelectLayerByGeometryId, []);
    const layer = useSelector(state => selectLayerByGeometryId(state, polygon.id));
    const terrainViewMode = useSelector(state => state.projectView.terrainViewMode);
    const isModelView = terrainViewMode === TerrainViewModes.MODEL;
    const {
        ac_volume_above_cubic_meters,
        ac_volume_base_level_meters,
        ac_volume_base_plane,
        ac_volume_below_cubic_meters,
        ac_volume_total_cubic_meters,
        ac_volume_surface
    } = polygon.content.properties;
    const units = useSelector(state => state.coordinateSystems.units);
    const unitsNaming = getUnitsShortName(units);
    const unitsCubicNaming = getUnitsCubicShortName(units);
    const coordinateSystem = useSelector(store => store.coordinateSystems.currentCrs);
    const isWgs84 = coordinateSystem.epsgCode === WGS84_EPSG_CODE;

    const volumeAbove = formatVolume(ac_volume_above_cubic_meters, units, unitsCubicNaming);
    const volumeBelow = formatVolume(ac_volume_below_cubic_meters, units, unitsCubicNaming);
    const volumeTotal = formatVolume(ac_volume_total_cubic_meters, units, unitsCubicNaming);
    const baseLevel = formatBaseLevel(ac_volume_base_level_meters, units, unitsNaming);
    const surfaceName = useDemSurfaceName(ac_volume_surface);

    function formatVolume(value: number | undefined, units: Units, unitsCubicNaming: string) {
        if (typeof value === 'number') {
            const convertedVolume = convertVolumeUnit(value, Units.METRE, units);
            return `${convertedVolume?.toFixed(precision)} ${unitsCubicNaming}`;
        }
    }

    function formatBaseLevel(value: number | 'Varies' | undefined, units: Units, unitsNaming: string) {
        if (value !== undefined) {
            return typeof value === 'number'
                ? `${convertLengthUnit(value, Units.METRE, units).toFixed(precision)} ${unitsNaming}`
                : value;
        }
    }

    const hasCalculatedVolume = !!ac_volume_base_plane;
    const hasValidValues =
        (Number.isFinite(ac_volume_base_level_meters) || typeof ac_volume_base_level_meters === 'string') &&
        [ac_volume_above_cubic_meters, ac_volume_below_cubic_meters, ac_volume_total_cubic_meters].every(_.isFinite) &&
        (ac_volume_above_cubic_meters as number) >= 0 &&
        (ac_volume_below_cubic_meters as number) >= 0;

    const canEdit = (owned && !isLinked) || layer?.isTemporary;
    const hasNotFinishedDrawing = currentlyDrawingShapeId === polygon.id;

    const isVisible = (owned && !isLinked) || (isLinked && hasValidValues) || layer?.isTemporary;

    const [volumeRef] = useOutsideClickRef(() => {
        setShowTool(false);
    });

    const clipboardText = `Volume surface\t${surfaceName || '-'}\nVolume base plane\t${ac_volume_base_plane}\n${
        isWgs84 && `Volume base level\t${baseLevel}\n`
    }Volume above\t${volumeAbove}\nVolume below\t${volumeBelow}\nVolume total\t${volumeTotal}`;

    if (!isVisible) return null;

    const volumeBlockExpanded = isGeometryPropertiesBlockExpanded(polygon.content, 'ac_volume_block_expanded');

    function setBlockExpansion(expanded: boolean) {
        dispatch(
            updateGeometryPropertyById({
                id: polygon.id,
                owned,
                propName: 'ac_volume_block_expanded',
                propValue: expanded
            })
        );
    }

    return (
        <div className='inspector-properties' ref={volumeRef}>
            <PropertiesBlockHead
                isBlockExpanded={volumeBlockExpanded}
                title={t('volume.title')}
                onClick={e => {
                    setBlockExpansion(!volumeBlockExpanded);
                }}
                className='inspector-tool-placeholder'
            >
                {!hasCalculatedVolume ? (
                    <TippyTooltip tooltipText={t('volume.tooltipAdd')}>
                        <div
                            className={classNames('properties-control', {
                                disabled: hasNotFinishedDrawing || isModelView
                            })}
                            onClick={e => {
                                setShowTool(true);
                                if (!volumeBlockExpanded) setBlockExpansion(true);
                            }}
                        >
                            <i className='icon icon-add' />
                        </div>
                    </TippyTooltip>
                ) : (
                    <>
                        {canEdit && (
                            <TippyTooltip tooltipText={t('volume.tooltipEdit')}>
                                <div
                                    className={classNames('properties-control', { disabled: isModelView })}
                                    onClick={e => {
                                        setShowTool(true);
                                        if (!volumeBlockExpanded) setBlockExpansion(true);
                                    }}
                                >
                                    <SvgEdit />
                                </div>
                            </TippyTooltip>
                        )}
                        {hasValidValues && <CopyPropertiesControl clipboardText={clipboardText} />}
                    </>
                )}
                {showTool && <VolumeTool setToolVisibility={setShowTool} />}
            </PropertiesBlockHead>
            {volumeBlockExpanded && hasCalculatedVolume && <VolumePropertiesList />}
        </div>
    );
}
