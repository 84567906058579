import { Select } from 'antd';
import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { SourceType } from '../../../generated/cloud-frontend-api';

type Props = {
    className?: string;
    selectedSourceType: SourceType;
    setSelectedSourceType: React.Dispatch<React.SetStateAction<SourceType>>;
};

export default function SelectSourceType({ className, selectedSourceType, setSelectedSourceType }: Props) {
    const { t } = useTranslation(['projectView', 'glossary']);
    const { isInEmbedView } = useContext(ProjectViewAccessContext);

    const sourceTypes: Record<typeof SourceType.POINT_CLOUD | typeof SourceType.TILED_MODEL, string> = useMemo(
        () => ({
            [SourceType.POINT_CLOUD]: t('glossary:sourceTypes.pointCloud'),
            [SourceType.TILED_MODEL]: t('glossary:sourceTypes.tiledModel')
        }),
        [t]
    );

    return (
        <div className={className}>
            <Select
                value={selectedSourceType}
                placeholder={t('projectView:inspectionSidebar.datasetProperties.select.placeholder')}
                onClick={e => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                }}
                onSelect={newValue => {
                    setSelectedSourceType(newValue);
                }}
                showSearch
                filterOption={(inputValue, option) => {
                    return Boolean(
                        option?.children &&
                            option.children.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                    );
                }}
                notFoundContent={false}
                className={classNames({ 'theme-dark': isInEmbedView })}
                popupClassName={classNames({ 'theme-dark': isInEmbedView })}
            >
                {[SourceType.TILED_MODEL, SourceType.POINT_CLOUD].map((v, i) => (
                    <Select.Option key={i} value={v}>
                        {sourceTypes[v]}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
}
