import { AnyAction } from '@reduxjs/toolkit';
import getThunkName from '../helpers';
import _ from 'lodash';

type LoadingState = Record<string, { isLoading: boolean; meta: Record<string, any> }>;

const initialState: LoadingState = {};

export default function loadingReducer(state: LoadingState = initialState, action: AnyAction): LoadingState {
    // This checks if action is async or not
    if (!action.type.match(/\/(pending|fulfilled|rejected)$/g)) return state;

    // Filter out class instances, because they break dev-only redux middleware
    const metaPropertiesObjectLiteralsOrPrimitives = _.pick(
        action?.meta?.arg || {},
        Object.keys(action.meta?.arg || {}).filter(key => {
            const value = action?.meta?.arg?.[key];
            return value?.constructor === Object || typeof value !== 'object';
        })
    );

    return {
        ...state,
        [getThunkName(action.type)]: {
            isLoading: !!action.type.match(/\/pending/)?.length,
            meta: metaPropertiesObjectLiteralsOrPrimitives
        }
    };
}
