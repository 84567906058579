import { Process, ProcessStatus, ProcessStep } from '../../generated/cloud-frontend-api/model';
import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { processApi } from '../../api/initApis';
import { ApplicationState } from '..';

type ProgressPollerValue = Required<Process> & { processId: string };
const progressAdapter = createEntityAdapter<ProgressPollerValue, string>({ selectId: model => model.processId });

type ProgressState = EntityState<ProgressPollerValue, string>;

const initialState: ProgressState = progressAdapter.getInitialState();

const name = 'progress';

export const firstProgressTick = createAsyncThunk<ProgressPollerValue, { projectId: string; processId: string }>(
    `${name}/firstTick`,
    async ({ projectId, processId }) => {
        const { data } = await processApi.getProcess(projectId, processId);
        return { ...(data as Required<Process>), processId };
    }
);

export const progressTick = createAsyncThunk<ProgressPollerValue, { projectId: string; processId: string }>(
    `${name}/tick`,
    async ({ projectId, processId }) => {
        const { data } = await processApi.getProcess(projectId, processId);
        return { ...(data as Required<Process>), processId };
    }
);

const progressSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: builder =>
        builder
            .addCase(firstProgressTick.pending, (state, { meta }) => {
                if (!state.ids.includes(meta.arg.processId)) {
                    progressAdapter.addOne(state, {
                        processId: meta.arg.processId,
                        status: ProcessStatus.INPROGRESS,
                        progress: 0,
                        duration: 0,
                        step: ProcessStep.PREPARING_DATA
                    });
                }
            })
            .addCase(progressTick.pending, (state, { meta }) => {
                if (!state.ids.includes(meta.arg.processId)) {
                    progressAdapter.addOne(state, {
                        processId: meta.arg.processId,
                        status: ProcessStatus.INPROGRESS,
                        progress: 0,
                        duration: 0,
                        step: ProcessStep.PREPARING_DATA
                    });
                }
            })
            .addCase(firstProgressTick.fulfilled, (state, { payload }) => {
                progressAdapter.updateOne(state, { id: payload.processId, changes: payload });
            })
            .addCase(progressTick.fulfilled, (state, { payload }) => {
                progressAdapter.updateOne(state, { id: payload.processId, changes: payload });
            })
});

export const progressActions = { firstProgressTick, progressTick };

export default progressSlice.reducer;

export const {
    selectAll: selectProgresses,
    selectById: selectProgressById,
    selectEntities: selectProgressEntities,
    selectIds: selectProgressIds,
    selectTotal: selectProgressCount
} = progressAdapter.getSelectors((state: ApplicationState) => state.progress);

export const makeSelectProgressById = () => selectProgressById;
