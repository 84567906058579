/* tslint:disable */
/* eslint-disable */
/**
 * project public API v2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { NewProject } from '../model';
// @ts-ignore
import { Project } from '../model';
// @ts-ignore
import { ProjectPatch } from '../model';
// @ts-ignore
import { ProjectType } from '../model';
// @ts-ignore
import { ProjectsPage } from '../model';
/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Request to create a project. It may take a few seconds before the project become available
         * @summary Create a project
         * @param {NewProject} newProject New user object that needs to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (newProject: NewProject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newProject' is not null or undefined
            assertParamExists('createProject', 'newProject', newProject)
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newProject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request to delete project with ID = {projectId}.
         * @summary Delete a project
         * @param {string} projectUid UUID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectById: async (projectUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('deleteProjectById', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request to get information about the project with specified projectUid.
         * @summary Get information about a project
         * @param {string} projectUid UUID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById: async (projectUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getProjectById', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request to get the list of the projects starting from specified page, number set as the specified limit parameter.
         * @summary Get the list of the projects
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {Array<'surveyDate' | 'name' | 'modifiedDate' | 'creationDate' | 'size'>} [sort] Order of projects: \&quot;+\&quot; - ascending, \&quot;-\&quot; - descending
         * @param {string} [pattern] String to filter projects by name
         * @param {Array<ProjectType>} [type] Types of project. Should return all projects if it is null
         * @param {boolean} [nested] Flag for nested projects. If true load all nested projects, false - just in required level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (limit?: number, page?: number, sort?: Array<'surveyDate' | 'name' | 'modifiedDate' | 'creationDate' | 'size'>, pattern?: string, type?: Array<ProjectType>, nested?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pattern !== undefined) {
                localVarQueryParameter['pattern'] = pattern;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request to get the list of the projects nested in the parent one with specified parameters.
         * @summary Get the list of the projects
         * @param {string} projectUid UUID of project
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {Array<'surveyDate' | 'name' | 'modifiedDate' | 'creationDate' | 'size'>} [sort] Order of projects: \&quot;+\&quot; - ascending, \&quot;-\&quot; - descending
         * @param {string} [pattern] String to filter projects by name
         * @param {Array<ProjectType>} [type] Types of project. Should return all projects if it is null
         * @param {boolean} [nested] Flag for nested projects. If true load all nested projects, false - just in required level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByParent: async (projectUid: string, limit?: number, page?: number, sort?: Array<'surveyDate' | 'name' | 'modifiedDate' | 'creationDate' | 'size'>, pattern?: string, type?: Array<ProjectType>, nested?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getProjectsByParent', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}/projects`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (pattern !== undefined) {
                localVarQueryParameter['pattern'] = pattern;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request to change project info. Replaces forbidden symbols in name with  \'_\'. Adds \'_\' in the beginning of project name  if it is one of Windows OS reserved words. Does not update null fields. To reset parentUid use reserved \"unset\"
         * @summary Patch project
         * @param {string} projectUid UUID of project
         * @param {ProjectPatch} [projectPatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProject: async (projectUid: string, projectPatch?: ProjectPatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('patchProject', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * Request to create a project. It may take a few seconds before the project become available
         * @summary Create a project
         * @param {NewProject} newProject New user object that needs to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(newProject: NewProject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(newProject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Request to delete project with ID = {projectId}.
         * @summary Delete a project
         * @param {string} projectUid UUID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectById(projectUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectById(projectUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Request to get information about the project with specified projectUid.
         * @summary Get information about a project
         * @param {string} projectUid UUID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectById(projectUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectById(projectUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Request to get the list of the projects starting from specified page, number set as the specified limit parameter.
         * @summary Get the list of the projects
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {Array<'surveyDate' | 'name' | 'modifiedDate' | 'creationDate' | 'size'>} [sort] Order of projects: \&quot;+\&quot; - ascending, \&quot;-\&quot; - descending
         * @param {string} [pattern] String to filter projects by name
         * @param {Array<ProjectType>} [type] Types of project. Should return all projects if it is null
         * @param {boolean} [nested] Flag for nested projects. If true load all nested projects, false - just in required level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(limit?: number, page?: number, sort?: Array<'surveyDate' | 'name' | 'modifiedDate' | 'creationDate' | 'size'>, pattern?: string, type?: Array<ProjectType>, nested?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(limit, page, sort, pattern, type, nested, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Request to get the list of the projects nested in the parent one with specified parameters.
         * @summary Get the list of the projects
         * @param {string} projectUid UUID of project
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {Array<'surveyDate' | 'name' | 'modifiedDate' | 'creationDate' | 'size'>} [sort] Order of projects: \&quot;+\&quot; - ascending, \&quot;-\&quot; - descending
         * @param {string} [pattern] String to filter projects by name
         * @param {Array<ProjectType>} [type] Types of project. Should return all projects if it is null
         * @param {boolean} [nested] Flag for nested projects. If true load all nested projects, false - just in required level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsByParent(projectUid: string, limit?: number, page?: number, sort?: Array<'surveyDate' | 'name' | 'modifiedDate' | 'creationDate' | 'size'>, pattern?: string, type?: Array<ProjectType>, nested?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsByParent(projectUid, limit, page, sort, pattern, type, nested, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Request to change project info. Replaces forbidden symbols in name with  \'_\'. Adds \'_\' in the beginning of project name  if it is one of Windows OS reserved words. Does not update null fields. To reset parentUid use reserved \"unset\"
         * @summary Patch project
         * @param {string} projectUid UUID of project
         * @param {ProjectPatch} [projectPatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchProject(projectUid: string, projectPatch?: ProjectPatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchProject(projectUid, projectPatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * Request to create a project. It may take a few seconds before the project become available
         * @summary Create a project
         * @param {NewProject} newProject New user object that needs to be added
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(newProject: NewProject, options?: any): AxiosPromise<Project> {
            return localVarFp.createProject(newProject, options).then((request) => request(axios, basePath));
        },
        /**
         * Request to delete project with ID = {projectId}.
         * @summary Delete a project
         * @param {string} projectUid UUID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectById(projectUid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectById(projectUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Request to get information about the project with specified projectUid.
         * @summary Get information about a project
         * @param {string} projectUid UUID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(projectUid: string, options?: any): AxiosPromise<Project> {
            return localVarFp.getProjectById(projectUid, options).then((request) => request(axios, basePath));
        },
        /**
         * Request to get the list of the projects starting from specified page, number set as the specified limit parameter.
         * @summary Get the list of the projects
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {Array<'surveyDate' | 'name' | 'modifiedDate' | 'creationDate' | 'size'>} [sort] Order of projects: \&quot;+\&quot; - ascending, \&quot;-\&quot; - descending
         * @param {string} [pattern] String to filter projects by name
         * @param {Array<ProjectType>} [type] Types of project. Should return all projects if it is null
         * @param {boolean} [nested] Flag for nested projects. If true load all nested projects, false - just in required level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(limit?: number, page?: number, sort?: Array<'surveyDate' | 'name' | 'modifiedDate' | 'creationDate' | 'size'>, pattern?: string, type?: Array<ProjectType>, nested?: boolean, options?: any): AxiosPromise<ProjectsPage> {
            return localVarFp.getProjects(limit, page, sort, pattern, type, nested, options).then((request) => request(axios, basePath));
        },
        /**
         * Request to get the list of the projects nested in the parent one with specified parameters.
         * @summary Get the list of the projects
         * @param {string} projectUid UUID of project
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {Array<'surveyDate' | 'name' | 'modifiedDate' | 'creationDate' | 'size'>} [sort] Order of projects: \&quot;+\&quot; - ascending, \&quot;-\&quot; - descending
         * @param {string} [pattern] String to filter projects by name
         * @param {Array<ProjectType>} [type] Types of project. Should return all projects if it is null
         * @param {boolean} [nested] Flag for nested projects. If true load all nested projects, false - just in required level
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByParent(projectUid: string, limit?: number, page?: number, sort?: Array<'surveyDate' | 'name' | 'modifiedDate' | 'creationDate' | 'size'>, pattern?: string, type?: Array<ProjectType>, nested?: boolean, options?: any): AxiosPromise<ProjectsPage> {
            return localVarFp.getProjectsByParent(projectUid, limit, page, sort, pattern, type, nested, options).then((request) => request(axios, basePath));
        },
        /**
         * Request to change project info. Replaces forbidden symbols in name with  \'_\'. Adds \'_\' in the beginning of project name  if it is one of Windows OS reserved words. Does not update null fields. To reset parentUid use reserved \"unset\"
         * @summary Patch project
         * @param {string} projectUid UUID of project
         * @param {ProjectPatch} [projectPatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProject(projectUid: string, projectPatch?: ProjectPatch, options?: any): AxiosPromise<void> {
            return localVarFp.patchProject(projectUid, projectPatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * Request to create a project. It may take a few seconds before the project become available
     * @summary Create a project
     * @param {NewProject} newProject New user object that needs to be added
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public createProject(newProject: NewProject, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).createProject(newProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request to delete project with ID = {projectId}.
     * @summary Delete a project
     * @param {string} projectUid UUID of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteProjectById(projectUid: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).deleteProjectById(projectUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request to get information about the project with specified projectUid.
     * @summary Get information about a project
     * @param {string} projectUid UUID of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectById(projectUid: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjectById(projectUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request to get the list of the projects starting from specified page, number set as the specified limit parameter.
     * @summary Get the list of the projects
     * @param {number} [limit] Maximum number of the items to be returned
     * @param {number} [page] Page number to be returned
     * @param {Array<'surveyDate' | 'name' | 'modifiedDate' | 'creationDate' | 'size'>} [sort] Order of projects: \&quot;+\&quot; - ascending, \&quot;-\&quot; - descending
     * @param {string} [pattern] String to filter projects by name
     * @param {Array<ProjectType>} [type] Types of project. Should return all projects if it is null
     * @param {boolean} [nested] Flag for nested projects. If true load all nested projects, false - just in required level
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjects(limit?: number, page?: number, sort?: Array<'surveyDate' | 'name' | 'modifiedDate' | 'creationDate' | 'size'>, pattern?: string, type?: Array<ProjectType>, nested?: boolean, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjects(limit, page, sort, pattern, type, nested, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request to get the list of the projects nested in the parent one with specified parameters.
     * @summary Get the list of the projects
     * @param {string} projectUid UUID of project
     * @param {number} [limit] Maximum number of the items to be returned
     * @param {number} [page] Page number to be returned
     * @param {Array<'surveyDate' | 'name' | 'modifiedDate' | 'creationDate' | 'size'>} [sort] Order of projects: \&quot;+\&quot; - ascending, \&quot;-\&quot; - descending
     * @param {string} [pattern] String to filter projects by name
     * @param {Array<ProjectType>} [type] Types of project. Should return all projects if it is null
     * @param {boolean} [nested] Flag for nested projects. If true load all nested projects, false - just in required level
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectsByParent(projectUid: string, limit?: number, page?: number, sort?: Array<'surveyDate' | 'name' | 'modifiedDate' | 'creationDate' | 'size'>, pattern?: string, type?: Array<ProjectType>, nested?: boolean, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjectsByParent(projectUid, limit, page, sort, pattern, type, nested, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request to change project info. Replaces forbidden symbols in name with  \'_\'. Adds \'_\' in the beginning of project name  if it is one of Windows OS reserved words. Does not update null fields. To reset parentUid use reserved \"unset\"
     * @summary Patch project
     * @param {string} projectUid UUID of project
     * @param {ProjectPatch} [projectPatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public patchProject(projectUid: string, projectPatch?: ProjectPatch, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).patchProject(projectUid, projectPatch, options).then((request) => request(this.axios, this.basePath));
    }
}
