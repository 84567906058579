import React from 'react';
import { EmbedViewMode } from '../sharedConstants';

export interface ProjectViewAccessContextValue {
    owned: boolean;
    rejectedProject: boolean;
    isInEmbedView: boolean;
    embedViewMode?: EmbedViewMode;
}

const contextValue: ProjectViewAccessContextValue = {
    owned: true,
    rejectedProject: false,
    isInEmbedView: false
};

export default React.createContext(contextValue);
