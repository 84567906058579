import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { GeometryTypes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { isPolygonFeature, TemporaryGeometry } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { isGeometryPropertiesBlockExpanded } from '../../../store/slices/geometries';
import { updateGeometryPropertyById } from '../../../store/slices/geometryLayers';
import CopyPropertiesControl from './elements/CopyPropertiesControl';
import PointProperties from './PointProperties';
import PolygonProperties from './PolygonProperties';
import PolylineProperties from './PolylineProperties';
import PropertiesBlockHead from './elements/PropertiesBlockHead';

export default function GeneralProperties() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));
    const { owned } = useContext(ProjectViewAccessContext);
    const geometry = selectedObjectInfo as TemporaryGeometry;
    const geometryType = geometry.content.geometry.type;

    const [textForCopying, setTextForCopying] = useState('');

    const generalBlockExpanded = isGeometryPropertiesBlockExpanded(geometry.content, 'ac_general_block_expanded');

    return (
        <div className='inspector-properties'>
            <PropertiesBlockHead
                isBlockExpanded={generalBlockExpanded}
                title={t('projectView:inspectionSidebar.titleGeneral')}
                onClick={() => {
                    dispatch(
                        updateGeometryPropertyById({
                            id: geometry.id,
                            owned,
                            propName: 'ac_general_block_expanded',
                            propValue: !generalBlockExpanded
                        })
                    );
                }}
            >
                <CopyPropertiesControl clipboardText={textForCopying} />
            </PropertiesBlockHead>
            {generalBlockExpanded && (
                <div className='properties-list'>
                    {geometryType === GeometryTypes.POINT && (
                        <PointProperties geoJson={geometry.content} setTextForCopying={setTextForCopying} />
                    )}
                    {geometryType === GeometryTypes.POLYLINE && (
                        <PolylineProperties geoJson={geometry.content} setTextForCopying={setTextForCopying} />
                    )}
                    {isPolygonFeature(geometry.content) && (
                        <PolygonProperties geoJson={geometry.content} setTextForCopying={setTextForCopying} />
                    )}
                </div>
            )}
        </div>
    );
}
