import { enUS, ru } from 'date-fns/locale';
import {
    formatDistanceToNowStrict as libFormatDistanceToNowStrict,
    formatDuration as libFormatDuration
} from 'date-fns';

function mapAppLocaleToDateLocale(appLocale: string) {
    return { en: enUS, ru: ru }[appLocale];
}

export const formatDistanceToNowStrict: (
    ...args: [...Parameters<typeof libFormatDistanceToNowStrict>, string]
) => ReturnType<typeof libFormatDistanceToNowStrict> = (date, options, appLocale) =>
    libFormatDistanceToNowStrict(date, { ...options, locale: mapAppLocaleToDateLocale(appLocale) });

export const formatDistanceToNowStrictShort: (
    ...args: [...Parameters<typeof libFormatDistanceToNowStrict>, string]
) => ReturnType<typeof libFormatDistanceToNowStrict> = (date, options, appLocale) => {
    let formatted = libFormatDistanceToNowStrict(date, { ...options, locale: mapAppLocaleToDateLocale(appLocale) });
    if (appLocale === 'en')
        formatted = formatted
            .replace('minutes', 'min')
            .replace('minute', 'min')
            .replace('hours', 'hrs')
            .replace('hour', 'hr');
    if (appLocale === 'ru')
        formatted = formatted
            .replace('минута', 'мин')
            .replace('минуты', 'мин')
            .replace('минут', 'мин')
            .replace('часов', 'ч')
            .replace('часа', 'ч')
            .replace('час', 'ч');
    return formatted;
};

export const formatDuration: (
    ...args: [...Parameters<typeof libFormatDuration>, string]
) => ReturnType<typeof libFormatDuration> = (date, options, appLocale) =>
    libFormatDuration(date, { ...options, locale: mapAppLocaleToDateLocale(appLocale) });
