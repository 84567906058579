import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ProcessStatus } from '../../../../generated/cloud-frontend-api';
import getShortTimeString from '../../../../lib/getShortTimeString';
import { ProcessStepInfo } from '../../../../services/processing';
import { ReactComponent as SvgSpinnerSmall } from '../../../../svg/general/spinner_small.svg';
import { ReactComponent as SvgAborted } from '../../../../svg/processing_info/aborted.svg';
import { ReactComponent as SvgCompleted } from '../../../../svg/processing_info/completed.svg';
import { ReactComponent as SvgScheduled } from '../../../../svg/processing_info/scheduled.svg';
import { statusDisplayValues } from './ProcessingInfoModal';

interface Props {
    processStepInfo: ProcessStepInfo;
    className?: string;
}

export default function ProcessingListItem({ processStepInfo, className }: Props) {
    const { t } = useTranslation('modals');
    const totalStepTimeSeconds = processStepInfo.processingTimeInSeconds;

    return (
        <div key={processStepInfo.name} className={classNames('processing-list_item', className)}>
            <div className='item-icon'>
                <div className='state-svg-wrapper'>
                    {processStepInfo.status === ProcessStatus.COMPLETED && <SvgCompleted />}
                    {processStepInfo.status === 'PENDING' && <SvgScheduled />}
                    {(processStepInfo.status === ProcessStatus.INPROGRESS ||
                        processStepInfo.status === ProcessStatus.ABORTING) && (
                        <SvgSpinnerSmall className='svg-spinner' />
                    )}
                    {(processStepInfo.status === ProcessStatus.ABORTED ||
                        processStepInfo.status === ProcessStatus.FAILED) && <SvgAborted />}
                </div>
            </div>
            <div className='item-status'>
                <b>{processStepInfo.info}</b>
                <span> - </span>
                <span>{statusDisplayValues()[processStepInfo.status]}</span>
                <span> </span>
                {!!totalStepTimeSeconds && (
                    <span className='item-time'>{getShortTimeString(totalStepTimeSeconds)}</span>
                )}
            </div>
        </div>
    );
}
