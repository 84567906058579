import { Img } from 'react-image';
import { Link, useRouteMatch } from 'react-router-dom';
import useIsLoggedIn from '../../hooks/useIsLoggedIn';
import { Routes } from '../../sharedConstants';
import LoginButton from './LoginButton';
import UserProfileButton from './UserProfileButton';
import { useSelector } from '../../store';

const AppHeader = () => {
    const loggedIn = useIsLoggedIn();
    const headerLogoUrl = useSelector(state => state.init.headerLogoUrl);
    const applicationName = useSelector(state => state.init.metaApplicationName);
    const hideUserActions = useRouteMatch([Routes.UNSUBSCRIBE, Routes.USER_AGREEMENT]);

    const showLoginButton = !hideUserActions && !loggedIn;
    const showUserProfile = !hideUserActions && loggedIn;

    return (
        <header id='header'>
            {loggedIn ? (
                <Link className='header_logo' to={Routes.INDEX}>
                    <Img src={headerLogoUrl} title={applicationName} />
                </Link>
            ) : (
                <a
                    className='header_logo'
                    role={'link'}
                    onClick={() => {
                        window.parent.location = Routes.INDEX;
                    }}
                >
                    <Img src={headerLogoUrl} title={applicationName} />
                </a>
            )}

            {showUserProfile && <UserProfileButton />}
            {showLoginButton && <LoginButton />}
        </header>
    );
};

export default AppHeader;
