import QuantizedMeshUtil from './QuantizedMeshUtil.js';
import {
    Cartographic,
    Check,
    Credit,
    defined,
    Event,
    GeographicTilingScheme,
    HeightmapTerrainData,
    IonResource,
    OrientedBoundingBox,
    Rectangle,
    Request,
    RequestType,
    Resource,
    RuntimeError,
    TerrainProvider,
    TileAvailability,
    TileProviderError,
    WebMercatorTilingScheme
} from 'cesium';
import { defaultValue } from './CesiumHelper';

function LayerInformation(layer) {
    this.resource = layer.resource;
    this.version = layer.version;
    this.isHeightmap = layer.isHeightmap;
    this.tileUrlTemplates = layer.tileUrlTemplates;
    this.availability = layer.availability;
    this.hasVertexNormals = layer.hasVertexNormals;
    this.hasWaterMask = layer.hasWaterMask;
    this.hasMetadata = layer.hasMetadata;
    this.availabilityLevels = layer.availabilityLevels;
    this.availabilityTilesLoaded = layer.availabilityTilesLoaded;
    this.littleEndianExtensionSize = layer.littleEndianExtensionSize;
    this.availabilityPromiseCache = {};
    this.isVisible = layer.isVisible;
    this.minElevation = layer.minElevation;
    this.maxElevation = layer.maxElevation;
    this.boundingBox = layer.boundingBox;
    this.boundingRectangle = layer.boundingRectangle;
    this.isGlobal = layer.isGlobal;
}

/**
 * @typedef {object} CesiumMultiTerrainProvider.ConstructorOptions
 *
 * Initialization options for the CesiumMultiTerrainProvider constructor
 *
 * @property {boolean} [requestVertexNormals=false] Flag that indicates if the client should request additional lighting information from the server, in the form of per vertex normals if available.
 * @property {boolean} [requestWaterMask=false] Flag that indicates if the client should request per tile water masks from the server, if available.
 * @property {boolean} [requestMetadata=true] Flag that indicates if the client should request per tile metadata from the server, if available.
 * @property {boolean} [enablePatching=true] Flag that indicates if the provider will patch mesh.
 * @property {Ellipsoid} [ellipsoid] The ellipsoid.  If not specified, the WGS84 ellipsoid is used.
 * @property {Credit|String} [credit] A credit for the data source, which is displayed on the canvas.
 */

/**
 * Used to track creation details while fetching initial metadata
 *
 * @constructor
 * @private
 *
 * @param {CesiumMultiTerrainProvider.ConstructorOptions} options An object describing initialization options
 */
function TerrainProviderBuilder(options) {
    this.requestVertexNormals = defaultValue(options.requestVertexNormals, false);
    this.requestWaterMask = defaultValue(options.requestWaterMask, false);
    this.requestMetadata = defaultValue(options.requestMetadata, true);
    this.ellipsoid = options.ellipsoid;

    this.heightmapWidth = 65;
    this.heightmapStructure = undefined;
    this.hasWaterMask = false;
    this.hasVertexNormals = false;
    this.hasMetadata = false;
    this.scheme = undefined;

    this.lastResource = undefined;
    this.layerJsonResource = undefined;
    this.previousError = undefined;
    this.availability = undefined;
    this.tilingScheme = undefined;
    this.levelZeroMaximumGeometricError = undefined;
    this.heightmapStructure = undefined;
    this.layers = [];
    this.attribution = '';
    this.overallAvailability = [];
    this.overallMaxZoom = 0;
    this.tileCredits = [];
    this.enablePatching = options.enablePatching;
}

/**
 * Complete CesiumMultiTerrainProvider creation based on builder values.
 *
 * @private
 *
 * @param {CesiumMultiTerrainProvider} provider
 */
TerrainProviderBuilder.prototype.build = function (provider) {
    provider._heightmapWidth = this.heightmapWidth;
    provider._scheme = this.scheme;

    // ion resources have a credits property we can use for additional attribution.
    const credits = defined(this.lastResource.credits) ? this.lastResource.credits : [];
    provider._tileCredits = credits.concat(this.tileCredits);
    provider._availability = this.availability;
    provider._tilingScheme = this.tilingScheme;
    provider._requestWaterMask = this.requestWaterMask;
    provider._levelZeroMaximumGeometricError = this.levelZeroMaximumGeometricError;
    provider._heightmapStructure = this.heightmapStructure;
    provider._layers = this.layers;

    provider._hasWaterMask = this.hasWaterMask;
    provider._hasVertexNormals = this.hasVertexNormals;
    provider._hasMetadata = this.hasMetadata;
};

async function parseMetadataSuccess(terrainProviderBuilder, data, provider) {
    if (!data.format) {
        const message = 'The tile format is not specified in the layer.json file.';
        terrainProviderBuilder.previousError = TileProviderError.reportError(
            terrainProviderBuilder.previousError,
            provider,
            defined(provider) ? provider._errorEvent : undefined,
            message
        );

        throw new RuntimeError(message);
    }

    if (!data.tiles || data.tiles.length === 0) {
        const message = 'The layer.json file does not specify any tile URL templates.';
        terrainProviderBuilder.previousError = TileProviderError.reportError(
            terrainProviderBuilder.previousError,
            provider,
            defined(provider) ? provider._errorEvent : undefined,
            message
        );

        throw new RuntimeError(message);
    }

    let hasVertexNormals = false;
    let hasWaterMask = false;
    let hasMetadata = false;
    let littleEndianExtensionSize = true;
    let isHeightmap = false;
    if (data.format === 'heightmap-1.0') {
        isHeightmap = true;
        if (!defined(terrainProviderBuilder.heightmapStructure)) {
            terrainProviderBuilder.heightmapStructure = {
                heightScale: 1.0 / 5.0,
                heightOffset: -1000.0,
                elementsPerHeight: 1,
                stride: 1,
                elementMultiplier: 256.0,
                isBigEndian: false,
                lowestEncodedHeight: 0,
                highestEncodedHeight: 256 * 256 - 1
            };
        }
        hasWaterMask = true;
        terrainProviderBuilder.requestWaterMask = true;
    } else if (data.format.indexOf('quantized-mesh-1.') !== 0) {
        const message = `The tile format "${data.format}" is invalid or not supported.`;
        terrainProviderBuilder.previousError = TileProviderError.reportError(
            terrainProviderBuilder.previousError,
            provider,
            defined(provider) ? provider._errorEvent : undefined,
            message
        );

        throw new RuntimeError(message);
    }

    const tileUrlTemplates = data.tiles;

    const maxZoom = data.maxzoom;
    terrainProviderBuilder.overallMaxZoom = Math.max(terrainProviderBuilder.overallMaxZoom, maxZoom);

    // Keeps track of which of the availability containing tiles have been loaded
    if (!data.projection || data.projection === 'EPSG:4326') {
        terrainProviderBuilder.tilingScheme = new GeographicTilingScheme({
            numberOfLevelZeroTilesX: 2,
            numberOfLevelZeroTilesY: 1,
            ellipsoid: terrainProviderBuilder.ellipsoid
        });
    } else if (data.projection === 'EPSG:3857') {
        terrainProviderBuilder.tilingScheme = new WebMercatorTilingScheme({
            numberOfLevelZeroTilesX: 1,
            numberOfLevelZeroTilesY: 1,
            ellipsoid: terrainProviderBuilder.ellipsoid
        });
    } else {
        const message = `The projection "${data.projection}" is invalid or not supported.`;
        terrainProviderBuilder.previousError = TileProviderError.reportError(
            terrainProviderBuilder.previousError,
            provider,
            defined(provider) ? provider._errorEvent : undefined,
            message
        );

        throw new RuntimeError(message);
    }

    terrainProviderBuilder.levelZeroMaximumGeometricError =
        TerrainProvider.getEstimatedLevelZeroGeometricErrorForAHeightmap(
            terrainProviderBuilder.tilingScheme.ellipsoid,
            terrainProviderBuilder.heightmapWidth,
            terrainProviderBuilder.tilingScheme.getNumberOfXTilesAtLevel(0)
        );
    if (!data.scheme || data.scheme === 'tms' || data.scheme === 'slippyMap') {
        terrainProviderBuilder.scheme = data.scheme;
    } else {
        const message = `The scheme "${data.scheme}" is invalid or not supported.`;
        terrainProviderBuilder.previousError = TileProviderError.reportError(
            terrainProviderBuilder.previousError,
            provider,
            defined(provider) ? provider._errorEvent : undefined,
            message
        );

        throw new RuntimeError(message);
    }

    let availabilityTilesLoaded;

    // The vertex normals defined in the 'octvertexnormals' extension is identical to the original
    // contents of the original 'vertexnormals' extension.  'vertexnormals' extension is now
    // deprecated, as the extensionLength for this extension was incorrectly using big endian.
    // We maintain backwards compatibility with the legacy 'vertexnormal' implementation
    // by setting the _littleEndianExtensionSize to false. Always prefer 'octvertexnormals'
    // over 'vertexnormals' if both extensions are supported by the server.
    if (defined(data.extensions) && data.extensions.indexOf('octvertexnormals') !== -1) {
        hasVertexNormals = true;
    } else if (defined(data.extensions) && data.extensions.indexOf('vertexnormals') !== -1) {
        hasVertexNormals = true;
        littleEndianExtensionSize = false;
    }
    if (defined(data.extensions) && data.extensions.indexOf('watermask') !== -1) {
        hasWaterMask = true;
    }
    if (defined(data.extensions) && data.extensions.indexOf('metadata') !== -1) {
        hasMetadata = true;
    }

    const availabilityLevels = data.metadataAvailability;
    const availableTiles = data.available;
    const tightBounds = data.tightBounds;
    let availability;
    let boundingBox;
    let boundingRectangle;
    if (defined(availableTiles) && !defined(availabilityLevels)) {
        availability = new TileAvailability(terrainProviderBuilder.tilingScheme, availableTiles.length);
        for (let level = 0; level < availableTiles.length; ++level) {
            const rangesAtLevel = availableTiles[level];
            const yTiles = terrainProviderBuilder.tilingScheme.getNumberOfYTilesAtLevel(level);
            if (!defined(terrainProviderBuilder.overallAvailability[level])) {
                terrainProviderBuilder.overallAvailability[level] = [];
            }
            const xTilesArray = [];
            const yTilesArray = [];
            for (let rangeIndex = 0; rangeIndex < rangesAtLevel.length; ++rangeIndex) {
                const range = rangesAtLevel[rangeIndex];
                const yStart = yTiles - range.endY - 1;
                const yEnd = yTiles - range.startY - 1;
                terrainProviderBuilder.overallAvailability[level].push([range.startX, yStart, range.endX, yEnd]);
                xTilesArray.push(range.startX, range.endX);
                yTilesArray.push(yStart, yEnd);
                availability.addAvailableTileRange(level, range.startX, yStart, range.endX, yEnd);
            }
            if (level === availableTiles.length - 1) {
                if (defined(tightBounds) && tightBounds.length == 8) {
                    boundingRectangle = Rectangle.fromCartographicArray([
                        Cartographic.fromDegrees(tightBounds[0], tightBounds[1]),
                        Cartographic.fromDegrees(tightBounds[2], tightBounds[3]),
                        Cartographic.fromDegrees(tightBounds[4], tightBounds[5]),
                        Cartographic.fromDegrees(tightBounds[6], tightBounds[7])
                    ]);
                } else {
                    boundingRectangle = getBoundingRectangleForTiles(
                        terrainProviderBuilder.tilingScheme,
                        level,
                        xTilesArray,
                        yTilesArray
                    );
                }
                boundingBox = getBoundingBoxForTiles(
                    terrainProviderBuilder.tilingScheme,
                    boundingRectangle,
                    data.minElevation,
                    data.maxElevation
                );
            }
        }
    } else if (defined(availabilityLevels)) {
        availabilityTilesLoaded = new TileAvailability(terrainProviderBuilder.tilingScheme, maxZoom);
        availability = new TileAvailability(terrainProviderBuilder.tilingScheme, maxZoom);
        terrainProviderBuilder.overallAvailability[0] = [[0, 0, 1, 0]];
        availability.addAvailableTileRange(0, 0, 0, 1, 0);
    }

    terrainProviderBuilder.hasWaterMask = terrainProviderBuilder.hasWaterMask || hasWaterMask;
    terrainProviderBuilder.hasVertexNormals = terrainProviderBuilder.hasVertexNormals || hasVertexNormals;
    terrainProviderBuilder.hasMetadata = terrainProviderBuilder.hasMetadata || hasMetadata;

    if (defined(data.attribution)) {
        if (terrainProviderBuilder.attribution.length > 0) {
            terrainProviderBuilder.attribution += ' ';
        }
        terrainProviderBuilder.attribution += data.attribution;
    }

    if (!defined(boundingBox)) {
        boundingBox = getWholePlanetBoundingBox(terrainProviderBuilder.tilingScheme);
    }
    if (!defined(boundingRectangle)) {
        boundingRectangle = Rectangle.fromDegrees(-180, -90, 180, 90);
    }

    terrainProviderBuilder.layers.unshift(
        new LayerInformation({
            resource: terrainProviderBuilder.lastResource,
            version: data.version,
            isHeightmap: isHeightmap,
            tileUrlTemplates: tileUrlTemplates,
            availability: availability,
            hasVertexNormals: hasVertexNormals,
            hasWaterMask: hasWaterMask,
            hasMetadata: hasMetadata,
            availabilityLevels: availabilityLevels,
            availabilityTilesLoaded: availabilityTilesLoaded,
            littleEndianExtensionSize: littleEndianExtensionSize,
            isVisible: terrainProviderBuilder.isVisible, //TODO correctly pass
            minElevation: data.minElevation,
            maxElevation: data.maxElevation,
            boundingBox: boundingBox,
            boundingRectangle: boundingRectangle,
            isGlobal: terrainProviderBuilder.layers.length === 0
        })
    );

    var parentUrl = data.parentUrl;
    if (defined(parentUrl)) {
        if (!defined(availability)) {
            if (process.env.NODE_ENV === 'development')
                console.log("A layer.json can't have a parentUrl if it does't have an available array.");

            return Promise.resolve();
        }
        that._lastResource = that._lastResource.getDerivedResource({
            url: parentUrl
        });
        that._lastResource.appendForwardSlash(); // Terrain always expects a directory
        that._layerJsonResource = that._lastResource.getDerivedResource({
            url: 'layer.json'
        });
        var parentMetadata = that._layerJsonResource.fetchJson();
        return Promise.resolve(parentMetadata, parseMetadataSuccess, parseMetadataFailure);
    }

    return Promise.resolve();
}

function getBoundingRectangleForTiles(tilingScheme, level, xTiles, yTiles) {
    if (xTiles.length === 0 || yTiles.length === 0) {
        return Rectangle.fromDegrees(-180, -90, 180, 90);
    }
    let minX = xTiles[0];
    let maxX = xTiles[0];
    let minY = yTiles[0];
    let maxY = yTiles[0];
    for (let i = 0; i < xTiles.length; i++) {
        const x = xTiles[i];
        if (x < minX) {
            minX = x;
        } else if (x > maxX) {
            maxX = x;
        }
    }
    for (let j = 0; j < yTiles.length; j++) {
        const y = yTiles[j];
        if (y < minY) {
            minY = y;
        } else if (y > maxY) {
            maxY = y;
        }
    }
    const minRectangle = tilingScheme.tileXYToRectangle(minX, minY, level);
    const maxRectangle = tilingScheme.tileXYToRectangle(maxX, maxY, level);
    const boundingRectangle = new Rectangle(
        minRectangle.west,
        maxRectangle.south,
        maxRectangle.east,
        minRectangle.north
    );
    return boundingRectangle;
}

function getBoundingBoxForTiles(tilingScheme, boundingRectangle, minElevation, maxElevation) {
    return OrientedBoundingBox.fromRectangle(boundingRectangle, minElevation, maxElevation, tilingScheme.ellipsoid);
}

function getWholePlanetBoundingBox(tilingScheme) {
    const boundingRectangle = Rectangle.fromDegrees(-180, -90, 180, 90);
    return OrientedBoundingBox.fromRectangle(boundingRectangle, 0, 0, tilingScheme.ellipsoid);
}

function parseMetadataFailure(terrainProviderBuilder, error, provider) {
    let message = `An error occurred while accessing ${terrainProviderBuilder.layerJsonResource.url}.`;
    if (defined(error)) {
        message += `\n${error.message}`;
    }

    terrainProviderBuilder.previousError = TileProviderError.reportError(
        terrainProviderBuilder.previousError,
        provider,
        defined(provider) ? provider._errorEvent : undefined,
        message
    );

    // If we can retry, do so. Otherwise throw the error.
    if (terrainProviderBuilder.previousError.retry) {
        return requestLayerJson(terrainProviderBuilder, provider);
    }

    throw new RuntimeError(message);
}

async function metadataSuccess(terrainProviderBuilder, data, provider) {
    await parseMetadataSuccess(terrainProviderBuilder, data, provider);

    const length = terrainProviderBuilder.overallAvailability.length;
    if (length > 0) {
        const availability = (terrainProviderBuilder.availability = new TileAvailability(
            terrainProviderBuilder.tilingScheme,
            terrainProviderBuilder.overallMaxZoom
        ));
        for (let level = 0; level < length; ++level) {
            const levelRanges = terrainProviderBuilder.overallAvailability[level];
            for (let i = 0; i < levelRanges.length; ++i) {
                const range = levelRanges[i];
                availability.addAvailableTileRange(level, range[0], range[1], range[2], range[3]);
            }
        }
    }

    if (terrainProviderBuilder.attribution.length > 0) {
        const layerJsonCredit = new Credit(terrainProviderBuilder.attribution);
        terrainProviderBuilder.tileCredits.push(layerJsonCredit);
    }

    return true;
}

async function requestLayerJson(terrainProviderBuilder, provider) {
    try {
        const data = await terrainProviderBuilder.layerJsonResource.fetchJson();
        return metadataSuccess(terrainProviderBuilder, data, provider);
    } catch (error) {
        // If the metadata is not found, assume this is a pre-metadata heightmap tileset.
        if (defined(error) && error.statusCode === 404) {
            await parseMetadataSuccess(
                terrainProviderBuilder,
                {
                    tilejson: '2.1.0',
                    format: 'heightmap-1.0',
                    version: '1.0.0',
                    scheme: 'tms',
                    tiles: ['{z}/{x}/{y}.terrain?v={version}']
                },
                provider
            );

            return true;
        }

        return parseMetadataFailure(terrainProviderBuilder, error, provider);
    }
}

/**
 * <div class="notice">
 * To construct a CesiumMultiTerrainProvider, call {@link CesiumMultiTerrainProvider.fromUrl}. Do not call the constructor directly.
 * </div>
 *
 * A {@link TerrainProvider} that accesses terrain data in a Cesium terrain format.
 * Terrain formats can be one of the following:
 * <ul>
 * <li> {@link https://github.com/AnalyticalGraphicsInc/quantized-mesh Quantized Mesh} </li>
 * <li> {@link https://github.com/AnalyticalGraphicsInc/cesium/wiki/heightmap-1.0 Height Map} </li>
 * </ul>
 *
 * @alias CesiumMultiTerrainProvider
 * @constructor
 *
 * @param {CesiumMultiTerrainProvider.ConstructorOptions} [options] An object describing initialization options
 *
 * try {
 *   const viewer = new Cesium.Viewer("cesiumContainer", {
 *     terrainProvider: await Cesium.CesiumMultiTerrainProvider.fromUrl(
 *       [Cesium.IonResource.fromAssetId(3956)], {
 *         requestVertexNormals: true
 *     })
 *   });
 * } catch (error) {
 *   console.log(error);
 * }
 *
 * @see createWorldTerrain
 * @see CesiumMultiTerrainProvider.fromUrl
 * @see TerrainProvider
 */
function CesiumMultiTerrainProvider(options) {
    options = defaultValue(options, defaultValue.EMPTY_OBJECT);

    this._heightmapWidth = undefined;
    this._heightmapStructure = undefined;
    this._hasWaterMask = false;
    this._hasVertexNormals = false;
    this._hasMetadata = false;
    this._scheme = undefined;
    this._ellipsoid = options.ellipsoid;

    /**
     * Boolean flag that indicates if the client should request vertex normals from the server.
     * @type {boolean}
     * @default false
     * @private
     */
    this._requestVertexNormals = defaultValue(options.requestVertexNormals, false);

    /**
     * Boolean flag that indicates if the client should request tile watermasks from the server.
     * @type {boolean}
     * @default false
     * @private
     */
    this._requestWaterMask = defaultValue(options.requestWaterMask, false);

    /**
     * Boolean flag that indicates if the client should request tile metadata from the server.
     * @type {boolean}
     * @default true
     * @private
     */
    this._requestMetadata = defaultValue(options.requestMetadata, true);

    this._errorEvent = new Event();

    let credit = options.credit;
    if (typeof credit === 'string') {
        credit = new Credit(credit);
    }
    this._credit = credit;

    this._availability = undefined;
    this._tilingScheme = undefined;
    this._levelZeroMaximumGeometricError = undefined;
    this._layers = [];

    this._tileCredits = undefined;
    this._enablePatching = options.enablePatching;
    this.key = Symbol('terrainProvider');
}

function getRequestHeader(extensionsList) {
    if (!defined(extensionsList) || extensionsList.length === 0) {
        return {
            Accept: 'application/vnd.quantized-mesh,application/octet-stream;q=0.9,*/*;q=0.01'
        };
    }
    const extensions = extensionsList.join('-');
    return {
        Accept: `application/vnd.quantized-mesh;extensions=${extensions},application/octet-stream;q=0.9,*/*;q=0.01`
    };
}

function createHeightmapTerrainData(provider, buffer) {
    const heightBuffer = new Uint16Array(buffer, 0, provider._heightmapWidth * provider._heightmapWidth);
    return new HeightmapTerrainData({
        buffer: heightBuffer,
        childTileMask: new Uint8Array(buffer, heightBuffer.byteLength, 1)[0],
        waterMask: new Uint8Array(buffer, heightBuffer.byteLength + 1, buffer.byteLength - heightBuffer.byteLength - 1),
        width: provider._heightmapWidth,
        height: provider._heightmapWidth,
        structure: provider._heightmapStructure,
        credits: provider._tileCredits
    });
}

/**
 * Requests the geometry for a given tile. The result must include terrain data and
 * may optionally include a water mask and an indication of which child tiles are available.
 *
 * @param {number} x The X coordinate of the tile for which to request geometry.
 * @param {number} y The Y coordinate of the tile for which to request geometry.
 * @param {number} level The level of the tile for which to request geometry.
 * @param {Request} [request] The request object. Intended for internal use only.
 *
 * @returns {Promise<TerrainData>|undefined} A promise for the requested geometry.  If this method
 *          returns undefined instead of a promise, it is an indication that too many requests are already
 *          pending and the request will be retried later.
 *
 */
CesiumMultiTerrainProvider.prototype.requestTileGeometry = function (x, y, level, request) {
    const layers = this._layers;
    let layerIndexToUse;
    const layerCount = layers.length;

    if (layerCount === 1) {
        layerIndexToUse = 0;
    } else {
        for (let i = 0; i < layerCount; ++i) {
            const layer = layers[i];
            if (
                layer.isVisible &&
                (!defined(layer.availability) ||
                    layer.availability.isTileAvailable(level, x, y) ||
                    isRequestedLevelExceedAccuracy(layer.availability, level, x, y))
            ) {
                layerIndexToUse = i;
                break;
            }
        }
        if (layerIndexToUse === undefined) {
            // console.debug('failed find layerToUse level=' + level + ' x= ' + x + ' y= ' + y);
            return;
        }
    }

    const layerToUse = layers[layerIndexToUse];
    if (layerToUse === undefined) {
        // console.debug('layerToUse === undefined level=' + level + ' x= ' + x + ' y= ' + y);
        return;
    }

    const promiseMainMesh = requestTileGeometry(this, x, y, level, layerToUse, request);
    if (layerIndexToUse < layerCount - 1 && this._enablePatching === true && defined(promiseMainMesh)) {
        const nextLayers = layers.slice(layerCount - 1, layerCount);
        const additionalLayersPromises = nextLayers
            .filter(layer => {
                return layer.isVisible;
            })
            .map(layer => {
                const tileCoords = getBestOfAvailableTileCoordinates(x, y, level, layer);
                return { layer, tileCoords };
            })
            .filter(layerWithTile => {
                return (
                    layerWithTile.layer.isGlobal ||
                    layerWithTile.tileCoords.level === level ||
                    layerWithTile.tileCoords.level === layerWithTile.layer.availability._maximumLevel - 1
                );
            })
            .map(layerWithTile => {
                const request = new Request();
                const tile = layerWithTile.tileCoords;
                return requestTileGeometryWithTileInfo(this, tile.x, tile.y, tile.level, layerWithTile.layer, request);
            });
        const promiseMainTileInfo = promiseMainMesh.then(
            terrainData => {
                return { terrain: terrainData, x: x, y: y, level: level, minElevation: layerToUse.minElevation };
            },
            reason => {
                return reason;
            }
        );
        return Promise.all([promiseMainTileInfo].concat(additionalLayersPromises)).then(values => {
            if (defined(values[0]) && values[0].statusCode === 404) {
                if (defined(values[1]) && level === values[1].level) {
                    return values[1].terrain;
                } else {
                    throw values[0];
                }
            }
            return QuantizedMeshUtil.patchMesh(values, this._tilingScheme);
        });
    }
    return promiseMainMesh;
};

function isRequestedLevelExceedAccuracy(availability, level, x, y) {
    const cartographicScratch = getPositionOfTileCenter(availability, level, x, y);
    const maxLevelForTile = availability.computeMaximumLevelAtPosition(cartographicScratch);
    return maxLevelForTile === availability._maximumLevel - 1 && maxLevelForTile < level;
}

function getPositionOfTileCenter(availability, level, x, y) {
    const rectangleScratch = new Rectangle();
    const cartographicScratch = new Cartographic();
    const rectangle = availability._tilingScheme.tileXYToRectangle(x, y, level, rectangleScratch);
    Rectangle.center(rectangle, cartographicScratch);
    return cartographicScratch;
}

function requestTileGeometryWithTileInfo(provider, x, y, level, layerToUse, request) {
    return requestTileGeometry(provider, x, y, level, layerToUse, request).then(terrainData => {
        return { terrain: terrainData, x: x, y: y, level: level, minElevation: layerToUse.minElevation };
    });
}

function getBestOfAvailableTileCoordinates(x, y, level, layerToUse) {
    const tileCenter = getPositionOfTileCenter(layerToUse.availability, level, x, y);
    const maxLevelForTile = layerToUse.availability.computeMaximumLevelAtPosition(tileCenter);
    if (maxLevelForTile < level) {
        const tileCoord = layerToUse.availability._tilingScheme.positionToTileXY(tileCenter, maxLevelForTile);
        return { x: tileCoord.x, y: tileCoord.y, level: maxLevelForTile };
    } else {
        return { x: x, y: y, level: level };
    }
}

function requestTileGeometry(provider, x, y, level, layerToUse, request) {
    if (!defined(layerToUse)) {
        return Promise.reject(new RuntimeError("Terrain tile doesn't exist"));
    }

    const urlTemplates = layerToUse.tileUrlTemplates;
    if (urlTemplates.length === 0) {
        return undefined;
    }

    // The TileMapService scheme counts from the bottom left
    let terrainY;
    if (!provider._scheme || provider._scheme === 'tms') {
        const yTiles = provider._tilingScheme.getNumberOfYTilesAtLevel(level);
        terrainY = yTiles - y - 1;
    } else {
        terrainY = y;
    }

    const extensionList = [];
    if (provider._requestVertexNormals && layerToUse.hasVertexNormals) {
        extensionList.push(layerToUse.littleEndianExtensionSize ? 'octvertexnormals' : 'vertexnormals');
    }
    if (provider._requestWaterMask && layerToUse.hasWaterMask) {
        extensionList.push('watermask');
    }
    if (provider._requestMetadata && layerToUse.hasMetadata) {
        extensionList.push('metadata');
    }

    let headers;
    let query;
    const url = urlTemplates[(x + terrainY + level) % urlTemplates.length];

    const resource = layerToUse.resource;
    if (defined(resource._ionEndpoint) && !defined(resource._ionEndpoint.externalType)) {
        // ion uses query paremeters to request extensions
        if (extensionList.length !== 0) {
            query = { extensions: extensionList.join('-') };
        }
        headers = getRequestHeader(undefined);
    } else {
        //All other terrain servers
        headers = getRequestHeader(extensionList);
    }

    const promise = resource
        .getDerivedResource({
            url: url,
            templateValues: {
                version: layerToUse.version,
                z: level,
                x: x,
                y: terrainY
            },
            queryParameters: query,
            headers: headers,
            request: request
        })
        .fetchArrayBuffer();

    if (!defined(promise)) {
        return undefined;
    }

    return promise.then(function (buffer) {
        if (!defined(buffer)) {
            return Promise.reject(new RuntimeError("Mesh buffer doesn't exist."));
        }
        if (defined(provider._heightmapStructure)) {
            return createHeightmapTerrainData(provider, buffer);
        }
        return QuantizedMeshUtil.createQuantizedMeshTerrainData(provider, buffer, level, x, y, layerToUse);
    });
}

Object.defineProperties(CesiumMultiTerrainProvider.prototype, {
    /**
     * Gets an event that is raised when the terrain provider encounters an asynchronous error.  By subscribing
     * to the event, you will be notified of the error and can potentially recover from it.  Event listeners
     * are passed an instance of {@link TileProviderError}.
     * @memberof CesiumMultiTerrainProvider.prototype
     * @type {Cesium.Event}
     */
    errorEvent: {
        get: function () {
            return this._errorEvent;
        }
    },

    /**
     * Gets the credit to display when this terrain provider is active.  Typically this is used to credit
     * the source of the terrain.
     * @memberof CesiumMultiTerrainProvider.prototype
     * @type {Credit}
     */
    credit: {
        get: function () {
            return this._credit;
        }
    },

    /**
     * Gets the tiling scheme used by this provider.
     * @memberof CesiumMultiTerrainProvider.prototype
     * @type {GeographicTilingScheme}
     */
    tilingScheme: {
        get: function () {
            return this._tilingScheme;
        }
    },

    /**
     * Gets a value indicating whether or not the provider includes a water mask.  The water mask
     * indicates which areas of the globe are water rather than land, so they can be rendered
     * as a reflective surface with animated waves.
     * @memberof CesiumMultiTerrainProvider.prototype
     * @type {boolean}
     */
    hasWaterMask: {
        get: function () {
            return this._hasWaterMask && this._requestWaterMask;
        }
    },

    /**
     * Gets a value indicating whether or not the requested tiles include vertex normals.
     * @memberof CesiumMultiTerrainProvider.prototype
     * @type {boolean}
     */
    hasVertexNormals: {
        get: function () {
            // returns true if we can request vertex normals from the server
            return this._hasVertexNormals && this._requestVertexNormals;
        }
    },

    /**
     * Gets a value indicating whether or not the requested tiles include metadata.
     * @memberof CesiumMultiTerrainProvider.prototype
     * @type {boolean}
     */
    hasMetadata: {
        get: function () {
            // returns true if we can request metadata from the server
            return this._hasMetadata && this._requestMetadata;
        }
    },

    /**
     * Boolean flag that indicates if the client should request vertex normals from the server.
     * Vertex normals data is appended to the standard tile mesh data only if the client requests the vertex normals and
     * if the server provides vertex normals.
     * @memberof CesiumMultiTerrainProvider.prototype
     * @type {boolean}
     */
    requestVertexNormals: {
        get: function () {
            return this._requestVertexNormals;
        }
    },

    /**
     * Boolean flag that indicates if the client should request a watermask from the server.
     * Watermask data is appended to the standard tile mesh data only if the client requests the watermask and
     * if the server provides a watermask.
     * @memberof CesiumMultiTerrainProvider.prototype
     * @type {boolean}
     */
    requestWaterMask: {
        get: function () {
            return this._requestWaterMask;
        }
    },

    /**
     * Boolean flag that indicates if the client should request metadata from the server.
     * Metadata is appended to the standard tile mesh data only if the client requests the metadata and
     * if the server provides a metadata.
     * @memberof CesiumMultiTerrainProvider.prototype
     * @type {boolean}
     */
    requestMetadata: {
        get: function () {
            return this._requestMetadata;
        }
    },

    /**
     * Gets an object that can be used to determine availability of terrain from this provider, such as
     * at points and in rectangles. This property may be undefined if availability
     * information is not available. Note that this reflects tiles that are known to be available currently.
     * Additional tiles may be discovered to be available in the future, e.g. if availability information
     * exists deeper in the tree rather than it all being discoverable at the root. However, a tile that
     * is available now will not become unavailable in the future.
     * @memberof CesiumMultiTerrainProvider.prototype
     * @type {TileAvailability}
     */
    availability: {
        get: function () {
            return this._availability;
        }
    }
});

/**
 * Gets the maximum geometric error allowed in a tile at a given level.
 *
 * @param {number} level The tile level for which to get the maximum geometric error.
 * @returns {number} The maximum geometric error.
 */
CesiumMultiTerrainProvider.prototype.getLevelMaximumGeometricError = function (level) {
    return this._levelZeroMaximumGeometricError / (1 << level);
};

/**
 * Determines whether data for a tile is available to be loaded.
 *
 * @param {number} x The X coordinate of the tile for which to request geometry.
 * @param {number} y The Y coordinate of the tile for which to request geometry.
 * @param {number} level The level of the tile for which to request geometry.
 * @returns {boolean} Undefined if not supported or availability is unknown, otherwise true or false.
 */
CesiumMultiTerrainProvider.prototype.getTileDataAvailable = function (x, y, level) {
    if (!defined(this._availability)) {
        return undefined;
    }
    if (level > this._availability.maximumLevel) {
        return false;
    }

    for (let i = 0; i < this._layers.length; ++i) {
        const layer = this._layers[i];
        if (!layer.isVisible) {
            continue;
        }
        if (!defined(layer.availability) || layer.availability.isTileAvailable(level, x, y)) {
            return true;
        } else if (isRequestedLevelExceedAccuracy(layer.availability, level, x, y)) {
            return false;
        }
    }
    if (!this._hasMetadata) {
        // If we don't have any layers with the metadata extension then we don't have this tile
        return false;
    }

    const layers = this._layers;
    const count = layers.length;
    for (let j = 0; j < count; ++j) {
        if (!layers[j].isVisible) {
            continue;
        }
        const layerResult = checkLayer(this, x, y, level, layers[j], j === 0);
        if (layerResult.result) {
            // There is a layer that may or may not have the tile
            return undefined;
        }
    }

    return false;
};

/**
 * Makes sure we load availability data for a tile
 *
 * @param {number} x The X coordinate of the tile for which to request geometry.
 * @param {number} y The Y coordinate of the tile for which to request geometry.
 * @param {number} level The level of the tile for which to request geometry.
 * @returns {undefined|Promise<void>} Undefined if nothing need to be loaded or a Promise that resolves when all required tiles are loaded
 */
CesiumMultiTerrainProvider.prototype.loadTileDataAvailability = function (x, y, level) {
    if (
        !defined(this._availability) ||
        level > this._availability.maximumLevel ||
        this._availability.isTileAvailable(level, x, y) ||
        !this._hasMetadata
    ) {
        // We know the tile is either available or not available so nothing to wait on
        return undefined;
    }

    const layers = this._layers;
    const count = layers.length;
    for (let i = 0; i < count; ++i) {
        const layerResult = checkLayer(this, x, y, level, layers[i], i === 0);
        if (defined(layerResult.promise)) {
            return layerResult.promise;
        }
    }
};

/**
 * Creates a {@link TerrainProvider} that accesses terrain data in a Cesium terrain format.
 * Terrain formats can be one of the following:
 * <ul>
 * <li> {@link https://github.com/AnalyticalGraphicsInc/quantized-mesh Quantized Mesh} </li>
 * <li> {@link https://github.com/AnalyticalGraphicsInc/cesium/wiki/heightmap-1.0 Height Map} </li>
 * </ul>
 *
 * @param [{Resource|String|Promise<Resource>|Promise<String>}] url The URL of the Cesium terrain server.
 * @param {CesiumMultiTerrainProvider.ConstructorOptions} [options] An object describing initialization options.
 * @returns {Promise<CesiumMultiTerrainProvider>}
 *
 * @example
 * // Create Arctic DEM terrain with normals.
 * try {
 *   const viewer = new Cesium.Viewer("cesiumContainer", {
 *     terrainProvider: await Cesium.CesiumMultuTerrainProvider.fromUrl(
 *       [Cesium.IonResource.fromAssetId(3956)], {
 *         requestVertexNormals: true
 *     })
 *   });
 * } catch (error) {
 *   console.log(error);
 * }
 *
 * @exception {RuntimeError} layer.json does not specify a format
 * @exception {RuntimeError} layer.json specifies an unknown format
 * @exception {RuntimeError} layer.json specifies an unsupported quantized-mesh version
 * @exception {RuntimeError} layer.json does not specify a tiles property, or specifies an empty array
 * @exception {RuntimeError} layer.json does not specify any tile URL templates
 */
CesiumMultiTerrainProvider.fromUrl = async function (url, options) {
    //>>includeStart('debug', pragmas.debug);
    Check.defined('url', url);
    //>>includeEnd('debug');

    options = defaultValue(options, defaultValue.EMPTY_OBJECT);

    url = await Promise.resolve(url);

    const terrainProviderBuilder = new TerrainProviderBuilder(options);
    let p = Promise.resolve();
    const that = this;
    url.forEach(
        url => (p = p.then(() => addOneLayer(url, true, terrainProviderBuilder)).catch(err => Promise.reject(err)))
    );

    await p
        .then(() => {
            that._addingLayersIsAvailable = true;
        })
        .catch(err => Promise.reject(err));

    const provider = new CesiumMultiTerrainProvider(options);
    terrainProviderBuilder.build(provider);

    return provider;
};

/**
 * Creates a {@link TerrainProvider} from a Cesium ion asset ID that accesses terrain data in a Cesium terrain format
 * Terrain formats can be one of the following:
 * <ul>
 * <li> {@link https://github.com/AnalyticalGraphicsInc/quantized-mesh Quantized Mesh} </li>
 * <li> {@link https://github.com/AnalyticalGraphicsInc/cesium/wiki/heightmap-1.0 Height Map} </li>
 * </ul>
 *
 * @param {number} assetId The Cesium ion asset id.
 * @param {CesiumMultiTerrainProvider.ConstructorOptions} [options] An object describing initialization options.
 * @returns {Promise<CesiumMultiTerrainProvider>}
 *
 * @example
 * // Create Arctic DEM terrain with normals.
 * try {
 *   const viewer = new Cesium.Viewer("cesiumContainer", {
 *     terrainProvider: await Cesium.CesiumMultiTerrainProvider.fromIonAssetId(3956, {
 *         requestVertexNormals: true
 *     })
 *   });
 * } catch (error) {
 *   console.log(error);
 * }
 *
 * @exception {RuntimeError} layer.json does not specify a format
 * @exception {RuntimeError} layer.json specifies an unknown format
 * @exception {RuntimeError} layer.json specifies an unsupported quantized-mesh version
 * @exception {RuntimeError} layer.json does not specify a tiles property, or specifies an empty array
 * @exception {RuntimeError} layer.json does not specify any tile URL templates
 */
CesiumMultiTerrainProvider.fromIonAssetId = async function (assetId, options) {
    //>>includeStart('debug', pragmas.debug);
    Check.defined('assetId', assetId);
    //>>includeEnd('debug');

    const resource = await IonResource.fromAssetId(assetId);
    return CesiumMultiTerrainProvider.fromUrl([resource], options);
};

function getAvailabilityTile(layer, x, y, level) {
    if (level === 0) {
        return;
    }

    const availabilityLevels = layer.availabilityLevels;
    const parentLevel =
        level % availabilityLevels === 0
            ? level - availabilityLevels
            : ((level / availabilityLevels) | 0) * availabilityLevels;
    const divisor = 1 << (level - parentLevel);
    const parentX = (x / divisor) | 0;
    const parentY = (y / divisor) | 0;

    return {
        level: parentLevel,
        x: parentX,
        y: parentY
    };
}

function checkLayer(provider, x, y, level, layer, topLayer) {
    if (!defined(layer.availabilityLevels)) {
        // It's definitely not in this layer
        return {
            result: false
        };
    }

    let cacheKey;
    const deleteFromCache = function () {
        delete layer.availabilityPromiseCache[cacheKey];
    };
    const availabilityTilesLoaded = layer.availabilityTilesLoaded;
    const availability = layer.availability;

    let tile = getAvailabilityTile(layer, x, y, level);
    while (defined(tile)) {
        if (
            availability.isTileAvailable(tile.level, tile.x, tile.y) &&
            !availabilityTilesLoaded.isTileAvailable(tile.level, tile.x, tile.y)
        ) {
            let requestPromise;
            if (!topLayer) {
                cacheKey = `${tile.level}-${tile.x}-${tile.y}`;
                requestPromise = layer.availabilityPromiseCache[cacheKey];
                if (!defined(requestPromise)) {
                    // For cutout terrain, if this isn't the top layer the availability tiles
                    //  may never get loaded, so request it here.
                    const request = new Request({
                        throttle: false,
                        throttleByServer: true,
                        type: RequestType.TERRAIN
                    });
                    requestPromise = requestTileGeometry(provider, tile.x, tile.y, tile.level, layer, request);
                    if (defined(requestPromise)) {
                        layer.availabilityPromiseCache[cacheKey] = requestPromise;
                        requestPromise.then(deleteFromCache);
                    }
                }
            }

            // The availability tile is available, but not loaded, so there
            //  is still a chance that it may become available at some point
            return {
                result: true,
                promise: requestPromise
            };
        }

        tile = getAvailabilityTile(layer, tile.x, tile.y, tile.level);
    }

    return {
        result: false
    };
}

function addOneLayer(url, visible, terrainProviderBuilder, provider) {
    return Promise.resolve(url).then(function (url) {
        const resource = Resource.createIfNeeded(url);
        resource.appendForwardSlash();
        terrainProviderBuilder.lastResource = resource;
        terrainProviderBuilder.isVisible = visible === undefined ? true : visible;
        terrainProviderBuilder.layerJsonResource = resource.getDerivedResource({
            url: 'layer.json'
        });
        return requestLayerJson(terrainProviderBuilder, provider);
    });
}

CesiumMultiTerrainProvider.prototype.disableLayer = async function (viewer, url) {
    const layers = this._layers;
    const count = layers.length;
    for (let i = 0; i < count; ++i) {
        if (layers[i].resource.url === url) {
            layers[i].isVisible = false;
            const qtPrimitive = viewer.scene.globe._surface;
            qtPrimitive.invalidateAllTiles();
        }
    }
};

CesiumMultiTerrainProvider.prototype.enableLayer = async function (viewer, url) {
    const layers = this._layers;
    const count = layers.length;
    for (let i = 0; i < count; ++i) {
        if (layers[i].resource.url === url) {
            layers[i].isVisible = true;
            const qtPrimitive = viewer.scene.globe._surface;
            qtPrimitive.invalidateAllTiles();
        }
    }
};

/**
 * Get layer info
 *
 * @param {String} url Url of the layer.
 */
CesiumMultiTerrainProvider.prototype.getLayer = function (url) {
    const layers = this._layers;
    const count = layers.length;
    for (let i = 0; i < count; ++i) {
        if (layers[i].resource.url === url) {
            return layers[i];
        }
    }
    return undefined;
};

/**
 * Get layers list
 *
 */
CesiumMultiTerrainProvider.prototype.getLayers = function () {
    return this._layers;
};

// Used for testing
CesiumMultiTerrainProvider._getAvailabilityTile = getAvailabilityTile;
export default CesiumMultiTerrainProvider;
