import { useTranslation } from 'react-i18next';
import { DocumentLog } from '../../../../generated/cloud-frontend-api';
import { ProcessingLogsDownloader } from '../../logs-downloader/ProcessingLogsDownloader';

type Props = {
    projectUid: string | undefined;
    access: string | undefined;
    processingLogs: DocumentLog[] | undefined;
};

export default function LogSharedProjectAction({ projectUid, access, processingLogs }: Props) {
    const { t } = useTranslation(['projects']);

    function download() {
        const documentDownloader = new ProcessingLogsDownloader();
        const downloadDocuments = new Map<string, string>();
        processingLogs?.forEach(log => {
            if (log.documentUid !== undefined && log.fileName !== undefined) {
                downloadDocuments.set(log.documentUid, log.fileName);
            }
        });

        if (projectUid !== undefined) {
            documentDownloader.download(projectUid, 'metashape-log', downloadDocuments, access);
        }
    }

    return (
        <a className='tippy-dropdown-item isLink' onClick={download}>
            {t('projects:projectActions.log')}
        </a>
    );
}
