import Modal, { ModalProps } from '../modal/Modal';
import ModalBody from '../modal/ModalBody';
import ModalHead from '../modal/ModalHead';
import ModalActions from '../modal/ModalActions';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../../store';

type Props = ModalProps;

export default function AddProjectHelpModal({ isOpen, setIsOpen }: Props) {
    const { t } = useTranslation('modals');
    const supportLink = useSelector(state => state.init.supportLink);

    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            onClick={e => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                setIsOpen(false); // close modal on overlay click
            }}
        >
            <ModalHead setIsOpen={setIsOpen}>{t('addProjectHelpModal.head')}</ModalHead>
            <ModalBody>
                <div className='modal-subtitle'>{t('addProjectHelpModal.subtitle1')}</div>
                <p className='modal-paragraph'>{t('addProjectHelpModal.paragraph1')}</p>
                <div className='modal-subtitle'>{t('addProjectHelpModal.subtitle2')}</div>
                <p className='modal-paragraph'>{t('addProjectHelpModal.paragraph2')}</p>
                <div className='modal-subtitle'>{t('addProjectHelpModal.subtitle3')}</div>
                <p className='modal-paragraph'>{t('addProjectHelpModal.paragraph3')}</p>
                <ModalActions>
                    <a className='modal-action btn' href={supportLink} target='_blank' rel={'noopener noreferrer'}>
                        {t('addProjectHelpModal.tutorialLink')}
                    </a>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}
