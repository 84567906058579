import { AccessInfoAccessTypeEnum, AccessInfoProjectRoleEnum } from '../generated/access-api-v2/model';
import { AccessInfo, ProjectInfo } from '../generated/cloud-frontend-api/model';

export function extractEmbedCode(project: ProjectInfo): string | undefined {
    return project.accesses?.find(
        accessItem =>
            accessItem.accessType === AccessInfoAccessTypeEnum.EMBED &&
            accessItem.projectRole !== AccessInfoProjectRoleEnum.NO_ACCESS
    )?.accessKey;
}

export function hasSharedOrEmbedAccess(accesses: AccessInfo[] | undefined): boolean {
    return hasSharedAccess(accesses) || hasEmbedAccess(accesses);
}

function hasSharedAccess(accesses: AccessInfo[] | undefined): boolean {
    return (
        !!accesses &&
        accesses.length !== 0 &&
        accesses.filter(
            accessItem =>
                accessItem.projectRole !== AccessInfoProjectRoleEnum.NO_ACCESS &&
                (accessItem.accessType === AccessInfoAccessTypeEnum.LINK ||
                    accessItem.accessType === AccessInfoAccessTypeEnum.EMAIL)
        ).length !== 0
    );
}

function hasEmbedAccess(accesses: AccessInfo[] | undefined): boolean {
    return (
        !!accesses &&
        accesses.length !== 0 &&
        accesses.filter(
            accessItem =>
                accessItem.projectRole !== AccessInfoProjectRoleEnum.NO_ACCESS &&
                accessItem.accessType === AccessInfoAccessTypeEnum.EMBED
        ).length !== 0
    );
}
