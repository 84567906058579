import * as Cesium from 'cesium';
import { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { BillboardGraphics, Entity, PointGraphics } from 'resium';
import { useKey } from 'rooks';
import pointTickImage from '../../../../assets/images/point_tick.svg';
import { polygonMinVerticesCount, polylineMinVerticesCount } from '../../../../hooks/useDrawingCancel';
import useProjectStructureObjectsActions from '../../../../hooks/useProjectStructureObjectsActions';
import { AppDispatch, useSelector } from '../../../../store';
import { isPolygonGeometry, isPolylineGeometry } from '../../../../store/helpers/interfaces';
import { removeCoordinateFromGeometry, updateGeometryContent } from '../../../../store/slices/geometries';
import { updateGeometryById } from '../../../../store/slices/geometryLayers';
import { setHoveredGeometryNodeId, setSelectedGeometryNodeId } from '../../../../store/slices/projectView';
import addNodeImage from '../../../../svg/project_view/add_node.svg';
import splitEntityId from '../../geometry-drawing/splitEntityId';
import { POINT_STYLES } from '../styling';
import { calculationAborted, invalidateCalculation } from '../../../../store/slices/volume';
import { elevationProfileCalculationInterrupted } from '../../../../store/sharedActions';
import ProjectViewAccessContext from '../../../../contexts/ProjectViewAccessContext';

type Props = {
    id: string;
    visible: boolean;
    position: Cesium.Cartesian3;
    color: Cesium.Color;
    isSavingPoint?: boolean;
    isMidpoint?: boolean;
    mouseEventsBlocked?: boolean;
    readonly?: boolean;
};

export default function PointNodeGeometry({
    position,
    id,
    visible,
    color,
    isSavingPoint,
    isMidpoint,
    mouseEventsBlocked,
    readonly
}: Props) {
    const dispatch: AppDispatch = useDispatch();
    const hoveredGeometryNodeId = useSelector(state => state.projectView.hoveredGeometryNodeId);
    const selectedGeometryNodeId = useSelector(state => state.projectView.selectedGeometryNodeId);
    const geometries = useSelector(state => state.geometries.entities);
    const { owned } = useContext(ProjectViewAccessContext);

    const pointId = `${id}${isSavingPoint ? '#SavingPoint' : ''}`;
    const isHovered = hoveredGeometryNodeId === pointId;
    const isSelected = selectedGeometryNodeId === pointId;

    const size = useMemo(() => {
        if (isSavingPoint) return POINT_STYLES.savingPointSize;
        if (isHovered || isSelected) return POINT_STYLES.sizeActive;
        return POINT_STYLES.size;
    }, [isSavingPoint, isHovered, isSelected]);

    const actualColor = useMemo(() => {
        if (isMidpoint) return color.withAlpha(POINT_STYLES.midpointColorAlpha);
        if (isSavingPoint) return color.withAlpha(POINT_STYLES.savingPointColorAlpha);
        return color;
    }, [isSavingPoint, isMidpoint, color]);

    const { deleteGeometry } = useProjectStructureObjectsActions();

    useKey('Delete', () => {
        if (!isSelected) return;

        const [type, shapeId, index, midpoint] = splitEntityId(pointId);
        if (midpoint) return;

        const geometry = geometries[shapeId];
        if (geometry) {
            const verticesCount = (() => {
                if (isPolylineGeometry(geometry)) return geometry.content.geometry.coordinates.length;
                if (isPolygonGeometry(geometry)) return geometry.content.geometry.coordinates[0].length;
                return 0;
            })();
            const isBecomingInvalid = () => {
                if (isPolylineGeometry(geometry)) return verticesCount - 1 < polylineMinVerticesCount;
                if (isPolygonGeometry(geometry)) return verticesCount - 1 < polygonMinVerticesCount;
                return false;
            };
            if (isBecomingInvalid()) {
                deleteGeometry(geometry);
            } else {
                dispatch(removeCoordinateFromGeometry({ id: shapeId, index: Number(index!) }));
                dispatch(updateGeometryById(shapeId));
            }
            dispatch(setSelectedGeometryNodeId(undefined));
            dispatch(setHoveredGeometryNodeId(undefined));

            dispatch(calculationAborted(shapeId));
            dispatch(invalidateCalculation({ id: shapeId, owned }));
            dispatch(elevationProfileCalculationInterrupted(shapeId));
        }
    });

    return (
        <Entity
            id={pointId}
            position={position}
            onClick={e => {
                if (mouseEventsBlocked || readonly || isMidpoint) return;
                dispatch(setSelectedGeometryNodeId(pointId));
            }}
            onMouseEnter={e => {
                if (mouseEventsBlocked || readonly) return;
                dispatch(setHoveredGeometryNodeId(id));
            }}
            onMouseLeave={e => {
                if (mouseEventsBlocked || readonly) return;
                if (isHovered) dispatch(setHoveredGeometryNodeId(undefined));
            }}
        >
            <PointGraphics
                pixelSize={size}
                color={actualColor}
                disableDepthTestDistance={Infinity}
                heightReference={Cesium.HeightReference.NONE}
                show={visible}
            />
            {isMidpoint && (
                <BillboardGraphics
                    image={addNodeImage}
                    disableDepthTestDistance={Infinity}
                    heightReference={Cesium.HeightReference.NONE}
                    show={visible}
                />
            )}
            {isSavingPoint && (
                <BillboardGraphics
                    image={pointTickImage}
                    disableDepthTestDistance={Infinity}
                    heightReference={Cesium.HeightReference.NONE}
                    show={visible}
                />
            )}
            {/* <LabelGraphics text={pointId} font={'sans-serif 8px'} /> */}
        </Entity>
    );
}
