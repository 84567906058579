import * as Cesium from 'cesium';
import _ from 'lodash';
import { memo, useMemo, useState } from 'react';
import { Tuple } from '../../../../sharedConstants';
import { useSelector } from '../../../../store';
import { ExtendedStructureInfo, selectLimitBoxParamsOfEnabledLimitBox } from '../../../../store/slices/structure';
import LimitBoxFace from './LimitBoxFace';

type Props = {
    boundingSphere: Cesium.BoundingSphere;
    structureInfo: ExtendedStructureInfo;
    transform: Cesium.Matrix4;
};

interface NormalAndDistance {
    normal: Cesium.Cartesian3;
    distance: number;
}
export interface LimitBoxParams {
    x: NormalAndDistance; // Right plane
    y: NormalAndDistance; // Top plane
    z: NormalAndDistance; // Front plane
    negativeX: NormalAndDistance; // Left plane
    negativeY: NormalAndDistance; // Bottom plane
    negativeZ: NormalAndDistance; // Back plane
}

function LimitBox({ boundingSphere, structureInfo, transform }: Props) {
    const limitBoxParams = useSelector(state => selectLimitBoxParamsOfEnabledLimitBox(state));
    const [hoveredPlaneId, setHoveredPlaneId] = useState<keyof LimitBoxParams | null>(null);

    const defaultBoxParams = useMemo(() => getDefaultLimitBoxParams(boundingSphere), [boundingSphere]);
    const boxParams = useMemo(() => limitBoxParams || defaultBoxParams, [limitBoxParams, defaultBoxParams]);

    // const transform = useMemo(() => Cesium.Transforms.eastNorthUpToFixedFrame(boundingSphere.center), [boundingSphere]);
    const planesCoordinates = useMemo(() => calculateLimitBox(boxParams, transform), [boxParams, transform]);

    return (
        <>
            {_.map(planesCoordinates, (coordinates, key) => {
                return (
                    <LimitBoxFace
                        key={key}
                        faceId={key as keyof LimitBoxParams}
                        coordinates={coordinates}
                        setHoveredPlaneId={setHoveredPlaneId}
                        hasHover={key === hoveredPlaneId}
                    />
                );
            })}
        </>
    );
}

export default memo(LimitBox);

export function getDefaultLimitBoxParams(boundingSphere: Cesium.BoundingSphere): LimitBoxParams {
    return {
        x: { normal: new Cesium.Cartesian3(1, 0, 0), distance: boundingSphere.radius },
        y: { normal: new Cesium.Cartesian3(0, 1, 0), distance: boundingSphere.radius },
        z: { normal: new Cesium.Cartesian3(0, 0, 1), distance: boundingSphere.radius },
        negativeX: { normal: new Cesium.Cartesian3(-1, 0, 0), distance: boundingSphere.radius },
        negativeY: { normal: new Cesium.Cartesian3(0, -1, 0), distance: boundingSphere.radius },
        negativeZ: { normal: new Cesium.Cartesian3(0, 0, -1), distance: boundingSphere.radius }
    };
}

function calculateLimitBox(
    boxParams: LimitBoxParams,
    transform: Cesium.Matrix4
): Record<keyof LimitBoxParams, Tuple<Cesium.Cartesian3, 4>> {
    const extendedNormals = _.map(boxParams, value =>
        Cesium.Cartesian3.multiplyByScalar(value.normal, value.distance, new Cesium.Cartesian3())
    );
    const [xVector, yVector, zVector, negativeXVector, negativeYVector, negativeZVector] = extendedNormals;

    return {
        x: [
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-xVector.x, -yVector.y, -zVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-xVector.x, -negativeYVector.y, -zVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-xVector.x, -negativeYVector.y, -negativeZVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-xVector.x, -yVector.y, -negativeZVector.z),
                new Cesium.Cartesian3()
            )
        ],
        y: [
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-negativeXVector.x, -yVector.y, -zVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-xVector.x, -yVector.y, -zVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-xVector.x, -yVector.y, -negativeZVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-negativeXVector.x, -yVector.y, -negativeZVector.z),
                new Cesium.Cartesian3()
            )
        ],
        z: [
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-negativeXVector.x, -yVector.y, -zVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-xVector.x, -yVector.y, -zVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-xVector.x, -negativeYVector.y, -zVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-negativeXVector.x, -negativeYVector.y, -zVector.z),
                new Cesium.Cartesian3()
            )
        ],
        negativeX: [
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-negativeXVector.x, -yVector.y, -zVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-negativeXVector.x, -negativeYVector.y, -zVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-negativeXVector.x, -negativeYVector.y, -negativeZVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-negativeXVector.x, -yVector.y, -negativeZVector.z),
                new Cesium.Cartesian3()
            )
        ],
        negativeY: [
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-negativeXVector.x, -negativeYVector.y, -zVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-xVector.x, -negativeYVector.y, -zVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-xVector.x, -negativeYVector.y, -negativeZVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-negativeXVector.x, -negativeYVector.y, -negativeZVector.z),
                new Cesium.Cartesian3()
            )
        ],
        negativeZ: [
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-negativeXVector.x, -yVector.y, -negativeZVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-xVector.x, -yVector.y, -negativeZVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-xVector.x, -negativeYVector.y, -negativeZVector.z),
                new Cesium.Cartesian3()
            ),
            Cesium.Matrix4.multiplyByPoint(
                transform,
                new Cesium.Cartesian3(-negativeXVector.x, -negativeYVector.y, -negativeZVector.z),
                new Cesium.Cartesian3()
            )
        ]
    };
}
