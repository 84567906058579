import classnames from 'classnames';

type Props = {
    isEmbed: boolean;
};

const SkeletonArtifact = ({ isEmbed }: Props) => {
    return (
        <div className='artefact'>
            <div className='artefact-head'>
                <span className={classnames('skeleton-arrow', { embed: isEmbed })} />
                <span className={classnames('skeleton-title', { embed: isEmbed })} />
            </div>
        </div>
    );
};

export default SkeletonArtifact;
