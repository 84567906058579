import { GeoJson } from '../helpers/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GeometryTypes } from '../../sharedConstants';
import { disableAllControls, resetProjectView, setRulerToolEnabled } from './projectView';

interface RulerToolState {
    geometry: GeoJson<GeometryTypes.POLYLINE> | null;
}

const initialState: RulerToolState = {
    geometry: null
};

const name = 'rulerTool';

const slice = createSlice({
    name,
    initialState,
    reducers: {
        createdRulerGeometry(state, { payload }: PayloadAction<GeoJson<GeometryTypes.POLYLINE>>) {
            state.geometry = payload;
        },
        addedPointToRulerGeometry(state, { payload }: PayloadAction<{ coordinates: number[] }>) {
            if (state.geometry) state.geometry.geometry.coordinates.push(payload.coordinates);
        },
        resetRulerGeometry(state) {
            state.geometry = null;
        }
    },
    extraReducers: builder => {
        // Reset state when user disables ruler tool
        builder
            .addCase(setRulerToolEnabled, (state, { payload }) => {
                if (!payload) state.geometry = null;
            })
            .addCase(resetProjectView, state => {
                state.geometry = null;
            })
            .addCase(disableAllControls, state => {
                state.geometry = null;
            });
    }
});

export const { createdRulerGeometry, addedPointToRulerGeometry, resetRulerGeometry } = slice.actions;

export default slice.reducer;
