import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Dataset } from '../../../entities/Dataset';
import { ProjectPartType } from '../../../generated/cloud-frontend-api';
import convertSize from '../../../lib/convertSize';
import { AppDispatch, useSelector } from '../../../store';
import { ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { ExtendedDatasetInfo } from '../../../store/slices/datasetfilesUpload';
import {
    expandedPropertiesBlockNames,
    selectPropertiesBlockExpansion,
    updateStructureInfo
} from '../../../store/slices/structure';
import GeneralProperty from '../../inspection-sidebar/GeneralProperty';
import CopyPropertiesControl from '../geometry-properties/elements/CopyPropertiesControl';
import PropertiesBlockHead from '../geometry-properties/elements/PropertiesBlockHead';

type Props = {
    dataset: ExtendedDatasetInfo;
    datasetCrsName: string;
};

const expandedPropertyName: (typeof expandedPropertiesBlockNames)[number] = 'generalBlockExpanded';

export default function DatasetGeneralPropertiesBlock({ dataset, datasetCrsName }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const isGeneralBlockExpanded = useSelector(state =>
        selectPropertiesBlockExpansion(state, dataset.datasetUid!, expandedPropertyName)
    );

    const showSourceSize = dataset.projectPartType !== ProjectPartType.TILESETS;
    const isLayer = dataset.projectPartType === ProjectPartType.VECTOR_LAYERS;

    const { hasFailedPublishing, isUploading, isPublishing } = useMemo(() => new Dataset(dataset), [dataset]);

    const tilesetSize = (() => {
        if (isUploading || isPublishing || hasFailedPublishing) return '-';
        return convertSize(dataset?.visualData?.sizeInBytes ?? 0) || '-';
    })();

    const sourceSize = (() => {
        if (isUploading) return '-';
        return convertSize(dataset?.sourceData?.sizeInBytes ?? 0) || '-';
    })();

    const sourceSizeTitle = isLayer
        ? t('projectView:inspectionSidebar.datasetProperties.size')
        : t('projectView:inspectionSidebar.datasetProperties.sourceFile');

    const textForCopying = (() => {
        let text = `${t('projectView:inspectionSidebar.datasetProperties.crs')}\t${datasetCrsName}`;
        if (!isLayer) {
            text = text.concat(`\n${t('projectView:inspectionSidebar.datasetProperties.tileset')}\t${tilesetSize}`);
        }
        text = text.concat(`\n${sourceSizeTitle}\t${sourceSize}`);
        return text;
    })();

    return (
        <div className='inspector-properties'>
            <PropertiesBlockHead
                isBlockExpanded={isGeneralBlockExpanded}
                title={t('projectView:inspectionSidebar.titleGeneral')}
                onClick={e => {
                    dispatch(
                        updateStructureInfo({
                            projectId: projectInfo.id!,
                            structureUid: dataset.datasetUid!,
                            type: ProjectStructureObjectTypes.DATASET,
                            propName: expandedPropertyName,
                            propValue: isGeneralBlockExpanded ? String(false) : String(true)
                        })
                    );
                }}
            >
                <CopyPropertiesControl clipboardText={textForCopying} />
            </PropertiesBlockHead>
            {isGeneralBlockExpanded && (
                <div className='properties-list'>
                    <GeneralProperty
                        label={t('projectView:inspectionSidebar.datasetProperties.crs')}
                        value={datasetCrsName}
                    />
                    {!isLayer && (
                        <GeneralProperty
                            label={t('projectView:inspectionSidebar.datasetProperties.tileset')}
                            value={tilesetSize}
                        />
                    )}
                    {showSourceSize && <GeneralProperty label={sourceSizeTitle} value={sourceSize} />}
                </div>
            )}
        </div>
    );
}
