import { ChartOptions } from 'chart.js';
import i18n from '../../../i18n/config';
import formatNumber from '../../../lib/formatNumber';

// Chart.js graphs general options
type Props = {
    distanceUnits: string;
    elevationUnits: string;
};

export const graphTheme = {
    background: {
        default: 'rgba(73,138,214,0.35)', // = #498AD6 with alpha=0.35
        embed: 'rgba(73,138,214,0.55)' // = #498AD6 with alpha=0.55
    }
};

export default function getGraphOptions({ distanceUnits, elevationUnits }: Props): ChartOptions<'line'> {
    function setTooltipTitle(context: any) {
        return `${i18n.t('projectView:elevationProfile.graph.dist')} ${formatNumber(
            context[0].label,
            2
        )} ${distanceUnits}`;
    }

    function setTooltipLabel(context: any) {
        return ` ${formatNumber(context.parsed.y, 3)} ${elevationUnits}`;
    }

    return {
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
            intersect: false,
            mode: 'nearest' as const,
            axis: 'x' as const
        },
        scales: {
            x: {
                position: 'top' as const,
                grid: {
                    color: '#ddd'
                },
                border: {
                    color: '#ddd'
                },
                ticks: {
                    maxRotation: 0,
                    color: '#909090',
                    font: {
                        family: 'Lato',
                        size: 10
                    }
                }
            },
            y: {
                grid: {
                    color: '#ddd'
                },
                border: {
                    color: '#ddd'
                },
                ticks: {
                    color: '#909090',
                    font: {
                        family: 'Lato',
                        size: 10
                    }
                }
            }
        },
        elements: {
            point: {
                radius: 0,
                hitRadius: 7
            },
            line: {
                borderWidth: 1
            }
        },
        plugins: {
            tooltip: {
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                borderColor: 'rgba(0,0,0,0.2)',
                borderWidth: 1,
                displayColors: true,
                callbacks: {
                    title: setTooltipTitle,
                    label: setTooltipLabel
                },
                titleColor: '#1e1e1e',
                titleFont: {
                    family: 'Lato',
                    size: 12,
                    weight: 'normal' as const
                },
                titleMarginBottom: 2,
                bodyColor: '#1e1e1e',
                bodyFont: {
                    family: 'Lato',
                    size: 12,
                    weight: 'normal' as const
                },
                padding: {
                    top: 4,
                    right: 5,
                    bottom: 3,
                    left: 5
                }
            }
        },
        datasets: {
            line: {
                label: i18n.t('projectView:elevationProfile.graph.datasetLabel')
                // pointBackgroundColor: '#FF981F',
                // pointBorderColor: '#FF981F'
            }
        }
    };
}
