import classNames from 'classnames';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';

type Props = {
    onClick(e: SyntheticEvent): void;
    extendedClassName?: boolean;
    showUnlinkTooltip?: boolean;
};

export default function DeleteControl({ onClick, extendedClassName = true, showUnlinkTooltip = false }: Props) {
    const { t } = useTranslation('projectView');

    return (
        <TippyTooltip
            tooltipText={
                showUnlinkTooltip
                    ? `${t('structureItem.controls.tooltipUnlink')}`
                    : `${t('structureItem.controls.tooltipDelete')}`
            }
        >
            <span
                className={classNames('control', { 'control-extended': extendedClassName })}
                onClick={e => {
                    e.stopPropagation();
                    onClick(e);
                }}
            >
                <i className='icon icon-delete' data-testid={'deleteIcon'} />
            </span>
        </TippyTooltip>
    );
}
