import { Select } from 'antd';
import { Form, Formik } from 'formik';
import _, { isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PlanType } from '../../../generated/account-frontend-api';
import { SupportedLocales, countriesToLangMapping } from '../../../i18n/config';
import { definedStatesCountries, statesMapping } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { updatePersonalInfo } from '../../../store/slices/user';
import FieldWithErrorMessage from './FieldWithErrorMessage';
import { CommercialPlanProfileSchema } from './validationSchemas';

import styles from '../Billing.module.scss';

type Props = {
    useCommercialPlanValidationSchema?: boolean;
    onSubmit?(): void;
    usedInUpgradePlanModal?: boolean;
    modalCancelButton?: JSX.Element;
    modalSubmitButtonTitle?: string;
};

export default function ProfileForm({
    useCommercialPlanValidationSchema,
    onSubmit,
    usedInUpgradePlanModal,
    modalCancelButton,
    modalSubmitButtonTitle
}: Props) {
    const { t, i18n } = useTranslation('cabinet');
    const dispatch: AppDispatch = useDispatch();
    const personalInfo = useSelector(state => state.user);
    const emailSubscriptions = useSelector(state => state.emailSubscriptions.list);
    const plan = useSelector(state => state.accountResources.account.plan);
    const [isCountryEmpty, setIsCountryEmpty] = useState(false);
    const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);

    return (
        <Formik
            enableReinitialize
            initialValues={{
                country: personalInfo.billingAddress.country,
                city: personalInfo.billingAddress.city || '', // fix: 'A component is changing an uncontrolled input to be controlled' warning
                region: personalInfo.billingAddress.region || '', // fix: 'A component is changing an uncontrolled input to be controlled' warning
                postalCode: personalInfo.billingAddress.postalCode || '', // fix: 'A component is changing an uncontrolled input to be controlled' warning
                address: personalInfo.billingAddress.address || '', // fix: 'A component is changing an uncontrolled input to be controlled' warning
                company: personalInfo.company
            }}
            validationSchema={
                plan.current?.type === PlanType.COMMERCIAL || useCommercialPlanValidationSchema
                    ? CommercialPlanProfileSchema
                    : undefined
            }
            onSubmit={async (values, { setFieldError, resetForm }) => {
                const result = await dispatch(
                    updatePersonalInfo({
                        company: values.company,
                        firstName: personalInfo.firstName,
                        lastName: personalInfo.lastName,
                        email: personalInfo.email,
                        subscriptions: { subscriptions: emailSubscriptions },
                        billingAddress: _.pick(values, ['country', 'address', 'region', 'postalCode', 'city'])
                    })
                );
                if (updatePersonalInfo.fulfilled.match(result)) {
                    setSuccessfullySubmitted(true);
                    resetForm({ values });
                    onSubmit?.();
                }
                if (updatePersonalInfo.rejected.match(result)) {
                    const error = result.payload;
                    if (error?.message === 'postalcode invalid')
                        setFieldError('postalCode', t('billing.postalCode.invalid'));
                }
            }}
        >
            {({ dirty, errors, values, setFieldValue, setFieldTouched, setFieldError }) => (
                <Form id={'profileForm'} className='form-profile'>
                    <div className={styles.fieldsWrapper}>
                        <div className={styles.fieldTitleContainer}>
                            <div className={styles.fieldTitle}>{t('billing.country.label')}</div>
                        </div>
                        <div>
                            <Select
                                value={values.country}
                                placeholder={t('billing.country.placeholder')}
                                showSearch={true}
                                onChange={(newValue: string) => {
                                    setFieldValue('country', newValue);

                                    if (definedStatesCountries.includes(newValue)) {
                                        setFieldValue('region', statesMapping[newValue][0].code);
                                    } else if (definedStatesCountries.includes(values.country)) {
                                        setFieldValue('region', '');
                                    }

                                    setFieldTouched('region', false);
                                    setFieldError('region', undefined);
                                }}
                                filterOption={(inputValue: string, option: any) => {
                                    return option?.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                                }}
                                notFoundContent={false}
                                onFocus={() => setIsCountryEmpty(false)}
                                onBlur={() => setIsCountryEmpty(values.country === undefined)}
                                status={isCountryEmpty ? 'error' : undefined}
                            >
                                {Object.entries(countriesToLangMapping[i18n.resolvedLanguage as SupportedLocales]).map(
                                    ([code, value]) => (
                                        <Select.Option key={code} value={code}>
                                            {value.name}
                                        </Select.Option>
                                    )
                                )}
                            </Select>
                            <div className='field-error'>{isCountryEmpty && t('billing.country.errorRequired')}</div>
                        </div>

                        <div className={styles.fieldTitleContainer}>
                            <div className={styles.fieldTitle}>{t('billing.region.label')}</div>
                        </div>
                        <div>
                            {definedStatesCountries.includes(values.country) ? (
                                <>
                                    <Select
                                        value={values.region}
                                        placeholder={t('billing.region.placeholderSelect')}
                                        showSearch={true}
                                        onChange={(newValue: string) => {
                                            setFieldValue('region', newValue);
                                        }}
                                        filterOption={(inputValue: string, option: any) => {
                                            return (
                                                option?.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                            );
                                        }}
                                        notFoundContent={false}
                                    >
                                        {statesMapping[values.country]?.map(s => (
                                            <Select.Option key={s.iso} value={s.iso}>
                                                {s.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                    <div className='field-error' />
                                </>
                            ) : (
                                <FieldWithErrorMessage
                                    type='text'
                                    name='region'
                                    placeholder={t('billing.region.placeholder')}
                                    trim={true}
                                />
                            )}
                        </div>

                        <div className={styles.fieldTitleContainer}>
                            <div className={styles.fieldTitle}>{t('billing.city.label')}</div>
                        </div>
                        <div>
                            <FieldWithErrorMessage
                                type='text'
                                name='city'
                                placeholder={t('billing.city.placeholder')}
                                trim={true}
                            />
                        </div>

                        <div className={styles.fieldTitleContainer}>
                            <div className={styles.fieldTitle}>{t('billing.postalCode.label')}</div>
                        </div>
                        <div>
                            <FieldWithErrorMessage
                                type='text'
                                name='postalCode'
                                placeholder={t('billing.postalCode.placeholder')}
                                trim={true}
                            />
                        </div>

                        <div className={styles.fieldTitleContainer}>
                            <div className={styles.fieldTitle}>{t('billing.address.label')}</div>
                        </div>
                        <div>
                            <FieldWithErrorMessage
                                type='text'
                                name='address'
                                placeholder={t('billing.address.placeholder')}
                                trim={true}
                            />
                        </div>

                        <div className={styles.fieldTitleContainer}>
                            <div className={styles.fieldTitle}>{t('billing.company.label')}</div>
                        </div>
                        <div>
                            <FieldWithErrorMessage
                                type='text'
                                name='company'
                                placeholder={t('billing.company.placeholder')}
                                trim={true}
                            />
                        </div>
                    </div>
                    <div className={styles.actionsWrapper}>
                        {usedInUpgradePlanModal ? (
                            <>
                                {modalCancelButton}
                                <button className='btn' type='submit' disabled={!dirty || !isEmpty(errors)}>
                                    {modalSubmitButtonTitle}
                                </button>
                            </>
                        ) : (
                            <>
                                {successfullySubmitted && !dirty ? (
                                    <button
                                        type='submit'
                                        className='btn-green'
                                        onClick={e => {
                                            e.preventDefault();
                                        }}
                                    >
                                        <span className='check' /> {t('billing.allSaved')}
                                    </button>
                                ) : (
                                    <button className='btn' type='submit' disabled={!dirty || !isEmpty(errors)}>
                                        {t('billing.buttonSave')}
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    );
}
