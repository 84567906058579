import { useTranslation } from 'react-i18next';
import { ReactComponent as SvgStorage } from '../../../svg/resources/storage.svg';
import { ReactComponent as SvgProcessingHours } from '../../../svg/resources/processing_hours.svg';

type Props = {
    isMobileView: boolean;
};

export default function SidebarResourcesSkeleton({ isMobileView }: Props) {
    const { t } = useTranslation('projects');

    return (
        <>
            <div className='skel-plan'>
                <div className='skel-plan-1' />
                <div className='skel-plan-2' />
            </div>
            {!isMobileView && (
                <div className='resources' data-testid={'sidebarResourcesSkeleton'}>
                    <div className='resource'>
                        <div className='resource-info'>
                            <div className='resource-icon'>
                                <SvgStorage />
                            </div>
                            <div className='resource-title'>{t('resources.storage')}</div>
                        </div>
                        <div className='skel-1' />
                        <div className='skel-2' />
                    </div>
                    <div className='resource'>
                        <div className='resource-info'>
                            <div className='resource-icon'>
                                <SvgProcessingHours />
                            </div>
                            <div className='resource-title'>{t('resources.processingHours')}</div>
                        </div>
                        <div className='skel-3' />
                    </div>
                </div>
            )}
        </>
    );
}
