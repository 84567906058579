import React from 'react';
import { useTranslation } from 'react-i18next';
import isOnline from '../../../lib/isOnline';

const OfflineReloadButton: React.FC = () => {
    const { t } = useTranslation('placeholders');

    return (
        <button
            className='empty-button btn'
            type={'button'}
            onClick={async event => {
                const online = isOnline();
                if (online) {
                    window.location.reload();
                }
            }}
        >
            {t('offlineReloadButton')}
        </button>
    );
};

export default OfflineReloadButton;
