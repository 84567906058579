import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { memo, useState } from 'react';
import AutoSave from '../../Elements/auto-save-formik/AutoSaveFormik';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import { MAX_GEOMETRY_DESCRIPTION_LENGTH } from '../../../sharedConstants';
import { Input } from 'antd';

type Props = {
    label: string;
    value: string;
    onSubmit(values: { label: string; value: string }): void;
    readOnly?: boolean;
    maxLength?: number; // Chars over it are ignored on input
};

function TextareaProperty({ label, value, readOnly, onSubmit, maxLength = MAX_GEOMETRY_DESCRIPTION_LENGTH }: Props) {
    const [inputHasFocus, setInputHasFocus] = useState(false);

    return (
        <Formik enableReinitialize initialValues={{ label, value }} onSubmit={onSubmit}>
            {({ errors, setFieldValue }) => (
                <TippyTooltip tooltipText={errors.label || ''} disabled={!errors.label} visible>
                    <Form className='property'>
                        <AutoSave debounceMs={300} />
                        <div className='prop-wrapper read-only'>
                            <div className='prop-label-wrapper'>
                                <div className='prop-text prop-label'>{label}</div>
                            </div>
                            <div className='prop-value-wrapper'>
                                <Field
                                    as={Input.TextArea}
                                    className={classNames('prop-input prop-value hasBorder', {
                                        active: inputHasFocus && !readOnly,
                                        alert: errors.label
                                    })}
                                    name='value'
                                    autoSize
                                    autoComplete='off'
                                    readOnly={readOnly}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        setFieldValue('value', e.target.value.substring(0, maxLength));
                                    }}
                                    onFocus={() => setInputHasFocus(true)}
                                    onBlur={() => setInputHasFocus(false)}
                                />
                            </div>
                        </div>
                        <div className='prop-action' />
                    </Form>
                </TippyTooltip>
            )}
        </Formik>
    );
}

export default memo(TextareaProperty);
