import classNames from 'classnames';
import produce from 'immer';
import React, { memo, useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import CompareStructureTreeContext from '../../../contexts/CompareStructureTreeContext';
import { SourceType, Status } from '../../../generated/cloud-frontend-api';
import useCanRenameStructureItem from '../../../hooks/useCanRenameStructureItem';
import { useExpansion, useVisibility } from '../../../hooks/useStructureInfo';
import { IS_TOUCH_DEVICE, TerrainViewModes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import {
    selectDatasetsByCompareTreeId,
    selectGeometryVisibility,
    selectProjectInfoByCompareTreeId,
    selectStructuresByCompareTreeId
} from '../../../store/selectors';
import { deleteRecentlyFinishedUploadId, setSelectedObject } from '../../../store/sharedActions';
import { nonComparableSourceTypes, updateCompareToolStructureInfo } from '../../../store/slices/compareTool';
import { isVisualDataAbsent } from '../../../store/slices/datasets';
import { putStructure } from '../../../store/slices/structure';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import StructureItemEditableName, { IRenameNode } from '../structure-tree/StructureItemEditableName';
import { TreeNode } from '../structure-tree/StructureTree';
import { draggableObjectTypes } from '../structure-tree/TreeDragDrop';
import StructureItemControls from './StructureItemControls';
import StructureItemIcon from './StructureItemIcon';
import Spinner from './StructureItemSpinner';

export const sourceTypesDisabledInModelView: SourceType[] = [SourceType.DEM, SourceType.DEM_3_D, SourceType.RASTER_MAP];

type Props = {
    data: TreeNode;
    isParentSelected(node: TreeNode): boolean;
    isParentVisible(node: TreeNode): boolean;
    isDragging?: boolean;
    dragClone?: boolean;
    setSortingEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

function StructureItem({ data, isParentSelected, isParentVisible, isDragging, dragClone, setSortingEnabled }: Props) {
    const { id, name, type, status, nestingLevel, sourceType } = data;
    const dispatch: AppDispatch = useDispatch();
    const compareContext = useContext(CompareStructureTreeContext);
    const projectInfo = useSelector(state => selectProjectInfoByCompareTreeId(state, compareContext?.treeId));
    const structures = useSelector(state => selectStructuresByCompareTreeId(state, compareContext?.treeId));
    const selectedObject = useSelector(state => state.project.selectedObject);
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const recentlyFinished = useSelector(state => state.datasetsUpload.recentlyFinished);
    const terrainViewMode = useSelector(state => state.projectView.terrainViewMode);
    const tilesetLoading = useSelector(state => state.projectView.tilesetLoading);
    const datasets = useSelector(state => selectDatasetsByCompareTreeId(state, compareContext?.treeId));
    const datasetVisible = useVisibility({ structureId: id, treeId: compareContext?.treeId });
    const geometryVisible = useSelector(state =>
        type === ProjectStructureObjectTypes.GEOMETRY ? selectGeometryVisibility(state, { id, type }) : false
    );
    const visible = type === ProjectStructureObjectTypes.GEOMETRY ? geometryVisible : datasetVisible;
    const datasetInfo = datasets.find(d => d.datasetUid === id);
    const isSpinnerVisible =
        type === ProjectStructureObjectTypes.ARTIFACT &&
        visible &&
        datasetInfo &&
        tilesetLoading[datasetInfo?.visualData?.dataUid!];
    const noVisualData =
        (type === ProjectStructureObjectTypes.ARTIFACT || type === ProjectStructureObjectTypes.DATASET) &&
        datasetInfo &&
        isVisualDataAbsent(datasetInfo);
    const expanded = useExpansion({ structureId: id, treeId: compareContext?.treeId });
    const parentVisible = isParentVisible(data);

    const [isRenameVisible, setRenameVisible] = useState(false);
    const structureInfo = structures.find(s => s.uid === id);

    const canRename = useCanRenameStructureItem(id, type);
    const showRename = useCallback(() => {
        if (canRename) {
            setRenameVisible(true);
            setSortingEnabled(false);
        }
    }, [setSortingEnabled, canRename]);

    function closeRename() {
        setRenameVisible(false);
        setSortingEnabled(draggableObjectTypes.includes(type));
    }

    function removeFromRecentlyFinished() {
        if (recentlyFinished.includes(id)) dispatch(deleteRecentlyFinishedUploadId({ id }));
    }

    function updateExpansion() {
        const newStructureInfo = {
            uid: id,
            parentUid: structureInfo?.parentUid || projectInfo?.id!,
            properties: produce(structureInfo?.properties || {}, draft => {
                draft.expanded = String(!expanded);
            })
        };
        if (isCompareToolEnabled && compareContext)
            dispatch(
                updateCompareToolStructureInfo({ treeId: compareContext?.treeId, structureInfo: newStructureInfo })
            );
        else dispatch(putStructure({ type, projectId: projectInfo?.id!, structureInfo: newStructureInfo }));
    }

    const renameExceptions = isDragging || isCompareToolEnabled || IS_TOUCH_DEVICE;
    const handleRename = () => {
        if (!renameExceptions) {
            showRename();
        }
    };

    const selected = selectedObject?.artifactId === id && selectedObject.treeId === compareContext?.treeId;
    const parentSelected = isParentSelected(data);
    const disabledInCompareTool =
        isCompareToolEnabled &&
        (nonComparableSourceTypes.includes(sourceType || ('' as SourceType)) ||
            type === ProjectStructureObjectTypes.GEOMETRY ||
            type === ProjectStructureObjectTypes.CAMERAS ||
            type === ProjectStructureObjectTypes.IMAGE);
    const disabledInModelView =
        terrainViewMode === TerrainViewModes.MODEL &&
        sourceTypesDisabledInModelView.includes(sourceType || ('' as SourceType));

    const renameData: IRenameNode = {
        id: id,
        type: type,
        name: name
    };

    return (
        <div
            className={classNames(nestingLevel < 2 ? 'artefact-head' : 'structure-item', {
                selected,
                expanded,
                disabled: !visible || !parentVisible || noVisualData || disabledInCompareTool || disabledInModelView,
                'parent-selected': parentSelected,
                'upload-error': status === Status.FAILED || status === 'uploadError' || status === 'validationError',
                ghost: isDragging,
                dragged: dragClone,
                nested: nestingLevel > 0
            })}
            data-testid={'structureItem'}
            onClick={e => {
                if (!isCompareToolEnabled)
                    dispatch(setSelectedObject({ type, artifactId: id, treeId: compareContext?.treeId }));
                removeFromRecentlyFinished();
            }}
            onMouseEnter={e => {
                removeFromRecentlyFinished();
            }}
        >
            {nestingLevel < 2 &&
                (!dragClone && !disabledInCompareTool && data.children?.length > 0 ? (
                    <span
                        data-testid={'expansionArrow'}
                        className={classNames('artefact-arrow', { expanded })}
                        onClick={event => {
                            event.stopPropagation();
                            removeFromRecentlyFinished();
                            updateExpansion();
                        }}
                    />
                ) : (
                    <span className={classNames('artefact-arrow-empty')} />
                ))}
            {isSpinnerVisible ? (
                <Spinner />
            ) : (
                <StructureItemIcon
                    className={nestingLevel < 2 ? 'artefact-icon' : 'structure-icon'}
                    structureItemId={id}
                    structureItemType={type}
                />
            )}

            {!isRenameVisible ? (
                <>
                    <span
                        className={nestingLevel < 2 ? 'artefact-title' : 'structure-title'}
                        onDoubleClick={handleRename}
                    >
                        <TippyTooltip tooltipText={name}>
                            <span className='tooltip-base'>{name}</span>
                        </TippyTooltip>
                    </span>

                    {!dragClone && (
                        <StructureItemControls
                            isParentVisible={isParentVisible}
                            node={data}
                            className={nestingLevel < 2 ? 'artefact-controls' : 'item-controls'}
                            isParentSelected={parentSelected}
                            disabledInCompareTool={disabledInCompareTool}
                        />
                    )}
                </>
            ) : (
                <StructureItemEditableName node={renameData} close={closeRename} />
            )}
        </div>
    );
}

export default StructureItem;
