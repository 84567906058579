import classNames from 'classnames';

const skeletonItems = 6;

export default function ProcessingListSkeleton() {
    return (
        <div className='processing-list-wrapper'>
            <div className='processing-list'>
                {[...Array(skeletonItems)].map((e, i) => (
                    <div
                        data-testid={'skeletonItem'}
                        className={classNames('processing-list_item processing-list_skel-item', {
                            'first-item': i === 0,
                            'last-item': i === skeletonItems - 1
                        })}
                        key={i}
                    >
                        <div className='item-icon'>
                            <div className='state-icon skel-icon' />
                        </div>
                        <div className='skel-title' />
                    </div>
                ))}
            </div>
        </div>
    );
}
