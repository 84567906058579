import { unwrapResult } from '@reduxjs/toolkit';
import * as Cesium from 'cesium';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CesiumMovementEvent, ScreenSpaceEvent } from 'resium';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { GeometryTypes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { GeoJson, ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { createLoadingSelector } from '../../../store/selectors/createLoadingSelector';
import { setSelectedObject } from '../../../store/sharedActions';
import { beginLayer, commitGeometry } from '../../../store/slices/geometryLayers';
import { setCurrentlyDrawingShapeId } from '../../../store/slices/projectView';
import useDrawing from './useDrawing';

const selectIsLayerCreationPending = createLoadingSelector([beginLayer.typePrefix]);

type Props = {
    lastPickedCartographicDegrees: number[];
};

export default function DrawPoint({ lastPickedCartographicDegrees }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const isLayerCreationPending = useSelector(state => selectIsLayerCreationPending(state));
    const { owned } = useContext(ProjectViewAccessContext);

    const {
        enableDrawingGeometryVisibilityIfInvisible,
        addGeometry,
        getGeometryColors,
        enableLayerVisibilityIfInvisible,
        getLayerUid
    } = useDrawing();

    async function draw(coordinates: number[]) {
        let id = '';

        const layer = await getLayerUid();
        enableLayerVisibilityIfInvisible(layer.id);
        enableDrawingGeometryVisibilityIfInvisible(layer.id);

        const geoJson: GeoJson = {
            type: 'Feature',
            geometry: { type: GeometryTypes.POINT, coordinates },
            properties: {
                ac_name: t('defaultNames.point'),
                ac_visibility: true,
                ...getGeometryColors(layer, GeometryTypes.POINT)
            }
        };
        id = addGeometry(geoJson, layer.id);

        await dispatch(
            setSelectedObject({ artifactId: id, type: ProjectStructureObjectTypes.GEOMETRY, needToScroll: true })
        );

        if (owned) {
            const { geometryId } = unwrapResult(await dispatch(commitGeometry({ temporaryGeometryUid: id })));
        }
    }

    return (
        <ScreenSpaceEvent
            type={Cesium.ScreenSpaceEventType.LEFT_CLICK}
            action={(e: CesiumMovementEvent) => {
                const coordinates = lastPickedCartographicDegrees;
                if (coordinates && !isLayerCreationPending) draw(coordinates);
            }}
        />
    );
}
