import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Document } from '../../../generated/cloud-frontend-api/model/document';
import getFilenameExtension from '../../../lib/getFilenameExtension';
import convertSize from '../../../lib/convertSize';
import { AppDispatch, useSelector } from '../../../store';
import { selectReportsPropertiesBlockExpansion, updateStructureInfo } from '../../../store/slices/structure';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { ExtendedChunk, ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EmbeddedProjectViewRouteParams } from '../../../pages/EmbeddedProjectView';
import ProcessingReportDropdown from './ProcessingReportDropdown';
import PropertiesBlockHead from '../../ProjectView/geometry-properties/elements/PropertiesBlockHead';

type Props = {
    report: Document;
};

export default function ProcessingReport({ report }: Props) {
    const { t } = useTranslation(['common', 'projectView']);
    const dispatch: AppDispatch = useDispatch();
    const { embed } = useParams<EmbeddedProjectViewRouteParams>();
    const accessInfo = useSelector(state => state.sharing.accessInfo);
    const access = useSelector(state => state.sharing.access);
    const isOwnedProject = (access.accessKey && accessInfo?.owned) || !access.accessKey;

    const projectInfo = useSelector(state => state.project.projectInfo);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));
    const reportsBlockExpanded = useSelector(state =>
        selectReportsPropertiesBlockExpansion(state, (selectedObjectInfo as ExtendedChunk)?.assetUid!)
    );

    const url = useMemo(() => {
        let url = report.link!;
        if (embed) url = url.concat(`?embed=${embed}`);
        if (!embed && !isOwnedProject) url = url.concat(`?access=${access.accessKey}`);
        return url;
    }, [embed, isOwnedProject, report.link, access.accessKey]);

    function updateReportsBlockExpansion(expanded: boolean) {
        dispatch(
            updateStructureInfo({
                projectId: projectInfo.id!,
                structureUid: (selectedObjectInfo as ExtendedChunk).assetUid!,
                type: ProjectStructureObjectTypes.CHUNK,
                propName: 'reportsBlockExpanded',
                propValue: expanded ? String(true) : String(false)
            })
        );
    }

    return (
        <div className='inspector-properties'>
            <PropertiesBlockHead
                isBlockExpanded={reportsBlockExpanded}
                title={t('projectView:inspectionSidebar.reports.title')}
                onClick={() => {
                    updateReportsBlockExpansion(!reportsBlockExpanded);
                }}
            />
            {reportsBlockExpanded && (
                <div className='inspector-report'>
                    <div className='report-title-wrapper'>
                        <a className='report-title' href={url} target='_blank' rel='noopener noreferrer'>
                            {t('common:processingReport')}
                        </a>
                    </div>
                    <div className='report-info'>
                        <span>{convertSize(report.sizeInBytes, true)}</span>
                        <span className='report-divider' />
                        <span>{getFilenameExtension(report.fileName || '')}</span>
                    </div>
                    <ProcessingReportDropdown
                        url={url}
                        filenameExtension={getFilenameExtension(report.fileName || '')}
                    />
                </div>
            )}
        </div>
    );
}
