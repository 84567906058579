import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useGetCopyrightString from '../../../hooks/useGetCopyrightString';

const CESIUM_CREDIT_CONTROL = '.cesium-credit-expand-link';

const AppDataAttribution = () => {
    const { t } = useTranslation(['projectView']);
    const appDataAttribution = useRef<HTMLSpanElement>(null);
    const copyrightText = useGetCopyrightString();

    const handleClick = () => {
        const defaultDataAttribution = document.querySelector(CESIUM_CREDIT_CONTROL) as HTMLElement;
        if (defaultDataAttribution) defaultDataAttribution.click();
    };

    return (
        <>
            <span className='copy-link' ref={appDataAttribution} onClick={handleClick}>
                {t('projectView:dataAttribution')}
            </span>
            <span>&ensp;</span>
            <span>{copyrightText}</span>
        </>
    );
};

export default AppDataAttribution;
