import { ReactNode } from 'react';
import classNames from 'classnames';
import { ReactComponent as SvgWarning } from '../../../svg/notifications/warning.svg';
import { ReactComponent as SvgInProgress } from '../../../svg/notifications/in_progress.svg';

import styles from './Notification.module.scss';

type Props = {
    children: ReactNode;
    icon?: 'warning' | 'in_progress';
    iconColor?: 'red';
};

export default function Notification({ children, icon = 'warning', iconColor }: Props) {
    return (
        <div className={styles.messageContainer}>
            <div className={styles.notifyMsg}>
                <div className={classNames(styles.msgIco, iconColor === 'red' && styles.msgIcoRed)}>
                    {icon === 'warning' && <SvgWarning />}
                    {icon === 'in_progress' && <SvgInProgress />}
                </div>
                {children}
            </div>
        </div>
    );
}
