import { useTranslation } from 'react-i18next';
import { LOGIN_URL } from '../../sharedConstants';

const LoginButton = () => {
    const { t } = useTranslation('common');

    return (
        <div className='header-user'>
            <button
                className='btn-login'
                type='button'
                onClick={e => {
                    window.location.href = LOGIN_URL;
                }}
            >
                {t('header.loginButton')}
            </button>
        </div>
    );
};
export default LoginButton;
