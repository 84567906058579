import produce from 'immer';
import { useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import CompareStructureTreeContext from '../../../contexts/CompareStructureTreeContext';
import { AppDispatch, useSelector } from '../../../store';
import {
    ProjectStructureObjectTypes,
    TemporaryGeometry,
    TemporaryLayer,
    getLayerProperties
} from '../../../store/helpers/interfaces';
import {
    selectLayersByCompareTreeId,
    selectProjectInfoByCompareTreeId,
    selectStructureItemInfoByCompareTreeId
} from '../../../store/selectors';
import { renameDataset } from '../../../store/slices/datasetfilesUpload';
import { updateGeometryContent } from '../../../store/slices/geometries';
import { updateGeometry, updateLayer } from '../../../store/slices/geometryLayers';
import { setLayerProperty } from '../../../store/slices/project';
import { setStructureProperty, updateStructureInfo } from '../../../store/slices/structure';

export interface IRenameNode {
    id: string;
    type: ProjectStructureObjectTypes;
    name: string;
}

type Props = {
    node: IRenameNode;
    close(): void;
};

export default function StructureItemEditableName({ node, close }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const compareContext = useContext(CompareStructureTreeContext);
    const initialName = useRef('');
    const projectInfo = useSelector(state => selectProjectInfoByCompareTreeId(state, compareContext?.treeId));
    const layers = useSelector(state => selectLayersByCompareTreeId(state, compareContext?.treeId));
    const structureItemInfo = useSelector(state =>
        selectStructureItemInfoByCompareTreeId(state, compareContext?.treeId || '', { id: node.id, type: node.type })
    );

    function update(value: string) {
        if (node.type === ProjectStructureObjectTypes.GROUP) {
            dispatch(setStructureProperty({ id: node.id, propName: 'name', propValue: value }));
        }
        if (node.type === ProjectStructureObjectTypes.DATASET) {
            dispatch(renameDataset({ projectId: projectInfo?.id!, datasetUid: node.id!, name: value }));
        }
        if (node.type === ProjectStructureObjectTypes.LAYER) {
            updateLayerName(value);
        }
        if (node.type === ProjectStructureObjectTypes.GEOMETRY) {
            updateGeometryName(value);
        }
    }

    function submit(value: string) {
        const newValue = value.trim() || initialName.current;

        if (newValue !== value) {
            update(newValue);
        }

        if (node.type === ProjectStructureObjectTypes.GROUP) {
            dispatch(
                updateStructureInfo({
                    type: node.type,
                    projectId: projectInfo?.id!,
                    structureUid: node.id,
                    propName: 'name',
                    propValue: newValue
                })
            );
        }
        if (node.type === ProjectStructureObjectTypes.DATASET) {
            dispatch(
                renameDataset({
                    projectId: projectInfo?.id!,
                    datasetUid: node.id!,
                    name: newValue
                })
            );
        }
        if (node.type === ProjectStructureObjectTypes.LAYER) {
            submitLayerName(newValue);
        }
        if (node.type === ProjectStructureObjectTypes.GEOMETRY) {
            submitGeometryName(newValue);
        }
    }

    return (
        <div className='artefact-rename'>
            <input
                autoFocus={true}
                type='text'
                className='artefact-rename-input'
                name={'name'}
                value={node.name}
                onFocus={e => {
                    initialName.current = node.name;
                    e.target.select();
                }}
                onChange={e => {
                    update(e.target.value);
                }}
                onKeyUp={e => {
                    if (e.key === 'Enter') {
                        e.currentTarget.blur();
                        close();
                    }
                }}
                onBlur={e => {
                    submit(e.target.value);
                    close();
                }}
            />
        </div>
    );

    function submitLayerName(value: string) {
        const layer = structureItemInfo as TemporaryLayer;
        dispatch(
            updateLayer({
                projectUid: projectInfo?.id!,
                layerUid: layer.id,
                properties: {
                    ...getLayerProperties(layer),
                    name: value
                }
            })
        );
    }

    function updateLayerName(value: string) {
        dispatch(
            setLayerProperty({
                id: node.id,
                propName: 'name',
                propValue: value
            })
        );
    }

    function updateGeometryName(value: string) {
        const geometry = structureItemInfo as TemporaryGeometry;
        dispatch(
            updateGeometryContent({
                id: node.id,
                geoJson: produce(geometry.content, draft => {
                    draft.properties.ac_name = value;
                })
            })
        );
    }

    function submitGeometryName(value: string) {
        const layer = layers.find(l => l.geometries.find(g => g === node.id));
        const geometry = structureItemInfo as TemporaryGeometry;
        dispatch(
            updateGeometry({
                projectUid: projectInfo?.id!,
                layerUid: layer!.id,
                id: node.id,
                geoJson: produce(geometry.content, draft => {
                    draft.properties.ac_name = value;
                })
            })
        );
    }
}
