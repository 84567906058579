import { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useOnWindowScroll, useWindowSize } from 'rooks';
import { ReactComponent as SvgArrowUp } from '../../svg/user-agreement/arrow_up.svg';
import { Trans, useTranslation } from 'react-i18next';

export default function UserAgreement() {
    const BREAKPOINT = 1200; // переход шапки в мобильный вид = $desktop_layout_breakpoint из '_variables.scss'
    const HEADER_HEIGHT = 60; // = высота шапки (px)
    const ANCHOR_OFFSET = 10; // = добавочный отступ над якорем (px)

    const { innerWidth, innerHeight } = useWindowSize();
    const { t } = useTranslation('userAgreement');
    const [isDesktop, setIsDesktop] = useState(innerWidth! >= BREAKPOINT ? true : false);
    const [yOffset, setYOffset] = useState((isDesktop ? HEADER_HEIGHT : 0) + ANCHOR_OFFSET);
    const [showScrollTop, setShowScrollTop] = useState(false);

    useEffect(() => {
        setIsDesktop(innerWidth! >= BREAKPOINT ? true : false);
    }, [innerWidth, BREAKPOINT]);

    useEffect(() => {
        setYOffset((isDesktop ? HEADER_HEIGHT : 0) + ANCHOR_OFFSET);
    }, [isDesktop]);

    const scrollWithOffset = (el: HTMLElement) => {
        const yCoord = el.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: yCoord - yOffset });
    };

    useOnWindowScroll(() => {
        if (!showScrollTop && window.scrollY > innerHeight!) {
            setShowScrollTop(true);
        } else if (showScrollTop && window.scrollY <= innerHeight!) {
            setShowScrollTop(false);
        }
    });

    return (
        <div className='user-agreement-wrapper'>
            <article id='user-agreement' className='user-agreement'>
                <h1>{t('pageHead')}</h1>

                <p className='user-agreement-version'>
                    <Trans ns={'userAgreement'} i18nKey={'version'} components={{ bold: <b /> }} />
                </p>

                <ul className='user-agreement-contents'>
                    <li>
                        <HashLink to='#ua_1' scroll={el => scrollWithOffset(el)}>
                            {t('1.head')}
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to='#ua_1_1' scroll={el => scrollWithOffset(el)}>
                            {t('1_1.head')}
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to='#ua_1_2' scroll={el => scrollWithOffset(el)}>
                            {t('1_2.head')}
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to='#ua_1_3' scroll={el => scrollWithOffset(el)}>
                            {t('1_3.head')}
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to='#ua_2' scroll={el => scrollWithOffset(el)}>
                            {t('2.head')}
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to='#ua_3' scroll={el => scrollWithOffset(el)}>
                            {t('3.head')}
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to='#ua_4' scroll={el => scrollWithOffset(el)}>
                            {t('4.head')}
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to='#ua_5' scroll={el => scrollWithOffset(el)}>
                            {t('5.head')}
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to='#ua_5_1' scroll={el => scrollWithOffset(el)}>
                            {t('5_1.head')}
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to='#ua_5_2' scroll={el => scrollWithOffset(el)}>
                            {t('5_2.head')}
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to='#ua_5_3' scroll={el => scrollWithOffset(el)}>
                            {t('5_3.head')}
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to='#ua_5_4' scroll={el => scrollWithOffset(el)}>
                            {t('5_4.head')}
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to='#ua_6' scroll={el => scrollWithOffset(el)}>
                            {t('6.head')}
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to='#ua_7' scroll={el => scrollWithOffset(el)}>
                            {t('7.head')}
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to='#ua_8' scroll={el => scrollWithOffset(el)}>
                            {t('8.head')}
                        </HashLink>
                    </li>
                    <li>
                        <HashLink to='#ua_9' scroll={el => scrollWithOffset(el)}>
                            {t('9.head')}
                        </HashLink>
                    </li>
                </ul>

                <Trans ns={'userAgreement'} i18nKey={'preWords'} components={{ p: <p /> }} />

                <h2 id='ua_1'>{t('1.head')}</h2>

                <h3 id='ua_1_1'>{t('1_1.head')}</h3>
                <p>{t('1_1.body')}</p>

                <h3 id='ua_1_2'>{t('1_2.head')}</h3>
                <p>{t('1_2.body')}</p>

                <h3 id='ua_1_3'>{t('1_3.head')}</h3>
                <Trans ns={'userAgreement'} i18nKey={'1_3.body'} components={{ p: <p />, b: <b /> }} />

                <h2 id='ua_2'>{t('2.head')}</h2>
                <Trans
                    ns={'userAgreement'}
                    i18nKey={'2.body'}
                    components={{
                        p: <p />,
                        i: <i />,
                        b: <b />,
                        aTag: (
                            <a
                                href='https://agisoft.freshdesk.com/a/solutions/articles/31000155990'
                                target='_blank'
                                rel='noopener noreferrer'
                            />
                        )
                    }}
                />

                <h2 id='ua_3'>{t('3.head')}</h2>
                <Trans
                    ns={'userAgreement'}
                    i18nKey={'3.body'}
                    components={{
                        p: <p />,
                        i: <i />,
                        b: <b />,
                        aTag: (
                            <a
                                href='https://agisoft.freshdesk.com/a/solutions/articles/31000155990'
                                target='_blank'
                                rel='noopener noreferrer'
                            />
                        )
                    }}
                />

                <h2 id='ua_4'>{t('4.head')}</h2>
                <p>
                    <Trans
                        ns={'userAgreement'}
                        i18nKey={'4.body'}
                        components={{
                            aTag: (
                                <a
                                    href='https://www.agisoft.com/pdf/privacy_policy.pdf'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                />
                            )
                        }}
                    />
                </p>

                <h2 id='ua_5'>{t('5.head')}</h2>

                <h3 id='ua_5_1'>{t('5_1.head')}</h3>
                <p>{t('5_1.body')}</p>

                <h3 id='ua_5_2'>{t('5_2.head')}</h3>
                <p>{t('5_2.body')}</p>

                <h3 id='ua_5_3'>{t('5_3.head')}</h3>
                <Trans ns={'userAgreement'} i18nKey={'5_3.body'} components={{ p: <p />, b: <b /> }} />

                <h3 id='ua_5_4'>{t('5_4.head')}</h3>
                <p>{t('5_4.body')}</p>

                <h2 id='ua_6'>{t('6.head')}</h2>
                <Trans ns={'userAgreement'} i18nKey={'6.body'} components={{ p: <p /> }} />

                <h2 id='ua_7'>{t('7.head')}</h2>
                <Trans ns={'userAgreement'} i18nKey={'7.body'} components={{ p: <p /> }} />

                <h2 id='ua_8'>{t('8.head')}</h2>
                <Trans ns={'userAgreement'} i18nKey={'8.body'} components={{ p: <p /> }} />

                <h2 id='ua_9'>{t('9.head')}</h2>
                <Trans ns={'userAgreement'} i18nKey={'9.body'} components={{ p: <p /> }} />
                <p className='end-of-agreement'>
                    <i>
                        <Trans
                            ns={'userAgreement'}
                            i18nKey={'end'}
                            components={{ span: <span className='nowrap' /> }}
                        />
                    </i>
                </p>
            </article>
            {showScrollTop && (
                <HashLink className='link-top' to='#user-agreement' scroll={el => scrollWithOffset(el)}>
                    <SvgArrowUp />
                </HashLink>
            )}
        </div>
    );
}
