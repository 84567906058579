import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import isProjectBelongsUser from '../lib/isProjectBelongsUser';
import { EmbeddedProjectViewRouteParams } from '../pages/EmbeddedProjectView';
import { WidthHeight } from '../sharedConstants';
import { useSelector } from '../store';
import { ExtendedCamera } from '../store/slices/cameras';

export function useCameraImageUrl(
    camera: ExtendedCamera | undefined,
    thumbnail?: WidthHeight & { mode: string },
    issueImageUid?: string
) {
    const projectInfo = useSelector(state => state.project.projectInfo);
    const accessInfo = useSelector(state => state.sharing.accessInfo);
    const access = useSelector(state => state.sharing.access);
    const isOwnedProject = isProjectBelongsUser(accessInfo, projectInfo);
    const { embed } = useParams<EmbeddedProjectViewRouteParams>();

    const preloadThumbUrl = useMemo(() => {
        if (!camera?.photoUid) return null;
        let preloadThumbUrl = `/api/projects/${projectInfo.id}/raw/${camera?.photoUid}/thumbnail?width=${thumbnail?.width}&height=${thumbnail?.height}&mode=${thumbnail?.mode}`;
        if (embed) preloadThumbUrl = preloadThumbUrl.concat(`&embed=${embed}`);
        if (!isOwnedProject) preloadThumbUrl = preloadThumbUrl.concat(`&access=${access.accessKey}`);
        return preloadThumbUrl;
    }, [embed, isOwnedProject, projectInfo.id, camera?.photoUid, access.accessKey, thumbnail]);

    const url = useMemo(() => {
        let url = `/api/projects/${projectInfo.id}/raw/files/${camera?.photoUid}`;
        if (embed) url = url.concat(`?embed=${embed}`);
        if (!embed && !isOwnedProject) url = url.concat(`?access=${access.accessKey}`);
        return url;
    }, [embed, isOwnedProject, projectInfo.id, camera?.photoUid, access.accessKey]);

    const thumbUrl = useMemo(() => {
        if (!camera?.photoUid) return null;
        let url = `/api/projects/${projectInfo.id}/raw/${camera?.photoUid}/thumbnail?width=${thumbnail?.width}&height=${thumbnail?.height}&mode=${thumbnail?.mode}`;
        if (embed) url = url.concat(`&embed=${embed}`);
        if (!embed && !isOwnedProject) url = url.concat(`&access=${access.accessKey}`);
        return url;
    }, [embed, isOwnedProject, projectInfo.id, camera?.photoUid, access.accessKey, thumbnail]);

    const urlFromIssue = useMemo(() => {
        let url = `/api/projects/${projectInfo.id}/raw/files/${issueImageUid}`;
        if (embed) url = url.concat(`?embed=${embed}`);
        if (!embed && !isOwnedProject) url = url.concat(`?access=${access.accessKey}`);
        return url;
    }, [access.accessKey, embed, isOwnedProject, issueImageUid, projectInfo.id]);

    return { thumbUrl, preloadThumbUrl, url: camera ? url : urlFromIssue };
}
