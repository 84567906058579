import Modal, { ModalProps } from '../../modal/Modal';
import ModalHead from '../../modal/ModalHead';
import ModalBody from '../../modal/ModalBody';
import ModalActions from '../../modal/ModalActions';
import { Trans, useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from '../../../../sharedConstants';
import classNames from 'classnames';

type Props = ModalProps & {
    datasetName?: string;
    onAbort(): void;
};

export default function AbortDatasetUploadModal({ setIsOpen, isOpen, datasetName, onAbort }: Props) {
    const { t } = useTranslation(['modals']);
    const isEmbed = !!useRouteMatch({ path: Routes.EMBEDDED_PROJECT_VIEW, exact: true });
    const name = datasetName || '';

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isEmbed={isEmbed}>
            <ModalHead setIsOpen={setIsOpen} isEmbed={isEmbed}>
                {t('modals:abortDatasetUploadModal.head')}
            </ModalHead>
            <ModalBody>
                <div>
                    <Trans
                        ns={'modals'}
                        i18nKey={'abortDatasetUploadModal.body'}
                        components={{ p: <p />, s: <strong className='overflow' /> }}
                        values={{ name }}
                    />
                </div>
                <ModalActions>
                    <button
                        className={classNames(isEmbed ? 'btn-ghost-alert' : 'btn-cancel')}
                        type='button'
                        onClick={e => {
                            setIsOpen(false);
                        }}
                    >
                        {t('modals:abortDatasetUploadModal.cancel')}
                    </button>

                    <button
                        className={classNames('btn-alert', { embed: isEmbed })}
                        type='button'
                        onClick={e => {
                            onAbort();
                            setIsOpen(false);
                        }}
                    >
                        {t('modals:abortDatasetUploadModal.abort')}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}
