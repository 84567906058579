import classnames from 'classnames';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../../store';
import { ExtendedDatasetInfo, deleteDataset, unlinkDataset } from '../../../store/slices/datasetfilesUpload';
import { isLinkedDataset, removeDataset } from '../../../store/slices/datasets';
import DeleteDatasetModal from '../../Elements/modals/delete-dataset-modal/DeleteDatasetModal';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import { useTranslation } from 'react-i18next';
import { setSelectedObject } from '../../../store/sharedActions';
import { SelectedObject } from '../../../store/helpers/interfaces';

type Props = {
    datasetInfo: ExtendedDatasetInfo;
    useExtendedClassName?: boolean;
};

const DATASET_ERROR_STRING = 'Not enough free storage space';

export default function DeleteDatasetControl({ datasetInfo, useExtendedClassName = false }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const isLinkedFromAnotherProject = isLinkedDataset(datasetInfo, projectInfo.id!);
    const selectedObject = useSelector(state => state.project.selectedObject);

    const datasetSourceData = datasetInfo.sourceData;
    const hasNotBeenUploadedYet = !!datasetSourceData?.status;
    const outOfFreeSpace = datasetSourceData?.lastError === DATASET_ERROR_STRING;

    return (
        <>
            <TippyTooltip
                tooltipText={
                    isLinkedFromAnotherProject
                        ? `${t('structureItem.controls.tooltipUnlink')}`
                        : `${t('structureItem.controls.tooltipDelete')}`
                }
            >
                <span
                    className={classnames('control', { 'control-extended': useExtendedClassName })}
                    onClick={e => {
                        e.stopPropagation();
                        setDeleteModalOpen(true);
                    }}
                >
                    {isLinkedFromAnotherProject ? (
                        <i className='icon icon-unlink' />
                    ) : (
                        <i className='icon icon-delete' />
                    )}
                </span>
            </TippyTooltip>
            <DeleteDatasetModal
                isOpen={isDeleteModalOpen}
                setIsOpen={setDeleteModalOpen}
                datasetInfo={datasetInfo}
                outOfFreeSpace={outOfFreeSpace}
                onDelete={async () => {
                    if (selectedObject?.artifactId === datasetInfo.datasetUid) {
                        await dispatch(setSelectedObject({} as SelectedObject));
                    }

                    if (hasNotBeenUploadedYet) {
                        dispatch(removeDataset({ datasetUid: datasetInfo.datasetUid! }));
                        return;
                    }

                    if (isLinkedFromAnotherProject) {
                        dispatch(
                            unlinkDataset({
                                datasetId: datasetInfo.datasetUid!,
                                projectId: datasetInfo.parentProject?.uid!,
                                linkedProjectId: projectInfo.id!
                            })
                        );
                    } else {
                        dispatch(deleteDataset({ datasetUid: datasetInfo.datasetUid!, projectId: projectInfo.id! }));
                    }
                }}
            />
        </>
    );
}
