/* tslint:disable */
/* eslint-disable */
/**
 * project-preview-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CreatePreviewRequest } from '../model';
// @ts-ignore
import { PreviewInfo } from '../model';
// @ts-ignore
import { UploadPreviewUrl } from '../model';
/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create presigned url to upload preview
         * @param {string} projectUid UUID of project
         * @param {CreatePreviewRequest} createPreviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreview: async (projectUid: string, createPreviewRequest: CreatePreviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('createPreview', 'projectUid', projectUid)
            // verify required parameter 'createPreviewRequest' is not null or undefined
            assertParamExists('createPreview', 'createPreviewRequest', createPreviewRequest)
            const localVarPath = `/projects/{projectUid}/preview/uploads`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPreviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the preview of the project
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreview: async (projectUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getPreview', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}/preview`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the project preview info
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewInfo: async (projectUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getPreviewInfo', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}/preview/info`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create presigned url to upload preview
         * @param {string} projectUid UUID of project
         * @param {CreatePreviewRequest} createPreviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPreview(projectUid: string, createPreviewRequest: CreatePreviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadPreviewUrl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPreview(projectUid, createPreviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the preview of the project
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreview(projectUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreview(projectUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the project preview info
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreviewInfo(projectUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreviewInfo(projectUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiApiFp(configuration)
    return {
        /**
         * 
         * @summary Create presigned url to upload preview
         * @param {string} projectUid UUID of project
         * @param {CreatePreviewRequest} createPreviewRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPreview(projectUid: string, createPreviewRequest: CreatePreviewRequest, options?: any): AxiosPromise<UploadPreviewUrl> {
            return localVarFp.createPreview(projectUid, createPreviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the preview of the project
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreview(projectUid: string, options?: any): AxiosPromise<any> {
            return localVarFp.getPreview(projectUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the project preview info
         * @param {string} projectUid Uid of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewInfo(projectUid: string, options?: any): AxiosPromise<PreviewInfo> {
            return localVarFp.getPreviewInfo(projectUid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
    /**
     * 
     * @summary Create presigned url to upload preview
     * @param {string} projectUid UUID of project
     * @param {CreatePreviewRequest} createPreviewRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createPreview(projectUid: string, createPreviewRequest: CreatePreviewRequest, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createPreview(projectUid, createPreviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the preview of the project
     * @param {string} projectUid Uid of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public getPreview(projectUid: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).getPreview(projectUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the project preview info
     * @param {string} projectUid Uid of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public getPreviewInfo(projectUid: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).getPreviewInfo(projectUid, options).then((request) => request(this.axios, this.basePath));
    }
}
