import { useEffect, useState } from 'react';
import { useSelector } from '../store/index';
import { selectSelectedObjectElevationProfile } from '../store/slices/elevationProfiles';

const MAP_PANEL_BUTTON_HEIGHT = 40; // высота кнопок переключения видимости панелей, берется из css-стилей
const MAP_PANEL_BUTTON_OFFSET = 16; // отступ между кнопками переключения видимости панелей и расширеной панелью построения профиля

export default function (
    wrapperCurrent: HTMLDivElement,
    wrapperHeight: number,
    elevationProfileTopCoord: number | undefined
) {
    const [defaultToggleBottom, setDefaultToggleBottom] = useState<number>();
    const [offsetString, setOffsetString] = useState<string | undefined>(undefined);
    const isElevationProfileExpanded = useSelector(state => state.projectView.isElevationProfileExpanded);
    const elevationProfile = useSelector(selectSelectedObjectElevationProfile);

    useEffect(() => {
        if (wrapperCurrent) {
            setDefaultToggleBottom(
                (wrapperHeight + MAP_PANEL_BUTTON_HEIGHT) / 2 + wrapperCurrent.getBoundingClientRect().top
            );
        }
    }, [wrapperCurrent, wrapperHeight]);

    useEffect(() => {
        if (
            isElevationProfileExpanded &&
            Boolean(elevationProfile?.status) &&
            elevationProfileTopCoord &&
            elevationProfileTopCoord < defaultToggleBottom! + MAP_PANEL_BUTTON_OFFSET
        ) {
            setOffsetString(`-${defaultToggleBottom! + MAP_PANEL_BUTTON_OFFSET - elevationProfileTopCoord}px`);
            return;
        }

        setOffsetString(undefined);
    }, [isElevationProfileExpanded, elevationProfileTopCoord, defaultToggleBottom, elevationProfile?.status]);

    return offsetString;
}
