import { useState } from 'react';
import { Issue } from '../../../entities/Issue';
import { useCameraImageUrl } from '../../../hooks/useCameraImageUrl';
import { GeometryTypes } from '../../../sharedConstants';
import { useSelector } from '../../../store';
import { TemporaryGeometry, isIssuePointGeometry } from '../../../store/helpers/interfaces';
import { ExtendedCamera } from '../../../store/slices/cameras';
import { selectGeometryById } from '../../../store/slices/geometries';
import { ReactComponent as SvgIssuePin } from '../../../svg/tool_inspection/issue_pin.svg';
import PreviewImageStub from '../../inspection-sidebar/preview-image/PreviewImageStub';

type Props = {
    camera: ExtendedCamera;
    cameraType: 'viewer' | 'swiper';
    alt?: string;
    className?: string;
    thumbnail: {
        width: number;
        height: number;
        mode: string;
    };
    onLoad?(): void;
    showIssueId?: string;
};

export default function CameraImage({ camera, cameraType, alt, className, thumbnail, onLoad, showIssueId }: Props) {
    const [displayStub, setDisplayStub] = useState(false);
    const issue = useSelector(state => selectGeometryById(state, showIssueId || ''));

    const { thumbUrl } = useCameraImageUrl(camera, thumbnail);

    return (
        <>
            {displayStub || !thumbUrl ? (
                <PreviewImageStub stubType={cameraType} />
            ) : (
                <>
                    <img
                        src={thumbUrl}
                        alt={alt || ''}
                        className={className}
                        loading={'lazy'}
                        onError={() => {
                            setDisplayStub(true);
                            onLoad?.();
                        }}
                        onLoad={e => {
                            onLoad?.();
                        }}
                    />
                    {issue && isIssuePointGeometry(issue) && (
                        <span className={'thumb-issue-icon'} data-testid={'issuePinIcon'}>
                            <SvgIssuePin
                                style={{ fill: new Issue(issue as TemporaryGeometry<GeometryTypes.POINT>).color }}
                            />
                        </span>
                    )}
                </>
            )}
        </>
    );
}
