import { PlanType } from '../../../generated/account-frontend-api';
import classNames from 'classnames';

import styles from '../PlanManagement.module.scss';

type Props = {
    itemType: keyof typeof PlanType;
};

const FREE_DETAILS_LIST_LENGTH = 11;
const COMMERCIAL_DETAILS_LIST_LENGTH = 5;

export default function PlanItemSkeleton({ itemType }: Props) {
    return (
        <div className={styles.plan}>
            <div className={styles.skelInfoBlock}>
                <div className={styles.skelState} />
                <div
                    className={classNames(
                        styles.skel,
                        itemType === 'FREE' ? styles.skelTitleFree : styles.skelTitleComm
                    )}
                />
                <div
                    className={classNames(
                        styles.skel,
                        itemType === 'FREE' ? styles.skelDescrFree1 : styles.skelDescrComm1
                    )}
                />
                <div
                    className={classNames(
                        styles.skel,
                        itemType === 'FREE' ? styles.skelDescrFree2 : styles.skelDescrComm2
                    )}
                />
                <div className={styles.skelResourceBlock}>
                    <div className={classNames(styles.skel, styles.skelStorageTitle)} />
                    <div
                        className={classNames(
                            styles.skel,
                            itemType === 'FREE' ? styles.skelStorageFree : styles.skelStorageComm
                        )}
                    />
                </div>
                <div className={styles.skelResourceBlock}>
                    <div className={classNames(styles.skel, styles.skelTimeTitle)} />
                    <div
                        className={classNames(
                            styles.skel,
                            itemType === 'FREE' ? styles.skelTimeFree : styles.skelTimeComm
                        )}
                    />
                </div>
            </div>

            <div className={styles.skelDetailsBlock}>
                <div className={classNames(styles.skel, styles.skelDetailsTitle)} />
                <div>
                    {Array(itemType === 'FREE' ? FREE_DETAILS_LIST_LENGTH : COMMERCIAL_DETAILS_LIST_LENGTH)
                        .fill(null)
                        .map((v, i) => (
                            <div
                                key={i}
                                data-testid={'skeletonItem'}
                                className={classNames(styles.skel, styles.skelListItem)}
                            />
                        ))}
                </div>
            </div>

            <div>
                <div className={classNames(styles.skel, styles.skelAction)} />
            </div>
        </div>
    );
}
