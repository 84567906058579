export function upsertConditionally<T>(
    array: T[],
    element: T,
    predicate: (element: T) => boolean,
    useUnshift = false
): void {
    const index = array.findIndex(predicate);
    if (index !== -1) array[index] = element;
    else useUnshift ? array.unshift(element) : array.push(element);
}
