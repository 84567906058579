import { IS_TOUCH_DEVICE, QualityOf3DModes } from '../../../../sharedConstants';

const lowQualityParametersDesktop = {
    dynamicScreenSpaceError: true,
    skipLevelOfDetail: true,
    foveatedConeSize: 0.1
};

const mediumQualityParametersDesktop = {
    dynamicScreenSpaceError: false,
    skipLevelOfDetail: true,
    foveatedConeSize: 0.3
};

const highQualityParametersDesktop = {
    dynamicScreenSpaceError: false,
    skipLevelOfDetail: true,
    foveatedConeSize: 1
};

const lowQualityParametersMobile = {
    dynamicScreenSpaceError: true,
    skipLevelOfDetail: true,
    foveatedConeSize: 0.1
};

const mediumQualityParametersMobile = {
    dynamicScreenSpaceError: true,
    skipLevelOfDetail: true,
    foveatedConeSize: 0.3
};

const highQualityParametersMobile = {
    dynamicScreenSpaceError: true,
    skipLevelOfDetail: true,
    foveatedConeSize: 0.5
};

export function getQualityParameters(maximumScreenSpaceErrorBase: number, quality: QualityOf3DModes) {
    if (IS_TOUCH_DEVICE) return getMobileQualityParameters(maximumScreenSpaceErrorBase, quality);
    return getDesktopQualityParameters(maximumScreenSpaceErrorBase, quality);
}

export function getMobileQualityParameters(maximumScreenSpaceErrorBase: number, quality: QualityOf3DModes) {
    switch (quality) {
        case QualityOf3DModes.HIGH:
            return { ...highQualityParametersMobile, maximumScreenSpaceError: maximumScreenSpaceErrorBase };
        case QualityOf3DModes.MEDIUM:
            return { ...mediumQualityParametersMobile, maximumScreenSpaceError: maximumScreenSpaceErrorBase * 2 };
        case QualityOf3DModes.LOW:
        default:
            return { ...lowQualityParametersMobile, maximumScreenSpaceError: maximumScreenSpaceErrorBase * 4 };
    }
}

export function getDesktopQualityParameters(maximumScreenSpaceErrorBase: number, quality: QualityOf3DModes) {
    switch (quality) {
        case QualityOf3DModes.HIGH:
            return { ...highQualityParametersDesktop, maximumScreenSpaceError: maximumScreenSpaceErrorBase };
        case QualityOf3DModes.MEDIUM:
            return { ...mediumQualityParametersDesktop, maximumScreenSpaceError: maximumScreenSpaceErrorBase * 2 };
        case QualityOf3DModes.LOW:
        default:
            return { ...lowQualityParametersDesktop, maximumScreenSpaceError: maximumScreenSpaceErrorBase * 4 };
    }
}
