// Color theme for embed projects. Values are set as CSS variables and should relate to '_embed_palette.scss'.
const embedProjectTheme: Record<string, string> = {
    '--map_wrapper_bg': '#343434',

    '--sb_background': 'rgba(30, 30, 30, 0.8)',

    '--sb_color': '#F7F7F7',
    '--sb_hover': '#ccc',
    '--sb_active': '#adadad',
    '--sb_disabled': '#7e7e7e',

    '--sb_selected_bg': 'rgba(73, 138, 214, 0.35)',
    '--sb_selected_child_bg': 'rgba(73, 138, 214, 0.15)',
    '--sb_solid_bg': '#1e1e1e',

    '--sb_title_border': '#4E4E4E',

    // Map controls
    '--controls_divider': '#7E7E7E',

    // 'Map mode' controls
    '--mode_shadow': '#444',
    '--mode_color': '#fff',
    '--mode_disabled': '#909090',

    // Color picker
    '--c_p_background': '#1e1e1e',
    '--c_p_border': '#fff',

    // Inspector elements
    '--input_background': 'transparent',
    '--prop_title': '#909090',
    '--prop_icon_hover': '#ccc',
    '--prop_icon_disabled': '#7E7E7E',

    // Inspector photos thumbnail background
    '--thumb_bg': '#4e4e4e',

    // Tools: Profile
    '--profile_overlay_color': '#909090',
    '--profile_overlay_bg': 'rgba(30, 30, 30, 0.8)',
    '--profile_overlay_cancel': '#fff',

    // Tools: Inspection
    '--inspection_border': '#4E4E4E',

    // Undo popup
    '--undo_background': '#1E1E1E',
    '--undo_color': '#FFF',
    '--undo_border': '#FFF',

    // Progress bar
    '--progress_background': '#7E7E7E',

    // Spinner
    '--spinner_bg': '#4E4E4E',

    // Cesium credit lightbox
    '--credit_bg': '#1E1E1E',
    '--credit_color': '#FFF',
    '--credit_shadow': '0 0 0 1px #DDD',
    '--credit_img_filter': 'none',

    // Swiper: slides
    '--swiper_bg': '#4E4E4E',
    '--swiper_color': '#909090',

    // Inspector: icon button states
    '--sb_ico_btn_hover': '#CCCCCC',
    '--sb_ico_btn_active': '#ADADAD',
    '--sb_ico_btn_disabled': '#7E7E7E'
};

export default embedProjectTheme;
