import * as Cesium from 'cesium';
import produce from 'immer';
import { useListener } from 'react-bus';
import { useDispatch } from 'react-redux';
import { useCesium } from 'resium';
import appAxios from '../../../api/appAxios';
import { geometryApi } from '../../../api/initApis';
import { getPhotoPovTuple } from '../../../lib/getPhotoPov';
import { Tuple } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { GeoJson } from '../../../store/helpers/interfaces';
import { ExtendedCamera, selectCameras } from '../../../store/slices/cameras';
import { selectIssues, updateGeometryContent } from '../../../store/slices/geometries';
import { commitGeometry, updateGeometryById } from '../../../store/slices/geometryLayers';
import { setIdOfGeometryWithSavingPreview } from '../../../store/slices/projectView';
import { isCameraAligned, isCameraMasterOrNotMultispectral } from '../frustums/Frustums';

export function BusEvents() {
    const dispatch: AppDispatch = useDispatch();
    const { viewer } = useCesium();
    const issues = useSelector(selectIssues);
    const cameras = useSelector(selectCameras);
    const project = useSelector(state => state.project.projectInfo);
    const layers = useSelector(state => state.project.structure.temporaryLayers);

    useListener<{ payload: { id: string; canvasBlob: Blob } }>('Issue.Save', async e => {
        const { id, canvasBlob } = e!.payload;
        const issue = issues.find(i => i.id === id);
        const camera = cameras
            .filter(isCameraMasterOrNotMultispectral)
            .find(c => c.uid === issue?.content.properties.ac_photoUid);
        if (issue && camera) {
            const bbox = issue.content.properties.ac_issue_bbox as Tuple<number, 4>;
            const u = (bbox[2] + bbox[0]) / 2;
            const w = (bbox[3] + bbox[1]) / 2;
            const rectCenter =
                isCameraAligned(camera) && camera.pPoint ? imagePointToWorldPoint(u, w, camera) : undefined;

            let newIssueGeojson: GeoJson;
            if (rectCenter) {
                newIssueGeojson = produce(issue.content, draft => {
                    const cartographic = Cesium.Cartographic.fromCartesian(rectCenter);
                    draft.geometry.coordinates = [
                        Cesium.Math.toDegrees(cartographic.longitude),
                        Cesium.Math.toDegrees(cartographic.latitude),
                        cartographic.height
                    ];
                    draft.properties.ac_point_of_view = getPhotoPovTuple(camera);
                    draft.properties.ac_image_name = camera.fileName;
                });
            } else {
                newIssueGeojson = produce(issue.content, draft => {
                    draft.properties.ac_point_of_view = getPhotoPovTuple(camera);
                    draft.properties.ac_image_name = camera.fileName;
                });
            }
            dispatch(updateGeometryContent({ id, geoJson: newIssueGeojson }));
            const { geometryId } = await dispatch(commitGeometry({ temporaryGeometryUid: id })).unwrap();
            dispatch(setIdOfGeometryWithSavingPreview(geometryId));

            const layer = layers.find(l => l.geometries.includes(id));

            try {
                const { data } = await geometryApi.createGeometryDocumentUpload(project.id!, layer?.id!, geometryId, {
                    name: camera.fileName,
                    contentType: 'application/octet-stream',
                    sizeInBytes: canvasBlob.size
                });
                await appAxios.put(data.url!, canvasBlob);
                await geometryApi.completeGeometryDocumentUpload(
                    project.id!,
                    layer?.id!,
                    geometryId,
                    data.documentUid!
                );
                dispatch(
                    updateGeometryContent({
                        id: geometryId,
                        geoJson: produce(newIssueGeojson, draft => {
                            draft.properties.ac_preview_uid = data.documentUid!;
                        })
                    })
                );
                await dispatch(updateGeometryById(geometryId));
            } finally {
                dispatch(setIdOfGeometryWithSavingPreview(undefined));
            }
        }

        function imagePointToWorldPoint(u: number, w: number, camera: ExtendedCamera) {
            const cameraX = (u - camera.width / 2 - camera.pPoint[0]) / camera.f;
            const cameraY = (w - camera.height / 2 - camera.pPoint[1]) / camera.f;
            const directionVector = new Cesium.Cartesian3(cameraX, cameraY, 1);

            const transformMatrix = Cesium.Matrix4.fromArray(camera.transform.flat());

            const cameraWorldPosition = Cesium.Cartesian3.fromArray(camera.position);

            const worldDirection = Cesium.Matrix4.multiplyByPointAsVector(
                transformMatrix,
                directionVector,
                new Cesium.Cartesian3()
            );

            const ray = new Cesium.Ray(cameraWorldPosition, worldDirection);

            // CHECKME This is using cesium private properties. May break on update!
            const intersection = (viewer?.scene as any).pickFromRay(ray)?.position;
            if (import.meta.env.DEV) {
                console.log('[CHECKME must not be undefined]', intersection);
            }

            return intersection;
        }
    });

    return null;
}
