import * as Cesium from 'cesium';
import { ComponentProps, useContext, useState } from 'react';
import { CameraFlyToBoundingSphere } from 'resium';
import FlyToTilesetsContext from '../../../../contexts/FlyToTilesetsContext';
import { nadirHeadingPitchRange } from '../../../../sharedConstants';
import InitialCameraFlyTo from '../../initial-camera-fly-to/InitialCameraFlyTo';
import HandleCameraFlyToEvent from './HandleCameraFlyToEvent';
import { TourPlaying } from './TourPlaying';
import { useSelector } from '../../../../store';

const commonProps: Partial<ComponentProps<typeof CameraFlyToBoundingSphere>> = {
    once: true,
    duration: 1,
    offset: nadirHeadingPitchRange
};

type Props = {
    artifactRefs: Record<string, Cesium.Cesium3DTileset | Cesium.ImageryLayer | Cesium.Entity>;
};

export default function CameraEvents({ artifactRefs }: Props) {
    const { clickedTilesets } = useContext(FlyToTilesetsContext);
    const tourPlayerState = useSelector(state => state.projectView.tourPlayerState);
    const [hasInitialFlyToHappened, setHasInitialFlyToHappened] = useState(false);

    return (
        <>
            {!hasInitialFlyToHappened && (
                <InitialCameraFlyTo
                    hasBeenPerformed={hasInitialFlyToHappened}
                    setHasBeenPerformed={setHasInitialFlyToHappened}
                />
            )}

            {clickedTilesets.ids && (
                <HandleCameraFlyToEvent
                    artifactRefs={artifactRefs}
                    objectType={clickedTilesets.objectType!}
                    objectId={clickedTilesets.ids}
                />
            )}

            {tourPlayerState === 'playing' && <TourPlaying />}
        </>
    );
}
