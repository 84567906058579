import classNames from 'classnames';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from '../../../store';
import CompareToolBreadcrumbs from './CompareToolBreadcrumbs';
import InspectToolBreadcrumbs from './InspectToolBreadcrumbs';
import PresentationToolBreadcrumbs from './PresentationToolBreadcrumbs';
import NormalBreadcrumbs from './NormalBreadcrumbs';
import { Routes } from '../../../sharedConstants';

const ProjectBreadcrumbs: React.FC = () => {
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const isCamerasInspectionEnabled = useSelector(state => state.projectView.isCamerasInspectionEnabled);
    const isPresentationSetupEnabled = useSelector(state => state.projectView.isPresentationSetupEnabled);
    const isAnyToolActive = isCompareToolEnabled || isCamerasInspectionEnabled || isPresentationSetupEnabled;
    const isSiteView = useRouteMatch({
        path: Routes.SITE_VIEW,
        strict: true
    });

    return (
        <div
            id='mapInfoBar'
            className={classNames({
                'infobar-tool': isAnyToolActive,
                'compare-site-tool': isSiteView && isCompareToolEnabled
            })}
        >
            {(() => {
                if (isCompareToolEnabled) return <CompareToolBreadcrumbs />;
                if (isCamerasInspectionEnabled) return <InspectToolBreadcrumbs />;
                if (isPresentationSetupEnabled) return <PresentationToolBreadcrumbs />;
                return <NormalBreadcrumbs />;
            })()}
        </div>
    );
};

export default ProjectBreadcrumbs;
