import PreviousPageLink from '../../components/Elements/previous-page-link/PreviousPageLink';
import SubscriptionForm from '../../components/subscriptions/SubscriptionForm';

import styles from './Time.module.scss';

export default function Time() {
    return (
        <div className={styles.contentWrapper}>
            <PreviousPageLink />
            <SubscriptionForm showHours={true} expandedHoursList={true} />
        </div>
    );
}
