/* tslint:disable */
/* eslint-disable */
/**
 * billing-api
 * Billing API allows to get info about products and purchase them
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BillingAccountInfo } from '../model';
// @ts-ignore
import { BillingAddressInfo } from '../model';
// @ts-ignore
import { InvoicesPage } from '../model';
// @ts-ignore
import { Order } from '../model';
// @ts-ignore
import { OrderResponse } from '../model';
// @ts-ignore
import { ProductsInfo } from '../model';
// @ts-ignore
import { State } from '../model';
// @ts-ignore
import { StorageSubscriptionInfoList } from '../model';
// @ts-ignore
import { StorageSubscriptionProduct } from '../model';
/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel storage subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscription/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel storage subscription transition, that was already planned. It could be downgrade or cancel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscriptionTransition: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscription/transition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an order
         * @param {Order} order Products to order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder: async (order: Order, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'order' is not null or undefined
            assertParamExists('createOrder', 'order', order)
            const localVarPath = `/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(order, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Downgrade storage subscription
         * @param {StorageSubscriptionProduct} storageSubscriptionProduct Subscription after downgrade
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downgradeSubscription: async (storageSubscriptionProduct: StorageSubscriptionProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'storageSubscriptionProduct' is not null or undefined
            assertParamExists('downgradeSubscription', 'storageSubscriptionProduct', storageSubscriptionProduct)
            const localVarPath = `/subscription/downgrade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storageSubscriptionProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get info about user account in billing system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingAccountInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get info about billing address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingAddressInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the list invoices of user in billing system
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {Array<'+payment_date' | '-payment_date'>} [sort] Order of invoices: \&#39;+\&#39; - ascending, \&#39;-\&#39; - descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesInfo: async (limit?: number, page?: number, sort?: Array<'+payment_date' | '-payment_date'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get info about all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get info about storage subscriptions in billing system
         * @param {Array<State>} [states] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorageSubscriptions: async (states?: Array<State>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/subscriptions/storage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (states) {
                localVarQueryParameter['states'] = states;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update info about user address in billing system
         * @param {BillingAddressInfo} billingAddressInfo Billing address for update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBillingAddressInfo: async (billingAddressInfo: BillingAddressInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingAddressInfo' is not null or undefined
            assertParamExists('updateBillingAddressInfo', 'billingAddressInfo', billingAddressInfo)
            const localVarPath = `/account/address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingAddressInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel storage subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSubscription(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscription(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel storage subscription transition, that was already planned. It could be downgrade or cancel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSubscriptionTransition(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscriptionTransition(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an order
         * @param {Order} order Products to order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrder(order: Order, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrder(order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Downgrade storage subscription
         * @param {StorageSubscriptionProduct} storageSubscriptionProduct Subscription after downgrade
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downgradeSubscription(storageSubscriptionProduct: StorageSubscriptionProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downgradeSubscription(storageSubscriptionProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get info about user account in billing system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBillingAccountInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingAccountInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBillingAccountInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get info about billing address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBillingAddressInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingAddressInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBillingAddressInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the list invoices of user in billing system
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {Array<'+payment_date' | '-payment_date'>} [sort] Order of invoices: \&#39;+\&#39; - ascending, \&#39;-\&#39; - descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoicesInfo(limit?: number, page?: number, sort?: Array<'+payment_date' | '-payment_date'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoicesPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoicesInfo(limit, page, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get info about all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductsInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get info about storage subscriptions in billing system
         * @param {Array<State>} [states] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStorageSubscriptions(states?: Array<State>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StorageSubscriptionInfoList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStorageSubscriptions(states, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update info about user address in billing system
         * @param {BillingAddressInfo} billingAddressInfo Billing address for update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBillingAddressInfo(billingAddressInfo: BillingAddressInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBillingAddressInfo(billingAddressInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel storage subscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscription(options?: any): AxiosPromise<void> {
            return localVarFp.cancelSubscription(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel storage subscription transition, that was already planned. It could be downgrade or cancel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscriptionTransition(options?: any): AxiosPromise<void> {
            return localVarFp.cancelSubscriptionTransition(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an order
         * @param {Order} order Products to order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(order: Order, options?: any): AxiosPromise<OrderResponse> {
            return localVarFp.createOrder(order, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Downgrade storage subscription
         * @param {StorageSubscriptionProduct} storageSubscriptionProduct Subscription after downgrade
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downgradeSubscription(storageSubscriptionProduct: StorageSubscriptionProduct, options?: any): AxiosPromise<void> {
            return localVarFp.downgradeSubscription(storageSubscriptionProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get info about user account in billing system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingAccountInfo(options?: any): AxiosPromise<BillingAccountInfo> {
            return localVarFp.getBillingAccountInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get info about billing address
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBillingAddressInfo(options?: any): AxiosPromise<BillingAddressInfo> {
            return localVarFp.getBillingAddressInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the list invoices of user in billing system
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {Array<'+payment_date' | '-payment_date'>} [sort] Order of invoices: \&#39;+\&#39; - ascending, \&#39;-\&#39; - descending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesInfo(limit?: number, page?: number, sort?: Array<'+payment_date' | '-payment_date'>, options?: any): AxiosPromise<InvoicesPage> {
            return localVarFp.getInvoicesInfo(limit, page, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get info about all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsInfo(options?: any): AxiosPromise<ProductsInfo> {
            return localVarFp.getProductsInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get info about storage subscriptions in billing system
         * @param {Array<State>} [states] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStorageSubscriptions(states?: Array<State>, options?: any): AxiosPromise<StorageSubscriptionInfoList> {
            return localVarFp.getStorageSubscriptions(states, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update info about user address in billing system
         * @param {BillingAddressInfo} billingAddressInfo Billing address for update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBillingAddressInfo(billingAddressInfo: BillingAddressInfo, options?: any): AxiosPromise<void> {
            return localVarFp.updateBillingAddressInfo(billingAddressInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
    /**
     * 
     * @summary Cancel storage subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public cancelSubscription(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).cancelSubscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel storage subscription transition, that was already planned. It could be downgrade or cancel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public cancelSubscriptionTransition(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).cancelSubscriptionTransition(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an order
     * @param {Order} order Products to order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public createOrder(order: Order, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).createOrder(order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Downgrade storage subscription
     * @param {StorageSubscriptionProduct} storageSubscriptionProduct Subscription after downgrade
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public downgradeSubscription(storageSubscriptionProduct: StorageSubscriptionProduct, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).downgradeSubscription(storageSubscriptionProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get info about user account in billing system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public getBillingAccountInfo(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).getBillingAccountInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get info about billing address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public getBillingAddressInfo(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).getBillingAddressInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the list invoices of user in billing system
     * @param {number} [limit] Maximum number of the items to be returned
     * @param {number} [page] Page number to be returned
     * @param {Array<'+payment_date' | '-payment_date'>} [sort] Order of invoices: \&#39;+\&#39; - ascending, \&#39;-\&#39; - descending
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public getInvoicesInfo(limit?: number, page?: number, sort?: Array<'+payment_date' | '-payment_date'>, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).getInvoicesInfo(limit, page, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get info about all products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public getProductsInfo(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).getProductsInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get info about storage subscriptions in billing system
     * @param {Array<State>} [states] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public getStorageSubscriptions(states?: Array<State>, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).getStorageSubscriptions(states, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update info about user address in billing system
     * @param {BillingAddressInfo} billingAddressInfo Billing address for update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public updateBillingAddressInfo(billingAddressInfo: BillingAddressInfo, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).updateBillingAddressInfo(billingAddressInfo, options).then((request) => request(this.axios, this.basePath));
    }
}
