import * as Cesium from 'cesium';
import _ from 'lodash';
import { forwardRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { CesiumComponentRef, Entity, PolylineGraphics, useCesium } from 'resium';
import wgs84ToCartesian3 from '../../../../lib/wgs84ToCartesian3';
import { GeometryTypes } from '../../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../../store';
import { ProjectStructureObjectTypes } from '../../../../store/helpers/interfaces';
import { setSelectedObject } from '../../../../store/sharedActions';
import { setCurrentlyHoveringShapeId } from '../../../../store/slices/projectView';
import ElevationProfilePoint from '../../elevation-profile/ElevationProfilePoint';
import { GeometryProps } from '../Geometry';
import { polylineWidth } from '../styling';
import Point from './PointNodeGeometry';

type Props = GeometryProps & {
    isPartOfPolygon?: boolean;
};

export default forwardRef<CesiumComponentRef<Cesium.Entity>, Props>(
    (
        {
            geometry,
            layerVisible,
            isEditing,
            isHovering,
            isSelected,
            isDrawing,
            color,
            isPartOfPolygon,
            mouseEventsBlocked,
            readonly
        },
        ref
    ) => {
        const dispatch: AppDispatch = useDispatch();
        const { scene } = useCesium();
        const floatingPointCoordinates = useSelector(state => state.projectView.floatingPointCoordinates);
        const currentlyDrawingShapeId = useSelector(state => state.projectView.currentlyDrawingShapeId);
        const shouldDrawFloatingPoint = currentlyDrawingShapeId === geometry.id;

        let coordinates = isPartOfPolygon
            ? (geometry.content.geometry.coordinates as number[][][])[0]
            : (geometry.content.geometry.coordinates as number[][]);

        if (isPartOfPolygon) coordinates = coordinates.slice(0, coordinates.length - 1);

        const coordinatesWithFloatingPoint = useMemo(() => {
            if (!shouldDrawFloatingPoint) return coordinates;
            return floatingPointCoordinates ? coordinates.concat([floatingPointCoordinates]) : coordinates;
        }, [floatingPointCoordinates, coordinates, shouldDrawFloatingPoint]);

        const coordinatesCartesian3 = useMemo(
            () => wgs84ToCartesian3(coordinatesWithFloatingPoint, scene!) as Cesium.Cartesian3[],
            [coordinatesWithFloatingPoint, scene]
        );

        const positions = useMemo(
            () => new Cesium.CallbackProperty(() => coordinatesCartesian3, false),
            [coordinatesCartesian3]
        );

        const midpoints = useMemo(() => {
            const midpoints: Cesium.Cartesian3[] = [];
            for (let i = 1; i < coordinatesCartesian3.length; i++) {
                midpoints.push(
                    Cesium.Cartesian3.midpoint(
                        coordinatesCartesian3[i - 1],
                        coordinatesCartesian3[i],
                        new Cesium.Cartesian3()
                    )
                );
            }
            if (isPartOfPolygon) {
                midpoints.push(
                    Cesium.Cartesian3.midpoint(
                        coordinatesCartesian3[0],
                        _.last(coordinatesCartesian3)!,
                        new Cesium.Cartesian3()
                    )
                );
            }
            return midpoints;
        }, [coordinatesCartesian3, isPartOfPolygon]);

        const visible = !!geometry.content.properties.ac_visibility;

        return (
            <>
                {/* Have to use composite ids, because polyline is a part of polygon geometry,
             // and cesium doesn't allow multiple entities with same id */}
                <Entity
                    ref={ref}
                    id={`${geometry.id}#${GeometryTypes.POLYLINE}`}
                    onMouseEnter={e => {
                        if (mouseEventsBlocked) return;

                        if (!isDrawing && !isEditing) dispatch(setCurrentlyHoveringShapeId(geometry.id));
                    }}
                    onMouseLeave={e => {
                        if (mouseEventsBlocked) return;

                        if (!isDrawing && !isEditing) dispatch(setCurrentlyHoveringShapeId(undefined));
                    }}
                    onClick={e => {
                        if (mouseEventsBlocked) return;

                        if (!isDrawing && !isEditing && isHovering)
                            dispatch(
                                setSelectedObject({
                                    type: ProjectStructureObjectTypes.GEOMETRY,
                                    artifactId: geometry.id,
                                    needToScroll: true
                                })
                            );
                    }}
                >
                    <PolylineGraphics
                        positions={positions}
                        clampToGround={false}
                        width={polylineWidth}
                        material={color}
                        arcType={Cesium.ArcType.NONE}
                        depthFailMaterial={color.withAlpha(0.7)}
                        show={layerVisible && visible}
                    />
                </Entity>
                {(isSelected || isDrawing) && !isPartOfPolygon && (
                    <>
                        {coordinatesCartesian3.map((c, index) => (
                            <Point
                                id={`${GeometryTypes.POLYLINE}#${geometry.id}#${index}`}
                                key={index}
                                position={c}
                                readonly={readonly}
                                visible={layerVisible && visible}
                                color={color}
                                isSavingPoint={isDrawing && index !== 0 && index === coordinates.length - 1}
                                mouseEventsBlocked={mouseEventsBlocked}
                            />
                        ))}
                    </>
                )}
                {!readonly && isSelected && (
                    <>
                        {midpoints.map((p, index) => (
                            <Point
                                id={`${GeometryTypes.POLYLINE}#${geometry.id}#${index}#midpoint`}
                                key={index}
                                position={p}
                                readonly={readonly}
                                visible={layerVisible && visible}
                                color={color}
                                isMidpoint
                                mouseEventsBlocked={mouseEventsBlocked}
                            />
                        ))}
                    </>
                )}
                <ElevationProfilePoint polylineId={geometry.id} />
            </>
        );
    }
);
