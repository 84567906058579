import * as Cesium from 'cesium';
import _ from 'lodash';
import { useMemo } from 'react';
import { Route } from 'react-router-dom';
import { CameraFlyTo, CameraFlyToBoundingSphere } from 'resium';
import { useQueryParams } from 'use-query-params';
import { SourceType } from '../../../generated/cloud-frontend-api';
import getRelated3DElevation from '../../../lib/getRelated3DElevation';
import { CSVParam, Routes, nadirHeadingPitchRange } from '../../../sharedConstants';
import { useSelector } from '../../../store';
import { isObjectVisible, selectDefaultPointOfView } from '../../../store/slices/structure';
import CameraFlyToPointOfView from '../ArtifactViewer/camera/CameraFlyToPointOfView';
import { isCameraAligned } from '../frustums/Frustums';

type Props = {
    hasBeenPerformed: boolean;
    setHasBeenPerformed(has: boolean): void;
};

export default function InitialCameraFlyTo({ hasBeenPerformed, setHasBeenPerformed }: Props) {
    const [{ position, orientation }] = useQueryParams({ position: CSVParam, orientation: CSVParam });
    const cameras = useSelector(state => state.cameras);
    const structures = useSelector(state => state.structure.structures);
    const chunks = useSelector(state => state.project.structure.chunks);
    const datasets = useSelector(state => state.datasets.datasets);
    const boundingSpheres = useSelector(state => state.projectView.tilesetsBoundingSpheres);
    const defaultPointOfView = useSelector(state => selectDefaultPointOfView(state));
    const hasCameraPositionsInQuery = position && orientation;

    const boundingSphere: Cesium.BoundingSphere | undefined = useMemo(() => {
        const visibleChunks = chunks.filter(c => isObjectVisible(structures, c.assetUid!));
        const visibleTilesets = visibleChunks
            .flatMap(c => c.artifacts)
            .filter(a => isObjectVisible(structures, a.datasetUid!))
            .filter(
                a =>
                    datasets.find(d => d.datasetUid === a.datasetUid)?.sourceData?.type !== SourceType.DEM ||
                    getRelated3DElevation(
                        datasets,
                        chunks.find(c => c.artifacts.map(a => a.datasetUid).includes(a.datasetUid)),
                        datasets.find(d => d.datasetUid === a.datasetUid)?.name
                    ) === undefined
            )
            .map(a => datasets.find(d => d.datasetUid === a.datasetUid)?.visualData?.dataUid!);
        const areVisibleTilesetsReady = visibleTilesets.every(t => Object.keys(boundingSpheres).includes(t));

        const visibleDatasets = datasets.filter(
            d => isObjectVisible(structures, d.datasetUid!) && d.visualData?.dataUid
        );
        const areVisibleDatasetsReady = visibleDatasets.every(d =>
            Object.keys(boundingSpheres).includes(d.visualData?.dataUid || '')
        );
        const visibleDatasetsBoundingSphere = areVisibleDatasetsReady
            ? Cesium.BoundingSphere.fromBoundingSpheres(
                  _.filter(boundingSpheres, (value, key) =>
                      visibleDatasets.map(d => d.visualData?.dataUid || '').includes(key)
                  )
              )
            : undefined;

        if (visibleTilesets.length) {
            const visibleTilesetsBoundingSphere = areVisibleTilesetsReady
                ? Cesium.BoundingSphere.fromBoundingSpheres(
                      _.filter(boundingSpheres, (value, key) => visibleTilesets.includes(key))
                  )
                : undefined;
            return visibleTilesetsBoundingSphere
                ? Cesium.BoundingSphere.fromBoundingSpheres(
                      _.compact([visibleTilesetsBoundingSphere, visibleDatasetsBoundingSphere])
                  )
                : undefined;
        } else if (visibleDatasets.length) {
            return visibleDatasetsBoundingSphere;
        } else {
            const visibleCameras = _.compact(
                visibleChunks.flatMap(c => (c.cameras ? cameras[c.cameras?.artifactUid!] : undefined))
            );
            if (visibleCameras.length) {
                const positions = visibleCameras.filter(isCameraAligned).map(c => Cesium.Cartesian3.unpack(c.position));
                const boundingSpheres = positions.map(p => new Cesium.BoundingSphere(p, 1));
                return Cesium.BoundingSphere.fromBoundingSpheres(boundingSpheres);
            }
        }
    }, [boundingSpheres, chunks, datasets, structures, cameras]);

    if (hasBeenPerformed) return null;

    if (defaultPointOfView)
        return (
            <CameraFlyToPointOfView
                pov={defaultPointOfView}
                onComplete={() => {
                    setHasBeenPerformed(true);
                }}
            />
        );

    if (boundingSphere)
        return (
            <>
                <CameraFlyToBoundingSphere
                    boundingSphere={boundingSphere}
                    once
                    duration={0}
                    onComplete={() => {
                        setHasBeenPerformed(true);
                    }}
                    offset={nadirHeadingPitchRange}
                />
                <>
                    {/* Backwards compatibility for old embeds */}
                    <Route exact path={Routes.EMBEDDED_PROJECT_VIEW}>
                        {hasCameraPositionsInQuery && (
                            <CameraFlyTo
                                destination={new Cesium.Cartesian3(...position.map(p => Number(p)))}
                                orientation={{
                                    heading: Number(orientation[0]),
                                    pitch: Number(orientation[1]),
                                    roll: Number(orientation[2])
                                }}
                                once
                                duration={0}
                                onComplete={() => {
                                    setHasBeenPerformed(true);
                                }}
                            />
                        )}
                    </Route>
                </>
            </>
        );

    return null;
}
