import { createAsyncThunk } from '@reduxjs/toolkit';
import initGdalJs from 'gdal3.js';
import * as Cesium from 'cesium';
import { AppDispatch, ApplicationState } from '../..';
import { validationStarted } from '../../slices/datasetsUpload';
import GeoJsonUpload from '../geojson-upload/GeoJsonUpload';
import getFilenameExtension from '../../../lib/getFilenameExtension';
import { v4 } from 'uuid';
import { getDeterminedCoordinateSystem } from '../../slices/coordinateSystems';

const thunkPrefix = 'vectorLayerUpload';

const uploadVectorLayer = createAsyncThunk<
    { id: string },
    { id: string; terrainProvider: Cesium.TerrainProvider; isTemporary: boolean; file: File; crsId: string },
    { dispatch: AppDispatch; state: ApplicationState; rejectValue: { id: string; error?: string } }
>(
    `${thunkPrefix}/upload`,
    async ({ id, isTemporary, terrainProvider, file, crsId }, { dispatch, getState, rejectWithValue }) => {
        dispatch(validationStarted({ id }));
        const personalAndDefaultCoordinateSystems = getState().coordinateSystems.personalCrs.concat(
            getState().coordinateSystems.defaultCrs
        );
        const crs = personalAndDefaultCoordinateSystems.find(cs => cs.uid === crsId);
        const crsCode = crs?.epsgCode;
        let wkt = '';
        if (!crsCode) {
            const crsInfo = await getDeterminedCoordinateSystem(getState().project.projectInfo.id!, crs?.uid!);
            wkt = crsInfo.wkt;
        }

        const Gdal = await initGdalJs();
        const options = [
            '-s_srs',
            crsCode ? `EPSG:${crsCode}` : wkt, // Coordinate system of input file
            '-f',
            'GeoJSON', // Format of output file
            '-t_srs',
            'EPSG:4326' // Coordinate system of output file
        ];
        try {
            const randomizedNameFile = new File([file], `${v4()}${file.name}`);
            let dataset: DatasetList | undefined;
            if (getFilenameExtension(file.name).toLowerCase() === 'zip') {
                dataset = await Gdal.open(randomizedNameFile, [], ['vsizip']);
            } else {
                dataset = await Gdal.open(randomizedNameFile);
            }
            const geojson = await Gdal.ogr2ogr(dataset.datasets[0], options);
            const bytes = await Gdal.getFileBytes(geojson);
            const blob = new Blob([bytes]);
            await Gdal.close(dataset.datasets[0]);

            return dispatch(
                GeoJsonUpload.uploadGeoJsonLayer({
                    file: new File([blob], file.name),
                    layerUid: id,
                    temporarily: isTemporary,
                    terrainProvider
                })
            ).unwrap();
        } catch (err) {
            return rejectWithValue({ id, error: (err as any)?.message || '' });
        }
    }
);

export const VectorLayerUpload = {
    uploadVectorLayer
};
