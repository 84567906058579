import * as Cesium from 'cesium';
import { memo, useMemo } from 'react';
import { Billboard, BillboardCollection, useCesium } from 'resium';
import { Issue } from '../../../../entities/Issue';
import wgs84ToCartesian3 from '../../../../lib/wgs84ToCartesian3';
import { GeometryTypes } from '../../../../sharedConstants';
import {
    ProjectStructureObjectTypes,
    TemporaryGeometry,
    CurrentlyEditingShape
} from '../../../../store/helpers/interfaces';
import { AppDispatch, useSelector } from '../../../../store';
import { useDispatch } from 'react-redux';
import { setSelectedObject } from '../../../../store/sharedActions';
import { setCurrentlyHoveringShapeId } from '../../../../store/slices/projectView';
import { useVisibility } from '../../../../hooks/useStructureInfo';
import { makeSelectLayerByGeometryId } from '../../../../store/slices/geometryLayers';
import { isObjectVisible, selectGroups, selectObjectVisibility } from '../../../../store/slices/structure';
import useAreSceneMouseEventsBlocked from '../../../../hooks/useAreSceneMouseEventsBlocked';

type Props = {
    issue: TemporaryGeometry<GeometryTypes.POINT>;
};

const ICON_WIDTH = 24;
const ICON_HEIGHT = 34;

function IssueGeometry({ issue }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { scene } = useCesium();
    const selectedObject = useSelector(state => state.project.selectedObject);
    const structures = useSelector(state => state.structure.structures);
    const groups = useSelector(selectGroups);
    const selectLayerByGeometryId = useMemo(makeSelectLayerByGeometryId, []);
    const layer = useSelector(state => selectLayerByGeometryId(state, issue.id));
    const currentlyHoveringShapeId = useSelector(state => state.projectView.currentlyHoveringShapeId);
    const isActive = selectedObject?.artifactId === issue.id || currentlyHoveringShapeId === issue.id;
    const layerVisible = useSelector(state => selectObjectVisibility(state, layer?.id!));
    const structureInfo = structures.find(s => s.uid === layer?.id);
    const group = groups.find(g => g.uid === structureInfo?.parentUid);
    const belongsToGroup = Boolean(group);
    const groupVisible = belongsToGroup ? isObjectVisible(structures, group?.uid!) : true;
    const show = issue.content.properties.ac_visibility && layerVisible && groupVisible;

    const mouseEventsBlocked = useAreSceneMouseEventsBlocked();

    const image = useMemo(() => {
        const fill = new Issue(issue).color;
        const width = isActive ? ICON_WIDTH + 4 : ICON_WIDTH;
        const height = isActive ? ICON_HEIGHT + 4 : ICON_HEIGHT;
        const svg = `<svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.7935 32.7021C16.3935 30.9446 19.0601 27.8067 21.3295 24.1264C23.5906 20.4595 25.5 16.1871 25.5 12.1748C25.5 5.69405 19.8694 0.5 13 0.5C6.13059 0.5 0.5 5.69405 0.5 12.1748C0.5 16.1871 2.40938 20.4595 4.67051 24.1264C6.93989 27.8067 9.60646 30.9446 11.2065 32.7021C12.175 33.766 13.825 33.766 14.7935 32.7021Z" fill="${fill}" stroke="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M11.9632 4.58123C12.445 3.80625 13.555 3.80626 14.0368 4.58123L21.8077 17.0815C22.325 17.9137 21.738 19 20.7709 19H5.22909C4.26204 19 3.67501 17.9137 4.19233 17.0815L11.9632 4.58123ZM13 6.02863L5.97222 17.3335H20.0278L13 6.02863ZM12.1836 8.9973C12.1836 8.53711 12.5499 8.16406 13.0017 8.16406C13.4535 8.16406 13.8197 8.53711 13.8197 8.9973V13.1635C13.8197 13.6236 13.4535 13.9967 13.0017 13.9967C12.5499 13.9967 12.1836 13.6236 12.1836 13.1635V8.9973ZM13.0017 14.832C12.5499 14.832 12.1836 15.2051 12.1836 15.6653C12.1836 16.1254 12.5499 16.4985 13.0017 16.4985C13.4535 16.4985 13.8197 16.1254 13.8197 15.6653C13.8197 15.2051 13.4535 14.832 13.0017 14.832Z" fill="white"/></svg>`;
        const image = new Image();
        image.src = 'data:image/svg+xml;base64,' + window.btoa(window.unescape(encodeURIComponent(svg)));
        return image;
    }, [issue, isActive]);

    const objectPosition = wgs84ToCartesian3(issue.content.geometry.coordinates, scene!) as Cesium.Cartesian3;

    return (
        <BillboardCollection>
            <Billboard
                show={show}
                id={issue.id}
                position={objectPosition}
                image={image.src}
                heightReference={Cesium.HeightReference.NONE}
                disableDepthTestDistance={Infinity}
                onMouseEnter={e => {
                    if (mouseEventsBlocked) return;
                    dispatch(setCurrentlyHoveringShapeId(issue.id));
                }}
                onMouseLeave={e => {
                    if (mouseEventsBlocked) return;
                    dispatch(setCurrentlyHoveringShapeId(undefined));
                }}
                onClick={e => {
                    if (mouseEventsBlocked) return;
                    dispatch(
                        setSelectedObject({
                            artifactId: issue.id,
                            needToScroll: true,
                            type: ProjectStructureObjectTypes.GEOMETRY
                        })
                    );
                }}
            />
        </BillboardCollection>
    );
}

export default memo(IssueGeometry);
