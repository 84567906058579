import { useSelector } from '../store';
import { selectDatasetsAndArtifactsAndLayersByTreeId, selectStructuresByCompareTreeId } from '../store/selectors';

interface Args {
    structureId?: string;
    treeId?: 'tree1' | 'tree2';
    dataId?: string;
}

function useStructureInfo({ structureId, treeId, dataId }: Args) {
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const structures = useSelector(state => selectStructuresByCompareTreeId(state, treeId));
    const datasets = useSelector(state => selectDatasetsAndArtifactsAndLayersByTreeId(state, treeId));

    function getDatasetByDataId() {
        return datasets.find(d => d.visualData?.dataUid === dataId);
    }

    if (!isCompareToolEnabled) {
        if (dataId) return structures.find(s => s.uid === getDatasetByDataId()?.datasetUid);
        else return structures.find(s => s.uid === structureId);
    }

    if (!treeId) return undefined;

    if (dataId) return structures.find(s => s.uid === getDatasetByDataId()?.datasetUid);
    else return structures.find(s => s.uid === structureId);
}

export const useVisibility = (arg: Args) => {
    const structureInfo = useStructureInfo(arg);
    if (structureInfo) return structureInfo?.properties?.visible === String(true);
    return true;
};

export const useExpansion = (arg: Args) => {
    const structureInfo = useStructureInfo(arg);
    if (structureInfo) return structureInfo?.properties?.expanded === String(true);
    return true;
};
