import { useTranslation } from 'react-i18next';
import { useSelector } from '../store';

export default function useGetCopyrightString() {
    const { t } = useTranslation(['common']);
    const serviceProvider = useSelector(state => state.init.serviceProvider);

    const year = new Date().getFullYear();
    const copyrightText = `© ${year} ${t('common:copyright.agisoftCloud')} ${t('common:copyright.serviceProvidedBy', {
        serviceProvider
    })}`;

    return copyrightText;
}
