import classNames from 'classnames';
import { useContext } from 'react';
import { Route } from 'react-router-dom';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import useIsOwnedEmptySite from '../../../hooks/useIsOwnedEmptySite';
import { EmbedViewMode, Routes, WEBGL_SUPPORTED } from '../../../sharedConstants';
import { useSelector } from '../../../store';
import AddGeometryControls from './AddGeometryControls';
import CompareControl from './CompareControl';
import InspectionControl from './InspectionControl';
import MapSettingsControl from './MapSettingsControl';
import PresentationControl from './PresentationControl';
import CreateViewpointControl from './CreateViewpointControl';
import { selectChunks } from '../../../store/slices/project';

type Props = {
    loading: boolean;
};

export default function MapControls({ loading }: Props) {
    const { embedViewMode, owned } = useContext(ProjectViewAccessContext);
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const isCamerasInspectionEnabled = useSelector(state => state.projectView.isCamerasInspectionEnabled);
    const isPresentationSetupEnabled = useSelector(state => state.projectView.isPresentationSetupEnabled);
    const chunks = useSelector(state => selectChunks(state));
    const hasCameras = chunks.some(chunk => !!chunk.cameras.artifactUid);
    const isOwnedEmptySite = useIsOwnedEmptySite();

    const isFullFeatured = embedViewMode === EmbedViewMode.FULL || !embedViewMode;
    const showControls = !isCompareToolEnabled && !isCamerasInspectionEnabled && !isPresentationSetupEnabled;

    if (loading || !WEBGL_SUPPORTED || isOwnedEmptySite) return null;

    return (
        <div id='mapControls'>
            <div className='map-controls-row-1'>
                {isFullFeatured && (
                    <>
                        <AddGeometryControls />
                        <MapSettingsControl />
                    </>
                )}
            </div>

            {isFullFeatured && showControls && (
                <div className='map-controls-row-2'>
                    <div className='actions-block'>
                        {hasCameras && <InspectionControl />}
                        <PresentationControl />
                        <CompareControl />
                    </div>
                </div>
            )}

            {isPresentationSetupEnabled && owned && (
                <div className='map-controls-row-2'>
                    <div className='actions-block'>
                        <CreateViewpointControl />
                    </div>
                </div>
            )}
        </div>
    );
}
