import { useState } from 'react';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { PlanType } from '../../../generated/account-frontend-api';
import { Account } from '../../../generated/account-frontend-api/model';
import { PaymentType } from '../../../generated/billing-api';
import { useSelector } from '../../../store';
import useCancelDowngrade from '../../../hooks/useCancelDowngrade';
import PlanItemState from './PlanItemState';
import PlanItemDetails from './PlanItemDetails';
import UpgradePlanButton from './UpgradePlanButton';
import DowngradePlanModal from '../../../components/Elements/modals/downgrade-plan-modal/DowngradePlanModal';

import styles from '../PlanManagement.module.scss';

type Props = {
    itemType: PlanType;
    isDesktopLayout: boolean;
    account: Account;
};

export default function PlanItem({ itemType, isDesktopLayout, account }: Props) {
    const { t } = useTranslation(['cabinet', 'glossary', 'subscribe']);

    const cancelDowngrade = useCancelDowngrade();

    const currentPlan = account.plan?.current;
    const scheduledPlan = account.plan?.scheduled;
    const paymentType = account.paymentType;

    const renewDate = currentPlan?.renewDate;
    const isCommercial = currentPlan?.type === PlanType.COMMERCIAL;
    const isOverdue = currentPlan?.overdue;

    const isActivePlanItem = currentPlan?.type === itemType;

    const [showDetails, setShowDetails] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const name =
        itemType === PlanType.FREE ? t('cabinet:plans.freePlan.title') : t('cabinet:plans.commercialPlan.title');

    const fastSpringLink = useSelector(state => state.user.fastspringLink);

    const showOverdueButton = itemType === PlanType.COMMERCIAL && isOverdue;
    const showUpgradeButton = itemType === PlanType.COMMERCIAL && !isCommercial;
    const showDowngradeButton =
        itemType === PlanType.FREE &&
        isCommercial &&
        (!scheduledPlan || scheduledPlan?.type === PlanType.COMMERCIAL || isOverdue);
    const showCancelDowngradeButton =
        itemType === PlanType.COMMERCIAL &&
        isCommercial &&
        !isOverdue &&
        scheduledPlan?.type === PlanType.FREE &&
        scheduledPlan?.cancelable;

    const hideDetails = !showDetails && !isDesktopLayout;
    const showActionButton =
        isDesktopLayout || showOverdueButton || showUpgradeButton || showDowngradeButton || showCancelDowngradeButton;
    const addDetailsPadding = !isDesktopLayout && !showActionButton;

    const description =
        itemType === PlanType.FREE ? (
            <Trans ns={'cabinet'} i18nKey={'plans.freePlan.description'} components={{ br: <br /> }} />
        ) : (
            <Trans ns={'cabinet'} i18nKey={'plans.commercialPlan.description'} components={{ br: <br /> }} />
        );

    const storage =
        itemType === PlanType.FREE ? (
            <Trans ns={'cabinet'} i18nKey={'plans.freePlan.storage'} components={{ b: <b /> }} />
        ) : (
            <Trans ns={'cabinet'} i18nKey={'plans.commercialPlan.storage'} components={{ b: <b /> }} />
        );

    const processing =
        itemType === PlanType.FREE ? (
            <Trans ns={'cabinet'} i18nKey={'plans.freePlan.time'} components={{ b: <b /> }} />
        ) : (
            <Trans ns={'cabinet'} i18nKey={'plans.commercialPlan.time'} components={{ b: <b /> }} />
        );

    const OverdueButton = () => (
        <a className='btn btn-block' href={fastSpringLink} target='_blank' rel='noopener noreferrer'>
            {paymentType === PaymentType.AUTO
                ? t('subscribe:actions.updatePaymentMethod')
                : t('subscribe:actions.payNow')}
        </a>
    );

    return (
        <div className={classNames(styles.plan, isActivePlanItem && styles.activePlan)}>
            <div>
                <div className={styles.planState}>
                    <PlanItemState
                        itemType={itemType}
                        scheduledPlanType={scheduledPlan?.type}
                        isCommercial={isCommercial}
                        isOverdue={isOverdue}
                        renewDate={renewDate}
                        paymentType={paymentType}
                    />
                </div>
                <h2 className={styles.planTitle}>{name}</h2>
                <div className={styles.planDescription}>{description}</div>
                <div className={styles.planSpecs}>
                    <b>{t('cabinet:plans.storage')}</b>
                    <span className={styles.specsInfo}>{storage}</span>
                </div>
                <div className={styles.planSpecs}>
                    <b>{t('cabinet:plans.processing')}</b>
                    <span className={styles.specsInfo}>{processing}</span>
                </div>
            </div>

            <div className={classNames(addDetailsPadding && styles.detailsMobile)}>
                <div>
                    {isDesktopLayout ? (
                        <span className={styles.detailsTitle}>{t('cabinet:plans.planDetails')}</span>
                    ) : (
                        <div
                            className={styles.detailsTitleWrapper}
                            onClick={() => {
                                setShowDetails(prev => !prev);
                            }}
                        >
                            <div className={styles.togglePlanDetails}>
                                <div className={classNames(styles.toggleArrow, showDetails && styles.expanded)} />
                            </div>
                            <span className={styles.detailsTitle}>{t('cabinet:plans.planDetails')}</span>
                        </div>
                    )}
                </div>
                <div className={classNames(styles.planDetails, hideDetails && styles.hidden)}>
                    {itemType === PlanType.COMMERCIAL && (
                        <div className={styles.detailsIncluded}>{t('cabinet:plans.includedInFreePlan')}</div>
                    )}
                    <PlanItemDetails planType={itemType} />
                </div>
            </div>

            {showActionButton && (
                <div className={styles.planActions}>
                    {showUpgradeButton && <UpgradePlanButton />}
                    {showOverdueButton && <OverdueButton />}
                    {showDowngradeButton && (
                        <button className='btn-ghost-blue btn-block' type='button' onClick={() => setIsModalOpen(true)}>
                            {isOverdue ? t('cabinet:plans.downgradeNow') : t('cabinet:plans.scheduleDowngrade')}
                        </button>
                    )}
                    {showCancelDowngradeButton && (
                        <button className='btn btn-block' type='button' onClick={() => cancelDowngrade()}>
                            {t('cabinet:plans.cancelPlanDowngrade')}
                        </button>
                    )}
                </div>
            )}

            {isModalOpen && <DowngradePlanModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />}
        </div>
    );
}
