import { useTranslation } from 'react-i18next';
import { ReactComponent as SvgLoader } from '../../../svg/general/loader.svg';

type Props = {
    type: 'loading' | 'blocked';
};

export default function ViewLoader({ type }: Props) {
    const { t } = useTranslation(['projectView']);

    return (
        <div className='loading-message' data-testid='loadingMessage'>
            <div className='loader'>
                <SvgLoader className='loader-svg' />
            </div>
            {type === 'loading' && <div className='loading-text'>{t('projectView:viewLoader')}</div>}
        </div>
    );
}
