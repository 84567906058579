import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import convertSize from '../../../../lib/convertSize';
import getFilename from '../../../../lib/getFilename';
import { useSelector } from '../../../../store';
import { ExtendedDatasetInfo } from '../../../../store/slices/datasetfilesUpload';
import Modal, { ModalProps } from '../../modal/Modal';
import ModalActions from '../../modal/ModalActions';
import ModalBody from '../../modal/ModalBody';
import ModalHead from '../../modal/ModalHead';
import { isLinkedDataset } from '../../../../store/slices/datasets';
import { Dataset } from '../../../../entities/Dataset';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from '../../../../sharedConstants';
import classNames from 'classnames';

type Props = ModalProps & {
    datasetInfo: ExtendedDatasetInfo;
    outOfFreeSpace?: boolean;
    onDelete(): void;
};

export default function DeleteDatasetModal({ datasetInfo, outOfFreeSpace, onDelete, setIsOpen, isOpen }: Props) {
    const { t } = useTranslation('modals');
    const { sourceData, visualData } = datasetInfo;
    const projectInfo = useSelector(state => state.project.projectInfo);
    const name = getFilename(datasetInfo.name || '');
    const isAddedFromAnotherProject = isLinkedDataset(datasetInfo, projectInfo.id!);
    const isEmbed = !!useRouteMatch({ path: Routes.EMBEDDED_PROJECT_VIEW, exact: true });
    const { hasFailedValidation } = useMemo(() => new Dataset(datasetInfo), [datasetInfo]);

    const totalSizeInBytes = useMemo(
        () => (sourceData?.sizeInBytes || 0) + (visualData?.sizeInBytes || 0),
        [sourceData, visualData]
    );

    function getBodyMessage() {
        if (isAddedFromAnotherProject) {
            return (
                <Trans
                    ns='modals'
                    i18nKey={'deleteDatasetModal.body_linkedFromAnotherProject'}
                    components={{ p: <p />, s: <strong className='overflow' /> }}
                    values={{ name, parentName: datasetInfo.parentProject?.name }}
                />
            );
        }
        if (outOfFreeSpace) {
            return (
                <Trans
                    ns='modals'
                    i18nKey={'deleteDatasetModal.body_outOfFreeSpace'}
                    components={{ p: <p />, s: <strong className='overflow' /> }}
                    values={{ name }}
                />
            );
        }
        if (hasFailedValidation) {
            return (
                <Trans
                    ns='modals'
                    i18nKey={'deleteDatasetModal.body_notSaved'}
                    components={{ p: <p />, s: <strong className='overflow' /> }}
                    values={{ name }}
                />
            );
        }
        return (
            <Trans
                ns='modals'
                i18nKey={'deleteDatasetModal.body'}
                components={{ p: <p />, s: <strong className='overflow' /> }}
                values={{ name, size: convertSize(totalSizeInBytes) }}
            />
        );
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen} isEmbed={isEmbed}>
            <ModalHead setIsOpen={setIsOpen} data-testid={'head'} isEmbed={isEmbed}>
                {isAddedFromAnotherProject ? t('deleteDatasetModal.head_unlink') : t('deleteDatasetModal.head_delete')}
            </ModalHead>
            <ModalBody>
                <div>{getBodyMessage()}</div>
                <ModalActions>
                    <button
                        className={classNames(isEmbed ? 'btn-ghost-alert' : 'btn-cancel')}
                        type='button'
                        onClick={e => {
                            setIsOpen(false);
                        }}
                    >
                        {t('deleteDatasetModal.action1')}
                    </button>

                    <button
                        className={classNames('btn-alert', { embed: isEmbed })}
                        type='button'
                        onClick={e => {
                            onDelete();
                            setIsOpen(false);
                        }}
                        data-testid={'actionButton'}
                    >
                        {isAddedFromAnotherProject
                            ? t('deleteDatasetModal.action2_unlink')
                            : t('deleteDatasetModal.action2_delete')}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}
