import Tippy from '@tippyjs/react/headless';
import classNames from 'classnames';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useOutsideClickRef } from 'rooks';
import { v4 } from 'uuid';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import useGetPublishState from '../../../hooks/useGetPublishState';
import useIsOwnedEmptySite from '../../../hooks/useIsOwnedEmptySite';
import { AppDispatch, useSelector } from '../../../store';
import { ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import { setDatasetsUploadModalOpen } from '../../../store/slices/datasetsUpload';
import { getNextStructureOrder, putStructure } from '../../../store/slices/structure';
import { ReactComponent as SvgGroup } from '../../../svg/dropdown_actions/group.svg';
import { ReactComponent as SvgLayer } from '../../../svg/dropdown_actions/layer.svg';
import { ReactComponent as SvgLink } from '../../../svg/dropdown_actions/link.svg';
import AddProjectModal from '../../Elements/add-project-modal/AddProjectModal';
import PublishPaymentOverdueModal from '../../Elements/modals/PublishPaymentOverdueModal';
import PublishStorageIsFullModal from '../../Elements/modals/PublishStorageIsFullModal';
import TippyDropdownItemWithIcon from '../../Elements/tippy-dropdown/TippyDropdownItemWithIcon';
import useDrawing from '../../ProjectView/geometry-drawing/useDrawing';
import LinkModal from '../../Elements/modals/link-modal/LinkModal';
import { ProjectType } from '../../../generated/cloud-frontend-api';

export default function ProjectStructureHelper() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const { owned, isInEmbedView } = useContext(ProjectViewAccessContext);
    const structures = useSelector(state => state.structure.structures);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [isLinkModalOpen, setLinkModalOpen] = useState(false);
    const { getNewLayerUid } = useDrawing();
    const publishState = useGetPublishState();
    const [showPaymentOverdueModal, setShowPaymentOverdueModal] = useState(false);
    const [showStorageIsFullModal, setShowStorageIsFullModal] = useState(false);
    const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
    const isOwnedEmptySite = useIsOwnedEmptySite();

    const [dropdown] = useOutsideClickRef(() => {
        setDropdownVisible(false);
    });

    return (
        <>
            <div className='helper-text'>
                {isOwnedEmptySite ? (
                    <>{t('structureSidebar.helper_emptySite')}</>
                ) : (
                    <>
                        {owned &&
                            (projectInfo.type === ProjectType.METASHAPE
                                ? t('structureSidebar.helper_noProcessingResults')
                                : t('structureSidebar.helper_owned'))}
                        {!owned &&
                            (projectInfo.type === ProjectType.METASHAPE
                                ? t('structureSidebar.helper_noProcessingResults_notOwned')
                                : t('structureSidebar.helper_notOwned'))}
                    </>
                )}
            </div>

            <div>
                <Tippy
                    ref={dropdown}
                    render={attrs => (
                        <div
                            className={classNames('tippy-dropdown', { 'theme-dark': isInEmbedView })}
                            onClick={e => {
                                setDropdownVisible(false);
                            }}
                            tabIndex={-1}
                            {...attrs}
                        >
                            <TippyDropdownItemWithIcon
                                icon={<SvgLayer />}
                                text={t('workspaceActions.createLayer')}
                                onClick={e => {
                                    getNewLayerUid();
                                }}
                            />
                            {owned && (
                                <TippyDropdownItemWithIcon
                                    icon={<SvgGroup />}
                                    text={t('workspaceActions.createGroup')}
                                    onClick={e => {
                                        const uid = v4();
                                        dispatch(
                                            putStructure({
                                                projectId: projectInfo.id!,
                                                type: ProjectStructureObjectTypes.GROUP,
                                                structureInfo: {
                                                    uid: uid,
                                                    parentUid: projectInfo.id!,
                                                    properties: {
                                                        name: t('defaultNames.group', { index: '1' }),
                                                        nestingLevel: '1',
                                                        order: String(getNextStructureOrder(structures)),
                                                        visible: true.toString(),
                                                        expanded: true.toString()
                                                    }
                                                }
                                            })
                                        );
                                    }}
                                />
                            )}
                            {owned && (
                                <TippyDropdownItemWithIcon
                                    icon={<SvgLink />}
                                    text={t('workspaceActions.linkDataset')}
                                    onClick={e => {
                                        setLinkModalOpen(open => !open);
                                    }}
                                />
                            )}
                            <TippyDropdownItemWithIcon
                                icon={<i className='icon icon-import' />}
                                text={owned ? t('workspaceActions.uploadDataset') : t('workspaceActions.uploadLayer')}
                                onClick={e => {
                                    switch (publishState) {
                                        case 'overdue':
                                            setShowPaymentOverdueModal(true);
                                            break;
                                        case 'storageFull':
                                            setShowStorageIsFullModal(true);
                                            break;
                                        default:
                                            dispatch(setDatasetsUploadModalOpen(true));
                                    }
                                }}
                            />
                        </div>
                    )}
                    delay={[100, 100]}
                    offset={[0, 6]}
                    interactive={true}
                    placement={'top'}
                    visible={isDropdownVisible}
                >
                    <button
                        type='button'
                        className='btn'
                        onClick={e => {
                            if (isOwnedEmptySite) setCreateProjectModalOpen(true);
                            else setDropdownVisible(!isDropdownVisible);
                        }}
                    >
                        {t('structureSidebar.add+')}
                    </button>
                </Tippy>
            </div>
            {showPaymentOverdueModal && (
                <PublishPaymentOverdueModal setIsOpen={setShowPaymentOverdueModal} isOpen={showPaymentOverdueModal} />
            )}
            {showStorageIsFullModal && (
                <PublishStorageIsFullModal setIsOpen={setShowStorageIsFullModal} isOpen={showStorageIsFullModal} />
            )}
            {isLinkModalOpen && <LinkModal isOpen={isLinkModalOpen} setIsOpen={setLinkModalOpen} />}
            {createProjectModalOpen && (
                <AddProjectModal
                    isOpen={createProjectModalOpen}
                    setIsOpen={setCreateProjectModalOpen}
                    projectType={ProjectType.NON_METASHAPE}
                />
            )}
        </>
    );
}
