import * as Cesium from 'cesium';

// CHECKME This is using cesium private properties. May break on update!
export default function () {
    // Modify polylines so that their depth test is always disabled.
    const oldPolylineUpdate = Cesium.PolylineCollection.prototype.update;
    // @ts-expect-error using private properties
    Cesium.PolylineCollection.prototype.update = function (frameState) {
        const oldMorphTime = frameState.morphTime;
        frameState.morphTime = 0.0;
        // @ts-expect-error using private properties
        oldPolylineUpdate.call(this, frameState);
        frameState.morphTime = oldMorphTime;
    };

    // Disabled to enable picking of the nearest limit box plane
    // // Modify polygons (and all other primitive objects) so that their depth test is always disabled.
    // const oldPrimitiveUpdate = Cesium.Primitive.prototype.update;
    // // @ts-expect-error
    // Cesium.Primitive.prototype.update = function (frameState) {
    //     (this.appearance as any)._renderState.depthTest.enabled = false;
    //     // @ts-expect-error
    //     oldPrimitiveUpdate.call(this, frameState);
    // };
}
