import { ReactComponent as SvgWaiting } from '../../../svg/notifications/waiting.svg';
import { ReactComponent as SvgClose } from '../../../svg/general/x-icon.svg';

type Props = {
    message: string;
    visible: boolean;
    close(): void;
};

export default function ProjectViewNotification({ message, visible, close }: Props) {
    if (!visible) return null;

    return (
        <div className='view-notification-placeholder'>
            <div className='view-notification'>
                <div className='notification-icon'>
                    <SvgWaiting />
                </div>
                <div className='notification-text'>{message}</div>
                <div className='notification-close' onClick={close}>
                    <SvgClose />
                </div>
            </div>
        </div>
    );
}
