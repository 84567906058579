import { immerable } from 'immer';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../../../i18n/config';
import { assertNever } from '../../../lib/assertNever';
import {
    isIssuePointGeometry,
    isViewpointGeometry,
    TemporaryGeometry,
    TemporaryLayer
} from '../../../store/helpers/interfaces';
import { GeometryTypes } from '../../../sharedConstants';

export enum UndoableOperationTypes {
    DELETE_LAYER,
    DELETE_POINT,
    DELETE_POLYLINE,
    DELETE_POLYGON,
    DELETE_ADDITIONAL_PROPERTY,
    DELETE_VIEWPOINT,
    DELETE_VOLUME,
    DELETE_INSPECTION,
    DELETE_ISSUE,
    DELETE_TOUR
}

export default class UndoableOperation {
    [immerable] = true;
    type: UndoableOperationTypes;
    undo: () => void;
    commit: () => void;
    id: string = uuidv4();

    constructor(type: UndoableOperationTypes, undo: () => void, commit: () => void) {
        this.type = type;
        this.undo = undo;
        this.commit = commit;
    }

    toString(): string {
        switch (this.type) {
            case UndoableOperationTypes.DELETE_LAYER:
                return i18n.t('projectView:undoableOperations.layerDeleted');
            case UndoableOperationTypes.DELETE_POINT:
                return i18n.t('projectView:undoableOperations.pointDeleted');
            case UndoableOperationTypes.DELETE_POLYLINE:
                return i18n.t('projectView:undoableOperations.polylineDeleted');
            case UndoableOperationTypes.DELETE_POLYGON:
                return i18n.t('projectView:undoableOperations.polygonDeleted');
            case UndoableOperationTypes.DELETE_ADDITIONAL_PROPERTY:
                return i18n.t('projectView:undoableOperations.propertyDeleted');
            case UndoableOperationTypes.DELETE_VIEWPOINT:
                return i18n.t('projectView:undoableOperations.viewpointDeleted');
            case UndoableOperationTypes.DELETE_VOLUME:
                return i18n.t('projectView:undoableOperations.volumeDeleted');
            case UndoableOperationTypes.DELETE_INSPECTION:
                return i18n.t('projectView:undoableOperations.inspectionDeleted');
            case UndoableOperationTypes.DELETE_ISSUE:
                return i18n.t('projectView:undoableOperations.issueDeleted');
            case UndoableOperationTypes.DELETE_TOUR:
                return i18n.t('projectView:undoableOperations.tourDeleted');
            default:
                return assertNever(this.type);
        }
    }

    static mapTypeToGeometryType(geometry: TemporaryGeometry): UndoableOperationTypes {
        const TYPES: Record<GeometryTypes, UndoableOperationTypes> = {
            [GeometryTypes.POINT]: UndoableOperationTypes.DELETE_POINT,
            [GeometryTypes.POLYLINE]: UndoableOperationTypes.DELETE_POLYLINE,
            [GeometryTypes.POLYGON]: UndoableOperationTypes.DELETE_POLYGON
        };

        if (isIssuePointGeometry(geometry)) return UndoableOperationTypes.DELETE_ISSUE;
        if (isViewpointGeometry(geometry)) return UndoableOperationTypes.DELETE_VIEWPOINT;
        return TYPES[geometry.content.geometry.type];
    }

    static mapTypeToLayerType(layer: TemporaryLayer): UndoableOperationTypes {
        if (layer.isInspection) return UndoableOperationTypes.DELETE_INSPECTION;
        if (layer.isPresentation) return UndoableOperationTypes.DELETE_TOUR;
        return UndoableOperationTypes.DELETE_LAYER;
    }
}
