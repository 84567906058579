import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import * as Cesium from 'cesium';
import { ApplicationState } from '..';
import appAxios from '../../api/appAxios';
import i18n, { SupportedLocales } from '../../i18n/config';
import { setBaseImageryProvider } from './projectView';

interface LayerInfo {
    id: string;
    type: 'ion' | 'url';
    name: string;
    creditHtml: string;
    accessToken: string;
    resource: Cesium.Resource.ConstructorOptions & { assetId?: number };
}

export interface BaseLayerInfo
    extends LayerInfo,
        Partial<
            Pick<Cesium.UrlTemplateImageryProvider, 'minimumLevel' | 'maximumLevel' | 'tileHeight' | 'tileWidth'>
        > {}

type TerrainLayerInfo = BaseLayerInfo;

interface InitState {
    faviconUrl: string;
    appleTouchIconUrl: string;
    safariPinnedTabUrl: string;
    manifestUrl: string;
    title: string;
    metaDescription: string;
    metaApplicationName: string;
    metaAppleMobileWebAppTitle: string;
    headerLogoUrl: string;
    headerBackgroundCss: string;
    defaultLanguage: 'en' | 'ru';
    isBillingActive: boolean;
    accountLink: string;
    supportLink: string;
    serviceProvider: string;
    baseLayers: BaseLayerInfo[];
    terrainLayers: TerrainLayerInfo[];
    fastspringAccessKey: string;
    fastspringStorefront: string;
    globalNotification:
        | {
              type: 'info' | 'alert';
              isVisible: boolean;
              text: Record<SupportedLocales, string>;
          }
        | undefined;
}

const initialState: InitState = {
    faviconUrl: '',
    appleTouchIconUrl: '',
    safariPinnedTabUrl: '',
    manifestUrl: '',
    title: '',
    metaDescription: '',
    metaApplicationName: '',
    metaAppleMobileWebAppTitle: '',
    headerBackgroundCss: '',
    headerLogoUrl: '',
    defaultLanguage: 'en',
    isBillingActive: false,
    serviceProvider: '',
    accountLink: '',
    supportLink: '',
    fastspringAccessKey: '',
    fastspringStorefront: '',
    baseLayers: [],
    terrainLayers: [],
    globalNotification: undefined
};

const name = 'init';

export const getInitialConfig = createAsyncThunk(`${name}`, async (_, { dispatch }) => {
    const url = import.meta.env.PROD ? '/configs/settings.json' : '/settings.json';
    const { data } = await appAxios.get(url);
    document.documentElement.style.setProperty('--header-background', data.headerBackgroundCss);
    i18n.changeLanguage(data.defaultLanguage); // TODO проверять наличие настройки языка
    dispatch(setBaseImageryProvider(data.baseLayers?.[0]?.id));
    return data as unknown as InitState;
});

const slice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getInitialConfig.fulfilled, (state, { payload }) => {
            return payload;
        });
    }
});

export default slice.reducer;

export const selectBaseLayers = createSelector(
    (state: ApplicationState) => state.init.baseLayers,
    baseLayers => baseLayers
);

export const selectIonBaseLayers = createSelector(selectBaseLayers, baseLayers =>
    baseLayers.filter(b => b.type === 'ion')
);

export const selectIonBaseLayersAssetIds = createSelector(selectIonBaseLayers, baseLayers =>
    baseLayers.map(l => String(l.resource.assetId))
);

export const selectUrlBaseLayers = createSelector(selectBaseLayers, baseLayers =>
    baseLayers.filter(b => b.type === 'url')
);

const selectTerrainLayers = createSelector(
    (state: ApplicationState) => state.init.terrainLayers,
    terrainLayers => terrainLayers
);

const selectIonTerrainLayers = createSelector(selectTerrainLayers, terrainLayers =>
    terrainLayers.filter(l => l.type === 'ion')
);

export const selectIonTerrainLayersResources = createSelector(selectIonTerrainLayers, ionTerrainLayers =>
    ionTerrainLayers.map(l => Cesium.IonResource.fromAssetId(l.resource.assetId!, { accessToken: l.accessToken }))
);

const selectUrlTerrainLayers = createSelector(selectTerrainLayers, terrainLayers =>
    terrainLayers.filter(l => l.type === 'url')
);

export const selectUrlTerrainLayersResources = createSelector(selectUrlTerrainLayers, urlTerrainLayers =>
    urlTerrainLayers.map(l => new Cesium.Resource(l.resource))
);

export const selectTerrainLayersCredits = createSelector(selectTerrainLayers, terrainLayers =>
    terrainLayers.map(l => l.creditHtml).join('<br/>')
);
