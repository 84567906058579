import * as Cesium from 'cesium';
import { useEffect, useMemo } from 'react';
import { useCesium } from 'resium';
import { PointOfView } from '../../../../store/helpers/interfaces';

type Props = {
    pov: PointOfView;
    duration?: number;
    onComplete(): void;
};

export default function CameraFlyToPointOfView({ pov, onComplete, duration }: Props) {
    const { camera } = useCesium();

    const cesiumCompatiblePOV: PointOfView<Cesium.Cartesian3> = useMemo(() => {
        if (pov.destination instanceof Cesium.Cartesian3) return pov;

        const povNumeric = pov as PointOfView<number>;
        return {
            destination: new Cesium.Cartesian3(...povNumeric.destination),
            orientation: {
                direction: new Cesium.Cartesian3(...povNumeric.orientation.direction),
                up: new Cesium.Cartesian3(...povNumeric.orientation.up)
            }
        };
    }, [pov]);

    useEffect(() => {
        camera?.flyTo({
            ...cesiumCompatiblePOV,
            duration: duration ?? 1,
            complete: onComplete
        });
    }, [cesiumCompatiblePOV, camera, onComplete, duration]);

    return null;
}
