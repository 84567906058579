import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { emailSubscriptionApi, subscriptionApi } from '../../api/initApis';
import _ from 'lodash';
import { getPersonalInfo, updatePersonalInfo } from './user';
import { EventType, SubscriptionInfo } from '../../generated/account-frontend-api';
import { SubscriptionsRequest } from '../../generated/subscription-api';

interface SubscriptionsState {
    list: Array<SubscriptionInfo>;
}

const initialState: SubscriptionsState = {
    list: []
};

const name = 'subscriptions';

interface GetSubscriptionsArgs {
    accountId: string;
    hash: string;
}

export const getSubscriptions = createAsyncThunk(`${name}/get`, async (arg?: GetSubscriptionsArgs) => {
    if (arg?.accountId && arg.hash)
        return (await emailSubscriptionApi.getEmailSubscriptions(arg.accountId, arg.hash)).data;
    return (await subscriptionApi.getSubscriptions()).data;
});

export const updateSubscriptions = createAsyncThunk(
    `${name}/update`,
    async ({ subscriptions }: SubscriptionsRequest, { dispatch }) => {
        const { data } = await subscriptionApi.subscribe({ subscriptions });
        dispatch(setSubscriptions(subscriptions!.filter(s => s.enabled).map(s => _.pick(s, ['type', 'event']))));
        return data;
    }
);

interface UnsubscribeArgs extends GetSubscriptionsArgs {
    types: Array<EventType>;
}

export const unsubscribe = createAsyncThunk(
    `${name}/unsubscribe`,
    async ({ accountId, hash, types }: UnsubscribeArgs) => {
        const { data } = await emailSubscriptionApi.deleteEmailSubscriptions(accountId, hash, types);
        return data;
    }
);

const emailSubscriptionsSlice = createSlice({
    name,
    initialState,
    reducers: {
        setSubscriptions(state, action: PayloadAction<Array<SubscriptionInfo>>) {
            state.list = action.payload;
        }
    },
    extraReducers: builder =>
        builder
            .addCase(getSubscriptions.fulfilled, (state, { payload }) => {
                state.list = payload.subscriptions || [];
            })
            .addCase(getPersonalInfo.fulfilled, (state, { payload }) => {
                state.list = payload.subscriptions?.subscriptions || [];
            })
            .addCase(updatePersonalInfo.fulfilled, (state, { payload }) => {
                if (payload.subscriptions?.subscriptions) state.list = payload.subscriptions.subscriptions;
            })
});

export const { setSubscriptions } = emailSubscriptionsSlice.actions;

export default emailSubscriptionsSlice.reducer;
