import * as Cesium from 'cesium';
import React from 'react';
import { useDispatch } from 'react-redux';
import { CesiumMovementEvent, useCesium } from 'resium';
import { GeometryTypes } from '../../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../../store';
import {
    isPointGeometry,
    isPolygonGeometry,
    isPolylineGeometry,
    TemporaryGeometry
} from '../../../../store/helpers/interfaces';
import PointPrimitives from './PointPrimitives';
import PolygonPrimitive from './PolygonPrimitive';
import PolylinePrimitive from './PolylinePrimitive';
import { colord } from 'colord';
import { adjustAlpha } from '../../../../lib/adjustAlpha';
import useAreSceneMouseEventsBlocked from '../../../../hooks/useAreSceneMouseEventsBlocked';

type Props = {
    hasRendered: Record<GeometryTypes, boolean>;
    setPrimitiveHasRendered: React.Dispatch<React.SetStateAction<Record<GeometryTypes, boolean>>>;
    geometries: TemporaryGeometry[];
    layerVisible: boolean;
};

function GeometryPrimitives({ geometries, hasRendered, setPrimitiveHasRendered, layerVisible }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { scene } = useCesium();
    const areMouseEventsBlocked = useAreSceneMouseEventsBlocked();

    function updateColor(target: Target, color: string) {
        const attributes = target.primitive.getGeometryInstanceAttributes(target.id);
        attributes.color = Cesium.ColorGeometryInstanceAttribute.toValue(Cesium.Color.fromCssColorString(color));
        scene?.requestRender();
    }

    function onMouseEnter(
        movement: CesiumMovementEvent,
        target: Target,
        colorProperty: 'ac_color' | 'ac_stroke_color' = 'ac_color'
    ) {
        if (areMouseEventsBlocked) return;

        const geometry = geometries.find(g => g.id === target.id);
        if (geometry) {
            const colorString = (geometry.content.properties[colorProperty] ||
                geometry.content.properties['ac_color']) as string;
            const color =
                isPolygonGeometry(geometry) && colorProperty === 'ac_color'
                    ? colord(colorString)
                    : adjustAlpha(colord(colorString));
            const brighterColor = color.lighten(0.1);
            updateColor(target, brighterColor.toHex());
        }
    }

    function onMouseLeave(
        movement: CesiumMovementEvent,
        target: Target,
        colorProperty: 'ac_color' | 'ac_stroke_color' = 'ac_color'
    ) {
        if (areMouseEventsBlocked) return;

        const geometry = geometries.find(g => g.id === target.id);
        if (geometry) {
            const colorString = (geometry.content.properties[colorProperty] ||
                geometry.content.properties['ac_color']) as string;
            const color =
                isPolygonGeometry(geometry) && colorProperty === 'ac_color'
                    ? colord(colorString)
                    : adjustAlpha(colord(colorString));
            updateColor(target, color.toHex());
        }
    }

    return (
        <>
            <PointPrimitives geometries={geometries.filter(isPointGeometry)} layerVisible={layerVisible} />
            <PolylinePrimitive
                layerVisible={layerVisible}
                hasPrimitiveRendered={hasRendered[GeometryTypes.POLYLINE]}
                setPrimitiveHasRendered={setPrimitiveHasRendered}
                geometries={geometries.filter(isPolylineGeometry)}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            />
            <PolygonPrimitive
                layerVisible={layerVisible}
                hasPrimitiveRendered={hasRendered[GeometryTypes.POLYGON]}
                setPrimitiveHasRendered={setPrimitiveHasRendered}
                geometries={geometries.filter(isPolygonGeometry)}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            />
        </>
    );
}

export default GeometryPrimitives;

interface Target {
    id: string;
    primitive: Cesium.Primitive;
}

export interface GeometryPrimitiveProps<T extends GeometryTypes> {
    setPrimitiveHasRendered: React.Dispatch<React.SetStateAction<Record<GeometryTypes, boolean>>>;
    layerVisible: boolean;
    hasPrimitiveRendered: boolean;
    geometries: TemporaryGeometry<T>[];
    onMouseEnter(movement: CesiumMovementEvent, target: Target, colorProperty?: 'ac_color' | 'ac_stroke_color'): void;
    onMouseLeave(movement: CesiumMovementEvent, target: Target, colorProperty?: 'ac_color' | 'ac_stroke_color'): void;
}
