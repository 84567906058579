/* tslint:disable */
/* eslint-disable */
/**
 * subscription-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EventType } from '../model';
// @ts-ignore
import { Subscriptions } from '../model';
/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete specified types of client\'s email subscriptions
         * @param {string} accountUid Uid of account
         * @param {string} hash Hash to validate authority
         * @param {Array<EventType>} type Event types of subscriptions to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmailSubscriptions: async (accountUid: string, hash: string, type: Array<EventType>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountUid' is not null or undefined
            assertParamExists('deleteEmailSubscriptions', 'accountUid', accountUid)
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('deleteEmailSubscriptions', 'hash', hash)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('deleteEmailSubscriptions', 'type', type)
            const localVarPath = `/subscriptions/email/unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountUid !== undefined) {
                localVarQueryParameter['account_uid'] = accountUid;
            }

            if (hash !== undefined) {
                localVarQueryParameter['hash'] = hash;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get client\'s email subscriptions
         * @param {string} accountUid Uid of account
         * @param {string} hash Hash to validate authority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailSubscriptions: async (accountUid: string, hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountUid' is not null or undefined
            assertParamExists('getEmailSubscriptions', 'accountUid', accountUid)
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('getEmailSubscriptions', 'hash', hash)
            const localVarPath = `/subscriptions/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountUid !== undefined) {
                localVarQueryParameter['account_uid'] = accountUid;
            }

            if (hash !== undefined) {
                localVarQueryParameter['hash'] = hash;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete specified types of client\'s email subscriptions
         * @param {string} accountUid Uid of account
         * @param {string} hash Hash to validate authority
         * @param {Array<EventType>} type Event types of subscriptions to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEmailSubscriptions(accountUid: string, hash: string, type: Array<EventType>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEmailSubscriptions(accountUid, hash, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get client\'s email subscriptions
         * @param {string} accountUid Uid of account
         * @param {string} hash Hash to validate authority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmailSubscriptions(accountUid: string, hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscriptions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmailSubscriptions(accountUid, hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete specified types of client\'s email subscriptions
         * @param {string} accountUid Uid of account
         * @param {string} hash Hash to validate authority
         * @param {Array<EventType>} type Event types of subscriptions to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmailSubscriptions(accountUid: string, hash: string, type: Array<EventType>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEmailSubscriptions(accountUid, hash, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get client\'s email subscriptions
         * @param {string} accountUid Uid of account
         * @param {string} hash Hash to validate authority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmailSubscriptions(accountUid: string, hash: string, options?: any): AxiosPromise<Subscriptions> {
            return localVarFp.getEmailSubscriptions(accountUid, hash, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
    /**
     * 
     * @summary Delete specified types of client\'s email subscriptions
     * @param {string} accountUid Uid of account
     * @param {string} hash Hash to validate authority
     * @param {Array<EventType>} type Event types of subscriptions to delete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public deleteEmailSubscriptions(accountUid: string, hash: string, type: Array<EventType>, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).deleteEmailSubscriptions(accountUid, hash, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get client\'s email subscriptions
     * @param {string} accountUid Uid of account
     * @param {string} hash Hash to validate authority
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public getEmailSubscriptions(accountUid: string, hash: string, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).getEmailSubscriptions(accountUid, hash, options).then((request) => request(this.axios, this.basePath));
    }
}
