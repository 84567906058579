import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { ApplicationState } from '..';
import { Dataset, ProjectPartType, SourceType, Status } from '../../generated/cloud-frontend-api';
import { DatasetInfo } from '../../generated/dataset-api';
import getArtifactDisplayName from '../../lib/getArtifactDisplayName';
import { upsertConditionally } from '../../lib/upsert';
import { ExtendedChunk } from '../helpers/interfaces';
import {
    cancelDatasetUpload,
    createDataset,
    deleteDataset,
    ExtendedDatasetInfo,
    getDataset,
    renameDataset,
    unlinkDataset,
    unlinkDatasetFromParentProject,
    uploadDatasetFile
} from './datasetfilesUpload';
import { getSortedDatasets, selectDatasets } from './datasetsUpload';
import { ExtendedStructureInfo } from './structure';

interface DatasetFilesUploadState {
    datasets: ExtendedDatasetInfo[];
}

const initialState: DatasetFilesUploadState = {
    datasets: []
};

const name = 'datasets';

const slice = createSlice({
    name,
    initialState,
    reducers: {
        setDatasets(state, { payload }: PayloadAction<DatasetInfo[]>) {
            state.datasets = payload;
            state.datasets.forEach(d => {
                if (d.projectPartType === ProjectPartType.DATASETS && d.sourceData?.type === SourceType.DEM) {
                    d.sourceData.type = SourceType.DEM_3_D;
                }
                if (d.projectPartType === ProjectPartType.TILESETS && !d.name) {
                    d.name = getArtifactDisplayName(d);
                }
            });
        },
        datasetSourceDataStatusChanged(
            state,
            {
                payload
            }: PayloadAction<{ datasetId: string; status: NonNullable<ExtendedDatasetInfo['sourceData']>['status'] }>
        ) {
            const dataset = state.datasets.find(d => d.datasetUid === payload.datasetId);
            if (dataset) dataset.sourceData!.status = payload.status;
        },
        datasetInfoUpdated(state, { payload }: PayloadAction<ExtendedDatasetInfo>) {
            upsertConditionally(state.datasets, payload, el => el.datasetUid === payload.datasetUid, true);
        },
        uploadPercentChanged(state, { payload }: PayloadAction<{ id: string; percent: number }>) {
            const dataset = state.datasets.find(d => d.datasetUid === payload.id);
            if (dataset) dataset.uploadPercent = payload.percent;
        },
        setDatasetProperty(
            state,
            {
                payload
            }: PayloadAction<{
                id: string;
                propName: keyof ExtendedDatasetInfo;
                value: ExtendedDatasetInfo[keyof ExtendedDatasetInfo];
            }>
        ) {
            const dataset = state.datasets.find(d => d.datasetUid === payload.id);
            if (dataset) (dataset[payload.propName] as any) = payload.value;
        },
        removeDataset(state, { payload }: PayloadAction<{ datasetUid: string }>) {
            const index = state.datasets.findIndex(d => d.datasetUid === payload.datasetUid);
            if (index !== -1) state.datasets.splice(index, 1);
        }
    },
    extraReducers: builder => {
        builder
            .addCase(uploadDatasetFile.fulfilled, (state, { payload }) => {
                upsertConditionally(state.datasets, payload, el => el.datasetUid === payload.datasetUid, true);
            })
            .addCase(uploadDatasetFile.rejected, (state, { payload }) => {
                if (payload) {
                    const dataset = state.datasets.find(d => d.datasetUid === payload.id);
                    if (dataset) {
                        dataset.sourceData!.status = 'uploadError';
                        dataset.sourceData!.lastError = payload.errorMessage;
                    }
                }
            })
            .addCase(deleteDataset.fulfilled, (state, { meta }) => {
                const index = state.datasets.findIndex(d => d.datasetUid === meta.arg.datasetUid);
                if (index !== -1) state.datasets.splice(index, 1);
            })
            .addCase(getDataset.fulfilled, (state, { payload }) => {
                const existingDataset = state.datasets.find(d => d.datasetUid === payload.datasetUid);
                const dataset = payload as ExtendedDatasetInfo;
                if (existingDataset === undefined) {
                    dataset.assetUid = dataset.datasetUid;
                    if (dataset.sourceData?.type === SourceType.DEM) dataset.sourceData.type = SourceType.DEM_3_D;

                    if ('parentProjectUid' in dataset) {
                        dataset.parentProject = { uid: dataset.parentProjectUid as string };
                    }
                } else {
                    dataset.assetUid = existingDataset.assetUid;
                    dataset.sizeInBytes = existingDataset.sizeInBytes;
                    dataset.parentProject = existingDataset.parentProject;
                    dataset.linkedProjects = existingDataset.linkedProjects;
                    if (dataset.sourceData?.type === SourceType.DEM) dataset.sourceData.type = SourceType.DEM_3_D;
                }
                if (dataset.projectPartType === ProjectPartType.TILESETS && !dataset.name) {
                    dataset.name = getArtifactDisplayName(dataset);
                }
                upsertConditionally(state.datasets, dataset, el => el.datasetUid === dataset.datasetUid);
            })
            .addCase(cancelDatasetUpload.fulfilled, (state, { meta }) => {
                const index = state.datasets.findIndex(d => d.datasetUid === meta.arg.datasetUid);
                if (index !== -1) state.datasets.splice(index, 1);
            })
            .addCase(renameDataset.pending, (state, { meta }) => {
                const { name, datasetUid } = meta.arg;
                const dataset = state.datasets.find(d => d.datasetUid === datasetUid);
                if (dataset) {
                    const extension = dataset.name!.split('.').pop();
                    dataset.name = name + '.' + extension;
                }
            })
            .addCase(unlinkDataset.fulfilled, (state, { meta }) => {
                const index = state.datasets.findIndex(d => d.datasetUid === meta.arg.datasetId);
                if (index !== -1) state.datasets.splice(index, 1);
            })
            .addCase(createDataset.fulfilled, (state, { payload, meta }) => {
                const { temporaryId } = meta.arg;
                if (temporaryId) {
                    const dataset = state.datasets.find(d => d.datasetUid === temporaryId);
                    if (dataset) {
                        dataset.datasetUid = payload;
                    }
                }
            });
    }
});

export const {
    datasetInfoUpdated,
    setDatasets,
    uploadPercentChanged,
    setDatasetProperty,
    datasetSourceDataStatusChanged,
    removeDataset
} = slice.actions;

export default slice.reducer;

export function getGroupDatasets(
    datasets: ExtendedDatasetInfo[],
    structures: ExtendedStructureInfo[],
    groupId: string
) {
    return datasets.filter(d => structures.find(s => s.uid === d.datasetUid)?.parentUid === groupId);
}
export const selectGroupDatasets = createSelector(
    (state: ApplicationState) => state.datasets.datasets,
    (state: ApplicationState) => state.structure.structures,
    (state: ApplicationState) => state.project,
    (state: ApplicationState) => state.datasetsUpload.uploads,
    (state: ApplicationState, groupId: string) => groupId,
    (datasets, structures, { structure, projectInfo }, uploads, groupId) =>
        getGroupDatasets(
            getSortedDatasets(
                structure.temporaryLayers,
                uploads,
                datasets,
                structures,
                projectInfo.id!,
                structure.chunks
            ),
            structures,
            groupId
        )
);

export function getGroupedDatasets(datasets: ExtendedDatasetInfo[], structures: ExtendedStructureInfo[]) {
    const groups = structures.filter(s => s.properties?.nestingLevel === '1');
    return datasets.filter(d =>
        groups.map(g => g.uid).includes(structures.find(s => s.uid === d.datasetUid)?.parentUid)
    );
}

export const isOwnOrLinkedDataset = (projectId: string, chunks: ExtendedChunk[]) => (d: Dataset) => {
    const isArtifact = chunks.some(c => c.artifacts.find(a => a.datasetUid === d.datasetUid));
    return (
        d.projectPartType === ProjectPartType.DATASETS ||
        (d.projectPartType === ProjectPartType.TILESETS && !isArtifact && isLinkedDataset(d, projectId))
    );
};

export function isLinkedDataset(dataset: Dataset | undefined, projectId: string) {
    if (!dataset) return false;
    // In shared/embed view parentProject data is unavailable
    if (!dataset.parentProject?.uid && dataset.parentProject === null) return true;

    if (!dataset.parentProject?.uid) return false;
    return dataset.parentProject?.uid !== projectId;
}

export function isVisualDataAbsent(dataset: Dataset): boolean {
    return dataset.visualData?.status === Status.ABSENT && !dataset.visualData?.lastError;
}

export const selectUploadingDatasets = createSelector(
    (state: ApplicationState) => state.datasets.datasets,
    datasets => datasets.filter(d => !d.visualData)
);

export const selectPendingDatasets = createSelector(
    (state: ApplicationState) => selectDatasets(state),
    datasets => datasets.filter(d => d.sourceData?.status === 'pending')
);

export function getDatasetStatus(
    dataset: ExtendedDatasetInfo
): Status | NonNullable<ExtendedDatasetInfo['sourceData']>['status'] {
    if (dataset.sourceData?.status) {
        return dataset.sourceData.status;
    } else {
        if (!dataset.visualData) return Status.IN_PROGRESS; // means uploading
        return dataset.visualData.status;
    }
}

export function isDatasetInfo(object: any): object is ExtendedDatasetInfo {
    return object?.datasetUid && object?.projectPartType !== ProjectPartType.VECTOR_LAYERS;
}
