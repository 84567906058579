import classNames from 'classnames';
import { SurfaceAltitudes } from '../../../store/helpers/elevation-profile/ElevationProfile';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/mousewheel';

type Props = {
    surfaceAltitudes: SurfaceAltitudes[];
    isLegendHidden?: boolean;
};

export default function ElevationProfileLegend({ surfaceAltitudes, isLegendHidden }: Props) {
    const hideLegendBorders = surfaceAltitudes.length === 0;

    if (isLegendHidden) return <div className='inspector-profile-legend hideBorders' />;

    return (
        <div className={classNames('inspector-profile-legend', { hideBorders: hideLegendBorders })}>
            <Swiper
                className='legend-list'
                spaceBetween={16}
                modules={[Mousewheel]}
                mousewheel={{ sensitivity: 2.5 }}
                slidesPerView='auto'
                grabCursor={true}
            >
                {surfaceAltitudes.map((item, index) => (
                    <SwiperSlide className='legend-item' key={index}>
                        <div className='legend-item-color' style={{ backgroundColor: item.color }} />
                        <div className='legend-item-text'>{item.name}</div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}
