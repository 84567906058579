import _ from 'lodash';
import React from 'react';
import { ReactComponent as SvgSearch } from '../../../svg/general/search.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
    disabled?: boolean;
    value: string;
    wrapperClass?: string;
    onChange(newValue: string): void;
    reset(): void;
};

export default function Search({ disabled, value, onChange, wrapperClass = 'search-form-wrapper', reset }: Props) {
    const { t } = useTranslation();
    const throttledOnChange = _.debounce(onChange, 200, { leading: true });

    return (
        <div className={classNames(wrapperClass)}>
            <form data-testid={'searchForm'} className='search-form'>
                <input
                    className='search-input'
                    type='text'
                    value={value}
                    placeholder={t('search.placeholder')}
                    onKeyDown={event => {
                        if (event.key === 'Enter') event.preventDefault();
                    }}
                    onChange={event => {
                        throttledOnChange(event.target.value);
                    }}
                    disabled={disabled}
                />
                <div className='search-icon'>
                    <SvgSearch />
                </div>
                {!!value && (
                    <button
                        className='search-reset'
                        type='reset'
                        disabled={disabled}
                        onClick={e => {
                            e.preventDefault();
                            reset();
                        }}
                    />
                )}
            </form>
        </div>
    );
}
