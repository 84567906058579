import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector as reduxUseSelector } from 'react-redux';
import { enableBatching } from 'redux-batched-actions';
import { createLogger } from 'redux-logger';
import { processingApi } from '../services/processing';
import loading from './reducers/loadingReducer';
import accountResources from './slices/accountResources';
import billing from './slices/billing';
import cameras from './slices/cameras';
import compareTool from './slices/compareTool';
import coordinateSystems from './slices/coordinateSystems';
import datasets from './slices/datasets';
import datasetsUpload from './slices/datasetsUpload';
import elevationProfiles from './slices/elevationProfiles';
import emailSubscriptions from './slices/emailSubscriptions';
import geometries from './slices/geometries';
import globalFlags from './slices/globalFlags';
import init from './slices/init';
import invoices from './slices/invoices';
import presentation from './slices/presentation';
import progress from './slices/progress';
import project from './slices/project';
import projectFiles from './slices/projectStructure';
import projectView, { setFloatingPointCoordinates } from './slices/projectView';
import projectsPage from './slices/projectsPage';
import rulerTool from './slices/rulerTool';
import sharingReducer from './slices/sharing';
import site from './slices/site';
import structure from './slices/structure';
import user from './slices/user';
import volume from './slices/volume';
import folder from './slices/folder';
import { PartialDeep } from 'type-fest';

const rootReducer = combineReducers({
    accountResources,
    billing,
    cameras,
    presentation,
    structure,
    datasetsUpload,
    projectFiles,
    datasets,
    volume,
    elevationProfiles,
    rulerTool,
    compareTool,
    coordinateSystems,
    emailSubscriptions,
    geometries,
    globalFlags,
    invoices,
    loading,
    progress,
    project,
    projectsPage,
    projectView,
    site,
    sharing: sharingReducer,
    user,
    init,
    folder,
    [processingApi.reducerPath]: processingApi.reducer
});

export type ApplicationState = ReturnType<typeof rootReducer>;

export const setupStore = (preloadedState?: PartialDeep<ApplicationState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState: preloadedState as Partial<ApplicationState>,
        middleware: getDefaultMiddleware => {
            const middleware = getDefaultMiddleware({ serializableCheck: false });
            return middleware.concat(processingApi.middleware);
        }
    });
};

export type ApplicationStore = ReturnType<typeof setupStore>;

export type AppDispatch = typeof store.dispatch;

export const useSelector: TypedUseSelectorHook<ApplicationState> = reduxUseSelector;

const store = configureStore({
    reducer: enableBatching(rootReducer),
    middleware: getDefaultMiddleware => {
        const middleware = getDefaultMiddleware({ serializableCheck: false });
        if (import.meta.env.DEV) {
            middleware.push(
                createLogger({
                    duration: true,
                    collapsed: true,
                    predicate(getState: () => any, action: any) {
                        return action.type !== setFloatingPointCoordinates.type && !action.type.includes('internal_');
                    }
                })
            );
        }
        return middleware.concat(processingApi.middleware);
    }
});

export default store;
