type Props = {
    showName: boolean;
    projectName: string | undefined;
};

export default function EmbedProjectInfo({ showName, projectName }: Props) {
    if (!projectName) return null;

    return (
        <div className='embed-project-info'>
            <div className='embed-title'>{showName && <div className='project-info'>{projectName}</div>}</div>
        </div>
    );
}
