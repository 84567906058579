import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import CompareStructureTreeContext from '../../../contexts/CompareStructureTreeContext';
import { SourceType } from '../../../generated/cloud-frontend-api';
import { GeometryTypes } from '../../../sharedConstants';
import { useSelector } from '../../../store';
import {
    ProjectStructureObjectTypes,
    TemporaryGeometry,
    TemporaryLayer,
    isGeometry,
    isIssuePointGeometry,
    isViewpointGeometry
} from '../../../store/helpers/interfaces';
import { selectStructureItemInfoByCompareTreeId } from '../../../store/selectors';
import { ExtendedDatasetInfo } from '../../../store/slices/datasetfilesUpload';
import { makeSelectLayerByGeometryId } from '../../../store/slices/geometryLayers';

type Props = {
    className?: string;
    structureItemId: string;
    structureItemType: ProjectStructureObjectTypes;
};

export default function StructureItemIcon({
    className = 'artefact-icon',
    structureItemType: type,
    structureItemId: id,
    ...props
}: Props) {
    const compareContext = useContext(CompareStructureTreeContext);
    const selectLayerByGeometryId = useMemo(makeSelectLayerByGeometryId, []);
    const layerOfSelectedStructureItem = useSelector(state => selectLayerByGeometryId(state, id));
    const structureItemInfo = useSelector(state =>
        selectStructureItemInfoByCompareTreeId(state, compareContext?.treeId || '', { id, type })
    );

    return <i className={classNames(className, 'icon', getIconClass())} data-testid={'icon'} {...props} />;

    function getIconClass(): string {
        if (type === ProjectStructureObjectTypes.GROUP) return 'icon-group';
        if (type === ProjectStructureObjectTypes.LAYER) {
            const isInspection = (structureItemInfo as TemporaryLayer).isInspection;
            const isTour = (structureItemInfo as TemporaryLayer).isPresentation;
            if (isInspection) return 'icon-inspector';
            if (isTour) return 'icon-presentation';

            const isTemporary = (structureItemInfo as TemporaryLayer).isTemporary;
            return classNames({ 'icon-layer': !isTemporary, 'icon-temp': isTemporary });
        }
        if (type === ProjectStructureObjectTypes.DATASET || type === ProjectStructureObjectTypes.ARTIFACT) {
            const sourceType = (structureItemInfo as ExtendedDatasetInfo)?.sourceData?.type!;
            return getSourceTypeIconClass(sourceType);
        }
        if (type === ProjectStructureObjectTypes.GEOMETRY && isGeometry(structureItemInfo)) {
            const isParentLayerInspection = layerOfSelectedStructureItem?.isInspection;
            const isParentLayerTour = layerOfSelectedStructureItem?.isPresentation;
            const isIssue = isParentLayerInspection && isIssuePointGeometry(structureItemInfo);
            const isViewpoint = isParentLayerTour && isViewpointGeometry(structureItemInfo);

            if (isIssue) return 'icon-issue';
            if (isViewpoint) return 'icon-slide';
            return getGeometryTypeIconClass(structureItemInfo.content.geometry.type);
        }
        if (type === ProjectStructureObjectTypes.CAMERAS) {
            return 'icon-camera';
        }
        if (type === ProjectStructureObjectTypes.IMAGE) {
            return 'icon-image';
        }
        if (type === ProjectStructureObjectTypes.CHUNK) {
            return 'icon-chunk';
        }

        return '';
    }

    function getSourceTypeIconClass(sourceType: SourceType): string {
        switch (sourceType) {
            case SourceType.POINT_CLOUD:
                return 'icon-dense_cloud';
            case SourceType.TIE_POINTS:
                return 'icon-tie_points';
            case SourceType.TILED_MODEL:
            case SourceType.BLOCK_MODEL:
                return 'icon-tiled_model';
            case SourceType.RASTER_MAP:
                return 'icon-orthomosaic';
            case SourceType.DEM:
                return 'icon-elevation';
            case SourceType.DEM_3_D:
                return 'icon-elevation';
            case SourceType.MODEL_3_D:
                return 'icon-3d_model';
            default:
                return '';
        }
    }

    function getGeometryTypeIconClass(geometryType: GeometryTypes): string {
        switch (geometryType) {
            case GeometryTypes.POINT:
                return 'icon-point';
            case GeometryTypes.POLYLINE:
                return 'icon-polyline';
            case GeometryTypes.POLYGON:
                return 'icon-polygon';
            default:
                return '';
        }
    }
}
