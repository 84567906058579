/* tslint:disable */
/* eslint-disable */
/**
 * dataset-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const SourceType = {
    TIE_POINTS: 'TIE_POINTS',
    POINT_CLOUD: 'POINT_CLOUD',
    TILED_MODEL: 'TILED_MODEL',
    RASTER_MAP: 'RASTER_MAP',
    DEM: 'DEM',
    DEM_3_D: 'DEM_3D',
    MODEL_3_D: 'MODEL_3D',
    GEOJSON: 'GEOJSON',
    BLOCK_MODEL: 'BLOCK_MODEL'
} as const;

export type SourceType = typeof SourceType[keyof typeof SourceType];



