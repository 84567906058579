import produce from 'immer';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'rooks';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { GeometryTypes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import {
    GeoJson,
    TemporaryGeometry,
    isPointGeometry,
    isPolygonGeometry,
    isPolylineGeometry
} from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { isGeometryPropertiesBlockExpanded, updateGeometryContent } from '../../../store/slices/geometries';
import { updateGeometry, updateGeometryPropertyById } from '../../../store/slices/geometryLayers';
import ColorPickerProperty from './ColorPickerProperty';
import CopyPropertiesControl from './elements/CopyPropertiesControl';
import PropertiesBlockHead from './elements/PropertiesBlockHead';
import { assertNever } from '../../../lib/assertNever';

type Props = {
    viewOnly: boolean;
};

export default function StyleProperties({ viewOnly }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const { owned } = useContext(ProjectViewAccessContext);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));
    const projectInfo = useSelector(state => state.project.projectInfo);
    const temporaryLayers = useSelector(state => state.project.structure.temporaryLayers);
    const geometry = selectedObjectInfo as TemporaryGeometry;
    const color = geometry.content.properties.ac_color;
    const geometryType = geometry.content.geometry.type;
    const polygonStrokeColor = isPolygonGeometry(geometry)
        ? geometry.content.properties.ac_stroke_color || geometry.content.properties.ac_color
        : '';
    const textForCopying = (() => {
        if (geometryType === GeometryTypes.POINT) return `${t('geometryProperties.fill')}\t${color.toUpperCase()}`;
        if (geometryType === GeometryTypes.POLYLINE) return `${t('geometryProperties.stroke')}\t${color.toUpperCase()}`;
        if (geometryType === GeometryTypes.POLYGON) {
            return `${t('geometryProperties.fill')}\t${color.toUpperCase()}\n${t(
                'geometryProperties.stroke'
            )}\t${polygonStrokeColor.toUpperCase()}`;
        }
        assertNever(geometryType);
    })();

    const styleBlockExpanded = isGeometryPropertiesBlockExpanded(geometry.content, 'ac_style_block_expanded');

    const saveColor = useDebounce((geoJson: GeoJson) => {
        const temporaryLayer = temporaryLayers.find(l => l.geometries.includes(geometry.id));
        dispatch(
            updateGeometry({ projectUid: projectInfo.id!, layerUid: temporaryLayer?.id!, id: geometry.id, geoJson })
        );
    }, 350);

    function updateGeometryColor(newColor: string, propertyName: 'ac_color' | 'ac_stroke_color' = 'ac_color') {
        const geoJson = produce(geometry.content, draft => {
            draft.properties[propertyName] = newColor;
        });
        if (owned) saveColor(geoJson);
        dispatch(updateGeometryContent({ id: geometry.id, geoJson }));
    }

    return (
        <div className='inspector-properties'>
            <PropertiesBlockHead
                isBlockExpanded={styleBlockExpanded}
                title={t('inspectionSidebar.geometryProperties.titleStyle')}
                onClick={() => {
                    dispatch(
                        updateGeometryPropertyById({
                            id: geometry.id,
                            owned,
                            propName: 'ac_style_block_expanded',
                            propValue: !styleBlockExpanded
                        })
                    );
                }}
            >
                <CopyPropertiesControl clipboardText={textForCopying} />
            </PropertiesBlockHead>
            {styleBlockExpanded && (
                <div className='properties-list'>
                    {isPointGeometry(geometry) && (
                        <ColorPickerProperty
                            viewOnly={viewOnly}
                            color={color}
                            label={t('geometryProperties.fill')}
                            onChange={newColor => {
                                updateGeometryColor(newColor);
                            }}
                        />
                    )}

                    {isPolylineGeometry(geometry) && (
                        <ColorPickerProperty
                            viewOnly={viewOnly}
                            color={color}
                            label={t('geometryProperties.stroke')}
                            onChange={newColor => {
                                updateGeometryColor(newColor);
                            }}
                        />
                    )}

                    {isPolygonGeometry(geometry) && (
                        <>
                            <ColorPickerProperty
                                viewOnly={viewOnly}
                                color={color}
                                label={t('geometryProperties.fill')}
                                onChange={newColor => {
                                    updateGeometryColor(newColor);
                                }}
                            />
                            <ColorPickerProperty
                                viewOnly={viewOnly}
                                color={polygonStrokeColor}
                                label={t('geometryProperties.stroke')}
                                onChange={newColor => {
                                    updateGeometryColor(newColor, 'ac_stroke_color');
                                }}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
