import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Parent, Project } from '../../generated/cloud-frontend-api';
import { isAxiosError } from 'axios';
import { generatePath } from 'react-router-dom';
import { projectsApi } from '../../api/initApis';
import { Routes } from '../../sharedConstants';
import { moveProject, renameProject } from './projectsPage';

type FolderState = Omit<Project, 'parent'> & { parent: Parent | null };

const initialState: FolderState = {
    parent: {},
    projectInfo: {},
    structure: {}
};

const name = 'folder';

interface GetFolderArgs {
    id: string;
}
export const getFolder = createAsyncThunk<Project, GetFolderArgs, { rejectValue: { status: number } }>(
    `${name}/get`,
    ({ id }, { rejectWithValue }) => {
        return projectsApi
            .getProjectById(id)
            .then(({ data }) => data)
            .catch(err => {
                if (isAxiosError(err)) {
                    if (err.response?.status === 404 || err.response?.status === 410) {
                        return rejectWithValue({ status: err.response.status });
                    }
                }
                throw err;
            });
    }
);

const slice = createSlice({
    name,
    initialState,
    reducers: {
        resetFolderState(state) {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getFolder.fulfilled, (state, { payload }) => {
                return payload as FolderState;
            })
            .addCase(renameProject.pending, (state, { meta }) => {
                if (state.projectInfo?.id === meta.arg.projectId) {
                    state.projectInfo.name = meta.arg.name;
                }
            });
    }
});

export default slice.reducer;

export const { resetFolderState } = slice.actions;
