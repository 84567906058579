import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Issue } from '../../../entities/Issue';
import { GeometryTypes } from '../../../sharedConstants';
import { useSelector } from '../../../store';
import { TemporaryGeometry } from '../../../store/helpers/interfaces';
import { makeSelectLayerGeometries } from '../../../store/slices/geometries';
import { makeSelectLayerByGeometryId } from '../../../store/slices/geometryLayers';

type Props = {
    issue: TemporaryGeometry<GeometryTypes.POINT>;
};

export default function IssuePhotoInfo({ issue }: Props) {
    const { t } = useTranslation('projectView');
    const selectInspectionLayer = useMemo(makeSelectLayerByGeometryId, []);
    const inspectionLayer = useSelector(state => selectInspectionLayer(state, issue.id));
    const selectLayerGeometries = useMemo(makeSelectLayerGeometries, []);
    const inspectionLayerIssues = useSelector(state => selectLayerGeometries(state, inspectionLayer?.id!));
    const index = inspectionLayerIssues.findIndex(i => i.id === issue.id);

    return (
        <div className='image-overlay'>
            <div className='image-title'>
                <span className='image-counter'>
                    <span data-testid={'imagesCounter'}>
                        {index + 1} / {inspectionLayerIssues.length}
                    </span>
                </span>
                <b>{issue.content.properties.ac_name}</b>
            </div>
            <div className='image-info'>
                <span data-testid={'severity'}>
                    <strong>{t('issueProperties.severity')}:</strong>{' '}
                    {Issue.Severities()[issue.content.properties.ac_severity!]}
                </span>
                <span className='divider' />
                <span data-testid={'type'}>
                    <strong>{t('issueProperties.type')}:</strong>{' '}
                    {Issue.TypesDisplayNames()[issue.content.properties.ac_type!]}
                </span>
            </div>
        </div>
    );
}
