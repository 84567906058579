import { Trans, useTranslation } from 'react-i18next';
import Modal, { ModalProps } from '../../modal/Modal';
import ModalBody from '../../modal/ModalBody';
import ModalHead from '../../modal/ModalHead';
import ProfileForm from '../../../../pages/billing/components/ProfileForm';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../sharedConstants';

type Props = ModalProps;

export default function UpgradePlanModal({ isOpen, setIsOpen }: Props) {
    const { t } = useTranslation('modals');
    const history = useHistory();

    const cancelButton = () => {
        return (
            <button
                type='button'
                className='btn-ghost-blue'
                onClick={() => {
                    setIsOpen(false);
                }}
            >
                {t('upgradePlanModal.cancel')}
            </button>
        );
    };

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <ModalHead setIsOpen={setIsOpen}>{t('upgradePlanModal.head')}</ModalHead>
            <ModalBody>
                <p>{t('upgradePlanModal.hint')}</p>
                <ProfileForm
                    useCommercialPlanValidationSchema
                    onSubmit={() => {
                        history.push(Routes.UPGRADE_PLAN);
                    }}
                    usedInUpgradePlanModal
                    modalCancelButton={cancelButton()}
                    modalSubmitButtonTitle={t('upgradePlanModal.continue')}
                />
            </ModalBody>
        </Modal>
    );
}
