import { CSSProperties, memo, useContext, useState } from 'react';
import CompareStructureTreeContext from '../../../contexts/CompareStructureTreeContext';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { IS_TOUCH_DEVICE } from '../../../sharedConstants';
import { useSelector } from '../../../store';
import { ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';
import {
    selectLayersByCompareTreeId,
    selectProjectInfoByCompareTreeId,
    selectStructuresByCompareTreeId
} from '../../../store/selectors';
import { isLinkedDataset } from '../../../store/slices/datasets';
import { selectFlatTree } from '../../../store/slices/projectStructure';
import { isObjectVisible } from '../../../store/slices/structure';
import Sortable from '../structure-item/Sortable';
import StructureItem from '../structure-item/StructureItem';
import { TreeData, TreeNode } from './StructureTree';
import { draggableObjectTypes } from './TreeDragDrop';

type Props = {
    data: TreeData;
    isOpen: boolean;
    isScrolling?: boolean;
    style: CSSProperties;
    setOpen(state: boolean): Promise<void>;
};

function StructureTreeNode({ style, data, setOpen, ...props }: Props) {
    const { owned } = useContext(ProjectViewAccessContext);
    const compareContext = useContext(CompareStructureTreeContext);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const projectInfo = useSelector(state => selectProjectInfoByCompareTreeId(state, compareContext?.treeId));
    const temporaryLayers = useSelector(state => selectLayersByCompareTreeId(state, compareContext?.treeId));
    const structures = useSelector(state => selectStructuresByCompareTreeId(state, compareContext?.treeId));
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const flatTree = useSelector(selectFlatTree);
    const [enabledSorting, setSortingEnabled] = useState(draggableObjectTypes.includes(data.node.type));

    function isParentSelected(node: TreeNode): boolean {
        // any-parent
        if (!selectedObject?.artifactId || !node.parentId) return false;
        if (selectedObject.artifactId === node.parentId && selectedObject.treeId === compareContext?.treeId)
            return true;
        else return isParentSelected(flatTree.find(n => n.id === node.parentId)!);
    }

    function isParentVisible(node: TreeNode): boolean {
        const getNextParent = (nd: TreeNode) => flatTree.find(n => n.id === nd?.parentId);

        let parentNode = getNextParent(node);
        while (parentNode) {
            if (!isObjectVisible(structures, parentNode.id)) return false;
            parentNode = getNextParent(parentNode);
        }
        return true;
    }

    const isGeometryTemporary = temporaryLayers.find(tl =>
        Boolean(tl.geometries.find(g => g === data.node.id))
    )?.isTemporary;
    const isGeometryFromLinkedLayer =
        data.node.type === ProjectStructureObjectTypes.GEOMETRY &&
        isLinkedDataset(
            temporaryLayers.find(l => l.geometries.find(g => g === data.node.id)),
            projectInfo?.id!
        );
    const disabledSorting =
        (!owned && !isGeometryTemporary) ||
        !enabledSorting ||
        isCompareToolEnabled ||
        IS_TOUCH_DEVICE ||
        isGeometryFromLinkedLayer;

    return (
        <Sortable id={data.node.id} style={style} disabled={disabledSorting}>
            {({ isDragging }) => (
                <StructureItem
                    data={data.node}
                    isParentSelected={isParentSelected}
                    isParentVisible={isParentVisible}
                    isDragging={isDragging}
                    setSortingEnabled={setSortingEnabled}
                />
            )}
        </Sortable>
    );
}

export default StructureTreeNode;
