import { Cartesian3 } from 'cesium';
import _, { isEmpty } from 'lodash';
import { CompareStructureTreeContextValue } from '../../contexts/CompareStructureTreeContext';
import { Issue } from '../../entities/Issue';
import { Artifact, Chunk, ProjectStructure, RelatedProjectInfo } from '../../generated/cloud-frontend-api/model';
import { GeometryTypes, SupportedGeometryNamesFontSizes, Tuple } from '../../sharedConstants';

export interface ExtendedChunk extends Chunk {
    artifacts: Array<Artifact>;
}

export interface TemporaryGeometry<T extends GeometryTypes = GeometryTypes> {
    id: string;
    content: GeoJson<T>;
    renderAsEntity?: boolean;
}

export interface TemporaryLayer {
    id: string;
    assetUid: string;
    name: string;
    isTemporary: boolean;
    parentProject?: RelatedProjectInfo;
    linkedProjects?: RelatedProjectInfo[];
    isPresentation?: boolean;
    isInspection?: boolean;
    selectOnCreate?: boolean;
    showGeometriesNames?: boolean;
    color: string;
    strokeColor: string;
    sizeInBytes?: number;
    geometriesNamesFont?: SupportedGeometryNamesFontSizes;
    geometries: string[];
    coordinateSystemUid?: string;
}

export function getLayerProperties(layer: TemporaryLayer) {
    return _.pick(layer, [
        'color',
        'strokeColor',
        'name',
        'showGeometriesNames',
        'geometriesNamesFont',
        'isInspection',
        'isPresentation'
    ]);
}

export interface ExtendedStructure extends Omit<ProjectStructure, 'datasets'> {
    chunks: Array<ExtendedChunk>;
    temporaryLayers: Array<TemporaryLayer>;
}

export enum ProjectStructureObjectTypes {
    CHUNK = 'CHUNK',
    ARTIFACT = 'ARTIFACT',
    CAMERAS = 'CAMERAS',
    LAYER = 'LAYER',
    GEOMETRY = 'GEOMETRY',
    ROOT = 'ROOT',
    DATASET = 'DATASET',
    GROUP = 'GROUP',
    IMAGE = 'IMAGE'
}

export type SelectedObject = {
    type: ProjectStructureObjectTypes;
    artifactId?: string;
    needToScroll?: boolean;
    treeId?: CompareStructureTreeContextValue['treeId'];
};

interface RequiredProperties {
    ac_name: string;
    ac_color: string;
    ac_visibility: boolean;
}

export interface PointOfView<T = number | Cartesian3> {
    destination: T extends number ? Tuple<number, 3> : Cartesian3;
    orientation: {
        direction: T extends number ? Tuple<number, 3> : Cartesian3;
        up: T extends number ? Tuple<number, 3> : Cartesian3;
    };
}
interface PointProperties {
    ac_elevation_meters?: number;

    // Below are used only for presentation points of interest
    pointOfView?: PointOfView<number>;
    description?: string;
    ac_duration?: number;

    // Below are used for issues
    ac_severity?: keyof ReturnType<typeof Issue.Severities>;
    ac_type?: keyof typeof Issue.Types;
    ac_description?: string;
    ac_issue_bbox?: Tuple<number, 4>;
    ac_issue_bbox_in_preview?: Tuple<number, 4>;
    ac_photoUid?: string;
    ac_image_name?: string;
    ac_point_of_view?: PointOfView<number>;
    ac_image_uid?: string;
    ac_preview_uid?: string;
}

interface PolylineProperties {
    ac_horizontal_length_meters?: number;
    ac_terrain_length_meters?: number;
    ac_elevation_difference_meters?: number;
    ac_slope_percents?: number;
}

export type VolumeBasePlane = 'bestFit' | 'meanLevel' | 'maxLevel' | 'minLevel' | 'customLevel';
export type VolumeBaseLevel = number | 'Varies';

interface PolygonProperties {
    ac_area2d_square_meters?: number;
    ac_area_square_meters?: number;
    ac_perimeter2d_meters?: number;
    ac_perimeter3d_meters?: number;
    ac_volume_base_plane?: VolumeBasePlane;
    ac_volume_base_level_meters?: VolumeBaseLevel;
    ac_volume_surface?: string;
    ac_volume_above_cubic_meters?: number;
    ac_volume_below_cubic_meters?: number;
    ac_volume_total_cubic_meters?: number;
    ac_stroke_color?: string;
}

interface CommonPropertyBlocksProperties {
    ac_style_block_expanded?: boolean;
    ac_general_block_expanded?: boolean;
    ac_additional_block_expanded?: boolean;
}

interface PolylinePropertyBlocksProperties {
    ac_elevation_profile_block_expanded?: boolean;
}

interface PolygonPropertyBlocksProperties {
    ac_volume_block_expanded?: boolean;
}

export type PropertyBlocksProperties<T extends GeometryTypes = GeometryTypes> = T extends GeometryTypes.POINT
    ? CommonPropertyBlocksProperties
    : T extends GeometryTypes.POLYLINE
    ? CommonPropertyBlocksProperties & PolylinePropertyBlocksProperties
    : T extends GeometryTypes.POLYGON
    ? CommonPropertyBlocksProperties & PolygonPropertyBlocksProperties
    : never;

type AdditionalProperties = Record<string, string | number | boolean | Tuple<number, 4> | PointOfView<number>>;

type NamedProperties<T extends GeometryTypes> = RequiredProperties &
    PropertyBlocksProperties<T> &
    (T extends GeometryTypes.POINT
        ? PointProperties
        : T extends GeometryTypes.POLYLINE
        ? PolylineProperties
        : T extends GeometryTypes.POLYGON
        ? PolygonProperties
        : never);

// TODO rename to GeoJsonFeature for consistency
export interface GeoJson<T extends GeometryTypes = GeometryTypes> {
    type: 'Feature';
    geometry: {
        type: T;
        coordinates: T extends GeometryTypes.POINT
            ? number[]
            : T extends GeometryTypes.POLYLINE
            ? number[][]
            : T extends GeometryTypes.POLYGON
            ? number[][][]
            : unknown;
    };
    bbox?: number[];
    properties: NamedProperties<T> & AdditionalProperties;
}

export interface GeoJsonFeatureCollection {
    type: 'FeatureCollection';
    bbox?: number[];
    features: GeoJson[];
}

export interface CurrentlyEditingShape {
    type: GeometryTypes;
    id: string;
    index?: number;
    isMidpoint: boolean;
}

export function isPointFeature(geoJson: GeoJson): geoJson is GeoJson<GeometryTypes.POINT> {
    return geoJson.geometry.type === GeometryTypes.POINT;
}

export function isLineStringFeature(geoJson: GeoJson): geoJson is GeoJson<GeometryTypes.POLYLINE> {
    return geoJson.geometry.type === GeometryTypes.POLYLINE;
}

export function isPolygonFeature(geoJson: GeoJson): geoJson is GeoJson<GeometryTypes.POLYGON> {
    return geoJson.geometry.type === GeometryTypes.POLYGON;
}

export function isPointGeometry(geometry: TemporaryGeometry): geometry is TemporaryGeometry<GeometryTypes.POINT> {
    return isPointFeature(geometry.content);
}

export function isPolylineGeometry(geometry: TemporaryGeometry): geometry is TemporaryGeometry<GeometryTypes.POLYLINE> {
    return isLineStringFeature(geometry.content);
}

export function isPolygonGeometry(geometry: TemporaryGeometry): geometry is TemporaryGeometry<GeometryTypes.POLYGON> {
    return isPolygonFeature(geometry.content);
}
export function isIssuePointGeometry(geometry: TemporaryGeometry): boolean {
    return isPointGeometry(geometry) && !_.isUndefined(geometry.content.properties.ac_severity);
}
export function isIssuePointFeature(geoJson: GeoJson): boolean {
    return isPointFeature(geoJson) && !_.isUndefined(geoJson.properties.ac_severity);
}
export function isViewpointGeometry(geometry: TemporaryGeometry): boolean {
    return (
        isPointGeometry(geometry) &&
        (!_.isUndefined(geometry.content.properties.index) || !_.isUndefined(geometry.content.properties.ac_duration))
    );
}
export function isViewpointFeature(geoJson: GeoJson): boolean {
    return (
        isPointFeature(geoJson) &&
        (!_.isUndefined(geoJson.properties.index) || !_.isUndefined(geoJson.properties.ac_duration))
    );
}

export function isLayer(object: any): object is TemporaryLayer {
    return !isEmpty(object) && Array.isArray(object?.geometries);
}
export function isGeometry(object: any): object is TemporaryGeometry {
    return !isEmpty(object) && object.content?.type === 'Feature';
}
