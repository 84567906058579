import { useState, useEffect, RefObject } from 'react';

export default function useGetElementDimensions(elementRef: RefObject<HTMLElement>, useBorderBox?: boolean) {
    const [width, setWidth] = useState<undefined | number>(undefined);
    const [height, setHeight] = useState<undefined | number>(undefined);

    useEffect(() => {
        const element = elementRef.current;

        const resizeObserver = new ResizeObserver(entries => {
            if (!useBorderBox) {
                setWidth(entries[0].contentBoxSize[0].inlineSize);
                setHeight(entries[0].contentBoxSize[0].blockSize);
            } else {
                setWidth(entries[0].borderBoxSize[0].inlineSize);
                setHeight(entries[0].borderBoxSize[0].blockSize);
            }
        });

        if (element) resizeObserver.observe(element);

        return () => {
            resizeObserver.disconnect();
        };
    }, [elementRef, useBorderBox]);

    return { width, height };
}
