import { useTranslation } from 'react-i18next';
import { ProjectStructureObjectTypes } from '../store/helpers/interfaces';
import { ExtendedChunk, TemporaryLayer, TemporaryGeometry } from '../store/helpers/interfaces';
import { ExtendedStructureInfo } from '../store/slices/structure';
import { ExtendedCamera } from '../store/slices/cameras';
import { ExtendedDatasetInfo } from '../store/slices/datasetfilesUpload';
import { Artifact } from '../generated/cloud-frontend-api';
import { DatasetInfo } from '../generated/dataset-api/model/dataset-info';
import { useSelector } from '../store';
import { isUndefined } from 'lodash';

interface INodeName {
    selectedObjectType: ProjectStructureObjectTypes;
    selectedObjectInfo: any;
}

export default function useGetNodeName({ selectedObjectType, selectedObjectInfo }: INodeName) {
    const { t } = useTranslation(['glossary']);
    const datasets = useSelector(state => state.datasets.datasets);

    function removeExtension(filename: string | undefined) {
        if (filename === undefined) return '';
        const dotPosition = filename.lastIndexOf('.');
        return dotPosition >= 0 ? filename.slice(0, dotPosition) : filename;
    }

    if (selectedObjectType === ProjectStructureObjectTypes.CHUNK)
        return (selectedObjectInfo as ExtendedChunk).chunkName ?? '';
    if (selectedObjectType === ProjectStructureObjectTypes.GROUP)
        return (selectedObjectInfo as ExtendedStructureInfo).properties.name ?? '';
    if (selectedObjectType === ProjectStructureObjectTypes.LAYER) {
        return (selectedObjectInfo as TemporaryLayer).name;
    }
    if (selectedObjectType === ProjectStructureObjectTypes.CAMERAS) return t('glossary:sourceTypes.images'); // no object own name
    if (selectedObjectType === ProjectStructureObjectTypes.IMAGE) return (selectedObjectInfo as ExtendedCamera).label;
    if (selectedObjectType === ProjectStructureObjectTypes.ARTIFACT) {
        const dataset = datasets.find(d => d.datasetUid === (selectedObjectInfo as Artifact).datasetUid);
        return (dataset as ExtendedDatasetInfo).name ?? '';
    }
    if (selectedObjectType === ProjectStructureObjectTypes.GEOMETRY) {
        const geometry = selectedObjectInfo as TemporaryGeometry;
        return geometry.content.properties.ac_name;
    }
    if (selectedObjectType === ProjectStructureObjectTypes.DATASET) {
        return removeExtension((selectedObjectInfo as DatasetInfo).name ?? '');
    }
    return '';
}
