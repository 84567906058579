import classNames from 'classnames';
import _ from 'lodash';
import { useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { matchPath, useParams, useRouteMatch } from 'react-router-dom';
import { useCesium } from 'resium';
import ProjectViewAccessContext from '../../../../contexts/ProjectViewAccessContext';
import { SourceType } from '../../../../generated/dataset-api';
import getFilenameExtension from '../../../../lib/getFilenameExtension';
import { Routes } from '../../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../../store';
import { VECTOR_LAYERS_EXTENSIONS } from '../../../../store/helpers/dataset-files-validators/DatasetFilesValidator';
import { ProjectStructureObjectTypes } from '../../../../store/helpers/interfaces';
import { uploadDatasets } from '../../../../store/slices/datasetfilesUpload';
import { setDatasetsUploadModalOpen } from '../../../../store/slices/datasetsUpload';
import { selectGroups } from '../../../../store/slices/structure';
import Modal from '../../modal/Modal';
import ModalActions from '../../modal/ModalActions';
import ModalBody from '../../modal/ModalBody';
import ModalHead from '../../modal/ModalHead';
import StorageIsFullModal from '../StorageIsFullModal';

// TODO: интерфейс из удаленного файла FileView.tsx.
interface FileViewRouteParams {
    id: string;
    fileId: string;
}

export default function UploadDatasetModal() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['modals', 'glossary']);
    const { scene } = useCesium();
    const { fileId } = useParams<FileViewRouteParams>();
    const { owned } = useContext(ProjectViewAccessContext);
    const fileInput = useRef<HTMLInputElement>(null!);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const isOpen = useSelector(state => state.datasetsUpload.isModalOpen);
    const resources = useSelector(state => state.accountResources.account.resources);
    const groups = useSelector(state => selectGroups(state));
    const selectedObject = useSelector(state => state.project.selectedObject);
    const [showStorageIsFullModal, setShowStorageIsFullModal] = useState(false);
    const isEmbed = !!useRouteMatch({ path: Routes.EMBEDDED_PROJECT_VIEW, exact: true });

    const canUploadVectorLayers = Boolean(
        matchPath(window.location.pathname, {
            exact: true,
            path: [Routes.PROJECT_VIEW, Routes.SHARED_PROJECT_VIEW, Routes.EMBEDDED_PROJECT_VIEW, Routes.SITE_VIEW]
        })
    );

    const canOnlyUploadVectorLayers = !!useRouteMatch({
        path: [Routes.SHARED_PROJECT_VIEW, Routes.EMBEDDED_PROJECT_VIEW],
        exact: true
    });

    const DATASETS: Record<SourceType, { name: string; fileTypes: string; fileTypesNames: string }> = useMemo(
        () => ({
            [SourceType.POINT_CLOUD]: {
                name: t('glossary:sourceTypes.pointCloud'),
                fileTypes: '.obj, .ply, .las, .laz, .e57, .pts, .pts.gz, .ptx, .pcd, .zip, .3tz',
                fileTypesNames: 'Cesium3DTiles (.zip, .3tz), .obj, .ply, .las, .laz, .e57, .pts, .pts.gz, .ptx, .pcd'
            },
            [SourceType.TIE_POINTS]: {
                name: t('glossary:sourceTypes.tiePoints'),
                fileTypes: '.obj, .ply, .las, .laz, .e57, .pts, .pts.gz, .ptx, .pcd',
                fileTypesNames: '.obj, .ply, .las, .laz, .e57, .pts, .pts.gz, .ptx, .pcd'
            },
            [SourceType.GEOJSON]: {
                name: t('glossary:sourceTypes.geojson'),
                fileTypes: '.zip, .dxf, .dgn, .geojson, .gml, .gpkg',
                fileTypesNames: 'Shapefile (.zip), .dxf, .dgn, .geojson, .gml, .gpkg'
            },
            [SourceType.RASTER_MAP]: {
                name: t('glossary:sourceTypes.rasterMap'),
                fileTypes: '.tif, .tiff, .geotiff',
                fileTypesNames: '.tif, .tiff, .geotiff'
            },
            [SourceType.DEM]: {
                name: t('glossary:sourceTypes.dem'),
                fileTypes: '.tif, .tiff, .geotiff',
                fileTypesNames: '.tif, .tiff, .geotiff'
            },
            [SourceType.DEM_3_D]: {
                name: t('glossary:sourceTypes.dem3d'),
                fileTypes: '.tif, .tiff, .geotiff',
                fileTypesNames: '.tif, .tiff, .geotiff'
            },
            [SourceType.TILED_MODEL]: {
                name: t('glossary:sourceTypes.tiledModel'),
                fileTypes: '.zip, .3tz',
                fileTypesNames: 'Cesium3DTiles (.zip, .3tz)'
            },
            [SourceType.BLOCK_MODEL]: {
                name: t('glossary:sourceTypes.tiledModel'),
                fileTypes: '.zip, .3tz',
                fileTypesNames: 'Cesium3DTiles (.zip, .3tz)'
            },
            [SourceType.MODEL_3_D]: {
                name: t('glossary:sourceTypes.model3d'),
                fileTypes: '',
                fileTypesNames: ''
            }
        }),
        [t]
    );

    const uploadableDatasets = useMemo(() => {
        if (canOnlyUploadVectorLayers) return [DATASETS[SourceType.GEOJSON]];

        return _.compact([
            SourceType.POINT_CLOUD,
            SourceType.TILED_MODEL,
            SourceType.RASTER_MAP,
            SourceType.DEM,
            canUploadVectorLayers ? SourceType.GEOJSON : null
        ]).map(sourceType => DATASETS[sourceType]);
    }, [canOnlyUploadVectorLayers, DATASETS, canUploadVectorLayers]);

    function setIsOpen(isOpen: boolean) {
        dispatch(setDatasetsUploadModalOpen(isOpen));
    }

    function closeExceedsStorageModal(showModal: boolean) {
        dispatch(setDatasetsUploadModalOpen(false));
        setShowStorageIsFullModal(showModal);
    }

    if (showStorageIsFullModal || resources?.storage?.free! <= 0) {
        return <StorageIsFullModal setIsOpen={closeExceedsStorageModal} isOpen={showStorageIsFullModal} />;
    }

    return (
        <Modal setIsOpen={setIsOpen} isOpen={isOpen} modalClass={'modal-upload-dataset'} isEmbed={isEmbed}>
            <ModalHead setIsOpen={setIsOpen} isEmbed={isEmbed}>
                {t('uploadDatasetModal.head')}
            </ModalHead>
            <ModalBody>
                <div className='upload-block'>
                    <div className='upload-select-title'>{t('uploadDatasetModal.info')}</div>
                </div>
                <div className='upload-block'>
                    <div className='upload-parameter'>
                        <b className='upload-subtitle'>{t('uploadDatasetModal.formats')}</b>
                        {uploadableDatasets.map(({ name, fileTypesNames }) => (
                            <div key={name} className={'upload-type'}>
                                <b>{name}</b>: {fileTypesNames}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={'upload-block'}>
                    <div className='upload-parameter'>
                        <b className='upload-subtitle'>{t('uploadDatasetModal.coordinateSystem')}</b>
                        <p className={'upload-type'}>{`WGS84 (EPSG::4326) ${t('uploadDatasetModal.transformable')}`}</p>
                    </div>
                </div>
                <ModalActions>
                    <button
                        type='button'
                        className='btn-ghost-blue'
                        onClick={e => {
                            dispatch(setDatasetsUploadModalOpen(false));
                        }}
                    >
                        {t('uploadDatasetModal.actionCancel')}
                    </button>
                    <button
                        type='button'
                        className={classNames('btn', { embed: isEmbed })}
                        onClick={e => {
                            fileInput.current.click();
                        }}
                    >
                        {t('uploadDatasetModal.actionBrowse')}
                    </button>
                </ModalActions>

                <input
                    data-testid={'datasetFileInput'}
                    ref={fileInput}
                    multiple
                    accept={uploadableDatasets.map(d => d.fileTypes).join(',')}
                    type={'file'}
                    style={{ display: 'none', visibility: 'hidden' }}
                    onChange={async e => {
                        if (!e.target.files?.length) return;

                        const allFiles = Array.from(e.target.files);
                        const allowedFiles = getAllowedFiles(allFiles);
                        const filesSize = allowedFiles.reduce((acc, f) => acc + f.size, 0);

                        if (owned && (resources?.storage?.free || 0) < filesSize) {
                            setShowStorageIsFullModal(true);
                            return;
                        }

                        const structureParentId = getStructureParentId();
                        dispatch(
                            uploadDatasets({
                                files: allowedFiles,
                                projectId: projectInfo.id!,
                                owned,
                                scene: scene!,
                                terrainProvider: scene?.globe?.terrainProvider!,
                                structureParentId
                            })
                        );
                        setIsOpen(false);
                    }}
                />
            </ModalBody>
        </Modal>
    );

    function getStructureParentId() {
        let structureParentId;
        if (selectedObject?.type === ProjectStructureObjectTypes.GROUP) structureParentId = selectedObject.artifactId;

        const group = groups.find(g => g.uid === fileId);
        if (group) structureParentId = group.uid;
        return structureParentId;
    }

    function getAllowedFiles(allFiles: File[]) {
        let allowedFiles = allFiles;
        if (!canUploadVectorLayers) {
            allowedFiles = allFiles.filter(
                f => !VECTOR_LAYERS_EXTENSIONS.includes(getFilenameExtension(f.name).toLowerCase())
            );
        } else if (canOnlyUploadVectorLayers) {
            allowedFiles = allowedFiles = allFiles.filter(f =>
                VECTOR_LAYERS_EXTENSIONS.includes(getFilenameExtension(f.name).toLowerCase())
            );
        }
        return allowedFiles;
    }
}
