import { globalTerrainOption } from '../components/ProjectView/elevation-profile/ElevationProfileTool';
import { useSelector } from '../store';
import { selectReadyDemFiles } from '../store/selectors';

export default function useDemSurfaceName(surfaceId: string | undefined) {
    const dems = useSelector(state => selectReadyDemFiles(state));

    if (surfaceId === globalTerrainOption().id) return globalTerrainOption().name;

    return dems.find(d => d.id === surfaceId)?.name;
}
