import classNames from 'classnames';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../../contexts/ProjectViewAccessContext';
import useAdaptableWidthSidebar from '../../hooks/useAdaptableWidthSidebar';
import {
    INSPECTOR_PANEL_ANIMATION_DURATION,
    MAP_PANEL_CONTROL_WIDTH,
    MAP_PANEL_DEFAULT_WIDTH
} from '../../sharedConstants';
import { AppDispatch, useSelector } from '../../store';
import { setAnimatedInspectorVisibility, setSidebarVisibility } from '../../store/slices/projectView';
import TippyTooltip from '../Elements/tippy-tooltip/TippyTooltip';
import ElementInspector from './element-inspector/ElementInspector';

type Props = {
    wrapperWidth: number;
    sidebarsToggleTransition: string | undefined;
};

export const TWO_PANELS_MIN_WIDTH = (MAP_PANEL_DEFAULT_WIDTH + MAP_PANEL_CONTROL_WIDTH) * 2;

export default function InspectionSidebar({ wrapperWidth, sidebarsToggleTransition }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const isInspectorExpanded = useSelector(state => state.projectView.isInspectorExpanded);
    const isInspectorAnimated = useSelector(state => state.projectView.isInspectorAnimated);
    const isSidebarExpanded = useSelector(state => state.projectView.isSidebarExpanded);
    const { isInEmbedView } = useContext(ProjectViewAccessContext);

    useAdaptableWidthSidebar('--inspector-width', wrapperWidth);

    const isTogglerVisible =
        wrapperWidth > MAP_PANEL_DEFAULT_WIDTH + MAP_PANEL_CONTROL_WIDTH * 2 ||
        (wrapperWidth <= MAP_PANEL_DEFAULT_WIDTH + MAP_PANEL_CONTROL_WIDTH * 2 && !isSidebarExpanded);

    const animationDurationString = `${INSPECTOR_PANEL_ANIMATION_DURATION / 1000}s`;
    const animationInlineStyle = isInspectorAnimated ? { transitionDuration: animationDurationString } : undefined;

    return (
        <div
            id='inspector'
            className={classNames('inspector', { expanded: isInspectorExpanded })}
            style={animationInlineStyle}
        >
            {isTogglerVisible && (
                <TippyTooltip
                    tooltipText={
                        isInspectorExpanded
                            ? t('projectView:inspectionSidebar.toggleTooltip_hide')
                            : t('projectView:inspectionSidebar.toggleTooltip_show')
                    }
                >
                    <div
                        className={classNames('inspector-toggle', { hasBorder: !isInEmbedView })}
                        onClick={e => {
                            if (wrapperWidth < TWO_PANELS_MIN_WIDTH && !isInspectorExpanded) {
                                dispatch(setSidebarVisibility(false));
                            }
                            dispatch(setAnimatedInspectorVisibility(!isInspectorExpanded));
                        }}
                        style={
                            sidebarsToggleTransition !== undefined
                                ? { transform: `translateY(${sidebarsToggleTransition})` }
                                : undefined
                        }
                    />
                </TippyTooltip>
            )}
            {/* TODO: заменить на <div inert>, когда атрибут будет поддерживаться реактом */}
            <div
                className='inspector-content'
                ref={node =>
                    node && (isInspectorExpanded ? node.removeAttribute('inert') : node.setAttribute('inert', ''))
                }
            >
                <ElementInspector />
            </div>
        </div>
    );
}
