import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from '../SubscriptionForm.module.scss';

// кол-во элементов в каждом списке
const STORAGE_ITEMS = 6;
const TIME_ITEMS = 5;

// повторяемый элемент списка
const listItem = (
    <li className={styles.listSkeleton}>
        <span className={classNames(styles.skel, styles.skelRadio)} />
        <span className={classNames(styles.skel, styles.skelRadioText)} />
    </li>
);

type Props = {
    showStorage?: boolean;
    showHours?: boolean;
};

export default function SubscriptionSkeleton({ showStorage = false, showHours = false }: Props) {
    const { t } = useTranslation(['cabinet', 'glossary']);

    return (
        <>
            {showStorage && (
                <div className={styles.contentSection}>
                    <h2 className={styles.sectionTitle}>{t('glossary:storage')}</h2>
                    <div className={classNames(styles.skel, styles.skelLine)} />
                    <div className={classNames(styles.skel, styles.skelHintLine)} />
                    <ul className={styles.formList}>{createList(listItem, STORAGE_ITEMS)}</ul>
                </div>
            )}

            {showHours && (
                <div className={styles.contentSection}>
                    <h2 className={styles.sectionTitle}>{t('glossary:processing')}</h2>
                    <div className={classNames(styles.skel, styles.skelLine)} />
                    <div className={classNames(styles.skel, styles.skelHintLine)} />
                    <ul className={styles.formList}>{createList(listItem, TIME_ITEMS)}</ul>
                </div>
            )}

            <div className={styles.skelSubscriptionSummary}>
                <div className={classNames(styles.skel, styles.skelSummary)} />
                <div className={classNames(styles.skel, styles.skelSubscription)} />
                <div className={classNames(styles.skel, styles.skelButton)} />
            </div>
        </>
    );
}

function createList(item: JSX.Element, number: number) {
    let output = [];
    let outputItem = { ...item };
    for (let n = 0; n < number; n++) {
        outputItem.key = String(n);
        output.push(outputItem);
    }
    return output;
}
