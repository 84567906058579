import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import useGetElementDimensions from '../../../../hooks/useGetElementDimensions';
import useSiteViewQueryParams from '../../../../hooks/useSiteViewQueryParams';
import { Routes } from '../../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../../store';
import { resetProjectView } from '../../../../store/slices/projectView';
import { selectFirstSiteProject } from '../../../../store/slices/site';
import { getComparedProject } from '../../../../store/slices/compareTool';
import formatSurveyDate from '../helpers/formatSurveyDate';

const REDUCE_DROPDOWN_WIDTH_BY = 8; // = px, used in setting dropdown horizontal position
const dropdownAlign = { offset: [-REDUCE_DROPDOWN_WIDTH_BY, 5] }; // rc-select properties

type Props = {
    selectDateType: 'left' | 'right';
};

export default function CompareSurveyDatePicker({ selectDateType }: Props) {
    const { t } = useTranslation(['projectView']);
    const dispatch: AppDispatch = useDispatch();
    const { queryProjectId, setQueryProjectId } = useSiteViewQueryParams();
    const firstSiteProject = useSelector(state => selectFirstSiteProject(state));
    const comparedProject = useSelector(state => state.compareTool.comparedProject);
    const siteProjects = useSelector(state => state.site.siteInfo.projects);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const isInEmbedView = !!useRouteMatch({ path: [Routes.EMBEDDED_PROJECT_VIEW], exact: true });

    const siteProjectsWithSurveyDatesFormatted = useMemo(
        () => siteProjects.map(p => ({ ...p, formattedSurveyDate: formatSurveyDate(p.surveyDate) })),
        [siteProjects]
    );

    const dropdownContainerRef = useRef<HTMLDivElement>(null);
    const dropdownContainerWidth = useGetElementDimensions(dropdownContainerRef).width || 0;

    const dropdownWidth = useMemo(() => {
        return dropdownContainerWidth ? dropdownContainerWidth - REDUCE_DROPDOWN_WIDTH_BY : 0;
    }, [dropdownContainerWidth]);

    function getIcon(selectDateType: 'left' | 'right') {
        return (
            <div className={classNames('compare-icon', selectDateType === 'left' ? 'compare-left' : 'compare-right')}>
                {selectDateType === 'left' ? t('projectView:compareMode.L') : t('projectView:compareMode.R')}
            </div>
        );
    }

    return (
        <div className={classNames('compare-select', { active: isDropdownVisible })} ref={dropdownContainerRef}>
            <div className='control-icon'>{getIcon(selectDateType)}</div>
            <Select
                variant='borderless'
                className={classNames('ant-select-small ant-survey-date', { 'theme-dark': isInEmbedView })}
                popupClassName={classNames('ant-dropdown-project', { 'theme-dark': isInEmbedView })}
                value={
                    selectDateType === 'left'
                        ? queryProjectId ?? firstSiteProject?.uid
                        : comparedProject?.projectInfo.id
                }
                onSelect={newValue => {
                    if (selectDateType === 'left' && queryProjectId !== newValue) {
                        dispatch(resetProjectView({ doNotResetCompareTool: true }));
                        setQueryProjectId(newValue);
                    }
                    if (selectDateType === 'right' && comparedProject?.projectInfo.id !== newValue) {
                        dispatch(getComparedProject(newValue));
                    }
                }}
                popupMatchSelectWidth={dropdownWidth}
                placement='bottomRight'
                dropdownAlign={dropdownAlign}
                onDropdownVisibleChange={(open: boolean) => {
                    setIsDropdownVisible(open);
                }}
                options={siteProjectsWithSurveyDatesFormatted.map(p => {
                    return {
                        value: p.uid,
                        label: p.formattedSurveyDate
                    };
                })}
            />
        </div>
    );
}
