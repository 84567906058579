import {
    EntityState,
    PayloadAction,
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    unwrapResult
} from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { matchPath } from 'react-router-dom';
import { projectApiV2, projectsApi, sharedProjectsApi } from '../../api/initApis';
import { AccessInfo, Parent, ProjectInfo, ProjectsPage } from '../../generated/cloud-frontend-api/model';
import { NewProject, ProjectType } from '../../generated/project-api-v2';
import { ProjectsSortModes, ProjectsSortOrders, Routes } from '../../sharedConstants';
import { createSetterReducer } from '../helpers';
import { AppDispatch, ApplicationState } from '../index';
import { loadNextProjectsPage } from '../sharedActions';
import { deleteProject } from './projectActions';
import {
    addEmailAccess,
    deleteEmailAccess,
    deleteProjectFromSharedWithMe,
    disableEmbedAccess,
    disableLinkAccess,
    enableEmbedAccess,
    enableLinkAccess
} from './sharing';
import { isAxiosError } from 'axios';

export function getDefaultSortParameters(): { sortMode: ProjectsSortModes } {
    if (matchPath(window.location.pathname, { path: [Routes.SITE, Routes.SHARED_SITE], exact: true })) {
        return { sortMode: ProjectsSortModes.SURVEY_DATE };
    } else {
        return { sortMode: ProjectsSortModes.MODIFICATION_DATE };
    }
}

const defaultSortMode = getDefaultSortParameters().sortMode;

export const projectsAdapter = createEntityAdapter<Required<ProjectInfo>>();

interface ProjectsPageState extends Omit<Required<ProjectsPage>, 'projects' | 'parent'> {
    projects: EntityState<Required<ProjectInfo>, string>;
    sortMode: ProjectsSortModes;
    sortOrder: ProjectsSortOrders;
    selected: string[];
    currentPage: number;
    searchText: string;
    amountOfProjects?: number;
    amountOfSharedProjects?: number;
    isLoading: boolean;
    previousSearchText: string;
    parent: Parent | null;
}

export const DEFAULT_SORT_MODE = ProjectsSortModes.MODIFICATION_DATE;
export const DEFAULT_SORT_ORDER = ProjectsSortOrders.DESCENDING;

function isLoadingPageInitially() {
    return !!matchPath(window.location.pathname, {
        path: [Routes.INDEX, Routes.SITE, Routes.SHARED, Routes.SHARED_SITE, Routes.FOLDER],
        exact: true
    });
}

const initialState: ProjectsPageState = {
    projects: projectsAdapter.getInitialState(),
    number: 0,
    numberOfElements: 0,
    size: 0,
    parent: null,
    totalElements: 0,
    totalPages: 0,
    sortMode: defaultSortMode,
    sortOrder: ProjectsSortOrders.DESCENDING,
    selected: [],
    currentPage: 0,
    searchText: '',
    previousSearchText: '',
    isLoading: isLoadingPageInitially()
};

const name = 'projectsPage';

const getTotalProjects = createAsyncThunk(`${name}/getTotalProjects`, async (type?: ProjectType) => {
    const { data } = await projectsApi.getProjects(
        ProjectsSortModes.MODIFICATION_DATE,
        0,
        0,
        undefined,
        undefined,
        type
    );
    return data.totalElements;
});

const getTotalProjectsByParent = createAsyncThunk(`${name}/getTotalProjectsByParent`, async (parentUid: string) => {
    const { data } = await projectsApi.getProjectsByParent(parentUid, ProjectsSortModes.MODIFICATION_DATE, 0, 0);
    return data.totalElements;
});

const getTotalSharedProjects = createAsyncThunk(`${name}/getTotalSharedProjects`, async () => {
    const { data } = await sharedProjectsApi.getSharedProjects(0, 0, ['name']);
    return data.totalElements;
});

export const replaceProjectInPage = createAsyncThunk(`${name}/replaceProject`, async (id: string) => {
    const { data } = await projectsApi.getProjectById(id);
    return data;
});

export function getSuitedOrder(sort: ProjectsSortModes, order: ProjectsSortOrders): ProjectsSortOrders {
    if (sort === ProjectsSortModes.NAME) {
        return order === ProjectsSortOrders.DESCENDING ? ProjectsSortOrders.ASCENDING : ProjectsSortOrders.DESCENDING;
    }
    return order;
}

export const createProject = createAsyncThunk(
    `${name}/createProject`,
    async ({ name, type, needPublish = false, parentUid, surveyDate }: NewProject) => {
        const { data } = await projectApiV2.createProject({ name, type, needPublish, parentUid, surveyDate });
        return data;
    }
);

interface RenameArgs {
    projectId: string;
    name: string;
}

export const renameProject = createAsyncThunk<void, RenameArgs>(
    `${name}/renameProject`,
    async ({ projectId, name }) => {
        const { data } = await projectApiV2.patchProject(projectId, { name });
        return data;
    }
);

export const getAllProjects = createAsyncThunk<ProjectsPage, { type?: ProjectType }, { dispatch: AppDispatch }>(
    `${name}/getAll`,
    ({ type }, { dispatch }) => {
        return dispatch(getTotalProjects(type))
            .then(unwrapResult)
            .then(totalProjects =>
                projectsApi.getProjects('modificationDate', totalProjects, 0, 'desc', undefined, type)
            )
            .then(({ data }) => ({
                projects: data.projects || []
            }));
    }
);

export const getAllProjectsByParent = createAsyncThunk<ProjectInfo[], { parentUid: string }, { dispatch: AppDispatch }>(
    `${name}/getAllByParent`,
    ({ parentUid }, { dispatch }) => {
        return dispatch(getTotalProjectsByParent(parentUid))
            .then(unwrapResult)
            .then(totalProjects =>
                projectsApi.getProjectsByParent(
                    parentUid,
                    ProjectsSortModes.MODIFICATION_DATE,
                    totalProjects,
                    0,
                    'desc'
                )
            )
            .then(({ data }) => data.projects || []);
    }
);

interface MoveProjectArgs {
    projectId: string;
    parent: Parent | 'unset';
}
export const moveProject = createAsyncThunk(`${name}/moveProject`, async ({ parent, projectId }: MoveProjectArgs) => {
    const { data } = await projectApiV2.patchProject(projectId, {
        parentUid: parent === 'unset' ? 'unset' : parent.uid
    });
    return data;
});

interface MoveProjectsArgs {
    projectIds: string[];
    parent: Parent | 'unset';
}
export const moveProjects = createAsyncThunk<void, MoveProjectsArgs, { state: ApplicationState }>(
    `${name}/moveProjects`,
    async ({ parent, projectIds }, { getState, rejectWithValue }) => {
        const previousParent = getState().projectsPage.parent;
        const successfulIds: string[] = [];
        let hasError = false;
        await Promise.all(
            projectIds.map(projectId =>
                projectApiV2
                    .patchProject(projectId, { parentUid: parent === 'unset' ? 'unset' : parent.uid })
                    .then(response => {
                        successfulIds.push(projectId);
                    })
                    .catch(err => {
                        if (isAxiosError(err) && err.response)
                            if (err.response.status >= 400 && err.response.status <= 499) {
                                hasError = true;
                            }
                    })
            )
        );

        // Revert successful moves, if at least one of moves has failed
        if (hasError) {
            Promise.all(
                successfulIds.map(projectId =>
                    projectApiV2.patchProject(projectId, { parentUid: !previousParent ? 'unset' : previousParent.uid })
                )
            );
            return rejectWithValue(null);
        }
    }
);

interface UpdateSurveyDateArgs {
    projectId: string;
    surveyDate: string;
}
export const updateSurveyDate = createAsyncThunk(
    `${name}/updateSurveyDate`,
    async ({ projectId, surveyDate }: UpdateSurveyDateArgs) => {
        const { data } = await projectApiV2.patchProject(projectId, { surveyDate });
        return data;
    }
);

const setterReducer = createSetterReducer<ProjectsPageState>();
const projectsPageSlice = createSlice({
    name,
    initialState,
    reducers: {
        setSortMode: setterReducer('sortMode'),
        setSortOrder: setterReducer('sortOrder'),
        setSelectedProjects(state, { payload }: PayloadAction<Array<string>>) {
            if (!payload.length) state.selected = [];
            for (const element of payload)
                if (state.selected.includes(element)) state.selected.splice(state.selected.indexOf(element), 1);
                else state.selected.push(element);
        },
        setCurrentProjectsPage: setterReducer('currentPage'),
        setSearchText(state, { payload }: PayloadAction<string>) {
            state.previousSearchText = state.searchText;
            state.searchText = payload;
        },
        changeProjectPropertyInPage(
            state,
            { payload }: PayloadAction<{ id: string; propName: keyof ProjectInfo; propValue: any }>
        ) {
            const { propValue, propName, id } = payload;
            projectsAdapter.updateOne(state.projects, { changes: { [propName]: propValue }, id });
        },
        resetProjects(state) {
            state.projects = projectsAdapter.getInitialState();
            state.currentPage = 0;
            state.selected = [];
            state.isLoading = isLoadingPageInitially();
        },
        setIsLoading: setterReducer('isLoading')
    },
    extraReducers: builder => {
        function updateAccessState(
            state: WritableDraft<ProjectsPageState>,
            projectId: string,
            newAccesses: AccessInfo[] | undefined
        ) {
            projectsAdapter.updateOne(state.projects, {
                id: projectId,
                changes: { accesses: newAccesses }
            });
        }

        builder
            .addCase(replaceProjectInPage.fulfilled, (state, { payload }) => {
                projectsAdapter.updateOne(state.projects, {
                    id: payload.projectInfo?.id!,
                    changes: payload.projectInfo!
                });
            })
            .addCase(enableLinkAccess.fulfilled, (state, action) => {
                const { projectId } = action.meta.arg;
                updateAccessState(state, projectId, action.payload);
            })
            .addCase(disableLinkAccess.fulfilled, (state, action) => {
                const { projectId } = action.meta.arg;
                updateAccessState(state, projectId, action.payload);
            })
            .addCase(enableEmbedAccess.fulfilled, (state, action) => {
                const { projectId } = action.meta.arg;
                updateAccessState(state, projectId, action.payload);
            })
            .addCase(disableEmbedAccess.fulfilled, (state, action) => {
                const { projectId } = action.meta.arg;
                updateAccessState(state, projectId, action.payload);
            })
            .addCase(addEmailAccess.fulfilled, (state, action) => {
                const { projectId } = action.meta.arg;
                updateAccessState(state, projectId, action.payload);
            })
            .addCase(deleteEmailAccess.fulfilled, (state, action) => {
                const { projectId } = action.meta.arg;
                updateAccessState(state, projectId, action.payload);
            })
            .addCase(getTotalProjects.fulfilled, (state, { payload }) => {
                if (payload !== undefined) {
                    state.totalElements = payload;
                    state.amountOfProjects = payload;
                }
            })
            .addCase(renameProject.pending, (state, action) => {
                const { projectId, name } = action.meta.arg;
                projectsAdapter.updateOne(state.projects, { id: projectId, changes: { name } });
            })
            .addCase(loadNextProjectsPage.fulfilled, (state, { payload, meta }) => {
                const isIndexPath = !!matchPath(window.location.pathname, { path: Routes.INDEX, exact: true });

                if (payload.projects?.length) {
                    projectsAdapter.addMany(state.projects, payload.projects as Required<ProjectInfo>[]);
                }
                if (!state.searchText && isIndexPath) state.amountOfProjects = payload.totalElements;
                state.numberOfElements = payload.numberOfElements!;
                state.totalElements = payload.totalElements!;
                state.totalPages = payload.totalPages!;
                state.isLoading = false;
                state.previousSearchText = meta.arg.pattern || '';
                if ('parent' in payload) {
                    state.parent = payload.parent || null;
                }
            })
            .addCase(deleteProject.fulfilled, (state, { meta }) => {
                projectsAdapter.removeOne(state.projects, meta.arg);
                state.amountOfProjects!--;
            })
            .addCase(createProject.fulfilled, (state, { meta }) => {
                state.amountOfProjects!++;
            })
            .addCase(moveProject.fulfilled, (state, { meta }) => {
                const project = state.projects.entities[meta.arg.projectId];
                const parent = meta.arg.parent;

                if (parent !== 'unset') {
                    const parentProject = state.projects.entities[parent.uid!];
                    if (
                        project &&
                        (parentProject?.type === ProjectType.SITE || parentProject?.type === ProjectType.FOLDER)
                    ) {
                        projectsAdapter.updateOne(state.projects, {
                            id: parentProject.id,
                            changes: { size: parentProject.size + project.size }
                        });
                    }
                }
                projectsAdapter.removeOne(state.projects, meta.arg.projectId);
            })
            .addCase(moveProjects.fulfilled, (state, { meta }) => {
                for (const projectId of meta.arg.projectIds) {
                    const project = state.projects.entities[projectId];
                    const parent = meta.arg.parent;

                    if (parent !== 'unset') {
                        const parentProject = state.projects.entities[parent.uid!];
                        if (
                            project &&
                            (parentProject?.type === ProjectType.SITE || parentProject?.type === ProjectType.FOLDER)
                        ) {
                            projectsAdapter.updateOne(state.projects, {
                                id: parentProject.id,
                                changes: { size: parentProject.size + project.size }
                            });
                        }
                    }
                    projectsAdapter.removeOne(state.projects, projectId);
                }
            })
            .addCase(getTotalSharedProjects.fulfilled, (state, { payload }) => {
                if (payload !== undefined) {
                    state.amountOfSharedProjects = payload;
                }
            })
            .addCase(deleteProjectFromSharedWithMe.fulfilled, (state, { meta }) => {
                projectsAdapter.removeOne(state.projects, meta.arg);
                state.amountOfSharedProjects!--;
            })
            .addCase(updateSurveyDate.pending, (state, { meta }) => {
                projectsAdapter.updateOne(state.projects, {
                    id: meta.arg.projectId,
                    changes: { surveyDate: meta.arg.surveyDate }
                });
            });
    }
});

export const {
    changeProjectPropertyInPage,
    setSearchText,
    setCurrentProjectsPage,
    setSelectedProjects,
    setSortMode,
    resetProjects,
    setSortOrder,
    setIsLoading
} = projectsPageSlice.actions;

export default projectsPageSlice.reducer;

export const {
    selectAll: selectProjects,
    selectById: selectProjectById,
    selectTotal: selectTotalProjects,
    selectIds: selectProjectIds,
    selectEntities: selectProjectsEntities
} = projectsAdapter.getSelectors((state: ApplicationState) => state.projectsPage.projects);

export const selectSelectedProjects = createSelector(
    selectProjects,
    (state: ApplicationState) => state.projectsPage.selected,
    (projects, selected) => projects.filter(p => selected.includes(p.id))
);
