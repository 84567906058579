import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../../store';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import BlockSwitchToCommercialPlanModal from '../../../components/Elements/modals/block-switch-to-commercial-plan-modal/BlockSwitchToCommercialPlanModal';
import UpgradePlanModal from '../../../components/Elements/modals/upgrade-plan-modal/UpgradePlanModal';
import { Routes } from '../../../sharedConstants';

export default function UpgradePlanButton() {
    const { t } = useTranslation('cabinet');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const lastName = useSelector(state => state.user.lastName);
    const billingAddress = useSelector(state => state.user.billingAddress);
    const hasActiveProcessings = useSelector(state => state.accountResources.account.hasActiveProcessings);
    const isAccountUpdateInProgress = useSelector(state => state.globalFlags.isAccountUpdateInProgress);
    const needsToFillBillingAddress = _.isEmpty(billingAddress) || _.some(billingAddress, field => !field) || !lastName;

    return (
        <>
            {needsToFillBillingAddress || hasActiveProcessings ? (
                <>
                    <button
                        type='button'
                        className='btn btn-block'
                        onClick={() => {
                            if (hasActiveProcessings) setIsModalOpen(true);
                            else setIsModalOpen2(true);
                        }}
                        disabled={isAccountUpdateInProgress}
                    >
                        {t('plans.upgradePlan')}
                    </button>
                    {isModalOpen && (
                        <BlockSwitchToCommercialPlanModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
                    )}
                    {isModalOpen2 && <UpgradePlanModal isOpen={isModalOpen2} setIsOpen={setIsModalOpen2} />}
                </>
            ) : (
                <Link
                    to={{ pathname: Routes.UPGRADE_PLAN }}
                    className={classNames('btn btn-block', { disabled: isAccountUpdateInProgress })}
                >
                    {t('plans.upgradePlan')}
                </Link>
            )}
        </>
    );
}
