import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './helpers';
import { ProcessStatus } from '../generated/cloud-frontend-api';

type ProcessingStepStatus = ProcessStatus | 'PENDING';

export interface ProcessStepInfo {
    name: string;
    info: string;
    status: ProcessingStepStatus;
    progress: number;
    lastError: string | undefined | null;
    processingTimeInSeconds: number;
}

export interface ProcessingInfo {
    items: ProcessStepInfo[];
    last_error: { message: string } | null;
    status: ProcessingStepStatus;
    progress: number;
    startDate: string;
}

export const processingApi = createApi({
    reducerPath: 'processing',
    baseQuery: axiosBaseQuery({ baseUrl: '/api/' }),
    endpoints(build) {
        return {
            getProcessingInfo: build.query<ProcessingInfo, { projectId: string; processId: string }>({
                query: ({ processId, projectId }) => ({
                    url: `projects/${projectId}/processing/${processId}`,
                    method: 'get'
                })
            })
        };
    }
});

export const { useGetProcessingInfoQuery } = processingApi;

export function reduceProcessingStepsStatus(items: ProcessStepInfo[]): ProcessingStepStatus {
    for (const status of [
        ProcessStatus.FAILED,
        ProcessStatus.INPROGRESS,
        ProcessStatus.ABORTED,
        ProcessStatus.ABORTING,
        'PENDING'
    ]) {
        if (items.some(i => i.status === status)) return status as ProcessingStepStatus;
    }

    if (items.every(i => i.status === ProcessStatus.COMPLETED)) return ProcessStatus.COMPLETED;

    return 'PENDING' as const;
}
