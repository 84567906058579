import { useTranslation } from 'react-i18next';

import styles from './ViewerError.module.scss';

type Props = {
    type: 'nowebgl' | 'badrender';
};

export default function ViewerError({ type }: Props) {
    const { t } = useTranslation(['projectView']);

    return (
        <div className={styles.viewerErrorPlaceholder}>
            <div className={styles.viewerError}>
                <div className={styles.viewerErrorTitle}>{t('projectView:viewerError.head')}</div>
                <div className={styles.viewerErrorText}>
                    {type === 'badrender' ? t('projectView:viewerError.render') : t('projectView:viewerError.noWebGL')}
                </div>
            </div>
        </div>
    );
}
