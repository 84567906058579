import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Provider } from 'react-bus';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BooleanParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';
import ArtifactViewer from '../components/ProjectView/ArtifactViewer';
import ViewerSplit from '../components/ProjectView/ArtifactViewer/ViewerSplit';
import MapSettings from '../components/ProjectView/MapSettings/MapSettings';
import BottomBar from '../components/ProjectView/bottom-bar/BottomBar';
import InspectOverlay from '../components/ProjectView/cameras-inspection/InspectOverlay';
import InspectedImageContainer from '../components/ProjectView/cameras-inspection/InspectedImageContainer';
import DarkenedOverlay from '../components/ProjectView/darkened-overlay/DarkenedOverlay';
import DatasetProgressPoller from '../components/ProjectView/dataset-progress-poller/DatasetProgressPoller';
import ElevationProfileExpandedTool from '../components/ProjectView/elevation-profile/ElevationProfileExpandedTool';
import EmbedProjectInfo from '../components/ProjectView/embed-project-info/EmbedProjectInfo';
import ImageViewer from '../components/ProjectView/image-viewer/ImageViewer';
import MapControls from '../components/ProjectView/map-controls/MapControls';
import ViewpointDescription from '../components/ProjectView/viewpoint-description/ViewpointDescription';
import TourPlayer from '../components/ProjectView/tour-player/TourPlayer';
import UndoOperation from '../components/ProjectView/undo-operation/UndoOperation';
import ViewLoader from '../components/ProjectView/view-loader/ViewLoader';
import ViewerError from '../components/ProjectView/viewer-error/ViewerError';
import ProjectsPlaceholder from '../components/Projects/ProjectsPlaceholder';
import InspectionSidebar, { TWO_PANELS_MIN_WIDTH } from '../components/inspection-sidebar/InspectionSidebar';
import ProjectStructureSidebar from '../components/project-structure-sidebar/ProjectStructureSidebar';
import FlyToTilesetsContext from '../contexts/FlyToTilesetsContext';
import ProjectViewAccessContext, { ProjectViewAccessContextValue } from '../contexts/ProjectViewAccessContext';
import { Status } from '../generated/dataset-api';
import useGetElementDimensions from '../hooks/useGetElementDimensions';
import useSidebarsToggleButtonsReposition from '../hooks/useSidebarsToggleButtonsReposition';
import { EmbedViewMode, TerrainViewModes, WEBGL_SUPPORTED } from '../sharedConstants';
import { AppDispatch, useSelector } from '../store';
import { selectedCameraSelector } from '../store/selectors';
import { createLoadingSelector } from '../store/selectors/createLoadingSelector';
import { getDefaultCoordinateSystems, setProjectCoordinateSystems } from '../store/slices/coordinateSystems';
import { selectIonBaseLayersAssetIds } from '../store/slices/init';
import { resetPresentationState } from '../store/slices/presentation';
import { getProjectById } from '../store/slices/project';
import {
    setBaseImageryProvider,
    setInspectorVisibility,
    setSidebarVisibility,
    setTerrainViewMode
} from '../store/slices/projectView';
import { setEmbedCode } from '../store/slices/sharing';
import { ReactComponent as SvgPlay } from '../svg/embed_controls/play.svg';
import embedProjectTheme from './color-themes/EmbedProjectTheme';
import EmbeddedProjectHead from './components/EmbeddedProjectHead';

const loadingSelector = createLoadingSelector([getProjectById.typePrefix]);

export default function EmbeddedProjectView() {
    const dispatch: AppDispatch = useDispatch();
    const project = useSelector(state => state.project);
    const loading = useSelector(state => loadingSelector(state));
    const isCesiumLoaded = useSelector(state => state.projectView.isCesiumLoaded);
    const isGalleryVisible = useSelector(state => state.projectView.galleryVisibility.isVisible);
    const ionBaseLayersAssetIds = useSelector(state => selectIonBaseLayersAssetIds(state));
    const hasCesiumError = useSelector(state => state.projectView.hasCesiumError);
    const isElevationProfileExpanded = useSelector(state => state.projectView.isElevationProfileExpanded);
    const isTerrainProviderLoaded = useSelector(state => state.projectView.isTerrainProviderLoaded);
    const isPresentationSetupEnabled = useSelector(state => state.projectView.isPresentationSetupEnabled);
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const isCamerasInspectionEnabled = useSelector(state => state.projectView.isCamerasInspectionEnabled);
    const isSidebarExpanded = useSelector(state => state.projectView.isSidebarExpanded);
    const selectedCamera = useSelector(state => selectedCameraSelector(state));
    const [hasQueriedForProject, setHasQueriedForProject] = useState(false);
    const [isProjectCrsUpdated, setIsProjectCrsUpdated] = useState(false);
    const datasets = useSelector(state => state.datasets.datasets);

    const wrapperRef = useRef<HTMLDivElement>(null!);
    const embedCover = useRef<HTMLDivElement>(null!);
    const [flyToTilesetsContextValue, setFlyToTilesetsContextValue] = useState<{ ids: string | string[] | null }>({
        ids: null
    });

    const wrapperWidth = useGetElementDimensions(wrapperRef).width || 0;
    const wrapperHeight = useGetElementDimensions(wrapperRef).height || 0;

    const { embed, id } = useParams<EmbeddedProjectViewRouteParams>();

    const [query, setQuery] = useQueryParams({
        autoplay: BooleanParam,
        baseImageryProvider: NumberParam,
        terrainViewMode: NumberParam,
        name: BooleanParam,
        mode: StringParam
    });
    const baseImageryProvider = query.baseImageryProvider && String(query.baseImageryProvider);
    const terrainViewMode = query.terrainViewMode && (query.terrainViewMode as TerrainViewModes);
    const embedViewMode = query?.mode as EmbedViewMode | undefined;
    // backwards compatibility: old embeds don't have mode param, but should be displayed full-featuredly
    const isFullFeatured = embedViewMode === EmbedViewMode.FULL || !embedViewMode;

    const showTourPlayer = isCesiumLoaded && ((isFullFeatured && isPresentationSetupEnabled) || !isFullFeatured);
    const showOverlayToolsArea = showTourPlayer || isElevationProfileExpanded;

    const [accessModeContextValue, setAccessModeContextValue] = useState<ProjectViewAccessContextValue>({
        owned: false,
        rejectedProject: false,
        isInEmbedView: true,
        embedViewMode: embedViewMode || EmbedViewMode.FULL
    });

    useEffect(() => {
        dispatch(setSidebarVisibility(false));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setEmbedCode(embed));
        dispatch(getProjectById({ id, embed })).finally(() => {
            setHasQueriedForProject(true);
        });
    }, [dispatch, id, embed, setHasQueriedForProject]);

    useEffect(() => {
        if (isFullFeatured && hasQueriedForProject && !isProjectCrsUpdated) {
            dispatch(getDefaultCoordinateSystems());
            dispatch(setProjectCoordinateSystems(project.projectInfo?.coordinateSystemInfo));
            setIsProjectCrsUpdated(true);
        }
    }, [
        dispatch,
        isFullFeatured,
        project?.projectInfo.coordinateSystemInfo,
        hasQueriedForProject,
        isProjectCrsUpdated,
        setIsProjectCrsUpdated
    ]);

    useEffect(() => {
        if (baseImageryProvider) {
            // Backwards compatibility for old embeds which have base layer specified
            if (ionBaseLayersAssetIds.includes(baseImageryProvider))
                dispatch(setBaseImageryProvider(baseImageryProvider));
        }
        if (terrainViewMode) dispatch(setTerrainViewMode(terrainViewMode));
    }, [baseImageryProvider, terrainViewMode, dispatch, ionBaseLayersAssetIds]);

    // apply/remove embed theme
    useEffect(() => {
        const documentElementStyle = document.documentElement.style;
        for (const property in embedProjectTheme) {
            documentElementStyle.setProperty(property, embedProjectTheme[property]);
        }
        return () => {
            for (const property in embedProjectTheme) {
                documentElementStyle.removeProperty(property);
            }
            dispatch(resetPresentationState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (wrapperWidth <= TWO_PANELS_MIN_WIDTH && isSidebarExpanded) {
            dispatch(setInspectorVisibility(false));
        }
    }, [wrapperWidth, dispatch, isSidebarExpanded]);

    const isLoading = loading || !isCesiumLoaded;
    const isDeletedOrNonExistent = _.isEmpty(project.projectInfo);
    const isPlaceholderVisible = isDeletedOrNonExistent && hasQueriedForProject;
    const isBlockedByTerrainProvider = !isLoading && !isTerrainProviderLoaded;

    // handle sidebars toggle buttons y-position
    const [elevationProfileTopCoord, setElevationProfileTopCoord] = useState<number>();
    const sidebarsToggleTransition = useSidebarsToggleButtonsReposition(
        wrapperRef.current,
        wrapperHeight,
        elevationProfileTopCoord
    );

    const showName = !!query.name;

    return (
        <Provider>
            <ProjectViewAccessContext.Provider value={accessModeContextValue}>
                <FlyToTilesetsContext.Provider
                    value={{
                        clickedTilesets: flyToTilesetsContextValue,
                        setClickedTilesetIds(newClickedTilesets) {
                            setFlyToTilesetsContextValue(newClickedTilesets);
                        }
                    }}
                >
                    {!query.autoplay && (
                        <div
                            ref={embedCover}
                            onClick={e => {
                                setQuery({ autoplay: true });
                            }}
                            className='embed-cover'
                            style={{ backgroundImage: `url(${project.projectInfo.preview}&embed=${embed})` }}
                        >
                            <EmbedProjectInfo showName={showName} projectName={project.projectInfo.name} />
                            <div className='embed-play'>
                                <SvgPlay />
                            </div>
                        </div>
                    )}
                    {!isPlaceholderVisible ? (
                        <>
                            <div id='wrapper'>
                                <EmbeddedProjectHead showName={showName} projectName={project.projectInfo.name} />

                                <div id='mapWrapper' className='embed-wrapper' ref={wrapperRef}>
                                    {loading && (
                                        <div className='embed-loading'>
                                            <ViewLoader type='loading' />
                                        </div>
                                    )}

                                    {!loading && query.autoplay && (
                                        <>
                                            <div
                                                className='map-placeholder'
                                                style={{ visibility: isCesiumLoaded ? 'visible' : 'hidden' }}
                                            >
                                                {WEBGL_SUPPORTED && <ArtifactViewer />}
                                                {isCompareToolEnabled && <ViewerSplit />}
                                            </div>

                                            {hasCesiumError && <ViewerError type='badrender' />}
                                            {!WEBGL_SUPPORTED && <ViewerError type='nowebgl' />}

                                            <ViewpointDescription />

                                            {isBlockedByTerrainProvider && (
                                                <div className='map-loading'>
                                                    <ViewLoader type='blocked' />
                                                </div>
                                            )}

                                            <div id='mapOverlay'>
                                                {isFullFeatured && (
                                                    <ProjectStructureSidebar
                                                        projectName={project.projectInfo.name!}
                                                        wrapperWidth={wrapperWidth}
                                                        sidebarsToggleTransition={sidebarsToggleTransition}
                                                    />
                                                )}

                                                <div className={'overlay-area'}>
                                                    {isCamerasInspectionEnabled && <InspectOverlay />}
                                                    <MapControls loading={isLoading} />
                                                    <MapSettings
                                                        wrapperWidth={wrapperWidth}
                                                        wrapperHeight={wrapperHeight}
                                                    />

                                                    <DarkenedOverlay />
                                                    {showOverlayToolsArea && (
                                                        <div
                                                            className={classNames('overlay-tools-area', {
                                                                'with-profile': isElevationProfileExpanded
                                                            })}
                                                        >
                                                            {showTourPlayer && <TourPlayer isEmbedView={true} />}

                                                            {isElevationProfileExpanded && (
                                                                <ElevationProfileExpandedTool
                                                                    getToolTopCoord={setElevationProfileTopCoord}
                                                                    wrapperHeight={wrapperHeight}
                                                                />
                                                            )}
                                                        </div>
                                                    )}

                                                    {isCamerasInspectionEnabled && selectedCamera && (
                                                        <InspectedImageContainer camera={selectedCamera} />
                                                    )}
                                                </div>

                                                {isFullFeatured && !isCompareToolEnabled && (
                                                    <InspectionSidebar
                                                        wrapperWidth={wrapperWidth}
                                                        sidebarsToggleTransition={sidebarsToggleTransition}
                                                    />
                                                )}
                                            </div>

                                            {isFullFeatured && <BottomBar />}

                                            <UndoOperation />
                                        </>
                                    )}
                                </div>
                            </div>
                            {isGalleryVisible && <ImageViewer />}
                        </>
                    ) : (
                        <div id='wrapper'>
                            <div className='content-centered'>
                                {isDeletedOrNonExistent && <ProjectsPlaceholder type={'deleted'} />}
                            </div>
                        </div>
                    )}
                </FlyToTilesetsContext.Provider>

                {datasets
                    .filter(d => d.visualData?.status === Status.IN_PROGRESS)
                    .map(dataset => (
                        <DatasetProgressPoller
                            key={dataset.datasetUid}
                            dataset={dataset}
                            hasQueriedForProject={hasQueriedForProject}
                        />
                    ))}
            </ProjectViewAccessContext.Provider>
        </Provider>
    );
}

export interface EmbeddedProjectViewRouteParams {
    id: string;
    embed: string;
}
