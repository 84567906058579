import Tippy from '@tippyjs/react/headless';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useDrawingCancel from '../../../hooks/useDrawingCancel';
import { GeometryTypes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { disableAllControls, setSelectedGeometryType } from '../../../store/slices/projectView';
import RulerControl from './RulerControl';

export default function AddGeometryControls() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('glossary');
    const selectedGeometryType = useSelector(state => state.projectView.selectedGeometryType);
    const isCompareToolEnabled = useSelector(state => state.projectView.isCompareToolEnabled);
    const isCamerasInspectionEnabled = useSelector(state => state.projectView.isCamerasInspectionEnabled);
    const isPresentationSetupEnabled = useSelector(state => state.projectView.isPresentationSetupEnabled);

    const showPrimitivesControls = !isCompareToolEnabled && !isCamerasInspectionEnabled && !isPresentationSetupEnabled;

    const cancelDrawing = useDrawingCancel();

    return (
        <div className={'add-element-block'}>
            <RulerControl />
            {showPrimitivesControls &&
                Object.values(GeometryTypes).map(geometryType => (
                    <Tippy
                        key={geometryType}
                        render={attrs => (
                            <div className='tippy-tooltip' tabIndex={-1} {...attrs}>
                                {geometryType === GeometryTypes.POINT && t('geojsonGeometryTypes.point')}
                                {geometryType === GeometryTypes.POLYLINE && t('geojsonGeometryTypes.lineString')}
                                {geometryType === GeometryTypes.POLYGON && t('geojsonGeometryTypes.polygon')}
                            </div>
                        )}
                        delay={[700, 100]}
                        offset={[0, 8]}
                        touch={false}
                    >
                        <div
                            key={geometryType}
                            className={classNames('control-btn', { active: selectedGeometryType === geometryType })}
                            onClick={e => {
                                cancelDrawing();
                                dispatch(disableAllControls());
                                dispatch(
                                    setSelectedGeometryType(
                                        selectedGeometryType === geometryType ? undefined : geometryType
                                    )
                                );
                            }}
                        >
                            <i
                                data-testid={geometryType}
                                className={classNames('icon', {
                                    'icon-point': geometryType === GeometryTypes.POINT,
                                    'icon-polyline': geometryType === GeometryTypes.POLYLINE,
                                    'icon-polygon': geometryType === GeometryTypes.POLYGON
                                })}
                            />
                        </div>
                    </Tippy>
                ))}
        </div>
    );
}
