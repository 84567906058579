import React from 'react';
import { CesiumMovementEvent, ScreenSpaceEvent } from 'resium';
import * as Cesium from 'cesium';
import useDrawing from './useDrawing';
import { AppDispatch, useSelector } from '../../../store';
import { useDispatch } from 'react-redux';
import { GeoJson } from '../../../store/helpers/interfaces';
import { GeometryTypes } from '../../../sharedConstants';
import { addedPointToRulerGeometry, createdRulerGeometry } from '../../../store/slices/rulerTool';

type Props = {
    lastPickedCartographicDegrees: number[];
};

export default function DrawRulerLine({ lastPickedCartographicDegrees }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { geometry } = useSelector(state => state.rulerTool);

    async function draw(coordinates: number[]) {
        if (!geometry) {
            const geoJson: GeoJson<GeometryTypes.POLYLINE> = {
                type: 'Feature',
                geometry: { type: GeometryTypes.POLYLINE, coordinates: [coordinates] },
                properties: { ac_name: '', ac_color: '#fff', ac_visibility: true }
            };
            dispatch(createdRulerGeometry(geoJson));
        } else {
            dispatch(addedPointToRulerGeometry({ coordinates }));
        }
    }

    return (
        <ScreenSpaceEvent
            type={Cesium.ScreenSpaceEventType.LEFT_CLICK}
            action={(e: CesiumMovementEvent) => {
                const coordinates = lastPickedCartographicDegrees;
                if (coordinates) draw(coordinates);
            }}
        />
    );
}
