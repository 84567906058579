import * as Cesium from 'cesium';
import { convertLengthUnit } from '../../../lib/convertUnit';
import { getUnitsFullName } from '../../../lib/getUnitsFullName';
import { Units } from '../../../sharedConstants';
import { ElevationProfileResult } from '../../../store/helpers/elevation-profile/ElevationProfile';

export default function generateElevationProfileCSVData(data: ElevationProfileResult, units: Units) {
    const unitsName = getUnitsFullName(units);
    let profileHeaders = `"Latitude (deg)","Longitude (deg)",`;
    for (const { name } of data.altitudes) {
        profileHeaders = profileHeaders.concat(`"Altitude ${replaceComma(name)} (${unitsName})",`);
    }
    profileHeaders = profileHeaders.concat(`"Distance (${unitsName})"`);

    const addOneLine = (point: Cesium.Cartesian3, index: number) => {
        const { latitude, longitude } = Cesium.Cartographic.fromCartesian(point);
        return [
            Cesium.Math.toDegrees(latitude),
            Cesium.Math.toDegrees(longitude),
            ...data.altitudes.map(({ altitudes }) => convertLengthUnit(altitudes[index], Units.METRE, units)),
            data.distances.map(d => convertLengthUnit(d, Units.METRE, units))[index]
        ].join(',');
    };
    const profileData = data.points.map(addOneLine).join('\n');
    const profile = `${profileHeaders}\n${profileData}`;

    return profile;
}

function replaceComma(str: string) {
    return str.replaceAll(',', '.');
}
