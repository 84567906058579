interface RenderedSize {
    width: number;
    height: number;
    left: number;
    top: number;
}

export default function scaleImage(
    srcWidth: number,
    srcHeight: number,
    targetWidth: number,
    targetHeight: number
): RenderedSize {
    const result: RenderedSize = { width: 0, height: 0, left: 0, top: 0 };

    if (srcWidth <= 0 || srcHeight <= 0 || targetWidth <= 0 || targetHeight <= 0) {
        return result;
    }

    const widthRatio = targetWidth / srcWidth;
    const heightRatio = targetHeight / srcHeight;
    const ratio = Math.min(widthRatio, heightRatio);
    const left = (targetWidth - srcWidth * ratio) / 2;
    const top = (targetHeight - srcHeight * ratio) / 2;
    return {
        width: srcWidth * ratio,
        height: srcHeight * ratio,
        top,
        left
    };
}
