import { ReactNode, SyntheticEvent } from 'react';
import classNames from 'classnames';

type Props = {
    icon: ReactNode;
    text: string;
    alert?: boolean;
    disabled?: boolean;
    active?: boolean;
    onClick: (event: SyntheticEvent) => void;
};

export default function TippyDropdownItemWithIcon({ icon, text, disabled, alert, active, onClick, ...props }: Props) {
    return (
        <div
            className={classNames('tippy-dropdown-item tippy_menu-item', { alert, disabled, active })}
            data-testid={'tippyDropdownItem'}
            onClick={event => {
                if (disabled) {
                    event.preventDefault();
                    event.stopPropagation();
                } else {
                    onClick(event);
                }
            }}
            {...props}
        >
            <div className='control-icon'>{icon}</div>
            <div className='control-text'>{text}</div>
        </div>
    );
}
