import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useDemSurfaceName from '../../../../hooks/useDemSurfaceName';
import { convertLengthUnit, convertVolumeUnit } from '../../../../lib/convertUnit';
import formatNumber from '../../../../lib/formatNumber';
import { getUnitsCubicShortName, getUnitsShortName } from '../../../../lib/getUnitsShortName';
import { GeometryTypes, Units, WGS84_EPSG_CODE } from '../../../../sharedConstants';
import { TemporaryGeometry } from '../../../../store/helpers/interfaces';
import { useSelector } from '../../../../store/index';
import { selectedObjectInfoSelector } from '../../../../store/selectors/index';
import VolumeProperty from './VolumeProperty';
import { volumeBasePlanes } from './VolumeTool';

const precision = 3;

export default function VolumePropertiesList() {
    const { t } = useTranslation('projectView');
    const polygon = useSelector(state => selectedObjectInfoSelector(state)) as TemporaryGeometry<GeometryTypes.POLYGON>;
    const calculation = useSelector(state => state.volume.calculations.find(c => c.id === polygon.id));
    const isCalculationPending = calculation?.status === 'pending';
    const hasCalculationFailed = calculation?.status === 'rejected';
    const units = useSelector(state => state.coordinateSystems.units);
    const unitsNaming = getUnitsShortName(units);
    const unitsCubicNaming = getUnitsCubicShortName(units);
    const coordinateSystem = useSelector(store => store.coordinateSystems.currentCrs);
    const isWgs84 = coordinateSystem.epsgCode === WGS84_EPSG_CODE;

    const {
        ac_volume_base_level_meters,
        ac_volume_above_cubic_meters,
        ac_volume_below_cubic_meters,
        ac_volume_total_cubic_meters,
        ac_volume_base_plane,
        ac_volume_surface
    } = polygon.content.properties;

    const surfaceName = useDemSurfaceName(ac_volume_surface);

    const baseLevelValue = useMemo(() => {
        if (ac_volume_base_level_meters !== undefined) {
            return typeof ac_volume_base_level_meters === 'string'
                ? ac_volume_base_level_meters
                : formatNumber(convertLengthUnit(ac_volume_base_level_meters, Units.METRE, units), 3) +
                      ` ${unitsNaming}`;
        }
        return '';
    }, [ac_volume_base_level_meters, units, unitsNaming]);

    const volumeAbove = hasCalculationFailed
        ? t('volume.errorValue')
        : validateVolume(ac_volume_above_cubic_meters, units, unitsCubicNaming);
    const volumeBelow = hasCalculationFailed
        ? t('volume.errorValue')
        : validateVolume(ac_volume_below_cubic_meters, units, unitsCubicNaming);
    const volumeTotal = hasCalculationFailed
        ? t('volume.errorValue')
        : validateTotalVolume(ac_volume_total_cubic_meters, units, unitsCubicNaming);

    const isValid = !!(
        volumeAbove !== '-' &&
        volumeBelow !== '-' &&
        volumeTotal !== '-' &&
        validateBaseLevel(ac_volume_base_level_meters)
    );

    const baseLevelPropertyValue =
        isValid || ac_volume_base_plane === 'customLevel' || ac_volume_base_plane === 'bestFit' ? baseLevelValue : '-';

    function validateVolume(value: any, units: Units, unitsNaming: string) {
        return Number.isFinite(value) && value >= 0
            ? formatNumber(convertVolumeUnit(value, Units.METRE, units), precision) + ` ${unitsNaming}`
            : '-';
    }

    function validateTotalVolume(value: any, units: Units, unitsNaming: string) {
        return Number.isFinite(value)
            ? formatNumber(convertVolumeUnit(value, Units.METRE, units), precision) + ` ${unitsNaming}`
            : '-';
    }

    function validateBaseLevel(baseLevel: any) {
        return !!(
            (ac_volume_base_plane === 'bestFit' && baseLevel === 'Varies') ||
            (ac_volume_base_plane !== 'bestFit' && Number.isFinite(baseLevel))
        );
    }

    return (
        <div className='properties-list'>
            <VolumeProperty label={t('volume.surface.label')} value={surfaceName || '-'} hideTextOverflow />
            <VolumeProperty
                label={t('volume.basePlane.label')}
                value={volumeBasePlanes().find(bp => bp.name === ac_volume_base_plane)?.displayName || ''}
            />
            {isWgs84 && (
                <VolumeProperty
                    label={t('volume.baseLevel.label')}
                    value={baseLevelPropertyValue}
                    loading={isCalculationPending && !baseLevelValue}
                />
            )}
            <VolumeProperty
                label={t('volume.above.label')}
                loading={isCalculationPending}
                value={isValid ? volumeAbove : '-'}
            />
            <VolumeProperty
                label={t('volume.below.label')}
                loading={isCalculationPending}
                value={isValid ? volumeBelow : '-'}
            />
            <VolumeProperty
                label={t('volume.total.label')}
                loading={isCalculationPending}
                value={isValid ? volumeTotal : '-'}
            />
        </div>
    );
}
