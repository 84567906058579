import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../../store';
import { unpublishProject } from '../../../store/slices/projectActions';
import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import ProjectActionModal from '../../Elements/project-actions/modals/ProjectActionModal';
import { ModalProps } from '../../Elements/modal/Modal';
import { selectProjects, setSelectedProjects } from '../../../store/slices/projectsPage';

type Props = ModalProps & { selectedProjects: string[]; totalPublishedSize: string };

export default function UnpublishSeveralProjectsModal({
    isOpen,
    setIsOpen,
    selectedProjects,
    totalPublishedSize
}: Props) {
    const { t } = useTranslation('projects');
    const dispatch: AppDispatch = useDispatch();
    const projects = useSelector(state => selectProjects(state));

    return (
        <ProjectActionModal
            isOpen={isOpen}
            setIsOpen={() => setIsOpen(false)}
            headerText={t('projectActions.unpublish')}
            areYouSureText={
                <Trans
                    ns={'modals'}
                    i18nKey={
                        selectedProjects.length > 1
                            ? 'unpublishSeveralProjectsModal.body'
                            : 'unpublishProjectModal.body'
                    }
                    components={{ p: <p />, s: <strong className='overflow' /> }}
                    values={{
                        publicationSize: totalPublishedSize,
                        projectsCount: selectedProjects.length,
                        projectName: projects.find(p => p.id === selectedProjects[0])?.name || ''
                    }}
                />
            }
            actionButtonText={t('projectActions.unpublish')}
            cancelButtonText={t('projectActions.cancel')}
            onAction={async () => {
                const promises = selectedProjects.map(p => dispatch(unpublishProject({ id: p, onProjectsPage: true })));
                await Promise.all(promises);
                dispatch(setSelectedProjects([]));
            }}
            onCancel={() => {
                setIsOpen(false);
            }}
        />
    );
}
