import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from '../../../sharedConstants';
import { useSelector } from '../../../store';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { TemporaryGeometry } from '../../../store/helpers/interfaces';
import PreviewImageStub from './PreviewImageStub';
import useDocumentUrl from '../../../hooks/useDocumentUrl';

export function ViewpointPreviewImage() {
    const container = useRef<HTMLDivElement>(null);
    const viewpoint = useSelector(state => selectedObjectInfoSelector(state)) as TemporaryGeometry;
    const idOfGeometryWithSavingPreview = useSelector(state => state.projectView.idOfGeometryWithSavingPreview);
    const isEmbedProjectRoute = !!useRouteMatch({ path: Routes.EMBEDDED_PROJECT_VIEW, exact: true });
    const previewUid = viewpoint.content.properties.ac_preview_uid as string | undefined;
    const [isLoading, setIsLoading] = useState(!!previewUid);
    const [displayStub, setDisplayStub] = useState(false);

    const isIssuePreviewBeingSaved = idOfGeometryWithSavingPreview === viewpoint.id;

    const {
        url: previewUrl,
        isLoading: isPreviewUrlLoading,
        hasFailed,
        hasTriedGettingUrl
    } = useDocumentUrl(previewUid, !isIssuePreviewBeingSaved && !!previewUid);

    const loading = isLoading || isPreviewUrlLoading || isIssuePreviewBeingSaved;
    const failed = hasFailed || displayStub;

    useEffect(() => {
        setDisplayStub(!previewUid);
    }, [previewUid]);

    return (
        <section className='inspector-image' ref={container}>
            <div
                className={classNames('image-wrapper', {
                    loading: loading && !isEmbedProjectRoute,
                    'loading-embed': loading && isEmbedProjectRoute
                })}
            >
                {(hasTriedGettingUrl || failed) && (
                    <>
                        {failed ? (
                            <PreviewImageStub stubType={'viewer'} />
                        ) : (
                            <img
                                key={previewUid}
                                src={previewUrl}
                                alt={''}
                                className={'image-source'}
                                loading={'lazy'}
                                onError={() => {
                                    setIsLoading(false);
                                    setDisplayStub(true);
                                }}
                                onLoad={e => {
                                    setIsLoading(false);
                                }}
                            />
                        )}
                    </>
                )}
            </div>
        </section>
    );
}
