import { useTranslation } from 'react-i18next';
import CompareStructureTreeContext from '../../../contexts/CompareStructureTreeContext';
import { useSelector } from '../../../store';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import formatSurveyDate from '../../ProjectView/breadcrumbs/helpers/formatSurveyDate';
import StructureTree from '../structure-tree/StructureTree';
import { selectCompareToolComparedProject } from '../../../store/slices/compareTool';

export default function CompareSiteToolSidebar() {
    const { t } = useTranslation('projectView');
    const project = useSelector(state => state.project.projectInfo);
    const comparedProject = useSelector(state => selectCompareToolComparedProject(state));

    return (
        <>
            <section className='section-title compare-title'>
                <div className='tline-number tline-l'>{t('compareMode.L')}</div>
                <TippyTooltip tooltipText={project.name || ''}>
                    <div className='project-title-wrapper'>
                        <div className='project-title with-subtitle'>{project.name || ''}</div>
                        <div className={'project-subtitle'}>{formatSurveyDate(project.surveyDate) || '-'}</div>
                    </div>
                </TippyTooltip>
            </section>
            <section className='section-artefacts'>
                <CompareStructureTreeContext.Provider value={{ treeId: 'tree1' }}>
                    <StructureTree />
                </CompareStructureTreeContext.Provider>
            </section>
            <div className='compare-delimiter' />
            <section className='section-title compare-title'>
                <div className='tline-number tline-r'>{t('compareMode.R')}</div>
                <TippyTooltip tooltipText={comparedProject?.projectInfo.name || ''}>
                    <div className='project-title-wrapper'>
                        <div className='project-title with-subtitle'>{comparedProject?.projectInfo.name || ''}</div>
                        <div className={'project-subtitle'}>
                            {formatSurveyDate(comparedProject?.projectInfo.surveyDate) || '-'}
                        </div>
                    </div>
                </TippyTooltip>
            </section>
            <section className='section-artefacts'>
                <CompareStructureTreeContext.Provider value={{ treeId: 'tree2' }}>
                    <StructureTree />
                </CompareStructureTreeContext.Provider>
            </section>
        </>
    );
}
