import axiosRetry from 'axios-retry';
import { saveAs } from 'file-saver';
import { BlobWriter, TextReader, ZipWriter } from '@zip.js/zip.js';
import { documentApi } from '../../../api/initApis';
import appAxios from '../../../api/appAxios';

interface Downloader<T = any> {
    download(projectUid: string | undefined, ...args: any): Promise<T>;
}

export class ProcessingLogsDownloader implements Downloader<void> {
    async download(
        projectUid: string,
        filename: string,
        documents: Map<string, string>,
        access?: string
    ): Promise<void> {
        const zipFileName = filename + '.zip';
        const zip = new ZipWriter(new BlobWriter());

        for (const [documentUid, documentFilename] of documents) {
            const {
                data: { url }
            } = await documentApi.getPresignedUrl(projectUid, documentUid, access, {
                'axios-retry': {
                    retries: 5,
                    retryDelay: (retryCount, error) => axiosRetry.exponentialDelay(retryCount, error, 2000)
                }
            });

            if (url !== undefined) {
                const response = await appAxios.request({
                    url: url,
                    method: 'GET',
                    'axios-retry': {
                        retries: 5,
                        retryDelay: (retryCount, error) => axiosRetry.exponentialDelay(retryCount, error, 2000)
                    }
                });

                zip.add(documentFilename, new TextReader(response.data));
            }
        }

        zip.close().then(function (content) {
            saveAs(content, zipFileName);
        });
    }
}
