import { object, string } from 'yup';

const country = string().required('Country is required');
const city = string().trim().required('City is required').max(100, "This field can't exceed 100 symbols");
const region = string().trim().required('Region is required').max(100, "This field can't exceed 100 symbols");
const postalCode = string().trim().required('Postal code is required').max(15, "This field can't exceed 15 symbols");
const address = string().trim().required('Address is required').max(256, "This field can't exceed 256 symbols");
const company = string().trim().max(100, "This field can't exceed 100 symbols");

export const CommercialPlanProfileSchema = object().shape({
    country,
    region,
    city,
    postalCode,
    address,
    company
});
