import { SelectProps } from 'antd';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { formatDuration } from '../../../i18n/dateTimeWrappers';
import { GeometryTypes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { TemporaryGeometry } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { isGeometryPropertiesBlockExpanded } from '../../../store/slices/geometries';
import { updateGeometryPropertyById } from '../../../store/slices/geometryLayers';
import CopyPropertiesControl from '../../ProjectView/geometry-properties/elements/CopyPropertiesControl';
import PropertiesBlockHead from '../../ProjectView/geometry-properties/elements/PropertiesBlockHead';
import SelectProperty from '../../ProjectView/geometry-properties/SelectProperty';
import TextareaProperty from '../../ProjectView/geometry-properties/TextareaProperty';
import { Viewpoint } from '../../../entities/Viewpoint';

type Props = {
    viewOnly: boolean;
    containerRef: HTMLDivElement;
};

const DURATION_OPTIONS_SECONDS = [1, 2, 5, 10, 15, 30];

export default function SlideGeneralProperties({ viewOnly, containerRef }: Props) {
    const { t, i18n } = useTranslation(['projectView']);
    const dispatch: AppDispatch = useDispatch();
    const { owned } = useContext(ProjectViewAccessContext);
    const viewpoint = useSelector(state => selectedObjectInfoSelector(state)) as TemporaryGeometry<GeometryTypes.POINT>;
    const idOfGeometryWithSavingPreview = useSelector(state => state.projectView.idOfGeometryWithSavingPreview);
    const description = viewpoint.content.properties.description || viewpoint.content.properties.ac_description || '';
    const duration = viewpoint.content.properties.ac_duration ?? Viewpoint.DEFAULT_DURATION_SECONDS;
    const generalPropertiesBlockExpanded = isGeometryPropertiesBlockExpanded(
        viewpoint.content,
        'ac_general_block_expanded'
    );

    const isIssuePreviewBeingSaved = idOfGeometryWithSavingPreview === viewpoint.id;

    const textForCopying = `${t('projectView:slideProperties.duration')}\t${duration}\n${t(
        'projectView:slideProperties.description'
    )}\t${description}`;

    const durationOptions: SelectProps['options'] = useMemo(
        () =>
            DURATION_OPTIONS_SECONDS.map(d => ({
                label: formatDuration({ seconds: d }, {}, i18n.resolvedLanguage!),
                value: String(d)
            })),
        [i18n.resolvedLanguage]
    );

    const durationString = formatDuration({ seconds: duration! }, {}, i18n.resolvedLanguage!);

    return (
        <div className='inspector-properties'>
            <PropertiesBlockHead
                isBlockExpanded={generalPropertiesBlockExpanded}
                title={t('projectView:inspectionSidebar.geometryProperties.titleGeneral')}
                onClick={e => {
                    dispatch(
                        updateGeometryPropertyById({
                            id: viewpoint.id,
                            owned,
                            propName: 'ac_general_block_expanded',
                            propValue: !generalPropertiesBlockExpanded
                        })
                    );
                }}
            >
                <CopyPropertiesControl clipboardText={textForCopying} />
            </PropertiesBlockHead>
            {generalPropertiesBlockExpanded && (
                <div className='properties-list'>
                    {viewOnly || isIssuePreviewBeingSaved ? (
                        <>
                            <div className='property'>
                                <div className='prop-wrapper read-only'>
                                    <div className='prop-label-wrapper'>
                                        <div className='prop-label prop-text'>
                                            {t('projectView:slideProperties.duration')}
                                        </div>
                                    </div>
                                    <div className='prop-value-wrapper'>
                                        <div className='prop-input prop-text'>{durationString}</div>
                                    </div>
                                </div>
                                <div className='prop-action' />
                            </div>
                            <div className='property'>
                                <div className='prop-wrapper read-only'>
                                    <div className='prop-label-wrapper'>
                                        <div className='prop-label prop-text'>
                                            {t('projectView:slideProperties.description')}
                                        </div>
                                    </div>
                                    <div className='prop-value-wrapper'>
                                        <div className='prop-input prop-text'>{description!}</div>
                                    </div>
                                </div>
                                <div className='prop-action' />
                            </div>
                        </>
                    ) : (
                        <>
                            <SelectProperty
                                disabled={viewOnly}
                                label={t('projectView:slideProperties.duration')}
                                value={String(duration)}
                                onChange={newValue => {
                                    dispatch(
                                        updateGeometryPropertyById({
                                            id: viewpoint.id,
                                            owned,
                                            propName: 'ac_duration',
                                            propValue: Number(newValue)
                                        })
                                    );
                                }}
                                containerRef={containerRef}
                                options={durationOptions}
                            />
                            <TextareaProperty
                                label={t('projectView:slideProperties.description')}
                                value={description}
                                readOnly={viewOnly}
                                onSubmit={values => {
                                    dispatch(
                                        updateGeometryPropertyById({
                                            id: viewpoint.id,
                                            owned,
                                            propName: 'ac_description',
                                            propValue: values.value
                                        })
                                    );
                                }}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
