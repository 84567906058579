import { unwrapResult } from '@reduxjs/toolkit';
import * as Cesium from 'cesium';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { batchActions } from 'redux-batched-actions';
import { BillboardGraphics, CesiumMovementEvent, Entity, ScreenSpaceEvent, useCesium } from 'resium';
import image from '../../../assets/images/inspection_point.svg';
import wgs84ToCartesian3 from '../../../lib/wgs84ToCartesian3';
import { AppDispatch, useSelector } from '../../../store';
import { filterCamerasByPoint } from '../../../store/slices/cameras';
import { setCameraFilteringPoint, setFilteredCamerasPointProjection } from '../../../store/slices/projectView';
import useDrawing from '../geometry-drawing/useDrawing';

export default function CamerasInspection() {
    const dispatch: AppDispatch = useDispatch();
    const { scene } = useCesium();
    const isCamerasInspectionEnabled = useSelector(state => state.projectView.isCamerasInspectionEnabled);
    const isRulerToolEnabled = useSelector(state => state.projectView.isRulerToolEnabled);
    const cameraFilteringPoint = useSelector(state => state.projectView.cameraFilteringPoint);
    const cameras = useSelector(state => state.cameras);
    const { pickCartographicDegrees } = useDrawing();

    const position = useMemo(
        () => (cameraFilteringPoint ? (wgs84ToCartesian3(cameraFilteringPoint, scene!) as Cesium.Cartesian3) : null),
        [cameraFilteringPoint, scene]
    );

    useEffect(() => {
        scene?.requestRender();
    }, [position, scene]);

    useEffect(() => {
        if (position) {
            dispatch(filterCamerasByPoint({ scene: scene!, point: position }))
                .then(unwrapResult)
                .then(pointProjectionsOnCameras => {
                    if (!pointProjectionsOnCameras.length) {
                        dispatch(setFilteredCamerasPointProjection([]));
                        return;
                    }
                    dispatch(setFilteredCamerasPointProjection(pointProjectionsOnCameras));
                    scene?.requestRender();
                });
        }
    }, [position, scene, dispatch, cameras]);

    return isCamerasInspectionEnabled ? (
        <>
            {!isRulerToolEnabled && (
                <ScreenSpaceEvent
                    type={Cesium.ScreenSpaceEventType.LEFT_CLICK}
                    action={(e: CesiumMovementEvent) => {
                        const cartographicDegrees = pickCartographicDegrees(e.position!);
                        if (cartographicDegrees) dispatch(setCameraFilteringPoint(cartographicDegrees));
                    }}
                />
            )}
            {position && (
                <Entity position={position}>
                    <BillboardGraphics
                        disableDepthTestDistance={Infinity}
                        image={image}
                        heightReference={Cesium.HeightReference.NONE}
                    />
                </Entity>
            )}
        </>
    ) : null;
}
