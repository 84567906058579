import { useState, useEffect, useCallback } from 'react';
import { ReactComponent as SvgClose } from '../../../svg/general/x-icon.svg';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../../store';
import { globalFlagsActions } from '../../../store/slices/globalFlags';

// full timeout = HIDE_TIMEOUT + HIDE_ANIMATION_DURATION:
const HIDE_TIMEOUT = 6700; // = before starting hide animation (ms)
const HIDE_ANIMATION_DURATION = 300; // = animation duration (ms)

const INLINE_ANIMATION_DURATION = '0.3s'; // = HIDE_ANIMATION_DURATION above in seconds

let timeout = 0;
let animationTimeout = 0;

export default function ServerErrorNotification() {
    const dispatch: AppDispatch = useDispatch();
    const { serverErrorNotification } = useSelector(state => state.globalFlags);
    const [useHideAnimation, setUseHideAnimation] = useState(false);

    const hideNotification = useCallback(() => {
        setUseHideAnimation(true);
        animationTimeout = window.setTimeout(() => {
            dispatch(globalFlagsActions.clearServerErrorNotification());
        }, HIDE_ANIMATION_DURATION);
        return () => {
            setUseHideAnimation(false);
        };
    }, [dispatch]);

    useEffect(() => {
        if (serverErrorNotification) {
            timeout = window.setTimeout(() => {
                hideNotification();
            }, HIDE_TIMEOUT);
        }
    }, [serverErrorNotification, hideNotification]);

    useEffect(() => {
        return () => {
            clearTimeout(timeout);
            clearTimeout(animationTimeout);
        };
    }, []);

    return (
        <div
            className={classNames('server-error', { hideAnimation: useHideAnimation })}
            style={{ transitionDuration: useHideAnimation ? INLINE_ANIMATION_DURATION : undefined }}
        >
            <div className='error-spacer' />
            <div className='error-text'>{serverErrorNotification}</div>
            <div
                className='error-close'
                onClick={e => {
                    e.stopPropagation();
                    hideNotification();
                }}
            >
                <SvgClose />
            </div>
        </div>
    );
}
