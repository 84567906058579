import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ProjectPartType } from '../../../../generated/cloud-frontend-api';
import convertSize from '../../../../lib/convertSize';
import { getGroupSize } from '../../../../lib/getGroupSize';
import { AppDispatch, useSelector } from '../../../../store';
import { SelectedObject } from '../../../../store/helpers/interfaces';
import { setSelectedObject } from '../../../../store/sharedActions';
import { deleteDataset, unlinkDataset } from '../../../../store/slices/datasetfilesUpload';
import { deleteLayer } from '../../../../store/slices/geometryLayers';
import { ExtendedStructureInfo, deleteStructure } from '../../../../store/slices/structure';
import Modal, { ModalProps } from '../../modal/Modal';
import ModalActions from '../../modal/ModalActions';
import ModalBody from '../../modal/ModalBody';
import ModalHead from '../../modal/ModalHead';
import { selectGroupDatasets, isLinkedDataset, removeDataset } from '../../../../store/slices/datasets';

type Props = ModalProps & { groupInfo: ExtendedStructureInfo };

export default function DeleteGroupModal({ isOpen, setIsOpen, groupInfo }: Props) {
    const { t } = useTranslation('modals');
    const dispatch: AppDispatch = useDispatch();
    const projectInfo = useSelector(state => state.project.projectInfo);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const temporaryLayers = useSelector(state => state.project.structure.temporaryLayers);
    const groupDatasets = useSelector(state => selectGroupDatasets(state, groupInfo.uid!));
    const groupSizeNum = getGroupSize(groupDatasets, projectInfo.id!);
    const isEmptyGroup = groupSizeNum === 0;
    const groupSize = convertSize(groupSizeNum);

    async function deleteGroup() {
        await deselectIfNeeded();
        dispatch(deleteStructure({ projectId: projectInfo.id!, structureId: groupInfo?.uid || '' }));
        groupDatasets
            .filter(d => d.projectPartType === ProjectPartType.DATASETS && !isLinkedDataset(d, projectInfo.id!))
            .forEach(dataset => {
                if (dataset.visualData)
                    dispatch(deleteDataset({ projectId: projectInfo.id!, datasetUid: dataset.datasetUid || '' }));
                else dispatch(removeDataset({ datasetUid: dataset.datasetUid! }));
            });
        groupDatasets
            .filter(d => d.projectPartType === ProjectPartType.VECTOR_LAYERS && !isLinkedDataset(d, projectInfo.id!))
            .forEach(d => {
                dispatch(
                    deleteLayer({
                        layerUid: d.datasetUid!,
                        projectUid: projectInfo.id!,
                        geometryIds: temporaryLayers.find(l => l.id === d.datasetUid)?.geometries || []
                    })
                );
            });
        groupDatasets
            .filter(d => isLinkedDataset(d, projectInfo.id!))
            .forEach(d =>
                dispatch(
                    unlinkDataset({
                        datasetId: d.datasetUid!,
                        projectId: d.parentProject?.uid!,
                        linkedProjectId: projectInfo.id!
                    })
                )
            );

        async function deselectIfNeeded() {
            if (
                selectedObject?.artifactId === groupInfo?.uid ||
                groupDatasets.some(d => d.datasetUid === selectedObject?.artifactId) ||
                temporaryLayers.some(l => l.geometries.includes(selectedObject?.artifactId!))
            )
                await dispatch(setSelectedObject({} as SelectedObject));
        }
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <ModalHead setIsOpen={setIsOpen}>{t('deleteGroupModal.head')}</ModalHead>
            <ModalBody>
                <div data-testid='deleteMessage'>
                    {isEmptyGroup ? (
                        <Trans
                            ns={'modals'}
                            i18nKey={'deleteGroupModal.body_emptyGroup'}
                            components={{ p: <p />, s: <strong className='overflow' /> }}
                            values={{ groupName: groupInfo.properties?.name }}
                        />
                    ) : (
                        <Trans
                            ns={'modals'}
                            i18nKey={'deleteGroupModal.body_default'}
                            components={{ p: <p />, s: <strong className='overflow' /> }}
                            values={{ groupName: groupInfo.properties?.name, groupSize }}
                        />
                    )}
                </div>
                <ModalActions>
                    <button
                        type='button'
                        className='btn-ghost-blue'
                        onClick={() => {
                            setIsOpen(false);
                        }}
                    >
                        {t('deleteGroupModal.action1')}
                    </button>
                    <button
                        type='button'
                        className='btn-alert'
                        onClick={() => {
                            setIsOpen(false);
                            deleteGroup();
                        }}
                    >
                        {t('deleteGroupModal.action2')}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}
