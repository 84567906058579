import { useState } from 'react';
import { DatePicker, TimePicker } from 'antd';
import day from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ProjectInfo } from '../../../../generated/cloud-frontend-api';
import Modal, { ModalProps } from '../../modal/Modal';
import ModalActions from '../../modal/ModalActions';
import ModalBody from '../../modal/ModalBody';
import ModalHead from '../../modal/ModalHead';
import { resetProjects, updateSurveyDate } from '../../../../store/slices/projectsPage';
import { AppDispatch, useSelector } from '../../../../store';
import { ProjectsSortModes, IS_TOUCH_DEVICE } from '../../../../sharedConstants';
import { ReactComponent as SvgCalendar } from '../../../../svg/dropdown_actions/calendar.svg';
import { ReactComponent as SvgClock } from '../../../../svg/dropdown_actions/processing_info.svg';

type Props = ModalProps & {
    project: ProjectInfo;
};

const dateFormats = ['DD/MM/YYYY', 'D/MM/YYYY', 'D/M/YYYY', 'DD/M/YYYY'];
const timeFormats = ['HH:mm', 'H:mm'];

export default function ChangeSurveyDateModal({ isOpen, setIsOpen, project }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['modals']);
    const [selectedDate, setSelectedDate] = useState(new Date(project.surveyDate || project.dateCreated!));
    const sortMode = useSelector(state => state.projectsPage.sortMode);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <ModalHead setIsOpen={setIsOpen}>{t('modals:changeSurveyDateModal.head')}</ModalHead>
            <ModalBody>
                <div className='modal-project-title'>
                    <span>{`${t('modals:changeSurveyDateModal.bodyProject')} `}</span>
                    <b>{project.name}</b>
                </div>
                <div className='pickers-wrapper'>
                    <DatePicker
                        className='picker-item'
                        suffixIcon={<SvgCalendar />}
                        allowClear={false}
                        value={day(selectedDate)}
                        format={dateFormats}
                        placement={IS_TOUCH_DEVICE ? 'topLeft' : 'bottomLeft'}
                        showNow={false}
                        onChange={newDate => {
                            if (newDate) {
                                const newSelectedDate = day(selectedDate)
                                    .set('year', newDate.year())
                                    .set('month', newDate.month())
                                    .set('date', newDate.date());
                                setSelectedDate(newSelectedDate.toDate());
                            }
                        }}
                    />
                    <TimePicker
                        className='picker-item'
                        suffixIcon={<SvgClock />}
                        allowClear={false}
                        value={day(selectedDate)}
                        format={timeFormats}
                        needConfirm={false}
                        placement={IS_TOUCH_DEVICE ? 'topRight' : 'bottomRight'}
                        showNow={false}
                        onChange={newTime => {
                            if (newTime) {
                                const newSelectedDate = day(selectedDate)
                                    .set('hour', newTime.hour())
                                    .set('minute', newTime.minute());
                                setSelectedDate(newSelectedDate.toDate());
                            }
                        }}
                    />
                </div>
                <ModalActions>
                    <button
                        className='btn-ghost-blue'
                        type='button'
                        onClick={e => {
                            setIsOpen(false);
                        }}
                    >
                        {t('modals:changeSurveyDateModal.actionCancel')}
                    </button>

                    <button
                        className='btn'
                        type='button'
                        onClick={async e => {
                            setIsOpen(false);
                            await dispatch(
                                updateSurveyDate({ projectId: project.id!, surveyDate: selectedDate.toISOString() })
                            );
                            if (sortMode === ProjectsSortModes.SURVEY_DATE) dispatch(resetProjects());
                        }}
                    >
                        {t('modals:changeSurveyDateModal.actionSave')}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}
