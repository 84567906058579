import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Layer, Stage } from 'react-konva';
import { useDispatch } from 'react-redux';
import { useImageOrientationAndRotation } from '../../../hooks/useImageOrientationAndRotation';
import { useImageSizeScaledToContainerSize } from '../../../hooks/useImageSizeScaledToContainerSize';
import { WidthHeight } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { ExtendedCamera } from '../../../store/slices/cameras';
import { selectIssues } from '../../../store/slices/geometries';
import { IssuesRects } from '../../ProjectView/image-viewer/IssuesRects';
import { useZoom } from '../../ProjectView/image-viewer/useKonvaUtils';
import { useURLImage } from '../../ProjectView/image-viewer/useURLImage';

type Props = {
    camera: ExtendedCamera | undefined;
    onLoad(): void;
    isLoading: boolean;
    setHasFailed: React.Dispatch<React.SetStateAction<boolean>>;
    containerSize: WidthHeight;
    imageUrl: string;
    issueId: string;
    usePreviewBbox: boolean;
};

function CanvasIssueImage({
    isLoading,
    onLoad,
    containerSize,
    setHasFailed,
    issueId,
    imageUrl,
    usePreviewBbox,
    camera
}: Props) {
    const { t } = useTranslation('projectView');
    const dispatch: AppDispatch = useDispatch();
    const issues = useSelector(state => selectIssues(state));
    const [isImageReady, setImageReady] = useState(false);

    const currentIssue = issues.find(i => i.id === issueId);

    const { image, orientation } = useURLImage({
        url: imageUrl,
        onError: () => {
            setHasFailed(true);
        },
        onLoad: () => {
            onLoad();
            setImageReady(true);
        },
        parseOrientation: true
    });

    const { scale } = useZoom();

    const { height, left, top, width } = useImageSizeScaledToContainerSize(containerSize, {
        width: image?.width ?? 0,
        height: image?.height ?? 0
    });

    const { offset, rotation } = useImageOrientationAndRotation(orientation, width || 0, height || 0);

    if (isLoading || !image || !currentIssue) return null;

    return (
        <Stage width={containerSize.width} height={containerSize.height} scale={scale}>
            <Layer x={left || 0} y={top || 0}>
                <Image image={image} height={height || 0} width={width || 0} rotation={rotation} position={offset} />
                {isImageReady && (
                    <IssuesRects
                        key={currentIssue.id}
                        sourceSize={
                            usePreviewBbox
                                ? { width: image.width, height: image.height }
                                : { width: camera!.width, height: camera!.height }
                        }
                        issue={currentIssue}
                        imageHeight={height || 0}
                        imageWidth={width || 0}
                        singleIssueIdToDisplay={issueId}
                        scale={scale}
                        selectionDisabled
                        usePreviewBbox={usePreviewBbox}
                    />
                )}
            </Layer>
        </Stage>
    );
}

export default memo(CanvasIssueImage);
