import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Cesium from 'cesium';
import _, { last } from 'lodash';
import { v4 } from 'uuid';
import { AppDispatch, ApplicationState } from '../..';
import appAxios from '../../../api/appAxios';
import i18n from '../../../i18n/config';
import GeoJsonUtils, { GeoJsonFeaturesScavengeResult } from '../../../lib/GeoJsonUtils';
import { getRandomColorHex } from '../../../lib/getRandomColor';
import { WGS84_EPSG_CODE } from '../../../sharedConstants';
import { addRecentlyFinishedUploadId } from '../../sharedActions';
import { selectPersonalAndDefaultCoordinateSystems } from '../../slices/coordinateSystems';
import { uploadStarted, validationStarted } from '../../slices/datasetsUpload';
import { addGeometries } from '../../slices/geometries';
import { beginLayer, commitLayer } from '../../slices/geometryLayers';
import { globalFlagsActions } from '../../slices/globalFlags';
import { addTemporaryLayer } from '../../slices/project';
import { addStructureInfo, getNextStructureOrder } from '../../slices/structure';
import { GeoJson, ProjectStructureObjectTypes, TemporaryGeometry } from '../interfaces';

const thunkPrefix = `geojsonUpload`;

const invalidGeometrySizeErrorMessage = 'invalidGeometrySizeError';

const createLayer = createAsyncThunk<
    { layerUid: string },
    { file: File; isTemporary: boolean; isGeojson: boolean },
    { state: ApplicationState; dispatch: AppDispatch }
>(`${thunkPrefix}/createLayer`, async ({ file, isTemporary, isGeojson }, { dispatch, getState }) => {
    const projectUid = getState().project.projectInfo.id!;
    const selectedObject = getState().project.selectedObject;
    let parentUid = projectUid;
    if (!isTemporary && selectedObject?.type === ProjectStructureObjectTypes.GROUP) {
        parentUid = selectedObject?.artifactId!;
    }

    const fileName = file.name.replace(/\.[^/.]+$/, '');
    let layerUid = '';
    const newColor = getRandomColorHex();
    if (!isTemporary) {
        const { layer } = await dispatch(
            beginLayer({
                projectUid,
                startTransaction: true,
                properties: { name: fileName, color: newColor, strokeColor: newColor },
                sizeInBytes: file.size,
                expanded: false,
                isGeojson: isGeojson
            })
        ).unwrap();
        layerUid = layer.id;
    } else {
        layerUid = dispatch(
            addTemporaryLayer(fileName, {
                crsId: isGeojson
                    ? selectPersonalAndDefaultCoordinateSystems(getState()).find(
                          crs => crs.epsgCode === WGS84_EPSG_CODE
                      )?.uid
                    : undefined
            })
        ).payload.id;
        const structureInfos = getState().structure.structures;
        const order = getNextStructureOrder(structureInfos);
        dispatch(
            addStructureInfo({
                uid: layerUid,
                parentUid,
                properties: { order: order.toString(), visible: true.toString(), expanded: false.toString() }
            })
        );
    }

    return { layerUid };
});

const uploadBatch = createAsyncThunk<
    TemporaryGeometry[],
    { layerUid: string; isTemporary: boolean; batch: GeoJson[]; lastOfPrevBatchUid: string | null },
    { state: ApplicationState; dispatch: AppDispatch }
>(
    `${thunkPrefix}/batch`,
    ({ batch, layerUid, isTemporary, lastOfPrevBatchUid }, { getState, dispatch, rejectWithValue }) => {
        const projectUid = getState().project.projectInfo.id!;
        if (!isTemporary) {
            const url = `/api/projects/${projectUid}/layers/${layerUid}/geometries${
                lastOfPrevBatchUid ? `?after=${lastOfPrevBatchUid}` : ''
            }`;
            return appAxios
                .request<string>({
                    url,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/stream+json', Accept: 'application/stream+json' },
                    data: batch.map(g => ({ content: g }))
                })
                .then(({ data }) => {
                    return _.compact(data.replaceAll('"', '').split('\n'));
                })
                .then(ids => {
                    if (ids) {
                        return ids.map((id, index) => ({ id, content: batch[index], renderAsEntity: false }));
                    }
                    return [];
                })
                .catch(error => {
                    if (error.response.status === 413) {
                        throw new Error(invalidGeometrySizeErrorMessage);
                    } else {
                        throw error;
                    }
                });
        } else {
            const ids = batch.map(g => v4());
            return Promise.resolve(ids).then(ids => {
                if (ids) {
                    return ids.map((id, index) => ({ id, content: batch[index], renderAsEntity: false }));
                }
                return [];
            });
        }
    },
    {
        // Cancel geometries uploading if upload was aborted by user
        condition({ layerUid }, { getState }) {
            const upload = getState().datasetsUpload.uploads[layerUid];
            if (upload.status === 'aborted') return false;
        }
    }
);

const BATCH_SIZE = 50;
const uploadBatches = createAsyncThunk<
    TemporaryGeometry[],
    { features: GeoJson[]; layerUid: string; isTemporary: boolean; terrainProvider: Cesium.TerrainProvider },
    { dispatch: AppDispatch; state: ApplicationState }
>(
    `${thunkPrefix}/uploadBatches`,
    async ({ features, isTemporary, layerUid, terrainProvider }, { dispatch, getState }) => {
        const addedGeometries: TemporaryGeometry[] = [];
        const layer = getState().project.structure.temporaryLayers.find(l => l.id === layerUid)!;
        const featuresWithElevation = features.filter(g => !GeoJsonUtils.hasNoElevation(g));
        const featuresWithoutElevation = features.filter(GeoJsonUtils.hasNoElevation);
        let batches = _.chunk(featuresWithElevation, BATCH_SIZE);
        let lastOfPrevBatchUid: string | null = null;

        for (let batch of batches) {
            batch = batch.map(b => GeoJsonUtils.fillRequiredPropertiesToDisplay(b, layer));
            const geometries: TemporaryGeometry[] = await dispatch(
                uploadBatch({ batch, layerUid, isTemporary, lastOfPrevBatchUid })
            ).unwrap();
            addedGeometries.push(...geometries);
            lastOfPrevBatchUid = last(geometries.map(g => g.id))!;
        }

        batches = _.chunk(featuresWithoutElevation, BATCH_SIZE);
        for (let batch of batches) {
            batch = await GeoJsonUtils.fillElevation(batch, terrainProvider);
            batch = batch.map(b => GeoJsonUtils.fillRequiredPropertiesToDisplay(b, layer));
            const geometries = await dispatch(
                uploadBatch({ batch, layerUid, isTemporary, lastOfPrevBatchUid })
            ).unwrap();
            addedGeometries.push(...geometries);
            lastOfPrevBatchUid = last(geometries.map(g => g.id))!;
        }

        return addedGeometries;
    }
);

const uploadGeoJsonLayer = createAsyncThunk<
    { id: string },
    { file: File; terrainProvider: Cesium.TerrainProvider; temporarily: boolean; layerUid: string },
    { state: ApplicationState; dispatch: AppDispatch; rejectValue: { id: string; error?: any; errorCode?: number } }
>(
    `${thunkPrefix}/do`,
    async ({ file, terrainProvider, temporarily, layerUid }, { getState, dispatch, rejectWithValue }) => {
        dispatch(validationStarted({ id: layerUid }));
        const data = await GeoJsonUtils.getValidatedFeatures(file);
        if (isErrorMessage(data)) return rejectWithValue({ id: layerUid, error: data });
        if (getState().datasetsUpload.uploads[layerUid]?.status === 'aborted') return rejectWithValue({ id: layerUid });

        const features = GeoJsonUtils.getFeaturesFlattened(data.result.features);
        const wgs84Uid = getState().coordinateSystems.defaultCrs.find(c => c.epsgCode === WGS84_EPSG_CODE)?.uid!;
        dispatch(uploadStarted({ id: layerUid, geometriesCount: features.length, coordinateSystemUid: wgs84Uid }));
        const invalidFeatures = data.rejected;
        if (invalidFeatures.length > 0) {
            const notificationMessage = i18n.t('projectView:datasetUpload.error_someGeometriesAreInvalid');
            dispatch(globalFlagsActions.setServerErrorNotification(notificationMessage));
        }
        let addedGeometries: TemporaryGeometry[] = [];
        try {
            addedGeometries = await dispatch(
                uploadBatches({ features, layerUid, isTemporary: temporarily, terrainProvider })
            ).unwrap();
        } catch (error: any) {
            if (error?.message === invalidGeometrySizeErrorMessage) {
                const notificationMessage = i18n.t('projectView:datasetUpload.error_maxGeometrySize');
                const errorMessage = i18n.t('projectView:datasetUpload.error_maxGeometrySizeLayer');
                dispatch(globalFlagsActions.setServerErrorNotification(notificationMessage));
                return rejectWithValue({ id: layerUid, error: errorMessage, errorCode: 413 });
            } else {
                throw error;
            }
        }
        const projectUid = getState().project.projectInfo.id!;
        const parentUid = getState().structure.structures.find(s => s.uid === layerUid)?.parentUid;
        if (!temporarily) await dispatch(commitLayer({ projectUid, layerUid, parentUid }));
        dispatch(addGeometries({ geometries: addedGeometries, datasetId: layerUid }));
        dispatch(addRecentlyFinishedUploadId({ id: layerUid }));

        return { id: layerUid };

        function isErrorMessage(message: GeoJsonFeaturesScavengeResult | string): message is string {
            return typeof message === 'string';
        }
    }
);

const GeoJsonUpload = {
    createLayer,
    uploadBatches,
    uploadBatch,
    uploadGeoJsonLayer
};

export default GeoJsonUpload;
