import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { matchPath } from 'react-router-dom';
import { projectsApi, sharedProjectsApi } from '../../api/initApis';
import { ProjectsPage, SharedProjectsPage } from '../../generated/cloud-frontend-api';
import isSharedProjectsPageRoute from '../../lib/isSharedProjectsPageRoute';
import { ProjectsSortModes, ProjectsSortOrders, Routes } from '../../sharedConstants';
import { ProjectStructureObjectTypes, SelectedObject, isIssuePointGeometry } from '../helpers/interfaces';
import { AppDispatch, ApplicationState } from '../index';
import { setElevationProfileExpanded, setSelectedCamera } from '../slices/projectView';
import { DEFAULT_SORT_MODE, DEFAULT_SORT_ORDER, getSuitedOrder } from '../slices/projectsPage';
import { VolumeCalculationInfo } from '../slices/volume';

export const calculationAdded = createAction<VolumeCalculationInfo>('volume/calculationAdded');

export const volumeDeleted = createAction<{ id: string }>('volume/deleted');

export const addRecentlyFinishedUploadId = createAction<{ id: string }>('datasetsUpload/addRecentlyFinishedId');

export const deleteRecentlyFinishedUploadId = createAction<{ id: string }>('datasetsUpload/deleteRecentlyFinishedId');

export const setSelectedObject = createAsyncThunk<
    SelectedObject,
    SelectedObject,
    { state: ApplicationState; dispatch: AppDispatch }
>(`project/setSelectedObject`, (selectedObject, { getState, dispatch }) => {
    if (selectedObject.type === ProjectStructureObjectTypes.GEOMETRY) {
        const geometry = getState().geometries.entities[selectedObject?.artifactId!];
        if (geometry && isIssuePointGeometry(geometry)) return selectedObject;
    }

    const isCamerasInspectionEnabled = getState().projectView.isCamerasInspectionEnabled;
    if (getState().projectView.selectedCamera && !isCamerasInspectionEnabled) {
        dispatch(setSelectedCamera(undefined));
    }

    if (selectedObject?.type === ProjectStructureObjectTypes.IMAGE && !isCamerasInspectionEnabled) {
        const allCameras = getState().cameras;
        const cameraArtifactUid = Object.keys(allCameras).find(key =>
            allCameras[key].find(c => c.uid === selectedObject?.artifactId)
        );
        dispatch(setSelectedCamera({ uid: selectedObject.artifactId!, artifactUid: cameraArtifactUid! }));
    }

    if (selectedObject?.artifactId !== getState().project.selectedObject?.artifactId) {
        if (getState().projectView.isElevationProfileExpanded) dispatch(setElevationProfileExpanded(false));
    }

    return selectedObject;
});

export const elevationProfileCalculationInterrupted = createAction<string>(`elevationProfile/interrupted`);

interface LoadNextProjectsPageArgs {
    parentProjectUid?: string;
    sortMode?: ProjectsSortModes;
    sortOrder?: ProjectsSortOrders;
    page?: number;
    pattern?: string;
    limit: number;
    access?: string;
    signal: AbortSignal;
}
export const loadNextProjectsPage = createAsyncThunk<ProjectsPage | SharedProjectsPage, LoadNextProjectsPageArgs>(
    `loadNextProjectsPage`,
    async ({
        limit,
        page = 0,
        pattern = '',
        sortMode = DEFAULT_SORT_MODE,
        sortOrder = DEFAULT_SORT_ORDER,
        parentProjectUid,
        access,
        signal
    }) => {
        const modifiedOrder = getSuitedOrder(sortMode, sortOrder);

        if (isSharedProjectsPageRoute()) {
            const { data } = await sharedProjectsApi.getSharedProjects(limit, page, ['name'], pattern, { signal });
            return data;
        }

        if (
            matchPath(window.location.pathname, {
                exact: true,
                path: [Routes.SITE, Routes.SHARED_SITE, Routes.FOLDER]
            }) &&
            parentProjectUid
        ) {
            const { data } = await projectsApi.getProjectsByParent(
                parentProjectUid,
                sortMode,
                limit,
                page,
                modifiedOrder,
                pattern,
                undefined,
                false,
                { params: { access }, signal }
            );
            return data;
        }

        const { data } = await projectsApi.getProjects(
            sortMode,
            limit,
            page,
            modifiedOrder,
            pattern,
            undefined,
            undefined,
            { signal }
        );
        return data;
    }
);
