import useGetCopyrightString from '../../../hooks/useGetCopyrightString';

import styles from './PagesCopyrightBar.module.scss';

export default function PagesCopyrightBar() {
    const copyrightText = useGetCopyrightString();

    return (
        <div className={styles.copyrightBar}>
            <div className={styles.copyrightText}>{copyrightText}</div>
        </div>
    );
}
