import axios from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';

const appAxios = axios.create({
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Agisoft-Client-Name': `${import.meta.env.VITE_CLIENT_NAME}`,
        'X-Agisoft-Client-Version': `${import.meta.env.VITE_CLIENT_VERSION}`,
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
});

axiosRetry(appAxios, {
    retries: 3,
    retryCondition: isNetworkOrIdempotentRequestError, // Network or 5xx,
    retryDelay: (retryCount, error) => axiosRetry.exponentialDelay(retryCount, error, 1000)
});

export default appAxios;
