import React, { useContext, useEffect } from 'react';
import { Cesium3DTileset, ImageryLayer, Entity } from 'cesium';
import { useCesium } from 'resium';
import { nadirHeadingPitchRange } from '../../../../sharedConstants';
import FlyToTilesetsContext from '../../../../contexts/FlyToTilesetsContext';

type Props = {
    object: Cesium3DTileset | ImageryLayer | Entity;
};

export default function CameraFlyToObject({ object }: Props) {
    const { viewer } = useCesium();
    const { setClickedTilesetIds } = useContext(FlyToTilesetsContext);

    useEffect(() => {
        viewer?.flyTo(object, { duration: 1, offset: nadirHeadingPitchRange }).then(() => {
            setClickedTilesetIds({ ids: null });
        });
    }, [object, setClickedTilesetIds, viewer]);

    return null;
}
