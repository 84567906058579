import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { billingApi } from '../../api/initApis';
import {
    ProcessingTimeProduct,
    ProductsInfo,
    ProductsToOrder,
    StorageSubscriptionInfo
} from '../../generated/billing-api';
import { ApplicationState } from '..';

interface BillingState {
    products: Required<ProductsInfo>;
    subscriptions: Array<StorageSubscriptionInfo>;
}

const initialState: BillingState = {
    products: {
        compensation: 0,
        additionalHoursOnUpgrade: 0,
        storageProducts: [],
        processingTimeProducts: []
    },
    subscriptions: []
};

const name = 'billing';

export const getProductsInfo = createAsyncThunk(`${name}/getProductsInfo`, () =>
    billingApi.getProductsInfo().then(({ data }) => data)
);

interface CreateBillingOrderArgs {
    products: ProductsToOrder;
}
export const createBillingOrder = createAsyncThunk(
    `${name}/createOrder`,
    async ({ products }: CreateBillingOrderArgs) => {
        const { data } = await billingApi.createOrder({ products });
        return data;
    }
);

export const downgradeSubscription = createAsyncThunk<void, string, { state: ApplicationState }>(
    `${name}/downgradeSubscription`,
    async (nextSubscriptionName: string) => {
        const { data } = await billingApi.downgradeSubscription({ name: nextSubscriptionName });
        return data;
    },
    {
        condition(arg, { getState }): boolean | undefined {
            const isPending = getState().loading[`${name}/downgradeSubscription`]?.isLoading;
            if (isPending) return false;
        }
    }
);

export const cancelSubscription = createAsyncThunk(`${name}/cancelSubscription`, async () => {
    const { status } = await billingApi.cancelSubscription();
    return status;
});

export const cancelSubscriptionTransition = createAsyncThunk<void, void, { state: ApplicationState }>(
    `${name}/cancelSubscriptionTransition`,
    async () => {
        const { data } = await billingApi.cancelSubscriptionTransition();
        return data;
    },
    {
        condition(arg, { getState }): boolean | undefined {
            const isPending = getState().loading[`${name}/cancelSubscriptionTransition`]?.isLoading;
            if (isPending) return false;
        }
    }
);

const billingSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: builder =>
        builder.addCase(getProductsInfo.fulfilled, (state, { payload }) => {
            state.products.storageProducts = payload.storageProducts || [];
            state.products.additionalHoursOnUpgrade = payload.additionalHoursOnUpgrade || 0;
            state.products.compensation = payload.compensation || 0;

            const zeroHoursProduct: ProcessingTimeProduct = { description: '0 hours', name: '0 hours', price: 0 };
            const processingTimeProducts = payload.processingTimeProducts || [];
            processingTimeProducts.unshift(zeroHoursProduct);
            state.products.processingTimeProducts = processingTimeProducts;
        })
});

export default billingSlice.reducer;
