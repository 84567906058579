/* tslint:disable */
/* eslint-disable */
/**
 * cloud-frontend-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Project } from '../model';
// @ts-ignore
import { ProjectTypeFilter } from '../model';
// @ts-ignore
import { ProjectsPage } from '../model';
/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes project with ID = {id}
         * @summary Deletes a project
         * @param {string} id ID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProjectById', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns information about the project with ID = {id}
         * @summary Returns information about a project
         * @param {string} id ID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectById', 'id', id)
            const localVarPath = `/projects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the list of the root projects starting from specified page, number set as the specified limit parameter
         * @summary Returns the list of the projects belongs to root
         * @param {'modificationDate' | 'surveyDate' | 'name' | 'size'} sort Sort type for projects
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {'asc' | 'desc'} [order] Order of projects
         * @param {string} [pattern] String to filter projects by name
         * @param {ProjectTypeFilter} [type] Type of project. Should return all projects if it is null
         * @param {boolean} [nested] Flag that determines whether nested projects should be returned or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (sort: 'modificationDate' | 'surveyDate' | 'name' | 'size', limit?: number, page?: number, order?: 'asc' | 'desc', pattern?: string, type?: ProjectTypeFilter, nested?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sort' is not null or undefined
            assertParamExists('getProjects', 'sort', sort)
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (pattern !== undefined) {
                localVarQueryParameter['pattern'] = pattern;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the list of the projects filtered by parent starting from specified page, number set as the specified limit parameter
         * @summary Returns the list of the projects filtered by parent
         * @param {string} projectUid UUID of project
         * @param {'modificationDate' | 'surveyDate' | 'name' | 'size'} sort Sort type for projects
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {'asc' | 'desc'} [order] Order of projects
         * @param {string} [pattern] String to filter projects by name
         * @param {ProjectTypeFilter} [type] Type of project. Should return all projects if it is null
         * @param {boolean} [nested] Flag that determines whether nested projects should be returned or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByParent: async (projectUid: string, sort: 'modificationDate' | 'surveyDate' | 'name' | 'size', limit?: number, page?: number, order?: 'asc' | 'desc', pattern?: string, type?: ProjectTypeFilter, nested?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getProjectsByParent', 'projectUid', projectUid)
            // verify required parameter 'sort' is not null or undefined
            assertParamExists('getProjectsByParent', 'sort', sort)
            const localVarPath = `/projects/{projectUid}/projects`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (pattern !== undefined) {
                localVarQueryParameter['pattern'] = pattern;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (nested !== undefined) {
                localVarQueryParameter['nested'] = nested;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Publishes project with ID = {id}
         * @summary Publishes project
         * @param {string} id ID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishProject: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publishProject', 'id', id)
            const localVarPath = `/projects/{id}/publication`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unpublishes project with ID = {id}
         * @summary Unpublishes project
         * @param {string} id ID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishProject: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unpublishProject', 'id', id)
            const localVarPath = `/projects/{id}/publication`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes project with ID = {id}
         * @summary Deletes a project
         * @param {string} id ID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns information about the project with ID = {id}
         * @summary Returns information about a project
         * @param {string} id ID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the list of the root projects starting from specified page, number set as the specified limit parameter
         * @summary Returns the list of the projects belongs to root
         * @param {'modificationDate' | 'surveyDate' | 'name' | 'size'} sort Sort type for projects
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {'asc' | 'desc'} [order] Order of projects
         * @param {string} [pattern] String to filter projects by name
         * @param {ProjectTypeFilter} [type] Type of project. Should return all projects if it is null
         * @param {boolean} [nested] Flag that determines whether nested projects should be returned or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(sort: 'modificationDate' | 'surveyDate' | 'name' | 'size', limit?: number, page?: number, order?: 'asc' | 'desc', pattern?: string, type?: ProjectTypeFilter, nested?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(sort, limit, page, order, pattern, type, nested, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the list of the projects filtered by parent starting from specified page, number set as the specified limit parameter
         * @summary Returns the list of the projects filtered by parent
         * @param {string} projectUid UUID of project
         * @param {'modificationDate' | 'surveyDate' | 'name' | 'size'} sort Sort type for projects
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {'asc' | 'desc'} [order] Order of projects
         * @param {string} [pattern] String to filter projects by name
         * @param {ProjectTypeFilter} [type] Type of project. Should return all projects if it is null
         * @param {boolean} [nested] Flag that determines whether nested projects should be returned or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsByParent(projectUid: string, sort: 'modificationDate' | 'surveyDate' | 'name' | 'size', limit?: number, page?: number, order?: 'asc' | 'desc', pattern?: string, type?: ProjectTypeFilter, nested?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsByParent(projectUid, sort, limit, page, order, pattern, type, nested, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Publishes project with ID = {id}
         * @summary Publishes project
         * @param {string} id ID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishProject(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishProject(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Unpublishes project with ID = {id}
         * @summary Unpublishes project
         * @param {string} id ID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unpublishProject(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unpublishProject(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * Deletes project with ID = {id}
         * @summary Deletes a project
         * @param {string} id ID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns information about the project with ID = {id}
         * @summary Returns information about a project
         * @param {string} id ID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(id: string, options?: any): AxiosPromise<Project> {
            return localVarFp.getProjectById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the list of the root projects starting from specified page, number set as the specified limit parameter
         * @summary Returns the list of the projects belongs to root
         * @param {'modificationDate' | 'surveyDate' | 'name' | 'size'} sort Sort type for projects
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {'asc' | 'desc'} [order] Order of projects
         * @param {string} [pattern] String to filter projects by name
         * @param {ProjectTypeFilter} [type] Type of project. Should return all projects if it is null
         * @param {boolean} [nested] Flag that determines whether nested projects should be returned or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(sort: 'modificationDate' | 'surveyDate' | 'name' | 'size', limit?: number, page?: number, order?: 'asc' | 'desc', pattern?: string, type?: ProjectTypeFilter, nested?: boolean, options?: any): AxiosPromise<ProjectsPage> {
            return localVarFp.getProjects(sort, limit, page, order, pattern, type, nested, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the list of the projects filtered by parent starting from specified page, number set as the specified limit parameter
         * @summary Returns the list of the projects filtered by parent
         * @param {string} projectUid UUID of project
         * @param {'modificationDate' | 'surveyDate' | 'name' | 'size'} sort Sort type for projects
         * @param {number} [limit] Maximum number of the items to be returned
         * @param {number} [page] Page number to be returned
         * @param {'asc' | 'desc'} [order] Order of projects
         * @param {string} [pattern] String to filter projects by name
         * @param {ProjectTypeFilter} [type] Type of project. Should return all projects if it is null
         * @param {boolean} [nested] Flag that determines whether nested projects should be returned or not
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByParent(projectUid: string, sort: 'modificationDate' | 'surveyDate' | 'name' | 'size', limit?: number, page?: number, order?: 'asc' | 'desc', pattern?: string, type?: ProjectTypeFilter, nested?: boolean, options?: any): AxiosPromise<ProjectsPage> {
            return localVarFp.getProjectsByParent(projectUid, sort, limit, page, order, pattern, type, nested, options).then((request) => request(axios, basePath));
        },
        /**
         * Publishes project with ID = {id}
         * @summary Publishes project
         * @param {string} id ID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishProject(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.publishProject(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Unpublishes project with ID = {id}
         * @summary Unpublishes project
         * @param {string} id ID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishProject(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.unpublishProject(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * Deletes project with ID = {id}
     * @summary Deletes a project
     * @param {string} id ID of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public deleteProjectById(id: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).deleteProjectById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns information about the project with ID = {id}
     * @summary Returns information about a project
     * @param {string} id ID of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectById(id: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjectById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the list of the root projects starting from specified page, number set as the specified limit parameter
     * @summary Returns the list of the projects belongs to root
     * @param {'modificationDate' | 'surveyDate' | 'name' | 'size'} sort Sort type for projects
     * @param {number} [limit] Maximum number of the items to be returned
     * @param {number} [page] Page number to be returned
     * @param {'asc' | 'desc'} [order] Order of projects
     * @param {string} [pattern] String to filter projects by name
     * @param {ProjectTypeFilter} [type] Type of project. Should return all projects if it is null
     * @param {boolean} [nested] Flag that determines whether nested projects should be returned or not
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjects(sort: 'modificationDate' | 'surveyDate' | 'name' | 'size', limit?: number, page?: number, order?: 'asc' | 'desc', pattern?: string, type?: ProjectTypeFilter, nested?: boolean, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjects(sort, limit, page, order, pattern, type, nested, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the list of the projects filtered by parent starting from specified page, number set as the specified limit parameter
     * @summary Returns the list of the projects filtered by parent
     * @param {string} projectUid UUID of project
     * @param {'modificationDate' | 'surveyDate' | 'name' | 'size'} sort Sort type for projects
     * @param {number} [limit] Maximum number of the items to be returned
     * @param {number} [page] Page number to be returned
     * @param {'asc' | 'desc'} [order] Order of projects
     * @param {string} [pattern] String to filter projects by name
     * @param {ProjectTypeFilter} [type] Type of project. Should return all projects if it is null
     * @param {boolean} [nested] Flag that determines whether nested projects should be returned or not
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getProjectsByParent(projectUid: string, sort: 'modificationDate' | 'surveyDate' | 'name' | 'size', limit?: number, page?: number, order?: 'asc' | 'desc', pattern?: string, type?: ProjectTypeFilter, nested?: boolean, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getProjectsByParent(projectUid, sort, limit, page, order, pattern, type, nested, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Publishes project with ID = {id}
     * @summary Publishes project
     * @param {string} id ID of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public publishProject(id: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).publishProject(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unpublishes project with ID = {id}
     * @summary Unpublishes project
     * @param {string} id ID of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public unpublishProject(id: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).unpublishProject(id, options).then((request) => request(this.axios, this.basePath));
    }
}
