import { useState } from 'react';
import { useOutsideClickRef } from 'rooks';
import Tippy from '@tippyjs/react/headless';
import classNames from 'classnames';
import { useSelector } from '../../../store';
import ProjectActionsList from '../../Elements/project-actions/ProjectActionsList';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from '../../../sharedConstants';

export default function BreadcrumbsDropdownMenu() {
    const project = useSelector(state => state.project.projectInfo);
    const projectParent = useSelector(state => state.project.parent);
    const siteProjectInfo = useSelector(state => state.site.projectInfo);
    const siteParent = useSelector(state => state.site.siteInfo.parent);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdown] = useOutsideClickRef(() => {
        setDropdownOpen(false);
    });

    const isSomeSiteRoute = !!useRouteMatch({ path: [Routes.SITE_VIEW, Routes.SITE], exact: true });
    const actualProjectInfo = isSomeSiteRoute ? siteProjectInfo : project;
    const parent = isSomeSiteRoute ? siteParent : projectParent;

    return (
        <Tippy
            ref={dropdown}
            render={attrs => (
                <div
                    className='tippy-dropdown'
                    tabIndex={-1}
                    {...attrs}
                    onClick={e => {
                        setDropdownOpen(false);
                    }}
                >
                    <ProjectActionsList project={actualProjectInfo} parent={parent} />
                </div>
            )}
            delay={[100, 100]}
            interactive={true}
            offset={[4, 8]}
            placement={'bottom-end'}
            visible={dropdownOpen}
            appendTo={() => document.body}
        >
            <div
                data-testid={'kebabMenu'}
                className={classNames('infobar-burger', { active: dropdownOpen })}
                onClick={() => setDropdownOpen(prev => !prev)}
            >
                <i className='icon icon-menu' />
            </div>
        </Tippy>
    );
}
