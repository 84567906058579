import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dataset } from '../../../entities/Dataset';
import { ProjectStructureObjectTypes, TemporaryLayer } from '../../../store/helpers/interfaces';
import { useSelector } from '../../../store/index';
import { selectedObjectInfoSelector } from '../../../store/selectors/index';
import { ExtendedDatasetInfo } from '../../../store/slices/datasetfilesUpload';
import { adaptVectorLayerToDataset, selectUpload } from '../../../store/slices/datasetsUpload';
import { InspectorStructureItemProgress } from '../../inspection-sidebar/inspector-structure-item-progress/InspectorStructureItemProgress';

export default function DatasetFileUploadInfo() {
    const { t } = useTranslation(['projectView']);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));
    const upload = useSelector(state => selectUpload(state, selectedObject?.artifactId!));
    const dataset =
        selectedObject.type === ProjectStructureObjectTypes.DATASET ||
        selectedObject.type === ProjectStructureObjectTypes.ARTIFACT
            ? (selectedObjectInfo as ExtendedDatasetInfo)
            : adaptVectorLayerToDataset(selectedObjectInfo as TemporaryLayer, upload!);
    const { isValidating, isUploading, isPublishing } = useMemo(() => new Dataset(dataset), [dataset]);
    const uploadPercent = Number(
        ((isUploading ? dataset?.uploadPercent : dataset?.visualData?.progress) || 0).toFixed(0)
    );

    if (!selectedObjectInfo) return null;

    const topText = (() => {
        if (isValidating) return t('projectView:inspectionSidebar.datasetUpload.step_validating');
        if (isUploading) return t('projectView:inspectionSidebar.datasetUpload.step_uploading');
        if (isPublishing) return t('projectView:inspectionSidebar.datasetUpload.step_publishing');
        return '';
    })();

    const percentText = () => {
        if (isUploading || isPublishing) return Number.isNaN(uploadPercent) ? '0%' : `${uploadPercent}%`;
        return '';
    };

    return (
        <div className='inspector-properties inspector-properties_upload'>
            <InspectorStructureItemProgress
                topText={topText}
                percentText={percentText()}
                percent={uploadPercent}
                infinite={isValidating}
            />
        </div>
    );
}
