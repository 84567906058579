import { Input, Select } from 'antd';
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import _ from 'lodash';
import { useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { number, object, string } from 'yup';
import ProjectViewAccessContext from '../../../../contexts/ProjectViewAccessContext';
import useGetElementDimensions from '../../../../hooks/useGetElementDimensions';
import i18n from '../../../../i18n/config';
import { convertLengthUnit } from '../../../../lib/convertUnit';
import { getUnitsShortName } from '../../../../lib/getUnitsShortName';
import trimNumberToNDecimalPoints from '../../../../lib/trimNumberToNDecimalPoints';
import { GeometryTypes, Units, WGS84_EPSG_CODE, floatRegex } from '../../../../sharedConstants';
import { TemporaryGeometry, VolumeBaseLevel, VolumeBasePlane } from '../../../../store/helpers/interfaces';
import { AppDispatch, useSelector } from '../../../../store/index';
import { selectReadyDemFiles, selectedObjectInfoSelector } from '../../../../store/selectors/index';
import { calculationAdded, volumeDeleted } from '../../../../store/sharedActions';
import { ReactComponent as SvgClose } from '../../../../svg/general/x-icon.svg';
import { ReactComponent as SvgSave } from '../../../../svg/general/save.svg';
import TippyTooltip from '../../../Elements/tippy-tooltip/TippyTooltip';
import { globalTerrainOption } from '../../elevation-profile/ElevationProfileTool';

type Props = {
    setToolVisibility: (toolVisibility: boolean) => void;
};

interface InitialValues {
    surfaceId: string | undefined;
    basePlane: VolumeBasePlane;
    baseLevel: VolumeBaseLevel | '';
}

export const volumeBasePlanes: () => { name: VolumeBasePlane; displayName: string }[] = () => [
    { name: 'bestFit', displayName: i18n.t('projectView:volume.basePlane.option_bestFit') },
    { name: 'meanLevel', displayName: i18n.t('projectView:volume.basePlane.option_meanLevel') },
    { name: 'maxLevel', displayName: i18n.t('projectView:volume.basePlane.option_maxLevel') },
    { name: 'minLevel', displayName: i18n.t('projectView:volume.basePlane.option_minLevel') },
    { name: 'customLevel', displayName: i18n.t('projectView:volume.basePlane.option_customLevel') }
];
const volumeBasePlanesNames = volumeBasePlanes().map(bp => bp.name);

export default function VolumeTool({ setToolVisibility }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView', 'common']);
    const { isInEmbedView } = useContext(ProjectViewAccessContext);
    const polygon = useSelector(state => selectedObjectInfoSelector(state) as TemporaryGeometry<GeometryTypes.POLYGON>);
    const coordinateSystem = useSelector(store => store.coordinateSystems.currentCrs);
    const isWgs84 = coordinateSystem.epsgCode === WGS84_EPSG_CODE;
    const units = useSelector(state => state.coordinateSystems.units);
    const dems = useSelector(state => selectReadyDemFiles(state));
    const toolRef = useRef<HTMLDivElement>(null);
    const surfaceIdSelectParent = useRef<HTMLDivElement>(null);
    const basePlaneSelectParent = useRef<HTMLDivElement>(null);

    const unitsShortName = getUnitsShortName(units);
    const dropdownWidth = useGetElementDimensions(toolRef).width;

    const { ac_volume_base_plane, ac_volume_base_level_meters, ac_volume_surface } = polygon.content.properties;

    const validationSchema = useMemo(
        () =>
            object({
                basePlane: string().oneOf(volumeBasePlanesNames).required(),
                surfaceId: string().required(),
                baseLevel: number()
                    .typeError(t('common:invalidValue'))
                    .when('basePlane', {
                        is: 'customLevel',
                        then: schema =>
                            schema
                                .required('')
                                .moreThan(-1e4, t('common:invalidValue'))
                                .lessThan(1e4, t('common:invalidValue'))
                    })
                    .when('basePlane', {
                        is: (value: VolumeBasePlane) =>
                            ['meanLevel', 'minLevel', 'maxLevel', 'bestFit'].includes(value),
                        then: schema => schema.notRequired()
                    })
            }),
        [t]
    );

    const initialValues: InitialValues = {
        surfaceId:
            ac_volume_surface === globalTerrainOption().id
                ? globalTerrainOption().id
                : dems.find(d => d.id === ac_volume_surface)?.id || undefined,
        basePlane:
            ac_volume_base_plane && (ac_volume_base_plane !== 'customLevel' || isWgs84)
                ? ac_volume_base_plane
                : 'bestFit',
        baseLevel:
            ac_volume_base_plane !== 'customLevel'
                ? ''
                : Number(convertLengthUnit(Number(ac_volume_base_level_meters!), Units.METRE, units).toFixed(3))
    };

    return (
        <div
            className={classNames('inspector-tool', { 'theme-dark': isInEmbedView })}
            data-testid='calculateVolumeTool'
            ref={toolRef}
        >
            <div className='tool-header'>
                <div className='tool-title'>
                    <span>{t('projectView:volume.tool.title')}</span>
                </div>
                <div
                    className='tool-close'
                    onClick={() => {
                        setToolVisibility(false);
                    }}
                >
                    <SvgClose />
                </div>
            </div>
            <div className='tool-body'>
                <Formik
                    validateOnMount
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={values => {
                        dispatch(volumeDeleted({ id: polygon.id }));
                        dispatch(
                            calculationAdded({
                                id: polygon.id,
                                status: 'new',
                                args: {
                                    ...values,
                                    surfaceId: values.surfaceId!,
                                    baseLevel:
                                        values.basePlane !== 'customLevel'
                                            ? 'Varies'
                                            : convertLengthUnit(Number(values.baseLevel), units, Units.METRE),
                                    polygon
                                }
                            })
                        );
                        setToolVisibility(false);
                    }}
                >
                    {({ values, errors, setFieldValue }) => {
                        return (
                            <Form>
                                <div className='tool-fields tool-fields-surface'>
                                    <div className='field-title'>{t('projectView:volume.surface.label')}</div>
                                    <div className='field-input' ref={surfaceIdSelectParent}>
                                        <Field
                                            as={Select}
                                            value={values.surfaceId}
                                            onChange={(newValue: string) => {
                                                setFieldValue('surfaceId', newValue);
                                            }}
                                            placeholder={t('projectView:volume.surface.placeholder')}
                                            className={classNames('ant-select-small', { 'theme-dark': isInEmbedView })}
                                            popupClassName={classNames('ant-dropdown-small', {
                                                'theme-dark': isInEmbedView
                                            })}
                                            getPopupContainer={() => surfaceIdSelectParent.current}
                                            popupMatchSelectWidth={dropdownWidth ? dropdownWidth : true}
                                        >
                                            <Select.Option value={globalTerrainOption().id}>
                                                {globalTerrainOption().name}
                                            </Select.Option>
                                            {dems.map(d => (
                                                <Select.Option key={d.id} value={d.id}>
                                                    {d.name}
                                                </Select.Option>
                                            ))}
                                        </Field>
                                    </div>
                                    <div className='field-title'>{t('projectView:volume.basePlane.label')}</div>
                                    <div className='field-input' ref={basePlaneSelectParent}>
                                        <Field
                                            as={Select}
                                            value={values.basePlane}
                                            onChange={(newValue: string) => {
                                                setFieldValue('basePlane', newValue);
                                            }}
                                            className={classNames('ant-select-small', { 'theme-dark': isInEmbedView })}
                                            popupClassName={classNames('ant-dropdown-small', {
                                                'theme-dark': isInEmbedView
                                            })}
                                            getPopupContainer={() => basePlaneSelectParent.current}
                                        >
                                            {volumeBasePlanes()
                                                .filter(plane => isWgs84 || plane.name !== 'customLevel')
                                                .map(({ name, displayName }) => (
                                                    <Select.Option key={name} value={name}>
                                                        {displayName}
                                                    </Select.Option>
                                                ))}
                                        </Field>
                                    </div>
                                    {values.basePlane === 'customLevel' && isWgs84 && (
                                        <>
                                            <div className='field-title'>
                                                {t('projectView:volume.baseLevel.label')} ({unitsShortName})
                                            </div>
                                            <TippyTooltip
                                                tooltipText={errors.baseLevel || ''}
                                                visible={!!errors.baseLevel}
                                                placement={'bottom'}
                                            >
                                                <div className='field-input'>
                                                    <Field
                                                        as={Input}
                                                        name={'baseLevel'}
                                                        className={classNames('input-small', {
                                                            'input-theme-dark': isInEmbedView,
                                                            'input-error': !!errors.baseLevel
                                                        })}
                                                        autoComplete={'off'}
                                                        placeholder={'0.000'}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            const value = (e.currentTarget as HTMLInputElement).value;
                                                            if (!floatRegex.test(value)) {
                                                                setFieldValue('baseLevel', values.baseLevel);
                                                                return;
                                                            }

                                                            // Restrict input by maximum of 3 floating digits, trim if needed
                                                            const trimmed = trimNumberToNDecimalPoints(value, 3);
                                                            setFieldValue('baseLevel', trimmed);
                                                        }}
                                                    />
                                                </div>
                                            </TippyTooltip>
                                        </>
                                    )}
                                </div>
                                <div className='tool-actions'>
                                    {/* {canDelete && (
                                        <TippyTooltip tooltipText='Delete'>
                                            <button
                                                className='tool-action volume-delete'
                                                type='button'
                                            >
                                                <SvgDelete />
                                            </button>
                                        </TippyTooltip>
                                    )} */}
                                    <TippyTooltip tooltipText={t('projectView:volume.tooltipCalculate')}>
                                        <button className='tool-action' type='submit' disabled={!_.isEmpty(errors)}>
                                            <SvgSave />
                                        </button>
                                    </TippyTooltip>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
}
