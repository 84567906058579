import { useFormikContext } from 'formik';
import { SubscriptionFormValues } from '../SubscriptionForm';
import { useSelector } from '../../../store';
import {
    processingTimeProductSelector,
    storageProductSelector,
    summaryTotalPriceSelector
} from '../../../store/selectors';
import { addMonths, format } from 'date-fns';
import { CommercialPlan, NonCommercialPlan } from '../../Elements/plan-filters/PlanFilters';
import { StorageProductGradeEnum } from '../../../generated/billing-api';
import { PlanType } from '../../../generated/account-frontend-api';
import SubscriptionSummaryInfoText from './SubscriptionSummaryInfoText';
import { DATE_FORMAT, SUMMARY_BREAKPOINT } from '../../../sharedConstants';
import { useWindowSize } from 'rooks';
import { useTranslation } from 'react-i18next';
import TotalSumText from '../elements/total-sum-text/TotalSum';

import styles from '../SubscriptionForm.module.scss';

type Props = {
    showStorage?: boolean;
    showHours?: boolean;
};

export default function SubscriptionSummary({ showStorage = false, showHours = false }: Props) {
    const { values } = useFormikContext<SubscriptionFormValues>();
    const { t } = useTranslation(['subscribe', 'common', 'glossary']);
    const totalSelector = summaryTotalPriceSelector({
        storage: showStorage ? values.storage : undefined,
        processingTime: showHours ? values.hours : undefined
    });
    const totalPrice = useSelector(state => totalSelector(state));
    const storageProduct = useSelector(state => storageProductSelector(values.storage)(state));
    const processingTimeProduct = useSelector(state => processingTimeProductSelector(values.hours)(state));
    const currentPlan = useSelector(state => state.accountResources.account.plan.current);
    const { additionalHoursOnUpgrade, compensation } = useSelector(state => state.billing.products);
    const isUpgradingStorage =
        currentPlan?.type === PlanType.COMMERCIAL && storageProduct?.grade === StorageProductGradeEnum.UPGRADE;
    const scheduledPlanType = useSelector(state => state.accountResources.account.plan.scheduled?.type);

    const { innerWidth } = useWindowSize();
    const isDesktopLayout = innerWidth! >= SUMMARY_BREAKPOINT;

    const isOverdue = currentPlan?.overdue;
    const isCommercial = currentPlan?.type === PlanType.COMMERCIAL;

    const onlyHours = showHours && !showStorage;

    const storageTotalString = `$${storageProduct?.price?.toFixed(2)}`;
    const hoursTotalString = isUpgradingStorage ? '$0.00' : `$${processingTimeProduct?.price?.toFixed(2)}`;

    return (
        <div className={styles.subscriptionSummary}>
            {isDesktopLayout ? (
                <div className={styles.summaryInfoDesktop}>
                    {showStorage && (
                        <>
                            <div className={styles.summaryBlock}>
                                <span>
                                    <b>{t('glossary:commercialPlan')}</b>
                                    <span> ({t('subscribe:summary.monthlySubscription')})</span>
                                </span>
                                <b>{storageProduct?.name?.split('/')?.[0]}</b>
                            </div>
                            <div className={styles.summaryBlock}>
                                <div>
                                    <NonCommercialPlan>
                                        <span>{t('subscribe:summary.nextPayment')}</span>
                                        <b> {format(addMonths(new Date(), 1), DATE_FORMAT)}</b>
                                    </NonCommercialPlan>
                                    <CommercialPlan>
                                        <SubscriptionSummaryInfoText />
                                    </CommercialPlan>
                                </div>
                                <b className={styles.alignRight}>{storageTotalString}</b>
                            </div>
                        </>
                    )}
                    {((showHours && processingTimeProduct?.price !== 0) ||
                        (isUpgradingStorage && additionalHoursOnUpgrade > 0)) && (
                        <>
                            <div className={styles.summaryBlock}>
                                <span>
                                    <b>{t('glossary:processingHours')}</b>
                                    <span> ({t('subscribe:summary.package')})</span>
                                </span>
                                <b>
                                    {isUpgradingStorage
                                        ? `${additionalHoursOnUpgrade} ${t('common:hours', {
                                              count: additionalHoursOnUpgrade
                                          })}`
                                        : processingTimeProduct?.name}
                                </b>
                            </div>
                            <div className={styles.summaryBlock}>
                                {isUpgradingStorage ? (
                                    <div>{t('subscribe:summary.oneTimeCompensation')}</div>
                                ) : (
                                    <div>{t('subscribe:summary.oneTimePurchase')}</div>
                                )}
                                <b className={styles.alignRight}>{hoursTotalString}</b>
                            </div>
                        </>
                    )}
                </div>
            ) : (
                <div className={styles.summaryInfoMobile}>
                    {showStorage && (
                        <div>
                            <div className={styles.summaryBlock}>
                                <span>
                                    <b>{t('glossary:commercialPlan')}</b>
                                    <span> ({t('subscribe:summary.monthlySubscription')})</span>
                                </span>
                                <b className={styles.alignRight}>{storageTotalString}</b>
                            </div>
                            <div className={styles.summaryBlock}>
                                <b>{storageProduct?.name?.split('/')?.[0]}</b>
                                <span>
                                    <NonCommercialPlan>
                                        <span>{t('subscribe:summary.nextPayment')}</span>
                                        <b> {format(addMonths(new Date(), 1), DATE_FORMAT)}</b>
                                    </NonCommercialPlan>
                                    <CommercialPlan>
                                        <SubscriptionSummaryInfoText />
                                    </CommercialPlan>
                                </span>
                            </div>
                        </div>
                    )}
                    {((showHours && processingTimeProduct?.price !== 0) ||
                        (isUpgradingStorage && additionalHoursOnUpgrade > 0)) && (
                        <div className={styles.summaryHours}>
                            <div className={styles.summaryBlock}>
                                <span>
                                    <b>{t('glossary:processingHours')}</b>
                                    <span> ({t('subscribe:summary.package')})</span>
                                </span>
                                <b className={styles.alignRight}>{hoursTotalString}</b>
                            </div>
                            <div className={styles.summaryBlock}>
                                <b>
                                    {isUpgradingStorage
                                        ? `${additionalHoursOnUpgrade} ${t('common:hours')}`
                                        : processingTimeProduct?.name}
                                </b>
                                <span>
                                    {isUpgradingStorage
                                        ? t('subscribe:summary.oneTimeCompensation')
                                        : t('subscribe:summary.oneTimePurchase')}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            )}

            <div className={styles.subscriptionTotal}>
                <div className={styles.totalSum}>
                    <TotalSumText
                        isCommercial={isCommercial}
                        onlyHours={onlyHours}
                        isOverdue={isOverdue}
                        storageProductGrade={storageProduct?.grade}
                        scheduledPlanType={scheduledPlanType}
                        totalPrice={Number(totalPrice)}
                    />
                </div>
            </div>
        </div>
    );
}
