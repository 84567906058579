import React, { ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
    className?: string;
    children: ReactNode;
};

export default function ModalActions({ className, children }: Props) {
    return <div className={classNames('modal-actions', className)}>{children}</div>;
}
