import * as Cesium from 'cesium';
import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route } from 'react-router-dom';
import { CesiumMovementEvent, ScreenSpaceEvent, useCesium } from 'resium';
import { useKey } from 'rooks';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import useDrawingCancel from '../../../hooks/useDrawingCancel';
import useDrawingModeCursor from '../../../hooks/useDrawingCursor';
import { formatCoordinatesWithAxis, transformCoordinates } from '../../../lib/coordinatesHelper';
import getGeometryMeasures from '../../../lib/getGeometryMeasures';
import pickEntities, { getPointFromPickedObjects } from '../../../lib/pickEntities';
import { DRAWING_CURSOR, GeometryTypes, IS_TOUCH_DEVICE, Routes, Units } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { CurrentlyEditingShape, ProjectStructureObjectTypes, SelectedObject } from '../../../store/helpers/interfaces';
import { elevationProfileCalculationInterrupted, setSelectedObject } from '../../../store/sharedActions';
import { DeterminedCoordinateSystem } from '../../../store/slices/coordinateSystems';
import { isLinkedDataset } from '../../../store/slices/datasets';
import {
    createCoordinateInGeometry,
    replaceCoordinatesInGeometry,
    selectGeometries,
    updateGeometryContent,
    updateGeometryProperty
} from '../../../store/slices/geometries';
import { updateGeometry } from '../../../store/slices/geometryLayers';
import {
    setCurrentlyEditingShapeId,
    setCurrentlyHoveringShapeId,
    setFloatingPointCoordinates,
    setHoveredGeometryNodeId,
    setLimitBoxMovingFaceId,
    setSelectedGeometryNodeId,
    setTourPlayerState
} from '../../../store/slices/projectView';
import { putStructure, selectStructureWithEnabledLimitBox } from '../../../store/slices/structure';
import { calculationAborted, invalidateCalculation } from '../../../store/slices/volume';
import useLimitBoxRecalculation from '../geometry-layers/limit-box/useLimitBoxRecalculation';
import DrawPoint from './DrawPoint';
import DrawPolygon from './DrawPolygon';
import DrawPolyline from './DrawPolyline';
import DrawRulerLine from './DrawRulerLine';
import DrawViewpoint from './DrawViewpoint';
import splitEntityId from './splitEntityId';
import useDrawing from './useDrawing';
import { selectIsCreateViewpointControlEnabled } from '../../../store/slices/presentation';

export default function GeometryDrawing() {
    const { scene, viewer } = useCesium();
    const dispatch: AppDispatch = useDispatch();
    const { owned } = useContext(ProjectViewAccessContext);
    const selectedGeometryType = useSelector(state => state.projectView.selectedGeometryType);
    const isCesiumLoaded = useSelector(state => state.projectView.isCesiumLoaded);
    const currentlyDrawingShapeId = useSelector(state => state.projectView.currentlyDrawingShapeId);
    const isCamerasInspectionEnabled = useSelector(state => state.projectView.isCamerasInspectionEnabled);
    const currentlyEditingShapeId = useSelector(state => state.projectView.currentlyEditingShapeId);
    const currentlyHoveringShapeId = useSelector(state => state.projectView.currentlyHoveringShapeId);
    const isRulerToolEnabled = useSelector(state => state.projectView.isRulerToolEnabled);
    const isCreateViewpointControlEnabled = useSelector(state => selectIsCreateViewpointControlEnabled(state));
    const limitBoxMovingFaceId = useSelector(state => state.projectView.limitBoxMovingFaceId);
    const datasets = useSelector(state => state.datasets.datasets);
    const boundingSpheres = useSelector(state => state.projectView.tilesetsBoundingSpheres);
    const tourPlayerState = useSelector(state => state.projectView.tourPlayerState);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const temporaryLayers = useSelector(state => state.project.structure.temporaryLayers);
    const geometries = useSelector(selectGeometries);
    const currentCrs = useSelector(state => state.coordinateSystems.currentCrs);
    const units = useSelector(state => state.coordinateSystems.units);
    const structureWithEnabledLimitBox = useSelector(state => selectStructureWithEnabledLimitBox(state));
    const tilesetIdOfDatasetWithEnabledLimitBox = datasets.find(d => d.datasetUid === structureWithEnabledLimitBox?.uid)
        ?.visualData?.dataUid;
    const boundingSphereOfDatasetWithEnabledLimitBox = boundingSpheres[tilesetIdOfDatasetWithEnabledLimitBox || ''];

    const [lastPickedCartographicDegrees, setLastPickedCartographicDegrees] = useState<number[] | undefined>(undefined);

    const cancelDrawing = useDrawingCancel();
    const { pickCartesian, cartesianToCartographicDegrees, hasPickedSavingPointWhileDrawing } = useDrawing();

    async function resetSelectedGeometry() {
        if (
            !currentlyHoveringShapeId &&
            !currentlyDrawingShapeId &&
            selectedObject?.type === ProjectStructureObjectTypes.GEOMETRY
        ) {
            await dispatch(setSelectedObject({} as SelectedObject));
            dispatch(setSelectedGeometryNodeId(undefined));
        }
    }

    useDrawingModeCursor();
    const recalculateLimitBox = useLimitBoxRecalculation();

    useKey('Escape', () => {
        cancelDrawing();
    });

    function handleMouseMove(endPosition: Cesium.Cartesian2, startPosition: Cesium.Cartesian2, coordinates: number[]) {
        changeCursor();

        if (isRulerToolEnabled) dispatch(setFloatingPointCoordinates(coordinates));

        if (currentlyEditingShapeId?.index === undefined) {
            if (
                [GeometryTypes.POLYLINE, GeometryTypes.POLYGON].includes(
                    selectedGeometryType || ('' as GeometryTypes)
                ) &&
                !IS_TOUCH_DEVICE
            )
                dispatch(setFloatingPointCoordinates(coordinates));
        } else {
            dispatch(replaceCoordinatesInGeometry({ currentlyEditingShape: currentlyEditingShapeId, coordinates }));
            dispatch(calculationAborted(currentlyEditingShapeId.id));
            dispatch(invalidateCalculation({ id: currentlyEditingShapeId.id, owned }));
            dispatch(elevationProfileCalculationInterrupted(currentlyEditingShapeId.id));
        }

        function changeCursor() {
            if (currentlyDrawingShapeId)
                if (hasPickedSavingPointWhileDrawing(endPosition)) viewer!.canvas.style.cursor = 'pointer';
                else viewer!.canvas.style.cursor = DRAWING_CURSOR;
        }
    }

    async function changeGeometryRenderingMethod(pickedObject: {
        id: string;
        primitive: Cesium.Primitive | Cesium.PointPrimitive;
    }) {
        const geometry = geometries[pickedObject.id];
        if (geometry) {
            dispatch(updateGeometryProperty({ id: geometry.id, propName: 'renderAsEntity', propValue: true }));
            await dispatch(
                setSelectedObject({
                    type: ProjectStructureObjectTypes.GEOMETRY,
                    artifactId: geometry.id,
                    needToScroll: true
                })
            );

            if (pickedObject.primitive instanceof Cesium.PointPrimitive) return; // Point primitives' visibility is changed differently

            const attributes = pickedObject.primitive.getGeometryInstanceAttributes(pickedObject.id);
            attributes.show = Cesium.ShowGeometryInstanceAttribute.toValue(false);
            scene?.requestRender();
        }
    }

    function pickCoordinates(position: Cesium.Cartesian2) {
        const cartesianCoordinates = pickCartesian(position);
        const cartographicCoordinates = cartesianCoordinates
            ? cartesianToCartographicDegrees(cartesianCoordinates)
            : undefined;
        if (cartographicCoordinates) setLastPickedCartographicDegrees(cartographicCoordinates);
        return { cartesianCoordinates, cartographicCoordinates };
    }

    return (
        <Route
            exact
            path={[Routes.PROJECT_VIEW, Routes.SHARED_PROJECT_VIEW, Routes.EMBEDDED_PROJECT_VIEW, Routes.SITE_VIEW]}
        >
            {!isCamerasInspectionEnabled && (
                <>
                    {/* Latest event handler overwrites all */}
                    {selectedGeometryType === GeometryTypes.POINT && (
                        <DrawPoint lastPickedCartographicDegrees={lastPickedCartographicDegrees!} />
                    )}
                    {selectedGeometryType === GeometryTypes.POLYLINE && (
                        <DrawPolyline lastPickedCartographicDegrees={lastPickedCartographicDegrees!} />
                    )}
                    {selectedGeometryType === GeometryTypes.POLYGON && (
                        <DrawPolygon lastPickedCartographicDegrees={lastPickedCartographicDegrees!} />
                    )}

                    {!selectedGeometryType && (
                        <ScreenSpaceEvent
                            type={Cesium.ScreenSpaceEventType.LEFT_CLICK}
                            action={(e: CesiumMovementEvent) => {
                                let pickedObject = scene?.pick(e.position!);
                                const isGeometryPrimitivePicked =
                                    (pickedObject?.primitive instanceof Cesium.Primitive ||
                                        pickedObject?.primitive instanceof Cesium.PointPrimitive) &&
                                    !(pickedObject?.id instanceof Cesium.Entity) &&
                                    !pickedObject?.id.includes('frustumPoint');

                                if (isGeometryPrimitivePicked) changeGeometryRenderingMethod(pickedObject);
                                resetSelectedGeometry();
                                scene?.requestRender();
                            }}
                        />
                    )}
                </>
            )}
            {isCreateViewpointControlEnabled && (
                <DrawViewpoint lastPickedCartographicDegrees={lastPickedCartographicDegrees!} />
            )}
            {isRulerToolEnabled && <DrawRulerLine lastPickedCartographicDegrees={lastPickedCartographicDegrees!} />}

            {isCesiumLoaded && (
                <>
                    <ScreenSpaceEvent
                        type={Cesium.ScreenSpaceEventType.MOUSE_MOVE}
                        action={(e: CesiumMovementEvent) => {
                            const { cartesianCoordinates, cartographicCoordinates } = pickCoordinates(e.endPosition!);
                            updateCoordinates(cartesianCoordinates, currentCrs, units);

                            if (
                                cartographicCoordinates &&
                                (!isCamerasInspectionEnabled || (isCamerasInspectionEnabled && isRulerToolEnabled))
                            ) {
                                handleMouseMove(e.endPosition!, e.startPosition!, cartographicCoordinates);
                            }

                            if (limitBoxMovingFaceId && boundingSphereOfDatasetWithEnabledLimitBox) {
                                recalculateLimitBox(e.startPosition!, e.endPosition!, scene!);
                            }
                        }}
                    />

                    {!isCamerasInspectionEnabled && (
                        <>
                            <ScreenSpaceEvent
                                type={Cesium.ScreenSpaceEventType.LEFT_DOWN}
                                action={async (e: CesiumMovementEvent) => {
                                    if (tourPlayerState === 'playing') {
                                        dispatch(setTourPlayerState('pause'));
                                    }

                                    if (IS_TOUCH_DEVICE) {
                                        const { cartesianCoordinates } = pickCoordinates(e.position!);
                                        updateCoordinates(cartesianCoordinates, currentCrs, units);
                                    }

                                    if (selectedGeometryType) return; // we are drawing then

                                    const entities = pickEntities(scene!, e.position!);
                                    const pointEntity = getPointFromPickedObjects(entities);
                                    if (
                                        pointEntity?.id &&
                                        !pointEntity.id.includes('nameLabel') &&
                                        !pointEntity.id.includes('ruler') &&
                                        !pointEntity.id.includes('elevationProfilePoint') &&
                                        !pointEntity.id.includes('frustumPoint')
                                    ) {
                                        const [type, id, index, midpoint] = splitEntityId(pointEntity.id);

                                        if (!isCreateViewpointControlEnabled && !isRulerToolEnabled) {
                                            dispatch(setCurrentlyHoveringShapeId(id));
                                            await dispatch(
                                                setSelectedObject({
                                                    type: ProjectStructureObjectTypes.GEOMETRY,
                                                    artifactId: id,
                                                    needToScroll: true
                                                })
                                            );
                                        }

                                        const layer = temporaryLayers.find(l => l.geometries.includes(id));
                                        // Editing not own geometries id forbidden
                                        if (
                                            (!owned && !layer?.isTemporary) ||
                                            (layer && isLinkedDataset(layer, projectInfo.id!))
                                        ) {
                                            return;
                                        }

                                        const checkedIndex = !isNaN(Number(index)) ? Number(index) : undefined;

                                        const currentlyEditingShape: CurrentlyEditingShape = {
                                            id,
                                            type,
                                            index: checkedIndex,
                                            isMidpoint: !!midpoint
                                        };
                                        dispatch(setCurrentlyEditingShapeId(currentlyEditingShape));

                                        if (midpoint) {
                                            dispatch(
                                                createCoordinateInGeometry({
                                                    currentlyEditingShape,
                                                    coordinates: lastPickedCartographicDegrees!
                                                })
                                            );
                                            dispatch(setHoveredGeometryNodeId(undefined));
                                        }
                                    }
                                }}
                            />
                            <ScreenSpaceEvent
                                type={Cesium.ScreenSpaceEventType.LEFT_UP}
                                action={e => {
                                    if (limitBoxMovingFaceId) {
                                        dispatch(setLimitBoxMovingFaceId(null));
                                        if (structureWithEnabledLimitBox) {
                                            dispatch(
                                                putStructure({
                                                    projectId: projectInfo.id!,
                                                    structureInfo: structureWithEnabledLimitBox,
                                                    type: ProjectStructureObjectTypes.DATASET
                                                })
                                            );
                                        }
                                    }

                                    if (!selectedGeometryType && currentlyEditingShapeId) {
                                        if (owned) {
                                            const layer = temporaryLayers.find(l =>
                                                l.geometries.includes(currentlyEditingShapeId.id)
                                            );
                                            const geoJson = geometries[currentlyEditingShapeId.id]?.content!;

                                            if (geoJson) {
                                                const measures = !layer?.isPresentation
                                                    ? getGeometryMeasures(geoJson)
                                                    : {};

                                                const newGeoJson = {
                                                    ...geoJson,
                                                    properties: { ...geoJson.properties, ...measures }
                                                };
                                                dispatch(
                                                    updateGeometry({
                                                        id: currentlyEditingShapeId.id,
                                                        projectUid: projectInfo.id!,
                                                        layerUid: layer?.id!,
                                                        geoJson: newGeoJson
                                                    })
                                                );
                                                dispatch(
                                                    updateGeometryContent({
                                                        id: currentlyEditingShapeId.id,
                                                        geoJson: newGeoJson
                                                    })
                                                );
                                            }
                                        }
                                        dispatch(setCurrentlyEditingShapeId(undefined));
                                    }
                                }}
                            />
                        </>
                    )}
                </>
            )}
        </Route>
    );
}

// Update coordinates handler is here, because cesium doesn't allow multiple mouse events handlers
function updateCoordinates(
    coordinates: Cesium.Cartesian3 | undefined,
    coordinateSystem: DeterminedCoordinateSystem,
    units: Units
) {
    const coordAxis_1 = document.getElementById('coordAxis_1');
    const coordAxis_2 = document.getElementById('coordAxis_2');
    const altAxis = document.getElementById('altAxis');

    if ([coordAxis_1, coordAxis_2, altAxis].every(el => !!el)) {
        const transformedCoords = transformCoordinates(coordinates, coordinateSystem, units);
        if (transformedCoords) {
            const formattedCoords = formatCoordinatesWithAxis(transformedCoords, coordinateSystem, units);
            coordAxis_1!.textContent = formattedCoords[0];
            coordAxis_2!.textContent = formattedCoords[1];
            altAxis!.textContent = coordinateSystem.invalidateHeight
                ? `${coordinateSystem.axis![2]}: -`
                : formattedCoords[2];
        } else {
            coordAxis_1!.textContent = '-';
            coordAxis_2!.textContent = '-';
            altAxis!.textContent = '-';
        }
    }
}
