import { unwrapResult } from '@reduxjs/toolkit';
import produce from 'immer';
import { useCallback, useEffect, useState } from 'react';
import { Provider } from 'react-bus';
import { useDispatch } from 'react-redux';
import {
    Redirect,
    Route,
    Switch,
    generatePath,
    useHistory,
    useLocation,
    useParams,
    useRouteMatch
} from 'react-router-dom';
import { batchActions } from 'redux-batched-actions';
import { usePreviousImmediate } from 'rooks';
import ProjectBreadcrumbs from '../components/ProjectView/breadcrumbs/ProjectBreadcrumbs';
import ProjectPlaceholders from '../components/ProjectView/placeholders/ProjectPlaceholders';
import DatasetProgressPoller from '../components/ProjectView/dataset-progress-poller/DatasetProgressPoller';
import FlyToTilesetsContext, { FlyToTilesetsContextValue } from '../contexts/FlyToTilesetsContext';
import ProjectViewAccessContext, { ProjectViewAccessContextValue } from '../contexts/ProjectViewAccessContext';
import { AccessInfo } from '../generated/access-api/model';
import { Status } from '../generated/dataset-api';
import useSiteViewQueryParams from '../hooks/useSiteViewQueryParams';
import isProjectBelongsUser from '../lib/isProjectBelongsUser';
import ProjectViewPage from '../pages/ProjectView';
import Site from '../pages/site/Site';
import { Routes } from '../sharedConstants';
import { AppDispatch, useSelector } from '../store';
import { createLoadingSelector } from '../store/selectors/createLoadingSelector';
import { resetCameras } from '../store/slices/cameras';
import {
    getDefaultCoordinateSystems,
    getPersonalCoordinateSystems,
    resetProjectViewCrsState,
    setProjectCoordinateSystems
} from '../store/slices/coordinateSystems';
import { setDatasets } from '../store/slices/datasets';
import { setRecentlyFinishedUploadIds } from '../store/slices/datasetsUpload';
import { removeGeometry } from '../store/slices/geometries';
import { getProjectById, resetProjectById } from '../store/slices/project';
import { resetStructureState } from '../store/slices/projectStructure';
import {
    getAccess,
    getAccessInfo,
    resetAccessInfoAndToken,
    setAccessInfo,
    setAccessToken
} from '../store/slices/sharing';
import { getSite, getSiteProjectInfo, resetSiteState } from '../store/slices/site';
import { isAxiosError } from 'axios';
import { ProjectType } from '../generated/cloud-frontend-api';

const isAnyQueryPendingSelector = createLoadingSelector([
    getProjectById.typePrefix,
    getAccessInfo.typePrefix,
    getAccess.typePrefix,
    getSite.typePrefix
]);
const isLoadingSelector = createLoadingSelector([getProjectById.typePrefix]);

export default function ProjectView() {
    const dispatch: AppDispatch = useDispatch();
    const { pathname } = useLocation();
    const history = useHistory();
    const sharing = useSelector(state => state.sharing);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const layers = useSelector(state => state.project.structure.temporaryLayers);
    const currentlyDrawingShapeId = useSelector(state => state.projectView.currentlyDrawingShapeId);
    const datasets = useSelector(state => state.datasets.datasets);
    const isAnyQueryPending = useSelector(state => isAnyQueryPendingSelector(state));
    const isLoading = useSelector(state => isLoadingSelector(state));
    const [hasQueriedForProject, setHasQueriedForProject] = useState(false);
    const [isPlaceholderVisible, setPlaceholderVisible] = useState(false);
    const params = useParams<{ id?: string; hash?: string; embed?: string }>();
    const isSiteViewRoute = !!useRouteMatch({ exact: true, path: Routes.SITE_VIEW });
    const { queryProjectId } = useSiteViewQueryParams();
    const prevPathname = usePreviousImmediate(pathname);
    const isSiteRoute = !!useRouteMatch({ path: Routes.SITE, exact: true });

    const [flyToTilesetsContextValue, setFlyToTilesetsContextValue] = useState<FlyToTilesetsContextValue>({
        clickedTilesets: { ids: null, objectType: null },
        setClickedTilesetIds(newClickedTilesets) {
            setFlyToTilesetsContextValue(prevState => ({ ...prevState, clickedTilesets: newClickedTilesets }));
        }
    });
    const [accessModeContextValue, setAccessModeContextValue] = useState<ProjectViewAccessContextValue>({
        owned: !!sharing.accessInfo?.owned,
        rejectedProject: false,
        isInEmbedView: false
    });

    const resetState = useCallback(() => {
        // AUTOBATCH
        dispatch(resetProjectById());
        dispatch(resetAccessInfoAndToken());
        dispatch(setDatasets([]));
        dispatch(setRecentlyFinishedUploadIds([]));
        dispatch(resetProjectViewCrsState());
        dispatch(resetCameras());
        dispatch(resetStructureState());
        dispatch(resetSiteState());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getDefaultCoordinateSystems());
    }, [dispatch]);

    useEffect(() => {
        resetState();
        fetchProject();

        async function fetchProject() {
            let projectId = params.id;
            const hash = params.hash;
            let accessInfo: AccessInfo = { owned: true, projectId };

            try {
                if (isSiteViewRoute && projectId) {
                    const siteInfo = await dispatch(getSite({ projectId })).unwrap();
                    dispatch(getSiteProjectInfo({ projectId: projectId!, access: hash }));
                    if (queryProjectId) projectId = queryProjectId;
                    else {
                        const firstProjectId = siteInfo.projects?.[0]?.uid;
                        if (firstProjectId) projectId = firstProjectId;
                        else return;
                    }
                }

                if (!projectId) {
                    dispatch(setAccessToken(hash!));
                    accessInfo = unwrapResult(await dispatch(getAccessInfo(hash!)));
                    projectId = accessInfo?.projectId!;
                    if (!projectId) throw new Error('accessDeniedForSharedProject');
                } else {
                    dispatch(setAccessInfo(accessInfo));
                }

                const result = await dispatch(getProjectById({ id: projectId, access: params.hash }));
                if (getProjectById.rejected.match(result)) {
                    setAccessModeContextValue(
                        produce(draft => {
                            draft.rejectedProject = true;
                        })
                    );
                }
                const { projectInfo } = unwrapResult(result);
                if (projectInfo.type === ProjectType.SITE) {
                    throw new Error('Requesting site on project route');
                }
                if (projectInfo.type === ProjectType.FOLDER) {
                    throw new Error('Requesting folder on project route');
                }
                dispatch(setProjectCoordinateSystems(projectInfo.coordinateSystemInfo));
                const isOwnedProject = isProjectBelongsUser(accessInfo, projectInfo);
                if (isOwnedProject) {
                    dispatch(getPersonalCoordinateSystems());
                }
                setAccessModeContextValue(
                    produce(draft => {
                        draft.owned = isOwnedProject;
                    })
                );
            } catch (e: any) {
                if (
                    window.location.pathname.includes('shared/projects') &&
                    e?.message === 'accessDeniedForSharedProject'
                ) {
                    return;
                }

                history.replace(generatePath(Routes.NOT_FOUND));
            } finally {
                setHasQueriedForProject(true);
            }
        }
        // eslint-disable-next-line
    }, [dispatch, queryProjectId, isSiteViewRoute, isSiteRoute]);

    // Cleanup unfinished drawing on pathname change
    useEffect(() => {
        if (pathname !== prevPathname && currentlyDrawingShapeId) {
            const layer = layers.find(l => l.geometries.includes(currentlyDrawingShapeId));
            dispatch(removeGeometry({ id: currentlyDrawingShapeId, datasetId: layer?.id! }));
        }
    }, [pathname, prevPathname, currentlyDrawingShapeId, dispatch, layers]);

    useEffect(() => {
        return () => {
            resetState();
        };
    }, [resetState]);

    return (
        <ProjectViewAccessContext.Provider value={accessModeContextValue}>
            <Provider>
                <div className='map' id='wrapper'>
                    <ProjectBreadcrumbs />

                    {!isPlaceholderVisible && (
                        <Switch>
                            <Route exact path={[Routes.SITE, Routes.SHARED_SITE]}>
                                <Site />
                            </Route>

                            <Route exact path={[Routes.SHARED_PROJECT_VIEW, Routes.PROJECT_VIEW, Routes.SITE_VIEW]}>
                                <FlyToTilesetsContext.Provider value={flyToTilesetsContextValue}>
                                    <ProjectViewPage
                                        isLoading={isLoading}
                                        isAnyQueryPending={isAnyQueryPending}
                                        hasQueriedForProject={hasQueriedForProject}
                                    />
                                    {sharing.accessInfo?.owned &&
                                        window.location.pathname.includes('shared/projects') &&
                                        projectInfo?.id && (
                                            <Redirect to={generatePath(Routes.PROJECT_VIEW, { id: projectInfo.id })} />
                                        )}
                                </FlyToTilesetsContext.Provider>
                            </Route>
                        </Switch>
                    )}
                    <ProjectPlaceholders
                        visible={isPlaceholderVisible}
                        setPlaceholderVisible={setPlaceholderVisible}
                        hasQueriedForProject={hasQueriedForProject}
                        isAnyQueryPending={isAnyQueryPending}
                    />
                </div>

                {datasets
                    .filter(d => d.visualData?.status === Status.IN_PROGRESS)
                    .map(dataset => (
                        <DatasetProgressPoller
                            key={dataset.datasetUid}
                            dataset={dataset}
                            hasQueriedForProject={hasQueriedForProject}
                        />
                    ))}
            </Provider>
        </ProjectViewAccessContext.Provider>
    );
}
