import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { PaymentType, StorageProductGradeEnum } from '../../../generated/billing-api';
import { useSelector } from '../../../store';
import { storageProductSelector } from '../../../store/selectors';
import { SubscriptionFormValues } from '../SubscriptionForm';
import { PlanType } from '../../../generated/account-frontend-api';
import formatISODate from '../../../lib/formatISODate';

export default function SubscriptionSummaryInfoText() {
    const { t } = useTranslation('subscribe');
    const { values } = useFormikContext<SubscriptionFormValues>();
    const storageProduct = useSelector(state => storageProductSelector(values.storage)(state));
    const isOverdue = useSelector(state => state.accountResources.account.plan.current?.overdue);
    const paymentType = useSelector(state => state.accountResources.account.paymentType);
    const renewDate = useSelector(state => state.accountResources.account.plan.current?.renewDate);
    const scheduledPlanType = useSelector(state => state.accountResources.account.plan.scheduled?.type);

    const formattedRenewDate = formatISODate(renewDate);

    const hasScheduledDowngrade = (
        [StorageProductGradeEnum.DOWNGRADE, StorageProductGradeEnum.SCHEDULED] as StorageProductGradeEnum[]
    ).includes(storageProduct?.grade!);

    function getMessage() {
        if (isOverdue || scheduledPlanType === PlanType.FREE) {
            return t('summary.subscriptionDeactivation');
        } else {
            if (hasScheduledDowngrade) {
                return t('summary.subscriptionDowngrade');
            } else {
                if (paymentType === PaymentType.AUTO) {
                    return t('summary.nextAutoPayment');
                } else {
                    return t('summary.nextManualPayment');
                }
            }
        }
    }

    return (
        <span>
            {getMessage()} <b>{formattedRenewDate}</b>
        </span>
    );
}
