import { Resource } from 'cesium';
import { ReactNode, memo, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import { Chunk, ProjectPartType, SourceType, Status } from '../../../generated/cloud-frontend-api';
import is3DElevation from '../../../lib/is3DElevation';
import isProjectBelongsUser from '../../../lib/isProjectBelongsUser';
import isTMS from '../../../lib/isTMS';
import { EmbeddedProjectViewRouteParams } from '../../../pages/EmbeddedProjectView';
import { EMPTY_ARRAY, LOGIN_URL } from '../../../sharedConstants';
import { ApplicationState, useSelector } from '../../../store';
import { ExtendedDatasetInfo } from '../../../store/slices/datasetfilesUpload';
import getFilenameExtension from '../../../lib/getFilenameExtension';
import { CESIUM3DTILES_EXTENSIONS } from '../../../store/helpers/dataset-files-validators/DatasetFilesValidator';
import { compact, uniq } from 'lodash';

const isDatasetReady = (d: ExtendedDatasetInfo): boolean =>
    (d.projectPartType === ProjectPartType.DATASETS || d.projectPartType === ProjectPartType.TILESETS) &&
    !!d.visualData?.dataUid &&
    d.visualData?.status === Status.COMPLETED;

const selectReadyTilesetsAndDatasets = createSelector(
    (state: ApplicationState) => state.datasets.datasets,
    datasets => datasets.filter(isDatasetReady)
);

const selectReadyTilesetsAndDatasetsFromComparedProject = createSelector(
    (state: ApplicationState) => state.compareTool.comparedProject?.datasets,
    datasets => (datasets || EMPTY_ARRAY).filter(isDatasetReady)
);

export function useResourceProvider() {
    const { embed } = useParams<EmbeddedProjectViewRouteParams>();
    const projectInfo = useSelector(state => state.project.projectInfo);
    const chunks = useSelector(state => state.project.structure.chunks);
    const datasets = useSelector(selectReadyTilesetsAndDatasets);
    const comparedProjectDatasets = useSelector(selectReadyTilesetsAndDatasetsFromComparedProject);
    const comparedProjectInfo = useSelector(state => state.compareTool.comparedProject?.projectInfo);
    const comparedProjectChunks = useSelector(state => state.compareTool.comparedProject?.chunks);
    const accessInfo = useSelector(state => state.sharing.accessInfo);
    const access = useSelector(state => state.sharing.access);
    const [resources, setResources] = useState<AppResource[]>([]);
    const [hasInitialized, setHasInitialized] = useState(false);
    const isOwnedProject = isProjectBelongsUser(accessInfo, projectInfo);

    const dataUids = useMemo(() => {
        return uniq(compact([...datasets, ...comparedProjectDatasets].map(d => d.visualData?.dataUid)));
    }, [datasets, comparedProjectDatasets]);

    useEffect(() => {
        if (hasInitialized || !datasets.length) return;
        setResources(
            datasets.map(d =>
                AppResource.build(
                    d,
                    projectInfo.id!,
                    chunks,
                    'current',
                    isOwnedProject ? undefined : access.accessKey,
                    embed
                )
            )
        );
        setHasInitialized(true);
    }, [hasInitialized, chunks, datasets, embed, access, isOwnedProject, projectInfo.id]);

    useEffect(() => {
        if (!hasInitialized) return;
        for (const d of datasets) {
            if (resources.findIndex(r => r.tilesetId === d.visualData?.dataUid) === -1) {
                setResources(resources => [
                    ...resources,
                    AppResource.build(
                        d,
                        projectInfo.id!,
                        chunks,
                        'current',
                        isOwnedProject ? undefined : access.accessKey,
                        embed
                    )
                ]);
            }
        }
    }, [chunks, datasets, embed, access, isOwnedProject, projectInfo.id, resources, hasInitialized]);

    useEffect(() => {
        if (!comparedProjectInfo?.id || !comparedProjectChunks) return;
        for (const d of comparedProjectDatasets) {
            if (resources.findIndex(r => r.tilesetId === d.visualData?.dataUid) === -1) {
                setResources(resources => [
                    ...resources,
                    AppResource.build(d, comparedProjectInfo.id!, comparedProjectChunks, 'compared')
                ]);
            }
        }
    }, [comparedProjectChunks, comparedProjectDatasets, comparedProjectInfo?.id, resources, hasInitialized]);

    useEffect(() => {
        // Remove resources left from previous compare session
        if (!hasInitialized) return;
        setResources(resources => resources.filter(r => dataUids.includes(r.tilesetId)));
    }, [dataUids, hasInitialized]);

    return resources;
}

export class AppResource extends Resource {
    constructor(
        options: Resource.ConstructorOptions,
        readonly tilesetId: string,
        readonly sourceType: SourceType,
        readonly fromSource: 'artifact' | 'dataset',
        readonly maximumScreenSpaceError: number,
        readonly fromProject: 'current' | 'compared'
    ) {
        super(options);
    }

    static build(
        dataset: ExtendedDatasetInfo,
        projectId: string,
        chunks: Chunk[],
        fromProject: 'current' | 'compared',
        accessHash?: string,
        embed?: string
    ): AppResource {
        // Cesium TileMapServiceImageryProvider automatically appends tilemap.xml to url
        let url = `/api/projects/${projectId}/datasets/${dataset.datasetUid}/tilesets/${dataset.visualData?.dataUid}/${
            isTMS(dataset.sourceData?.type!) || is3DElevation(dataset.sourceData?.type!) ? '' : 'tileset.json'
        }`;
        if (accessHash) url = url.concat(`?access=${accessHash}`);
        if (embed) url = url.concat(`?embed=${embed}`);

        const fromSource = chunks.some(c => c.artifacts?.findIndex(a => a.datasetUid === dataset.datasetUid) !== -1)
            ? 'artifact'
            : 'dataset';

        const extension = getFilenameExtension(dataset.name || '');
        const maximumScreenSpaceError =
            dataset.projectPartType === ProjectPartType.DATASETS &&
            CESIUM3DTILES_EXTENSIONS.includes(extension.toLowerCase())
                ? 16
                : 1;

        return new AppResource(
            {
                url,
                headers: { 'X-Requested-With': 'XMLHttpRequest' },
                retryCallback: (resource: Resource | undefined, error: any) => {
                    if (error.statusCode === 403 || error.statusCode === 401) window.location.href = LOGIN_URL;
                    if (error.statusCode >= 500 && error.statusCode <= 599) return true;
                    return false;
                },
                retryAttempts: 1
            },
            dataset.visualData?.dataUid!,
            dataset.sourceData?.type!,
            fromSource,
            maximumScreenSpaceError,
            fromProject
        );
    }
}
