import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApplicationState } from '..';
import { accountApi } from '../../api/initApis';
import { BillingAddressInfo, PersonalInfo, Subscriptions } from '../../generated/account-frontend-api';
import { ApiError } from '../../sharedConstants';

interface UserState extends Required<Omit<PersonalInfo, 'subscriptions' | 'billingAddress'>> {
    billingAddress: Required<BillingAddressInfo>;
}

const initialState: UserState = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    billingAddress: {
        address: '',
        city: '',
        country: '',
        postalCode: '',
        region: ''
    },
    fastspringLink: ''
};

const name = 'user';

export const getPersonalInfo = createAsyncThunk(`${name}/getPersonalInfo`, async () => {
    const { data } = await accountApi.getPersonalInfo();
    return data;
});

type UpdatePersonalInfoArgs = Required<Omit<PersonalInfo, 'fastspringLink'>>;
export const updatePersonalInfo = createAsyncThunk<
    UpdatePersonalInfoArgs,
    UpdatePersonalInfoArgs,
    { rejectValue: ApiError }
>(
    `${name}/update`,
    (personalInfo, { rejectWithValue }) => {
        return accountApi
            .updatePersonalInfo(personalInfo)
            .then(() => personalInfo)
            .catch(error => rejectWithValue(error?.response?.data));
    },
    {
        condition(arg, { getState }): boolean | undefined {
            const isPending = (getState as () => ApplicationState)().loading[`${name}/update`]?.isLoading;
            if (isPending) return false;
        }
    }
);

const userSlice = createSlice({
    name,
    initialState,
    reducers: {
        setPersonalInfo(
            state,
            { payload }: PayloadAction<Required<Pick<PersonalInfo, 'firstName' | 'lastName' | 'company'>>>
        ) {
            state.firstName = payload.firstName;
            state.lastName = payload.lastName;
            state.company = payload.company;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getPersonalInfo.fulfilled, (state, { payload }) => {
                state.firstName = payload.firstName || '';
                state.lastName = payload.lastName || '';
                state.email = payload.email || '';
                state.billingAddress = (payload.billingAddress || {}) as Required<BillingAddressInfo>;
                state.company = payload.company || '';
                state.fastspringLink = payload.fastspringLink || '';
            }) // Have to react on update to prevent formik incorrect reinitialization in ProfileForm
            .addCase(updatePersonalInfo.fulfilled, (state, { payload }) => {
                state.billingAddress = payload.billingAddress as Required<BillingAddressInfo>;
                state.company = payload.company || '';
            });
    }
});

export default userSlice.reducer;
