import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch, useSelector } from '../../../store';
import { setFullscreen } from '../../../store/slices/projectView';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';

export default function FullscreenButton() {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const isFullscreen = useSelector(state => state.projectView.isFullscreen);

    const fullscreenElem = document.body as any;
    const useWebkit = useMemo(() => {
        return (
            typeof fullscreenElem.requestFullscreen !== 'function' &&
            typeof fullscreenElem.webkitRequestFullscreen === 'function'
        );
    }, [fullscreenElem]);

    useEffect(() => {
        const prefix = useWebkit ? 'webkit' : '';

        function onFullscreenChange() {
            const hasFullScreenElement = Boolean(
                useWebkit ? (document as any).webkitFullscreenElement : (document as any).fullscreenElement
            );
            dispatch(setFullscreen(hasFullScreenElement));
        }

        document.addEventListener(`${prefix}fullscreenchange`, onFullscreenChange);

        return () => document.removeEventListener(`${prefix}fullscreenchange`, onFullscreenChange);
    }, [dispatch, useWebkit]);

    return (
        <>
            {isFullscreen ? (
                <TippyTooltip tooltipText={t('fullscreenControl.tooltipMinimize')}>
                    <div
                        className='fullscreen-btn'
                        onClick={e => {
                            useWebkit ? (document as any).webkitExitFullscreen() : (document as any).exitFullscreen();
                            dispatch(setFullscreen(false));
                        }}
                    >
                        <i className='icon icon-windowed' data-testid={'iconMinimize'} />
                    </div>
                </TippyTooltip>
            ) : (
                <TippyTooltip tooltipText={t('fullscreenControl.tooltipMaximize')}>
                    <div
                        className='fullscreen-btn'
                        onClick={e => {
                            useWebkit ? fullscreenElem.webkitRequestFullscreen() : fullscreenElem.requestFullscreen();
                        }}
                    >
                        <i className='icon icon-fullscreen' data-testid={'iconMaximize'} />
                    </div>
                </TippyTooltip>
            )}
        </>
    );
}
