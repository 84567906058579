import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { usePreviousImmediate } from 'rooks';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { reservedPropertiesNames } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { TemporaryGeometry } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { isGeometryPropertiesBlockExpanded } from '../../../store/slices/geometries';
import { updateGeometryPropertyById } from '../../../store/slices/geometryLayers';
import AdditionalProperty from './additional-property/AdditionalProperty';
import AddProperty from './AddProperty';
import CopyPropertiesControl from './elements/CopyPropertiesControl';
import PropertiesBlockHead from './elements/PropertiesBlockHead';

type Props = {
    viewOnly: boolean;
};

export default function AdditionalProperties({ viewOnly }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const geometry = useSelector(state => selectedObjectInfoSelector(state)) as TemporaryGeometry;
    const allProperties = geometry.content.properties;
    const [customProperties, setCustomProperties] = useState<string[]>([]);
    const { owned } = useContext(ProjectViewAccessContext);

    const previousSelectedObjectId = usePreviousImmediate(geometry.id);

    useEffect(() => {
        if (previousSelectedObjectId !== geometry.id)
            setCustomProperties(Object.keys(allProperties).filter(k => !reservedPropertiesNames.includes(k)));
    }, [allProperties, previousSelectedObjectId, geometry.id]);

    function getClipboardText() {
        const pairs = customProperties.map(key => `${key}\t${allProperties[key]}`);
        const text = pairs.reduce((acc, cur, index) => acc + cur + (index !== pairs.length - 1 ? '\n' : ''), '');
        return text;
    }

    const additionalBlockExpanded = isGeometryPropertiesBlockExpanded(geometry.content, 'ac_additional_block_expanded');

    return (
        <>
            <PropertiesBlockHead
                isBlockExpanded={additionalBlockExpanded}
                title={t('inspectionSidebar.geometryProperties.titleAdditional')}
                onClick={e => {
                    dispatch(
                        updateGeometryPropertyById({
                            id: geometry.id,
                            owned,
                            propName: 'ac_additional_block_expanded',
                            propValue: !additionalBlockExpanded
                        })
                    );
                }}
            >
                <CopyPropertiesControl clipboardText={getClipboardText()} />
            </PropertiesBlockHead>
            {additionalBlockExpanded && (
                <div className='properties-list'>
                    {!viewOnly && <AddProperty key={geometry.id} setCustomProperties={setCustomProperties} />}
                    {customProperties.map((key, index) => (
                        <AdditionalProperty
                            key={index}
                            label={key}
                            value={String(allProperties[key])}
                            deletable={!viewOnly}
                            readOnly={viewOnly}
                            setCustomProperties={setCustomProperties}
                            customProperties={customProperties}
                        />
                    ))}
                </div>
            )}
        </>
    );
}

export function parseProperty(propertyValue: string): string | number {
    const reducedValue = propertyValue.replace(/(  *|\r\n|\n|\r)/gm, ''); // remove spaces and breaks/new lines
    const floatValue = Number(propertyValue);
    if (!isNaN(floatValue) && reducedValue !== '') {
        return floatValue;
    }
    return propertyValue;
}
