import { ReactElement, ReactNode } from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { Routes } from '../sharedConstants';
import { useSelector } from '../store';
import AppHeader from './AppHeader/AppHeader';
import NavSidebar from './Elements/nav-sidebar/NavSidebar';
import ServerErrorNotification from './Elements/server-error-notification/ServerErrorNotification';
import Socket from './Elements/socket/Socket';
import ProjectsPageHead from './Projects/ProjectsPageHead';
import ProjectBreadcrumbs from './ProjectView/breadcrumbs/ProjectBreadcrumbs';
import PagesCopyrightBar from './Elements/pages-copyright-bar/PagesCopyrightBar';

type Props = {
    serverErrorNotification: string | undefined;
    isCommercial: boolean;
    isFree: boolean;
    children: ReactNode;
};

function Layout(props: Props) {
    const isBillingActive = useSelector(state => state.init.isBillingActive);

    const defaultLayout: ReactElement = (
        <div id={'wrapper'}>
            <AppHeader />
            <Socket />
            <NavSidebar />
            <ProjectsPageHead />
            <div className='content content_projects-list' id='content'>
                <div className='content-inner content-inner_projects-list'>{props.children}</div>
            </div>
            <PagesCopyrightBar />
        </div>
    );

    const siteStructureLayout: ReactElement = (
        <div id='wrapper'>
            <ProjectBreadcrumbs />
            <ProjectsPageHead />
            <div className='content content_site-list' id='content'>
                <div className='content-inner content-inner_projects-list'>{props.children}</div>
            </div>
            <PagesCopyrightBar />
        </div>
    );

    const paymentsPagesLayout: ReactElement = (
        <div id={'wrapper'}>
            <AppHeader />
            <Socket />
            <NavSidebar />
            <div className='content' id='content'>
                <div className='content-inner'>{props.children}</div>
            </div>
            <PagesCopyrightBar />
        </div>
    );

    const projectViewLayout: ReactElement = <>{props.children}</>;

    const cabinetViewLayout: ReactElement = (
        <div id={'wrapper'}>
            <AppHeader />
            <Socket />
            <div className='cabinet-content'>
                <div className='cabinet-content-inner'>{props.children}</div>
            </div>
            <PagesCopyrightBar />
        </div>
    );

    const unsubscribeViewLayout: ReactElement = (
        <div id='wrapper'>
            <AppHeader />
            {props.children}
            <PagesCopyrightBar />
        </div>
    );

    const defaultLayoutPaths = [Routes.INDEX, Routes.SHARED, Routes.FOLDER];

    const paymentsPagesLayoutPaths = [Routes.BILLING, Routes.INVOICES];

    const projectViewLayoutPaths = [
        Routes.PROJECT_VIEW,
        Routes.SHARED_PROJECT_VIEW,
        Routes.EMBEDDED_PROJECT_VIEW,
        Routes.SITE_VIEW,
        Routes.SHARED_SITE_VIEW
    ];

    const siteStructureLayoutPaths = [Routes.SITE, Routes.SHARED_SITE];

    const cabinetLayoutPaths = [Routes.PREFERENCES, Routes.PLAN_MANAGEMENT, Routes.UPGRADE_PLAN, Routes.USER_AGREEMENT];

    const cabinetLayoutPathsCommercial = [Routes.STORAGE, Routes.TIME];

    return (
        <>
            {props.serverErrorNotification && <ServerErrorNotification />}
            <Switch>
                <Route exact path={defaultLayoutPaths}>
                    {defaultLayout}
                </Route>
                <Route exact path={siteStructureLayoutPaths}>
                    {siteStructureLayout}
                </Route>
                <Route exact path={projectViewLayoutPaths}>
                    {projectViewLayout}
                </Route>

                {isBillingActive && (
                    <Route exact path={paymentsPagesLayoutPaths}>
                        {paymentsPagesLayout}
                    </Route>
                )}
                {isBillingActive && (
                    <Route exact path={cabinetLayoutPaths}>
                        {cabinetViewLayout}
                    </Route>
                )}
                {isBillingActive && props.isCommercial && (
                    <Route exact path={cabinetLayoutPathsCommercial}>
                        {cabinetViewLayout}
                    </Route>
                )}

                <Route exact path={Routes.UNSUBSCRIBE}>
                    {unsubscribeViewLayout}
                </Route>
                <Route path={'*'}>{projectViewLayout}</Route>
            </Switch>
        </>
    );
}

export default Layout;
