import React from 'react';
import { ProjectStructureObjectTypes } from '../store/helpers/interfaces';

// TODO make it better!
export interface FlyToTilesetsContextValue {
    clickedTilesets: { ids: string | string[] | null; objectType?: ProjectStructureObjectTypes | null };
    setClickedTilesetIds: (newClickedTilesets: {
        ids: string | string[] | null;
        objectType?: ProjectStructureObjectTypes;
    }) => void;
}

const contextValue: FlyToTilesetsContextValue = {
    clickedTilesets: { ids: null, objectType: null },
    setClickedTilesetIds: newClickedTilesets => {}
};

export default React.createContext(contextValue);
