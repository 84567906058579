import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import UndoableOperation, { UndoableOperationTypes } from '../components/ProjectView/undo-operation/UndoableOperation';
import ProjectViewAccessContext from '../contexts/ProjectViewAccessContext';
import { GeometryTypes } from '../sharedConstants';
import { AppDispatch, useSelector } from '../store';
import {
    ProjectStructureObjectTypes,
    SelectedObject,
    TemporaryGeometry,
    TemporaryLayer,
    isIssuePointFeature
} from '../store/helpers/interfaces';
import { deleteRecentlyFinishedUploadId, setSelectedObject } from '../store/sharedActions';
import { uploadRemoved } from '../store/slices/datasetsUpload';
import { addGeometry, deleteGeometry, removeGeometry } from '../store/slices/geometries';
import { deleteLayer } from '../store/slices/geometryLayers';
import { addTemporaryLayer, deleteTemporaryLayer } from '../store/slices/project';
import { setCurrentlyDrawingShapeId, setUndoableOperation } from '../store/slices/projectView';
import { deleteStructureInfo } from '../store/slices/structure';

interface ReturnValue {
    deleteGeometry(object: TemporaryGeometry): Promise<void>;
    deleteLayer(layer: TemporaryLayer): void;
    deleteFromRecentlyFinished(id: string): void;
}

export default function useProjectStructureObjectsActions(): ReturnValue {
    const dispatch: AppDispatch = useDispatch();
    const temporaryLayers = useSelector(state => state.project.structure.temporaryLayers);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const recentlyFinished = useSelector(state => state.datasetsUpload.recentlyFinished);
    const uploads = useSelector(state => state.datasetsUpload.uploads);
    const { owned } = useContext(ProjectViewAccessContext);
    const currentlyDrawingShapeId = useSelector(state => state.projectView.currentlyDrawingShapeId);

    return {
        async deleteGeometry(object: TemporaryGeometry) {
            if (currentlyDrawingShapeId === object.id) dispatch(setCurrentlyDrawingShapeId(undefined));

            const layer = temporaryLayers.find(l => l.geometries.includes(object.id))!;
            await dispatch(setSelectedObject({ type: ProjectStructureObjectTypes.LAYER, artifactId: layer.id }));
            dispatch(removeGeometry({ datasetId: layer.id, id: object.id }));

            const geoJson = { ...object.content };
            const type = UndoableOperation.mapTypeToGeometryType(object);

            const index = layer.geometries.indexOf(object.id);
            const undoableOperation = new UndoableOperation(
                type,
                () => {
                    dispatch(addGeometry({ id: object.id, datasetId: layer.id, geoJson, index }));
                    dispatch(
                        setSelectedObject({
                            type: ProjectStructureObjectTypes.GEOMETRY,
                            artifactId: object.id,
                            needToScroll: true
                        })
                    );
                },
                () =>
                    owned &&
                    dispatch(deleteGeometry({ id: object.id, layerUid: layer.id, projectUid: projectInfo.id! }))
            );
            dispatch(setUndoableOperation(undoableOperation));
        },
        async deleteLayer(layer: TemporaryLayer) {
            const index = temporaryLayers.indexOf(layer);
            await dispatch(setSelectedObject({} as SelectedObject));
            dispatch(deleteTemporaryLayer(layer.id));

            if (layer.geometries.includes(currentlyDrawingShapeId || ''))
                dispatch(setCurrentlyDrawingShapeId(undefined));

            const undoableOperation = new UndoableOperation(
                UndoableOperation.mapTypeToLayerType(layer),
                () => {
                    dispatch(addTemporaryLayer(layer, { selectOnCreate: true, index }));
                    dispatch(setSelectedObject({ type: ProjectStructureObjectTypes.LAYER, artifactId: layer.id }));
                },
                () => {
                    owned &&
                        dispatch(
                            deleteLayer({
                                projectUid: projectInfo.id!,
                                layerUid: layer.id,
                                geometryIds: layer.geometries
                            })
                        );
                    if (uploads[layer.id]) {
                        dispatch(uploadRemoved(layer.id));
                        dispatch(deleteStructureInfo({ id: layer.id }));
                    }
                }
            );
            dispatch(setUndoableOperation(undoableOperation));
        },
        deleteFromRecentlyFinished(id: string) {
            if (recentlyFinished.includes(id)) dispatch(deleteRecentlyFinishedUploadId({ id }));
        }
    };
}
