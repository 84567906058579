import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { ExtendedDatasetInfo, unlinkDatasetFromParentProject } from '../../../store/slices/datasetfilesUpload';
import UnlinkOwnDatasetModal from '../../Elements/modals/unlink-own-dataset-modal/UnlinkOwnDatasetModal';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import { ProjectPartType } from '../../../generated/cloud-frontend-api';
import { datasetInfoUpdated } from '../../../store/slices/datasets';
import { setLayerProperty } from '../../../store/slices/project';

type Props = {
    projectId: string;
    linkedProjectId: string;
    linkedProjectName: string;
    datasetInfo: ExtendedDatasetInfo;
};

export default function RelationsItem({ projectId, linkedProjectId, linkedProjectName, datasetInfo }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation(['projectView']);
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <div className='property property-project' key={linkedProjectId}>
                <div className='prop-name-wrapper'>
                    <b className='prop-project-name'>{linkedProjectName}</b>
                </div>
                <TippyTooltip tooltipText={t('projectView:inspectionSidebar.relations.tooltipUnlink')}>
                    <div
                        className='prop-action'
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                    >
                        <i className='icon icon-unlink' />
                    </div>
                </TippyTooltip>
            </div>

            {isModalOpen && (
                <UnlinkOwnDatasetModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    datasetName={datasetInfo.name!}
                    linkedProjectName={linkedProjectName}
                    onUnlink={() => {
                        dispatch(
                            unlinkDatasetFromParentProject({
                                projectId: projectId,
                                linkedProjectId: linkedProjectId,
                                datasetId: datasetInfo.datasetUid!
                            })
                        ).then(() => {
                            const linkedProjects = datasetInfo.linkedProjects?.filter(lp => lp.uid !== linkedProjectId);
                            if (datasetInfo.projectPartType === ProjectPartType.VECTOR_LAYERS)
                                dispatch(
                                    setLayerProperty({
                                        id: datasetInfo.datasetUid!,
                                        propName: 'linkedProjects',
                                        propValue: linkedProjects
                                    })
                                );
                            else dispatch(datasetInfoUpdated({ ...datasetInfo, linkedProjects }));
                        });
                    }}
                />
            )}
        </>
    );
}
