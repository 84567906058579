import { Constructor } from 'type-fest';
import * as AccessAPI from '../generated/access-api';
import * as AccessV2API from '../generated/access-api-v2';
import * as AccountAPI from '../generated/account-frontend-api';
import * as BillingAPI from '../generated/billing-api';
import * as CameraAPI from '../generated/camera-api';
import * as CloudAPI from '../generated/cloud-frontend-api';
import { Configuration, ConfigurationParameters } from '../generated/cloud-frontend-api/configuration';
import * as CoordinateSystemAPI from '../generated/coordinate-system-api';
import * as DatasetAPI from '../generated/dataset-api';
import * as DocumentAPI from '../generated/document-api';
import * as PipelineAPI from '../generated/pipeline-api';
import * as ProjectPreviewAPI from '../generated/project-preview-api';
import * as ProjectStructureAPI from '../generated/project-structure-api';
import * as EmailSubscriptionAPI from '../generated/subscription-api';
import * as UploadedDatasetAPI from '../generated/uploaded-dataset-api';
import * as VectorAPI from '../generated/vector-api';
import * as ProjectAPIv2 from '../generated/project-api-v2';
import appAxios from './appAxios';

const configurationParameters: ConfigurationParameters = {
    basePath: '/api'
};

const configurationV2Parameters: ConfigurationParameters = {
    basePath: '/api/v2'
};
const configuration = new Configuration(configurationParameters);
const configurationV2 = new Configuration(configurationV2Parameters);

function initApi<T>(apiConstructor: Constructor<T>, conf: Configuration = configuration): T {
    return new apiConstructor(conf, conf.basePath, appAxios);
}

export const projectsApi = initApi(CloudAPI.ProjectApi);
export const projectApiV2 = initApi(ProjectAPIv2.ProjectApi, configurationV2);
export const processApi = initApi(CloudAPI.ProcessApi);
export const userApi = initApi(CloudAPI.UserApi);
export const sharedProjectsApi = initApi(CloudAPI.SharedApi);
export const crsApi = initApi(CloudAPI.CrsApi);
export const accessApi = initApi(AccessAPI.ProjectAccessApi);
export const accessV2Api = initApi(AccessV2API.ProjectAccessV2Api, configurationV2);
export const cameraApi = initApi(CameraAPI.ApiApi);
export const accountApi = initApi(AccountAPI.ApiApi);
export const layerApi = initApi(VectorAPI.LayerApi);
export const geometryApi = initApi(VectorAPI.GeometryApi);
export const structureApi = initApi(ProjectStructureAPI.ProjectStructureApi);
export const datasetApi = initApi(DatasetAPI.ApiApi);
export const documentApi = initApi(DocumentAPI.ApiApi);
export const coordinateSystemApi = initApi(CoordinateSystemAPI.ApiApi);
export const pipelineApi = initApi(PipelineAPI.PipelineApi);
export const uploadedDatasetApi = initApi(UploadedDatasetAPI.ApiApi);
export const projectPreviewApi = initApi(ProjectPreviewAPI.ApiApi);
export const billingApi = initApi(BillingAPI.ApiApi);
export const emailSubscriptionApi = initApi(EmailSubscriptionAPI.EmailApi);
export const subscriptionApi = initApi(EmailSubscriptionAPI.SubscriptionApi);
export const siteApi = initApi(CloudAPI.SiteApi);
