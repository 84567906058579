import Tippy from '@tippyjs/react/headless';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { ProjectType } from '../../../generated/cloud-frontend-api';
import { Routes } from '../../../sharedConstants';
import { ReactComponent as SvgQuestion } from '../../../svg/general/question.svg';
import { ReactComponent as SvgCreateFolder } from '.././../../svg/projects_list/create_folder.svg';
import { ReactComponent as SvgCreateProject } from '.././../../svg/projects_list/create_project.svg';
import { ReactComponent as SvgCreateSite } from '.././../../svg/projects_list/create_site.svg';
import AddProjectModal from '../add-project-modal/AddProjectModal';
import TippyDropdownItemWithIcon from '../tippy-dropdown/TippyDropdownItemWithIcon';
import AddProjectHelpModal from './AddProjectHelpModal';

type Props = {
    fixedCardHeight?: boolean;
};

export default function AddProjectTile({ fixedCardHeight }: Props) {
    const { t } = useTranslation(['projects']);
    const [isHelpOpen, seIsHelpOpen] = useState(false);
    const [isCreateProjectOpen, setIsCreateProjectOpen] = useState(false);
    const [isCreateSiteOpen, setIsCreateSiteOpen] = useState(false);
    const [isCreateFolderOpen, setIsCreateFolderOpen] = useState(false);
    const canOnlyCreateProjects = !!useRouteMatch({ exact: true, path: [Routes.SITE] });
    const canCreateFolders = !!useRouteMatch({ exact: true, path: [Routes.INDEX, Routes.FOLDER] });

    return (
        <>
            <div className={classNames('add-project', { 'fixed-height': fixedCardHeight })}>
                <div className='add-project-text'>
                    <span data-testid={'addProject'}>
                        {canOnlyCreateProjects
                            ? t('projects:addProject.text_projectOnly')
                            : canCreateFolders
                            ? t('projects:addProject.text')
                            : t('projects:addProject.text_exceptFolders')}
                    </span>
                    {!canOnlyCreateProjects && (
                        <div
                            className='add-project-help'
                            data-testid={'addProjectHelp'}
                            onClick={() => seIsHelpOpen(true)}
                        >
                            <SvgQuestion />
                        </div>
                    )}
                </div>
                {!canOnlyCreateProjects ? (
                    <Tippy
                        render={(attrs, content, instance) => (
                            <div
                                className='tippy-dropdown'
                                tabIndex={-1}
                                {...attrs}
                                onClick={e => {
                                    instance?.hide();
                                }}
                            >
                                {canCreateFolders && (
                                    <TippyDropdownItemWithIcon
                                        icon={<SvgCreateFolder />}
                                        text={t('projects:addProject.createFolder')}
                                        onClick={() => {
                                            setIsCreateFolderOpen(true);
                                        }}
                                        data-testid={'createControl'}
                                    />
                                )}

                                <TippyDropdownItemWithIcon
                                    icon={<SvgCreateSite />}
                                    text={t('projects:addProject.createSite')}
                                    onClick={() => {
                                        setIsCreateSiteOpen(true);
                                    }}
                                    data-testid={'createControl'}
                                />

                                <TippyDropdownItemWithIcon
                                    icon={<SvgCreateProject />}
                                    text={t('projects:addProject.createProject')}
                                    onClick={() => {
                                        setIsCreateProjectOpen(true);
                                    }}
                                    data-testid={'createControl'}
                                />
                            </div>
                        )}
                        delay={[100, 100]}
                        offset={[0, 6]}
                        touch={true}
                        placement={'bottom-start'}
                        trigger={'click'}
                        interactive={true}
                        hideOnClick
                    >
                        <button className='btn add-project-btn'>{t('projects:addProject.add+')}</button>
                    </Tippy>
                ) : (
                    <button
                        className='btn add-project-btn'
                        onClick={e => {
                            setIsCreateProjectOpen(true);
                        }}
                    >
                        {t('projects:addProject.add+')}
                    </button>
                )}
            </div>
            <AddProjectHelpModal isOpen={isHelpOpen} setIsOpen={seIsHelpOpen} />
            <AddProjectModal
                projectType={ProjectType.NON_METASHAPE}
                isOpen={isCreateProjectOpen}
                setIsOpen={setIsCreateProjectOpen}
            />
            <AddProjectModal projectType={ProjectType.SITE} isOpen={isCreateSiteOpen} setIsOpen={setIsCreateSiteOpen} />
            <AddProjectModal
                projectType={ProjectType.FOLDER}
                isOpen={isCreateFolderOpen}
                setIsOpen={setIsCreateFolderOpen}
            />
        </>
    );
}
