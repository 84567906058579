import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Cesium from 'cesium';
import { createSelector } from 'reselect';
import { AppDispatch, ApplicationState } from '..';
import isProjectBelongsUser from '../../lib/isProjectBelongsUser';
import { GeometryTypes } from '../../sharedConstants';
import ElevationProfile, { ElevationProfileResult } from '../helpers/elevation-profile/ElevationProfile';
import { isPolylineGeometry, ProjectStructureObjectTypes, TemporaryGeometry } from '../helpers/interfaces';
import { selectedObjectInfoSelector } from '../selectors';
import { elevationProfileCalculationInterrupted } from '../sharedActions';

export type ElevationProfileCalculationInfo = {
    id: string;
    step: number;
    surfaceIds: string[]; // corresponds to datasetUid in DatasetInfo interface
    status: 'new' | 'pending' | 'rejected' | 'fulfilled' | 'aborted';
    result: ElevationProfileResult;
};

interface ElevationProfilesState {
    calculations: ElevationProfileCalculationInfo[];
    hoveredPointIndex: number | null;
}

const initialState: ElevationProfilesState = {
    calculations: [],
    hoveredPointIndex: null
};

const name = 'elevationProfiles';

interface CalculateElevationProfileArgs {
    id: string;
    scene: Cesium.Scene;
    terrainProvider: Cesium.TerrainProvider;
    owned: boolean;
}
export const calculateElevationProfile = createAsyncThunk<
    ElevationProfileResult,
    CalculateElevationProfileArgs,
    { dispatch: AppDispatch; state: ApplicationState }
>(
    `${name}/calculate`,
    async ({ id, owned, scene, terrainProvider }, { dispatch, getState, signal, rejectWithValue }) => {
        const polyline = getState().geometries.entities[id] as TemporaryGeometry<GeometryTypes.POLYLINE>;
        const projectInfo = getState().project.projectInfo;
        const { access, accessInfo, embedCode } = getState().sharing;
        const isOwnedProject = isProjectBelongsUser(accessInfo, projectInfo);
        const calculationInfo = getState().elevationProfiles.calculations.find(c => c.id === id)!;

        try {
            return await new ElevationProfile(
                polyline,
                calculationInfo,
                { getState, signal },
                terrainProvider,
                isOwnedProject ? undefined : access.accessKey,
                embedCode
            ).build();
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

const slice = createSlice({
    name,
    initialState,
    reducers: {
        calculationAdded(state, { payload }: PayloadAction<ElevationProfileCalculationInfo>) {
            const index = state.calculations.findIndex(c => c.id === payload.id);
            if (index !== -1) state.calculations[index] = payload;
            else state.calculations.push(payload);
        },
        calculationAborted(state, { payload }: PayloadAction<string>) {
            const calculation = state.calculations.find(c => c.id === payload);
            if (calculation) calculation.status = 'aborted';
        },
        setHoveredPointIndex(state, { payload }: PayloadAction<typeof initialState.hoveredPointIndex>) {
            state.hoveredPointIndex = payload;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(calculateElevationProfile.pending, (state, { meta }) => {
                const calculation = state.calculations.find(c => c.id === meta.arg.id);
                if (calculation) calculation.status = 'pending';
            })
            .addCase(calculateElevationProfile.fulfilled, (state, { meta, payload }) => {
                const calculation = state.calculations.find(c => c.id === meta.arg.id);
                if (calculation) {
                    calculation.status = 'fulfilled';
                    calculation.result = payload;
                }
            })
            .addCase(calculateElevationProfile.rejected, (state, { meta, error }) => {
                const index = state.calculations.findIndex(c => c.id === meta.arg.id);
                if (index !== -1 && !meta.aborted) {
                    state.calculations[index].status = 'rejected';
                }
            })
            .addCase(elevationProfileCalculationInterrupted, (state, { payload }) => {
                const calculation = state.calculations.find(c => c.id === payload);
                if (calculation) calculation.status = 'aborted';
            });
    }
});

export default slice.reducer;

export const { calculationAdded, calculationAborted, setHoveredPointIndex } = slice.actions;

export const selectHoveredPoint = createSelector(
    (state: ApplicationState) => state.elevationProfiles,
    (state: ApplicationState) => state.project.selectedObject,
    selectedObjectInfoSelector,
    ({ hoveredPointIndex, calculations }, selectedObject, selectedObjectInfo) => {
        if (
            hoveredPointIndex != null &&
            selectedObject?.type === ProjectStructureObjectTypes.GEOMETRY &&
            isPolylineGeometry(selectedObjectInfo as TemporaryGeometry)
        ) {
            const calculation = calculations.find(c => c.id === selectedObject?.artifactId);
            if (calculation?.result) {
                return calculation.result.points[hoveredPointIndex];
            }
        }
        return null;
    }
);

export const selectSelectedObjectElevationProfile = createSelector(
    (state: ApplicationState) => state.project.selectedObject,
    (state: ApplicationState) => state.elevationProfiles.calculations,
    (selectedObject, calculations) => calculations.find(c => c.id === selectedObject?.artifactId)
);

export const makeSelectElevationProfileById = () =>
    createSelector(
        (state: ApplicationState) => state.elevationProfiles.calculations,
        (state: ApplicationState, polylineId: string) => polylineId,
        (calculations, polylineId) => calculations.find(c => c.id === polylineId)
    );
