export default function UnsubscribeSkeleton() {
    return (
        <div className='form-unsubscribe'>
            <div className='field-group form-checkbox skeleton'>
                <div className='checkbox-skeleton'></div>
                <div className='checkbox-label-skeleton skeleton-label1'></div>
            </div>
            <div className='field-group form-checkbox skeleton'>
                <div className='checkbox-skeleton'></div>
                <div className='checkbox-label-skeleton skeleton-label2'></div>
            </div>
            <div className='field-group form-checkbox skeleton'>
                <div className='checkbox-skeleton'></div>
                <div className='checkbox-label-skeleton skeleton-label3'></div>
            </div>
            <div className='form-actions'>
                <div className='btn-skeleton'></div>
            </div>
        </div>
    );
}
