import { useTranslation } from 'react-i18next';
import { PlanType } from '../../../generated/account-frontend-api';
import { PaymentType } from '../../../generated/billing-api';
import formatISODate from '../../../lib/formatISODate';

type Props = {
    isCommercial: boolean;
    isOverdue?: boolean;
    paymentType: PaymentType;
    scheduledPlanType?: PlanType;
    renewDate?: string;
};

export default function SidebarResourceState({
    isCommercial,
    isOverdue,
    paymentType,
    scheduledPlanType,
    renewDate
}: Props) {
    const { t } = useTranslation(['glossary']);

    const formattedRenewDate = formatISODate(renewDate);

    let text = null;

    if (isCommercial) {
        if (isOverdue) {
            text = t('glossary:deactivationOn', { renewDate: formattedRenewDate });
        } else {
            if (scheduledPlanType === PlanType.FREE) {
                text = t('glossary:deactivationOn', { renewDate: formattedRenewDate });
            } else {
                if (paymentType === PaymentType.AUTO) {
                    text = t('glossary:nextPaymentBy', { renewDate: formattedRenewDate });
                } else {
                    text = t('glossary:nextPaymentOn', { renewDate: formattedRenewDate });
                }
            }
        }
    }

    return text === null ? null : <div className='plan-info'>{text}</div>;
}
