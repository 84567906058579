import * as Cesium from 'cesium';

export enum FrustumParts {
    CAMERA_ORIGIN = 'CAMERA_ORIGIN',
    FRAME = 'FRAME'
}

export type FrustumGeometryColors = Record<FrustumParts, Cesium.Color>;

export const frustumFrameAppearance = new Cesium.PerInstanceColorAppearance({ flat: true });

export const frustumCameraOriginSize = 14;
export const frustumCameraOriginOutlineWidth = 2;
export const frustumCameraOriginOutlineColor = new Cesium.Color(0.29, 0.54, 0.84, 1);
export const frustumCameraOriginScaleByDistance = new Cesium.NearFarScalar(1, 2, 100, 0.5);

export const frustumDefaultColors: FrustumGeometryColors = {
    [FrustumParts.CAMERA_ORIGIN]: new Cesium.Color(0.29, 0.54, 0.84, 0.4),
    [FrustumParts.FRAME]: new Cesium.Color(0.29, 0.54, 0.84, 1)
};

export const frustumHoverColors: FrustumGeometryColors = {
    [FrustumParts.CAMERA_ORIGIN]: new Cesium.Color(0.67, 0.88, 1, 1),
    [FrustumParts.FRAME]: new Cesium.Color(0.67, 0.88, 1, 1)
};

export const frustumActiveColors: FrustumGeometryColors = {
    [FrustumParts.CAMERA_ORIGIN]: new Cesium.Color(0.46, 0.76, 0.99, 1),
    [FrustumParts.FRAME]: new Cesium.Color(0.46, 0.76, 0.99, 1)
};
