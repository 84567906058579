import { ReactNode, SyntheticEvent } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { ModalScrollLock } from './ModalScrollLock';

export interface ModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

type Props = ModalProps & {
    children: ReactNode;
    modalClass?: string;
    onClick?: (e: SyntheticEvent) => void;
    useShareModalLayout?: boolean;
    disableOverlayClick?: boolean;
    isEmbed?: boolean;
};

type WrapperProps = {
    children: ReactNode;
    add?: boolean;
};

export const MODAL_ID = 'modal';

// Share modal use additional wrapper for vertical positioning
function AddShareWrapper({ children, add }: WrapperProps) {
    return add ? <div className='modal-share'>{children}</div> : <>{children}</>;
}

export default function Modal({
    isOpen,
    setIsOpen,
    children,
    modalClass,
    onClick,
    useShareModalLayout,
    disableOverlayClick = false,
    isEmbed = false
}: Props) {
    if (!isOpen) return null;

    return createPortal(
        <div
            className={classNames('modal', modalClass)}
            id={MODAL_ID}
            data-no-dnd='true'
            data-testid='overlay'
            onClick={e => {
                if (!disableOverlayClick) {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    // close modal on overlay click
                    setIsOpen(false);
                }
            }}
        >
            <div className='modal-dialog' role={'dialog'}>
                <ModalScrollLock />
                <AddShareWrapper add={!!useShareModalLayout}>
                    <div
                        data-testid={'modalContent'}
                        className={classNames('modal-content', { embed: isEmbed })}
                        onClick={e => {
                            // to prevent closing modal on inside click
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                        }}
                    >
                        {children}
                    </div>
                </AddShareWrapper>
            </div>
        </div>,
        document.getElementById('root') || document.body
    );
}
