import { ProjectInfo } from '../../../generated/cloud-frontend-api';
import { TemporaryGeometry, TemporaryLayer } from '../../../store/helpers/interfaces';
import { ElevationProfileCalculationInfo } from '../../../store/slices/elevationProfiles';
import { convertLengthUnit } from '../../../lib/convertUnit';
import { Units, GeometryTypes } from '../../../sharedConstants';

type Props = {
    projectInfo: ProjectInfo;
    layer: TemporaryLayer;
    previewUrl: string | undefined;
    geometries: Record<string, TemporaryGeometry>;
    elevationProfiles: ElevationProfileCalculationInfo[];
    units: Units;
    demsList: Record<string, string>;
    outputType?: 'window' | 'file';
};

export default function measurementsPDFStarter({
    projectInfo,
    layer,
    previewUrl,
    geometries,
    elevationProfiles,
    units,
    demsList,
    outputType
}: Props) {
    const projectName = projectInfo.name!;
    const companyName = ''; // TODO: правка по бэку, пробросить название компании
    const layerName = layer.name;
    const reportOutputType = outputType ? outputType : 'window';

    const layerGeometryIds = layer.geometries;
    const layerElevationProfiles = elevationProfiles.filter(profile => {
        return layerGeometryIds.includes(profile.id) && profile.status === 'fulfilled';
    });
    const layerGeometries = layerGeometryIds.map(id => geometries[id]!);

    // оптимизация передаваемой информации, удалены координаты точек
    const optimizedProfilesData = layerElevationProfiles.map(profile => {
        const geometryId = Object.keys(geometries).find(key => key === profile.id)!;
        const geometryName = geometries[geometryId]!.content.properties.ac_name;
        const geometryColor = geometries[geometryId]!.content.properties.ac_color;

        // convert profile data to current units (warning: numbers converted to strings)
        const profileStep = convertLengthUnit(profile.step, Units.METRE, units).toFixed(3);
        const profileDistance = profile.result.distances.map(value =>
            convertLengthUnit(value, Units.METRE, units).toFixed(2)
        );
        const profileAltitudes = profile.result.altitudes.map(altitude => {
            const convertedAltitudes = altitude.altitudes.map(value => convertLengthUnit(value, Units.METRE, units));
            return {
                name: altitude.name,
                color: altitude.color,
                altitudes: convertedAltitudes
            };
        });

        return {
            id: profile.id,
            name: geometryName,
            color: geometryColor,
            step: profileStep,
            distance: profileDistance,
            altitudes: profileAltitudes
        };
    });

    // необходимо отсортировать порядок профилей по порядку полилиний, т.к. профили создаются в произвольном порядке
    // а так же добавить порядковые номера профилей относительно полного списка полилиний
    const reorderedProfilesData: any[] = [];
    const reorderedProfilesListIndexes: number[] = [];

    const layerPolylinesIds = layerGeometries
        .filter(geometry => geometry.content.geometry.type === GeometryTypes.POLYLINE)
        .map(polyline => polyline.id);
    const layerProfilesIds = layerElevationProfiles.map(profile => profile.id);

    for (let i = 0; i < layerPolylinesIds.length; i++) {
        if (layerProfilesIds.includes(layerPolylinesIds[i])) {
            const profile = optimizedProfilesData.filter(profile => profile.id === layerPolylinesIds[i]);
            reorderedProfilesData.push(profile[0]);
            reorderedProfilesListIndexes.push(i);
        }
    }

    import('./MeasurementsPDFGenerator').then(({ default: measurementsPDFGenerator }) => {
        measurementsPDFGenerator(
            projectName,
            companyName,
            layerName,
            layerGeometries,
            reportOutputType,
            units,
            demsList,
            reorderedProfilesData,
            reorderedProfilesListIndexes,
            previewUrl
        );
    });
}
