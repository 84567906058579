import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { LS_ACCOUNT_IN_PROGRESS } from '../../sharedConstants';
import { ApplicationState } from '../index';

interface GlobalFlagsState {
    isServiceAvailable: boolean;
    isOnline: boolean;
    serverErrorNotification: string | undefined;
    isAccountUpdateInProgress: boolean;
}

const initialState: GlobalFlagsState = {
    isServiceAvailable: true,
    isOnline: true,
    serverErrorNotification: undefined,
    isAccountUpdateInProgress: false
};

const name = 'globalFlags';

const globalFlagsSlice = createSlice({
    name,
    initialState,
    reducers: {
        setServiceAvailability(state, action: PayloadAction<boolean>) {
            state.isServiceAvailable = action.payload;
        },
        setOnlineStatus(state, action: PayloadAction<boolean>) {
            state.isOnline = action.payload;
        },
        setServerErrorNotification(state, action: PayloadAction<string>) {
            state.serverErrorNotification = action.payload;
        },
        clearServerErrorNotification(state) {
            state.serverErrorNotification = undefined;
        }
    },
    extraReducers: builder =>
        builder.addCase(setAccountUpdateInProgress.fulfilled, (state, { payload }) => {
            state.isAccountUpdateInProgress = payload;
        })
});

export const globalFlagsActions = globalFlagsSlice.actions;

export default globalFlagsSlice.reducer;

// state has to be any here, in order to workaround a circular type reference
export const setAccountUpdateInProgress = createAsyncThunk<boolean, boolean, { state: any }>(
    `${name}/setAccountUpdateInProgress`,
    (isInProgress: boolean, { getState }) => {
        localStorage.setItem(LS_ACCOUNT_IN_PROGRESS, isInProgress ? getAccountUpdateIndicatorSnapshot(getState()) : '');
        return isInProgress;
    }
);

function getAccountUpdateIndicatorSnapshot(state: ApplicationState): string {
    return JSON.stringify({
        resources: state.accountResources.account.resources,
        plan: state.accountResources.account.plan
    });
}
