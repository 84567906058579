import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ProjectInfo } from '../../../generated/cloud-frontend-api/model/project-info';
import { EmbedViewMode } from '../../../sharedConstants';
import { AppDispatch } from '../../../store';
import Modal, { ModalProps } from '../modal/Modal';
import ModalBody from '../modal/ModalBody';
import ModalHead from '../modal/ModalHead';
import TippyTooltip from '../tippy-tooltip/TippyTooltip';
import Checkbox from '../checkbox/Checkbox';
import { disableEmbedAccess, enableEmbedAccess } from '../../../store/slices/sharing';
import { ProjectType } from '../../../generated/cloud-frontend-api';
import { extractEmbedCode } from '../../../lib/accessHelpers';

type Props = ModalProps & { project: ProjectInfo };

const TOOLTIP_TIMEOUT = 2000;
let timeout = 0;

export default function EmbedModal({ isOpen, setIsOpen, project }: Props) {
    const dispatch: AppDispatch = useDispatch();

    const { t } = useTranslation('modals');

    const [width, setWidth] = useState('640');
    const [height, setHeight] = useState('480');
    const [autoplay, setAutoplay] = useState(false);
    const [showName, setShowName] = useState(false);
    const [fullMode, setFullMode] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const embedCode = extractEmbedCode(project);
    const [tooltipType, setTooltipType] = useState<string | undefined>(undefined);

    const [embedAccess, setEmbedAccess] = useState(!!embedCode);

    const handleCopyMessage = (text: string, type: string) => {
        if (showTooltip) {
            clearTimeout(timeout);
            setShowTooltip(false);
            setTooltipType(undefined);
        }
        navigator.clipboard.writeText(text);
        setShowTooltip(true);
        setTooltipType(type);
        timeout = window.setTimeout(() => {
            setShowTooltip(false);
            setTooltipType(undefined);
        }, TOOLTIP_TIMEOUT);
    };

    useEffect(() => {
        return () => clearTimeout(timeout);
    }, []);

    const isShared = embedAccess && embedCode !== undefined;

    // const iframeSrc = `${window.location.origin}/embedded/projects/${project.id}/${embedCode}?autoplay=${
    //     autoplay ? '1' : '0'
    // }&baseImageryProvider=${baseImageryProvider}&terrainViewMode=${terrainViewMode}&position=${
    //     cameraPositions.position
    // }&orientation=${cameraPositions.orientation}`;

    const switchEmbedShare = (isShared: boolean | undefined) => {
        if (isShared) {
            setEmbedAccess(false);
            dispatch(
                disableEmbedAccess({
                    projectId: project.id!,
                    currentAccesses: project.accesses
                })
            );
        } else {
            setEmbedAccess(true);
            dispatch(
                enableEmbedAccess({
                    projectId: project.id!,
                    currentAccesses: project.accesses
                })
            );
        }
    };

    function getIframeText(mode: EmbedViewMode) {
        const url = `${window.location.origin}/embedded/projects/${project.id}/${embedCode}/?autoplay=${
            autoplay ? '1' : '0'
        }&name=${showName ? '1' : '0'}&mode=${mode}`;

        return `<iframe src="${url}" width="${isValidDimension(width) ? width : 320}px" height="${
            isValidDimension(height) ? height : 320
        }px" allow="clipboard-write" allowfullscreen="allowfullscreen" style="border: 0" title="${
            project.name
        }"></iframe>`;
    }

    return (
        <Modal setIsOpen={setIsOpen} isOpen={isOpen} modalClass='modal-embed'>
            <ModalHead setIsOpen={setIsOpen}>{t('embedModal.head')}</ModalHead>
            <ModalBody>
                <div className='embed-project-title'>
                    {project.type === ProjectType.SITE ? t('shareModal.bodySite') : t('shareModal.bodyProject')}{' '}
                    <b>{project.name}</b>
                </div>

                <div className='title-wrapper'>
                    <span>{t('embedModal.switchTitle')}</span>
                    <label className='switch-wrapper'>
                        <input type='checkbox' checked={isShared} onChange={() => switchEmbedShare(isShared)} />
                        <span className='switch-styler' />
                    </label>
                </div>

                <div className='embed-tip'>{t('embedModal.tip')}</div>
                <div className='embed-block embed-dimensions'>
                    <b className='embed-size'>{t('embedModal.size.label')}</b>
                    <input
                        className='embed-size-input'
                        type='text'
                        value={width}
                        onChange={e => {
                            if (isValidDimension(e.target.value) || !e.target.value) setWidth(e.target.value);
                        }}
                    />
                    <div className='embed-x' />
                    <input
                        className='embed-size-input'
                        type='text'
                        value={height}
                        onChange={e => {
                            if (isValidDimension(e.target.value) || !e.target.value) setHeight(e.target.value);
                        }}
                    />
                </div>
                <div className='embed-block embed-settings'>
                    <div className='embed-setting'>
                        <Checkbox
                            checked={autoplay}
                            onChange={e => {
                                setAutoplay(!autoplay);
                            }}
                        />
                        <span>{t('embedModal.startOnPageLoad')}</span>
                    </div>
                    <div className='embed-setting'>
                        <Checkbox
                            checked={showName}
                            onChange={e => {
                                setShowName(!showName);
                            }}
                        />
                        <span>{t('embedModal.showProjectName')}</span>
                    </div>
                    <div className='embed-setting'>
                        <Checkbox
                            checked={fullMode}
                            onChange={e => {
                                setFullMode(!fullMode);
                            }}
                        />
                        <span>{t('embedModal.showPanelsAndTools')}</span>
                    </div>
                </div>
                <div className='embed-block'>
                    <form className='embed-form'>
                        <input
                            type='text'
                            disabled={!isShared}
                            placeholder={getIframeText(EmbedViewMode.PRESENTATION)}
                            readOnly
                            value={
                                isShared
                                    ? getIframeText(fullMode ? EmbedViewMode.FULL : EmbedViewMode.PRESENTATION)
                                    : ''
                            }
                        />
                        <TippyTooltip
                            tooltipText={t('embedModal.tooltip_codeCopied')}
                            visible={showTooltip && tooltipType === 'presentation'}
                            placement={'left'}
                            offset={[0, 6]}
                            touch={true}
                        >
                            <button
                                className='embed-form-btn btn-ghost-blue'
                                type='button'
                                disabled={!isShared}
                                onClick={() => {
                                    handleCopyMessage(
                                        getIframeText(fullMode ? EmbedViewMode.FULL : EmbedViewMode.PRESENTATION),
                                        'presentation'
                                    );
                                }}
                            >
                                {t('embedModal.copy')}
                            </button>
                        </TippyTooltip>
                    </form>
                </div>
            </ModalBody>
        </Modal>
    );
}

function isValidDimension(dimension: string): boolean {
    const intRegex = /^\d+$/;
    return intRegex.test(dimension) && Number(dimension) > 0;
}
