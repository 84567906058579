import { useContext, useState } from 'react';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { Select, SelectProps } from 'antd';
import classNames from 'classnames';

type Props = {
    label: string;
    value: string;
    options: SelectProps['options'];
    onChange(newValue: string): void;
    disabled?: boolean;
    containerRef?: HTMLDivElement;
};

export default function SelectProperty({ label, value, options, onChange, disabled, containerRef }: Props) {
    const { isInEmbedView } = useContext(ProjectViewAccessContext);
    const [hasHover, setHasHover] = useState(false);

    return (
        <div className='property'>
            <div className='prop-wrapper read-only'>
                <div className='prop-label-wrapper'>
                    <div className='prop-label prop-text'>{label}</div>
                </div>
                <div className='prop-value-wrapper'>
                    <Select
                        disabled={disabled}
                        value={value}
                        getPopupContainer={() => (containerRef ? containerRef : document.body)}
                        onChange={onChange}
                        variant={hasHover ? 'outlined' : 'borderless'}
                        suffixIcon={hasHover ? undefined : null}
                        onMouseEnter={e => {
                            setHasHover(true);
                        }}
                        onMouseLeave={e => {
                            setHasHover(false);
                        }}
                        onClick={e => {
                            e.stopPropagation();
                        }}
                        className={classNames('ant-select-small', { 'theme-dark': isInEmbedView })}
                        popupClassName={classNames({ 'theme-dark': isInEmbedView })}
                        options={options}
                    />
                </div>
            </div>
            <div className='prop-action' />
        </div>
    );
}
