import { Tuple } from '../sharedConstants';
import { PointOfView } from '../store/helpers/interfaces';
import { ExtendedCamera } from '../store/slices/cameras';
import * as Cesium from 'cesium';

export function getPhotoPov(camera: ExtendedCamera): PointOfView<Cesium.Cartesian3> {
    const transform = Cesium.Matrix4.fromArray(camera.transform.flat());
    const matrix3 = Cesium.Matrix4.getMatrix3(transform, new Cesium.Matrix3());
    const rotation = Cesium.Matrix3.getRotation(matrix3, new Cesium.Matrix3());
    const position = Cesium.Matrix4.getTranslation(transform, new Cesium.Cartesian3());
    const direction = Cesium.Matrix3.multiplyByVector(rotation, Cesium.Cartesian3.UNIT_Z, new Cesium.Cartesian3());
    const up = Cesium.Matrix3.multiplyByVector(
        rotation,
        Cesium.Cartesian3.multiplyByScalar(Cesium.Cartesian3.UNIT_Y, -1, new Cesium.Cartesian3()),
        new Cesium.Cartesian3()
    );

    return { destination: position, orientation: { direction, up } };
}

export function getPhotoPovTuple(camera: ExtendedCamera): PointOfView<number> {
    const { destination, orientation } = getPhotoPov(camera);

    return {
        destination: cartesian3ToTuple(destination),
        orientation: { direction: cartesian3ToTuple(orientation.direction), up: cartesian3ToTuple(orientation.up) }
    };

    function cartesian3ToTuple(cartesian: Cesium.Cartesian3): Tuple<number, 3> {
        const { x, y, z } = cartesian;
        return [x, y, z];
    }
}
