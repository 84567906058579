import { PointerSensor as LibPointerSensor } from '@dnd-kit/core';
import type { PointerEvent } from 'react';

// https://github.com/clauderic/dnd-kit/issues/477#issuecomment-985194908

export class PointerSensor extends LibPointerSensor {
    static activators = [
        {
            eventName: 'onPointerDown' as const,
            handler: ({ nativeEvent: event }: PointerEvent) => {
                return shouldHandleEvent(event.target as HTMLElement);
            }
        }
    ];
}

function shouldHandleEvent(element: HTMLElement | null) {
    let cur = element;

    while (cur) {
        if (cur.dataset && cur.dataset.noDnd) {
            return false;
        }
        cur = cur.parentElement;
    }

    return true;
}
