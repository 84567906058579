import { TreeNode } from './StructureTree';
import { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { createContext } from 'react';
import { ProjectStructureObjectTypes } from '../../../store/helpers/interfaces';

export type ProjectionContextValue = {
    depth: number | null;
    parentId: string | null;
    isValid: boolean;
};
export const ProjectionContext = createContext<ProjectionContextValue>({
    depth: null,
    parentId: null,
    isValid: true
});

export const DRAG_SPACING_PX = 64; // padding-left when dropping item, depending on projected depth

function getDragDepth(offset: number, indentationWidth: number) {
    return Math.round(offset / indentationWidth);
}

export function getProjection(
    items: TreeNode[],
    activeId: UniqueIdentifier,
    overId: UniqueIdentifier,
    dragOffset: number,
    indentationWidth: number
): Omit<ProjectionContextValue, 'isValid'> {
    const overItemIndex = items.findIndex(({ id }) => id === overId);
    const activeItemIndex = items.findIndex(({ id }) => id === activeId);
    const activeItem = items[activeItemIndex];
    const parentItem = items.find(i => i.id === activeItem.parentId);
    const newItems = arrayMove(items, activeItemIndex, overItemIndex);
    const previousItem = newItems[overItemIndex - 1];
    const nextItem = newItems[overItemIndex + 1];
    const dragDepth = getDragDepth(dragOffset, indentationWidth);
    const projectedDepth = activeItem.nestingLevel + dragDepth;
    const maxDepth = getMaxDepth({ previousItem, activeItem, parentItem });
    const minDepth = getMinDepth({ nextItem, activeItem, parentItem });
    let depth = projectedDepth;
    if (projectedDepth >= maxDepth) {
        depth = maxDepth;
    } else if (projectedDepth < minDepth) {
        depth = minDepth;
    }

    const projectedParentId = getParentId(depth);
    depth = getCorrectedDepth(depth, projectedParentId);

    return { depth, parentId: projectedParentId };

    function getCorrectedDepth(projectedDepth: number, projectedParentId: UniqueIdentifier | null): number {
        if (activeItem?.type === ProjectStructureObjectTypes.GROUP) return 0;
        if ([ProjectStructureObjectTypes.DATASET, ProjectStructureObjectTypes.LAYER].includes(activeItem?.type)) {
            if (!projectedParentId) return 0;
            if (projectedDepth > 1) return 1;
        }

        return projectedDepth;
    }

    function getParentId(depth: number) {
        if (depth === 0 || !previousItem) {
            return null;
        }

        if (depth === previousItem.nestingLevel) {
            return getCorrectedParentId(previousItem.parentId);
        }

        if (depth > previousItem.nestingLevel) {
            return getCorrectedParentId(previousItem.id);
        }

        const newParent = newItems
            .slice(0, overItemIndex)
            .reverse()
            .find(item => item.nestingLevel === depth)?.parentId;

        return getCorrectedParentId(newParent) ?? null;
    }

    function getCorrectedParentId(parentId: string | undefined | null) {
        const parentItem = items.find(i => i.id === parentId);
        const overItem = items.find(i => i.id === overId);

        if (overItem?.type === ProjectStructureObjectTypes.GEOMETRY) {
            return items.find(i => i.id === overItem.parentId)?.parentId ?? null;
        }

        // If moving inside another layer or dataset, set new parent to the parent of the layer or dataset
        if (
            parentItem?.type === ProjectStructureObjectTypes.DATASET ||
            parentItem?.type === ProjectStructureObjectTypes.LAYER
        ) {
            return items.find(i => i.id === parentItem?.id)?.parentId ?? null;
        }
        return parentId ?? null;
    }
}

function getMaxDepth({
    previousItem,
    activeItem,
    parentItem
}: {
    previousItem: TreeNode;
    activeItem: TreeNode;
    parentItem: TreeNode | undefined;
}) {
    if (activeItem?.type === ProjectStructureObjectTypes.GROUP) return 0;
    if (
        activeItem?.type === ProjectStructureObjectTypes.DATASET ||
        activeItem?.type === ProjectStructureObjectTypes.LAYER
    )
        return 1;

    if (activeItem.type === ProjectStructureObjectTypes.GEOMETRY && parentItem) {
        return parentItem.nestingLevel + 1;
    }

    if (previousItem) {
        return previousItem.nestingLevel + 1;
    }

    return 0;
}

function getMinDepth({
    nextItem,
    activeItem,
    parentItem
}: {
    nextItem: TreeNode;
    activeItem: TreeNode;
    parentItem: TreeNode | undefined;
}) {
    if (activeItem.type === ProjectStructureObjectTypes.GEOMETRY && parentItem) {
        return parentItem.nestingLevel + 1;
    }

    if (nextItem) {
        return nextItem.nestingLevel;
    }

    return 0;
}
