import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import ProjectsPlaceholder from '../../components/Projects/ProjectsPlaceholder';
import ProjectsGrid from '../../components/projects-grid/ProjectsGrid';
import { Routes } from '../../sharedConstants';
import { AppDispatch, useSelector } from '../../store';
import { createLoadingSelector } from '../../store/selectors/createLoadingSelector';
import { firstProgressTick } from '../../store/slices/progress';
import { resetProjects, selectProjects } from '../../store/slices/projectsPage';
import { getSite, getSiteProjectInfo, resetSiteState } from '../../store/slices/site';

export interface SiteRouteParams {
    id?: string;
    hash?: string;
}

const loadingSelector = createLoadingSelector([firstProgressTick.typePrefix]);

export default function Site() {
    const dispatch: AppDispatch = useDispatch();
    const history = useHistory();
    const isOnline = useSelector(state => state.globalFlags.isOnline);
    const { id: siteId } = useParams<SiteRouteParams>();
    const isSiteRoute = !!useRouteMatch({ exact: true, path: Routes.SITE });
    const site = useSelector(state => state.site.siteInfo);
    const page = useSelector(state => state.projectsPage);
    const isLoading = useSelector(state => loadingSelector(state) || state.projectsPage.isLoading);
    const projects = useSelector(selectProjects);
    const notFoundBySearch = !projects.length && !isLoading && !!page.searchText.length;
    const isPlaceholderVisible = !isOnline || notFoundBySearch;

    useEffect(() => {
        fetchSite();

        async function fetchSite() {
            if (isSiteRoute && siteId && site.uid !== siteId) {
                dispatch(resetSiteState());
                const result = await dispatch(getSite({ projectId: siteId }));
                if (
                    getSite.rejected.match(result) &&
                    (result.payload?.statusCode === 404 || result.payload?.statusCode === 410)
                ) {
                    history.replace(generatePath(Routes.NOT_FOUND));
                }
                dispatch(getSiteProjectInfo({ projectId: siteId }));
            }
        }

        return () => {
            dispatch(resetProjects());
        };
    }, [dispatch, siteId, site.uid, isSiteRoute, history]);

    return (
        <>
            {!isOnline && <ProjectsPlaceholder type={'offline'} />}

            {notFoundBySearch && isOnline && <ProjectsPlaceholder type={'noprojectsfound'} />}

            {!isPlaceholderVisible && <ProjectsGrid />}
        </>
    );
}
