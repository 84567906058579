import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export default function useOutsideRightClick(
    ref: MutableRefObject<HTMLElement | null>,
    handler: (e: MouseEvent) => any,
    when = true
): void {
    const savedHandler = useRef(handler);

    const memoizedCallback = useCallback(
        (e: MouseEvent) => {
            if (ref && ref.current && !ref.current.contains(e.target as Element) && e.button === 2) {
                savedHandler.current(e);
            }
        },
        [ref]
    );

    useEffect(() => {
        savedHandler.current = handler;
    });

    useEffect(() => {
        if (when) {
            window.addEventListener('contextmenu', memoizedCallback);
            return () => {
                window.removeEventListener('contextmenu', memoizedCallback);
            };
        }
    }, [ref, handler, when, memoizedCallback]);
}
