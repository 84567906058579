import { GeometryTypes } from '../../../sharedConstants';

export default function splitEntityId(id: string) {
    return id.split('#') as [
        type: GeometryTypes,
        shapeId: string,
        index: string | undefined,
        midpoint: 'midpoint' | undefined
    ];
}
