import { GetThunkAPI } from '@reduxjs/toolkit';
import * as Cesium from 'cesium';
import type { ApplicationState } from '../..';
import { globalTerrainOption } from '../../../components/ProjectView/elevation-profile/ElevationProfileTool';
import GeometryMeasures from '../../../components/ProjectView/geometry-properties/measures/GeometryMeasures';
import PolylineMeasures from '../../../components/ProjectView/geometry-properties/measures/PolylineMeasures';
import getFilename from '../../../lib/getFilename';
import HeightsSampler from '../../../lib/HeightsSampler';
import { GeometryTypes, distinctColorsList } from '../../../sharedConstants';
import { ExtendedDatasetInfo } from '../../slices/datasetfilesUpload';
import { ElevationProfileCalculationInfo } from '../../slices/elevationProfiles';
import { TemporaryGeometry } from '../interfaces';

export interface SurfaceAltitudes {
    name: string;
    color: string;
    altitudes: number[];
}

export interface ElevationProfileResult {
    points: Cesium.Cartesian3[]; // Точки вдоль линии на расстоянии step друг от друга в ECEF
    distances: number[]; // Расстояние от начала полилинии до каждой точки
    altitudes: SurfaceAltitudes[];
}

type NeededThunkApi = Pick<GetThunkAPI<{ state: ApplicationState }>, 'getState' | 'signal'>;

export default class ElevationProfile {
    private readonly polylineMeasures: PolylineMeasures;
    constructor(
        private readonly polyline: TemporaryGeometry<GeometryTypes.POLYLINE>,
        private readonly calculationInfo: ElevationProfileCalculationInfo,
        private readonly thunkApi: NeededThunkApi,
        private readonly globalTerrainProvider?: Cesium.TerrainProvider,
        private readonly access?: string,
        private readonly embed?: string
    ) {
        this.polylineMeasures = new PolylineMeasures(polyline.content.geometry.coordinates);
    }

    async build(): Promise<ElevationProfileResult> {
        const { enuToEcef, enuVertices } = this.getEnuVertices();

        const result: ElevationProfileResult = { points: [], distances: [], altitudes: [] };

        let distanceFromStart = 0;
        const profilePointsEnu: Cesium.Cartesian3[] = [enuVertices[0]];
        const profilePointsDistances: number[] = [distanceFromStart];

        for (let i = 0; i < enuVertices.length - 1; i++) {
            let currentShift = this.calculationInfo.step;
            const segmentStart = enuVertices[i];
            const segmentEnd = enuVertices[i + 1];

            const { segmentLength, segmentVectorNormalized } = this.getSegmentMeasures(segmentStart, segmentEnd);

            // Generate profile points on given segment until coming out of given segment
            while (currentShift <= segmentLength) {
                distanceFromStart += this.calculationInfo.step;
                // Getting vector shifted by current shift value
                const segmentVectorShifted = Cesium.Cartesian2.multiplyByScalar(
                    segmentVectorNormalized,
                    currentShift,
                    new Cesium.Cartesian2()
                );
                // Getting point on vector shifted from start point on current shift value
                const profilePoint2D = Cesium.Cartesian2.add(
                    new Cesium.Cartesian2(segmentStart.x, segmentStart.y),
                    segmentVectorShifted,
                    new Cesium.Cartesian2()
                );
                // Pushing calculated profile point as 3D point with 0 height above ENU XY plane.
                profilePointsEnu.push(new Cesium.Cartesian3(profilePoint2D.x, profilePoint2D.y, 0.0));
                profilePointsDistances.push(distanceFromStart);

                /*
                    In case on the next while loop turn we will go out of segment, but there will still be some space on segment
                    we need to add this distance to the distanceFromStart in order so the first profile point on the next segment
                    will have the proper distanceFromStart value
                */
                if (currentShift + this.calculationInfo.step > segmentLength) {
                    distanceFromStart += segmentLength - currentShift;
                    if (i + 1 < enuVertices.length) {
                        profilePointsEnu.push(enuVertices[i + 1]);
                        profilePointsDistances.push(distanceFromStart);
                    }
                }
                currentShift += this.calculationInfo.step;
            }
        }

        const ecefProfilePoints = GeometryMeasures.applyTransformationMatrix(profilePointsEnu, enuToEcef);
        result.points.push(...ecefProfilePoints);
        result.distances.push(...profilePointsDistances.map(d => parseFloat(d.toFixed(3))));

        const projectId = this.thunkApi.getState().project.projectInfo.id!;
        const surfaces = this.thunkApi
            .getState()
            .datasets.datasets.filter(d => this.calculationInfo.surfaceIds.includes(d.datasetUid!));

        for (const id of this.calculationInfo.surfaceIds) {
            let terrainProvider: Cesium.TerrainProvider;
            let surface: ExtendedDatasetInfo;

            if (id === globalTerrainOption().id) {
                terrainProvider = this.globalTerrainProvider!;
                surface = { name: globalTerrainOption().name };
            } else {
                surface = surfaces.find(s => s.datasetUid === id)!;
                if (!surface) continue; // Skip if surface is deleted from project
                let url = `/api/projects/${projectId}/datasets/${surface.datasetUid}/tilesets/${surface.visualData?.dataUid}`;
                if (this.access) url = url.concat(`?access=${this.access}`);
                if (this.embed) url = url.concat(`?embed=${this.embed}`);
                terrainProvider = await Cesium.CesiumTerrainProvider.fromUrl(url, { requestVertexNormals: true });
            }

            const heightsSampler = new HeightsSampler(
                terrainProvider as unknown as Cesium.TerrainProvider,
                null as unknown as Cesium.Scene
            );
            const pointsWithHeights: Cesium.Cartographic[] = await heightsSampler.doSampling(ecefProfilePoints);
            if (pointsWithHeights.some(point => !point.height)) throw new Error('Unable to sample heights');
            result.altitudes.push({
                name: getFilename(surface.name!),
                altitudes: pointsWithHeights.map(p => p.height),
                color: ''
            });
        }
        result.altitudes.forEach((alt, index) => {
            alt.color = distinctColorsList[index];
        });
        return result;
    }

    private getEnuVertices() {
        const centroid = this.polylineMeasures.centroid();
        const enuToEcef = Cesium.Transforms.eastNorthUpToFixedFrame(centroid);
        const ecefToEnu = Cesium.Matrix4.inverse(enuToEcef, new Cesium.Matrix4());
        const enuVertices = GeometryMeasures.applyTransformationMatrix(this.polylineMeasures.vertices, ecefToEnu);
        return { enuToEcef, enuVertices };
    }

    private getSegmentMeasures(segmentStart: Cesium.Cartesian3, segmentEnd: Cesium.Cartesian3) {
        // Calculating segment length on 2D ENU XY plane, ignoring Z coordinate
        const segmentLength = Cesium.Cartesian2.distance(
            new Cesium.Cartesian2(segmentStart.x, segmentStart.y),
            new Cesium.Cartesian2(segmentEnd.x, segmentEnd.y)
        );

        // Getting vector from segment start point to segment end point
        const segmentVector = Cesium.Cartesian2.subtract(
            new Cesium.Cartesian2(segmentEnd.x, segmentEnd.y),
            new Cesium.Cartesian2(segmentStart.x, segmentStart.y),
            new Cesium.Cartesian2()
        );

        // Normalizing vector
        const segmentVectorNormalized = Cesium.Cartesian2.normalize(segmentVector, new Cesium.Cartesian2());

        return { segmentLength, segmentVector, segmentVectorNormalized };
    }
}
