import { useTranslation, Trans } from 'react-i18next';
import Modal, { ModalProps } from '../../modal/Modal';
import ModalActions from '../../modal/ModalActions';
import ModalBody from '../../modal/ModalBody';
import ModalHead from '../../modal/ModalHead';

type Props = ModalProps & {
    datasetName: string;
    linkedProjectName: string;
    onUnlink(): void;
};

export default function UnlinkOwnDatasetModal({ isOpen, setIsOpen, datasetName, linkedProjectName, onUnlink }: Props) {
    const { t } = useTranslation(['modals']);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <ModalHead setIsOpen={setIsOpen}>{t('modals:unlinkOwnDatasetModal.head')}</ModalHead>
            <ModalBody>
                <Trans
                    ns='modals'
                    i18nKey={'unlinkOwnDatasetModal.body'}
                    components={{ p: <p />, s: <strong className='overflow' /> }}
                    values={{ name: datasetName, parentName: linkedProjectName }}
                />
                <ModalActions>
                    <button
                        className='btn-cancel'
                        type='button'
                        onClick={e => {
                            setIsOpen(false);
                        }}
                    >
                        {t('modals:unlinkOwnDatasetModal.actionCancel')}
                    </button>

                    <button
                        className='btn-alert'
                        type='button'
                        onClick={() => {
                            onUnlink();
                            setIsOpen(false);
                        }}
                        data-testid={'actionButton'}
                    >
                        {t('modals:unlinkOwnDatasetModal.actionUnlink')}
                    </button>
                </ModalActions>
            </ModalBody>
        </Modal>
    );
}
