import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import AppDataAttribution from '../ArtifactViewer/AppDataAttribution';
import { ApplicationState, useSelector } from '../../../store';
import { Units, WEBGL_SUPPORTED } from '../../../sharedConstants';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import TippyTooltipTimed from '../../Elements/tippy-tooltip/TippyTooltipTimed';
import { createLoadingSelector } from '../../../store/selectors/createLoadingSelector';
import { getProjectById } from '../../../store/slices/project';
import { getAccess, getAccessInfo } from '../../../store/slices/sharing';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SvgQuestion } from '../../../svg/general/question.svg';

const loadingSelector = createLoadingSelector([
    getProjectById.typePrefix,
    getAccess.typePrefix,
    getAccessInfo.typePrefix
]);

function BottomBar() {
    const { t } = useTranslation('projectView');
    const showCoordinates = useMediaQuery({ minWidth: 876 });
    const currentCrs = useSelector(state => state.coordinateSystems.currentCrs);
    const units = useSelector(state => state.coordinateSystems.units);
    const loading = useSelector(state => loadingSelector(state));
    const isCesiumLoaded = useSelector(state => state.projectView.isCesiumLoaded);
    const isLoading = loading || (WEBGL_SUPPORTED && !isCesiumLoaded);

    const [coordSystemShortNaming, setCoordSystemShortNaming] = useState('');
    const [coordSystemTooltipNaming, setCoordSystemTooltipNaming] = useState('');

    useEffect(() => {
        if (isLoading) return;
        currentCrs?.epsgCode
            ? setCoordSystemShortNaming('EPSG::'.concat(currentCrs.epsgCode.toString()))
            : setCoordSystemShortNaming(t('bottomBar.customCrsName'));
    }, [currentCrs?.epsgCode, currentCrs?.name, isLoading, t]);

    useEffect(() => {
        if (isLoading) return;
        setCoordSystemTooltipNaming(currentCrs?.name);
    }, [currentCrs?.name, isLoading]);

    return (
        <div id='mapBottomBar'>
            {showCoordinates && (
                <div className={classNames('coordinates', { 'us-ft': units === Units.US_SURVEY_FOOT })}>
                    <TippyTooltip tooltipText={coordSystemTooltipNaming}>
                        <div className='coord-system'>{coordSystemShortNaming}</div>
                    </TippyTooltip>
                    <div className='line' />

                    <div className='coord'>
                        <span id='coordAxis_1'>-</span>
                    </div>

                    <div className='line' />

                    <div className='coord'>
                        <span id='coordAxis_2'>-</span>
                    </div>

                    <div className='line' />

                    <div className='alt'>
                        <span id='altAxis'>-</span>
                        {currentCrs.invalidateHeight && !isLoading && (
                            <TippyTooltipTimed
                                tooltipText={t('bottomBar.altTooltip')}
                                disableHover={true}
                                allowFullWidth={true}
                            >
                                <div className='alt-tooltip'>
                                    <SvgQuestion className='alt-tooltip-icon' />
                                </div>
                            </TippyTooltipTimed>
                        )}
                    </div>

                    <div className='line' />

                    {/* Custom legend, may come in handy */}
                    {/*<div className='scale'>*/}
                    {/*    {areCoordinatesEmpty ? (*/}
                    {/*        <span className='spacer'>-</span>*/}
                    {/*    ) : (*/}
                    {/*        <div className='scale-line' style={{ width: scale_line_width }}>*/}
                    {/*            50 m*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </div>
            )}

            <div className='map-copyright'>
                <AppDataAttribution />
            </div>
        </div>
    );
}

export default BottomBar;
