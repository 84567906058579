import produce from 'immer';
import { EMPTY_ARRAY } from '../sharedConstants';
import {
    GeoJson,
    GeoJsonFeatureCollection,
    isIssuePointFeature,
    isViewpointFeature
} from '../store/helpers/interfaces';
import { ExtendedCamera } from '../store/slices/cameras';

export default function exportAsJson(
    jsonData: GeoJson | GeoJsonFeatureCollection,
    filename: string,
    testOnlyLinkElement?: HTMLAnchorElement
): void {
    const dataStr = JSON.stringify(jsonData);
    const dataUri = 'data:application/geojson;charset=utf-8,' + encodeURIComponent(dataStr);
    const linkElement = testOnlyLinkElement || document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', filename + '.geojson');
    linkElement.click();
}

/**
 * Removes or replaces geoJson properties we don't want for user to see
 */
export function preProcessGeoJsonProperties(geoJson: GeoJson): GeoJson {
    return produce(geoJson, draft => {
        if (isIssuePointFeature(geoJson)) {
            delete draft.properties['ac_photoUid'];
            delete draft.properties['saved'];
            delete draft.properties['ac_image_uid'];
            delete draft.properties['ac_preview_uid'];
        }

        if (isViewpointFeature(geoJson)) {
            // Remove now deprecated properties of slides
            delete draft.properties['index'];
            delete draft.properties['saved'];
            delete draft.properties['thumbnail'];

            delete draft.properties.ac_duration;

            if (draft.properties.ac_point_of_view) delete draft.properties['pointOfView'];

            if (draft.properties.ac_description) delete draft.properties['description'];
        }
    });
}
