import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ProjectsPlaceholder from '../components/Projects/ProjectsPlaceholder';
import ProjectsGrid from '../components/projects-grid/ProjectsGrid';
import { AppDispatch, useSelector } from '../store';
import { resetProjects, setIsLoading } from '../store/slices/projectsPage';

export default function SharedProjects() {
    const dispatch: AppDispatch = useDispatch();
    const page = useSelector(state => state.projectsPage);
    const isLoading = useSelector(state => state.projectsPage.isLoading);
    const isOnline = useSelector(state => state.globalFlags.isOnline);
    const noProjects = !page.totalElements && !isLoading && !page.searchText;
    const notFoundBySearch = !page.numberOfElements && !isLoading && page.searchText;
    const isPlaceholderVisible = !isOnline || noProjects || notFoundBySearch;

    useEffect(() => {
        return () => {
            dispatch(resetProjects());
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(setIsLoading(true));
    }, [dispatch]);

    return (
        <>
            {!isOnline && <ProjectsPlaceholder type={'offline'} />}

            {notFoundBySearch && isOnline && <ProjectsPlaceholder type={'noprojectsfound'} />}

            {noProjects && isOnline && <ProjectsPlaceholder type={'shared-empty'} />}

            {!isPlaceholderVisible && <ProjectsGrid />}
        </>
    );
}
