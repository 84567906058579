/* tslint:disable */
/* eslint-disable */
/**
 * project-structure-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { StructureInfo } from '../model';
/**
 * ProjectStructureApi - axios parameter creator
 * @export
 */
export const ProjectStructureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete the structure
         * @param {string} projectUid UUID of project
         * @param {string} structureUid Uid of structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStructure: async (projectUid: string, structureUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('deleteStructure', 'projectUid', projectUid)
            // verify required parameter 'structureUid' is not null or undefined
            assertParamExists('deleteStructure', 'structureUid', structureUid)
            const localVarPath = `/projects/{projectUid}/structures/{structureUid}`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)))
                .replace(`{${"structureUid"}}`, encodeURIComponent(String(structureUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return a list of project structures
         * @param {string} projectUid UUID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructures: async (projectUid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('getStructures', 'projectUid', projectUid)
            const localVarPath = `/projects/{projectUid}/structures`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create or update a structure
         * @param {string} projectUid UUID of project
         * @param {StructureInfo} structureInfo Structure that needs to be added or altered
         * @param {string} [after] Uid of structure after which this structure should be inserted. If absent - insert in the beggining
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStructure: async (projectUid: string, structureInfo: StructureInfo, after?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('putStructure', 'projectUid', projectUid)
            // verify required parameter 'structureInfo' is not null or undefined
            assertParamExists('putStructure', 'structureInfo', structureInfo)
            const localVarPath = `/projects/{projectUid}/structure`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(structureInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Put a list of structures. If there are other structures with same accountUid and projectUid they will be deleted
         * @param {string} projectUid UUID of project
         * @param {Array<StructureInfo>} structureInfo List of structures that need to be added/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStructures: async (projectUid: string, structureInfo: Array<StructureInfo>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectUid' is not null or undefined
            assertParamExists('putStructures', 'projectUid', projectUid)
            // verify required parameter 'structureInfo' is not null or undefined
            assertParamExists('putStructures', 'structureInfo', structureInfo)
            const localVarPath = `/projects/{projectUid}/structures`
                .replace(`{${"projectUid"}}`, encodeURIComponent(String(projectUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(structureInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectStructureApi - functional programming interface
 * @export
 */
export const ProjectStructureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectStructureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete the structure
         * @param {string} projectUid UUID of project
         * @param {string} structureUid Uid of structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStructure(projectUid: string, structureUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStructure(projectUid, structureUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return a list of project structures
         * @param {string} projectUid UUID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStructures(projectUid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StructureInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStructures(projectUid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create or update a structure
         * @param {string} projectUid UUID of project
         * @param {StructureInfo} structureInfo Structure that needs to be added or altered
         * @param {string} [after] Uid of structure after which this structure should be inserted. If absent - insert in the beggining
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putStructure(projectUid: string, structureInfo: StructureInfo, after?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putStructure(projectUid, structureInfo, after, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Put a list of structures. If there are other structures with same accountUid and projectUid they will be deleted
         * @param {string} projectUid UUID of project
         * @param {Array<StructureInfo>} structureInfo List of structures that need to be added/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putStructures(projectUid: string, structureInfo: Array<StructureInfo>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putStructures(projectUid, structureInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectStructureApi - factory interface
 * @export
 */
export const ProjectStructureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectStructureApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete the structure
         * @param {string} projectUid UUID of project
         * @param {string} structureUid Uid of structure
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStructure(projectUid: string, structureUid: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteStructure(projectUid, structureUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return a list of project structures
         * @param {string} projectUid UUID of project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStructures(projectUid: string, options?: any): AxiosPromise<Array<StructureInfo>> {
            return localVarFp.getStructures(projectUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create or update a structure
         * @param {string} projectUid UUID of project
         * @param {StructureInfo} structureInfo Structure that needs to be added or altered
         * @param {string} [after] Uid of structure after which this structure should be inserted. If absent - insert in the beggining
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStructure(projectUid: string, structureInfo: StructureInfo, after?: string, options?: any): AxiosPromise<void> {
            return localVarFp.putStructure(projectUid, structureInfo, after, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Put a list of structures. If there are other structures with same accountUid and projectUid they will be deleted
         * @param {string} projectUid UUID of project
         * @param {Array<StructureInfo>} structureInfo List of structures that need to be added/updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putStructures(projectUid: string, structureInfo: Array<StructureInfo>, options?: any): AxiosPromise<void> {
            return localVarFp.putStructures(projectUid, structureInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectStructureApi - object-oriented interface
 * @export
 * @class ProjectStructureApi
 * @extends {BaseAPI}
 */
export class ProjectStructureApi extends BaseAPI {
    /**
     * 
     * @summary Delete the structure
     * @param {string} projectUid UUID of project
     * @param {string} structureUid Uid of structure
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectStructureApi
     */
    public deleteStructure(projectUid: string, structureUid: string, options?: AxiosRequestConfig) {
        return ProjectStructureApiFp(this.configuration).deleteStructure(projectUid, structureUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return a list of project structures
     * @param {string} projectUid UUID of project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectStructureApi
     */
    public getStructures(projectUid: string, options?: AxiosRequestConfig) {
        return ProjectStructureApiFp(this.configuration).getStructures(projectUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create or update a structure
     * @param {string} projectUid UUID of project
     * @param {StructureInfo} structureInfo Structure that needs to be added or altered
     * @param {string} [after] Uid of structure after which this structure should be inserted. If absent - insert in the beggining
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectStructureApi
     */
    public putStructure(projectUid: string, structureInfo: StructureInfo, after?: string, options?: AxiosRequestConfig) {
        return ProjectStructureApiFp(this.configuration).putStructure(projectUid, structureInfo, after, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Put a list of structures. If there are other structures with same accountUid and projectUid they will be deleted
     * @param {string} projectUid UUID of project
     * @param {Array<StructureInfo>} structureInfo List of structures that need to be added/updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectStructureApi
     */
    public putStructures(projectUid: string, structureInfo: Array<StructureInfo>, options?: AxiosRequestConfig) {
        return ProjectStructureApiFp(this.configuration).putStructures(projectUid, structureInfo, options).then((request) => request(this.axios, this.basePath));
    }
}
