import { GeoJson, isLineStringFeature, isPointFeature } from '../store/helpers/interfaces';
import PolylineMeasures from '../components/ProjectView/geometry-properties/measures/PolylineMeasures';
import PolygonMeasures from '../components/ProjectView/geometry-properties/measures/PolygonMeasures';

export default function getGeometryMeasures(geoJson: GeoJson): Record<string, number> {
    if (isPointFeature(geoJson))
        return {
            ac_elevation_meters: parseFloat(geoJson.geometry.coordinates[2].toFixed(3))
        };
    if (isLineStringFeature(geoJson)) {
        const measures = new PolylineMeasures(geoJson.geometry.coordinates);
        return measures.valuesToObject();
    }

    const coordinates = geoJson.geometry.coordinates as number[][][];
    const measures = new PolygonMeasures(coordinates);
    return measures.valuesToObject();
}
