import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TippyDropdownItemWithIcon from '../../tippy-dropdown/TippyDropdownItemWithIcon';
import UnpublishProjectModal from '../modals/UnpublishProjectModal';
import { ProjectInfo } from '../../../../generated/cloud-frontend-api/model';
import { ReactComponent as SvgUnpublish } from '../../../../svg/dropdown_actions/unpublish.svg';

type Props = {
    project: ProjectInfo;
};

export default function UnpublishProjectAction({ project }: Props) {
    const { t } = useTranslation(['projects']);
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <TippyDropdownItemWithIcon
                icon={<SvgUnpublish />}
                text={t('projects:projectActions.unpublish')}
                onClick={() => {
                    setShowModal(true);
                }}
            />

            {showModal && <UnpublishProjectModal setIsOpen={setShowModal} isOpen={showModal} project={project} />}
        </>
    );
}
