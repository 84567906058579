import classNames from 'classnames';
import { memo, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useOutsideClickRef } from 'rooks';
import ProjectViewAccessContext from '../../../contexts/ProjectViewAccessContext';
import { GeometryTypes, TerrainViewModes } from '../../../sharedConstants';
import { AppDispatch, useSelector } from '../../../store';
import { SurfaceAltitudes } from '../../../store/helpers/elevation-profile/ElevationProfile';
import { TemporaryGeometry } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { makeSelectElevationProfileById } from '../../../store/slices/elevationProfiles';
import { isGeometryPropertiesBlockExpanded } from '../../../store/slices/geometries';
import { makeSelectLayerByGeometryId, updateGeometryPropertyById } from '../../../store/slices/geometryLayers';
import { setElevationProfileExpanded } from '../../../store/slices/projectView';
import { ReactComponent as SvgExpand } from '../../../svg/tool_profile/expand.svg';
import { ReactComponent as SvgEdit } from '../../../svg/volume/edit.svg';
import TippyTooltip from '../../Elements/tippy-tooltip/TippyTooltip';
import ElevationProfileGraph from './ElevationProfileGraph';
import ElevationProfileGraphOverlay from './ElevationProfileGraphOverlay';
import ElevationProfileLegend from './ElevationProfileLegend';
import ElevationProfileTool from './ElevationProfileTool';
import exportAsCsv from './export';
import PropertiesBlockHead from '../geometry-properties/elements/PropertiesBlockHead';

type Props = {
    isLinked: boolean;
};

export default function ElevationProfile({ isLinked }: Props) {
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation('projectView');
    const isElevationProfileExpanded = useSelector(state => state.projectView.isElevationProfileExpanded);
    const currentlyDrawingShapeId = useSelector(state => state.projectView.currentlyDrawingShapeId);
    const polyline = useSelector(state =>
        selectedObjectInfoSelector(state)
    ) as TemporaryGeometry<GeometryTypes.POLYLINE>;
    const units = useSelector(state => state.coordinateSystems.units);
    const selectElevationProfileById = useMemo(makeSelectElevationProfileById, []);
    const elevationProfile = useSelector(state => selectElevationProfileById(state, polyline.id));
    const terrainViewMode = useSelector(state => state.projectView.terrainViewMode);
    const isCameraInspectionEnabled = useSelector(state => state.projectView.isCamerasInspectionEnabled);
    const projectInfo = useSelector(state => state.project.projectInfo);
    const selectLayerByGeometryId = useMemo(makeSelectLayerByGeometryId, []);
    const layer = useSelector(state => selectLayerByGeometryId(state, polyline.id));
    const { owned } = useContext(ProjectViewAccessContext);
    const isModelView = terrainViewMode === TerrainViewModes.MODEL;
    const isReady = elevationProfile?.status === 'fulfilled';
    const isPending = elevationProfile?.status === 'pending';
    const hasFailed = elevationProfile?.status === 'rejected';
    const isRestartable = elevationProfile?.status === 'aborted';
    const hasNotFinishedDrawing = currentlyDrawingShapeId === polyline.id;
    const surfaceAltitudes: SurfaceAltitudes[] = elevationProfile?.result.altitudes || [];

    const [showTool, setShowTool] = useState(false);
    const [toolRef] = useOutsideClickRef(() => {
        setShowTool(false);
    });

    const showOverlay = isPending || isRestartable || hasFailed;

    const overlayType = () => {
        if (isPending) return 'inProgress';
        if (isRestartable) return 'interrupted';
        if (hasFailed) return 'error';
    };

    const isVisible = (owned && !isLinked) || layer?.isTemporary;

    const isLegendHidden = elevationProfile?.status !== 'fulfilled';

    if (!isVisible) return null;

    const elevationProfileBlockExpanded = isGeometryPropertiesBlockExpanded(
        polyline.content,
        'ac_elevation_profile_block_expanded'
    );

    function setBlockExpansion(expanded: boolean) {
        dispatch(
            updateGeometryPropertyById({
                id: polyline.id,
                owned,
                propName: 'ac_elevation_profile_block_expanded',
                propValue: expanded
            })
        );
    }

    return (
        <div className='inspector-properties inspector-properties-relative' ref={toolRef}>
            <PropertiesBlockHead
                isBlockExpanded={elevationProfileBlockExpanded}
                title={t('elevationProfile.title')}
                onClick={e => {
                    setBlockExpansion(!elevationProfileBlockExpanded);
                }}
                className='inspector-tool-placeholder'
            >
                {elevationProfile ? (
                    <>
                        <TippyTooltip tooltipText={t('elevationProfile.controls.tooltipExport')}>
                            <div
                                className={classNames('properties-control', { disabled: !isReady })}
                                onClick={e => {
                                    exportAsCsv(
                                        elevationProfile.result,
                                        projectInfo.name!,
                                        layer?.name!,
                                        polyline.content.properties.ac_name,
                                        units
                                    );
                                }}
                            >
                                <i className='icon icon-export' data-testid={'exportControl'} />
                            </div>
                        </TippyTooltip>

                        <TippyTooltip tooltipText={t('elevationProfile.controls.tooltipExpand')}>
                            <div
                                className={classNames('properties-control', {
                                    disabled: isModelView || isCameraInspectionEnabled,
                                    active: isElevationProfileExpanded
                                })}
                                onClick={() => {
                                    dispatch(setElevationProfileExpanded(!isElevationProfileExpanded));
                                }}
                            >
                                <SvgExpand data-testid={'expandControl'} />
                            </div>
                        </TippyTooltip>

                        <TippyTooltip tooltipText={t('elevationProfile.controls.tooltipEdit')}>
                            <div
                                className={classNames('properties-control', { disabled: isModelView })}
                                onClick={() => {
                                    setShowTool(true);
                                    if (!elevationProfileBlockExpanded) setBlockExpansion(true);
                                }}
                            >
                                <SvgEdit data-testid={'editControl'} />
                            </div>
                        </TippyTooltip>
                    </>
                ) : (
                    <TippyTooltip tooltipText={t('elevationProfile.controls.tooltipAdd')}>
                        <div
                            className={classNames('properties-control', {
                                disabled: hasNotFinishedDrawing || isModelView
                            })}
                            onClick={() => {
                                setShowTool(true);
                                if (!elevationProfileBlockExpanded) setBlockExpansion(true);
                            }}
                        >
                            <i className='icon icon-add' data-testid={'iconAddElevationProfile'} />
                        </div>
                    </TippyTooltip>
                )}
            </PropertiesBlockHead>

            {elevationProfileBlockExpanded && elevationProfile && (
                <div className='inspector-profile-wrapper'>
                    <ElevationProfileLegend surfaceAltitudes={surfaceAltitudes} isLegendHidden={isLegendHidden} />
                    <div className='inspector-profile-placeholder' data-testid={'elevationProfileOverlay'}>
                        {isReady && <ElevationProfileGraph />}
                    </div>
                    {showOverlay && (
                        <ElevationProfileGraphOverlay elevationProfile={elevationProfile} type={overlayType()!} />
                    )}
                </div>
            )}

            {showTool && <ElevationProfileTool setToolVisibility={setShowTool} />}
        </div>
    );
}
