import { BlobReader, ZipWriter, BlobWriter } from '@zip.js/zip.js';
import i18n from '../../../i18n/config';
import { TemporaryGeometry } from '../../../store/helpers/interfaces';
import { Units } from '../../../sharedConstants';
import { getUnitsCubicFullName, getUnitsFullName, getUnitsSquareFullName } from '../../../lib/getUnitsFullName';
import { convertAreaUnit, convertLengthUnit, convertVolumeUnit } from '../../../lib/convertUnit';
import generateElevationProfileCSVData from '../../ProjectView/elevation-profile/generateElevationProfileCSVData';

export default function MeasuremenetsCSVGenerator(
    projectName: string,
    layerName: string,
    geometries: Array<TemporaryGeometry>,
    units: Units,
    demsList: Record<string, string>,
    profilesList: any[],
    profileGeometriesNames: string[]
) {
    const unitsName = getUnitsFullName(units);
    const squareUnitsName = getUnitsSquareFullName(units);
    const cubicUnitsName = getUnitsCubicFullName(units);

    let profilesDuplicateFilenames: Record<string, number> = {};

    const pointsCSVHeaders = [
        `"${i18n.t('generatedReports:measurementReport_csv.heading_name')}","${i18n.t(
            'generatedReports:measurementReport_csv.points.heading_latitude'
        )} (${i18n.t('generatedReports:measurementReport_csv.units.deg')})","${i18n.t(
            'generatedReports:measurementReport_csv.points.heading_longitude'
        )} (${i18n.t('generatedReports:measurementReport_csv.units.deg')})","${i18n.t(
            'generatedReports:measurementReport_csv.points.heading_altitude'
        )} (${unitsName})"`
    ];
    const linesCSVHeaders = [
        `"${i18n.t('generatedReports:measurementReport_csv.heading_name')}","${i18n.t(
            'generatedReports:measurementReport_csv.polylines.heading_length'
        )} (${unitsName})","${i18n.t(
            'generatedReports:measurementReport_csv.polylines.heading_horizontalLength'
        )} (${unitsName})","${i18n.t(
            'generatedReports:measurementReport_csv.polylines.heading_elevationDifference'
        )} (${unitsName})","${i18n.t('generatedReports:measurementReport_csv.polylines.heading_slope')} (${i18n.t(
            'generatedReports:measurementReport_csv.units.percents'
        )})"`
    ];
    const polygonsCSVHeaders = [
        `"${i18n.t('generatedReports:measurementReport_csv.heading_name')}","${i18n.t(
            'generatedReports:measurementReport_csv.polygons.heading_perimeter2d'
        )} (${unitsName})","${i18n.t(
            'generatedReports:measurementReport_csv.polygons.heading_perimeter3d'
        )} (${unitsName})","${i18n.t(
            'generatedReports:measurementReport_csv.polygons.heading_area2d'
        )} (${squareUnitsName})","${i18n.t(
            'generatedReports:measurementReport_csv.polygons.heading_area'
        )} (${squareUnitsName})"`
    ];
    const volumesCSVHeaders = [
        `"${i18n.t('generatedReports:measurementReport_csv.heading_name')}","${i18n.t(
            'generatedReports:measurementReport_csv.polygonsVolume.heading_surface'
        )}","${i18n.t(
            'generatedReports:measurementReport_csv.polygonsVolume.heading_volumeAbove'
        )} (${cubicUnitsName})","${i18n.t(
            'generatedReports:measurementReport_csv.polygonsVolume.heading_volumeBelow'
        )} (${cubicUnitsName})","${i18n.t(
            'generatedReports:measurementReport_csv.polygonsVolume.heading_volumeTotal'
        )} (${cubicUnitsName})"`
    ];

    let pointsCSV: Array<any> = [];
    let linesCSV: Array<any> = [];
    let polygonsCSV: Array<any> = [];
    let volumesCSV: Array<any> = [];

    geometries.forEach(g => {
        switch (g.content.geometry.type) {
            case 'Point':
                addPoint(g);
                break;
            case 'LineString':
                addLine(g);
                break;
            case 'Polygon':
                addPolygon(g);
                break;
        }
    });

    function addPoint(g: any) {
        pointsCSV.push(
            [
                replaceComma(g.content.properties.ac_name),
                g.content.geometry.coordinates[1].toFixed(6),
                g.content.geometry.coordinates[0].toFixed(6),
                convertLengthUnit(g.content.geometry.coordinates[2], Units.METRE, units).toFixed(3)
            ].join(',')
        );
    }

    function addLine(g: any) {
        linesCSV.push(
            [
                replaceComma(g.content.properties.ac_name),

                getNumericString(g.content.properties.ac_length_meters, 'length'),
                getNumericString(g.content.properties.ac_horizontal_length_meters, 'length'),
                getNumericString(g.content.properties.ac_elevation_difference_meters, 'length'),
                (g.content.properties.ac_slope_percents || 0).toFixed(3)
            ].join(',')
        );
    }

    function addPolygon(g: any) {
        polygonsCSV.push(
            [
                replaceComma(g.content.properties.ac_name),
                getNumericString(g.content.properties.ac_perimeter2d_meters, 'length'),
                getNumericString(g.content.properties.ac_perimeter3d_meters, 'length'),
                getNumericString(g.content.properties.ac_area2d_square_meters, 'area'),
                getNumericString(g.content.properties.ac_area_square_meters, 'area')
            ].join(',')
        );

        if (Number.isFinite(g.content.properties.ac_volume_total_cubic_meters)) {
            let surfaceName = '-';
            if (demsList) {
                const surfaceId = Object.keys(demsList).find(index => index === g.content.properties.ac_volume_surface);
                if (surfaceId) {
                    surfaceName = demsList[surfaceId];
                }
            }

            volumesCSV.push([
                replaceComma(g.content.properties.ac_name),
                surfaceName,
                getNumericString(g.content.properties.ac_volume_above_cubic_meters, 'volume'),
                getNumericString(g.content.properties.ac_volume_below_cubic_meters, 'volume'),
                getNumericString(g.content.properties.ac_volume_total_cubic_meters, 'volume')
            ]);
        }
    }

    function replaceComma(name: string) {
        return name.replaceAll(',', '.');
    }

    function getNumericString(fieldValue: any, type: 'volume' | 'area' | 'length') {
        let newValue;
        switch (type) {
            case 'volume':
                newValue = fieldValue ? convertVolumeUnit(fieldValue, Units.METRE, units) : 0;
                break;
            case 'area':
                newValue = fieldValue ? convertAreaUnit(fieldValue, Units.METRE, units) : 0;
                break;
            case 'length':
                newValue = fieldValue ? convertLengthUnit(fieldValue, Units.METRE, units) : 0;
                break;
            default:
                newValue = 0;
        }
        return newValue.toFixed(3);
    }

    const pointsData = [...pointsCSVHeaders, ...pointsCSV].join('\n');
    const pointsBlob = new Blob([pointsData], { type: 'text/csv' });
    const pointsFileName = i18n.t('generatedReports:measurementReport_csv.filenames.points', {
        projectName,
        layerName
    });

    const linesData = [...linesCSVHeaders, ...linesCSV].join('\n');
    const linesBlob = new Blob([linesData], { type: 'text/csv' });
    const linesFileName = i18n.t('generatedReports:measurementReport_csv.filenames.polylines', {
        projectName,
        layerName
    });

    const polygonsData = [...polygonsCSVHeaders, ...polygonsCSV].join('\n');
    const polygonsBlob = new Blob([polygonsData], { type: 'text/csv' });
    const polygonsFileName = i18n.t('generatedReports:measurementReport_csv.filenames.polygons', {
        projectName,
        layerName
    });

    const volumesData = [...volumesCSVHeaders, ...volumesCSV].join('\n');
    const volumesBlob = new Blob([volumesData], { type: 'text/csv' });
    const volumesFileName = i18n.t('generatedReports:measurementReport_csv.filenames.volumes', {
        projectName,
        layerName
    });

    const zipFileName = i18n.t('generatedReports:measurementReport_csv.filenames.zip', { projectName, layerName });

    getZipFileBlob().then(zipBlob => {
        const a = document.createElement('a');
        a.download = zipFileName;
        a.href = window.URL.createObjectURL(zipBlob);
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true
        });
        a.dispatchEvent(clickEvt);
        a.remove();
    });

    async function getZipFileBlob() {
        const zipWriter = new ZipWriter(new BlobWriter('application/zip'));
        await Promise.all([
            pointsCSV.length > 0 && zipWriter.add(pointsFileName, new BlobReader(pointsBlob)),
            linesCSV.length > 0 && zipWriter.add(linesFileName, new BlobReader(linesBlob)),
            polygonsCSV.length > 0 && zipWriter.add(polygonsFileName, new BlobReader(polygonsBlob)),
            volumesCSV.length > 0 && zipWriter.add(volumesFileName, new BlobReader(volumesBlob)),
            profilesList.length > 0 && profilesList.map((profile, index) => addProfileFile(profile, index, zipWriter))
        ]);
        return zipWriter.close();
    }

    function addProfileFile(profile: any, index: number, zipWriter: any) {
        const profileData = generateElevationProfileCSVData(profile, units);
        const profileBlob = new Blob([profileData], { type: 'text/csv' });
        const profileGeometryName = getGeometryName();
        const profileFileName = i18n.t('generatedReports:measurementReport_csv.filenames.profiles', {
            projectName,
            layerName,
            geometryName: profileGeometryName
        });

        return zipWriter.add(profileFileName, new BlobReader(profileBlob));

        function getGeometryName() {
            const geometryName = profileGeometriesNames[index];

            let duplicateFilenameIndex = '';

            if (geometryName in profilesDuplicateFilenames) {
                duplicateFilenameIndex = `_${profilesDuplicateFilenames[geometryName]}`;
                profilesDuplicateFilenames[geometryName] += 1;
            } else {
                profilesDuplicateFilenames[geometryName] = 1;
            }

            return geometryName + duplicateFilenameIndex;
        }
    }
}
