import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { useSelector } from '../../../store';
import { INVOICES_PAGE_LIMIT } from '../../../sharedConstants';

import styles from '../Invoices.module.scss';

export default function InvoicesSkeleton() {
    const { t } = useTranslation('cabinet');
    const sort = useSelector(state => state.invoices.sort);

    return (
        <table className={styles.skeletonTable}>
            <thead>
                <tr>
                    <th className={styles.thSorting}>
                        <div className={styles.sortingParameter}>
                            <span>{t('invoices.table.date')}</span>
                            <div
                                className={classNames(styles.sortArrow, sort.includes('+payment_date') && styles.up)}
                            />
                        </div>
                    </th>
                    <th>{t('invoices.table.resource')}</th>
                    <th className={styles.thValue}>{t('invoices.table.value')}</th>
                    <th className={styles.thStatus} />
                    <th className={styles.thActions} />
                </tr>
            </thead>
            <tbody>
                {_.range(INVOICES_PAGE_LIMIT).map(value => (
                    <tr key={value} data-testid={'invoiceSkeleton'}>
                        <td>
                            <div className={styles.skeleton} />
                        </td>
                        <td>
                            <div className={classNames(styles.skeleton, styles.resourceSkeleton)} />
                        </td>
                        <td>
                            <div className={styles.skeleton} />
                        </td>
                        <td>
                            <div className={classNames(styles.skeleton, styles.statusSkeleton)} />
                        </td>
                        <td>
                            <div className={styles.skeleton} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
