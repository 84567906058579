import React from 'react';
import { createPortal } from 'react-dom';

type Props = {
    message: string;
    visible: boolean;
    container?: Element | null;
};

export const BOTTOM_MESSAGE_TIMEOUT = 3000; // Confirmation message visbility timeout

export default function BottomMessage({ message, visible, container = document.body }: Props) {
    if (!visible || !container) return null;

    return createPortal(
        <div className='modal-message'>
            <div className='modal-message-text'>{message}</div>
        </div>,
        container
    );
}
