import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dataset } from '../../../entities/Dataset';
import { Artifact } from '../../../generated/cloud-frontend-api';
import { useSelector } from '../../../store';
import { ProjectStructureObjectTypes, TemporaryLayer } from '../../../store/helpers/interfaces';
import { selectedObjectInfoSelector } from '../../../store/selectors';
import { makeSelectCrsFromProjectCrsById } from '../../../store/slices/coordinateSystems';
import { ExtendedDatasetInfo } from '../../../store/slices/datasetfilesUpload';
import { adaptVectorLayerToDataset, selectUpload } from '../../../store/slices/datasetsUpload';
import SelectCrsAndTypeProperty from '../geometry-properties/SelectCrsAndTypeProperty';
import DatasetFileUploadInfo from './DatasetFileUploadInfo';
import DatasetGeneralPropertiesBlock from './DatasetGeneralPropertiesBlock';

export default function DatasetGeneralProperties() {
    const { t } = useTranslation(['projectView']);
    const selectedObject = useSelector(state => state.project.selectedObject);
    const selectedObjectInfo = useSelector(state => selectedObjectInfoSelector(state));
    const datasets = useSelector(state => state.datasets.datasets);
    const upload = useSelector(state => selectUpload(state, selectedObject.artifactId!));
    const dataset =
        selectedObject.type === ProjectStructureObjectTypes.ARTIFACT
            ? datasets.find(d => d.datasetUid === (selectedObjectInfo as Artifact).datasetUid)!
            : selectedObject.type === ProjectStructureObjectTypes.DATASET
            ? (selectedObjectInfo as ExtendedDatasetInfo)
            : adaptVectorLayerToDataset(selectedObjectInfo as TemporaryLayer, upload);

    const {
        isPendingManualAction,
        hasFailedPublishing,
        hasFailedValidation,
        isInProgress,
        hasFailedUpload,
        isInErrorState,
        isValidating
    } = useMemo(() => new Dataset(dataset), [dataset]);

    const selectCrsFromProjectCrsById = useMemo(makeSelectCrsFromProjectCrsById, []);
    const datasetCrs = useSelector(state => selectCrsFromProjectCrsById(state, dataset?.coordinateSystemUid!));

    const validationErrorMessage =
        dataset?.sourceData?.lastError ||
        upload?.error ||
        t('projectView:inspectionSidebar.datasetProperties.validationErrorMessage_default');

    const uploadErrorMessage =
        dataset.sourceData?.lastError ||
        t('projectView:inspectionSidebar.datasetProperties.uploadErrorMessage_default');

    const publicationErrorMessage =
        dataset?.visualData?.lastError ||
        t('projectView:inspectionSidebar.datasetProperties.publicationErrorMessage_default');

    const datasetCrsName = datasetCrs?.epsgCode
        ? 'EPSG::'.concat(datasetCrs?.epsgCode.toString().concat(` (${datasetCrs.name})`))
        : datasetCrs?.name;

    return (
        <>
            {isPendingManualAction && <SelectCrsAndTypeProperty />}

            {isInProgress && <DatasetFileUploadInfo />}

            {isInErrorState && (
                <div className='inspector-properties inspector-properties_upload upload-error'>
                    {hasFailedValidation && (
                        <>
                            <b className='upload-title'>
                                {t('projectView:inspectionSidebar.datasetProperties.validationError')}
                            </b>
                            <div>{validationErrorMessage}</div>
                        </>
                    )}
                    {hasFailedPublishing && (
                        <>
                            <b className='upload-title'>
                                {t('projectView:inspectionSidebar.datasetProperties.publicationError')}
                            </b>
                            <div>{publicationErrorMessage}</div>
                        </>
                    )}
                    {hasFailedUpload && (
                        <>
                            <b className='upload-title'>
                                {t('projectView:inspectionSidebar.datasetProperties.uploadError')}
                            </b>
                            <div>{uploadErrorMessage}</div>
                        </>
                    )}
                </div>
            )}

            {datasetCrsName && !isPendingManualAction && !isValidating && !hasFailedValidation && (
                <DatasetGeneralPropertiesBlock dataset={dataset} datasetCrsName={datasetCrsName} />
            )}
        </>
    );
}
