import * as Cesium from 'cesium';
import { useMemo } from 'react';
import { Entity, LabelGraphics, useCesium } from 'resium';
import { GeometryNamesFontSizes, GeometryTypes } from '../../../sharedConstants';
import { useSelector } from '../../../store';
import {
    TemporaryGeometry,
    TemporaryLayer,
    isPointGeometry,
    isPolygonGeometry,
    isPolylineGeometry
} from '../../../store/helpers/interfaces';
import { isObjectVisible, selectGroups, selectObjectVisibility } from '../../../store/slices/structure';
import PolygonMeasures from '../geometry-properties/measures/PolygonMeasures';

type Props = {
    geometry: TemporaryGeometry;
    layer: TemporaryLayer;
};

const fillColor = Cesium.Color.fromCssColorString('#1E1E1E');
const offset = new Cesium.Cartesian2(0, -25);

export default function GeometryName({ geometry, layer }: Props) {
    const { scene } = useCesium();
    const { structures } = useSelector(state => state.structure);
    const groups = useSelector(state => selectGroups(state));
    const structureInfo = structures.find(s => s.uid === layer.id);
    const group = groups.find(g => g.uid === structureInfo?.parentUid);
    const belongsToGroup = Boolean(group);
    const groupVisible = belongsToGroup ? isObjectVisible(structures, group?.uid!) : true;
    const layerVisible = useSelector(state => selectObjectVisibility(state, layer.id)) && groupVisible;
    const name = geometry.content.properties.ac_name;
    const fontSize = layer.geometriesNamesFont || GeometryNamesFontSizes.medium;

    const position = useMemo(() => getPosition(geometry), [geometry]);

    return (
        <Entity position={position} id={`${geometry.id}#nameLabel`}>
            <LabelGraphics
                showBackground
                fillColor={Cesium.Color.WHITE}
                disableDepthTestDistance={Infinity}
                pixelOffset={offset}
                text={name}
                font={fontSize}
                backgroundColor={fillColor}
                show={layerVisible && Boolean(geometry.content.properties.ac_visibility)}
                heightReference={Cesium.HeightReference.NONE}
            />
        </Entity>
    );
}

function getPosition(geometry: TemporaryGeometry): Cesium.Cartesian3 {
    if (isPointGeometry(geometry)) {
        const [lon, lat, height] = geometry.content.geometry.coordinates;
        return Cesium.Cartesian3.fromDegrees(lon, lat, height);
    }

    if (isPolylineGeometry(geometry)) {
        const [lon, lat, height] = geometry.content.geometry.coordinates[0];
        return Cesium.Cartesian3.fromDegrees(lon, lat, height);
    }

    if (isPolygonGeometry(geometry)) {
        const measures = new PolygonMeasures(geometry.content.geometry.coordinates);
        return measures.center();
    }

    return new Cesium.Cartesian3();
}
